import { connect } from 'react-redux'

import { confirmNewPassword } from 'actions/user'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

const errorSelector = createErrorSelector(['PASSWORD_RESET_CONFIRM'])
const loadingSelector = createLoadingSelector(['PASSWORD_RESET_CONFIRM'])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  error: errorSelector(state),
  isLoading: loadingSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  confirmNewPassword: (
    uid,
    token,
    password,
    passwordRepeat,
    history,
    callback,
  ) =>
    dispatch(
      confirmNewPassword(
        uid,
        token,
        password,
        passwordRepeat,
        history,
        callback,
      ),
    ),
})

export default connect(mapStateToProps, mapDispatchToProps)
