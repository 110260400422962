export const WORK_LOAD_STATUS = {
  STATUS_PENDING: 1,
  STATUS_ACCEPTED: 2,
  STATUS_REJECTED: 3,
}

export const WORK_LOAD_STATES = [
  {
    id: WORK_LOAD_STATUS.STATUS_PENDING,
    name: 'workload.status.pending',
  },
  {
    id: WORK_LOAD_STATUS.STATUS_ACCEPTED,
    name: 'workload.status.accepted',
  },
  {
    id: WORK_LOAD_STATUS.STATUS_REJECTED,
    name: 'workload.status.declined',
  },
]

export enum WORK_LOAD_CATEGORY {
  PRICEAGREEMENT,
  CLAIM,
  RELABELING,
  OTHER_SERVICE,
  CONTAINER_CHANGED,
  CANCELLED_PAID_DOCUMENT,
  ADDITIONAL_RENT,
  EFB,
  EPD_ROTATION,
}

export const WORK_LOAD_CATEGORIES = [
  {
    id: WORK_LOAD_CATEGORY.CONTAINER_CHANGED,
    name: 'workload.category.containerChanged',
  },
  {
    id: WORK_LOAD_CATEGORY.PRICEAGREEMENT,
    name: 'workload.category.priceagreement',
  },
  {
    id: WORK_LOAD_CATEGORY.CLAIM,
    name: 'workload.category.claim',
  },
  {
    id: WORK_LOAD_CATEGORY.OTHER_SERVICE,
    name: 'workload.category.otherService',
  },
  {
    id: WORK_LOAD_CATEGORY.ADDITIONAL_RENT,
    name: 'workload.category.additionalRent',
  },
  {
    id: WORK_LOAD_CATEGORY.CANCELLED_PAID_DOCUMENT,
    name: 'workload.category.cancelledPaidDocument',
  },
  {
    id: WORK_LOAD_CATEGORY.RELABELING,
    name: 'workload.category.relabeling',
  },
  {
    id: WORK_LOAD_CATEGORY.EFB,
    name: 'workload.category.efb',
  },
  {
    id: WORK_LOAD_CATEGORY.EPD_ROTATION,
    name: 'workload.category.epdRotation',
  },
]
