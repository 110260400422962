import { ErrorMessageTranslations } from './components/ErrorMessage/translations'
import { FooterTranslations } from './components/Footer/translations'
import { HeaderTranslations } from './components/Header/translations'
import { OrderManagementFormTranslations } from './components/OrderManagementForm/translations'
import { SucessMessageTranslations } from './components/SuccessMessage/translations'

export const WasteDisposerOrderManagementPageTranslations = {
  footer: FooterTranslations,
  header: HeaderTranslations,
  successMessage: SucessMessageTranslations,
  errorMessage: ErrorMessageTranslations,
  ...OrderManagementFormTranslations,
}
