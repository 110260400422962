import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import classNames from 'classnames'
import { oneOf, node } from 'prop-types'
import React from 'react'

export const INFO_BANNER_BACKGROUND_COLOR = {
  DEFAULT: 'default',
  WARNING: 'warning',
}

const InfoBanner = ({ backgroundColor, children }) => (
  <div
    className={classNames(
      'info-banner',
      `info-banner--background-color-${backgroundColor}`,
    )}
  >
    {children}
  </div>
)

InfoBanner.propTypes = forbidExtraProps({
  backgroundColor: oneOf(Object.values(INFO_BANNER_BACKGROUND_COLOR)),
  children: node.isRequired,
})

InfoBanner.defaultProps = {
  backgroundColor: INFO_BANNER_BACKGROUND_COLOR.DEFAULT,
}

export default InfoBanner
