import german from 'hyphenation.de'
import Hypher from 'hypher'
import moment from 'moment'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { SectionHeader } from 'components/common/SectionHeader'
import { ReadOnlyField } from 'components/common/ReadOnly'
import Icon from 'components/common/Fontello'
import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'

import { INVOICE_CHECK_STATUS } from '../../constants'

interface InvoiceCheckOverviewInvoiceDataProps {
  invoiceCheck?: InvoiceCheck
  handleOpenCloseInvoiceCheckEditFormModal: any
}

/**
 * @description This component displays the details of an InvoiceCheck.
 * @function
 */
export const InvoiceCheckOverviewInvoiceData: FC<
  InvoiceCheckOverviewInvoiceDataProps
> = ({
  invoiceCheck = null,
  handleOpenCloseInvoiceCheckEditFormModal = null,
}) => {
  if (!invoiceCheck) return null

  const hypher = new Hypher(german)

  return (
    <section className='uk-card uk-padding-remove'>
      <SectionHeader>
        <Icon name='to-do' />
        <Translate value='invoiceCheckOverviewTranslations.section_header.invoice_data' />
      </SectionHeader>
      <div className='uk-padding details-container'>
        <div className='uk-grid'>
          <div className='uk-width-1-2'>
            {invoiceCheck.company_object && (
              <ReadOnlyField
                className='uk-margin-medium-bottom'
                label={I18n.t(
                  'invoiceCheckOverviewTranslations.section.invoice_data.company_name',
                )}
                value={hypher.hyphenateText(
                  invoiceCheck.company_object.name ?? '',
                )}
              />
            )}
          </div>
          <div className='uk-width-1-2'>
            {invoiceCheck.status ===
              INVOICE_CHECK_STATUS.INVOICE_CHECK_PENDING && (
              <ButtonLink
                className='uk-float-right'
                onClick={handleOpenCloseInvoiceCheckEditFormModal}
                linkColor={BUTTON_LINK_COLOR.PRIMARY}
              >
                {I18n.t('invoiceCheckOverviewTranslations.editInvoiceCheck')}
              </ButtonLink>
            )}
          </div>
          <div className='uk-width-1-1 uk-width-1-2@l'>
            <ReadOnlyField
              className='uk-margin-medium-bottom'
              label={I18n.t(
                'invoiceCheckOverviewTranslations.section.invoice_data.document_number',
              )}
              value={invoiceCheck.document_number}
            />
          </div>
          <div className='uk-width-1-1 uk-width-1-2@l'>
            <ReadOnlyField
              className='uk-margin-medium-bottom'
              label={I18n.t(
                'invoiceCheckOverviewTranslations.section.invoice_data.document_date',
              )}
              value={moment(invoiceCheck.document_date).format('L')}
            />
          </div>
          <div className='uk-width-1-1 uk-width-1-2@l'>
            <ReadOnlyField
              className='uk-margin-medium-bottom'
              label={I18n.t(
                'invoiceCheckOverviewTranslations.section.invoice_data.gross_price',
              )}
              value={parseFloat(
                invoiceCheck.gross_price.toString(),
              ).toLocaleString('de-DE', {
                style: 'currency',
                currency: 'EUR',
              })}
            />
          </div>
          <div className='uk-width-1-1 uk-width-1-2@l'>
            <ReadOnlyField
              className='uk-margin-medium-bottom'
              label={I18n.t(
                'invoiceCheckOverviewTranslations.section.invoice_data.vat',
              )}
              value={`${parseFloat(`${invoiceCheck.vat}`).toLocaleString(
                'de-DE',
                { minimumFractionDigits: 0 },
              )}%`}
            />
          </div>
          <div className='uk-width-1-1 uk-width-1-2@l'>
            <ReadOnlyField
              className='uk-margin-medium-bottom'
              label={I18n.t(
                'invoiceCheckOverviewTranslations.section.invoice_data.document_type.label',
              )}
              value={I18n.t(
                `invoiceCheckOverviewTranslations.section.invoice_data.document_type.${invoiceCheck.document_type}`,
              )}
            />
          </div>
          <div className='uk-width-1-1 uk-width-1-2@l'>
            <ReadOnlyField
              className='uk-margin-medium-bottom'
              label={I18n.t(
                'invoiceCheckOverviewTranslations.section.invoice_data.created_at',
              )}
              value={moment(invoiceCheck.created_at).format('L')}
            />
          </div>
          <div className='uk-width-1-1 uk-width-1-2@l'>
            <ReadOnlyField
              className='uk-margin-medium-bottom'
              label={I18n.t(
                'invoiceCheckOverviewTranslations.section.invoice_data.created_by',
              )}
              value={invoiceCheck.created_by_name}
            />
          </div>
          <div className='uk-width-1-1'>
            {invoiceCheck.comment && (
              <ReadOnlyField
                className='uk-margin-medium-bottom'
                label={I18n.t(
                  'invoiceCheckOverviewTranslations.section.invoice_data.comment',
                )}
                value={hypher.hyphenateText(invoiceCheck.comment ?? '')}
              />
            )}
          </div>
          {invoiceCheck.status ===
            INVOICE_CHECK_STATUS.INVOICE_CHECK_STATUS_REJECTED && (
            <>
              <div className='uk-width-1-1 uk-width-1-2@l'>
                <ReadOnlyField
                  className='uk-margin-medium-bottom'
                  label={I18n.t(
                    'invoiceCheckOverviewTranslations.section.invoice_data.rejected_at',
                  )}
                  value={moment(invoiceCheck.reviewed_at).format('L')}
                />
              </div>
              <div className='uk-width-1-1 uk-width-1-2@l'>
                <ReadOnlyField
                  className='uk-margin-medium-bottom'
                  label={I18n.t(
                    'invoiceCheckOverviewTranslations.section.invoice_data.rejected_by',
                  )}
                  value={invoiceCheck.reviewed_by_name}
                />
              </div>
              <div className='uk-width-1-1'>
                <ReadOnlyField
                  className='uk-margin-medium-bottom'
                  label={I18n.t(
                    'invoiceCheckOverviewTranslations.section.invoice_data.cancel_reason_text',
                  )}
                  value={hypher.hyphenateText(
                    invoiceCheck.cancel_reason_text ?? '',
                  )}
                />
              </div>
            </>
          )}
          {invoiceCheck.status ===
            INVOICE_CHECK_STATUS.INVOICE_CHECK_STATUS_ACCEPTED && (
            <>
              <div className='uk-width-1-1 uk-width-1-2@l'>
                <ReadOnlyField
                  className='uk-margin-medium-bottom'
                  label={I18n.t(
                    'invoiceCheckOverviewTranslations.section.invoice_data.released_at',
                  )}
                  value={moment(invoiceCheck.reviewed_at).format('L')}
                />
              </div>
              <div className='uk-width-1-1 uk-width-1-2@l'>
                <ReadOnlyField
                  className='uk-margin-medium-bottom'
                  label={I18n.t(
                    'invoiceCheckOverviewTranslations.section.invoice_data.released_by',
                  )}
                  value={invoiceCheck.reviewed_by_name}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  )
}
