import keyMirror from 'keymirror'

export const userInvitationInitialState = {
  email: '',
  gender: '',
  first_name: '',
  last_name: '',
  preferred_address: null,
  profile_image: null,
  group_id: null,
}

export const USER_MODALS = keyMirror({
  USER_FORM_MODAL: null,
  CONTACT_PERSON_INFO_MODAL: null,
})

export const USER_STATUS = {
  STATUS_INVITED: 1,
  STATUS_ACTIVE: 2,
  STATUS_BLOCKED: 3,
}

export const USER_STATES = [
  {
    id: USER_STATUS.STATUS_INVITED,
    name: 'constant.status.invited',
    colorClass: 'gray',
  },
  {
    id: USER_STATUS.STATUS_ACTIVE,
    name: 'constant.status.active',
    colorClass: 'green',
  },
  {
    id: USER_STATUS.STATUS_BLOCKED,
    name: 'constant.status.locked',
    colorClass: 'red',
  },
]

export default {
  ...USER_MODALS,
  ...USER_STATUS,
  ...USER_STATES,
}
