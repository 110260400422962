import { I18n } from 'react-i18nify'

import { get } from 'helper/general'

export const getPageTitleForAgreement = (offer: Offer, user: User) => {
  const addressText =
    offer &&
    offer.company_object &&
    user &&
    user.company === offer.company_object.id
      ? offer.company_object.name
      : offer &&
        offer.collection_address_object &&
        offer.collection_address_object.display_name

  return I18n.t('offerDetails.offerForAccepted', {
    offerNumber: get(() => offer!.id),
    address: addressText,
  })
}

/**
 * Gets the current selected container properties.
 */
export const getCurrentContainer = (
  containers: Container[],
  selectedContainer: number,
) => containers.find(container => container.id === selectedContainer)
