import { useFormikContext } from 'formik'
import React from 'react'
import { I18n } from 'react-i18nify'

import { InputDecimal } from 'components/common/InputDecimal'

import { AddAgreementFormValues } from '../../AddAgreementFormSteps'
import { ROLE_KEYS } from '../../Step1/helpers'
import { PRICING } from '../DefineNetPriceForm'
import { getContainerPriceFieldValuesByRole } from '../helpers'

import { inputIsValid } from './helpers'

type PackagePriceFormFieldsPropsType = {
  pricing: string
  role: string
}

export const ContainerPriceFormFields = ({
  pricing,
  role,
}: PackagePriceFormFieldsPropsType) => {
  const translationString = 'addAgreementPageTranslations.steps.4.fields'

  const { values, handleChange } = useFormikContext<AddAgreementFormValues>()

  const { packageRentPrice, costContainer, compensationContainer } =
    getContainerPriceFieldValuesByRole({ role, values })

  return (
    <>
      {/*BEHÄLTERPREISE KUNDE*/}
      {role === ROLE_KEYS.CUSTOMER && pricing === PRICING.DISPOSAL && (
        <>
          {/*MIETPREIS (€ PRO BEHÄLTER UND MONAT*/}
          <InputDecimal
            name={`${role}_rent_price`}
            label={I18n.t(`${translationString}.rentPrice.label`)}
            placeholder={I18n.t(`${translationString}.rentPrice.placeholder`)}
            value={values.customer_rent_price}
            maxValue={999.99}
            onChange={e =>
              inputIsValid(values.customer_rent_price, e.target.value) &&
              handleChange(e)
            }
          />
          {/*ENTSORGUNGSKOSTEN (€ PRO BEHÄLTER)*/}
          <InputDecimal
            name={`${role}_disposal_cost_container`}
            label={I18n.t(`${translationString}.containerDisposalPrice.label`)}
            placeholder={I18n.t(
              `${translationString}.containerDisposalPrice.placeholder`,
            )}
            value={values.customer_disposal_cost_container}
            maxValue={4999.99}
            onChange={e =>
              inputIsValid(
                values.customer_disposal_cost_container,
                e.target.value,
              ) && handleChange(e)
            }
          />
        </>
      )}

      {role === ROLE_KEYS.PARTNER && pricing === PRICING.DISPOSAL && (
        <>
          {/*MIETPREIS (€ PRO BEHÄLTER UND MONAT)*/}
          <InputDecimal
            name={`${role}_rent_price`}
            label={I18n.t(`${translationString}.rentPriceContainerMonth.label`)}
            placeholder={I18n.t(
              `${translationString}.rentPriceContainerMonth.placeholder`,
            )}
            value={packageRentPrice}
            maxValue={999.99}
            onChange={e =>
              inputIsValid(packageRentPrice, e.target.value) && handleChange(e)
            }
          />
          {/*TRANSPORT- UND ENTSORGUNGSKOSTEN (€ PRO BEHÄLTER)*/}
          <InputDecimal
            name={`${role}_disposal_cost_container`}
            label={I18n.t(
              `${translationString}.packageTransportAndDisposalPrice.label`,
            )}
            placeholder={I18n.t(
              `${translationString}.packageTransportAndDisposalPrice.placeholder`,
            )}
            value={values.partner_disposal_cost_container}
            maxValue={4999.99}
            onChange={e =>
              inputIsValid(
                values.partner_disposal_cost_container,
                e.target.value,
              ) && handleChange(e)
            }
          />
        </>
      )}

      {/*PREISMODELL VERGÜTUNG*/}
      {pricing === PRICING.COMPENSATION && (
        <>
          {/*MIETPREIS (€ PRO BEHÄLTER UND MONAT)*/}
          <InputDecimal
            name={`${role}_rent_price`}
            label={I18n.t(`${translationString}.rentPriceContainerMonth.label`)}
            placeholder={I18n.t(
              `${translationString}.rentPriceContainerMonth.placeholder`,
            )}
            value={packageRentPrice}
            maxValue={999.99}
            onChange={e =>
              inputIsValid(packageRentPrice, e.target.value) && handleChange(e)
            }
          />
          {/*KOSTEN (€ PRO BEHÄLTER)*/}
          {/*cost_container is not yet implemented in the backend */}
          <InputDecimal
            name={`${role}_cost_container`}
            label={I18n.t(`${translationString}.costContainer.label`)}
            placeholder={I18n.t(
              `${translationString}.costContainer.placeholder`,
            )}
            value={costContainer}
            maxValue={4999.99}
            onChange={e =>
              inputIsValid(costContainer, e.target.value) && handleChange(e)
            }
          />
          {/*VERGÜTUNG (€ PRO BEHÄLTER)*/}
          <InputDecimal
            name={`${role}_compensation_container`}
            label={I18n.t(`${translationString}.compensationContainer.label`)}
            placeholder={I18n.t(
              `${translationString}.compensationContainer.placeholder`,
            )}
            value={compensationContainer}
            maxValue={4999.99}
            onChange={e =>
              inputIsValid(compensationContainer, e.target.value) &&
              handleChange(e)
            }
          />
        </>
      )}
    </>
  )
}
