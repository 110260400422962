import { useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC, useContext } from 'react'
import { I18n } from 'react-i18nify'

import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { FormStepSummaryRow } from 'components/common/FormSteps'
import {
  INQUIRY_ORDER_TYPE,
  INQUIRY_ORDER_TYPES,
  INQUIRY_SERVICE_INTERVAL,
  INQUIRY_SERVICE_INTERVALS,
  INQUIRY_TIMES_OF_DAY,
} from 'components/inquiry/constants'
import { WEEKDAYS } from 'constants/app'

import {
  AddAgreementFormContext,
  AddAgreementFormValues,
} from '../AddAgreementFormSteps'

export const SelectAgreementDetailsSummary: FC = () => {
  const { values } = useFormikContext<AddAgreementFormValues>()
  const { editStep } = useContext(AddAgreementFormContext)

  return (
    <div className='maklerpremium-step-summary'>
      <div className='maklerpremium-step-summary_grid-body'>
        <FormStepSummaryRow
          label={I18n.t(
            'orderTypeDetailsGroupTranslations.form.label.orderType',
          )}
          value={I18n.t(
            String(
              INQUIRY_ORDER_TYPES.find(
                type => type.id === Number(values.order_type),
              )?.name,
            ),
          )}
          key='maklerpremium-step-summary-order_type'
        />
        {Number(values.order_type) === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME && (
          <>
            <FormStepSummaryRow
              label={I18n.t(
                'orderTypeDetailsGroupTranslations.form.label.deliveryDate',
              )}
              value={`${moment(values.delivery_date).format('L')}, ${I18n.t(
                String(
                  INQUIRY_TIMES_OF_DAY.find(
                    timeOfDay =>
                      timeOfDay.id === Number(values.time_of_day_delivery),
                  )?.name,
                ),
              )}`}
              key='maklerpremium-step-summary-delivery_date'
            />
            <FormStepSummaryRow
              label={I18n.t(
                'orderTypeDetailsGroupTranslations.form.label.collectionDate',
              )}
              value={`${moment(values.collection_date).format('L')}, ${I18n.t(
                String(
                  INQUIRY_TIMES_OF_DAY.find(
                    timeOfDay =>
                      timeOfDay.id === Number(values.time_of_day_collection),
                  )?.name,
                ),
              )}`}
              key='maklerpremium-step-summary-collection_date'
            />
          </>
        )}
        {(Number(values.order_type) === INQUIRY_ORDER_TYPE.TYPE_ON_DEMAND ||
          Number(values.order_type) === INQUIRY_ORDER_TYPE.TYPE_RECURRING) && (
          <>
            <FormStepSummaryRow
              label={I18n.t(
                'orderTypeDetailsGroupTranslations.form.label.agreementBegin',
              )}
              value={moment(values.turn_begin).format('L')}
              key='maklerpremium-step-summary-turn_begin'
            />
            <FormStepSummaryRow
              label={I18n.t(
                'orderTypeDetailsGroupTranslations.form.placeholder.agreementEnd',
              )}
              value={moment(values.turn_end).format('L')}
              key='maklerpremium-step-summary-turn_end'
            />
            {Number(values.order_type) ===
              INQUIRY_ORDER_TYPE.TYPE_RECURRING && (
              <>
                <FormStepSummaryRow
                  label={I18n.t(
                    'orderTypeDetailsGroupTranslations.form.label.interval',
                  )}
                  value={I18n.t(
                    String(
                      INQUIRY_SERVICE_INTERVALS.find(
                        interval => interval.id === Number(values.interval),
                      )?.name,
                    ),
                  )}
                  key='maklerpremium-step-summary-interval'
                />
                <FormStepSummaryRow
                  label={I18n.t(
                    'orderTypeDetailsGroupTranslations.form.label.intervalWeekdayFirst',
                  )}
                  value={I18n.t(
                    String(
                      WEEKDAYS.find(
                        weekday =>
                          weekday.id === Number(values.interval_weekday_first),
                      )?.name,
                    ),
                  )}
                  key='maklerpremium-step-summary-weekday_first'
                />
                {Number(values.interval) ===
                  INQUIRY_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK && (
                  <FormStepSummaryRow
                    label={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.label.intervalWeekdaySecond',
                    )}
                    value={I18n.t(
                      String(
                        WEEKDAYS.find(
                          weekday =>
                            weekday.id ===
                            Number(values.interval_weekday_second),
                        )?.name,
                      ),
                    )}
                    key='maklerpremium-step-summary-weekday_second'
                  />
                )}
                {values.rythm_begin && (
                  <FormStepSummaryRow
                    label={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.label.rythmBegin',
                    )}
                    value={moment(values.rythm_begin).format('L')}
                    key='maklerpremium-step-summary-rythm_begin'
                  />
                )}
              </>
            )}
          </>
        )}
      </div>

      <div className='sidebar'>
        <ButtonLink
          className='sidebar__edit-link'
          linkColor={BUTTON_LINK_COLOR.PRIMARY}
          onClick={editStep}
        >
          {I18n.t('createInquiryTranslations.form.button.editStep')}
        </ButtonLink>
      </div>
    </div>
  )
}
