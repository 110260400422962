import React, {
  FC,
  FocusEventHandler,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OnChangeValue } from 'react-select'

import {
  getActiveCompanies,
  getCombinedOfferEPDCompaniesForTVP,
  getCustomInvoiceCompanies,
  getMaklerPremiumCompanies,
} from 'actions/company'
import {
  getActiveCompaniesListSelector,
  getMaklerPremiumCompaniesSelector,
} from 'selectors/company'
import { createLoadingSelector } from 'selectors/loading'

import { COMPANY_ROLE } from '../../company/constants'
import {
  AsyncMultiselectCombobox,
  AsyncMultiselectComboboxProps,
} from '../AsyncMultiselectCombobox/AsyncMultiselectCombobox'
import { Option } from '../StaticCombobox/StaticCombobox'

export enum FindCompanyByFilterInputActions {
  ACTIVE_COMPANIES,
  COMBINED_OFFER_EPD_COMPANIES_FOR_TVP,
  MAKLER_PREMIUM_COMPANIES,
  CUSTOM_INVOICE_COMPANIES,
}

export const FindCompanyByFilterInputActionMap = {
  [FindCompanyByFilterInputActions.ACTIVE_COMPANIES]: {
    action: getActiveCompanies,
    loadingSelector: 'GET_ACTIVE_COMPANIES',
    selector: getActiveCompaniesListSelector,
    roleKey: 'role',
  },
  [FindCompanyByFilterInputActions.COMBINED_OFFER_EPD_COMPANIES_FOR_TVP]: {
    action: getCombinedOfferEPDCompaniesForTVP,
    loadingSelector: 'GET_COMBINED_OFFER_EPD_COMPANIES_FOR_TVP',
    selector: getMaklerPremiumCompaniesSelector,
    roleKey: 'maklerpremium_role',
  },
  [FindCompanyByFilterInputActions.MAKLER_PREMIUM_COMPANIES]: {
    action: getMaklerPremiumCompanies,
    loadingSelector: 'GET_MAKLER_PREMIUM_COMPANIES',
    selector: getMaklerPremiumCompaniesSelector,
    roleKey: 'maklerpremium_role',
  },
  [FindCompanyByFilterInputActions.CUSTOM_INVOICE_COMPANIES]: {
    action: getCustomInvoiceCompanies,
    loadingSelector: 'GET_CUSTOM_INVOICE_COMPANIES',
    selector: getActiveCompaniesListSelector,
    roleKey: 'role',
  },
}

interface FindCompanyByFilterInputProps
  extends Omit<
    AsyncMultiselectComboboxProps,
    'value' | 'isLoading' | 'loadOptions' | 'options' | 'handleChange'
  > {
  action?: FindCompanyByFilterInputActions
  additionalFilters?: any
  error?: string
  handleBlur: FocusEventHandler<HTMLElement>
  handleSelectionChange: (value: any, companies: Company[]) => void
  mapOptionLabel: (company: CompanySearchFilterResponse) => Option
  minCharacters?: number
  name: string
  noOptionsMessage: string
  placeholder: string
  resultType?: COMPANY_ROLE
  value?: number
  withCheckmark?: boolean
  hasEmptoAgreements?: boolean
  hasMaklerPremiumAgreements?: boolean
  disposerProducerListCall?: boolean
  initialOptions?: any
  debounceCallbackTime?: number
  loadOptions?: (value?: string) => void
  options?: Record<string, any>[]
  handleChange?: (
    value?: OnChangeValue<any, true> | OnChangeValue<any, false>,
    action?: string,
  ) => void
}

export const FindCompanyByFilterInput: FC<FindCompanyByFilterInputProps> = ({
  action = FindCompanyByFilterInputActions.MAKLER_PREMIUM_COMPANIES,
  additionalFilters,
  error = '',
  handleBlur,
  handleSelectionChange,
  mapOptionLabel,
  minCharacters = 3,
  name,
  noOptionsMessage,
  placeholder,
  resultType,
  value = 0,
  withCheckmark = false,
  hasEmptoAgreements = false,
  hasMaklerPremiumAgreements = false,
  initialOptions = undefined, // this is an edge-case for Vereinbarung+, because we need to load 2 companies at once
  debounceCallbackTime = 400,
  ...rest
}) => {
  const dispatch = useDispatch()
  const loadingSelector = useMemo(
    () => FindCompanyByFilterInputActionMap[action].loadingSelector,
    [action],
  )
  const isLoading = useSelector(createLoadingSelector([loadingSelector]))

  const selector = useMemo(
    () => FindCompanyByFilterInputActionMap[action].selector,
    [action],
  )

  // set companies by triggering a useEffect when the loading state changes
  // this helps get around the problem of triggering the child components with changed companies dozens of times
  // apparently redux refreshes the selector and changes it to a seemingly different value during each state transition
  const reduxCompanies = useSelector(selector)
  const [companies, setCompanies] = useState<Company[]>([])
  useEffect(() => {
    if (!isLoading) {
      if (initialValue) {
        setCompanies([initialValue, ...reduxCompanies])
      } else {
        setCompanies(reduxCompanies)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, reduxCompanies])

  const roleKey = useMemo(
    () => FindCompanyByFilterInputActionMap[action].roleKey,
    [action],
  )

  const actionFunction = useMemo(
    () => FindCompanyByFilterInputActionMap[action].action,
    [action],
  )

  const loadOptionsFromApi = (inputValue?: string, inputName?: string) => {
    if (inputValue && inputValue.length >= minCharacters) {
      // if not explicitly passed as prop, action will default to getMaklerPremiumCompanies
      const filters: { [key: string]: string | number | boolean | undefined } =
        {
          [inputName || name]: inputValue,
          [roleKey]: resultType,
          page_size: 1000,
          ...additionalFilters,
        }

      // only add filter if it is true, because the value of false will be interpreted as string from backend
      // and this is valid value for filter
      if (hasMaklerPremiumAgreements) {
        filters.has_maklerpremium_agreements = hasMaklerPremiumAgreements
      }
      if (hasEmptoAgreements) {
        filters.has_empto_agreements = hasEmptoAgreements
      }
      dispatchAction(filters)
    }
  }

  const dispatchAction = (filters: {
    [key: string]: string | number | boolean | undefined
  }) => {
    if (
      [
        FindCompanyByFilterInputActions.ACTIVE_COMPANIES,
        FindCompanyByFilterInputActions.CUSTOM_INVOICE_COMPANIES,
      ].includes(action)
    ) {
      dispatch(actionFunction(filters))
    } else {
      dispatch(actionFunction(0, filters)) // MAKLER_PREMIUM_COMPANIES is paginated
    }
  }

  // when the component is mounted, we want to fetch the company with the given id
  useEffect(() => {
    if (
      value &&
      action !== FindCompanyByFilterInputActions.CUSTOM_INVOICE_COMPANIES
    ) {
      dispatchAction({ id: value })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialValue = useMemo(() => {
    return value !== 0
      ? (initialOptions ?? companies).find(option => option.id === value)
      : undefined
  }, [initialOptions, value, companies])

  return (
    <AsyncMultiselectCombobox
      error={error}
      getOptionLabel={company =>
        mapOptionLabel(company as CompanySearchFilterResponse).label
      }
      getOptionValue={company =>
        `${mapOptionLabel(company as CompanySearchFilterResponse).value}`
      }
      handleBlur={handleBlur}
      handleChange={company => {
        handleSelectionChange(company.id, companies)
      }}
      isLoading={isLoading}
      loadingMessage={''}
      loadOptions={loadOptionsFromApi}
      debounceCallbackTime={debounceCallbackTime}
      multiSelect={false}
      name={name}
      noInputMessage={placeholder}
      noOptionsMessage={noOptionsMessage}
      options={companies}
      placeholder={placeholder}
      showCheckmark={!!value && !error}
      withCheckmark={withCheckmark}
      {...rest}
      label={placeholder}
      value={initialValue}
    />
  )
}
