const autoOfferApiTranslations = {
  handleGetMyAutoOffers: {
    error: 'Jobs konnten leider nicht geladen werden.',
  },
  handleGetAutoOffer: {
    error: 'Job konnten leider nicht geladen werden.',
  },
  handleCreateAutoOffer: {
    success: 'Job wurde erfolgreich hinzugefügt.',
    error: 'Hinzufügen des Jobs leider fehlgeschlagen.',
  },
  handleUpdateAutoOffer: {
    success: 'Job wurde erfolgreich aktualisiert.',
    error: 'Speichern des Jobs leider fehlgeschlagen.',
  },
  handleUpdateStatusAutoOffer: {
    activation: {
      success: 'Job wurde erfolgreich aktiviert.',
    },
    deactivation: {
      success: 'Job wurde erfolgreich deaktiviert.',
    },
  },
  handleUploadAutoOffersCSV: {
    success: 'Daten wurden erfolgreich hochgeladen.',
    error: 'Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre Datei.',
    detailsInfoError: 'Es liegen Fehler in folgenden Reihen vor: %{lines}.',
  },
  handleLockAutoOffers: {
    error: 'Verwalten des Angebotsassistenten ist leider fehlgeschlagen.',
  },
  handleExportAutoOffers: {
    error: 'Exportieren des Angebotsassistenten leider fehlgeschlagen.',
    success: 'CSV-Export-Auftrag erfolgreich erhalten.',
  },
}

export default autoOfferApiTranslations
