import ActionTypes from '../actiontypes/fraction'

/** @function
 * @name getFractions
 * @description An action method get a list of fractions.
 * @return {Object}
 */
export const getFractions = () => ({
  type: ActionTypes.GET_FRACTIONS_REQUEST,
})

/** @function
 * @name getFractionContent
 * @description An action method to get the content from fractions.
 * @return {Object}
 */
export const getFractionContent = () => ({
  type: ActionTypes.GET_FRACTION_CONTENT_REQUEST,
})

/**
 * @function
 * @name getFractionsForFilter
 * @description An action method to get a list of all Factions serialized for filter usage
 * @return {Object}
 */
export const getFractionsForFilter = filters => ({
  type: ActionTypes.GET_FRACTIONS_FOR_FILTER_REQUEST,
  payload: {
    ...filters,
  },
})

export default {}
