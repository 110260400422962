/*
 * Google Analytics Middleware
 */
import { useEffect } from 'react'
import { withRouter } from 'react-router'

import { APP_CONSTANTS } from '../constants/app'

export const trackingId = APP_CONSTANTS.REACT_APP_GA_PROPERTY

const GoogleAnalyticsComponent = ({ children, location: { pathname } }) => {
  useEffect(() => {
    const { gtag } = window

    if (typeof gtag === 'function') {
      gtag('config', trackingId, {
        page_title: document.title,
        page_location: window.location.href,
        page_path: pathname,
      })
    }
  }, [pathname])

  return children || null
}

export const GoogleAnalytics = withRouter(GoogleAnalyticsComponent)
