import { Form, withFormik } from 'formik'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { compose } from 'recompose'
import * as yup from 'yup'
import { bool, func, number, shape, string } from 'prop-types'

import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import Icon from 'components/common/Fontello'
import { passwordIsSafe } from 'components/common/Form/validators'
import InputPassword from 'components/common/InputPassword'
import { ProgressButton } from 'components/common/ProgressButton'
import withErrorBoundary from 'helper/withErrorBoundary'

import connector from './connector'

/**
 * @description This component renders and controls a set password form component.
 * @function
 * @param {Object} props the component props
 */
export class SetPasswordFormComponent extends Component {
  static propTypes = {
    errors: shape({
      password: string,
      password_repeat: string,
      confirmPassword: string,
    }).isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    handleSubmit: func.isRequired,
    isLoading: bool,
    isSubmitting: bool.isRequired,
    isValid: bool.isRequired,
    submitCount: number.isRequired,
    touched: shape({
      password: bool,
      password_repeat: bool,
      confirmPassword: bool,
    }).isRequired,
    values: shape({
      password: string,
      password_repeat: string,
      confirmPassword: string,
    }).isRequired,
  }

  static defaultProps = {
    isLoading: false,
  }

  render() {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      isLoading,
      isSubmitting,
      isValid,
      submitCount,
      touched,
      values,
    } = this.props

    return (
      <div className='set-password-form-container uk-text-left uk-align-center'>
        <Form
          className='set-password-form'
          data-testid='set-password-form'
          onSubmit={handleSubmit}
          noValidate // Disable browser validation
        >
          <div className='uk-position-relative uk-width-1-1'>
            <InputPassword
              dataTestId='set-password-form-password-input'
              dataTestIdError='set-password-form-group-error'
              error={submitCount > 0 && touched.password ? errors.password : ''}
              isRequired={values.password === ''}
              label={I18n.t(
                'userActivationTranslations.setPasswordForm.password.label',
              )}
              maxLength={50}
              name='password'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'userActivationTranslations.setPasswordForm.password.placeholder',
              )}
              value={values.password}
            />
          </div>

          <div className='uk-position-relative uk-width-1-1'>
            <InputPassword
              dataTestId='set-password-form-password-repeat-input'
              dataTestIdError='set-password-form-group-error'
              error={
                submitCount > 0 && touched.password_repeat
                  ? errors.password_repeat
                  : ''
              }
              isRequired={values.password_repeat === ''}
              label={I18n.t(
                'userActivationTranslations.setPasswordForm.repeatPassword.label',
              )}
              maxLength={50}
              name='password_repeat'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'userActivationTranslations.setPasswordForm.repeatPassword.placeholder',
              )}
              value={values.password_repeat}
            />
          </div>

          <div className='uk-margin'>
            <ProgressButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              dataTestId='set-password-form-submit'
              isDisabled={
                (submitCount > 0 && !isValid) || (isSubmitting && isLoading)
              }
              isLoading={isSubmitting && isLoading}
              onClick={handleSubmit}
            >
              <Icon name='enter' />
              <Translate value='userActivationTranslations.setPasswordForm.submit' />
            </ProgressButton>
          </div>
        </Form>
      </div>
    )
  }
}

export default compose(
  withErrorBoundary,
  connector,
  withFormik({
    handleSubmit: (values, { props, setFieldError }) => {
      props.setPassword(props.uid, props.token, values.password, {
        success: () => props.onSuccess(),
        setError: (key, string) => setFieldError(key, string),
      })
    },
    mapPropsToValues: () => ({
      password: '',
      password_repeat: '',
    }),
    validationSchema: () =>
      yup.object().shape({
        password: yup
          .string()
          .required(
            I18n.t(
              'userActivationTranslations.setPasswordForm.password.required',
            ),
          )
          .test(
            'secure-password',
            I18n.t(
              'userActivationTranslations.setPasswordForm.password.notSecure',
            ),
            passwordIsSafe,
          ),
        password_repeat: yup
          .string()
          .required(
            I18n.t(
              'userActivationTranslations.setPasswordForm.repeatPassword.required',
            ),
          )
          .oneOf(
            [yup.ref('password')],
            I18n.t(
              'userActivationTranslations.setPasswordForm.repeatPassword.notEqual',
            ),
          ),
      }),
  }),
)(SetPasswordFormComponent)
