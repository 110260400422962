import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

import { PaginatedTable } from 'components/common/PaginatedTable'
import { UpDownArrow } from 'components/common/UpDownArrow'

export const ActivitiesTable: FC = () => {
  const activitiesTableColumns = [
    {
      id: 'timestamp',
      Header: (
        <span>
          <Translate value='priceManagementPageTranslations.activitiesTable.columns.timestamp' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'timestamp',
      maxWidth: 150,
    },
    {
      id: 'name',
      Header: (
        <span>
          <Translate value='priceManagementPageTranslations.activitiesTable.columns.name' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'name',
      maxWidth: 150,
    },
    {
      id: 'activity',
      Header: (
        <span>
          <Translate value='priceManagementPageTranslations.activitiesTable.columns.activity' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'activity',
      maxWidth: 200,
    },
    {
      id: 'entry',
      Header: (
        <span>
          <Translate value='priceManagementPageTranslations.activitiesTable.columns.entry' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'entry',
      maxWidth: 380,
    },
    {
      id: 'change',
      Header: (
        <span>
          <Translate value='priceManagementPageTranslations.activitiesTable.columns.change' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'change',
    },
  ]

  const testData = [
    {
      timestamp: '12.09.2021, 17:41',
      name: 'Gotsch, Justine',
      activity: 'Neue Kombination angelegt',
      entry: 'verpackungslizenzierung.de, 2019, Papier/Pappe/Karton',
      change: 'Eintrag neu angelegt',
    },
    {
      timestamp: '12.09.2021, 17:41',
      name: 'Gotsch, Justine',
      activity: 'Kombination verändert',
      entry: 'verpackungslizenzierung.de, 2019, Kunststoffe',
      change:
        'Vorher: Preis bis 4,999 to 19,00 €, Nachher: Preis bis 4,999 to 25,00 €',
    },
  ]

  return (
    <PaginatedTable
      serverSidePagination
      page={1}
      pages={1}
      loading={false}
      handleShowPreviousPage={() => undefined}
      handleShowNextPage={() => undefined}
      table={{
        columns: activitiesTableColumns,
        data: testData,
        onFetchData: () => undefined,
      }}
    />
  )
}
