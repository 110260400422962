import { connect } from 'react-redux'
import { actions } from 'react-redux-form'

import { getMyOffersInquiredCompanies } from 'actions/company'
import { exportMyOffers, getMyOffers } from 'actions/offer'
import { CARD_LIST_PAGE_SIZE } from 'constants/app'
import { getMyOffersCompaniesSelector } from 'selectors/company'
import { getContainersSelector } from 'selectors/container'
import { createErrorSelector } from 'selectors/error'
import { getFractionsSelector } from 'selectors/fraction'
import { createLoadingSelector } from 'selectors/loading'
import {
  getMyOffersSelector,
  getOffersPaginationSelector,
} from 'selectors/offer'

const getMyOffersLoadingSelector = createLoadingSelector(['GET_MY_OFFERS'])
const exportMyOffersLoadingSelector = createLoadingSelector([
  'EXPORT_MY_OFFERS',
])
const getFractionListLoadingSelector = createLoadingSelector(['GET_FRACTIONS'])
const getMyOffersCompaniesLoadingSelector = createLoadingSelector([
  'GET_MY_OFFERS_INQUIRED_COMPANIES',
])
const errorSelector = createErrorSelector([
  'GET_FRACTIONS',
  'GET_MY_OFFERS',
  'EXPORT_MY_OFFERS',
  'GET_MY_OFFERS_INQUIRED_COMPANIES',
])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  containerList: getContainersSelector(state),
  error: errorSelector(state),
  fractionList: getFractionsSelector(state),
  offersInquiredCompanyList: getMyOffersCompaniesSelector(state),
  isLoading: {
    myOffers: getMyOffersLoadingSelector(state),
    fractionList: getFractionListLoadingSelector(state),
    myOffersInquiredCompaniesList: getMyOffersCompaniesLoadingSelector(state),
    exportMyOffers: exportMyOffersLoadingSelector(state),
  },
  offerList: getMyOffersSelector(state),
  offersPagination: getOffersPaginationSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  formReset: field => dispatch(actions.reset(field)),
  getMyOffers: (pagination, filterValues) =>
    dispatch(getMyOffers(pagination, filterValues, CARD_LIST_PAGE_SIZE)),
  getMyOffersInquiredCompanies: () => dispatch(getMyOffersInquiredCompanies()),
  exportMyOffers: () => dispatch(exportMyOffers()),
})

export default connect(mapStateToProps, mapDispatchToProps)
