import React, { FC } from 'react'

import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'

import { LandingPageContent } from '../HomepageWastemanager'

interface ExplanationSectionHeroProps {
  section: LandingPageContent
}

export const ExplanationSectionHero: FC<ExplanationSectionHeroProps> = ({
  section,
}) => (
  <header className='explanation-section__hero'>
    <Headline
      color={HEADLINE_COLOR.GRAYISH_BROWN}
      fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
      fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
      headlineStyle={HEADLINE_STYLE.H3}
      tag={HEADLINE_TAG.H2}
      noMargin
    >
      {section.title}
    </Headline>
    <p className='explanation-section__text'>{section.content}</p>
  </header>
)
