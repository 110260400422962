import { connect } from 'react-redux'

import { getCompanyFormSelector, getCompanySelector } from 'selectors/company'
import { getCountriesSelector } from 'selectors/country'

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  countries: getCountriesSelector(state),
  company: getCompanySelector(state),
  companyForm: getCompanyFormSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @return {Object}
 */
const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)
