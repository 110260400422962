import { FieldArray, useFormikContext } from 'formik'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getDszFractions } from 'actions/dsz'
import { getCustomInvoiceNetGrossPricesVat } from 'actions/maklerpremium'
import { AsyncMultiselectCombobox } from 'components/common/AsyncMultiselectCombobox/AsyncMultiselectCombobox'
import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { DropDownInput } from 'components/common/DropDownInput'
import Icon from 'components/common/Fontello'
import {
  FormStepSummaryRow,
  GoToNextFormStepButton,
} from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { ICON_POSITION, IconButton } from 'components/common/IconButton'
import { InputDecimal } from 'components/common/InputDecimal'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { DSZ_ADD_DOCUMENT_POSITION_UNIT_CHOICES } from 'components/maklerpremium/constants'
import { germanDecimalToInternationalFormat } from 'helper/general'
import { getName } from 'helper/translations'
import { getDszFractionsSelector } from 'selectors/dsz'
import { createLoadingSelector } from 'selectors/loading'

import {
  DSZAddInvoiceFormContext,
  DSZAddInvoiceFormValues,
} from '../DSZAddInvoiceFormSteps'

export const DSZAddInvoicePositionForm: FC = () => {
  const maxNumberOfPositions = 10
  const dispatch = useDispatch()

  const { stepStatus, saveStep, editStep } = useContext(
    DSZAddInvoiceFormContext,
  )

  const { setFieldValue, values, handleChange, handleBlur } =
    useFormikContext<DSZAddInvoiceFormValues>()

  const reduxDszFractions = useSelector(getDszFractionsSelector)
  const dszFractionIsLoading = useSelector(
    createLoadingSelector(['GET_DSZ_FRACTIONS']),
  )

  const [dszFractions, setDszFractions] = useState<DszFraction[]>([])

  useEffect(() => {
    if (!dszFractionIsLoading) {
      setDszFractions(reduxDszFractions)
    }
  }, [dszFractionIsLoading, reduxDszFractions])

  const saveAllAndGoToNextStep = () => {
    if (values.positions) {
      const newPositions = values.positions.map(pos => ({
        ...pos,
        edit_mode: false,
      }))
      setFieldValue('positions', newPositions)
      dispatch(
        getCustomInvoiceNetGrossPricesVat({
          positions: newPositions.map(pos => ({
            ...pos,
            amount: (
              germanDecimalToInternationalFormat(pos.amount) / 1000
            ).toLocaleString('de-DE', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 6,
              useGrouping: false,
            }),
            service: pos.service
              ? `${pos.service.shop_name} - ${pos.service.legal_name}`
              : '',
            service_date: `${values.licensing_year}-07-01`,
          })),
          assigned_vat: values.assigned_vat,
        }),
      )
    }
    saveStep()
  }

  const defaultInvoicePosition = {
    amount: '',
    edit_mode: true,
    unit: DSZ_ADD_DOCUMENT_POSITION_UNIT_CHOICES[0].id,
    service: undefined,
    service_date: `${values.licensing_year}-01-01`,
    single_net_price: '',
    total_net_price: '',
  }

  // Ensure that at least one empty position always exists
  if (!values.positions || values.positions?.length === 0) {
    values.positions = [defaultInvoicePosition]
  }

  // Saves the last position by setting edit_mode false
  // and adds a new position
  const addPositionAndSaveLastPosition = () => {
    if (values.positions) {
      const newPositions = values.positions
      newPositions[newPositions.length - 1].edit_mode = false
      newPositions.push(defaultInvoicePosition)
      setFieldValue('positions', newPositions)
    }
  }

  const calculateAndSetTotalNetPrice = (index, amount, single_net_price) => {
    setFieldValue(
      `positions.${index}.total_net_price`,
      single_net_price && amount
        ? // single_net_price is per ton (1000kg), but amount is in kg (0.001 ton)
          (Number(single_net_price.replace(',', '.')) / 1000) *
            Number(amount.replace(',', '.'))
        : undefined,
      false,
    )
  }

  const deletePosition = (positionIndex: number) => {
    const newPositions = values.positions
    // eslint-disable-next-line no-unused-expressions
    newPositions?.splice(positionIndex, 1)
    setFieldValue('positions', newPositions)
  }

  const isPositionInvalid = (positionIndex: number) => {
    if (
      values?.positions &&
      typeof values?.positions[positionIndex] !== 'undefined'
    ) {
      const pos = values?.positions[positionIndex]

      return (
        !pos.amount ||
        !pos.service ||
        // Allow single_net_price to be 0
        typeof pos.single_net_price === 'undefined' ||
        pos.single_net_price === '' ||
        !pos.unit
      )
    }
    return false
  }

  const allPositionsValid = () => {
    if (values.positions) {
      return !values.positions.some((pos, posIdx) => isPositionInvalid(posIdx))
    }
    return false
  }

  const allPositionsValidConst = allPositionsValid()

  const enableEditModeForPosition = (positionIndex: number) => {
    const newPositions = values.positions
    if (newPositions) {
      newPositions[positionIndex].edit_mode = true
    }
    setFieldValue('positions', newPositions)
  }

  const serviceValues = useMemo(
    () => values.positions.map(pos => pos.service),
    [values.positions],
  )

  return (
    <div className='add_invoice_positions_form'>
      <Row>
        <Col md={8}>
          <FieldArray name='positions'>
            {() => (
              <>
                {values.positions?.map((position, index) => (
                  <div key={`${index}`}>
                    {index >= 1 && <hr className='form-step__divider' />}
                    <Row>
                      <Col md={6}>
                        <p>{`${index + 1}. Position`}</p>
                      </Col>
                      <Col md={6}>
                        {stepStatus !== STEP_STATUS.COMPLETED && (
                          <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                            {!position.edit_mode && (
                              <button
                                type='button'
                                className='icon-button'
                                onClick={() => enableEditModeForPosition(index)}
                                data-uk-tooltip={`title: ${I18n.t(
                                  'general.button.edit',
                                )}`}
                              >
                                <Icon name='pencil' />
                              </button>
                            )}
                            <button
                              type='button'
                              className='icon-button delete-button'
                              data-uk-tooltip={`title: ${I18n.t(
                                'general.button.delete',
                              )}`}
                              onClick={() => deletePosition(index)}
                            >
                              <Icon name='trash' />
                            </button>
                          </ButtonBar>
                        )}
                      </Col>
                    </Row>
                    {position.edit_mode ? (
                      <>
                        {/* EDITING*/}

                        {/* Leistung */}
                        <AsyncMultiselectCombobox
                          getOptionLabel={fraction =>
                            `${(fraction as DszFraction)?.shop_name} - ${
                              (fraction as DszFraction)?.legal_name
                            }`
                          }
                          getOptionValue={fraction =>
                            `${(fraction as DszFraction).id}`
                          }
                          handleBlur={handleBlur}
                          handleChange={value =>
                            setFieldValue(`positions.${index}.service`, value)
                          }
                          isLoading={dszFractionIsLoading}
                          label={I18n.t(
                            'addInvoicePageTranslations.dsz.steps.3.fields.dszFraction.placeholder',
                          )}
                          loadingMessage={I18n.t(
                            'addInvoicePageTranslations.dsz.steps.3.fields.dszFraction.loadingMessage',
                          )}
                          loadOptions={inputValue => {
                            dispatch(getDszFractions(inputValue))
                          }}
                          multiSelect={false}
                          noInputMessage={I18n.t(
                            'addInvoicePageTranslations.dsz.steps.3.fields.dszFraction.noInputMessage',
                          )}
                          noOptionsMessage={I18n.t(
                            'addInvoicePageTranslations.dsz.steps.3.fields.dszFraction.noOptionsMessage',
                          )}
                          options={dszFractions as Record<string, any>[]}
                          placeholder={I18n.t(
                            'addInvoicePageTranslations.dsz.steps.3.fields.dszFraction.placeholder',
                          )}
                          showCheckmark={!!position.service}
                          value={serviceValues[index]}
                        />

                        <div className='execution-proof-review--side-by-side'>
                          {/* Menge */}
                          <InputDecimal
                            label={I18n.t(
                              'addInvoicePageTranslations.epd.steps.3.fields.amount.label',
                            )}
                            placeholder={I18n.t(
                              'addInvoicePageTranslations.epd.steps.3.fields.amount.placeholder',
                            )}
                            name={`positions.${index}.amount`}
                            value={position.amount}
                            maxValue={9999999.999}
                            onChange={e => {
                              handleChange(e)
                              if (values.positions) {
                                calculateAndSetTotalNetPrice(
                                  index,
                                  e.target.value,
                                  position.single_net_price,
                                )
                              }
                            }}
                          />

                          {/* Einheit */}
                          <DropDownInput
                            choices={DSZ_ADD_DOCUMENT_POSITION_UNIT_CHOICES.map(
                              choice => ({
                                optionValue: choice.id,
                                optionLabel: I18n.t(choice.name),
                              }),
                            )}
                            isDisabled
                            label={I18n.t(
                              'addInvoicePageTranslations.epd.steps.3.fields.unit.label',
                            )}
                            name={`positions.${index}.unit`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            placeholder={I18n.t(
                              'addInvoicePageTranslations.epd.steps.3.fields.unit.label',
                            )}
                            showCheckmark={!!position.amount && !!position.unit}
                            value={position.unit}
                            withCheckmark
                          />

                          {/* Nettoeinzelpreis*/}
                          <InputDecimal
                            name={`positions.${index}.single_net_price`}
                            label={I18n.t(
                              'addInvoicePageTranslations.dsz.steps.3.fields.single_net_price.label',
                            )}
                            placeholder={I18n.t(
                              'addInvoicePageTranslations.dsz.steps.3.fields.single_net_price.placeholder',
                            )}
                            value={position.single_net_price}
                            maxValue={9999.99}
                            onChange={e => {
                              handleChange(e)
                              if (values.positions) {
                                calculateAndSetTotalNetPrice(
                                  index,
                                  position.amount,
                                  e.target.value,
                                )
                              }
                            }}
                          />

                          {/* Nettogesamtpreis*/}
                          <ReadOnlyField
                            className='net_price_read_only_field'
                            label={I18n.t(
                              'addInvoicePageTranslations.epd.steps.3.fields.total_net_price.label',
                            )}
                            value={
                              position.amount && position.single_net_price
                                ? (
                                    (Number(position.amount.replace(',', '.')) *
                                      Number(
                                        position.single_net_price.replace(
                                          ',',
                                          '.',
                                        ),
                                      )) /
                                    1000
                                  ) // single_net_price is per ton (1000kg), but amount is in kg (0.001 ton)
                                    .toLocaleString('de-DE', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                : ''
                            }
                            withCheckmark={
                              position.single_net_price.length >= 1
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {/* READONLY*/}
                        <div className='maklerpremium-step-summary'>
                          <div className='maklerpremium-step-summary_grid-body'>
                            {/* Leistung*/}
                            <FormStepSummaryRow
                              label={I18n.t(
                                'addInvoicePageTranslations.epd.steps.3.fields.service.label',
                              )}
                              value={
                                position.service
                                  ? `${position.service.legal_name} - ${position.service.shop_name}`
                                  : ''
                              }
                            />

                            {/* Menge*/}
                            <FormStepSummaryRow
                              label={I18n.t(
                                'addInvoicePageTranslations.epd.steps.3.fields.amount.label',
                              )}
                              value={position.amount}
                            />

                            {/* Einheit*/}
                            <FormStepSummaryRow
                              label={I18n.t(
                                'addInvoicePageTranslations.epd.steps.3.fields.unit.label',
                              )}
                              value={getName(
                                position.unit,
                                DSZ_ADD_DOCUMENT_POSITION_UNIT_CHOICES,
                              )}
                            />

                            {/* Nettoeinzelpreis*/}
                            <FormStepSummaryRow
                              label={I18n.t(
                                'addInvoicePageTranslations.epd.steps.3.fields.single_net_price.label',
                              )}
                              value={Number(
                                position.single_net_price.replace(',', '.'),
                              ).toLocaleString('de-DE', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            />

                            {/* Nettogesamtpreis*/}
                            <FormStepSummaryRow
                              label={I18n.t(
                                'addInvoicePageTranslations.epd.steps.3.fields.total_net_price.label',
                              )}
                              value={
                                position.amount && position.single_net_price
                                  ? (
                                      Number(
                                        position.amount.replace(',', '.'),
                                      ) *
                                      (Number(
                                        position.single_net_price.replace(
                                          ',',
                                          '.',
                                        ),
                                      ) /
                                        1000)
                                    ) // single_net_price is per ton (1000kg), but amount is in kg (0.001 ton)
                                      .toLocaleString('de-DE', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                  : ''
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
                {/* remove / add buttons*/}
                {stepStatus !== STEP_STATUS.COMPLETED &&
                  (!values.positions ||
                    values.positions?.length < maxNumberOfPositions) && (
                    <div className='uk-margin-top add-position-button-container'>
                      <IconButton
                        backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
                        fullWidth
                        iconName='plus'
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={() => addPositionAndSaveLastPosition()}
                        isDisabled={!allPositionsValidConst}
                      >
                        {I18n.t(
                          'addInvoicePageTranslations.epd.steps.3.buttons.addRemovePosition',
                        )}
                      </IconButton>
                    </div>
                  )}
                {stepStatus !== STEP_STATUS.COMPLETED && (
                  <div className='uk-margin-top'>
                    <GoToNextFormStepButton
                      buttonText={
                        stepStatus === STEP_STATUS.EDITING
                          ? I18n.t('general.button.save')
                          : I18n.t(
                              'createInquiryTranslations.form.button.nextStep',
                            )
                      }
                      onClick={saveAllAndGoToNextStep}
                      isDisabled={!allPositionsValidConst}
                    />
                  </div>
                )}
              </>
            )}
          </FieldArray>
        </Col>
        <Col md={4} />
      </Row>
      {stepStatus === STEP_STATUS.COMPLETED && (
        <div className='sidebar'>
          <ButtonLink
            className='sidebar__edit-link'
            linkColor={BUTTON_LINK_COLOR.PRIMARY}
            onClick={editStep}
          >
            {I18n.t('createInquiryTranslations.form.button.editStep')}
          </ButtonLink>
        </div>
      )}
    </div>
  )
}
