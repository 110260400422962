import './TimeFrameSwitcher.scss'

import classNames from 'classnames'
import * as React from 'react'
import { Translate } from 'react-i18nify'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'

export enum TIME_FRAME {
  TODAY,
  MONTH,
}

export const TimeFrameSwitcher = ({
  timeFrame,
  setTimeFrame,
}: {
  timeFrame: TIME_FRAME
  setTimeFrame: (timeFrame: TIME_FRAME) => void
}) => (
  <div className='timeframe-switcher uk-flex'>
    <Button
      backgroundColor={
        timeFrame === TIME_FRAME.TODAY
          ? BUTTON_BACKGROUND_COLOR.WHITE
          : BUTTON_BACKGROUND_COLOR.TRANSPARENT
      }
      className={classNames('button--border-none', 'uk-flex-1', {
        'timeswitcher-active': timeFrame === TIME_FRAME.TODAY,
      })}
      onClick={() => setTimeFrame(TIME_FRAME.TODAY)}
    >
      <Translate value='constant.date.today' />
    </Button>
    <Button
      backgroundColor={
        timeFrame === TIME_FRAME.MONTH
          ? BUTTON_BACKGROUND_COLOR.WHITE
          : BUTTON_BACKGROUND_COLOR.TRANSPARENT
      }
      className={classNames('button--border-none', 'uk-flex-1', {
        'timeswitcher-active': timeFrame === TIME_FRAME.MONTH,
      })}
      onClick={() => setTimeFrame(TIME_FRAME.MONTH)}
    >
      <Translate value='constant.date.month' />
    </Button>
  </div>
)
