import MyOffersPage from '../components/offer/MyOffersPage'
import { OfferDetailsPage } from '../components/offer/OfferDetailsPage'

/**
 * @constant
 * @description A constant to define offer routes.
 */
export const offerRoute = [
  {
    path: '/offer',
    component: MyOffersPage,
    exact: true,
  },
  {
    path: '/offer/:offerId',
    component: OfferDetailsPage,
    exact: true,
  },
]

export default {}
