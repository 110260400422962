import './style.scss'

import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import { Element as ScrollElement } from 'react-scroll'

import containerdienstLogo from 'assets/svg/containerdienst-de-logo.svg'
import emtpoLogo from 'assets/svg/empto-logo.svg'
import {
  Headline,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import {
  BUTTON_BACKGROUND_COLOR,
  LinkButton,
} from 'components/common/LinkButton'
import Paragraph from 'components/common/Paragraph'

import { SEARCH_RESULTS_SCROLL_ANCHOR } from '../../constants'
import { HomepageCard } from '../HomepageCard'
import { HomepageCards } from '../HomepageCards'

interface NoOfferResultsProps {
  zipCode: ZipCode
}

export const NoOfferResults: FC<NoOfferResultsProps> = ({ zipCode = null }) => {
  if (!zipCode) return null

  // The following elements use random keys to force rendering in Safari on iOS devices!
  // https://gitlab.ambient-innovation.com/zentek/empto/issues/1285#note_201484

  return (
    <div className='no-offer-results'>
      <ScrollElement name={SEARCH_RESULTS_SCROLL_ANCHOR} />
      <div
        className={classNames(
          'no-offer-results__headline',
          'uk-animation-fade',
          'uk-margin-medium-bottom',
        )}
      >
        <Paragraph>
          <Translate
            className='uk-text-bold'
            value='homepages.wasteProducer.zipCode.noOfferResults'
            dangerousHTML
          />
        </Paragraph>
      </div>

      <HomepageCards className='no-offer-results' disableLastChildTooltip>
        <HomepageCard
          key={`empto-card-${uniqueId()}`}
          className='card--empto'
          title={
            <Headline
              key={`empto-card-headline-${uniqueId()}`}
              headlineStyle={HEADLINE_STYLE.H5}
              tag={HEADLINE_TAG.H3}
              noMargin
            >
              <img
                key={`empto-card-logo-${uniqueId()}`}
                alt='Logo empto'
                src={emtpoLogo}
              />
              <Translate value='homepages.wasteProducer.zipCode.emptoWastemanager.title' />
            </Headline>
          }
          text={
            <p key={`empto-card-text-${uniqueId()}`}>
              <Translate value='homepages.wasteProducer.zipCode.emptoWastemanager.text' />
            </p>
          }
          footer={
            <LinkButton
              key={`empto-card-button-${uniqueId()}`}
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              target={{ pathname: '/abfallmanager' }}
            >
              <Translate value='homepages.wasteProducer.zipCode.emptoWastemanager.buttonText' />
            </LinkButton>
          }
        />
        <HomepageCard
          key={`containerdienst-card-${uniqueId()}`}
          className='card--containerdienst'
          title={
            <Headline
              key={`containerdienst-card-headline-${uniqueId()}`}
              headlineStyle={HEADLINE_STYLE.H5}
              tag={HEADLINE_TAG.H3}
              noMargin
            >
              <img
                key={`containerdienst-card-logo-${uniqueId()}`}
                className='logo uk-logo'
                alt='Logo Containerdienst.de'
                src={containerdienstLogo}
              />
            </Headline>
          }
          text={
            <p key={`containerdienst-card-text-${uniqueId()}`}>
              <Translate value='homepages.wasteProducer.zipCode.containerdienst.text' />
            </p>
          }
          footer={
            <a
              key={`containerdienst-card-button-${uniqueId()}`}
              className={`button button--border-none button--disabled-style-default
              button--color-primary button--font-color-default button--shape-default uk-button uk-button-primary`}
              href='https://www.containerdienst.de?ref=empto.de'
            >
              <Translate value='homepages.wasteProducer.zipCode.containerdienst.buttonText' />
            </a>
          }
        />
      </HomepageCards>
    </div>
  )
}
