import './style.scss'

import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import React, { FC, useContext, useEffect, useState } from 'react'

import { TooltipIcon } from '../../common/TooltipIcon'
import { Button, BUTTON_BACKGROUND_COLOR } from '../Button'
import { RadioPanelGroupContext } from '../RadioPanelGroup'

export interface RadioPanelProps {
  ariaLabel?: string
  buttonColor?: BUTTON_BACKGROUND_COLOR
  buttonLabel?: string
  isDisabled?: boolean
  showButton?: boolean
  tooltip?: string
  value: string
}

/**
 * @description This component renders and controls a add radio panel component.
 */
export const RadioPanel: FC<RadioPanelProps> = ({
  ariaLabel,
  buttonColor = BUTTON_BACKGROUND_COLOR.PRIMARY,
  buttonLabel,
  children,
  isDisabled = false,
  showButton,
  tooltip = '',
  value,
}) => {
  const id = uniqueId()

  const {
    selectedValue,
    onReset,
    onSelected,
    radioButtonGroupName,
    resetText,
  } = useContext(RadioPanelGroupContext)

  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    if (selectedValue) {
      setIsChecked(value === selectedValue)
    }
  }, [selectedValue, value])

  /**
   * Handles clicking the submit button.
   */
  const onClick = () => {
    if (isChecked && onReset) {
      onReset(value)
    } else {
      onSelected(value)
    }
  }

  /**
   * Handles change the radio button.
   */
  const onChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    onSelected(event.target.value)
  }

  return (
    <section
      className={classNames(
        'radio-panel',
        {
          'radio-panel--is-active': isChecked,
        },
        {
          'radio-panel--is-disabled': isDisabled,
        },
      )}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label
        aria-label={ariaLabel ?? buttonLabel} // This is the only content announced to assistive technologies
        className={classNames('radio-panel__label', {
          'radio-panel__label--is-disabled': isDisabled,
        })}
        htmlFor={id}
      >
        <div className='radio-panel__content'>{children}</div>
        {showButton && !isDisabled && (
          <div className='radio-panel__button'>
            <Button
              backgroundColor={
                isChecked && resetText
                  ? BUTTON_BACKGROUND_COLOR.WHITE
                  : buttonColor
              }
              isDisabled={isChecked && !resetText}
              fullWidth
              onClick={onClick}
              tabIndex={-1} // Hide for keyboard navigation
            >
              {isChecked && resetText ? resetText : buttonLabel}
            </Button>
          </div>
        )}
      </label>

      {!isDisabled && (
        <input
          className='uk-radio radio-panel__input'
          checked={isChecked}
          id={id}
          name={radioButtonGroupName}
          onChange={onChange}
          type='radio'
          value={value}
        />
      )}

      {tooltip !== '' && (
        <div className='radio-panel__tooltip'>
          <TooltipIcon
            description={tooltip}
            iconClass='question'
            withClickCatcher
          />
        </div>
      )}
    </section>
  )
}
