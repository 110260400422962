import './style.scss'

import classNames from 'classnames'
import debounce from 'lodash.debounce'
import React, {
  FC,
  FocusEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { OnChangeValue } from 'react-select'
import AsyncSelect from 'react-select/async'

import { ApiValidationMessages } from '../Form/components/ApiValidationMessages'
import { callbackOrType } from '../Input'
import { InputCheckmark } from '../InputCheckmark'

export interface AsyncMultiselectComboboxProps {
  dataTestId?: string
  defaultValue?: Record<string, any> | Record<string, any>[]
  error?: callbackOrType<string>
  errors?: ApiError
  fieldError?: string
  getOptionLabel?: (
    option?: Record<string, any> | Record<string, any>[],
  ) => string
  getOptionValue?: (
    option?: Record<string, any> | Record<string, any>[],
  ) => string
  handleBlur: FocusEventHandler<HTMLElement>
  handleChange: (
    value?: OnChangeValue<any, true> | OnChangeValue<any, false>,
    action?: string,
  ) => void
  inputMinLength?: number
  isDisabled?: boolean
  isLoading: boolean
  label?: string
  loadingMessage?: string
  loadOptions: (value?: string) => void
  debounceCallbackTime?: number
  maxLength?: number
  multiSelect?: boolean
  name?: string
  noInputMessage?: string
  noOptionsMessage?: string
  options: Record<string, any>[]
  placeholder: string
  showRequiredDot?: boolean
  showCheckmark?: callbackOrType<boolean>
  withCheckmark?: boolean
  value?: Record<string, any> | Record<string, any>[]
  isClearable?: boolean
}

export const AsyncMultiselectCombobox: FC<AsyncMultiselectComboboxProps> = ({
  dataTestId = 'async-multi-select',
  error = '',
  errors,
  defaultValue,
  getOptionLabel,
  getOptionValue,
  handleBlur,
  handleChange,
  inputMinLength = 3,
  isDisabled,
  isLoading,
  label,
  loadingMessage,
  loadOptions,
  maxLength,
  multiSelect = true,
  name = '',
  noInputMessage,
  noOptionsMessage,
  options,
  placeholder,
  showCheckmark = false,
  showRequiredDot = false,
  withCheckmark = false,
  value,
  isClearable = false,
}) => {
  const containerRef = useRef<any>(null)
  const asyncRef = useRef<any>(null)

  const showCheckmarkValue =
    typeof showCheckmark === 'boolean' ? showCheckmark : showCheckmark(name)
  const errorValue = typeof error === 'string' ? error : error(name)

  const callback = useRef<(options: Record<string, unknown>[]) => void>()
  const defaultOrFallback = useMemo(
    () => defaultValue ?? (multiSelect ? [] : undefined),
    [defaultValue, multiSelect],
  )

  const [currentValue, setCurrentValue] = useState<OnChangeValue<any, false>>(
    value || defaultOrFallback,
  )

  useEffect(() => {
    const newValue = value || defaultOrFallback
    setCurrentValue(value || defaultOrFallback)
    // clear the select when the currentValue changes to empty/undefined/null
    // expect this to break in any update to react-select, this is undocumented functionality
    if (asyncRef && asyncRef.current && !newValue) asyncRef.current.popValue()
  }, [defaultOrFallback, value])

  useEffect(() => {
    if (callback && callback.current) {
      return callback.current(options)
    }
  }, [options])

  // debounce returns a function with an unknown signature, so eslint cannot validate deps
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedLoader = useCallback(
    debounce(inputValue => loadOptions(inputValue), 500),
    [loadOptions],
  )

  const cssMaxWidth =
    containerRef.current === null
      ? ''
      : containerRef.current.getBoundingClientRect().width + 'px'

  return (
    <div
      className='async-multiselect'
      style={{ maxWidth: cssMaxWidth }}
      ref={containerRef}
    >
      <Row
        middle='xs'
        className={classNames({ 'combobox--with-checkmark': withCheckmark })}
      >
        {label && (
          <Col xs={12} sm={12} className='label-col label-col-top'>
            <div className='label'>{label}</div>
          </Col>
        )}
        <Col xs={12} sm={12} style={{ position: 'relative' }}>
          {showRequiredDot && (
            <div
              className={classNames('async-multiselect__required-dot', {
                'async-multiselect__required-dot--error': error !== '',
              })}
            />
          )}
          <AsyncSelect
            className={`react-select-container ${dataTestId}`}
            inputId={dataTestId}
            classNamePrefix='react-select'
            defaultValue={defaultOrFallback}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isMulti={multiSelect}
            isClearable={isClearable}
            loadingMessage={() => loadingMessage}
            loadOptions={(inputValue, cb) => {
              if (inputValue.length >= inputMinLength) {
                debouncedLoader(inputValue)
                callback.current = cb
              } else {
                cb([])
              }
            }}
            noOptionsMessage={({ inputValue }) =>
              inputValue ? noOptionsMessage : noInputMessage
            }
            onBlur={handleBlur}
            onChange={inputValue => {
              setCurrentValue(inputValue ?? [])
              handleChange(inputValue ?? [])
            }}
            onInputChange={(inputValue: string) => {
              const value = inputValue.trimStart()

              if (maxLength && value.length > maxLength) {
                return value.substring(0, value.length - 1)
              }

              return value
            }}
            placeholder={placeholder}
            ref={asyncRef}
            value={currentValue}
          />
          {withCheckmark && <InputCheckmark isHidden={!showCheckmarkValue} />}
        </Col>
      </Row>
      {errors && <ApiValidationMessages error={errors} />}
      {errorValue !== '' && (
        <div className='drop-down-input__error'>{errorValue}</div>
      )}
    </div>
  )
}
