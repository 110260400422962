import moment from 'moment'
import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

interface CustomerNumbersProps {
  company: Company
  showCustomerNumbers?: boolean
  showExternalNumber?: boolean
}

export const CustomerNumbers: FC<CustomerNumbersProps> = ({
  company,
  showCustomerNumbers = false,
  showExternalNumber = false,
}) => {
  if (!showCustomerNumbers) return null

  return (
    <div className='basic-information__customer-number'>
      <p>
        <Translate
          value='companyInfoPage.customerNumber'
          customerNumber={company.empto_external_number}
          dangerousHTML
        />
        {/* former customer number from epd */}
        {showExternalNumber && company.epd_external_number && (
          <Translate
            value='companyInfoPage.externalNumber'
            externalNumber={company.epd_external_number}
          />
        )}

        <br />
        <Translate
          value='companyInfoPage.memberSince'
          memberSince={moment(company.created_at).format('L')}
        />
      </p>
    </div>
  )
}
