const orderClaimStatusMessages = {
  accepted: {
    aez: {
      TYPE_NOT_CARRIED:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldete nicht durchgeführte Entsorgung' +
        ' geprüft und akzeptiert.',
      TYPE_NOT_COMPLETED:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldete nicht vollständig durchgeführte' +
        ' Entsorgung geprüft und akzeptiert.',
      TYPE_CREDIT_WRONG:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldete nicht korrekte Abrechnung geprüft' +
        ' und akzeptiert.',
      TYPE_OTHERS:
        '<b>empto</b> hat das von <b>%{companyName}</b> gemeldete Problem geprüft und akzeptiert.',
    },
    teb: {
      TYPE_NOT_CARRIED:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldete Fehlfahrt geprüft und akzeptiert.' +
        ' Die Abrechnungsbelege werden erzeugt.',
      TYPE_CREDIT_WRONG:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldete nicht korrekte Abrechnung ' +
        'geprüft und akzeptiert.',
      TYPE_DEADHEAD:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldete Leerfahrt geprüft und akzeptiert.' +
        ' Die Abrechnungsbelege werden erzeugt.',
      TYPE_OTHERS:
        '<b>empto</b> hat das von <b>%{companyName}</b> gemeldete Problem geprüft und akzeptiert.',
      TYPE_WAITING_TIME:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldeten Wartezeiten/Sortierkosten ' +
        'geprüft und akzeptiert.',
    },
  },
  rejected: {
    aez: {
      TYPE_NOT_CARRIED:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldete nicht durchgeführte Entsorgung ' +
        'geprüft und abgelehnt.',
      TYPE_NOT_COMPLETED:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldete nicht vollständig durchgeführte ' +
        'Entsorgung geprüft und abgelehnt.',
      TYPE_CREDIT_WRONG:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldete nicht korrekte Abrechnung ' +
        'geprüft und abgelehnt.',
      TYPE_OTHERS:
        '<b>empto</b> hat das von <b>%{companyName}</b> gemeldete Problem geprüft und abgelehnt.',
    },
    teb: {
      TYPE_NOT_CARRIED:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldete Fehlfahrt geprüft und abgelehnt.',
      TYPE_CREDIT_WRONG:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldete nicht korrekte Abrechnung ' +
        'geprüft und abgelehnt.',
      TYPE_DEADHEAD:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldete Leerfahrt geprüft und abgelehnt.',
      TYPE_OTHERS:
        '<b>empto</b> hat das von <b>%{companyName}</b> gemeldete Problem geprüft und abgelehnt.',
      TYPE_WAITING_TIME:
        '<b>empto</b> hat die von <b>%{companyName}</b> gemeldeten Wartezeiten/Sortierkosten ' +
        'geprüft und abgelehnt.',
    },
  },
}

export default orderClaimStatusMessages
