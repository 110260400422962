export const EPDDashboardTranslations = {
  // BUTTON BAR
  offerCreate: {
    buttonText: 'Angebot erstellen',
  },
  // FIRST ROW
  offerDaily: {
    buttonText: 'Alle Angebote ansehen',
    caption: {
      singular: 'Heutiges Angebot',
      plural: 'Heutige Angebote',
    },
  },
  ordersTodayCount: {
    buttonText: 'Alle Aufträge ansehen',
    caption: {
      singular: 'Auftrag heute',
      plural: 'Aufträge heute',
    },
  },
  salesToday: {
    buttonText: 'Alle Umsätze ansehen',
    caption: {
      singular: 'Heutiger Umsatz',
      plural: 'Heutige Umsätze',
    },
  },
  // ACTIONS REQUIRED IS IN BASE TRANSLATION
  // SECOND ROW
  upcomingOrder: {
    buttonText: 'Aufträge verwalten',
    value: 'Geplante Aufträge',
    caption: 'Übersicht der für die nächsten 2 Werktage geplanten Aufträge',
  },
  offerPipelineCount: {
    buttonText: 'Angebote verwalten',
    value: 'Angebotspipeline',
    caption: 'Übersicht über alle offenen Angebote',
  },
  // CUSTOMER MANAGE IS IN BASE TRANSLATION
  partnerManage: {
    buttonText: 'Partner verwalten',
    value: 'Partnerverwaltung',
    caption: 'Übersicht über alle Partner und Verwaltungsmöglichkeiten',
  },
  stakeholder: {
    headline: 'Entsorgung.de - Dashboard',
    salesToday: '%{salesTodayShop} % Shops\nTagesumsatz',
    salesMonth: '%{salesMonthShop} % Shops\nMonatsumsatz',
    offersToday: '~ %{offerSalesToday} \nAngebote heute',
    offersMonth:
      '~ %{offerSalesMonth} (%{changesOfSuccess} % Erfolg)\nAngebote Monat',
    ordersToday: 'Aufträge heute',
    ordersMonth: 'Aufträge Monat',
    demandTotal: 'Forderungen Gesamt',
    demandMonth: 'Forderungen > 30 Tage',
    demandTwoMonths: 'Forderungen > 60 Tage',
    demandCaption: 'Offene Forderungen',
    demandTimestamp: 'Stand',
    offerPipeline: '%{offerPipelineForecast} \nAngebotspipeline',
    podium: {
      title: 'Top 3 des Monats',
      offers: '%{offersCount} Angebote',
    },
  },
}
