export const compensationPriceFieldsTranslations = {
  pricePlaceholder: 'Wert zwischen 0 € und 999,99 €',
  pricePlaceholderCompensation: 'Wert zwischen 0 € und 1999,99 €',
  compensationTon: 'Vergütung (€ pro Tonne)',
  validation: {
    required: 'Dieses Feld muss befüllt sein.',
    notZero: 'Dieses Feld darf nicht 0 sein.',
  },
  compensationContainer: 'Vergütung (€ pro Behälter)',
  index: {
    label: 'Index',
    noValues: 'Keine Indizes auswählbar.',
    noIndex: 'Kein Index',
  },
  indexMonth: {
    label: 'relevanter Monat für Indexwert',
    placeholder: 'Vormonat',
    error: 'Ungültiger relevanter Monat für Indexwert',
    options: {
      0: 'Vormonat',
      1: 'Vor-Vormonat',
      2: 'Leistungsmonat',
    },
  },
  surcharge: {
    label: 'Zuschlag (€ pro Tonne)',
    placeholder: 'Wert zwischen 0 € und 999,99 €',
    error: 'Ungültiger oder leerer Zuschlag',
  },
  reduction: {
    label: 'Abschlag (€ pro Tonne)',
    placeholder: 'Wert zwischen 0 € und 999,99 €',
    error: 'Ungültiger oder leerer Abschlag',
  },
}
