import { AgreementDetailsPage } from '../components/agreement/AgreementDetailsPage'
import { AgreementPage } from '../components/agreement/AgreementPage'
import { AddAgreementPage } from '../components/maklerpremium/epd/AddAgreementPage/AddAgreementPage'
import PreviousOrdersPage from '../components/order/PreviousOrdersPage'

export const agreementRoute = [
  {
    path: '/agreement',
    component: AgreementPage,
    exact: true,
  },
  {
    path: '/agreement/add-agreement',
    component: AddAgreementPage,
    exact: true,
    showErrorPage: true,
  },
  {
    path: '/agreement/:agreementId',
    component: AgreementDetailsPage,
    exact: true,
  },
  {
    path: '/agreement/:offerId/orders',
    component: PreviousOrdersPage,
    exact: true,
  },
]
