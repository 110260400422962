import './styles.scss'

import React, { FC } from 'react'

interface ErrorMessagesProps {
  submitCount: number
  isSubmitting: boolean
  isValid: boolean
  errorsArray?: string[]
}

export const ApiErrorMessages: FC<ErrorMessagesProps> = ({
  submitCount,
  isSubmitting,
  isValid,
  errorsArray,
}) => {
  if (submitCount > 0 && !isSubmitting && !isValid) {
    return (
      <div className='api-error-messages'>
        <ul className='uk-list uk-list-space uk-margin-remove'>
          {errorsArray?.map(errorString => (
            <li key={errorString} data-testid='error-messages__error'>
              {errorString}
            </li>
          ))}
        </ul>
      </div>
    )
  }

  return null
}
