import { useFormikContext } from 'formik'
import React, { FC, useContext, useMemo } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { FormStepSummaryRow } from 'components/common/FormSteps'
import createMarkup from 'components/common/TooltipIcon/helpers'
import { getSelectedAddressDisplayName } from 'components/inquiry/CreateInquiryPage/helpers'
import {
  getContainerName,
  getFractionsFromFractionId,
} from 'components/inquiry/helpers'
import { getAddressListForCompanySelector } from 'selectors/address'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'
import { getSecurityGroupsSelector } from 'selectors/securitygroup'

import {
  AddAgreementFormContext,
  AddAgreementFormValues,
} from '../AddAgreementFormSteps'

export const SelectAddressContainerFractionAvvSummary: FC = () => {
  const { values } = useFormikContext<AddAgreementFormValues>()
  const { editStep } = useContext(AddAgreementFormContext)

  const addressList = useSelector<any, Address[]>(
    getAddressListForCompanySelector,
  )
  const fractionList = useSelector<any, Fraction[]>(getFractionsSelector)
  const containerList = useSelector<any, Container[]>(getContainersSelector)
  const securityGroupList = useSelector<any, SecurityGroup[]>(
    getSecurityGroupsSelector,
  )

  const summarySecurityGroup = useMemo(
    () => securityGroupList.find(group => group.id === values.security_group),
    [securityGroupList, values.security_group],
  )

  const { fraction: summaryFineFraction, coarseFraction: summaryFraction } =
    getFractionsFromFractionId(
      values.fine_fraction ?? values.fraction,
      fractionList,
    )
  const summaryContainerAndSize = getContainerName(
    values.container,
    containerList,
  )
  const summaryOnPublicGround = values.position_on_public_ground
    ? 'onPublicGround'
    : 'onPrivateGround'

  return (
    <div className='maklerpremium-step-summary'>
      <div className='maklerpremium-step-summary_grid-body'>
        <FormStepSummaryRow
          label={I18n.t(
            'createInquiryTranslations.form.addressAndDeliveryFormGroup.summary.address',
          )}
          value={
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={createMarkup(
                getSelectedAddressDisplayName(
                  Number(values.collection_address),
                  addressList,
                ),
              )}
            />
          }
        />
        <FormStepSummaryRow
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.fraction.label',
          )}
          value={summaryFraction?.name}
          key='maklerpremium-step-summary-fine_fraction'
        />
        <FormStepSummaryRow
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.fineFraction.label',
          )}
          value={summaryFineFraction?.name}
          key='maklerpremium-step-summary-fraction'
        />
        <FormStepSummaryRow
          label={I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.label',
          )}
          value={
            summaryFraction?.avv_list.find(avv => avv.id === Number(values.avv))
              ?.number
          }
          key='maklerpremium-step-summary-avv'
        />
        {values.security_group && summarySecurityGroup && (
          <FormStepSummaryRow
            label={I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.2.fields.security_group.label',
            )}
            value={`${I18n.t(
              'selectSecurityGroupTranslations.protectionClassNumber',
              { protectionClass: summarySecurityGroup.protection_class },
            )} / ${I18n.t(
              'selectSecurityGroupTranslations.securityLevelNumber',
              { securityLevel: summarySecurityGroup.security_level },
            )}`}
            key='maklerpremium-step-summary-security-group'
          />
        )}
        <FormStepSummaryRow
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.container.label',
          )}
          value={summaryContainerAndSize}
          key='maklerpremium-step-summary-container_and_size'
        />
        <FormStepSummaryRow
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.numberOfContainers.label',
          )}
          value={values.number_of_containers}
          key='maklerpremium-step-summary-number_of_containers'
        />
        <FormStepSummaryRow
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.positionOnPublicGround.label',
          )}
          value={I18n.t(
            `createMaklerPremiumOfferPageTranslations.steps.2.radiopanel.${summaryOnPublicGround}`,
          )}
          key='maklerpremium-step-summary-on_public_ground'
        />
      </div>
      <div className='sidebar'>
        <ButtonLink
          className='sidebar__edit-link'
          linkColor={BUTTON_LINK_COLOR.PRIMARY}
          onClick={editStep}
        >
          {I18n.t('createInquiryTranslations.form.button.editStep')}
        </ButtonLink>
      </div>
    </div>
  )
}
