import React from 'react'
import { Translate } from 'react-i18nify'

import { UpDownArrow } from '../../common/UpDownArrow'

export const OperationsStage2Columns = [
  {
    id: 'id',
    Header: (
      <>
        <Translate value='communicationCenterPageTranslations.columns.id' />
        <UpDownArrow />
      </>
    ),
    width: 75,
    accessor: data => data.id,
  },
  {
    id: 'email',
    Header: (
      <>
        <Translate value='communicationCenterPageTranslations.columns.email' />
        <UpDownArrow />
      </>
    ),
    width: 300,
    Cell: data => {
      return <a href={`mailto:${data.original.email}`}>{data.original.email}</a>
    },
    headerStyle: { padding: '0' },
  },
  {
    id: 'type',
    Header: (
      <>
        <Translate value='communicationCenterPageTranslations.columns.email_type' />
        <UpDownArrow />
      </>
    ),
    width: 125,
    accessor: data => data.type,
  },
  {
    id: 'name',
    Header: (
      <>
        <Translate value='communicationCenterPageTranslations.columns.name' />
        <UpDownArrow />
      </>
    ),
    width: 200,
    accessor: data => data.name,
  },
  {
    id: 'fraction',
    Header: (
      <>
        <Translate value='communicationCenterPageTranslations.columns.waste_type' />
        <UpDownArrow />
      </>
    ),
    accessor: data => data.fraction_name,
  },
  {
    id: 'container_type',
    Header: (
      <>
        <Translate value='communicationCenterPageTranslations.columns.container' />
        <UpDownArrow />
      </>
    ),
    accessor: data => data.container_type,
  },
  {
    id: 'area',
    Header: (
      <>
        <Translate value='communicationCenterPageTranslations.columns.area' />
        <UpDownArrow />
      </>
    ),
    accessor: data => data.area,
  },
]
