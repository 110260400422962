import { useFormikContext } from 'formik'
import moment, { now } from 'moment/moment'
import React, { FC, useEffect, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { decimalToGermanFormat } from 'helper/general'
import { UserPermission } from 'constants/user'
import {
  BUSINESS_SEGMENT_CHOICES,
  COLLECTION_CONTAINER_IDS,
} from 'constants/app'
import { resetApiFetchErrors } from 'actions/app'
import { getAutoOfferSelector } from 'selectors/autooffer'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

import { Button, BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import { Checkbox } from '../../common/Checkbox'
import { CommunityControl } from '../../common/CommunityControl'
import { DropDownInput } from '../../common/DropDownInput'
import { EmptoPhoneUserSelector } from '../../common/EmptoPhoneUserSelector'
import { ApiValidationMessages } from '../../common/Form/components/ApiValidationMessages'
import { InputChooseItem } from '../../common/InputChooseItem'
import InputDate from '../../common/InputDate'
import { InputDecimal } from '../../common/InputDecimal'
import { ProgressButton } from '../../common/ProgressButton'
import { ReadOnlyField } from '../../common/ReadOnly'
import { SelectSecurityGroup } from '../../common/SelectSecurityGroup'
import { isSecurityRequired } from '../../common/SelectSecurityGroup/helpers'
import {
  TOOLTIP_POSITION,
  TOOLTIP_THEME,
  TOOLTIP_ZINDEX,
} from '../../common/Tooltip'
import { ZipcodeControl } from '../../common/ZipcodeControl'
import { CONTENT_TYPES } from '../../inquiry/CreateInquiryPage/FractionAndContainerFormGroup/constants'
import {
  getCoarseFractionItemsForSegment,
  getContainerItems,
  getContainerTop,
  getFineFractionItems,
} from '../../inquiry/helpers'
import { AUTO_OFFER_ORDER_TYPES } from '../constants'
import { PriceInputs } from '../CreateOfferPage/PriceDetailFields'

import { AutoOfferFormValues } from './AutoOfferFormModal'
import './style.scss'

interface AutoOfferFormProps {
  isEditing?: boolean
  onClose?: () => void
  fractionList?: Fraction[]
  containerList?: Container[]
}

export const AutoOfferForm: FC<AutoOfferFormProps> = ({
  isEditing = false,
  onClose = () => undefined,
  fractionList = [],
  containerList = [],
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldTouched,
    setFieldValue,
    setValues,
    submitCount,
    touched,
    values,
  } = useFormikContext<AutoOfferFormValues>()

  const [fineFractionList, setFineFractionList] = useState<Fraction[]>([])
  const [selectableFractionList, setSelectableFractionList] = useState<
    Fraction[]
  >([])
  const [selectableContainerList, setSelectableContainerList] = useState<
    Container[]
  >([])
  const [avvList, setAvvList] = useState<Avv[]>([])

  const dispatch = useDispatch()

  const autoOffer = useSelector(getAutoOfferSelector)

  const isLoading = {
    getAutoOffer: useSelector(createLoadingSelector(['GET_AUTO_OFFER'])),
    createAutoOffer: useSelector(createLoadingSelector(['CREATE_AUTO_OFFER'])),
    updateAutoOffer: useSelector(createLoadingSelector(['UPDATE_AUTO_OFFER'])),
  }

  const apiError = {
    createAutoOffer: useSelector<any, ApiError>(
      createErrorSelector(['CREATE_AUTO_OFFER']),
    ),
    updateAutoOffer: useSelector<any, ApiError>(
      createErrorSelector(['UPDATE_AUTO_OFFER']),
    ),
  }

  const createdMoment =
    autoOffer.created_at && isEditing ? moment(autoOffer.created_at) : null
  const lastModifiedMoment =
    autoOffer.lastmodified_at && isEditing
      ? moment(autoOffer.lastmodified_at)
      : null

  const showCommunitiesAndZipcodesRequiredDot =
    values.communities &&
    values.communities.length < 1 &&
    values.zipcodes &&
    values.zipcodes.length < 1

  // Altough there is no effect to cleanup, we can use the "useEffect" capabilities to reset the ApiErrors on
  // general cleanup. This will behave like previously using "componentWillUnmount". A sole function is returned by
  // the effect with the desired action
  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors('CREATE_AUTO_OFFER'))
    },
    [dispatch],
  )

  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors('UPDATE_AUTO_OFFER'))
    },
    [dispatch],
  )

  useEffect(() => {
    if (values.business_segment) {
      setSelectableFractionList(
        getCoarseFractionItemsForSegment(
          fractionList.filter(
            item =>
              item.visible_in_empto &&
              fractionList.filter(
                fractionItem =>
                  item.parent === fractionItem.id &&
                  fractionItem.visible_in_empto,
              ),
          ),
          Number(values.business_segment),
        ),
      )
    }
  }, [fractionList, values.business_segment])

  useEffect(() => {
    if (values.fraction) {
      setSelectableContainerList(
        getContainerItems(
          Number(values.fraction),
          values.fine_fraction,
          fractionList,
          containerList,
          Number(values.business_segment),
        ) as Container[],
      )
    }
  }, [
    containerList,
    fractionList,
    values.business_segment,
    values.fine_fraction,
    values.fraction,
  ])

  useEffect(() => {
    if (isEditing) {
      let fraction
      let fine_fraction
      const selectedFraction = fractionList.find(
        _fraction => _fraction.id === autoOffer.fraction,
      )
      if (selectedFraction) {
        fraction = selectedFraction.id
        if (selectedFraction.parent) {
          fraction = selectedFraction.parent
          fine_fraction = selectedFraction.id
        }
      }

      const convertCurrency = value =>
        new Intl.NumberFormat('de-DE').format(value)

      setValues({
        avv: autoOffer.avv || '',
        business_segment:
          typeof autoOffer.business_segment !== 'undefined'
            ? String(autoOffer.business_segment)
            : '',
        communities: autoOffer.communities,
        company: autoOffer.company || '',
        container: autoOffer.container || '',
        disposal_cost_container:
          convertCurrency(autoOffer.disposal_cost_container) || '',
        disposal_cost_ton: convertCurrency(autoOffer.disposal_cost_ton),
        compensation_ton: convertCurrency(autoOffer.compensation_ton),
        compensation_container:
          convertCurrency(autoOffer.compensation_container) || '',
        cost_container: convertCurrency(autoOffer.cost_container) || '',
        fine_fraction: fine_fraction || '',
        fraction,
        handle_cost_ton: convertCurrency(autoOffer.handle_cost_ton) || '',
        index: autoOffer.index || '',
        number_of_containers: autoOffer.number_of_containers || '',
        quantity_in_cubic_meters:
          decimalToGermanFormat(autoOffer.quantity_in_cubic_meters) || '',
        order_type: autoOffer.order_type || '',
        phone_user: autoOffer.phone_user,
        position_on_public_ground: autoOffer.position_on_public_ground,
        reduction: convertCurrency(autoOffer.reduction) || '',
        rent_price_container_month:
          convertCurrency(autoOffer.rent_price_container_month) || '',
        security_group: autoOffer.security_group || '',
        surcharge: convertCurrency(autoOffer.surcharge) || '',
        transport_price_piece:
          convertCurrency(autoOffer.transport_price_piece) || '',
        valid_from: moment(autoOffer.valid_from).format('L'),
        valid_until: moment(autoOffer.valid_until).format('L'),
        with_estimated_time_of_arrival:
          autoOffer.with_estimated_time_of_arrival,
        with_top: autoOffer.with_top,
        zipcodes: autoOffer.zipcodes,
      })
    }
  }, [
    autoOffer,
    fractionList,
    isEditing,
    setFieldTouched,
    setFieldValue,
    setValues,
  ])

  useEffect(() => {
    setFineFractionList(
      getFineFractionItems(
        Number(values.fraction),
        fractionList.filter(
          item =>
            item.visible_in_empto &&
            fractionList.filter(
              fractionItem =>
                item.parent === fractionItem.id &&
                fractionItem.visible_in_empto,
            ),
        ),
      ),
    )
    const fraction = fractionList.find(
      _fraction => _fraction.id === Number(values.fraction),
    )
    setAvvList(fraction ? fraction.avv_list : [])

    if (!touched.avv && fraction && fraction.avv_list.length === 1) {
      setFieldValue('avv', fraction.avv_list[0].id)
      setFieldTouched('avv')
    }
  }, [
    fractionList,
    setFieldTouched,
    setFieldValue,
    touched.avv,
    values.fraction,
  ])

  const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
    Number(values.container),
  )

  if (
    isLoading.getAutoOffer ||
    (isEditing && typeof autoOffer.id === 'undefined')
  )
    return null
  return (
    <>
      <div
        className='uk-modal-body'
        data-testid='my-auto-offers-page__form_modal-fields'
      >
        {isEditing && (
          <>
            {/* There might be an offset between server and client that leads to a moment in the future.
            We display nothing then. It would be a kind of useless information like „just created“ anyway. */}
            {createdMoment && createdMoment.diff(now()) < 1 && (
              <p className='uk-text-meta my-auto-offers-page__form_modal-fields-modified-message'>
                <Translate
                  date={createdMoment.format('LL')}
                  fromNow={createdMoment.fromNow()}
                  name={autoOffer.created_by_name}
                  time={createdMoment.format('LT')}
                  value='myAutoOffersPageTranslations.form.info.created'
                />
              </p>
            )}
            {/* There is always a tiny gap between both dates, and last modified is set right after creation
             so we can not just compare both with !== to decide which date to display. */}
            {lastModifiedMoment &&
              createdMoment &&
              lastModifiedMoment.diff(createdMoment, 'seconds') > 10 && (
                <p className='uk-text-meta my-auto-offers-page__form_modal-fields-modified-message'>
                  <Translate
                    date={lastModifiedMoment.format('LL')}
                    fromNow={lastModifiedMoment.fromNow()}
                    name={autoOffer.lastmodified_by_name}
                    time={lastModifiedMoment.format('LT')}
                    value='myAutoOffersPageTranslations.form.info.modified'
                  />
                </p>
              )}
          </>
        )}

        {(apiError.createAutoOffer || apiError.updateAutoOffer) && (
          <>
            <ApiValidationMessages error={apiError.createAutoOffer} />
            <ApiValidationMessages error={apiError.updateAutoOffer} />
          </>
        )}

        <EmptoPhoneUserSelector
          requiredPermissions={[UserPermission.CREATE_PHONE_AUTO_OFFERS]}
          showListForWasteCompanies
          showOnlyActiveCompanies
        />

        {/* SELECT BUSINESS SEGMENT */}
        <DropDownInput
          choices={BUSINESS_SEGMENT_CHOICES.map(item => ({
            optionValue: item.id,
            optionLabel: I18n.t(item.name),
          }))}
          dataTestId='auto-offer-form-business-segment'
          error={
            submitCount > 0 &&
            touched.business_segment &&
            errors.business_segment
              ? (errors.business_segment as string)
              : ''
          }
          isRequired={!values.business_segment}
          label={I18n.t(
            'myAutoOffersPageTranslations.form.fields.businessSegment.label',
          )}
          tooltip={{
            description: I18n.t(
              'myAutoOffersPageTranslations.form.fields.businessSegment.tooltip',
            ),
            theme: TOOLTIP_THEME.LIGHT,
            tooltipId: 'businessSegment',
            zIndex: TOOLTIP_ZINDEX.BEFORE_MODALS,
            position: TOOLTIP_POSITION.RIGHT,
          }}
          name='business_segment'
          onChange={handleChange}
          placeholder={I18n.t(
            'myAutoOffersPageTranslations.form.fields.businessSegment.placeholder',
          )}
          value={values.business_segment}
        />

        <div className='my-auto-offers-page__form_modal-fields--side-by-side'>
          <ReadOnlyField
            editable={!isEditing}
            label={I18n.t(
              'myAutoOffersPageTranslations.form.fields.validFrom.label',
            )}
            value={moment(autoOffer.valid_from).format('L')}
          >
            <InputDate
              error={
                submitCount > 0 && touched.valid_from && errors.valid_from
                  ? errors.valid_from
                  : ''
              }
              placeholder={I18n.t(
                'myAutoOffersPageTranslations.form.fields.validFrom.label',
              )}
              label={I18n.t(
                'myAutoOffersPageTranslations.form.fields.validFrom.label',
              )}
              isRequired={!values.valid_from}
              minDate={moment()}
              name='valid_from'
              onChange={value => {
                setFieldValue('valid_from', value.format('L'))
                setFieldTouched('valid_from')
                setFieldValue('valid_until', '')
                setFieldTouched('valid_until')
              }}
              value={values.valid_from}
            />
          </ReadOnlyField>

          <InputDate
            error={
              submitCount > 0 && touched.valid_until && errors.valid_until
                ? errors.valid_until
                : ''
            }
            placeholder={I18n.t(
              'myAutoOffersPageTranslations.form.fields.validUntil.label',
            )}
            label={I18n.t(
              'myAutoOffersPageTranslations.form.fields.validUntil.label',
            )}
            isRequired={!values.valid_until}
            minDate={
              values.valid_from && !isEditing
                ? moment(values.valid_from, 'L')
                : moment()
            }
            name='valid_until'
            onChange={value => {
              setFieldValue('valid_until', value.format('L'))
              setFieldTouched('valid_until')
            }}
            value={values.valid_until}
          />
        </div>
        {/* FRACTION */}
        <InputChooseItem
          dataTestId='select-fraction'
          contentType={CONTENT_TYPES.FRACTION}
          error={
            submitCount > 0 && touched.fraction && errors.fraction
              ? (errors.fraction as string)
              : ''
          }
          name='fraction'
          label={I18n.t('createOfferTranslations.label.fraction')}
          subLabel={I18n.t(
            'createInquiryTranslations.form.fractionAndContainerFormGroup.subLabel.fraction',
          )}
          placeholder={I18n.t(
            'createInquiryTranslations.form.fractionAndContainerFormGroup.placeholder.fraction',
          )}
          itemList={selectableFractionList}
          showSelectionImage
          title={I18n.t('inquiryForm.addFractionModal.header.title')}
          handleChange={(value: React.ChangeEvent<any>) => {
            setValues(
              {
                ...values,
                fraction: String(value),
                fine_fraction: '',
                security_group: '',
                container: '',
                number_of_containers: '1',
                quantity_in_cubic_meters: '',
                rent_price_container_month: '',
                disposal_cost_container: '',
                transport_price_piece: '',
                disposal_cost_ton: '',
                compensation_ton: '',
                compensation_container: '',
                index: '',
                surcharge: '',
                reduction: '',
                handle_cost_ton: '',
              },
              true,
            )
          }}
          value={values.fraction}
          confirmText={I18n.t(
            'inquiryForm.addFractionModal.details.confirmSelection',
          )}
          backToSelectionText={I18n.t(
            'inquiryForm.addFractionModal.details.backToSelection',
          )}
          isRequired={!values.fraction}
        />

        {/* FINE FRACTION */}
        {values.fraction && fineFractionList.length > 0 && (
          <DropDownInput
            dataTestId='select-fine-fraction'
            choices={fineFractionList.map(item => ({
              optionValue: item.id,
              optionLabel: item.name,
            }))}
            isPlaceholderClickable
            label={I18n.t('createOfferTranslations.label.fineFraction')}
            name='fine_fraction'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t('general.placeholder.empty')}
            value={values.fine_fraction || ''}
          />
        )}

        {/* SECURITY GROUPS */}
        {values.fraction && isSecurityRequired(null, fractionList, values) && (
          <SelectSecurityGroup
            error={
              submitCount > 0 &&
              touched.security_group &&
              !!errors.security_group
                ? (errors.security_group as string)
                : ''
            }
            selectedSecurityGroup={values.security_group}
            onSelectionChange={(groupId: number | null) =>
              setFieldValue('security_group', Number(groupId))
            }
            tooltipZIndex={TOOLTIP_ZINDEX.BEFORE_MODALS}
          />
        )}

        {/* CONTAINER */}
        {values.fraction && (
          <>
            <InputChooseItem
              dataTestId='select-container'
              name='container'
              error={
                submitCount > 0 && touched.fraction && errors.container
                  ? (errors.container as string)
                  : ''
              }
              label={I18n.t('createOfferTranslations.label.container')}
              placeholder={I18n.t(
                'createInquiryTranslations.form.fractionAndContainerFormGroup.placeholder.container',
              )}
              itemList={selectableContainerList}
              title={I18n.t('inquiryForm.addContainerModal.header.title')}
              handleChange={(value: React.ChangeEvent<any>) => {
                setValues(
                  {
                    ...values,
                    container: String(value),
                    number_of_containers: '1',
                    quantity_in_cubic_meters: '',
                    rent_price_container_month: '',
                    disposal_cost_container: '',
                    transport_price_piece: '',
                    disposal_cost_ton: '',
                    compensation_ton: '',
                    compensation_container: '',
                    index: '',
                    surcharge: '',
                    reduction: '',
                    handle_cost_ton: '',
                  },
                  true,
                )
              }}
              value={values.container}
              informationText={I18n.t(
                'inquiryForm.addContainerModal.header.information',
              )}
              contentType={CONTENT_TYPES.CONTAINER}
              confirmText={I18n.t(
                'inquiryForm.addContainerModal.details.confirmSelection',
              )}
              backToSelectionText={I18n.t(
                'inquiryForm.addContainerModal.details.backToSelection',
              )}
              isRequired={!values.container}
            />

            {/* NUMBER OF CONTAINERS */}
            {!showQuantityInCubicMeters && (
              <DropDownInput
                dataTestId='input-number-of-containers'
                choices={Array.from(Array(10).keys()).map(item => ({
                  optionValue: String(item + 1),
                  optionLabel: String(item + 1),
                }))}
                label={I18n.t(
                  'createOfferTranslations.label.numberOfContainers',
                )}
                name='number_of_containers'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.number_of_containers}
                isRequired={!values.number_of_containers}
                error={
                  submitCount > 0 &&
                  touched.number_of_containers &&
                  errors.number_of_containers
                    ? (errors.number_of_containers as string)
                    : ''
                }
              />
            )}

            {/* QUANTITY IN CUBIC METERS */}
            {showQuantityInCubicMeters && (
              <InputDecimal
                dataTestId='quantity-in-cubic-meters-input'
                label={I18n.t(
                  'createOfferTranslations.label.quantityInCubicMeters',
                )}
                placeholder={I18n.t(
                  'createOfferTranslations.label.quantityInCubicMeters',
                )}
                maxValue={99.99}
                name='quantity_in_cubic_meters'
                onChange={handleChange}
                value={values.quantity_in_cubic_meters}
                removeBrowserStyling
                isRequired={!values.quantity_in_cubic_meters}
                error={
                  submitCount > 0 &&
                  touched.quantity_in_cubic_meters &&
                  errors.quantity_in_cubic_meters
                    ? errors.quantity_in_cubic_meters
                    : ''
                }
              />
            )}
          </>
        )}

        {/* CONTAINER WITH TOP */}
        {values.container &&
          getContainerTop(values.container, containerList) && (
            <Checkbox
              isChecked={values.with_top}
              label={I18n.t(
                'createInquiryTranslations.form.fractionAndContainerFormGroup.label.withTop',
              )}
              name='with_top'
              onChange={handleChange}
            />
          )}

        {/* ORDER TYPE */}
        <DropDownInput
          choices={AUTO_OFFER_ORDER_TYPES.map(item => ({
            optionValue: item.id,
            optionLabel: I18n.t(item.name),
          }))}
          dataTestId='select-order-type'
          error={
            submitCount > 0 && touched.order_type && errors.order_type
              ? (errors.order_type as string)
              : ''
          }
          isRequired={!values.order_type}
          label={I18n.t('inquiry.orderType')}
          name='order_type'
          onChange={handleChange}
          placeholder={I18n.t('inquiry.orderType')}
          value={values.order_type}
        />

        {/* AVV NUMBER */}
        {values.fraction && (
          <DropDownInput
            dataTestId='select-avv'
            error={
              submitCount > 0 && touched.avv && errors.avv
                ? (errors.avv as string)
                : ''
            }
            choices={avvList.map(item => ({
              optionLabel: item.number,
              optionValue: item.id,
            }))}
            isPlaceholderClickable
            label={I18n.t('offerForm.avvNumber.label')}
            name='avv'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t('offerForm.avvNumber.selector')}
            value={values.avv}
            isRequired={!values.avv}
          />
        )}

        <div className='my-auto-offers-page__form_modal-fields-communities-select'>
          <CommunityControl
            dataTestId='community-select'
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            name='communities'
            onBlur={setFieldTouched}
            onChange={setFieldValue}
            showRequiredDot={showCommunitiesAndZipcodesRequiredDot}
            error={
              submitCount > 0 && touched.communities && errors.communities
                ? (errors.communities as string)
                : ''
            }
            value={values.communities}
          />
          {/* TODO @devTeam: this is a workaround and should be integrated into a new selected async component.
          For some reason, passing a prop with the error and showing this within, is not working */}
          {submitCount > 0 && touched.communities && !!errors.communities && (
            <div
              className='my-auto-offers-page__form_modal-fields-communities-select-field-error'
              data-testid=''
            >
              {errors.communities}
            </div>
          )}
        </div>

        <div className='my-auto-offers-page__form_modal-fields-zipcodes-select'>
          <ZipcodeControl
            dataTestId='zipcodes-select'
            getOptionLabel={option => option.code}
            getOptionValue={option => option.id}
            name='zipcodes'
            onBlur={setFieldTouched}
            onChange={setFieldValue}
            showRequiredDot={showCommunitiesAndZipcodesRequiredDot}
            error={
              submitCount > 0 && touched.zipcodes && errors.zipcodes
                ? (errors.zipcodes as string)
                : ''
            }
            value={values.zipcodes}
          />
          {/* TODO @devTeam: this is a workaround and should be integrated into a new selected async component.
          For some reason, passing a prop with the error and showing this within, is not working */}
          {submitCount > 0 && touched.zipcodes && !!errors.zipcodes && (
            <div
              className='my-auto-offers-page__form_modal-fields-zipcodes-select-field-error'
              data-testid=''
            >
              {errors.zipcodes}
            </div>
          )}
        </div>
        <br />
        <Checkbox
          dataTestId='auto-offer-form-position-on-public-ground'
          isChecked={values.position_on_public_ground}
          label={I18n.t('inquiry.positionOnPublicGround.description')}
          name='position_on_public_ground'
          onChange={handleChange}
        />
        <br />
        <Checkbox
          dataTestId='auto-offer-form-with-estimated-time-of-arrival'
          isChecked={values.with_estimated_time_of_arrival}
          label={I18n.t('inquiry.withEstimatedTimeOfArrival')}
          name='with_estimated_time_of_arrival'
          onChange={handleChange}
        />
        <br />
        {/* prices */}
        {values.container && (
          <PriceInputs isEditing showErrors showRequiredDot />
        )}
      </div>

      <div className='uk-modal-footer my-auto-offers-page__form_modal-fields-footer'>
        <div className='my-auto-offers-page__form_modal-fields-footer-action-buttons'>
          <Button
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            onClick={onClose}
          >
            <Translate value='general.button.cancel' />
          </Button>

          <ProgressButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            dataTestId='create-auto-offer-form-submit-button'
            isDisabled={
              (submitCount > 0 && !isValid) ||
              (isSubmitting &&
                (isLoading.createAutoOffer || isLoading.updateAutoOffer))
            }
            isLoading={
              isSubmitting &&
              (isLoading.createAutoOffer || isLoading.updateAutoOffer)
            }
            onClick={handleSubmit}
          >
            <Translate
              value={`myAutoOffersPageTranslations.form.submitButton.${
                isEditing ? 'update' : 'create'
              }`}
            />
          </ProgressButton>
        </div>
        {submitCount > 0 && !isValid && (
          <div className='my-auto-offers-page__form_modal-fields-footer-global-form-submission-error'>
            <Translate value='myAutoOffersPageTranslations.form.validation.globalFormSubmissionError' />
          </div>
        )}
      </div>
    </>
  )
}
