import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'

import Icon from 'components/common/Fontello'
import logo from 'assets/svg/empto-logo.svg'

/**
 * @description This component renders information when the user reaches a non existing route on the system
 * @return {*}
 */

export const NotFoundComponent: FC = () => (
  <div className='not-found-route-container'>
    <h1 className='uk-text-center uk-text-bold uk-margin-medium-top'>
      <Translate value='routeError.error.404' />
    </h1>
    <div className='uk-navbar-left'>
      <img className='uk-height-medium uk-margin-auto' alt='' src={logo} />
    </div>
    <div className='uk-margin uk-text-large uk-text-center'>
      <Link to='/'>
        <Icon name='reply' />
        <Translate value='routeError.goHome' />
      </Link>
    </div>
  </div>
)
