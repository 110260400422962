import classNames from 'classnames'
import { useFormikContext } from 'formik'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { getIndexes } from 'components/common/DetailsPage/helper'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { BILLING_INTERVAL } from 'components/company/constants'
import { getFractionsSelector } from 'selectors/fraction'
import { getIndexesSelector } from 'selectors/indexes'

import {
  AddAgreementFormContext,
  AddAgreementFormValues,
  step4validationSchema,
} from '../AddAgreementFormSteps'
import { ROLE_KEYS, ROLES } from '../Step1/helpers'

import { BillingModelFormFields } from './components/BillingModelFormFields'
import { ContainerPriceFormFields } from './components/ContainerPriceFormFields'
import { resetAllPricingFields } from './components/helpers'
import { PackagePriceFormFields } from './components/PackagePriceFormFields'
import { PricingFormFields } from './components/PricingFormFields'
import { SinglePriceFormFields } from './components/SinglePriceFormFields'
import {
  getBillingModelValueAndSetterByRole,
  getPricingValueAndSetterByRole,
  getSinglePriceFieldValuesByRole,
} from './helpers'

export const PRICING = {
  DISPOSAL: 'disposal', // Entsorgung
  COMPENSATION: 'compensation', // Vergütung
}

export const BILLING_MODEL = {
  PACKAGE_PRICE: 'packagePrice', // Pauschalpreis
  SINGLE_PRICE: 'singlePrice', // Einzelpreis
  CONTAINER_PRICE: 'containerPrice', // Behälterpreis
}

export const DefineNetPriceForm = () => {
  const { values, setValues } = useFormikContext<AddAgreementFormValues>()
  const { stepStatus, saveStep } = useContext(AddAgreementFormContext)

  const customerIsBilledPerOrder =
    values.customer.selected_company?.billing_interval ===
    BILLING_INTERVAL.ORDER

  // If either of the following values are no longer undefined (as per initialValues), that means, that the form
  // must have been filled out before and is currently being edited
  const isEditing =
    !!values.customer_compensation_container ||
    !!values.customer_compensation_ton ||
    !!values.customer_cost_container ||
    !!values.customer_index ||
    !!values.customer_package_price ||
    !!values.customer_rent_price

  const [customerBillingModel, setCustomerBillingModel] = useState<string>(
    () => {
      if (isEditing) return values.customer_billing_model
      return customerIsBilledPerOrder
        ? BILLING_MODEL.PACKAGE_PRICE
        : BILLING_MODEL.SINGLE_PRICE
    },
  )

  // Initial values are provided by the forms initial values
  const [customerPricing, setCustomerPricing] = useState<string>(
    values.customer_pricing,
  )
  const [partnerPricing, setPartnerPricing] = useState<string>(
    values.partner_pricing,
  )
  const [partnerBillingModel, setPartnerBillingModel] = useState<string>(
    values.partner_billing_model,
  )

  const fractionList = useSelector(getFractionsSelector)
  const indexList = useSelector(getIndexesSelector)

  const [indexListNames, setIndexListNames] = useState<any>([])
  const selectedFraction = useMemo(
    () =>
      fractionList.find(
        item =>
          item.id ===
          Number(values.fine_fraction ? values.fine_fraction : values.fraction),
      ),
    [fractionList, values.fine_fraction, values.fraction],
  )

  useEffect(() => {
    setIndexListNames(
      getIndexes(
        values.fraction,
        values.fine_fraction,
        fractionList,
        indexList,
      ),
    )
  }, [values.fraction, values.fine_fraction, fractionList, indexList])

  useEffect(() => {
    if (
      !selectedFraction?.allow_price_model_compensation &&
      !STEP_STATUS.EDITING
    ) {
      // reset pricing state
      setCustomerPricing(PRICING.DISPOSAL)
      setPartnerPricing(PRICING.DISPOSAL)

      // reset billing state
      setCustomerBillingModel(BILLING_MODEL.SINGLE_PRICE)
      setPartnerBillingModel(BILLING_MODEL.CONTAINER_PRICE)

      resetAllPricingFields(values, setValues)
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFraction, setValues])

  const pricingDisableCompensation = role => {
    return (
      (role === ROLE_KEYS.CUSTOMER && customerIsBilledPerOrder) ||
      (role === ROLE_KEYS.CUSTOMER &&
        !customerIsBilledPerOrder &&
        !selectedFraction?.allow_price_model_compensation) || // SAMMELRECHNUNGSKUNDEN
      (role === ROLE_KEYS.PARTNER &&
        !selectedFraction?.allow_price_model_compensation) //PARNTER
    )
  }

  return (
    <div className='maklerpremium-agreementform__step4'>
      {ROLES.map((role, index) => {
        const { billingModel, setBillingModel } =
          getBillingModelValueAndSetterByRole({
            role,
            useStateValues: {
              customerBillingModel,
              partnerBillingModel,
            },
            useStateSetter: {
              setCustomerBillingModel,
              setPartnerBillingModel,
            },
          })
        const { pricing, setPricing } = getPricingValueAndSetterByRole({
          role,
          useStateValues: {
            customerPricing,
            partnerPricing,
          },
          useStateSetter: {
            setCustomerPricing,
            setPartnerPricing,
          },
        })

        return (
          <div key={role}>
            <p
              className={classNames('role-section-headline', {
                'role-section-headline__smaller-height': index === 0,
              })}
            >
              {I18n.t(
                `addAgreementPageTranslations.steps.1.fields.${role}_title`,
              )}
            </p>

            <PricingFormFields
              pricing={pricing}
              role={role}
              setPricing={setPricing}
              disableCompensation={pricingDisableCompensation(role)}
            />

            <BillingModelFormFields
              billingModel={billingModel}
              role={role}
              pricing={pricing}
              setBillingModel={setBillingModel}
              disableSinglePrice={
                role === ROLE_KEYS.CUSTOMER && customerIsBilledPerOrder
              }
              disableContainerPrice={
                role === ROLE_KEYS.CUSTOMER && customerIsBilledPerOrder
              }
            />

            <SinglePriceFormFields
              billingModel={billingModel}
              pricing={pricing}
              role={role}
              getSinglePriceFieldValuesByRole={getSinglePriceFieldValuesByRole}
              indexListNames={indexListNames}
            />
            {billingModel === BILLING_MODEL.PACKAGE_PRICE && (
              <PackagePriceFormFields role={role} pricing={pricing} />
            )}
            {billingModel === BILLING_MODEL.CONTAINER_PRICE && (
              <ContainerPriceFormFields role={role} pricing={pricing} />
            )}
          </div>
        )
      })}

      <GoToNextFormStepButton
        buttonText={
          stepStatus === STEP_STATUS.EDITING
            ? I18n.t('general.button.save')
            : I18n.t('createInquiryTranslations.form.button.nextStep')
        }
        onClick={saveStep}
        isDisabled={!step4validationSchema(fractionList).isValidSync(values)}
      />
    </div>
  )
}
