import { convertInquiryFormTranslations } from './ConvertInquiryForm/translations'

export const convertInquiryModalTranslations = {
  modalTitle: 'Umwandlung in einen Dauerauftrag auf Abruf',
  modalInfo:
    'Hier können Sie einen Einmalauftrag in einen Dauerauftrag auf Abruf ändern.' +
    'Für die Umwandlung wird ein Ansprechpartner und eine E-Mailadresse des Kunden benötigt. ' +
    'Dieser bekommt eine E-Mail zugesendet, in der die Umwandlung bestätigt werden muss.',
  convertInquiryForm: convertInquiryFormTranslations,
}
