import './style.scss'

import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import Media from 'react-media'

import Accordion from 'components/common/Accordion'
import { NavigationBar } from 'components/common/DetailsPage/components/NavigationBar'
import { BREAKPOINT } from 'constants/design'

interface sectionsProps {
  bookkeepingComponent: React.ReactNode
  salesComponent: React.ReactNode
  operationsComponent: React.ReactNode
  activitiesComponent: React.ReactNode
}

export const Sections: FC<sectionsProps> = ({
  bookkeepingComponent,
  salesComponent,
  operationsComponent,
  activitiesComponent,
}) => {
  const sections = new Map([
    [
      'bookkeeping',
      {
        offset: -230,
        title: (
          <Translate value='communicationCenterPageTranslations.section.bookkeeping.title' />
        ),
        component: bookkeepingComponent,
      },
    ],
    [
      'sales',
      {
        offset: -230,
        title: (
          <Translate value='communicationCenterPageTranslations.section.sales.title' />
        ),
        component: salesComponent,
      },
    ],
    [
      null,
      {
        offset: -230,
        title: (
          <Translate value='communicationCenterPageTranslations.navigation.operations_1' />
        ),
        component: operationsComponent,
      },
    ],
    [
      'operations_1',
      {
        offset: -230,
        title: (
          <Translate value='communicationCenterPageTranslations.navigation.operations_1' />
        ),
      },
    ],
    [
      'operations_2',
      {
        offset: -230,
        title: (
          <Translate value='communicationCenterPageTranslations.navigation.operations_2' />
        ),
      },
    ],
    [
      'operations_3',
      {
        offset: -230,
        title: (
          <Translate value='communicationCenterPageTranslations.navigation.operations_3' />
        ),
      },
    ],
    [
      'activities',
      {
        offset: -230,
        title: (
          <Translate value='communicationCenterPageTranslations.section.activities.title' />
        ),
        component: activitiesComponent,
      },
    ],
  ])

  return (
    <div className='communicationcenterpage-sections'>
      <Media query={{ minWidth: BREAKPOINT.XLARGE }}>
        <NavigationBar sections={sections} sticky spaceBetween noIcons />
      </Media>
      <Media query={{ maxWidth: BREAKPOINT.XLARGE - 1 }}>
        {matches => (
          <div style={{ marginTop: '20px' }}>
            <Accordion collapsible={matches} multiple={matches} active={0}>
              {Array.from(sections, (value, index) => {
                if (value[1].component) {
                  return (
                    <li
                      id={value[0] ? value[0] : undefined}
                      key={`${index}_${value[0]}`}
                    >
                      {value[1].component}
                    </li>
                  )
                } else {
                  return null
                }
              })}
            </Accordion>
          </div>
        )}
      </Media>
    </div>
  )
}
