export const DocumentUploadFormTranslations = {
  type: {
    label: 'Dokumenttyp',
    placeholder: 'Dokumenttyp',
    error: 'Ungültiger oder leerer Dokumenttyp',
  },
  attachments: {
    label: 'Dokument',
    placeholder: 'Dokument',
    error: 'Ungültiges oder leeres Dokument',
  },
  description: {
    label: 'Genaue Bezeichnung',
    placeholder: 'Genaue Bezeichnung',
    error: 'Ungültige oder zu lange Bezeichnung',
  },
  noAttachmentsTitle: 'Hochgeladenes Dokument',
  noAttachmentsText: 'Noch kein Dokument hochgeladen.',
  choices: {
    efbCertificate: 'Efb-Zertifikat',
    priceAgreement: 'Preisvereinbarung',
    other: 'Sonstiges',
  },
}
