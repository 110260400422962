export const OfferReviewPageTranslations = {
  breadcrumb: {
    title: {
      rotation: 'Turnusbearbeitung',
    },
    prevLinkTitle: 'Arbeitsvorrat',
  },
  pageTitle: {
    rotation: 'Turnusbearbeitung',
  },
  documentTitle: {
    rotation: 'Turnusbearbeitung für Vereinbarung %{number}',
  },
  submitButtonText: {
    isSaving: 'Prüfung speichern',
    isReviewing: 'Prüfung abschließen',
  },
  saveInternalNote: {
    confirm: {
      rotation:
        'Möchten Sie die Turnusbearbeitung zwischenspeichern? Der interne Kommentar wird beibehalten',
    },
    warning: 'Bitte schreiben Sie eine internen Kommentar',
  },
  validation: {
    status: 'Bitte ändern Sie den Status.',
    rejectReason: 'Bitte begründen Sie Ihre Reklamation.',
  },
}
