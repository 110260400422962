import uniqueId from 'lodash.uniqueid'
import moment from 'moment/moment'
import React, { FC, useContext, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import {
  createCollectiveInvoice,
  createTotalCollectiveInvoice,
} from 'actions/company'
import {
  exportCollectiveInvoicing,
  exportCollectiveInvoicingActivities,
  exportCollectiveInvoicingForecast,
} from 'actions/invoice'
import { localizeDateForBackend } from 'helper/general'
import { getCurrentUserSelector } from 'selectors/user'
import { AsyncExportModal } from 'components/common/AsyncExportModal'
import {
  DateForm,
  LabelType,
} from 'components/common/AsyncExportModal/DateForm/DateForm'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'

import { CollectiveInvoicingPageContext } from '../../CollectiveInvoicingPage'

export const Modals: FC = () => {
  const dispatch = useDispatch()

  const user = useSelector(getCurrentUserSelector)
  const [exportStartDate, setExportStartDate] = useState<string>()
  const [exportEndDate, setExportEndDate] = useState<string>()
  const idTotalInvoiceModal = uniqueId()
  const idSingleInvoiceWarningModal = uniqueId()

  const { currentFilters } = useContext(CollectiveInvoicingPageContext)

  const getExportFilters = () => {
    let filters = {
      makler_premium_company: user.company,
      ...currentFilters,
      order_by: JSON.stringify([{ id: 'document_date', desc: true }]),
      company: currentFilters.id,
    }

    Object.keys(filters).forEach(filter => {
      //remove empty filter
      if (currentFilters[filter] === '') {
        delete filters[filter]
        return
      }
    })

    if (exportStartDate) {
      filters = {
        ...filters,
        document_date_start: localizeDateForBackend(exportStartDate),
      }
    }

    if (exportEndDate) {
      filters = {
        ...filters,
        document_date_end: localizeDateForBackend(exportEndDate),
      }
    }

    return filters
  }

  const getCleanedExportFilters = () => {
    const filters = getExportFilters()
    delete filters.document_date_start
    delete filters.document_date_end
    delete filters.makler_premium_company
    delete filters.order_by

    return filters
  }

  return (
    <CollectiveInvoicingPageContext.Consumer>
      {context => (
        <>
          <AsyncExportModal
            isOpen={context.isCollectiveInvoicingForecastModalOpen}
            onClose={() =>
              context.setIsCollectiveInvoicingForecastModalOpen(false)
            }
            reduxSelector='EXPORT_EPD_COLLECTIVE_INVOICING_FORECAST'
            title={I18n.t(
              'collectiveInvoicingTranslations.collectiveInvoicingForecastExport.modal.title',
            )}
            description_translation_key={
              'collectiveInvoicingTranslations.collectiveInvoicingForecastExport.modal.explanation'
            }
            logic={exportCollectiveInvoicingForecast({ ...getExportFilters() })}
            resetOnDispatch={() => {
              setExportEndDate(undefined)
              setExportStartDate(undefined)
            }}
            notice_translation_key={
              getCleanedExportFilters().company ||
              getCleanedExportFilters().tasklog_status
                ? 'asyncExportModalTranslations.dateForm.filterNotice'
                : ''
            }
          >
            <DateForm
              setStartDate={setExportStartDate}
              setEndDate={setExportEndDate}
              labelType={LabelType.INVOICING_DATE}
              overwriteMinDate={moment().subtract(1, 'year').date(1)}
              overwriteMaxDate={moment().date(31)}
            />
          </AsyncExportModal>

          <AsyncExportModal
            isOpen={context.isCollectiveInvoicingActivitiesModalOpen}
            onClose={() =>
              context.setIsCollectiveInvoicingActivitiesModalOpen(false)
            }
            reduxSelector='EXPORT_EPD_COLLECTIVE_INVOICING_ACTIVITIES'
            title={I18n.t(
              'collectiveInvoicingTranslations.exportCollectiveInvoicingActivities.modal.title',
            )}
            description_translation_key={
              'asyncExportModalTranslations.descriptionWithFilter'
            }
            logic={exportCollectiveInvoicingActivities({
              ...getExportFilters(),
            })}
            resetOnDispatch={() => {
              setExportEndDate(undefined)
              setExportStartDate(undefined)
            }}
            notice_translation_key={
              getCleanedExportFilters().company ||
              getCleanedExportFilters().tasklog_status
                ? 'asyncExportModalTranslations.dateForm.filterNotice'
                : ''
            }
          >
            <DateForm
              setStartDate={setExportStartDate}
              setEndDate={setExportEndDate}
              labelType={LabelType.ORDER_EXECUTION_DATE}
              overwriteMinDate={moment().subtract(1, 'year').date(1)}
              overwriteMaxDate={moment().date(31)}
            />
          </AsyncExportModal>

          <AsyncExportModal
            isOpen={context.isCollectiveInvoicingModalOpen}
            onClose={() => context.setIsCollectiveInvoicingModalOpen(false)}
            reduxSelector='EXPORT_EPD_COLLECTIVE_INVOICING'
            title={I18n.t(
              'collectiveInvoicingTranslations.exportCollectiveInvoicingCompanies.modal.title',
            )}
            description_translation_key={
              'asyncExportModalTranslations.descriptionWithFilter'
            }
            logic={exportCollectiveInvoicing({ ...getExportFilters() })}
            resetOnDispatch={() => {
              setExportEndDate(undefined)
              setExportStartDate(undefined)
            }}
            notice_translation_key={
              getCleanedExportFilters().company ||
              getCleanedExportFilters().tasklog_status
                ? 'asyncExportModalTranslations.dateForm.filterNotice'
                : ''
            }
          >
            <DateForm
              setStartDate={setExportStartDate}
              setEndDate={setExportEndDate}
              labelType={LabelType.INVOICING_DATE}
              overwriteMinDate={moment().subtract(1, 'year').date(1)}
              overwriteMaxDate={moment().date(31)}
            />
          </AsyncExportModal>

          <Modal
            ariaDescribedBy={idTotalInvoiceModal}
            isOpen={context.isTotalInvoiceModalOpen}
            onClose={() => context.setIsTotalInvoiceModalOpen(false)}
          >
            <ModalHeader
              onClose={() => context.setIsTotalInvoiceModalOpen(false)}
              title={I18n.t(
                'collectiveInvoicingTranslations.totalInvoice.modal.title',
              )}
              titleId={idTotalInvoiceModal}
            />
            <div className='uk-padding'>
              <p className='uk-text'>
                <Translate
                  value='collectiveInvoicingTranslations.totalInvoice.modal.warning'
                  dangerousHTML
                />
              </p>
            </div>
            <div className='uk-modal-footer uk-text-right'>
              {/* CANCEL */}
              <span className='uk-margin-right'>
                <Button
                  backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                  onClick={() => context.setIsTotalInvoiceModalOpen(false)}
                >
                  {I18n.t('general.button.cancel')}
                </Button>
              </span>

              {/* SUBMIT */}
              <Button
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                onClick={() => {
                  dispatch(createTotalCollectiveInvoice())
                  context.setIsTotalInvoiceModalOpen(false)
                }}
                type={BUTTON_TYPE.SUBMIT}
              >
                {I18n.t('general.button.submit')}
              </Button>
            </div>
          </Modal>

          <Modal
            ariaDescribedBy={idTotalInvoiceModal}
            isOpen={!!context.companyIdToInvoice}
            onClose={() => context.setCompanyIdToInvoice(0)}
          >
            <ModalHeader
              onClose={() => context.setCompanyIdToInvoice(0)}
              title={I18n.t(
                'collectiveInvoicingTranslations.invoice.modal.title',
              )}
              titleId={idSingleInvoiceWarningModal}
            />
            <div className='uk-padding'>
              <p className='uk-text'>
                <Translate
                  value='collectiveInvoicingTranslations.invoice.modal.warning'
                  dangerousHTML
                />
              </p>
            </div>
            <div className='uk-modal-footer uk-text-right'>
              {/* CANCEL */}
              <span className='uk-margin-right'>
                <Button
                  backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                  onClick={() => context.setCompanyIdToInvoice(0)}
                >
                  {I18n.t('general.button.cancel')}
                </Button>
              </span>

              {/* SUBMIT */}
              <Button
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                onClick={() => {
                  dispatch(createCollectiveInvoice(context.companyIdToInvoice))
                  context.setCompanyIdToInvoice(0)
                }}
                type={BUTTON_TYPE.SUBMIT}
              >
                {I18n.t('general.button.submit')}
              </Button>
            </div>
          </Modal>
        </>
      )}
    </CollectiveInvoicingPageContext.Consumer>
  )
}
