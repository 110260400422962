import keyMirror from 'keymirror'

const GET_MY_CERTIFICATES = keyMirror({
  GET_MY_CERTIFICATES_REQUEST: null,
  GET_MY_CERTIFICATES_SUCCESS: null,
  GET_MY_CERTIFICATES_ERROR: null,
})

const GET_MY_LATEST_CERTIFICATES = keyMirror({
  GET_MY_LATEST_CERTIFICATES_REQUEST: null,
  GET_MY_LATEST_CERTIFICATES_SUCCESS: null,
  GET_MY_LATEST_CERTIFICATES_ERROR: null,
})

const GET_COMPANY_PRICE_AGREEMENTS = keyMirror({
  GET_COMPANY_PRICE_AGREEMENTS_REQUEST: null,
  GET_COMPANY_PRICE_AGREEMENTS_SUCCESS: null,
  GET_COMPANY_PRICE_AGREEMENTS_ERROR: null,
})

const GET_COMPANY_LATEST_CERTIFICATES = keyMirror({
  GET_COMPANY_LATEST_CERTIFICATES_REQUEST: null,
  GET_COMPANY_LATEST_CERTIFICATES_SUCCESS: null,
  GET_COMPANY_LATEST_CERTIFICATES_ERROR: null,
})

const GET_CERTIFICATES = keyMirror({
  GET_CERTIFICATES_REQUEST: null,
  GET_CERTIFICATES_SUCCESS: null,
  GET_CERTIFICATES_ERROR: null,
})

const GET_CERTIFICATE = keyMirror({
  GET_CERTIFICATE_REQUEST: null,
  GET_CERTIFICATE_SUCCESS: null,
  GET_CERTIFICATE_ERROR: null,
})

const CREATE_CERTIFICATE = keyMirror({
  CREATE_CERTIFICATE_REQUEST: null,
  CREATE_CERTIFICATE_SUCCESS: null,
  CREATE_CERTIFICATE_ERROR: null,
})

const UPDATE_CERTIFICATE = keyMirror({
  UPDATE_CERTIFICATE_REQUEST: null,
  UPDATE_CERTIFICATE_SUCCESS: null,
  UPDATE_CERTIFICATE_ERROR: null,
})

const UPDATE_REVIEWED_CERTIFICATE = keyMirror({
  UPDATE_REVIEWED_CERTIFICATE_REQUEST: null,
  UPDATE_REVIEWED_CERTIFICATE_SUCCESS: null,
  UPDATE_REVIEWED_CERTIFICATE_ERROR: null,
})

const EXPORT_CERTIFICATES = keyMirror({
  EXPORT_CERTIFICATES_REQUEST: null,
  EXPORT_CERTIFICATES_SUCCESS: null,
})

const ADD_CERTIFICATE_TYPE = keyMirror({
  ADD_CERTIFICATE_TYPE_REQUEST: null,
})

const RESET_CERTIFICATE_ITEM = keyMirror({
  RESET_CERTIFICATE_ITEM_REQUEST: null,
})

const ARCHIVE_CERTIFICATE = keyMirror({
  ARCHIVE_CERTIFICATE_REQUEST: null,
  ARCHIVE_CERTIFICATE_SUCCESS: null,
  ARCHIVE_CERTIFICATE_ERROR: null,
})

const GET_ARCHIVED_CERTIFICATES = keyMirror({
  GET_ARCHIVED_CERTIFICATES_REQUEST: null,
  GET_ARCHIVED_CERTIFICATES_SUCCESS: null,
  GET_ARCHIVED_CERTIFICATES_ERROR: null,
})

export default {
  ...GET_MY_CERTIFICATES,
  ...GET_CERTIFICATES,
  ...GET_CERTIFICATE,
  ...GET_MY_LATEST_CERTIFICATES,
  ...GET_COMPANY_PRICE_AGREEMENTS,
  ...GET_COMPANY_LATEST_CERTIFICATES,
  ...GET_ARCHIVED_CERTIFICATES,
  ...CREATE_CERTIFICATE,
  ...UPDATE_CERTIFICATE,
  ...UPDATE_REVIEWED_CERTIFICATE,
  ...EXPORT_CERTIFICATES,
  ...ADD_CERTIFICATE_TYPE,
  ...RESET_CERTIFICATE_ITEM,
  ...ARCHIVE_CERTIFICATE,
}
