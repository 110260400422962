import { shape, string, number, func, bool } from 'prop-types'

import { ControlProps } from '../../schemes'

const ActionsScheme = shape({
  form: shape({}),
})

const TextareaControlProps = {
  ...ControlProps,
  actions: ActionsScheme.isRequired,
  caption: string,
  maxLength: number,
  handleCursorPosition: func,
  getRef: func,
  allowSubmissionByKey: bool,
}

export default TextareaControlProps
