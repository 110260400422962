import './style.scss'

import React from 'react'
import { Translate } from 'react-i18nify'

/**
 * @description Small container for the message that the company is inactive.
 */
const UploadCompanyProofMessage = () => (
  <>
    <p className='upload-company-proof-message--bold'>
      <Translate value='indicationMessageTranslation.uploadCompanyProofMessage.title' />
    </p>

    <p>
      <Translate value='indicationMessageTranslation.uploadCompanyProofMessage.message' />
    </p>
  </>
)

export default UploadCompanyProofMessage
