import 'react-table/react-table.css'

import moment from 'moment/moment'
import { arrayOf, bool, func, number, shape } from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import Spinner from 'react-spinkit'

import { UserPermission } from 'constants/user'
import { AddressSmallScheme } from 'schemes/address'

import Icon from '../../common/Fontello'
import { NoContentMessage } from '../../common/NoContentMessage'
import { PaginatedTable } from '../../common/PaginatedTable'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { Switcher } from '../../common/Switcher'
import { UpDownArrow } from '../../common/UpDownArrow'
import AddAddressButton from '../AddAddressButton'
import { ADDRESS_STATES, ADDRESS_STATUS } from '../constants'

/**
 * @description This component renders table with company addresses.
 * @function
 * @param {Object} props the component props
 */
export class AddressesListComponent extends Component {
  static propTypes = {
    isLoading: bool.isRequired,
    addressList: arrayOf(shape(AddressSmallScheme)),
    page: number,
    pages: number,
    onFetchData: func.isRequired,
    onPreviousPageClick: func.isRequired,
    onNextPageClick: func.isRequired,
    onAddAddress: func.isRequired,
    onEditAddress: func.isRequired,
    onStatusChange: func.isRequired,
  }

  static defaultProps = {
    addressList: [],
    page: null,
    pages: null,
  }

  /**
   * @description Renders an interactive address table
   * @returns {*[]}
   */
  renderAddressTable() {
    const columns = [
      {
        Header: (
          <span>
            <Translate value='myAddresses.table.header.id' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'id',
        width: 60,
      },
      {
        Header: (
          <span>
            <Translate value='myAddresses.table.header.description' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'description',
      },
      {
        id: 'street',
        Header: (
          <span>
            <Translate value='myAddresses.table.header.address' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'display_name',
        Cell: data => (
          <span>
            {data.original.street} {data.original.house_number}
            <br />
            {data.original.zipcode} {data.original.location}
          </span>
        ),
      },
      {
        Header: (
          <span>
            <Translate value='myAddresses.table.header.created_at' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'created_at',
        Cell: data => moment(data.value).format('L LT'),
      },
      {
        Header: (
          <span>
            <Translate value='myAddresses.table.header.status_lastmodified_at' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'status_lastmodified_at',
        Cell: data => moment(data.value).format('L LT'),
      },
      {
        Header: (
          <span>
            <Translate value='myAddresses.table.header.status' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'status',
        Cell: data => (
          <Switcher
            currentValue={ADDRESS_STATES.find(state => state.id === data.value)}
            isActive={data.value === ADDRESS_STATUS.STATUS_ACTIVE}
            onValueChange={value =>
              this.props.onStatusChange(data.original, value.id)
            }
            values={ADDRESS_STATES}
          />
        ),
      },
      {
        id: 'actions',
        Header: I18n.t('myAddresses.table.header.actions'),
        width: 100,
        sortable: false,
        filterable: false,
        Cell: data => (
          <span className='action-buttons'>
            <RequiredPermissions
              requiredPermissions={[UserPermission.CHANGE_ADDRESS]}
            >
              <button
                type='button'
                className='icon-button'
                onClick={() => this.props.onEditAddress(data.original)}
                data-uk-tooltip={`title: ${I18n.t('general.editButton')}`}
                disabled={data.original.status !== ADDRESS_STATUS.STATUS_ACTIVE}
              >
                <Icon name='pencil' />
              </button>
            </RequiredPermissions>
          </span>
        ),
      },
    ]
    return (
      <PaginatedTable
        serverSidePagination
        page={this.props.page}
        pages={this.props.pages}
        loading={this.props.isLoading}
        handleShowPreviousPage={this.props.onPreviousPageClick}
        handleShowNextPage={this.props.onNextPageClick}
        handleChangeStatus={this.props.onStatusChange}
        handleEditAddress={this.props.onEditAddress}
        table={{
          columns,
          data: this.props.addressList,
          onFetchData: this.props.onFetchData,
        }}
      />
    )
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    const { onAddAddress, isLoading, addressList } = this.props
    return (
      <div className='address-list'>
        {/* Loading Indicator */}
        {isLoading && !addressList.length && (
          <div className='uk-flex uk-flex-center uk-margin-large-top'>
            <Spinner name='circle' />
          </div>
        )}

        {/* Table */}
        {addressList.length > 0 && (
          <div className='uk-margin-large-top'>{this.renderAddressTable()}</div>
        )}

        {/* Empty message */}
        {!isLoading && addressList.length < 1 && (
          <NoContentMessage
            message={I18n.t('myAddresses.emptyResultSet')}
            createButtonComponent={<AddAddressButton onClick={onAddAddress} />}
            showResetFilterButton={false}
          />
        )}
      </div>
    )
  }
}

export default AddressesListComponent
