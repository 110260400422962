import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import ActionTypes from '../actiontypes/attachment'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleUploadFiles
 * @description uploads a file or a list of files to the attachments api.
 */
export const handleUploadFiles = createLogic({
  type: ActionTypes.UPLOAD_FILES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const apiCalls = action.payload.files.map(item => {
      // Files must be sent via multipart form, so we need to construct the request like this.
      const data = new FormData()
      // some hacky trick because sometimes the file is item.file or the item itself...
      data.append('file', item.file || item.data || item)
      // if the item itself is a file there is a proto method called text which causes errors!
      if (typeof item.text !== 'function') {
        data.append('text', item.text)
      }
      if (typeof item.orientation !== 'undefined') {
        data.append('orientation', item.orientation || 0)
      } else if (action.payload.callbacks) {
        data.append('orientation', action.payload.callbacks.orientation || 0)
      } else {
        data.append('orientation', 0)
      }

      return httpClient
        .post(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/attachment/`, data)
        .then(res => res.data)
        .then(res => {
          dispatch({
            type: ActionTypes.UPLOAD_FILES_SUCCESS,
            payload: res,
          })
          if (
            action.payload.callbacks &&
            action.payload.callbacks.updateAccount
          ) {
            action.payload.callbacks.updateAccount(res.id)
          }
          return res
        })
    })

    // apiCalls might be undefined
    if ((apiCalls?.length ?? -1) <= 0) {
      dispatch({
        type: ActionTypes.UPLOAD_FILES_SUCCESS,
        payload: {},
      })
    }

    return Promise.all(apiCalls)
      .then(attachmentIds => {
        if (action.payload.callbacks) {
          if (action.payload.callbacks.createInquiry)
            action.payload.callbacks.createInquiry(attachmentIds)
          if (action.payload.callbacks.onDocumentSubmit)
            action.payload.callbacks.onDocumentSubmit(attachmentIds)
        }
        done()
      })
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.attachment.handleUploadFiles.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.UPLOAD_FILES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [handleUploadFiles]
