import './style.scss'

import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { CallToActionType } from 'components/common/DetailsPage/constants'

interface CallToActionProps {
  callToAction: CallToActionType
  handleCtaToggle?: (ctaOpen?: boolean) => void
  isSmallScreen?: boolean
  hideMoreInfo?: boolean
  renameBtnToAction?: boolean
  closeButtonTranslationKey?: string
}

/**
 * CallToAction Component used to display the different possible CallToAction versions inside the common container.
 * Always includes a headline and teaser and optionally one or more buttons to trigger actions.
 */
export const CallToAction: FC<CallToActionProps> = ({
  callToAction,
  handleCtaToggle = () => undefined,
  isSmallScreen = false,
  hideMoreInfo = false,
  renameBtnToAction = false,
  closeButtonTranslationKey = undefined,
}) => (
  <>
    <div className='header__call-to-action'>
      {callToAction!.header}
      {callToAction!.content}
    </div>
    {callToAction!.buttonsOrFields}
    {isSmallScreen && !hideMoreInfo && (
      <Button
        backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
        onClick={() => handleCtaToggle()}
        key='inquiry-details-more-less-button'
        fullWidth
        dataTestId='inquiry-details-more-less-button'
      >
        <Translate
          value={
            closeButtonTranslationKey ??
            `inquiryDetailsTranslations.cta.buttons.${
              !renameBtnToAction ? 'closeInfo' : 'closeAction'
            }`
          }
        />
      </Button>
    )}
  </>
)
