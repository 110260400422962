import addressLogic from './address'
import attachmentLogic from './attachment'
import autoOfferLogic from './autooffer'
import certificateLogic from './certificate'
import communityLogic from './community'
import companyLogic from './company'
import containerLogic from './container'
import coreLogic from './core'
import countryLogic from './country'
import dashboardLogic from './dashboard'
import { demoLogic } from './demo'
import discountLogic from './discount'
import dszLogic from './dsz'
import emptoDownloadsLogic from './emptodownloads'
import emptoInternalDownloadsLogic from './emptointernaldownloads'
import executionProofLogic from './executionproof'
import fractionLogic from './fraction'
import holidayLogic from './holiday'
import indexesLogic from './indexes'
import inquiryLogic from './inquiry'
import invoiceLogic from './invoice'
import landingPageLogic from './landingpage'
import maklerpremiumLogic from './maklerpremium'
import offerLogic from './offer'
import orderLogic from './order'
import orderClaimLogic from './orderclaim'
import securityGroupLogic from './securitygroup'
import userLogic from './user'
import workloadLogic from './workload'
import zipcodeLogic from './zipcode'

/**
 * @description A constant array of all logics.
 */
const rootLogic = [
  ...addressLogic,
  ...attachmentLogic,
  ...autoOfferLogic,
  ...certificateLogic,
  ...communityLogic,
  ...companyLogic,
  ...containerLogic,
  ...coreLogic,
  ...countryLogic,
  ...dashboardLogic,
  ...demoLogic,
  ...discountLogic,
  ...dszLogic,
  ...emptoDownloadsLogic,
  ...emptoInternalDownloadsLogic,
  ...executionProofLogic,
  ...fractionLogic,
  ...holidayLogic,
  ...indexesLogic,
  ...inquiryLogic,
  ...invoiceLogic,
  ...landingPageLogic,
  ...maklerpremiumLogic,
  ...offerLogic,
  ...orderClaimLogic,
  ...orderLogic,
  ...securityGroupLogic,
  ...userLogic,
  ...workloadLogic,
  ...zipcodeLogic,
]

export default rootLogic
