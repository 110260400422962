import { Formik } from 'formik'
import React, { FC, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { resetApiFetchLoading } from 'actions/app'
import { createDszFractionPrice, updateDszFractionPrice } from 'actions/dsz'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import withErrorBoundary from 'helper/withErrorBoundary'
import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'
import { getCurrentUserSelector } from 'selectors/user'

import { AddPriceModalForm, AddPriceModalFormValues } from './index'

interface AddPriceModalFormikWrapperComponentProps {
  handleCloseModal: () => void
  editingInstance: DszFractionPrice | undefined
}

const AddPriceModalFormikWrapperComponent: FC<
  AddPriceModalFormikWrapperComponentProps
> = ({ handleCloseModal, editingInstance }) => {
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUserSelector)
  const requestStatus = useSelector(
    createRequestStatusSelector([
      'CREATE_DSZ_FRACTION_PRICE',
      'UPDATE_DSZ_FRACTION_PRICE',
    ]),
  )

  useEffect(() => {
    if (requestStatus === REQUEST_STATUS.SUCCESS) {
      if (editingInstance)
        dispatch(resetApiFetchLoading('UPDATE_DSZ_FRACTION_PRICE'))
      else dispatch(resetApiFetchLoading('CREATE_DSZ_FRACTION_PRICE'))
      handleCloseModal()
    }
  }, [dispatch, editingInstance, handleCloseModal, requestStatus])

  let initialValues: AddPriceModalFormValues
  if (editingInstance) {
    initialValues = {
      fraction: editingInstance.fraction_id,
      price_xs: `${editingInstance.price.price_xs}`,
      price_s: `${editingInstance.price.price_s}`,
      price_m: `${editingInstance.price.price_m}`,
      price_l: `${editingInstance.price.price_l}`,
      price_xl: `${editingInstance.price.price_xl}`,
      price_xxl: `${editingInstance.price.price_xxl}`,
      shop: editingInstance.shop,
      year: editingInstance.year,
    }
  } else
    initialValues = {
      fraction: undefined,
      price_xs: '',
      price_s: '',
      price_m: '',
      price_l: '',
      price_xl: '',
      price_xxl: '',
      shop: undefined,
      year: '',
    }

  const yup_price_object = yup
    .string()
    .required(
      I18n.t(
        'priceManagementPageTranslations.addPriceModal.modalFields.error.price',
      ),
    )
    .test(
      'notNull',
      I18n.t(
        'priceManagementPageTranslations.addPriceModal.modalFields.error.price',
      ),
      value => value !== '0',
    )

  const convertPriceValue = (price: string) => Number(price.replace(',', '.'))

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: AddPriceModalFormValues, formikProps) => {
        // Send
        if (editingInstance) {
          const formatted_values = {
            id: editingInstance.id,
            price_xs: convertPriceValue(values.price_xs),
            price_s: convertPriceValue(values.price_s),
            price_m: convertPriceValue(values.price_m),
            price_l: convertPriceValue(values.price_l),
            price_xl: convertPriceValue(values.price_xl),
            price_xxl: convertPriceValue(values.price_xxl),
          }
          dispatch(updateDszFractionPrice(formatted_values))
        } else {
          const formatted_values = {
            created_by: user.id,
            fraction: values.fraction,
            lastmodified_by: user.id,
            price_xs: convertPriceValue(values.price_xs),
            price_s: convertPriceValue(values.price_s),
            price_m: convertPriceValue(values.price_m),
            price_l: convertPriceValue(values.price_l),
            price_xl: convertPriceValue(values.price_xl),
            price_xxl: convertPriceValue(values.price_xxl),
            shop: values.shop,
            year: values.year,
          }
          dispatch(createDszFractionPrice(formatted_values))
        }
        formikProps.setSubmitting(false)
      }}
      validationSchema={() =>
        yup.object().shape({
          fraction: yup
            .number()
            .required(
              I18n.t(
                'priceManagementPageTranslations.addPriceModal.modalFields.error.fraction',
              ),
            ),
          price_xs: yup_price_object,
          price_s: yup_price_object,
          price_m: yup_price_object,
          price_l: yup_price_object,
          price_xl: yup_price_object,
          price_xxl: yup_price_object,
          shop: yup
            .number()
            .required(
              I18n.t(
                'priceManagementPageTranslations.addPriceModal.modalFields.error.shop',
              ),
            ),
          year: yup
            .number()
            .min(
              2019,
              I18n.t(
                'priceManagementPageTranslations.addPriceModal.modalFields.error.year',
              ),
            )
            .max(
              2050,
              I18n.t(
                'priceManagementPageTranslations.addPriceModal.modalFields.error.year',
              ),
            )
            .required(
              I18n.t(
                'priceManagementPageTranslations.addPriceModal.modalFields.error.year',
              ),
            ),
        })
      }
      // Set all values to touched if an editingInstance is given to trigger validation
      initialTouched={
        editingInstance
          ? {
              fraction: true,
              price_xs: true,
              price_s: true,
              price_m: true,
              price_l: true,
              price_xl: true,
              price_xxl: true,
              shop: true,
              year: true,
            }
          : undefined
      }
    >
      <AddPriceModalForm
        handleCloseModal={handleCloseModal}
        isEditing={!!editingInstance}
      />
    </Formik>
  )
}

export const AddPriceModalFormikWrapper: FC<AddPriceModalFormikWrapperComponentProps> =
  withErrorBoundary(withApiErrorHandling(AddPriceModalFormikWrapperComponent))
