import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import {
  EmptoGroups,
  MaklerPremiumGroups,
  UserPermission,
} from 'constants/user'
import { checkRequiredPermissions } from 'helper/permissions'
import { getCurrentUserSelector } from 'selectors/user'

import { NotFoundComponent } from '../RoutesHandler/components/NotFoundComponent'

interface RequiredPermissionsProps {
  notEmpto?: boolean
  notMaklerPremium?: boolean
  requiredPermissions: UserPermission[]
  showErrorPage?: boolean
}

export const RequiredPermissions: FC<RequiredPermissionsProps> = ({
  children,
  notEmpto = false,
  notMaklerPremium = false,
  requiredPermissions,
  showErrorPage = false,
}) => {
  const user = useSelector<any, User>(state => getCurrentUserSelector(state))

  if (
    !checkRequiredPermissions(user.permission_codenames, requiredPermissions) ||
    (notEmpto && user.group_id in EmptoGroups) ||
    (notMaklerPremium && user.group_id in MaklerPremiumGroups)
  ) {
    return showErrorPage ? <NotFoundComponent /> : null
  }
  return <>{children}</>
}
