import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

import { FilesViewer } from 'components/common/FilesViewer'
import Icon from 'components/common/Fontello'
import { SectionHeader } from 'components/common/SectionHeader'

interface InvoiceCheckOverviewInvoiceDataProps {
  invoiceCheck?: InvoiceCheck
}

/**
 * @description This component displays a list of documents attached to an InvoiceCheck.
 * @function
 */
export const InvoiceCheckOverviewDocuments: FC<
  InvoiceCheckOverviewInvoiceDataProps
> = ({ invoiceCheck = null }) => {
  if (!invoiceCheck) return null

  return (
    <section className='uk-card uk-padding-remove'>
      <SectionHeader>
        <Icon name='to-do' />
        <Translate value='invoiceCheckOverviewTranslations.section_header.document' />
      </SectionHeader>
      <FilesViewer
        files={invoiceCheck.attachments}
        showPreview={false}
        withFilenameTooltip
      />
    </section>
  )
}
