import './style.scss'

import { useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'

import { getHolidays } from 'actions/holiday'
import {
  COLLECTION_DATE_AHEAD_WEEKS,
  TURN_END_MAXIMUM_YEARS,
  WEEKDAY,
} from 'constants/app'
import { BREAKPOINT } from 'constants/design'
import { getHolidaysSelector } from 'selectors/holiday'

import {
  INQUIRY_ORDER_TYPE,
  INQUIRY_ORDER_TYPES,
  INQUIRY_SERVICE_INTERVAL,
  INQUIRY_SERVICE_INTERVAL_WEEKDAYS,
  INQUIRY_SERVICE_INTERVALS,
  INQUIRY_TIME_OF_DAY,
  INQUIRY_TIMES_OF_DAY,
} from '../../inquiry/constants'
import { DismissableInfo } from '../DismissableInfo'
import { DropDownInput } from '../DropDownInput'
import InputDate from '../InputDate'
import { TOOLTIP_THEME } from '../Tooltip'

import { filterDate } from './helpers'

export type OrderTypeDetailsValues = {
  collection_date: string
  delivery_date: string
  interval?: INQUIRY_SERVICE_INTERVAL | ''
  interval_weekday_first?: WEEKDAY | ''
  interval_weekday_second?: WEEKDAY | ''
  order_type?: INQUIRY_ORDER_TYPE
  time_of_day_collection: string
  time_of_day_delivery: string
  rythm_begin: string
  turn_begin: string
  turn_end: string
}

interface OrderTypeDetailsGroupProps {
  showRentTimeExtensionWarning?: boolean
  deliveryMinDate?: any
  collectionMinDate?: any
  collectionMaxDate?: any
  turnBeginMinDate?: any
  turnEndMinDate?: any
  turnEndMaxDate?: any
  isInquiryForm?: boolean
  useCollectionDateAheadWeeks?: boolean
  holidayRestrictionEnabled?: boolean
}

/**
 * @description: Functional component to render the order details Group
 * @returns {*}
 * @constructor
 */
export const OrderTypeDetailsGroup: FC<OrderTypeDetailsGroupProps> = ({
  showRentTimeExtensionWarning,
  deliveryMinDate = moment(),
  collectionMinDate = moment(),
  collectionMaxDate = moment(),
  turnBeginMinDate = moment(),
  turnEndMinDate = moment(),
  turnEndMaxDate = moment(),
  isInquiryForm,
  useCollectionDateAheadWeeks = true,
  holidayRestrictionEnabled = false,
}) => {
  const dispatch = useDispatch()
  const { errors, handleBlur, handleChange, setFieldValue, values } =
    useFormikContext<OrderTypeDetailsValues>()

  const holidays = useSelector(getHolidaysSelector)

  const differenceInDays = moment(values.collection_date).diff(
    moment(values.delivery_date),
    'days',
  )
  const showRentTimeExtensionWeeks = Math.max(
    Math.ceil((differenceInDays - 14) / 7),
    0,
  )

  const intervalChoices = INQUIRY_SERVICE_INTERVAL_WEEKDAYS.map(item => ({
    optionValue: item.id,
    optionLabel: `${I18n.t(item.name)}`,
  }))

  let inquiryOrderTypeChoices = INQUIRY_ORDER_TYPES.map(item => ({
    optionValue: item.id,
    optionLabel: `${I18n.t(item.name)}`,
  }))

  if (!isInquiryForm) {
    inquiryOrderTypeChoices = inquiryOrderTypeChoices.filter(
      choice => choice.optionValue !== INQUIRY_ORDER_TYPE.TYPE_QUICK,
    )
  }

  const [intervalSecondChoices, setIntervalSecondChoices] =
    useState(intervalChoices)

  const isTruthyOrEmpty = val => val === '' || !!val

  useEffect(
    () => {
      if (holidayRestrictionEnabled) {
        dispatch(getHolidays())
      }
    },
    [], // eslint-disable-line
  )

  return (
    <>
      {/* ORDER TYPE */}
      <DropDownInput
        choices={inquiryOrderTypeChoices}
        label={I18n.t('orderTypeDetailsGroupTranslations.form.label.orderType')}
        name='order_type'
        onBlur={handleBlur}
        onChange={(event: React.ChangeEvent<any>) => {
          setFieldValue('order_type', Number(event.target.value))
          setFieldValue('delivery_date', '')
          setFieldValue('collection_date', '')
          setFieldValue('rythm_begin', '')
          setFieldValue('turn_begin', '')
          setFieldValue('turn_end', '')
          setFieldValue('interval', '')
          setFieldValue('interval_weekday_first', '')
          setFieldValue('interval_weekday_second', '')
          setFieldValue(
            'time_of_day_delivery',
            String(INQUIRY_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY),
          )
          setFieldValue(
            'time_of_day_collection',
            String(INQUIRY_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY),
          )
        }}
        placeholder={I18n.t(
          'orderTypeDetailsGroupTranslations.form.placeholder.orderType',
        )}
        value={values.order_type}
        tooltip={{
          description: I18n.t(
            'orderTypeDetailsGroupTranslations.form.tooltip.orderType',
          ),
          theme: TOOLTIP_THEME.LIGHT,
          tooltipId: 'orderType',
        }}
        withCheckmark
        showCheckmark={!!values.order_type && !errors.order_type}
      />
      {values.order_type && (
        <Media query={{ maxWidth: BREAKPOINT.LARGE }}>
          {smallView => (
            <div className='order-type-details-group'>
              {(Number(values.order_type) ===
                INQUIRY_ORDER_TYPE.TYPE_ONE_TIME ||
                Number(values.order_type) ===
                  INQUIRY_ORDER_TYPE.TYPE_QUICK) && (
                <div className='elements-container'>
                  <InputDate
                    label={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.label.deliveryDate',
                    )}
                    minDate={deliveryMinDate}
                    name='delivery_date'
                    // needed to reset the collection_date when delivery_date changes
                    onChange={value => {
                      setFieldValue('delivery_date', value)
                      if (
                        moment(values.collection_date, 'L').isBefore(
                          moment(value, 'L'),
                        )
                      ) {
                        setFieldValue('collection_date', '')
                      }
                    }}
                    placeholder={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.placeholder.deliveryDate',
                    )}
                    popperPlacement='top-start'
                    value={values.delivery_date}
                    withCheckmark={smallView}
                    showCheckmark={
                      smallView &&
                      !!values.delivery_date &&
                      !errors.delivery_date
                    }
                    filterDate={date =>
                      filterDate(holidays, date, holidayRestrictionEnabled)
                    }
                  />
                  {/* Time of day delivery */}
                  <DropDownInput
                    choices={INQUIRY_TIMES_OF_DAY.map(item => ({
                      optionValue: item.id,
                      optionLabel: `${I18n.t(item.name)}`,
                    }))}
                    label={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.label.timeOfDayDelivery',
                    )}
                    name='time_of_day_delivery'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={I18n.t('general.timeOfDay')}
                    value={
                      values.time_of_day_delivery !== ''
                        ? values.time_of_day_delivery
                        : String(INQUIRY_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY)
                    }
                    withCheckmark
                    showCheckmark={
                      !smallView &&
                      !!values.delivery_date &&
                      !errors.delivery_date
                    }
                  />
                </div>
              )}

              {Number(values.order_type) ===
                INQUIRY_ORDER_TYPE.TYPE_ONE_TIME && (
                <div className='elements-container'>
                  {/* Collection date */}
                  <InputDate
                    label={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.label.collectionDate',
                    )}
                    minDate={
                      values.delivery_date
                        ? moment(values.delivery_date, 'L')
                        : collectionMinDate
                    }
                    maxDate={
                      useCollectionDateAheadWeeks
                        ? (values.delivery_date
                            ? moment(values.delivery_date, 'L')
                            : (collectionMaxDate as moment.Moment)
                          ).add(COLLECTION_DATE_AHEAD_WEEKS, 'weeks')
                        : (collectionMaxDate as moment.Moment)
                    }
                    name='collection_date'
                    onChange={value => setFieldValue('collection_date', value)}
                    placeholder={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.placeholder.collectionDate',
                    )}
                    popperPlacement='top-start'
                    value={values.collection_date}
                    withCheckmark={smallView}
                    showCheckmark={
                      smallView &&
                      !!values.collection_date &&
                      !errors.collection_date
                    }
                    filterDate={date =>
                      filterDate(holidays, date, holidayRestrictionEnabled)
                    }
                  />
                  {/* Time of day collection */}
                  <DropDownInput
                    choices={INQUIRY_TIMES_OF_DAY.map(item => ({
                      optionValue: item.id,
                      optionLabel: `${I18n.t(item.name)}`,
                    }))}
                    label={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.label.timeOfDayCollection',
                    )}
                    name='time_of_day_collection'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={I18n.t('general.timeOfDay')}
                    value={
                      values.time_of_day_collection !== ''
                        ? values.time_of_day_collection
                        : String(INQUIRY_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY)
                    }
                    withCheckmark
                    showCheckmark={
                      !smallView &&
                      !!values.collection_date &&
                      !errors.collection_date
                    }
                  />
                </div>
              )}
              {showRentTimeExtensionWarning &&
                showRentTimeExtensionWeeks >= 1 && (
                  <DismissableInfo
                    className='note_for_additional_rent_cost'
                    title={I18n.t('general.information')}
                    text={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.note_rent_time_extension',
                    )}
                    buttonText={I18n.t('general.button.done')}
                  />
                )}
              {(Number(values.order_type) ===
                INQUIRY_ORDER_TYPE.TYPE_RECURRING ||
                Number(values.order_type) ===
                  INQUIRY_ORDER_TYPE.TYPE_ON_DEMAND) && (
                <div className='elements-container'>
                  <InputDate
                    label={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.label.agreementBegin',
                    )}
                    minDate={turnBeginMinDate}
                    name='turn_begin'
                    onChange={value => {
                      setFieldValue('turn_begin', value)
                      setFieldValue('rythm_begin', undefined)
                      if (
                        moment(values.turn_end, 'L').isBefore(
                          moment(value, 'L'),
                        )
                      ) {
                        setFieldValue('turn_end', '')
                      }
                    }}
                    placeholder={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.placeholder.agreementBegin',
                    )}
                    popperPlacement='top-start'
                    value={values.turn_begin}
                    withCheckmark={smallView}
                    showCheckmark={
                      smallView && !!values.turn_begin && !errors.turn_begin
                    }
                    filterDate={date =>
                      filterDate(holidays, date, holidayRestrictionEnabled)
                    }
                  />
                  {/* Turn end date */}
                  <InputDate
                    label={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.label.agreementEnd',
                    )}
                    minDate={
                      values.turn_begin
                        ? moment(values.turn_begin, 'L')
                        : turnEndMinDate
                    }
                    maxDate={(values.turn_begin
                      ? moment(values.turn_begin, 'L')
                      : turnEndMaxDate
                    ).add(TURN_END_MAXIMUM_YEARS, 'years')}
                    name='turn_end'
                    onChange={value => {
                      setFieldValue('turn_end', value)
                      setFieldValue('rythm_begin', undefined)
                    }}
                    placeholder={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.placeholder.agreementEnd',
                    )}
                    popperPlacement='top-start'
                    value={values.turn_end}
                    withCheckmark
                    showCheckmark={
                      !smallView
                        ? !!values.turn_begin &&
                          !!values.turn_end &&
                          !errors.turn_begin &&
                          !errors.turn_end
                        : !!values.turn_end && !errors.turn_end
                    }
                    filterDate={date =>
                      filterDate(holidays, date, holidayRestrictionEnabled)
                    }
                  />
                </div>
              )}
              {/* Interval */}
              {Number(values.order_type) ===
                INQUIRY_ORDER_TYPE.TYPE_RECURRING && (
                <div className='elements-container'>
                  <DropDownInput
                    choices={INQUIRY_SERVICE_INTERVALS.map(item => ({
                      optionValue: item.id,
                      optionLabel: `${I18n.t(item.name)}`,
                    }))}
                    label={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.label.interval',
                    )}
                    name='interval'
                    onBlur={handleBlur}
                    onChange={event => {
                      setFieldValue('interval_weekday_second', undefined)
                      setFieldValue('rythm_begin', undefined)
                      handleChange(event)
                    }}
                    placeholder={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.placeholder.interval',
                    )}
                    value={values.interval}
                    withCheckmark={smallView}
                    showCheckmark={
                      smallView && !!values.interval && !errors.interval
                    }
                  />
                  {/* Weekdays */}
                  {Number(values.interval) ===
                    INQUIRY_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK && (
                    <div className='elements-container__stacked-items'>
                      <DropDownInput
                        choices={intervalChoices}
                        isPlaceholderClickable={isInquiryForm}
                        label={I18n.t(
                          'orderTypeDetailsGroupTranslations.form.label.intervalWeekdayFirst',
                        )}
                        name='interval_weekday_first'
                        onBlur={handleBlur}
                        onChange={e => {
                          setFieldValue('interval_weekday_second', '')
                          setFieldValue('rythm_begin', undefined)
                          setIntervalSecondChoices(
                            intervalChoices.filter(
                              item => `${item.optionValue}` !== e.target.value,
                            ),
                          )
                          handleChange(e)
                        }}
                        placeholder={I18n.t('general.allTheSame')}
                        value={values.interval_weekday_first}
                        withCheckmark
                        showCheckmark={
                          !smallView &&
                          isTruthyOrEmpty(values.interval_weekday_first) &&
                          !errors.interval_weekday_first
                        }
                      />
                      <DropDownInput
                        choices={intervalSecondChoices}
                        isPlaceholderClickable={isInquiryForm}
                        label={I18n.t(
                          'orderTypeDetailsGroupTranslations.form.label.intervalWeekdaySecond',
                        )}
                        name='interval_weekday_second'
                        onBlur={handleBlur}
                        onChange={event => {
                          setFieldValue('rythm_begin', undefined)
                          handleChange(event)
                        }}
                        placeholder={I18n.t('general.allTheSame')}
                        value={values.interval_weekday_second}
                        withCheckmark
                        showCheckmark={
                          !smallView &&
                          isTruthyOrEmpty(values.interval_weekday_second) &&
                          !errors.interval_weekday_second
                        }
                      />
                    </div>
                  )}
                  {Number(values.interval) !==
                    INQUIRY_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK && (
                    <DropDownInput
                      choices={intervalChoices}
                      isPlaceholderClickable={isInquiryForm}
                      label={I18n.t(
                        'orderTypeDetailsGroupTranslations.form.label.intervalWeekdayFirst',
                      )}
                      name='interval_weekday_first'
                      onBlur={handleBlur}
                      onChange={event => {
                        setFieldValue('rythm_begin', undefined)
                        handleChange(event)
                      }}
                      placeholder={I18n.t('general.allTheSame')}
                      value={values.interval_weekday_first}
                      withCheckmark
                      showCheckmark={
                        !smallView &&
                        isTruthyOrEmpty(values.interval) &&
                        !errors.interval
                      }
                    />
                  )}
                </div>
              )}
              {/* Rythm */}
              {values.order_type &&
                values.turn_begin &&
                values.turn_end &&
                Number(values.order_type) ===
                  INQUIRY_ORDER_TYPE.TYPE_RECURRING && (
                  <InputDate
                    label={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.label.rythmBegin',
                    )}
                    minDate={values.turn_begin}
                    maxDate={values.turn_end}
                    name='rythm_begin'
                    onChange={value => {
                      setFieldValue('rythm_begin', value)
                      if (
                        moment(value, 'L').isBefore(
                          moment(values.turn_begin, 'L'),
                        ) ||
                        moment(value, 'L').isAfter(moment(values.turn_end, 'L'))
                      ) {
                        setFieldValue('rythm_begin', '')
                      }
                    }}
                    placeholder={I18n.t(
                      'orderTypeDetailsGroupTranslations.form.placeholder.rythmBegin',
                    )}
                    popperPlacement='top-start'
                    value={values.rythm_begin}
                    withCheckmark
                    showCheckmark={
                      !smallView && !!values.rythm_begin && !errors.rythm_begin
                    }
                    filterDate={date =>
                      filterDate(
                        holidays,
                        date,
                        holidayRestrictionEnabled,
                        values.interval_weekday_first,
                        values.interval_weekday_second,
                        true,
                      )
                    }
                  />
                )}
            </div>
          )}
        </Media>
      )}
    </>
  )
}
