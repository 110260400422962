import { CompanyArchivePage } from '../components/company/CompanyArchivePage'
import { CompanyInfoPage } from '../components/company/CompanyInfoPage'
import CompanyProfilePage from '../components/company/CompanyProfilePage'
import { MyAddressesPage } from '../components/company/MyAddressesPage'
import IndexValuesPage from '../components/indexvalues'
import { UserPermission } from '../constants/user'

/**
 * @constant
 * @description A constant to define the routes for the company module.
 */
export const companyRoutes = [
  {
    path: '/company/profile',
    component: CompanyProfilePage,
    exact: true,
  },
  {
    path: '/company/profile/:companyId',
    component: CompanyProfilePage,
    requiredPermissions: [
      UserPermission.VIEW_MAKLER_PREMIUM_PARTNER_BASE_DATA,
      UserPermission.VIEW_MAKLER_PREMIUM_CUSTOMER_BASE_DATA,
    ],
    exact: true,
    showErrorPage: true,
  },
  {
    path: '/company/:companyId',
    component: CompanyInfoPage,
    exact: true,
  },
  {
    path: '/company/:companyId/archive',
    component: CompanyArchivePage,
    exact: true,
  },
  {
    path: '/address/mine',
    component: MyAddressesPage,
    exact: true,
  },
  {
    path: '/address/:companyId',
    component: MyAddressesPage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_MAKLER_PREMIUM_COMPANY_ADDRESS],
  },
  {
    path: '/index/values',
    component: IndexValuesPage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_INDEXVALUES],
    showErrorPage: true,
  },
]

export default {}
