/**
 * Color values from _variables.scss
 * @type {{JADE: string, GREEN: string, BUTTERCUP: string}}
 */
export enum COLOR {
  BUTTERCUP = '#f39c12',
  GREEN = '#90b332',
  JADE = '#00cc66',
}

/**
 * Breakpoints
 */
export enum BREAKPOINT {
  SMALL = 480,
  MEDIUM = 768,
  LARGE = 960,
  XLARGE = 1200,
}
