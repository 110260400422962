import './style.scss'

import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import Icon, { ICON_NAME } from 'components/common/Fontello'

export interface HeaderProps {
  logoUrl: string
  certificates: string[]
  hotline: {
    text: string
    number: string
  }
  disposerDetails: Pick<
    OrderForDisposerManagement,
    | 'disposer_name'
    | 'disposer_address'
    | 'disposer_address_street'
    | 'disposer_logo'
  >
}

export const Header: FC<HeaderProps> = ({
  logoUrl,
  certificates,
  hotline,
  disposerDetails,
}) => {
  return (
    <div className='landingpage-header color-scheme__entsorgung-de'>
      <div className='landingpage-header__content'>
        <div className='landingpage-header__divider'>
          <div className='uk-container uk-container-large'>
            <div className='landingpage-header__content__certificates'>
              <img
                className='landingpage-header__logo backgroundColor'
                src={logoUrl}
                alt='logo'
              />
              <div className='landingpage-header__content__certificates__spacer' />
              <div className='landingpage-header__content__certificates__container'>
                {certificates.map((certificate, index) => (
                  <span key={`certificate-${index}`}>
                    <Icon name={ICON_NAME.CHECKMARK} /> {certificate}
                  </span>
                ))}
              </div>
              <span className='landingpage-header__content__certificates__spacer landingpage-header__content__certificates__hotline'>
                {hotline.text} <b>{hotline.number}</b>
              </span>
            </div>
          </div>
        </div>
        <div className='landingpage-header__divider'>
          <div className='uk-container uk-container-large'>
            <div className='landingpage-header__content__user-information'>
              <div>
                <p className='no-margin'>
                  <b>{disposerDetails.disposer_name}</b>
                </p>
                <p className='no-margin'>
                  {disposerDetails.disposer_address_street}
                </p>
                <p className='no-margin'>{disposerDetails.disposer_address}</p>
              </div>
              {disposerDetails.disposer_logo ? (
                <img
                  alt={disposerDetails.disposer_name}
                  src={disposerDetails.disposer_logo.thumbnail}
                  className='landingpage-header__content__user-information__logo'
                />
              ) : (
                <div className='landingpage-header__content__user-information__no-logo'>
                  {/* Disable the link until we have an actual page to upload the logo */}
                  <a
                    href='#@'
                    className='landingpage-header__content__user-information__logo__no-logo'
                  >
                    <span>
                      <Icon name={ICON_NAME.PHOTO} />
                    </span>
                    <span>
                      {I18n.t(
                        'WasteDisposerOrderManagementPageTranslations.header.uploadLogo',
                      )}
                    </span>
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
