import { forbidExtraProps } from 'airbnb-prop-types'
import classNames from 'classnames'
import { bool, node, number, oneOfType } from 'prop-types'
import React from 'react'

/**
 * @description This component renders a accordion
 */
const Accordion = ({ active, children, collapsible, multiple }) => {
  const ulProps = {
    className: classNames({
      accordion: !collapsible,
      'accordion--collapsible': collapsible,
    }),
  }

  if (collapsible) {
    ulProps['data-uk-accordion'] = `
      active: ${active};
      collapsible: true;
      multiple: ${multiple ? 'true' : 'false'};
    `
  }

  return <ul {...ulProps}>{children}</ul>
}

Accordion.propTypes = forbidExtraProps({
  active: oneOfType([number, bool]),
  children: node.isRequired,
  collapsible: bool,
  multiple: bool,
})

Accordion.defaultProps = {
  active: false,
  collapsible: false,
  multiple: false,
}

export default Accordion
