import { useFormikContext } from 'formik'
import React, { useContext } from 'react'
import { I18n } from 'react-i18nify'

import { FindCompanyByFilterInput } from 'components/common/FindByFilterInput'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { COMPANY_ROLE } from 'components/company/constants'

import {
  TVPContractFormContext,
  TVPContractFormValues,
} from '../TVPContractFormSteps'

export const CustomerInfoForm = () => {
  const { errors, handleBlur, setFieldValue, values } =
    useFormikContext<TVPContractFormValues>()
  const mapCompaniesToOptions = companies =>
    companies.map(company => ({
      label: `${company.name}, ${company.main_address_object.zipcode}, ${company.main_address_object.location}`,
      value: company.id,
    }))
  const { stepStatus, saveStep } = useContext(TVPContractFormContext)

  return (
    <>
      <div className='maklerpremium-offerform__column-2'>
        <FindCompanyByFilterInput
          resultType={COMPANY_ROLE.WASTE_PRODUCER}
          label={I18n.t(
            'TVPContractPageTranslations.steps.1.fields.company.label',
          )}
          mapOptionLabel={mapCompaniesToOptions}
          name='company_and_address'
          noOptionsMessage={I18n.t(
            'TVPContractPageTranslations.steps.1.fields.company.noOptionsMessage',
          )}
          placeholder={I18n.t(
            'TVPContractPageTranslations.steps.1.fields.company.placeholder',
          )}
          handleBlur={handleBlur}
          handleSelectionChange={(value, companies) => {
            const company = companies.find(item => item.id === value)
            setFieldValue('company_id', company?.id)
            // @TODO adapt this later for customer already has contract (was teased in design, but not part of ticket)
            // setCreditLimitCommentDismissed(false);
          }}
          value={values.company_id}
          error={errors.company_id}
          withCheckmark
        />
        {/* @TODO adapt this later for customer already has contract (was teased in design, but not part of ticket) */}
        {/* {!creditLimitCommentDismissed && !!noOfUnpaidInvoices && (*/}
        {/*  <div className='error-warning'>*/}
        {/*    <DismissableInfo*/}
        {/*      title={I18n.t('general.information')}*/}
        {/*      onClick={() => setCreditLimitCommentDismissed(true)}*/}
        {/*      text={*/}
        {/*        (<Translate*/}
        {/*          value='createMaklerPremiumOfferPageTranslations.steps.1.fields.creditLimitInfo'*/}
        {/*          sumOfUnpaidInvoices={String(sumOfUnpaidInvoices).replace('.', ',')}*/}
        {/*          noOfUnpaidInvoices={noOfUnpaidInvoices}*/}
        {/*          companyName={getSelectedCompany(Number(values.company), customers)?.name}*/}
        {/*          dangerousHTML*/}
        {/*        />)*/}
        {/*      }*/}
        {/*      buttonText={I18n.t('general.button.submit')}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/* )} */}
      </div>
      <div className='maklerpremium-offerform__column-2'>
        <GoToNextFormStepButton
          buttonText={
            stepStatus === STEP_STATUS.EDITING
              ? I18n.t('general.button.save')
              : I18n.t('createInquiryTranslations.form.button.nextStep')
          }
          onClick={() => {
            saveStep()
          }}
          isDisabled={!values.company_id}
        />
      </div>
    </>
  )
}
