import { connect } from 'react-redux'

import { getSecurityGroups } from 'actions/securitygroup'
import { createLoadingSelector } from 'selectors/loading'
import { getSecurityGroupsSelector } from 'selectors/securitygroup'

const securityGroupListLoading = createLoadingSelector(['GET_SECURITY_GROUPS'])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  securityGroupList: getSecurityGroupsSelector(state),
  loading: securityGroupListLoading(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getSecurityGroups: () => dispatch(getSecurityGroups()),
})

export default connect(mapStateToProps, mapDispatchToProps)
