export const MAX_LENGTH = {
  COMPANY_NAME: 200,
  FIRST_NAME: 50,
  LAST_NAME: 50,
  POSITION: 50,
  PASSWORD: 50,
  MAIL_ADDRESS: 50,
  PHONE_NUMBER: 20,
  POSTAL_CODE: 5,
  STREET: 100,
  HOUSE_NUMBER: 10,
  CITY: 100,
}

export const INVOICE_CHECK_STATUS = {
  INVOICE_CHECK_PENDING: 1,
  INVOICE_CHECK_STATUS_ACCEPTED: 2,
  INVOICE_CHECK_STATUS_REJECTED: 3,
  INVOICE_CHECK_STATUS_REOPENED: 4,
}

export enum INVOICE_CHECK_STATUSES {
  STATUS_PENDING = 1,
  STATUS_ACCEPTED,
  STATUS_REJECTED,
  STATUS_REOPENED,
}

export const INVOICE_CHECK_STATES = [
  {
    id: INVOICE_CHECK_STATUSES.STATUS_PENDING,
    name: 'invoiceCheckTranslations.status.pending',
  },
  {
    id: INVOICE_CHECK_STATUSES.STATUS_ACCEPTED,
    name: 'invoiceCheckTranslations.status.accepted',
  },
  {
    id: INVOICE_CHECK_STATUSES.STATUS_REJECTED,
    name: 'invoiceCheckTranslations.status.rejected',
  },
  {
    id: INVOICE_CHECK_STATUSES.STATUS_REOPENED,
    name: 'invoiceCheckTranslations.status.reopened',
  },
]

export enum INVOICE_CHECK_POSITIONS_CATEGORIES {
  DEADHEAD = 1,
  SORTING_COSTS,
  TAKEOVER_SLIP,
  DISCOUNT,
  OTHER,
  PLACEMENT_PERMIT,
  RENT,
  STREET_CHARGE,
}

export const INVOICE_CHECK_POSITIONS_CATEGORIES_CHOICES = [
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.DEADHEAD,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.deadhead',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.PLACEMENT_PERMIT,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.placementPermit',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.RENT,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.rent',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.STREET_CHARGE,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.streetCharge',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.SORTING_COSTS,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.sortingCosts',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.TAKEOVER_SLIP,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.takeoverSlip',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.DISCOUNT,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.discount',
  },
  {
    id: INVOICE_CHECK_POSITIONS_CATEGORIES.OTHER,
    name: 'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.other',
  },
]

export enum ADD_DOCUMENT_POSITION_UNITS {
  // these numbers need to correspond to the constants in backend/empto/invoicing/models/invoicing_documents.py > InvoiceDocumentItem
  PIECE = 1,
  TON = 2,
  CBM = 8,
  HOUR = 10,
}

export const ADD_DOCUMENT_POSITION_UNIT_CHOICES = [
  {
    id: ADD_DOCUMENT_POSITION_UNITS.CBM,
    name: 'addInvoicePageTranslations.epd.steps.3.fields.unit.cbm',
  },
  {
    id: ADD_DOCUMENT_POSITION_UNITS.PIECE,
    name: 'addInvoicePageTranslations.epd.steps.3.fields.unit.piece',
  },
  {
    id: ADD_DOCUMENT_POSITION_UNITS.TON,
    name: 'addInvoicePageTranslations.epd.steps.3.fields.unit.ton',
  },
  {
    id: ADD_DOCUMENT_POSITION_UNITS.HOUR,
    name: 'addInvoicePageTranslations.epd.steps.3.fields.unit.hour',
  },
]

export enum DSZ_ADD_DOCUMENT_POSITION_UNITS {
  KG = 9,
}

export const DSZ_ADD_DOCUMENT_POSITION_UNIT_CHOICES = [
  {
    id: DSZ_ADD_DOCUMENT_POSITION_UNITS.KG,
    name: 'addInvoicePageTranslations.dsz.steps.3.fields.unit.kg',
  },
]
