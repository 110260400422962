import OrderDetailsPage from '../components/order/OrderDetailsPage'
import { OrdersPage } from '../components/order/OrdersPage'

/**
 * @constant
 * @description A constant to define order routes.
 */
export const orderRoutes = [
  {
    path: '/order',
    component: OrdersPage,
    exact: true,
  },
  {
    path: '/order/:orderId',
    component: OrderDetailsPage,
    exact: true,
  },
]

export default {}
