import './style.scss'

import classNames from 'classnames'
import german from 'hyphenation.de'
import Hypher from 'hypher'
import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'

import { getDisplayName, getName } from 'helper/translations'
import { getFractionContent } from 'actions/fraction'
import { getContainerContent } from 'actions/container'
import { getContainerContentSelector } from 'selectors/container'
import { getFractionsContentSelector } from 'selectors/fraction'

import { CONTENT_TYPES } from '../../inquiry/CreateInquiryPage/FractionAndContainerFormGroup/constants'
import { BUTTON_DIMENSIONS } from '../Button'
import Icon from '../Fontello'
import { IconButton } from '../IconButton'
import { callbackOrType } from '../Input'
import { InputCheckmark } from '../InputCheckmark'
import {
  Tooltip,
  TOOLTIP_POSITION,
  TOOLTIP_THEME,
  TOOLTIP_ZINDEX,
} from '../Tooltip'

import ItemListModal from './components/ItemListModal'

const hypher = new Hypher(german)

type InputChooseItemProps<T extends AbstractInquiryItem> = {
  afterTooltipLabel?: string
  backToSelectionText?: string
  confirmText?: string
  contentType?: React.ReactText
  dataTestId?: string
  dataTestIdError?: string
  error?: callbackOrType<string>
  handleChange?: (event: React.ChangeEvent<any>) => void
  informationText?: string
  isLoading?: boolean
  isRequired?: boolean
  itemList: T[]
  label?: string
  name?: string
  placeholder?: string
  renderItemDetails?: (
    item: T,
    selectedItemContent: T extends Fraction
      ? FractionContent[]
      : ContainerContent[],
  ) => React.ReactNode
  showCheckmark?: callbackOrType<boolean>
  showSelectionImage?: boolean
  subLabel?: string
  symbol?: string
  title?: string
  tooltipText?: string
  tooltipZIndex?: TOOLTIP_ZINDEX
  value?: React.ReactText
  withCheckmark?: boolean
}

// eslint-disable-next-line arrow-parens
export const InputChooseItem = <T extends AbstractInquiryItem>(
  props: InputChooseItemProps<T>,
) => {
  const {
    afterTooltipLabel = '',
    backToSelectionText = '',
    confirmText = '',
    contentType,
    dataTestId = undefined,
    dataTestIdError = undefined,
    error = '',
    handleChange,
    informationText = '',
    isLoading = false,
    isRequired = false,
    itemList,
    label = 'Label',
    name = '',
    placeholder = 'Placeholder',
    renderItemDetails = undefined,
    showCheckmark = false,
    showSelectionImage = false,
    subLabel = '',
    symbol = 'expand-2',
    title = 'title',
    tooltipText = '',
    tooltipZIndex = TOOLTIP_ZINDEX.BEFORE_HEADER_BEHIND_MODAL,
    value = '',
    withCheckmark = false,
  } = props

  const dispatch = useDispatch()
  const fractionContent = useSelector(getFractionsContentSelector)
  const containerContent = useSelector(getContainerContentSelector)

  const [openModal, setOpenModal] = useState(false)

  /**
   * @description: Method used to open or close the ItemListModal
   */
  const handleModalToggle = () => {
    setOpenModal(!openModal)
  }

  useEffect(() => {
    if (contentType === CONTENT_TYPES.CONTAINER) {
      dispatch(getContainerContent())
    } else if (contentType === CONTENT_TYPES.FRACTION) {
      dispatch(getFractionContent())
    }
  }, [contentType, dispatch])

  const errorValue = typeof error === 'string' ? error : error(name)
  const showCheckmarkValue =
    typeof showCheckmark === 'boolean' ? showCheckmark : showCheckmark(name)

  return (
    <div
      className={classNames('input-choose-item', {
        'input-choose-item--with-checkmark': withCheckmark,
      })}
    >
      <Row middle='xs'>
        <Col xs={12} sm={12}>
          <div className='input-choose-item__label-container'>
            <div className='input-choose-item__label-container__label'>
              {label}
              {subLabel && (
                <span className='combobox__label-container__label__sub-label'>
                  {subLabel}
                </span>
              )}
            </div>

            {tooltipText && (
              <div className='input-choose-item__label-container__tooltip'>
                <Tooltip
                  html={<Translate value={tooltipText} dangerousHTML />}
                  tooltipId={name}
                  position={TOOLTIP_POSITION.RIGHT}
                  theme={TOOLTIP_THEME.LIGHT}
                  zIndex={tooltipZIndex}
                >
                  <IconButton
                    border='none'
                    className='input-choose-item__label-container--tooltip__icon'
                    dimensions={BUTTON_DIMENSIONS.AUTO}
                    iconName='question'
                    noHover
                  />
                </Tooltip>
              </div>
            )}
            {afterTooltipLabel && (
              <div className='input-choose-item__label-container--after-tooltip-label'>
                <Translate value={afterTooltipLabel} dangerousHTML />
              </div>
            )}
          </div>
        </Col>
        <Col xs={12} sm={12} style={{ position: 'relative' }}>
          {isRequired && (
            <div
              className={classNames('input-choose-item__required-dot', {
                'input-choose-item__required-dot--error': error !== '',
              })}
            />
          )}
          <div className='input-choose-item__wrap'>
            <button
              className='uk-input input-choose-item__button'
              type='button'
              data-testid={dataTestId}
              onClick={handleModalToggle}
            >
              {value && (
                <>
                  {showSelectionImage &&
                    itemList.find(item => item.id === Number(value)) &&
                    itemList.find(item => item.id === Number(value))!.image && (
                      <span>
                        <img
                          src={
                            itemList.find(item => item.id === Number(value))!
                              .image
                          }
                          alt={
                            itemList.find(item => item.id === Number(value))!
                              .name
                              ? itemList.find(
                                  item => item.id === Number(value),
                                )!.name
                              : ''
                          }
                          className='input-choose-item__button--image'
                        />
                      </span>
                    )}
                  <span className='input-choose-item__button--value'>
                    {itemList.find(item => item.display_name)
                      ? hypher.hyphenateText(getDisplayName(value, itemList))
                      : hypher.hyphenateText(getName(value, itemList))}
                  </span>
                </>
              )}
              {!value && placeholder && (
                <span className='input-choose-item__button--placeholder'>
                  {placeholder}
                </span>
              )}
              {symbol && !isLoading && (
                <div className='input-choose-item__caption input-choose-item__symbol'>
                  <Icon name={symbol} />
                </div>
              )}
            </button>
            {withCheckmark && <InputCheckmark isHidden={!showCheckmarkValue} />}
          </div>
          {isLoading && (
            <div className='input-choose-item__caption input-choose-item__symbol'>
              <Spinner
                fadeIn='none' // Show immediately
                name='circle'
                color='grey'
              />
            </div>
          )}
          {error !== '' && (
            <div
              className='input-choose-item__error'
              data-testid={dataTestIdError}
            >
              {errorValue}
            </div>
          )}
          <ItemListModal
            callback={handleModalToggle}
            itemList={itemList}
            state={openModal}
            title={title}
            renderItemDetails={renderItemDetails}
            content={
              contentType === CONTENT_TYPES.FRACTION
                ? fractionContent
                : containerContent
            }
            handleChange={handleChange}
            confirmText={confirmText || I18n.t('general.button.back')}
            backToSelectionText={
              backToSelectionText || I18n.t('general.button.submit')
            }
            informationText={informationText}
          />
        </Col>
      </Row>
    </div>
  )
}
