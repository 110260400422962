export const cleanUpAndCountFiltersForReset = filters => {
  const clonedFilters = { ...filters }

  // remove default filter values
  delete clonedFilters.page
  delete clonedFilters.page_size
  delete clonedFilters.order_by
  delete clonedFilters.direction
  delete clonedFilters.price
  delete clonedFilters.makler_premium_company
  delete clonedFilters.role
  delete clonedFilters.archive

  for (const [key, value] of Object.entries(clonedFilters)) {
    if (value === '' || value === undefined) {
      delete clonedFilters[key]
    }
  }

  return Object.keys(clonedFilters).length
}
