import moment from 'moment'
import React, { FC, useContext } from 'react'
import { Translate } from 'react-i18nify'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import {
  Headline,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import Paragraph from 'components/common/Paragraph/index'
import { Tooltip, TOOLTIP_POSITION } from 'components/common/Tooltip'

import { OFFER_COMPANY_EVALUATION_TYPES } from '../constants'
import { EvaluationsType } from '../helpers'
import { OrderEvaluationContext } from '../index'

import { EvaluationGroup } from './EvaluationGroup'

interface EvaluationsProps {
  id: number
  onSubmit?: (evaluations?: EvaluationsType[]) => void
  disabled?: boolean
}

export const Evaluations: FC<EvaluationsProps> = ({
  id,
  onSubmit = () => undefined,
  disabled = false,
}) => {
  const {
    EvaluationsElementArray,
    submitButtonDisabledWasteProducer,
    submitButtonDisabledWasteDisposer,
    handleThumbsClick,
  } = useContext(OrderEvaluationContext)

  /**
   * @description render evaluation info.
   * @param evaluations
   * @param self
   * @return {*}
   */
  const renderEvaluationInfo = (evaluations, self = false) => (
    <>
      <Headline
        headlineStyle={HEADLINE_STYLE.H3}
        noMargin={self}
        tag={HEADLINE_TAG.H3}
      >
        <Translate
          className='uk-text-bold'
          value={`orderEvaluation.${
            self ? 'submittedEvaluations' : 'receivingEvaluations'
          }`}
        />
      </Headline>

      {evaluations && evaluations.length > 0 && (
        <Paragraph>
          <Translate
            className='uk-text-bold'
            date={moment(evaluations.find(e => e.id).created_at).format(
              'L HH:mm',
            )}
            name={evaluations.find(e => e.id).created_by_name}
            value='orderEvaluation.submittedByAt'
          />
        </Paragraph>
      )}
    </>
  )

  // Since we assigned each created "item" in the EvaluationsElementArray an id, and passed the id of that "item" as
  // a prop, we now filter ALL created items in the array for the item, that's currently being rendered,
  // and extract its information (alreadyEvaluated, submitting, showReceiving, receiving)
  const element = EvaluationsElementArray.filter(el => el.id === id)[0]
  const { alreadyEvaluated, submitting, showReceiving, receiving } = element

  /**
   * @description render evaluation heading
   * @param evaluations
   * @param self
   * @return {*}
   */
  const renderHeading = (evaluations, self = false) => {
    if (self && !alreadyEvaluated) {
      return (
        <Headline headlineStyle={HEADLINE_STYLE.H3} tag={HEADLINE_TAG.H3}>
          <Translate
            className='uk-text-bold'
            value='orderEvaluation.submitEvaluations'
          />
        </Headline>
      )
    }

    return renderEvaluationInfo(evaluations, self)
  }

  const submitButtonDisabled = OFFER_COMPANY_EVALUATION_TYPES.includes(
    submitting[0].type,
  )
    ? submitButtonDisabledWasteProducer
    : submitButtonDisabledWasteDisposer

  return (
    <>
      <EvaluationGroup
        disabled={disabled || alreadyEvaluated}
        evaluations={submitting}
        onClick={handleThumbsClick}
        renderHeading={() => renderHeading(submitting, true)}
      />

      {!disabled && !alreadyEvaluated && (
        <div className='uk-margin-medium-bottom'>
          <Tooltip
            disabled={!submitButtonDisabled}
            distance={35}
            html={<Translate value='orderEvaluation.tooltip' dangerousHTML />}
            position={TOOLTIP_POSITION.BOTTOM}
            tooltipId='orderEvaluation'
            inline
          >
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              isDisabled={submitButtonDisabled}
              onClick={() => {
                onSubmit(submitting)
              }}
            >
              <Translate value='orderEvaluation.evaluate' />
            </Button>
          </Tooltip>
        </div>
      )}

      {showReceiving && receiving.length > 0 && (
        <EvaluationGroup
          disabled
          evaluations={receiving}
          renderHeading={() => renderHeading(receiving)}
        />
      )}
    </>
  )
}
