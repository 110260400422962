import './UniversalFileUploadModal.scss'

import React, { createRef, FC, useState } from 'react'
import { I18n } from 'react-i18nify'

import { Button, BUTTON_BACKGROUND_COLOR } from '../Button'
import Icon, { ICON_NAME } from '../Fontello'
import { Modal } from '../Modal'
import ModalHeader from '../ModalHeader'
import { Textarea } from '../Textarea'

import { UploadItem } from './types'

interface UniversalFileUploadModalProps {
  allowComments?: boolean
  item: UploadItem
  onClose: () => void
  onCommentChange: (text: string) => void
}

/**
 * Renders a modal with image preview and commenting features
 * @constructor
 */
export const UniversalFileUploadModal: FC<UniversalFileUploadModalProps> = ({
  allowComments,
  item,
  onClose,
  onCommentChange,
}) => {
  /* calculate maximum possible dimension for image to fit into square canvas */
  const imageWrapRef = createRef<HTMLDivElement>()
  const [maxImageSize, setMaxImageSize] = useState<number | undefined>()

  /* calculate maxImageSize when image is loaded to ensure dom is available */
  const handleImageLoad = () =>
    setMaxImageSize(imageWrapRef.current?.clientWidth)

  return (
    <Modal
      isOpen
      onClose={onClose}
      ariaDescribedBy='universal-file-upload-preview-modal'
    >
      <ModalHeader
        title={I18n.t('universalFileUpload.modalTitle')}
        titleId='universal-file-upload-preview-preview-modal'
        onClose={onClose}
      />
      <div className='universal-file-upload-modal__content'>
        {item.type && (
          <>
            {/* display an image preview if file type matches any image type */}
            {item.type.includes('image') && (
              <div className='universal-file-upload-modal__image-container'>
                <div
                  ref={imageWrapRef}
                  className='universal-file-upload-modal__image-wrap'
                >
                  <img
                    className='universal-file-upload-modal__image'
                    src={item.file}
                    alt={item.name}
                    onLoad={handleImageLoad}
                    style={{
                      transform: `rotate(${item.orientation}deg`,
                      maxHeight: `${maxImageSize}px`,
                      maxWidth: `${maxImageSize}px`,
                    }}
                  />
                </div>
              </div>
            )}

            {/* display a document/pdf icon instead of an image preview if file type includes pdf */}
            {item.type.includes('pdf') && (
              <Icon
                className='universal-file-upload-modal__icon'
                name={ICON_NAME.FILE_PDF}
              />
            )}

            {/* currently there is no CSV icon - but maybe someday it should be here */}

            {/* display open file button if file is not an image and cannot be shown directly */}
            {!item.type.includes('image') && (
              <Button
                className='universal-file-upload-modal__preview-button'
                onClick={() => window.open(item.file, '_blank')}
              >
                {I18n.t('universalFileUpload.modalPreviewButton')}
              </Button>
            )}
          </>
        )}

        {/* if allowComments is set to true display textarea input to comment files */}
        {allowComments && (
          <Textarea
            className='universal-file-upload-modal__comment-input'
            name='universal-file-upload-comments'
            onChange={event => onCommentChange(event.currentTarget.value)}
            value={item.text}
            showRemainingCharacters
            maxLength={200}
            rows={3}
            placeholder={I18n.t('universalFileUpload.modalCommentPlaceholder')}
          />
        )}

        {/* just show the comment if allowComment is set to false but file has a comments */}
        {!allowComments && item.text && item.text !== '' && (
          <p className='universal-file-upload-modal__comment-value'>
            {item.text}
          </p>
        )}

        <Button
          className='universal-file-upload-modal__confirm-button'
          onClick={onClose}
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
        >
          {I18n.t('universalFileUpload.modalConfirmButton')}
        </Button>
      </div>
    </Modal>
  )
}
