import CompanyActionTypes from '../actiontypes/company'
import ActionTypes from '../actiontypes/user'
import { storedLoggedIn } from '../services/http'

const userActivationInitialState = {
  alreadyActive: false,
  hasPassword: false,
  isEditing: false,
  success: false,
}

export const initialState = {
  activation: userActivationInitialState,
  // indicates if the user activation by email has been successful.
  // Used to render the correct JSX in the UserActivation component.
  activationSuccessful: false,
  current: {}, // the currently logged in and edited user (in his own profile)
  isEditing: false,
  item: {}, // currently edited user (e.g. admin edits user)
  itemList: [], // list of items
  // restore the state with the loggedIn flag to ensure the user doesn't get logged out when refreshing
  loggedIn: storedLoggedIn(),
  selectionItemList: [], // list to be used by a select dropdown with usernames
  companyItemList: [], // list of company users
  updatedItem: null, // to remember which user was updated and trigger an onSuccess method
  userIsContactPerson: false,
  pagination: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
}

/**
 * @description A reducer for user registration, activation and reset password.
 * @param {Object} state is the current globale state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REGISTER_USER_SUCCESS:
      return {
        ...state,
        current: initialState.current,
      }
    case ActionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        item: action.payload,
      }
    case ActionTypes.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        current: action.payload,
      }
    case ActionTypes.UPDATE_CURRENT_USER_SUCCESS:
      return {
        ...state,
        current: action.payload,
        itemList: state.itemList.map(user =>
          user.id === action.payload.id ? action.payload : user,
        ),
      }
    case ActionTypes.UPDATE_USER_SUCCESS:
      return {
        ...state,
        current:
          state.current.id === action.payload.id
            ? action.payload
            : state.current,
        item: action.payload,
        updatedItem: action.payload,
        itemList: state.itemList.map(user =>
          user.id === action.payload.id ? action.payload : user,
        ),
        isEditing: false,
      }
    case CompanyActionTypes.UPDATE_COMPANY_SUCCESS:
    case CompanyActionTypes.PAUSE_COMPANY_SUCCESS:
      if (
        state.current &&
        action.payload &&
        state.current.company === action.payload.id
      ) {
        return {
          ...state,
          current: {
            ...state.current,
            company: action.payload.id,
            company_object: action.payload,
          },
        }
      }
      return state
    case ActionTypes.GET_MY_USERS_SUCCESS:
      return {
        ...state,
        itemList: action.payload.data,
        pagination: {
          loaded: true,
          ...action.payload.pagination,
        },
      }
    case ActionTypes.GET_COMPANY_USERS_SUCCESS:
    case ActionTypes.GET_COMBINED_OFFER_EPD_USERS_FOR_TVP_SUCCESS:
      return {
        ...state,
        selectionItemList: action.payload,
      }
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        current: action.payload.user,
        loggedIn: true,
      }
    case ActionTypes.LOGOUT_REQUEST:
      return {
        ...state,
        current: {},
        itemList: [],
        loggedIn: false,
      }
    case ActionTypes.ACTIVATE_CURRENT_USER_REQUEST:
      return {
        ...state,
        activation: {
          ...userActivationInitialState,
        },
      }
    case ActionTypes.ACTIVATE_CURRENT_USER_SUCCESS:
      return {
        ...state,
        activation: {
          ...state.activation,
          success: true,
          hasPassword: action.payload.hasPassword,
          alreadyActive: action.payload.alreadyActive,
        },
      }
    case ActionTypes.ACTIVATE_CURRENT_USER_ERROR:
      return {
        ...state,
        activation: {
          ...state.activation,
          success: false,
        },
      }
    case ActionTypes.GET_CURRENT_USER_ERROR:
    case ActionTypes.PASSWORD_RESET_ERROR:
    case ActionTypes.PASSWORD_RESET_CONFIRM_ERROR:
      return {
        ...state,
        current: {},
      }
    case ActionTypes.UPDATE_USER_REQUEST:
      return {
        ...state,
        updatedItem: null,
      }
    case ActionTypes.INVITE_USER_SUCCESS:
      return {
        ...state,
        updatedItem: action.payload,
        itemList: [...state.itemList, action.payload],
      }
    case ActionTypes.GET_USERS_BY_COMPANY_SUCCESS:
      return {
        ...state,
        companyItemList: action.payload,
      }
    case ActionTypes.SET_USER_IS_CONTACT_PERSON:
      return {
        ...state,
        userIsContactPerson: true,
      }
    case ActionTypes.RESET_USER_IS_CONTACT_PERSON:
      return {
        ...state,
        userIsContactPerson: initialState.userIsContactPerson,
      }
    case ActionTypes.RESET_USER:
      return {
        ...state,
        item: initialState.item,
      }
    default:
      return state
  }
}

export default userReducer
