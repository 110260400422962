/* Selectors */

/**
 * @description selector to get the list of execution proofs.
 * @param state
 * @return {*}
 */
export const getExecutionProofsSelector = state => state.executionProof.itemList

/**
 * @description selector to get a execution proof.
 * @param state
 * @return {*}
 */
export const getExecutionProofSelector = state => state.executionProof.item

/**
 * @description selector to get an execution proof pagination object.
 * @param state
 * @return {*}
 */
export const getExecutionProofPaginationSelector = state =>
  state.executionProof.pagination

export default {}
