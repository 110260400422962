import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import uniqueId from 'lodash.uniqueid'
import { bool, func, node, number, oneOf, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { I18n } from 'react-i18nify'

import { TooltipIconProps } from '../../common/TooltipIcon/schemes'
import Icon from '../Fontello'
import { IconButton } from '../IconButton'
import { Input, INPUT_BORDER_COLOR, INPUT_TYPE, KEY_ENTER } from '../Input'
import { Tooltip, TOOLTIP_TRIGGER, TOOLTIP_ZINDEX } from '../Tooltip'

const CAPS_LOCK = 'CapsLock'
const KEY_UP = 'keyup'

class InputPassword extends Component {
  static propTypes = forbidExtraProps({
    borderColor: oneOf(Object.values(INPUT_BORDER_COLOR)),
    dataTestId: string,
    dataTestIdError: string,
    error: string,
    hideLabel: bool,
    isRequired: bool,
    label: node.isRequired,
    maxLength: number,
    name: string.isRequired,
    onBlur: func,
    onChange: func.isRequired,
    onSubmit: func,
    placeholder: string,
    tooltipZIndex: number,
    tooltip: shape(TooltipIconProps),
    value: string.isRequired,
  })

  static defaultProps = {
    borderColor: INPUT_BORDER_COLOR.DEFAULT,
    dataTestId: '',
    dataTestIdError: '',
    error: '',
    hideLabel: false,
    isRequired: false,
    maxLength: 255,
    onBlur: () => undefined,
    onSubmit: () => undefined,
    tooltipZIndex: TOOLTIP_ZINDEX.BEFORE_HEADER_BEHIND_MODAL,
    placeholder: '',
    tooltip: null,
  }

  state = {
    showPassword: false,
    isCapsLockActive: false,
  }

  componentDidMount() {
    document.addEventListener(KEY_UP, this.checkCapsLockActive)
  }

  componentWillUnmount() {
    document.removeEventListener(KEY_UP, this.checkCapsLockActive)
  }

  onValueChange = event => {
    if (event.target.value.length <= this.props.maxLength)
      this.props.onChange(event)
  }

  checkCapsLockActive = event => {
    if (event.getModifierState) {
      this.setState({ isCapsLockActive: event.getModifierState(CAPS_LOCK) })
    }
  }

  toggleShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword })
  }

  allowSubmissionByEnter = event => {
    if (this.props.onSubmit && event && event.key === KEY_ENTER)
      this.props.onSubmit(event)
  }

  keyPressEvents = event => {
    this.allowSubmissionByEnter(event)
  }

  render() {
    const {
      borderColor,
      dataTestId,
      dataTestIdError,
      error,
      hideLabel,
      isRequired,
      label,
      name,
      onBlur,
      placeholder,
      tooltip,
      tooltipZIndex,
      value,
    } = this.props

    const { isCapsLockActive, showPassword } = this.state

    const tooltipId = uniqueId() // necessary when there are more than 1 password input field

    return (
      <div className='input-password'>
        <div className='input-password__show-password'>
          <Tooltip
            duration={0}
            hideOnClick={false}
            title={
              showPassword
                ? I18n.t('message.validation.password.hidePassword')
                : I18n.t('message.validation.password.showPassword')
            }
            trigger={TOOLTIP_TRIGGER.MOUSEENTER}
            tooltipId={`password_${tooltipId}`}
            zIndex={tooltipZIndex}
          >
            <IconButton
              iconName='preview'
              onClick={this.toggleShowPassword}
              tabIndex={-1} // Prevent from being keyboard-accessible
            />
          </Tooltip>
        </div>
        <Input
          borderColor={borderColor}
          dataTestId={dataTestId}
          dataTestIdError={dataTestIdError}
          error={error}
          hideLabel={hideLabel}
          isRequired={isRequired}
          label={label}
          name={name}
          onBlur={onBlur}
          onChange={this.onValueChange}
          onKeyPress={this.keyPressEvents}
          placeholder={placeholder}
          type={showPassword ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD}
          tooltip={tooltip}
          value={value}
        />
        {isCapsLockActive && (
          <div className='input-password__caps-lock-info'>
            <Icon name='warning' />
            {I18n.t('general.capsLockOn')}
          </div>
        )}
      </div>
    )
  }
}

export default InputPassword
