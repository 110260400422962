import './style.scss'

import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { ReadOnlyField } from 'components/common/ReadOnly'
import {
  getAvvValue,
  getContainerName,
  getFractionName,
} from 'components/executionproof/helpers'
import { COLLECTION_CONTAINER_IDS } from 'constants/app'
import { decimalToGermanFormat } from 'helper/general'
import { getActualServiceDate } from 'helper/order'

import { SelectSecurityGroup } from '../../SelectSecurityGroup'
import {
  getSelectedProtectionClassName,
  getSelectedSecurityLevelName,
} from '../../SelectSecurityGroup/helpers'
import { DetailsSection } from '../DetailsSection'

interface OrderDetailsComponentProps {
  order: Order
  fractionList: Fraction[]
  containerList: Container[]
}

export const OrderDetails: FC<OrderDetailsComponentProps> = ({
  order,
  fractionList = [],
  containerList = [],
}) => {
  const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
    Number(order.offer_object.container),
  )

  return (
    <DetailsSection
      title={I18n.t('documentReviewPageTranslations.orderDetails.sectionTitle')}
    >
      <div className='document-review-section-order-details'>
        {/* ORDER NUMBER */}
        <div className='document-review-section-order-details__producer-section'>
          {!!order.id && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderDetails.orderNumber',
              )}
              value={order.id}
            />
          )}
          {/* COLLECTION DATE */}
          {!!getActualServiceDate(order) && (
            <ReadOnlyField
              dataTestId='service-date'
              label={I18n.t(
                'documentReviewPageTranslations.orderDetails.collectionDate',
              )}
              value={getActualServiceDate(order)}
            />
          )}
        </div>

        {/* DISPOSER */}
        {!!order.offer_object.company_object.name && (
          <div className='document-review-section-order-details__disposer-section'>
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderDetails.disposer',
              )}
              value={order.offer_object.company_object.name}
            />
          </div>
        )}

        {/* COLLECTION ADDRESS */}
        {!!order.collection_address_object.display_name && (
          <div className='document-review-section-order-details__disposer-section'>
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderDetails.collectionAddress',
              )}
              value={order.collection_address_object.display_name}
            />
          </div>
        )}

        <div className='document-review-section-order-details__waste-section'>
          {/* FRACTION */}
          {!!order.offer_object.fraction && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderDetails.fraction',
              )}
              value={getFractionName(order.offer_object.fraction, fractionList)}
            />
          )}

          {/* FINE FRACTION */}
          {!!order.offer_object.fine_fraction && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderDetails.fineFraction',
              )}
              value={getFractionName(
                order.offer_object.fine_fraction,
                fractionList,
              )}
            />
          )}

          {/* SECURITY GROUPS */}
          {order.offer_object.security_group && (
            <SelectSecurityGroup
              selectedSecurityGroup={order.offer_object.security_group}
              renderProtectionClassInput={(
                { securityGroupList },
                { selectedProtectionClass },
              ) => (
                <ReadOnlyField
                  label={I18n.t(
                    'selectSecurityGroupTranslations.protectionClass',
                  )}
                  value={getSelectedProtectionClassName(
                    securityGroupList,
                    selectedProtectionClass,
                  )}
                />
              )}
              renderSecurityLevelInput={(
                { securityGroupList },
                { selectedSecurityLevel },
              ) => (
                <ReadOnlyField
                  label={I18n.t(
                    'selectSecurityGroupTranslations.securityLevel',
                  )}
                  value={getSelectedSecurityLevelName(
                    securityGroupList,
                    selectedSecurityLevel,
                  )}
                />
              )}
              onSelectionChange={() => undefined} // ReadOnly view doesn't need a change handler
            />
          )}

          {/* AVV */}
          {!!order.offer_object.avv && order.offer_object.fraction && (
            <ReadOnlyField
              label={I18n.t('documentReviewPageTranslations.orderDetails.avv')}
              value={getAvvValue(
                order.offer_object.avv,
                order.offer_object.fraction,
                fractionList,
              )}
            />
          )}

          {/* CONTAINER */}
          {!!order.offer_object.container && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderDetails.container',
              )}
              value={getContainerName(
                order.offer_object.container,
                containerList,
              )}
            />
          )}

          {/* NUMBER OF CONTAINERS */}
          {/* Here we are interested in the number of containers in the order, not the offer (because it could have been
        changed in case of Auftrag auf Abruf) */}
          {!!order.number_of_containers && !showQuantityInCubicMeters && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderDetails.numberOfContainers',
              )}
              value={order.number_of_containers}
            />
          )}

          {/* QUANTITY IN CUBIC METERS */}
          {!!order.quantity_in_cubic_meters && showQuantityInCubicMeters && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderDetails.quantityInCubicMeters',
              )}
              value={decimalToGermanFormat(order.quantity_in_cubic_meters)}
            />
          )}
        </div>
      </div>
    </DetailsSection>
  )
}
