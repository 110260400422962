import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import { node, oneOf, oneOfType, string } from 'prop-types'
import React from 'react'

import Icon from 'components/common/Fontello'

export const ICON_COLOR = {
  GREEN: 'green',
  RED: 'red',
}

/**
 * @description This component renders and controls a waste type header component.
 * @function
 * @param {Object} props the component props
 */
const WasteTypeRulesCard = props => (
  <div className='waste-type-rules-card simple-card-border'>
    <div className='waste-type-rules-card__headline'>
      <h4>
        {props.title}
        <Icon
          className={`waste-type-rules-card__headline--${props.iconColor}`}
          name={props.icon}
        />
      </h4>
    </div>
    <div className='waste-type-rules-card__list'>{props.content}</div>
  </div>
)

WasteTypeRulesCard.propTypes = forbidExtraProps({
  title: string,
  icon: string.isRequired,
  content: oneOfType([string, node]),
  iconColor: oneOf(Object.values(ICON_COLOR)),
})

WasteTypeRulesCard.defaultProps = {
  title: '',
  content: '',
  iconColor: ICON_COLOR.GREEN,
}

export default WasteTypeRulesCard
