import { Formik, FormikProps } from 'formik'
import moment, { isMoment } from 'moment'
import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { resendOffer } from 'actions/offer'
import { getUsersByCompany } from 'actions/user'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import InputDate from 'components/common/InputDate'
import { ProgressButton } from 'components/common/ProgressButton'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { MaklerPremiumGroups } from 'constants/user'
import { getCurrentUser } from 'helper/user'
import { createLoadingSelector } from 'selectors/loading'
import { getUsersByCompanyList } from 'selectors/user'

interface ResendOfferFormComponentProps extends FormikProps<any> {
  offer: Offer
  onClose: () => void
  user: User
  history: History
}

export const ResendOfferForm: FC<ResendOfferFormComponentProps> = ({
  offer,
  onClose = () => undefined,
  user,
  history,
}) => {
  const isLoading = {
    usersByCompanyList: useSelector(
      createLoadingSelector(['GET_USERS_BY_COMPANY']),
    ),
    resendOffer: useSelector(createLoadingSelector(['RESEND_OFFER'])),
  }

  const dispatch = useDispatch()
  const usersByCompanyList = useSelector(getUsersByCompanyList)
  const [currentPhoneUser, setCurrentPhoneUser] = useState<{
    label: string
    value: string | number
  }>()

  const userIsMaklerPremium = user.group_id in MaklerPremiumGroups
  const isMaklerPremiumOffer =
    offer.customer_company_object.makler_premium_company === user.company

  useEffect(() => {
    if (offer && offer.customer_company_object) {
      dispatch(getUsersByCompany(offer.customer_company_object.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getMinDate = () => {
    // Function for calculating the minimum selectable date for the runtime of the offer
    // If the service_start_date has already passed, pick the service_start_date as min_date
    if (moment() > moment(offer.service_start_date)) {
      return moment(offer.service_start_date).subtract(1, 'days')
    } else {
      return moment().hours() >= 22
        ? moment().add(1, 'days').hours(8).minutes(0)
        : moment().add(1, 'hours').startOf('hour')
    }
  }

  return (
    <Formik
      onSubmit={values => {
        const offerObject = {
          id: offer.id,
        }

        dispatch(
          resendOffer(
            {
              ...offerObject,
              email_user_id: values.phone_user,
              // just send runtime_of_inquiry if it is a momentObj otherwise parse it to a momentObj
              new_runtime_of_inquiry: isMoment(values.runtime_of_inquiry)
                ? values.runtime_of_inquiry.format()
                : moment(
                    values.runtime_of_inquiry,
                    'DD.MM.YYYY hh:mm',
                  ).format(),
            },
            history,
          ),
        )
        onClose()
      }}
      validate={values => {
        const errors: {
          phone_user?: string
        } = {}

        if (String(values.phone_user) === '') {
          errors.phone_user = `${I18n.t(
            'message.validation.selectionRequired',
          )}`
        }
        return errors
      }}
      initialValues={{
        phone_user: '',
        runtime_of_inquiry: moment(offer.runtime_of_inquiry),
      }}
      validationSchema={() =>
        yup.object().shape({
          phone_user: yup
            .string()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError('${value} ist nicht vom Typ ${type}'),
        })
      }
      render={({
        setFieldValue,
        handleSubmit,
        submitCount,
        touched,
        errors: formikErrors,
        values,
        isValid,
        isSubmitting,
      }) => (
        <>
          <div className='resend-offer-form uk-text-center uk-text-lead uk-modal-body'>
            <div className='uk-margin-medium'>
              {I18n.t('offerForm.resendOffer.info')}
            </div>
          </div>
          {userIsMaklerPremium && isMaklerPremiumOffer && (
            <>
              <div className='uk-modal-body'>
                <StaticCombobox
                  error={
                    submitCount > 0 && touched.phone_user
                      ? formikErrors.phone_user
                      : ''
                  }
                  isRequired={!values.phone_user}
                  dataTestId='resend-offer-form-user'
                  label={I18n.t('general.emailAddress')}
                  isLoading={isLoading.usersByCompanyList}
                  name='phone_user'
                  noResultsText={I18n.t('inquiryForm.noInputResults.noUsers')}
                  options={usersByCompanyList
                    .filter(
                      item =>
                        !item.email.endsWith('maklerpremium.example.com') &&
                        !item.email.endsWith('epd-fake-email.de'),
                    )
                    .map(item => ({
                      value: `${item.id}`,
                      label: `${item.first_name} ${item.last_name} (${item.email})`,
                    }))}
                  onSelectionChange={value => {
                    setFieldValue('phone_user', value.target.value)
                    setCurrentPhoneUser(
                      getCurrentUser(usersByCompanyList, value.target.value),
                    )
                  }}
                  placeholder={I18n.t('general.emailAddress')}
                  selectedOption={currentPhoneUser}
                />
                <InputDate
                  label={I18n.t('offerForm.resendOffer.runtimeLabel')}
                  maxTime={moment().hours(22).minutes(0)}
                  maxDate={moment(offer.service_start_date).subtract(1, 'days')}
                  minDate={getMinDate()}
                  minTime={moment().hours(8).minutes(0)}
                  showTimeSelect
                  name='runtime_of_inquiry'
                  onChange={value => setFieldValue('runtime_of_inquiry', value)}
                  value={values.runtime_of_inquiry}
                  withCheckmark
                />
              </div>
            </>
          )}
          {/* BUTTONS */}
          <div className='uk-modal-footer uk-text-right'>
            <span className='uk-margin-right'>
              <Button
                backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                onClick={onClose}
                type={BUTTON_TYPE.BUTTON}
              >
                {I18n.t('general.button.cancel')}
              </Button>
            </span>

            <ProgressButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              dataTestId='resend-offer-form-submit'
              isDisabled={
                (submitCount > 0 && !isValid) ||
                (isSubmitting && isLoading.resendOffer)
              }
              isLoading={isSubmitting && isLoading.resendOffer}
              onClick={handleSubmit}
            >
              {I18n.t('general.button.send')}
            </ProgressButton>
          </div>
        </>
      )}
    />
  )
}
