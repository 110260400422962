import { createSelector } from 'reselect'

/**
 * @description selector to get the list of zipcodes.
 * @param state
 * @return {*}
 */
export const getZipcodesSelector = state => state.zipcode.itemList

/**
 * @description selector to get a zipcode.
 * @param state
 * @return {*}
 */
export const getZipcodeSelector = state => state.zipcode.item

/**
 * @description selector to get a the submitted state of the zipcode form.
 * @param state
 * @return {boolean}
 */
export const getZipcodeSubmittedSelector = state => state.zipcode.submitted

/**
 * @description selector to get the list of active zipcodes.
 * @param state
 * @return {*}
 */
export const getActiveZipcodesSelector = createSelector(
  getZipcodesSelector,
  zipcodes => zipcodes.filter(zipcode => zipcode.is_active),
)

export default {}
