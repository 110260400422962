import keyMirror from 'keymirror'

const GET_COMMUNITIES = keyMirror({
  GET_COMMUNITIES_REQUEST: null,
  GET_COMMUNITIES_SUCCESS: null,
  GET_COMMUNITIES_ERROR: null,
})

export default {
  ...GET_COMMUNITIES,
}
