export const InquiryOfferCardTranslations = {
  newInquiryOfferCard: {
    inquiryDescription: '<strong>Anfrage %{id}</strong> vom %{date}',
    offerDescription: '<strong>Angebot %{id}</strong> vom %{date}',
    container: {
      default: '%{capacity} %{name} (Anzahl:&nbsp;%{amount})',
      everythingHasChanged:
        '<span class="changed-value">%{capacity} %{name} (Anzahl:&nbsp;%{amount})</span>',
      everythingHasChangedWithQuantity:
        '<span class="changed-value">' +
        '%{capacity} %{name} (Menge:&nbsp;%{amount})' +
        '</span>',
      nameHasChanged:
        '<span class="changed-value">%{capacity} %{name}</span>&#32;(Anzahl:&nbsp;%{amount})',
      nameHasChangedWithQuantity:
        '<span class="changed-value">%{capacity} %{name}</span>&#32;(Menge:&nbsp;%{amount})',
      countHasChanged:
        '%{capacity} %{name}&#32;<span class="changed-value">(Anzahl:&nbsp;%{amount})</span>',
      countHasChangedWithQuantity:
        '%{capacity} %{name}&#32;<span class="changed-value">(Menge:&nbsp;%{amount})</span>',
      quantitytHasChanged:
        '%{capacity} %{name}&#32;<span class="changed-value">(Menge:&nbsp;%{amount})</span>',
    },
    serviceBegin: {
      default: 'Beginnt am %{date} %{timeOfDay}',
      everythingHasChanged:
        'Beginnt am <span class="changed-value">%{date} %{timeOfDay}</span>',
      dateHasChanged:
        'Beginnt am <span class="changed-value">%{date}</span> %{timeOfDay}',
      timeOfDayHasChanged:
        'Beginnt am %{date} <span class="changed-value">%{timeOfDay}</span>',
    },
    calc: {
      index: '%{indexName}',
      transportPricePiece:
        'Transportpreis pro Stück: <span class="no-wrap">%{amount}</span>',
      disposalPerTon:
        'Entsorgungskosten pro Tonne: <span class="no-wrap">%{amount}</span>',
      revenuePerTon:
        'Vergütung pro Tonne: <span class="no-wrap">%{amount}</span>',
      rentPriceContainerMonth:
        'Miete pro Monat und Behälter: <span class="no-wrap">%{amount}</span>',
      handleCostTon:
        'Handlingskosten pro Tonne: <span class="no-wrap">%{amount}</span>',
      discountPerTon:
        'Abschlag pro Tonne: <span class="no-wrap">%{amount}</span>',
      surchargePerTon:
        'Zuschlag pro Tonne: <span class="no-wrap">%{amount}</span>',
      surchargeDiscountPerTon:
        'Zuschlag / Abschlag pro Tonne: <span class="no-wrap">%{amount}</span>',
    },
    totalRevenue: 'Gesamterlöse (<strong>Prognose</strong>):&nbsp;',
    totalRevenueInfo:
      '<p>Für die Berechnung der Erlöse werden alle Angebote mit generischen Details berechnet. ' +
      'Der hier angezeigte Wert ist eine ungefähre Berechnung und ' +
      '<strong>nicht der verbindliche Erlös</strong>.</p>',
    totalCost: 'Gesamtkosten (<strong>Prognose</strong>):&nbsp;',
    totalCostInfo:
      '<p>Für die Berechnung des Preises werden alle Angebote mit generischen Details berechnet. ' +
      'Der hier angezeigte Preis ist eine ungefähre Berechnung und ' +
      '<strong>nicht der verbindliche Endpreis</strong>.</p>',
  },
}
