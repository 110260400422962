import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

import { Button } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'

interface NoContentMessageProps {
  createButtonComponent?: React.ReactNode
  message: string
  onResetFilter?: () => void
  showResetFilterButton?: boolean
}

/**
 * @description Small container for the message that there is currently no content with a "reset filters" button.
 */
export const NoContentMessage: FC<NoContentMessageProps> = ({
  createButtonComponent,
  message,
  onResetFilter = () => undefined,
  showResetFilterButton = true,
}) => (
  <div className='uk-text-center'>
    <p>{message}</p>
    <div className='uk-margin-small-top'>
      {(showResetFilterButton || createButtonComponent) && (
        <ButtonBar align={BUTTON_BAR_ALIGN.CENTER}>
          {showResetFilterButton && (
            <Button onClick={onResetFilter} dataTestId='filter-reset-button'>
              <Translate value='noContentMessageTranslation.resetFilter' />
            </Button>
          )}
          {createButtonComponent && createButtonComponent}
        </ButtonBar>
      )}
    </div>
  </div>
)
