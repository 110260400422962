import { Form, Formik } from 'formik'
import uniqueId from 'lodash.uniqueid'
import moment from 'moment'
import React, { FC, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'
import * as yup from 'yup'

import {
  createAutoOffer,
  getAutoOffer,
  updateAutoOffer,
} from 'actions/autooffer'
import { getContainers } from 'actions/container'
import { getFractions } from 'actions/fraction'
import { BUSINESS_SEGMENT, COLLECTION_CONTAINER_IDS } from 'constants/app'
import { UserPermission } from 'constants/user'
import { checkRequiredPermissions } from 'helper/permissions'
import { getAutoOfferSelector } from 'selectors/autooffer'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'

import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { AUTO_OFFER_STATUS, PRICE_MODELS } from '../constants'

import { AutoOfferForm } from './AutoOfferForm'

interface AutoOfferModalProps {
  isOpen?: boolean
  onClose?: () => void
  isEditing?: boolean
  autoOfferToEditId?: number | string
}

export type AutoOfferFormValues = Partial<{
  avv: React.ReactText
  business_segment: React.ReactText
  communities: Community[]
  company: React.ReactText
  container: React.ReactText
  disposal_cost_container: string
  cost_container: string
  disposal_cost_ton: string
  compensation_ton: string
  compensation_container: string
  fine_fraction: string
  fraction: string
  handle_cost_ton: string
  index: React.ReactText
  index_month: string
  price_model: string
  number_of_containers: React.ReactText
  // needs to be string because the input decimal field works with strings, otherwise, all the comma rules
  // will not work
  quantity_in_cubic_meters: string
  order_type: React.ReactText
  phone_user: React.ReactText
  position_on_public_ground: boolean
  rent_price_container_month: string
  security_group: React.ReactText
  surcharge: string
  reduction: string
  transport_price_piece: string
  valid_from: string
  valid_until: string
  with_estimated_time_of_arrival: boolean
  with_top: boolean
  zipcodes: ZipCode[]
}>

export const AutoOfferModal: FC<AutoOfferModalProps> = ({
  isOpen = false,
  onClose = () => undefined,
  isEditing = false,
  autoOfferToEditId = null,
}) => {
  const dispatch = useDispatch()

  const autoOffer = useSelector(getAutoOfferSelector) // used to get communities and zipcode from the store and Id
  const fractionList = useSelector(getFractionsSelector)
  const containerList = useSelector(getContainersSelector)
  const user = useSelector(getCurrentUserSelector)

  const isLoading = {
    getFractionList: useSelector(createLoadingSelector(['GET_FRACTIONS'])),
    getContainerList: useSelector(createLoadingSelector(['GET_CONTAINERS'])),
    getCurrentUser: useSelector(createLoadingSelector(['GET_CURRENT_USER'])),
  }

  const userCanCreatePhoneAutoOffer = checkRequiredPermissions(
    user.permission_codenames,
    [UserPermission.CREATE_PHONE_AUTO_OFFERS],
  )

  useEffect(() => {
    if (fractionList.length < 1) dispatch(getFractions())
  }, [dispatch, fractionList.length])

  useEffect(() => {
    if (containerList.length < 1) dispatch(getContainers())
  }, [containerList.length, dispatch])

  useEffect(() => {
    if (isEditing) dispatch(getAutoOffer(autoOfferToEditId))
  }, [autoOfferToEditId, dispatch, isEditing])

  const idAutoOfferFormModalHeadline = uniqueId()

  return (
    <Modal
      ariaDescribedBy={idAutoOfferFormModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={
          isEditing
            ? I18n.t('myAutoOffersPageTranslations.form.header.titleEdit')
            : I18n.t('myAutoOffersPageTranslations.form.header.title')
        }
        titleId={idAutoOfferFormModalHeadline}
      />
      {isLoading.getFractionList ||
      isLoading.getContainerList ||
      isLoading.getCurrentUser ? (
        <div className='uk-flex uk-flex-center uk-margin-large-top'>
          <Spinner name='circle' />
        </div>
      ) : (
        <Formik
          initialValues={{
            avv: '',
            business_segment: BUSINESS_SEGMENT.BUSINESS_EMPTO,
            communities: [],
            company: '',
            compensation_ton: '',
            compensation_container: '',
            container: '',
            disposal_cost_container: '',
            cost_container: '',
            disposal_cost_ton: '',
            fine_fraction: '',
            fraction: '',
            handle_cost_ton: '',
            index: '',
            index_month: '',
            number_of_containers: '1',
            quantity_in_cubic_meters: '',
            order_type: '',
            phone_user: '',
            position_on_public_ground: false,
            rent_price_container_month: '',
            security_group: '',
            reduction: '',
            surcharge: '',
            transport_price_piece: '',
            valid_from: '',
            valid_until: '',
            price_model: 'disposalSingle',
            with_estimated_time_of_arrival: false,
            with_top: false,
            zipcodes: [],
          }}
          onSubmit={(values: AutoOfferFormValues) => {
            const isValidIndexMonth = value =>
              typeof value === 'string' ? value !== '' : false
            if (!isValidIndexMonth(values.index_month)) {
              values.index_month = undefined
            }

            const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
              Number(values.container),
            )

            const callbacks = {
              success: () => onClose(),
            }
            const communities = values.communities || []
            const zipcodes = values.zipcodes || []

            const valuesToSend = { ...values, communities, zipcodes }

            if (!userCanCreatePhoneAutoOffer) {
              delete valuesToSend.company
              delete valuesToSend.phone_user
            }

            if (!showQuantityInCubicMeters) {
              delete valuesToSend.quantity_in_cubic_meters
            }

            if (isEditing) {
              dispatch(
                updateAutoOffer({
                  ...valuesToSend,
                  id: autoOffer.id,
                  callbacks,
                }),
              )
            } else {
              dispatch(
                createAutoOffer({
                  ...valuesToSend,
                  status: AUTO_OFFER_STATUS.STATUS_ACTIVE,
                  callbacks,
                }),
              )
            }
          }}
          validationSchema={() =>
            yup.object().shape({
              avv: yup
                .string()
                .required(
                  I18n.t(
                    'myAutoOffersPageTranslations.form.validation.avvError',
                  ),
                ),
              business_segment: yup
                .string()
                .required(
                  I18n.t(
                    'myAutoOffersPageTranslations.form.validation.businessSegmentError',
                  ),
                ),
              communities: yup.array(),
              company: yup.string(),
              container: yup
                .string()
                .required(
                  I18n.t(
                    'myAutoOffersPageTranslations.form.validation.containerError',
                  ),
                ),
              disposal_cost_container: yup.string(),
              cost_container: yup.string(),
              disposal_cost_ton: yup.string(),
              compensation_ton: yup.string(),
              compensation_container: yup.string(),
              fine_fraction: yup.string(),
              fraction: yup
                .string()
                .required(
                  I18n.t(
                    'myAutoOffersPageTranslations.form.validation.fractionError',
                  ),
                ),
              handle_cost_ton: yup.string(),
              index: yup.string(),
              index_month: yup.string(),
              number_of_containers: yup.string(),
              quantity_in_cubic_meters: yup.string(),
              order_type: yup
                .string()
                .required(
                  I18n.t(
                    'myAutoOffersPageTranslations.form.validation.orderTypeError',
                  ),
                ),
              phone_user: yup.string(),
              position_on_public_ground: yup.boolean(),
              rent_price_container_month: yup.string(),
              security_group: yup.string(),
              reduction: yup.string(),
              surcharge: yup.string(),
              transport_price_piece: yup.string(),
              valid_from: yup
                .string()
                .required(
                  I18n.t(
                    'myAutoOffersPageTranslations.form.validation.validFromError',
                  ),
                ),
              valid_until: yup
                .string()
                .required(
                  I18n.t(
                    'myAutoOffersPageTranslations.form.validation.validUntilError',
                  ),
                ),
              price_model: yup
                .string()
                .required(
                  I18n.t(
                    'myAutoOffersPageTranslations.form.validation.priceModelError',
                  ),
                ),
              with_top: yup.boolean(),
              zipcodes: yup.array(),
              with_estimated_time_of_arrival: yup.boolean(),
            })
          }
          validate={values => {
            const errors: {
              avv?: string
              business_segment?: string
              communities?: string
              company?: string
              container?: string
              disposal_cost_container?: string
              cost_container?: string
              disposal_cost_ton?: string
              compensation_ton?: string
              compensation_container?: string
              fine_fraction?: string
              fraction?: string
              handle_cost_ton?: string
              index?: string
              index_month?: string
              number_of_containers?: string
              quantity_in_cubic_meters?: string
              order_type?: string
              phone_user?: string
              position_on_public_ground?: string
              rent_price_container_month?: string
              security_group?: string
              surcharge?: string
              reduction?: string
              transport_price_piece?: string
              valid_from?: string
              valid_until?: string
              with_estimated_time_of_arrival?: string
              with_top?: string
              zipcodes?: string
              price_model?: string
            } = {}
            const isValidPrice = value =>
              typeof value === 'string'
                ? value !== ''
                : !Number.isNaN(Number(value)) && Number(value) >= 0
            const isValidIndexMonth = value =>
              typeof value === 'string' ? value !== '' : false
            const price_model = values.price_model
            const index = values.index

            const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
              Number(values.container),
            )

            if (userCanCreatePhoneAutoOffer) {
              if (!values.company) {
                errors.company = I18n.t(
                  'myAutoOffersPageTranslations.form.validation.companyError',
                )
              }
              if (!values.phone_user) {
                errors.phone_user = I18n.t(
                  'myAutoOffersPageTranslations.form.validation.phoneUserError',
                )
              }
            }

            if (showQuantityInCubicMeters) {
              if (
                typeof values.quantity_in_cubic_meters === 'string' &&
                values.quantity_in_cubic_meters === ''
              ) {
                errors.quantity_in_cubic_meters = I18n.t(
                  'myAutoOffersPageTranslations.form.validation.quantityInCubicMetersError',
                )
              }
              if (
                !Number.isNaN(Number(values.quantity_in_cubic_meters)) &&
                Number(values.quantity_in_cubic_meters) <= 0
              ) {
                errors.quantity_in_cubic_meters = I18n.t(
                  'myAutoOffersPageTranslations.form.validation.quantityInCubicMetersError',
                )
              }
            } else {
              if (
                typeof values.number_of_containers === 'string' &&
                values.number_of_containers === ''
              ) {
                errors.number_of_containers = I18n.t(
                  'myAutoOffersPageTranslations.form.validation.numberOfContainersError',
                )
              }
              if (
                !Number.isNaN(Number(values.number_of_containers)) &&
                (Number(values.number_of_containers) <= 0 ||
                  Number(values.number_of_containers) > 10)
              ) {
                errors.number_of_containers = I18n.t(
                  'myAutoOffersPageTranslations.form.validation.numberOfContainersError',
                )
              }
            }

            if (
              moment(values.valid_from, 'L').isAfter(
                moment(values.valid_until, 'L'),
              )
            ) {
              errors.valid_from = I18n.t(
                'myAutoOffersPageTranslations.form.validation.validFromIsAfterValidUntil',
              )
            }
            if (
              moment(values.valid_until, 'L').isBefore(
                moment(values.valid_from, 'L'),
              )
            ) {
              errors.valid_from = I18n.t(
                'myAutoOffersPageTranslations.form.validation.validUntilIsBeforeValidFrom',
              )
            }

            const selectedFraction = fractionList.find(
              fraction => fraction.id === Number(values.fraction),
            )

            if (
              selectedFraction &&
              selectedFraction.requires_securitygroup &&
              (values.security_group === '' ||
                Number(values.security_group) === 0)
            ) {
              errors.security_group = I18n.t(
                'myAutoOffersPageTranslations.form.validation.securityGroupError',
              )
            }

            if (
              (!values.zipcodes || values.zipcodes.length < 1) &&
              (!values.communities || values.communities.length < 1)
            ) {
              errors.zipcodes = I18n.t(
                'myAutoOffersPageTranslations.form.validation.zipcodesError',
              )
              errors.communities = I18n.t(
                'myAutoOffersPageTranslations.form.validation.communitiesError',
              )
            }

            if (!isValidPrice(values.rent_price_container_month)) {
              errors.rent_price_container_month = ' '
            }

            if (
              price_model === PRICE_MODELS.DISPOSAL_CONTAINER &&
              !isValidPrice(values.disposal_cost_container)
            ) {
              errors.disposal_cost_container = ' '
            }

            if (
              price_model === PRICE_MODELS.DISPOSAL_SINGLE &&
              (!isValidPrice(values.disposal_cost_ton) ||
                (!Number.isNaN(Number(values.disposal_cost_ton)) &&
                  (Number(values.disposal_cost_ton) < 0 ||
                    Number(values.disposal_cost_ton) > 2000)))
            ) {
              errors.disposal_cost_ton = ' '
            }
            if (
              price_model === PRICE_MODELS.DISPOSAL_SINGLE &&
              !isValidPrice(values.transport_price_piece)
            ) {
              errors.transport_price_piece = ' '
            }

            if (
              price_model === PRICE_MODELS.COMPENSATION_CONTAINER &&
              !isValidPrice(values.cost_container)
            ) {
              errors.cost_container = ' '
            }
            if (
              price_model === PRICE_MODELS.COMPENSATION_CONTAINER &&
              !isValidPrice(values.compensation_container)
            ) {
              errors.compensation_container = ' '
            }

            if (
              price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
              !isValidPrice(values.handle_cost_ton)
            ) {
              errors.handle_cost_ton = ' '
            }
            if (
              price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
              !isValidPrice(values.transport_price_piece)
            ) {
              errors.transport_price_piece = ' '
            }

            if (index) {
              if (
                price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
                !isValidIndexMonth(values.index_month)
              ) {
                errors.index_month = ' '
              }
              if (
                price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
                !isValidPrice(values.surcharge) &&
                !isValidPrice(values.reduction)
              ) {
                errors.surcharge = ' '
                errors.reduction = ' '
              }
            } else {
              if (
                price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
                !isValidPrice(values.compensation_ton)
              ) {
                errors.compensation_ton = ' '
              }
            }

            return errors
          }}
        >
          <Form
            className='my-auto-offers-form'
            data-testid='my-auto-offers-form'
            noValidate // Disable browser validation
          >
            <AutoOfferForm
              isEditing={isEditing}
              onClose={onClose}
              fractionList={fractionList}
              containerList={containerList}
            />
          </Form>
        </Formik>
      )}
    </Modal>
  )
}
