import './style.scss'

import classNames from 'classnames'
import React, { createRef, FC, useState } from 'react'

import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import { RegisterOrRequestDemoButtons } from 'components/common/RegisterOrRequestDemoButtons'
import { SliderPaginator } from 'components/common/SliderPaginator'
import { SLIDER_PAGINATOR_TYPES } from 'components/common/SliderPaginator/constants'

import ImageAnfrageformular from './img/slide_anfrageformular.png'
import ImageAngebotDetail from './img/slide_angebot_detail.png'
import ImageAngebotsvergleich from './img/slide_angebotsvergleich.png'
import ImageAufragVerlauf from './img/slide_auftrag_verlauf.png'
import ImageEmptoLogo from './img/slide_empto_logo.png'
import ImageNachweise from './img/slide_nachweise.png'

export interface LandingPageWasteProducerContent {
  id: number
  title: string
  content: string
  slide_image: number
  type: number
}

export enum LandingPageWasteProducerSlideImage {
  IMAGE_NONE = 1,
  IMAGE_EMPTO_LOGO = 2,
  IMAGE_ANFRAGEFORMULAR = 3,
  IMAGE_ANGEBOTSVERGLEICH = 4,
  IMAGE_ANGEBOT_DETAIL = 5,
  IMAGE_AUFTRAG_VERLAUF = 6,
  IMAGE_NACHWEISE = 7,
}

const slideImagesMap = {
  [LandingPageWasteProducerSlideImage.IMAGE_EMPTO_LOGO]: {
    image: ImageEmptoLogo,
    altText: 'empto Logo',
  },
  [LandingPageWasteProducerSlideImage.IMAGE_ANFRAGEFORMULAR]: {
    image: ImageAnfrageformular,
    altText: 'Anfrageformular',
  },
  [LandingPageWasteProducerSlideImage.IMAGE_ANGEBOTSVERGLEICH]: {
    image: ImageAngebotsvergleich,
    altText: 'Angebotsvergleich',
  },
  [LandingPageWasteProducerSlideImage.IMAGE_ANGEBOT_DETAIL]: {
    image: ImageAngebotDetail,
    altText: 'Angebot Detail',
  },
  [LandingPageWasteProducerSlideImage.IMAGE_AUFTRAG_VERLAUF]: {
    image: ImageAufragVerlauf,
    altText: 'Auftrag Verlauf',
  },
  [LandingPageWasteProducerSlideImage.IMAGE_NACHWEISE]: {
    image: ImageNachweise,
    altText: 'Nachweise',
  },
}

export enum LandingPageWasteProducerModeType {
  TYPE_DEFAULT = 1,
  TYPE_LAST_SLIDE = 2,
}

interface HomepagesSliderComponentProps {
  slidesContent: LandingPageWasteProducerContent[]
}

export const HomepagesSlider: FC<HomepagesSliderComponentProps> = ({
  slidesContent = [],
}) => {
  const sliderRef = createRef<HTMLDivElement>()
  const [counter, setCounter] = useState<number>(0)

  return (
    <section className='homepage-slider-waste-producer-section'>
      {slidesContent.length > 0 && (
        <div
          className='homepage-slider'
          ref={sliderRef}
          data-uk-slider={`
            autoplay: false;
            draggable: false;
            finite: false;
            index: ${counter}
          `}
        >
          <ul className='uk-slider-items uk-child-width-1-1@'>
            {slidesContent.map((sliderObject, sliderObjectIndex) => (
              <li
                key={`homepage-slider-li-${sliderObject.id})`}
                data-slider-index={sliderObjectIndex}
              >
                <div className='homepage-slide-wrapper'>
                  <div
                    className={classNames('homepage-slide', {
                      'homepage-slide__centered':
                        sliderObject.type ===
                        LandingPageWasteProducerModeType.TYPE_LAST_SLIDE,
                    })}
                  >
                    <div className='homepage-slide__image-area'>
                      {sliderObject.slide_image !==
                        LandingPageWasteProducerSlideImage.IMAGE_NONE && (
                        <img
                          alt={slideImagesMap[sliderObject.slide_image].altText}
                          src={slideImagesMap[sliderObject.slide_image].image}
                        />
                      )}
                    </div>
                    <div className='homepage-slide__content-area'>
                      <Headline
                        color={HEADLINE_COLOR.DARK_GRAY}
                        fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                        fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
                        headlineStyle={HEADLINE_STYLE.H3}
                        tag={HEADLINE_TAG.H4}
                      >
                        {sliderObject.title}
                      </Headline>
                      {sliderObject.type ===
                      LandingPageWasteProducerModeType.TYPE_DEFAULT ? (
                        // eslint-disable-next-line react/no-danger
                        <div
                          dangerouslySetInnerHTML={{
                            __html: sliderObject.content,
                          }}
                        />
                      ) : (
                        <div className='register_or_request_demo_buttons_container'>
                          <RegisterOrRequestDemoButtons />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <SliderPaginator
            currentSlide={counter}
            maxSlides={slidesContent.length}
            setPageCallback={newCounterValue => setCounter(newCounterValue)}
            mode={SLIDER_PAGINATOR_TYPES.WITH_DOTS}
          />
        </div>
      )}
    </section>
  )
}
