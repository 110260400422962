import moment from 'moment/moment'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import {
  CERTIFICATE_STATES,
  CERTIFICATE_STATUS,
  CERTIFICATE_TYPE,
} from 'components/certificate/constants'
import Icon from 'components/common/Fontello'
import { UniversalFileUploadPreviewItem } from 'components/common/UniversalFileUpload/UniversalFileUploadPreviewItem'
import { getName } from 'helper/translations'

import { getIconNameForStatus, getStatusString } from '../../helpers'

/**
 * @description This is a small container component that displays one colum (for one certificate) in the
 * certificates section of the company profile. It displays the certificate's status and whether a new certificate can
 * be uploaded.
 */
interface CertificateStatusProps {
  onAddClick: () => void
  onPreviewClick: () => void
  certificate: Certificate
  title: string
}

export const CertificateStatus: FC<CertificateStatusProps> = ({
  onAddClick = () => undefined,
  onPreviewClick = () => undefined,
  certificate = null,
  title = 'Zertifikat',
}) => (
  <div>
    <div className='form-group-label uk-margin'>{title}</div>

    {/* If there is a certificate yet, we display the status */}
    {certificate && (
      <div>
        <p className='uk-text-primary uk-text-bold'>
          <Icon name={getIconNameForStatus(certificate.status)} />
          <span>{I18n.t(getName(certificate.status, CERTIFICATE_STATES))}</span>
        </p>

        {certificate.status === CERTIFICATE_STATUS.STATUS_ACCEPTED &&
          certificate.valid_until && (
            <p>
              <Translate
                value='general.validUntilDate'
                date={moment(certificate.valid_until).format('L')}
              />
            </p>
          )}

        {certificate.status === CERTIFICATE_STATUS.STATUS_REJECTED && (
          <p>
            <Translate value='companyProfilePage.certificateNeeded' />
          </p>
        )}

        <p>
          <Translate
            value={
              certificate.type !== CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT
                ? `companyProfilePage.certificates.${getStatusString(
                    certificate.status,
                  )}`
                : `companyProfilePage.priceAgreement.${getStatusString(
                    certificate.status,
                  )}`
            }
            date={moment(certificate.created_at).format('L')}
            user={certificate.created_by_name}
          />
        </p>
        {certificate.attachments.length > 0 && (
          <div
            className='files uk-margin uk-child-width-1-3@l uk-grid-small'
            data-uk-grid
          >
            {certificate.attachments.map(file => (
              <UniversalFileUploadPreviewItem
                file={file}
                key={`${file.name}-key`}
                onClick={onPreviewClick}
              />
            ))}
          </div>
        )}
      </div>
    )}

    {/* If there is no certificate yet (never uploaded one), we display this info. */}
    {!certificate && (
      <p>
        <Translate value='companyProfilePage.certificates.none' />
      </p>
    )}

    {/* If there was never a certificate uploaded or the current one is not pending, we can upload a new one. */}
    {(!certificate ||
      certificate.status !== CERTIFICATE_STATUS.STATUS_PENDING) && (
      <div className='uk-margin-bottom'>
        <button
          type='button'
          onClick={onAddClick}
          className='uk-button uk-button-primary'
        >
          <Translate value='companyProfilePage.certificates.addButton' />
        </button>
      </div>
    )}
  </div>
)
