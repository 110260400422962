import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import InquiryActionTypes from '../actiontypes/inquiry'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetAvvs
 * @description get addresses with Order numbers
 */
export const handleGetAvvs = createLogic({
  type: InquiryActionTypes.GET_AVVS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}
    if (action.payload && action.payload.filters) {
      data = { ...action.payload.filters }
    }

    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/avv/`
    httpClient
      .get(url, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InquiryActionTypes.GET_AVVS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.inquiry.handleGetAvvs.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InquiryActionTypes.GET_AVVS_ERROR,
          payload,
        })

        if (!err.hideToastr) {
          toastr.error('', humanReadableError)
        }

        done()
      })
  },
})

export const handleSendConvertInquiryConfirmation = createLogic({
  type: InquiryActionTypes.SEND_CONVERT_INQUIRY_CONFIRMATION_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/inquiry/send-convert-email/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InquiryActionTypes.SEND_CONVERT_INQUIRY_CONFIRMATION_SUCCESS,
          payload: res,
        })
        toastr.success(
          '',
          I18n.t('api.inquiry.handleSendConvertInquiryConfirmation.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.inquiry.handleSendConvertInquiryConfirmation.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InquiryActionTypes.SEND_CONVERT_INQUIRY_CONFIRMATION_ERROR,
          payload,
        })

        if (!err.hideToastr) {
          toastr.error('', humanReadableError)
        }

        done()
      })
  },
})

export default [handleSendConvertInquiryConfirmation, handleGetAvvs]
