import React, { FC } from 'react'

import IconDesktopImage from 'assets/img/icons/icon-desktop-gradient.svg'
import IconGraphLineImage from 'assets/img/icons/icon-graph-line-gradient.svg'
import IconThumbsUpImage from 'assets/img/icons/icon-thumbs-up-gradient.svg'
import { BenefitItem } from 'components/common/BenefitItem'
import { BenefitsSection } from 'components/common/BenefitsSection'

export const WastemanagerBenefitsSection: FC = () => (
  <BenefitsSection>
    <BenefitItem
      imageUrl={IconGraphLineImage}
      headlineTitle='wastemanagerBenefitSection.item1.headline'
      headlineText='wastemanagerBenefitSection.item1.subline'
    />
    <BenefitItem
      imageUrl={IconThumbsUpImage}
      headlineTitle='wastemanagerBenefitSection.item2.headline'
      headlineText='wastemanagerBenefitSection.item2.subline'
    />
    <BenefitItem
      imageUrl={IconDesktopImage}
      headlineTitle='wastemanagerBenefitSection.item3.headline'
      headlineText='wastemanagerBenefitSection.item3.subline'
    />
  </BenefitsSection>
)
