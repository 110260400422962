import { connect } from 'react-redux'

import { getExecutionProofs } from 'actions/executionproof'

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getExecutionProofs: (page, filters, sortedBy) =>
    dispatch(getExecutionProofs(page, filters, sortedBy)),
})

export default connect(null, mapDispatchToProps)
