import keyMirror from 'keymirror'

const GET_HOLIDAYS = keyMirror({
  GET_HOLIDAYS_REQUEST: null,
  GET_HOLIDAYS_SUCCESS: null,
  GET_HOLIDAYS_ERROR: null,
})

export default {
  ...GET_HOLIDAYS,
}
