import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import withErrorBoundary from 'helper/withErrorBoundary'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import { getCurrentUserSelector } from 'selectors/user'

import { getBusinessSegments } from '../../helpers'

import { DSZAddInvoiceFormSteps, EPDAddInvoiceFormSteps } from '.'

const AddInvoicePageComponent: FC = () => {
  const user = useSelector(getCurrentUserSelector)
  const { isDSZ } = getBusinessSegments(user)

  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.maklerpremiumAddInvoice')}</title>
      </Helmet>
      <div className='formsteps-page'>
        <header className='formsteps-page__header'>
          <div className='uk-container uk-container-large'>
            <div className='formsteps-page__header-content'>
              <Headline
                fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                headlineStyle={HEADLINE_STYLE.H2}
                tag={HEADLINE_TAG.H2}
                color={HEADLINE_COLOR.GRAYISH_BROWN}
                fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
              >
                {I18n.t('addInvoicePageTranslations.header.title')}
              </Headline>
              <p className='formsteps-page__header-description'>
                {I18n.t('addInvoicePageTranslations.header.description')}
              </p>
            </div>
          </div>
        </header>
        <div className='formsteps-page__body uk-container uk-container-large'>
          <main className='formsteps-page__main formsteps-page__main--without-sidebar'>
            {isDSZ ? <DSZAddInvoiceFormSteps /> : <EPDAddInvoiceFormSteps />}
          </main>
        </div>
      </div>
    </>
  )
}

export const AddInvoicePage = withErrorBoundary(
  withApiErrorHandling(AddInvoicePageComponent, ['GET_INVOICES']),
)
