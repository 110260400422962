import keyMirror from 'keymirror'

const EXPORT_INQUIRY = keyMirror({
  EXPORT_INQUIRY_REQUEST: null,
  EXPORT_INQUIRY_SUCCESS: null,
})

const CANCEL_INQUIRY = keyMirror({
  CANCEL_INQUIRY_REQUEST: null,
  CANCEL_INQUIRY_SUCCESS: null,
  CANCEL_INQUIRY_ERROR: null,
})

const INFORM_WASTE_DISPOSER = keyMirror({
  INFORM_WASTE_DISPOSER_REQUEST: null,
  INFORM_WASTE_DISPOSER_SUCCESS: null,
  INFORM_WASTE_DISPOSER_ERROR: null,
})

const RESET_HIGHLIGHTED_INQUIRY = keyMirror({
  RESET_HIGHLIGHTED_INQUIRY_SUCCESS: null,
})

const GET_AVVS = keyMirror({
  GET_AVVS_REQUEST: null,
  GET_AVVS_SUCCESS: null,
  GET_AVVS_ERROR: null,
})

const SEND_CONVERT_INQUIRY_CONFIRMATION = keyMirror({
  SEND_CONVERT_INQUIRY_CONFIRMATION_REQUEST: null,
  SEND_CONVERT_INQUIRY_CONFIRMATION_SUCCESS: null,
  SEND_CONVERT_INQUIRY_CONFIRMATION_ERROR: null,
})
export default {
  ...EXPORT_INQUIRY,
  ...CANCEL_INQUIRY,
  ...INFORM_WASTE_DISPOSER,
  ...RESET_HIGHLIGHTED_INQUIRY,
  ...SEND_CONVERT_INQUIRY_CONFIRMATION,
  ...GET_AVVS,
}
