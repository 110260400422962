import { I18n } from 'react-i18nify'
import { actions } from 'react-redux-form'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import { getOfferPipeline } from '../actions/maklerpremium'
import AppActionTypes from '../actiontypes/app'
import MaklerPremiumTypes from '../actiontypes/maklerpremium'
import { INVOICE_CHECK_STATUSES } from '../components/maklerpremium/constants'
import { OrderType } from '../components/maklerpremium/InvoiceCheckOverview/InvoiceCheckPositionsForm/Step2'
import { APP_CONSTANTS, TABLE_PAGE_SIZE_10 } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

// Send avv as raw number ("15 01 10*" -> "150110")
const cleanAvvBeforeSend = data => {
  const newData = { ...data }
  if (typeof data.avv !== 'undefined' && data.avv !== null) {
    newData.avv = data.avv.replace(/[ *]/g, '')
  }
  return newData
}

const convertPriceStringToDecimal = decimalString =>
  Number(decimalString.replace(',', '.'))

// net_price is a string, we need to convert it to number for the backend
const convertOtherServiceNetPriceToNumber = data => {
  const newData = { ...data }
  if ([OrderType.OTHER_SERVICE, OrderType.RENT].includes(data.order_type)) {
    newData.net_price = convertPriceStringToDecimal(data.net_price)
  }
  return newData
}

/**
 * @function
 * @name handleCreateCustomer
 * @description Creates a new company, user and address
 */
export const handleCreateCustomer = createLogic({
  type: MaklerPremiumTypes.CREATE_CUSTOMER_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/create-customer/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.CREATE_CUSTOMER_SUCCESS,
          payload: {
            ...res,
          },
        })

        if (
          action.payload.callback &&
          action.payload.callback.onSuccessCallback
        ) {
          action.payload.callback.onSuccessCallback()
        }

        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleCreateCustomerRequest.success'),
        )
      })
      .then(done)
      .catch(err => {
        if (err.response && err.response.data) {
          const errorData = err.response.data.data
          const { setErrorCallback } = action.payload.callback

          if (errorData) {
            Object.keys(errorData).forEach(errorKey => {
              const errorMessage = errorData[errorKey][0]

              switch (errorKey) {
                case 'company_name':
                  setErrorCallback('company_name', errorMessage)
                  setErrorCallback('first_name', errorMessage)
                  setErrorCallback('last_name', errorMessage)
                  break
                case 'company_tax_number':
                  setErrorCallback('tax_number', errorMessage)
                  break
                case 'company_tax_id':
                  setErrorCallback('tax_id', errorMessage)
                  break
                case 'company_central_contact_email':
                  setErrorCallback('contact_person_email', errorMessage)
                  break
                case 'company_central_contact_phone':
                  setErrorCallback('contact_person_phone', errorMessage)
                  break
                case 'main_address_zipcode':
                  setErrorCallback('postal_code', errorMessage)
                  break
                case 'main_address_location':
                  setErrorCallback('city', errorMessage)
                  break
                case 'user_email':
                  if (
                    errorMessage ===
                    'User with this E-Mail-Adresse already exists.'
                  ) {
                    setErrorCallback(
                      'contact_person_email',
                      I18n.t(
                        'maklerpremium.addWasteProducerModal.form.validation.contact_person_email_already_exists',
                      ),
                    )
                  } else {
                    setErrorCallback('contact_person_email', errorMessage)
                  }
                  break
                case 'user_first_name':
                  setErrorCallback('contact_person_first_name', errorMessage)
                  break
                case 'user_last_name':
                  setErrorCallback('contact_person_last_name', errorMessage)
                  break
                case 'user_position':
                  setErrorCallback('contact_person_position', errorMessage)
                  break
                default:
                  setErrorCallback(
                    'contact_person_email',
                    `Fehler bei ${errorKey}: ${errorMessage}`,
                  )
                  break
              }
            })
          }
        }

        let humanReadableError = ''

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateCustomerRequest.forbidden',
          )
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateCustomerRequest.error',
          )
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.CREATE_CUSTOMER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreatePartner
 * @description Creates a new company, user and address for makler premium partner
 */
export const handleCreatePartner = createLogic({
  type: MaklerPremiumTypes.CREATE_PARTNER_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/create-partner/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.CREATE_PARTNER_SUCCESS,
          payload: {
            ...res,
          },
        })

        if (
          action.payload.callback &&
          action.payload.callback.onSuccessCallback
        ) {
          action.payload.callback.onSuccessCallback()
        }

        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleCreatePartnerRequest.success'),
        )
      })
      .then(done)
      .catch(err => {
        if (err.response && err.response.data) {
          const errorData = err.response.data.data
          const { setErrorCallback } = action.payload.callback

          if (errorData) {
            Object.keys(errorData).forEach(errorKey => {
              const errorMessage = errorData[errorKey][0]
              switch (errorKey) {
                case 'company_name':
                  setErrorCallback('company_name', errorMessage)
                  break
                case 'company_tax_number':
                  setErrorCallback('tax_number', errorMessage)
                  break
                case 'company_tax_id':
                  setErrorCallback('tax_id', errorMessage)
                  break
                case 'company_iban':
                  setErrorCallback('iban', errorMessage)
                  break
                case 'company_bic':
                  setErrorCallback('bic', errorMessage)
                  break
                case 'company_central_contact_email':
                  setErrorCallback('contact_person_email', errorMessage)
                  break
                case 'company_central_contact_phone':
                  setErrorCallback('contact_person_phone', errorMessage)
                  break
                case 'main_address_zipcode':
                  setErrorCallback('postal_code', errorMessage)
                  break
                case 'main_address_location':
                  setErrorCallback('city', errorMessage)
                  break
                case 'user_email':
                  if (
                    errorMessage ===
                    'User with this E-Mail-Adresse already exists.'
                  ) {
                    setErrorCallback(
                      'contact_person_email',
                      I18n.t(
                        'maklerpremium.addWasteProducerModal.form.validation.contact_person_email_already_exists',
                      ),
                    )
                  } else {
                    setErrorCallback('contact_person_email', errorMessage)
                  }
                  break
                case 'user_position':
                  setErrorCallback('contact_person_position', errorMessage)
                  break
                default:
                  setErrorCallback(
                    'contact_person_email',
                    `Fehler bei ${errorKey}: ${errorMessage}`,
                  )
                  break
              }
            })
          }

          let humanReadableError = ''

          if (err.response.status === 403) {
            humanReadableError = I18n.t(
              'api.maklerPremium.handleCreatePartnerRequest.forbidden',
            )
          } else {
            humanReadableError = I18n.t(
              'api.maklerPremium.handleCreatePartnerRequest.error',
            )
          }

          const payload = enhanceError(
            err.message,
            err.config,
            err.code,
            err.request,
            err.response,
            humanReadableError,
          )
          dispatch({
            type: MaklerPremiumTypes.CREATE_PARTNER_ERROR,
            payload,
          })

          if (!err.hideToastr) toastr.error('', humanReadableError)

          done()
        }
      })
  },
})

/**
 * @function
 * @name handleGetPriceComparisonTable
 * @description Creates a new company, user and address
 */
export const handleGetPriceComparisonTable = createLogic({
  type: MaklerPremiumTypes.GET_PRICE_COMPARISON_TABLE_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/pricecalc/price_comparison_table/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_PRICE_COMPARISON_TABLE_SUCCESS,
          payload: {
            ...res,
          },
        })

        if (res.length > 0) {
          toastr.success(
            '',
            I18n.t(
              'api.maklerPremium.handleGetPriceComparisonTable.success.withResults',
              { count: res.length },
            ),
          )
        } else {
          toastr.success(
            '',
            I18n.t(
              'api.maklerPremium.handleGetPriceComparisonTable.success.noResults',
            ),
          )
        }
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleGetPriceComparisonTable.success.noResults',
        )

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.GET_PRICE_COMPARISON_TABLE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)
        done()
      })
  },
})

/**
 * @function
 * @name handleGetPriceComparisonTable
 * @description Creates a new company, user and address
 */
export const handleGetPriceComparisonTablePaused = createLogic({
  type: MaklerPremiumTypes.GET_PRICE_COMPARISON_TABLE_PAUSED_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/pricecalc/price_comparison_table/`,
        { ...action.payload, block_auto_offers: true },
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_PRICE_COMPARISON_TABLE_PAUSED_SUCCESS,
          payload: {
            ...res,
          },
        })

        if (res.length > 0) {
          toastr.success(
            '',
            I18n.t(
              'api.maklerPremium.handleGetPriceComparisonTable.success.withResults',
              { count: res.length },
            ),
          )
        } else {
          toastr.success(
            '',
            I18n.t(
              'api.maklerPremium.handleGetPriceComparisonTable.success.noResults',
            ),
          )
        }
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleGetPriceComparisonTable.success.noResults',
        )

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.GET_PRICE_COMPARISON_TABLE_PAUSED_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)
        done()
      })
  },
})

/**
 * @function
 * @name handleCreateCombinedOffer
 * @description Creates a combined Offer
 */
export const handleCreateCombinedOffer = createLogic({
  type: MaklerPremiumTypes.CREATE_COMBINED_OFFER_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/create-combined-offer/`,
        action.payload.createOfferData,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.CREATE_COMBINED_OFFER_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleCreateCombinedOfferRequest.success'),
        )
        action.payload.callback()
      })
      .then(done)
      .catch(err => {
        // TODO bbb proper error handling
        let humanReadableError = ''

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateCombinedOfferRequest.forbidden',
          )
        } else if (err.response.status === 409) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateCombinedOfferRequest.frameworkContractError',
          )
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateCombinedOfferRequest.error',
          )
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.CREATE_COMBINED_OFFER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)
        action.payload.callback(true, err.response.data)
        done()
      })
  },
})

/**
 * @function
 * @name handleCreateInvoiceCheck
 * @description Creates a new InvoiceCheck Record
 */
export const handleCreateInvoiceCheck = createLogic({
  type: MaklerPremiumTypes.CREATE_INVOICE_CHECK_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { createInvoiceCheckData } = action.payload
    createInvoiceCheckData.gross_price = parseFloat(
      createInvoiceCheckData.gross_price.replace(',', '.'),
    )

    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check/`,
        createInvoiceCheckData,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.CREATE_INVOICE_CHECK_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleCreateInvoiceCheckRequest.success'),
        )
      })
      .then(done)
      .catch(err => {
        let humanReadableError
        const duplicateErrors = [
          'The fields document_number, document_date, company must make a unique set.',
          'Die Felder document_number, document_date, company müssen eine eindeutige Menge bilden.',
        ]

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateInvoiceCheckRequest.forbidden',
          )
        } else if (
          err.response.data !== undefined &&
          err.response.data.non_field_errors !== undefined &&
          err.response.data.non_field_errors.some(item =>
            duplicateErrors.includes(item),
          )
        ) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateInvoiceCheckRequest.duplicate',
          )
          err.hideToastr = true
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateInvoiceCheckRequest.error',
          )
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.CREATE_INVOICE_CHECK_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)
        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateInvoiceCheck
 * @description Creates a new InvoiceCheck Record
 */
export const handleUpdateInvoiceCheck = createLogic({
  type: MaklerPremiumTypes.UPDATE_INVOICE_CHECK_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { updateInvoiceCheckData } = action.payload
    updateInvoiceCheckData.gross_price = parseFloat(
      updateInvoiceCheckData.gross_price.replace(',', '.'),
    )

    httpClient
      .put(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check/${updateInvoiceCheckData.id}/`,
        updateInvoiceCheckData,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.UPDATE_INVOICE_CHECK_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleUpdateInvoiceCheckRequest.success'),
        )
      })
      .then(done)
      .catch(err => {
        let humanReadableError

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleUpdateInvoiceCheckRequest.forbidden',
          )
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleUpdateInvoiceCheckRequest.error',
          )
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.UPDATE_INVOICE_CHECK_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetInvoiceChecks
 * @description gets list of invoicechecks
 */
export const handleGetInvoiceChecks = createLogic({
  type: MaklerPremiumTypes.GET_INVOICE_CHECKS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}
    if (action.payload) {
      data = {
        page: action.payload.page || 1,
        order_by: JSON.stringify([
          { id: 'status', desc: false },
          { id: 'created_at', desc: false },
        ]),
      }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check/`,
        data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_INVOICE_CHECKS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleGetInvoiceChecks.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.GET_INVOICE_CHECKS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetInvoiceCheck
 * @description gets invoicecheck by id
 */
export const handleGetInvoiceCheck = createLogic({
  type: MaklerPremiumTypes.GET_INVOICE_CHECK_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}
    let expand = ''
    if (action.payload) {
      data = { invoiceCheckId: action.payload.invoiceCheckId }
      expand = action.payload.expand.join()
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check/${data.invoiceCheckId}/?expand=${expand}`,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_INVOICE_CHECK_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleGetInvoiceChecks.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.GET_INVOICE_CHECK_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)
        done()
      })
  },
})

/**
 * @function
 * @name handleGetInvoiceCheckForCoveredOrder
 * @description gets invoicecheck by id
 */
export const handleGetInvoiceCheckForAlreadyCoveredOrder = createLogic({
  type: MaklerPremiumTypes.GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}
    if (action.payload) {
      data = {
        page: 1,
        order_and_not_rejected_invoice_checks: action.payload.orderId,
      }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check/`,
        data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleGetInvoiceChecks.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetInvoiceCheckPosition
 * @description gets a single invoiceCheckPosition by ID
 */
export const handleGetInvoiceCheckPosition = createLogic({
  type: MaklerPremiumTypes.GET_INVOICE_CHECK_POSITION_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    let data = {
      ...action.payload,
    }

    if (action.payload?.expand) {
      data = { ...data, expand: action.payload.expand.join(',') }
    }

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check-position/${action.payload.id}/`,
        data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_INVOICE_CHECK_POSITION_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleGetInvoiceCheckPosition.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: MaklerPremiumTypes.GET_INVOICE_CHECK_POSITION_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetInvoiceCheckPositions
 * @description gets list of invoicecheckpositions
 */
export const handleGetInvoiceCheckPositions = createLogic({
  type: MaklerPremiumTypes.GET_INVOICE_CHECK_POSITIONS_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    let data = {}
    if (action.payload) {
      data = {
        page: action.payload.page || 1,
      }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check-position/`,
        data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_INVOICE_CHECK_POSITIONS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleGetInvoiceCheckPosition.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: MaklerPremiumTypes.GET_INVOICE_CHECK_POSITIONS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateInvoiceCheckPosition
 * @description Creates a new InvoiceCheckPosition Record
 */
export const handleCreateInvoiceCheckPosition = createLogic({
  type: MaklerPremiumTypes.CREATE_INVOICE_CHECK_POSITION_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    // eslint-disable-next-line no-param-reassign
    action.payload.data = cleanAvvBeforeSend(action.payload.data)
    // eslint-disable-next-line no-param-reassign
    action.payload.data = convertOtherServiceNetPriceToNumber(
      action.payload.data,
    )

    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check-position/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.CREATE_INVOICE_CHECK_POSITION_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success(
          '',
          I18n.t(
            'api.maklerPremium.handleCreateInvoiceCheckPositionRequest.success',
          ),
        )
      })
      .then(done)
      .catch(err => {
        let humanReadableError

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateInvoiceCheckPositionRequest.forbidden',
          )
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleCreateInvoiceCheckPositionRequest.error',
          )
        }

        // handle human readable backend errors
        if (err && err.response && err.response.data) {
          if (err.response.data.non_field_errors)
            [humanReadableError] = err.response.data.non_field_errors
          if (err.response.data.length === 1)
            [humanReadableError] = err.response.data
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.CREATE_INVOICE_CHECK_POSITION_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)
        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateInvoiceCheckPosition
 * @description Updates a new InvoiceCheckPosition Record
 */
export const handleUpdateInvoiceCheckPosition = createLogic({
  type: MaklerPremiumTypes.UPDATE_INVOICE_CHECK_POSITION_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    // eslint-disable-next-line no-param-reassign
    action.payload.data = cleanAvvBeforeSend(action.payload.data)
    // eslint-disable-next-line no-param-reassign
    action.payload.data = convertOtherServiceNetPriceToNumber(
      action.payload.data,
    )
    httpClient
      .put(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check-position/${action.payload.id}/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.UPDATE_INVOICE_CHECK_POSITION_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success(
          '',
          I18n.t(
            'api.maklerPremium.handleUpdateInvoiceCheckPositionRequest.success',
          ),
        )
      })
      .then(done)
      .catch(err => {
        let humanReadableError

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleUpdateInvoiceCheckPositionRequest.forbidden',
          )
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleUpdateInvoiceCheckPositionRequest.error',
          )
        }

        // handle human readable backend errors
        if (err && err.response && err.response.data) {
          if (err.response.data.non_field_errors)
            [humanReadableError] = err.response.data.non_field_errors
          if (err.response.data.length === 1)
            [humanReadableError] = err.response.data
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.UPDATE_INVOICE_CHECK_POSITION_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)
        done()
      })
  },
})

/**
 * @function
 * @name handleDeleteInvoiceCheckPosition
 * @description Deletes a new InvoiceCheckPosition Record
 */
export const handleDeleteInvoiceCheckPosition = createLogic({
  type: MaklerPremiumTypes.DELETE_INVOICE_CHECK_POSITION_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .delete(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check-position/${action.payload.id}/`,
      )
      .then(res => res.data)
      .then(() => {
        dispatch({
          type: MaklerPremiumTypes.DELETE_INVOICE_CHECK_POSITION_SUCCESS,
          payload: {
            id: action.payload.id,
          },
        })
        toastr.success(
          '',
          I18n.t(
            'api.maklerPremium.handleDeleteInvoiceCheckPositionRequest.success',
          ),
        )
      })
      .then(done)
      .catch(err => {
        let humanReadableError

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleDeleteInvoiceCheckPositionRequest.forbidden',
          )
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleDeleteInvoiceCheckPositionRequest.error',
          )
        }

        // handle human readable backend errors
        if (err && err.response && err.response.data) {
          if (err.response.data.non_field_errors)
            [humanReadableError] = err.response.data.non_field_errors
          if (err.response.data.length === 1)
            [humanReadableError] = err.response.data
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.DELETE_INVOICE_CHECK_POSITION_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)
        done()
      })
  },
})

/**
 * @function
 * @name handleGetInvoiceCheckPositionPrice
 * @description Gets a new InvoiceCheckPosition Price
 */
export const handleGetInvoiceCheckPositionPrice = createLogic({
  type: MaklerPremiumTypes.GET_INVOICE_CHECK_POSITION_PRICE_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    // eslint-disable-next-line no-param-reassign
    action.payload.data = cleanAvvBeforeSend(action.payload.data)
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check-position/calculate-net-price/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_INVOICE_CHECK_POSITION_PRICE_SUCCESS,
          payload: {
            ...res,
          },
        })
      })
      .then(done)
      .catch(err => {
        let humanReadableError

        if (err.response.status === 403) {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleGetInvoiceCheckPositionPrice.forbidden',
          )
        } else {
          humanReadableError = I18n.t(
            'api.maklerPremium.handleGetInvoiceCheckPositionPrice.error',
          )
        }

        // handle human readable backend errors
        if (err && err.response && err.response.data) {
          if (err.response.data.non_field_errors)
            [humanReadableError] = err.response.data.non_field_errors
          if (err.response.data.length === 1)
            [humanReadableError] = err.response.data
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.GET_INVOICE_CHECK_POSITION_PRICE_ERROR,
          payload,
        })

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateReviewedInvoiceCheckPosition
 * @description Updates a reviewed invoice check position
 */
export const handleUpdateReviewedInvoiceCheckPosition = createLogic({
  type: MaklerPremiumTypes.UPDATE_REVIEWED_INVOICE_CHECK_POSITION_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check-position/${action.payload.id}/review/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.UPDATE_REVIEWED_INVOICE_CHECK_POSITION_SUCCESS,
          payload: res,
        })

        let message = I18n.t(
          'api.maklerPremium.handleUpdateReviewedInvoiceCheckPosition.relabeled.success',
        )
        if (res.container_changed) {
          message = I18n.t(
            'api.maklerPremium.handleUpdateReviewedInvoiceCheckPosition.containerChanged.success',
          )
        } else if (res.additional_rent) {
          message = I18n.t(
            'api.maklerPremium.handleUpdateReviewedInvoiceCheckPosition.additionalRent.success',
          )
        }
        toastr.success('', message)
      })
      .then(() => action.payload.history.goBack())
      .then(done)
      .catch(err => {
        dispatch(
          actions.setPending('maklerpremium.invoiceCheckPosition.item', false),
        )
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleUpdateReviewedInvoiceCheckPosition.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.UPDATE_REVIEWED_INVOICE_CHECK_POSITION_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCustomInvoiceNetGrossPricesVat
 * @description Calculate total prices and vat of positions
 */
export const handleGetCustomInvoiceNetGrossPricesVat = createLogic({
  type: MaklerPremiumTypes.GET_CUSTOM_INVOICE_NET_GROSS_PRICES_VAT_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/custom-document/calculate-net-gross-prices-and-vat/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_CUSTOM_INVOICE_NET_GROSS_PRICES_VAT_SUCCESS,
          payload: {
            ...res,
          },
        })
      })
      .then(done)
      .catch(err => {
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
        )

        dispatch({
          type: MaklerPremiumTypes.GET_CUSTOM_INVOICE_NET_GROSS_PRICES_VAT_ERROR,
          payload,
        })

        if (!err.hideToastr) {
          err.response.data.forEach(entry => {
            toastr.error('', entry)
          })
        }

        done()
      })
  },
})

/**
 * @function
 * @name handleValidateIBAN
 * @description Validate IBAN
 */
export const handleValidateIBAN = createLogic({
  type: MaklerPremiumTypes.VALIDATE_IBAN_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/custom-document/validate-iban/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.VALIDATE_IBAN_SUCCESS,
          payload: {
            ...res,
          },
        })
      })
      .then(done)
      .catch(err => {
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
        )

        dispatch({
          type: MaklerPremiumTypes.VALIDATE_IBAN_ERROR,
          payload,
        })

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateCustomInvoice
 * @description Creates an invoice using the custom data provided in Beleg+
 */
export const handleCreateCustomInvoice = createLogic({
  type: MaklerPremiumTypes.CREATE_CUSTOM_INVOICE_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/custom-document/create-custom-document/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.CREATE_CUSTOM_INVOICE_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleCreateCustomInvoiceRequest.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleCreateCustomInvoiceRequest.error',
        )

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.CREATE_CUSTOM_INVOICE_ERROR,
          payload,
        })

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateCustomInvoice
 * @description Creates an invoice using the custom data provided in Beleg+
 */
export const handleGetCustomTax = createLogic({
  type: MaklerPremiumTypes.GET_CUSTOM_TAX_REQUEST,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/custom-tax/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_CUSTOM_TAX_SUCCESS,
          payload: {
            ...res,
          },
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleCreateCustomInvoiceRequest.error',
        )

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.GET_CUSTOM_TAX_ERROR,
          payload,
        })

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateCustomInvoice
 * @description Creates an invoice using the custom data provided in Beleg+
 */
export const handleGetFrameworkContract = createLogic({
  type: MaklerPremiumTypes.GET_FRAMEWORKCONTRACT_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/frameworkcontractposition`,
        action.payload.filters ? action.payload.filters : '',
      )
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_FRAMEWORKCONTRACT_SUCCESS,
          payload: {
            ...res.data,
          },
        })
      })
      .then(done)
  },
})

export const handleGetFrameworkContractPositionsCount = createLogic({
  type: MaklerPremiumTypes.GET_FRAMEWORKCONTRACT_POSITIONS_COUNT_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/frameworkcontractposition/count/`,
        action.payload,
      )
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_FRAMEWORKCONTRACT_POSITIONS_COUNT_SUCCESS,
          payload: {
            ...res.data,
          },
        })
      })
      .then(done)
  },
})

/**
 * @function
 * @name handleCreateCustomInvoice
 * @description Creates an invoice using the custom data provided in Beleg+
 */
export const handleGetCancelledInvoicePosition = createLogic({
  type: MaklerPremiumTypes.GET_CANCELLEDPAIDDOCUMENT_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/cancelled-paid-document/${action.payload.id}`,
      )
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_CANCELLEDPAIDDOCUMENT_SUCCESS,
          payload: {
            ...res.data,
          },
        })
      })
      .then(done)
  },
})

/**
 * @function
 * @name handleUpdateReviewedInvoiceCheckPosition
 * @description Updates a reviewed invoice check position
 */
export const handleUpdateCancelledInvoicePosition = createLogic({
  type: MaklerPremiumTypes.UPDATE_CANCELLEDPAIDDOCUMENT_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/cancelled-paid-document/${action.payload.id}/review/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.UPDATE_CANCELLEDPAIDDOCUMENT_SUCCESS,
          payload: res,
        })
        toastr.success(
          '',
          I18n.t(
            'api.maklerPremium.handleUpdateCancelledInvoicePosition.success',
          ),
        )
      })
      .then(() => action.payload.history.goBack())
      .then(done)
      .catch(err => {
        dispatch(
          actions.setPending('maklerpremium.invoiceCheckPosition.item', false),
        )
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleUpdateCancelledInvoicePosition.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.UPDATE_CANCELLEDPAIDDOCUMENT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateCustomOffer
 * @description Creates a custom offer
 */
export const handleCreateCustomOffer = createLogic({
  type: MaklerPremiumTypes.CREATE_CUSTOMOFFER_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const data = {
      ...action.payload.data,
      net_price: convertPriceStringToDecimal(action.payload.data.net_price),
    }

    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/custom-offer/`,
        data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.CREATE_CUSTOMOFFER_SUCCESS,
          payload: {
            ...res,
          },
        })

        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleCreateCustomOfferRequest.success'),
        )

        dispatch(getOfferPipeline())
      })
      .then(done)
      .catch(err => {
        let apiErrorMessage
        if (err.response?.data?.non_field_errors) {
          apiErrorMessage = err.response?.data?.non_field_errors[0]
        } else {
          apiErrorMessage = ''
        }

        const humanReadableError = I18n.t(
          'api.maklerPremium.handleCreateCustomOfferRequest.error',
          { error: apiErrorMessage },
        )

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.CREATE_CUSTOMOFFER_ERROR,
          payload,
        })

        toastr.error(
          '',
          I18n.t('api.maklerPremium.handleCreateCustomOfferRequest.error', {
            error: apiErrorMessage,
          }),
        )

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateCustomOffer
 * @description Creates a custom offer
 */
export const handleUpdateCustomOffer = createLogic({
  type: MaklerPremiumTypes.UPDATE_CUSTOMOFFER_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/custom-offer/${action.payload.customOfferID}/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.UPDATE_CUSTOMOFFER_SUCCESS,
          payload: {
            ...res,
          },
        })

        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleUpdateCustomOfferRequest.success'),
        )

        dispatch(getOfferPipeline())
      })
      .then(done)
      .catch(err => {
        const apiErrorMessage =
          err.response?.data?.non_field_errors &&
          err.response?.data?.non_field_errors[0]
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleUpdateCustomOfferRequest.error',
          { error: apiErrorMessage },
        )

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.UPDATE_CUSTOMOFFER_ERROR,
          payload,
        })

        toastr.error(
          '',
          I18n.t('api.maklerPremium.handleCreateCustomOfferRequest.error', {
            error: apiErrorMessage,
          }),
        )

        done()
      })
  },
})

/**
 * @function
 * @name handleGetOfferPipeline
 * @description Loads all offers that were created by my company.
 */
export const handleGetOfferPipeline = createLogic({
  type: MaklerPremiumTypes.GET_OFFER_PIPELINE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}
    if (action.payload) {
      data = {
        page: action.payload.page || 1,
        page_size: action.payload.pageSize || TABLE_PAGE_SIZE_10,
      }

      let filters = {}
      if (action.payload.filters) {
        // We only expand the order_type variable to get rid of it
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { company, order_type, ...rest } = action.payload.filters
        filters = {
          company_id: company,
          ...rest,
        }
      }

      data = { ...data, ...filters }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/offer-pipeline/`,
        data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.GET_OFFER_PIPELINE_SUCCESS,
          payload: res,
        })
        dispatch({
          type: AppActionTypes.RESET_API_FETCH_ERRORS,
          payload: 'GET_OFFER_PIPELINE',
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.offer.handleGetMyOffers.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.GET_OFFER_PIPELINE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleEPDExportBatchFile
 * @description triggers the invoice export for epd.
 */
export const handleExportInvoiceCheckBatchFile = createLogic({
  type: MaklerPremiumTypes.EXPORT_INVOICE_CHECK_BATCH_FILE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}
    if (action.payload.filters) {
      data = { ...data, filters: JSON.stringify(action.payload.filters) }
    }

    if (action.payload.sortedBy && action.payload.sortedBy.length) {
      data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check/export-batch-file/`,
        data,
      )
      .then(() => {
        dispatch({
          type: MaklerPremiumTypes.EXPORT_INVOICE_CHECK_BATCH_FILE_SUCCESS,
        })

        toastr.success(
          '',
          I18n.t('api.invoice.handleExportInvoiceCheckBatchFile.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.invoice.handleExportInvoiceCheckBatchFile.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.EXPORT_INVOICE_CHECK_BATCH_FILE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportUpcomingOrders
 * @description triggers the upcoming orders export.
 */
export const handleExportUpcomingOrders = createLogic({
  type: MaklerPremiumTypes.EXPORT_UPCOMING_ORDERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { filters } = action.payload
    let data = {}

    if (filters) {
      data = { ...data, filters: JSON.stringify(filters) }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/upcoming-orders/export/`,
        data,
      )
      .then(() => {
        dispatch({
          type: MaklerPremiumTypes.EXPORT_UPCOMING_ORDERS_SUCCESS,
        })

        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleExportUpcomingOrdersRequest.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleExportUpcomingOrdersRequest.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.EXPORT_UPCOMING_ORDERS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportInvoiceCheck
 * @description triggers the invoice check export.
 */
export const handleExportInvoiceCheck = createLogic({
  type: MaklerPremiumTypes.EXPORT_INVOICE_CHECK_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { filters } = action.payload
    let data = {}

    if (filters) {
      data = { ...data, filters: JSON.stringify(filters) }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check/export/`,
        data,
      )
      .then(() => {
        dispatch({
          type: MaklerPremiumTypes.EXPORT_INVOICE_CHECK_SUCCESS,
        })
        toastr.success(
          '',
          I18n.t('api.invoice.handleExportInvoiceCheck.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.invoice.handleExportInvoiceCheck.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.EXPORT_INVOICE_CHECK_ERROR,
          payload,
        })
        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportOfferPipeline
 * @description triggers the offer pipeline export for epd.
 */
export const handleExportOfferPipeline = createLogic({
  type: MaklerPremiumTypes.EXPORT_OFFER_PIPELINE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload.filters) {
      data = { ...data, ...action.payload.filters }
    }

    if (action.payload.sortedBy && action.payload.sortedBy.length) {
      data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
    }

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/offer-pipeline/offer_pipeline_export/`,
        data,
      )
      .then(() => {
        dispatch({
          type: MaklerPremiumTypes.EXPORT_OFFER_PIPELINE_SUCCESS,
        })

        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleExportOfferPipeline.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleExportOfferPipeline.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.EXPORT_OFFER_PIPELINE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleReopenInvoiceCheck
 * @description reopens an InvoiceCheck.
 */
export const handleReopenInvoiceCheck = createLogic({
  type: MaklerPremiumTypes.REOPEN_INVOICE_CHECK_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {
      status: INVOICE_CHECK_STATUSES.STATUS_REOPENED,
    }
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/invoice-check/${action.payload.invoiceCheckId}/`,
        data,
      )
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.REOPEN_INVOICE_CHECK_SUCCESS,
          payload: {
            ...res.data,
          },
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleReopenInvoiceCheckRequest.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.REOPEN_INVOICE_CHECK_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportPostalStatistics
 * @description triggers the postal statistics export.
 */
export const handleExportPostalStatistics = createLogic({
  type: MaklerPremiumTypes.EXPORT_POSTAL_STATISTICS_REQUEST,
  latest: true,

  process({ httpClient }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/offer-pipeline/postal_statistics_export/`,
      )
      .then(() => {
        dispatch({
          type: MaklerPremiumTypes.EXPORT_POSTAL_STATISTICS_SUCCESS,
        })
        toastr.success('', I18n.t('general.handleCSVExport.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('general.handleCSVExport.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.EXPORT_POSTAL_STATISTICS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportPostalStatistics
 * @description triggers the postal statistics export.
 */
export const handleExportCustomerData = createLogic({
  type: MaklerPremiumTypes.EXPORT_CUSTOMER_DATA_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { company_id } = action.payload
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/${company_id}/export-customer-data`,
      )
      .then(() => {
        dispatch({
          type: MaklerPremiumTypes.EXPORT_CUSTOMER_DATA_SUCCESS,
        })
        toastr.success('', I18n.t('general.handleCSVExport.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('general.handleCSVExport.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.EXPORT_CUSTOMER_DATA_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export const handleExportCommunicationcenter = createLogic({
  type: MaklerPremiumTypes.EXPORT_COMMUNICATIONCENTER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { company_id } = action.payload
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/com-center/${company_id}/export-communicationcenter`,
      )
      .then(() => {
        dispatch({
          type: MaklerPremiumTypes.EXPORT_COMMUNICATIONCENTER_SUCCESS,
        })
        toastr.success('', I18n.t('general.handleCSVExport.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('general.handleCSVExport.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: MaklerPremiumTypes.EXPORT_COMMUNICATIONCENTER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

const parseCreateCustomAgreementFields = data => {
  let parsedData = { ...data }
  parsedData['customer_company_id'] = parsedData.customer.selected_company.id
  delete parsedData.customer
  parsedData['partner_company_id'] = parsedData.partner.selected_company.id
  delete parsedData.partner

  parsedData['collection_address_id'] = parsedData.collection_address
  delete parsedData.collection_address
  parsedData['fraction_id'] = parsedData.fraction
  delete parsedData.fraction
  if (parsedData.fine_fraction) {
    parsedData['fine_fraction_id'] = parsedData.fine_fraction
    delete parsedData.fine_fraction
  }
  if (parsedData.security_group) {
    parsedData['security_group_id'] = parsedData.security_group
    delete parsedData.security_group
  }
  parsedData['avv_id'] = parsedData.avv
  delete parsedData.avv
  parsedData['container_id'] = parsedData.container
  delete parsedData.container

  if (parsedData['collection_date']) {
    parsedData['collection_date'] =
      parsedData['collection_date'].format('YYYY-MM-DD')
  }

  if (parsedData['delivery_date']) {
    parsedData['delivery_date'] =
      parsedData['delivery_date'].format('YYYY-MM-DD')
  }

  if (parsedData['turn_begin']) {
    parsedData['turn_begin'] = parsedData['turn_begin'].format('YYYY-MM-DD')
  }

  if (parsedData['turn_end']) {
    parsedData['turn_end'] = parsedData['turn_end'].format('YYYY-MM-DD')
  }

  if (parsedData['rythm_begin']) {
    parsedData['rythm_begin'] = parsedData['rythm_begin'].format('YYYY-MM-DD')
  }

  if (parsedData['customer_index']) {
    parsedData['customer_index_id'] = parsedData.customer_index
    delete parsedData.customer_index
  }

  if (parsedData['partner_index']) {
    // Casting index id to a number here to avoid changing the Index type to avoid side effects in the empto marketplace
    parsedData['partner_index_id'] = Number(parsedData.partner_index)
    delete parsedData.partner_index
  }

  return parsedData
}

/**
 * @function
 * @param data
 * @description Function for removing empty fields from data and converting any price field into a decimal value
 */
const cleanEmptyFields = data => {
  const decimalFields = [
    'rent_price', // rent_price_container_month
    'transport_price', // transport_price_piece
    'package_price', // gross_retail_price
    'disposal_price', // disposal_cost_container, disposal_cost_ton
    'surcharge', // surcharge
    'reduction', // reduction
    'discount', // discount
    'compensation_ton', // compensation_ton
    'compensation_container', // compensation_container
    'handle_cost_ton', // handle_cost_ton
    'disposal_cost_container', // disposal_cost_container
    'disposal_cost_ton', // disposal_cpst_ton
    'rent_price_container_month', // rent_price_container_month
    'package_rent_price', // package_rent_price
    'package_transport_and_disposal_price', // package_transport_and_disposal_price
    'cost_container',
  ]
  const removeRoleSubstring = str =>
    str.replace('customer_', '').replace('partner_', '')

  return Object.fromEntries(
    Object.entries(data)
      .filter(([, value]) => value !== '')
      .map(([key, value]) => {
        if (decimalFields.includes(removeRoleSubstring(key)) && value) {
          value = convertPriceStringToDecimal(value)
        }
        return [key, value]
      }),
  )
}

/**
 * @function
 * @name handleCreateCustomAgreement
 * @description triggers creation of a custom Agreement in the EPD context.
 */
export const handleCreateCustomAgreement = createLogic({
  type: MaklerPremiumTypes.CREATE_CUSTOM_AGREEMENT_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = parseCreateCustomAgreementFields(action.payload.data)
    data = cleanEmptyFields(data)

    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/create-custom-agreement/`,
        data,
      )
      .then(res => {
        dispatch({
          type: MaklerPremiumTypes.CREATE_CUSTOM_AGREEMENT_SUCCESS,
        })

        const createdAgreementId = res.data.id
        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleCreateAgreement.success', {
            createdAgreementId: createdAgreementId,
          }),
        )

        action.payload.history.push(`/agreement/${createdAgreementId}`)
      })
      .then(done)
      .catch(err => {
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          err.response.data,
        )
        dispatch({
          type: MaklerPremiumTypes.CREATE_CUSTOM_AGREEMENT_ERROR,
          payload,
        })

        if (!err.hideToastr) {
          if (err.response.status === 500) {
            toastr.error(
              '',
              I18n.t('api.maklerPremium.handleCreateAgreement.error'),
            )
          } else if (err.response.data) {
            Object.values(err.response.data).map(entry =>
              toastr.error('', entry[0]),
            )
          }
        }

        done()
      })
  },
})

export default [
  handleCreateCombinedOffer,
  handleCreateCustomAgreement,
  handleCreateCustomer,
  handleCreateCustomInvoice,
  handleCreateCustomOffer,
  handleCreateInvoiceCheck,
  handleCreateInvoiceCheckPosition,
  handleCreatePartner,
  handleDeleteInvoiceCheckPosition,
  handleExportCommunicationcenter,
  handleExportCustomerData,
  handleExportInvoiceCheck,
  handleExportInvoiceCheckBatchFile,
  handleExportOfferPipeline,
  handleExportPostalStatistics,
  handleExportUpcomingOrders,
  handleGetCancelledInvoicePosition,
  handleGetCustomInvoiceNetGrossPricesVat,
  handleGetCustomTax,
  handleGetFrameworkContract,
  handleGetFrameworkContractPositionsCount,
  handleGetInvoiceCheck,
  handleGetInvoiceCheckForAlreadyCoveredOrder,
  handleGetInvoiceCheckPosition,
  handleGetInvoiceCheckPositionPrice,
  handleGetInvoiceCheckPositions,
  handleGetInvoiceChecks,
  handleGetOfferPipeline,
  handleGetPriceComparisonTable,
  handleGetPriceComparisonTablePaused,
  handleReopenInvoiceCheck,
  handleUpdateCancelledInvoicePosition,
  handleUpdateCustomOffer,
  handleUpdateInvoiceCheck,
  handleUpdateInvoiceCheckPosition,
  handleUpdateReviewedInvoiceCheckPosition,
  handleValidateIBAN,
]
