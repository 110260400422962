import './style.scss'

import { Formik } from 'formik'
import React, { FC, ReactNode } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { getCurrentUserSelector } from 'selectors/user'
import { checkRequiredPermissions } from 'helper/permissions'
import { UserPermission } from 'constants/user'
import { uploadFiles } from 'actions/attachment'

import { CERTIFICATE_TYPE } from '../../certificate/constants'
import { UploadItem } from '../../common/UniversalFileUpload/types'
import { COMPANY_ROLE } from '../../company/constants'
import { ORDER_CLAIM_TYPE } from '../../order/OrderDetailsPage/constants'
import { RequiredPermissions } from '../RequiredPermissions'

import { UploadDocumentFormFields } from './UploadDocumentFormFields'

export type UploadDocumentFormValues = {
  attachments: UploadItem[]
  business_segment?: number
  claim_description: string
  claim_type: string
  company?: string
  phone_user?: string
}

interface UploadDocumentFormComponentProps {
  acceptFileTypes?: string
  // if user wanst to reset an aditional Api Fetch Error, the action name should be given here
  aditionalApiFetchError?: string
  apiError: ApiError
  description?: ReactNode
  doNotShowNumberOfUploadedFiles?: boolean
  document?: Order | Certificate
  documentType?: number
  isLoading?: boolean
  maxFiles?: number
  onCloseForm?: () => void
  onDocumentSubmit?: (values: any) => void
  permissions?: UserPermission[]
  preSelectedCompanyId?: number | string
  showApiValidationMessage?: boolean
  showJustFileName?: boolean
  showOnlyActiveCompanies?: boolean
  showListForWasteCompanies?: boolean
  showOrderClaimOptions?: boolean
  showUploadWarningMessage?: boolean
  subLabelNewLine?: boolean
  uploadLabel?: string
}

export const UploadDocumentForm: FC<UploadDocumentFormComponentProps> = ({
  acceptFileTypes = 'image/png, image/jpeg, application/pdf',
  aditionalApiFetchError = '',
  apiError,
  description = '',
  doNotShowNumberOfUploadedFiles = false,
  document = {},
  documentType,
  isLoading = false,
  maxFiles = 3,
  onCloseForm = () => undefined,
  onDocumentSubmit = () => undefined,
  permissions = [],
  preSelectedCompanyId = '',
  showApiValidationMessage = false,
  showJustFileName = false,
  showOnlyActiveCompanies = false,
  showListForWasteCompanies = true,
  showOrderClaimOptions = false,
  showUploadWarningMessage = false,
  subLabelNewLine = false,
  uploadLabel = '',
}) => {
  const dispatch = useDispatch()

  const user = useSelector(getCurrentUserSelector)

  return (
    <RequiredPermissions requiredPermissions={permissions}>
      <div className='upload-document-form'>
        {/* DESCRIPTION */}
        {description && (
          <p className='uk-text-meta uk-margin-remove'>{description}</p>
        )}
        <Formik
          initialValues={{
            company: '',
            phone_user: '',
            claim_type: '',
            claim_description: '',
            business_segment: undefined,
            attachments: [],
          }}
          validationSchema={() =>
            yup.object().shape({
              company: yup.string().typeError(''),
              phone_user: yup.string().typeError(''),
              business_segment: yup.number().typeError(''),
              claim_type: yup.string().typeError(''),
              claim_description: yup.string().typeError(''),
            })
          }
          validateOnBlur={false}
          validate={(values: UploadDocumentFormValues) => {
            const errors: {
              company?: string
              phone_user?: string
              business_segment?: string
              claim_type?: string
              claim_description?: string
              attachments?: string
            } = {}

            if (
              !user.company &&
              !preSelectedCompanyId &&
              values.company === ''
            ) {
              errors.company = I18n.t(
                'uploadReviewDocumentTranslations.validation.selectedCompany',
              )
            }

            if (!user.company && values.phone_user === '') {
              errors.phone_user = I18n.t(
                'uploadReviewDocumentTranslations.validation.selectedUser',
              )
            }

            if (
              documentType === CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT &&
              !values.business_segment
            ) {
              errors.business_segment = I18n.t(
                'uploadReviewDocumentTranslations.validation.selectedBusinessSegment',
              )
            }

            if (!showOrderClaimOptions) {
              if (values.attachments.length < 1) {
                errors.attachments = I18n.t(
                  'uploadReviewDocumentTranslations.validation.attachments',
                )
              }
            }

            if (showOrderClaimOptions) {
              if (values.claim_type === '') {
                errors.claim_type = I18n.t(
                  'uploadReviewDocumentTranslations.validation.claimType',
                )
              }

              if (
                values.claim_description === '' &&
                Number(values.claim_type) === ORDER_CLAIM_TYPE.TYPE_OTHERS
              ) {
                errors.claim_description = I18n.t(
                  'uploadReviewDocumentTranslations.validation.claimDescription',
                )
              }
            }

            return errors
          }}
          onSubmit={(values: UploadDocumentFormValues) => {
            const callbacks = {
              onSuccess: () => onCloseForm(),
            }
            const valuesToSend: Partial<UploadDocumentFormValues> & {
              callbacks: { onSuccess: () => void }
            } = {
              ...values,
              callbacks,
            }

            if (
              valuesToSend.phone_user === '' &&
              user.company_object.role === COMPANY_ROLE.MIDDLE_MAN
            ) {
              valuesToSend.phone_user = user.id.toString()
            }

            if (!showOrderClaimOptions) {
              delete valuesToSend.claim_type
              delete valuesToSend.claim_description
            }

            if (
              permissions.includes(
                UserPermission.REPORT_MAKLER_PREMIUM_CLAIMS,
              ) &&
              checkRequiredPermissions(user.permission_codenames, [
                UserPermission.REPORT_MAKLER_PREMIUM_CLAIMS,
              ])
            ) {
              if (valuesToSend.company && valuesToSend.company.length > 0) {
                // If user is Makler Premium, they can report problems on behalf of their clients
                delete valuesToSend.phone_user
              } else {
                valuesToSend.company = (document as Order).offer_object
                  ? `${(document as Order).offer_object.company_object.id}`
                  : ''
              }
            } else if (user.company) {
              // If user has a company
              delete valuesToSend.phone_user
            } else if (!user.company && preSelectedCompanyId) {
              delete valuesToSend.company
            }

            if ((valuesToSend.attachments?.length ?? 0) > 0) {
              const uploadCallbacks = {
                onDocumentSubmit: attachments =>
                  onDocumentSubmit({ ...valuesToSend, attachments }),
              }
              // map attachments with their comments
              const attachments = values.attachments.map(uploadItem => ({
                file: uploadItem.data,
                text: '',
              }))
              dispatch(uploadFiles(attachments, uploadCallbacks))
            } else {
              onDocumentSubmit(valuesToSend)
            }
          }}
        >
          <UploadDocumentFormFields
            preSelectedCompanyId={preSelectedCompanyId}
            showApiValidationMessage={showApiValidationMessage}
            apiError={apiError}
            isLoading={isLoading}
            showOrderClaimOptions={showOrderClaimOptions}
            document={document}
            documentType={documentType}
            showUploadWarningMessage={showUploadWarningMessage}
            uploadLabel={uploadLabel}
            maxFiles={maxFiles}
            subLabelNewLine={subLabelNewLine}
            doNotShowNumberOfUploadedFiles={doNotShowNumberOfUploadedFiles}
            showJustFileName={showJustFileName}
            showOnlyActiveCompanies={showOnlyActiveCompanies}
            acceptFileTypes={acceptFileTypes}
            onCloseForm={onCloseForm}
            showListForWasteCompanies={showListForWasteCompanies}
            aditionalApiFetchError={aditionalApiFetchError}
          />
        </Formik>
      </div>
    </RequiredPermissions>
  )
}
