import uniqueId from 'lodash.uniqueid'
import React, { FC, useEffect } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { UserPermission } from 'constants/user'
import { uploadAutoOffers } from 'actions/autooffer'
import { resetApiFetchErrors } from 'actions/app'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { UploadDocumentForm } from '../../common/UploadDocumentForm'

interface UploadAutoOffersModalProps {
  isOpen?: boolean
  onClose?: () => void
}

export const UploadAutoOffersModal: FC<UploadAutoOffersModalProps> = ({
  isOpen = false,
  onClose = () => undefined,
}) => {
  const dispatch = useDispatch()

  const apiError = useSelector(
    createErrorSelector(['UPLOAD_AUTO_OFFERS']),
  ) as ApiError
  const isLoading = useSelector(createLoadingSelector(['UPLOAD_AUTO_OFFERS']))

  const idUploadAutoOffersFormUploadModalHeadline = uniqueId()

  // Altough there is no effect to cleanup, we can use the "useEffect" capabilities to reset the ApiErrors on
  // general cleanup. This will behave like previously using "componentWillUnmount". A sole function is returned by
  // the effect with the desired action
  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors('UPLOAD_AUTO_OFFERS'))
    },
    [dispatch],
  )

  return (
    <Modal
      ariaDescribedBy={idUploadAutoOffersFormUploadModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={I18n.t('myAutoOffersPageTranslations.csvImport.title')}
        titleId={idUploadAutoOffersFormUploadModalHeadline}
      />

      <UploadDocumentForm
        acceptFileTypes='text/csv'
        aditionalApiFetchError='UPLOAD_AUTO_OFFERS_REQUEST'
        apiError={apiError}
        description={
          <Translate
            value='myAutoOffersPageTranslations.csvImport.description'
            dangerousHTML
          />
        }
        doNotShowNumberOfUploadedFiles
        isLoading={isLoading}
        maxFiles={1}
        onCloseForm={onClose}
        onDocumentSubmit={values => dispatch(uploadAutoOffers(values))}
        permissions={[UserPermission.ADD_AUTOOFFER]}
        showJustFileName
        showOnlyActiveCompanies
        showUploadWarningMessage={
          apiError &&
          apiError.response &&
          (apiError.response.showError as boolean)
        }
        uploadLabel={I18n.t('myAutoOffersPageTranslations.csvImport.label')}
      />
    </Modal>
  )
}
