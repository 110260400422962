import './style.scss'

import { useFormikContext } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, { FC, useContext, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getAddressListForCompany } from 'actions/address'
import { getContainers } from 'actions/container'
import { getFractions } from 'actions/fraction'
import { getSecurityGroups } from 'actions/securitygroup'
import { getCompanyUsers } from 'actions/user'
import { resetZipcode } from 'actions/zipCode'
import { AddressForm } from 'components/common/AddressForm'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { FractionAndContainerFormFields } from 'components/common/FractionAndContainerFormFields'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { Option } from 'components/common/StaticCombobox/StaticCombobox'
import { ADDRESS_STATUS } from 'components/company/constants'
import { ADD_ADDRESS_OPTION } from 'components/inquiry/constants'
import { getAddressListForCompanySelector } from 'selectors/address'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'
import { createLoadingSelector } from 'selectors/loading'
import { getSecurityGroupsSelector } from 'selectors/securitygroup'

import {
  AddAgreementFormContext,
  AddAgreementFormValues,
} from '../AddAgreementFormSteps'

export const SelectAddressContainerFractionAvvForm: FC = () => {
  const dispatch = useDispatch()
  const addressList = useSelector<any, Address[]>(
    getAddressListForCompanySelector,
  )
  const fractionList = useSelector<any, Fraction[]>(getFractionsSelector)
  const containerList = useSelector<any, Container[]>(getContainersSelector)
  const securityGroupList = useSelector<any, SecurityGroup[]>(
    getSecurityGroupsSelector,
  )
  const idAddressModalHeadline = uniqueId()

  const { setFieldValue, values } = useFormikContext<AddAgreementFormValues>()
  const { stepStatus, saveStep } = useContext(AddAgreementFormContext)

  const isLoading = {
    addressList: useSelector<any, boolean>(state =>
      createLoadingSelector(['GET_ADDRESSES_FOR_COMPANY'])(state),
    ),
    fractionList: useSelector<any, boolean>(state =>
      createLoadingSelector(['GET_FRACTIONS'])(state),
    ),
    containerList: useSelector<any, boolean>(state =>
      createLoadingSelector(['GET_CONTAINERS'])(state),
    ),
    securityGroupList: useSelector<any, boolean>(state =>
      createLoadingSelector(['GET_SECURITY_GROUPS'])(state),
    ),
  }

  useEffect(() => {
    if (fractionList.length < 1) dispatch(getFractions())

    if (containerList.length < 1) dispatch(getContainers())

    if (securityGroupList.length < 1) dispatch(getSecurityGroups())
  }, [
    dispatch,
    fractionList.length,
    containerList.length,
    securityGroupList.length,
  ])

  // Prepare addresses for dropdown
  useEffect(() => {
    const options = addressList.map(address => ({
      label: address.display_name,
      value: Number(address.id),
    })) as Option[]
    options.unshift({
      label: I18n.t(ADD_ADDRESS_OPTION.label),
      value: ADD_ADDRESS_OPTION.value,
    })
    setAddressOptions(options)
  }, [addressList])

  // Get address for selected company
  useEffect(() => {
    dispatch(
      getAddressListForCompany(values.customer?.selected_company?.id, {
        status: ADDRESS_STATUS.STATUS_ACTIVE,
      }),
    )
  }, [dispatch, values.customer.selected_company])

  const securityGroupFilterOptions = [4, 8]
  const defaultSelectedSecurityGroupId = 4

  const [addressOptions, setAddressOptions] = useState<Option[]>([])
  const [currentSelectedAddress, setCurrentSelectedAddress] = useState<Option>()
  const [openAddAddressModal, setOpenAddAddressModal] = useState<boolean>(false)

  useEffect(() => {
    if (values.collection_address) {
      setCurrentSelectedAddress(
        addressOptions.find(
          option => option.value === values.collection_address,
        ),
      )
    }
  }, [addressOptions, values.collection_address])

  const handleChangeAddress = event => {
    setCurrentSelectedAddress(
      addressOptions.find(
        option => option.value === Number(event.target.value),
      ),
    )
    if (event.target.value === ADD_ADDRESS_OPTION.value) {
      setFieldValue('collection_address', '')
      dispatch(getCompanyUsers(values.customer?.selected_company?.id, true))
      setOpenAddAddressModal(true)
      dispatch(resetZipcode())
    } else {
      setFieldValue('collection_address', Number(event.target.value))
    }
  }

  return (
    <>
      <div className='maklerpremium-agreementform__step2'>
        <StaticCombobox
          label={I18n.t(
            `addAgreementPageTranslations.steps.2.fields.address.label`,
          )}
          dataTestId='address-input'
          subLabel={I18n.t(
            'addAgreementPageTranslations.steps.2.fields.address.subLabel',
          )}
          isLoading={isLoading.addressList}
          name='collection_address'
          noResultsText={I18n.t(
            'addAgreementPageTranslations.steps.2.fields.address.noInputResults',
          )}
          options={addressOptions}
          onSelectionChange={handleChangeAddress}
          placeholder={I18n.t(
            'addAgreementPageTranslations.steps.2.fields.address.placeholder',
          )}
          selectedOption={
            values.collection_address ? currentSelectedAddress : undefined
          }
          withCheckmark
          showCheckmark={!!values.collection_address}
        />

        {!isLoading.containerList &&
          !isLoading.fractionList &&
          !isLoading.securityGroupList && (
            <FractionAndContainerFormFields
              hideDismissableInfos
              showAvvField
              useAvvAsyncSelect={false}
              showSecurityGroupField
              securityGroupFilterOptions={securityGroupFilterOptions}
              defaultSelectedSecurityGroupId={defaultSelectedSecurityGroupId}
            />
          )}

        <GoToNextFormStepButton
          buttonText={
            stepStatus === STEP_STATUS.EDITING
              ? I18n.t('general.button.save')
              : I18n.t('createInquiryTranslations.form.button.nextStep')
          }
          onClick={saveStep}
          isDisabled={
            !(
              values.collection_address &&
              values.fraction &&
              values.avv &&
              values.container &&
              values.number_of_containers
            )
          }
        />
      </div>

      <Modal
        ariaDescribedBy={idAddressModalHeadline}
        isOpen={openAddAddressModal}
        onClose={() => setOpenAddAddressModal(false)}
      >
        <ModalHeader
          onClose={() => setOpenAddAddressModal(false)}
          title={I18n.t('addressForm.header.title')}
          titleId={idAddressModalHeadline}
        />

        <AddressForm
          onCancel={() => setOpenAddAddressModal(false)}
          onSuccess={(address: Address) => {
            setFieldValue('collection_address', address.id)
            setFieldValue('zipcode', address.zipcode)
            setOpenAddAddressModal(false)
          }}
          sendUserInfo={{
            company: values.customer?.selected_company?.id,
          }}
        />
      </Modal>
    </>
  )
}
