import moment from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { ReactComponent as Clock } from 'assets/svg/detailspage/clock.svg'
import AccordionItem from 'components/common/Accordion/components/AccordionItem'
import { ReadOnlyField } from 'components/common/ReadOnly'
import {
  INQUIRY_ORDER_TYPE,
  INQUIRY_ORDER_TYPES,
  INQUIRY_SERVICE_INTERVAL,
  INQUIRY_SERVICE_INTERVAL_WEEKDAYS,
  INQUIRY_SERVICE_INTERVALS,
  INQUIRY_TIMES_OF_DAY,
} from 'components/inquiry/constants'
import { getName } from 'helper/translations'

import { OldAndNewValuesSectionProps } from '../scheme'

export const TimeSection: FC<
  OldAndNewValuesSectionProps & { containerList: Container[] }
> = ({ addScrollElement, containerList, offer }) => {
  const iconTitle = (
    <>
      <Clock className='inquiry-details-page__section-header' />
      {I18n.t('inquiryDetailsTranslations.section.time.title')}
    </>
  )

  const isOrderType = {
    oneTime:
      offer.order_type &&
      Number(offer.order_type) === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME,
    regular:
      offer.order_type &&
      Number(offer.order_type) === INQUIRY_ORDER_TYPE.TYPE_RECURRING,
    onDemand:
      offer.order_type &&
      Number(offer.order_type) === INQUIRY_ORDER_TYPE.TYPE_ON_DEMAND,
    quick:
      offer.order_type &&
      Number(offer.order_type) === INQUIRY_ORDER_TYPE.TYPE_QUICK,
  }

  const offerDeliveryTime =
    offer &&
    `${moment(offer.delivery_date).format('L')}, ` +
      `${getName(offer.time_of_day_delivery, INQUIRY_TIMES_OF_DAY, true)}`
  const offerCollectionTime =
    offer &&
    `${moment(offer.collection_date).format('L')}, ` +
      `${getName(offer.time_of_day_collection, INQUIRY_TIMES_OF_DAY, true)}`

  const offerContainer =
    offer && containerList.find(_item => `${_item.id}` === `${offer.container}`)

  const offerOrInquiryInterval = offer.interval
  let offerDays
  if (isOrderType.regular && offer.interval) {
    if (
      offer &&
      offer.interval === INQUIRY_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK
    ) {
      offerDays = [
        offer && offer.interval_weekday_first,
        offer && offer.interval_weekday_second,
      ]
    } else {
      offerDays = [offer && offer.interval_weekday_first]
    }
  }

  return (
    <AccordionItem addScrollElement={addScrollElement} title={iconTitle}>
      <div
        className='inquiry-details-page__section uk-child-width-1-2@m'
        data-uk-grid=''
      >
        {/* RUNTIME OF INQUIRY */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.time.fields.runtimeOfInquiry',
          )}
          value={moment(offer.runtime_of_inquiry).format('L HH:mm')}
        />
        {/* ORDER TYPE */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.time.fields.orderType',
          )}
          value={getName(offer.order_type, INQUIRY_ORDER_TYPES, true)}
        />
        {/* DELIVERY DATE, TIME OF DAY */}
        {(isOrderType.oneTime || isOrderType.quick) && offer.delivery_date && (
          <ReadOnlyField
            label={I18n.t(
              'inquiryDetailsTranslations.section.time.fields.deliveryDateAndTime',
            )}
            value={offerDeliveryTime}
          />
        )}
        {/* COLLECTION DATE, TIME OF DAY */}
        {isOrderType.oneTime && offer.collection_date && (
          <ReadOnlyField
            label={I18n.t(
              'inquiryDetailsTranslations.section.time.fields.collectionDateAndTime',
            )}
            value={offerCollectionTime}
          />
        )}
        {/* TURN BEGIN DATE */}
        {(isOrderType.regular || isOrderType.onDemand) && offer.turn_begin && (
          <ReadOnlyField
            label={I18n.t(
              'inquiryDetailsTranslations.section.time.fields.' +
                `${isOrderType.regular ? 'turnBegin' : 'agreementBegin'}`,
            )}
            value={offer ? moment(offer.turn_begin).format('L') : ''}
          />
        )}
        {/* TURN END DATE */}
        {(isOrderType.regular || isOrderType.onDemand) && offer.turn_end && (
          <ReadOnlyField
            label={I18n.t(
              'inquiryDetailsTranslations.section.time.fields.' +
                `${isOrderType.regular ? 'turnEnd' : 'agreementEnd'}`,
            )}
            value={moment(offer && offer.turn_end).format('L')}
          />
        )}
        {/* INTERVAL */}
        {isOrderType.regular && offer.interval && (
          <ReadOnlyField
            label={I18n.t(
              'inquiryDetailsTranslations.section.time.fields.serviceInterval',
            )}
            value={getName(
              offer && offer.interval,
              INQUIRY_SERVICE_INTERVALS,
              true,
            )}
          />
        )}
        {/* WEEKDAYS */}
        {isOrderType.regular && offer.interval && (
          <ReadOnlyField
            label={I18n.t(
              'inquiryDetailsTranslations.section.time.fields.serviceIntervalWeekdays',
              {
                count:
                  offerOrInquiryInterval ===
                  INQUIRY_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK
                    ? 2
                    : 1,
              },
            )}
            value={offerDays
              .map(value =>
                value != null
                  ? getName(value, INQUIRY_SERVICE_INTERVAL_WEEKDAYS, true)
                  : I18n.t('general.allTheSame'),
              )
              .join(', ')}
          />
        )}
        {/* REACTION TIME */}
        {isOrderType.onDemand && offer.container && (
          <ReadOnlyField
            label={I18n.t(
              'inquiryDetailsTranslations.section.time.fields.reactionTime',
            )}
            value={
              offerContainer
                ? I18n.t(
                    `constant.date.interval.hours${offerContainer.reaction_time}`,
                  )
                : ''
            }
          />
        )}
      </div>
    </AccordionItem>
  )
}
