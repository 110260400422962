import { shape, string } from 'prop-types'

export const LocationScheme = {
  state: shape({
    from: shape({
      pathname: string,
      breadcrumb: string,
    }),
  }),
}
