import { LoadScriptNext } from '@react-google-maps/api'
import React from 'react'

import { GoogleMapsParms } from './constants'
import { Maps } from './Maps'

export const GoogleMaps = props => (
  <LoadScriptNext id='script-loader' googleMapsApiKey={GoogleMapsParms.key}>
    <Maps {...props} />
  </LoadScriptNext>
)
