import './style.scss'

import { Circle } from 'rc-progress'
import React, { useState, FC } from 'react'
import { I18n } from 'react-i18nify'

import { WEEKDAY } from 'constants/app'
import { COLOR } from 'constants/design'
import { getDateInfo, localizeTime } from 'helper/general'
import { getName } from 'helper/translations'
import { Button } from 'components/common/Button'
import { ORDER_CLAIM_PROOF_STATUS } from 'components/common/DocumentReview/OrderClaimReview/OrderClaimDetails/constants'
import Icon from 'components/common/Fontello'
import { getWeekdayName } from 'components/inquiry/CreateInquiryPage/helpers'
import { ORDER_STATUS } from 'components/order/constants'
import { ORDER_CANCELLATION_REASONS } from 'components/order/OrderDetailsPage/components/OrderCancelForm'

import ItemInfo, { ICON_TYPE } from './components/ItemInfo'
import { OrderStatusRelabeledModal } from './components/OrderStatusRelabeledModal/'
import {
  claimAcceptedStatusMessage,
  claimRejectedStatusMessage,
} from './helper'

interface OrderStatusListItemComponentProps {
  order: Order
  orderStatus: OrderStatus
  orderStatusConfig: OrderStatusConfig
  relabeledType: string | undefined
  isDone: boolean
}

export const OrderStatusListItemComponent: FC<
  OrderStatusListItemComponentProps
> = ({
  order,
  orderStatus,
  orderStatusConfig,
  relabeledType = undefined,
  isDone = false,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const handleModalToggle = () => setOpenModal(!openModal)

  /**
   * * @description Get message and date string to use in the item display
   * @return {*}
   */
  const getMessageData = () => {
    const history_invoice_check = orderStatus.order_history?.invoice_check

    return {
      name: orderStatus.claimed_by ?? orderStatus.created_by_name,
      aez: order.offer_object.customer_company_object.name,
      disposer: order.offer_object.company_object.name,
      deliveryDate: getDateInfo(
        order.delivery_date,
        order.time_of_day_delivery,
      ),
      deliveryDateDisposed: getDateInfo(
        order.delivery_date_disposed,
        order.time_of_day_delivery_disposed,
      ),
      deliveryDatePostpone: getDateInfo(
        orderStatus.order_history
          ? orderStatus.order_history.delivery_date
          : order.delivery_date_postponed,
        orderStatus.order_history
          ? orderStatus.order_history.time_of_day_delivery
          : order.time_of_day_delivery_postponed,
      ),
      deliveryDateExecuted: getDateInfo(order.delivery_date_executed, null),
      collectionDate: getDateInfo(
        order.offer_object.collection_date,
        order.offer_object.time_of_day_collection,
      ),
      collectionDatePostpone: getDateInfo(
        orderStatus.order_history
          ? orderStatus.order_history.collection_date
          : order.collection_date_postponed,
        orderStatus.order_history
          ? orderStatus.order_history.time_of_day_collection
          : order.time_of_day_collection_postponed,
      ),
      collectionDateExecuted: getDateInfo(order.collection_date_executed, null),
      etaDelivery: localizeTime(order.eta_delivery) || '',
      etaCollection: localizeTime(order.eta_collection) || '',
      interval_weekday_first: Object.values(WEEKDAY).includes(
        orderStatus.order_history?.interval_weekday_first,
      )
        ? I18n.t(
            getWeekdayName(orderStatus.order_history?.interval_weekday_first),
          )
        : '',
      interval_weekday_second: Object.values(WEEKDAY).includes(
        orderStatus.order_history?.interval_weekday_second,
      )
        ? I18n.t(
            getWeekdayName(orderStatus.order_history?.interval_weekday_second),
          )
        : '',
      cancellation_reason: getName(
        order.cancellation_reason,
        ORDER_CANCELLATION_REASONS,
        true,
      ),
      cancellation_reason_text: `<br><b>${order.cancellation_reason_text}</b>`,
      invoice_check_id: history_invoice_check?.id,
      invoice_check_document_number: history_invoice_check?.document_number,
      invoice_check_category: orderStatus.order_history?.invoice_check_category,
      invoice_check_partner_slug: history_invoice_check?.partner_slug,
      invoice_check_partner_id: history_invoice_check?.partner_id,
      invoice_check_partner_name: history_invoice_check?.partner_name,
      previousDisposerName: orderStatus.order_history?.previous_disposer_name,
      newDisposerName: orderStatus.order_history?.new_disposer_name,
    }
  }
  const message = I18n.t(orderStatusConfig.message, getMessageData())

  return (
    <div
      key={orderStatus.id}
      className='order-status-list-item'
      data-testid='order-status-list-item'
    >
      {orderStatusConfig.progress && (
        <ItemInfo
          createdByName={orderStatus.created_by_name}
          creationDate={orderStatus.created_at}
          message={message}
          icon={
            <Circle
              percent={orderStatusConfig.progress}
              strokeColor={isDone ? COLOR.JADE : COLOR.BUTTERCUP}
              strokeWidth={15}
            />
          }
          iconType={ICON_TYPE.CIRCLE}
        />
      )}

      {/* In case the order status is EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE, more information about
        new price fields need no be shown */}
      {orderStatus.value ===
        ORDER_STATUS.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE && (
        <div className='order-status-list-item__price-button'>
          <Button onClick={handleModalToggle}>
            {I18n.t('orderStatusList.viewUpdatedPriceFields')}
          </Button>
        </div>
      )}

      {orderStatus.claim_status ===
        ORDER_CLAIM_PROOF_STATUS.STATUS_ACCEPTED && (
        <div className='order-status-list-item__claim-status-message'>
          <ItemInfo
            createdByName={orderStatus.created_by_name}
            creationDate={orderStatus.claim_review_date}
            message={claimAcceptedStatusMessage(
              orderStatus.claim_company,
              order.offer_object.customer_company_object.id,
              orderStatus.value,
              orderStatus.company_name,
            )}
          />
        </div>
      )}

      {orderStatus.claim_status ===
        ORDER_CLAIM_PROOF_STATUS.STATUS_REJECTED && (
        <div className='order-status-list-item__claim-status-message'>
          <ItemInfo
            createdByName={orderStatus.created_by_name}
            creationDate={orderStatus.claim_review_date}
            message={claimRejectedStatusMessage(
              orderStatus.claim_company,
              order.offer_object.customer_company_object.id,
              orderStatus.value,
              orderStatus.company_name,
            )}
            icon={<Icon name='cross' />}
            iconType={ICON_TYPE.CROSS}
          />
        </div>
      )}

      {relabeledType && (
        <OrderStatusRelabeledModal
          onClose={handleModalToggle}
          order={order}
          orderStatus={orderStatus}
          relabeledType={relabeledType}
          isOpen={openModal}
        />
      )}
    </div>
  )
}

export default OrderStatusListItemComponent
