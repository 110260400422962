import { createSelector } from 'reselect'

/**
 * @description selector to get an offer list pagination.
 * @param state
 * @return {*}
 */
export const getAutoOffersPaginationSelector: (
  state: any,
) => Pagination = state => state.autoOffer.autoOfferList.pagination

/**
 * @description selector to get a list of the auto offers (automatic bids) of the current user's company.
 * @param state
 * @return {*}
 */
export const getMyAutoOffersSelector: (state: any) => AutoOffer[] =
  createSelector(
    state => state.autoOffer.autoOfferList.data,
    autoOffers =>
      autoOffers.map(autoOffer => ({
        ...autoOffer,
      })),
  )

/**
 * @description selector to get the currently loaded auto offer
 * @param state
 * @return {*}
 */
export const getAutoOfferSelector: (state: any) => AutoOffer = state =>
  state.autoOffer.item

/**
 * @description selector to get the last updated auto offer item. This is required to identify
 * whether the current form submitted successfully when saving an auto offer.
 * @param state
 * @return {*}
 */
export const getUpdatedAutoOfferSelector: (state: any) => AutoOffer = state =>
  state.autoOffer.updatedItem
