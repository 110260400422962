import { INQUIRY_ORDER_TYPE } from '../components/inquiry/constants'
import {
  ORDER_STATES_OBJECT,
  ORDER_STATUS,
} from '../components/order/constants'
import { MaklerPremiumGroups } from '../constants/user'

/**
 * @description get a order status constant data.
 * @param orderStatus
 * @param order
 * @param user
 * @param relabeledType
 * @return {*}
 */
export const getStatusConf = (
  orderStatus,
  order,
  user,
  relabeledType = undefined,
) => {
  if (user.group_id in MaklerPremiumGroups) {
    if (orderStatus.value === ORDER_STATUS.INVOICING_DOCUMENTS_CREATED) {
      return {
        id: ORDER_STATUS.INVOICING_DOCUMENTS_CREATED,
        name: 'constant.orderStatus.INVOICING_DOCUMENTS_CREATED',
        message:
          'constant.orderStatusMessage.MAKLERPREMIUM_INVOICING_DOCUMENTS_CREATED',
        progress: 140,
        totalProgress: 140,
      }
    }

    if (orderStatus.value === ORDER_STATUS.OTHER_INVOICING_DOCUMENTS_CREATED) {
      return {
        id: ORDER_STATUS.OTHER_INVOICING_DOCUMENTS_CREATED,
        name: 'constant.orderStatus.OTHER_INVOICING_DOCUMENTS_CREATED',
        message:
          'constant.orderStatusMessage.MAKLERPREMIUM_INVOICING_DOCUMENTS_CREATED_OTHER_SERVICES',
        progress: 145,
        totalProgress: 145,
      }
    }
  }

  // Sonderfall Einmalauftrag in Statusliste/Detailansicht
  if (order.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME) {
    switch (orderStatus.value) {
      case ORDER_STATUS.DISPOSER_WILL_EXECUTE:
        return ORDER_STATES_OBJECT[ORDER_STATUS.DISPOSER_WILL_EXECUTE_ONE_TIME]
      case ORDER_STATUS.DISPOSER_DID_POSTPONE_DELIVERY:
        return ORDER_STATES_OBJECT[
          ORDER_STATUS.DISPOSER_DID_POSTPONE_DELIVERY_ONE_TIME
        ]
      case ORDER_STATUS.DISPOSER_WILL_ARRIVE_DELIVERY:
        return ORDER_STATES_OBJECT[
          ORDER_STATUS.DISPOSER_WILL_ARRIVE_DELIVERY_ONE_TIME
        ]
      case ORDER_STATUS.DISPOSER_DID_EXECUTE_DELIVERY:
        return ORDER_STATES_OBJECT[
          ORDER_STATUS.DISPOSER_DID_EXECUTE_DELIVERY_ONE_TIME
        ]
      default:
        break
    }
  }
  // Sonderfall Sofortbefüllung auf der Kachel (letzter / aktueller Status)
  if (
    order.order_type === INQUIRY_ORDER_TYPE.TYPE_QUICK &&
    orderStatus === ORDER_STATUS.DISPOSER_WILL_EXECUTE
  ) {
    return ORDER_STATES_OBJECT[ORDER_STATUS.DISPOSER_WILL_EXECUTE_QUICK]
  }

  // Sonderfall Dauerauftrag im Turnus auf der Kachel (letzter / aktueller Status)
  if (
    order.order_type === INQUIRY_ORDER_TYPE.TYPE_RECURRING &&
    orderStatus === ORDER_STATUS.DISPOSER_WILL_EXECUTE
  ) {
    return ORDER_STATES_OBJECT[ORDER_STATUS.DISPOSER_WILL_EXECUTE_REGULAR]
  }

  // Sonderfall Leistungsnachweis abgelehnt
  if (
    orderStatus === ORDER_STATUS.EXECUTION_PROOFS_REJECTED &&
    user.company === order.customer_company
  ) {
    return ORDER_STATES_OBJECT[ORDER_STATUS.EXECUTION_PROOFS_REJECTED_PRODUCER]
  }

  if (
    orderStatus.value ===
      ORDER_STATUS.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE &&
    relabeledType
  ) {
    return ORDER_STATES_OBJECT[relabeledType]
  }

  // Standardbehandlung in Statusliste/Detailansicht
  if (typeof orderStatus.value === 'number') {
    return ORDER_STATES_OBJECT[orderStatus.value]
  }

  // Standardbehandlung auf der Kachel (letzter / aktueller Status)
  return ORDER_STATES_OBJECT[orderStatus]
}

export default {}
