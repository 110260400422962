import './style.scss'

import classNames from 'classnames'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { getLoggedInSelector } from 'selectors/user'

import { MenuItems } from '../MenuItems'
import { MenuType } from '../MenuItems/constants'
import { UserInfo } from '../UserDropdown/components/UserInfo'

/**
 * @description OffCanvas Menu for mobile screens.
 * @description Displays a burger menu button and renders an offcanvas navigation by clicking it.
 * @constructor
 */
export const OffCanvasMenu = () => {
  const history = useHistory()
  const [isToggled, setIsToggled] = useState(false)

  // ID for the OffCanvasPortal DOM Element.
  const offCanvasPortalID = 'OffCanvasPortal'

  // Reference to the OffCanvasPortal DOM Element.
  const offCanvasPortalRef = useRef<HTMLDivElement>()

  // Reference to the body DOM Element.
  const bodyDomElementRef = useRef(document.querySelector('body'))

  // Reference to the OffCanvasBackgroundLayer DOM Element.
  const offCanvasBackgroundLayerRef = useRef(null)

  /**
   * Toggles the OffCanvas state.
   */
  const toggleOffCanvas = useCallback(() => {
    // add offcanvas-open class to body and remove overflow so scrollbars can't conflict with menu
    bodyDomElementRef.current!.className = isToggled ? '' : 'offcanvas-open'
    setIsToggled(!isToggled)
  }, [isToggled])

  const isLoggedIn = !!useSelector(getLoggedInSelector)

  /**
   * Create a OffCanvasPortal container and add it to the DOM.
   * Also listen to react-router-dom history and handle them.
   */

  useEffect(() => {
    // Create a DOM Element as a react portal and add it to the body.
    offCanvasPortalRef.current = document.createElement('div')
    offCanvasPortalRef.current.id = offCanvasPortalID
    bodyDomElementRef.current!.appendChild(offCanvasPortalRef.current)

    const bodyElement = bodyDomElementRef.current

    // Listen to react-router-dom history changes and handle them.

    return () => {
      /**
       * Remove the OffCanvasPortal container from DOM when this component won't be mounted anymore.
       */
      bodyElement!.removeChild(offCanvasPortalRef.current as Node)
      offCanvasPortalRef.current = undefined
    }
  }, [])

  /**
   * Add an EventListener to the background layer, to handle clicks outside the menu.
   */
  useEffect(() => {
    /**
     * Handles the OffCanvas behaviour if the react-router-dom history pushes change events.
     */
    const handleHistoryChanges = (_location: any, action: string) => {
      const routeHasChangedAndOffCanvasIsOpen = action === 'PUSH' && isToggled
      if (routeHasChangedAndOffCanvasIsOpen) toggleOffCanvas()
    }
    history.listen(handleHistoryChanges)
  }, [history, isToggled, toggleOffCanvas])

  const isSafari =
    !!window.navigator.userAgent.match(/(iPhone|iPad|iPod)/i) &&
    !!window.navigator.userAgent.match(/Safari/i)

  return (
    <>
      <button
        type='button'
        className={classNames('hamburger', 'hamburger--slider', {
          'is-active': isToggled,
        })}
        aria-label={I18n.t(
          isToggled
            ? 'general.hideOffCanvasNavigation'
            : 'general.displayOffCanvasNavigation',
        )}
        onClick={toggleOffCanvas}
      >
        <span className='hamburger-box'>
          <span className='hamburger-inner' />
        </span>
      </button>

      {isToggled &&
        offCanvasPortalRef.current &&
        createPortal(
          <>
            <nav
              className={classNames('offcanvas', {
                'offcanvas-ios': isSafari,
              })}
            >
              {isLoggedIn && (
                <header className='uk-flex uk-flex-middle'>
                  <UserInfo displayEmailAddress={false} />
                </header>
              )}
              <ul
                className='uk-nav uk-nav-default uk-nav-parent-icon'
                data-uk-nav='data-uk-nav'
                data-name='bene-mobile-menu'
              >
                <MenuItems
                  activeClassName='active'
                  menuType={MenuType.Mobile}
                  showIcon
                />
              </ul>
            </nav>
            <div
              className='offcanvas-background'
              ref={offCanvasBackgroundLayerRef}
              aria-hidden='true'
            />
          </>,
          offCanvasPortalRef.current,
        )}
    </>
  )
}
