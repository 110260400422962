import './style.scss'

import React, { FC } from 'react'

import WasteProducerFallbackImage from 'assets/svg/detailspage/company_gradient.svg'
import WasteCompanyFallbackImage from 'assets/svg/detailspage/truck_gradient.svg'
import { COMPANY_ROLE } from 'components/company/constants'

/**
 * @description This component displays the company profile image
 */
export const CompanyProfileImage: FC<{ company?: Company }> = ({ company }) => {
  if (!company) {
    return null
  }

  const getCompanyProfileImage = (company: Company) => {
    if (company.profile_image_object) {
      return (
        <img
          className='company-profile-image--image'
          src={company.profile_image_object.thumbnail}
          alt={company.name}
        />
      )
    } else {
      if (company.role === COMPANY_ROLE.WASTE_COMPANY) {
        return (
          <img
            className='company-profile-image--fallback'
            src={WasteCompanyFallbackImage}
            alt={company.name}
          />
        )
      } else {
        return (
          <img
            className='company-profile-image--fallback'
            src={WasteProducerFallbackImage}
            alt={company.name}
          />
        )
      }
    }
  }

  return (
    <div className='company-profile-image uk-width-1-5 uk-width-1-1@m uk-width-1-1@l uk-display-inline-block'>
      {getCompanyProfileImage(company)}
    </div>
  )
}
