const advantagesForWasteProducers = [
  'Intuitive Erstellung von Anfragen für einmalige und dauerhafte Vereinbarungen',
  'Einfacher Angebotsvergleich',
  'Bequeme Beauftragung und Statusverfolgung',
  'Verständliche, einheitliche Fakturierung',
  'Alle Nachweise jederzeit verfügbar',
]

const advantagesForDisposalCompanies = [
  'Bequeme Gewinnung neuer Kunden',
  'Übersichtliche, standardisierte Beschreibung der Anfragen',
  'Einfache Angebotserstellung sowie Angebotsverfolgung',
  'Leichte und unkomplizierte Auftragsdokumentation',
  'Garantierte Bezahlung innerhalb von einer Woche nach Bereitstellung des Leistungsnachweises ' +
    '(Lieferschein, Wiegeschein)',
]

const autoOffer = [
  'Der gültige Indexwert (Wert des Vormonats) für das betreffende Material bleibt über die gesamte Dauer der ' +
    'Vereinbarung unverändert.',
  'Das Gewicht pro Behälter entspricht dem von empto hinterlegten Schüttgewicht. Gerne stellen wir Ihnen auf ' +
    'Wunsch eine Übersicht der Schüttgewichte pro Kombination Abfallart/Behälter zur Verfügung.',
  'Bei der Auftragsart „Dauerauftrag auf Abruf“ wird der Abfall dieser Leistungsadresse alle 14 Tage entsorgt.',
]

const advantagesForWasteProducersList = `<ul class='uk-list uk-list-bullet line-height-20'>
${advantagesForWasteProducers.map(line => `<li>${line}</li>`).join('')}</ul>`

const advantagesForDisposalCompaniesList = `<ul class='uk-list uk-list-bullet line-height-20'>
${advantagesForDisposalCompanies.map(line => `<li>${line}</li>`).join('')}</ul>`

const autoOfferList = `<ul class='uk-list uk-list-bullet line-height-20'>
${autoOffer.map(line => `<li>${line}</li>`).join('')}</ul>`

export const pdfLink = `<a href='/pdf/Sortierkriterien.pdf' target='_blank' class='nav-link'>
empto Sortierkriterien</a>`

const phoneString = '0800 00 56356'
const phoneValue = '0800-00-56356'

const infoItems = {
  whereIsEmptoActive: {
    title: 'Wo ist empto aktiv?',
    content:
      'empto ist in einer Pilotregion gestartet, die weite Teile des nördlichen Rheinlands umfasste.' +
      ' Die Verfügbarkeit von empto wird seit dem kontinuierlich erweitert und so können immer mehr Regionen wie' +
      ' das Münsterland, Brandenburg, Frankfurt, München und Berlin den Marktplatz verwenden.' +
      ' Entsorgungsunternehmen und Gewerbetreibende wie zum Beispiel' +
      ' Bauunternehmen, Tankstellen, Lebensmittelhändler oder Werkstätten können' +
      ' den Marktplatz in den erschlossenen Regionen nutzen.<br /><br /> Bei der Erstellung der Leistungsadresse' +
      ' wird von empto automatisiert die Verfügbarkeit unserer Dienstleistungen geprüft. Falls wir in Ihrer Region' +
      ' noch nicht tätig sein sollten, informieren wir Sie gerne automatisch per E-Mail, sobald Sie von den Vorteilen' +
      ' unserer Plattform profitieren können.',
    // showPilotRegionModal: true,
  },
  howToContactEmpto: {
    title: 'Wie können Sie empto kontaktieren?',
    content:
      'Das empto Team steht Ihnen für Fragen gerne zur Verfügung.<br />' +
      `Telefon: <a href="tel:${phoneValue}">${phoneString}</a><br />` +
      'Montag - Freitag, 8 Uhr - 18 Uhr<br />' +
      'E-Mail: <a href="mailto:service@empto.de">service@empto.de</a>',
  },
}

const loggedOut = {
  whatIsEmpto: {
    title: 'Was ist empto?',
    content:
      'empto ist Ihr digitaler Abfallassistent. empto bringt Gewerbetreibende und ' +
      'Entsorgungsunternehmen zusammen – einfach, transparent und rechtssicher!<br /><br />Gewerbetreibende wie ' +
      'Bauunternehmen, Werkstätten oder Lebensmittelhändler beschreiben in kurzen Worten die benötige Leistung und ' +
      'erhalten daraufhin Angebote von mehreren Entsorgungsunternehmen. empto ermöglicht die Abwicklung ' +
      'sämtlicher Entsorgungsvorgänge – von der einmaligen Sofortbefüllung bis zur mehrjährigen Vereinbarung ' +
      'regelmäßiger Leerungen.<br /><br />Nach der Auftragsvergabe navigiert empto beide Parteien durch ' +
      'den gesamten Prozess. empto klärt Fragen, prüft Nachweise, generiert die Abrechnung und ' +
      'übernimmt den Zahlungsverkehr.',
  },
  whyEmpto: {
    title: 'Warum sollten Sie empto nutzen?',
    content: `Vorteile für Gewerbetreibende: <br /> ${advantagesForWasteProducersList} <br /> Vorteile für \
    Entsorger: <br /> ${advantagesForDisposalCompaniesList} `,
  },
  whoCanRegister: {
    title: 'Wer darf sich registrieren?',
    content:
      'empto ist ein B2B Marktplatz und steht allen Unternehmen offen, bei denen durch ihre ' +
      'Tätigkeiten Abfälle anfallen. Privatpersonen können die Dienstleistungen von empto nicht in ' +
      'Anspruch nehmen. Gerne können Sie unseren Shop containerdienst.de nutzen.<br /><br />empto steht ' +
      'allen Maklern und Entsorgungsunternehmen offen, die über die notwendigen Qualifikationen und Nachweise ' +
      'verfügen. Weitere Informationen entnehmen Sie bitte der Registrierung sowie den Teilnahmebedingungen.',
  },
  whatCostsEmpto: {
    title: 'Was kostet empto?',
    content:
      'Wir erheben für empto keine Anmeldegebühr und verlangen auch keine Mindestumsätze oder ' +
      'vertragliche Bindung. empto erhält eine Umsatzbeteiligung in Höhe von 4 % des Nettobetrags. ' +
      'Weitere Informationen entnehmen Sie bitte den Teilnahmebedingungen.',
  },
  whoIsBehindEmpto: {
    title: 'Wer steckt hinter empto?',
    content:
      'empto ist ein Produkt der Zentek Services GmbH & Co. KG ' +
      '(<a href="https://www.zentek.de" target="_blank">www.zentek.de</a>). Als spezialisierter ' +
      'Dienstleister für überregionale Entsorgungssysteme entwickelt und realisiert Zentek kunden- und ' +
      'branchenspezifische Lösungen für bundesweit tätige Unternehmen. Der Geschäftsbereich Zentek digital ' +
      'treibt die Digitalisierung der Abfallwirtschaft und bietet mit containerdienst.de und empto ' +
      'passgenaue Lösungen für Privat- und Geschäftskunden.',
  },
  ...infoItems,
}

const loggedIn = {
  howMuchFilling: {
    title: 'Wie voll darf der Behälter befüllt werden?',
    content:
      'Gewerbetreibende müssen beachten, dass der Behälter/Container nur bis zur Ladekante befüllt werden ' +
      'darf, damit ein sicherer Abtransport Ihrer Abfälle gewährleistet ist. Der Abfall darf die Höhe der ' +
      'Seitenwände nicht überragen. Das maximale Füllgewicht des Behälters darf nicht überschritten ' +
      'werden.<br /><br /> Die eigenständige Erhöhung der Seitenwände durch Bretter o.ä. ist nicht zulässig. ' +
      'Bei bestimmten Behältern herrschen weitere Befüllbedingungen. Gerne berät Sie das empto Team.',
  },
  wasteTypes: {
    title: 'Abfallarten - was dürfen Sie in einem Behälter abholen lassen?',
    content: `${pdfLink}`,
  },
  wrongSpecifications: {
    title: 'Was passiert, wenn Sie falsche Angaben zu Ihren Abfällen machen?',
    content:
      'Der Gewerbetreibende sollte die Qualität seiner Abfallarten realistisch einschätzen, da die richtige ' +
      'Eingruppierung in seiner Verantwortung liegt.<br /><br />  Wenn die Angaben bzgl. der Qualität nicht richtig ' +
      'sind, bemerkt dies das Entsorgungsunternehmen bei der Leerung. In der Regel erfolgt in diesen Fällen eine ' +
      'nachweisbare Umdeklarierung des Materials. empto bewertet dann die Richtigkeit der Umdeklarierung ' +
      'und informiert den Gewerbetreibenden mit Begründung und Bilddokumentation über mögliche zusätzliche Kosten.',
  },
  offerAssistant: {
    title: 'Wie funktioniert der Angebotsassistent?',
    content:
      'Mit dem empto Angebotsassistenten können Entsorgungsunternehmen einfach und automatisiert ' +
      'auf neue Anfragen bieten. Mit wenigen Klicks sind Abfallart, Behälter und Gebiet ausgewählt. Das jeweilige ' +
      'Entsorgungsunternehmen entscheidet vollkommen frei über ihre Angebotspreise, die von empto ' +
      'unverändert für die automatisierte Angebotsgenerierung verwendet werden.<br /><br />Der empto ' +
      'Angebotsassistent unterstützt die Auftragsarten „Sofortbefüllung“ und „Einmalauftrag“.<br /><br />Über das ' +
      'Feld „Maximale Anzahl“ kann das Entsorgungsunternehmen die Anzahl der Behälter festlegen, bis zu der die ' +
      'eingetragenen Einzelpreise von empto angeboten werden dürfen.<br /><br />Bei der Festlegung des ' +
      'Gebiets dürfen „Landkreise“ und/oder Postleitzahlen ausgewählt werden.<br /><br />Alle Jobs werden in einer ' +
      'Tabelle übersichtlich angezeigt. Der Status pro Job kann bequem mit einem Klick auf das Label von „Aktiv“ in ' +
      '„Inaktiv“ und zurück geändert werden. Mit einem Klick auf den Stift können die Details des Jobs aufgerufen und ' +
      'bei Bedarf jederzeit verändert werden. Die Änderungen treten nur bei neuen Anfragen in Kraft und ändern keine ' +
      'bereits abgegebenen Angebote.',
  },
  offerComparison: {
    title: 'Wie funktioniert der Angebotsvergleich?',
    content: `Der Angebotsvergleich unterstützt den Gewerbetreibenden bei der Auswahl des passenden \
    Entsorgungsunternehmens. In Abhängigkeit des Preismodells werden folgende Informationen direkt auf der \
    Kachel bereitgestellt:<br /><br /> Gesamterlöse: Prognostizierte Erlöse über die Gesamtlaufzeit der \
    Vereinbarung.<br /><br /> Gesamtkosten: Prognostizierte Kosten über die Gesamtlaufzeit der \
    Vereinbarung.<br /><br />Kosten pro Transport: Prognostizierte Kosten pro Transport (Abholung, Leerung, \
    Tausch).<br /><br /> Die Ermittlung der prognostizierten Gesamterlöse und Gesamtkosten erfolgt auf Basis \
    folgender Annahmen:<br /><br />${autoOfferList}<br />Die Berechnung der „Kosten pro Transport“ erfolgt wie folgt: \
    (Gesamtkosten abzgl. Gesamterlöse) geteilt durch die Anzahl der Transporte.<br /><br />WICHTIG: Die tatsächlich \
    fakturierten Beträge können von den auf der Kachel angezeigten Werten abweichen. Mit einem Klick auf die Kachel \
    des Angebots werden dem Gewerbetreibenden die Details des Angebots inklusive der Einzelpositionen sowie möglicher \
    Hinweise des Anbieters angezeigt. Die Auftragsvergabe kann nur über die Detailseite mit einem Klick auf „Angebot \
    annehmen“ erfolgen.`,
  },
  evaluationCriteria: {
    title: 'Wie ermittelt empto die Bewertungen der Unternehmen?',
    content:
      'empto ermöglicht qualifizierte Bewertungen. Nach jeder Auftragsdurchführung darf Sie Ihr ' +
      'Geschäftspartner bewerten, genauso wie Sie ihn bewerten dürfen.<br /><br />Alle Bewertungen eines Unternehmens ' +
      'fließen in dessen Gesamtbewertung ein. Aktuell wird jede Stimmenabgabe pro Kriterium (Daumen hoch, Daumen ' +
      'runter) gleich gewichtet.<br /><br />Gewerbetreibende dürfen Entsorger nach den Kriterien „Pünktlichkeit“, ' +
      '„Freundlichkeit des Fahrers“ sowie „Zustand von Fahrzeug und Container“ bewerten.<br /><br />' +
      'Entsorgungsunternehmen dürfen Gewerbetreibende nach den Kriterien „Situation vor Ort gemäß Auftrag“ und ' +
      '„Wartezeit“ bewerten.<br /><br />Der neben dem Namen des Unternehmens angezeigte Prozentsatz gibt Auskunft ' +
      'über den Anteil der positiven Bewertungen (Daumen hoch). Die Summe der insgesamt abgegeben Bewertungen wird ' +
      'dahinter in Klammern ausgewiesen.',
  },
  whatHappensWithWaste: {
    title: 'Was passiert mit Ihren Abfällen?',
    content:
      'Als zertifizierter Entsorgungsfachbetrieb gewährleistet empto den verantwortungsbewussten ' +
      'und nachhaltigen Umgang mit den Abfällen der Gewerbetreibenden.<br /><br />Abfallarten, die Gewerbetreibende ' +
      'über empto von einem Entsorgungsunternehmen  abholen lassen, werden unter strenger Beachtung der ' +
      'gesetzlichen Vorgaben sowie Verordnungen verwertet. So wird aus Bauschuttabfällen zumeist Straßenbaumaterial ' +
      'gewonnen und aus Erdaushub Materialien für den Garten- und Landschaftsbau.<br /><br />empto ' +
      'arbeitet ausschließlich mit Entsorgungsunternehmen, die über einen gültigen Nachweis verfügen, dass das ' +
      'Unternehmen gemäß §56 KrWG in Verbindung mit der Entsorgungsfachbetriebeverordnung die Bezeichnung ' +
      'Entsorgungsfachbetrieb führen darf.<br /><br />Auf Wunsch stellen wir Gewerbetreibenden die entsprechende ' +
      'Zertifikate der von ihnen über empto eingesetzten Entsorgungsunternehmen zur Verfügung.',
  },
  ...infoItems,
}

export default {
  loggedOut,
  loggedIn,
}
