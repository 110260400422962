import './style.scss'

import classNames from 'classnames'
import { FormikProps } from 'formik'
import moment from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { Checkbox } from 'components/common/Checkbox'
import {
  ORDER_CLAIM_PROOF_STATES,
  ORDER_CLAIM_PROOF_STATUS,
  ORDER_CLAIM_PROOF_WAITING_TIMES,
} from 'components/common/DocumentReview/OrderClaimReview/OrderClaimDetails/constants'
import { DropDownInput } from 'components/common/DropDownInput'
import InputDate from 'components/common/InputDate/index'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { Textarea } from 'components/common/Textarea'
import { ORDER_CLAIM_TYPE } from 'components/order/OrderDetailsPage/constants'
import { MaklerPremiumGroups } from 'constants/user'
import { getName } from 'helper/translations'
import { getCurrentUserSelector } from 'selectors/user'

import { DetailsSection } from '../DetailsSection'

interface OrderClaimReviewComponentProps {
  claim: OrderClaimList
  formikProps: FormikProps<any>
  isReview?: boolean
}

export const OrderClaimReview: FC<OrderClaimReviewComponentProps> = ({
  claim,
  isReview = false,
  formikProps,
}) => {
  const user = useSelector(getCurrentUserSelector)
  const userIsMaklerPremium = user.group_id in MaklerPremiumGroups

  const InternalNote = (
    <ReadOnlyField
      editable={isReview}
      label={I18n.t(
        'documentReviewPageTranslations.orderClaimReview.internalNote',
      )}
      value={claim.internal_note}
    >
      <Textarea
        label={I18n.t(
          'documentReviewPageTranslations.orderClaimReview.internalNote',
        )}
        maxLength={500}
        name='internal_note'
        onChange={formikProps.handleChange}
        placeholder={I18n.t(
          'documentReviewPageTranslations.orderClaimReview.internalNote',
        )}
        showRemainingCharacters
        value={formikProps.values.internal_note}
      />
    </ReadOnlyField>
  )

  const renderCriteria = isReview ? formikProps.values : claim
  const orderClaimNotRejected =
    Number(formikProps.values.status) !==
    ORDER_CLAIM_PROOF_STATUS.STATUS_REJECTED

  return (
    <DetailsSection
      title={
        isReview
          ? I18n.t(
              'documentReviewPageTranslations.orderClaimReview.sectionTitle.review',
            )
          : I18n.t(
              'documentReviewPageTranslations.orderClaimReview.sectionTitle.details',
            )
      }
    >
      <div className='order-claim-review'>
        <div
          className={classNames({
            'order-claim-review__review-group': !isReview,
          })}
        >
          {/* DATE OF REVIEW (DAY.MONTH.YEAR) */}
          {!isReview && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimReview.reviewedAt',
              )}
              value={moment(claim.reviewed_at).format('L')}
            />
          )}

          {/* WAITING TIME DATE */}
          {isReview &&
            claim.claim_type === ORDER_CLAIM_TYPE.TYPE_WAITING_TIME && (
              <InputDate
                error={
                  formikProps.submitCount > 0 &&
                  formikProps.touched.waiting_time_date
                    ? formikProps.errors.waiting_time_date
                    : ''
                }
                dataTestId='waiting-time-date-input'
                label={I18n.t(
                  'documentReviewPageTranslations.orderClaimReview.waitingTimeDate',
                )}
                maxDate={moment()}
                name='waiting_time_date'
                onChange={value => {
                  formikProps.setFieldValue(
                    'waiting_time_date',
                    value.format('L'),
                  )
                  formikProps.setFieldTouched('waiting_time_date')
                }}
                placeholder={I18n.t(
                  'documentReviewPageTranslations.orderClaimReview.waitingTimeDate',
                )}
                value={formikProps.values.waiting_time_date}
                isRequired={
                  !formikProps.values.waiting_time_date && orderClaimNotRejected
                }
              />
            )}

          {/* SERVICE DATE */}
          {isReview && claim.claim_type === ORDER_CLAIM_TYPE.TYPE_DEADHEAD && (
            <InputDate
              error={
                formikProps.submitCount > 0 && formikProps.touched.service_date
                  ? formikProps.errors.service_date
                  : ''
              }
              dataTestId='service-date-input'
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimReview.serviceDate',
              )}
              maxDate={moment()}
              name='service_date'
              onChange={value => {
                formikProps.setFieldValue('service_date', value.format('L'))
                formikProps.setFieldTouched('service_date')
              }}
              placeholder={I18n.t(
                'documentReviewPageTranslations.orderClaimReview.serviceDate',
              )}
              value={formikProps.values.service_date}
              isRequired={
                !formikProps.values.service_date && orderClaimNotRejected
              }
            />
          )}

          {/* EFFORT DURATION */}
          {isReview &&
            claim.claim_type === ORDER_CLAIM_TYPE.TYPE_WAITING_TIME && (
              <DropDownInput
                error={
                  formikProps.submitCount > 0 &&
                  formikProps.touched.effort_duration
                    ? (formikProps.errors.effort_duration as string)
                    : ''
                }
                dataTestId='effort-duration-select'
                choices={ORDER_CLAIM_PROOF_WAITING_TIMES.map(item => ({
                  optionValue: item.id,
                  optionLabel: I18n.t(item.name),
                }))}
                label={I18n.t(
                  'documentReviewPageTranslations.orderClaimReview.effortDuration',
                )}
                name='effort_duration'
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
                placeholder={I18n.t(
                  'documentReviewPageTranslations.orderClaimReview.effortDuration',
                )}
                value={formikProps.values.effort_duration}
                isRequired={
                  !formikProps.values.effort_duration && orderClaimNotRejected
                }
              />
            )}

          {/* INTERNAL NOTE (position in form must be before status) */}
          {isReview && InternalNote}

          {/* STATUS */}
          <ReadOnlyField
            editable={isReview}
            label={I18n.t(
              'documentReviewPageTranslations.orderClaimReview.status',
            )}
            value={
              !isReview
                ? I18n.t(getName(claim.status, ORDER_CLAIM_PROOF_STATES))
                : undefined
            }
          >
            <DropDownInput
              error={
                formikProps.submitCount > 0 && formikProps.touched.status
                  ? (formikProps.errors.status as string)
                  : ''
              }
              dataTestId='status-select'
              choices={ORDER_CLAIM_PROOF_STATES.map(item => ({
                optionValue: item.id,
                optionLabel: I18n.t(item.name),
              }))}
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimReview.status',
              )}
              name='status'
              onBlur={formikProps.handleBlur}
              onChange={formikProps.handleChange}
              placeholder={I18n.t(
                'documentReviewPageTranslations.orderClaimReview.status',
              )}
              value={formikProps.values.status || ''}
              isRequired={
                Number(formikProps.values.status) ===
                ORDER_CLAIM_PROOF_STATUS.STATUS_PENDING
              }
            />
          </ReadOnlyField>

          {/* NAME OF USER WHO MADE REVIEW */}
          {!isReview && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimReview.reviewedBy',
              )}
              value={claim.reviewed_by_name}
            />
          )}

          {/* TIME OF REVIEW (HOUR:MINUTE) */}
          {!isReview && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimReview.timeOfReview',
              )}
              value={moment(claim.reviewed_at).format('LT')}
            />
          )}
        </div>
        {/* INTERNAL NOTE (Position in details should be after reviewer name) */}
        {!isReview && !!renderCriteria.internal_note && InternalNote}

        <div
          className={classNames({
            'order-claim-review__review-message-group': !isReview,
          })}
        >
          {/* REVIEW MESSAGE */}
          {Number(renderCriteria.status) ===
            ORDER_CLAIM_PROOF_STATUS.STATUS_ACCEPTED &&
            !!renderCriteria.review_message && (
              <ReadOnlyField
                editable={isReview}
                label={I18n.t(
                  'documentReviewPageTranslations.orderClaimReview.reviewText.label',
                )}
                value={claim.review_message}
              >
                <Textarea
                  label={I18n.t(
                    'documentReviewPageTranslations.orderClaimReview.reviewText.placeholder',
                  )}
                  maxLength={500}
                  name='review_message'
                  onChange={formikProps.handleChange}
                  placeholder={I18n.t(
                    'documentReviewPageTranslations.orderClaimReview.reviewText.placeholder',
                  )}
                  showRemainingCharacters
                  value={formikProps.values.review_message}
                />
              </ReadOnlyField>
            )}

          {/* CHECKBOX TO ACCEPTANCE OF DEADHEAD */}
          {isReview &&
            !userIsMaklerPremium &&
            claim.claim_type === ORDER_CLAIM_TYPE.TYPE_DEADHEAD &&
            Number(renderCriteria.status) ===
              ORDER_CLAIM_PROOF_STATUS.STATUS_ACCEPTED && (
              <div className='uk-margin-medium-top'>
                <Checkbox
                  dataTestId='enabled-deadhead-accepting-input'
                  dataTestIdError='form-group-error'
                  error={
                    formikProps.submitCount > 0 &&
                    formikProps.touched.enabled_deadhead_accepting
                      ? (formikProps.errors
                          .enabled_deadhead_accepting as string)
                      : ''
                  }
                  isChecked={formikProps.values.enabled_deadhead_accepting}
                  isRequired
                  label={I18n.t(
                    'documentReviewPageTranslations.orderClaimReview.acceptDeadhead',
                  )}
                  name='enabled_deadhead_accepting'
                  onChange={formikProps.handleChange}
                />
              </div>
            )}

          {/* CHECKBOX TO CONFIRM WAITING TIME */}
          {isReview &&
            claim.claim_type === ORDER_CLAIM_TYPE.TYPE_WAITING_TIME &&
            Number(renderCriteria.status) ===
              ORDER_CLAIM_PROOF_STATUS.STATUS_ACCEPTED && (
              <div className='uk-margin-medium-top'>
                <Checkbox
                  dataTestId='enabled-waiting-time-accepting-input'
                  dataTestIdError='form-group-error'
                  error={
                    formikProps.submitCount > 0 &&
                    formikProps.touched.enabled_waiting_time_accepting
                      ? (formikProps.errors
                          .enabled_waiting_time_accepting as string)
                      : ''
                  }
                  isChecked={formikProps.values.enabled_waiting_time_accepting}
                  isRequired
                  label={I18n.t(
                    'documentReviewPageTranslations.orderClaimReview.acceptWaitingTime',
                  )}
                  name='enabled_waiting_time_accepting'
                  onChange={formikProps.handleChange}
                />
              </div>
            )}

          {/* REJECT REASON */}
          {((!isReview && claim.reject_reason) ||
            Number(renderCriteria.status) ===
              ORDER_CLAIM_PROOF_STATUS.STATUS_REJECTED) && (
            <ReadOnlyField
              editable={isReview}
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimReview.rejectReason.label.details',
              )}
              value={claim.reject_reason}
            >
              <Textarea
                error={
                  formikProps.submitCount > 0 &&
                  formikProps.touched.reject_reason
                    ? (formikProps.errors.reject_reason as string)
                    : ''
                }
                isRequired={!formikProps.values.reject_reason}
                label={I18n.t(
                  'documentReviewPageTranslations.orderClaimReview.rejectReason.label.review',
                )}
                placeholder={I18n.t(
                  'documentReviewPageTranslations.orderClaimReview.rejectReason.placeholder',
                )}
                maxLength={500}
                name='reject_reason'
                onChange={formikProps.handleChange}
                showRemainingCharacters
                value={formikProps.values.reject_reason}
              />
            </ReadOnlyField>
          )}
        </div>
      </div>
    </DetailsSection>
  )
}
