import { useFormikContext } from 'formik'
import React from 'react'
import { I18n, Translate } from 'react-i18nify'

import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import { DropDownInput } from 'components/common/DropDownInput'
import { InputEmail } from 'components/common/InputEmail'
import { InputTelephoneNumber } from 'components/common/InputTelephoneNumber'
import { InputText } from 'components/common/InputText'
import { ProgressButton } from 'components/common/ProgressButton'
import { MAX_LENGTH } from 'components/homepages/HomepageRegister/constants'
import { USER_GENDER_CHOICES } from 'helper/user'

import { AddContactPersonFormValues } from './AddContactPersonModal'

type AddContactPersonFormPropsType = {
  onClose: () => void
}

export const AddContactPersonForm = (props: AddContactPersonFormPropsType) => {
  const { onClose } = props

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    submitCount,
    touched,
    values,
  } = useFormikContext<AddContactPersonFormValues>()

  return (
    <>
      <div className='uk-modal-body'>
        {/* Gender */}
        <DropDownInput
          choices={USER_GENDER_CHOICES()}
          dataTestId='user-gender-input'
          dataTestIdError='form-group-error'
          error={submitCount > 0 && touched.gender ? errors.gender : ''}
          isRequired={!values.gender}
          label={I18n.t('general.placeholder.gender')}
          name='gender'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={I18n.t('general.placeholder.gender')}
          value={values.gender}
        />

        {/* First name */}
        <InputText
          dataTestId='user-first-name-input'
          dataTestIdError='form-group-error'
          error={submitCount > 0 && touched.first_name ? errors.first_name : ''}
          isRequired={!values.first_name}
          label={I18n.t('general.placeholder.firstName')}
          maxLength={MAX_LENGTH.FIRST_NAME}
          name='first_name'
          noNumbers
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={I18n.t('general.placeholder.firstName')}
          value={values.first_name}
        />

        {/* Last name */}
        <InputText
          dataTestId='user-last-name-input'
          dataTestIdError='form-group-error'
          error={submitCount > 0 && touched.last_name ? errors.last_name : ''}
          isRequired={!values.last_name}
          label={I18n.t('general.placeholder.lastName')}
          maxLength={MAX_LENGTH.LAST_NAME}
          name='last_name'
          noNumbers
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={I18n.t('general.placeholder.lastName')}
          value={values.last_name}
        />

        {/* Telephone number*/}
        <InputTelephoneNumber
          dataTestId='user-phone-number-input'
          dataTestIdError='form-group-error'
          placeholder={I18n.t('userForm.placeholder.phoneNumber')}
          label={I18n.t('userForm.placeholder.phoneNumber')}
          maxLength={MAX_LENGTH.PHONE_NUMBER}
          name='business_phone_number'
          onBlur={handleBlur}
          onChange={handleChange}
          value={
            values.business_phone_number ? values.business_phone_number : ''
          }
        />

        {/* Email */}
        <InputEmail
          dataTestId='user-email-input'
          dataTestIdError='form-group-error'
          error={submitCount > 0 && touched.email ? errors.email : ''}
          isRequired={!values.email}
          label={I18n.t('general.placeholder.email')}
          maxLength={MAX_LENGTH.MAIL_ADDRESS}
          name='email'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={I18n.t('general.placeholder.email')}
          value={values.email || ''}
        />
      </div>

      <div className='uk-modal-footer uk-text-right'>
        <span className='uk-margin-right'>
          <Button
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            onClick={onClose}
          >
            <Translate value='general.button.cancel' />
          </Button>
        </span>

        <ProgressButton
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          dataTestId='invite-user-form-submit'
          isDisabled={submitCount > 0 && !isValid}
          onClick={handleSubmit}
          type={BUTTON_TYPE.SUBMIT}
        >
          <Translate value='general.button.submit' />
        </ProgressButton>
      </div>
    </>
  )
}
