import { Form, Formik } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, { FC, useCallback, useMemo } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { updateDszDocument } from 'actions/dsz'
import { updateEPDDocument as updateEmptoDocument } from 'actions/invoice'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import {
  DOCUMENT_STATUS_TYPE,
  DOCUMENT_TYPE,
} from 'components/invoice/constants'
import { UserPermission } from 'constants/user'
import { checkRequiredPermissions } from 'helper/permissions'
import { getCurrentUserSelector } from 'selectors/user'

import { SetDocumentPaidForm } from './SetDocumentPaidForm'

interface SetDocumentPaidModalProps {
  isOpen?: boolean
  onClose?: () => void
  document?: Invoice
}

export type DocumentPaidFormValues = Partial<{
  actual_payment_method: number
  comment: string
  paid_at: string
  transaction_key: string
}>

export const SetDocumentPaidModal: FC<SetDocumentPaidModalProps> = ({
  isOpen = false,
  onClose = () => undefined,
  document = undefined,
}) => {
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUserSelector)
  const isDsz = useMemo(
    () =>
      checkRequiredPermissions(user.permission_codenames, [
        UserPermission.VIEW_DSZINVOICE,
      ]),
    [user],
  )

  const documentIsCreditNote =
    document?.document_type === DOCUMENT_TYPE.CREDIT_NOTE

  const idSetDocumentPaidModalHeadline = uniqueId()

  const updateDocument = useCallback(
    values => {
      if (isDsz) {
        return updateDszDocument({
          ...values,
          status: DOCUMENT_STATUS_TYPE.DSZ_PAID,
          invoice_id: document?.id ?? 0,
        })
      } else {
        return updateEmptoDocument({
          ...values,
          ...(documentIsCreditNote
            ? // backend expects different keys depending on the documents type.
              { credit_note_id: document?.id ?? 0 }
            : { invoice_id: document?.id ?? 0 }),
          document_status: DOCUMENT_STATUS_TYPE.PAID,
        })
      }
    },
    [isDsz, document, documentIsCreditNote],
  )

  const handleSubmit = values => {
    onClose()
    dispatch(updateDocument(values))
  }

  return (
    <Modal
      ariaDescribedBy={idSetDocumentPaidModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={
          documentIsCreditNote
            ? I18n.t(
                'bookkeepingTranslations.setDocumentPaidModal.titleCreditNote',
              )
            : I18n.t(
                'bookkeepingTranslations.setDocumentPaidModal.titleInvoice',
              )
        }
        titleId={idSetDocumentPaidModalHeadline}
      />
      <Formik
        initialValues={{
          actual_payment_method: document?.payment_method,
          comment: '',
          paid_at: undefined,
          transaction_key: document?.transaction_key ?? '',
        }}
        onSubmit={(values: DocumentPaidFormValues) => handleSubmit(values)}
        validationSchema={() =>
          yup.object().shape({
            actual_payment_method: yup.number(),
            comment: yup.string(),
            paid_at: yup
              .string()
              .required(
                I18n.t(
                  'bookkeepingTranslations.setDocumentPaidModal.fields.paidAtRequired',
                ),
              ),
            transaction_key: yup.string(),
          })
        }
      >
        <Form
          className='set-document-paid-form'
          data-testid='set-document-paid-form'
          noValidate
        >
          <SetDocumentPaidForm
            isCreditNote={documentIsCreditNote}
            onClose={onClose}
          />
        </Form>
      </Formik>
    </Modal>
  )
}
