export default {
  title: 'Rechtliche Hinweise',
  text:
    'Die Zentek Services GmbH & Co. KG bemüht sich, auf ihrer Website zutreffende Informationen und Daten zur ' +
    'Verfügung zu stellen. Eine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der zur ' +
    'Verfügung gestellten Informationen und Daten ist jedoch ausgeschlossen. Dies gilt ebenfalls auch für alle ' +
    'anderen direkt oder indirekt angeführten Websites, auf die mittels eines Hyperlinks verwiesen wird. Die ' +
    'Zentek Services GmbH & Co. KG ist für den Inhalt solcher Websites, die mit diesen Links erreicht werden, ' +
    'nicht verantwortlich.<br /><br />' +
    'Die Zentek Services GmbH & Co. KG haftet darüber hinaus weder für direkte noch indirekte Schäden, einschließlich ' +
    'entgangenen Gewinns, die durch die Nutzung der auf dieser Website zu findenden Informationen oder Daten ' +
    'entstehen.<br /> Ausgeschlossen sind auch Rechte und Pflichten zwischen der Zentek Services GmbH & Co. KG und ' +
    'dem Nutzer ' +
    'dieser Website oder Dritten. Die Zentek Services GmbH & Co. KG behält sich vor, ohne vorherige Ankündigung ' +
    'Änderungen oder Ergänzungen der bereitgestellten Informationen oder Daten jederzeit vorzunehmen.<br /><br />' +
    'Der Inhalt dieser Website, insbesondere alle Texte, Bilder, Graphiken, Logos, Webcam-Bilder, Ton-, Video- ' +
    'und Animationsdateien sowie auch ihre Arrangements, unterliegen dem Urheberrecht und anderen Gesetzen zum ' +
    'Schutz geistigen Eigentums. Die Zentek Services GmbH & Co. KG gewährt jedoch den Nutzern das Recht, ' +
    'die auf dieser Website bereitgestellten Texte, Bilder und Graphiken zu publizistischen Zwecken im Rahmen des ' +
    'geltenden Presse- und Urheberrechts ganz oder ausschnittsweise zu verwenden, zu speichern und zu vervielfältigen, ' +
    'wobei die Zentek Services GmbH & Co. KG als Quelle/Urheber genannt und ein Belegexemplar der ' +
    'Zentek Services GmbH & Co. KG, Frau Gabriele Holzky (presse@zentek.de) zur Verfügung gestellt werden muß. ' +
    'Die Verwendung, Speicherung sowie Vervielfältigung der auf der Zentek Services GmbH & Co. KG-Website ' +
    'verwendeten Logos, Webcam-Bilder, Ton-, Video- und Animationsdateien und deren Arrangements bedarf dagegen der ' +
    'vorherigen Zustimmung der Zentek Services GmbH & Co. KG.<br /><br /> ' +
    'Wenn Sie auf unsere Internet-Seite zugreifen, werden durch unseren Web-Server ' +
    'automatisch technische Zugriffsdaten aufgezeichnet und ausgewertet (z.B. verwendeter Internet-Browser und ' +
    'Betriebssystem, Domain-Name der zuvor besuchten Web-Seite, durchschnittliche Verweildauer und aufgerufene Seiten ' +
    'in unserem Angebot).<br /> Zudem setzen wir in einigen Bereichen unseres Internet-Angebotes Cookies ein. Dabei ' +
    'handelt es sich um kleine Datenelemente, die ein Internet-Server an Ihren Computer senden kann, um diesen bei ' +
    'Aufruf der Seite zu identifizieren und die Verwendung unserer Internet-Seite zu erleichtern. Sie können Ihren ' +
    'Internet-Browser so einstellen, dass Sie die Entgegennahme der Cookies ablehnen können.<br /> Bei der ' +
    'Aufzeichnung und Auswertung der technische Daten bleibt der einzelne Benutzer anonym. Ihre Verwendung erfolgt zur ' +
    'Verbesserung der Attraktivität und Bedienbarkeit unserer Internet-Seite und deren Inhalte sowie der ' +
    'Erkennung von Problemen in unserem Internet-Angebot.<br /><br /> Die auf der Zentek Services GmbH & Co. KG' +
    '-Website bereitgestellten Texte, Bilder, Graphiken, Logos etc. dürfen aber weder verändert, noch für ' +
    'Handelszwecke kopiert und auf anderen Websites verwendet werden.<br /><br /> ' +
    'Die Webseite der Zentek Services GmbH & Co. KG gewährt auch keinerlei Lizenzrechte an dem geistigen Eigentum ' +
    'der Zentek Services GmbH & Co. KG.<br /><br />' +
    'Alle auf der Zentek Services GmbH & Co. KG-Website genannten Marken und Logos sind gesetzlich geschützte ' +
    'Warenzeichen.<br /><br /> Sämtliche Informationen oder Daten, ihre Nutzung und die Anmeldung zur Zentek ' +
    'Services GmbH & Co. KG-Website sowie sämtliches mit der Zentek Services GmbH & Co. KG-Website zusammenhängendes ' +
    'Tun, Dulden oder Unterlassen unterliegen ausschließlich deutschem Recht. Erfüllungsort und ausschließlicher ' +
    'Gerichtsstand ist Köln.',
}
