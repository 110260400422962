import CurrentUserProfilePageComponent from '../components/user/CurrentUserProfilePage'
import ForgetPasswordComponent from '../components/user/ForgetPassword'
import PasswordResetConfirmComponent from '../components/user/ForgetPassword/components/PasswordResetConfirm'
import PasswordResetDone from '../components/user/ForgetPassword/components/PasswordResetDone'
import UserActivationComponent from '../components/user/UserActivation'
import UserOverviewPageComponent from '../components/user/UserOverviewPage'
import { UserProfilePage } from '../components/user/UserProfilePage'

/**
 * @constant
 * @description A constant to define routes for the user module.
 */
export const userRoute = [
  {
    path: '/forget-password/done',
    component: PasswordResetDone,
    exact: true,
    public: true,
  },
  {
    path: '/forget-password',
    component: ForgetPasswordComponent,
    exact: true,
    public: true,
  },
  {
    path: '/reset/:uid/:token',
    component: PasswordResetConfirmComponent,
    exact: true,
    public: true,
  },
  {
    path: '/activate/:uid/:token',
    component: UserActivationComponent,
    exact: true,
    public: true,
  },
  {
    path: '/user/mine',
    component: UserOverviewPageComponent,
  },
  {
    path: '/user/profile',
    component: CurrentUserProfilePageComponent,
  },
  {
    path: '/user/company/:companyId',
    component: UserOverviewPageComponent,
  },
  {
    path: '/user/:userId',
    component: UserProfilePage,
  },
]

export default {}
