import { connect } from 'react-redux'

import { getMyOffers } from 'actions/offer'
import { getCurrentUserSelector } from 'selectors/user'

const mapStateToProps = state => ({
  user: getCurrentUserSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getMyOffers: (page, filters, pageSize) =>
    dispatch(getMyOffers(page, filters, pageSize)),
})

export default connect(mapStateToProps, mapDispatchToProps)
