import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import AppActionTypes from '../actiontypes/app'
import DszActionTypes from '../actiontypes/dsz'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetDszInvoices
 * @description Loads all dsz invoices.
 */
export const handleGetDszInvoices = createLogic({
  type: DszActionTypes.GET_DSZ_INVOICES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload) {
      data = { page: action.payload.page || 1 }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }

      if (action.payload.sortedBy && action.payload.sortedBy.length) {
        data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
      }
    }

    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/dsz/invoice/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: DszActionTypes.GET_DSZ_INVOICES_SUCCESS,
          payload: res,
        })
        dispatch({
          type: AppActionTypes.RESET_API_FETCH_ERRORS,
          payload: 'GET_DSZ_INVOICES',
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.dsz.handleGetDszInvoices.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: DszActionTypes.GET_DSZ_INVOICES_ERROR,
          payload,
        })

        if (!err.hideToastr) {
          toastr.error('', humanReadableError)
        }

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateCustomInvoice
 * @description Creates an invoice using the custom data provided in Beleg+
 */
export const handleCreateCustomInvoice = createLogic({
  type: DszActionTypes.CREATE_CUSTOM_DSZ_INVOICE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/dsz/custom-document/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: DszActionTypes.CREATE_CUSTOM_DSZ_INVOICE_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success('', I18n.t('api.dsz.handleCreateCustomInvoice.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.dsz.handleCreateCustomInvoice.error',
        )

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: DszActionTypes.CREATE_CUSTOM_DSZ_INVOICE_ERROR,
          payload,
        })

        done()
      })
  },
})

/**
 * Update DSZ Invoice documents (cancel, mark as paid, ...)
 * @see src/logics/invoices.js@handleUpdateEPDDocument
 */
export const handleUpdateDszDocument = createLogic({
  type: DszActionTypes.UPDATE_DSZ_INVOICE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/dsz/invoice/${action.payload.invoice_id}/`

    httpClient
      .patch(url, action.payload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: DszActionTypes.UPDATE_DSZ_INVOICE_SUCCESS,
          payload: res.document ? res.document : res,
        })

        toastr.success('', I18n.t('api.dsz.handleUpdateDszDocument.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.dsz.handleUpdateDszDocument.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: DszActionTypes.UPDATE_DSZ_INVOICE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleTriggerMynatureExports
 * @description triggers the dsz my nature export.
 */
export const handleTriggerMynatureExports = createLogic({
  type: DszActionTypes.TRIGGER_MY_NATURE_EXPORTS_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const payload = action.payload
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/dsz/my_nature_exports/export/`

    httpClient
      .get(url, payload)
      .then(() => {
        toastr.success(
          '',
          I18n.t('api.dsz.handleTriggerMynatureExports.success'),
        )
        dispatch({
          type: DszActionTypes.TRIGGER_MY_NATURE_EXPORTS_SUCCESS,
        })
      })
      .then(done)
      .catch(err => {
        dispatch({
          type: DszActionTypes.TRIGGER_MY_NATURE_EXPORTS_ERROR,
        })

        // 404 is not an error but the intended status code if no contracts will be exported
        if (err.response.status === 404) {
          toastr.error(
            '',
            I18n.t('api.dsz.handleTriggerMynatureExports.exportEmpty'),
          )
        } else {
          toastr.error('', I18n.t('api.dsz.handleTriggerMynatureExports.error'))
        }

        done()
      })
  },
})

/**
 * @function
 * @name handleGetContracts
 * @description Loads all dsz contracts.
 */
export const handleGetContracts = createLogic({
  type: DszActionTypes.GET_CONTRACTS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload) {
      data = { page: action.payload.page || 1 }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }

      if (action.payload.sortedBy && action.payload.sortedBy.length) {
        data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
      }
    }

    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/dsz/contract/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: DszActionTypes.GET_CONTRACTS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.dsz.handleGetContracts.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: DszActionTypes.GET_CONTRACTS_ERROR,
          payload,
        })

        if (!err.hideToastr) {
          toastr.error('', humanReadableError)
        }

        done()
      })
  },
})

export const handleGetDszFractions = createLogic({
  type: DszActionTypes.GET_DSZ_FRACTIONS_REQUEST,
  latest: true,

  process: ({ httpClient, action }, dispatch, done) => {
    let data = {}

    if (action.payload) {
      data = {
        page: action.payload.page || 1,
        page_size: action.payload.pageSize || Number.MAX_SAFE_INTEGER,
      }

      if (action.payload.search) {
        data = { ...data, search: action.payload.search }
      }
    }

    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/dsz/fraction/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: DszActionTypes.GET_DSZ_FRACTIONS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.dsz.handleGetFractions.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: DszActionTypes.GET_DSZ_FRACTIONS_ERROR,
          payload,
        })

        if (!err.hideToastr) {
          toastr.error('', humanReadableError)
        }

        done()
      })
  },
})

export const handleGetDszFractionPrices = createLogic({
  type: DszActionTypes.GET_DSZ_FRACTION_PRICES_REQUEST,
  latest: true,

  process: ({ httpClient, action }, dispatch, done) => {
    let data = {}

    if (action.payload) {
      data = { page: action.payload.page || 1 }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }
    }

    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/dsz/fraction-price/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: DszActionTypes.GET_DSZ_FRACTION_PRICES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.dsz.handleGetFractionPrices.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: DszActionTypes.GET_DSZ_FRACTION_PRICES_ERROR,
          payload,
        })

        if (!err.hideToastr) {
          toastr.error('', humanReadableError)
        }

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateDszFractionPrice
 * @description Creates a dsz fraction price in DSZ Preis hinzufügen+
 */
export const handleCreateDszFractionPrice = createLogic({
  type: DszActionTypes.CREATE_DSZ_FRACTION_PRICE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/dsz/fraction-price/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: DszActionTypes.CREATE_DSZ_FRACTION_PRICE_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success(
          '',
          I18n.t('api.dsz.handleCreateDszFractionPrice.success'),
        )
        dispatch({
          type: DszActionTypes.GET_DSZ_FRACTION_PRICES_REQUEST,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.dsz.handleCreateDszFractionPrice.error',
        )

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: DszActionTypes.CREATE_DSZ_FRACTION_PRICE_ERROR,
          payload,
        })
        toastr.error('', I18n.t('api.dsz.handleCreateDszFractionPrice.error'))

        done()
      })
  },
})

export const handleUpdateDszFractionPrice = createLogic({
  type: DszActionTypes.UPDATE_DSZ_FRACTION_PRICE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/dsz/fraction-price/${action.payload.data.id}/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: DszActionTypes.UPDATE_DSZ_FRACTION_PRICE_SUCCESS,
          payload: {
            ...res,
          },
        })
        toastr.success(
          '',
          I18n.t('api.dsz.handleUpdateDszFractionPrice.success'),
        )
        dispatch({
          type: DszActionTypes.GET_DSZ_FRACTION_PRICES_REQUEST,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.dsz.handleUpdateDszFractionPrice.error',
        )

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: DszActionTypes.UPDATE_DSZ_FRACTION_PRICE_ERROR,
          payload,
        })
        toastr.error('', I18n.t('api.dsz.handleUpdateDszFractionPrice.error'))

        done()
      })
  },
})

export default [
  handleGetDszInvoices,
  handleCreateCustomInvoice,
  handleUpdateDszDocument,
  handleTriggerMynatureExports,
  handleGetContracts,
  handleGetDszFractions,
  handleGetDszFractionPrices,
  handleCreateDszFractionPrice,
  handleUpdateDszFractionPrice,
]
