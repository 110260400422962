import { arrayOf, bool, func, shape } from 'prop-types'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'
import { compose } from 'redux'

import withErrorBoundary from 'helper/withErrorBoundary'
import { CertificateScheme } from 'schemes/certificate'
import { LocationScheme } from 'schemes/location'
import { PaginationScheme } from 'schemes/pagination'

import { BackButton } from '../../common/BackButton'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import MyCertificateFilter from '../../common/Filter/components/CertificateFilter'
import { PageHeader } from '../../layout/PageHeader'
import { PROOF_TYPE } from '../../proofs/constants'
import { ProofsList } from '../../proofs/ProofsList'

import connector from './connector'

/**
 * @description This component displays a list of the archived (non-pending) certificates of the user's company.
 * @function
 * @param {Object} props the component props
 */
export class MyCertificatesPageComponent extends Component {
  static propTypes = {
    location: shape(LocationScheme).isRequired,
    getMyCertificates: func.isRequired,
    pagination: shape(PaginationScheme),
    isLoading: bool,
    certificates: arrayOf(shape(CertificateScheme)),
  }

  static defaultProps = {
    certificates: [],
    isLoading: false,
    pagination: {},
  }

  state = {
    currentFilterValues: {},
    sortedBy: [],
  }

  /**
   * Component “lifecycle method” UNSAFE_componentWillMount
   */
  UNSAFE_componentWillMount() {
    if (this.props.certificates.length < 1) this.props.getMyCertificates()
  }

  /**
   * @description call getMyCertificates and save sorting values in state
   */
  getSortingValues = state => {
    if (state.sorted.length) {
      this.props.getMyCertificates(
        null,
        this.state.currentFilterValues,
        state.sorted,
      )
      this.setState({ sortedBy: state.sorted })
    }
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{I18n.t('pageTitles.certificateArchive')}</title>
        </Helmet>

        <div className='my-certificates-archive-page'>
          <PageHeader title={I18n.t('certificateArchive.heading')}>
            {this.props.location.state && (
              <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                <BackButton />
              </ButtonBar>
            )}
          </PageHeader>

          <Filter
            isLoading={this.props.isLoading}
            length={this.props.certificates.length}
          >
            <MyCertificateFilter
              getCurrentFilterValues={values =>
                this.setState({ currentFilterValues: values })
              }
              sortedBy={this.state.sortedBy}
            />
          </Filter>

          <ProofsList
            pages={this.props.pagination.count}
            page={this.props.pagination.current}
            onPreviousPageClick={() =>
              this.props.getMyCertificates(
                this.props.pagination.previous,
                this.state.currentFilterValues,
                this.state.sortedBy,
              )
            }
            onNextPageClick={() =>
              this.props.getMyCertificates(
                this.props.pagination.next,
                this.state.currentFilterValues,
                this.state.sortedBy,
              )
            }
            onFetchData={this.getSortingValues}
            proofsList={this.props.certificates}
            proofsType={PROOF_TYPE.MY_CERTIFICATE}
            isLoading={this.props.isLoading}
          />
        </div>
      </>
    )
  }
}

export default compose(
  withErrorBoundary,
  connector,
)(MyCertificatesPageComponent)
