import moment from 'moment/moment'
import React, { FC, useState } from 'react'
import { Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { PaginatedTable } from 'components/common/PaginatedTable'
import { ReactComponent as DocumentIcon } from 'assets/svg/detailspage/document.svg'
import { getTaskLogs } from 'actions/company'
import {
  getTaskLogsPaginationSelector,
  getTaskLogsSelector,
} from 'selectors/company'
import { createLoadingSelector } from 'selectors/loading'

import { CollectiveInvoicingPageContext } from '../../CollectiveInvoicingPage'

import { DetailsModal } from './DetailsModal'

export const ActivityTable: FC = () => {
  const dispatch = useDispatch()
  const taskLogs = useSelector(getTaskLogsSelector)
  const pagination = useSelector(getTaskLogsPaginationSelector)
  const loading = useSelector(createLoadingSelector(['GET_TASK_LOGS']))
  const [showDetailsModal, setShowDetailsModal] = useState({
    showModal: false,
    takLogId: undefined,
    companyEmptoExternalNumber: undefined,
    companyName: undefined,
  })

  const columns = [
    {
      id: 'created_at',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.activityTable.columns.timestamp' />
        </span>
      ),
      Cell: data => moment(data.original.created_at).format('L LT'),
    },
    {
      id: 'company__empto_external_number',
      accessor: 'company',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.activityTable.columns.customerNumber' />
        </span>
      ),
      Cell: data => data.original.content_object?.empto_external_number,
    },
    {
      id: 'company__name',
      accessor: 'company',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.activityTable.columns.customer' />
        </span>
      ),
      Cell: data => (
        <Link to={`/company/${data.original.content_object?.id}`}>
          {data.original.content_object?.name}
        </Link>
      ),
    },
    {
      id: 'status',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.activityTable.columns.status' />
        </span>
      ),
      Cell: data => data.original.status,
    },
    {
      id: 'created_documents',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.activityTable.columns.createdDocuments' />
        </span>
      ),
      Cell: data => (
        <span>
          {data.original.created_documents.map(created_document => (
            <Link
              key={created_document.document_number}
              style={{ marginRight: '10px', display: 'inline-block' }}
              to={{ pathname: created_document.pdf_link }}
              onClick={event => {
                event.preventDefault()
                window.open(created_document.pdf_link)
              }}
            >
              {created_document.document_number}
            </Link>
          ))}
        </span>
      ),
      style: { whiteSpace: 'normal', wordWrap: 'break-word' },
      width: 300,
      sortable: false,
    },
    {
      id: 'sent_automatically',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.activityTable.columns.autoSendEmail' />
        </span>
      ),
      Cell: data =>
        data.original.sent_automatically ? (
          <Translate value='general.yes' />
        ) : (
          <Translate value='general.no' />
        ),
    },
    {
      id: 'created_by_name',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.activityTable.columns.username' />
        </span>
      ),
      Cell: data => data.original.created_by_name,
    },
    {
      id: 'details',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.activityTable.columns.details' />
        </span>
      ),
      Cell: data => (
        <Link
          to='#'
          onClick={() => {
            setShowDetailsModal({
              showModal: true,
              takLogId: data.original.id,
              companyEmptoExternalNumber:
                data.original.content_object?.empto_external_number,
              companyName: data.original.content_object?.name,
            })
          }}
        >
          <Translate value='collectiveInvoicingTranslations.activityTable.columns.details' />
        </Link>
      ),
      sortable: false,
    },
  ]
  const defaultSorted = [{ id: 'created_at', desc: true }]

  return (
    <CollectiveInvoicingPageContext.Consumer>
      {context => (
        <div className='uk-margin-large-top'>
          <span className='dsz-price-management-page--section--title'>
            <DocumentIcon className='icon--color-default icon--background-color-default dsz-price-management-page--section--icon' />
            <Translate value='priceManagementPageTranslations.activitiesTable.headline' />
          </span>
          <PaginatedTable
            serverSidePagination
            page={pagination.current}
            pages={pagination.count}
            loading={loading}
            handleShowPreviousPage={() => {
              dispatch(
                getTaskLogs(
                  pagination.previous,
                  context.currentActivityFilters,
                ),
              )
            }}
            handleShowNextPage={() => {
              dispatch(
                getTaskLogs(pagination.next, context.currentActivityFilters),
              )
            }}
            table={{
              columns,
              data: taskLogs,
              defaultSorted,
              onFetchData: ({ page, sorted }) => {
                if (sorted && sorted.length > 0) {
                  const newFilters = {
                    ...context.currentActivityFilters,
                    order_by: JSON.stringify(sorted),
                  }
                  context.setCurrentActivityFilters(newFilters)
                  if (!loading) {
                    dispatch(getTaskLogs(page > 0 ? page : 1, newFilters))
                  }
                }
              },
            }}
          />
          <DetailsModal
            callback={() =>
              setShowDetailsModal({
                showModal: false,
                takLogId: undefined,
                companyEmptoExternalNumber: undefined,
                companyName: undefined,
              })
            }
            showDetailsModal={showDetailsModal.showModal}
            taskLogId={showDetailsModal.takLogId}
            companyEmptoExternalNumber={
              showDetailsModal.companyEmptoExternalNumber
            }
            companyName={showDetailsModal.companyName}
          />
        </div>
      )}
    </CollectiveInvoicingPageContext.Consumer>
  )
}
