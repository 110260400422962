import './style.scss'

import { arrayOf, number, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { I18n } from 'react-i18nify'

/**
 * @description This component renders and controls a waste type content component.
 * @function
 * @param {Object} props the component props
 */

class WasteTypeAVVTable extends Component {
  static propTypes = {
    avvList: arrayOf(
      shape({
        id: number,
        name: string,
        number: string,
      }),
    ).isRequired,
  }

  render() {
    const { avvList } = this.props

    return (
      <div className='waste-type-avv-table'>
        <table>
          <thead className='waste-type-avv-table__header'>
            <tr>
              <th className='waste-type-avv-table__header--number'>
                {I18n.t('general.avv')}
              </th>
              <th className='waste-type-avv-table__header--name'>
                {I18n.t('general.name')}
              </th>
            </tr>
          </thead>
          <tbody className='waste-type-avv-table__body'>
            {avvList.map(avv => (
              <tr key={avv.id}>
                <td className='waste-type-avv-table__body--number'>
                  {avv.number}
                </td>
                <td className='waste-type-avv-table__body--name'>{avv.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default WasteTypeAVVTable
