import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

import { CardDetailsSubList } from './CardDetailsSubList'

interface CardDetailsListItemProps {
  subListItems?: (string | ReactNode)[]
  withoutIcon?: boolean
  hasButton?: boolean
}

/**
 * A component which renders a details list item with its styles of our new cards.
 * @constructor
 */
export const CardDetailsListItem: FC<CardDetailsListItemProps> = ({
  children,
  subListItems,
  withoutIcon,
  hasButton,
}) => (
  <li
    className={classNames(
      'new-card__details-list-item',
      { 'new-card__details-list-item--no-icon': withoutIcon },
      { 'new-card__details-list-item--has-sublist': !!subListItems },
      { 'new-card__details-list-item--has-button': hasButton },
    )}
  >
    {children}
    {subListItems && <CardDetailsSubList items={subListItems} />}
  </li>
)
