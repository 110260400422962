import ActionTypes from '../actiontypes/app'

export const initialState = {
  cookiesAccepted: false,
  mobileMenuVisible: false,
}

/**
 * @description Application global reducer.
 * @param {Object} state is the current globale state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ACCEPT_COOKIES:
      return {
        ...state,
        cookiesAccepted: true,
      }
    case ActionTypes.TOGGLE_MOBILE_MENU:
      return {
        ...state,
        mobileMenuVisible: action.payload,
      }
    default:
      return state
  }
}

export default appReducer
