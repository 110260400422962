import './style.scss'

import classNames from 'classnames'
import React, { FC, useEffect } from 'react'
import { Translate } from 'react-i18nify'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'

import { getLandingPageWasteProducerContent } from 'actions/landingpage'
import DevicesImage from 'assets/img/homepage/devices.png'
import IconComparabilityImage from 'assets/img/icons/icon-comparability-gradient.svg'
import IconCustomerServiceImage from 'assets/img/icons/icon-customer-service-gradient.svg'
import IconLegalSecurityImage from 'assets/img/icons/icon-legal-security-gradient.svg'
import IconStartDirectlyImage from 'assets/img/icons/icon-start-directly-gradient.svg'
import IconTransparencyImage from 'assets/img/icons/icon-transparency-gradient.svg'
import IconWarrantyServiceImage from 'assets/img/icons/icon-warranty-service-gradient.svg'
import { BenefitItem } from 'components/common/BenefitItem'
import { BenefitsSection } from 'components/common/BenefitsSection'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import {
  BUTTON_BACKGROUND_COLOR,
  LinkButton,
} from 'components/common/LinkButton'
import { BREAKPOINT } from 'constants/design'

import {
  HomepagesSlider,
  LandingPageWasteProducerContent,
} from '../HomepagesSlider'
import { VisualBackgroundElement } from '../IntroSection/components/VisualBackgroundElement'

interface HomepageBenefitsSectionComponentProps {
  showWasteProducerContent?: boolean
  showWasteDisposerContent?: boolean
}

export const HomepageBenefitsSection: FC<
  HomepageBenefitsSectionComponentProps
> = ({
  showWasteProducerContent = false,
  showWasteDisposerContent = false,
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getLandingPageWasteProducerContent())
  }, [dispatch])
  const sliderLandingPageWasteProducerContent = useSelector<
    any,
    LandingPageWasteProducerContent[]
  >(state => state.landingPage.wasteProducerLandingPage.itemList)
  return (
    <>
      <VisualBackgroundElement>
        <section className='homepage-benefits-section__header'>
          <div className='homepage-benefits-section__header--image'>
            <img alt='' src={DevicesImage} aria-hidden='true' />
          </div>
          <div
            className={classNames(
              'homepage-benefits-section__header--headline',
              {
                'homepage-benefits-section__header--headline-zip-code':
                  showWasteProducerContent,
              },
              {
                'homepage-benefits-section__header--headline-request-demo':
                  showWasteDisposerContent,
              },
            )}
          >
            <Headline
              color={HEADLINE_COLOR.DARK_GRAY}
              fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
              fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
              headlineStyle={HEADLINE_STYLE.H2}
              tag={HEADLINE_TAG.H1}
            >
              {showWasteProducerContent && (
                <Translate value='homepages.wasteProducer.benefits.headline' />
              )}
              {showWasteDisposerContent && (
                <Translate value='homepages.wasteDisposer.benefits.headline' />
              )}
            </Headline>
            <p>
              {showWasteProducerContent && (
                <Translate value='homepages.wasteProducer.benefits.bodytext' />
              )}
              {showWasteDisposerContent && (
                <Translate value='homepages.wasteDisposer.benefits.bodytext' />
              )}
            </p>
            <Media query={{ minWidth: BREAKPOINT.LARGE }}>
              {isBigScreen => (
                <div
                  className={classNames({
                    'homepage-benefits-section__header--headline-request-demo-center-button':
                      !isBigScreen,
                  })}
                >
                  <LinkButton
                    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                    target='/demo-anfordern'
                  >
                    <Translate value='homepages.wasteProducer.benefits.requestDemo' />
                  </LinkButton>
                </div>
              )}
            </Media>
          </div>
        </section>
      </VisualBackgroundElement>

      {showWasteProducerContent && (
        <>
          <HomepagesSlider
            slidesContent={sliderLandingPageWasteProducerContent}
          />

          <BenefitsSection>
            <BenefitItem
              imageUrl={IconComparabilityImage}
              headlineTitle='homepages.wasteProducer.benefits.comparability.headline'
              headlineText='homepages.wasteProducer.benefits.comparability.paragraph'
            />
            <BenefitItem
              imageUrl={IconTransparencyImage}
              headlineTitle='homepages.wasteProducer.benefits.transparency.headline'
              headlineText='homepages.wasteProducer.benefits.transparency.paragraph'
            />
            <BenefitItem
              imageUrl={IconWarrantyServiceImage}
              headlineTitle='homepages.wasteProducer.benefits.warrantyService.headline'
              headlineText='homepages.wasteProducer.benefits.warrantyService.paragraph'
            />
            <BenefitItem
              imageUrl={IconStartDirectlyImage}
              headlineTitle='homepages.wasteProducer.benefits.startDirectly.headline'
              headlineText='homepages.wasteProducer.benefits.startDirectly.paragraph'
            />
            <BenefitItem
              imageUrl={IconLegalSecurityImage}
              headlineTitle='homepages.wasteProducer.benefits.legalSecurity.headline'
              headlineText='homepages.wasteProducer.benefits.legalSecurity.paragraph'
            />
            <BenefitItem
              imageUrl={IconCustomerServiceImage}
              headlineTitle='homepages.wasteProducer.benefits.customerService.headline'
              headlineText='homepages.wasteProducer.benefits.customerService.paragraph'
            />
          </BenefitsSection>
        </>
      )}
    </>
  )
}
