export const companyFilterTranslation = {
  address: 'Adresse',
  avvNumber: 'AVV Nummer',
  company: 'Unternehmen',
  contactPerson: 'Ansprechpartner',
  container: 'Behälter',
  customerNumber: 'Kundennummer',
  disposer: 'Partner',
  fraction: 'Abfallart',
  producer: 'Kunde',
}
