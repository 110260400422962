import { createSelector } from 'reselect'

/**
 * @description selector to get an offer.
 * @param state
 * @return {*}
 */
export const getOfferSelector: (state: any) => Offer = state => ({
  ...state.offer.item,
  net_cost: Number(state.offer.item.net_cost),
  total_cost: Number(state.offer.item.total_cost),
  total_revenue: Number(state.offer.item.total_revenue),
})

/**
 * @description selector to get an offer list pagination.
 * @param state
 * @return {*}
 */
export const getOffersPaginationSelector: (state: any) => Pagination = state =>
  state.offer.pagination

/**
 * @description selector to get an offer order availability.
 * @param state
 * @return {*}
 */
export const getOffersHasOrdersSelector: (state: any) => boolean = state =>
  state.offer.hasOrders

/**
 * @description selector to get offer archive availability.
 * @param state
 * @return {*}
 */
export const getOffersHasArchiveSelector: (state: any) => boolean = state =>
  state.offer.hasArchive

/**
 * @description selector to get offer archive availability.
 * @param state
 * @return {*}
 */
export const getOffersIsFilteredSelector: (state: any) => boolean = state =>
  state.offer.isFiltered

/**
 * @description selector to get an offer list.
 * @param state
 * @return {*}
 */
export const getOffersSelector: (state: any) => Offer[] = createSelector(
  state => state.offer.itemList,
  itemList =>
    itemList.map(item => ({
      ...item,
      net_cost: Number(item.net_cost),
      total_cost: Number(item.total_cost),
      total_revenue: Number(item.total_revenue),
    })),
)

/**
 * @description selector to get an offer list.
 * @param state
 * @return {*}
 */
export const getMyOffersSelector: (state: any) => Offer[] = createSelector(
  state => state.offer.myItemList,
  itemList =>
    itemList.map(item => ({
      ...item,
      net_cost: Number(item.net_cost),
      total_cost: Number(item.total_cost),
      total_revenue: Number(item.total_revenue),
      number_of_containers: Number(item.number_of_containers),
    })),
)

export const getOfferReviewSelector: (state: any) => {
  loaded: boolean
  item: OfferReview
} = state => state.offer
