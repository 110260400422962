import './style.scss'

import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { autoChangeDisposer } from '../../actions/offer'
import {
  getOrderForDisposerManagement,
  setDisposerManagementOrderToConfirmed,
} from '../../actions/order'
import { getOrderForDisposerManagementSelector } from '../../selectors/order'
import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from '../../selectors/requestStatus'
import { UpcomingOrderType } from '../maklerpremium/UpcomingOrders/UpcomingOrdersList'

import { ErrorMessage } from './components/ErrorMessage'
import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { OrderManagementForm } from './components/OrderManagementForm/orderManagementForm'
import { SuccessMessage } from './components/SuccessMessage'

const getTodayAsString = (): string => {
  const todayDate = new Date().toISOString().slice(0, 10)
  return todayDate
}

export const WasteDisposerOrderManagementPage = () => {
  const dispatch = useDispatch()
  const [status, setStatus] = useState('')
  const location = useLocation()
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  )

  // fetch information on this order
  useEffect(() => {
    dispatch(
      getOrderForDisposerManagement(
        Number(searchParams.get('order_id') ?? -1),
        searchParams.get('order_hash') ?? '',
      ),
    )
  }, [dispatch, searchParams])

  const orderDetails = useSelector(getOrderForDisposerManagementSelector)

  // RequestStatus allows to check if the confirmation/cancellation actions succeeded or failed
  const cancellationStatus = useSelector(
    createRequestStatusSelector(['AUTO_CHANGE_DISPOSER']),
  )
  const confirmationStatus = useSelector(
    createRequestStatusSelector(['SET_DISPOSER_MANAGEMENT_ORDER_TO_CONFIRMED']),
  )

  // set status for cancellation action
  useEffect(() => {
    if (cancellationStatus === REQUEST_STATUS.SUCCESS) {
      setStatus('cancelled')
    }

    if (cancellationStatus === REQUEST_STATUS.ERROR) {
      setStatus('cancellationError')
    }
  }, [cancellationStatus])

  // set status for confirmation action
  useEffect(() => {
    if (confirmationStatus === REQUEST_STATUS.SUCCESS) {
      setStatus('confirmed')
    }

    if (confirmationStatus === REQUEST_STATUS.ERROR) {
      setStatus('confirmationError')
    }
  }, [confirmationStatus])

  useEffect(() => {
    const today = getTodayAsString()
    if (!moment(orderDetails?.delivery_date).isSameOrAfter(today)) {
      setStatus('expired')
    }
  }, [orderDetails])

  const disposerDetails = (({
    disposer_name,
    disposer_address,
    disposer_address_street,
    disposer_logo,
  }) => ({
    disposer_name,
    disposer_address,
    disposer_address_street,
    disposer_logo,
  }))(orderDetails)

  //todo: get actual header configuration (TODO: implement an API and configuration in backend first)
  const headerConfiguration = {
    logoUrl: '/img/email/epd-logo-vertical.png',
    certificates: [
      'Zertifizierte Entsorgung',
      'Nur für Gewerbe',
      '20 Jahre Erfahrung',
    ],
    hotline: {
      text: 'Kostenlose Hotline ',
      number: '0800 638 25 53',
    },
  }

  //todo: get actual footer configuration (TODO: implement an API and configuration in backend first)
  const footerConfiguration = {
    systemName: '@ 2022 Entsorgung Punkt DE GmbH',
    privacyLink: 'https://www.entsorgung.de/datenschutz/',
    termsLink: 'https://www.entsorgung.de/agb/',
    imprintLink: 'https://www.entsorgung.de/impressum/',
  }

  const handleConfirmation = () => {
    if (searchParams.get('delivery') && searchParams.get('collection')) {
      dispatch(
        setDisposerManagementOrderToConfirmed(
          searchParams.get('order_hash') ?? '',
          Number(searchParams.get('order_id') ?? -1),
          Number(searchParams.get('delivery') ?? -1),
          [UpcomingOrderType.DELIVERY, UpcomingOrderType.COLLECTION],
          Number(searchParams.get('user_id') ?? -1),
        ),
      )
    } else if (searchParams.get('delivery')) {
      dispatch(
        setDisposerManagementOrderToConfirmed(
          searchParams.get('order_hash') ?? '',
          Number(searchParams.get('order_id') ?? -1),
          Number(searchParams.get('delivery') ?? -1),
          [UpcomingOrderType.DELIVERY],
          Number(searchParams.get('user_id') ?? -1),
        ),
      )
    } else if (searchParams.get('collection')) {
      dispatch(
        setDisposerManagementOrderToConfirmed(
          searchParams.get('order_hash') ?? '',
          Number(searchParams.get('order_id') ?? -1),
          Number(searchParams.get('collection') ?? -1),
          [UpcomingOrderType.COLLECTION],
          Number(searchParams.get('user_id') ?? -1),
        ),
      )
    }
  }

  const handleCancellation = (
    offer_id: number,
    offer_hash: string,
    user_id: number,
    reason: string,
    reasonText?: string,
  ) => {
    dispatch(
      autoChangeDisposer(offer_id, offer_hash, user_id, reason, reasonText),
    )
  }

  const renderComponent = status => {
    switch (status) {
      case 'confirmed':
        // Confirmation successful
        return (
          <SuccessMessage
            title={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.successMessage.success.title',
            )}
            subTitle={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.successMessage.success.subTitle',
            )}
            text={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.successMessage.success.text',
            )}
          />
        )
      case 'cancelled':
        // Cancellation successful
        return (
          <SuccessMessage
            title={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.successMessage.cancelled.title',
            )}
            subTitle={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.successMessage.cancelled.subTitle',
            )}
            text={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.successMessage.cancelled.text',
            )}
          />
        )
      case 'confirmationError':
        // Confirmation failed
        return (
          <ErrorMessage
            title={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.errorMessage.confirmation.title',
            )}
            subTitle={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.errorMessage.confirmation.subTitle',
            )}
            text={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.errorMessage.confirmation.text',
            )}
          />
        )
      case 'cancellationError':
        // Cancellation failed
        return (
          <ErrorMessage
            title={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.errorMessage.cancellation.title',
            )}
            subTitle={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.errorMessage.cancellation.subTitle',
            )}
            text={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.errorMessage.cancellation.text',
            )}
          />
        )
      case 'expired':
        // The offer has already expired, user cannot perform any self-management tasks
        return (
          <ErrorMessage
            title={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.errorMessage.expired.title',
            )}
            subTitle={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.errorMessage.expired.subTitle',
            )}
            text={I18n.t(
              'WasteDisposerOrderManagementPageTranslations.errorMessage.expired.text',
            )}
          />
        )
      default:
        return (
          <OrderManagementForm
            handleConfirmation={handleConfirmation}
            handleCancellation={handleCancellation}
            orderDetails={orderDetails}
          />
        )
    }
  }

  return (
    <>
      <Header
        logoUrl={headerConfiguration.logoUrl}
        certificates={headerConfiguration.certificates}
        hotline={headerConfiguration.hotline}
        disposerDetails={disposerDetails}
      />
      <div className='page color-scheme__entsorgung-de'>
        <div className='uk-container uk-container-large'>
          {renderComponent(status)}
        </div>
      </div>
      <Footer
        systemName={footerConfiguration.systemName}
        privacyLink={footerConfiguration.privacyLink}
        termsLink={footerConfiguration.termsLink}
        imprintLink={footerConfiguration.imprintLink}
      />
    </>
  )
}
