import '../../CreateMaklerPremiumOfferPage/OfferFormSteps/style.scss'
import './style.scss'

import { Form, Formik, FormikErrors } from 'formik'
import moment, { isMoment, Moment } from 'moment'
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { resetApiFetchLoading } from 'actions/app'
import { resetAutoOffers } from 'actions/autooffer'
import { resetIndexValues } from 'actions/indexes'
import {
  createInvoiceCheckPosition,
  updateInvoiceCheckPosition,
} from 'actions/maklerpremium'
import { resetOrders } from 'actions/order'
import { FormStep } from 'components/common/FormSteps'
import {
  progressStepStatus,
  STEP_STATUS,
} from 'components/common/FormSteps/helper'
import { INQUIRY_ORDER_TYPE } from 'components/inquiry/constants'
import { getFractionsFromFractionId } from 'components/inquiry/helpers'
import { PRICE_MODELS } from 'components/offer/constants'
import { PriceDetailsValues } from 'components/offer/CreateOfferPage/constants'
import { COLLECTION_CONTAINER_IDS, DATE_FORMATS } from 'constants/app'
import { germanDecimalToInternationalFormat } from 'helper/general'
import withErrorBoundary from 'helper/withErrorBoundary'
import { getEPDFractionsSelector } from 'selectors/fraction'
import { getMyOffersSelector } from 'selectors/offer'
import { getOrdersForInvoiceCheckSelector } from 'selectors/order'
import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'

import { INVOICE_CHECK_POSITIONS_CATEGORIES } from '../../constants'
import { InvoiceCheckDocumentType } from '../../InvoiceCheckPage/InvoiceCheckAcquisition'

import { CollectionAddressForm, CollectionAddressSummary } from './Step1'
import {
  OrderAndServicesForm,
  OrderAndServicesSummary,
  OrderType,
} from './Step2'
import { SURCHARGE_REDUCTION } from './Step2/OpenOrderForm/CompensationPriceFields/CompensationPriceFields'
import { NetPriceType, WeightOrNumber } from './Step2/OrderAndServicesForm'
import { PositionsSummary } from './Step3'

type InvoiceCheckContextType = {
  stepStatus: STEP_STATUS
  saveStep: () => void
  editStep: () => void
  invoiceCheck?: InvoiceCheck
  invoiceCheckPosition?: InvoiceCheckPosition
  handleCloseModal: () => void
}

export const InvoiceCheckPositionsFormContext =
  createContext<InvoiceCheckContextType>({
    stepStatus: STEP_STATUS.INITIAL,
    saveStep: () => undefined,
    editStep: () => undefined,
    invoiceCheck: undefined,
    invoiceCheckPosition: undefined,
    handleCloseModal: () => undefined,
  })

export interface InvoiceCheckPositionsFormStepsValues
  extends PriceDetailsValues {
  non_field_errors: any
  id: undefined | React.ReactText
  // Step 1: CustomerInfo
  search_field: string
  collection_address: number
  order: React.ReactText
  company_name: string
  zipcode: React.ReactText
  // Step 2: OrderAndServices
  order_type: OrderType
  avv: string
  number_of_containers: number
  weight_or_number: WeightOrNumber
  weight_object_list: WeightReceipt[]
  weight_receipt: string // errors only
  weight: string // errors only
  quantity_in_cubic_meters: number
  delivery_date: Moment | string
  collection_date: Moment | string
  disposal_proof_number: string
  transportation_company_number: string
  disposer_number: string
  security_group: number
  // Step 2 > Sonstige Dienstleistung && Step 2 > Mietbeleg
  category: number
  net_price_type: number
  // Step 2 > Sonstige Dienstleistung
  other_service_other_text: string
  // Step 2 > Mietbeleg
  offer: React.ReactText
  // Sonstige Dienstleistung & Mietbeleg
  net_price: string
}

export type InvoiceCheckPositionsFormValues =
  Partial<InvoiceCheckPositionsFormStepsValues>

export const clearOtherValues = submittedValues => {
  if (submittedValues.order_type === OrderType.OPEN_ORDER) {
    return {
      ...submittedValues,
      category: undefined,
      net_price: undefined,
      net_price_type: undefined,
      offer: undefined,
      other_service_other_text: '',
      weight_receipts:
        submittedValues.weight_or_number === WeightOrNumber.INPUT_WEIGHT
          ? submittedValues.weight_receipts
          : [],
    }
  }
  if (submittedValues.order_type === OrderType.OTHER_SERVICE) {
    return {
      ...submittedValues,
      avv: undefined,
      compensation_container: undefined,
      disposal_cost_container: undefined,
      disposal_cost_ton: undefined,
      disposal_proof_number: undefined,
      disposer_number: undefined,
      handle_cost_ton: undefined,
      number_of_containers: undefined,
      offer: undefined,
      quantity_in_cubic_meters: undefined,
      security_group: undefined,
      transport_price_piece: undefined,
      transportation_company_number: undefined,
      weight_or_number: undefined,
      weight_receipts: undefined,
      index: undefined,
      index_month: undefined,
      surcharge: undefined,
      reduction: undefined,
    }
  }
  if (submittedValues.order_type === OrderType.RENT) {
    return {
      ...submittedValues,
      avv: undefined,
      compensation_container: undefined,
      disposal_cost_container: undefined,
      disposal_cost_ton: undefined,
      disposal_proof_number: undefined,
      disposer_number: undefined,
      handle_cost_ton: undefined,
      number_of_containers: undefined,
      other_service_other_text: '',
      quantity_in_cubic_meters: undefined,
      security_group: undefined,
      transport_price_piece: undefined,
      transportation_company_number: undefined,
      weight_or_number: undefined,
      weight_receipts: undefined,
      index: undefined,
      index_month: undefined,
      surcharge: undefined,
      reduction: undefined,
    }
  }
  return Object.fromEntries(
    Object.entries(submittedValues).filter(([, value]) => value !== ''),
  )
}

interface InvoiceCheckPositionsFormStepsProps {
  handleCloseModal: () => void
  invoiceCheck: InvoiceCheck
  invoiceCheckPosition?: InvoiceCheckPosition
  setInvoiceCheckPosition: (item: InvoiceCheckPosition | undefined) => void
}

const InvoiceCheckPositionsFormStepsComponent: FC<
  InvoiceCheckPositionsFormStepsProps
> = ({
  handleCloseModal,
  invoiceCheck,
  invoiceCheckPosition,
  setInvoiceCheckPosition,
}) => {
  const dispatch = useDispatch()

  const fractionList = useSelector(getEPDFractionsSelector)
  const { coarseFraction, fraction } = getFractionsFromFractionId(
    invoiceCheckPosition?.fraction,
    fractionList,
  )

  const initialStepsStatus = useMemo(
    () => [
      STEP_STATUS.COMPLETED,
      STEP_STATUS.CREATING,
      STEP_STATUS.INITIAL,
      STEP_STATUS.INITIAL,
    ],
    [],
  )

  const [stepsStatus, setStepsStatus] =
    useState<STEP_STATUS[]>(initialStepsStatus)

  // reset steps on submit / cancel
  const onCloseModal = useCallback(() => {
    setStepsStatus(initialStepsStatus)
    setInvoiceCheckPosition(undefined)
    handleCloseModal()
  }, [handleCloseModal, initialStepsStatus, setInvoiceCheckPosition])

  // reset loading state and close modal
  const createRequestStatus = useSelector(
    createRequestStatusSelector(['CREATE_INVOICE_CHECK_POSITION']),
  )
  useEffect(() => {
    if (createRequestStatus === REQUEST_STATUS.SUCCESS) {
      dispatch(resetApiFetchLoading('CREATE_INVOICE_CHECK_POSITION'))
      onCloseModal()
    } else if (createRequestStatus === REQUEST_STATUS.ERROR) {
      dispatch(resetApiFetchLoading('CREATE_INVOICE_CHECK_POSITION'))
    }
  }, [createRequestStatus, dispatch, onCloseModal])

  // reset loading state and close modal
  const updateRequestStatus = useSelector(
    createRequestStatusSelector(['UPDATE_INVOICE_CHECK_POSITION']),
  )
  useEffect(() => {
    if (updateRequestStatus === REQUEST_STATUS.SUCCESS) {
      dispatch(resetApiFetchLoading('UPDATE_INVOICE_CHECK_POSITION'))
      onCloseModal()
    } else if (updateRequestStatus === REQUEST_STATUS.ERROR) {
      dispatch(resetApiFetchLoading('UPDATE_INVOICE_CHECK_POSITION'))
    }
  }, [dispatch, onCloseModal, updateRequestStatus])

  // reset loading state and close modal
  const deletePositionRequestStatus = useSelector(
    createRequestStatusSelector(['DELETE_INVOICE_CHECK_POSITION']),
  )
  useEffect(() => {
    if (deletePositionRequestStatus === REQUEST_STATUS.SUCCESS) {
      dispatch(resetApiFetchLoading('DELETE_INVOICE_CHECK_POSITION'))
      onCloseModal()
    } else if (deletePositionRequestStatus === REQUEST_STATUS.ERROR) {
      dispatch(resetApiFetchLoading('DELETE_INVOICE_CHECK_POSITION'))
    }
  }, [deletePositionRequestStatus, dispatch, onCloseModal])

  useEffect(() => {
    // reset auto-offers in state whenever this component is rendered
    dispatch(resetAutoOffers())

    // reset orders in state whenever this component is unmounted
    dispatch(resetOrders())

    dispatch(resetIndexValues())
  }, [dispatch])

  const orders = useSelector(getOrdersForInvoiceCheckSelector)
  const offers = useSelector(getMyOffersSelector)

  const formSteps = {
    // Step 1: CollectionAddress
    1: {
      form: <CollectionAddressForm />,
      summary: <CollectionAddressSummary />,
    },
    // Step 2: OrderAndServices
    2: {
      form: <OrderAndServicesForm />,
      summary: <OrderAndServicesSummary />,
    },
    // Step 3: PositionsSummary
    3: { form: <PositionsSummary />, summary: <></> },
  }

  function renderStep(
    index: string,
    step: { summary: ReactNode; form: ReactNode },
  ) {
    return stepsStatus[Number(index)] > STEP_STATUS.INITIAL &&
      stepsStatus[Number(index)] === STEP_STATUS.COMPLETED
      ? step.summary
      : step.form
  }

  const initialOrderType = invoiceCheckPosition?.order_type
    ? invoiceCheckPosition?.order_type
    : OrderType.OPEN_ORDER

  let initialCollectionAddress
  let initialZipcode
  let initialCompany
  let initialWeightOrNumber

  if (initialOrderType === OrderType.RENT) {
    initialCollectionAddress =
      invoiceCheckPosition?.offer_object.collection_address_object.id
    initialZipcode =
      invoiceCheckPosition?.offer_object.collection_address_object.zipcode
    initialCompany =
      invoiceCheckPosition?.offer_object.customer_company_object.name
  } else {
    initialCollectionAddress =
      invoiceCheckPosition?.order_object.collection_address_object.id
    initialZipcode =
      invoiceCheckPosition?.order_object.collection_address_object.zipcode
    initialCompany =
      invoiceCheckPosition?.order_object.customer_company_object.name
  }

  if (
    invoiceCheckPosition?.weight_receipts &&
    invoiceCheckPosition?.weight_receipts?.length > 0
  ) {
    initialWeightOrNumber = WeightOrNumber.INPUT_WEIGHT
  } else if (invoiceCheckPosition?.number_of_containers) {
    initialWeightOrNumber = WeightOrNumber.INPUT_NUMBER_OF_CONTAINERS
  } else {
    initialWeightOrNumber = WeightOrNumber.INPUT_WEIGHT
  }

  const initialValues = {
    non_field_errors: undefined, // dummy value for non-field-errors, DO NOT USE in Form
    id: invoiceCheckPosition?.id,
    // Step 1: CustomerInfo
    search_field: 'company_name',
    collection_address: initialCollectionAddress,
    order: invoiceCheckPosition?.order_object
      ? invoiceCheckPosition?.order_object.id
      : undefined,
    zipcode: initialZipcode,
    company_name: initialCompany,
    // Step 2: OrderAndServices
    order_type: initialOrderType,
    fraction: coarseFraction?.id,
    fine_fraction: fraction?.id,
    container: invoiceCheckPosition?.container
      ? invoiceCheckPosition?.container
      : 1,
    number_of_containers: invoiceCheckPosition?.number_of_containers,
    disposal_proof_number: invoiceCheckPosition?.disposal_proof_number
      ? invoiceCheckPosition.disposal_proof_number
      : undefined,
    transportation_company_number:
      invoiceCheckPosition?.transportation_company_number
        ? invoiceCheckPosition.transportation_company_number
        : undefined,
    disposer_number: invoiceCheckPosition?.disposer_number
      ? invoiceCheckPosition.disposer_number
      : undefined,
    avv: invoiceCheckPosition?.avv ? invoiceCheckPosition?.avv : '',
    security_group: invoiceCheckPosition?.security_group
      ? invoiceCheckPosition.security_group
      : undefined,

    transport_price_piece: Number(
      (invoiceCheckPosition?.transport_price_piece
        ? invoiceCheckPosition?.transport_price_piece
        : invoiceCheckPosition?.order_object?.transport_price_piece) ?? 0,
    ).toLocaleString('de-DE', { minimumFractionDigits: 2 }),
    disposal_cost_container: Number(
      (invoiceCheckPosition?.disposal_cost_container
        ? invoiceCheckPosition?.disposal_cost_container
        : invoiceCheckPosition?.order_object?.disposal_cost_container) ?? 0,
    ).toLocaleString('de-DE', { minimumFractionDigits: 2 }),
    cost_container: Number(
      (invoiceCheckPosition?.cost_container
        ? invoiceCheckPosition?.cost_container
        : invoiceCheckPosition?.order_object?.cost_container) ?? 0,
    ).toLocaleString('de-DE', { minimumFractionDigits: 2 }),
    compensation_container: Number(
      (invoiceCheckPosition?.compensation_container
        ? invoiceCheckPosition?.compensation_container
        : invoiceCheckPosition?.order_object?.compensation_container) ?? 0,
    ).toLocaleString('de-DE', { minimumFractionDigits: 2 }),
    disposal_cost_ton: Number(
      (invoiceCheckPosition?.disposal_cost_ton
        ? invoiceCheckPosition?.disposal_cost_ton
        : invoiceCheckPosition?.order_object?.disposal_cost_ton) ?? 0,
    ).toLocaleString('de-DE', { minimumFractionDigits: 2 }),
    compensation_ton: Number(
      (invoiceCheckPosition?.compensation_ton
        ? invoiceCheckPosition?.compensation_ton
        : invoiceCheckPosition?.order_object?.compensation_ton) ?? 0,
    ).toLocaleString('de-DE', { minimumFractionDigits: 2 }),
    handle_cost_ton: Number(
      (invoiceCheckPosition?.handle_cost_ton
        ? invoiceCheckPosition?.handle_cost_ton
        : invoiceCheckPosition?.order_object?.handle_cost_ton) ?? 0,
    ).toLocaleString('de-DE', { minimumFractionDigits: 2 }),
    index: invoiceCheckPosition?.index,
    index_month: invoiceCheckPosition?.index_month,
    index_surcharge_reduction: invoiceCheckPosition?.reduction
      ? SURCHARGE_REDUCTION.REDUCTION
      : SURCHARGE_REDUCTION.SURCHARGE,
    surcharge: Number(
      invoiceCheckPosition?.surcharge
        ? invoiceCheckPosition?.surcharge
        : undefined,
    ).toLocaleString('de-DE', { minimumFractionDigits: 2 }),
    reduction: Number(
      invoiceCheckPosition?.reduction
        ? invoiceCheckPosition?.reduction
        : undefined,
    ).toLocaleString('de-DE', { minimumFractionDigits: 2 }),

    weight_or_number: initialWeightOrNumber,
    weight_object_list: invoiceCheckPosition?.weight_receipts ?? [
      { weight_receipt: '', weight: '' },
    ],
    quantity_in_cubic_meters: undefined,
    delivery_date: invoiceCheckPosition
      ? moment(invoiceCheckPosition.delivery_date, DATE_FORMATS).format('L')
      : '',
    collection_date: invoiceCheckPosition
      ? moment(invoiceCheckPosition.collection_date, DATE_FORMATS).format('L')
      : '',

    category: invoiceCheckPosition?.category
      ? invoiceCheckPosition?.category
      : undefined,
    other_service_other_text: invoiceCheckPosition?.other_service_other_text
      ? invoiceCheckPosition?.other_service_other_text
      : '',
    net_price: invoiceCheckPosition?.net_price
      ? String(invoiceCheckPosition?.net_price)
      : '',
    net_price_type:
      invoiceCheckPosition?.net_price_type ??
      (invoiceCheck.document_type === InvoiceCheckDocumentType.CREDIT_NOTE
        ? NetPriceType.CREDIT
        : NetPriceType.BURDEN),
    offer: invoiceCheckPosition?.offer_object
      ? invoiceCheckPosition?.offer_object.id
      : undefined,
  }

  const validationSchema = yup.object().shape({
    // Step 1: CustomerInfo
    collection_address: yup
      .number()
      .required(
        I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.1.fields.collection_address.error',
        ),
      ),
    // Step 2: OrderAndServices
    order_type: yup
      .number()
      .required(
        I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.2.fields.order_type.error',
        ),
      ),
    order: yup.number().when('order_type', {
      is: order_type =>
        order_type === OrderType.OTHER_SERVICE ||
        order_type === OrderType.OPEN_ORDER,
      then: yup
        .number()
        .required(
          I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.order.error',
          ),
        ),
      otherwise: yup.number().nullable(),
    }),
    offer: yup.number().when('order_type', {
      is: order_type => order_type === OrderType.RENT,
      then: yup
        .number()
        .required(
          I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.offer.error',
          ),
        ),
      otherwise: yup.number().nullable(),
    }),
    fraction: yup.number().nullable(),
    container: yup.number().nullable(),
    avv: yup.string().nullable(),
    weight_or_number: yup.number().nullable(),
    weight_object_list: yup
      .array()
      .of(
        yup.object({
          weight_receipt: yup.string().nullable(),
          weight: yup.string().nullable(),
        }),
      )
      .nullable(),
    delivery_date: yup
      .string()
      .required(
        I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.2.fields.delivery_date.error',
        ),
      ),

    category: yup.number().when('order_type', {
      is: order_type => order_type === OrderType.OTHER_SERVICE,
      then: yup
        .number()
        .required(
          I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.categoryText.error',
          ),
        ),
    }),
    other_service_other_text: yup.string().nullable(),
    net_price: yup.string().nullable(),
    security_group: yup.number().when('fraction', {
      is: fraction => fraction?.security_group === true,
      then: yup
        .number()
        .required(
          I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.security_group.error',
          ),
        ),
      otherwise: yup.number().nullable(),
    }),
  })

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={(values: InvoiceCheckPositionsFormValues) => {
        const errors: FormikErrors<InvoiceCheckPositionsFormValues> = {}
        const selectedOrder = orders.find(
          order => order.id === Number(values.order),
        )
        const selectedOffer = offers.find(
          offer => offer.id === Number(values.offer),
        )

        let showQuantityInCubicMeters = false

        const typeIsOtherServices =
          values.order_type === OrderType.OTHER_SERVICE
        const typeIsOpenOrder = values.order_type === OrderType.OPEN_ORDER
        const typeIsRent = values.order_type === OrderType.RENT

        const isValidPrice = value =>
          typeof value === 'string'
            ? value !== ''
            : !Number.isNaN(Number(value)) && Number(value) >= 0

        if (!selectedOrder && (typeIsOpenOrder || typeIsOtherServices)) {
          errors.order = I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.1.fields.order.error',
          )
        } else if (selectedOrder) {
          showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
            Number(selectedOrder.container),
          )
        }

        if (!selectedOffer && typeIsRent) {
          errors.offer = I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.offer.error',
          )
        }

        if (typeIsOpenOrder) {
          if (!values.fraction) {
            errors.fraction = I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.2.fields.fraction.error',
            )
          }
          if (!values.avv) {
            errors.avv = I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.error',
            )
          }

          const currentFraction = fractionList.find(
            f => f.id === Number(values.fraction),
          )

          if (currentFraction && values && values.avv) {
            if (
              currentFraction.avv_list.filter(f =>
                values?.avv?.includes(f.number),
              ).length === 0
            ) {
              errors.avv = I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.notMatch',
              )
              errors.fraction = I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.fraction.notMatch',
              )
            }
          }

          if (
            currentFraction?.requires_securitygroup &&
            !values.security_group
          ) {
            errors.security_group = I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.2.fields.security_group.error',
            )
          }

          if (
            values.avv &&
            values.avv.indexOf('*') !== -1 &&
            !fractionList.find(fraction => fraction.id === currentFraction?.id)
              ?.no_takeover_certificate
          ) {
            if (values.disposal_proof_number?.length !== 12) {
              errors.disposal_proof_number = I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.disposal_proof_number.error',
              )
            }

            if (values.transportation_company_number?.length !== 9) {
              errors.transportation_company_number = I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.transportation_company_number.error',
              )
            }

            if (values.disposer_number?.length !== 9) {
              errors.disposer_number = I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.error',
              )
            }
          }

          if (!values.container) {
            errors.fraction = I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.2.fields.container.error',
            )
          }

          if (
            !values.number_of_containers ||
            values.number_of_containers >
              (selectedOffer?.number_of_containers ?? 0)
          ) {
            errors.number_of_containers = I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.2.fields.number_of_containers.error',
            )
          }

          if (
            invoiceCheck.document_type === InvoiceCheckDocumentType.INVOICE &&
            values.price_model === PRICE_MODELS.DISPOSAL_SINGLE
          ) {
            if (!isValidPrice(values.transport_price_piece)) {
              errors.transport_price_piece = I18n.t(
                'priceDetailFieldsTranslations.transportPricePiece',
              )
            }
            if (!isValidPrice(values.disposal_cost_ton)) {
              errors.disposal_cost_ton = I18n.t(
                'priceDetailFieldsTranslations.disposalCostTon',
              )
            }
          }
          if (
            invoiceCheck.document_type === InvoiceCheckDocumentType.INVOICE &&
            values.price_model === PRICE_MODELS.COMPENSATION_SINGLE
          ) {
            if (!isValidPrice(values.transport_price_piece)) {
              errors.transport_price_piece = I18n.t(
                'priceDetailFieldsTranslations.transportPricePiece',
              )
            }
            if (!isValidPrice(values.handle_cost_ton)) {
              errors.handle_cost_ton = I18n.t(
                'disposalPriceFieldsTranslations.handleCostTon',
              )
            }
          }
          if (
            invoiceCheck.document_type ===
              InvoiceCheckDocumentType.CREDIT_NOTE &&
            values.price_model === PRICE_MODELS.COMPENSATION_SINGLE
          ) {
            if (!isValidPrice(values.compensation_ton)) {
              errors.compensation_ton = I18n.t(
                'compensationPriceFieldsTranslations.compensationTon',
              )
            }
          }
          if (
            invoiceCheck.document_type === InvoiceCheckDocumentType.INVOICE &&
            values.price_model === PRICE_MODELS.DISPOSAL_CONTAINER
          ) {
            if (!isValidPrice(values.disposal_cost_container)) {
              errors.disposal_cost_container = I18n.t(
                'priceDetailFieldsTranslations.disposalCostContainer',
              )
            }
          }

          if (
            invoiceCheck.document_type === InvoiceCheckDocumentType.INVOICE &&
            values.price_model === PRICE_MODELS.COMPENSATION_CONTAINER
          ) {
            if (!isValidPrice(values.cost_container)) {
              errors.cost_container = I18n.t(
                'disposalPriceFieldsTranslations.costContainer',
              )
            }
          }

          if (
            invoiceCheck.document_type ===
              InvoiceCheckDocumentType.CREDIT_NOTE &&
            values.price_model === PRICE_MODELS.COMPENSATION_CONTAINER
          ) {
            if (!isValidPrice(values.compensation_container)) {
              errors.compensation_container = I18n.t(
                'compensationPriceFieldsTranslations.compensationContainer',
              )
            }
          }

          if (values.weight_or_number === WeightOrNumber.INPUT_WEIGHT) {
            if (
              !values.weight_object_list ||
              values.weight_object_list.length === 0
            ) {
              errors.weight_receipt = I18n.t(
                'executionProofDetailsPageTranslations.validation.weightReceipt',
              )
              errors.weight = I18n.t(
                'executionProofDetailsPageTranslations.validation.fractionNotEqual',
              )
            } else {
              values.weight_object_list.forEach(val => {
                if (!val.weight_receipt) {
                  errors.weight_receipt = I18n.t(
                    'executionProofDetailsPageTranslations.validation.weightReceipt',
                  )
                }
                if (!val.weight) {
                  errors.weight = I18n.t(
                    'executionProofDetailsPageTranslations.validation.weight',
                  )
                }
              })
            }
          }

          if (
            values.weight_or_number ===
            WeightOrNumber.INPUT_NUMBER_OF_CONTAINERS
          ) {
            if (!showQuantityInCubicMeters && !values.number_of_containers) {
              errors.number_of_containers = I18n.t(
                'executionProofDetailsPageTranslations.validation.numberOfContainers',
              )
            }

            if (showQuantityInCubicMeters) {
              if (!values.quantity_in_cubic_meters) {
                errors.quantity_in_cubic_meters = I18n.t(
                  'executionProofDetailsPageTranslations.validation.numberOfContainers',
                )
              }
              if (
                !Number.isNaN(Number(values.quantity_in_cubic_meters)) &&
                Number(values.quantity_in_cubic_meters) <= 0
              ) {
                errors.quantity_in_cubic_meters = I18n.t(
                  'executionProofDetailsPageTranslations.validation.numberOfContainers',
                )
              }
            }
          }
        }

        if (typeIsOpenOrder) {
          if (selectedOrder?.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME) {
            const delivery_date = moment(values.delivery_date, DATE_FORMATS)
            const collection_date = moment(values.collection_date, DATE_FORMATS)
            if (
              !(
                // negated!!!
                (
                  isMoment(delivery_date) &&
                  delivery_date.isValid() &&
                  isMoment(collection_date) &&
                  collection_date.isValid() &&
                  !collection_date.isBefore(delivery_date)
                )
              )
            ) {
              errors.collection_date = I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.collection_date.error',
              )
            }
          } else {
            const delivery_date = moment(values.delivery_date, DATE_FORMATS)
            // negated!!!
            if (!(isMoment(delivery_date) && delivery_date.isValid())) {
              errors.delivery_date = I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.delivery_date.error',
              )
            }
          }

          if (values.index) {
            if (!isValidPrice(values.index_month)) {
              errors.index_month = I18n.t(
                'compensationPriceFieldsTranslations.indexMonth.error',
              )
            }
            if (
              values.index_surcharge_reduction ===
                SURCHARGE_REDUCTION.SURCHARGE &&
              !isValidPrice(values.surcharge)
            ) {
              errors.surcharge = I18n.t(
                'compensationPriceFieldsTranslations.surcharge.error',
              )
            }

            if (
              values.index_surcharge_reduction ===
                SURCHARGE_REDUCTION.REDUCTION &&
              !isValidPrice(values.reduction)
            ) {
              errors.reduction = I18n.t(
                'compensationPriceFieldsTranslations.reduction.error',
              )
            }
          }
        }

        if (typeIsOtherServices) {
          if (
            Number(values.category) ===
              INVOICE_CHECK_POSITIONS_CATEGORIES.OTHER &&
            !values.other_service_other_text
          ) {
            errors.other_service_other_text = I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.categoryText.error',
            )
          }
        }

        if (typeIsRent || typeIsOtherServices) {
          if (!values.net_price) {
            errors.net_price = I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.netPrice.error',
            )
          }
        }

        return errors
      }}
      onSubmit={(values: InvoiceCheckPositionsFormValues, formikHelpers) => {
        let valuesToSend

        if (values.order_type === OrderType.OPEN_ORDER) {
          const valuesToSendOpenOrder: CreateInvoiceCheckPositionData = {
            avv: values.avv as string,
            price_model: values.price_model as string,
            collection_date:
              values.collection_date &&
              isMoment(values.collection_date) &&
              values.collection_date.isValid()
                ? values.collection_date.format('L')
                : (values.collection_date as string),
            container: values.container as number,
            delivery_date:
              isMoment(values.delivery_date) && values.delivery_date.isValid()
                ? values.delivery_date.format('L')
                : (values.delivery_date as string),
            disposal_cost_container: values.disposal_cost_container
              ? germanDecimalToInternationalFormat(
                  values.disposal_cost_container as string,
                )
              : undefined,
            cost_container: values.cost_container
              ? germanDecimalToInternationalFormat(
                  values.cost_container as string,
                )
              : undefined,
            compensation_container: values.compensation_container
              ? germanDecimalToInternationalFormat(
                  values.compensation_container as string,
                )
              : undefined,
            disposal_proof_number: values.disposal_proof_number as string,
            disposer_number: values.disposer_number as string,
            fraction: (values.fine_fraction ?? values.fraction) as number,
            handle_cost_ton: values.handle_cost_ton
              ? germanDecimalToInternationalFormat(
                  values.handle_cost_ton as string,
                )
              : undefined,
            compensation_ton: values.compensation_ton
              ? germanDecimalToInternationalFormat(
                  values.compensation_ton as string,
                )
              : undefined,
            invoice_check: invoiceCheck.id,
            number_of_containers: values.number_of_containers as number,
            order: `${values.order}`,
            order_type: values.order_type,
            quantity_in_cubic_meters: values.quantity_in_cubic_meters as number,
            security_group: values.security_group as number,
            transportation_company_number:
              values.transportation_company_number as string,
            weight_or_number: values.weight_or_number,
            weight_receipts: values.weight_object_list,
            index: values.index ? (values.index as number) : undefined,
            index_month: values.index_month
              ? (values.index_month as number)
              : undefined,
            surcharge: values.surcharge
              ? germanDecimalToInternationalFormat(values.surcharge as string)
              : undefined,
            reduction: values.reduction
              ? germanDecimalToInternationalFormat(values.reduction as string)
              : undefined,
            disposal_cost_ton: values.disposal_cost_ton
              ? germanDecimalToInternationalFormat(
                  values.disposal_cost_ton as string,
                )
              : undefined,
            transport_price_piece: values.transport_price_piece
              ? germanDecimalToInternationalFormat(
                  values.transport_price_piece as string,
                )
              : undefined,
          }

          valuesToSend = clearOtherValues(valuesToSendOpenOrder)
        }

        if (values.order_type === OrderType.OTHER_SERVICE) {
          const valuesToSendOtherServices: CreateInvoiceCheckPositionOtherServiceData =
            {
              invoice_check: invoiceCheck.id,
              order: `${values.order}`,
              order_type: values.order_type,
              delivery_date:
                isMoment(values.delivery_date) && values.delivery_date.isValid()
                  ? values.delivery_date.format('L')
                  : (values.delivery_date as string),
              collection_date:
                values.collection_date &&
                isMoment(values.collection_date) &&
                values.collection_date.isValid()
                  ? values.collection_date.format('L')
                  : (values.collection_date as string),

              category: values.category as number,
              other_service_other_text:
                values.other_service_other_text as string,
              net_price: values.net_price as string,
              net_price_type: values.net_price_type as number,
            }
          valuesToSend = clearOtherValues(valuesToSendOtherServices)
        }

        if (values.order_type === OrderType.RENT) {
          const valuesToSendRent: CreateInvoiceCheckPositionRentData = {
            invoice_check: invoiceCheck.id,
            offer: `${values.offer}`,
            order_type: values.order_type,
            delivery_date:
              isMoment(values.delivery_date) && values.delivery_date.isValid()
                ? values.delivery_date.format('L')
                : (values.delivery_date as string),
            category: values.category as number,
            net_price: values.net_price as string,
            net_price_type: values.net_price_type as number,
          }
          valuesToSend = clearOtherValues(valuesToSendRent)
        }

        if (invoiceCheckPosition && invoiceCheckPosition.id) {
          dispatch(
            updateInvoiceCheckPosition(valuesToSend, invoiceCheckPosition.id),
          )
        } else {
          dispatch(createInvoiceCheckPosition(valuesToSend))
        }

        formikHelpers.setSubmitting(false)
      }}
    >
      <Form>
        <div className='uk-modal-body'>
          {Object.entries(formSteps).map(([index, step]) => (
            <FormStep
              key={`step_${index}`}
              isDisabled={
                stepsStatus[Number(index) - 1] < STEP_STATUS.COMPLETED
              }
              showDivider
              showProgressLine
              stepNumber={
                stepsStatus[Number(index)] < STEP_STATUS.COMPLETED &&
                Number(index)
              }
              title={`invoiceCheckTranslations.positionsForm.steps.${index}.title`}
            >
              <InvoiceCheckPositionsFormContext.Provider
                value={{
                  stepStatus: stepsStatus[Number(index)],
                  saveStep: () => {
                    progressStepStatus(
                      stepsStatus,
                      setStepsStatus,
                      Number(index),
                    )
                  },
                  editStep: () => {
                    progressStepStatus(
                      stepsStatus,
                      setStepsStatus,
                      Number(index),
                    )
                  },
                  invoiceCheck,
                  invoiceCheckPosition,
                  handleCloseModal: onCloseModal,
                }}
              >
                {renderStep(index, step)}
              </InvoiceCheckPositionsFormContext.Provider>
            </FormStep>
          ))}
        </div>
      </Form>
    </Formik>
  )
}

export const InvoiceCheckPositionsFormSteps: FC<InvoiceCheckPositionsFormStepsProps> =
  withErrorBoundary(InvoiceCheckPositionsFormStepsComponent)
