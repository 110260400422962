import './style.scss'

import { Form, useFormikContext } from 'formik'
import React, { FC, useState } from 'react'
import { I18n } from 'react-i18nify'

import { UserPermission } from 'constants/user'

import { Button, BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import { EmptoPhoneUserSelector } from '../../common/EmptoPhoneUserSelector'
import { ProgressButton } from '../../common/ProgressButton'

interface ManageAutoOffersModalFormProps {
  isLoading: boolean
  onClose: () => void
}

export const ManageAutoOffersModalForm: FC<ManageAutoOffersModalFormProps> = ({
  isLoading,
  onClose,
}) => {
  const [selectedCompanyIsActive, setSelectedCompanyIsActive] = useState(true)

  const { handleSubmit, isSubmitting, isValid, isValidating, setFieldValue } =
    useFormikContext<any>()

  return (
    <div className='uk-modal-body my-auto-offers-page__manage-modal'>
      <p className='uk-text-meta uk-margin-remove'>
        {I18n.t('myAutoOffersPageTranslations.manageAutoOffers.modal.message')}
      </p>
      <Form>
        <EmptoPhoneUserSelector
          requiredPermissions={[UserPermission.CREATE_PHONE_AUTO_OFFERS]}
          showListForWasteCompanies
          mapToOptionCompany={item => ({
            // eslint-disable-next-line max-len
            label: `${item.name} / ${item.empto_external_number} / ${item.street} / ${item.location} / ${item.central_contact}`,
            value: `${item.id},${item.block_auto_offers}`,
          })}
          onSelectionChangeCompany={company => {
            setFieldValue('phone_user', undefined)
            setFieldValue('company', company.id)
            setFieldValue('block_auto_offers', !company.block_auto_offers)
            setSelectedCompanyIsActive(!company.block_auto_offers)
          }}
        />
        <div className='uk-modal-footer uk-text-right'>
          <span className='uk-margin-right'>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
              onClick={() => onClose()}
            >
              {I18n.t('general.button.cancel')}
            </Button>
          </span>

          <ProgressButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            dataTestId='lock-unlock-auto-offer-submit'
            isDisabled={!isValid || isSubmitting}
            isLoading={isLoading || isValidating}
            onClick={handleSubmit}
          >
            {selectedCompanyIsActive
              ? I18n.t('myAutoOffersPageTranslations.actionButtons.lock')
              : I18n.t('myAutoOffersPageTranslations.actionButtons.unlock')}
          </ProgressButton>
        </div>
      </Form>
    </div>
  )
}
