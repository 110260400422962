import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getCommunities } from 'actions/community'
import { getActiveCommunitiesSelector } from 'selectors/community'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

import { AsyncMultiselectCombobox } from '../AsyncMultiselectCombobox/AsyncMultiselectCombobox'
import { callbackOrType } from '../Input'

interface CommunityControlProps<T extends { [key: string]: any }> {
  dataTestId?: string
  defaultValue?: T | T[]
  getOptionLabel?: (option?: T | T[]) => string
  getOptionValue?: (option?: T | T[]) => string
  name: string
  onBlur: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
  onChange: (field: string, value: any, shouldValidate?: boolean) => void
  showRequiredDot?: boolean
  value?: T | T[]
  error?: string
  isClearable?: boolean
  showCheckmark?: callbackOrType<boolean>
  withCheckmark?: boolean
}

export const CommunityControl: FC<CommunityControlProps<any>> = ({
  defaultValue = [],
  getOptionLabel,
  getOptionValue,
  name,
  onBlur,
  onChange,
  showRequiredDot = false,
  value,
  error = '',
  isClearable = false,
  showCheckmark = false,
  withCheckmark = false,
}) => {
  const dispatch = useDispatch()
  const reduxCommunities = useSelector(getActiveCommunitiesSelector)
  const isLoading = useSelector(createLoadingSelector(['GET_COMMUNITIES']))
  const apiErrors = useSelector(createErrorSelector(['GET_COMMUNITIES']))

  const handleChange = inputValue => {
    onChange(name, inputValue)
  }
  const handleBlur = () => {
    onBlur(name, true)
  }

  const loadOptionsFromApi = (inputValue?: string) => {
    dispatch(getCommunities({ name: inputValue }))
  }

  const [communities, setCommunities] = useState<Community[]>([])

  useEffect(() => {
    if (!isLoading) {
      setCommunities(reduxCommunities)
    }
  }, [isLoading, reduxCommunities])

  const placeholder = I18n.t(
    'myAutoOffersPageTranslations.form.fields.communityControl.placeholder',
  )
  const noInputMessage = I18n.t(
    'myAutoOffersPageTranslations.form.fields.communityControl.noInputMessage',
  )
  const noOptionsMessage = I18n.t(
    'myAutoOffersPageTranslations.form.fields.communityControl.noOptionsMessage',
  )
  const loadingMessage = I18n.t(
    'myAutoOffersPageTranslations.form.fields.communityControl.loadingMessage',
  )

  return (
    <AsyncMultiselectCombobox
      dataTestId='community-select'
      defaultValue={defaultValue}
      errors={apiErrors}
      fieldError={error}
      handleBlur={handleBlur}
      handleChange={handleChange}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      isLoading={isLoading}
      label={placeholder}
      loadingMessage={loadingMessage}
      loadOptions={loadOptionsFromApi}
      noInputMessage={noInputMessage}
      noOptionsMessage={noOptionsMessage}
      options={communities}
      placeholder={placeholder}
      showRequiredDot={showRequiredDot}
      value={value}
      isClearable={isClearable}
      showCheckmark={showCheckmark}
      withCheckmark={withCheckmark}
    />
  )
}
