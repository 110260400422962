import classNames from 'classnames'
import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import React, { Component } from 'react'
import Spinner from 'react-spinkit'
import ReactTable from 'react-table'

import PaginatedContent from '../PaginatedContent'

export class PaginatedTable extends Component {
  static propTypes = {
    table: shape({
      data: arrayOf(shape({})),
      columns: arrayOf(shape({})),
      onFetchData: func,
    }),
    pageSize: number,
    tableClassName: string,
    serverSidePagination: bool,
    handleShowPreviousPage: func,
    handleShowNextPage: func,
    pages: number,
    page: number,
    loading: bool,
  }

  static defaultProps = {
    table: {
      data: [],
      columns: [],
      onFetchData: () => undefined, // Request new data when things change
    },
    pageSize: 10,
    tableClassName: '',
    serverSidePagination: false,
    handleShowPreviousPage: null,
    handleShowNextPage: null,
    page: null,
    pages: null,
    loading: false,
  }

  state = {
    page: 1,
    pages: 0,
  }

  /**
   * @description Component “lifecycle method” componentDidMount
   */
  componentDidMount() {
    this.updatePages(this.props)
  }

  /**
   * @description Component “lifecycle method” componentDidUpdate
   */
  componentDidUpdate(prevProps) {
    if (
      this.props.serverSidePagination !== prevProps.serverSidePagination ||
      this.props.table.data.length !== prevProps.table.data.length ||
      this.props.pageSize !== prevProps.pageSize
    ) {
      this.updatePages(this.props)
    }
  }

  /**
   * @description Show next page
   */
  handleShowNextPage = () => {
    const page = Math.min(this.state.pages, this.state.page + 1)
    this.setState({ page })
    this.tableRef.setState({ page: page - 1 })
  }

  /**
   * @description Show previous page
   */
  handleShowPreviousPage = () => {
    const page = Math.max(1, this.state.page - 1)
    this.setState({ page })
    this.tableRef.setState({ page: page - 1 })
  }

  /**
   * @description Update the page count
   */
  updatePages(props) {
    if (!this.props.serverSidePagination) {
      this.setState({
        pages: Math.ceil(props.table.data.length / props.pageSize),
      })
    }
  }

  /**
   * @description Render custom loading component
   * https://github.com/react-tools/react-table/issues/698
   */
  renderLoadingComponent = ({ className, loading, loadingText, ...rest }) => (
    <div
      className={classNames('-loading', { '-active': loading }, className)}
      {...rest}
    >
      <div className='-loading-inner'>
        <div className='uk-flex uk-flex-center'>
          {loadingText}
          <Spinner name='circle' />
        </div>
      </div>
    </div>
  )

  /**
   * @description Render
   */
  render() {
    return (
      <div className='paginated-table uk-width-1-1'>
        <PaginatedContent
          page={
            this.props.serverSidePagination ? this.props.page : this.state.page
          }
          pages={
            this.props.serverSidePagination
              ? this.props.pages
              : this.state.pages
          }
          onPreviousPageClick={
            this.props.handleShowPreviousPage || this.handleShowPreviousPage
          }
          onNextPageClick={
            this.props.handleShowNextPage || this.handleShowNextPage
          }
        >
          <ReactTable
            ref={el => {
              this.tableRef = el
            }}
            {...this.props.table}
            // Forces table not to paginate or sort automatically, so we can handle it server-side
            manual={this.props.serverSidePagination}
            minRows={0}
            loading={this.props.loading}
            LoadingComponent={this.renderLoadingComponent}
            defaultPageSize={this.props.pageSize}
            className={classNames(
              'table -highlight',
              this.props.tableClassName,
            )}
            showPagination={false}
            showPageSizeOptions={false}
          />
        </PaginatedContent>
      </div>
    )
  }
}
