import { passwordResetFormTranslation } from './components/PasswordResetConfirm/translations'
import { passwordResetDoneTranslation } from './components/PasswordResetDone/translations'

export const forgetPasswordTranslations = {
  email: {
    label: 'E-Mail',
    placeholder: 'E-Mail',
    required: 'Bitte geben Sie Ihre E-Mail Adresse ein.',
    invalid: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
  },
  passwordResetForm: passwordResetFormTranslation,
  passwordResetDone: passwordResetDoneTranslation,
  submit: 'Passwort zurücksetzen',
  title: 'Passwort vergessen',
}

export default {}
