import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import { bool, func, number, oneOfType, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { getOrientationClass } from 'helper/attachments'

import {
  FILE_TYPE_CSV,
  FILE_TYPE_IMAGE,
  FILE_TYPE_PDF,
} from '../fileUpload/constants'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_DIMENSIONS,
  BUTTON_SHAPE,
} from '../Button'
import Icon, { ICON_NAME } from '../Fontello'
import { Modal, MODAL_SIZE } from '../Modal'
import ModalHeader from '../ModalHeader'
import { Textarea } from '../Textarea'
import { Tooltip, TOOLTIP_TRIGGER } from '../Tooltip'

/**
 * @description This component renders and controls a FilePreview component.
 * @function
 * @param {Object} props the component props
 */
export class FilePreview extends Component {
  static propTypes = forbidExtraProps({
    allowComment: bool,
    alt: string.isRequired,
    file: shape({
      name: string,
      type: string,
      text: oneOfType([string, func]),
      file: string,
    }),
    modalTitle: string.isRequired,
    onAddComment: func,
    onDeleteImageClick: func,
    onGetComment: func,
    orientation: number,
    showMultiple: bool,
    src: string.isRequired,
    showJustFileName: bool,
  })

  static defaultProps = {
    allowComment: false,
    file: null,
    onAddComment: null,
    onDeleteImageClick: null,
    onGetComment: () => '',
    orientation: 0,
    showMultiple: false,
    showJustFileName: false,
  }

  state = {
    isModalOpen: false,
    textareaValue: '',
    selectedFileType: null,
  }

  componentDidMount() {
    if (this.props.file) {
      if (this.props.file.type) {
        this.setState({ selectedFileType: this.props.file.type.split('/')[0] })
      } else {
        if (this.props.file.file.includes('pdf'))
          this.setState({ selectedFileType: FILE_TYPE_PDF })
        if (this.props.file.file.includes('bmp'))
          this.setState({ selectedFileType: FILE_TYPE_IMAGE })
        if (this.props.file.file.includes('flif'))
          this.setState({ selectedFileType: FILE_TYPE_IMAGE })
        if (this.props.file.file.includes('jpeg'))
          this.setState({ selectedFileType: FILE_TYPE_IMAGE })
        if (this.props.file.file.includes('jpg'))
          this.setState({ selectedFileType: FILE_TYPE_IMAGE })
        if (this.props.file.file.includes('png'))
          this.setState({ selectedFileType: FILE_TYPE_IMAGE })
        if (this.props.file.file.includes('svg+xml'))
          this.setState({ selectedFileType: FILE_TYPE_IMAGE })
        if (this.props.file.file.includes('webp'))
          this.setState({ selectedFileType: FILE_TYPE_IMAGE })
      }
    }
  }

  /**
   * @function
   * @description Method to close modal
   */
  closeModal = () => {
    const { allowComment, file, onAddComment } = this.props

    this.setState({
      isModalOpen: false,
    })

    if (allowComment)
      onAddComment({ fileName: file.name, text: this.state.textareaValue })
  }

  /**
   * @function
   * @description Method to change text in the Textarea
   */
  handleChangeText = ({ target: { value } }) => {
    this.setState({ textareaValue: value })
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    const {
      alt,
      modalTitle,
      onDeleteImageClick,
      onGetComment,
      src,
      file,
      orientation,
      showMultiple,
      showJustFileName,
    } = this.props

    const { isModalOpen, textareaValue, selectedFileType } = this.state

    const idModalTitle = uniqueId()

    const fileIsPDF =
      file && selectedFileType && selectedFileType !== FILE_TYPE_IMAGE

    return (
      <div
        className={classNames(
          'file-preview',
          { 'file-preview--just-file-name': showJustFileName },
          { 'file-preview--multiple': showMultiple },
        )}
      >
        {file && file.name && showJustFileName && <>{file.name}</>}
        {!showJustFileName && (
          <>
            {src === '' ? (
              <div className='file-preview__no-image'>
                <Translate value='filePreview.noImage' />
              </div>
            ) : (
              <>
                {file &&
                selectedFileType &&
                selectedFileType === FILE_TYPE_CSV ? (
                  <div className='file-preview__text-document-icon'>
                    <Icon name={ICON_NAME.DOCUMENT} />
                  </div>
                ) : (
                  <button
                    className={classNames(
                      'file-preview__toggle-modal-button',
                      {
                        'file-preview__toggle-modal-button--multiple':
                          showMultiple,
                      },
                      { 'file-preview__toggle-modal-button--pdf': fileIsPDF },
                    )}
                    onClick={() =>
                      this.setState({
                        isModalOpen: true,
                        textareaValue: showMultiple ? onGetComment(file) : '',
                      })
                    }
                    type='button'
                  >
                    {fileIsPDF ? (
                      <Icon className='document' name={ICON_NAME.FILE_PDF} />
                    ) : (
                      <img
                        alt={alt}
                        className={classNames(
                          'file-preview__image',
                          {
                            [`file-preview__image--${getOrientationClass(
                              orientation,
                            )}`]: !!getOrientationClass(orientation),
                          }, // eslint-disable-line
                          { 'file-preview__image--multiple': showMultiple },
                        )}
                        src={src}
                      />
                    )}

                    {file &&
                      onGetComment(file) !== '' &&
                      onGetComment(file) !== undefined && (
                        <div className='file-preview__message-icon'>
                          <Icon name={ICON_NAME.MESSAGE} />
                        </div>
                      )}
                  </button>
                )}
              </>
            )}
          </>
        )}

        {onDeleteImageClick !== null && src !== '' && (
          <div
            className={classNames(
              'file-preview__delete-file-button',
              { 'file-preview__delete-file-button--multiple': showMultiple },
              {
                'file-preview__delete-file-button--just-file-name':
                  showJustFileName,
              },
            )}
          >
            <Tooltip
              distance={35}
              html={
                <div style={{ maxWidth: '200px' }}>
                  <Translate value='filePreview.deleteImage' />
                </div>
              }
              trigger={TOOLTIP_TRIGGER.MOUSEENTER}
              tooltipId='deleteImage'
            >
              <Button
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                dimensions={
                  showMultiple
                    ? BUTTON_DIMENSIONS.SMALL
                    : BUTTON_DIMENSIONS.FULL_SIZE
                }
                noPadding
                onClick={() => onDeleteImageClick(file)}
                shape={BUTTON_SHAPE.CIRCULAR}
              >
                <Icon name={ICON_NAME.CROSS} />
              </Button>
            </Tooltip>
          </div>
        )}

        <Modal
          ariaDescribedBy={idModalTitle}
          isOpen={isModalOpen}
          onClose={this.closeModal}
          size={showMultiple ? MODAL_SIZE.DEFAULT : MODAL_SIZE.LARGE}
        >
          <ModalHeader
            onClose={this.closeModal}
            title={modalTitle}
            titleId={idModalTitle}
          />

          <div className='file-preview__modal-content'>
            <div className='preview-document'>
              {file &&
                selectedFileType &&
                selectedFileType === FILE_TYPE_PDF && (
                  <div className='document'>
                    <Icon name='file-pdf' />
                  </div>
                )}
              {file &&
                selectedFileType &&
                selectedFileType === FILE_TYPE_IMAGE && (
                  <img
                    alt={alt}
                    src={src}
                    className={classNames(
                      'file-preview__modal-image',
                      {
                        [`file-preview__image--${getOrientationClass(
                          orientation,
                        )}`]: !!getOrientationClass(orientation),
                      }, // eslint-disable-line
                    )}
                  />
                )}

              {file &&
                selectedFileType &&
                selectedFileType === FILE_TYPE_PDF && (
                  <a
                    href={src}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='uk-button uk-button-secondary uk-margin-top document-button'
                  >
                    <Translate value='fileUpload.openFileButtonTitle' />
                  </a>
                )}
            </div>
            {this.props.allowComment && (
              <Textarea
                maxLength={200}
                name='file-comment'
                onChange={this.handleChangeText}
                placeholder={I18n.t(
                  'fileUpload.fileCommentForm.placeholder.text',
                )}
                showRemainingCharacters
                value={textareaValue}
              />
            )}
            {!this.props.allowComment &&
              file &&
              file.text &&
              typeof file.text === 'string' && <p>{file.text}</p>}
          </div>
          <div className='uk-modal-footer uk-text-right'>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              onClick={this.closeModal}
            >
              <Translate value='general.button.submit' />
            </Button>
          </div>
        </Modal>
      </div>
    )
  }
}

export default FilePreview
