import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getContainerForFilter } from 'actions/container'
import { AsyncMultiselectCombobox } from 'components/common/AsyncMultiselectCombobox/AsyncMultiselectCombobox'
import { Option } from 'components/common/StaticCombobox/StaticCombobox'
import { COMPANY_ROLE } from 'components/company/constants'
import { MyAutoOfferListFilters } from 'components/offer/MyAutoOffersPage/MyAutoOffersPage'
import { getContainerForFilterSelector } from 'selectors/container'
import { createLoadingSelector } from 'selectors/loading'

import { CommonFilterProps } from '../../types'

interface ContainerSearchFilterProps extends Partial<CommonFilterProps> {
  action?: (page: number | null, currentFilters: MyAutoOfferListFilters) => any
  currentFilters: MyAutoOfferListFilters | Record<string, unknown>
  label: string
  minCharacters?: number
  name?: string
  noResultsText: string
  placeholder: string
  resultType?: COMPANY_ROLE
  setCurrentFilterValues: (currentFilters: any) => void
  mapToOption: (
    fraction: FractionFilterResponse,
    property: string,
    value: string,
  ) => Option
  isClearable?: boolean
}

export const ContainerSearchFilter: FC<ContainerSearchFilterProps> = ({
  action,
  currentFilters,
  label,
  minCharacters = 3,
  name = 'container',
  noResultsText,
  placeholder,
  setCurrentFilterValues,
  mapToOption,
  isClearable = false,
}) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(
    createLoadingSelector(['GET_CONTAINER_FOR_FILTER']),
  )
  const reduxContainers = useSelector(getContainerForFilterSelector)
  const [containers, setContainers] = useState<ContainerFilterResponse[]>([])

  useEffect(() => {
    if (!isLoading) {
      setContainers(reduxContainers)
    }
  }, [isLoading, reduxContainers])

  const loadOptionsFromApi = (inputValue?: string) => {
    if (inputValue && inputValue.length >= minCharacters) {
      dispatch(
        getContainerForFilter({
          name: inputValue,
        }),
      )
    }
  }

  const handleSelectionChange = value => {
    const newFilters = { ...currentFilters, page: 1, id: value }
    setCurrentFilterValues(newFilters)
    if (action) {
      dispatch(action(null, newFilters as MyAutoOfferListFilters))
    }
  }

  // prepend filter option for all containers
  useEffect(() => {
    containers.unshift({
      id: 0,
      display_name: '',
      display_name_frontend: I18n.t('general.placeholder.all'),
      name: '',
    })
  })

  return (
    <AsyncMultiselectCombobox
      getOptionLabel={container =>
        mapToOption(
          container as ContainerFilterResponse,
          'display_name_frontend',
          'id',
        ).label
      }
      getOptionValue={container =>
        `${(container as ContainerFilterResponse).id}`
      }
      handleBlur={() => undefined}
      handleChange={container => {
        handleSelectionChange(container.id !== 0 ? container.id : '')
      }}
      isLoading={isLoading}
      label={label}
      loadingMessage={''}
      loadOptions={loadOptionsFromApi}
      multiSelect={false}
      name={name}
      noInputMessage={placeholder}
      noOptionsMessage={noResultsText}
      options={containers}
      placeholder={placeholder}
      isClearable={isClearable}
    />
  )
}
