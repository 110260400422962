import './style.scss'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { DropDownInput } from 'components/common/DropDownInput'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { Textarea } from 'components/common/Textarea'
import {
  OFFER_REVIEW_STATUS,
  OFFER_REVIEW_STATES,
} from 'components/maklerpremium/OfferReviewPage/constants'
import { getName } from 'helper/translations'
import { OfferReviewFormValues } from 'components/maklerpremium/OfferReviewPage'

import { DetailsSection } from '../../DetailsSection'

interface OfferReviewDetailProps {
  offer: OfferReview
  isReview?: boolean
}

export const OfferReviewDetail: FC<OfferReviewDetailProps> = ({
  offer,
  isReview = false,
}) => {
  const { errors, handleBlur, handleChange, submitCount, touched, values } =
    useFormikContext<OfferReviewFormValues>()
  const renderCriteria = isReview ? values : offer
  const InternalNote = (
    <ReadOnlyField
      editable={isReview}
      label={I18n.t('OfferReviewTranslations.offerReview.internalNote.label')}
      value={offer.internal_note}
    >
      <Textarea
        label={I18n.t('OfferReviewTranslations.offerReview.internalNote.label')}
        maxLength={500}
        name='internal_note'
        onChange={handleChange}
        placeholder={I18n.t(
          'OfferReviewTranslations.offerReview.internalNote.placeholder',
        )}
        showRemainingCharacters
        value={values.internal_note}
      />
    </ReadOnlyField>
  )

  return (
    <DetailsSection
      title={
        isReview
          ? I18n.t('OfferReviewTranslations.offerReview.sectionTitle.review')
          : I18n.t('OfferReviewTranslations.offerReview.sectionTitle.details')
      }
    >
      <div className='invoice_check-review'>
        <div
          className={classNames({
            'order-claim-review__review-group': !isReview,
          })}
        >
          {/* DATE OF REVIEW (DAY.MONTH.YEAR) */}
          {!isReview && (
            <ReadOnlyField
              label={I18n.t('OfferReviewTranslations.offerReview.reviewedAt')}
              value={moment(offer.reviewed_at).format('L')}
            />
          )}

          {/* INTERNAL NOTE (position in form must be before status) */}
          {isReview && InternalNote}

          {/* STATUS */}
          <ReadOnlyField
            editable={isReview}
            label={I18n.t('OfferReviewTranslations.offerReview.status')}
            value={
              !isReview && offer.review_status
                ? I18n.t(getName(offer.review_status, OFFER_REVIEW_STATES))
                : undefined
            }
          >
            <DropDownInput
              error={
                submitCount > 0 && touched.review_status
                  ? (errors.review_status as string)
                  : ''
              }
              dataTestId='status-select'
              choices={OFFER_REVIEW_STATES.map(item => ({
                optionValue: item.id,
                optionLabel: I18n.t(item.name),
              }))}
              label={I18n.t('OfferReviewTranslations.offerReview.status')}
              name='review_status'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t('OfferReviewTranslations.offerReview.status')}
              value={values.review_status || ''}
              isRequired={
                Number(values.review_status) ===
                OFFER_REVIEW_STATUS.STATUS_PENDING
              }
            />
          </ReadOnlyField>

          {/* NAME OF USER WHO MADE REVIEW */}
          {!isReview && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimReview.reviewedBy',
              )}
              value={offer.reviewed_by_name}
            />
          )}

          {/* TIME OF REVIEW (HOUR:MINUTE) */}
          {!isReview && (
            <ReadOnlyField
              label={I18n.t('OfferReviewTranslations.offerReview.timeOfReview')}
              value={moment(offer.reviewed_at).format('LT')}
            />
          )}
        </div>
        {/* INTERNAL NOTE (Position in details should be after reviewer name) */}
        {!isReview && !!renderCriteria.internal_note && InternalNote}

        <div
          className={classNames({
            'order-claim-review__review-message-group': !isReview,
          })}
        >
          {/* REJECT REASON */}
          {((!isReview && offer.reject_reason) ||
            Number(renderCriteria.review_status) ===
              OFFER_REVIEW_STATUS.STATUS_REJECTED) && (
            <ReadOnlyField
              editable={isReview}
              label={I18n.t(
                'OfferReviewTranslations.offerReview.rejectReason.label.details',
              )}
              value={offer.reject_reason}
            >
              <Textarea
                error={
                  submitCount > 0 && touched.reject_reason
                    ? (errors.reject_reason as string)
                    : ''
                }
                isRequired={!values.reject_reason}
                label={I18n.t(
                  'OfferReviewTranslations.offerReview.rejectReason.label.review',
                )}
                placeholder={I18n.t(
                  'OfferReviewTranslations.offerReview.rejectReason.placeholder',
                )}
                maxLength={500}
                name='reject_reason'
                onChange={handleChange}
                showRemainingCharacters
                value={values.reject_reason}
              />
            </ReadOnlyField>
          )}
        </div>
      </div>
    </DetailsSection>
  )
}
