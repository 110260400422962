import keyMirror from 'keymirror'

const GET_FRACTIONS = keyMirror({
  GET_FRACTIONS_REQUEST: null,
  GET_FRACTIONS_SUCCESS: null,
  GET_FRACTIONS_ERROR: null,
})

const GET_FRACTION_CONTENT = keyMirror({
  GET_FRACTION_CONTENT_REQUEST: null,
  GET_FRACTION_CONTENT_SUCCESS: null,
  GET_FRACTION_CONTENT_ERROR: null,
})

const GET_FRACTIONS_FOR_FILTER = keyMirror({
  GET_FRACTIONS_FOR_FILTER_REQUEST: null,
  GET_FRACTIONS_FOR_FILTER_SUCCESS: null,
  GET_FRACTIONS_FOR_FILTER_ERROR: null,
  RESET_FRACTIONS_FOR_FILTER: null,
})

export default {
  ...GET_FRACTIONS,
  ...GET_FRACTION_CONTENT,
  ...GET_FRACTIONS_FOR_FILTER,
}
