import './style.scss'

import { number, shape } from 'prop-types'
import React from 'react'

import { AttachmentScheme } from 'components/common/fileUpload/schemes'

/**
 * @description this component displays a image
 * @param props
 */
const ImageViewerComponent = props => (
  <div className='image-viewer'>
    <img
      style={{ transform: `rotate(${props.rotate}deg)` }}
      src={props.file.file}
      alt='file-viewer'
    />
  </div>
)

ImageViewerComponent.propTypes = {
  file: shape(AttachmentScheme).isRequired,
  rotate: number,
}

ImageViewerComponent.defaultProps = {
  rotate: 0,
}

export default ImageViewerComponent
