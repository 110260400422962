import { Form, Formik, FormikProps, FormikValues } from 'formik'
import { Location } from 'history'
import React, { FC, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { withRouter } from 'react-router'
import * as yup from 'yup'

import withErrorBoundary from 'helper/withErrorBoundary'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import { checkRequiredPermissions } from 'helper/permissions'
import { get } from 'helper/general'
import { UserPermission } from 'constants/user'

import { DocumentReview } from '../../common/DocumentReview'
import { CertificateReviewDetails } from '../../common/DocumentReview/CertificateReview'
import { CERTIFICATE_STATUS, CERTIFICATE_TYPE } from '../constants'

import { connector } from './connector'

interface CertificateDetailsPageComponentProps extends FormikProps<any> {
  certificate: Certificate
  getCertificate: (certificateId: number) => void
  updateReviewedCertificate: (
    certificate: FormikValues,
    history: History,
    user: User,
  ) => void
  resetCertificateItem: () => void
  history: History
  isLoading: {
    getCertificate?: boolean
    updateCertificate?: boolean
  }
  match: {
    params: {
      certificateId: string
    }
  }
  user: User
  location: Location
}

export const CertificateDetailsPageComponent: FC<
  CertificateDetailsPageComponentProps
> = props => {
  const {
    certificate,
    getCertificate,
    updateReviewedCertificate,
    resetCertificateItem,
    isLoading,
    match,
    user,
    history,
    location,
  } = props
  // trigger reset certificate item on component unmount
  useEffect(() => () => resetCertificateItem(), [resetCertificateItem])

  useEffect(() => {
    const certificateId = Number(match.params.certificateId)
    getCertificate(certificateId)
  }, [getCertificate, match.params.certificateId])

  const getCertificateTypeTranslation = () => {
    switch (certificate.type) {
      case CERTIFICATE_TYPE.TYPE_EFB:
        return I18n.t('certificateDetailsPageTranslations.certificateType.efb')
      case CERTIFICATE_TYPE.TYPE_OTHER:
        return I18n.t(
          'certificateDetailsPageTranslations.certificateType.other',
        )
      case CERTIFICATE_TYPE.TYPE_COMPANY:
        return I18n.t(
          'certificateDetailsPageTranslations.certificateType.company',
        )
      case CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT:
        return I18n.t(
          'certificateDetailsPageTranslations.certificateType.priceAgreement',
        )
      default:
        return ''
    }
  }

  const reviewPageTitle = I18n.t(
    'certificateDetailsPageTranslations.pageTitle.review',
    {
      name: get(() => certificate.company_object.name),
      type: getCertificateTypeTranslation(),
    },
  )
  const regularPageTitle = I18n.t(
    'certificateDetailsPageTranslations.pageTitle.details',
    {
      name: get(() => certificate.company_object.name),
      type: getCertificateTypeTranslation(),
    },
  )

  const isReview =
    (certificate.status === CERTIFICATE_STATUS.STATUS_PENDING ||
      !certificate.reviewed_at) &&
    checkRequiredPermissions(user.permission_codenames, [
      UserPermission.REVIEW_CERTIFICATE,
    ])

  if (
    isLoading.getCertificate ||
    Number(match.params.certificateId) !== certificate.id
  )
    return null

  return (
    <div className='certificate-review-page'>
      <Formik
        initialValues={{
          valid_until: '',
          issued_at: '',
          internal_note: '',
          status: String(CERTIFICATE_STATUS.STATUS_PENDING),
          reject_reason: '',
          disposited_prices: '',
        }}
        validationSchema={() =>
          yup.object().shape({
            valid_until: yup.string().typeError(''),
            issued_at: yup.string().typeError(''),
            internal_note: yup.string().typeError(''),
            status: yup.number().typeError(''),
            reject_reason: yup.string().typeError(''),
            disposited_prices: yup.number().typeError(''),
          })
        }
        validate={values => {
          const errors: {
            valid_until?: string
            issued_at?: string
            internal_note?: string
            status?: string
            reject_reason?: string
            disposited_prices?: string
          } = {}

          if (Number(values.status) !== CERTIFICATE_STATUS.STATUS_REJECTED) {
            if (
              values.valid_until === '' &&
              (certificate.type === CERTIFICATE_TYPE.TYPE_EFB ||
                certificate.type === CERTIFICATE_TYPE.TYPE_OTHER)
            ) {
              errors.valid_until = I18n.t(
                'certificateDetailsPageTranslations.validation.validUntil',
              )
            }

            if (
              values.issued_at === '' &&
              certificate.type !== CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT
            ) {
              errors.issued_at = I18n.t(
                'certificateDetailsPageTranslations.validation.issuedAt',
              )
            }

            if (Number(values.status) === CERTIFICATE_STATUS.STATUS_PENDING) {
              errors.status = I18n.t(
                'certificateDetailsPageTranslations.validation.status',
              )
            }
          }

          if (Number(values.status) === CERTIFICATE_STATUS.STATUS_REJECTED) {
            if (values.reject_reason === '') {
              errors.reject_reason = I18n.t(
                'certificateDetailsPageTranslations.validation.rejectReason',
              )
            }
          }

          if (
            certificate.type === CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT &&
            values.disposited_prices === ''
          ) {
            errors.disposited_prices = I18n.t(
              'certificateDetailsPageTranslations.validation.dispositedPrices',
            )
          }

          return errors
        }}
        onSubmit={values => {
          const valuesToSend: Partial<FormikValues> = values

          if (Number(values.status) !== CERTIFICATE_STATUS.STATUS_REJECTED) {
            // valid until can only be sent if type is EFB or other
            if (
              certificate.type === CERTIFICATE_TYPE.TYPE_COMPANY ||
              certificate.type === CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT
            ) {
              delete valuesToSend.valid_until
            }
          }
          if (Number(values.status) === CERTIFICATE_STATUS.STATUS_REJECTED) {
            delete valuesToSend.issued_at
            delete valuesToSend.valid_until
          }

          if (certificate.type === CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT) {
            delete valuesToSend.issued_at
            delete valuesToSend.valid_until
          }

          updateReviewedCertificate(
            { ...valuesToSend, id: certificate.id, type: certificate.type },
            history,
            user,
          )
        }}
        render={formikProps => (
          <DocumentReview
            location={location}
            breadcrumb={{
              breadcrumbTitle: isReview
                ? I18n.t(
                    'certificateDetailsPageTranslations.breadcrumb.title.review',
                  )
                : I18n.t(
                    'certificateDetailsPageTranslations.breadcrumb.title.details',
                  ),
              prevLinkTitle: I18n.t(
                'certificateDetailsPageTranslations.breadcrumb.prevLinkTitle',
              ),
              prevLinkTo: '/certificate/all',
              teaserText: '',
              teaserTitle: '',
            }}
            pageTitle={I18n.t('pageTitles.certificateDetails')}
            documentTitle={isReview ? reviewPageTitle : regularPageTitle}
            formSubmissionButton={{
              buttonText: I18n.t(
                'certificateDetailsPageTranslations.submitButtonText',
              ),
              action: () => formikProps.handleSubmit(),
              isLoading:
                formikProps.isSubmitting && isLoading.updateCertificate,
              isDisabled:
                (formikProps.submitCount > 0 && !formikProps.isValid) ||
                (formikProps.isSubmitting && isLoading.updateCertificate),
            }}
            showFormSubmitButton={isReview}
            showDocumentDetails
            showCompanyDetails
            reviewDocument={certificate}
          >
            <Form
              className='uk-panel certificate-review-form'
              data-testid='certificate-review-form'
              noValidate // Disable browser validation
            >
              <CertificateReviewDetails
                certificate={certificate}
                isReview={isReview}
                formikProps={formikProps}
              />
            </Form>
          </DocumentReview>
        )}
      />
    </div>
  )
}

export const CertificateDetailsPage = connector(
  withRouter(
    withErrorBoundary(withApiErrorHandling(CertificateDetailsPageComponent)),
  ) as any,
)
