import './style.scss'

import { isString, useFormikContext } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { getIndexes } from 'components/common/DetailsPage/helper'
import { DropDownInput } from 'components/common/DropDownInput'
import { InputDecimal } from 'components/common/InputDecimal'
import { RadioButton } from 'components/common/RadioButton'
import { ReadOnlyField } from 'components/common/ReadOnly'
import {
  INDEX_MONTHS_CHOICES,
  PARTNER_PRICE_MODEL_CHOICES,
} from 'constants/app'
import { BREAKPOINT } from 'constants/design'
import { getFractionsSelector } from 'selectors/fraction'
import { getIndexesSelector } from 'selectors/indexes'

import { PRICE_MODELS } from '../../constants'
import { PriceDetailsValues } from '../constants'

interface PriceInputsProps {
  isEditing?: boolean
  showErrors?: boolean
  showRequiredDot?: boolean
}

export const PriceInputs: FC<PriceInputsProps> = ({
  // A variant of PriceDetailFields which contains old logic for displaying price fields.
  // All usages of PriceDetailFields should be replaced by this component
  isEditing = true,
  showErrors = false,
  showRequiredDot = false,
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    submitCount,
    values,
  } = useFormikContext<PriceDetailsValues>()

  const fractionList = useSelector(getFractionsSelector)
  const indexList = useSelector(getIndexesSelector)
  const [chosenSurchargeOrReduction, setChosenSurchargeOrReduction] =
    useState('')
  const [indexListNames, setIndexListNames] = useState<any>([])

  useEffect(() => {
    setIndexListNames(
      getIndexes(
        values.fraction,
        values.fine_fraction,
        fractionList,
        indexList,
      ),
    )
  }, [values.fraction, values.fine_fraction, fractionList, indexList])

  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth < BREAKPOINT.XLARGE - 1,
  )

  useEffect(() => {
    const handleWindowResize = () => {
      setIsSmallScreen(window.innerWidth < BREAKPOINT.XLARGE - 1)
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const showErrorMsg = fieldErr => {
    if (submitCount > 0 && (isSmallScreen || showErrors) && fieldErr)
      return isString(fieldErr)
        ? fieldErr
        : `${I18n.t('priceDetailFieldsTranslations.validation.required')}`
    return ''
  }

  const selectedIndexValue = (id, list) => {
    if (id && list.length > 0) {
      const desiredIndex = list.find(
        theIndex => Number(theIndex.id) === Number(id),
      )
      if (desiredIndex) return desiredIndex.name
    }
    return I18n.t('priceDetailFieldsTranslations.index.noIndex')
  }

  const showTransportPricePiece = [
    PRICE_MODELS.DISPOSAL_SINGLE,
    PRICE_MODELS.COMPENSATION_SINGLE,
  ].includes(String(values.price_model))
  const showDisposalCostTon =
    values.price_model === PRICE_MODELS.DISPOSAL_SINGLE
  const showDisposalCostContainer =
    values.price_model === PRICE_MODELS.DISPOSAL_CONTAINER
  const showCostContainer =
    values.price_model === PRICE_MODELS.COMPENSATION_CONTAINER
  const showCompensationContainer =
    values.price_model === PRICE_MODELS.COMPENSATION_CONTAINER
  const showCompensationTon =
    values.price_model === PRICE_MODELS.COMPENSATION_SINGLE && !values.index
  const showIndex = values.price_model === PRICE_MODELS.COMPENSATION_SINGLE
  const showIndexMonth =
    values.price_model === PRICE_MODELS.COMPENSATION_SINGLE && !!values.index
  const showSurcharge =
    values.price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
    !!values.index &&
    chosenSurchargeOrReduction === 'SURCHARGE'
  const showReduction =
    values.price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
    !!values.index &&
    chosenSurchargeOrReduction === 'REDUCTION'
  const showHandleCostTon =
    values.price_model === PRICE_MODELS.COMPENSATION_SINGLE

  const showSurchargeOrReduction =
    isEditing &&
    values.price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
    !!values.index

  const isFieldValid = name =>
    values[name] ? `${values[name]}`.length > 0 && !errors[name] : false

  return (
    <div className='price-detail-fields'>
      <ReadOnlyField
        editable={isEditing}
        label={I18n.t('priceDetailFieldsTranslations.priceModel')}
        value={values.price_model}
      >
        <DropDownInput
          choices={PARTNER_PRICE_MODEL_CHOICES.map(item => ({
            optionValue: item.optionValue,
            optionLabel: `${I18n.t(item.optionLabel)}`,
          }))}
          error={showErrorMsg(errors.price_model)}
          label={I18n.t(`priceDetailFieldsTranslations.priceModel`)}
          name='price_model'
          onChange={handleChange}
          value={values.price_model}
          isRequired={showRequiredDot && !values.price_model}
          showCheckmark={isFieldValid}
        />
      </ReadOnlyField>

      <ReadOnlyField
        editable={isEditing}
        label={I18n.t('priceDetailFieldsTranslations.rentPriceContainerMonth')}
        value={values.rent_price_container_month}
      >
        <InputDecimal
          dataTestId='rent-price-container-month-input'
          error={showErrorMsg(errors.rent_price_container_month)}
          label={I18n.t(
            'priceDetailFieldsTranslations.rentPriceContainerMonth',
          )}
          maxValue={999.99}
          name='rent_price_container_month'
          onChange={handleChange}
          placeholder={I18n.t(
            'priceDetailFieldsTranslations.zeroTo999Placeholder',
          )}
          value={values.rent_price_container_month}
          removeBrowserStyling
          isRequired={showRequiredDot && !values.rent_price_container_month}
          showCheckmark={isFieldValid}
        />
      </ReadOnlyField>

      {showTransportPricePiece && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('priceDetailFieldsTranslations.transportPricePiece')}
          value={values.transport_price_piece}
        >
          <InputDecimal
            dataTestId='transport-price-piece-input'
            error={showErrorMsg(errors.transport_price_piece)}
            label={I18n.t('priceDetailFieldsTranslations.transportPricePiece')}
            maxValue={999.99}
            name='transport_price_piece'
            onChange={handleChange}
            placeholder={I18n.t(
              'priceDetailFieldsTranslations.zeroTo999Placeholder',
            )}
            value={values.transport_price_piece}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.transport_price_piece}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}
      {showDisposalCostTon && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('priceDetailFieldsTranslations.disposalCostTon')}
          value={values.disposal_cost_ton}
        >
          <InputDecimal
            dataTestId='disposal-cost-ton-input'
            error={showErrorMsg(errors.disposal_cost_ton)}
            label={I18n.t('priceDetailFieldsTranslations.disposalCostTon')}
            maxValue={4999.99}
            name='disposal_cost_ton'
            onChange={handleChange}
            placeholder={I18n.t(
              'priceDetailFieldsTranslations.zeroTo4999Placeholder',
            )}
            value={values.disposal_cost_ton}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.disposal_cost_ton}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}
      {showDisposalCostContainer && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('priceDetailFieldsTranslations.disposalCostContainer')}
          value={values.disposal_cost_container}
        >
          <InputDecimal
            dataTestId='disposal-cost-container-input'
            error={showErrorMsg(errors.disposal_cost_container)}
            label={I18n.t(
              'priceDetailFieldsTranslations.disposalCostContainer',
            )}
            maxValue={4999.99}
            name='disposal_cost_container'
            onChange={handleChange}
            placeholder={I18n.t(
              'priceDetailFieldsTranslations.zeroTo4999Placeholder',
            )}
            value={values.disposal_cost_container}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.disposal_cost_container}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}
      {showCostContainer && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('priceDetailFieldsTranslations.costContainer')}
          value={values.cost_container}
        >
          <InputDecimal
            dataTestId='cost-container-input'
            error={showErrorMsg(errors.cost_container)}
            label={I18n.t('priceDetailFieldsTranslations.costContainer')}
            maxValue={4999.99}
            name='cost_container'
            onChange={handleChange}
            placeholder={I18n.t(
              'priceDetailFieldsTranslations.zeroTo4999Placeholder',
            )}
            value={values.cost_container}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.cost_container}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}
      {showCompensationContainer && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('priceDetailFieldsTranslations.compensationContainer')}
          value={values.compensation_container}
        >
          <InputDecimal
            dataTestId='compensation-container-input'
            error={showErrorMsg(errors.compensation_container)}
            label={I18n.t(
              'priceDetailFieldsTranslations.compensationContainer',
            )}
            maxValue={1999.99}
            name='compensation_container'
            onChange={handleChange}
            placeholder={I18n.t(
              'priceDetailFieldsTranslations.zeroTo1999Placeholder',
            )}
            value={values.compensation_container}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.compensation_container}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}

      {showIndex && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('priceDetailFieldsTranslations.index.label')}
          value={selectedIndexValue(values.index, indexList)}
        >
          <>
            {indexListNames.length > 0 ? (
              <DropDownInput
                dataTestId='index-input'
                choices={indexListNames.map(index => ({
                  optionValue: index.id,
                  optionLabel: index.name,
                }))}
                isPlaceholderClickable
                label={I18n.t('priceDetailFieldsTranslations.index.label')}
                name='index'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={I18n.t('general.placeholder.empty')}
                value={values.index}
                showCheckmark={isFieldValid}
              />
            ) : (
              <ReadOnlyField
                label={I18n.t('priceDetailFieldsTranslations.index.label')}
                value={I18n.t('priceDetailFieldsTranslations.index.noValues')}
              />
            )}
          </>
        </ReadOnlyField>
      )}

      {showCompensationTon && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t(
            'priceDetailFieldsTranslations.compensation.compensation',
          )}
          value={values.compensation_ton}
        >
          <InputDecimal
            dataTestId='compensation-ton-input'
            error={showErrorMsg(errors.compensation_ton)}
            label={I18n.t(
              'priceDetailFieldsTranslations.compensation.compensation',
            )}
            maxValue={999.99}
            name='compensation_ton'
            onChange={handleChange}
            placeholder={I18n.t(
              'priceDetailFieldsTranslations.zeroTo999Placeholder',
            )}
            value={values.compensation_ton}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.compensation_ton}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}

      {showSurchargeOrReduction && (
        <div className='uk-margin-small-top'>
          <RadioButton
            isChecked={chosenSurchargeOrReduction === 'SURCHARGE'}
            label={I18n.t(
              'priceDetailFieldsTranslations.compensation.surcharge',
            )}
            name='surcharge_or_reduction'
            onChange={(event: React.ChangeEvent<any>) => {
              if (event.target.value === 'on') {
                setChosenSurchargeOrReduction('SURCHARGE')
                setFieldValue('reduction', undefined)
              }
            }}
          />
          <RadioButton
            isChecked={chosenSurchargeOrReduction === 'REDUCTION'}
            label={I18n.t(
              'priceDetailFieldsTranslations.compensation.reduction',
            )}
            name='surcharge_or_reduction'
            onChange={(event: React.ChangeEvent<any>) => {
              if (event.target.value === 'on') {
                setChosenSurchargeOrReduction('REDUCTION')
                setFieldValue('surcharge', undefined)
              }
            }}
          />
        </div>
      )}

      {showSurcharge && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('priceDetailFieldsTranslations.compensation.surcharge')}
          value={values.surcharge}
        >
          <InputDecimal
            dataTestId='surcharge-input'
            error={showErrorMsg(errors.surcharge)}
            label={I18n.t(
              'priceDetailFieldsTranslations.compensation.surcharge',
            )}
            maxValue={4999.99}
            name='surcharge'
            onChange={handleChange}
            placeholder={I18n.t(
              'priceDetailFieldsTranslations.zeroTo4999Placeholder',
            )}
            value={values.surcharge}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.surcharge}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}
      {showReduction && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('priceDetailFieldsTranslations.compensation.reduction')}
          value={values.reduction}
        >
          <InputDecimal
            dataTestId='reduction-input'
            error={showErrorMsg(errors.reduction)}
            label={I18n.t(
              'priceDetailFieldsTranslations.compensation.reduction',
            )}
            maxValue={4999.99}
            name='reduction'
            onChange={handleChange}
            placeholder={I18n.t(
              'priceDetailFieldsTranslations.zeroTo4999Placeholder',
            )}
            value={values.reduction}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.reduction}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}

      {showIndexMonth && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('priceDetailFieldsTranslations.indexMonth')}
          value={values.index_month}
        >
          <DropDownInput
            dataTestId='index-month-input'
            error={showErrorMsg(errors.index_month)}
            label={I18n.t('priceDetailFieldsTranslations.indexMonth')}
            name='index_month'
            onChange={handleChange}
            placeholder={I18n.t('general.placeholder.empty')}
            onBlur={handleBlur}
            value={values.index_month}
            isRequired={showRequiredDot && !values.index_month}
            showCheckmark={isFieldValid}
            choices={INDEX_MONTHS_CHOICES.map(item => ({
              optionValue: item.optionValue,
              optionLabel: `${I18n.t(item.optionLabel)}`,
            }))}
            isPlaceholderClickable
          />
        </ReadOnlyField>
      )}
      {showHandleCostTon && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('priceDetailFieldsTranslations.handleCostTon')}
          value={values.handle_cost_ton}
        >
          <InputDecimal
            dataTestId='handle-cost-ton-input'
            error={showErrorMsg(errors.handle_cost_ton)}
            label={I18n.t('priceDetailFieldsTranslations.handleCostTon')}
            maxValue={999.99}
            name='handle_cost_ton'
            onChange={handleChange}
            placeholder={I18n.t(
              'priceDetailFieldsTranslations.zeroTo999Placeholder',
            )}
            value={values.handle_cost_ton}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.handle_cost_ton}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}
    </div>
  )
}
