import ActionTypes from '../actiontypes/invoice'
import { replaceObjectInArray } from '../helper/general'

export const initialState = {
  itemList: [], // list of all invoices that admin can see
  pagination: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
}

/**
 * @description A reducer for invoices.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const invoiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_INVOICES_SUCCESS:
      return {
        ...state,
        itemList: action.payload.data,
        pagination: { loaded: true, ...action.payload.pagination },
      }
    case ActionTypes.GET_INVOICE_SUCCESS:
      return {
        ...state,
        item: action.payload,
      }
    case ActionTypes.UPDATE_INVOICE_SUCCESS:
      // Replace updated document in itemList
      // eslint-disable-next-line no-case-declarations
      const itemList = replaceObjectInArray(action.payload, state.itemList)

      // Find index of replaced document and insert its cancellation_document (if existent) after the updated document
      // eslint-disable-next-line no-case-declarations
      const indexOfUpdatedDocument = itemList.findIndex(
        doc => doc.id === action.payload.id,
      )
      if (action.payload.cancelled_document_object) {
        itemList.splice(
          indexOfUpdatedDocument + 1,
          0,
          action.payload.cancelled_document_object,
        )
      }

      return {
        ...state,
        itemList: itemList,
      }
    case ActionTypes.UPDATE_EPD_CANCELLED_DOCUMENT_SUCCESS:
      return {
        ...state,
        itemList: state.itemList.map(_object =>
          _object.document_no === action.payload.document_no
            ? action.payload
            : _object,
        ),
      }
    case ActionTypes.EXPORT_BATCH_FILE_SUCCESS:
      return {
        ...state,
      }
    default:
      return state
  }
}

export default invoiceReducer
