import { PriceManagementPage } from '../components/dsz/priceManagementPage/PriceManagementPage'
import { UserPermission } from '../constants/user'

export const dsztRoute = [
  {
    path: '/dsz/prices',
    component: PriceManagementPage,
    requiredPermissions: [UserPermission.VIEW_DSZFRACTIONPRICE],
    exact: true,
    showErrorPage: true,
  },
]
