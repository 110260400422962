import ActionTypes from '../actiontypes/offer'
import { TABLE_PAGE_SIZE_10 } from '../constants/app'

/**
 * @function
 * @name createOffer
 * @description An action method to create offer.
 * @return {Object}
 */
export const createOffer = (offer, history) => ({
  type: ActionTypes.CREATE_OFFER_REQUEST,
  payload: {
    offer,
    history,
  },
})

/**
 * @function
 * @name getOffers
 * @description Loads a list of offers.
 * @param filters {Record | undefined}
 * @param expand {string[] | undefined}
 * @return {Object}
 */
export const getOffers = (filters, expand = []) => ({
  type: ActionTypes.GET_OFFERS_REQUEST,
  payload: { filters, expand },
})

/**
 * @function
 * @name getMyOffers
 * @description Loads a list of offers that were created by my company.
 * @return {Object}
 */
export const getMyOffers = (page, filters, pageSize = TABLE_PAGE_SIZE_10) => ({
  type: ActionTypes.GET_MY_OFFERS_REQUEST,
  payload: {
    page,
    filters,
    pageSize,
  },
})

/**
 * @function
 * @name getOffer
 * @description Loads an offer by id if it is created by my company.
 * @return {Object}
 */
export const getOffer = (id, expand = []) => ({
  type: ActionTypes.GET_OFFER_REQUEST,
  payload: { id, expand },
})

/**
 * @function
 * @name cancelOffer
 * @description Cancels an offer by id.
 * @return {Object}
 */
export const cancelOffer = (offer, history) => ({
  type: ActionTypes.CANCEL_OFFER_REQUEST,
  payload: { offer, history },
})

/**
 * @function
 * @name cancelOffer
 * @description Resends an offer by id.
 * @return {Object}
 */
export const resendOffer = (offer, history) => ({
  type: ActionTypes.RESEND_OFFER_REQUEST,
  payload: { offer, history },
})

/**
 * @function
 * @name exportMyOffers
 * @return {Object}
 */
export const exportMyOffers = filters => ({
  type: ActionTypes.EXPORT_MY_OFFERS_REQUEST,
  payload: {
    filters,
  },
})

/**
 * @function
 * @name getAcceptedOffers
 * @description Loads a list of accepted offers.
 * @return {Object}
 */
export const getAcceptedOffers = (page, filters, sortedBy) => ({
  type: ActionTypes.GET_ACCEPTED_OFFERS_REQUEST,
  payload: {
    page,
    filters,
    sortedBy,
  },
})

/**
 * @function
 * @name exportAcceptedOffers
 * @return {Object}
 */
export const exportAcceptedOffers = filters => ({
  type: ActionTypes.EXPORT_ACCEPTED_OFFERS_REQUEST,
  payload: {
    filters,
  },
})

/**
 * @function
 * @name resetOffer
 * @return {Object}
 */
export const resetOffer = () => ({
  type: ActionTypes.GET_OFFER_RESET,
})

/**
 * @function
 * @name resetOffer
 * @return {Object}
 */
export const resetAcceptedOffers = () => ({
  type: ActionTypes.GET_ACCEPTED_OFFERS_RESET,
})

/**
 * @param offerId
 * @param data
 * @returns {{payload: {data, offer_id}, type: undefined}}
 */
export const postponeIntervalOffer = (offerId, data) => ({
  type: ActionTypes.POSTPONE_INTERVAL_OFFER_REQUEST,
  payload: {
    id: offerId,
    ...data,
  },
})

/**
 * @param offerId
 * @param data
 * @returns {{payload: {data, offer_id}, type: null}}
 */
export const changeIntervalOffer = (offerId, data) => ({
  type: ActionTypes.CHANGE_INTERVAL_OFFER_REQUEST,
  payload: {
    id: offerId,
    ...data,
  },
})

/**
 * @name changeDisposerOffer
 * @description Changes a combined offer
 */
export const changeDisposerOffer = (
  offerId,
  autoOfferId,
  newStartDate,
  newEndDate,
) => ({
  type: ActionTypes.CHANGE_DISPOSER_OFFER_REQUEST,
  payload: {
    id: offerId,
    auto_offer_id: autoOfferId,
    new_start_date: newStartDate,
    new_end_date: newEndDate,
  },
})

/**
 * @function
 * @name changeAgreementPeriodForStandingOffers
 * @returns {Object}
 */
export const changeAgreementPeriodForStandingOffers = (
  offerId,
  serviceStartDate,
  serviceEndDate,
  offerIsRecurring,
) => ({
  type: ActionTypes.CHANGE_AGREEMENT_PERIOD_FOR_STANDING_OFFERS_REQUEST,
  payload: {
    id: offerId,
    serviceStartDate: serviceStartDate,
    serviceEndDate: serviceEndDate,
    offerIsRecurring: offerIsRecurring,
  },
})

/** @function
 * @name autoChangeDisposer
 * @description Changes a combined offer
 * @param offerId
 * @param offerHash
 * @param userId
 * @param reason
 * @param reasonText
 * @return {Object}
 */
export const autoChangeDisposer = (
  offerId,
  offerHash,
  userId,
  reason,
  reasonText,
) => ({
  type: ActionTypes.AUTO_CHANGE_DISPOSER_REQUEST,
  payload: {
    id: offerId,
    hash: offerHash,
    userId: userId,
    reason: reason,
    reasonText: reasonText,
  },
})

/**
 * @function
 * @name exportRent
 * @return {Object}
 */
export const exportRent = filters => ({
  type: ActionTypes.EXPORT_RENT_REQUEST,
  payload: {
    filters,
  },
})

/**
 * @function
 * @name getOfferReview
 * @return {payload: *}
 * @param id
 * @param expand
 */
export const getOfferReview = (id, expand) => ({
  type: ActionTypes.GET_OFFER_REQUEST,
  payload: {
    id,
    expand,
  },
})

/**
 * @name updateReviewedOffer
 * @description updates the status and review fields of an invoice check position.
 * @return {Object}
 */
export const updateReviewedOffer = (offer, id, history) => ({
  type: ActionTypes.UPDATE_REVIEWED_OFFER_REQUEST,
  payload: {
    data: offer,
    id,
    history,
  },
})
