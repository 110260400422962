export const INVOICE_CHECK_POSITION_STATUS = {
  STATUS_PENDING: 1,
  STATUS_ACCEPTED: 2,
  STATUS_REJECTED: 3,
}

export const INVOICE_CHECK_POSITION_STATES = [
  {
    id: INVOICE_CHECK_POSITION_STATUS.STATUS_PENDING,
    name: 'invoiceCheckPosition.status.pending',
  },
  {
    id: INVOICE_CHECK_POSITION_STATUS.STATUS_ACCEPTED,
    name: 'invoiceCheckPosition.status.accepted',
  },
  {
    id: INVOICE_CHECK_POSITION_STATUS.STATUS_REJECTED,
    name: 'invoiceCheckPosition.status.rejected',
  },
]

export default {
  ...INVOICE_CHECK_POSITION_STATUS,
  ...INVOICE_CHECK_POSITION_STATES,
}
