import { useFormikContext } from 'formik'
import React, { useContext, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'

import { getMaklerPremiumCompanies } from 'actions/company'
import {
  FindCompanyByFilterInput,
  FindCompanyByFilterInputActions,
} from 'components/common/FindByFilterInput/FindCompanyByFilterInput'
import { COMPANY_ROLE } from 'components/company/constants'
import { getFieldError } from 'components/maklerpremium/CommunicationCenterPage/helper'
import { NewEntryValues } from 'components/maklerpremium/CommunicationCenterPage/types'

import { ComCenterContactFormContext } from '../index'

/**
 * @description This component displays the stage 3 part of the operations within the main modal form of the communication center page
 */
export const CategoryOperationsStage3Form = () => {
  const {
    values,
    submitCount,
    setFieldValue,
    errors,
    validateForm,
    handleChange,
  } = useFormikContext<NewEntryValues>()

  const formikContext = {
    values,
    errors,
    submitCount,
    validateForm,
    handleChange,
    setFieldValue,
  }

  const { comCenterContact } = useContext(ComCenterContactFormContext)

  const dispatch = useDispatch()

  const mapCompanyToOption = company => ({
    label: `${company.name}`,
    value: company.id,
  })

  useEffect(() => {
    if (comCenterContact && values.customer) {
      dispatch(
        getMaklerPremiumCompanies(null, {
          id_slug: `${values.customer},${comCenterContact.customer_slug}`,
        }),
      )
    }
  }, [dispatch, comCenterContact, values.customer])

  return (
    <>
      <FindCompanyByFilterInput
        action={FindCompanyByFilterInputActions.MAKLER_PREMIUM_COMPANIES}
        additionalFilters={{
          block_auto_offers: false,
          page_size: 25,
          is_active: true,
        }}
        resultType={COMPANY_ROLE.WASTE_PRODUCER}
        label={I18n.t(
          'communicationCenterPageTranslations.fields.customer.label',
        )}
        mapOptionLabel={mapCompanyToOption}
        name='company_name'
        noOptionsMessage={I18n.t('general.form.noOptions')}
        placeholder={I18n.t(
          'communicationCenterPageTranslations.fields.customer.placeholder',
        )}
        handleBlur={() => undefined}
        handleSelectionChange={value => {
          setFieldValue('customer', `${value}`)
        }}
        value={Number(values.customer)}
        error={getFieldError('customer', formikContext)}
        withCheckmark
      />
    </>
  )
}
