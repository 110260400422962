import ActionTypes from '../actiontypes/emptointernaldownloads'

/**
 * @function
 * @name getEmptoInternalDownloads
 * @description Loads all static internal Download files
 * @return [Object]
 */
export const getEmptoInternalDownloads = () => ({
  type: ActionTypes.GET_EMPTO_INTERNAL_DOWNLOADS_REQUEST,
})
