import { useFormikContext } from 'formik'
import React, { FC, useContext, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'

import { getAddressesWithOrders } from 'actions/address'
import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { FormStepSummaryRow } from 'components/common/FormSteps'
import createMarkup from 'components/common/TooltipIcon/helpers'
import { getSelectedAddress } from 'components/inquiry/CreateInquiryPage/helpers'
import { getAddressesWithOrdersSelector } from 'selectors/address'

import {
  InvoiceCheckPositionsFormContext,
  InvoiceCheckPositionsFormValues,
} from '../InvoiceCheckPositionsFormSteps'

export const CollectionAddressSummary: FC = () => {
  const addressList = useSelector(getAddressesWithOrdersSelector)
  const dispatch = useDispatch()
  const { values } = useFormikContext<InvoiceCheckPositionsFormValues>()

  useEffect(() => {
    if (!addressList || addressList.length < 1) {
      dispatch(getAddressesWithOrders())
    }
  }, [addressList, dispatch])

  const { editStep } = useContext(InvoiceCheckPositionsFormContext)

  const address = getSelectedAddress(
    Number(values.collection_address),
    addressList,
  )

  if (!address) return <Spinner name='circle' />

  const displayName = address.display_name.replace(',', '<br />')

  return (
    <div className='maklerpremium-step-summary'>
      <div className='maklerpremium-step-summary_grid-body'>
        <FormStepSummaryRow
          label={I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.1.fields.company_name.label',
          )}
          value={address.company_name}
        />
        <FormStepSummaryRow
          label={I18n.t('inquiry.address')}
          value={
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={createMarkup(`${displayName}`)}
            />
          }
        />
      </div>
      <div className='sidebar'>
        <ButtonLink
          className='sidebar__edit-link'
          linkColor={BUTTON_LINK_COLOR.PRIMARY}
          onClick={editStep}
        >
          {I18n.t('createInquiryTranslations.form.button.editStep')}
        </ButtonLink>
      </div>
    </div>
  )
}
