export const DSZAddInvoiceFormStepsTranslations = {
  steps: {
    1: {
      title: 'Belegempfänger auswählen',
      fields: {
        // search fields
        search_field: {
          label: 'Suchfeld auswählen',
          placeholder: 'Suchfeld',
        },
        company_name: {
          label: 'Name des Kunden',
          noOptionsMessage: 'Kein Kunde mit diesem Namen gefunden.',
          placeholder: 'Name',
          error: 'Ungültiger oder leerer Name',
        },
        dsz_external_number: {
          label: 'Kundennummer',
          noOptionsMessage: 'Kein Kunde mit dieser Kundennummer gefunden.',
          placeholder: 'Kundennummer',
          error: 'Ungültige oder leere Kundennummer',
        },
        zipcode: {
          label: 'Postleitzahl',
          noOptionsMessage: 'Kein Kunde mit dieser Postleitzahl gefunden.',
          placeholder: 'Postleitzahl',
          error: 'Ungültige oder leere Postleitzahl',
        },
        // readonly fields
        invoice_address: {
          label: 'Rechnungsadresse',
        },
        tax_id: {
          label: 'UST-ID',
        },
      },
    },
    2: {
      title: 'Vertragszuordnung, Belegart und Steuersatz festlegen',
      fields: {
        assignable_to_contract: {
          label: 'Kann dem Beleg ein konkreter Vertrag zugeordnet werden?',
          summary_label: 'Vertragszuordnung',
        },
        assigned_contract: {
          label: 'Vertrag',
          noResultsText: 'Kein Vertrag gefunden',
          error: 'Ungültige oder leere Vertragsnummer ausgewählt',
        },
        assigned_contract_number: {
          label: 'Vertragsnummer',
        },
        assigned_contract_shop: {
          label: 'Shop',
        },
        assigned_contract_created_at: {
          label: 'Erstellungsdatum',
        },
        licensing_year: {
          label: 'Lizenzierungsjahr',
        },
        iban: {
          label:
            'Optional: Bitte tragen Sie hier die IBAN des Gutschriftsempfängers ein',
          placeholder: 'IBAN',
          error: 'IBAN ungültig',
        },
        invoice_or_credit_note: {
          label:
            'Bitte wählen Sie aus, ob Sie eine Rechnung oder eine Gutschrift erstellen möchten.',
          invoice: 'Rechnung',
          credit_note: 'Gutschrift',
          summary_label: 'Belegart',
        },
        assigned_vat: {
          label: 'Bitte wählen Sie den Steuersatz für den Beleg aus.',
          summaryLabel: 'Steuersatz',
        },
      },
    },
    3: {
      title: 'Belegpositionen erfassen',
      fields: {
        service: {
          label: 'Abfallart',
        },
        dszFraction: {
          placeholder: 'Abfallart',
          noInputMessage:
            'Bitte geben Sie die ersten drei Anfangsbuchstaben einer Abfallart ein.',
          noOptionsMessage: 'Keine passenden Abfallarten gefunden.',
          loadingMessage: 'Lade Abfallarten ...',
        },
        unit: {
          kg: 'kg',
        },
        single_net_price: {
          label: 'Nettoeinzelpreis pro Tonne in €',
          placeholder: 'Nettoeinzelpreis in €',
        },
      },
      buttons: {
        addRemovePosition: 'Position',
      },
    },
    4: {
      title: 'Eingaben prüfen',
      description:
        'Bitte überprüfen Sie Ihre Angaben sowie den Gesamtpreis bevor Sie die Belegerstellung abschließen.',
      fields: {},
      buttons: {
        saveInvoice: 'Beleg erstellen',
        cancel: 'Abbrechen',
      },
      modal: {
        title: 'Beleg erstellen?',
        description:
          'Mit der Bestätigung wird der Beleg erstellt und es können keine Änderungen' +
          ' mehr vorgenommen werden.',
        accept: 'Bestätigen',
      },
    },
  },
}
