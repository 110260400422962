import ActionTypes from '../actiontypes/container'

export const initialState = {
  itemList: [],
  content: [],
  filterList: [],
}

/**
 * @description A reducer for fine fraction.
 * @param {Object} state is the current global state from container InitialState.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const containerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CONTAINERS_SUCCESS:
      return {
        ...state,
        itemList: action.payload,
      }
    case ActionTypes.GET_CONTAINER_CONTENT_SUCCESS:
      return {
        ...state,
        content: action.payload,
      }
    case ActionTypes.GET_CONTAINER_FOR_FILTER_SUCCESS:
      return {
        ...state,
        filterList: action.payload,
      }
    default:
      return state
  }
}

export default containerReducer
