import { useField, useFormikContext } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, { FC, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getZipcode, hideZipcodeValidationMessage } from 'actions/zipCode'
import { getZipcodeSelector } from 'selectors/zipCode'

import { Button, BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import { InputText } from '../../common/InputText'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { InformMeModalContent } from '../InformMeModalContent'

interface ZipcodeFormFieldProps {
  withCheckmark?: boolean
  showCheckmark?: boolean
  showRequiredDot?: boolean
}

export const ZipcodeFormField: FC<ZipcodeFormFieldProps> = ({
  withCheckmark = false,
  showCheckmark = false,
  showRequiredDot = true,
}) => {
  const { submitCount, values } = useFormikContext<{ zipcode: number }>()

  const dispatch = useDispatch()

  const [inputProps, metaProps] = useField({
    name: 'zipcode',
    validate: value => {
      let error

      if (values.zipcode !== value) {
        // Pattern to verify validity of zipcode
        const pattern = new RegExp(
          '(^(?!01000|99999)(0[1-9]\\d{3}|[1-9]\\d{4})$)',
          '',
        )

        // Async validation for zipcode, to validate active zip code.
        if (String(value).length > 0) {
          if (pattern.test(String(value)))
            dispatch(getZipcode(value, { is_active: true }))
          else {
            error = I18n.t('addressForm.validation.validZipcode')
          }
        } else {
          error = I18n.t('addressForm.validation.zipcode')
          dispatch(hideZipcodeValidationMessage())
        }
      }

      return error
    },
  })

  const zipcode = useSelector(getZipcodeSelector)

  const [openInformMeAboutZipcodeModal, setOpenInformMeAboutZipcodeModal] =
    useState<boolean>(false)
  const idInformMeZipCodeSupportedModalHeadline = uniqueId()

  return (
    <>
      <InputText
        dataTestId='add-address-form-zipcode-field'
        error={submitCount > 0 && metaProps.touched ? metaProps.error : ''}
        isRequired={showRequiredDot && !metaProps.value}
        label={I18n.t('general.placeholder.zipcode')}
        maxLength={5}
        name='zipcode'
        onlyNumbers
        onBlur={inputProps.onBlur}
        onChange={inputProps.onChange}
        placeholder={I18n.t('general.placeholder.zipcode')}
        showCheckmark={showCheckmark}
        value={metaProps.value ?? ''}
        withCheckmark={withCheckmark}
      />
      {zipcode && zipcode.showValidationMessage && (
        <>
          <span className='form-info address-form__zipcode-info'>
            {I18n.t('addressForm.validation.activeZipcodeWastemanager')}
          </span>
          <div className='uk-margin uk-text-right'>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              dataTestId='add-address-inform-me-button'
              onClick={() => {
                setOpenInformMeAboutZipcodeModal(true)
              }}
            >
              <Translate value='general.button.informMe' />
            </Button>
          </div>
        </>
      )}
      {/* "Inform me when my zipcode is supported" modal */}
      <Modal
        ariaDescribedBy={idInformMeZipCodeSupportedModalHeadline}
        isOpen={openInformMeAboutZipcodeModal}
        onClose={() => setOpenInformMeAboutZipcodeModal(false)}
      >
        <ModalHeader
          onClose={() => setOpenInformMeAboutZipcodeModal(false)}
          title={I18n.t('addressForm.informMeModal.header.title')}
          titleId={idInformMeZipCodeSupportedModalHeadline}
        />

        <InformMeModalContent
          onCancel={() => setOpenInformMeAboutZipcodeModal(false)}
          onSuccess={() => setOpenInformMeAboutZipcodeModal(false)}
          zipcode={metaProps.value}
        />
      </Modal>
    </>
  )
}
