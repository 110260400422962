import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { bindActionCreators } from 'redux'

import * as IndexesActions from '../../actions/indexes'
import { createErrorSelector } from '../../selectors/error'
import {
  getIndexesSelector,
  getIndexValuesItemFormSelector,
  getIndexValuesItemSelector,
  getIndexValuesSelector,
  getNextMonthIndexValuesSelector,
} from '../../selectors/indexes'
import { createLoadingSelector } from '../../selectors/loading'

const exportIndexValuesLoadingSelector = createLoadingSelector([
  'EXPORT_INDEX_VALUES',
])
const indexListLoadingSelector = createLoadingSelector(['GET_INDEX_VALUES'])
const errorSelector = createErrorSelector([
  'GET_INDEXES',
  'GET_INDEX_VALUES',
  'EXPORT_INDEX_VALUES',
])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  nextMonthValues: getNextMonthIndexValuesSelector(state),
  indexChangeForm: getIndexValuesItemFormSelector(state),
  indexChange: getIndexValuesItemSelector(state),
  indexValues: getIndexValuesSelector(state),
  indexList: getIndexesSelector(state),
  isLoading: {
    exportIndexValues: exportIndexValuesLoadingSelector(state),
    indexList: indexListLoadingSelector(state),
  },
  error: errorSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  actions: {
    indexes: bindActionCreators(IndexesActions, dispatch),
    form: bindActionCreators(actions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
