import React from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'

import { WasteTypeList } from '../../common/WasteTypeList'
import { PageHeader } from '../../layout/PageHeader'

const HomepageWasteType = () => (
  <>
    <Helmet>
      <title>{I18n.t('pageTitles.wasteType')}</title>
      <meta name='Description' content={I18n.t('pageDescriptions.wasteType')} />
    </Helmet>

    <div className='homepage-waste-type'>
      <PageHeader title={I18n.t('wasteType.heading')} />
      <WasteTypeList />
    </div>
  </>
)

export default HomepageWasteType
