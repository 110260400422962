import keyMirror from 'keymirror'

const GET_INDEXES = keyMirror({
  GET_INDEXES_REQUEST: null,
  GET_INDEXES_SUCCESS: null,
  GET_INDEXES_ERROR: null,
})

const GET_INDEX_VALUES = keyMirror({
  GET_INDEX_VALUES_REQUEST: null,
  GET_INDEX_VALUES_SUCCESS: null,
  GET_INDEX_VALUES_ERROR: null,
  GET_INDEX_VALUES_RESET: null,
})

const UPDATE_INDEX_VALUES = keyMirror({
  UPDATE_INDEX_VALUES_REQUEST: null,
  UPDATE_INDEX_VALUES_SUCCESS: null,
  UPDATE_INDEX_VALUES_ERROR: null,
})

const CREATE_INDEX_VALUE = keyMirror({
  CREATE_INDEX_VALUE_REQUEST: null,
  CREATE_INDEX_VALUE_SUCCESS: null,
  CREATE_INDEX_VALUE_ERROR: null,
})

const EXPORT_INDEX_VALUES = keyMirror({
  EXPORT_INDEX_VALUES_REQUEST: null,
  EXPORT_INDEX_VALUES_SUCCESS: null,
  EXPORT_INDEX_VALUES_ERROR: null,
})

export default {
  ...GET_INDEXES,
  ...GET_INDEX_VALUES,
  ...UPDATE_INDEX_VALUES,
  ...CREATE_INDEX_VALUE,
  ...EXPORT_INDEX_VALUES,
}
