import { ReactText } from 'react'

export const filterDate: (
  holidays: Holiday[],
  date: any,
  filterHolidays: boolean,
  interval_weekday_first?: ReactText,
  interval_weekday_second?: ReactText,
  filterWeekdays?: boolean,
) => boolean = (
  holidays,
  date,
  filterHolidays,
  interval_weekday_first,
  interval_weekday_second,
  filterWeekdays,
) => {
  let dateFiltered = false
  if (holidays && filterHolidays) {
    dateFiltered = holidays.includes(date.format('DD-MM-YYYY'))
  }

  if (filterWeekdays && !dateFiltered) {
    interval_weekday_first = String(interval_weekday_first)
    interval_weekday_second = String(interval_weekday_second)
    if (interval_weekday_first || interval_weekday_second) {
      // For the check of first and second day, we have to add 1 day to our interval days to make the day from moment
      // and our internal days comparable.
      dateFiltered = !(
        (interval_weekday_first
          ? date.day() === Number(interval_weekday_first) + 1
          : false) ||
        (interval_weekday_second
          ? date.day() === Number(interval_weekday_second) + 1
          : false)
      )
    }
  }

  return !dateFiltered
}
