export const offerPipelineTranslations = {
  columns: {
    offerNumber: 'Angebotsnr.',
    createdAt: 'Datum',
    customer: 'Kunde',
    zipcode: 'PLZ',
    location: 'Ort',
    fraction: 'Abfallart',
    retailPrice: 'Nettobetrag',
    createdBy: 'Erstellt von',
    validUntil: 'Gültig bis',
  },
  headline: 'Angebotspipeline',
  exportModalTitle: 'Angebotspipeline CSV Export',
  exportPostalStatisticsModal: {
    title: 'PLZ CSV-Export',
    buttonText: 'PLZ CSV Export',
  },
  subtitle: {
    description:
      'Hier finden Sie eine Übersicht über sämtliche offene Angebote.',
    totalResults: 'Anzahl aller Angebote:',
    totalResultsPostfix: ' Stück', // Attn: the leading space is intentional
    totalValue: 'Gesamtbetrag:',
  },
  addCustomOfferButton: 'Angebot hochladen +',
  customOfferCreateModal: {
    title: 'Angebot hochladen',
    description:
      'Hier können Sie Ihr manuell erstelltes Angebot in die Angebotspipeline einfügen. ' +
      'Bitte füllen Sie alle Felder korrekt aus und laden das Angebot im Format PDF hoch.',
    form: {
      attachments: {
        label: 'Angebot',
        error: 'Ungültiges oder leeres Dokument',
        typeError: 'Das Dokument ist kein PDF',
      },
      company: {
        error:
          'Bitte geben Sie einen Kunden an, für den das manuelle Angebot gelten soll',
      },
      customOfferNumber: {
        label: 'Angebotsnummer',
        error: 'Bitte geben Sie eine Angebotsnummer an',
      },
      issuedAt: {
        label: 'Angebotsdatum',
        error: 'Bitte geben Sie ein Angebotsdatum an',
      },
      net_price: {
        label: 'Nettobetrag in €',
        error: 'Bitte geben Sie einen Bruttobetrag an',
      },
      runtimeOfCustomOffer: {
        label: 'Gültigkeit des Angebots',
        error: 'Bitte geben Sie eine Gültigkeit des Angebots an',
      },
    },
  },
  customOfferRuntimeModal: {
    title: 'Status des manuellen Angebots',
    accepted: 'Zusage erhalten',
    rejected: 'Absage erhalten',
  },
}
