import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import classNames from 'classnames'
import { node, oneOfType, string } from 'prop-types'
import React from 'react'

/**
 * @description This component renders and controls a header with a description component.
 * @function
 * @param {Object} props the component props
 */
const DescriptionHeader = props => (
  <div className={classNames('description-header')}>
    <div className='description-header__position'>
      <h1 className='waste-type-header__headline'>{props.title}</h1>
      <div className='paragraph paragraph--color-gray-light description-header__bodytext'>
        {props.content}
      </div>
    </div>
    <div className='description-header__image'>{props.children}</div>
  </div>
)

DescriptionHeader.propTypes = forbidExtraProps({
  title: string,
  content: oneOfType([string, node]),
  children: node,
})

DescriptionHeader.defaultProps = {
  title: '',
  content: '',
  children: null,
}

export default DescriptionHeader
