/**
 * @description check if number is smaller then thousand.
 * @param values
 * @return {boolean}
 */
export const smallerThenThousand = ({ floatValue }) => {
  if (!floatValue) return true

  // In case we have negative numbers, we also need to limit the minimum value
  return floatValue < 1000 && floatValue > -1000
}

/**
 * @description check if number is smaller then hundred thousand.
 * @param values
 * @return {boolean}
 */
export const smallerThenHundredThousand = ({ floatValue }) => {
  if (!floatValue) return true
  // this is being used for a weight, so no need for negative numbers
  return floatValue < 100000
}

export default {}
