import './Podium.scss'

import classNames from 'classnames'
import * as React from 'react'
import { I18n } from 'react-i18nify'

import Icon, { ICON_COLOR } from 'components/common/Fontello'
import { Headline, HEADLINE_TAG } from 'components/common/Headline'
import UserImg from 'components/layout/Header/UserDropdown/components/UserInfo/components/UserImg'

export type Rank = {
  user: UserSmall
  amount: string
  count_offers: number
}

interface PodiumProps {
  ranking: Rank[]
  span?: boolean
  last?: boolean
}

export const Podium = ({ ranking, span = true, last = false }: PodiumProps) => (
  <div
    className={classNames(
      'podium',
      'uk-flex',
      'uk-flex-center',
      'uk-flex-wrap',
      'classification-tile',
      'classification-tile--span-rows',
      {
        'classification-tile--span': span,
        'uk-flex-last': last,
      },
    )}
  >
    <Headline
      tag={HEADLINE_TAG.H3}
      className='uk-width-1-1 uk-margin-remove-bottom'
    >
      <Icon name='star' color={ICON_COLOR.BRAND} />
      {I18n.t('dashboard.MP.EPD.stakeholder.podium.title')}
    </Headline>
    <div className='podium_ranks uk-flex uk-flex-around uk-width-1-1 uk-flex-bottom'>
      {ranking.map(rank => {
        const firstName = rank.user.first_name
        const fullName = `${rank.user.first_name} ${rank.user.last_name}`
        return (
          <div className='podium__rank' key={rank.user.first_name}>
            <div className='podium__rank-avatar uk-flex'>
              <UserImg profile_image_object={rank.user.profile_image_object} />
            </div>
            <div className='podium__rank-name'>
              {fullName.length < 20 ? fullName : firstName}
            </div>
            <div className='podium__rank-amount'>
              {new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR',
              }).format(Number(rank.amount))}
            </div>
            <div className='podium__rank-offers'>
              {I18n.t('dashboard.MP.EPD.stakeholder.podium.offers', {
                offersCount: rank.count_offers,
              })}
            </div>
          </div>
        )
      })}
    </div>
  </div>
)
