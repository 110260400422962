import './style.scss'

import React, { useEffect, useMemo, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { updateCompany } from 'actions/company'
import { getMyUsers } from 'actions/user'
import { ReactComponent as HeadsetIcon } from 'assets/svg/detailspage/headset.svg'
import { AsyncMultiselectCombobox } from 'components/common/AsyncMultiselectCombobox/AsyncMultiselectCombobox'
import { getCompanySelector } from 'selectors/company'
import { createLoadingSelector } from 'selectors/loading'
import { getMyCompanyActiveUserListSelector } from 'selectors/user'

export interface AboutUsValues {
  about_us: string
}

/**
 * @description This component displays the about us text of company
 */
export const ResponsibleEmployee = ({ minCharacters = 3 }) => {
  // Redux
  const [myUsers, setMyUsers] = useState<User[]>([])
  const company = useSelector(getCompanySelector)
  const dispatch = useDispatch()
  const isLoading = useSelector(createLoadingSelector(['GET_MY_USERS']))
  const reduxMyUsers = useSelector(getMyCompanyActiveUserListSelector)

  // State
  const [isEditing, setIsEditing] = useState(false)
  const [currentInputValue, setCurrentInputValue] = useState('')

  const responsibleEmployee = company.responsible_employee_object

  const loadOptionsFromApi = (inputValue?: string) => {
    if (inputValue && inputValue.length >= minCharacters) {
      dispatch(
        getMyUsers(null, [], {
          name: inputValue,
          status: 3,
        }),
      )
    }
  }

  const getInitialValue = useMemo(() => {
    return responsibleEmployee
      ? myUsers.find(option => option.id === responsibleEmployee.id)
      : undefined
  }, [responsibleEmployee, myUsers])

  useEffect(() => {
    if (isEditing && responsibleEmployee) {
      dispatch(getMyUsers(null, [], { id: responsibleEmployee.id }))
    }
  }, [isEditing, responsibleEmployee, dispatch])

  useEffect(() => {
    if (!isLoading) {
      setMyUsers(reduxMyUsers)
    }
  }, [isLoading, reduxMyUsers])

  return (
    <div className='uk-flex uk-flex-between uk-flex-middle uk-flex-top gap-10 height-3rem'>
      <div className='uk-flex uk-flex-middle uk-flex-1'>
        <HeadsetIcon className='icon' />
        {isEditing ? (
          <div className='uk-flex-1'>
            <AsyncMultiselectCombobox
              handleBlur={() => undefined}
              handleChange={user => {
                setCurrentInputValue(`${user.id}`)
              }}
              isLoading={isLoading}
              loadOptions={loadOptionsFromApi}
              options={myUsers}
              placeholder={I18n.t(
                'responsibleEmployeeTranslations.placeholder',
              )}
              value={getInitialValue}
              noInputMessage={I18n.t(
                'responsibleEmployeeTranslations.noOptionsMessage',
              )}
              noOptionsMessage={I18n.t(
                'responsibleEmployeeTranslations.noOptionsMessage',
              )}
              loadingMessage={I18n.t(
                'responsibleEmployeeTranslations.loadingMessage',
              )}
              multiSelect={false}
              getOptionLabel={user =>
                `${(user as User).first_name} ${(user as User).last_name}`
              }
              getOptionValue={user => `${(user as User).id}`}
              isClearable
            />
          </div>
        ) : (
          <span>
            {responsibleEmployee
              ? `${responsibleEmployee.first_name} ${responsibleEmployee.last_name}`
              : I18n.t(
                  'responsibleEmployeeTranslations.no_responsible_employee',
                )}
          </span>
        )}
      </div>
      {isEditing ? (
        <Link
          to='#'
          onClick={() => {
            dispatch(
              updateCompany({
                id: company.id,
                /* AsyncSelect resets the value to the string 'undefined' whenever the user
                clicks on the "x"-Icon to reset the value. */
                responsible_employee:
                  currentInputValue === 'undefined' ? '' : currentInputValue,
                status: 3,
                isUpdatingCompanyInfo: true,
              }),
            )
            setIsEditing(false)
          }}
        >
          <Translate value='general.button.save' />
        </Link>
      ) : (
        <Link
          to='#'
          onClick={() => {
            if (responsibleEmployee) {
              setCurrentInputValue(`${responsibleEmployee.id}`)
            }
            setIsEditing(true)
          }}
        >
          <Translate value='general.button.edit' />
        </Link>
      )}
    </div>
  )
}
