import React, { FC, useEffect, useMemo, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { NoContentMessage } from 'components/common/NoContentMessage'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { Option } from 'components/common/StaticCombobox/StaticCombobox'
import {
  UPCOMING_ORDER_STATES,
  UPCOMING_ORDER_TYPE_ACTIONS_CHOICES,
} from 'components/order/constants'
import { getCurrentUserSelector } from 'selectors/user'

import { getCurrentValueObject, resetInputOption } from '../../helpers'
import { CommonFilterProps } from '../../types'
import { FilterReset } from '../FilterReset'
import { cleanUpAndCountFiltersForReset } from '../FilterReset/helpers'

interface CompanyAndStatusFilterProps extends CommonFilterProps {
  action: (page: any, filters: any) => any // sorry, Redux with redux-logics is untypeable
  statusField?: string
  statusTranslation: string
  statusFilterActive: boolean
  companyTranslation: string
  typeTranslation: string
  containerTranslation: string
  companyFilterActive: boolean
  containerFilterActive: boolean
  setCurrentFilterValues: (currentFilters: any) => void
  currentFilterValues: any
  typeFilterActive: boolean
}

export const CompanyAndStatusFilter: FC<CompanyAndStatusFilterProps> = ({
  action,
  companyList,
  containerList,
  handleChange,
  statusField = 'status',
  statusTranslation,
  companyTranslation = I18n.t('filterTranslations.companyAndIdFilter.company'),
  typeTranslation = I18n.t('filterTranslations.companyAndIdFilter.type'),
  containerTranslation = I18n.t(
    'filterTranslations.companyAndIdFilter.container',
  ),
  // The following three booleans would allow this filter to be used somewhere else,
  // if only one filter field was needed.
  // This is meant as a first step towards global filters, instead of custom filters for each new page.
  statusFilterActive = true,
  companyFilterActive = true,
  typeFilterActive = true,
  containerFilterActive = true,
  isLoading,
  length,
  resetForm,
  setCurrentFilterValues,
  currentFilterValues,
  values,
}) => {
  const dispatch = useDispatch()
  const [currentSelectedCompany, setCurrentSelectedCompany] = useState<Option>()
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<Option>()
  const [currentSelectedType, setCurrentSelectedType] = useState<Option>()
  const [currentSelectedContainer, setCurrentSelectedContainer] =
    useState<Option>()
  const [filterWasReset, setFilterWasReset] = useState(false)

  const user = useSelector(getCurrentUserSelector)

  const selectionItems = useMemo(
    () => ({
      companyList: [
        ...resetInputOption,
        ...companyList.map(item => ({
          label: item.name,
          value: `${item.id}`,
        })),
      ],
      agreementStateList: [
        ...resetInputOption,
        ...UPCOMING_ORDER_STATES.map(item => ({
          label: `${I18n.t(item.name)}`,
          value: `${item.id}`,
        })),
      ],
      typeList: [
        ...resetInputOption,
        ...UPCOMING_ORDER_TYPE_ACTIONS_CHOICES.map(item => ({
          label: `${item.name}`,
          value: `${item.id}`,
        })),
      ],
      containerList: [
        ...resetInputOption,
        ...containerList.map(item => ({
          label: item.name,
          value: `${item.name}`,
        })),
      ],
    }),
    [companyList, containerList],
  )

  useEffect(() => {
    setCurrentSelectedCompany(
      getCurrentValueObject(selectionItems.companyList, values.company),
    )
  }, [selectionItems, selectionItems.companyList, values.company])

  useEffect(() => {
    setCurrentSelectedStatus(
      getCurrentValueObject(selectionItems.agreementStateList, values.status),
    )
  }, [selectionItems, selectionItems.agreementStateList, values.status])

  useEffect(() => {
    setCurrentSelectedType(
      getCurrentValueObject(selectionItems.typeList, values.type),
    )
  }, [selectionItems, selectionItems.typeList, values.type])

  useEffect(() => {
    setCurrentSelectedContainer(
      getCurrentValueObject(selectionItems.containerList, values.container),
    )
  }, [selectionItems, selectionItems.containerList, values.container])

  useEffect(() => {
    const requestValues = {
      ...currentFilterValues,
      disposer_company_id: values.company,
      [statusField]: values.status,
      type: values.type,
      container: values.container,
    }
    if (
      filterWasReset ||
      JSON.stringify(currentFilterValues) !== JSON.stringify(requestValues)
    ) {
      setCurrentFilterValues(requestValues)
      dispatch(action(null, requestValues))
      if (filterWasReset) {
        setFilterWasReset(false)
      }
    }
  }, [
    action,
    currentFilterValues,
    dispatch,
    filterWasReset,
    setCurrentFilterValues,
    statusField,
    user.company,
    values,
  ])

  return (
    <>
      <div className='uk-grid uk-child-width-1-5@l uk-child-width-1-2@m'>
        {companyFilterActive && (
          <StaticCombobox
            isLoading={isLoading}
            label={companyTranslation}
            name='company'
            noResultsText={I18n.t('filterTranslations.noInputResults')}
            options={selectionItems.companyList}
            onSelectionChange={e => {
              handleChange(e)
            }}
            placeholder={I18n.t('general.placeholder.all')}
            selectedOption={currentSelectedCompany}
          />
        )}

        {statusFilterActive && (
          <StaticCombobox
            isLoading={isLoading}
            label={statusTranslation}
            name='status'
            noResultsText={I18n.t('filterTranslations.noInputResults')}
            options={selectionItems.agreementStateList}
            onSelectionChange={e => {
              handleChange(e)
            }}
            placeholder={I18n.t('general.placeholder.all')}
            selectedOption={currentSelectedStatus}
          />
        )}
        {typeFilterActive && (
          <StaticCombobox
            isLoading={isLoading}
            label={typeTranslation}
            name='type'
            noResultsText={I18n.t('filterTranslations.noInputResults')}
            options={selectionItems.typeList}
            onSelectionChange={e => {
              handleChange(e)
            }}
            placeholder={I18n.t('general.placeholder.all')}
            selectedOption={currentSelectedType}
          />
        )}
        {containerFilterActive && (
          <StaticCombobox
            isLoading={isLoading}
            label={containerTranslation}
            name='container'
            noResultsText={I18n.t('filterTranslations.noInputResults')}
            options={selectionItems.containerList}
            onSelectionChange={e => {
              handleChange(e)
            }}
            placeholder={I18n.t('general.placeholder.all')}
            selectedOption={currentSelectedContainer}
          />
        )}
        <FilterReset
          onResetFilter={() => {
            resetForm()
            setCurrentFilterValues({})
            setFilterWasReset(true)
          }}
          showResetFilterButton={
            cleanUpAndCountFiltersForReset(currentFilterValues) > 0
          }
        />
      </div>

      <div className='filter__empty-message'>
        {!isLoading && length < 1 && (
          <NoContentMessage
            message={I18n.t('general.emptyFilterResultMessage')}
            showResetFilterButton={false}
          />
        )}
      </div>
    </>
  )
}
