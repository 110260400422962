import './style.scss'

import { Form, Formik } from 'formik'
import React, { FC, ReactText, useEffect, useMemo } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'react-redux-form'
import Spinner from 'react-spinkit'
import * as yup from 'yup'

import { addAddress, updateAddress } from 'actions/address'
import { resetApiFetchErrors } from 'actions/app'
import { getUsersByCompany } from 'actions/user'
import { MaklerPremiumGroups } from 'constants/user'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import {
  getActiveUserList,
  getCurrentUserSelector,
  getUsersByCompanyList,
} from 'selectors/user'

import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from '../../common/Button'
import { DropDownInput } from '../../common/DropDownInput'
import { InputEmail } from '../../common/InputEmail'
import { InputTelephoneNumber } from '../../common/InputTelephoneNumber'
import { InputText } from '../../common/InputText'
import { ProgressButton } from '../../common/ProgressButton'
import { Textarea } from '../../common/Textarea'
import { ApiValidationMessages } from '../Form/components/ApiValidationMessages'

import { ZipcodeFormField } from './ZipcodeFormField'

interface AddressFormProps {
  onCancel: () => void
  onSuccess: (address: Address) => void
  isEditing?: boolean
  addressToEdit?: Address
  sendUserInfo?: {
    company?: number | string
    phone_user?: number | string
  }
}

export interface AddressFormValues {
  street?: string
  house_number: string
  zipcode: string
  location: string
  description: string
  contact_person: ReactText
  contact_person_on_site: string
  telephone_number_on_site: string
  email_on_site: string
}

/**
 * @description This component renders and controls a add address form component.
 * @function
 * @param {Object} props the component props
 */
export const AddressForm: FC<AddressFormProps> = ({
  onCancel,
  onSuccess,
  isEditing = false,
  addressToEdit,
  sendUserInfo = {},
}) => {
  const isLoading = useSelector(
    createLoadingSelector(['ADD_ADDRESS', 'UPDATE_ADDRESS']),
  )
  const apiError = useSelector(
    createErrorSelector(['ADD_ADDRESS', 'UPDATE_ADDRESS']),
  )
  const userList = useSelector(getActiveUserList)
  const currentUser = useSelector(getCurrentUserSelector)
  // get ContactPerson from Company for initialValue in Form
  const usersByCompanyList = useSelector(getUsersByCompanyList)
  const dispatch = useDispatch()

  const contactPersonChoices = useMemo(() => {
    // a loading spinner will displayed until contactPersonChoices returns a valid list depending on
    // currently logged in used and selected userlist from state
    if (!userList) {
      return undefined
    }

    // set active user
    let listToMap = userList
    if (currentUser.group_id in MaklerPremiumGroups) {
      // set user list from selected company that will loaded from use effect with getUsersByCompany dispatch
      listToMap = usersByCompanyList
      if (!isEditing) {
        listToMap = userList
      }
    }
    return listToMap.map(item => ({
      optionValue: item.id,
      optionLabel: `${item.first_name} ${item.last_name}`,
    }))
  }, [userList, usersByCompanyList, currentUser, isEditing])

  const initialContactPerson = useMemo(() => {
    // initialContactPerson person can only be set if contactPersonChoices returns a valid list
    if (!contactPersonChoices) {
      return undefined
    }
    let contactPerson: number | string | undefined =
      userList[0]?.company_object?.contact_person

    if (isEditing) {
      contactPerson = addressToEdit!.contact_person
    }

    return contactPersonChoices.find(item => item.optionValue === contactPerson)
      ?.optionValue
  }, [isEditing, userList, addressToEdit, contactPersonChoices])

  // TODO @bb: still necessary? Zipcode form field refactored
  useEffect(() => {
    if (!addressToEdit?.id) {
      dispatch(actions.reset('zipcode.item'))
    }
  }, [addressToEdit, dispatch])

  // Altough there is no effect to cleanup, we can use the "useEffect" capabilities to reset the ApiErrors on
  // general cleanup. This will behave like previously using "componentWillUnmount". A sole function is returned by
  // the effect with the desired action
  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors('ADD_ADDRESS'))
      dispatch(resetApiFetchErrors('UPDATE_ADDRESS'))
    },
    [dispatch],
  )

  useEffect(() => {
    if (addressToEdit && currentUser.group_id in MaklerPremiumGroups) {
      dispatch(getUsersByCompany(addressToEdit.company))
    }
  }, [addressToEdit, currentUser.group_id, dispatch])

  if (!contactPersonChoices) {
    return <Spinner name='circle' />
  }

  return (
    <Formik
      // it is necessary to set enableReinitialize because initialContactPerson changes while rerendering
      // and formik needs to know about this changes
      enableReinitialize
      initialValues={{
        // we can assert non-null types for addressToEdit when the isEditing switch is true
        street: isEditing ? addressToEdit!.street : '',
        house_number: isEditing ? `${addressToEdit!.house_number ?? ''}` : '',
        zipcode: isEditing ? `${addressToEdit!.zipcode ?? ''}` : '',
        location: isEditing ? addressToEdit!.location ?? '' : '',
        description: isEditing ? addressToEdit!.description ?? '' : '',
        contact_person: initialContactPerson ?? '',
        contact_person_on_site: isEditing
          ? addressToEdit!.contact_person_on_site ?? ''
          : '',
        telephone_number_on_site: isEditing
          ? addressToEdit!.telephone_number_on_site ?? ''
          : '',
        email_on_site: isEditing ? addressToEdit!.email_on_site ?? '' : '',
      }}
      onSubmit={(values: AddressFormValues) => {
        const callbacks = {
          success: address => onSuccess(address),
        }

        const valuesToSend = {
          ...values,
          ...sendUserInfo,
        }

        if (isEditing) {
          dispatch(
            updateAddress(
              { ...valuesToSend, id: addressToEdit!.id },
              callbacks,
            ),
          )
        } else {
          dispatch(addAddress(valuesToSend, callbacks))
        }
      }}
      validationSchema={() =>
        yup.object().shape({
          street: yup
            .string()
            .required(I18n.t('addressForm.validation.street')),
          house_number: yup
            .string()
            .required(I18n.t('addressForm.validation.houseNumber')),
          zipcode: yup
            .string()
            .required(I18n.t('addressForm.validation.zipcode')),
          location: yup
            .string()
            .required(I18n.t('addressForm.validation.location')),
          description: yup
            .string()
            .trim()
            .min(1, I18n.t('addressForm.validation.description'))
            .max(50, I18n.t('addressForm.validation.description')),
          contact_person: yup
            .string()
            .required(I18n.t('addressForm.validation.contactPerson')),
          contact_person_on_site: yup.string(),
          telephone_number_on_site: yup.string(),
          email_on_site: yup
            .string()
            .email(I18n.t('message.validation.email.isEmail')),
        })
      }
    >
      {({
        handleSubmit,
        submitCount,
        touched,
        errors,
        values,
        handleBlur,
        handleChange,
        isValid,
        isSubmitting,
      }) => {
        return (
          <>
            <Form
              className='address-form'
              data-testid='add-address-form'
              onSubmit={handleSubmit}
              noValidate
            >
              <div className='uk-modal-body'>
                {/* This Api Validation message is needed here to get a customized error message
               (zB. Address already exists) */}
                <ApiValidationMessages
                  data-testid='add-address-form-api-error-message'
                  error={apiError as ApiError}
                />

                <Textarea
                  dataTestId='add-address-form-description-field'
                  error={
                    touched.description && errors.description
                      ? errors.description
                      : ''
                  }
                  label={I18n.t('general.label.specialNote')}
                  maxLength={50}
                  name='description'
                  onChange={handleChange}
                  placeholder={I18n.t('general.placeholder.specialNote')}
                  showRemainingCharacters
                  value={values.description}
                />

                <InputText
                  dataTestId='add-address-form-street-field'
                  dataTestIdError='form-group-error'
                  error={submitCount > 0 && touched.street ? errors.street : ''}
                  isRequired={!values.street}
                  label={I18n.t('general.placeholder.street')}
                  maxLength={200}
                  name='street'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t('general.placeholder.street')}
                  value={values.street}
                />

                <InputText
                  dataTestId='add-address-form-house-number-field'
                  dataTestIdError='form-group-error'
                  error={
                    submitCount > 0 && touched.house_number
                      ? errors.house_number
                      : ''
                  }
                  isRequired={!values.house_number}
                  label={I18n.t('general.placeholder.houseNumber')}
                  maxLength={10}
                  name='house_number'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t('general.placeholder.houseNumber')}
                  value={values.house_number}
                />

                <ZipcodeFormField />

                <InputText
                  dataTestId='add-address-form-location-field'
                  dataTestIdError='form-group-error'
                  error={
                    submitCount > 0 && touched.location ? errors.location : ''
                  }
                  isRequired={!values.location}
                  label={I18n.t('general.placeholder.location')}
                  maxLength={100}
                  name='location'
                  noNumbers
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t('general.placeholder.location')}
                  value={values.location}
                />

                <InputText
                  dataTestId='add-address-form-contact-person-on-site-field'
                  dataTestIdError='form-group-error'
                  error={
                    submitCount > 0 && touched.contact_person_on_site
                      ? errors.contact_person_on_site
                      : ''
                  }
                  label={I18n.t('general.placeholder.contactPersonOnSite')}
                  maxLength={50}
                  name='contact_person_on_site'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t(
                    'general.placeholder.contactPersonOnSite',
                  )}
                  value={values.contact_person_on_site}
                />

                <InputTelephoneNumber
                  dataTestId='add-address-form-telephone-number-on-site-field'
                  dataTestIdError='form-group-error'
                  error={
                    submitCount > 0 && touched.telephone_number_on_site
                      ? errors.telephone_number_on_site
                      : ''
                  }
                  label={I18n.t('general.placeholder.telephoneNumberOnSite')}
                  maxLength={200}
                  name='telephone_number_on_site'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t(
                    'general.placeholder.telephoneNumberOnSite',
                  )}
                  value={values.telephone_number_on_site}
                />

                <InputEmail
                  dataTestId='add-address-form-email-on-site-field'
                  dataTestIdError='form-group-error'
                  error={
                    submitCount > 0 && touched.email_on_site
                      ? errors.email_on_site
                      : ''
                  }
                  label={I18n.t('general.placeholder.emailOnSite')}
                  maxLength={200}
                  name='email_on_site'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t('general.placeholder.emailOnSite')}
                  value={values.email_on_site}
                />

                <DropDownInput
                  choices={contactPersonChoices}
                  dataTestId='add-address-form-description-field'
                  error={
                    submitCount > 0 && touched.contact_person
                      ? errors.contact_person
                      : ''
                  }
                  isRequired={!values.contact_person}
                  label={I18n.t('general.placeholder.contactPerson')}
                  name='contact_person'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.contact_person}
                  standardInputStyle
                  placeholder={'Ansprechpartner'}
                />
              </div>
              <div className='uk-modal-footer uk-text-right'>
                <span className='uk-margin-right'>
                  <Button
                    backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                    dataTestId='add-address-form-close'
                    onClick={onCancel}
                  >
                    <Translate value='general.button.cancel' />
                  </Button>
                </span>
                <ProgressButton
                  backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                  dataTestId='add-address-form-submit'
                  isDisabled={
                    (submitCount > 0 && !isValid) || (isSubmitting && isLoading)
                  }
                  isLoading={isSubmitting && isLoading}
                  onClick={handleSubmit}
                  type={BUTTON_TYPE.SUBMIT}
                >
                  <Translate
                    value={`general.button.${isEditing ? 'save' : 'submit'}`}
                  />
                </ProgressButton>
              </div>
            </Form>
          </>
        )
      }}
    </Formik>
  )
}
