import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import classNames from 'classnames'
import React from 'react'
import Media from 'react-media'
import { oneOf, oneOfType, node, func } from 'prop-types'

import { BREAKPOINT } from 'constants/design'

export const BUTTON_BAR_ALIGN = {
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
}

const ButtonBar = ({ align, children }) => (
  <div
    className={classNames(
      'button-bar',
      { 'button-bar--left': align === BUTTON_BAR_ALIGN.LEFT },
      { 'button-bar--center': align === BUTTON_BAR_ALIGN.CENTER },
      { 'button-bar--right': align === BUTTON_BAR_ALIGN.RIGHT },
    )}
  >
    {typeof children === 'function' ? (
      <Media query={{ maxWidth: BREAKPOINT.SMALL }}>
        {isSmallScreen => <>{children(isSmallScreen)}</>}
      </Media>
    ) : (
      <>{children}</>
    )}
  </div>
)

ButtonBar.propTypes = forbidExtraProps({
  align: oneOf(Object.values(BUTTON_BAR_ALIGN)),
  children: oneOfType([node, func]).isRequired,
})

ButtonBar.defaultProps = {
  align: BUTTON_BAR_ALIGN.LEFT,
}

export default ButtonBar
