import React from 'react'
import { I18n, Translate } from 'react-i18nify'

import { BUTTON_BACKGROUND_COLOR, LinkButton } from '../../common/LinkButton'
import { TooltipIcon } from '../TooltipIcon'

/**
 * Renders a rating caption by a given rating value.
 */
export const RegisterOrRequestDemoButtons = () => (
  // TODO Rename classes
  <div className='offer-results__buttons'>
    {/* REGISTER (disabled in #3228) */}
    {/*<LinkButton*/}
    {/*  className='offer-results__button offer-results__button--register'*/}
    {/*  target={{*/}
    {/*    pathname: '/registrieren',*/}
    {/*    state: {*/}
    {/*      initialSelection: COMPANY_ROLE.WASTE_PRODUCER,*/}
    {/*    },*/}
    {/*  }}*/}
    {/*>*/}
    {/*  <Translate value='RegisterOrRequestDemoButtons.registerBtn' />*/}
    {/*</LinkButton>*/}
    {/*<TextDivider*/}
    {/*  className='offer-results__divider'*/}
    {/*  text={I18n.t('RegisterOrRequestDemoButtons.divider')}*/}
    {/*/>*/}
    <LinkButton
      backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
      className='offer-results__button offer-results__button--demo'
      target='/demo-anfordern'
    >
      <Translate value='RegisterOrRequestDemoButtons.demoBtn' />
    </LinkButton>
    <div className='offer-results__tooltip'>
      <Translate
        value='RegisterOrRequestDemoButtons.tooltipCaption'
        className='offer-results__tooltip-caption'
      />
      <TooltipIcon
        iconClass='information'
        description={I18n.t('RegisterOrRequestDemoButtons.tooltipContent')}
      />
    </div>
  </div>
)
