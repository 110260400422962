import './style.scss'

import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import Media from 'react-media'
import { useSelector } from 'react-redux'

import { UserPermission } from 'constants/user'
import { BREAKPOINT } from 'constants/design'
import { createLoadingSelector } from 'selectors/loading'

import { Button } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_COLOR,
  LinkButton,
} from '../../common/LinkButton'
import { RequiredPermissions } from '../../common/RequiredPermissions'

interface MyAutoOffersActionButtonsProps {
  isArchiveModeEnabled?: boolean
  isCompanyInactive?: boolean
  areAutoOffersLocked?: boolean
  openExportAutoOffersModal?: () => void
  openUploadAutoOfferModal?: () => void
  openLockUnlockAutoOfferModal?: () => void
  openManageAutoOfferModal?: () => void
  openAutoOfferFormModal?: () => void
}

export const MyAutoOffersActionButtons: FC<MyAutoOffersActionButtonsProps> = ({
  isArchiveModeEnabled = false,
  isCompanyInactive = false,
  areAutoOffersLocked = false,
  openExportAutoOffersModal = () => undefined,
  openUploadAutoOfferModal = () => undefined,
  openLockUnlockAutoOfferModal = () => undefined,
  openManageAutoOfferModal = () => undefined,
  openAutoOfferFormModal = () => undefined,
}) => {
  const isLoading = useSelector(createLoadingSelector(['UPDATE_COMPANY']))

  return (
    <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
      {!isArchiveModeEnabled && (
        <>
          {/* ARCHIV*/}
          <LinkButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            target='/auto-offer/archive'
          >
            <Translate value='myAutoOffersPageTranslations.actionButtons.archive' />
          </LinkButton>

          {/* CSV IMPORT*/}
          <Media query={{ minWidth: BREAKPOINT.LARGE }}>
            {isBigScreen =>
              isBigScreen && (
                <>
                  <RequiredPermissions
                    requiredPermissions={[
                      UserPermission.IMPORT_CSV_WITH_AUTO_OFFERS,
                    ]}
                  >
                    <IconButton
                      iconName='download'
                      iconPosition={ICON_POSITION.RIGHT}
                      dataTestId='upload-auto-offer-form-button'
                      isDisabled={isCompanyInactive}
                      onClick={openUploadAutoOfferModal}
                    >
                      <Translate value='myAutoOffersPageTranslations.actionButtons.upload' />
                    </IconButton>
                  </RequiredPermissions>
                </>
              )
            }
          </Media>
        </>
      )}

      {/* EXPORT AUTO OFFERS*/}
      <RequiredPermissions
        requiredPermissions={[
          UserPermission.ADD_AUTOOFFER,
          UserPermission.VIEW_AUTOOFFER,
          UserPermission.EXPORT_AUTO_OFFERS,
        ]}
      >
        <Media
          key='media-extag'
          query={{ minWidth: BREAKPOINT.XLARGE }}
          render={() => (
            <IconButton
              iconName='export'
              iconPosition={ICON_POSITION.RIGHT}
              onClick={() => {
                openExportAutoOffersModal()
              }}
            >
              <Translate value='general.export' />
            </IconButton>
          )}
        />
      </RequiredPermissions>

      {!isArchiveModeEnabled && (
        <>
          {/* JOBS VERWALTEN*/}
          <RequiredPermissions
            requiredPermissions={[UserPermission.CREATE_PHONE_AUTO_OFFERS]}
          >
            <IconButton
              fontColor={BUTTON_FONT_COLOR.WHITE}
              backgroundColor={BUTTON_BACKGROUND_COLOR.WARNING}
              dataTestId='manage-auto-offer-button'
              isLoading={isLoading}
              onClick={openManageAutoOfferModal}
            >
              <Translate value='myAutoOffersPageTranslations.actionButtons.manage' />
            </IconButton>
          </RequiredPermissions>

          {/* PAUSIEREN*/}
          {!areAutoOffersLocked && (
            <RequiredPermissions
              requiredPermissions={[UserPermission.VIEW_MY_AUTO_OFFER]}
            >
              <IconButton
                fontColor={BUTTON_FONT_COLOR.WHITE}
                backgroundColor={BUTTON_BACKGROUND_COLOR.WARNING}
                dataTestId='lock-auto-offer-button'
                isDisabled={isCompanyInactive}
                isLoading={isLoading}
                onClick={openLockUnlockAutoOfferModal}
              >
                <Translate value='myAutoOffersPageTranslations.actionButtons.lock' />
              </IconButton>
            </RequiredPermissions>
          )}

          {/* NEUEN JOB EINRICHTEN*/}
          <RequiredPermissions
            requiredPermissions={[UserPermission.ADD_AUTOOFFER]}
          >
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              dataTestId='open-auto-offer-form-button'
              isDisabled={isCompanyInactive}
              onClick={openAutoOfferFormModal}
            >
              <Translate value='myAutoOffersPageTranslations.actionButtons.add' />
            </Button>
          </RequiredPermissions>
        </>
      )}

      {isArchiveModeEnabled && (
        // ZURÜCK
        <LinkButton
          backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
          target='/auto-offer'
        >
          <Translate value='general.back' />
        </LinkButton>
      )}
    </ButtonBar>
  )
}
