import { postponeIntervalFormTranslations } from './PostponeIntervalForm/translations'

export const postponeIntervalModalTranslations = {
  modalTitle: 'Wochentage und/oder Rhythmus anpassen',
  modalInfo:
    'Hier können Sie die Wochentage und/oder den Rhythmus in einem Dauerauftrag im Turnus ändern.' +
    'Von dieser Änderung sind alle zukünftigen Aufträge betroffen. ' +
    'Kunden und Partner erhalten eine E-Mail mit den betroffenen Änderungen.',
  postponeIntervalForm: postponeIntervalFormTranslations,
}
