import keyMirror from 'keymirror'

const GET_ZIPCODES = keyMirror({
  GET_ZIPCODES_REQUEST: null,
  GET_ZIPCODES_SUCCESS: null,
  GET_ZIPCODES_ERROR: null,
})

const GET_ZIPCODE = keyMirror({
  GET_ZIPCODE_REQUEST: null,
  GET_ZIPCODE_SUCCESS: null,
  GET_ZIPCODE_ERROR: null,
})

const RESET_ZIPCODE = keyMirror({
  RESET_ZIPCODE: null,
})

const SUBMIT_ZIPCODE = keyMirror({
  SUBMIT_ZIPCODE: null,
})

const HIDE_ZIPCODE_VALIDATION = keyMirror({
  HIDE_ZIPCODE_VALIDATION_MESSAGE: null,
})

const CREATE_INACTIVE_ZIPCODE_WISHLIST = keyMirror({
  CREATE_INACTIVE_ZIPCODE_WISHLIST_REQUEST: null,
})

export default {
  ...GET_ZIPCODES,
  ...GET_ZIPCODE,
  ...RESET_ZIPCODE,
  ...SUBMIT_ZIPCODE,
  ...HIDE_ZIPCODE_VALIDATION,
  ...CREATE_INACTIVE_ZIPCODE_WISHLIST,
}
