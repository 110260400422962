import keyMirror from 'keymirror'

const GET_EMPTO_INTERNAL_DOWNLOADS = keyMirror({
  GET_EMPTO_INTERNAL_DOWNLOADS_REQUEST: null,
  GET_EMPTO_INTERNAL_DOWNLOADS_SUCCESS: null,
  GET_EMPTO_INTERNAL_DOWNLOADS_ERROR: null,
})

export default {
  ...GET_EMPTO_INTERNAL_DOWNLOADS,
}
