/**
 * @description use this const to import in specific control.
 */
export const ControlDefaultProps = {
  label: null,
  placeholder: null,
  className: '',
  labelColSize: 12,
  fieldColSize: 12,
  showLabelColumn: false,
  showTooltip: false,
  tooltip: null,
  afterTooltipLabel: null,
  validators: {},
  asyncValidators: {},
  asyncValidateOn: ['blur', 'change'],
  errorMessages: {},
  tabIndex: 0,
  marginBottom: true,
  clickableLabel: false,
  disabled: false,
  errorOnlyIfNotPristine: false,
  showRequiredDot: undefined,
}

export default {
  ...ControlDefaultProps,
}
