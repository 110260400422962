import { I18n } from 'react-i18nify'
import * as yup from 'yup'

import {
  CATEGORY_CHOICES,
  STAGE_CHOICES,
  TYPE_CHOICES,
  WASTE_CATEGORY_CHOICES,
} from '../../constants'

export const validationSchema = () =>
  yup.object().shape({
    category: yup.string().required(),
    email: yup.string().email().required(),
    type: yup.string().required(),
    last_name: yup.string().when('type', {
      is: category => category === String(TYPE_CHOICES.CHOICE_PRIVATE),
      then: yup
        .string()
        .required(
          I18n.t('communicationCenterPageTranslations.fields.last_name.error'),
        ),
      otherwise: yup.string(),
    }),
    first_name: yup.string().when('type', {
      is: category => category === String(TYPE_CHOICES.CHOICE_PRIVATE),
      then: yup
        .string()
        .required(
          I18n.t('communicationCenterPageTranslations.fields.first_name.error'),
        ),
      otherwise: yup.string(),
    }),
    stage: yup.string().when('category', {
      is: category => category === String(CATEGORY_CHOICES.CHOICE_OPERATIONS),
      then: yup
        .string()
        .required(
          I18n.t('communicationCenterPageTranslations.fields.category.error'),
        ),
      otherwise: yup.string(),
    }),
    waste_category: yup.string().when('stage', {
      is: stage => stage === String(STAGE_CHOICES.CHOICE_STAGE2),
      then: yup
        .string()
        .required(
          I18n.t(
            'communicationCenterPageTranslations.fields.waste_category.error',
          ),
        ),
      otherwise: yup.string(),
    }),
    communities: yup.array().when('stage', {
      is: stage => stage === String(STAGE_CHOICES.CHOICE_STAGE2),
      then: yup
        .array()
        .required(
          I18n.t(
            'communicationCenterPageTranslations.fields.communities.error',
          ),
        ),
      otherwise: yup.array(),
    }),
    zipcodes: yup.array().when('stage', {
      is: stage => stage === String(STAGE_CHOICES.CHOICE_STAGE2),
      then: yup
        .array()
        .required(
          I18n.t('communicationCenterPageTranslations.fields.zipcodes.error'),
        ),
      otherwise: yup.array(),
    }),
    container_type: yup.string(),
    fraction: yup.string().when('waste_category', {
      is: wasteCategory =>
        wasteCategory === String(WASTE_CATEGORY_CHOICES.CHOICE_SPECIFIC),
      then: yup
        .string()
        .required(
          I18n.t('communicationCenterPageTranslations.fields.fraction.error'),
        ),
      otherwise: yup.string(),
    }),
    customer: yup.string().when('stage', {
      is: stage => stage === String(STAGE_CHOICES.CHOICE_STAGE3),
      then: yup
        .string()
        .required(
          I18n.t('communicationCenterPageTranslations.fields.customer.error'),
        ),
      otherwise: yup.string(),
    }),
  })
