import './UniversalFileUploadPreviewTextItem.scss'

import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import Icon, { ICON_NAME } from '../Fontello'

import { UploadItem } from './types'

interface UniversalFileUploadPreviewTextItemProps {
  file: UploadItem
  onRemove?: () => void
}

export const UniversalFileUploadPreviewTextItem: FC<
  UniversalFileUploadPreviewTextItemProps
> = ({ file, onRemove = () => undefined }) => (
  <div className='universal-file-upload-preview-text-item'>
    {file.name}

    <button
      className='universal-file-upload-preview-text-item__remove'
      data-uk-tooltip={I18n.t('universalFileUpload.previewRemoveButton')}
      onClick={onRemove}
      type='button'
    >
      <Icon
        className='universal-file-upload-preview-text-item__icon'
        name={ICON_NAME.CROSS}
      />
    </button>
  </div>
)
