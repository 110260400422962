import { CERTIFICATE_STATUS } from '../../certificate/constants'

import { COUNTRY_GERMANY } from './constants'

/**
 * @description Returns a matching icon name for the status of a certificate
 * @param status
 * @returns {string}
 */
export const getIconNameForStatus = status => {
  if (status === CERTIFICATE_STATUS.STATUS_PENDING) return 'search'
  if (status === CERTIFICATE_STATUS.STATUS_ACCEPTED) return 'checkmark'
  return 'warning'
}

/**
 * @description Returns a matching string that can be used for i18n instead of the ID if the status
 * @param status
 * @returns {string}
 */
export const getStatusString = status => {
  if (status === CERTIFICATE_STATUS.STATUS_PENDING) return 'pending'
  if (status === CERTIFICATE_STATUS.STATUS_ACCEPTED) return 'accepted'
  return 'rejected'
}

export const countryIsGermany = countryID =>
  Number(countryID) === COUNTRY_GERMANY

/**
 * @description Return true if _all_ fields are empty, else false.
 * Casts each field to a boolean (undefined / '' => false, 'a string' / 2 / etc => true),
 * combines them in an OR statement and flips the resulting value. This will return (a flipped) true,
 * if _all_ fields are empty (ie: undefined or ''). If at least one field is not empty,
 * this will return (a flipped) false.
 * Returning false results in an error being displayed.
 * @param postalAddress
 * @returns boolean
 */
export const allPostalAddressFieldsAreEmpty = postalAddress =>
  !(
    !!postalAddress.recipient ||
    !!postalAddress.street ||
    !!postalAddress.house_number ||
    !!postalAddress.zipcode ||
    !!postalAddress.location ||
    !!postalAddress.country ||
    !!postalAddress.email_for_invoicing_documents
  )

/**
 * @description Return true if the postalAddress fields are valid, else false
 * @param postalAddress
 * @returns boolean
 */
export const postalAddressFieldsAreValid = postalAddress => {
  // Casts each required field to a boolean (undefined / '' => false, 'a string' / 2 / etc => true)
  // and combine them in an AND statement.
  // This will return false, if at least one field is empty.
  // If none of the fields are undefined or '', this will return true.
  const allRequiredFieldsFilled =
    !!postalAddress.recipient &&
    !!postalAddress.street &&
    !!postalAddress.house_number &&
    !!postalAddress.zipcode &&
    !!postalAddress.location &&
    !!postalAddress.country

  return (
    allPostalAddressFieldsAreEmpty(postalAddress) || allRequiredFieldsFilled
  )
}

export default {}
