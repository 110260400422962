import { number, shape, string } from 'prop-types'

export const ErrorsScheme = shape({})

const FormGroupErrorProps = {
  model: string.isRequired,
  errorMessages: shape({}),
  min: number,
}

export default FormGroupErrorProps
