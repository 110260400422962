import moment from 'moment'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Link, useLocation } from 'react-router-dom'
import Spinner from 'react-spinkit'

import withErrorBoundary from 'helper/withErrorBoundary'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import { getName } from 'helper/translations'
import { checkRequiredPermissions } from 'helper/permissions'
import { localizeDateForBackend } from 'helper/general'
import { UserPermission } from 'constants/user'
import { BREAKPOINT } from 'constants/design'
import {
  exportDSZMaklerPremiumInvoices,
  exportEPDBatchFile,
  exportEPDMaklerPremiumInvoices,
  getInvoices as getEmptoInvoices,
} from 'actions/invoice'
import { getDszInvoices } from 'actions/dsz'
import {
  getDszInvoicesPaginationSelector,
  getDszInvoicesSelector,
} from 'selectors/dsz'
import { createErrorSelector } from 'selectors/error'
import {
  getInvoicesPaginationSelector as getEPDInvoicesPaginationSelector,
  getInvoicesSelector as getEPDInvoicesSelector,
} from 'selectors/invoice'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'

import { AsyncExportModal } from '../../common/AsyncExportModal'
import {
  DateForm,
  LabelType,
} from '../../common/AsyncExportModal/DateForm/DateForm'
import { BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import { CompanyAndIdAndStatusAndOrderNumberFilter } from '../../common/Filter/components/CompanyAndIdAndStatusAndOrderNumberFilter'
import Icon, { ICON_NAME } from '../../common/Fontello'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { PaginatedTable } from '../../common/PaginatedTable'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { UpDownArrow } from '../../common/UpDownArrow'
import { PAYMENT_METHODS } from '../../company/constants'
import { INITIAL_FILTER_OPEN_WIDTH } from '../../inquiry/constants'
import {
  DOCUMENT_STATUS_TYPE,
  DOCUMENT_STATUS_TYPES,
  DOCUMENT_TYPE,
  DOCUMENT_TYPES,
} from '../../invoice/constants'
import { PageHeader } from '../../layout/PageHeader'
import { DSZSetDocumentCancelledModal } from '../dsz/DSZSetDocumentCancelledModalForm/DSZSetDocumentCancelledModal'

import { CreditreformModal } from './CreditreformModal/CreditreformModal'
import { DatevModal } from './DatevModal/DatevModal'
import { ExportInvoiceBatchForm } from './ExportInvoiceBatchForm/ExportInvoiceBatchForm'
import { SetCancelledDocumentDoneModal } from './SetCancelledDocumentDoneModalForm/SetCancelledDocumentDoneModal'
import { SetDocumentCancelledModal } from './SetDocumentCancelledModalForm/SetDocumentCancelledModal'
import { SetDocumentPaidModal } from './SetDocumentPaidModalForm/SetDocumentPaidModal'
import { SetDocumentUnpaidModal } from './SetDocumentUnpaidModalForm/SetDocumentUnpaidModal'
import { ToggleDunningBlockModal } from './ToggleDunningBlockModalForm/ToggleDunningBlockModal'

const BookkeepingListComponent: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(getCurrentUserSelector)
  const isDsz = useMemo(
    () =>
      checkRequiredPermissions(user.permission_codenames, [
        UserPermission.VIEW_DSZINVOICE,
      ]),
    [user],
  )

  const epdInvoices = useSelector(getEPDInvoicesSelector)
  const epdPagination = useSelector(getEPDInvoicesPaginationSelector)

  const dszInvoices = useSelector(getDszInvoicesSelector)
  const dszPagination = useSelector(getDszInvoicesPaginationSelector)

  const invoices = useMemo(
    () => (isDsz ? dszInvoices : epdInvoices),
    [isDsz, dszInvoices, epdInvoices],
  )
  const pagination = useMemo(
    () => (isDsz ? dszPagination : epdPagination),
    [isDsz, dszPagination, epdPagination],
  )
  const isLoading = {
    BatchFile: useSelector(createLoadingSelector(['EXPORT_BATCH_FILE'])),
    EPDMaklerPremiumInvoicesExport: useSelector(
      createLoadingSelector(['EXPORT_EPD_MAKLER_PREMIUM_INVOICES']),
    ),
    DSZMaklerPremiumInvoicesExport: useSelector(
      createLoadingSelector(['EXPORT_DSZ_MAKLER_PREMIUM_INVOICES']),
    ),
    EPDInvoices: useSelector(createLoadingSelector(['GET_INVOICES'])),
    DSZInvoices: useSelector(createLoadingSelector(['GET_DSZ_INVOICES'])),
  }
  const errors = {
    EPDBatchFile: useSelector(createErrorSelector(['EXPORT_BATCH_FILE'])),
    EPDMaklerPremiumInvoicesExport: useSelector(
      createErrorSelector(['EXPORT_EPD_MAKLER_PREMIUM_INVOICES']),
    ),
    DSZMaklerPremiumInvoicesExport: useSelector(
      createErrorSelector(['EXPORT_DSZ_MAKLER_PREMIUM_INVOICES']),
    ),
    EPDInvoices: useSelector(createErrorSelector(['GET_INVOICES'])),
    DSZInvoices: useSelector(createErrorSelector(['GET_DSZ_INVOICES'])),
  }

  const location = useLocation<{ company?: string }>()

  const [filterOpen, setFilterOpen] = useState(
    window.innerWidth >= INITIAL_FILTER_OPEN_WIDTH,
  )
  const defaultSorted = [{ id: 'id', desc: true }]
  const defaultFilters: any = {
    order_by: JSON.stringify(defaultSorted),
    makler_premium_company: user.company,
  }
  if (location?.state?.company) {
    defaultFilters.company = location.state.company
  }

  const [paidModalIsOpen, setPaidModalIsOpen] = useState<boolean>(false)
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState<boolean>(false)
  const [cancelModalDSZIsOpen, setCancelModalDSZIsOpen] =
    useState<boolean>(false)
  const [dunningModalIsOpen, setDunningModalIsOpen] = useState<boolean>(false)
  const [unpaidModalIsOpen, setUnpaidModalIsOpen] = useState<boolean>(false)
  const [cancelledDoneModalIsOpen, setCancelledDoneModalIsOpen] =
    useState<boolean>(false)
  const [creditreformModalIsOpen, setCreditreformModalIsOpen] =
    useState<boolean>(false)
  const [datevModalIsOpen, setDatevModalIsOpen] = useState<boolean>(false)
  const [currentFilters, setCurrentFilters] = useState<any>(defaultFilters)
  const [invoice, setInvoice] = useState<Invoice>()
  const [isEPDExportInvoiceModalOpen, setIsEPDExportInvoiceModalOpen] =
    useState(false)
  const [isDSZExportInvoiceModalOpen, setIsDSZExportInvoiceModalOpen] =
    useState(false)
  const [isExportInvoiceBatchModalOpen, setIsExportInvoiceBatchModalOpen] =
    useState(false)
  const [exportStartDate, setExportStartDate] = useState<string>()
  const [exportEndDate, setExportEndDate] = useState<string>()

  const getExportFilters = () => {
    let filters = {
      makler_premium_company: user.company,
      order_by: JSON.stringify([{ id: 'document_date', desc: true }]),
      ...currentFilters,
    }

    Object.keys(filters).forEach(filter => {
      //remove empty filter
      if (currentFilters[filter] === '') {
        delete filters[filter]
        return
      }
    })

    if (exportStartDate) {
      filters = {
        ...filters,
        document_date_start: localizeDateForBackend(exportStartDate),
      }
    }

    if (exportEndDate) {
      filters = {
        ...filters,
        document_date_end: localizeDateForBackend(exportEndDate),
      }
    }

    return filters
  }

  const getCleanedExportFilters = () => {
    const filters = getExportFilters()
    delete filters.document_date_start
    delete filters.document_date_end
    delete filters.makler_premium_company
    delete filters.order_by

    return filters
  }

  const getInvoices = useCallback(
    (
      page?: number,
      filters?: { [key: string]: string },
      sortedBy?: { [key: string]: string },
    ) => {
      if (isDsz) {
        return getDszInvoices(page, filters, sortedBy)
      } else {
        return getEmptoInvoices(page, filters, sortedBy)
      }
    },
    [isDsz],
  )

  const getExportEPDBatchFileBody = () => {
    let data
    data = {
      ...currentFilters,
      makler_premium_company: user.company,
      order_by: JSON.stringify([{ id: 'document_date', desc: true }]),
    }

    if (exportStartDate)
      data = { ...data, document_date_start: exportStartDate }
    if (exportEndDate) data = { ...data, document_date_end: exportEndDate }

    return data
  }

  const togglePaidModal = () => {
    setPaidModalIsOpen(!paidModalIsOpen)
  }

  const toggleCancelModal = () => {
    setCancelModalIsOpen(!cancelModalIsOpen)
  }

  const toggleCancelModalDSZ = () => {
    setCancelModalDSZIsOpen(!cancelModalDSZIsOpen)
  }

  const toggleDunningModal = () => {
    setDunningModalIsOpen(!dunningModalIsOpen)
  }

  const toggleUnpaidModal = () => {
    setUnpaidModalIsOpen(!unpaidModalIsOpen)
  }

  const toggleCancelledDoneModal = () => {
    setCancelledDoneModalIsOpen(!cancelledDoneModalIsOpen)
  }

  const toggleCreditreformModalIsOpen = () => {
    setCreditreformModalIsOpen(!creditreformModalIsOpen)
  }

  const toggleDatevModalIsOpen = () => {
    setDatevModalIsOpen(!datevModalIsOpen)
  }

  const isDocumentTypeCancelled = document_type =>
    [
      DOCUMENT_TYPE.CANCELLATION_INVOICE,
      DOCUMENT_TYPE.CANCELLATION_CREDIT_NOTE,
    ].includes(Number(document_type))

  const documentCanBeMarkedAsPaid = document_status =>
    [
      DOCUMENT_STATUS_TYPE.OPEN,
      DOCUMENT_STATUS_TYPE.PAYMENT_REMINDER,
      DOCUMENT_STATUS_TYPE.OVERDUE_WARNING,
      DOCUMENT_STATUS_TYPE.DSZ_OPEN,
      DOCUMENT_STATUS_TYPE.CREDITREFORM,
      DOCUMENT_STATUS_TYPE.DATEV,
    ].includes(document_status)

  useEffect(() => {
    if (isDsz) {
      if (!isLoading.DSZInvoices && !pagination.loaded && !errors.DSZInvoices) {
        dispatch(getInvoices(undefined, currentFilters))
      }
    } else {
      if (!isLoading.EPDInvoices && !pagination.loaded && !errors.EPDInvoices) {
        dispatch(getInvoices(undefined, currentFilters))
      }
    }
  }, [
    currentFilters,
    dispatch,
    errors.DSZInvoices,
    errors.EPDInvoices,
    getInvoices,
    invoices,
    isDsz,
    isLoading.DSZInvoices,
    isLoading.EPDInvoices,
    pagination,
  ])

  const columns = [
    /* Receipt Number */
    {
      id: 'document_no',
      Header: (
        <span>
          <Translate value='invoice.invoiceList.receiptNumber' />
          <UpDownArrow />
        </span>
      ),
      width: 125,
      Cell: ({ original: { document_no, pdf_link } }) =>
        pdf_link ? (
          <Link
            to={{ pathname: pdf_link }}
            onClick={event => {
              event.preventDefault()
              window.open(pdf_link)
            }}
          >
            {`${document_no}`}
          </Link>
        ) : (
          <>{`${document_no}`}</>
        ),
    },
    /* Type of Receipt (Rechnung, Gutschrift, Storno) */
    {
      id: 'document_type',
      accessor: data => getName(data.document_type, DOCUMENT_TYPES, true),
      Header: (
        <span>
          <Translate value='invoice.invoiceList.receiptType' />
          <UpDownArrow />
        </span>
      ),
    },
    /* Date of Receipt */
    {
      id: 'document_date',
      accessor: data =>
        data.document_date && moment(data.document_date).format('L'),
      Header: (
        <span>
          <Translate value='invoice.invoiceList.receiptDate' />
          <UpDownArrow />
        </span>
      ),
      width: 120,
    },
    /* Customer */
    {
      id: 'company_name',
      accessor: 'company',
      Header: (
        <span>
          <Translate value='offerPipelineTranslations.columns.customer' />
          <UpDownArrow />
        </span>
      ),
      minWidth: 250,
      Cell: (data: { value: CompanySmall }) => (
        <Link to={`/company/${data.value.id}`}>{data.value.name}</Link>
      ),
    },
    /* Gross Price */
    {
      id: 'gross_price',
      accessor: data =>
        data &&
        data.gross_price &&
        data.gross_price.toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      Header: (
        <span>
          <Translate value='invoice.invoiceList.grossAmount' />
          <UpDownArrow />
        </span>
      ),
    },
    /* Status */
    {
      id: 'status',
      Header: (
        <span>
          <Translate value='invoice.documentPaymentStatus.statusLabel' />
        </span>
      ),
      Cell: data =>
        getName(data.original.document_status, DOCUMENT_STATUS_TYPES, true),
      filterable: false,
      sortable: false,
    },
    /* Paid At */
    {
      id: 'paid_at',
      Header: (
        <span>
          <Translate value='invoice.invoiceList.paidAt' />
          {/* <UpDownArrow />*/}
        </span>
      ),
      Cell: data =>
        data.original.paid_at ? moment(data.original.paid_at).format('L') : '-',
      filterable: false,
      sortable: false,
    },
    /* Link to Receipt (Document view / Download) */
    {
      id: 'payment_method',
      Header: (
        <Translate value='bookkeepingTranslations.columns.paymentMethod' />
      ),
      Cell: ({ original: { payment_method, actual_payment_method } }) => {
        return getName(
          actual_payment_method ?? payment_method,
          PAYMENT_METHODS,
          true,
        )
      },
    },
    /* Actions */
    {
      id: 'actions',
      Header: <Translate value='bookkeepingTranslations.columns.actions' />,
      Cell: data => {
        const document = data.original
        return (
          <RequiredPermissions
            requiredPermissions={[UserPermission.CHANGE_INVOICE]}
          >
            <span className='document-buttons'>
              {/* Exclude cancelled invoices*/}
              {!isDocumentTypeCancelled(document.document_type) && (
                <>
                  {documentCanBeMarkedAsPaid(document.document_status) && (
                    <Link
                      to='/'
                      onClick={event => {
                        event.preventDefault()
                        setInvoice(document)
                        togglePaidModal()
                      }}
                      data-uk-tooltip={`title: ${
                        document.document_type === DOCUMENT_TYPE.CREDIT_NOTE
                          ? I18n.t(
                              'bookkeepingTranslations.setDocumentPaidModal.titleCreditNote',
                            )
                          : I18n.t(
                              'bookkeepingTranslations.setDocumentPaidModal.titleInvoice',
                            )
                      }`}
                    >
                      <Icon name={ICON_NAME.CHECKED} />
                    </Link>
                  )}
                  {document.document_status === DOCUMENT_STATUS_TYPE.PAID && (
                    <Link
                      to='/'
                      onClick={event => {
                        event.preventDefault()
                        setInvoice(document)
                        toggleUnpaidModal()
                      }}
                      data-uk-tooltip={`title: ${
                        document.document_type === DOCUMENT_TYPE.CREDIT_NOTE
                          ? I18n.t(
                              'bookkeepingTranslations.setDocumentUnpaidModal.titleCreditNote',
                            )
                          : I18n.t(
                              'bookkeepingTranslations.setDocumentUnpaidModal.titleInvoice',
                            )
                      }`}
                    >
                      <Icon name={ICON_NAME.TIME_REVERSE} />
                    </Link>
                  )}
                  {document.document_status !==
                    DOCUMENT_STATUS_TYPE.CANCELLED &&
                    document.document_status !==
                      DOCUMENT_STATUS_TYPE.DSZ_CANCELLED && (
                      <Link
                        to='/'
                        onClick={event => {
                          event.preventDefault()
                          setInvoice(document)
                          isDsz ? toggleCancelModalDSZ() : toggleCancelModal()
                        }}
                        data-uk-tooltip={`title: ${
                          document.document_type === DOCUMENT_TYPE.INVOICE
                            ? I18n.t(
                                'bookkeepingTranslations.setDocumentCancelledModal.tooltipInvoice',
                              )
                            : I18n.t(
                                'bookkeepingTranslations.setDocumentCancelledModal.tooltipCreditNote',
                              )
                        }`}
                      >
                        <Icon name={ICON_NAME.WRONG} />
                      </Link>
                    )}
                  {(document.document_status === DOCUMENT_STATUS_TYPE.OPEN ||
                    document.document_status ===
                      DOCUMENT_STATUS_TYPE.PAYMENT_REMINDER) &&
                    !document.dunning_block && (
                      <Link
                        to='/'
                        onClick={event => {
                          event.preventDefault()
                          setInvoice(document)
                          toggleDunningModal()
                        }}
                        data-uk-tooltip={`title: ${I18n.t(
                          'bookkeepingTranslations.toggleDunningBlockModal.tooltip',
                        )}`}
                      >
                        <Icon name={ICON_NAME.LOCK} />
                      </Link>
                    )}
                  {(document.document_status === DOCUMENT_STATUS_TYPE.OPEN ||
                    document.document_status ===
                      DOCUMENT_STATUS_TYPE.PAYMENT_REMINDER) &&
                    document.dunning_block && (
                      <Link
                        to='/'
                        onClick={event => {
                          event.preventDefault()
                          setInvoice(document)
                          toggleDunningModal()
                        }}
                        data-uk-tooltip={`title: ${I18n.t(
                          'bookkeepingTranslations.toggleDunningBlockModal.tooltipDeactivate',
                        )}`}
                      >
                        <Icon name={ICON_NAME.LOCK_OPEN} />
                      </Link>
                    )}
                </>
              )}
              {isDocumentTypeCancelled(document.document_type) &&
                document.document_status === DOCUMENT_STATUS_TYPE.OPEN && (
                  <Link
                    to='/'
                    onClick={event => {
                      event.preventDefault()
                      setInvoice(document)
                      toggleCancelledDoneModal()
                    }}
                    data-uk-tooltip={`title: ${
                      Number(document.document_type) ===
                      DOCUMENT_TYPE.CANCELLATION_CREDIT_NOTE
                        ? I18n.t(
                            'bookkeepingTranslations.setCancelledDocumentDoneModal.titleCreditNote',
                          )
                        : I18n.t(
                            'bookkeepingTranslations.setCancelledDocumentDoneModal.titleInvoice',
                          )
                    }`}
                  >
                    <Icon name={ICON_NAME.CHECKED} />
                  </Link>
                )}
              {/*Creditreform*/}
              {(document.document_status === DOCUMENT_STATUS_TYPE.OPEN ||
                document.document_status ===
                  DOCUMENT_STATUS_TYPE.PAYMENT_REMINDER ||
                document.document_status ===
                  DOCUMENT_STATUS_TYPE.OVERDUE_WARNING ||
                document.document_status === DOCUMENT_STATUS_TYPE.DATEV ||
                (document.document_status !== DOCUMENT_STATUS_TYPE.PAID &&
                  document.document_status !==
                    DOCUMENT_STATUS_TYPE.CREDITREFORM)) && (
                <Link
                  to='#'
                  onClick={event => {
                    event.preventDefault()
                    setInvoice(document)
                    setCreditreformModalIsOpen(!creditreformModalIsOpen)
                  }}
                  data-uk-tooltip={`title: ${I18n.t(
                    'bookkeepingTranslations.creditreformModal.setCreditreform.title',
                  )}`}
                >
                  <Icon name={ICON_NAME.FLAG} />
                </Link>
              )}
              {document.document_status ===
                DOCUMENT_STATUS_TYPE.CREDITREFORM && (
                <Link
                  to='#'
                  onClick={event => {
                    event.preventDefault()
                    setInvoice(document)
                    setCreditreformModalIsOpen(!creditreformModalIsOpen)
                  }}
                  data-uk-tooltip={`title: ${I18n.t(
                    'bookkeepingTranslations.creditreformModal.resetCreditreform.title',
                  )}`}
                >
                  <Icon name={ICON_NAME.RETURN} />
                </Link>
              )}
              {/*DATEV*/}
              {(document.document_status === DOCUMENT_STATUS_TYPE.OPEN ||
                document.document_status ===
                  DOCUMENT_STATUS_TYPE.PAYMENT_REMINDER ||
                document.document_status ===
                  DOCUMENT_STATUS_TYPE.OVERDUE_WARNING ||
                document.document_status ===
                  DOCUMENT_STATUS_TYPE.CREDITREFORM ||
                (document.document_status !== DOCUMENT_STATUS_TYPE.CANCELLED &&
                  document.document_status !== DOCUMENT_STATUS_TYPE.DONE &&
                  document.document_status !== DOCUMENT_STATUS_TYPE.DATEV &&
                  document.document_status !== DOCUMENT_STATUS_TYPE.PAID)) && (
                <Link
                  to='#'
                  onClick={event => {
                    event.preventDefault()
                    setInvoice(document)
                    setDatevModalIsOpen(!datevModalIsOpen)
                  }}
                  data-uk-tooltip={`title: ${I18n.t(
                    'bookkeepingTranslations.datevModal.setDatev.iconDescription',
                  )}`}
                >
                  <Icon name={ICON_NAME.WARNING} />
                </Link>
              )}
              {/*{document.document_status === DOCUMENT_STATUS_TYPE.DATEV && (*/}
              {/*  <Link*/}
              {/*    to='#'*/}
              {/*    onClick={event => {*/}
              {/*      event.preventDefault()*/}
              {/*      setInvoice(document)*/}
              {/*      setDatevModalIsOpen(!datevModalIsOpen)*/}
              {/*    }}*/}
              {/*    data-uk-tooltip={`title: ${I18n.t(*/}
              {/*      'bookkeepingTranslations.datevModal.resetDatev.iconDescription',*/}
              {/*    )}`}*/}
              {/*  >*/}
              {/*    <Icon name={ICON_NAME.RETURN} />*/}
              {/*  </Link>*/}
              {/*)}*/}
            </span>
          </RequiredPermissions>
        )
      },
      filterable: false,
      sortable: false,
    },
  ]

  if (!pagination.loaded) {
    return (
      <>
        <Helmet>
          <title>{I18n.t('bookkeepingTranslations.headline')}</title>
        </Helmet>
        <Spinner name='circle' />
      </>
    )
  }

  return (
    <>
      <Helmet>
        <title>{I18n.t('bookkeepingTranslations.headline')}</title>
      </Helmet>

      <div className='bookkeeping-list'>
        {/*ButtonBar*/}
        <Media query={{ maxWidth: BREAKPOINT.MEDIUM }}>
          {isSmallScreen => (
            <PageHeader
              title={I18n.t('bookkeepingTranslations.headline')}
              noMargin={!isSmallScreen}
            >
              <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                <RequiredPermissions
                  requiredPermissions={[UserPermission.ADD_CUSTOMINVOICEITEM]}
                >
                  <IconButton
                    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                    iconName={ICON_NAME.PLUS}
                    iconPosition={ICON_POSITION.RIGHT}
                    onClick={() =>
                      history.push('/maklerpremium/bookkeeping/add_invoice')
                    }
                  >
                    <Translate value='bookkeepingTranslations.addInvoice' />
                  </IconButton>
                </RequiredPermissions>
                <Media
                  key='media-extag'
                  query={{ minWidth: BREAKPOINT.XLARGE }}
                >
                  <>
                    <RequiredPermissions
                      requiredPermissions={[
                        UserPermission.EXPORT_MAKLER_PREMIUM_INVOICES,
                      ]}
                    >
                      <IconButton
                        iconName='export'
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={() => setIsEPDExportInvoiceModalOpen(true)}
                        isDisabled={
                          isLoading.EPDInvoices ||
                          isLoading.EPDMaklerPremiumInvoicesExport
                        }
                        isLoading={isLoading.EPDMaklerPremiumInvoicesExport}
                      >
                        <Translate value='general.export' />
                      </IconButton>
                    </RequiredPermissions>
                    <RequiredPermissions
                      requiredPermissions={[UserPermission.EXPORT_DSZ_INVOICES]}
                    >
                      <IconButton
                        iconName='export'
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={() => setIsDSZExportInvoiceModalOpen(true)}
                        isDisabled={
                          isLoading.EPDInvoices ||
                          isLoading.EPDMaklerPremiumInvoicesExport
                        }
                        isLoading={isLoading.EPDMaklerPremiumInvoicesExport}
                      >
                        <Translate value='general.export' />
                      </IconButton>
                    </RequiredPermissions>
                    <RequiredPermissions
                      requiredPermissions={[
                        UserPermission.EXPORT_BATCH_FILE_EPD,
                        UserPermission.EXPORT_BATCH_FILE_DSZ,
                      ]}
                    >
                      <IconButton
                        iconName='export'
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={() => {
                          setIsExportInvoiceBatchModalOpen(true)
                        }}
                        isDisabled={
                          isLoading.EPDInvoices || isLoading.BatchFile
                        }
                        isLoading={isLoading.BatchFile}
                      >
                        <Translate value='bookkeepingTranslations.pageHeader.buttons.accountingExport' />
                      </IconButton>
                    </RequiredPermissions>
                  </>
                </Media>
                <IconButton
                  iconName={ICON_NAME.EXPERIMENT}
                  iconPosition={ICON_POSITION.RIGHT}
                  onClick={() => {
                    setFilterOpen(!filterOpen)
                  }}
                >
                  <Translate
                    value={
                      filterOpen ? 'general.hideFilter' : 'general.showFilter'
                    }
                  />
                </IconButton>
              </ButtonBar>
            </PageHeader>
          )}
        </Media>
        {/* Filter */}
        <Collapse isOpened={filterOpen}>
          <Filter isLoading={isLoading.EPDInvoices} length={invoices.length}>
            {/* Ignore TS explicitly and ignore the PyCharm error in your mind: required props passed via <Filter> */}
            {/* @ts-ignore */}
            <CompanyAndIdAndStatusAndOrderNumberFilter
              action={getInvoices}
              idField='document_no'
              idTranslation={I18n.t(
                'filterTranslations.companyAndIdFilter.invoiceNumber',
              )}
              setCurrentFilterValues={setCurrentFilters}
              currentFilterValues={currentFilters}
            />
          </Filter>
        </Collapse>
        {!invoices.length ? (
          ''
        ) : (
          <PaginatedTable
            serverSidePagination
            page={pagination.current}
            pages={pagination.count}
            loading={isLoading.EPDInvoices}
            handleShowPreviousPage={() => {
              dispatch(getInvoices(pagination.previous, currentFilters))
            }}
            handleShowNextPage={() => {
              dispatch(getInvoices(pagination.next, currentFilters))
            }}
            table={{
              columns,
              data: invoices,
              defaultSorted,
              onFetchData: ({ page, sorted }) => {
                if (sorted && sorted.length > 0) {
                  const newFilters = {
                    ...currentFilters,
                    order_by: JSON.stringify(sorted),
                  }
                  setCurrentFilters(newFilters)
                  if (!isLoading.EPDInvoices && !errors.EPDInvoices) {
                    dispatch(getInvoices(page, newFilters))
                  }
                }
              },
            }}
          />
        )}
      </div>

      <SetDocumentPaidModal
        isOpen={paidModalIsOpen}
        onClose={togglePaidModal}
        document={invoice}
      />
      <SetDocumentCancelledModal
        isOpen={cancelModalIsOpen}
        onClose={toggleCancelModal}
        document={invoice}
      />
      <DSZSetDocumentCancelledModal
        isOpen={cancelModalDSZIsOpen && isDsz}
        onClose={toggleCancelModalDSZ}
        document={invoice}
      />
      <ToggleDunningBlockModal
        isOpen={dunningModalIsOpen}
        onClose={toggleDunningModal}
        invoice={invoice}
        dunningBlock={invoice?.dunning_block as boolean}
      />
      <SetDocumentUnpaidModal
        isOpen={unpaidModalIsOpen}
        onClose={toggleUnpaidModal}
        document={invoice}
      />
      <SetCancelledDocumentDoneModal
        isOpen={cancelledDoneModalIsOpen}
        onClose={toggleCancelledDoneModal}
        document={invoice}
      />
      <CreditreformModal
        isOpen={creditreformModalIsOpen}
        onClose={toggleCreditreformModalIsOpen}
        document={invoice}
      />
      <DatevModal
        isOpen={datevModalIsOpen}
        onClose={toggleDatevModalIsOpen}
        document={invoice}
      />
      <AsyncExportModal
        isOpen={isEPDExportInvoiceModalOpen}
        onClose={() => setIsEPDExportInvoiceModalOpen(false)}
        reduxSelector='EXPORT_EPD_MAKLER_PREMIUM_INVOICES'
        title={I18n.t('bookkeepingTranslations.exportModal.title')}
        description_translation_key={
          'asyncExportModalTranslations.descriptionWithFilter'
        }
        notice_translation_key={
          Object.values(getCleanedExportFilters()).every(
            x =>
              x === null ||
              x === '' ||
              x === 185 ||
              x === undefined ||
              x === '[{"id":"id","desc":true}]' ||
              x === '[{"id":"document_date","desc":true}]',
          )
            ? ''
            : 'asyncExportModalTranslations.dateForm.filterNotice'
        }
        logic={exportEPDMaklerPremiumInvoices({ ...getExportFilters() })}
        resetOnDispatch={() => {
          setExportStartDate(undefined)
          setExportEndDate(undefined)
        }}
      >
        <DateForm
          setStartDate={setExportStartDate}
          setEndDate={setExportEndDate}
          labelType={LabelType.DOCUMENT_DATE}
        />
      </AsyncExportModal>
      <AsyncExportModal
        isOpen={isDSZExportInvoiceModalOpen}
        onClose={() => setIsDSZExportInvoiceModalOpen(false)}
        reduxSelector='EXPORT_DSZ_MAKLER_PREMIUM_INVOICES'
        title={I18n.t('bookkeepingTranslations.exportModal.title')}
        logic={exportDSZMaklerPremiumInvoices({
          order_by: JSON.stringify([{ id: 'document_date', desc: true }]),
        })}
      />
      <AsyncExportModal
        isOpen={isExportInvoiceBatchModalOpen}
        onClose={() => setIsExportInvoiceBatchModalOpen(false)}
        reduxSelector='EXPORT_BATCH_FILE'
        title={I18n.t('bookkeepingTranslations.exportBatchModal.title')}
        logic={exportEPDBatchFile(getExportEPDBatchFileBody())}
        resetOnDispatch={() => {
          setExportEndDate(undefined)
          setExportStartDate(undefined)
        }}
      >
        <ExportInvoiceBatchForm
          setStartDate={setExportStartDate}
          setEndDate={setExportEndDate}
        />
      </AsyncExportModal>
    </>
  )
}

export const BookkeepingList = withErrorBoundary(
  withApiErrorHandling(BookkeepingListComponent, ['GET_INVOICES']),
)
