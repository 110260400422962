import { OrderClaimProofDetailsPage } from '../components/orderclaimproof/OrderClaimProofDetailsPage'

/**
 * @constant
 * @description A constant to define execution proof components.
 */
export const claimProofRoutes = [
  {
    path: '/claim-proof/:orderClaimId',
    component: OrderClaimProofDetailsPage,
    exact: true,
  },
]

export default {}
