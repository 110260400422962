import { MONTH_PREVIOUS_CHOICES } from 'components/company/constants'

export function getFirstDayofThreeYearsAgoAsFormattedString() {
  const date = new Date(new Date().getFullYear(), 1, 1)
  return getNMonthsAgoAsFormattedString(date, 36)
}

export function getNMonthsAgoAsFormattedString(
  date: Date,
  numberOfMonths: number,
) {
  date.setFullYear(date.getFullYear(), date.getMonth() - numberOfMonths)
  return date.toISOString().split('T')[0]
}

export function get_index_month_number(index_month, delivery_date) {
  // Returns a number from 1-11 which represents a month in a year for index_month.

  const date = new Date(delivery_date)

  switch (Number(index_month)) {
    case MONTH_PREVIOUS_CHOICES.MONTH_PREVIOUS:
      date.setMonth(date.getMonth() - 1)
      return date.getMonth()
    case MONTH_PREVIOUS_CHOICES.MONTH_PREVIOUS_BEFORE:
      date.setMonth(date.getMonth() - 2)
      return date.getMonth()
    case MONTH_PREVIOUS_CHOICES.MONTH_DELIVERY:
      return date.getMonth()
  }
}

export const getOfferDisposerRentPriceContainerMonth = (offers, offerId) => {
  if (!offers) return ''
  const item = offers.find(_item => `${_item.id}` === `${offerId}`)
  if (!item) return ''

  return item.disposer_rent_price_container_month
}
