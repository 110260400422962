import { connect } from 'react-redux'

import { getOrder } from 'actions/order'
import { createOrderClaim, getOrderClaims } from 'actions/orderclaim'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getOrderSelector } from 'selectors/order'
import { getOrderStatesAndClaimsSelector } from 'selectors/orderstatus'
import { getCurrentUserSelector } from 'selectors/user'

const getOrderLoadingSelector = createLoadingSelector(['GET_ORDER'])
const getOrderClaimListLoadingSelector = createLoadingSelector([
  'GET_ORDER_CLAIM_LIST',
])
const createOrderClaimLoadingSelector = createLoadingSelector([
  'CREATE_ORDER_CLAIM',
])
const getOrderErrorSelector = createErrorSelector(['GET_ORDER'])
const getOrderClaimListErrorSelector = createErrorSelector(['GET_ORDER'])
const createOrderClaimErrorSelector = createErrorSelector([
  'CREATE_ORDER_CLAIM',
])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  isLoading: {
    getOrder: getOrderLoadingSelector(state),
    getOrderClaimList: getOrderClaimListLoadingSelector(state),
    createOrderClaim: createOrderClaimLoadingSelector(state),
  },
  order: getOrderSelector(state),
  orderStates: getOrderStatesAndClaimsSelector(state),
  user: getCurrentUserSelector(state),
  error: {
    getOrder: getOrderErrorSelector(state),
    getOrderClaimList: getOrderClaimListErrorSelector(state),
    createOrderClaim: createOrderClaimErrorSelector(state),
  },
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getOrder: (orderId, expand) => dispatch(getOrder(orderId, expand)),
  getOrderClaims: (page, filters, sortedBy) =>
    dispatch(getOrderClaims(page, filters, sortedBy)),
  createOrderClaim: orderClaim => dispatch(createOrderClaim(orderClaim)),
})

export default connect(mapStateToProps, mapDispatchToProps)
