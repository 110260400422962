import { arrayOf, bool, func, number, shape, string } from 'prop-types'

export const AttachmentScheme = {
  id: number,
  file: string,
  name: string,
  type: string,
  size: number,
  text: string,
  lastmodified_at: string,
  thumbnail: string,
}

const CommonUploadProps = {
  accept: string, // Accepted mime types (default image/*, application/pdf)
  maxSize: number, // Accepted max file size (default 5MB)
  onFilesUploaded: func.isRequired, // Called after files were successfully uploaded
  className: string,
  showPreviewMetaData: bool, // Whether to show meta data like the upload date, default false
}

export const FileUploadProps = {
  ...CommonUploadProps,
  initialFiles: arrayOf(shape(AttachmentScheme)),
  maxFiles: number, // Max number of files, null for unlimited (default 3)
  onFileRemoved: func.isRequired, // Called after a file was removed
  onFileEdited: func.isRequired, // Called after the text of a file was changed
  allowFileComment: bool, // Whether the "Text" field in the file preview will be displayed, default true.
}

export const SingleFileUploadProps = {
  ...CommonUploadProps,
  initialFile: shape(AttachmentScheme), // The currently uploaded files to display a preview
  iconName: string, // Display icon when there is no img
  displayEditIcon: bool, // Whether to display the "edit" icon, default false
}
