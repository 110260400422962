import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { bindActionCreators } from 'redux'

import * as IndexesActions from 'actions/indexes'
import { createErrorSelector } from 'selectors/error'
import {
  getIndexValuesItemFormSelector,
  getIndexValuesItemSelector,
} from 'selectors/indexes'

const errorSelector = createErrorSelector([
  'UPDATE_INDEX_VALUES',
  'CREATE_INDEX_VALUE',
])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  indexChangeForm: getIndexValuesItemFormSelector(state),
  indexChange: getIndexValuesItemSelector(state),
  error: errorSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  actions: {
    indexes: bindActionCreators(IndexesActions, dispatch),
    form: bindActionCreators(actions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
