import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as FractionActions from 'actions/fraction'
import * as OfferActions from 'actions/offer'
import * as OrderActions from 'actions/order'
import { getContainersSelector } from 'selectors/container'
import { createErrorSelector } from 'selectors/error'
import { getFractionsSelector } from 'selectors/fraction'
import { createLoadingSelector } from 'selectors/loading'
import { getOfferSelector } from 'selectors/offer'
import { getOrdersPaginationSelector, getOrdersSelector } from 'selectors/order'

const loadingSelector = createLoadingSelector([
  'GET_FRACTIONS',
  'GET_OFFER',
  'GET_ORDERS',
])
const errorSelector = createErrorSelector([
  'GET_FRACTIONS',
  'GET_OFFER',
  'GET_ORDERS',
])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  offer: getOfferSelector(state),
  orderList: getOrdersSelector(state),
  ordersPagination: getOrdersPaginationSelector(state),
  fractionList: getFractionsSelector(state),
  containerList: getContainersSelector(state),
  isLoading: loadingSelector(state),
  error: errorSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  actions: {
    order: bindActionCreators(OrderActions, dispatch),
    offer: bindActionCreators(OfferActions, dispatch),
    fraction: bindActionCreators(FractionActions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
