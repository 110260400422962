import { useFormikContext } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { ProgressButton } from 'components/common/ProgressButton'
import { DSZAddInvoiceFormValues } from 'components/maklerpremium/dsz/DSZInvoiceFormSteps/DSZAddInvoiceFormSteps'
import { createLoadingSelector } from 'selectors/loading'

import {
  EPDAddInvoiceFormValues,
  EPDAddInvoicePosition,
} from '../EPDAddInvoiceFormSteps'

interface CreateCustomInvoiceModalProps {
  isOpen?: boolean
  onClose?: () => void
}

export type CreateCustomInvoiceValues = {
  positions?: EPDAddInvoicePosition[]
  assigned_order_id?: number
  assigned_vat?: number
  invoice_address_id?: number
  iban?: string | undefined
  is_invoice: boolean
  selected_company_id?: number
}

export const CreateCustomInvoiceModal: FC<CreateCustomInvoiceModalProps> = ({
  isOpen = false,
  onClose = () => undefined,
}) => {
  const idCreateCustomInvoiceModalHeadline = uniqueId()
  const { handleSubmit } = useFormikContext<
    EPDAddInvoiceFormValues | DSZAddInvoiceFormValues
  >()
  const isLoading = useSelector(
    createLoadingSelector(['CREATE_CUSTOM_INVOICE']),
  )

  return (
    <Modal
      ariaDescribedBy={idCreateCustomInvoiceModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={I18n.t('addInvoicePageTranslations.epd.steps.4.modal.title')}
        titleId={idCreateCustomInvoiceModalHeadline}
      />

      {/* DESCRIPTION*/}
      <div className='uk-modal-body'>
        <p>
          <Translate value='addInvoicePageTranslations.epd.steps.4.modal.description' />
        </p>
      </div>

      {/* BUTTONS*/}
      <div className='uk-modal-footer uk-text-right uk-padding-remove-right'>
        <ButtonBar align={BUTTON_BAR_ALIGN.CENTER}>
          <Button
            className='uk-button uk-margin-medium-right'
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            onClick={onClose}
            isDisabled={isLoading}
          >
            <Translate value='general.button.cancel' />
          </Button>

          <ProgressButton
            className='uk-button uk-margin-medium-right'
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            onClick={() => {
              handleSubmit()
              onClose()
            }}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            <Translate value='addInvoicePageTranslations.epd.steps.4.modal.accept' />
          </ProgressButton>
        </ButtonBar>
      </div>
    </Modal>
  )
}
