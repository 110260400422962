import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types'

import { AttachmentScheme } from '../components/common/fileUpload/schemes'
import { FieldScheme } from '../components/common/Form/schemes'

import { AddressSmallScheme } from './address'
import { CompanySmallScheme } from './company'
import { ExecutionProofSmallScheme } from './executionproof'
import { OfferScheme } from './offer'
import { OrderStatusSmallScheme } from './orderstatus'

export const OrderEvaluationScheme = {
  id: number,
  type: number,
  order: number,
  company: number,
  company_name: string,
  created_at: string,
  created_by: number,
  created_by_name: string,
  rating: number,
}

export const OrderListScheme = {
  id: oneOfType([number, string]),
  collection_address_object: shape(AddressSmallScheme), // This is the full object (read only)
  customer_company: oneOfType([number, string]),
  customer_company_object: shape(CompanySmallScheme),
  offer_company: oneOfType([number, string]),
  status: number,
  progress_status: number, // only statuses relevant for progress
}

export const OrderSmallScheme = {
  id: oneOfType([number, string]),
  container: number,
  number_of_containers: number,
  fraction: oneOfType([number, string]),
  order_type: oneOfType([number, string]),
  delivery_date: oneOfType([number, string]),
  time_of_day_delivery: oneOfType([number, string]),
  delivery_date_disposed: oneOfType([number, string]),
  time_of_day_delivery_disposed: oneOfType([number, string]),
  delivery_date_postponed: oneOfType([number, string]),
  time_of_day_delivery_postponed: oneOfType([number, string]),
  collection_date: oneOfType([number, string]),
  collection_date_postponed: oneOfType([number, string]),
  time_of_day_collection_postponed: oneOfType([number, string]),
  delivery_date_executed: oneOfType([number, string]),
  collection_date_executed: oneOfType([number, string]),
  eta_delivery: oneOfType([number, string]),
  collection_address_object: shape(AddressSmallScheme), // This is the full object (read only)
  customer_company_object: shape(CompanySmallScheme),
  order_status_objects: arrayOf(shape(OrderStatusSmallScheme)),
  offer: oneOfType([number, string]),
  inquiry: oneOfType([number, string]),
  is_deduction: bool,
  progress_status: number,
}

export const OrderScheme = {
  ...OrderSmallScheme,
  attachments: arrayOf(shape(AttachmentScheme)),
  offer_object: shape(OfferScheme),
  offer_company: oneOfType([number, string]),
  customer_company: oneOfType([number, string]),
  evaluations: arrayOf(shape(OrderEvaluationScheme)),
  is_deduction: bool,
  executionproofs: arrayOf(shape(ExecutionProofSmallScheme)),
  invoice_check_attachments: arrayOf(shape(AttachmentScheme)),
}

export const OrderFormScheme = {
  $form: shape({}).isRequired,
  id: FieldScheme,
  fraction: FieldScheme,
  order_type: FieldScheme,
  delivery_date: FieldScheme,
  time_of_day_delivery: FieldScheme,
  delivery_date_disposed: FieldScheme,
  time_of_day_delivery_disposed: FieldScheme,
  eta_delivery: FieldScheme,
  collection_address_object: FieldScheme, // This is the full object (read only)
  container_name: FieldScheme,
  number_of_containers: FieldScheme,
}

export const OrderHistoryScheme = {
  id: oneOfType([number, string]),
  delivery_date: oneOfType([number, string]),
  time_of_day_delivery: oneOfType([number, string]),
  collection_date: oneOfType([number, string]),
  time_of_day_collection: oneOfType([number, string]),
  created_at: oneOfType([number, string]),
  order: number,
}
