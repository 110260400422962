import { useFormikContext } from 'formik'
import React, {
  ReactText,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'

import { getWorkloads } from 'actions/workload'
import { InputNumber } from 'components/common/InputNumber'
import { NoContentMessage } from 'components/common/NoContentMessage'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { Option } from 'components/common/StaticCombobox/StaticCombobox'
import {
  WORK_LOAD_CATEGORIES,
  WORK_LOAD_STATES,
} from 'components/maklerpremium/WorkloadPage/constants'
import { TABLE_PAGE_SIZE_10 } from 'constants/app'

import { getCurrentValueObject, resetInputOption } from '../../helpers'
import { FilterProps } from '../../types'

export type WorkloadFilterValues = {
  order_id: ReactText
  id: ReactText
  status: string
  customer: string
  partner: string
  category: string
  orderBy: { [key: string]: ReactText }
  date_start: string
  date_end: string
}

interface WorkloadFilterProps extends Partial<FilterProps> {
  getCurrentFilterValues: (filters: Partial<WorkloadFilterValues>) => void
  currentFilterValues: any
}

export const WorkloadFilter = ({
  isLoading = false,
  length = 0,
  getCurrentFilterValues,
  currentFilterValues,
  customerList = [],
  partnerList = [],
  shouldResetForm,
}: WorkloadFilterProps) => {
  const [currentSelectedWorkloadState, setCurrentSelectedWorkloadState] =
    useState<Option>()
  const [currentSelectedWorkloadCategory, setCurrentSelectedWorkloadCategory] =
    useState<Option>()
  const { handleBlur, handleChange, values } =
    useFormikContext<WorkloadFilterValues>()
  const dispatch = useDispatch()

  const getSelectionItems = () => ({
    WORKLOAD_STATES: [
      ...resetInputOption,
      ...WORK_LOAD_STATES.map(item => ({
        label: `${I18n.t(item.name)}`,
        value: `${item.id}`,
      })),
    ],
    WORKLOAD_CATEGORIES: [
      ...resetInputOption,
      ...WORK_LOAD_CATEGORIES.map(item => ({
        label: `${I18n.t(item.name)}`,
        value: `${item.id}`,
      })),
    ],
  })

  const getSelectionCompanyItems = useCallback(() => {
    return {
      CUSTOMER: [
        ...resetInputOption,
        ...customerList.map(item => ({
          label: item.name,
          value: `${item.id}`,
        })),
      ],
      PARTNER: [
        ...resetInputOption,
        ...partnerList.map(item => ({
          label: item.name,
          value: `${item.id}`,
        })),
      ],
    }
  }, [customerList, partnerList])

  const currentSelectedCustomer = useMemo(
    () =>
      getCurrentValueObject(
        getSelectionCompanyItems().CUSTOMER,
        values.customer,
      ),
    [values.customer, getSelectionCompanyItems],
  )

  const currentSelectedPartner = useMemo(
    () =>
      getCurrentValueObject(getSelectionCompanyItems().PARTNER, values.partner),
    [values.partner, getSelectionCompanyItems],
  )

  useEffect(() => {
    const requestValues = {
      ...currentFilterValues,
      order_id: values.order_id,
      status: values.status,
      category: values.category,
      customer: values.customer,
      partner: values.partner,
    }
    if (
      shouldResetForm ||
      JSON.stringify(currentFilterValues) !== JSON.stringify(requestValues)
    ) {
      getCurrentFilterValues(requestValues)
      dispatch(getWorkloads(undefined, requestValues, TABLE_PAGE_SIZE_10))
    }
  }, [
    values,
    getCurrentFilterValues,
    dispatch,
    shouldResetForm,
    currentFilterValues,
  ])

  useEffect(() => {
    setCurrentSelectedWorkloadState(
      getCurrentValueObject(getSelectionItems().WORKLOAD_STATES, values.status),
    )
  }, [values.status])

  useEffect(() => {
    setCurrentSelectedWorkloadCategory(
      getCurrentValueObject(
        getSelectionItems().WORKLOAD_CATEGORIES,
        values.category,
      ),
    )
  }, [values.category])

  return (
    <>
      <div className='uk-grid uk-child-width-1-1 uk-child-width-1-4@m'>
        <StaticCombobox
          label={I18n.t('filterTranslations.workloadFilter.status')}
          name='status'
          noResultsText={I18n.t('filterTranslations.noInputResults')}
          options={getSelectionItems().WORKLOAD_STATES}
          onSelectionChange={handleChange}
          placeholder={I18n.t('general.placeholder.all')}
          selectedOption={currentSelectedWorkloadState}
        />
        <StaticCombobox
          label={I18n.t('filterTranslations.workloadFilter.category')}
          name='category'
          noResultsText={I18n.t('filterTranslations.noInputResults')}
          options={getSelectionItems().WORKLOAD_CATEGORIES}
          onSelectionChange={handleChange}
          placeholder={I18n.t('general.placeholder.all')}
          selectedOption={currentSelectedWorkloadCategory}
          dataTestId='category-combobox'
        />
        <StaticCombobox
          label={I18n.t('filterTranslations.workloadFilter.customer')}
          name='customer'
          noResultsText={I18n.t('filterTranslations.noInputResults')}
          options={getSelectionCompanyItems().CUSTOMER}
          onSelectionChange={handleChange}
          placeholder={I18n.t('general.placeholder.all')}
          selectedOption={currentSelectedCustomer}
        />
        <StaticCombobox
          label={I18n.t('filterTranslations.workloadFilter.partner')}
          name='partner'
          noResultsText={I18n.t('filterTranslations.noInputResults')}
          options={getSelectionCompanyItems().PARTNER}
          onSelectionChange={handleChange}
          placeholder={I18n.t('general.placeholder.all')}
          selectedOption={currentSelectedPartner}
        />

        <InputNumber
          label={I18n.t('filterTranslations.workloadFilter.order')}
          maxLength={15}
          name='order_id'
          onBlur={handleBlur}
          onChange={handleChange}
          value={values.order_id}
        />
      </div>

      <div className='filter__empty-message'>
        {!isLoading && length < 1 && (
          <NoContentMessage
            message={I18n.t('general.emptyFilterResultMessage')}
            showResetFilterButton={false}
          />
        )}
      </div>
    </>
  )
}
