import { CertificateDetailsPage } from '../components/certificate/CertificateDetailsPage'
import CertificatesPage from '../components/certificate/CertificatesPage'
import MyCertificatesPage from '../components/certificate/MyCertificatesPage'
import { UserPermission } from '../constants/user'

/**
 * @constant
 * @description A constant to define certificate components.
 */
export const certificateRoutes = [
  {
    path: '/certificate/all',
    component: CertificatesPage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_CERTIFICATE],
    showErrorPage: true,
  },
  {
    path: '/certificate/mine',
    component: MyCertificatesPage,
    exact: true,
  },
  {
    path: '/certificate/:certificateId/review',
    component: CertificateDetailsPage,
    exact: true,
  },
  {
    path: '/certificate/:certificateId',
    component: CertificateDetailsPage,
    exact: true,
  },
]

export default {}
