import { useSelector } from 'react-redux'

import { isWasteProducer } from 'helper/company'
import { getCompanySelector } from 'selectors/company'
import { getCurrentUserSelector } from 'selectors/user'

import { getRoles } from '../../helpers'

export const useIsMaklerPremiumCustomer = () => {
  const currentUser = useSelector(getCurrentUserSelector)
  const company = useSelector(getCompanySelector)
  const { isMaklerPremium } = getRoles(currentUser)

  const companyBelongsToCurrentUser =
    isMaklerPremium &&
    currentUser?.company_object?.id === company.makler_premium_company

  return companyBelongsToCurrentUser && isWasteProducer(company)
}
