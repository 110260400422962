export const communicationCenterPageTranslations = {
  headline: 'Kommunikationscenter',
  introduction: `
Hier finden Sie die Kontaktdaten der Empfänger für auftrags- und abrechnungsrelevante Informationen.
Sie können die E-Mail-Adressen für die Zustellung von auftragsrelevanten E-Mails verwalten, indem Sie
neue Einträge hinzufügen, bestehende Einträge bearbeiten oder löschen.
  `,
  disposerCustomerName: 'Entsorgername',
  disposerCustomerNumber: 'Kundennr.',
  addNewEntryButtonLabel: 'Neuen Eintrag hinzufügen',
  noEntriesAvailable: 'Noch keine E-Mail-Adressen vorhanden.',
  noActivityEntriesAvailable: 'Noch keine Tätigkeiten vorhanden.',
  navigation: {
    operations_1: 'Operative Abwicklung (Stufe 1)',
    operations_2: 'Operative Abwicklung (Stufe 2)',
    operations_3: 'Operative Abwicklung (Stufe 3)',
  },
  operations_level_1_title: 'Stufe 1 (Zentrale E-Mail-Adresse)',
  operations_level_2_title: 'Stufe 2 (Kombination)',
  operations_level_3_title: 'Stufe 3 (Kunde)',
  operations_level_2_notice:
    'Hinweis: Wenn für eine Kombination keine E-Mail-Adresse hinterlegt ist, wird die zentrale E-Mail-Adresse (Stufe 1) des Entsorgers verwendet.',
  operations_level_3_notice:
    'Hinweis: Wenn für einen Kunden keine E-Mail-Adresse hinterlegt ist, wird die E-Mail-Adresse der passenden Kombination (Stufe 2) herangezogen.',
  log: {
    before: 'Vorher',
    after: 'Nachher',
  },
  columns: {
    id: 'ID',
    email: 'E-Mail-Adresse',
    email_type: 'Art',
    name: 'Name',
    actions: 'Aktionen',
    timestamp: 'Zeitstempel',
    activity: 'Tätigkeit',
    change: 'Änderung',
    waste_type: 'Abfallart',
    container: 'Behälter',
    area: 'Gebiet',
    company: 'Unternehmen',
  },
  section: {
    bookkeeping: {
      title: 'Buchhaltung',
    },
    sales: {
      title: 'Vertrieb',
    },
    operations: {
      title: 'Operative Abwicklung',
    },
    activities: {
      title: 'Tätigkeiten',
    },
  },
  buttons: {
    saveAndNewEntry: 'Speichern & weiterer Eintrag',
    save: 'Speichern',
  },
  modals: {
    newEntryAllStagesModal: {
      title: 'Eintrag erstellen',
    },
    deleteEntryModal: {
      title: 'Eintrag löschen',
      notice: 'Möchten Sie den folgenden Eintrag unwiderruflich löschen?',
    },
  },
  revisionTypes: {
    new: 'Neu',
    updated: 'Änderung',
    deleted: 'Löschung',
  },
  fields: {
    category: {
      label: 'Kategorie',
      placeholder: 'Kategorie',
      error: 'Das Feld für die Kategorie darf nicht leer sein.',
      options: {
        bookkeeping: 'Buchhaltung',
        sales: 'Vertrieb',
        operations: 'Operative Abwicklung',
      },
    },
    email: {
      label: 'E-Mail-Adresse',
      placeholder: 'E-Mail-Adresse',
      error: 'Das Feld für die E-Mail-Adresse darf nicht leer sein.',
    },
    type: {
      label: 'Art',
      placeholder: 'Art',
      error: 'Das Feld für die Art darf nicht leer sein.',
      options: {
        inbox: 'Postfach',
        private: 'Privat',
      },
    },
    waste_category: {
      label: 'Abfallart',
      placeholder: 'Abfallart',
      error: 'Das Feld für die Abfallart darf nicht leer sein.',
      options: {
        all: 'Alle Abfallarten',
        specific: 'spezifische Abfallart',
      },
    },
    last_name: {
      label: 'Nachname',
      placeholder: 'Nachname',
      error: 'Das Feld für den Nachnamen darf nicht leer sein.',
    },
    first_name: {
      label: 'Vorname',
      placeholder: 'Vorname',
      error: 'Das Feld für den Vornamen darf nicht leer sein.',
    },
    stage: {
      label: 'Stufe',
      placeholder: 'Stufe',
      error: 'Das Feld für die Stufe darf nicht leer sein.',
      options: {
        stage1: 'Stufe 1',
        stage2: 'Stufe 2',
        stage3: 'Stufe 3',
      },
    },
    fraction: {
      label: 'Abfallart',
      placeholder: 'Abfallart',
      error: 'Das Feld für die Abfallart darf nicht leer sein.',
    },
    communities: {
      label: 'Landkreise',
      placeholder: 'Landkreise',
      error: 'Das Feld für die Landkreise darf nicht leer sein.',
    },
    zipcodes: {
      label: 'Postleitzahl',
      placeholder: 'Postleitzahl',
      error: 'Das Feld für die Postleitzahl darf nicht leer sein.',
    },
    customer: {
      label: 'Unternehmen',
      placeholder: 'Unternehmen',
      error: 'Das Feld für das Unternehmen darf nicht leer sein.',
    },
    container_type: {
      label: 'Behälter',
      placeholder: 'Behälter',
      noResults: 'keine Behälter vorhanden',
      error: 'Das Feld für den Behälter darf nicht leer sein.',
    },
  },
}
