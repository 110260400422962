import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { localizeDate } from 'helper/general'
import { getName } from 'helper/translations'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { getContainerName } from 'components/executionproof/helpers'
import { RELABELED_TYPES } from 'components/order/constants'

import { getAvvValue } from './helper'

interface OrderStatusRelabeledModalProps {
  isOpen: boolean
  onClose: () => void
  order: Order
  orderStatus: any
  relabeledType: string
}

export const OrderStatusRelabeledModal: FC<OrderStatusRelabeledModalProps> = ({
  isOpen,
  order,
  orderStatus,
  relabeledType,
  onClose,
}) => {
  const showPriceFieldsModalId = uniqueId() // from lodash package. Generate a unique ID
  const containerList = useSelector(getContainersSelector)
  const fractionList = useSelector(getFractionsSelector)

  const relabeledTypeMap = {
    [RELABELED_TYPES.RELABELED]: {
      title: I18n.t('orderStatusList.relabeledModal.relabeledTitle'),
      text: (
        <Translate
          value='orderStatusList.relabeledModal.relabeledInfo'
          fraction={getName(order.fraction, fractionList)}
          fineFraction={
            order.fine_fraction
              ? `(${getName(order.fine_fraction, fractionList)})`
              : ''
          }
          avv={getAvvValue(order.fraction, fractionList, order.avv)}
          dangerousHTML
        />
      ),
    },
    [RELABELED_TYPES.CONTAINER_CHANGED]: {
      title: I18n.t('orderStatusList.relabeledModal.containerChangedTitle'),
      text: (
        <Translate
          value='orderStatusList.relabeledModal.containerChangedInfo'
          containerName={getContainerName(
            orderStatus?.open_order_invoice_check_positions[0]?.container,
            containerList,
          )}
          dangerousHTML
        />
      ),
    },
    [RELABELED_TYPES.CONTAINER_COUNT_CHANGED]: {
      title: I18n.t(
        'orderStatusList.relabeledModal.containerCountChangedTitle',
      ),
      text: (
        <Translate
          value='orderStatusList.relabeledModal.containerCountChangedInfo'
          containerCount={order.number_of_containers}
          dangerousHTML
        />
      ),
    },
    [RELABELED_TYPES.ADDITIONAL_RENT]: {
      title: I18n.t('orderStatusList.relabeledModal.additionalRentTitle'),
      text: (
        <Translate
          value='orderStatusList.relabeledModal.additionalRentInfo'
          deliveryDate={localizeDate(order.delivery_date_executed)}
          collectionDate={localizeDate(order.collection_date_executed)}
          dangerousHTML
        />
      ),
    },
  }

  return (
    <Modal
      ariaDescribedBy={showPriceFieldsModalId}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={relabeledTypeMap[relabeledType].title}
        titleId={showPriceFieldsModalId}
      />
      <div className='uk-modal-body'>
        {relabeledTypeMap[relabeledType].text}
      </div>
    </Modal>
  )
}
