import React, {
  FC,
  FocusEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getAddressesWithOrders } from 'actions/address'
import { getAddressesWithOrdersSelector } from 'selectors/address'
import { createLoadingSelector } from 'selectors/loading'

import { COMPANY_ROLE } from '../../company/constants'
import {
  AsyncMultiselectCombobox,
  AsyncMultiselectComboboxProps,
} from '../AsyncMultiselectCombobox/AsyncMultiselectCombobox'
import { callbackOrType } from '../Input'

interface FindAddressByFilterInputProps
  extends Omit<Partial<AsyncMultiselectComboboxProps>, 'error' | 'value'> {
  additionalFilters?: { [key: string]: React.ReactText }
  error?: callbackOrType<string>
  handleBlur: FocusEventHandler<HTMLElement>
  handleSelectionChange: (value: any, rawValue: string) => void
  isDisabled?: boolean
  minCharacters?: number
  name: string
  noResultsText: string
  label: string
  placeholder: string
  resultType: COMPANY_ROLE
  value?: number
  withCheckmark?: boolean
  initialOptions?: AddressWithOrders[]
  isClearable?: boolean
  onlyAddressLabel?: boolean
  onlyUniqueOptions?: boolean
}

export const FindAddressByFilterInput: FC<FindAddressByFilterInputProps> = ({
  additionalFilters,
  error = '',
  handleBlur,
  handleSelectionChange,
  isDisabled,
  minCharacters = 3,
  name,
  noResultsText,
  label,
  initialOptions,
  placeholder,
  resultType,
  value,
  withCheckmark = false,
  isClearable = false,
  onlyAddressLabel = false,
  onlyUniqueOptions = false,
}) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(
    createLoadingSelector(['GET_ADDRESSES_WITH_ORDERS']),
  )

  const getUniqueOptions = useCallback(
    (addresses: AddressWithOrders[]) => {
      if (!onlyUniqueOptions) {
        return addresses
      } else {
        const filteredSet = new Set()
        return addresses.filter(obj => {
          const duplicate = filteredSet.has(obj.display_name)
          filteredSet.add(obj.display_name)
          return !duplicate
        })
      }
    },
    [onlyUniqueOptions],
  )

  const reduxAddresses = useSelector(getAddressesWithOrdersSelector)
  const [addresses, setAddresses] = useState<AddressWithOrders[]>([])
  useEffect(() => {
    if (!isLoading) {
      setAddresses(getUniqueOptions(reduxAddresses))
    }
  }, [getUniqueOptions, isLoading, reduxAddresses])

  const errorValue = typeof error === 'string' ? error : error(name)
  const [currentInputValue, setCurrentInputValue] = useState('')

  const loadOptionsFromApi = (inputValue?: string, inputName?: string) => {
    if (inputValue && inputValue.length >= minCharacters) {
      setCurrentInputValue(inputValue)
      dispatch(
        getAddressesWithOrders({
          [inputName || name]: inputValue,
          role: resultType,
          ...additionalFilters,
        }),
      )
    }
  }

  const getVariableOptionLabel = (address: AddressWithOrders) => {
    if (!onlyAddressLabel) {
      return `${(address as AddressWithOrders)?.company_name} / ${
        (address as AddressWithOrders)?.display_name
      } / ${(address as AddressWithOrders)?.customer_no}`
    } else {
      return `${(address as AddressWithOrders)?.street} ${
        (address as AddressWithOrders)?.house_number
      }, ${(address as AddressWithOrders)?.zipcode} ${
        (address as AddressWithOrders)?.location
      }`
    }
  }

  const getInitialValue = useMemo(() => {
    return value !== 0
      ? (initialOptions ?? addresses).find(option => option.id === value)
      : undefined
  }, [value, initialOptions, addresses])

  return (
    <AsyncMultiselectCombobox
      error={errorValue}
      getOptionLabel={address =>
        getVariableOptionLabel(address as AddressWithOrders)
      }
      getOptionValue={address => `${(address as AddressWithOrders).id}`}
      handleBlur={handleBlur}
      handleChange={address => {
        handleSelectionChange(address.id, currentInputValue)
      }}
      isDisabled={isDisabled}
      isLoading={isLoading}
      loadingMessage={''}
      loadOptions={loadOptionsFromApi}
      multiSelect={false}
      name={name}
      noInputMessage={placeholder}
      noOptionsMessage={noResultsText}
      options={addresses}
      placeholder={placeholder}
      showCheckmark={!!value && !errorValue}
      value={getInitialValue}
      withCheckmark={withCheckmark}
      label={label}
      isClearable={isClearable}
    />
  )
}
