import { arrayOf, bool, func, shape } from 'prop-types'

import { DiscountScheme } from 'schemes/discount'
import { PaginationScheme } from 'schemes/pagination'
import { UserScheme } from 'schemes/user'

const DiscountPageProps = {
  getDiscounts: func.isRequired,
  getDiscount: func.isRequired,
  updateDiscountStatus: func.isRequired,
  pagination: shape(PaginationScheme),
  discount: shape(DiscountScheme),
  discountList: arrayOf(shape(DiscountScheme)),
  isLoading: shape({
    exportRedeemedDiscounts: bool,
    discountList: bool,
    discount: bool,
  }),
  user: shape(UserScheme),
}

export default DiscountPageProps
