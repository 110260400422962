import { connect } from 'react-redux'

import { getContainersSelector } from 'selectors/container'

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  containerList: getContainersSelector(state),
})

export default connect(mapStateToProps, null)
