const inactiveCompanyMessageTranslation = {
  here: 'hier',
  make: ' vornehmen.',
  message:
    'Bitte prüfen Sie die Gültigkeit Ihrer ' +
    'Nachweise. Den Upload der Nachweise können Sie ',
  title: 'Ihr Unternehmen ist für diesen Service nicht freigeschaltet',
}

export default inactiveCompanyMessageTranslation
