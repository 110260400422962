import ActionTypes from '../actiontypes/autooffer'
import CompanyActionTypes from '../actiontypes/company'

/**
 * @function
 * @name getMyAutoOffers
 * @description An action method to load the autoOffers (automatic bid template for offer) if the current user's
 * company.
 * @return {Object}
 */
export const getMyAutoOffers = (page, filters) => ({
  type: ActionTypes.GET_MY_AUTO_OFFERS_REQUEST,
  payload: {
    ...filters,
    page,
  },
})

/**
 * @function
 * @name getAutoOffer
 * @description An action method to load a single auto offer with all details
 * company.
 * @return {Object}
 */
export const getAutoOffer = id => ({
  type: ActionTypes.GET_AUTO_OFFER_REQUEST,
  payload: id,
})

/**
 * @function
 * @name createAutoOffer
 * @description Creates a new auto offer.
 * @param autoOffer AutoOfferScheme the object
 * @return {Object}
 */
export const createAutoOffer = autoOffer => ({
  type: ActionTypes.CREATE_AUTO_OFFER_REQUEST,
  payload: autoOffer,
})

/** @function
 * @name updateAutoOffer
 * @description Updates an existing auto offer. It uses the ID from the object to generate the route.
 * @param autoOffer AutoOfferScheme the object including its ID.
 * @return {Object}
 */
export const updateAutoOffer = autoOffer => ({
  type: ActionTypes.UPDATE_AUTO_OFFER_REQUEST,
  payload: autoOffer,
})

/** @function
 * @name lockUnlockAutoOffers
 * @description Locks or unlocks the capability of making auto-offers for a certain company
 * @param companyId id of a company.
 * @param lockAutoOffers boolean to lock or unlock the auto-offers.
 * @param [phoneUser] string user that requested blocking auto-offers
 * @return {Object}
 */
export const lockUnlockAutoOffers = (companyId, lockAutoOffers, phoneUser) => ({
  type: CompanyActionTypes.UPDATE_COMPANY_REQUEST,
  payload: {
    id: companyId,
    block_auto_offers: lockAutoOffers,
    updated_by_empto: phoneUser,
  },
})

/** @function
 * @name uploadAutoOffers
 * @description action to allow the upload of a list of auto offers
 * @param csvFile file to read.
 * @return {Object}
 */
export const uploadAutoOffers = csvFile => ({
  type: ActionTypes.UPLOAD_AUTO_OFFERS_REQUEST,
  payload: csvFile,
})

/**
 * @function
 * @name exportAutoOffers
 * @return {Object}
 */
export const exportAutoOffers = filters => ({
  type: ActionTypes.EXPORT_AUTO_OFFERS_REQUEST,
  payload: {
    filters,
  },
})

/**
 * @function
 * @name exportAutoOffers
 * @return {Object}
 */
export const resetAutoOffers = () => ({
  type: ActionTypes.GET_MY_AUTO_OFFERS_RESET,
})
