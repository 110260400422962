import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import classNames from 'classnames'
import { node, oneOf } from 'prop-types'
import React from 'react'

export const PARAGRAPH_ALIGN = {
  INHERIT: 'inherit',
  LEFT: 'left',
  CENTER: 'center',
  RIGHT: 'right',
}

export const PARAGRAPH_COLOR = {
  DEFAULT: 'default',
  GRAY_LIGHT: 'gray-light',
  WHITE: 'white',
  BLUE: 'blue',
}

export const PARAGRAPH_SHADOW = {
  NONE: 'none',
  LARGE: 'large',
}

export const PARAGRAPH_MARGIN = {
  INHERIT: 'inherit',
  TOP_NONE: 0,
}

const Paragraph = ({ align, children, color, shadow, margin }) => (
  <p
    className={classNames(
      'paragraph',
      { 'paragraph--color-white': color === PARAGRAPH_COLOR.WHITE },
      { 'paragraph--color-gray-light': color === PARAGRAPH_COLOR.GRAY_LIGHT },
      { 'paragraph--color-blue': color === PARAGRAPH_COLOR.BLUE },
      { 'paragraph--align-left': align === PARAGRAPH_ALIGN.LEFT },
      { 'paragraph--align-center': align === PARAGRAPH_ALIGN.CENTER },
      { 'paragraph--align-right': align === PARAGRAPH_ALIGN.RIGHT },
      { 'paragraph--shadow-large': shadow === PARAGRAPH_SHADOW.LARGE },
      { 'paragraph--margin-top-none': margin === PARAGRAPH_MARGIN.TOP_NONE },
    )}
  >
    {children}
  </p>
)

Paragraph.propTypes = forbidExtraProps({
  align: oneOf(Object.values(PARAGRAPH_ALIGN)),
  children: node.isRequired,
  color: oneOf(Object.values(PARAGRAPH_COLOR)),
  shadow: oneOf(Object.values(PARAGRAPH_SHADOW)),
  margin: oneOf(Object.values(PARAGRAPH_MARGIN)),
})

Paragraph.defaultProps = {
  align: PARAGRAPH_ALIGN.INHERIT,
  color: PARAGRAPH_COLOR.DEFAULT,
  shadow: PARAGRAPH_SHADOW.NONE,
  margin: PARAGRAPH_MARGIN.INHERIT,
}

export default Paragraph
