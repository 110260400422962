import keyMirror from 'keymirror'

export const CREATE_DEMO_REQUEST = keyMirror({
  CREATE_DEMO_REQUEST_REQUEST: null,
  CREATE_DEMO_REQUEST_SUCCESS: null,
  CREATE_DEMO_REQUEST_ERROR: null,
})

export default {
  ...CREATE_DEMO_REQUEST,
}
