import React, { FC, useContext, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getCollectiveInvoiceCompanies } from 'actions/company'
import { ReactComponent as MoneyGradientIcon } from 'assets/svg/detailspage/money-gradient.svg'
import { PaginatedTable } from 'components/common/PaginatedTable'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { UpDownArrow } from 'components/common/UpDownArrow'
import {
  COLLECTIVE_INVOICING_MODES,
  COMPANY_STATES,
} from 'components/company/constants'
import { UserPermission } from 'constants/user'
import { localizeDateTime } from 'helper/general'
import { getName } from 'helper/translations'
import {
  getCollectiveInvoiceCompanyListPaginationSelector,
  getCollectiveInvoiceCompanyListSelector,
} from 'selectors/company'
import { createLoadingSelector } from 'selectors/loading'

import { CollectiveInvoicingPageContext } from '../../CollectiveInvoicingPage'

export const CompanyTable: FC = () => {
  const dispatch = useDispatch()
  const companyList = useSelector(getCollectiveInvoiceCompanyListSelector)
  const pagination = useSelector(
    getCollectiveInvoiceCompanyListPaginationSelector,
  )

  const isLoading = useSelector(
    createLoadingSelector(['GET_COLLECTIVE_INVOICE_COMPANIES']),
  )

  const { setCompanyIdToInvoice } = useContext(CollectiveInvoicingPageContext)

  const [disabledButton, setDisabledButton] = useState(false)

  const columns = [
    {
      id: 'empto_external_number',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.companyTable.columns.customerNumber' />
          <UpDownArrow />
        </span>
      ),
      Cell: (data: { original }) => data.original.empto_external_number,
    },
    {
      id: 'name',
      accessor: 'company',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.companyTable.columns.customer' />
          <UpDownArrow />
        </span>
      ),
      Cell: (data: { original }) => (
        <Link to={`/company/${data.original.id}`}>{data.original.name}</Link>
      ),
    },
    {
      id: 'status',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.companyTable.columns.customerStatus' />
          <UpDownArrow />
        </span>
      ),
      Cell: (data: { original }) =>
        data.original.status &&
        getName(data.original.status, COMPANY_STATES, true),
    },
    {
      id: 'collective_invoicing_mode',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.companyTable.columns.collectiveInvoicingMode' />
          <UpDownArrow />
        </span>
      ),
      Cell: (data: { original }) =>
        data.original.collective_invoicing_mode &&
        getName(
          data.original.collective_invoicing_mode,
          COLLECTIVE_INVOICING_MODES,
          true,
        ),
    },
    {
      id: 'collective_invoicing_auto_send_email',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.companyTable.columns.collectiveInvoicingAutoSendEmail' />
          <UpDownArrow />
        </span>
      ),
      Cell: (data: { original }) =>
        data.original.collective_invoicing_auto_send_email
          ? I18n.t('general.yes')
          : I18n.t('general.no'),
    },
    {
      id: 'latest_collective_invoicing_tasklog__created_at',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.companyTable.columns.lastInvoicingAt' />
          <UpDownArrow />
        </span>
      ),
      Cell: (data: { original }) =>
        (data.original.latest_collective_invoicing_tasklog?.created_at &&
          localizeDateTime(
            data.original.latest_collective_invoicing_tasklog?.created_at,
          )) ??
        '-',
    },
    {
      id: 'latest_collective_invoicing_tasklog__created_by',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.companyTable.columns.lastInvoicingBy' />
          <UpDownArrow />
        </span>
      ),
      Cell: (data: { original }) =>
        data.original.latest_collective_invoicing_tasklog?.created_by
          ?.full_name ?? '-',
    },
    {
      id: 'latest_collective_invoicing_tasklog__status',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.companyTable.columns.lastInvoicingStatus' />
          <UpDownArrow />
        </span>
      ),
      Cell: (data: { original }) =>
        data.original.latest_collective_invoicing_tasklog?.status ?? '-',
    },
    {
      id: 'actions',
      Header: (
        <span>
          <Translate value='collectiveInvoicingTranslations.companyTable.columns.actions.name' />
        </span>
      ),
      Cell: (data: { original }) => {
        return (
          <RequiredPermissions
            requiredPermissions={[UserPermission.CREATE_COLLECTIVE_INVOICING]}
          >
            <button
              type='button'
              className='icon-button'
              onClick={() => {
                setDisabledButton(true)
                setCompanyIdToInvoice(data.original.id)

                setTimeout(() => setDisabledButton(false), 1000)
              }}
              data-uk-tooltip={`title: ${I18n.t(
                'collectiveInvoicingTranslations.companyTable.columns.actions.buttons.collective_invoicing_start',
              )}`}
              disabled={disabledButton}
            >
              <MoneyGradientIcon style={{ height: '20px' }} />
            </button>
          </RequiredPermissions>
        )
      },
      sortable: false,
    },
  ]
  const defaultSorted = [{ id: 'name', desc: false }]

  return (
    <CollectiveInvoicingPageContext.Consumer>
      {context => (
        <div className='uk-margin-large-top'>
          <PaginatedTable
            serverSidePagination
            page={pagination.current}
            pages={pagination.count}
            loading={isLoading}
            handleShowPreviousPage={() => {
              dispatch(
                getCollectiveInvoiceCompanies(
                  pagination.previous,
                  context.currentFilters,
                ),
              )
            }}
            handleShowNextPage={() => {
              dispatch(
                getCollectiveInvoiceCompanies(
                  pagination.next,
                  context.currentFilters,
                ),
              )
            }}
            table={{
              columns,
              data: companyList,
              defaultSorted,
              onFetchData: ({ page, sorted }) => {
                if (sorted && sorted.length > 0) {
                  const newFilters = {
                    ...context.currentFilters,
                    order_by: JSON.stringify(sorted),
                  }
                  context.setCurrentFilters(newFilters)
                  if (!isLoading) {
                    dispatch(getCollectiveInvoiceCompanies(page, newFilters))
                  }
                }
              },
            }}
          />
        </div>
      )}
    </CollectiveInvoicingPageContext.Consumer>
  )
}
