import { bool, func, shape, string } from 'prop-types'

import { ControlProps } from '../../schemes'

const ActionsScheme = shape({
  form: shape({
    setTouched: func,
  }),
})

const ButtonControlProps = {
  ...ControlProps,
  actions: ActionsScheme.isRequired,
  value: string,
  symbol: string,
  onClick: func,
  isLoading: bool, // Whether the loading animation is shown or not
}

export default ButtonControlProps
