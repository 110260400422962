import uniqueId from 'lodash.uniqueid'
import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { resetApiFetchErrors, resetApiFetchLoading } from 'actions/app'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'
import { getCurrentUserSelector } from 'selectors/user'

import { Button, BUTTON_BACKGROUND_COLOR, BUTTON_TYPE } from '../Button'
import { InputText } from '../InputText'
import { Modal } from '../Modal'
import ModalHeader from '../ModalHeader'
import { ProgressButton } from '../ProgressButton'

interface AsyncDSZExportModalProps {
  isOpen: boolean
  onClose: () => void
  reduxSelector: string
  title: string
  logic: (any) => Record<string, unknown>
}

export const AsyncDSZExportModal: FC<AsyncDSZExportModalProps> = ({
  isOpen,
  onClose,
  reduxSelector,
  title,
  logic,
}) => {
  const dispatch = useDispatch()

  const apiError = useSelector(createErrorSelector([reduxSelector])) as
    | ApiError
    | undefined
  const isLoading = useSelector(createLoadingSelector([reduxSelector]))
  const status = useSelector(createRequestStatusSelector([reduxSelector]))

  const user = useSelector(getCurrentUserSelector)

  const [selectedYear, setSelectedYear] = useState<string>()

  const idAsyncDSZExportModalHeadline = uniqueId()

  // Although there is no effect to cleanup, we can use the 'useEffect' capabilities to reset the ApiErrors on
  // general cleanup. This will behave like previously using 'componentWillUnmount'. A sole function is returned by
  // the effect with the desired action
  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors(reduxSelector))
    },
    [dispatch, reduxSelector],
  )

  useEffect(() => {
    if (status === REQUEST_STATUS.SUCCESS) {
      onClose()
      // reset loading status so the modal doesn't autoclose immediately
      dispatch(resetApiFetchLoading(reduxSelector))
    }
  }, [dispatch, onClose, reduxSelector, status])

  const selectedSYearIsValid = !!(
    selectedYear &&
    selectedYear.length === 4 &&
    Number(selectedYear) >= 2015
  )

  return (
    <Modal
      ariaDescribedBy={idAsyncDSZExportModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={title}
        titleId={idAsyncDSZExportModalHeadline}
      />
      <div className='uk-padding'>
        {/* DESCRIPTION */}
        <p
          className='uk-text'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: I18n.t('asyncExportModalTranslations.description', {
              userEmail: user.email,
            }),
          }}
        />
        {/*YEAR SELECTOR*/}
        <InputText
          dataTestId={'dsz-company-export-year-inputtext'}
          label={I18n.t('asyncExportModalTranslations.dsz.licensingYearTitle')}
          maxLength={4}
          name={'dsz-company-export-year-inputtext'}
          // onBlur={handleBlur}
          onChange={e => setSelectedYear(e.target.value)}
          placeholder={I18n.t(
            'asyncExportModalTranslations.dsz.licensingYearPlaceholder',
          )}
          value={selectedYear}
          error={
            selectedYear && selectedYear.length === 4 && !selectedSYearIsValid
              ? I18n.t('asyncExportModalTranslations.dsz.licensingYearError')
              : ''
          }
        />
      </div>

      {/*BUTTONS*/}
      <div className='uk-modal-footer uk-text-right'>
        <span className='uk-margin-right'>
          <Button
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            onClick={onClose}
          >
            {I18n.t('general.button.cancel')}
          </Button>
        </span>
        <ProgressButton
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          isDisabled={isLoading || !!apiError || !selectedSYearIsValid}
          isLoading={isLoading}
          onClick={() => {
            dispatch(logic({ year: selectedYear }))
            setSelectedYear(undefined)
          }}
          type={BUTTON_TYPE.SUBMIT}
          dataTestId='export-submit-button'
        >
          {I18n.t('general.button.submit')}
        </ProgressButton>
      </div>
    </Modal>
  )
}
