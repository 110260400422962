import ActionTypes from '../actiontypes/fraction'

export const initialState = {
  itemList: [],
  content: [],
  filterList: [],
  filterListIsLoading: false,
}

/**
 * @description A reducer for fraction.
 * @param {Object} state is the current global state from fractionInitialState.
 * @param {Object} action is the called action.
 * @return {Object}
 */

const fractionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_FRACTIONS_SUCCESS:
      return {
        ...state,
        itemList: action.payload,
      }
    case ActionTypes.GET_FRACTION_CONTENT_SUCCESS:
      return {
        ...state,
        content: action.payload,
      }
    case ActionTypes.GET_FRACTIONS_FOR_FILTER_REQUEST:
      return {
        ...state,
        filterListIsLoading: true,
      }
    case ActionTypes.GET_FRACTIONS_FOR_FILTER_SUCCESS:
      return {
        ...state,
        filterList: action.payload,
        filterListIsLoading: false,
      }
    default:
      return state
  }
}

export default fractionReducer
