import keyMirror from 'keymirror'

const GET_INVOICES = keyMirror({
  GET_INVOICES_REQUEST: null,
  GET_INVOICES_SUCCESS: null,
  GET_INVOICES_ERROR: null,
})

const GET_INVOICE = keyMirror({
  GET_INVOICE_REQUEST: null,
  GET_INVOICE_SUCCESS: null,
  GET_INVOICE_ERROR: null,
})

const UPDATE_INVOICE = keyMirror({
  UPDATE_INVOICE_REQUEST: null,
  UPDATE_INVOICE_SUCCESS: null,
  UPDATE_INVOICE_ERROR: null,
})

const UPDATE_EPD_CANCELLED_DOCUMENT = keyMirror({
  UPDATE_EPD_CANCELLED_DOCUMENT_REQUEST: null,
  UPDATE_EPD_CANCELLED_DOCUMENT_SUCCESS: null,
  UPDATE_EPD_CANCELLED_DOCUMENT_ERROR: null,
})

const EXPORT_INVOICES = keyMirror({
  EXPORT_INVOICES_REQUEST: null,
  EXPORT_INVOICES_SUCCESS: null,
})

const EXPORT_EPD_MAKLER_PREMIUM_INVOICES = keyMirror({
  EXPORT_EPD_MAKLER_PREMIUM_INVOICES_REQUEST: null,
  EXPORT_EPD_MAKLER_PREMIUM_INVOICES_SUCCESS: null,
  EXPORT_EPD_MAKLER_PREMIUM_INVOICES_ERROR: null,
})

const EXPORT_DSZ_MAKLER_PREMIUM_INVOICES = keyMirror({
  EXPORT_DSZ_MAKLER_PREMIUM_INVOICES_REQUEST: null,
  EXPORT_DSZ_MAKLER_PREMIUM_INVOICES_SUCCESS: null,
  EXPORT_DSZ_MAKLER_PREMIUM_INVOICES_ERROR: null,
})

const EXPORT_BATCH_FILE = keyMirror({
  EXPORT_BATCH_FILE_REQUEST: null,
  EXPORT_BATCH_FILE_SUCCESS: null,
  EXPORT_BATCH_FILE_ERROR: null,
})

const EXPORT_EPD_COLLECTIVE_INVOICING_FORECAST = keyMirror({
  EXPORT_EPD_COLLECTIVE_INVOICING_FORECAST_REQUEST: null,
  EXPORT_EPD_COLLECTIVE_INVOICING_FORECAST_SUCCESS: null,
  EXPORT_EPD_COLLECTIVE_INVOICING_FORECAST_ERROR: null,
})

const EXPORT_EPD_COLLECTIVE_INVOICING = keyMirror({
  EXPORT_EPD_COLLECTIVE_INVOICING_REQUEST: null,
  EXPORT_EPD_COLLECTIVE_INVOICING_SUCCESS: null,
  EXPORT_EPD_COLLECTIVE_INVOICING_ERROR: null,
})

const EXPORT_EPD_COLLECTIVE_INVOICING_ACTIVITIES = keyMirror({
  EXPORT_EPD_COLLECTIVE_INVOICING_ACTIVITIES_REQUEST: null,
  EXPORT_EPD_COLLECTIVE_INVOICING_ACTIVITIES_SUCCESS: null,
  EXPORT_EPD_COLLECTIVE_INVOICING_ACTIVITIES_ERROR: null,
})

export default {
  ...GET_INVOICES,
  ...GET_INVOICE,
  ...UPDATE_INVOICE,
  ...UPDATE_EPD_CANCELLED_DOCUMENT,
  ...EXPORT_INVOICES,
  ...EXPORT_EPD_MAKLER_PREMIUM_INVOICES,
  ...EXPORT_DSZ_MAKLER_PREMIUM_INVOICES,
  ...EXPORT_BATCH_FILE,
  ...EXPORT_EPD_COLLECTIVE_INVOICING_FORECAST,
  ...EXPORT_EPD_COLLECTIVE_INVOICING,
  ...EXPORT_EPD_COLLECTIVE_INVOICING_ACTIVITIES,
}
