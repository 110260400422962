import './styles.scss'

import { getParser } from 'bowser'
import React, { Component } from 'react'
import { Translate } from 'react-i18nify'

import { isSnapshot } from 'helper/snapshots'

import { Button, BUTTON_BACKGROUND_COLOR } from '../Button'
import {
  Headline,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
} from '../Headline'
import { Modal } from '../Modal'

export const bowserConfig = {
  chrome: '>=61', // as used by cypress:3.3.1
  chromium: '>=61', // as used by cypress:3.3.1
  firefox: '>=65',
  edge: '>15',
  safari: '>=11',
  opera: '>=55',
  ie: '>11',
  googlebot: '>=0', // disable this message for all googlebot versions
}

/**
 * Handles whether to show an outdated browser warning or not.
 */
class LegacyBrowserNotification extends Component {
  state = {
    isValidBrowser: true,
    displayWarning: false,
  }

  /**
   * Bowser Parser for the current userAgent
   * @type {Bowser.Parser.Parser}
   */
  browser = getParser(window.navigator.userAgent)

  /**
   * SessionStorage key to evaluate if the outdated message has been shown for this user session
   * @type {string}
   */
  legacyBrowserWarningSessionStorageKey = 'legcay-browser-warning-shown'

  UNSAFE_componentWillMount() {
    this.evaluateBrowser()
  }

  /**
   * Evaluates if the current browser is satisfying our requirements and if the warning should be displayed
   */
  evaluateBrowser = () => {
    const browserSatisfies = this.browser.satisfies(bowserConfig)
    // When we are snapshotting (rendering static HTML pages for SEO), we do not want to see the browser warning
    const warningAlreadyShown = this.checkWarningAlreadyShown()

    this.setState({
      isValidBrowser: browserSatisfies,
      displayWarning:
        !warningAlreadyShown && !browserSatisfies && !isSnapshot(),
    })
  }

  /**
   * Try to safely access the sessionStorage otherwise return false
   */
  checkWarningAlreadyShown = () => {
    let warningAlreadyShown

    try {
      warningAlreadyShown =
        sessionStorage.getItem(this.legacyBrowserWarningSessionStorageKey) ===
        'true'
    } catch (e) {
      warningAlreadyShown = false
    }

    return warningAlreadyShown
  }

  /**
   * Hides the legacy browser modal and sets a sessionStorage entry
   */
  hideModal = () => {
    this.setState(
      {
        displayWarning: false,
      },
      () => {
        sessionStorage.setItem(
          this.legacyBrowserWarningSessionStorageKey,
          'true',
        )
      },
    )
  }

  render() {
    const { isValidBrowser, displayWarning } = this.state

    return (
      <>
        {!isValidBrowser && (
          <Modal
            isOpen={displayWarning}
            ariaDescribedBy='modal'
            displayOverTooltip
            onClose={() => undefined}
          >
            <aside className='legacy-browser-notification'>
              <Headline
                headlineStyle={HEADLINE_STYLE.H2}
                fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
              >
                <Translate value='legacyBrowserNotification.headline' />
              </Headline>
              <section className='legacy-browser-notification__recommend'>
                <Translate
                  value='legacyBrowserNotification.recommend'
                  dangerousHTML
                />
              </section>
              <section className='legacy-browser-notification__contact'>
                <Headline
                  fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                  fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
                  headlineStyle={HEADLINE_STYLE.H4}
                  noMargin
                >
                  <Translate value='legacyBrowserNotification.contact.information' />
                </Headline>
                <div className='legacy-browser-notification__phone'>
                  <Translate
                    value='legacyBrowserNotification.contact.phone'
                    dangerousHTML
                  />
                </div>
                <div className='legacy-browser-notification__mail'>
                  <Translate
                    value='legacyBrowserNotification.contact.email'
                    dangerousHTML
                  />
                </div>
              </section>
              <Button
                onClick={this.hideModal}
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              >
                <Translate value='legacyBrowserNotification.button' />
              </Button>
            </aside>
          </Modal>
        )}
      </>
    )
  }
}

export default LegacyBrowserNotification
