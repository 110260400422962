import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import ActionTypes from '../actiontypes/community'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetCommunities
 * @description gets a list of communities. You should use this with a filter only, because there are many zipcodes.
 */
const handleGetCommunities = createLogic({
  type: ActionTypes.GET_COMMUNITIES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/community/`, action.payload)
      .then(res => Promise.resolve(res.data.results))
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COMMUNITIES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.community.handleGetCommunities.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_COMMUNITIES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [handleGetCommunities]
