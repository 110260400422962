import keyMirror from 'keymirror'

const GET_CONTAINERS = keyMirror({
  GET_CONTAINERS_REQUEST: null,
  GET_CONTAINERS_SUCCESS: null,
  GET_CONTAINERS_ERROR: null,
})

const GET_CONTAINER_CONTENT = keyMirror({
  GET_CONTAINER_CONTENT_REQUEST: null,
  GET_CONTAINER_CONTENT_SUCCESS: null,
  GET_CONTAINER_CONTENT_ERROR: null,
})

const GET_CONTAINER_FOR_FILTER = keyMirror({
  GET_CONTAINER_FOR_FILTER_REQUEST: null,
  GET_CONTAINER_FOR_FILTER_SUCCESS: null,
  GET_CONTAINER_FOR_FILTER_ERROR: null,
  RESET_CONTAINER_FOR_FILTER: null,
})

export default {
  ...GET_CONTAINERS,
  ...GET_CONTAINER_CONTENT,
  ...GET_CONTAINER_FOR_FILTER,
}
