import keyMirror from 'keymirror'

import { isValidFloat } from '../common/Form/validators'

export const INDEX_VALUES_MODALS = keyMirror({
  CHANGE_VALUE_MODAL: null,
  ADD_NEXT_MONTH_VALUES_MODAL: null,
})

export const ChangeIndexValueFormValidators = {
  value: {
    isValidFloat,
  },
}

export default {}
