import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createLogicMiddleware } from 'redux-logic'
import thunk from 'redux-thunk'

import { getCurrentUser } from '../actions/user'
import rootLogic from '../logics/root'
import rootReducer from '../reducers/root'
import { HttpService, storedLoggedIn } from '../services/http'

/*
 * A constant to include optional injected dependencies for logic - anything you
 * need to have available in your logic
 */
export const deps = {
  httpClient: HttpService,
}

/*
 * A method to initialize the logic middleware
 */
const logicMiddleware = createLogicMiddleware(rootLogic, deps)

/**
 * @description Creates the store and returns it.
 *
 * creates the global store
 */

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(logicMiddleware, thunk)),
)

if (storedLoggedIn()) {
  // Get the current loggedIn user if a user is logged in
  store.dispatch(getCurrentUser())
}

deps.httpClient.dispatch = action => {
  store.dispatch(action)
}
