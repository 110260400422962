import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import moment from 'moment/moment'
import { shape } from 'prop-types'
import React from 'react'
import { I18n } from 'react-i18nify'

import { getName } from 'helper/translations'
import { DiscountScheme } from 'schemes/discount'

import { ReadOnlyField } from '../../common/ReadOnly'
import { DISCOUNT_OCCASIONS, DISCOUNT_STATES } from '../constants'

/**
 * Renders a discount detail component
 * @param discount
 * @returns {*}
 * @constructor
 */
const DiscountDetails = ({ discount }) => (
  <div className='discount-details'>
    {/* Rabatt-ID*/}
    <ReadOnlyField
      label={I18n.t('discountOverview.fields.discountId')}
      value={discount.id}
    />

    {/* Status*/}
    <ReadOnlyField
      label={I18n.t('general.status')}
      value={I18n.t(getName(discount.status, DISCOUNT_STATES))}
    />

    {/* Rabattcode*/}
    <ReadOnlyField
      label={I18n.t('discountOverview.fields.code')}
      value={discount.code}
    />

    {/* Wert*/}
    <ReadOnlyField
      label={I18n.t('discountOverview.fields.value')}
      value={
        discount.value
          ? discount.value.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : (discount.value_percent / 100).toLocaleString('de-DE', {
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
      }
    />

    {/* Rabattyp*/}
    <ReadOnlyField
      label={I18n.t('discountOverview.fields.discountType')}
      value={
        discount.company
          ? I18n.t('discountOverview.discountType.individual')
          : I18n.t('discountOverview.discountType.general')
      }
    />

    {/* Anlass*/}
    <ReadOnlyField
      label={I18n.t('discountOverview.fields.occasion')}
      value={I18n.t(getName(discount.occasion, DISCOUNT_OCCASIONS))}
    />

    {/* Ersteller/in*/}
    <ReadOnlyField
      label={I18n.t('general.createdBy')}
      value={discount.created_by_name}
    />

    {/* Erstellt am*/}
    <ReadOnlyField
      label={I18n.t('general.createdAt')}
      value={moment(discount.created_at).format('L HH:mm')}
    />

    {/* Gültig ab*/}
    <ReadOnlyField
      label={I18n.t('discountOverview.fields.validFrom')}
      value={moment(discount.valid_from).format('L')}
    />

    {/* Gültig bis*/}
    <ReadOnlyField
      label={I18n.t('discountOverview.fields.validUntil')}
      value={moment(discount.valid_until).format('L')}
    />

    {/* if discount has no company (==> Rabatttyp is "Allgemein")*/}
    {/* then do not show the following three fields*/}
    {discount.company && (
      <>
        {/* Eingelöst von*/}
        <ReadOnlyField
          label={I18n.t('discountOverview.fields.redeemedBy')}
          value={discount.redeemed_by_name}
        />

        {/* Eingelöst am*/}
        <ReadOnlyField
          label={I18n.t('discountOverview.fields.redeemedAt')}
          value={
            discount.redeemed_at && moment(discount.redeemed_at).format('L')
          }
        />

        {/* Name des Kunden*/}
        <ReadOnlyField
          label={I18n.t('discountOverview.fields.customerName')}
          value={discount.company_object && discount.company_object.name}
        />
      </>
    )}

    {/* Begründung*/}
    <ReadOnlyField
      label={I18n.t('discountOverview.fields.description')}
      value={discount.description}
    />
  </div>
)

export const propTypes = {
  discount: shape(DiscountScheme).isRequired,
}

DiscountDetails.propTypes = forbidExtraProps(propTypes)

export default DiscountDetails
