import React from 'react'
import { I18n } from 'react-i18nify'

import { ICON_NAME } from 'components/common/Fontello'

import {
  ActionCard,
  ActionCardGrid,
  ActionCardIcon,
} from '../../components/ActionCard'
import {
  ClassificationGrid,
  ClassificationTile,
} from '../../components/Classification'
import { DashboardTileIcon } from '../../components/DashboardTileIcon/DashboardTileIcon'
import { getMetricsCaptionSingularOrPlural } from '../../helpers'

type EpdDashboardPropsType = {
  dashboardApiResponse: Dashboard
  mapsComponent: () => JSX.Element
}

export const EpdDashboard = (props: EpdDashboardPropsType) => {
  const {
    orders_today_last_week_count: ordersTodayLastWeekCount,
    orders_today_count: ordersTodayCount,
    offers_today_last_week_count: offersTodayLastWeekCount,
    offers_today_count: offersTodayCount,
    offer_pipeline_count: offerPipelineCount,
    sales_today: salesToday,
    workload,
  } = props.dashboardApiResponse

  const { mapsComponent } = props

  const workloadTimestampFormatted = new Date(
    workload?.timestamp ?? 0,
  ).toLocaleTimeString()
  const workloadCaption = I18n.t(
    `dashboard.action.caption.${getMetricsCaptionSingularOrPlural(
      workload?.count ?? 0,
    )}`,
    { timestamp: workloadTimestampFormatted },
  )

  return (
    <>
      <ClassificationGrid>
        {/* EPD 1. ROW */}
        <ClassificationTile
          buttonText={I18n.t('dashboard.MP.EPD.offerDaily.buttonText')}
          buttonTarget='/offer'
          caption={I18n.t(
            `dashboard.MP.EPD.offerDaily.caption.${getMetricsCaptionSingularOrPlural(
              offersTodayCount,
            )}`,
          )}
          value={offersTodayCount}
          offsetValue={offersTodayLastWeekCount}
          icon={DashboardTileIcon.Document}
        />
        <ClassificationTile
          buttonText={I18n.t('dashboard.MP.EPD.ordersTodayCount.buttonText')}
          buttonTarget='/order'
          caption={I18n.t(
            `dashboard.MP.EPD.ordersTodayCount.caption.${getMetricsCaptionSingularOrPlural(
              ordersTodayCount,
            )}`,
          )}
          value={ordersTodayCount}
          offsetValue={ordersTodayLastWeekCount}
          icon={DashboardTileIcon.Truck}
        />
        <ClassificationTile
          buttonText={I18n.t('dashboard.MP.EPD.salesToday.buttonText')}
          buttonTarget='/maklerpremium/bookkeeping'
          caption={I18n.t(
            `dashboard.MP.EPD.salesToday.caption.${getMetricsCaptionSingularOrPlural(
              salesToday,
            )}`,
          )}
          value={Number(salesToday).toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          icon={DashboardTileIcon.Graph}
        />
        <ClassificationTile
          buttonText={I18n.t('dashboard.action.buttonText')}
          buttonTarget='/maklerpremium/workload'
          caption={workloadCaption}
          className='caption--faded'
          value={workload?.count ?? ''}
          icon={DashboardTileIcon.Checklist}
        />
      </ClassificationGrid>

      {/* EPD 2. ROW */}
      <section className='dashboard__contents'>
        {/* MAPS */}
        {mapsComponent()}

        {/* ACTION CARDS */}
        <div className='dashboard__contents__card-list'>
          <ActionCardGrid>
            <ActionCard
              buttonText={I18n.t('dashboard.MP.EPD.upcomingOrder.buttonText')}
              buttonTarget='/maklerpremium/upcoming_orders'
              value={I18n.t('dashboard.MP.EPD.upcomingOrder.value')}
              caption={I18n.t('dashboard.MP.EPD.upcomingOrder.caption')}
            />
            <ActionCard
              buttonText={I18n.t(
                'dashboard.MP.EPD.offerPipelineCount.buttonText',
              )}
              buttonTarget='/maklerpremium/offer/pipeline'
              buttonIcon={ICON_NAME.PIPE}
              value={I18n.t('dashboard.MP.EPD.offerPipelineCount.value')}
              caption={I18n.t('dashboard.MP.EPD.offerPipelineCount.caption')}
              dataButtonCount={offerPipelineCount}
            />
            <ActionCard
              buttonText={I18n.t('dashboard.MP.customerManage.buttonText')}
              buttonTarget='/maklerpremium/customer'
              value={I18n.t('dashboard.MP.customerManage.value')}
              caption={I18n.t('dashboard.MP.customerManage.caption')}
              icon={ActionCardIcon.WASTE_PRODUCER}
            />
            <ActionCard
              buttonText={I18n.t('dashboard.MP.EPD.partnerManage.buttonText')}
              buttonTarget='/maklerpremium/partner'
              value={I18n.t('dashboard.MP.EPD.partnerManage.value')}
              caption={I18n.t('dashboard.MP.EPD.partnerManage.caption')}
              icon={ActionCardIcon.WASTE_DISPOSER}
            />
          </ActionCardGrid>
        </div>
      </section>
    </>
  )
}
