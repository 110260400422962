import { useFormikContext } from 'formik'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { Checkbox } from 'components/common/Checkbox'
import { DropDownInput } from 'components/common/DropDownInput'
import { InputEmail } from 'components/common/InputEmail'
import { InputTelephoneNumber } from 'components/common/InputTelephoneNumber'
import { InputText } from 'components/common/InputText'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { USER_GENDER_CHOICES } from 'helper/user'

import { MaklerPremiumOfferValues } from '../CreateMaklerPremiumOfferFormSteps'

export const NewCommonCustomerFields: FC = () => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    submitCount,
    values,
  } = useFormikContext<MaklerPremiumOfferValues>()

  const isFieldValid = name =>
    values[name] && values[name].length > 0 && !errors[name]
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0 ? errors[name] : undefined

  return (
    <>
      <DropDownInput
        choices={USER_GENDER_CHOICES()}
        className='maklerpremium-offerform__halfwidth-field'
        containerClassName='maklerpremium-offerform__column-2'
        error={getFieldError}
        label={I18n.t('maklerpremium.addWasteProducerModal.form.salutation')}
        name='gender'
        onChange={handleChange}
        placeholder={I18n.t(
          'maklerpremium.addWasteProducerModal.form.salutation',
        )}
        showCheckmark={isFieldValid}
        value={values.gender}
        withCheckmark
      />
      <InputText
        error={getFieldError}
        label={I18n.t('maklerpremium.addWasteProducerModal.form.first_name')}
        name='first_name'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t(
          'maklerpremium.addWasteProducerModal.form.first_name',
        )}
        value={values.first_name}
      />
      <InputText
        error={getFieldError}
        label={I18n.t('maklerpremium.addWasteProducerModal.form.last_name')}
        name='last_name'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t(
          'maklerpremium.addWasteProducerModal.form.last_name',
        )}
        showCheckmark={isFieldValid}
        value={values.last_name}
        withCheckmark
      />
      <InputEmail
        error={getFieldError}
        label={I18n.t(
          'createMaklerPremiumOfferPageTranslations.steps.3.fields.customer_email.label',
        )}
        name='customer_email'
        onChange={handleChange}
        placeholder={I18n.t(
          'createMaklerPremiumOfferPageTranslations.steps.3.fields.customer_email.placeholder',
        )}
        value={values.customer_email}
      />
      <InputTelephoneNumber
        error={getFieldError}
        label={`Optional: ${I18n.t(
          'createMaklerPremiumOfferPageTranslations.steps.3.fields.customer_telephone.label',
        )}`}
        name='customer_telephone'
        onChange={handleChange}
        placeholder={I18n.t(
          'createMaklerPremiumOfferPageTranslations.steps.3.fields.customer_telephone.placeholder',
        )}
        showCheckmark={!!isFieldValid('customer_email')}
        value={values.customer_telephone}
        withCheckmark
      />
      <InputText
        error={getFieldError('street')}
        label={`Optional: ${I18n.t(
          'maklerpremium.addWasteProducerModal.form.street',
        )}`}
        name='street'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t('maklerpremium.addWasteProducerModal.form.street')}
        value={values.street}
      />
      <InputText
        error={getFieldError}
        label={`Optional: ${I18n.t(
          'maklerpremium.addWasteProducerModal.form.house_number',
        )}`}
        name='house_number'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t(
          'maklerpremium.addWasteProducerModal.form.house_number',
        )}
        value={values.house_number}
        showCheckmark={
          !!(isFieldValid('street') && isFieldValid('house_number'))
        }
        withCheckmark
      />
      <ReadOnlyField
        label={I18n.t('maklerpremium.addWasteProducerModal.form.postal_code')}
        value={values.zipcode}
      />
      <InputText
        error={getFieldError}
        label={`Optional: ${I18n.t(
          'maklerpremium.addWasteProducerModal.form.city',
        )}`}
        name='city'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t('maklerpremium.addWasteProducerModal.form.city')}
        value={values.city}
        showCheckmark={isFieldValid}
        withCheckmark
      />
      <Checkbox
        className='maklerpremium-offerform__column-2'
        error={getFieldError('different_invoicing_address')}
        label={I18n.t(
          'createMaklerPremiumOfferPageTranslations.steps.3.fields.different_invoicing_address.label',
        )}
        name='different_invoicing_address'
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setFieldValue('different_invoicing_address', e.target.checked)
        }
        isChecked={!!values.different_invoicing_address}
      />
      {!!values.different_invoicing_address && (
        <>
          <InputText
            className='maklerpremium-offerform__column-2'
            error={getFieldError}
            label={`${I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.3.fields.invoice_company_name.label',
            )}`}
            name='address_description'
            onChange={handleChange}
            placeholder={I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.3.fields.invoice_company_name.placeholder',
            )}
            showCheckmark={isFieldValid}
            value={values.address_description}
            withCheckmark
          />
          <InputText
            className='maklerpremium-offerform__column-2'
            error={getFieldError}
            label={`Optional: ${I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.3.fields.invoice_recipient.label',
            )}`}
            name='invoice_recipient'
            onChange={handleChange}
            placeholder={I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.3.fields.invoice_recipient.placeholder',
            )}
            showCheckmark={isFieldValid}
            value={values.invoice_recipient}
            withCheckmark
          />
          <InputText
            error={getFieldError}
            label={I18n.t('maklerpremium.addWasteProducerModal.form.street')}
            name='invoice_street'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t(
              'maklerpremium.addWasteProducerModal.form.street',
            )}
            value={values.invoice_street}
          />
          <InputText
            error={getFieldError}
            label={I18n.t(
              'maklerpremium.addWasteProducerModal.form.house_number',
            )}
            name='invoice_house_number'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t(
              'maklerpremium.addWasteProducerModal.form.house_number',
            )}
            value={values.invoice_house_number}
            showCheckmark={
              !!(
                isFieldValid('invoice_street') &&
                isFieldValid('invoice_house_number')
              )
            }
            withCheckmark
          />
          <InputText
            error={getFieldError}
            label={I18n.t(
              'maklerpremium.addWasteProducerModal.form.postal_code',
            )}
            name='invoice_zipcode'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t(
              'maklerpremium.addWasteProducerModal.form.postal_code',
            )}
            value={values.invoice_zipcode}
          />
          <InputText
            error={getFieldError}
            label={I18n.t('maklerpremium.addWasteProducerModal.form.city')}
            name='invoice_city'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t(
              'maklerpremium.addWasteProducerModal.form.city',
            )}
            value={values.invoice_city}
            showCheckmark={
              !!(
                isFieldValid('invoice_zipcode') && isFieldValid('invoice_city')
              )
            }
            withCheckmark
          />
        </>
      )}
    </>
  )
}
