import './UniversalFileUpload.scss'

import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import React, {
  createRef,
  FC,
  SyntheticEvent,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { I18n } from 'react-i18nify'

import { Button } from '../Button'
import { callbackOrType } from '../Input'
import { InputCheckmark } from '../InputCheckmark'

import { UploadItem } from './types'
import { UniversalFileUploadLabel } from './UniversalFileUploadLabel'
import { UniversalFileUploadPreview } from './UniversalFileUploadPreview'

interface UniversalFileUploadProps {
  allowComments?: boolean // allow added files to be commented
  allowRemove?: boolean
  allowRotate?: boolean // allow added files to be rotated
  allowedFileTypes?: string | string[] // string or array of allowed file types
  className?: string // additional value for className
  error?: callbackOrType<string> // text value for error message
  initialValues?: UploadItem[] // initial values for e.g. profile image upload
  isDisabled?: boolean
  label?: string // text value for label
  largePreviews?: boolean // display larger previews e.g. profile image
  maxFiles?: number // maximum amount of selectable files
  name: string // input field name
  onChange?: (fileList: UploadItem[]) => void // callback which is triggered on fileList changes
  showButtonBelowPreview?: boolean // display upload button below preview images like in the profile picture upload
  showCheckmark?: callbackOrType<boolean> // display the validation checkmark
  showFilename?: boolean // display filename below the file previews
  showPreviewAsTextItem?: boolean // render only a text list with filenames as preview
  showPreviewLabel?: boolean // display label above preview section
  showPreviewLabelCounter?: boolean // display label above preview section with current amount of maximum files
  subLabel?: string // text value for smaller secondary label
  withCheckmark?: boolean // prepare component styles for displaying the validation checkmark
}

/**
 * FileUpload component which combines the functionality of uploading documents
 * in a minimal way or display enhanced previews for uploaded files with
 * multiple options like image previews or adding comments
 * @constructor
 */
export const UniversalFileUpload: FC<UniversalFileUploadProps> = ({
  allowComments = false,
  allowRemove = true,
  allowRotate = false,
  allowedFileTypes = ['*/*'],
  className,
  error,
  initialValues,
  isDisabled = false,
  label,
  largePreviews = false,
  maxFiles = 1,
  name,
  onChange = () => undefined,
  showButtonBelowPreview = false,
  showCheckmark = false,
  showFilename = false,
  showPreviewAsTextItem = false,
  showPreviewLabel = false,
  showPreviewLabelCounter = false,
  subLabel,
  withCheckmark = false,
}) => {
  const inputId = useRef<string>(uniqueId('universal-input-'))
  const inputRef = createRef<HTMLInputElement>()

  const initialItemsWithoutOrientation =
    initialValues && initialValues.map(item => ({ ...item, orientation: 0 }))

  const [fileList, setFileList] = useState<UploadItem[]>(
    initialItemsWithoutOrientation ?? [],
  )
  const [uploadIsAllowed, setUploadIsAllowed] = useState<boolean>(true)

  /* determine if the upload button is enabled and further uploads are allowed */
  useEffect(() => {
    setUploadIsAllowed(fileList.length < maxFiles || maxFiles === 1)
  }, [fileList, maxFiles])

  /* creates and UploadItem object from a native File object to allow comments and rotation */
  /* this structure is also similar to the Attachment interface which describes API data */
  const createUploadItem = (file: File): UploadItem => ({
    file: URL.createObjectURL(file),
    name: file.name,
    type: file.type,
    orientation: 0,
    data: file,
  })

  /* handles addition of files or changes */
  const handleInputChange = (event: SyntheticEvent<HTMLInputElement>) => {
    const addedFile = event.currentTarget?.files![0]

    /* if only one file is allowed overwrite file list */
    const uploadItem = createUploadItem(addedFile)
    if (maxFiles === 1) {
      setFileList([uploadItem])
      onChange([uploadItem])
      return
    }

    /* if more than one file is allowed extend fileList if file is not existing */
    const fileAlreadyInList =
      fileList.findIndex(file => addedFile.name === file.name) !== -1
    if (fileAlreadyInList || !uploadIsAllowed) return

    setFileList([...fileList, uploadItem])
    onChange([...fileList, uploadItem])
  }

  /* removes a given file from current files list */
  const handleFileRemove = (selectedFile: UploadItem): void => {
    const filteredFileList = fileList.filter(
      file => file.name !== selectedFile.name,
    )

    setFileList(filteredFileList)
    onChange(filteredFileList)
  }

  /* increases the orientation value of an file */
  const handleFileRotate = (selectedFile: UploadItem): void => {
    const orientation =
      selectedFile.orientation < 270 ? selectedFile.orientation + 90 : 0

    const updatedFileList = fileList.map(file =>
      file.name !== selectedFile.name
        ? file
        : {
            ...selectedFile,
            orientation,
          },
    )

    setFileList(updatedFileList)
    onChange(updatedFileList)
  }

  /* changes the text field of an file object */
  const handleFileComment = (selectedFile: UploadItem, text: string): void => {
    const updatedFileList = fileList.map(file =>
      file.name !== selectedFile.name
        ? file
        : {
            ...selectedFile,
            text,
          },
    )

    setFileList(updatedFileList)
    onChange(updatedFileList)
  }

  const errorValue =
    typeof error === 'string' || typeof error === 'undefined'
      ? error
      : error(name)
  const showCheckmarkValue =
    typeof showCheckmark === 'boolean' ? showCheckmark : showCheckmark(name)

  return (
    <div
      className={classNames(
        {
          'universal-file-upload': true,
          'universal-file-upload--large-previews': largePreviews,
          'universal-file-upload--with-checkmark': withCheckmark,
        },
        className,
      )}
    >
      {/* visually hidden input field for enabling native file uploading behaviour */}
      <input
        id={inputId.current}
        className='universal-file-upload__input'
        name={name}
        accept={allowedFileTypes.toString()}
        type='file'
        ref={inputRef}
        onChange={handleInputChange}
        aria-hidden
        tabIndex={-1}
      />
      <Row middle='xs'>
        <Col xs={12} sm={12}>
          {/* conditionally renders label and subLabel */}
          <UniversalFileUploadLabel label={label} subLabel={subLabel} />
          {/* display upload button above preview images (default) */}
          {!isDisabled && !showButtonBelowPreview && (
            <Button
              className={classNames({
                'universal-file-upload__button': true,
                'universal-file-upload__button--above-preview':
                  !showButtonBelowPreview,
              })}
              onClick={() => {
                // the value inside of the hidden input field needs to be resettet,
                // so the same file can be uploaded again
                if (inputRef.current) {
                  inputRef.current.value = ''
                  inputRef.current.click()
                }
              }}
              isDisabled={!uploadIsAllowed}
            >
              {I18n.t('universalFileUpload.buttonText')}
            </Button>
          )}
          {/* display checkmark besides upload component (like createInquiry) */}
          {!isDisabled && withCheckmark && (
            <InputCheckmark isHidden={!showCheckmarkValue} />
          )}
          {/* renders the preview section with thumbnails or text list */}
          <UniversalFileUploadPreview
            allowComments={allowComments}
            allowRemove={allowRemove}
            allowRotate={allowRotate}
            fileList={fileList}
            maxFiles={maxFiles}
            showFilename={showFilename}
            showPreviewAsTextItem={showPreviewAsTextItem}
            showPreviewLabel={showPreviewLabel}
            showPreviewLabelCounter={showPreviewLabelCounter}
            onRemove={handleFileRemove}
            onRotate={handleFileRotate}
            onComment={handleFileComment}
          />
          {/* display upload button below preview images (profile picture behaviour) */}
          {!isDisabled && showButtonBelowPreview && (
            <Button
              className={classNames({
                'universal-file-upload__button': true,
                'universal-file-upload__button--below-preview':
                  !showButtonBelowPreview,
              })}
              onClick={() => inputRef.current?.click()}
              isDisabled={!uploadIsAllowed}
            >
              {I18n.t('universalFileUpload.buttonText')}
            </Button>
          )}
        </Col>
      </Row>
      {/* display error message if error contains text */}
      {!isDisabled && errorValue && errorValue !== '' && (
        <div className='universal-file-upload__error'>{errorValue}</div>
      )}
    </div>
  )
}
