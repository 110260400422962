import React, { Component } from 'react'
import { I18n } from 'react-i18nify'
import { arrayOf, bool, shape } from 'prop-types'

import DetailRow from 'components/common/DetailSwitcher/components/DetailRow'
import DetailSection from 'components/common/DetailSwitcher/components/DetailSection'
import { getContainerName } from 'components/inquiry/helpers'
import { get } from 'helper/general'
import { ContainerScheme } from 'schemes/container'
import { FractionScheme } from 'schemes/fraction'
import { OrderScheme } from 'schemes/order'

/**
 * @description This component renders the details component.
 */
export class DetailsComponent extends Component {
  render() {
    const fraction = this.props.fractionList.find(
      _fraction => _fraction.id === this.props.order.offer_object.fraction,
    )
    const fineFraction = this.props.fractionList.find(
      _fraction => _fraction.id === this.props.order.offer_object.fine_fraction,
    )
    return (
      <>
        <DetailSection
          title={I18n.t('orderDetails.details.title')}
          icon={`${this.props.showAccordion ? 'document' : ''}`}
          showAccordion={this.props.showAccordion}
        >
          <DetailRow
            label={I18n.t('inquiry.fraction')}
            value={get(() => fraction.name)}
          />
          <DetailRow
            label={I18n.t('inquiry.fineFraction')}
            value={
              fineFraction !== fraction
                ? get(() => fineFraction.name)
                : I18n.t('offerForm.noFineFraction')
            }
          />
          <DetailRow
            label={I18n.t('inquiry.container')}
            value={getContainerName(
              this.props.order.offer_object.container,
              this.props.containerList,
            )}
          />
        </DetailSection>

        <div
          className={`uk-accordion-content ${
            !this.props.showAccordion ? 'uk-hidden' : ''
          }`}
        >
          <DetailSection title={I18n.t('homepage.fractionSelectorDefault')}>
            <DetailRow
              label={I18n.t('inquiry.fraction')}
              value={get(() => fraction.name)}
            />
            <DetailRow
              label={I18n.t('inquiry.fineFraction')}
              value={
                fineFraction !== fraction
                  ? get(() => fineFraction.name)
                  : I18n.t('offerForm.noFineFraction')
              }
            />
            <DetailRow
              label={I18n.t('offerForm.avvNumber.label')}
              value={this.props.order.offer_object.avv_number}
            />
          </DetailSection>
          <br />
          <DetailSection title={I18n.t('inquiry.container')}>
            <DetailRow
              label={I18n.t('inquiry.container')}
              value={getContainerName(
                this.props.order.offer_object.container,
                this.props.containerList,
              )}
            />
            <DetailRow
              label={I18n.t('inquiry.numberOfContainers')}
              value={this.props.order.number_of_containers}
            />
            <DetailRow
              label={I18n.t('inquiry.withTop')}
              value={I18n.t(
                `general.${
                  this.props.order.offer_object.with_top ? 'yes' : 'no'
                }`,
              )}
            />
          </DetailSection>
        </div>
      </>
    )
  }
}

DetailsComponent.propTypes = {
  order: shape(OrderScheme).isRequired,
  fractionList: arrayOf(shape(FractionScheme)).isRequired,
  containerList: arrayOf(shape(ContainerScheme)).isRequired,
  showAccordion: bool.isRequired,
}

export default DetailsComponent
