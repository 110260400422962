import ActionTypes from '../actiontypes/workload'

export const initialState = {
  workloadList: [],
  pagination: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
}

/**
 * @description A reducer for workload.
 * @param {Object} state is the current globale state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
export const workloadReducer = (state = initialState, action) => {
  if (action.type === ActionTypes.GET_WORKLOADS_SUCCESS) {
    return {
      ...state,
      workloadList: action.payload.data,
      pagination: { loaded: true, ...action.payload.pagination },
    }
  }
  return state
}
