import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { FilesViewer } from '../../FilesViewer'
import { DetailsSection } from '../DetailsSection'

interface DocumentDetailsComponentProps {
  files: Attachment[]
}

export const DocumentDetails: FC<DocumentDetailsComponentProps> = ({
  files,
}) => (
  <DetailsSection
    title={
      files.length > 1
        ? I18n.t(
            'documentReviewPageTranslations.documentDetails.sectionTitle.plural',
          )
        : I18n.t(
            'documentReviewPageTranslations.documentDetails.sectionTitle.singular',
          )
    }
    noStyle
  >
    <FilesViewer files={files} allowRotate />
  </DetailsSection>
)
