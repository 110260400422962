export const cancelOfferFormTranslations = {
  button: 'Angebot stornieren',
  buttonCancelAgreement: 'Widerruf der Vereinbarung',
  info: 'Sind Sie sicher, dass Sie Ihr Angebot stornieren möchten? ',
  infoAgreement:
    'Sind Sie sicher, dass Sie diese Vereinbarung stornieren möchten? ',
  toastr: {
    success:
      'Dieses Angebot wurde erfolgreich storniert. Bei Bedarf können Sie nun ein neues Angebot abgeben',
  },
  makeNewOffer: 'Neues Angebot abgeben',
}
