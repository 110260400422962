import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import Media from 'react-media'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import IndicationMessage from 'components/common/IndicationMessage'
import { BREAKPOINT } from 'constants/design'

interface CompanyInfoPageWasteProducerIndicationMessageProps {
  blockUnblockCompanyAction?: () => void
  statusReason: string
}

export const CompanyInfoPageWasteProducerIndicationMessage: FC<
  CompanyInfoPageWasteProducerIndicationMessageProps
> = ({ blockUnblockCompanyAction = () => undefined, statusReason }) => {
  const unblockButton = (
    <div className='company-info-page__indication-messages__cta uk-width-1-1 uk-width-1-5@m'>
      <Button
        backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
        onClick={blockUnblockCompanyAction}
      >
        <Translate value='companyInfoPageIndicationMessage.buttonTextUnlock' />
      </Button>
    </div>
  )

  return (
    <div className='company-info-page__indication-messages'>
      <IndicationMessage
        className='warning'
        statusClass='warning'
        statusIconName='information'
        footerContent={
          <>
            <Media query={{ maxWidth: BREAKPOINT.MEDIUM - 1 }}>
              {unblockButton}
            </Media>
          </>
        }
      >
        <div className='uk-grid'>
          <div className='uk-width-1-1 uk-width-4-5@m'>
            <p className='company-info-page__indication-messages--bold uk-text-bold'>
              <Translate value='companyInfoPageIndicationMessage.title' />
            </p>
            <p>
              <Translate
                value='companyInfoPageIndicationMessage.message'
                reason={statusReason}
              />
            </p>
          </div>
          <Media query={{ minWidth: BREAKPOINT.MEDIUM }}>{unblockButton}</Media>
        </div>
      </IndicationMessage>
    </div>
  )
}
