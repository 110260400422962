export enum DividerColor {
  Black = 'black',
  Gray = 'gray',
  GrayishBrown = 'grayish-brown',
}

export enum DividerWidth {
  ExtraSmall = 'extra-small',
  Small = 'small',
}

export { Divider } from './Divider'
export { TextDivider } from './TextDivider'
