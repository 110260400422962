import { Form, Formik, FormikProps } from 'formik'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { updateCompany } from 'actions/company'
import { BUTTON_BACKGROUND_COLOR, BUTTON_TYPE } from 'components/common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { ApiValidationMessages } from 'components/common/Form/components/ApiValidationMessages'
import { ProgressButton } from 'components/common/ProgressButton'
import { Textarea } from 'components/common/Textarea'
import { COMPANY_STATUS } from 'components/company/constants'
import { BUSINESS_SEGMENT } from 'constants/app'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

interface BlockingModalFormProps {
  company: Company
  onCancel?: () => void
  onSuccess?: () => void
}

/**
 * @description This component renders and controls the BlockingModalForm
 * @param offer the agreement to be changed
 * @param onSuccess action to be executed when submit successes
 * @constructor
 */
export const BlockingModalForm: FC<BlockingModalFormProps> = ({
  company,
  onSuccess = () => undefined,
}) => {
  const dispatch = useDispatch()

  const error = useSelector(createErrorSelector(['UPDATE_COMPANY_REQUEST']))
  const isLoading = useSelector(
    createLoadingSelector(['UPDATE_COMPANY_REQUEST']),
  )

  type BlockingValues = {
    reason?: string
  }

  return (
    <Formik
      initialValues={{
        reason: '',
      }}
      validationSchema={() =>
        yup.object().shape({
          reason: yup
            .string()
            // eslint-disable-next-line no-template-curly-in-string
            .typeError('${value} ist nicht vom Typ ${type}'),
        })
      }
      validate={() => {
        const errors: {
          start_date?: string
          end_date?: string
          reason?: string
        } = {}

        return errors
      }}
      onSubmit={(values: BlockingValues) => {
        if (
          company.business_segment !== BUSINESS_SEGMENT.BUSINESS_TVP &&
          company.postal_address_object?.id === -1
        ) {
          delete company.postal_address_object
        }

        dispatch(
          updateCompany({
            ...company,
            status: COMPANY_STATUS.STATUS_INACTIVE_BY_EPD,
            status_reason: values.reason,
            isUpdatingCompanyStatus: true,
          }),
        )
        onSuccess()
      }}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        submitCount,
        touched,
        values,
      }: FormikProps<BlockingValues>) => {
        return (
          <Form
            className='uk-modal-body'
            noValidate // Disable browser validation
          >
            <ApiValidationMessages error={error} />
            <Textarea
              error={submitCount > 0 && touched.reason ? errors.reason : ''}
              label={I18n.t(
                'blockCompanyModalTranslations.blockCompanyForm.reason.label',
              )}
              placeholder={I18n.t(
                'blockCompanyModalTranslations.blockCompanyForm.reason.placeholder',
              )}
              maxLength={200}
              name='reason'
              onChange={handleChange}
              value={values.reason}
              showRemainingCharacters
            />
            <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
              <ProgressButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                dataTestId='change-agreement-period-form-submit'
                isDisabled={
                  (submitCount > 0 && !isValid) || (isSubmitting && isLoading)
                }
                isLoading={isSubmitting && isLoading}
                onClick={handleSubmit}
                type={BUTTON_TYPE.BUTTON}
              >
                <Translate value='blockCompanyModalTranslations.blockCompanyForm.submit' />
              </ProgressButton>
            </ButtonBar>
          </Form>
        )
      }}
    </Formik>
  )
}
