export const OFFER_REVIEW_STATUS = {
  STATUS_PENDING: 1,
  STATUS_ACCEPTED: 2,
  STATUS_REJECTED: 3,
}

export const OFFER_REVIEW_STATES = [
  {
    id: OFFER_REVIEW_STATUS.STATUS_PENDING,
    name: 'invoiceCheckPosition.status.pending',
  },
  {
    id: OFFER_REVIEW_STATUS.STATUS_ACCEPTED,
    name: 'invoiceCheckPosition.status.accepted',
  },
  {
    id: OFFER_REVIEW_STATUS.STATUS_REJECTED,
    name: 'invoiceCheckPosition.status.rejected',
  },
]

export default {
  ...OFFER_REVIEW_STATUS,
  ...OFFER_REVIEW_STATES,
}
