import { connect } from 'react-redux'

import { setPassword } from 'actions/user'
import { createLoadingSelector } from 'selectors/loading'

const loadingSelector = createLoadingSelector(['SET_PASSWORD'])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  isLoading: loadingSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  setPassword: (uid, token, password, callback) =>
    dispatch(setPassword(uid, token, password, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)
