import { useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC, useContext } from 'react'
import { I18n } from 'react-i18nify'

import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { FormStepSummaryRow } from 'components/common/FormSteps'
import { getShop } from 'components/dsz/helpers'
import { DATE_FORMATS } from 'constants/app'

import {
  DSZAddInvoiceFormContext,
  DSZAddInvoiceFormValues,
} from '../DSZAddInvoiceFormSteps'

export const AssignedContractSummary: FC = () => {
  const { values } = useFormikContext<DSZAddInvoiceFormValues>()
  const { editStep, isInvoice } = useContext(DSZAddInvoiceFormContext)

  const shop = getShop(values.assigned_contract?.shop)

  return (
    <div className='maklerpremium-step-summary'>
      <div className='maklerpremium-step-summary_grid-body'>
        <FormStepSummaryRow
          label={I18n.t(
            'addInvoicePageTranslations.dsz.steps.2.fields.assignable_to_contract.summary_label',
          )}
          value={
            values.assigned_contract
              ? I18n.t('general.yes')
              : I18n.t('general.no')
          }
        />
        {values.assigned_contract && (
          <>
            <FormStepSummaryRow
              label={I18n.t(
                'addInvoicePageTranslations.dsz.steps.2.fields.assigned_contract_number.label',
              )}
              value={`${values.assigned_contract.id}`}
            />
            <FormStepSummaryRow
              label={I18n.t(
                'addInvoicePageTranslations.dsz.steps.2.fields.assigned_contract_shop.label',
              )}
              value={`${I18n.t(shop!.translationKey) ?? 'k.A'}`}
            />
            <FormStepSummaryRow
              label={I18n.t(
                'addInvoicePageTranslations.dsz.steps.2.fields.assigned_contract_created_at.label',
              )}
              value={`${moment(
                values.assigned_contract.created_at,
                DATE_FORMATS,
              ).format('L')}`}
            />
          </>
        )}
        <FormStepSummaryRow
          label={I18n.t(
            'addInvoicePageTranslations.dsz.steps.2.fields.licensing_year.label',
          )}
          value={values.licensing_year}
        />
        <FormStepSummaryRow
          label={I18n.t(
            'addInvoicePageTranslations.dsz.steps.2.fields.invoice_or_credit_note.summary_label',
          )}
          value={
            isInvoice
              ? I18n.t(
                  'addInvoicePageTranslations.dsz.steps.2.fields.invoice_or_credit_note.invoice',
                )
              : I18n.t(
                  'addInvoicePageTranslations.dsz.steps.2.fields.invoice_or_credit_note.credit_note',
                )
          }
        />
        {!isInvoice && values.iban && (
          <FormStepSummaryRow
            label={I18n.t(
              'addInvoicePageTranslations.dsz.steps.2.fields.iban.placeholder',
            )}
            value={`${values.iban}`}
          />
        )}
        <FormStepSummaryRow
          label={I18n.t(
            'addInvoicePageTranslations.dsz.steps.2.fields.assigned_vat.summaryLabel',
          )}
          value={`${parseFloat(`${values.assigned_vat}`).toLocaleString(
            'de-DE',
            { minimumFractionDigits: 0 },
          )} %`}
        />
      </div>
      <div className='sidebar'>
        <ButtonLink
          className='sidebar__edit-link'
          linkColor={BUTTON_LINK_COLOR.PRIMARY}
          onClick={editStep}
        >
          {I18n.t('createInquiryTranslations.form.button.editStep')}
        </ButtonLink>
      </div>
    </div>
  )
}
