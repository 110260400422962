import ActionTypes from '../actiontypes/emptodownloads'

export const itemInitialState = {
  id: null,
  file: null,
  file_class: null,
  lastmodified_at: null,
}

export const initialState = {
  itemList: [],
}

/**
 * @description A reducer for empto downloads.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const emptoDownloadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_EMPTO_DOWNLOADS_SUCCESS:
      return {
        ...state,
        itemList: action.payload,
      }
    default:
      return state
  }
}

export default emptoDownloadsReducer
