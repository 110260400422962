import { connect } from 'react-redux'

import { getLandingPageWasteManagerContent } from 'actions/landingpage'
import { createErrorSelector } from 'selectors/error'

const actions = ['GET_LANDING_PAGE_CONTENT']
const errorSelector = createErrorSelector(actions)

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  landingPageContent: state.landingPage.wastemanagerLandingPage.itemList,
  error: errorSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getLandingPageContent: () => {
    dispatch(getLandingPageWasteManagerContent())
  },
})

export const connector = connect(mapStateToProps, mapDispatchToProps)
