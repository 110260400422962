import { connect } from 'react-redux'

import { getMyCertificates } from 'actions/certificate'
import {
  getCertificatePaginationSelector,
  getMyCertificatesSelector,
} from 'selectors/certificate'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'

const loadingMyCertificatesSelector = createLoadingSelector([
  'GET_MY_CERTIFICATES',
])
const errorSelector = createErrorSelector(['GET_MY_CERTIFICATES'])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  user: getCurrentUserSelector(state),
  certificates: getMyCertificatesSelector(state),
  pagination: getCertificatePaginationSelector(state),
  isLoading: loadingMyCertificatesSelector(state),
  error: errorSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getMyCertificates: (page, filters, sortedBy) =>
    dispatch(getMyCertificates(page, filters, sortedBy)),
})

export default connect(mapStateToProps, mapDispatchToProps)
