import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getCompany } from 'actions/company'
import { getCompanySelector } from 'selectors/company'

export const useCompany = ({ companyId, slug }) => {
  const company = useSelector(getCompanySelector)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!companyId) {
      return
    }

    if (!company || company.id !== Number(companyId)) {
      dispatch(
        getCompany({
          id: Number(companyId),
          slug,
        }),
      )
    }
  }, [company, companyId, dispatch, slug])
}
