import React, { FC, useEffect } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getFrameworkContractPositionsCount } from 'actions/maklerpremium'
import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { LinkButton } from 'components/common/LinkButton'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { COMPANY_ROLE } from 'components/company/constants'
import { getFrameworkContractPositionsCountSelector } from 'selectors/maklerpremium'

/**
 * @description This component renders the agreements and orders section of the extended company info page
 */
export const AgreementsAndOrders: FC<{
  agreementsCount: number
  company: Company
  ordersCount: number
}> = ({ agreementsCount, company, ordersCount }) => {
  const dispatch = useDispatch()
  const frameworkContractPositionsCount = useSelector(
    getFrameworkContractPositionsCountSelector,
  )
  const company_role_number = Number(company.role)

  useEffect(() => {
    if (company_role_number === COMPANY_ROLE.WASTE_PRODUCER) {
      dispatch(getFrameworkContractPositionsCount(company.id))
    }
  }, [dispatch, company, company_role_number])

  const frameworkContractsCount = () => {
    switch (company_role_number) {
      case COMPANY_ROLE.WASTE_PRODUCER:
        return (
          <div className={'uk-width-1-1@s uk-width-1-3@m'}>
            <ReadOnlyField
              label={I18n.t('agreementsAndOrders.frameworkPositions')}
              value={frameworkContractPositionsCount}
            />
            <LinkButton
              target={{
                pathname: '',
              }}
              backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
              fullWidth
            >
              <Translate value='agreementsAndOrders.buttonFrameworks' />
            </LinkButton>
          </div>
        )
      case COMPANY_ROLE.WASTE_COMPANY:
        return (
          <div className={'uk-width-1-1@s uk-width-1-3@m'}>
            <ReadOnlyField
              label={I18n.t('agreementsAndOrders.offerJobs')}
              value={0}
            />
            <LinkButton
              target={{
                pathname: '',
              }}
              backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
              fullWidth
            >
              <Translate value='agreementsAndOrders.buttonOfferJobs' />
            </LinkButton>
          </div>
        )
      default:
        return
    }
  }

  return (
    <div className='agreements-orders agreement-orders uk-grid' data-uk-grid=''>
      <div className='uk-width-1-1 uk-text-break'>
        <div className='uk-grid' data-uk-grid=''>
          <div className={'uk-width-1-1@s uk-width-1-3@m'}>
            <ReadOnlyField
              label={I18n.t('agreementsAndOrders.allAgreements')}
              value={agreementsCount}
            />
            <LinkButton
              target={{
                pathname: '/agreement',
                state: { company: company.id, role: company.role },
              }}
              backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
              fullWidth
            >
              <Translate value='agreementsAndOrders.buttonAgreements' />
            </LinkButton>
          </div>
          <div className={'uk-width-1-1@s uk-width-1-3@m'}>
            <ReadOnlyField
              label={I18n.t('agreementsAndOrders.allOrders')}
              value={ordersCount}
            />
            <LinkButton
              target={{
                pathname: '/order',
                state: {
                  company: company.id,
                  role: company.role,
                  archive: false,
                },
              }}
              backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
              fullWidth
            >
              <Translate value='agreementsAndOrders.buttonOrders' />
            </LinkButton>
          </div>
          {frameworkContractsCount()}
        </div>
      </div>
    </div>
  )
}
