import { connect } from 'react-redux'

import { getIndexes } from 'actions/indexes'
import { getIndexesSelector } from 'selectors/indexes'

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  indexList: getIndexesSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getIndexes: () => dispatch(getIndexes()),
})

export const connector = connect(mapStateToProps, mapDispatchToProps)
