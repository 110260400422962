import { FormikErrors, FormikTouched } from 'formik'
import React from 'react'

export interface FormPropsType {
  dirty: boolean
  errors: FormikErrors<any>
  handleBlur: (e: any) => void
  handleChange: (e: React.ChangeEvent<any>) => void
  handleSubmit: (e?: any) => void
  isLoading?: boolean
  isSubmitting: boolean
  isValid: boolean
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => void
  submitCount: number
  touched: FormikTouched<any>
  values: any
}

export interface CallToActionType {
  buttonsOrFields: React.ReactNode
  content?: React.ReactNode
  header?: React.ReactNode
  hideMoreInfo?: boolean
}

export enum PageType {
  NONE,
  AGREEMENT,
  CREATE_OFFER,
  OFFER,
  INQUIRY_MINE,
  INQUIRY_OFFER,
}
