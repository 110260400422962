/**
 * @description selector to get a discount.
 * @param state
 * @return {*}
 */
export const getDiscountSelector = state => state.discount.item

/**
 * @description selector to get discount list.
 * @param state
 * @return {*}
 */
export const getDiscountsSelector = state => state.discount.itemList

/**
 * @description selector to get a discount list pagination.
 * @param state
 * @return {*}
 */
export const getDiscountsPaginationSelector = state => state.discount.pagination

export default {}
