import moment from 'moment'
import React from 'react'
import { I18n } from 'react-i18nify'
import { shape } from 'prop-types'

import DetailRow from 'components/common/DetailSwitcher/components/DetailRow'
import DetailSection from 'components/common/DetailSwitcher/components/DetailSection'
import {
  INQUIRY_ORDER_TYPE,
  INQUIRY_ORDER_TYPES,
} from 'components/inquiry/constants'
import { UserPermission } from 'constants/user'
import { getDateInfo, localizeDate } from 'helper/general'
import {
  getActualOrderCollectionDate,
  getActualOrderDeliveryDate,
  getActualOrderTimeOfDayDelivery,
} from 'helper/order'
import { getName } from 'helper/translations'
import { OrderScheme } from 'schemes/order'

import { InternalCommentTextArea } from './components/InternalComment'

export const OrderComponent = ({ order }) => (
  <>
    <DetailSection className='uk-padding' title={I18n.t('order.title')}>
      <DetailRow
        label={I18n.t('inquiry.orderType')}
        value={I18n.t(
          getName(order.offer_object.order_type, INQUIRY_ORDER_TYPES),
        )}
      />
      <DetailRow label={I18n.t('order.number')} value={order.id} />
      <DetailRow
        label={I18n.t('orderDetails.orderDate')}
        value={moment(order.created_at).format('L LT')}
      />

      {order.offer_object.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME && (
        <DetailRow
          label={I18n.t('orderDetails.deliveryDate')}
          value={`${localizeDate(getActualOrderDeliveryDate(order))}
                / ${localizeDate(getActualOrderCollectionDate(order))}`}
        />
      )}

      {order.offer_object.order_type !== INQUIRY_ORDER_TYPE.TYPE_ONE_TIME && (
        <DetailRow
          label={I18n.t('orderDetails.deliveryDate')}
          value={getDateInfo(
            getActualOrderDeliveryDate(order),
            getActualOrderTimeOfDayDelivery(order),
          )}
        />
      )}

      <DetailRow
        label={I18n.t('general.createdBy')}
        value={`${order.created_by_object.first_name} ${order.created_by_object.last_name}`}
      />

      <DetailRow
        label={I18n.t('inquiry.withEstimatedTimeOfArrival')}
        value={I18n.t(
          `general.${
            order.offer_object.with_estimated_time_of_arrival ? 'yes' : 'no'
          }`,
        )}
      />

      <DetailRow
        label={I18n.t('inquiry.positionOnPublicGround.short')}
        value={I18n.t(
          `general.${
            order.offer_object.position_on_public_ground ? 'yes' : 'no'
          }`,
        )}
      />

      {order.offer_object.special_note && (
        <DetailRow
          label={I18n.t('inquiryForm.placeholder.specialNote')}
          value={order.offer_object.special_note}
        />
      )}

      <DetailRow
        label={I18n.t('acceptedOffers.number')}
        value={order.offer}
        path={`/agreement/${order.offer}`}
      />
      <InternalCommentTextArea
        requiresPermissions={[UserPermission.SET_INTERNAL_COMMENT]}
        order={order}
        label={I18n.t('orderDetails.internalComment')}
        value={order.internal_comment}
        buttonValue={I18n.t('general.button.save')}
      />
    </DetailSection>
  </>
)

OrderComponent.propTypes = {
  order: shape(OrderScheme).isRequired,
}

export default OrderComponent
