export const InquiryCardTranslations = {
  newInquiryCard: {
    status: {
      title: 'Status der Anfrage',
    },
    stepIndicator: {
      stepOneTitle: 'Anfrage erstellt',
      stepTwoTitle: 'Angebote erhalten',
      stepThreeTitleOpen: 'Angebot annehmen',
      stepThreeTitleDone: 'Vereinbarung geschlossen',
    },
    details: {
      headline: '<strong>Anfrage %{id}</strong> vom %{date}',
      container: '%{capacity} %{name} (Anzahl:&nbsp;%{amount})',
      containerCubicMeters: '%{capacity} %{name} (Menge:&nbsp;%{amount})',
      executionIsAt: 'Ausführung am %{date}',
      executionStartsAt: 'Beginnt am %{date}',
      callToAction: 'Details',
    },
  },
}

export default {}
