import classNames from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { useDispatch, useSelector } from 'react-redux'
import AsyncSelect from 'react-select/async'

import { getAvvs } from 'actions/inquiry'
import { getAvvsSelector } from 'selectors/inquiry'

import { ApiValidationMessages } from '../../common/Form/components/ApiValidationMessages'
import { AsyncMultiselectComboboxProps } from '../AsyncMultiselectCombobox/AsyncMultiselectCombobox'
import { callbackOrType } from '../Input'
import { InputCheckmark } from '../InputCheckmark'
import { Option } from '../StaticCombobox/StaticCombobox'

interface FindAvvByFilterInputProps
  extends Omit<
    Partial<AsyncMultiselectComboboxProps>,
    'error' | 'value' | 'options'
  > {
  additionalFilters?: { [key: string]: React.ReactText }
  error?: callbackOrType<string>
  handleSelectionChange: (value: any, rawValue: Avv[]) => void
  mapOptionLabel: (avvs: Avv[]) => Option[]
  minCharacters?: number
  maxCharacters?: number
  name: string
  noOptionsMessage: string
  placeholder: string
  value?: string
  withCheckmark?: boolean
  options?: Option[]
  noResultsText?: string
  isClearable?: boolean
}

export const FindAvvByFilterInput: FC<FindAvvByFilterInputProps> = ({
  error = '',
  handleSelectionChange,
  mapOptionLabel,
  minCharacters = 5,
  maxCharacters = 6,
  name,
  noOptionsMessage,
  options: initialOptions,
  placeholder,
  value,
  withCheckmark = false,
  showCheckmark = false,
  label = '',
  isClearable = false,
}) => {
  const dispatch = useDispatch()
  const avvs = useSelector(getAvvsSelector)

  const [options, setOptions] = useState<Option[]>(initialOptions ?? [])
  const [inputValue, setInputValue] = useState<string>('')

  const parseInputValue = value => {
    // Remove spaces and non-numeric values
    let parsedValue = value.replaceAll(' ', '')
    parsedValue = parsedValue.replaceAll(/\D/g, '')
    return parsedValue
  }

  const errorValue = typeof error === 'string' ? error : error(name)
  const defaultValue = options
    ? options.find(option => option.value === value)
    : { label: value, value }
  const filterOptions = (inputValue: string) => {
    return options.filter(i =>
      (i.value as string).replace(/\W/g, '').includes(inputValue),
    )
  }
  const handleLoadOptions = (inputValue: string, callback) => {
    // handle function for loading available options
    const parsedValue = parseInputValue(inputValue)
    if (parsedValue.length >= minCharacters) {
      callback(filterOptions(parsedValue.slice(0, maxCharacters)))
    } else callback([])
  }
  const handleOnInputChange = (newValue, actionMeta) => {
    // handle function for input change
    if (actionMeta.action === 'input-change') {
      const parsedValue = parseInputValue(newValue)
      // If parsedValues length is below max maxCharacters use it, otherwise use the previous value
      if (parsedValue.length <= maxCharacters) {
        setInputValue(parsedValue)
      } else {
        setInputValue(actionMeta.prevInputValue)
      }
    } else {
      // just pass the value through for any other action than "input-change"
      setInputValue(newValue)
    }
  }
  const handleOnChange = option => {
    // handle function for selected option change
    handleSelectionChange(option?.value ? option!.value : undefined, avvs)
    setInputValue('') // Clear input value to be able to display selected option
  }

  useEffect(() => {
    if (avvs) {
      setOptions(mapOptionLabel(avvs))
    }
  }, [avvs, mapOptionLabel])

  useEffect(() => {
    dispatch(getAvvs())
  }, [dispatch])

  return (
    <>
      <Row middle='xs'>
        <Col
          xs={12}
          sm={12}
          className='combobox__label-container label-col label-col-top'
        >
          <div className='combobox__label-container__label'>{label}</div>
        </Col>
        <Col xs={12} sm={12}>
          <div
            className={classNames({
              'combobox--with-checkmark': withCheckmark,
            })}
          >
            <div className='combobox__input-container'>
              <AsyncSelect
                className='react-select-container'
                classNamePrefix='react-select'
                defaultValue={defaultValue}
                loadOptions={handleLoadOptions}
                onInputChange={handleOnInputChange}
                inputValue={inputValue}
                noOptionsMessage={() => noOptionsMessage}
                onChange={handleOnChange}
                placeholder={placeholder}
                isClearable={isClearable}
              />
              {withCheckmark && <InputCheckmark isHidden={!showCheckmark} />}
            </div>
          </div>
        </Col>
      </Row>
      {errorValue && (
        <ApiValidationMessages
          error={{ response: { data: errorValue }, message: '' }}
        />
      )}
    </>
  )
}
