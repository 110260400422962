import { MyAutoOffersPage } from '../components/offer/MyAutoOffersPage'

/**
 * @constant
 * @description A constant to define auto offer routes.
 */
export const autoOfferRoute = [
  {
    path: '/auto-offer',
    component: MyAutoOffersPage,
    exact: true,
  },
  {
    path: '/auto-offer/archive',
    component: MyAutoOffersPage,
    exact: true,
  },
]

export default {}
