import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getContainers } from 'actions/container'
import { getFractions } from 'actions/fraction'
import { getIndexes } from 'actions/indexes'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'
import { getIndexesSelector } from 'selectors/indexes'
import { getLoggedInSelector } from 'selectors/user'

/**
 * Loads some common data used by most components as early is possible in the app locading process
 * 1. Containers(List)
 * 2. Fractions(List)
 * 3. Indexes(List)
 *
 * @param children: ReactNode
 * @constructor
 */
export const BaseDataLoader: FC<any> = ({ children }) => {
  const isLoggedIn = !!useSelector(getLoggedInSelector)

  const containerList = useSelector<any, Container[]>(getContainersSelector)
  const fractionList = useSelector<any, Fraction[]>(getFractionsSelector)
  const indexesList = useSelector<any, OfferIndex[]>(getIndexesSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (isLoggedIn && containerList.length === 0) dispatch(getContainers())
  }, [containerList, dispatch, isLoggedIn])

  useEffect(() => {
    if (isLoggedIn && fractionList.length === 0) dispatch(getFractions())
  }, [dispatch, fractionList, isLoggedIn])

  useEffect(() => {
    if (isLoggedIn && indexesList.length === 0) dispatch(getIndexes())
  }, [dispatch, indexesList, isLoggedIn])

  return children
}
