import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch } from 'react-redux'

import { archiveCertificate } from 'actions/certificate'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { ProgressButton } from 'components/common/ProgressButton'

/**
 * @description This component displays the modal window for archiving a certificate
 */
export const ArchiveModal: FC<{
  certificate?: Certificate
  onClose: (...args) => void
}> = ({ certificate, onClose }) => {
  const modalId = uniqueId()
  const dispatch = useDispatch()

  const handleOnClick = () => {
    dispatch(archiveCertificate(certificate)) // Archive selected certificate
    onClose()
  }

  return (
    <Modal isOpen={!!certificate} onClose={onClose} ariaDescribedBy={modalId}>
      <ModalHeader
        onClose={onClose}
        title={I18n.t('archiveModalTranslations.modalTitle')}
        titleId={modalId}
      />
      <div className='uk-padding'>
        {/* DESCRIPTION */}
        <Translate value='archiveModalTranslations.description' />
      </div>
      <div className='uk-modal-footer uk-text-right'>
        {/* CANCEL */}
        <span className='uk-margin-right'>
          <Button
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            onClick={onClose}
          >
            <Translate value='general.button.cancel' />
          </Button>
        </span>

        {/* SUBMIT */}
        <ProgressButton
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          onClick={handleOnClick}
          type={BUTTON_TYPE.SUBMIT}
          dataTestId='export-submit-button'
        >
          <Translate value='archiveModalTranslations.buttonArchive' />
        </ProgressButton>
      </div>
    </Modal>
  )
}
