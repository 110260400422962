import { Form, Formik, FormikProps } from 'formik'
import moment from 'moment'
import React, { FC, ReactText, useEffect, useMemo, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'

import { getPriceComparisonTable } from 'actions/maklerpremium'
import { changeDisposerOffer } from 'actions/offer'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import InputDate from 'components/common/InputDate'
import { InputText } from 'components/common/InputText'
import { ProgressButton } from 'components/common/ProgressButton'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { localizeDate, localizeDateForBackend } from 'helper/general'
import { createLoadingSelector } from 'selectors/loading'
import { getPriceComparisonTableSelector } from 'selectors/pricecalc'
import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'

interface ChangeDisposerFormProps {
  onCancel: () => void
  offer: Offer
  containerList: Container[]
}

export interface ChangeDisposerFormValues {
  auto_offer: ReactText
  price: ReactText
  margin: ReactText
  new_start_date?: ReactText
  new_end_date?: ReactText
}

/**
 * @description This component renders and controls a form component to change disposer,
 * selected from create offer step two
 */
export const ChangeDisposerForm: FC<ChangeDisposerFormProps> = ({
  onCancel,
  offer,
  containerList,
}) => {
  const dispatch = useDispatch()
  const priceComparisonTable = useSelector(getPriceComparisonTableSelector)
  const container = containerList.find(
    _container => _container.id === offer.container,
  )
  const isLoading = {
    priceComparisonTable: useSelector(
      createLoadingSelector(['GET_PRICE_COMPARISON_TABLE']),
    ),
  }
  const priceCalcStatus = useSelector(
    createRequestStatusSelector(['GET_PRICE_COMPARISON_TABLE']),
  )

  const [autoOfferOptions, setAutoOfferOptions] = useState([])

  useEffect(() => {
    if (
      container &&
      !isLoading.priceComparisonTable &&
      priceComparisonTable.length < 1 &&
      priceCalcStatus !== REQUEST_STATUS.SUCCESS
    ) {
      const valuesToSend = {
        capacity: container.capacity,
        containerType: container.name,
        fractionId: offer.fine_fraction ? offer.fine_fraction : offer.fraction,
        positionOnPublicGround: offer.position_on_public_ground,
        security_group: offer.security_group,
        zipcode: offer.collection_address_object.zipcode,
      }

      dispatch(getPriceComparisonTable({ ...valuesToSend }))
    }
  })

  useEffect(() => {
    const options: any = []
    if (priceComparisonTable.length === 0) return

    priceComparisonTable.forEach(item => {
      const optionItem = item.container_prices[container!.capacity]

      const marginString = Math.abs(Number(optionItem.margin)).toLocaleString(
        'de-DE',
        { minimumFractionDigits: 2 },
      )

      const priceString = optionItem.price?.toLocaleString('de-DE', {
        minimumFractionDigits: 2,
      })

      options.push({
        value: `${optionItem.auto_offer_id},${optionItem.price},${optionItem.margin}`,
        label: `${item.name} (${item.empto_external_number}) - Marge ${marginString} %,
    Einkaufspreis (Brutto) ${priceString} €`,
      })
    })
    setAutoOfferOptions(options)
  }, [container, priceComparisonTable])

  const handleChangeStartDate = (
    value,
    values,
    setFieldValue,
    setFieldTouched,
  ) => {
    setFieldTouched('new_start_date', true)
    setFieldValue('new_start_date', localizeDate(value))
    if (moment(value, 'L') > moment(values.new_end_date, 'L')) {
      setFieldTouched('new_end_date', true)
      setFieldValue('new_end_date', '')
    }
  }

  const handleChangeEndDate = (
    value,
    values,
    setFieldValue,
    setFieldTouched,
  ) => {
    setFieldTouched('new_end_date', true)
    setFieldValue('new_end_date', localizeDate(value))
    if (moment(value, 'L') < moment(values.new_start_date, 'L')) {
      setFieldTouched('new_start_date', true)
      setFieldValue('new_start_date', '')
    }
  }

  const isAgreementInFuture = useMemo(
    () => !moment(offer.service_start_date).isBefore(),
    [offer.service_start_date],
  )

  return (
    <>
      {isLoading.priceComparisonTable && priceComparisonTable.length < 1 ? (
        <div className='uk-flex uk-flex-center uk-margin-large-top'>
          <Spinner
            fadeIn='none' // Show immediately
            name='circle'
            color='grey'
          />
        </div>
      ) : (
        <Formik
          initialValues={{
            auto_offer: '',
            price: '',
            margin: '',
            new_start_date: localizeDate(offer.service_start_date),
            new_end_date: localizeDate(offer.service_end_date),
          }}
          initialErrors={{
            auto_offer: I18n.t('message.validation.selectionRequired'),
            price: I18n.t('message.validation.selectionRequired'),
            margin: I18n.t('message.validation.selectionRequired'),
            new_start_date: isAgreementInFuture
              ? undefined
              : I18n.t('message.validation.selectionRequired'),
            new_end_date: isAgreementInFuture
              ? undefined
              : I18n.t('message.validation.selectionRequired'),
          }}
          onSubmit={(values: ChangeDisposerFormValues) => {
            dispatch(
              changeDisposerOffer(
                offer.id,
                values.auto_offer,
                localizeDateForBackend(values.new_start_date),
                localizeDateForBackend(values.new_end_date),
              ),
            )
            onCancel()
          }}
          validate={(values: ChangeDisposerFormValues) => {
            const errors: {
              auto_offer?: string
              new_start_date?: string
              new_end_date?: string
            } = {}
            if (!values.auto_offer) {
              errors.auto_offer = I18n.t('message.validation.selectionRequired')
            }

            if (moment(offer.service_start_date).isBefore(undefined, 'day')) {
              if (!values.new_start_date) {
                errors.new_start_date = I18n.t(
                  'message.validation.selectionRequired',
                )
              }
              if (!values.new_end_date) {
                errors.new_end_date = I18n.t(
                  'message.validation.selectionRequired',
                )
              }
              if (
                moment(values.new_start_date, 'L').isBefore(undefined, 'day')
              ) {
                errors.new_start_date = I18n.t(
                  'changeDisposerFormTranslations.new_start_date.error.inPast',
                )
              }
              if (moment(values.new_end_date, 'L').isBefore(undefined, 'day')) {
                errors.new_end_date = I18n.t(
                  'changeDisposerFormTranslations.new_end_date.error.inPast',
                )
              }
              if (
                moment(values.new_start_date, 'L').isAfter(
                  moment(values.new_end_date, 'L'),
                )
              ) {
                errors.new_start_date = I18n.t(
                  'changeDisposerFormTranslations.new_start_date.error.afterEnd',
                )
                errors.new_start_date = I18n.t(
                  'changeDisposerFormTranslations.new_start_date.error.afterEnd',
                )
              }
            }

            return errors
          }}
        >
          {({
            setValues,
            setFieldValue,
            setFieldTouched,
            handleSubmit,
            touched,
            errors,
            values,
            isValid,
            isSubmitting,
          }: FormikProps<ChangeDisposerFormValues>) => (
            <>
              <Form
                className='convert-inquiry-form'
                data-testid='convert-inquiry-form'
                onSubmit={handleSubmit}
                noValidate
              >
                <div className='uk-modal-body'>
                  <StaticCombobox
                    error={touched.auto_offer ? errors.auto_offer : ''}
                    isRequired={!values.auto_offer}
                    dataTestId='convert-inquiry-form-auto-offer'
                    label={I18n.t(
                      'changeDisposerFormTranslations.autoOffer.label',
                    )}
                    isLoading={isLoading.priceComparisonTable}
                    name='auto_offer'
                    noResultsText={I18n.t(
                      'changeDisposerFormTranslations.noInputResults.noDisposer',
                    )}
                    options={autoOfferOptions}
                    onSelectionChange={e => {
                      const [auto_offer, price, margin] =
                        e.target.value.split(',')
                      setValues(
                        {
                          auto_offer,
                          price: `${Number(price).toLocaleString('de-DE', {
                            minimumFractionDigits: 2,
                          })} €`,
                          margin: `${Math.abs(Number(margin)).toLocaleString(
                            'de-DE',
                            { minimumFractionDigits: 2 },
                          )} %`,
                          new_start_date: values.new_start_date,
                          new_end_date: values.new_end_date,
                        },
                        // do not trigger validation if dates need to be filled first as that would clear those fields
                        isAgreementInFuture ||
                          touched.new_start_date ||
                          touched.new_end_date,
                      )
                    }}
                    placeholder={I18n.t(
                      'changeDisposerFormTranslations.autoOffer.placeholder',
                    )}
                    showCheckmark={!!values.auto_offer && !errors.auto_offer}
                    withCheckmark
                  />

                  <InputText
                    dataTestId='change-disposer-form-endprice'
                    dataTestIdError='form-group-error'
                    error={touched.price ? errors.price : ''}
                    label={I18n.t('changeDisposerFormTranslations.price.label')}
                    name='price'
                    onBlur={() => undefined}
                    onChange={() => undefined}
                    value={values.price}
                    disabled
                  />
                  <InputText
                    dataTestId='change-disposer-form-margin'
                    dataTestIdError='form-group-error'
                    error={touched.margin ? errors.margin : ''}
                    label={I18n.t(
                      'changeDisposerFormTranslations.margin.label',
                    )}
                    name='margin'
                    onBlur={() => undefined}
                    onChange={() => undefined}
                    value={values.margin}
                    disabled
                  />
                  {moment(offer.service_start_date).isBefore() && (
                    <>
                      <InputDate
                        error={
                          touched.new_start_date ? errors.new_start_date : ''
                        }
                        label={I18n.t(
                          'changeDisposerFormTranslations.new_start_date.label',
                        )}
                        minDate={moment()}
                        name='new_start_date'
                        onChange={value => {
                          handleChangeStartDate(
                            value,
                            values,
                            setFieldValue,
                            setFieldTouched,
                          )
                        }}
                        value={values.new_start_date}
                        validateOnMount={false}
                        showCheckmark={
                          !!values.new_start_date && !errors.new_start_date
                        }
                        withCheckmark
                      />
                      <InputDate
                        error={touched.new_end_date ? errors.new_end_date : ''}
                        label={I18n.t(
                          'changeDisposerFormTranslations.new_end_date.label',
                        )}
                        minDate={moment()}
                        name='new_end_date'
                        onChange={value => {
                          handleChangeEndDate(
                            value,
                            values,
                            setFieldValue,
                            setFieldTouched,
                          )
                        }}
                        value={values.new_end_date}
                        validateOnMount={false}
                        showCheckmark={
                          !!values.new_start_date && !errors.new_start_date
                        }
                        withCheckmark
                      />
                    </>
                  )}
                </div>
                <div className='uk-modal-footer uk-text-right'>
                  <span className='uk-margin-right'>
                    <Button
                      backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                      dataTestId='convert-inquiry-form-close'
                      onClick={onCancel}
                    >
                      <Translate value='general.button.cancel' />
                    </Button>
                  </span>
                  <ProgressButton
                    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                    dataTestId='convert-inquiry-form-submit'
                    isDisabled={
                      !isValid ||
                      (isSubmitting && isLoading.priceComparisonTable)
                    }
                    isLoading={isSubmitting && isLoading.priceComparisonTable}
                    onClick={handleSubmit}
                    type={BUTTON_TYPE.SUBMIT}
                  >
                    <Translate value='changeDisposerFormTranslations.buttons.submit' />
                  </ProgressButton>
                </div>
              </Form>
            </>
          )}
        </Formik>
      )}
    </>
  )
}
