import classNames from 'classnames'
import { node, string } from 'prop-types'
import React, { Component } from 'react'

import Icon from 'components/common/Fontello'
import { humanDateTime } from 'helper/general'

export const ICON_TYPE = {
  CIRCLE: 'circle',
  CROSS: 'cross',
  CHECKMARK: 'checkmark',
}

class ItemInfo extends Component {
  static propTypes = {
    createdByName: string,
    creationDate: string,
    message: string,
    icon: node,
    iconType: string,
  }

  static defaultProps = {
    createdByName: '',
    creationDate: '',
    message: '',
    icon: <Icon name='checkmark' />,
    iconType: ICON_TYPE.CHECKMARK,
  }

  render() {
    const { createdByName, creationDate, message, icon, iconType } = this.props

    return (
      <div>
        <div
          className={classNames(
            'order-status-list-item-circle',
            { 'order-status-list-item-cross': iconType === ICON_TYPE.CROSS },
            {
              'order-status-list-item-checkmark':
                iconType === ICON_TYPE.CHECKMARK,
            },
          )}
        >
          {icon}
        </div>
        <p
          className='order-status-list-item-message'
          // This is our only way to inject translated html. But we control the translation and it is static.
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: message }}
        />
        <p className='order-status-list-item-date uk-text-meta'>
          {humanDateTime(creationDate)} - {createdByName}
        </p>
      </div>
    )
  }
}

export default ItemInfo
