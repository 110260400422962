import * as Sentry from '@sentry/react'
import React from 'react'

import { DefaultErrorComponent as DefaultError } from './defaultError'

/**
 * Higher-order function to add an Error Boundary to a component.
 *
 * @param {*} WrappedComponent Wrapped component which is shown if no error occurs.
 *
 * @returns {*} ErrorComponent if an error occurred, otherwise the WrappedComponent.
 */

const withDefaults = WrappedComponent =>
  Sentry.withErrorBoundary(WrappedComponent, {
    fallback: ({ error }) => <DefaultError errorMessage={error.message} />,
  })

export default withDefaults
