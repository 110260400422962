import { I18n } from 'react-i18nify'

/**
 * Each security group is a valid combination of a protection class and a security level. Protection classes are groups
 * of security levels. This function returns a unique list of protection classes.
 * @param securityGroups
 * @returns {(number)[]}
 */
export const getProtectionClasses = (securityGroups = []) => {
  const protectionClassesArray = securityGroups.map(
    group => group.protection_class,
  )
  return [...new Set(protectionClassesArray)]
}

/**
 * Returns the security levels that belong to one protection class.
 * @param protectionClass
 * @param securityGroups
 * @returns {(null|number)[]}
 */
export const getSecurityLevelsInProtectionClass = (
  protectionClass,
  securityGroups = [],
) =>
  securityGroups
    .filter(group => group.protection_class === protectionClass)
    .map(group => group.security_level)

/**
 * Returns the whole security group of a given Protection Class and Security Level.
 * @param protectionClass
 * @param securityLevel
 * @param securityGroups
 * @returns {Object}
 */
export const getSecurityGroupFromLevelAndClass = (
  protectionClass,
  securityLevel,
  securityGroups = [],
) =>
  securityGroups.find(
    group =>
      group.protection_class === Number(protectionClass) &&
      group.security_level === Number(securityLevel),
  )

/**
 * Checks if the security level form field is required
 * @param form
 * @param fractionList
 * @param item
 * @returns {Boolean}
 */
export const isSecurityRequired = (form, fractionList, item) => {
  if (fractionList && fractionList.length > 0) {
    let getFractionFromList

    if (form && form.fraction.value) {
      getFractionFromList = elem => elem.id === form.fraction.value
    } else if (item) {
      getFractionFromList = elem => elem.id === Number(item.fraction)
    } else {
      return false
    }

    const fraction = fractionList.find(getFractionFromList)
    return fraction ? fraction.requires_securitygroup : false
  }
  return false
}

/**
 * Returns name of a given Protection Class.
 * @param selectionList
 * @param selectedOptionId
 * @returns {string}
 */
export const getSelectedProtectionClassName = (
  selectionList,
  selectedOptionId,
) => {
  const selectedOption = selectionList.find(
    option => option.protection_class === Number(selectedOptionId),
  )
  if (selectedOption) {
    return I18n.t('selectSecurityGroupTranslations.protectionClassNumber', {
      protectionClass: selectedOption.protection_class,
    })
  }
  return I18n.t('selectSecurityGroupTranslations.noProtectionClassSelection')
}

/**
 * Returns name of a overwritten Protection Class.
 * @param selectionList
 * @param selectedOptionId
 * @returns {string}
 */
export const getOverwrittenProtectionClassName = (
  selectionList,
  selectedOptionId,
) => {
  const selectedOption = selectionList.find(
    option => option.id === Number(selectedOptionId),
  )
  if (selectedOption) {
    return I18n.t('selectSecurityGroupTranslations.protectionClassNumber', {
      protectionClass: selectedOption.protection_class,
    })
  }
  return I18n.t('selectSecurityGroupTranslations.noProtectionClassSelection')
}

/**
 * Returns the name of a given Security Group.
 * @param selectionList
 * @param selectedOptionId
 * @returns {string}
 */
export const getSelectedSecurityLevelName = (
  selectionList,
  selectedOptionId,
) => {
  const selectedOption = selectionList.find(
    option => option.security_level === Number(selectedOptionId),
  )
  if (selectedOption) {
    return I18n.t('selectSecurityGroupTranslations.securityLevelNumber', {
      securityLevel: selectedOption.security_level,
    })
  }
  return I18n.t('selectSecurityGroupTranslations.noSecurityLevelSelection')
}

/**
 * Returns the name of a overwritten Security Group.
 * @param selectionList
 * @param selectedOptionId
 * @returns {string}
 */
export const getOverwrittenSelectedSecurityLevelName = (
  selectionList,
  selectedOptionId,
) => {
  const selectedOption = selectionList.find(
    option => option.id === Number(selectedOptionId),
  )
  if (selectedOption) {
    return I18n.t('selectSecurityGroupTranslations.securityLevelNumber', {
      securityLevel: selectedOption.security_level,
    })
  }
  return I18n.t('selectSecurityGroupTranslations.noSecurityLevelSelection')
}
