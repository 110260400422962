import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { bool, shape } from 'prop-types'

import DetailSection from 'components/common/DetailSwitcher/components/DetailSection'
import { Slider } from 'components/common/Slider'
import { OrderScheme } from 'schemes/order'

/**
 * @description This component renders the slider component.
 */
export class SliderComponent extends Component {
  render() {
    return (
      <>
        <DetailSection
          title={I18n.t('detailsSwitcher.images')}
          icon={`${this.props.showAccordion ? 'photo-group' : ''}`}
          showAccordion={this.props.showAccordion}
          fullWidth
        >
          {!this.props.showAccordion &&
            (this.props.order.offer_object.attachments.length > 0 ? (
              <Slider images={this.props.order.offer_object.attachments} />
            ) : (
              <Translate value='general.emptyImageSet' />
            ))}
        </DetailSection>

        <div
          className={`uk-accordion-content ${
            !this.props.showAccordion ? 'uk-hidden' : ''
          }`}
        >
          {this.props.order.offer_object.attachments.length > 0 ? (
            <Slider images={this.props.order.offer_object.attachments} />
          ) : (
            <Translate value='general.emptyImageSet' />
          )}
        </div>
      </>
    )
  }
}

SliderComponent.propTypes = {
  order: shape(OrderScheme).isRequired,
  showAccordion: bool.isRequired,
}

export default SliderComponent
