/**
 * Returns the name and, if existing, the position
 * @param user
 * @return {string}
 */
export const getListLabelForUser = user => {
  if (!user) {
    return ''
  }
  if (user.position) {
    return `${user.first_name} ${user.last_name} (${user.position})`
  }
  return `${user.first_name} ${user.last_name}`
}

export default {}
