import './style.scss'

import React, { FC } from 'react'

export enum PROGRESS_BAR_COLOR {
  GREEN = 'green',
  ORANGE = 'orange',
}

export const ProgressBar: FC<{
  color: PROGRESS_BAR_COLOR
  percent: number
}> = ({ color, percent }) => (
  <div className='progress-bar'>
    <div
      className={`progress-bar__progress progress-bar__progress--${color}`}
      style={{ width: `${Number(percent)}%` }}
    />
  </div>
)
