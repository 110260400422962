import keyMirror from 'keymirror'

const GET_EMPTO_DOWNLOADS = keyMirror({
  GET_EMPTO_DOWNLOADS_REQUEST: null,
  GET_EMPTO_DOWNLOADS_SUCCESS: null,
  GET_EMPTO_DOWNLOADS_ERROR: null,
})

export default {
  ...GET_EMPTO_DOWNLOADS,
}
