import './style.scss'

import german from 'hyphenation.de'
import Hypher from 'hypher'
import moment from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { ReadOnlyField } from 'components/common/ReadOnly'

import { DetailsSection } from '../../DetailsSection'

import { getReasonValue } from './helpers'

interface OrderClaimDetailsComponentProps {
  claim: OrderClaimList
}

export const OrderClaimDetails: FC<OrderClaimDetailsComponentProps> = ({
  claim,
}) => {
  const hypher = new Hypher(german)

  if (!claim.company_object && !claim.created_by_object) return null

  return (
    <DetailsSection
      icon='user'
      title={I18n.t(
        'documentReviewPageTranslations.orderClaimDetails.sectionTitle',
      )}
    >
      <div className='document-review-section-order-claim-details'>
        <div className='document-review-section-order-claim-details__costumer-section'>
          {/* NAME OF COMPANY WHO CREATED CLAIM */}
          {(!!claim.claimed_by_company_name && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimDetails.companyName',
              )}
              value={claim.claimed_by_company_name}
            />
          )) ??
            (!!claim.company_object.name && (
              <ReadOnlyField
                label={I18n.t(
                  'documentReviewPageTranslations.orderClaimDetails.companyName',
                )}
                value={claim.company_object.name}
              />
            ))}
          {/* NAME OF USER WHO CREATED CLAIM */}
          {!!claim.created_by_object.first_name && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimDetails.claimer.name',
              )}
              value={`${claim.created_by_object.first_name}
                ${claim.created_by_object.last_name}`}
            />
          )}
          {/* POSITION OF USER WHO CREATED CLAIM */}
          {!!claim.created_by_object.position && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimDetails.claimer.position',
              )}
              value={claim.created_by_object.position}
            />
          )}
          {/* PHONE NUMBER OF USER WHO CREATED CLAIM */}
          {!!claim.created_by_object.business_phone_number && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimDetails.claimer.phoneNumber',
              )}
              value={claim.created_by_object.business_phone_number}
            />
          )}
          {/* EMAIL OF USER WHO CREATED CLAIM */}
          {!!claim.created_by_object.email && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimDetails.claimer.email',
              )}
              value={
                claim.created_by_object
                  ? hypher.hyphenateText(claim.created_by_object.email)
                  : ''
              }
            />
          )}
        </div>
        <div className='document-review-section-order-claim-details__claim-type-section'>
          {/* TIMESTAMP OF WHEN COMPLAINT WAS FILED */}
          {!!claim.created_at && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimDetails.createdAt',
              )}
              value={moment(claim.created_at).format('L LT')}
            />
          )}
          {/* REASON FOR COMPLAINT */}
          {!!claim.claim_type && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimDetails.claimType',
              )}
              value={getReasonValue(claim.claim_type)}
            />
          )}
        </div>
        <div className='document-review-section-order-claim-details__claim-description-section'>
          {/* MESSAGE OF COMPLAINT */}
          {!!claim.claim_description && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.orderClaimDetails.claimDescription',
              )}
              value={claim.claim_description}
            />
          )}
        </div>
      </div>
    </DetailsSection>
  )
}
