import keyMirror from 'keymirror'
import { I18n } from 'react-i18nify'

import { NewEntryValues } from './types'

export const LOG_FIELDS = [
  'category',
  'communities',
  'container_type',
  'customer_id',
  'email',
  'first_name',
  'fraction_id',
  'last_name',
  'stage',
  'type',
  'zipcodes',
]

export const LOG_FIELD_SUFFIX = '_before'

export const COMMUNICATION_CENTER_MODALS = keyMirror({
  NEW_ENTRY_ALL_LEVELS_MODAL: null,
  EDIT_ENTRY_MODAL: null,
  DELETE_ENTRY_MODAL: null,
})

export const CATEGORY_CHOICES = {
  CHOICE_BOOKKEEPING: 1,
  CHOICE_SALES: 2,
  CHOICE_OPERATIONS: 3,
}

export const TYPE_CHOICES = {
  CHOICE_INBOX: 1,
  CHOICE_PRIVATE: 2,
}

export const STAGE_CHOICES = {
  CHOICE_STAGE1: 1,
  CHOICE_STAGE2: 2,
  CHOICE_STAGE3: 3,
}

export const REVISION_TYPES = {
  REVISION_TYPE_NEW: 1,
  REVISION_TYPE_UPDATED: 2,
  REVISION_TYPE_DELETED: 3,
}

export const REVISION_TYPES_TRANSLATIONS = {
  1: I18n.t('communicationCenterPageTranslations.revisionTypes.new'),
  2: I18n.t('communicationCenterPageTranslations.revisionTypes.updated'),
  3: I18n.t('communicationCenterPageTranslations.revisionTypes.deleted'),
}

export const CATEGORY_CHOICES_TRANSLATIONS = {
  1: I18n.t(
    'communicationCenterPageTranslations.fields.category.options.bookkeeping',
  ),
  2: I18n.t(
    'communicationCenterPageTranslations.fields.category.options.sales',
  ),
  3: I18n.t(
    'communicationCenterPageTranslations.fields.category.options.operations',
  ),
}

export const TYPE_CHOICES_TRANSLATIONS = {
  1: I18n.t('communicationCenterPageTranslations.fields.type.options.inbox'),
  2: I18n.t('communicationCenterPageTranslations.fields.type.options.private'),
}

export const STAGE_CHOICES_TRANSLATIONS = {
  1: I18n.t('communicationCenterPageTranslations.fields.stage.options.stage1'),
  2: I18n.t('communicationCenterPageTranslations.fields.stage.options.stage2'),
  3: I18n.t('communicationCenterPageTranslations.fields.stage.options.stage3'),
}

export const WASTE_CATEGORY_CHOICES = {
  CHOICE_ALL: 1,
  CHOICE_SPECIFIC: 2,
}

export const NEW_ENTRY_CATEGORY_CHOICES = [
  {
    label: I18n.t(
      'communicationCenterPageTranslations.fields.category.options.bookkeeping',
    ),
    value: CATEGORY_CHOICES.CHOICE_BOOKKEEPING,
  },
  {
    label: I18n.t(
      'communicationCenterPageTranslations.fields.category.options.sales',
    ),
    value: CATEGORY_CHOICES.CHOICE_SALES,
  },
  {
    label: I18n.t(
      'communicationCenterPageTranslations.fields.category.options.operations',
    ),
    value: CATEGORY_CHOICES.CHOICE_OPERATIONS,
  },
]

export const INITIAL_VALUES: NewEntryValues = {
  category: '',
  email: '',
  type: String(TYPE_CHOICES.CHOICE_INBOX),
  fraction: '',
  last_name: '',
  first_name: '',
  stage: String(STAGE_CHOICES.CHOICE_STAGE1),
  waste_category: String(WASTE_CATEGORY_CHOICES.CHOICE_ALL),
  communities: [],
  zipcodes: [],
  customer: '',
  container_type: '',
}

export enum COM_CENTER_ACTION_TYPES {
  ADD = 'ADD_COM_CENTER_CONTACT',
  UPDATE = 'UPDATE_COM_CENTER_CONTACT',
}
