import { connect } from 'react-redux'

import { registerUser } from 'actions/user'
import { cookiesAcceptedSelector } from 'selectors/app'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'

const loadingSelector = createLoadingSelector(['REGISTER_USER'])

const errorSelector = createErrorSelector(['REGISTER_USER'])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  error: errorSelector(state),
  isLoading: loadingSelector(state),
  cookiesAccepted: cookiesAcceptedSelector(state),
  user: getCurrentUserSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  registerUser: (userInfo, history, callback) =>
    dispatch(registerUser(userInfo, history, callback)),
})

export const connector = connect(mapStateToProps, mapDispatchToProps)
