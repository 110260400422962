export const priceManagementPageTranslations = {
  headline: 'Preisverwaltung',
  introduction:
    'Hier können Sie sämtliche Verkaufspreise der DSZ Shops einsehen und bei Bedarf bearbeiten. Jede vorgenommene Änderung ist sofort aktiv und kann unter Tätigkeiten eingesehen werden. <br />' +
    'Bei den angezeigten Preisen handelt es sich um Nettopreise.',
  addPriceModal: {
    buttonText: 'DSZ Preis hinzufügen',
    introText:
      'Hier können Sie die Preise für den gewählten Shop, die gewählte Abfallart und das gewählte Lizenzierungsjahr auswählen.',
    modalFields: {
      text: {
        fraction: 'Abfallart',
        price_xs: 'Preis bis 0,499 to in €',
        price_s: 'Preis bis 0,999 to in €',
        price_m: 'Preis bis 4,999 to in €',
        price_l: 'Preis bis 9,999 to in €',
        price_xl: 'Preis bis 19,999 to in €',
        price_xxl: 'Preis bis 999,999 to in €',
        shop: 'Shop',
        year: 'Lizenzierungsjahr',
      },
      error: {
        fraction: 'Es muss eine Abfallart ausgewählt sein.',
        noInputResultsFraction:
          'Es konnte keine passende Abfallart gefunden werden.',
        noInputResultsShop:
          'Es konnte kein passender Shop (DSZ) gefunden werden.',
        price:
          'Es muss ein gültiger Preis eingetragen sein. Das Feld darf nicht leer sein.',
        shop: 'Es muss ein Shop ausgewählt sein.',
        year: 'Das Lizenzierungsjahr muss zwischen 2019 und 2050 liegen.',
      },
    },
  },
  pricesTable: {
    columns: {
      shop: 'Shop',
      year: 'Jahr',
      fraction: 'Abfallart',
      actions: 'Aktionen',
      editTitle: 'Preise ändern',
    },
    filters: {
      shop: 'Shop',
      licenseYear: 'Lizenzierungsjahr',
      reset: 'Filter zurücksetzen',
    },
  },
  minimumTurnoverTable: {
    headline: 'Mindestumsätze',
    columns: {
      shop: 'Shop',
      minimumTurnover: 'Mindestumsatz',
      actions: 'Aktionen',
    },
  },
  activitiesTable: {
    headline: 'Tätigkeiten',
    columns: {
      timestamp: 'Zeitstempel',
      name: 'Name',
      activity: 'Tätigkeit',
      entry: 'Eintrag',
      change: 'Änderung',
      actions: 'Aktionen',
    },
  },
}
