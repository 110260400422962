import { changeDisposerFormTranslations } from './ChangeDisposerForm/translations'

export const changeDisposerModalTranslations = {
  modalTitle: 'Wechsel des Entsorgungspartners',
  modalInfo:
    'Hier können Sie den Entsorgungspartner für einen Einmalauftrag ändern. ' +
    'Bitte wählen Sie einen neuen Entsorgungspartner aus und klicken danach auf den Button ' +
    '"Entsorger jetzt wechseln". Der neue Entsorgungspartner wird direkt beauftragt. ' +
    'Der bisherige Entsorgungspartner wird automatisch über die Stornierung informiert.',
  convertInquiryForm: changeDisposerFormTranslations,
}
