import { bool, shape, string } from 'prop-types'

import { FieldComponentScheme } from '../../schemes'
import { ErrorsScheme } from '../FormGroupError/schemes'

const FormGroupFieldContainerProps = {
  fieldComponent: FieldComponentScheme,
  showTooltip: bool.isRequired,
  errors: ErrorsScheme,
  errorMessages: shape({}),
  model: string,
  required: bool,
}

export default FormGroupFieldContainerProps
