import './styles.scss'

import React from 'react'

import Icon, { ICON_NAME } from '../Fontello'

export const UpDownArrow = () => (
  <span className='updown-arrow'>
    <Icon name={ICON_NAME.CHEVRON_UP} />
    <Icon name={ICON_NAME.CHEVRON_DOWN} />
  </span>
)
