import React, { FC } from 'react'

import Icon from 'components/common/Fontello'

interface CardHeaderIconProps {
  iconName: string
  tooltip: React.ReactNode
  testDataId?: string
}

export const CardHeaderIcon: FC<CardHeaderIconProps> = ({
  iconName,
  tooltip,
  testDataId,
}) => (
  <span
    className='new-card__header-icon'
    data-testid={testDataId || ''}
    data-uk-tooltip={tooltip}
  >
    <Icon name={iconName} />
  </span>
)
