import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

interface CardStatusHeaderProps {
  state?: string | ReactNode
  highlighted?: boolean
  title: string
}

export const CardStatusHeader: FC<CardStatusHeaderProps> = ({
  state,
  highlighted,
  title,
}) => (
  <header className='new-card__status-header'>
    {title && <span className='new-card__status-header-title'>{title}</span>}
    {state && (
      <span
        className={classNames('new-card__status-header-state', {
          'new-card__status-header-state--highlighted': highlighted,
        })}
      >
        {state}
      </span>
    )}
  </header>
)
