import './ActionCard.scss'

import React, { FC } from 'react'
import { useHistory } from 'react-router'

import { ReactComponent as PriceManagement } from 'assets/svg/icon-price-management.svg'
import { ReactComponent as WasteDisposer } from 'assets/svg/icon-waste-disposer.svg'
import { ReactComponent as WasteProducer } from 'assets/svg/icon-waste-producer.svg'
import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { ICON_POSITION, IconButton } from 'components/common/IconButton'

export enum ActionCardIcon {
  WASTE_PRODUCER = 'icon-waste-producer',
  WASTE_DISPOSER = 'icon-waste-disposer',
  PRICE_MANAGEMENT = 'icon-price-management',
}

interface ActionCardProps {
  buttonText?: string
  buttonTarget?: string
  buttonIcon?: string
  value: string
  caption: string
  icon?: ActionCardIcon
  dataButtonCount?: number
}

/**
 * Renders the ActionCard
 * @constructor
 */
export const ActionCard: FC<ActionCardProps> = ({
  buttonText,
  buttonTarget,
  buttonIcon,
  value,
  caption,
  icon,
  dataButtonCount,
}) => {
  /**
   * Determine the correct IconComponent
   */
  const ActionCardIconComponent = (): JSX.Element => {
    const iconClassName = 'action-card__icon-svg'

    if (icon === ActionCardIcon.WASTE_PRODUCER)
      return <WasteProducer className={iconClassName} />
    if (icon === ActionCardIcon.WASTE_DISPOSER)
      return <WasteDisposer className={iconClassName} />
    if (icon === ActionCardIcon.PRICE_MANAGEMENT)
      return <PriceManagement className={iconClassName} />

    return <></>
  }

  const history = useHistory()

  return (
    <article className='action-card'>
      <div className='action-card__container'>
        <div className='action-card__content'>
          {icon && (
            <div className='action-card__icon'>
              <ActionCardIconComponent />
            </div>
          )}
          <div className='action-card__value'>{value}</div>
          <div className='action-card__caption'>{caption}</div>
        </div>
        {buttonText && buttonTarget && (
          <div className='action-card__button'>
            <IconButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              iconName={buttonIcon}
              iconPosition={ICON_POSITION.RIGHT}
              onClick={() => history.push(buttonTarget)} // Link to button Target
              dataButtonCount={dataButtonCount}
            >
              {buttonText}
            </IconButton>
          </div>
        )}
      </div>
    </article>
  )
}
