// Styles
import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { NavLink, useLocation } from 'react-router-dom'

import { toggleMobileMenu } from 'actions/app'
import { logout } from 'actions/user'
import { getLoggedInSelector } from 'selectors/user'
import Icon from 'components/common/Fontello/index'
import { RequiredPermissions } from 'components/common/RequiredPermissions'

import { BaseMenuItemElement, MenuType } from '../constants'

interface MenuItemProps extends BaseMenuItemElement {
  isBold?: boolean
  menuType: MenuType
  activeClassName: string
  showIcon?: boolean
  toggleTarget?: string
  linkTo?: string
  isLogoutButton?: boolean
}

/**
 * @description This component renders and controls a menu item component.
 * @function
 */
export const MenuItem: FC<MenuItemProps> = ({
  dataTestId = '',
  exact,
  iconName = null,
  isBold,
  menuType,
  activeClassName,
  linkTo,
  name,
  requiredPermissions = [],
  showIcon = false,
  spaceLeft = false,
  toggleTarget = '',
  notEmpto = false,
  showWhen,
  isLogoutButton,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const isLoggedIn = !!useSelector(getLoggedInSelector)

  const isMobileMenu = menuType === MenuType.Mobile

  if (
    showWhen.isLoggedIn !== isLoggedIn ||
    showWhen.menuTypes.indexOf(menuType) === -1
  ) {
    return null
  }

  const defaultHandleClick = () => {
    if (isMobileMenu) {
      dispatch(toggleMobileMenu(false))
    }
  }

  const logoutHandler = () => {
    dispatch(logout(history))
  }

  const linkParam: { pathname?: string; state?: { from?: string } } = {
    pathname: linkTo,
  }
  if (location.pathname === linkTo) {
    linkParam.state = { from: location.pathname }
  }

  return (
    <RequiredPermissions
      requiredPermissions={requiredPermissions}
      notEmpto={notEmpto}
    >
      <li
        className={classNames({
          'menu-item': !isMobileMenu,
          'menu-item--space-left': spaceLeft,
          'mobile-menu-item': isMobileMenu,
          'menu-item-bold': isBold,
        })}
        data-uk-toggle={
          toggleTarget && toggleTarget !== ''
            ? `target: ${toggleTarget}`
            : undefined
        }
      >
        <NavLink
          to={linkParam}
          exact={exact}
          className='uk-flex uk-flex-middle'
          activeClassName={activeClassName}
          onClick={isLogoutButton ? logoutHandler : defaultHandleClick}
          data-testid={dataTestId}
        >
          {showIcon && iconName && <Icon name={iconName} />}
          <span>{name}</span>
        </NavLink>
      </li>
    </RequiredPermissions>
  )
}
