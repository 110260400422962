import { arrayOf, bool, number, shape, string } from 'prop-types'

import { AttachmentScheme } from '../components/common/fileUpload/schemes'
import { FieldScheme } from '../components/common/Form/schemes'

import { CompanySmallScheme } from './company'

export const CertificateScheme = {
  id: number,
  status: number,
  type: number,
  attachments: arrayOf(shape(AttachmentScheme)),
  company: number,
  company_object: shape(CompanySmallScheme),
  valid_until: string,
  issued_at: string,
  internal_note: string,
  reject_reason: string,
  created_at: string,
  created_by_name: string,
  reviewed_at: string,
  reviewed_by_name: string,
  disposited_prices: bool,
}

export const CertificateFormScheme = {
  $form: shape({
    model: string,
    submitFailed: bool,
  }).isRequired,
  internal_note: FieldScheme,
  issued_at: FieldScheme,
  reject_reason: FieldScheme,
  service_date: FieldScheme,
  status: FieldScheme,
  valid_until: FieldScheme,
  disposited_prices: FieldScheme,
}
