import React from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'

import withErrorBoundary from 'helper/withErrorBoundary'

export const ImprintComponent = () => (
  <>
    <Helmet>
      <title>{I18n.t('pageTitles.imprint')}</title>
      <meta name='Description' content={I18n.t('pageDescriptions.imprint')} />
    </Helmet>

    <article className='uk-article uk-margin-top'>
      <h3>
        <Translate value='imprint.title' />
      </h3>
      <p>
        <Translate value='imprint.meta' dangerousHTML />
      </p>
      <p>
        <Translate value='imprint.text' dangerousHTML />
      </p>
      <p>
        <Translate value='imprint.certification' dangerousHTML />
      </p>
    </article>
  </>
)

export default withErrorBoundary(ImprintComponent)
