export const SucessMessageTranslations = {
  success: {
    title: 'Vielen Dank!',
    subTitle: 'Aufragsbestätigung erhalten',
    text: 'Mit dieser Online-Bestätigung haben Sie sich und uns Zeit und Geld gespart, da wir Sie nicht mehr anrufen müssen.',
  },
  cancelled: {
    title: 'Sehr Schade!',
    subTitle: 'Der Auftrag geht an einen anderen Entsorger.',
    text: 'Mit dieser Online-Bestätigung haben Sie sich und uns Zeit und Geld gespart, da wir Sie nicht mehr anrufen müssen.',
  },
}
