import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { Filter as FilterComponent } from 'components/common/Filter'
import { CollectiveInvoicingFilter } from 'components/common/Filter/components/CollectiveInvoicingFilter'
import { getCollectiveInvoiceCompanyListPaginationSelector } from 'selectors/company'

import { CollectiveInvoicingPageContext } from '../../CollectiveInvoicingPage'

export const Filter: FC = () => {
  const pagination = useSelector(
    getCollectiveInvoiceCompanyListPaginationSelector,
  )
  return (
    <CollectiveInvoicingPageContext.Consumer>
      {context => (
        <FilterComponent length={pagination.count}>
          {/* Ignore TS explicitly and ignore the PyCharm error in your mind: required props passed via <Filter> */}
          {/* @ts-ignore */}
          <CollectiveInvoicingFilter
            setCurrentFilters={context.setCurrentFilters}
            currentFilters={context.currentFilters}
            setCurrentActivityFilters={context.setCurrentActivityFilters}
            currentActivityFilters={context.currentActivityFilters}
          />
        </FilterComponent>
      )}
    </CollectiveInvoicingPageContext.Consumer>
  )
}
