import './FormStepSummaryRow.scss'

import React, { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

export enum LABEL_TYPE {
  DEFAULT = 'default',
  BOLD = 'bold',
}

interface FormStepSummaryRowProps {
  label: string
  labelType?: LABEL_TYPE
  value?: ReactNode
  path?: string
}

/**
 * @description Functional component to render FormStepSummaryRowComponent
 * @param label
 * @param labelType
 * @param path
 * @param value
 */
export const FormStepSummaryRow: FC<FormStepSummaryRowProps> = ({
  label,
  labelType = LABEL_TYPE.BOLD,
  path = null,
  value = null,
}) => {
  if (path) {
    return (
      <Link className='form-step-summary-row' to={path}>
        <div className={`form-step-summary-row label-type-${labelType}`}>
          {`${label}:`}
        </div>

        <div className='form-step-summary-row form-step-summary-row-value'>
          {value}
        </div>
      </Link>
    )
  }

  return (
    <>
      <div className={`form-step-summary-row label-type-${labelType}`}>
        {`${label}:`}
      </div>
      <div className='form-step-summary-row form-step-summary-row-value'>
        {value}
      </div>
    </>
  )
}
