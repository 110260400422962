import './style.scss'

import { node } from 'prop-types'
import React from 'react'

/**
 * @description This component renders and controls a waste type content component.
 * @function
 */
const WasteTypeContact = props => (
  <div className='waste-type-contact'>{props.content}</div>
)

WasteTypeContact.propTypes = {
  content: node,
}

WasteTypeContact.defaultProps = {
  content: null,
}

export default WasteTypeContact
