import './style.scss'

import React, { FC } from 'react'

import {
  LandingPageContent,
  WasteManagerLandingPageType,
} from '../HomepageWastemanager'

import { ExplanationSectionChild } from './ExplanationSectionChild'
import { ExplanationSectionHero } from './ExplanationSectionHero'

interface ExplanationSectionProps {
  sections: LandingPageContent[]
}

export const ExplanationSection: FC<ExplanationSectionProps> = ({
  sections,
}) => (
  <section className='explanation-section'>
    {sections.map(section =>
      section.type === WasteManagerLandingPageType.Wastemanager ? (
        <ExplanationSectionHero key={section.id} section={section} />
      ) : (
        <ExplanationSectionChild key={section.id} section={section} />
      ),
    )}
  </section>
)
