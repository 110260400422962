import React from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'

import withErrorBoundary from 'helper/withErrorBoundary'

export const LegalNoticeComponent = () => (
  <>
    <Helmet>
      <title>{I18n.t('pageTitles.legalNotice')}</title>
      <meta
        name='Description'
        content={I18n.t('pageDescriptions.legalNotice')}
      />
    </Helmet>

    <article className='uk-article uk-margin-top'>
      <h3>
        <Translate value='legalNotice.title' />
      </h3>
      <p>
        <Translate value='legalNotice.text' dangerousHTML />
      </p>
    </article>
  </>
)

export default withErrorBoundary(LegalNoticeComponent)
