export const passwordResetFormTranslation = {
  password: {
    label: 'Wählen Sie ein Passwort',
    notSecure:
      'Mindestens 14 Zeichen: Groß- sowie Kleinbuchstaben, Ziffern und Sonderzeichen.',
    placeholder: 'Wählen Sie ein Passwort.',
    required: 'Bitte geben Sie Ihr Passwort ein.',
  },
  passwordRepeat: {
    label: 'Passwort wiederholen',
    notEqual: 'Die Passwörter müssen übereinstimmen.',
    placeholder: 'Wiederholen Sie Ihr Passwort.',
    required: 'Bitte wiederholen Sie Ihr Passwort.',
  },
  submit: 'OK',
  title: 'Passwort vergessen',
}

export default {}
