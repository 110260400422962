import { useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC, useContext } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { DATE_FORMATS } from 'constants/app'
import { getFractionName } from 'components/executionproof/helpers'
import { FormStepSummaryRow } from 'components/common/FormSteps'
import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { getEPDFractionsSelector } from 'selectors/fraction'

import {
  EPDAddInvoiceFormContext,
  EPDAddInvoiceFormValues,
} from '../EPDAddInvoiceFormSteps'

export const AssignedOrderSummary: FC = () => {
  const { values } = useFormikContext<EPDAddInvoiceFormValues>()
  const { editStep, isInvoice } = useContext(EPDAddInvoiceFormContext)

  const fractionList = useSelector(getEPDFractionsSelector)

  let fraction
  if (values.assigned_order) {
    fraction = getFractionName(values.assigned_order.fraction, fractionList)
  }

  return (
    <div className='maklerpremium-step-summary'>
      <div className='maklerpremium-step-summary_grid-body'>
        <FormStepSummaryRow
          label={I18n.t(
            'addInvoicePageTranslations.epd.steps.2.fields.assignable_to_order.summary_label',
          )}
          value={
            values.assigned_order ? I18n.t('general.yes') : I18n.t('general.no')
          }
        />
        {values.assigned_order && (
          <>
            <FormStepSummaryRow
              label={I18n.t(
                'addInvoicePageTranslations.epd.steps.2.fields.assigned_order_number.label',
              )}
              value={`${values.assigned_order.id}`}
            />
            <FormStepSummaryRow
              label={I18n.t(
                'addInvoicePageTranslations.epd.steps.2.fields.assigned_order_fraction.label',
              )}
              value={`${fraction}`}
            />
            <FormStepSummaryRow
              label={I18n.t(
                'addInvoicePageTranslations.epd.steps.2.fields.assigned_order_delivery_date.label',
              )}
              value={`${moment(
                values.assigned_order.delivery_date,
                DATE_FORMATS,
              ).format('L')}`}
            />
          </>
        )}
        {values.is_not_allowed_on_collective_invoice !== undefined && (
          <FormStepSummaryRow
            label={I18n.t(
              'addInvoicePageTranslations.epd.steps.2.fields.is_not_allowed_on_collective_invoice.summary_label',
            )}
            value={
              values.is_not_allowed_on_collective_invoice
                ? I18n.t(
                    'addInvoicePageTranslations.epd.steps.2.fields.is_not_allowed_on_collective_invoice.options.complete_billing',
                  )
                : I18n.t(
                    'addInvoicePageTranslations.epd.steps.2.fields.is_not_allowed_on_collective_invoice.options.other_service',
                  )
            }
          />
        )}

        {values.is_not_allowed_on_collective_invoice &&
          values.is_not_allowed_on_collective_invoice_comment && (
            <FormStepSummaryRow
              label={I18n.t(
                'addInvoicePageTranslations.epd.steps.2.fields.is_not_allowed_on_collective_invoice_comment.label',
              )}
              value={values.is_not_allowed_on_collective_invoice_comment}
            />
          )}

        <FormStepSummaryRow
          label={I18n.t(
            'addInvoicePageTranslations.epd.steps.2.fields.invoice_or_credit_note.summary_label',
          )}
          value={
            isInvoice
              ? I18n.t(
                  'addInvoicePageTranslations.epd.steps.2.fields.invoice_or_credit_note.invoice',
                )
              : I18n.t(
                  'addInvoicePageTranslations.epd.steps.2.fields.invoice_or_credit_note.credit_note',
                )
          }
        />
        {!isInvoice && values.iban && (
          <FormStepSummaryRow
            label={I18n.t(
              'addInvoicePageTranslations.epd.steps.2.fields.iban.placeholder',
            )}
            value={`${values.iban}`}
          />
        )}
        <FormStepSummaryRow
          label={I18n.t(
            'addInvoicePageTranslations.epd.steps.2.fields.assigned_vat.summaryLabel',
          )}
          value={`${parseFloat(`${values.assigned_vat}`).toLocaleString(
            'de-DE',
            { minimumFractionDigits: 0 },
          )} %`}
        />
        {values.rcv && (
          <FormStepSummaryRow
            label={I18n.t(
              'addInvoicePageTranslations.epd.steps.2.fields.rcv.summaryLabel',
            )}
            value={I18n.t(
              'addInvoicePageTranslations.epd.steps.2.fields.rcv.label',
            )}
          />
        )}
      </div>
      <div className='sidebar'>
        <ButtonLink
          className='sidebar__edit-link'
          linkColor={BUTTON_LINK_COLOR.PRIMARY}
          onClick={editStep}
        >
          {I18n.t('createInquiryTranslations.form.button.editStep')}
        </ButtonLink>
      </div>
    </div>
  )
}
