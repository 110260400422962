import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'

export enum BUTTON_LINK_COLOR {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  MUTED = 'muted',
}

interface ButtonLinkProps {
  className?: string
  linkColor?: BUTTON_LINK_COLOR
  onClick: React.MouseEventHandler
}

/**
 * Renders a button with link appearance
 */
export const ButtonLink: FC<ButtonLinkProps> = ({
  children,
  className = '',
  linkColor = BUTTON_LINK_COLOR.DEFAULT,
  onClick,
}) => (
  <button
    className={classNames(
      `button-link ${className}`,
      linkColor === BUTTON_LINK_COLOR.MUTED ? 'uk-text-muted' : '',
      { 'button-link--default': linkColor === BUTTON_LINK_COLOR.DEFAULT },
      { 'uk-link': linkColor === BUTTON_LINK_COLOR.PRIMARY },
    )}
    onClick={onClick}
    type='button'
  >
    {children}
  </button>
)
