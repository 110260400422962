import React from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'

import withErrorBoundary from 'helper/withErrorBoundary'

/* eslint-disable no-irregular-whitespace */
export const PrivacyComponent = () => (
  <>
    <Helmet>
      <title>{I18n.t('pageTitles.privacy')}</title>
      <meta name='Description' content={I18n.t('pageDescriptions.privacy')} />
    </Helmet>

    <article className='uk-article uk-margin-top'>
      <h3>
        <Translate value='privacy.title' />
      </h3>
      <p className='uk-article-meta'>
        <Translate value='privacy.meta' />
      </p>
      <Translate value='privacy.text' dangerousHTML />
    </article>
  </>
)

export default withErrorBoundary(PrivacyComponent)
