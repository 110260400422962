import React, { Component } from 'react'
import ReduxToastr from 'react-redux-toastr'

/**
 * @description This component renders and controls a Toastr component.
 * @function
 * @param {Object} props the component props
 */
export default class Toastr extends Component {
  /**
   * @function
   * @return {*}
   */
  render() {
    return (
      <ReduxToastr
        timeOut={5000}
        position='top-right'
        transitionIn='fadeIn'
        transitionOut='fadeOut'
        progressBar
        preventDuplicates
        newestOnTop={false}
      />
    )
  }
}
