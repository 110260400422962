import classNames from 'classnames'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { LinkButton } from 'components/common/LinkButton'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { TooltipIcon } from 'components/common/TooltipIcon'
import {
  BILLING_INTERVAL,
  COLLECTIVE_INVOICING_MODES,
  COMPANY_ROLE,
  PAYMENT_METHODS,
} from 'components/company/constants'
import { UserPermission } from 'constants/user'
import { getName } from 'helper/translations'

/**
 * @description This component renders the payment method and discount section of the extended company info page
 */
export const PaymentAndDiscount: FC<{ company?: Company }> = ({ company }) => {
  if (!company) return null
  const company_role_number = Number(company.role)
  const ukWidth = {
    'uk-width-1-3@m': company_role_number === COMPANY_ROLE.WASTE_PRODUCER,
    'uk-width-1-2@m': company_role_number === COMPANY_ROLE.WASTE_COMPANY,
  }

  const payment = () => {
    switch (company_role_number) {
      case COMPANY_ROLE.WASTE_PRODUCER:
        return (
          <ReadOnlyField
            label={I18n.t('paymentAndDiscount.paymentType')}
            value={getName(company.payment_method, PAYMENT_METHODS, true)}
          />
        )
      default:
        return -1
    }
  }

  const renderCustomerSpecificFields = () => {
    switch (company_role_number) {
      case COMPANY_ROLE.WASTE_PRODUCER:
        return (
          <>
            <div
              className={classNames(
                'payment-discount__payment uk-width-1-1@s',
                ukWidth,
              )}
            >
              <div className='payment-discount__payment__tooltip'>
                <TooltipIcon
                  description={I18n.t('paymentAndDiscount.tooltip.description')}
                  iconClass='question'
                />
              </div>
              {payment()}
            </div>
            <div
              className={classNames(
                'payment-discount__payment uk-width-1-1@s',
                ukWidth,
              )}
            >
              <ReadOnlyField
                label={I18n.t('paymentAndDiscount.permanentDiscount')}
                value={
                  company.permanent_discount
                    ? `${company.permanent_discount} %`
                    : I18n.t('paymentAndDiscount.permanentDiscountNone')
                }
              />
            </div>
            <div
              className={classNames(
                'payment-discount__payment uk-width-1-1@s',
                ukWidth,
              )}
            >
              <ReadOnlyField
                label={I18n.t('paymentAndDiscount.billingInterval')}
                value={
                  company.billing_interval ? `${company.billing_interval}` : '-'
                }
              />
            </div>
            {company.billing_interval === BILLING_INTERVAL.MONTH && (
              <>
                <div
                  className={classNames(
                    'payment-discount__payment uk-width-1-1@s',
                    ukWidth,
                  )}
                >
                  <ReadOnlyField
                    label={I18n.t(
                      'paymentAndDiscount.collectiveInvoicingAutoSendEmail',
                    )}
                    value={
                      company.collective_invoicing_auto_send_email
                        ? I18n.t('general.yes')
                        : I18n.t('general.no')
                    }
                  />
                </div>
                <div
                  className={classNames(
                    'payment-discount__payment uk-width-1-1@s',
                    ukWidth,
                  )}
                >
                  <ReadOnlyField
                    label={I18n.t('paymentAndDiscount.collectiveInvoicingMode')}
                    value={getName(
                      company.collective_invoicing_mode,
                      COLLECTIVE_INVOICING_MODES,
                      true,
                    )}
                  />
                </div>
                <div
                  className={classNames(
                    'payment-discount__payment uk-width-1-1@s',
                    ukWidth,
                  )}
                ></div>
              </>
            )}
            <div className='payment-discount__payment uk-width-1-1@s uk-width-1-3@m'>
              <LinkButton
                target={{
                  pathname: '/maklerpremium/bookkeeping',
                  state: { company: company.id, archive: false },
                }}
                backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
                fullWidth
              >
                <Translate value='paymentAndDiscount.button' />
              </LinkButton>
            </div>
          </>
        )
      default:
        return
    }
  }

  const renderPartnerSpecificFields = () => {
    switch (company_role_number) {
      case COMPANY_ROLE.WASTE_COMPANY:
        return (
          <>
            <div
              className={classNames(
                'payment-discount__payment uk-width-1-1@l',
                ukWidth,
              )}
            >
              <ReadOnlyField
                label={I18n.t('paymentAndDiscount.numberOfInvoiceChecks')}
                value={company.number_of_invoice_checks}
              />
            </div>
            <div className='payment-discount__payment uk-width-1-1@s uk-width-1-3@m'>
              <RequiredPermissions
                requiredPermissions={[UserPermission.VIEW_INVOICECHECK]}
              >
                <LinkButton
                  target={{
                    pathname: '/maklerpremium/invoice_check',
                    state: { company: company.id, archive: false },
                  }}
                  backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
                  fullWidth
                >
                  <Translate value='paymentAndDiscount.partnerButton' />
                </LinkButton>
              </RequiredPermissions>
            </div>
          </>
        )
      default:
        return
    }
  }

  return (
    <div className='payment-discount uk-grid' data-uk-grid=''>
      <div className='uk-width-1-1 uk-text-break'>
        <div className='uk-grid' data-uk-grid=''>
          {renderCustomerSpecificFields()}
          {renderPartnerSpecificFields()}
        </div>
      </div>
    </div>
  )
}
