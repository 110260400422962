import './style.scss'

import React, { Component } from 'react'
import Media from 'react-media'
import { oneOfType, string, object } from 'prop-types'

import { BREAKPOINT } from 'constants/design'

import { ButtonLearnMore } from '../ButtonLearnMore'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
} from '../Headline'

import ImageOvalDesktop from './img/oval-desktop.svg'
import ImageOvalMobile from './img/oval-mobile.svg'
import ImageOvalTablet from './img/oval-tablet.svg'

class HeroSectionComponent extends Component {
  static propTypes = {
    headline: oneOfType([string, object]),
    content: oneOfType([string, object]),
    imageDeviceMobile: oneOfType([string, object]),
    imageDeviceTablet: oneOfType([string, object]),
    imageDeviceDesktop: oneOfType([string, object]),
  }

  static defaultProps = {
    headline: <></>,
    content: <></>,
    imageDeviceMobile: '',
    imageDeviceTablet: '',
    imageDeviceDesktop: '',
  }

  render() {
    return (
      <>
        <section className='hero-section-component'>
          <div className='hero-section-component__content'>
            <div className='hero-section-component__content-wrap'>
              <Headline
                headlineStyle={HEADLINE_STYLE.H1}
                fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
                color={HEADLINE_COLOR.GRAYISH_BROWN}
              >
                {this.props.headline}
              </Headline>
              {this.props.content}
            </div>
          </div>
          <div className='hero-section-component__visuals' aria-hidden>
            <Media query={{ maxWidth: BREAKPOINT.MEDIUM - 1 }}>
              <>
                <img
                  alt=''
                  className='hero-section-component__device'
                  src={this.props.imageDeviceMobile}
                />
                <img
                  alt=''
                  className='hero-section-component__oval'
                  src={ImageOvalMobile}
                />
              </>
            </Media>
            <Media
              query={`(min-width: ${BREAKPOINT.MEDIUM}px) and (max-width: ${
                BREAKPOINT.XLARGE - 1
              }px)`}
            >
              <>
                <img
                  alt=''
                  className='hero-section-component__device'
                  src={this.props.imageDeviceTablet}
                />
                <img
                  alt=''
                  className='hero-section-component__oval'
                  src={ImageOvalTablet}
                />
              </>
            </Media>
            <Media query={{ minWidth: BREAKPOINT.XLARGE }}>
              <>
                <img
                  alt=''
                  className='hero-section-component__device'
                  src={this.props.imageDeviceDesktop}
                />
                <img
                  alt=''
                  className='hero-section-component__oval'
                  src={ImageOvalDesktop}
                />
              </>
            </Media>
          </div>
        </section>
        <ButtonLearnMore minWidth={BREAKPOINT.XLARGE} />
      </>
    )
  }
}

export default HeroSectionComponent
