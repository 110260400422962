import './style.scss'

import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'

import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from '../../common/Headline'

import { CreateMaklerPremiumOfferFormSteps } from './OfferFormSteps'

export const CreateMaklerPremiumOfferPage: FC = () => (
  <>
    <Helmet>
      <title>{I18n.t('pageTitles.maklerpremiumCreateOffer')}</title>
    </Helmet>
    <div className='formsteps-page'>
      <header className='formsteps-page__header'>
        <div className='uk-container uk-container-large'>
          <div className='formsteps-page__header-content'>
            <Headline
              fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
              headlineStyle={HEADLINE_STYLE.H2}
              tag={HEADLINE_TAG.H2}
              color={HEADLINE_COLOR.GRAYISH_BROWN}
              fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
            >
              {I18n.t('createMaklerPremiumOfferPageTranslations.header.title')}
            </Headline>
            <p className='formsteps-page__header-description'>
              {I18n.t(
                'createMaklerPremiumOfferPageTranslations.header.description',
              )}
            </p>
          </div>
        </div>
      </header>
      <div className='formsteps-page__body uk-container uk-container-large'>
        <main className='formsteps-page__main formsteps-page__main--without-sidebar'>
          <CreateMaklerPremiumOfferFormSteps />
        </main>
      </div>
    </div>
  </>
)
