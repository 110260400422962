import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'

export enum HEADLINE_COLOR {
  BLUE = 'blue',
  DEFAULT = 'default',
  DARK_GRAY = 'dark-gray',
  GRAY = 'gray',
  GRAYISH_BROWN = 'grayish-brown',
  WHITE = 'white',
  BLACK = 'black',
}

export enum HEADLINE_FONT_TYPE {
  SANS_SERIF = 'sans-serif',
  SERIF = 'serif',
}

export enum HEADLINE_TAG {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

export enum HEADLINE_STYLE {
  H1 = 'uk-h1',
  H2 = 'uk-h2',
  H3 = 'uk-h3',
  H4 = 'uk-h4',
  H5 = 'uk-h5',
  H6 = 'uk-h6',
}

export enum HEADLINE_FONT_WEIGHT {
  DEFAULT = 'default',
  LIGHT = 'light',
  MEDIUM = 'medium',
  BOLD = 'bold',
}

export enum HEADLINE_SHADOW {
  NONE = 'none',
  LARGE = 'large',
}

interface HeadlineProps {
  color?: HEADLINE_COLOR
  className?: string
  fontType?: HEADLINE_FONT_TYPE
  fontWeight?: HEADLINE_FONT_WEIGHT
  headlineStyle?: HEADLINE_STYLE
  noMargin?: boolean
  shadow?: HEADLINE_SHADOW
  tag?: HEADLINE_TAG
  uppercase?: boolean
}

export const Headline: FC<HeadlineProps> = ({
  children,
  className,
  color = HEADLINE_COLOR.DEFAULT,
  fontType = HEADLINE_FONT_TYPE.SERIF,
  fontWeight = HEADLINE_FONT_WEIGHT.DEFAULT,
  headlineStyle,
  noMargin = false,
  shadow = HEADLINE_SHADOW.NONE,
  tag = HEADLINE_TAG.H2,
  uppercase = false,
}) => {
  const HeadlineTag: keyof React.ReactHTML = `${tag}` as keyof React.ReactHTML
  const style = headlineStyle || HEADLINE_STYLE[tag.toUpperCase()]

  return (
    <HeadlineTag
      className={classNames(
        'headline',
        `headline--color-${color}`,
        `headline--font-weight-${fontWeight}`,
        {
          'headline--font-type-sans-serif':
            fontType === HEADLINE_FONT_TYPE.SANS_SERIF,
        },
        { 'headline--no-margin': noMargin },
        { 'headline--uppercase': uppercase },
        { 'headline--shadow-large': shadow === HEADLINE_SHADOW.LARGE },
        `${style}`,
        className,
      )}
    >
      {children}
    </HeadlineTag>
  )
}
