import {
  GET_LANDING_PAGE_WASTE_MANAGER_CONTENT,
  GET_LANDING_PAGE_WASTE_PRODUCER_CONTENT,
} from '../actiontypes/landingpage'

/**
 * @function
 * @name getLandingPageContent
 * @description Loads waste-manager landing page contents
 * @return [Object]
 */
export const getLandingPageWasteManagerContent = () => ({
  type: GET_LANDING_PAGE_WASTE_MANAGER_CONTENT.GET_LANDING_PAGE_WASTE_MANAGER_CONTENT_REQUEST,
})

/**
 * @function
 * @name getLandingPageContent
 * @description Loads waste-producer-landing page contents
 * @return [Object]
 */
export const getLandingPageWasteProducerContent = () => ({
  type: GET_LANDING_PAGE_WASTE_PRODUCER_CONTENT.GET_LANDING_PAGE_WASTE_PRODUCER_CONTENT_REQUEST,
})
