export const InvoiceCheckPositionReviewTranslations = {
  documentDetails: {
    sectionTitle: {
      singular: 'Dokument',
      plural: 'Dokumente',
    },
  },
  orderDetails: {
    sectionTitle: 'Auftrag',
    orderNumber: 'Auftragsnummer',
    aez: 'Kunde',
    billingInterval: 'Abrechnungsintervall',
    permanentDiscount: 'Dauerrabatt',
    collectionAddress: 'Leistungsadresse',
    invoiceCheckNumber: 'Belegnummer',
    invoiceCheckCompany: 'Beleg Entsorgungspartner',
    disposer: 'Partner',
    avv: 'AVV Nummer',
    fraction: 'Abfallart',
    fineFraction: 'Feinfraktion',
    container: 'Behälter',
    numberOfContainers: 'Anzahl der Behälter',
    quantityInCubicMeters: 'Menge in m\u00B3',
    collectionDate: 'Abholdatum',
    deliveryDate: 'Lieferdatum',
    serviceDate: 'Leistungsdatum',
    category: 'Kategorie',
    text: 'Beschreibung',
    securityGroup: 'Schutzklasse / Sicherheitsstufe',
  },
  invoiceCheckPositionReview: {
    sectionTitle: {
      review: 'Prüfung',
      details: 'Prüfungsergebnis',
    },
    internalNote: {
      label: 'Interner Kommentar',
      placeholder: 'Belegnummer angeben',
    },
    serviceDate: 'Datum der Leerfahrt',
    effortDuration: 'Dauer des Aufwands',
    status: 'Status',
    reviewedBy: 'Geprüft von',
    reviewedAt: 'Geprüft am',
    timeOfReview: 'Zeitstempel der Prüfung',
    rejectReason: {
      label: {
        review: 'Ablehnungsgrund',
        details: 'Begründung',
      },
      placeholder: 'Ablehnungsgrund',
    },
    reviewText: {
      label: 'Begründung',
      placeholder: 'Nachricht des Prüfungsergebnisses',
    },
  },
}
