import classNames from 'classnames'
import React, { FC } from 'react'

import { ImageSwatch } from 'components/common/ImageSwatch'

interface CardSwatchProps {
  className?: string
  blurred?: boolean
  name: string
  image: string
}

/**
 * A component which renders the image swatch section with its styles of our new cards.
 * @constructor
 */
export const CardSwatch: FC<CardSwatchProps> = ({
  className,
  blurred,
  name,
  image,
}) => (
  <div className={classNames('new-card__swatch-container', className)}>
    <ImageSwatch
      blur={blurred}
      className='new-card__swatch'
      image={image}
      name={name}
    />
  </div>
)
