import ActionTypes from '../actiontypes/indexes'

/**
 * @function
 * @name getIndexes
 * @description An action method get a list of Indexes.
 * @return {Object}
 */
export const getIndexes = () => ({
  type: ActionTypes.GET_INDEXES_REQUEST,
})

/**
 * @function
 * @name getIndexValues
 * @description An action method get a the index values.
 * @return {Object}
 */
export const getIndexValues = data => ({
  type: ActionTypes.GET_INDEX_VALUES_REQUEST,
  payload: data,
})

/**
 * @function
 * @name changeIndexValues
 * @description An action method get a the index values.
 * @return {Object}
 */
export const changeIndexValues = data => ({
  type: ActionTypes.UPDATE_INDEX_VALUES_REQUEST,
  payload: data,
})

/**
 * @function
 * @name addIndexValue
 * @description An action method get a the index values.
 * @return {Object}
 */
export const addIndexValue = data => ({
  type: ActionTypes.CREATE_INDEX_VALUE_REQUEST,
  payload: data,
})

/**
 * @function
 * @name exportIndexValues
 * @return {Object}
 */
export const exportIndexValues = () => ({
  type: ActionTypes.EXPORT_INDEX_VALUES_REQUEST,
})

/**
 * @function
 * @name resetIndexValues
 * @returns {Object}
 */
export const resetIndexValues = () => ({
  type: ActionTypes.GET_INDEX_VALUES_RESET,
})
