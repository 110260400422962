import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import AppActionTypes from '../actiontypes/app'
import AutoOfferActionTypes from '../actiontypes/autooffer'
import { AUTO_OFFER_STATUS } from '../components/offer/constants'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'
import {
  combineCoarseAndFineFraction,
  replaceDecimalValues,
} from '../helper/offer'

/**
 * @function
 * @name handleGetMyAutoOffers
 * @description Loads all auto offers (templates for automatic bidding) that were created by my company.
 */
export const handleGetMyAutoOffers = createLogic({
  type: AutoOfferActionTypes.GET_MY_AUTO_OFFERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/auto-offer/`

    const data = {
      ...action.payload,
      page: action.payload.page || 1,
    }
    httpClient
      .get(url, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: AutoOfferActionTypes.GET_MY_AUTO_OFFERS_SUCCESS,
          payload: res,
        })
        dispatch({
          type: AppActionTypes.RESET_API_FETCH_ERRORS,
          payload: 'GET_AUTO_OFFER',
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.autoOffer.handleGetMyAutoOffers.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: AutoOfferActionTypes.GET_MY_AUTO_OFFERS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetAutoOffer
 * @description Loads a single auto offer object with all details
 */
export const handleGetAutoOffer = createLogic({
  type: AutoOfferActionTypes.GET_AUTO_OFFER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/auto-offer/${action.payload}/`,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: AutoOfferActionTypes.GET_AUTO_OFFER_SUCCESS,
          payload: {
            ...res,
          },
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.autoOffer.handleGetAutoOffer.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: AutoOfferActionTypes.GET_AUTO_OFFER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateAutoOffer
 * @description Creates a new auto offer
 */
export const handleCreateAutoOffer = createLogic({
  type: AutoOfferActionTypes.CREATE_AUTO_OFFER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    // In the user interface, we make a distinction between fine_fraction and fraction. In the model we only save the
    // most accurate fraction we know (so fine fraction if available).
    let newAutoOffer = combineCoarseAndFineFraction(action.payload)
    const decimalValues = [
      'quantity_in_cubic_meters',
      'rent_price_container_month',
      'disposal_cost_container',
      'transport_price_piece',
      'disposal_cost_ton',
      'handle_cost_ton',
      'surcharge',
      'reduction',
      'compensation_container',
      'compensation_ton',
      'cost_container',
    ]

    newAutoOffer = replaceDecimalValues(newAutoOffer, decimalValues)

    httpClient
      .post(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/auto-offer/`, newAutoOffer)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: AutoOfferActionTypes.CREATE_AUTO_OFFER_SUCCESS,
          payload: {
            ...res,
          },
        })

        if (action.payload.callbacks && action.payload.callbacks.success) {
          action.payload.callbacks.success()
        }

        toastr.success(
          '',
          I18n.t('api.autoOffer.handleCreateAutoOffer.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.autoOffer.handleCreateAutoOffer.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: AutoOfferActionTypes.CREATE_AUTO_OFFER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateAutoOffer
 * @description Updates a new auto offer
 */
export const handleUpdateAutoOffer = createLogic({
  type: AutoOfferActionTypes.UPDATE_AUTO_OFFER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    // In the user interface, we make a distinction between fine_fraction and fraction. In the model we only save the
    // most accurate fraction we know (so fine fraction if available).
    let updatedAutoOffer = combineCoarseAndFineFraction(action.payload)
    const decimalValues = [
      'quantity_in_cubic_meters',
      'rent_price_container_month',
      'disposal_cost_container',
      'transport_price_piece',
      'disposal_cost_ton',
      'handle_cost_ton',
      'surcharge',
      'discount',
    ]
    updatedAutoOffer = replaceDecimalValues(updatedAutoOffer, decimalValues)
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/auto-offer/${updatedAutoOffer.id}/`,
        updatedAutoOffer,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: AutoOfferActionTypes.UPDATE_AUTO_OFFER_SUCCESS,
          payload: {
            ...res,
          },
        })
        // show a different message in the toaster, depending if we are just activating/deactivating the offer or
        // updating the whole object
        // In case of activation / deactivation, we are just sending an object with 2 keys (id and status)
        if (Object.keys(action.payload).length > 2) {
          if (action.payload.callbacks && action.payload.callbacks.success) {
            action.payload.callbacks.success()
          }
          toastr.success(
            '',
            I18n.t('api.autoOffer.handleUpdateAutoOffer.success'),
          )
        } else {
          if (
            action.payload.status &&
            action.payload.status === AUTO_OFFER_STATUS.STATUS_ACTIVE
          ) {
            toastr.success(
              '',
              I18n.t(
                'api.autoOffer.handleUpdateStatusAutoOffer.activation.success',
              ),
            )
          }
          if (
            action.payload.status &&
            action.payload.status === AUTO_OFFER_STATUS.STATUS_INACTIVE
          ) {
            toastr.success(
              '',
              I18n.t(
                'api.autoOffer.handleUpdateStatusAutoOffer.deactivation.success',
              ),
            )
          }
        }
      })
      .then(done)
      .catch(err => {
        let humanReadableError
        if (
          err.response !== undefined &&
          err.response.data !== undefined &&
          err.response.data.non_field_errors !== undefined
        ) {
          humanReadableError = err.response.data.non_field_errors.join('<br />')
        } else {
          humanReadableError = I18n.t(
            'api.autoOffer.handleUpdateAutoOffer.error',
          )
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: AutoOfferActionTypes.UPDATE_AUTO_OFFER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUploadAutoOffers
 * @description triggers order import.
 */
export const handleUploadAutoOffers = createLogic({
  type: AutoOfferActionTypes.UPLOAD_AUTO_OFFERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const data = new FormData()
    data.append('file', action.payload.attachments[0].file)
    data.append('type', action.payload.attachments[0].type)
    data.append('company', action.payload.company)
    data.append('user', action.payload.phone_user)

    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/auto-offer/upload-csv/`,
        data,
      )
      .then(res => {
        dispatch({
          type: AutoOfferActionTypes.UPLOAD_AUTO_OFFERS_SUCCESS,
          payload: res.data,
        })

        if (action.payload.callbacks && action.payload.callbacks.onSuccess)
          action.payload.callbacks.onSuccess()

        toastr.success(
          '',
          I18n.t('api.autoOffer.handleUploadAutoOffersCSV.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.autoOffer.handleUploadAutoOffersCSV.error',
        )
        let responseErrorData = err.response.data
        let showError = false
        if (
          err.response &&
          err.response.status &&
          err.response.status === 409 &&
          err.response.data
        ) {
          responseErrorData = [err.response.data.join(', ')]
          showError = true
        }

        if (action.payload.callbacks && action.payload.callbacks.onError) {
          action.payload.callbacks.onError()
        }

        const responseError = {
          ...err.response,
          data: responseErrorData,
          showError,
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          responseError,
          humanReadableError,
        )

        dispatch({
          type: AutoOfferActionTypes.UPLOAD_AUTO_OFFERS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportAutoOffers
 * @description triggers the auto offer export.
 */
export const handleExportAutoOffers = createLogic({
  type: AutoOfferActionTypes.EXPORT_AUTO_OFFERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/auto-offer/export/`,
        action.payload?.filters,
      )
      .then(() => {
        dispatch({
          type: AutoOfferActionTypes.EXPORT_AUTO_OFFERS_SUCCESS,
        })
        toastr.success(
          '',
          I18n.t('api.autoOffer.handleExportAutoOffers.success'),
        )
      })
      .then(done)
      .catch(err => {
        if (!err.hideToastr)
          toastr.error('', I18n.t('api.autoOffer.handleExportAutoOffers.error'))

        done()
      })
  },
})

export default [
  handleGetMyAutoOffers,
  handleGetAutoOffer,
  handleCreateAutoOffer,
  handleUpdateAutoOffer,
  handleUploadAutoOffers,
  handleExportAutoOffers,
]
