/**
 * @description Returns the name of the container of a given ID and array
 * @param containerId
 * @param containerList
 */
import { DSZ_SHOPS_CHOICES } from './constants'

export const getShop = shopId => {
  return DSZ_SHOPS_CHOICES.find(_shop => _shop.id === shopId)
}
