import ActionTypes from '../actiontypes/inquiry'

// This is the structure of the store
export const initialState = {
  item: {},
  avv: [],
  response: null, // Response from API in case it is not the item
  highlightedInquiry: null,
}

/**
 * @description A reducer for inquiry.
 * @param {Object} state is the current globale state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const inquiryReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_AVVS_SUCCESS:
      return {
        ...state,
        avv: action.payload,
      }
    case ActionTypes.CANCEL_INQUIRY_SUCCESS:
      return {
        ...state,
        item: action.payload,
      }
    case ActionTypes.RESET_HIGHLIGHTED_INQUIRY_SUCCESS:
      return {
        ...state,
        highlightedInquiry: initialState.highlightedInquiry,
      }
    default:
      return state
  }
}

export default inquiryReducer
