import { Form, Formik } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import { UploadItem } from 'components/common/UniversalFileUpload/types'
import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'
import { createCustomOffer } from 'actions/maklerpremium'
import { uploadFiles } from 'actions/attachment'

import { CustomOfferCreateForm } from './CustomOfferCreateForm'

export type CustomOfferCreateFormValues = Partial<{
  attachments: UploadItem[]
  company: number
  custom_offer_number: string
  issued_at: string
  net_price: string
  runtime_of_custom_offer: string
  search_field: string
}>

type CustomOfferCreateModalPropsType = {
  isOpen: boolean
  onClose: () => void
}

export const CustomOfferCreateModal = (
  props: CustomOfferCreateModalPropsType,
) => {
  const { isOpen, onClose } = props

  const dispatch = useDispatch()

  const idCustomOfferCreateModalHeadline = uniqueId()

  const handleSubmit = (values: CustomOfferCreateFormValues) => {
    const uploadCallbacks = {
      onDocumentSubmit: attachments => {
        dispatch(createCustomOffer({ ...values, attachments }))
        onClose()
      },
    }
    if (values.attachments) {
      dispatch(
        uploadFiles([{ file: values.attachments[0].data }], uploadCallbacks),
      )
    }
  }

  return (
    <Modal
      ariaDescribedBy={idCustomOfferCreateModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={I18n.t('offerPipelineTranslations.customOfferCreateModal.title')}
        titleId={idCustomOfferCreateModalHeadline}
      />
      <div className='uk-modal-body'>
        <p>
          {I18n.t(
            'offerPipelineTranslations.customOfferCreateModal.description',
          )}
        </p>
      </div>
      <Formik
        initialValues={{
          attachments: undefined,
          company: undefined,
          custom_offer_number: '',
          issued_at: '',
          net_price: '',
          runtime_of_custom_offer: '',
          search_field: 'company_name',
        }}
        onSubmit={(values: CustomOfferCreateFormValues) => {
          const valuesToSend = {
            ...values,
            issued_at: `${values.issued_at} ${
              values.runtime_of_custom_offer?.split(' ')[1]
            }`,
          }
          handleSubmit(valuesToSend)
        }}
        validationSchema={() =>
          yup.object().shape({
            attachments: yup
              .mixed()
              .required(
                I18n.t(
                  'offerPipelineTranslations.customOfferCreateModal.form.attachments.error',
                ),
              )
              .test(
                'length',
                I18n.t(
                  'offerPipelineTranslations.customOfferCreateModal.form.attachments.error',
                ),
                value => value?.length > 0,
              )
              .test(
                'type',
                I18n.t(
                  'offerPipelineTranslations.customOfferCreateModal.form.attachments.typeError',
                ),
                value => value && value[0]?.type === 'application/pdf',
              ),
            company: yup
              .number()
              .required(
                I18n.t(
                  'offerPipelineTranslations.customOfferCreateModal.form.company.error',
                ),
              ),
            custom_offer_number: yup
              .string()
              .required(
                I18n.t(
                  'offerPipelineTranslations.customOfferCreateModal.form.customOfferNumber.error',
                ),
              ),
            issued_at: yup
              .string()
              .required(
                I18n.t(
                  'offerPipelineTranslations.customOfferCreateModal.form.issuedAt.error',
                ),
              ),
            net_price: yup
              .string()
              .required(
                I18n.t(
                  'offerPipelineTranslations.customOfferCreateModal.form.net_price.error',
                ),
              ),
            runtime_of_custom_offer: yup
              .string()
              .required(
                I18n.t(
                  'offerPipelineTranslations.customOfferCreateModal.form.runtimeOfCustomOffer.error',
                ),
              ),
            search_field: yup.string(),
          })
        }
      >
        <Form
          className='custom-offer-form'
          data-testid='custom-offer-form'
          noValidate
        >
          <CustomOfferCreateForm onClose={onClose} />
        </Form>
      </Formik>
    </Modal>
  )
}
