import { useFormikContext } from 'formik'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { InputText } from 'components/common/InputText'
import { NoContentMessage } from 'components/common/NoContentMessage'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { Option } from 'components/common/StaticCombobox/StaticCombobox'
import { INVOICE_CHECK_STATES } from 'components/maklerpremium/constants'
import { getCurrentUserSelector } from 'selectors/user'

import { getCurrentValueObject, resetInputOption } from '../../helpers'
import { CommonFilterProps } from '../../types'
import { FilterReset } from '../FilterReset'
import { cleanUpAndCountFiltersForReset } from '../FilterReset/helpers'

interface InvoiceCheckFilterProps extends CommonFilterProps {
  action: (page: any, filters: any) => any // sorry, Redux with redux-logics is untypeable
  setCurrentFilterValues: (currentFilters: any) => void
  currentFilterValues: any
}

export const InvoiceCheckFilter: FC<InvoiceCheckFilterProps> = ({
  action,
  companyList,
  handleBlur,
  handleChange,
  isLoading,
  length,
  resetForm,
  setCurrentFilterValues,
  currentFilterValues,
  values,
}) => {
  const dispatch = useDispatch()
  const [currentSelectedCompany, setCurrentSelectedCompany] = useState<Option>()
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<Option>()
  const [filterWasReset, setFilterWasReset] = useState(false)

  const user = useSelector(getCurrentUserSelector)
  const { setFieldValue } = useFormikContext()
  const [companyChanged, setCompanyChanged] = useState(false)

  const selectionItems = useMemo(
    () => ({
      companyList: [
        ...resetInputOption,
        ...companyList.map(item => ({
          label: item.name,
          value: `${item.id}`,
        })),
      ],
      invoiceStateList: [
        ...resetInputOption,
        ...INVOICE_CHECK_STATES.map(item => ({
          label: `${I18n.t(item.name)}`,
          value: `${item.id}`,
        })),
      ],
    }),
    [companyList],
  )

  useEffect(() => {
    setCurrentSelectedStatus(
      getCurrentValueObject(selectionItems.invoiceStateList, values.status),
    )
  }, [selectionItems.invoiceStateList, values.status])

  useEffect(() => {
    setCurrentSelectedCompany(
      getCurrentValueObject(selectionItems.companyList, String(values.company)),
    )
  }, [selectionItems.companyList, values.company])

  useEffect(() => {
    if (!companyChanged && currentFilterValues.company) {
      // an initial filter value can be passed in 'currentFilterValues' but has to be set in 'values'
      setFieldValue('company', currentFilterValues.company)
      setCompanyChanged(true)
    }
    const requestValues = {
      ...currentFilterValues,
      company: values.company,
      document_number: values.document_number,
      status: values.status,
    }
    if (
      filterWasReset ||
      JSON.stringify(currentFilterValues) !== JSON.stringify(requestValues)
    ) {
      setCurrentFilterValues(requestValues)
      dispatch(action(null, requestValues))
      if (filterWasReset) {
        setFilterWasReset(false)
      }
    }
  }, [
    companyChanged,
    setFieldValue,
    action,
    currentFilterValues,
    dispatch,
    filterWasReset,
    setCurrentFilterValues,
    user.company,
    values,
  ])

  return (
    <>
      <div className='uk-grid uk-child-width-1-5@l uk-child-width-1-2@m'>
        <StaticCombobox
          isLoading={isLoading}
          label={I18n.t('filterTranslations.invoiceCheckFilter.company')}
          name='company'
          noResultsText={I18n.t('filterTranslations.noInputResults')}
          options={selectionItems.companyList}
          onSelectionChange={e => {
            handleChange(e)
            setCompanyChanged(true)
            if (!e.target.value && !values.document_number && !values.status) {
              resetForm()
              setFilterWasReset(true)
            }
          }}
          placeholder={I18n.t('general.placeholder.all')}
          selectedOption={currentSelectedCompany}
        />
        <InputText
          label={I18n.t('filterTranslations.invoiceCheckFilter.documentNumber')}
          maxLength={15}
          name='document_number'
          onBlur={handleBlur}
          onChange={e => {
            handleChange(e)
            if (!e.target.value && !values.company && !values.status) {
              resetForm()
              setFilterWasReset(true)
            }
          }}
          value={values.document_number}
        />
        <StaticCombobox
          isLoading={isLoading}
          label={I18n.t('filterTranslations.invoiceCheckFilter.status')}
          name='status'
          noResultsText={I18n.t('filterTranslations.noInputResults')}
          options={selectionItems.invoiceStateList}
          onSelectionChange={e => {
            handleChange(e)
            if (!e.target.value && !values.document_number && !values.company) {
              resetForm()
              setFilterWasReset(true)
            }
          }}
          placeholder={I18n.t('general.placeholder.all')}
          selectedOption={currentSelectedStatus}
        />
        <FilterReset
          onResetFilter={() => {
            resetForm()
            setFilterWasReset(true)
          }}
          showResetFilterButton={
            cleanUpAndCountFiltersForReset(currentFilterValues) > 0
          }
        />
      </div>
      <div className='filter__empty-message'>
        {!isLoading && length < 1 && (
          <NoContentMessage
            message={I18n.t('general.emptyFilterResultMessage')}
            showResetFilterButton={false}
          />
        )}
      </div>
    </>
  )
}
