import { FormPropsType } from '../../common/DetailsPage/constants'

export interface CreateOrderValuesType {
  delivery_date: number | string
  time_of_day_delivery: number | string
  number_of_containers: number | string
  quantity_in_cubic_meters: number | string
  additional_information?: string
}

export interface NewOrderFormPropsType extends FormPropsType {
  values: CreateOrderValuesType
}

export enum SHOULD_FETCH_INVOICE_CHECK_POSITION {
  DO_NOT_FETCH,
  WAIT_FOR_NEW_ORDER_ID,
  FETCH_NEW,
}
