// eslint-disable-next-line import/prefer-default-export
import keyMirror from 'keymirror'

export const ZIP_CODE_HERO_STEP = {
  ZIP_CODE_INPUT: 0,
  OFFER_SEARCH: 1,
  OFFER_RESULTS: 2,
}

export const HERO_MODALS = keyMirror({
  REGION_MAP_MODAL: null,
})

export const SEARCH_RESULTS_SCROLL_ANCHOR = 'zipciode-offer-results'
export const ZIPCODE_INPUT_HEADLINE_SCROLL_ANCHOR = 'zipcode-headline'
