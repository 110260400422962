export const TVPDashboardTranslations = {
  // BUTTON BAR
  offerCreate: {
    buttonText: 'EPD Angebot erstellen',
  },
  // FIRST ROW
  ordersTodayCount: {
    buttonText: 'Alle Aufträge ansehen',
    caption: {
      singular: 'Auftrag heute',
      plural: 'Aufträge heute',
    },
  },
  ordersThisMonthCount: {
    buttonText: 'Alle Aufträge ansehen',
    caption: {
      singular: 'Auftrag Monat',
      plural: 'Aufträge Monat',
    },
  },
  salesMonthly: {
    buttonText: 'Umsatz ansehen',
    caption: 'Monatsumsatz',
  },
  // ACTIONS REQUIRED IS IN BASE TRANSLATION
  // SECOND ROW
  upcomingOrders: {
    buttonText: 'Aufträge verwalten',
    value: 'Geplante Aufträge',
    caption: 'Übersicht der für die nächsten 2 Werktage geplanten TVP-Aufträge',
  },
  missingQuantityReports: {
    buttonText: 'Meldungen verwalten',
    value: 'Fehlende Mengenmeldungen',
    caption: 'Übersicht über sämtliche fehlende Mengenmeldungen',
  },
  // CUSTOMER MANAGE IS IN BASE TRANSLATION
  partnerCommissioning: {
    buttonText: 'Entsorger beauftragen',
    value: 'Entsorgerbeauftragung',
    caption: 'Entsorger für Abholung von Transportverpackungen beauftragen',
  },
}
