import './DismissableInfo.scss'

import classNames from 'classnames'
import React, { FC, useState } from 'react'

import { Button, BUTTON_BACKGROUND_COLOR } from '../Button'

interface DismissableInfoProps {
  buttonText?: string
  className?: string
  onClick?: () => void
  text?: string | React.ReactNode
  title?: string
  disableOnAnimationEnd?: boolean
}

/**
 * renders an info box with hiding feature
 * @constructor
 */
export const DismissableInfo: FC<DismissableInfoProps> = ({
  // When buttonText is missing the button won't be displayed
  buttonText,
  className,
  onClick = () => undefined,
  text,
  title,
  children,
  // Avoids that DismissableInfo can be closed by input when button is hidden
  disableOnAnimationEnd = false,
}) => {
  const [infoClicked, setInfoClicked] = useState<boolean>(false)
  const [infoHidden, setInfoHidden] = useState<boolean>(false)

  /**
   * fires callbacks after animation for smoother rendering
   */
  const handleAnimationEnd = () => {
    setInfoHidden(true)
  }

  const handleOnClick = () => {
    if (!disableOnAnimationEnd) {
      setInfoClicked(true)
    }
    onClick()
  }

  if (infoHidden) return null

  return (
    <div
      className={classNames(
        {
          'dismissable-info': true,
          'dismissable-info--fading-out': infoClicked,
        },
        className,
      )}
      onAnimationEnd={!disableOnAnimationEnd ? handleAnimationEnd : undefined}
    >
      {title && <strong className='dismissable-info__title'>{title}</strong>}
      {text && <p className='dismissable-info__text'>{text}</p>}
      {buttonText && (
        <Button
          className='dismissable-info__button'
          onClick={handleOnClick}
          backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
        >
          {buttonText}
        </Button>
      )}
      {children}
    </div>
  )
}
