export { AddPriceModalFormikWrapper } from './AddPriceModalFormikWrapper'

export { AddPriceModalForm } from './AddPriceModalForm'

export enum DszPriceManagementShopType {
  SHOP_VERPACKUNGSLIZENZIERUNG = 2,
  SHOP_ZMART_CHINA = 3,
}

export type AddPriceModalFormValues = {
  fraction: number | undefined
  price_xs: string
  price_s: string
  price_m: string
  price_l: string
  price_xl: string
  price_xxl: string
  shop?: number
  year: string | number
}
