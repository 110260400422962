import { Form, useFormikContext } from 'formik'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { BUTTON_TYPE } from 'components/common/Button'
import {
  BUTTON_BACKGROUND_COLOR,
  ProgressButton,
} from 'components/common/ProgressButton'
import { Textarea } from 'components/common/Textarea'

export interface AboutUsValues {
  about_us: string
}

/**
 * @description This component displays the about us text of company
 */
export const AboutUsForm: FC<{ maxLength?: number }> = ({
  maxLength = 500,
}) => {
  const { values, setValues, isValid } = useFormikContext<AboutUsValues>()

  return (
    <Form>
      <Textarea
        maxLength={maxLength}
        rows={25}
        name='about_us'
        placeholder={I18n.t('aboutUsFormTranslations.placeholder')}
        onChange={event =>
          setValues({ ...values, about_us: event.target.value })
        }
        value={values.about_us}
        showRemainingCharacters
      />
      <ProgressButton
        backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
        className='uk-margin-top uk-float-right uk-width-1-1 uk-width-1-3@m'
        type={BUTTON_TYPE.SUBMIT}
        isLoading={false}
        onClick={() => undefined}
        isDisabled={!isValid}
      >
        <Translate value='general.button.save' />
      </ProgressButton>
    </Form>
  )
}
