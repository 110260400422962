const myAutoOffersPageTranslations = {
  heading: 'Angebotsassistent',
  list: {
    zipcodes: 'Postleitzahlen',
    communities: 'Landkreise',
  },
  archive: {
    heading: 'Angebotsassistent Archiv',
  },
  inactiveCompany: {
    message: {
      start:
        'Bevor Sie automatisiert bieten können, benötigen wir noch Ihr gültiges Efb-Zertifikat und ggf. eine ' +
        'Eichbescheinigung. Die Uploadfunktion sowie den aktuellen Status Ihrer Nachweise finden Sie ',
      link: 'hier',
      end: '.',
    },
    title: 'Sie können aktuell den Angebotsassistenten nicht nutzen.',
  },
  exportAutoOffers: {
    title: 'Angebotsassistent CSV-Export',
  },
  lockAutoOffers: {
    warningMessage:
      'Sobald der Angebotsassistent pausiert, werden keine automatisierten Angebote zu neuen ' +
      'Anfragen abgegeben. Sie können den Angebotsassistenten jederzeit wieder aktivieren.',
    modal: {
      title: 'Angebotsassistent wirklich pausieren?',
    },
  },
  manageAutoOffers: {
    modal: {
      message:
        'Hier können alle Jobs eines Unternehmens pausiert oder das Pausieren wieder aufgehoben werden. ' +
        'Einfach das Unternehmen und den User auswählen und den Button betätigen.',
      title: 'Jobs verwalten',
      button_pause: 'Angebotsassistent pausieren',
      button_unpause: 'Pausieren aufheben',
      cancel: 'Abbrechen',
      label: {
        company: 'Unternehmen',
        user: 'Benutzer',
      },
    },
  },
  unlockAutoOffers: {
    title: 'Ihr Angebotsassistent ist pausiert',
    button: 'Angebotsassistent aktivieren',
    message:
      'Aktuell werden keine automatisierten Angebote zu neuen Anfragen abgegeben. Sie können den ' +
      'Angebotsassistenten und damit alle Jobs wieder aktivieren, um Angebote wieder automatisiert abzugeben.',
    warningMessage:
      'Sind Sie sicher, dass Sie die Pausierung beenden möchten? Alle gültigen Jobs ' +
      'werden wieder aktiviert.',
    modal: {
      title: 'Angebotsassistent wirklich aktivieren?',
    },
  },
  form: {
    header: {
      title: 'Neuen Job anlegen',
      titleEdit: 'Job bearbeiten',
    },
    info: {
      modified: 'Zuletzt bearbeitet am %{date} um %{time} von %{name}.',
      created: 'Dieser Job wurde am %{date} um %{time} von %{name} erstellt.',
    },
    fields: {
      validFrom: {
        label: 'Gültig ab',
      },
      validUntil: {
        label: 'Gültig bis',
      },
      zipcodeControl: {
        placeholder: 'Postleitzahlen',
        loadingMessage: 'Lade Postleitzahlen ...',
        noInputMessage:
          'Bitte geben Sie die ersten drei Stellen einer Postleitzahl ein.',
        noOptionsMessage: 'Keine passenden Postleitzahlen gefunden.',
      },
      communityControl: {
        placeholder: 'Landkreise',
        loadingMessage: 'Lade Landkreise ...',
        noInputMessage:
          'Bitte geben Sie die ersten drei Anfangsbuchstaben eines Landkreises ein.',
        noOptionsMessage: 'Keine passenden Landkreise gefunden.',
      },
      businessSegment: {
        label: 'Adressierte Kunden',
        tooltip:
          'Mit diesem Feld legen Sie fest für welches Geschäftsfeld Ihre Konditionen gelten.',
        placeholder: 'Adressierte Kunden',
        options: {
          all: 'Alle',
          empto: 'Nur empto',
          epd: 'Nur Entsorgung.de',
        },
      },
    },
    validation: {
      companyError: 'Bitte wählen Sie ein Unternehmen aus',
      phoneUserError: 'Bitte wählen Sie einen Kunden aus',
      businessSegmentError: 'Bitte wählen Sie einen Adressierte Kunden aus',
      fractionError: 'Bitte wählen Sie eine Abfallart aus.',
      securityGroupError:
        'Bitte wählen Sie eine Schutzklasse und Sicherheitsstufe aus.',
      containerError: 'Bitte wählen Sie einen Behälter aus.',
      avvError: 'Bitte wählen Sie einen AVV Nummer aus.',
      orderTypeError: 'Bitte wählen Sie eine Auftragsart aus.',
      zipcodesError:
        'Wählen Sie mindestens eine Postleitzahl oder einen Landkreis aus.',
      communitiesError:
        'Wählen Sie mindestens einen Landkreis oder eine Postleitzahl aus.',
      validFromError: 'Geben Sie ein gültiges Datum für "Gültig ab" ein',
      validUntilError: 'Geben Sie ein gültiges Datum für "Gültig bis" ein',
      priceModelError: 'Geben Sie ein gültiges Prisemodell an',
      validFromIsAfterValidUntil:
        '"Gültig ab" kann nicht nach "Gültig bis" liegen.',
      validUntilIsBeforeValidFrom:
        '"Gültig bis" kann nicht vor "Gültig ab" liegen.',
      numberOfContainersError: 'Bitte geben Sie einen korrekten Wert an.',
      quantityInCubicMetersError: 'Bitte geben Sie einen korrekten Wert an.',
      globalFormSubmissionError: 'Bitte überprüfen Sie Ihre Eingaben.',
      disposalCostTon:
        'Bitte geben Sie einen Wert zwischen 0 € und 1999,99 € ein.',
    },
    submitButton: {
      create: 'Job aktivieren',
      update: 'Job aktualisieren',
    },
  },
  actionButtons: {
    add: 'Neuen Job einrichten +',
    archive: 'Archiv',
    lock: 'Angebotsassistent pausieren',
    unlock: 'Angebotsassistent aktivieren',
    manage: 'Jobs verwalten',
    upload: 'CSV Import',
  },
  toastr: {
    success: 'Job wurde erfolgreich gespeichert.',
    error: 'Speichern des Jobs fehlgeschlagen.',
    lock: {
      success: 'Angebotsassistent pausiert.',
    },
    unlock: {
      success: 'Angebotsassistent aktiviert.',
    },
  },
  csvImport: {
    title: 'CSV Import',
    description:
      '<p>Der CSV Import ermöglicht Ihnen die leichte Änderung bestehender Jobs. Bitte aktualisieren Sie ' +
      'einfach Ihre Werte wie Preise, Postleitzahlen oder die Gültigkeit Ihrer Jobs im CSV Export und laden die ' +
      'bearbeitete Tabelle hier wieder hoch. Gerne unterstützt Sie der empto Kundenservice telefonisch unter ' +
      '<a href="tel:+498000056356">0800 00 56356</a>.</p>',
    label: 'CSV Datei',
  },
}

export default myAutoOffersPageTranslations
