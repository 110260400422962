import { useFormikContext } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { BUSINESS_SEGMENT } from 'constants/app'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'
import { RadioButton } from 'components/common/RadioButton'
import {
  getCoarseFractionItemsForSegment,
  getContainerItems,
} from 'components/inquiry/helpers'
import {
  CATEGORY_CHOICES,
  STAGE_CHOICES,
  WASTE_CATEGORY_CHOICES,
} from 'components/maklerpremium/CommunicationCenterPage/constants'
import {
  isFieldValid,
  setRadioButtonValue,
} from 'components/maklerpremium/CommunicationCenterPage/helper'
import { NewEntryValues } from 'components/maklerpremium/CommunicationCenterPage/types'
import {
  StaticCombobox,
  Option,
} from 'components/common/StaticCombobox/StaticCombobox'

import { CommunityZipcodeForm } from './CommunityZipcodeForm'

/**
 * @description This component displays the stage 2 part of the operations within the main modal form of the communication center page
 */
export const CategoryOperationsStage2Form = () => {
  const {
    values,
    errors,
    submitCount,
    validateForm,
    handleChange,
    setFieldValue,
  } = useFormikContext<NewEntryValues>()

  const formikContext = {
    values,
    errors,
    submitCount,
    validateForm,
    handleChange,
    setFieldValue,
  }

  // State
  const [coarseFractionOptions, setCoarseFractionOptions] = useState<Option[]>(
    [],
  )

  // Redux
  const fractionList = useSelector<any, Fraction[]>(getFractionsSelector)
  const containerList = useSelector<any, Container[]>(getContainersSelector)

  //Memo
  const containerTypeOptions = useMemo(() => {
    let containers: Container[] = containerList.filter(
      container =>
        Number(container.business_segment) === BUSINESS_SEGMENT.BUSINESS_EPD,
    )

    if (values.fraction) {
      containers = getContainerItems(
        values.fraction,
        // fineFractionId is set to undefined, because the form
        // does not have a field for the fine fraction
        undefined,
        fractionList,
        containerList,
        BUSINESS_SEGMENT.BUSINESS_EPD,
      )
    }

    const containerTypes = new Set(containers.map(container => container!.name))
    return Array.from(containerTypes.values()).map(item => ({
      value: item,
      label: item,
    }))
  }, [fractionList, containerList, values.fraction])

  const selectedContainerTypeOption = useMemo(() => {
    if (values.container_type) {
      return containerTypeOptions.find(
        containerType => containerType.value === values.container_type,
      )
    }

    if (values.fraction) {
      const containers: Container[] = getContainerItems(
        values.fraction,
        // fineFractionId is set to undefined, because the form
        // does not have a field for the fine fraction
        undefined,
        fractionList,
        containerList,
        BUSINESS_SEGMENT.BUSINESS_EPD,
      )

      const containerTypes = new Set(
        containers.map(container => container!.name),
      )
      if (containerTypes.size === 1) {
        const value = Array.from(containerTypes.values())[0]

        return {
          value,
          label: value,
        }
      } else {
        return { label: '', value: '' }
      }
    }
  }, [
    containerTypeOptions,
    fractionList,
    containerList,
    values.fraction,
    values.container_type,
  ])

  const selectedFraction = useMemo(() => {
    if (values.fraction) {
      return coarseFractionOptions.find(
        fraction => fraction.value === Number(values.fraction),
      )
    }
  }, [coarseFractionOptions, values.fraction])

  useEffect(() => {
    const coarseFractions = getCoarseFractionItemsForSegment(
      fractionList,
      BUSINESS_SEGMENT.BUSINESS_EPD,
    )
    setCoarseFractionOptions(
      coarseFractions.map(item => ({
        value: item.id,
        label: item.name,
      })),
    )
  }, [fractionList])

  return (
    <>
      <div className='communicationcenterpage-new-entry-all-stages-form__radio-button-group'>
        <Translate
          value='communicationCenterPageTranslations.fields.waste_category.label'
          className='communicationcenterpage-new-entry-all-stages-form__radio-button-group-label'
        />
        <div className='communicationcenterpage-new-entry-all-stages-form__radio-button-group-buttons'>
          <RadioButton
            isChecked={
              values.waste_category === `${WASTE_CATEGORY_CHOICES.CHOICE_ALL}`
            }
            label={I18n.t(
              'communicationCenterPageTranslations.fields.waste_category.options.all',
            )}
            name='waste_category'
            onChange={async (event: React.ChangeEvent<any>) => {
              await setRadioButtonValue({
                name: 'waste_category',
                event,
                value: WASTE_CATEGORY_CHOICES.CHOICE_ALL,
                formikContext,
              })
            }}
          />
          <RadioButton
            isChecked={
              values.waste_category ===
              `${WASTE_CATEGORY_CHOICES.CHOICE_SPECIFIC}`
            }
            label={I18n.t(
              'communicationCenterPageTranslations.fields.waste_category.options.specific',
            )}
            name='waste_category'
            onChange={async (event: React.ChangeEvent<any>) => {
              await setRadioButtonValue({
                name: 'waste_category',
                event,
                value: WASTE_CATEGORY_CHOICES.CHOICE_SPECIFIC,
                formikContext,
              })
            }}
          />
        </div>
      </div>
      {values.category === `${CATEGORY_CHOICES.CHOICE_OPERATIONS}` &&
        values.stage === `${STAGE_CHOICES.CHOICE_STAGE2}` &&
        values.waste_category ===
          `${WASTE_CATEGORY_CHOICES.CHOICE_SPECIFIC}` && (
          <StaticCombobox
            label={I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.2.fields.fraction.label',
            )}
            onSelectionChange={event => {
              setFieldValue('fraction', String(event.target.value))
              setFieldValue('container_type', '')
            }}
            name='fraction'
            noResultsText={I18n.t(
              'communicationCenterPageTranslations.fields.waste_category.options.specific',
            )}
            options={coarseFractionOptions}
            placeholder={I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.2.fields.fraction.label',
            )}
            withCheckmark
            showCheckmark={() => isFieldValid('fraction', formikContext)}
            selectedOption={selectedFraction}
          />
        )}
      <CommunityZipcodeForm />
      <StaticCombobox
        label={I18n.t(
          'communicationCenterPageTranslations.fields.container_type.label',
        )}
        name='container'
        noResultsText={I18n.t(
          'communicationCenterPageTranslations.fields.container_type.noResults',
        )}
        options={containerTypeOptions}
        placeholder={I18n.t(
          'communicationCenterPageTranslations.fields.container_type.placeholder',
        )}
        onSelectionChange={event => {
          setFieldValue('container_type', String(event.target.value))
        }}
        withCheckmark
        showCheckmark={() => isFieldValid('container_type', formikContext)}
        isDisabled={containerTypeOptions.length === 0}
        selectedOption={selectedContainerTypeOption}
      />
    </>
  )
}
