import './style.scss'

import React, { Component } from 'react'
import { arrayOf, func, shape, string } from 'prop-types'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'

/**
 * @description This component renders and controls ItemSelectionDetails.
 * @function
 * @param {Object} props the component props
 */
class ItemSelectionDetails extends Component {
  static propTypes = {
    item: shape(),
    onConfirm: func,
    onReturn: func,
    renderItemDetails: func,
    content: arrayOf(shape()),
    confirmText: string.isRequired,
    backToSelectionText: string.isRequired,
  }

  static defaultProps = {
    item: null,
    onConfirm: () => undefined,
    onReturn: () => undefined,
    renderItemDetails: null,
    content: [],
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    const {
      item,
      onReturn,
      onConfirm,
      renderItemDetails,
      content,
      backToSelectionText,
      confirmText,
    } = this.props

    const selectedItemContent = content.filter(
      c => c.fraction === item.id || c.container === item.id,
    )

    return (
      <div className='item-selection-details'>
        {renderItemDetails(item, selectedItemContent)}
        {/* BUTTONS */}
        <div className='uk-modal-footer item-selection-details__buttons'>
          <Button
            className='item-selection-details__buttons--back'
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            onClick={onReturn}
          >
            {backToSelectionText}
          </Button>

          <Button
            className='item-selection-details__buttons--select'
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            onClick={onConfirm}
          >
            {confirmText}
          </Button>
        </div>
      </div>
    )
  }
}

export default ItemSelectionDetails
