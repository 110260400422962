import uniqueId from 'lodash.uniqueid'
import React, { Component } from 'react'
import { arrayOf, bool, func, shape, string } from 'prop-types'

import ModalHeader from 'components/common/ModalHeader'
import { Modal, MODAL_SIZE } from 'components/common/Modal'

import ItemSelectionDetails from '../ItemSelectionDetails'
import ItemSelectionList from '../ItemSelectionList'

/**
 * @description This component renders and controls ItemListModal.
 * @function
 * @param {Object} props the component props
 */
class ItemListModal extends Component {
  static propTypes = {
    title: string,
    state: bool,
    callback: func,
    content: arrayOf(shape()).isRequired,
    itemList: arrayOf(shape()).isRequired,
    handleChange: func,
    renderItemDetails: func,
    confirmText: string.isRequired,
    backToSelectionText: string.isRequired,
    informationText: string.isRequired,
  }

  static defaultProps = {
    title: 'Title',
    state: false,
    callback: () => undefined,
    handleChange: () => undefined,
    renderItemDetails: null,
  }

  state = {
    chosenItem: null,
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.chosenItem !== this.state.chosenItem) {
      // calling window.scrollTo(0,0) does not work, because the modal has an overlay which does not lay onto the
      // window object. So, we first need to find the modal overlay and force it to scroll to top.
      // Needed so every time the user selects an item, the modal is always in the original position.
      // See discussion on ticket #983
      const elem = document.querySelector('.modal__overlay')
      elem.scrollTop = 0 // MS-Edge doesn't support element.scrollTo, scrollTop is an equal alternative here
    }
  }

  /**
   * @description: Changes the state of chosenItem when user selects an item
   * @param itemId
   */
  handleChoseItem = itemId => {
    this.setState({ chosenItem: itemId })
  }

  /**
   * @description: Confirms the item selection when user selects clicks on "select item button" on the item details
   */
  handleConfirmItem = () => {
    this.props.handleChange(this.state.chosenItem)
    this.handleClearSelection()
    this.props.callback()
  }

  /**
   * @description: Confirms the item selection when user selects click on the item, without going to the details
   * @param chosenItem
   */
  handleDirectSelection = chosenItem => {
    this.props.handleChange(chosenItem)
    this.handleClearSelection()
    this.props.callback()
  }

  /**
   * @description: clears the current selection when clicking "back" on the item details
   */
  handleClearSelection = () => {
    this.setState({ chosenItem: null })
  }

  /**
   * @description: Closes the modal and clears any already made selection
   */
  handleCloseModal = () => {
    this.handleClearSelection()
    this.props.callback()
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    const {
      state,
      itemList,
      title,
      renderItemDetails,
      content,
      confirmText,
      backToSelectionText,
      informationText,
    } = this.props

    const modalId = uniqueId() // from lodash package. Generate a unique ID
    return (
      <div className='item-list-modal'>
        <Modal
          ariaDescribedBy={modalId}
          isOpen={state}
          onClose={this.handleCloseModal}
          size={MODAL_SIZE.LARGE}
        >
          <ModalHeader
            onClose={this.handleCloseModal}
            title={title}
            titleId={modalId}
          />
          {!this.state.chosenItem && (
            <ItemSelectionList
              itemList={itemList}
              onSelect={
                renderItemDetails
                  ? this.handleChoseItem
                  : this.handleDirectSelection
              }
              informationText={informationText}
            />
          )}

          {this.state.chosenItem && renderItemDetails && (
            <ItemSelectionDetails
              onConfirm={this.handleConfirmItem}
              onReturn={this.handleClearSelection}
              item={itemList.find(item => item.id === this.state.chosenItem)}
              renderItemDetails={renderItemDetails}
              content={content}
              backToSelectionText={backToSelectionText}
              confirmText={confirmText}
            />
          )}
        </Modal>
      </div>
    )
  }
}

export default ItemListModal
