import './style.scss'

import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import Logo320GradImage from 'assets/img/homepage/logo-320-grad.png'
import LogoDeutschesHandwerksblattImage from 'assets/img/homepage/logo-deutsches-handwerksblatt.svg'
import LogoHandwerksblattDeImage from 'assets/img/homepage/logo-handwerksblatt-de.svg'
import LogoRecyclingMagazineImage from 'assets/img/homepage/logo-recycling-magazin.png'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'

export const KnownFromSection: FC = () => (
  <section className='known-from-section'>
    <div className='known-from-section__container'>
      <div className='known-from-section__item known-from-section__item--headline'>
        <Headline
          color={HEADLINE_COLOR.GRAYISH_BROWN}
          fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
          fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
          headlineStyle={HEADLINE_STYLE.H2}
          tag={HEADLINE_TAG.H2}
          noMargin
        >
          <Translate value='homepages.wasteProducer.knownFrom.headline' />
          <span aria-hidden='true'>&nbsp;|</span>
        </Headline>
      </div>
      <div className='known-from-section__item known-from-section__item--image'>
        <img
          alt={I18n.t('homepages.wasteProducer.knownFrom.logos.320grad')}
          className='known-from-section__logo known-from-section__logo--square'
          src={Logo320GradImage}
          width='80'
        />
      </div>
      <div className='known-from-section__item known-from-section__item--image'>
        <img
          alt={I18n.t(
            'homepages.wasteProducer.knownFrom.logos.recyclingMagazin',
          )}
          className='known-from-section__logo known-from-section__logo--rectangle'
          src={LogoRecyclingMagazineImage}
          width='200'
        />
      </div>
      <div className='known-from-section__item known-from-section__item--image'>
        <img
          alt={I18n.t(
            'homepages.wasteProducer.knownFrom.logos.deutschesHandwerksblatt',
          )}
          className='known-from-section__logo known-from-section__logo--rectangle'
          src={LogoDeutschesHandwerksblattImage}
          width='200'
        />
      </div>
      <div className='known-from-section__item known-from-section__item--image'>
        <img
          alt={I18n.t(
            'homepages.wasteProducer.knownFrom.logos.handwerksblattDe',
          )}
          className='known-from-section__logo known-from-section__logo--rectangle'
          src={LogoHandwerksblattDeImage}
          width='200'
        />
      </div>
    </div>
  </section>
)
