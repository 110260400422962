import React, { FC, ReactNode, useState } from 'react'

import { ThumbsButton } from './ThumbsButton'

interface ThumbsSelectorProps {
  onClick: (setEvaluation: boolean) => void
  thumbsUpSelected?: boolean
  thumbsDownSelected?: boolean
  disabled?: boolean
  children?: ReactNode
}

export const ThumbsSelector: FC<ThumbsSelectorProps> = ({
  onClick,
  thumbsUpSelected = false,
  thumbsDownSelected = false,
  disabled = false,
  children = undefined,
}) => {
  const [thumbsUp, setThumbsUp] = useState(thumbsUpSelected)
  const [thumbsDown, setThumbsDown] = useState(thumbsDownSelected)

  return (
    <div className='uk-flex uk-flex-middle uk-flex-between'>
      {children}

      <div className='uk-flex uk-flex-middle'>
        <div>
          <ThumbsButton
            isDisabled={disabled}
            isSelected={thumbsUp}
            onClick={() => {
              setThumbsUp(!thumbsUp)
              if (thumbsDown) {
                setThumbsDown(!thumbsDown)
              }
              return onClick(true)
            }}
          />
        </div>

        <div className='uk-margin-small-left'>
          <ThumbsButton
            direction='down'
            isSelected={thumbsDown}
            isDisabled={disabled}
            onClick={() => {
              setThumbsDown(!thumbsDown)
              if (thumbsUp) {
                setThumbsUp(!thumbsUp)
              }
              return onClick(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}
