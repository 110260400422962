export const WastemanagerHeroSectionTranslation = {
  wastemanagerHeroSection: {
    headline: 'Abfallmanager',
    description:
      'Der empto Abfallmanager ist ein hilfreicher Assistent für die digitale Organisation und Planung Ihrer' +
      ' laufenden externen und der über empto geschlossenen Vereinbarungen. Alle Entsorgungsvorgänge können Sie somit' +
      ' unkompliziert über den empto Abfallmanager organisieren – digital und übersichtlich an einem Ort.',
    callToAction: 'Jetzt registrieren und durchstarten',
  },
}
