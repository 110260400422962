import { Form, useFormikContext } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, { FC, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import Spinner from 'react-spinkit'

import { UserPermission } from 'constants/user'
import { getUser, updateAccount } from 'actions/user'
import { uploadFiles } from 'actions/attachment'
import { createLoadingSelector } from 'selectors/loading'
import { getUserProfileItem } from 'selectors/user'

import { Button, BUTTON_TYPE } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import {
  BUTTON_BACKGROUND_COLOR,
  ProgressButton,
} from '../../common/ProgressButton'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { UniversalFileUpload } from '../../common/UniversalFileUpload'
import { PageHeader } from '../../layout/PageHeader'

import ChangePasswordForm from './components/ChangePasswordForm'
import UserProfileFormFields from './components/UserProfileForm'
import { UserProfilePageValues } from './UserProfilePage'

export const UserProfilePageFields: FC = () => {
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [isCurrentUser, setIsCurrentUser] = useState(true)

  const { userId } = useParams<{ userId: string }>()

  const userItem = useSelector<any, User>(state =>
    getUserProfileItem(state, userId),
  )
  const isUserLoading = useSelector(createLoadingSelector(['GET_USER']))
  const isUserUpdateLoading = useSelector(
    createLoadingSelector(['UPDATE_CURRENT_USER']),
  )
  const isAttachmentUploadLoading = useSelector(
    createLoadingSelector(['UPLOAD_FILES']),
  )

  const {
    dirty,
    errors,
    isSubmitting,
    isValid,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
    setSubmitting,
    submitCount,
    touched,
    values,
  } = useFormikContext<UserProfilePageValues>()

  useEffect(() => {
    if (userId) {
      dispatch(getUser(userId))
      setIsCurrentUser(false)
    }
  }, [dispatch, userId])

  /* This cannot be handled by the regular formik handleSubmit method. This is probably related to the way
   `FilePreviewComponent` is built.
   Each time the user selects a picture to upload and tries to submit the form, no calls are made to the API.
   This is probably because this component is just rendering FilePreview as a Child. TODO: look further into this
   Here we are calling the action to upload the picture. Inside, we are sending a callback, to handle the actual form
   submission in the `uploadFiles` Redux Logic.
   This is needed because:
   - In this new FileUpload component, we are not actualy uploading the file as soon as the file is opened.
   - The file is actually just uploaded when the user clicks on "submit form"
   - Since there is not yet an picture ID to attribute to the user object, we need to handle the account update when
   we have an ID, otherwise the picture is uploaded but not associated with the user.
   This only happens if the current selected picture has no ID, meaning that the user selected a new picture.
   If no picure was ever uploaded (profile_picture null), just send the call with the values.
   */
  const handleSubmissionWithAndWithoutProfilePicture = () => {
    const noUserPicture =
      !values.profile_image || values.profile_image.size === 0
    // user does not have a picture yet, we don't want to upload any image or an empty file
    // (the default in case there is no profile picture object)
    if (noUserPicture) {
      dispatch(
        updateAccount({
          ...values,
          profile_image: null,
        }),
      )
    }

    // if user has a profile picture but no new image was introduced in the form
    if (!noUserPicture && !values.profile_image.id) {
      setSubmitting(true)
      const callbacks = {
        updateAccount: uploadedPictureId =>
          dispatch(
            updateAccount({
              ...values,
              profile_image: uploadedPictureId,
            }),
          ),
      }

      dispatch(
        uploadFiles(
          [
            {
              file: values.profile_image.data,
              orientation: values.profile_image.orientation,
            },
          ],
          callbacks,
        ),
      )
    }
  }

  /**
   * @description Method to toggle the information modal.
   */
  const handleModalToggle = () => {
    setOpenModal(!openModal)
  }

  const idChangePasswordModalHeadline = uniqueId()

  if (!userItem.id) return null

  return (
    <div className='user-profile-page'>
      <Helmet>
        <title>{I18n.t('userProfilePageTranslations.title')}</title>
      </Helmet>

      {/* Loading Indicator */}
      {isUserLoading && (
        <div className='uk-flex uk-flex-center uk-margin-large-top'>
          <Spinner name='circle' />
        </div>
      )}

      {!isUserLoading && (
        <Form
          className='user-profile-page-form'
          data-testid='user-profile-page-form'
          onSubmit={handleSubmit}
          noValidate // Disable browser validation
        >
          <PageHeader title={I18n.t('userProfilePageTranslations.title')}>
            {!isUserLoading && isCurrentUser && (
              <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                <RequiredPermissions
                  requiredPermissions={[UserPermission.CHANGE_EMAILUSER]}
                >
                  <ProgressButton
                    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                    dataTestId='user-profile-form-submit'
                    isDisabled={
                      !dirty ||
                      (submitCount === 0 && !isValid) ||
                      (isSubmitting &&
                        (isUserLoading ||
                          isUserUpdateLoading ||
                          isAttachmentUploadLoading))
                    }
                    isLoading={
                      isSubmitting &&
                      (isUserLoading ||
                        isUserUpdateLoading ||
                        isAttachmentUploadLoading)
                    }
                    // In case we are submitting the form with the picture, we don't want to submit
                    type={
                      !values.profile_image || !values.profile_image.id
                        ? BUTTON_TYPE.BUTTON
                        : BUTTON_TYPE.SUBMIT
                    }
                    onClick={handleSubmissionWithAndWithoutProfilePicture}
                  >
                    <Translate dangerousHTML value='general.button.save' />
                  </ProgressButton>
                </RequiredPermissions>
                <RequiredPermissions
                  requiredPermissions={[UserPermission.CHANGE_EMAILUSER]}
                >
                  <Button
                    dataTestId='user-profile-form-open-change-password-modal'
                    onClick={() => {
                      handleModalToggle()
                    }}
                  >
                    <Translate value='general.button.changePassword' />
                  </Button>
                </RequiredPermissions>
              </ButtonBar>
            )}
          </PageHeader>

          <div className='user-profile-page-form-groups'>
            <div className='user-profile-page-form-groups__profile-picture'>
              <UniversalFileUpload
                allowedFileTypes='image/png, image/jpeg, image/jpg'
                allowRemove={false}
                allowRotate={!!(isCurrentUser && touched.profile_image)}
                isDisabled={!isCurrentUser}
                initialValues={
                  userItem.profile_image
                    ? [userItem.profile_image_object]
                    : undefined
                }
                largePreviews
                maxFiles={1}
                name='profile_image'
                onChange={uploadItems => {
                  setFieldValue('profile_image', uploadItems[0], false)
                  setFieldTouched('profile_image')
                }}
                showButtonBelowPreview
              />
            </div>
            <div className='user-profile-page-form-groups__form-fields'>
              <UserProfileFormFields
                errors={errors}
                handleBlur={handleBlur}
                handleChange={handleChange}
                isCurrentUser={isCurrentUser}
                setFieldValue={setFieldValue}
                submitCount={submitCount}
                touched={touched}
                user={userItem}
                values={values}
              />
            </div>
          </div>
        </Form>
      )}
      {/* Change password modal */}
      <Modal
        ariaDescribedBy={idChangePasswordModalHeadline}
        isOpen={openModal}
        onClose={() => handleModalToggle()}
      >
        <ModalHeader
          onClose={() => handleModalToggle()}
          title={I18n.t('userProfilePageTranslations.changePasswordForm.title')}
          titleId={idChangePasswordModalHeadline}
        />

        <ChangePasswordForm
          onCancel={() => handleModalToggle()}
          onSuccess={() => handleModalToggle()}
        />
      </Modal>
    </div>
  )
}
