import { COMPANY_ROLE } from '../components/company/constants'
import { USER_STATUS } from '../components/user/constants'

/**
 * @description selector to get the list of my company's users.
 * @param state
 * @return {*}
 */
export const getMyCompanyUserListSelector: (state: {
  [key: string]: any
}) => User[] = state => state.user.itemList

/**
 * @description selector to get the list of my company's active users.
 * @param state
 * @return {*}
 */
export const getMyCompanyActiveUserListSelector: (state: {
  [key: string]: any
}) => User[] = state =>
  state.user.itemList.filter(item => item.status === USER_STATUS.STATUS_ACTIVE)

/**
 * @description selector to get the auth token.
 * @param state
 * @return {*}
 */
export const getLoggedInSelector: (state: {
  [key: string]: any
}) => string = state => state.user.loggedIn

/**
 * @description selector to get a user.
 * @param state
 * @return {*}
 */
export const getUserSelector: (state: { [key: string]: any }) => User = state =>
  state.user.item

/**
 * @description selector to get the current user.
 * @param state
 * @return {*}
 */
export const getCurrentUserSelector: (state: {
  [key: string]: any
}) => User = state => state.user.current

/**
 * @description selector to get an user pagination object.
 * @param state
 * @return {*}
 */
export const getUserPaginationSelector = state => state.user.pagination

/**
 * Selector for the permissions of the current user.
 *
 * @param {object} state Entire state.
 *
 * @returns {string[]}
 */
export const getUserPermissionsSelector: (state: {
  [key: string]: any
}) => string[] = state =>
  state &&
  state.user &&
  state.user.current &&
  state.user.current.permission_codenames
    ? state.user.current.permission_codenames
    : []

/**
 * Selector for the role of an user.
 *
 * @param {object} state Entire state.
 *
 * @returns {number} User role.
 */
export const getUserRoleSelector: (state: {
  [key: string]: any
}) => number = state =>
  state &&
  state.user &&
  state.user.current &&
  state.user.current.company_object &&
  state.user.current.company_object.role
    ? state.user.current.company_object.role
    : COMPANY_ROLE.NONE

/**
 * Selector to get a list of active users from a company (for use in selection fields).
 * @param {object} state Entire state.
 */
export const getActiveUserList: (state: {
  [key: string]: any
}) => User[] = state =>
  state.user.selectionItemList.filter(
    item => item && item.status === USER_STATUS.STATUS_ACTIVE,
  )

/**
 * Selector to get a list of users from a company (for use in company info).
 * @param {object} state Entire state.
 */
export const getUserList: (state: { [key: string]: any }) => User[] = state =>
  state.user.selectionItemList

/**
 * Selector to get the object for the current logged-in user or another system user
 * @param {object} state Entire state.
 * @param {object} currentUser.
 */
export const getUserProfileItem: (
  state: { [key: string]: any },
  currentUser?: string,
) => User = (state, currentUser) => {
  if (currentUser) return getUserSelector(state)
  return getCurrentUserSelector(state)
}

/**
 * Selector to get a list of active users from a company.
 */
export const getUsersByCompanyList: (state: {
  [key: string]: any
}) => User[] = state =>
  state.user.companyItemList.filter(
    item => item && item.status === USER_STATUS.STATUS_ACTIVE,
  )
