import React, { createContext, FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { createOrderEvaluations } from 'actions/order'
import { UserPermission } from 'constants/user'
import { checkRequiredPermissions } from 'helper/permissions'
import { getCurrentUserSelector } from 'selectors/user'

import {
  CUSTOMER_COMPANY_EVALUATION_TYPES,
  OFFER_COMPANY_EVALUATION_TYPES,
  ORDER_EVALUATION_RATING,
} from './constants'
import { Evaluations } from './Evaluations'
import { EvaluationsType, getOrderEvaluations } from './helpers'

type EvaluationTypes = {
  id: number
  submitting: EvaluationsType[]
  receiving: EvaluationsType[]
  alreadyEvaluated: boolean
  // don't show receiving evaluations if user is Makler Premium, they already see both sent evaluations
  showReceiving: boolean
}

type OrderEvaluationContextType = {
  EvaluationsElementArray: EvaluationTypes[]
  submitButtonDisabledWasteProducer: boolean
  submitButtonDisabledWasteDisposer: boolean
  handleThumbsClick: (
    type: number | undefined,
    rating: number | undefined,
  ) => void
}

export const OrderEvaluationContext = createContext<OrderEvaluationContextType>(
  {
    EvaluationsElementArray: [],
    submitButtonDisabledWasteProducer: true,
    submitButtonDisabledWasteDisposer: true,
    handleThumbsClick: () => undefined,
  },
)

interface OrderEvaluationComponentProps {
  order: Order
}

export const OrderEvaluation: FC<OrderEvaluationComponentProps> = ({
  order,
}) => {
  const [evaluations, setEvaluations] = useState(getOrderEvaluations(order))
  const dispatch = useDispatch()
  const user = useSelector(getCurrentUserSelector)

  const [
    submitButtonDisabledWasteProducer,
    setSubmitButtonDisabledWasteProducer,
  ] = useState(true)
  const [
    submitButtonDisabledWasteDisposer,
    setSubmitButtonDisabledWasteDisposer,
  ] = useState(true)

  useEffect(() => {
    setEvaluations(getOrderEvaluations(order))
  }, [order])

  /**
   * @description handles the thumbs click.
   * @param type
   * @param rating
   */
  const handleThumbsClick = (type, rating) => {
    const evaluationRating = evaluations.find(ev => ev.type === type)

    evaluationRating!.rating =
      evaluationRating!.rating !== rating
        ? rating
        : ORDER_EVALUATION_RATING.NEUTRAL
    setEvaluations(evaluations)

    // type can only be in OFFER_COMPANY_EVALUATION_TYPES if the rating person is WasteProducer
    // (or MaklerPremium acting in the name of their WasteProducer)
    if (OFFER_COMPANY_EVALUATION_TYPES.includes(type)) {
      setSubmitButtonDisabledWasteProducer(
        evaluations
          .filter(
            // since our evaluations always contain ALL possible evaluation types,
            // we need to filter for those evaluation types which only exist in the types a WasteProducer can rate
            e => OFFER_COMPANY_EVALUATION_TYPES.includes(e.type),
          )
          .every(
            // we then check, whether EVERY rating in the filtered types is neutral (= 10).
            // If that is the case, then this returns 'true', which then in turn sets the submit-button to disabled
            e => e.rating === ORDER_EVALUATION_RATING.NEUTRAL,
          ),
      )
    } else {
      // read the above comments - this is the same process but for WasteDisposers
      // (or MaklerPremium acting in the name of their WasteProducer)
      setSubmitButtonDisabledWasteDisposer(
        evaluations
          .filter(e => CUSTOMER_COMPANY_EVALUATION_TYPES.includes(e.type))
          .every(e => e.rating === ORDER_EVALUATION_RATING.NEUTRAL),
      )
    }
  }

  const hasRequiredPermissions = checkRequiredPermissions(
    user.permission_codenames,
    [UserPermission.ADD_ORDEREVALUATION],
  )

  const isWasteProducer =
    user.company === order.offer_object.customer_company_object.id
  const isWasteDisposer = user.company === order.offer_object.company_object.id
  const isMaklerPremium = [
    order.offer_object.customer_company_object.makler_premium_company,
    order.offer_object.company_object.makler_premium_company,
  ].includes(Number(user.company))

  // ------------------ evaluationTypes get filled here ------------------

  /*
  The following if-Statements each fill the EvaluationsElementArray Array with one "item" of type evaluationTypes.

  Since the following if statements are non-exclusive, it could definitely happen, that multiple if's apply,
  and that hence multiple items are generated. The items are clearly separated via id
  (ids are static as of now, because we can "only" have four items in total, and hence I didn't deem it necessary to
  create id's dynamically)

  As is explained by the "Skeleton" for evaluationTypes, each "item" will contain the same values.
  At the end of each if, the currently created "item" will be copied into the EvaluationsElementArray as an entry,
  and execution continues to the next if.

  Later, for each item created and added to the Array, we render an individual Evaluations Component.
   */

  // "Skeleton" for evaluationTypes - will be filled dynamically
  const evaluationTypes: EvaluationTypes = {
    id: 0,
    submitting: [] as EvaluationsType[],
    receiving: [] as EvaluationsType[],
    alreadyEvaluated: true,
    // don't show receiving evaluations if user is Makler Premium, they already see both sent evaluations
    showReceiving: false,
  }

  const EvaluationsElementArray = [] as EvaluationTypes[]

  if (isWasteProducer || isMaklerPremium) {
    evaluationTypes.id = 1
    evaluationTypes.submitting = evaluations.filter(e =>
      OFFER_COMPANY_EVALUATION_TYPES.includes(e.type),
    )
    evaluationTypes.receiving = evaluations.filter(e =>
      CUSTOMER_COMPANY_EVALUATION_TYPES.includes(e.type),
    )
    evaluationTypes.alreadyEvaluated = order.evaluations.some(e =>
      OFFER_COMPANY_EVALUATION_TYPES.includes(e.type),
    )
    evaluationTypes.showReceiving =
      !isMaklerPremium &&
      order.evaluations.some(e =>
        CUSTOMER_COMPANY_EVALUATION_TYPES.includes(e.type),
      )

    const tempEvaluationTypes = { ...evaluationTypes }
    EvaluationsElementArray.push(tempEvaluationTypes)
  }

  if (isWasteDisposer || isMaklerPremium) {
    evaluationTypes.id = 2
    evaluationTypes.submitting = evaluations.filter(e =>
      CUSTOMER_COMPANY_EVALUATION_TYPES.includes(e.type),
    )
    evaluationTypes.receiving = evaluations.filter(e =>
      OFFER_COMPANY_EVALUATION_TYPES.includes(e.type),
    )
    evaluationTypes.alreadyEvaluated = order.evaluations.some(e =>
      CUSTOMER_COMPANY_EVALUATION_TYPES.includes(e.type),
    )
    evaluationTypes.showReceiving =
      !isMaklerPremium &&
      order.evaluations.some(e =>
        OFFER_COMPANY_EVALUATION_TYPES.includes(e.type),
      )

    const tempEvaluationTypes = { ...evaluationTypes }
    EvaluationsElementArray.push(tempEvaluationTypes)
  }

  if (!isWasteProducer && !isWasteDisposer && !isMaklerPremium) {
    if (
      order.evaluations.some(e =>
        OFFER_COMPANY_EVALUATION_TYPES.includes(e.type),
      )
    ) {
      evaluationTypes.id = 3
      evaluationTypes.submitting = evaluations.filter(e =>
        OFFER_COMPANY_EVALUATION_TYPES.includes(e.type),
      )

      const tempEvaluationTypes = { ...evaluationTypes }
      EvaluationsElementArray.push(tempEvaluationTypes)
    }
    if (
      order.evaluations.some(e =>
        CUSTOMER_COMPANY_EVALUATION_TYPES.includes(e.type),
      )
    ) {
      evaluationTypes.id = 4
      evaluationTypes.submitting = evaluations.filter(e =>
        CUSTOMER_COMPANY_EVALUATION_TYPES.includes(e.type),
      )

      const tempEvaluationTypes = { ...evaluationTypes }
      EvaluationsElementArray.push(tempEvaluationTypes)
    }
  }

  // ------------------ end evaluationTypes filling ------------------

  if (!user.id || !order.id) return null

  return (
    <div className='order-status-task--on-order-details'>
      <OrderEvaluationContext.Provider
        value={{
          EvaluationsElementArray,
          submitButtonDisabledWasteProducer,
          submitButtonDisabledWasteDisposer,
          handleThumbsClick,
        }}
      >
        {EvaluationsElementArray.map(element => (
          <Evaluations
            key={element.id}
            id={element.id}
            disabled={!hasRequiredPermissions}
            onSubmit={_evaluations =>
              _evaluations && dispatch(createOrderEvaluations(_evaluations))
            }
          />
        ))}
      </OrderEvaluationContext.Provider>
    </div>
  )
}
