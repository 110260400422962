import './style.scss'

import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { Col, Row } from 'react-flexbox-grid'

import { TooltipIcon, TooltipIconProps } from '../../common/TooltipIcon'
import { InputCheckmark } from '../InputCheckmark'

export const KEY_ENTER = 'Enter'

export enum INPUT_BORDER_COLOR {
  DEFAULT = 'default',
  PRIMARY_DISABLED = 'primary-disabled',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
}

export enum INPUT_TYPE {
  EMAIL = 'email',
  NUMBER = 'number',
  PASSWORD = 'password',
  TEXT = 'text',
  TEL = 'tel',
}

export type callbackOrType<T> = T | ((name: string) => T | undefined)

export interface InputProps {
  borderColor?: INPUT_BORDER_COLOR
  className?: string
  dataTestId?: string
  dataTestIdError?: string
  error?: callbackOrType<string>
  hideLabel?: boolean
  inputRef?: React.Ref<HTMLInputElement>
  isRequired?: boolean
  label: React.ReactNode
  max?: number
  min?: number
  name: string
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onChange: React.ChangeEventHandler<HTMLInputElement>
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>
  onWheel?: React.WheelEventHandler<HTMLInputElement>
  onInput?: React.FormEventHandler<HTMLInputElement>
  placeholder?: string
  removeBrowserStyling?: boolean
  type?: INPUT_TYPE
  tooltip?: TooltipIconProps
  value?: React.ReactText
  withCheckmark?: boolean
  showCheckmark?: callbackOrType<boolean>
  disabled?: boolean
}

/**
 * @description This component renders and controls a add input component.
 * @function
 * @param {Object} props the component props
 */
export const Input: FC<InputProps> = ({
  borderColor = INPUT_BORDER_COLOR.DEFAULT,
  className,
  dataTestId = '',
  dataTestIdError = '',
  error = '',
  hideLabel = false,
  inputRef = null,
  isRequired = false,
  label,
  max = undefined,
  min = undefined,
  name,
  onBlur = () => undefined,
  onChange,
  onInput = () => undefined,
  onKeyPress = () => undefined,
  onWheel = () => undefined,
  placeholder = '',
  removeBrowserStyling = false,
  showCheckmark = false,
  tooltip = null,
  type = INPUT_TYPE.TEXT,
  value,
  withCheckmark = false,
  disabled = false,
}) => {
  const id = uniqueId()

  const errorValue = typeof error === 'string' ? error : error(name)
  const showCheckmarkValue =
    typeof showCheckmark === 'boolean' ? showCheckmark : showCheckmark(name)

  return (
    <div
      className={classNames(
        'input',
        { 'input--with-checkmark': withCheckmark },
        className,
      )}
    >
      <Row middle='xs'>
        <Col xs={12} sm={12}>
          {label && (
            <div
              className={classNames('input__label-container', {
                'visually-hidden': hideLabel,
              })}
            >
              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
              <label
                className={classNames('input__label', {
                  'visually-hidden': hideLabel,
                })}
                htmlFor={id}
              >
                {label}
              </label>
              {tooltip && (
                <div className='input__label-container__tooltip'>
                  <TooltipIcon {...tooltip} />
                </div>
              )}
            </div>
          )}
        </Col>
        <Col xs={12} sm={12} style={{ position: 'relative' }}>
          {isRequired && (
            <div
              className={classNames('input__required-dot', {
                'input__required-dot--error': errorValue !== '',
              })}
            />
          )}
          <input
            ref={inputRef}
            className={classNames(
              'uk-input',
              'input__input',
              `input__input--border-color-${borderColor}`,
              { 'input__input--no-browser-styling': removeBrowserStyling },
            )}
            data-testid={dataTestId}
            id={id}
            max={max}
            min={min}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            onKeyPress={onKeyPress}
            onInput={onInput}
            onWheel={onWheel}
            placeholder={placeholder}
            required={isRequired}
            type={type}
            value={value}
            disabled={disabled}
          />

          {withCheckmark && <InputCheckmark isHidden={!showCheckmarkValue} />}
        </Col>
      </Row>
      {errorValue !== '' && (
        <div className='input__error' data-testid={dataTestIdError}>
          {errorValue}
        </div>
      )}
    </div>
  )
}
