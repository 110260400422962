import { arrayOf, bool, func, shape } from 'prop-types'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import { compose } from 'recompose'

import withErrorBoundary from 'helper/withErrorBoundary'
import { checkRequiredPermissions } from 'helper/permissions'
import { get } from 'helper/general'
import { UserPermission } from 'constants/user'
import { BREAKPOINT } from 'constants/design'
import { CertificateScheme } from 'schemes/certificate'
import { PaginationScheme } from 'schemes/pagination'
import { UserScheme } from 'schemes/user'

import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import CertificateFilter from '../../common/Filter/components/CertificateFilter'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { PageHeader } from '../../layout/PageHeader'
import { PROOF_TYPE } from '../../proofs/constants'
import { ProofsList } from '../../proofs/ProofsList'

import connector from './connector'

/**
 * @description This component displays a list of the archived (non-pending) certificates of all companies
 * @function
 * @param {Object} props the component props
 */
export class CertificatesPageComponent extends Component {
  static propTypes = {
    getCertificates: func.isRequired,
    exportCertificates: func.isRequired,
    pagination: shape(PaginationScheme),
    user: shape(UserScheme),
    isLoading: shape({
      certificates: bool,
      exportCertificates: bool,
    }),
    certificates: arrayOf(shape(CertificateScheme)),
  }

  static defaultProps = {
    user: null,
    certificates: [],
    isLoading: {
      certificates: false,
      exportCertificates: false,
    },
    pagination: {},
  }

  state = {
    currentFilterValues: {},
    sortedBy: [],
  }

  /**
   * Component “lifecycle method” UNSAFE_componentWillMount
   */
  componentDidMount() {
    this.props.getCertificates()
  }

  /**
   * @description call getCertificates and save sorting values in state
   */
  getSortingValues = state => {
    if (state.sorted.length) {
      this.props.getCertificates(
        null,
        this.state.currentFilterValues,
        state.sorted,
      )
      this.setState({ sortedBy: state.sorted })
    }
  }

  /**
   * @description Run export action.
   */
  handleExport = () => {
    this.props.exportCertificates()
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{I18n.t('pageTitles.certificates')}</title>
        </Helmet>

        <div className='certificates-archive-page'>
          <PageHeader title={I18n.t('general.certificates')}>
            <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
              <Media
                key='media-extag'
                query={{ minWidth: BREAKPOINT.XLARGE }}
                render={() => (
                  <RequiredPermissions
                    requiredPermissions={[UserPermission.EXPORT_CERTIFICATES]}
                  >
                    <IconButton
                      iconName='export'
                      iconPosition={ICON_POSITION.RIGHT}
                      onClick={this.handleExport}
                      isDisabled={this.props.isLoading.exportCertificates}
                      isLoading={this.props.isLoading.exportCertificates}
                    >
                      <Translate value='general.export' />
                    </IconButton>
                  </RequiredPermissions>
                )}
              />
            </ButtonBar>
          </PageHeader>

          <Filter
            isLoading={this.props.isLoading.certificates}
            length={this.props.certificates.length}
          >
            <CertificateFilter
              getCurrentFilterValues={values =>
                this.setState({ currentFilterValues: values })
              }
              showCustomerNumber
            />
          </Filter>

          <ProofsList
            proofsType={PROOF_TYPE.CERTIFICATE}
            proofsList={this.props.certificates}
            isLoading={this.props.isLoading.certificates}
            isReviewButtonVisible={checkRequiredPermissions(
              get(() => this.props.user.permission_codenames),
              [UserPermission.REVIEW_CERTIFICATE],
            )}
            isReviewerColumnVisible
            onFetchData={this.getSortingValues}
            onNextPageClick={() =>
              this.props.getCertificates(
                this.props.pagination.next,
                this.state.currentFilterValues,
                this.state.sortedBy,
              )
            }
            onPreviousPageClick={() =>
              this.props.getCertificates(
                this.props.pagination.previous,
                this.state.currentFilterValues,
                this.state.sortedBy,
              )
            }
            page={this.props.pagination.current}
            pages={this.props.pagination.count}
          />
        </div>
      </>
    )
  }
}

export default compose(withErrorBoundary, connector)(CertificatesPageComponent)
