import classNames from 'classnames'
import React, { FC, useEffect } from 'react'
import ReactModal from 'react-modal'

export enum MODAL_SIZE {
  DEFAULT = 'default',
  LARGE = 'large',
}

interface ModalProps {
  ariaDescribedBy: string
  displayOverTooltip?: boolean
  isOpen: boolean
  onClose: (...args) => void
  size?: MODAL_SIZE
}

export const Modal: FC<ModalProps> = ({
  ariaDescribedBy,
  children,
  displayOverTooltip,
  isOpen,
  onClose,
  size = MODAL_SIZE.DEFAULT,
}) => {
  const removeScrollBarFromBody = () => {
    document.body.style.overflow = 'hidden'
  }

  const showScrollBarInBody = () => {
    document.body.style.overflow = 'inherit'
  }

  useEffect(() => {
    if (isOpen) {
      removeScrollBarFromBody()
    } else {
      showScrollBarInBody()
    }
  }, [isOpen])

  useEffect(() => () => {
    // unmount Effect
    showScrollBarInBody()
  })

  return (
    <ReactModal
      aria={{
        describedby: ariaDescribedBy,
      }}
      className={{
        afterOpen: classNames('uk-margin-medium-bottom', {
          'uk-width-4-5@m': size === MODAL_SIZE.LARGE,
        }),
        base: 'uk-modal-dialog modal__dialog',
        beforeClose: '', // Empty string required otherwise `undefined` will be used as class name
      }}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={{
        afterOpen: 'uk-open',
        base: classNames('uk-modal', 'modal__overlay', {
          'modal__overlay--display-over-tooltip': displayOverTooltip,
        }),
        beforeClose: '', // Empty string required otherwise `undefined` will be used as class name
      }}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick={false}
    >
      {children}
    </ReactModal>
  )
}
