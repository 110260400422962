import React from 'react'

import { UploadItem } from 'components/common/UniversalFileUpload/types'

export { InvoiceCheckAcquisitionFormikWrapper } from './InvoiceCheckAcquisitionFormikWrapper'

export { InvoiceCheckAcquisitionForm } from './InvoiceCheckAcquisitionForm'

// make sure to keep this in sync with invoicecheck.d.ts
export enum InvoiceCheckDocumentType {
  INVOICE = 1,
  CREDIT_NOTE,
}

export type InvoiceCheckAcquisitionFormValues = {
  id?: React.ReactText
  company?: number
  company_name: string
  editMode: boolean
  comment?: string
  document_date: string
  document_number: string
  document_type: InvoiceCheckDocumentType
  attachments: UploadItem[]
  gross_price?: React.ReactText
  vat?: React.ReactText
  saveAndCheckWasClicked: boolean
  search_field: string
  status: number
  tax_id: React.ReactText
  tax_number: React.ReactText
  zipcode: React.ReactText
}
