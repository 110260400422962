import 'components/offer/MyAutoOffersPage/style.scss'

import { useFormikContext } from 'formik'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { DropDownInput } from 'components/common/DropDownInput'
import { ProgressButton } from 'components/common/ProgressButton'
import { Textarea } from 'components/common/Textarea'
import {
  INVOICE_DUNNING_REASON,
  INVOICE_DUNNING_REASONS,
} from 'components/invoice/constants'

import { ToggleDunningBlockFormValues } from './ToggleDunningBlockModal'

interface ChangeDunningFormProps {
  onClose?: () => void
  dunning_block?: boolean
}

export const ToggleDunningBlockForm: FC<ChangeDunningFormProps> = ({
  onClose = () => undefined,
  dunning_block = false,
}) => {
  const {
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    submitCount,
    touched,
    values,
  } = useFormikContext<ToggleDunningBlockFormValues>()

  return (
    <>
      {!dunning_block && (
        <div
          className='uk-modal-body bookkeeping-list__form_modal-fields'
          data-testid='bookkeeping-list__form_modal-fields'
        >
          <DropDownInput
            error={
              submitCount > 0 && touched.dunning_block_reason
                ? errors.dunning_block_reason
                : ''
            }
            choices={INVOICE_DUNNING_REASONS.map(item => ({
              optionValue: item.id,
              optionLabel: `${I18n.t(item.name)}`,
            }))}
            label={I18n.t(
              'bookkeepingTranslations.toggleDunningBlockModal.fields.changeDunningBlockReason',
            )}
            name='dunning_block_reason'
            onChange={handleChange}
            placeholder={I18n.t(
              'bookkeepingTranslations.toggleDunningBlockModal.fields.changeDunningBlockCommentRequired',
            )}
            value={values.dunning_block_reason}
          />

          {values.dunning_block_reason ===
            String(INVOICE_DUNNING_REASON.CANCEL_OTHER) && (
            <Textarea
              label={I18n.t(
                'bookkeepingTranslations.toggleDunningBlockModal.fields.comment',
              )}
              maxLength={500}
              name='dunning_block_reason_text'
              onChange={handleChange}
              placeholder={I18n.t(
                'bookkeepingTranslations.toggleDunningBlockModal.fields.commentPlaceholder',
              )}
              showRemainingCharacters
              value={values.dunning_block_reason_text}
            />
          )}
        </div>
      )}
      <div className='uk-modal-footer uk-text-right uk-padding-remove-right'>
        <Button
          className='uk-button uk-margin-medium-right'
          backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
          onClick={onClose}
        >
          <Translate value='general.button.cancel' />
        </Button>

        <ProgressButton
          className='uk-button uk-margin-medium-right'
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          isDisabled={submitCount > 0 && !isValid}
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          <Translate value='general.button.save' />
        </ProgressButton>
      </div>
    </>
  )
}
