import './style.scss'

import { Form, useFormikContext } from 'formik'
import React, { FC, useEffect } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { resetApiFetchLoading } from 'actions/app'
import { getCompanyLatestCertificates } from 'actions/certificate'
import {
  BUTTON_BACKGROUND_COLOR,
  ProgressButton,
} from 'components/common/ProgressButton'
import { StaticCombobox } from 'components/common/StaticCombobox/StaticCombobox'
import { Textarea } from 'components/common/Textarea'
import { UniversalFileUpload } from 'components/common/UniversalFileUpload/UniversalFileUpload'
import { getCompanySelector } from 'selectors/company'
import { createLoadingSelector } from 'selectors/loading'
import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'

import { getTypeChoices } from './helpers'
import { DocumentUploadFormValues } from './types'

/**
 * @description This component displays the form of the document upload modal
 */
export const DocumentUploadForm: FC<{
  onClose: (...args) => void
}> = ({ onClose }) => {
  const createRequestStatus = useSelector(
    createRequestStatusSelector(['CREATE_CERTIFICATE']),
  )
  // Redux
  const company = useSelector(getCompanySelector)

  const dispatch = useDispatch()

  const {
    values,
    isValid,
    isSubmitting,
    validateForm,
    submitCount,
    handleSubmit,
    setFieldValue,
    errors,
    handleChange,
    resetForm,
  } = useFormikContext<DocumentUploadFormValues>()

  useEffect(() => {
    if (createRequestStatus === REQUEST_STATUS.SUCCESS) {
      dispatch(getCompanyLatestCertificates(company.id))
      dispatch(resetApiFetchLoading('CREATE_CERTIFICATE'))
      resetForm()
      onClose()
    }
  }, [dispatch, createRequestStatus, resetForm, company.id, onClose])

  const isLoading = {
    fileupload: useSelector(createLoadingSelector(['UPLOAD_FILES'])),
    createCertificate: useSelector(
      createLoadingSelector(['CREATE_CERTIFICATE']),
    ),
  }
  const formIsLoading = isLoading.fileupload || isLoading.createCertificate

  const isFieldValid = name =>
    (values[name] ?? '') && values[name].length > 0 && !errors[name]
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0
      ? (errors[name] as string)
      : undefined

  const handleOnChange = async (e: React.ChangeEvent<any>) => {
    await handleChange(e)
    if (submitCount > 0) {
      await validateForm()
    }
  }

  const showNoAttachmentsMessage =
    values.attachments.length === 0 && !getFieldError('attachments')

  return (
    <div className='uk-modal-body company-info-page-document-upload-form'>
      <Form>
        <StaticCombobox
          label={I18n.t('documentUploadFormTranslations.type.label')}
          onSelectionChange={event => handleOnChange(event)}
          name='type'
          // Text is not required in this case, because options are static
          noResultsText=''
          options={getTypeChoices(company)}
          placeholder={I18n.t(
            'documentUploadFormTranslations.type.placeholder',
          )}
          error={getFieldError}
          className='company-info-page-document-upload-form__field'
        />
        <UniversalFileUpload
          name='attachments'
          subLabel={I18n.t('fileUpload.hint')}
          error={getFieldError}
          label={I18n.t('documentUploadFormTranslations.attachments.label')}
          allowedFileTypes='image/png, image/jpeg, application/pdf'
          maxFiles={1}
          initialValues={values.attachments}
          onChange={async fileList => {
            await setFieldValue('attachments', fileList, true)
            if (submitCount > 0) await validateForm()
          }}
          showFilename
        />
        {showNoAttachmentsMessage && (
          <div className='company-info-page-document-upload-form__no-attachments-wrapper'>
            <span className='company-info-page-document-upload-form__no-attachments-title'>
              {I18n.t('documentUploadFormTranslations.noAttachmentsTitle')}
            </span>
            <span className='company-info-page-document-upload-form__no-attachments-text'>
              {I18n.t('documentUploadFormTranslations.noAttachmentsText')}
            </span>
          </div>
        )}
        <Textarea
          error={getFieldError}
          label={I18n.t('documentUploadFormTranslations.description.label')}
          maxLength={100}
          name='description'
          onChange={event => handleOnChange(event)}
          showRemainingCharacters
          placeholder={I18n.t(
            'documentUploadFormTranslations.description.label',
          )}
          value={values.description}
          rows={5}
        />
        <div className='company-info-page-document-upload-form__submit-button-wrapper'>
          <ProgressButton
            className='company-info-page-document-upload-form__submit-button'
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            fullWidth
            isDisabled={formIsLoading || isSubmitting || !isValid}
            onClick={handleSubmit}
            isLoading={formIsLoading || isSubmitting}
          >
            <Translate value='general.button.save' />
          </ProgressButton>
        </div>
      </Form>
    </div>
  )
}
