import { COMPANY_ROLE, COMPANY_STATUS } from '../components/company/constants'

/**
 * Method to check if the company is active.
 */
export const isCompanyActive = (company: Company): boolean =>
  company.status === COMPANY_STATUS.STATUS_ACTIVE

/**
 * Method to check if the company is inactive / by empto / by epd.
 */
export const isCompanyInactive = (company: Company): boolean =>
  company.status === COMPANY_STATUS.STATUS_INACTIVE ||
  company.status === COMPANY_STATUS.STATUS_INACTIVE_BY_EMPTO ||
  company.status === COMPANY_STATUS.STATUS_INACTIVE_BY_EPD

/**
 * Method to check if the company of user is inactive / by empto / by epd.
 */
export const isUserCompanyInactive = (user: User): boolean =>
  user.company_object
    ? user.company_object.status === COMPANY_STATUS.STATUS_INACTIVE ||
      user.company_object.status === COMPANY_STATUS.STATUS_INACTIVE_BY_EMPTO ||
      user.company_object.status === COMPANY_STATUS.STATUS_INACTIVE_BY_EPD
    : false

/**
 * Determine whether to show the warning for uncompleted company data and an already created a inquiry.
 */
export const displayCreateInquiryWarning = (user: User): boolean =>
  isUserCompanyInactive(user) &&
  user.company_object &&
  user.company_object.inquiries_count >= 1

/**
 * Determine if the company is waste company.
 */
export const isWasteCompany = (company: Company): boolean =>
  company.role === COMPANY_ROLE.WASTE_COMPANY

/**
 * Determine if the company is waste producer.
 */
export const isWasteProducer = (company: Company): boolean =>
  company.role === COMPANY_ROLE.WASTE_PRODUCER
