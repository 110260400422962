import './style.scss'

import { isNaN, useFormikContext } from 'formik'
import moment, { isMoment } from 'moment'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getMyAutoOffers } from 'actions/autooffer'
import {
  getInvoiceCheckForAlreadyCoveredOrder,
  getInvoiceCheckPositionPrice,
  getInvoiceCheckPositions,
} from 'actions/maklerpremium'
import { getAcceptedOffers } from 'actions/offer'
import { getOrdersForInvoiceCheck } from 'actions/order'
import { DismissableInfo } from 'components/common/DismissableInfo'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { InputDecimal } from 'components/common/InputDecimal'
import { RadioButton } from 'components/common/RadioButton'
import { getFractionName } from 'components/executionproof/helpers'
import { INQUIRY_ORDER_TYPE } from 'components/inquiry/constants'
import {
  getContainerName,
  getFractionFromItem,
  getFractionsFromFractionId,
} from 'components/inquiry/helpers'
import { INVOICE_CHECK_POSITIONS_CATEGORIES } from 'components/maklerpremium/constants'
import { InvoiceCheckDocumentType } from 'components/maklerpremium/InvoiceCheckPage/InvoiceCheckAcquisition'
import { AUTO_OFFER_STATUS } from 'components/offer/constants'
import { ORDER_STATUS } from 'components/order/constants'
import { getOrderDetailURL } from 'components/order/OrderDetailsPage/components/OrderSwitcherNav/OrderSwitcherNav'
import { ORDER_SWITCH_LABELS } from 'components/order/OrderDetailsPage/constants'
import { DATE_FORMATS } from 'constants/app'
import { germanDecimalToInternationalFormat } from 'helper/general'
import {
  getActualOrderCollectionDate,
  getActualOrderDeliveryDate,
} from 'helper/order'
import { getAddressesWithOrdersSelector } from 'selectors/address'
import {
  getAutoOffersPaginationSelector,
  getMyAutoOffersSelector,
} from 'selectors/autooffer'
import { getEPDContainersSelector } from 'selectors/container'
import { getEPDFractionsSelector } from 'selectors/fraction'
import { createLoadingSelector } from 'selectors/loading'
import {
  getInvoiceCheckPositionsSelector,
  getInvoiceChecksSelector,
} from 'selectors/maklerpremium'
import { getMyOffersSelector } from 'selectors/offer'
import { getOrdersForInvoiceCheckSelector } from 'selectors/order'

import {
  clearOtherValues,
  InvoiceCheckPositionsFormContext,
  InvoiceCheckPositionsFormValues,
} from '../InvoiceCheckPositionsFormSteps'

import {
  getNMonthsAgoAsFormattedString,
  getOfferDisposerRentPriceContainerMonth,
} from './helper'
import { SURCHARGE_REDUCTION } from './OpenOrderForm/CompensationPriceFields/CompensationPriceFields'
import { OpenOrderForm } from './OpenOrderForm/OpenOrderForm'
import { OtherServiceForm } from './OtherServiceForm/OtherServiceForm'
import { RentForm } from './RentForm/RentForm'

export enum OrderType {
  OPEN_ORDER = 1,
  OTHER_SERVICE,
  RENT = 3,
}

export enum NetPriceType {
  BURDEN = 1,
  CREDIT,
}

export enum WeightOrNumber {
  INPUT_WEIGHT,
  INPUT_NUMBER_OF_CONTAINERS,
}

export const OrderAndServicesForm: FC = () => {
  const dispatch = useDispatch()

  // Form State (values, errors, functions)
  const {
    errors,
    handleChange,
    isValid,
    setFieldValue,
    setValues,
    submitCount,
    values,
  } = useFormikContext<InvoiceCheckPositionsFormValues>()

  // FormStep Context (save/next state and action, invoiceCheck and invoiceCheckPosition objects)
  const { stepStatus, saveStep, invoiceCheck, invoiceCheckPosition } =
    useContext(InvoiceCheckPositionsFormContext)

  const isLoading = {
    orderOptions: useSelector(
      createLoadingSelector(['GET_ORDERS_FOR_INVOICE_CHECK']),
    ),
    invoiceCheckListForAlreadyCoveredOrder: useSelector(
      createLoadingSelector(['GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER']),
    ),
    invoiceCheckPositions: useSelector(
      createLoadingSelector(['GET_INVOICE_CHECK_POSITIONS']),
    ),
    acceptedOffer: useSelector(createLoadingSelector(['GET_ACCEPTED_OFFERS'])),
  }

  const isFieldValid = name =>
    values[name] ? `${values[name]}`.length > 0 && !errors[name] : false

  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0
      ? (errors[name] as string)
      : undefined

  // Temporarily disable this in #2404, so you can select orders by disposers not linked to the invoiceCheck
  // const invoiceDisposer = invoiceCheck?.company_object.id

  // Base data already fetched by default (fractions and containers)
  const fractionList = useSelector(getEPDFractionsSelector)
  const containerList = useSelector(getEPDContainersSelector)
  const invoiceCheckList = useSelector(getInvoiceChecksSelector)

  // Filter for invoiceChecks of the current invoiceCheck document_type only
  const invoiceCheckListFromSameType = useMemo(
    () =>
      [...invoiceCheckList].filter(
        element => element.document_type === invoiceCheck?.document_type,
      ),
    [invoiceCheck, invoiceCheckList],
  )

  // invoiceCheckPositionList is pre filtered by order_id and order_type OPEN_ORDER from backend, because of set filter
  // at dispatch
  const invoiceCheckPositionList = useSelector(getInvoiceCheckPositionsSelector)

  const invoiceCheckPositionIdList = useMemo(
    () =>
      invoiceCheckPositionList.map(
        invoiceCheckPosition => invoiceCheckPosition.id,
      ),
    [invoiceCheckPositionList],
  )

  const [isDispatching, setIsDispatching] = useState(false)
  const [openOrderDisabled, setOpenOrderDisabled] = useState(false)

  // --------------------- ORDERS ---------------------

  // Request Orders for collection address from API
  useEffect(() => {
    if (values.collection_address) {
      // Only load orders if an address is selected otherwise
      // this crashes if you go back to step 1 and change the address
      // as it will try to load all the orders of the last 12 months on the system
      dispatch(
        getOrdersForInvoiceCheck(
          undefined,
          {
            address: values.collection_address,
            // Temporarily disable this in #2404, so you can select orders by disposers not linked to the invoiceCheck
            // offer_company: invoiceDisposer ?? '',
            // Increase from 5 months to 12 months (#2676) and allow to select from the first of the month onwards #2460
            delivery_date__gte: getNMonthsAgoAsFormattedString(new Date(), 12),
          },
          Number.MAX_SAFE_INTEGER,
          ['order_statuses'],
        ),
      )
    }
  }, [dispatch, values.collection_address])

  // Get the fetched orders
  const orders = useSelector(getOrdersForInvoiceCheckSelector)

  // Parse Order-objects into Option array for the StaticCombobox
  const orderOptions = useMemo(
    () =>
      orders.map(order => {
        const fraction = getFractionName(order.fraction, fractionList)
        const container = getContainerName(order.container, containerList)
        // Set order_date to be A: the actual date of B: collection for one time orders or delivery for standing orders
        // See: https://gitlab.ambient-innovation.com/zentek/empto/-/issues/2400
        const order_date =
          order.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME
            ? order.actual_collection_date
            : order.actual_delivery_date
        return {
          label: `${order.id} / ${fraction} / ${container} / ${moment(
            order_date,
            DATE_FORMATS,
          ).format('L')}`,
          value: `${order.id}`,
        }
      }),
    [containerList, fractionList, orders],
  )

  // Find the selected order and the corresponding option object
  const selectedOrder = useMemo(
    () => orders.find(order => order.id === Number(values.order)),
    [orders, values.order],
  )

  const selectedOrderOption = useMemo(
    () =>
      orderOptions.find(orderOption => orderOption.value === `${values.order}`),
    [orderOptions, values.order],
  )

  // Set relevant form fields
  useEffect(() => {
    if (!values.order) return

    const order = orders.find(item => `${item.id}` === `${values.order}`)

    if (!order) return

    const { fraction, coarseFraction } = getFractionsFromFractionId(
      order.fraction,
      fractionList,
    )

    if (values.order_type === OrderType.OPEN_ORDER) {
      // Common fields (reset price fields by default)
      let weight_or_number = WeightOrNumber.INPUT_WEIGHT

      if (invoiceCheckPosition) {
        if ((invoiceCheckPosition.weight_receipts?.length ?? 0) > 0) {
          weight_or_number = WeightOrNumber.INPUT_WEIGHT
        } else {
          weight_or_number = WeightOrNumber.INPUT_NUMBER_OF_CONTAINERS
        }
      }

      const orderValues = {
        ...values,
        fraction: coarseFraction?.id,
        // Only fill fine_fraction if fraction is actually a fine fraction. getFractionsFromFractionId returns a
        // fraction as both coarse and fine fraction if a coarse fraction is given
        fine_fraction: fraction?.parent ? fraction?.id : undefined,
        container: order.container ? `${order.container}` : undefined,
        number_of_containers: order.number_of_containers,
        delivery_date: moment(getActualOrderDeliveryDate(order), DATE_FORMATS),
        collection_date: moment(
          getActualOrderCollectionDate(order),
          DATE_FORMATS,
        ),
        index: order.disposer_price_fields?.index
          ? String(order.disposer_price_fields?.index)
          : undefined,
        index_month:
          order.disposer_price_fields?.index &&
          !isNaN(order.disposer_price_fields?.index_month)
            ? String(order.disposer_price_fields?.index_month)
            : undefined,
        index_surcharge_reduction: order.disposer_price_fields?.reduction
          ? SURCHARGE_REDUCTION.REDUCTION
          : SURCHARGE_REDUCTION.SURCHARGE,
        surcharge: order.disposer_price_fields?.surcharge
          ? Number(order.disposer_price_fields?.surcharge).toLocaleString(
              'de-DE',
              { minimumFractionDigits: 2 },
            )
          : undefined,
        reduction: order.disposer_price_fields?.reduction
          ? Number(order.disposer_price_fields?.reduction).toLocaleString(
              'de-DE',
              { minimumFractionDigits: 2 },
            )
          : undefined,
        transport_price_piece: '',
        disposal_cost_ton: '',
        disposal_cost_container: '',
        cost_container: '',
        compensation_container: '',
        security_group: order.security_group_id,
        weight_or_number: weight_or_number,
        price_model: order.disposer_price_fields?.price_model,
      }

      // There is a race condition between the useEffect to set the offer value and this one
      // Sometimes this effect doesn't yet know about the changed offer value and will inevitably overwrite it
      // To make sure that doesn't happen, pick the value for offer from the selectedOffer if that is set
      if (!values.offer && selectedOffer?.id) {
        orderValues.offer = selectedOffer.id
      }

      if (order.dayrate_pricing) {
        // If Dayrate pricing is set, do not prefill the price fields
        setValues(orderValues, true)
      } else if (
        invoiceCheck?.document_type === InvoiceCheckDocumentType.INVOICE
      ) {
        // If Disposer price fields for document_type credit_note are set, unset compensation fields and use price
        // fields. Expect that order.disposer_price_fields are always set.
        setValues(
          {
            ...orderValues,
            transport_price_piece: Number(
              order.disposer_price_fields!.transport_price_piece,
            ).toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              useGrouping: false,
            }),
            disposal_cost_ton: Number(
              order.disposer_price_fields!.disposal_cost_ton,
            ).toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              useGrouping: false,
            }),
            disposal_cost_container: Number(
              order.disposer_price_fields!.disposal_cost_container,
            ).toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              useGrouping: false,
            }),
            cost_container: Number(
              order.disposer_price_fields!.cost_container,
            ).toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              useGrouping: false,
            }),
            handle_cost_ton: Number(
              order.disposer_price_fields!.handle_cost_ton,
            ).toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              useGrouping: false,
            }),
          },
          true,
        )
      } else if (
        order.disposer_price_fields !== null &&
        invoiceCheck?.document_type === InvoiceCheckDocumentType.CREDIT_NOTE
      ) {
        // If Disposer price fields for document_type credit_note are set, unset price fields and use compensation
        // fields
        setValues(
          {
            ...orderValues,
            transport_price_piece: undefined,
            handle_cost_ton: undefined,
            disposal_cost_container: undefined,
            cost_container: undefined,
            compensation_container: Number(
              order.disposer_price_fields!.compensation_container,
            ).toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              useGrouping: false,
            }),
            compensation_ton: order.disposer_price_fields!.compensation_ton
              ? Number(
                  order.disposer_price_fields!.compensation_ton,
                ).toLocaleString('de-DE', {
                  minimumFractionDigits: 2,
                  useGrouping: false,
                })
              : undefined,
          },
          true,
        )
      } else {
        // Last but not least, fallback to customer price fields
        setValues(
          {
            ...orderValues,
            transport_price_piece: Number(
              order.transport_price_piece,
            ).toLocaleString('de-DE', { minimumFractionDigits: 2 }),
            disposal_cost_ton: Number(order.disposal_cost_ton).toLocaleString(
              'de-DE',
              { minimumFractionDigits: 2, useGrouping: false },
            ),
            disposal_cost_container: Number(
              order.disposal_cost_container,
            ).toLocaleString('de-DE', {
              minimumFractionDigits: 2,
              useGrouping: false,
            }),
          },
          true,
        )
      }
    } else {
      setValues(
        {
          ...values,
          avv: undefined,
          collection_date: undefined,
          compensation_container: undefined,
          delivery_date: moment(
            getActualOrderDeliveryDate(order),
            DATE_FORMATS,
          ),
          disposal_cost_container: undefined,
          disposal_cost_ton: undefined,
          disposal_proof_number: undefined,
          disposer_number: undefined,
          number_of_containers: undefined,
          quantity_in_cubic_meters: undefined,
          security_group: undefined,
          transport_price_piece: undefined,
          transportation_company_number: undefined,
          weight: undefined,
          weight_object_list: undefined,
          weight_or_number: undefined,
          weight_receipt: undefined,
        },
        true,
      )
    }
    // only update all these things if the order changes, leave them alone when any other field changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orders,
    selectedOrder,
    setFieldValue,
    setValues,
    values.order,
    values.order_type,
  ])

  // If an order has been selected, request any invoiceCheck it might appear on
  useEffect(() => {
    if (!selectedOrder) return
    setIsDispatching(true)
    dispatch(getInvoiceCheckForAlreadyCoveredOrder(Number(selectedOrder.id)))
    dispatch(
      getInvoiceCheckPositions(undefined, {
        order_id: selectedOrder.id,
        order_type: OrderType.OPEN_ORDER,
        invoice_check__document_type: invoiceCheck?.document_type,
      }),
    )
    setIsDispatching(false)
  }, [dispatch, selectedOrder, invoiceCheck])

  // Find any invoiceCheck this order might already appear on
  useEffect(() => {
    // Return early if the requests are either dispatching or still loading
    if (
      isDispatching ||
      isLoading.orderOptions ||
      isLoading.invoiceCheckListForAlreadyCoveredOrder ||
      isLoading.invoiceCheckPositions ||
      isLoading.acceptedOffer
    )
      return

    // reset disabled status
    setOpenOrderDisabled(false)

    // If the selectedOrder has not been specified yet or the preexisting invoice_check list is empty or no values.id
    // is set, return early
    if (!selectedOrder || invoiceCheckPositionList.length === 0) return
    // If selected order mismatches the currently loaded invoicecheckpositions
    if (invoiceCheckPositionList[0].order_object?.id !== selectedOrder.id)
      return

    // Return early, if the positions' id is defined, and it's not the order_type is not OPEN_ORDER(edit mode)
    // invoiceCheckPositionList = positions with order_type = OPEN_ORDER
    // Only relevant for editing
    if (
      values.id !== undefined &&
      invoiceCheckPositionList.filter(
        position => `${position.id}` === `${values.id}`,
      ).length !== 0
    )
      return

    // Disabled the open order radio button if the loaded invoicecheckpositions belong to the current order and we
    // have at least 1 position of type OPEN_ORDER
    if (
      invoiceCheckPositionList.filter(
        position =>
          position.order_object?.id === selectedOrder.id &&
          position.order_type === OrderType.OPEN_ORDER,
      ).length !== 0
    ) {
      setOpenOrderDisabled(true)
      // Only set order_type of type is equal to 'open_order', to prevent from blocking choosing 'rent'
      if (values.order_type === OrderType.OPEN_ORDER)
        setFieldValue('order_type', OrderType.OTHER_SERVICE)
    }
  }, [
    invoiceCheckPositionIdList,
    invoiceCheckPositionList,
    isLoading.invoiceCheckListForAlreadyCoveredOrder,
    isLoading.orderOptions,
    isLoading.invoiceCheckPositions,
    isLoading.acceptedOffer,
    isDispatching,
    selectedOrder,
    setFieldValue,
    values.id,
    values.order_type,
  ])

  useEffect(() => {
    // Get all weight_receipts from values that are not empty
    const non_empty_weight_object_list =
      values.weight_object_list?.filter(entry => entry.weight_receipt !== '') ||
      []
    // Only pre-fill weight-receipts if the initial weight_object_list is empty
    if (non_empty_weight_object_list.length === 0) {
      // Extract all weight_receipts from the invoiceChecks for the current order
      const weight_receipts = invoiceCheckList
        .flatMap(invoiceCheck => invoiceCheck?.positions || [])
        .filter(position => position.order_object?.id === Number(values.order))
        .flatMap(position => position?.weight_receipts || [])
        .map(weight_receipt => {
          return {
            weight_receipt: weight_receipt.weight_receipt,
            weight: weight_receipt.weight,
          }
        })
      if (weight_receipts.length !== 0)
        setFieldValue('weight_object_list', weight_receipts)
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.order, invoiceCheckList, setFieldValue])

  // --------------------- ORDERS END ---------------------

  // --------------------- OFFERS ---------------------

  // Request Accepted Offers for collection address from API
  useEffect(() => {
    const orderNumberWasSearchField = values.search_field === 'order'
    if (
      typeof values.order !== 'undefined' ||
      typeof values.collection_address !== 'undefined'
    ) {
      // Only load agreements if an address or order is selected otherwise
      // this crashes if you go back to step 1 and change the address
      // as it will try to load all the agreements of the last 12 months on the system
      dispatch(
        getAcceptedOffers(null, {
          order_id: orderNumberWasSearchField ? values.order : '',
          inquiry__collection_address: values.collection_address,
          collection_address: values.collection_address,
          agreement_status: '',
          page_size: orderNumberWasSearchField ? 1 : 1000,
        }),
      )
    }
  }, [
    dispatch,
    selectedOrder,
    values.collection_address,
    values.order,
    values.search_field,
  ])

  // Get fetched accepted offers
  const offers = useSelector(getMyOffersSelector)

  // Parse Offer-objects into Option array for the StaticCombobox
  const offerOptions = useMemo(
    () =>
      offers.map(offer => {
        const fraction = getFractionName(offer.fraction, fractionList)
        const container = getContainerName(offer.container, containerList)

        const service_start_date = moment(
          offer.service_start_date,
          DATE_FORMATS,
        ).format('L')
        const service_end_date = moment(
          offer.service_end_date,
          DATE_FORMATS,
        ).format('L')

        return {
          label: `${offer.id} / ${fraction} / ${container} / ${service_start_date} - ${service_end_date}`,
          value: `${offer.id}`,
        }
      }),
    [containerList, fractionList, offers],
  )

  // Store possible orderId in this constant, so that useMemos dependency array has no complex expression anymore
  const selectedOrdersOfferId = selectedOrder?.offer_id

  // Find the selected offer and the corresponding option object
  const selectedOffer = useMemo(() => {
    // If an order was searched, we know exactly which offer belongs to it, so we asked for only this offer and can
    // safely assume that the only and first entry of offerOptions is the desired offer
    if (values.search_field === 'order') {
      return offers.find(offer => `${offer.id}` === offerOptions[0].value)
    }
    return offers.find(offer => offer.id === selectedOrdersOfferId)
  }, [offerOptions, offers, selectedOrdersOfferId, values.search_field])

  const selectedOfferOption = useMemo(() => {
    // If an order was searched, we know exactly which offer belongs to it, so we asked for only this offer and can
    // safely assume that the only and first entry of offerOptions is the desired offer
    if (values.search_field === 'order') {
      return offerOptions[0]
    }

    // Return early, if orders request is still loading
    if (isLoading.orderOptions) return undefined

    return offerOptions.find(
      offerOption => offerOption.value === `${selectedOrdersOfferId}`,
    )
  }, [
    isLoading.orderOptions,
    offerOptions,
    selectedOrdersOfferId,
    values.search_field,
  ])

  // If search_field was order, automatically set the offer field
  useEffect(() => {
    if (
      values.search_field === 'order' &&
      selectedOffer &&
      selectedOffer.id !== values.offer
    ) {
      setFieldValue('offer', selectedOffer.id)
      setFieldValue(
        'net_price',
        getOfferDisposerRentPriceContainerMonth(offers, selectedOffer.id),
      )
    }
  }, [selectedOffer, setFieldValue, values.offer, values.search_field, offers])

  // --------------------- OFFERS END ---------------------

  // --------------------- AUTO OFFERS START ---------------------

  const addressesList = useSelector(getAddressesWithOrdersSelector)
  const address = useMemo(
    () =>
      addressesList.find(element => element.id === values.collection_address),
    [addressesList, values.collection_address],
  )

  const autoOffers = useSelector(getMyAutoOffersSelector)
  const autoOffersPagination = useSelector(getAutoOffersPaginationSelector)

  // Get fraction/fine-fraction from selectedOffer
  const selectedOfferFraction = useMemo(
    () => getFractionFromItem(selectedOffer, fractionList),
    [selectedOffer, fractionList],
  )

  // Get fraction/fine-fraction from values
  const valueFraction = useMemo(
    () => getFractionFromItem(values, fractionList),
    [values, fractionList],
  )

  const shouldRefreshAutoOffer = useMemo(
    () =>
      !!(
        values.order_type === OrderType.OPEN_ORDER &&
        selectedOffer &&
        selectedOfferFraction &&
        valueFraction &&
        values.container &&
        (selectedOfferFraction.id !== valueFraction.id ||
          selectedOffer?.container !== Number(values.container))
      ),
    [
      selectedOffer,
      selectedOfferFraction,
      valueFraction,
      values.container,
      values.order_type,
    ],
  )

  // Fetch Auto-offers
  useEffect(() => {
    // only fetch if order_type is open order, we have at least a fraction/fine-fraction & container
    // from both the selected offer and the form AND either of them mismatches
    if (shouldRefreshAutoOffer) {
      dispatch(
        getMyAutoOffers(1, {
          company: invoiceCheck?.company_object.id,
          zipcode: address?.zipcode,
          fraction: valueFraction!.id, // assert not-falsy in shouldRefreshAutoOffer
          container: values.container,
          status: AUTO_OFFER_STATUS.STATUS_ACTIVE,
          order_by: JSON.stringify([{ id: 'net_buying_price', desc: false }]),
        }),
      )
    }
  }, [
    address,
    dispatch,
    invoiceCheck,
    valueFraction,
    values.container,
    shouldRefreshAutoOffer,
  ])

  // Set price fields using data from the first autoOffer found in the autoOffers array
  useEffect(() => {
    if (!autoOffersPagination.loaded) return

    if (autoOffersPagination.total_results === 0) {
      // If no autoOffers were found, reset all price fields and have the user enter them manually
      setFieldValue('disposal_cost_container', '')
      setFieldValue('transport_price_piece', '')
      setFieldValue('disposal_cost_ton', '')
      setFieldValue('handle_cost_ton', '')
    } else {
      // If no autoOffers were found, set this to undefined and reset all the fields
      // else set the prices from the first autoOffer (the one with the best net_buying_price)
      const autoOffer = autoOffers.length > 0 ? autoOffers[0] : undefined

      setFieldValue(
        'disposal_cost_container',
        Number(autoOffer?.disposal_cost_container ?? 0).toLocaleString(
          'de-DE',
          { minimumFractionDigits: 2, useGrouping: false },
        ),
      )
      setFieldValue(
        'transport_price_piece',
        Number(autoOffer?.transport_price_piece ?? 0).toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          useGrouping: false,
        }),
      )
      setFieldValue(
        'disposal_cost_ton',
        Number(autoOffer?.disposal_cost_ton ?? 0).toLocaleString('de-DE', {
          minimumFractionDigits: 2,
          useGrouping: false,
        }),
      )
    }
  }, [autoOffers, autoOffersPagination, setFieldValue])

  // --------------------- AUTO OFFERS END ---------------------

  const dismissibleInfoForOrderPictures = (
    <>
      {!!selectedOrder?.attachments_count && (
        <div className='form-error bottom'>
          <DismissableInfo
            title={I18n.t('general.attention')}
            text={I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.2.picturesForOrderExistInfo',
            )}
            buttonText={I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.2.picturesForOrderExistCallToAction',
            )}
            onClick={() => {
              window.open(
                getOrderDetailURL(selectedOrder.id, ORDER_SWITCH_LABELS.IMAGES),
                '_blank',
              )
            }}
            disableOnAnimationEnd
          />
        </div>
      )}
    </>
  )

  const dismissibleInfoForAlreadyCapturedOrder = useMemo(() => {
    if (
      values.id !== undefined ||
      !selectedOrder ||
      invoiceCheckListFromSameType.length === 0
    )
      return <></>

    return (
      <>
        {selectedOrder && invoiceCheckListFromSameType.length > 0 && (
          <div className='form-error bottom'>
            <DismissableInfo
              title={I18n.t('general.attention')}
              text={
                <>
                  <Translate value='invoiceCheckTranslations.positionsForm.steps.2.AlreadyCoveredOrder' />
                  <br />
                  {invoiceCheckListFromSameType.map(invoiceCheck => (
                    <>
                      <Link
                        to={`/maklerpremium/invoice_check/overview/${invoiceCheck?.id}`}
                        target='_blank'
                      >
                        {invoiceCheck?.document_number}
                      </Link>
                      <br />
                    </>
                  ))}
                </>
              }
              disableOnAnimationEnd
            />
          </div>
        )}
      </>
    )
  }, [values.id, selectedOrder, invoiceCheckListFromSameType])

  const dismissibleInfoForCancelledOrder = useMemo(() => {
    if (!selectedOrder) {
      return <></>
    }
    const orderIsCancelled = [
      ORDER_STATUS.AEZ_CANCELLED,
      ORDER_STATUS.CANCELLED_EMPTO,
      ORDER_STATUS.CANCELLED_EPD,
    ].filter(status =>
      selectedOrder.order_statuses.find(_status => _status.value === status),
    )
    return (
      <>
        {orderIsCancelled.length > 0 && (
          <div className='form-error bottom'>
            <DismissableInfo
              title={I18n.t('general.attention')}
              text={I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.CancelledOrderInfo',
              )}
              buttonText={I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.CancelledOrderCallToAction',
              )}
              onClick={() => {
                window.open(
                  getOrderDetailURL(
                    selectedOrder.id,
                    ORDER_SWITCH_LABELS.SHORT_OVERVIEW,
                  ),
                  '_blank',
                )
              }}
              disableOnAnimationEnd
            />
          </div>
        )}
      </>
    )
  }, [selectedOrder])

  return (
    <div className='order-and-services-form'>
      {/* Offener Auftrag vs Sonstige Dienstleistung vs Mietbeleg*/}
      <div className='uk-flex-left' data-uk-grid='uk-grid'>
        {/*Offener Auftrag*/}
        <RadioButton
          isChecked={values.order_type === OrderType.OPEN_ORDER}
          isDisabled={openOrderDisabled}
          label={I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.order_type.open_order.label',
          )}
          name='order_type'
          onChange={(event: React.ChangeEvent<any>) => {
            if (event.target.value === 'on') {
              setFieldValue(event.target.name, OrderType.OPEN_ORDER)
            }
          }}
        />

        {/*Sonstige Dienstleistung*/}
        <RadioButton
          isChecked={values.order_type === OrderType.OTHER_SERVICE}
          label={I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.order_type.other_service.label',
          )}
          name='order_type'
          onChange={(event: React.ChangeEvent<any>) => {
            if (event.target.value === 'on') {
              setFieldValue(event.target.name, OrderType.OTHER_SERVICE)
            }
          }}
        />

        {/*Mietbeleg*/}
        <RadioButton
          isChecked={values.order_type === OrderType.RENT}
          label={I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.order_type.rent.label',
          )}
          name='order_type'
          onChange={(event: React.ChangeEvent<any>) => {
            if (event.target.value === 'on') {
              setFieldValue(event.target.name, OrderType.RENT)
              setFieldValue('category', INVOICE_CHECK_POSITIONS_CATEGORIES.RENT)
            }
          }}
        />
      </div>

      {/*Offener Auftrag*/}
      <OpenOrderForm
        getFieldError={getFieldError}
        orderOptions={orderOptions}
        selectedOrderOption={selectedOrderOption}
        isFieldValid={isFieldValid}
        isLoading={isLoading}
        dismissibleInfoForAlreadyCapturedOrder={
          dismissibleInfoForAlreadyCapturedOrder
        }
        dismissibleInfoForCancelledOrder={dismissibleInfoForCancelledOrder}
        dismissibleInfoForOrderPictures={dismissibleInfoForOrderPictures}
        selectedOrder={selectedOrder}
      />

      {/*Sonstige Dienstleistung*/}
      <OtherServiceForm
        getFieldError={getFieldError}
        orderOptions={orderOptions}
        selectedOrderOption={selectedOrderOption}
        isFieldValid={isFieldValid}
        isLoading={isLoading}
        dismissibleInfoForAlreadyCapturedOrder={
          dismissibleInfoForAlreadyCapturedOrder
        }
        dismissibleInfoForCancelledOrder={dismissibleInfoForCancelledOrder}
        dismissibleInfoForOrderPictures={dismissibleInfoForOrderPictures}
      />

      {/*Mietbeleg*/}
      <RentForm
        getFieldError={getFieldError}
        isFieldValid={isFieldValid}
        offerOptions={offerOptions}
        selectedOfferOption={selectedOfferOption}
        offers={offers}
      />

      {[OrderType.OTHER_SERVICE, OrderType.RENT].includes(
        values.order_type as OrderType,
      ) && (
        <>
          {/* Nettopreis in Euro */}
          <InputDecimal
            error={getFieldError}
            label={I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.netPrice.label',
            )}
            maxValue={9999.99}
            name='net_price'
            onChange={handleChange}
            placeholder={I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.netPrice.label',
            )}
            removeBrowserStyling
            showCheckmark={isFieldValid}
            value={String(values.net_price).replace('.', ',')}
            withCheckmark
          />

          {/* Art der Belastung*/}
          <div className='uk-flex-left' data-uk-grid='uk-grid'>
            {/* Belastung an EPD*/}
            <RadioButton
              isChecked={values.net_price_type === NetPriceType.BURDEN}
              isDisabled={
                invoiceCheck?.document_type ===
                InvoiceCheckDocumentType.CREDIT_NOTE
              }
              label={I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.netPrice.burden',
              )}
              name='net_price_type'
              onChange={(event: React.ChangeEvent<any>) => {
                if (event.target.value === 'on') {
                  setFieldValue(event.target.name, NetPriceType.BURDEN, true)
                }
              }}
            />

            {/* Gutschrift an EPD*/}
            <RadioButton
              isChecked={values.net_price_type === NetPriceType.CREDIT}
              isDisabled={
                invoiceCheck?.document_type ===
                InvoiceCheckDocumentType.CREDIT_NOTE
              }
              label={I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.netPrice.credit',
              )}
              name='net_price_type'
              onChange={(event: React.ChangeEvent<any>) => {
                if (event.target.value === 'on') {
                  setFieldValue(event.target.name, NetPriceType.CREDIT, true)
                }
              }}
            />
          </div>
        </>
      )}

      <div className='uk-margin-top'>
        <GoToNextFormStepButton
          buttonText={
            stepStatus === STEP_STATUS.EDITING
              ? I18n.t('general.button.save')
              : I18n.t('createInquiryTranslations.form.button.nextStep')
          }
          onClick={() => {
            if (invoiceCheck) {
              if (values.order_type === OrderType.OPEN_ORDER) {
                const valuesToSend: CreateInvoiceCheckPositionData = {
                  invoice_check: invoiceCheck.id,
                  order: `${values.order}`,
                  order_type: values.order_type as number,
                  number_of_containers: values.number_of_containers as number,
                  quantity_in_cubic_meters:
                    values.quantity_in_cubic_meters as number,
                  delivery_date:
                    isMoment(values.delivery_date) &&
                    values.delivery_date.isValid()
                      ? values.delivery_date.format('L')
                      : (values.delivery_date as string),
                  compensation_ton: values.compensation_ton
                    ? germanDecimalToInternationalFormat(
                        values.compensation_ton as string,
                      )
                    : undefined,
                  collection_date:
                    values.collection_date &&
                    isMoment(values.collection_date) &&
                    values.collection_date.isValid()
                      ? values.collection_date.format('L')
                      : (values.collection_date as string),
                  handle_cost_ton: values.handle_cost_ton
                    ? germanDecimalToInternationalFormat(
                        values.handle_cost_ton as string,
                      )
                    : undefined,
                  disposal_cost_ton: values.disposal_cost_ton
                    ? germanDecimalToInternationalFormat(
                        values.disposal_cost_ton as string,
                      )
                    : undefined,
                  weight_or_number: values.weight_or_number,
                  weight_receipts: values.weight_object_list,
                  avv: values.avv as string,
                  price_model: values.price_model as string,
                  container: values.container as number,
                  fraction: (values.fine_fraction ?? values.fraction) as number,
                  disposal_proof_number: values.disposal_proof_number as string,
                  disposer_number: values.disposer_number as string,
                  transportation_company_number:
                    values.transportation_company_number as string,
                  disposal_cost_container: values.disposal_cost_container
                    ? germanDecimalToInternationalFormat(
                        values.disposal_cost_container as string,
                      )
                    : undefined,
                  cost_container: values.cost_container
                    ? germanDecimalToInternationalFormat(
                        values.cost_container as string,
                      )
                    : undefined,
                  security_group: values.security_group as number,
                  compensation_container: values.compensation_container
                    ? germanDecimalToInternationalFormat(
                        values.compensation_container as string,
                      )
                    : undefined,
                  index: values.index ? (values.index as number) : undefined,
                  index_month: values.index_month
                    ? (values.index_month as number)
                    : undefined,
                  surcharge:
                    values.surcharge && values.index
                      ? germanDecimalToInternationalFormat(
                          values.surcharge as string,
                        )
                      : undefined,
                  reduction:
                    values.reduction && values.index
                      ? germanDecimalToInternationalFormat(
                          values.reduction as string,
                        )
                      : undefined,
                  transport_price_piece: values.transport_price_piece
                    ? germanDecimalToInternationalFormat(
                        values.transport_price_piece as string,
                      )
                    : undefined,
                }

                dispatch(
                  getInvoiceCheckPositionPrice(clearOtherValues(valuesToSend)),
                )
              }
            }
            saveStep()
          }}
          isDisabled={!isValid}
        />
      </div>
    </div>
  )
}
