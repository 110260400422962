import ActionTypes from '../actiontypes/orderclaim'
import { replaceInternalNote, replaceObjectInArray } from '../helper/general'

export const initialState = {
  item: {},
  itemList: [],
  pagination: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
}

/**
 * @description A reducer for order.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const orderClaimReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_ORDER_CLAIM_SUCCESS: {
      return {
        ...state,
        item: {
          ...state.item,
          ...action.payload,
        },
        itemList: [action.payload, ...state.itemList],
      }
    }
    case ActionTypes.GET_ORDER_CLAIM_SUCCESS: {
      return {
        ...state,
        item: {
          ...state.item,
          ...action.payload,
        },
      }
    }
    case ActionTypes.GET_ORDER_CLAIM_LIST_SUCCESS:
      return {
        ...state,
        itemList: action.payload.data,
        pagination: { loaded: true, ...action.payload.pagination },
      }
    // Action triggered after reviewing an order claim
    case ActionTypes.UPDATE_REVIEWED_ORDER_CLAIM_SUCCESS:
      return {
        ...state,
        item: initialState.item,
        itemList: replaceObjectInArray(action.payload, state.itemList),
      }
    case ActionTypes.UPDATE_ORDER_CLAIM_INTERNAL_NOTE_SUCCESS:
      return {
        ...state,
        itemList: replaceInternalNote(action.payload, state.itemList),
      }
    case ActionTypes.RESET_ORDER_CLAIM_ITEM_REQUEST:
      return {
        ...state,
        item: initialState.item,
      }
    default:
      return state
  }
}

export default orderClaimReducer
