import './style.scss'

import { number, oneOfType, string } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

export const DetailRowComponent = ({ label, path, value }) => {
  if (path) {
    return (
      <Link
        className='detail-rows uk-grid uk-grid-small uk-flex-left uk-child-width-1-2@s'
        to={path}
      >
        <div className='detail-row uk-margin-small-bottom'>{label}</div>

        <div className='detail-row detail-row-value uk-margin-small-bottom'>
          {value}
        </div>
      </Link>
    )
  }

  return (
    <>
      <div className='detail-row uk-margin-small-bottom'>{label}</div>
      <div className='detail-row detail-row-value uk-margin-small-bottom'>
        {value}
      </div>
    </>
  )
}

DetailRowComponent.propTypes = {
  label: string.isRequired,
  value: oneOfType([number, string]),
  path: string,
}

DetailRowComponent.defaultProps = {
  value: null,
  path: null,
}

export default DetailRowComponent
