import { func, shape } from 'prop-types'

import { ControlProps } from '../../schemes'

const ActionsScheme = shape({
  form: shape({}),
})

const CheckboxControlProps = {
  ...ControlProps,
  actions: ActionsScheme.isRequired,
  onChange: func,
}

export default CheckboxControlProps
