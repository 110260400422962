import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'
import { withRouter } from 'react-router'
import Spinner from 'react-spinkit'
import { compose } from 'recompose'
import { bool, func, shape, string } from 'prop-types'

import withErrorBoundary from 'helper/withErrorBoundary'

import Icon from '../../common/Fontello'

import SetPasswordForm from './components/SetPasswordForm'
import connector from './connector'

/**
 * @description This component renders and controls the feedback given to the user after clicking on user activation.
 * @function
 * @param {Object} props the component props
 */
class UserActivation extends Component {
  static propTypes = {
    actions: shape({
      user: shape({
        activateCurrentUser: func.isRequired,
      }).isRequired,
    }).isRequired,
    match: shape({
      params: shape({
        uid: string,
        token: string,
      }),
    }),
    isLoading: bool.isRequired,
    activation: shape({
      success: bool.isRequired,
      hasPassword: bool.isRequired,
      alreadyActive: bool.isRequired,
    }).isRequired,
    history: {
      push: func,
    }.isRequired, // in TS this is type { History } from history, but I'll not map the full type here
  }

  static defaultProps = {
    match: {},
  }

  /**
   * @description Component “lifecycle method” UNSAFE_componentWillMount
   */
  UNSAFE_componentWillMount() {
    this.props.actions.user.activateCurrentUser(
      this.props.match.params.uid,
      this.props.match.params.token,
    )
  }

  /**
   * @function
   * @return {*}
   */
  renderLoading() {
    // eslint-disable-line class-methods-use-this
    return (
      <div className='uk-flex uk-flex-center uk-margin-large-top'>
        <Spinner name='circle' />
      </div>
    )
  }

  /**
   * @function
   * @return {*}
   */
  renderSuccessCard() {
    // eslint-disable-line class-methods-use-this
    return (
      <div className='uk-card uk-card-default'>
        <div className='uk-card-body uk-text-center'>
          <div className='uk-heading-primary uk-text-success'>
            <Icon name='checkmark' />
          </div>

          <div>
            <h2>
              {I18n.t('userRegistrationForm.activationFeedback.success.title')}
            </h2>
          </div>

          <div>
            <p className='uk-text-bold uk-text-center'>
              {I18n.t(
                'userRegistrationForm.activationFeedback.success.message',
              )}
            </p>
          </div>
          <div className='uk-margin'>
            <button
              type='button'
              className='uk-button uk-button-primary'
              onClick={() => this.props.history.push('/anmelden')}
            >
              <Icon name='enter' />
              {I18n.t('userRegistrationForm.activationFeedback.success.button')}
            </button>
          </div>
        </div>
      </div>
    )
  }

  /**
   * @function
   * @return {*}
   */
  renderErrorCard() {
    // eslint-disable-line class-methods-use-this
    return (
      <div className='uk-card uk-card-default'>
        <div className='uk-card-body uk-text-center'>
          <div className='uk-heading-primary uk-text-danger'>
            <Icon name='cross' />
          </div>

          <div>
            <h2>
              {I18n.t('userRegistrationForm.activationFeedback.error.title')}
            </h2>
          </div>

          <div>
            <p className='uk-text-bold uk-text-center'>
              {I18n.t('userRegistrationForm.activationFeedback.error.message')}
            </p>
          </div>
          <div className='uk-margin'>
            <button
              type='button'
              className='uk-button uk-button-primary'
              onClick={() => this.props.history.push('/registrieren')}
            >
              <Icon name='enter' />
              {I18n.t('userRegistrationForm.activationFeedback.error.button')}
            </button>
          </div>
        </div>
      </div>
    )
  }

  /**
   * @function
   * @return {*}
   */
  renderPasswordFormCard() {
    return (
      <div className='uk-card uk-card-default'>
        <div className='uk-card-body uk-text-center'>
          <div className='uk-heading-primary uk-text-primary'>
            <Icon name='brightness-low' />
          </div>

          <div>
            <h2>
              {I18n.t('userRegistrationForm.activationFeedback.password.title')}
            </h2>
          </div>

          <div>
            <p className='uk-text-bold uk-text-center'>
              {I18n.t(
                'userRegistrationForm.activationFeedback.password.message',
              )}
            </p>
          </div>

          <SetPasswordForm
            uid={this.props.match.params.uid}
            token={this.props.match.params.token}
            onSuccess={() => this.props.history.push('/anmelden')}
          />
        </div>
      </div>
    )
  }

  /**
   * @function
   * @return {*}
   */
  renderCard() {
    if (this.props.isLoading) {
      return this.renderLoading()
    }
    if (this.props.activation.success) {
      if (!this.props.activation.hasPassword) {
        return this.renderPasswordFormCard()
      }
      return this.renderSuccessCard()
    }
    return this.renderErrorCard()
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    return (
      <>
        <Helmet>
          <title>{I18n.t('pageTitles.userActivate')}</title>
        </Helmet>

        <div className='login-container uk-align-center uk-width-1-1@s uk-width-3-5@l uk-width-1-3@xl'>
          {this.renderCard()}
        </div>
      </>
    )
  }
}

export default compose(withRouter, withErrorBoundary, connector)(UserActivation)
