import './style.scss'

import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'
import { Element as ScrollElement, scroller } from 'react-scroll'
import { shape, string, bool, number } from 'prop-types'

import EmptoMapPilotRegionImage from 'assets/img/homepage/empto_Karte_Pilotregionen.png'
import { ButtonLearnMore } from 'components/common/ButtonLearnMore'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import {
  BUTTON_BACKGROUND_COLOR,
  LinkButton,
} from 'components/common/LinkButton'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { BREAKPOINT } from 'constants/design'

import { ZipCodeButtonBar } from '../ZipCodeButtonBar'
import ZipCodeInput from '../ZipCodeInput'

import { HeroImage } from './components/HeroImage'
import { NoOfferResults } from './components/NoOfferResults'
import { OfferResults } from './components/OfferResults'
import OfferSearchIndicator from './components/OfferSearchIndicator'
import { connector } from './connector'
import {
  HERO_MODALS,
  SEARCH_RESULTS_SCROLL_ANCHOR,
  ZIP_CODE_HERO_STEP,
  ZIPCODE_INPUT_HEADLINE_SCROLL_ANCHOR,
} from './constants'

/**
 * @description Hero section which allows searching new offers quickly.
 * @constructor
 */
class IntroSection extends Component {
  static propTypes = {
    zipCode: shape({
      code: string,
      is_active: bool,
      disposer_count: number,
    }),
    zipCodeSubmitted: bool,
    showPrivateCustomerContent: bool,
    showWasteProducerContent: bool,
    showWasteDisposerContent: bool,
  }

  static defaultProps = {
    zipCode: null,
    zipCodeSubmitted: false,
    showPrivateCustomerContent: false,
    showWasteProducerContent: false,
    showWasteDisposerContent: false,
  }

  state = {
    lastStep: null,
    step: ZIP_CODE_HERO_STEP.ZIP_CODE_INPUT,
    displayZipCodeResults: false,
    openModal: null,
    showHeroImage: true,
  }

  /**
   * @description Component “lifecycle method” UNSAFE_componentWillMount
   */
  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowResize.bind(this))
    window.addEventListener(
      'orientationchange',
      this.handleWindowResize.bind(this),
    )

    // Execute the function when the component has been mounted
    this.updateHeroImageVisibility()
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.zipCode &&
      this.props.zipCode.code &&
      this.props.zipCodeSubmitted &&
      !prevProps.zipCodeSubmitted
    ) {
      this.setState(
        {
          displayZipCodeResults: true,
        },
        () => {
          this.handleStepChange(ZIP_CODE_HERO_STEP.OFFER_SEARCH)
        },
      )
    }

    if (prevProps.zipCodeSubmitted && !this.props.zipCodeSubmitted) {
      this.setState({
        lastStep: null,
        step: ZIP_CODE_HERO_STEP.ZIP_CODE_INPUT,
        displayZipCodeResults: false,
      })
    }
  }

  /**
   * @description Component “lifecycle method” UNSAFE_componentWillUnmount
   */
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize.bind(this))
    window.removeEventListener(
      'orientationchange',
      this.handleWindowResize.bind(this),
    )
  }

  /**
   * @description Method to toggle the region map modal.
   */
  handleModalToggle = (modal = null) => {
    this.setState({ openModal: modal })
  }

  /**
   * @description Stores the given zip code and triggers the next step.
   * @param zipCode
   */

  onZipCodeChange = zipCode => {
    const hasValidZipCode = zipCode && zipCode.code && zipCode.code.length === 5

    if (hasValidZipCode) {
      this.setState(
        {
          displayZipCodeResults: hasValidZipCode,
        },
        () => this.handleStepChange(ZIP_CODE_HERO_STEP.OFFER_SEARCH),
      )
      scroller.scrollTo(ZIPCODE_INPUT_HEADLINE_SCROLL_ANCHOR, {
        duration: 1000,
        smooth: true,
        offset: -500,
      })
    }
  }

  /**
   * @description calculates the value as css would do it (Taken from https://stackoverflow.com/a/44109531)
   * @param v Percent-value of viewport-width
   */
  calculateVw = v => {
    const w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0,
    )
    return (v * w) / 100
  }

  /**
   * @description method that is called after window resize
   */
  handleWindowResize = () => {
    this.updateHeroImageVisibility()
  }

  updateHeroImageVisibility = () => {
    // Its solved this way because chrome has a bug that using vh, vw values in calc does not work
    // see #1402 and #1474
    // 45vw = value of scss-variable {$hero-image-height-mobile}
    // 75px = value of scss-variable {$header-height-mobile}
    // 80px additional offset
    let showHeroImage = true
    if (window.innerWidth <= BREAKPOINT.LARGE) {
      const calculatedMinHeightPixels =
        Math.floor(this.calculateVw(45), 10) + 75 + 80
      showHeroImage = calculatedMinHeightPixels <= window.innerHeight
    }
    this.setState({ showHeroImage })
  }

  /**
   * @description handles the step change.
   * @param nextStep
   */
  handleStepChange = nextStep => {
    const { step } = this.state

    this.setState(
      {
        lastStep: step,
        step: nextStep,
      },
      () => {
        setTimeout(() => {
          this.setState({ lastStep: null })
        }, 500)
      },
    )
  }

  renderTiles = () => {
    const { lastStep, step } = this.state

    const { zipCode } = this.props

    return (
      <>
        {step === ZIP_CODE_HERO_STEP.OFFER_SEARCH && (
          <div
            className={classNames(
              'intro-section__offer-search-indicator-wrapper',
              'uk-animation-slide-bottom-small',
              'uk-margin-medium-top',
              {
                'uk-animation-reverse':
                  lastStep === ZIP_CODE_HERO_STEP.OFFER_SEARCH,
              },
            )}
          >
            <Media query={{ maxWidth: BREAKPOINT.LARGE }}>
              {matches => (
                <OfferSearchIndicator
                  onComplete={() => {
                    setTimeout(() => {
                      this.handleStepChange(ZIP_CODE_HERO_STEP.OFFER_RESULTS)
                      if (matches) {
                        scroller.scrollTo(SEARCH_RESULTS_SCROLL_ANCHOR, {
                          duration: 1000,
                          smooth: true,
                          offset: -75,
                        })
                      }
                    }, 1200)
                  }}
                >
                  <Translate
                    value='homepages.wasteProducer.zipCode.offerSearch'
                    code={zipCode.code}
                    className='uk-text-bold'
                  />
                </OfferSearchIndicator>
              )}
            </Media>
          </div>
        )}

        {step === ZIP_CODE_HERO_STEP.OFFER_RESULTS && (
          <>
            {!zipCode && <></>}
            {(!zipCode.is_active || zipCode.disposer_count < 1) && (
              <NoOfferResults zipCode={zipCode} />
            )}
            {zipCode.is_active && zipCode.disposer_count > 0 && (
              <OfferResults
                results={zipCode.disposer_count}
                zipCode={zipCode.code}
              />
            )}
          </>
        )}
      </>
    )
  }

  render() {
    const idRegionMapModalHeadline = uniqueId()

    const {
      showPrivateCustomerContent,
      showWasteProducerContent,
      showWasteDisposerContent,
    } = this.props

    return (
      <section
        className={classNames('intro-section uk-position-relative', {
          'intro-section__private-costumer-settings':
            showPrivateCustomerContent,
        })}
      >
        <div className='uk-grid uk-grid-collapse uk-height-1-1'>
          <div className='intro-section__zip-code-form-wrap uk-width-1-1 uk-width-1-2@l'>
            <div className='intro-section__zip-code-form'>
              <h1 className='intro-section__headline'>
                {showPrivateCustomerContent && (
                  <Translate value='homepages.privateCustomer.intro.headline' />
                )}
                {showWasteProducerContent && (
                  <Translate value='homepages.wasteProducer.zipCode.headline' />
                )}
                {showWasteDisposerContent && (
                  <Translate value='homepages.wasteDisposer.intro.headline' />
                )}
              </h1>
              <Headline
                color={HEADLINE_COLOR.DARK_GRAY}
                fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
                headlineStyle={HEADLINE_STYLE.H4}
                tag={HEADLINE_TAG.H2}
              >
                {showWasteProducerContent && (
                  <Translate value='homepages.wasteProducer.zipCode.description1' />
                )}
              </Headline>
              <p className='intro-section__description'>
                {showPrivateCustomerContent && (
                  <Translate value='homepages.privateCustomer.intro.description2' />
                )}
                {showWasteProducerContent && (
                  <Translate value='homepages.wasteProducer.zipCode.description2' />
                )}
                {showWasteDisposerContent && (
                  <Translate value='homepages.wasteDisposer.intro.description2' />
                )}
              </p>

              {(showWasteDisposerContent || showPrivateCustomerContent) && (
                <Media query={{ minWidth: BREAKPOINT.LARGE }}>
                  {isBigScreen => (
                    <div
                      className={classNames({
                        'intro-section__zip-code-form--center-button':
                          !isBigScreen,
                      })}
                    >
                      {/* REGISTER (disabled in #3228) */}
                      {/*{showWasteDisposerContent && (*/}
                      {/*  <LinkButton*/}
                      {/*    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}*/}
                      {/*    target={{*/}
                      {/*      pathname: '/registrieren',*/}
                      {/*      state: {*/}
                      {/*        initialSelection: COMPANY_ROLE.WASTE_COMPANY,*/}
                      {/*      },*/}
                      {/*    }}*/}
                      {/*  >*/}
                      {/*    <Translate value='homepages.wasteDisposer.intro.testEmptoButton' />*/}
                      {/*  </LinkButton>*/}
                      {/*)}*/}
                      {showPrivateCustomerContent && (
                        <LinkButton
                          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                          onClick={() => {
                            window.open('https://www.containerdienst.de')
                          }}
                        >
                          <Translate value='homepages.privateCustomer.intro.containerdienstButton' />
                        </LinkButton>
                      )}
                    </div>
                  )}
                </Media>
              )}
              {showWasteProducerContent && (
                <>
                  <Headline
                    color={HEADLINE_COLOR.DARK_GRAY}
                    fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                    fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
                    headlineStyle={HEADLINE_STYLE.H4}
                    tag={HEADLINE_TAG.H2}
                  >
                    <Translate value='homepages.wasteProducer.zipCode.subHeadline' />
                  </Headline>
                  <ScrollElement name={ZIPCODE_INPUT_HEADLINE_SCROLL_ANCHOR} />
                  {!this.state.displayZipCodeResults ? (
                    <div className='uk-animation-fade'>
                      <ZipCodeInput
                        onClick={this.onZipCodeChange}
                        onModalToggle={() =>
                          this.handleModalToggle(HERO_MODALS.REGION_MAP_MODAL)
                        }
                        inlineFlex
                      />
                    </div>
                  ) : (
                    <div className='uk-animation-fade'>
                      <ZipCodeButtonBar />
                    </div>
                  )}
                  <Media query={{ maxWidth: BREAKPOINT.LARGE }}>
                    <div className='uk-margin-medium-top'>
                      {this.renderTiles()}
                    </div>
                  </Media>
                </>
              )}
            </div>
          </div>
          <div className='uk-width-1-1 uk-width-1-2@l'>
            {this.state.showHeroImage && (
              <HeroImage
                toggled={this.state.displayZipCodeResults}
                showPrivateCustomerBackground={showPrivateCustomerContent}
                showWasteProducerBackground={showWasteProducerContent}
                showWasteDisposerBackground={showWasteDisposerContent}
              >
                {showWasteProducerContent && (
                  <Media query={{ minWidth: BREAKPOINT.LARGE }}>
                    {this.renderTiles()}
                  </Media>
                )}
              </HeroImage>
            )}
          </div>
        </div>

        {!showPrivateCustomerContent && (
          <ButtonLearnMore minWidth={BREAKPOINT.LARGE} />
        )}
        <Modal
          ariaDescribedBy={idRegionMapModalHeadline}
          displayOverTooltip
          isOpen={this.state.openModal === HERO_MODALS.REGION_MAP_MODAL}
          onClose={this.handleModalToggle}
        >
          <ModalHeader
            onClose={this.handleModalToggle}
            title={I18n.t('homepages.wasteProducer.zipCode.regionMap')}
            titleId={idRegionMapModalHeadline}
          />
          <img
            alt={I18n.t(
              'homepages.wasteProducer.zipCode.regionMapAlternativeText',
            )}
            src={EmptoMapPilotRegionImage}
          />
        </Modal>
      </section>
    )
  }
}

export default connector(IntroSection)
