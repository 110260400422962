import React, { Component } from 'react'
import { I18n } from 'react-i18nify'
import { bool, shape } from 'prop-types'

import DetailRow from 'components/common/DetailSwitcher/components/DetailRow'
import DetailSection from 'components/common/DetailSwitcher/components/DetailSection'
import { OrderScheme } from 'schemes/order'

/**
 * @description This component renders the order component.
 */
export class OrderComponent extends Component {
  render() {
    return (
      <>
        <DetailSection
          title={I18n.t('general.address')}
          icon={`${this.props.showAccordion ? 'mail' : ''}`}
          showAccordion={this.props.showAccordion}
        >
          <DetailRow
            label={I18n.t('inquiry.address')}
            value={
              this.props.order.offer_object.collection_address_object
                .display_name
            }
          />
        </DetailSection>

        <div
          className={`uk-accordion-content ${
            !this.props.showAccordion ? 'uk-hidden' : ''
          }`}
        >
          <DetailSection>
            <DetailRow
              label={I18n.t('inquiry.address')}
              value={
                this.props.order.offer_object.collection_address_object
                  .display_name
              }
            />

            {this.props.order.offer_object.collection_address_object
              .description && (
              <DetailRow
                label={I18n.t('inquiry.addressDescription')}
                value={
                  this.props.order.offer_object.collection_address_object
                    .description
                }
              />
            )}

            {this.props.order.offer_object.additional_contact_person && (
              <DetailRow
                label={I18n.t('inquiryForm.placeholder.contactPerson')}
                value={this.props.order.offer_object.additional_contact_person}
              />
            )}
          </DetailSection>
        </div>
      </>
    )
  }
}

OrderComponent.propTypes = {
  order: shape(OrderScheme).isRequired,
  showAccordion: bool.isRequired,
}

export default OrderComponent
