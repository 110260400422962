import './style.scss'

import React, { Component } from 'react'
import { Col, Row } from 'react-flexbox-grid'

import { TooltipIcon } from '../TooltipIcon'

import FormGroupError from './components/FormGroupError'
import FormGroupFieldContainer from './components/FormGroupFieldContainer'
import FormGroupLabel from './components/FormGroupLabel'
import { ControlDefaultProps } from './constants'
import fieldHasErrors from './helpers'
import { FormFieldProps } from './schemes'

/**
 * @description This is the base class for all form fields. From this can be derived or imported
 * see: http://davidkpiano.github.io/react-redux-form/docs/guides/custom-controls.html
 */
class FormField extends Component {
  /**
   * @param {Object} props the component props
   */
  constructor(props) {
    super(props)

    let { fieldColSize } = this.props

    if (
      (this.props.showLabelColumn || this.props.label) &&
      this.props.labelColSize + fieldColSize > 12
    ) {
      fieldColSize -= this.props.labelColSize + fieldColSize - 12

      if (fieldColSize === 0) fieldColSize = 12
    }

    this.state = {
      field: this.props.field,
      fieldColSize,
    }

    this.onLabelClick = this.onLabelClick.bind(this)
  }

  componentDidUpdate(prevProps) {
    if (this.props.field !== prevProps.field) {
      this.setState({
        field: this.props.field,
      })
    }
  }

  /**
   * @function
   * @description Gets called when user clicks on label
   */
  onLabelClick() {
    this.props.actions.form.focus(this.state.field.model)
    if (this.props.onLabelClick) {
      this.props.onLabelClick()
    }
  }

  /**
   * @function
   * @description Get all error messages.
   * If you enable errorOnlyIfNotPristine (default false), error messages are displayed ony for fields that have been
   * changed, so you do not see an initial error message when first seeing the field. Alternatively, you can set
   * `validateOn`on the form depending on whether the form has submitted but failed
   * (`validateOn={this.props.myForm.$form.submitFailed ? 'change' : 'submit'}`), which you should prefer. But in cases
   * where you do not want to validate only after submit, you can use errorOnlyIfNotPristine.
   * @return {*}
   */
  getErrors() {
    if (this.props.errorOnlyIfNotPristine && this.state.field.pristine)
      return null

    if (!fieldHasErrors(this.state.field)) return null

    return <FormGroupError model={this.state.field.model} />
  }

  /**
   * @function
   * @description Check if a field value exists for the state field.
   * @return {Boolean}
   */
  hasFieldValue() {
    if (this.state.field.value instanceof Array) {
      return this.state.field.value.length > 0
    }

    return !!this.state.field.value
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    const required =
      this.props.showRequiredDot === true ||
      (this.props.showRequiredDot !== false &&
        this.props.validators.isRequired &&
        !this.hasFieldValue())
    const errors = this.getErrors()

    return (
      <div
        className={`form-group${
          this.props.marginBottom ? ' form-group-margin-bottom' : ''
        }`}
      >
        <Row middle='xs'>
          {(this.props.showLabelColumn || this.props.label) && (
            <Col
              xs={12}
              sm={this.props.labelColSize}
              className={`label-col${
                this.props.labelColSize === 12 ? ' label-col-top' : ''
              }`}
            >
              {this.props.label && (
                <FormGroupLabel
                  label={this.props.label}
                  required={required}
                  errors={errors}
                  clickable={this.props.clickableLabel}
                  onClick={() => this.onLabelClick()}
                />
              )}

              {this.props.tooltip && <TooltipIcon {...this.props.tooltip} />}
              {this.props.afterTooltipLabel}
            </Col>
          )}

          <Col xs={12} sm={this.state.fieldColSize}>
            <FormGroupFieldContainer
              fieldComponent={this.props.fieldComponent}
              model={this.state.field ? this.state.field.model : null}
              required={required}
              errors={errors}
              errorMessages={this.props.errorMessages}
              showTooltip={this.props.showTooltip}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

FormField.propTypes = FormFieldProps

FormField.defaultProps = {
  ...ControlDefaultProps,
  showLabelColumn: false,
  onLabelClick: null,
  showRequiredDot: undefined,
}

export default FormField
