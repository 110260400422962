import './GoToNextFormStepButton.scss'

import React, { FC, useEffect, useState } from 'react'

import { BUTTON_BACKGROUND_COLOR, ProgressButton } from '../ProgressButton'

interface GoToNextFormStepButtonProps {
  buttonText: string
  onClick?: (any) => void
  isDisabled?: boolean
  isLoading?: boolean
  disableOnClick?: boolean
}

/**
 * Functional component to render Next Step button on the inquiry form
 * @function
 */
export const GoToNextFormStepButton: FC<GoToNextFormStepButtonProps> = ({
  buttonText = '',
  onClick = () => undefined,
  isDisabled = false,
  isLoading = false,
}) => {
  const [isDisabledState, setIsDisabledState] = useState(false)

  useEffect(() => {
    setIsDisabledState(isDisabled || isLoading)
  }, [isLoading, isDisabled])

  // isLoading is not immediately true after onClick,
  // therefore the button must get temporarily disabled
  // (prevents multiple submit actions)
  const handleOnClick = () => {
    onClick(null)
    setIsDisabledState(true)
  }

  return (
    <div className='go-to-next-step-form-step-button'>
      <ProgressButton
        backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
        onClick={handleOnClick}
        fullWidth
        isDisabled={isDisabledState}
        isLoading={isLoading}
      >
        {buttonText}
      </ProgressButton>
    </div>
  )
}
