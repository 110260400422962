import { Form, Formik } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import {
  DOCUMENT_STATUS_TYPE,
  DOCUMENT_TYPE,
  DOCUMENT_UNPAID_REASON,
} from 'components/invoice/constants'
import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'
import { updateEPDDocument } from 'actions/invoice'

import { SetDocumentUnpaidForm } from './SetDocumentUnpaidForm'

interface SetDocumentUnpaidModalProps {
  isOpen?: boolean
  onClose?: () => void
  document?: Invoice
}

export type DocumentUnpaidFormValues = Partial<{
  reopened_reason: string
  reopened_reason_text: string
}>

export const SetDocumentUnpaidModal: FC<SetDocumentUnpaidModalProps> = ({
  isOpen = false,
  onClose = () => undefined,
  document = undefined,
}) => {
  const dispatch = useDispatch()

  const documentIsCreditNote =
    document?.document_type === DOCUMENT_TYPE.CREDIT_NOTE

  const idSetDocumentUnpaidModalHeadline = uniqueId()

  const handleSubmit = values => {
    onClose()
    dispatch(
      updateEPDDocument({
        ...values,
        ...(documentIsCreditNote
          ? // backend expects different keys depending on the documents type.
            { credit_note_id: document?.id ?? 0 }
          : { invoice_id: document?.id ?? 0 }),
        document_status: DOCUMENT_STATUS_TYPE.OPEN,
      }),
    )
  }

  return (
    <Modal
      ariaDescribedBy={idSetDocumentUnpaidModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={
          documentIsCreditNote
            ? I18n.t(
                'bookkeepingTranslations.setDocumentUnpaidModal.titleCreditNote',
              )
            : I18n.t(
                'bookkeepingTranslations.setDocumentUnpaidModal.titleInvoice',
              )
        }
        titleId={idSetDocumentUnpaidModalHeadline}
      />
      <Formik
        initialValues={{
          reopened_reason: String(DOCUMENT_UNPAID_REASON.CUSTOMER_REFUND),
          reopened_reason_text: '',
        }}
        onSubmit={(values: DocumentUnpaidFormValues) => handleSubmit(values)}
        validationSchema={() =>
          yup.object().shape({
            reopened_reason: yup.string(),
            reopened_reason_text: yup.string(),
          })
        }
      >
        <Form
          className='set-document-unpaid-form'
          data-testid='set-document-unpaid-form'
          noValidate
        >
          <SetDocumentUnpaidForm onClose={onClose} />
        </Form>
      </Formik>
    </Modal>
  )
}
