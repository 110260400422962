export const CERTIFICATE_TYPE = {
  TYPE_EFB: 1,
  TYPE_OTHER: 2,
  TYPE_COMPANY: 3,
  TYPE_PRICE_AGREEMENT: 4,
  TYPE_DSZ_LICENSE_CONFIRMATION: 5,
  TYPE_DSZ_PARTICIPATION_CONFIRMATION: 6,
  TYPE_DSZ_XML: 7,
}

export const CERTIFICATE_TYPES = [
  {
    id: CERTIFICATE_TYPE.TYPE_EFB,
    name: 'certificateArchive.type.efb',
  },
  {
    id: CERTIFICATE_TYPE.TYPE_OTHER,
    name: 'certificateArchive.type.other',
  },
  {
    id: CERTIFICATE_TYPE.TYPE_COMPANY,
    name: 'certificateArchive.type.company',
  },
  {
    id: CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT,
    name: 'certificateArchive.type.priceAgreement',
  },
  {
    id: CERTIFICATE_TYPE.TYPE_DSZ_LICENSE_CONFIRMATION,
    name: 'certificateArchive.type.dszLicenseConfirmation',
  },
  {
    id: CERTIFICATE_TYPE.TYPE_DSZ_PARTICIPATION_CONFIRMATION,
    name: 'certificateArchive.type.dszParticipationConfirmation',
  },
  {
    id: CERTIFICATE_TYPE.TYPE_DSZ_XML,
    name: 'certificateArchive.type.dszXml',
  },
]

export const CERTIFICATE_STATUS = {
  STATUS_PENDING: 1,
  STATUS_ACCEPTED: 2,
  STATUS_REJECTED: 3,
}

export const CERTIFICATE_STATES = [
  {
    id: CERTIFICATE_STATUS.STATUS_PENDING,
    name: 'certificateArchive.status.pending',
  },
  {
    id: CERTIFICATE_STATUS.STATUS_ACCEPTED,
    name: 'certificateArchive.status.accepted',
  },
  {
    id: CERTIFICATE_STATUS.STATUS_REJECTED,
    name: 'certificateArchive.status.rejected',
  },
]

export const CERTIFICATE_BUSINESS_SEGMENT = {
  SEGMENT_EMPTO: 1,
  SEGMENT_EPD: 2,
}

export const CERTIFICATE_BUSINESS_SEGMENTS = [
  {
    id: CERTIFICATE_BUSINESS_SEGMENT.SEGMENT_EMPTO,
    name: 'certificateArchive.segment.empto',
  },
  {
    id: CERTIFICATE_BUSINESS_SEGMENT.SEGMENT_EPD,
    name: 'certificateArchive.segment.epd',
  },
]

export const CERTIFICATE_PRICE_AGREEMENT_DISPOSITE = {
  YES: 1,
  NO: 0,
}

export const CERTIFICATE_PRICE_AGREEMENT_DISPOSITE_CHOICE = [
  {
    id: CERTIFICATE_PRICE_AGREEMENT_DISPOSITE.NO,
    name: 'general.no',
  },
  {
    id: CERTIFICATE_PRICE_AGREEMENT_DISPOSITE.YES,
    name: 'general.yes',
  },
]

export default {
  ...CERTIFICATE_TYPE,
  ...CERTIFICATE_TYPES,
  ...CERTIFICATE_STATUS,
  ...CERTIFICATE_STATES,
  ...CERTIFICATE_PRICE_AGREEMENT_DISPOSITE_CHOICE,
}
