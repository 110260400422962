import { isSnapshot } from '../helper/snapshots'

/**
 * @description: adds a class to the documents body to display the default empto background gradient.
 * Note: This can cause problems with snapshotting/server-side-rendering, since the class is added to
 * the body and the body is outside the root div hydrated by react. The background will then stay on other
 * pages, since the hook's cleanup function is not triggered when redirecting. See #1449.
 */
export const addBackgroundGradient = (
  renderGradientBelowBottom?: boolean,
  doNotRender?: boolean,
) => {
  if (!doNotRender && !isSnapshot()) {
    const bodyDomRef = document.querySelector('body')
    const backgroundGradientClass = 'with-background-gradient'
    const backgroundGradientClassBelowBottom =
      'with-background-gradient--below-bottom'

    if (bodyDomRef) bodyDomRef.classList.add(backgroundGradientClass)
    if (bodyDomRef && renderGradientBelowBottom)
      bodyDomRef.classList.add(backgroundGradientClassBelowBottom)

    return (): void => {
      if (bodyDomRef) bodyDomRef.classList.remove(backgroundGradientClass)
      if (bodyDomRef && renderGradientBelowBottom)
        bodyDomRef.classList.remove(backgroundGradientClassBelowBottom)
    }
  }
  return undefined
}
