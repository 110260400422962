/**
 * Returns the name
 * @param country
 * @return {string}
 */
export const getListLabelForCountry = country => {
  if (country && country.name) {
    return country.name
  }
  return ''
}

export default {}
