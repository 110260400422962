import { connect } from 'react-redux'

import { resetApiFetchErrors } from 'actions/app'
import { changePassword } from 'actions/user'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

const errorSelector = createErrorSelector(['CHANGE_PASSWORD'])
const loadingSelector = createLoadingSelector(['CHANGE_PASSWORD'])
/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  error: errorSelector(state),
  isLoading: loadingSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  changePassword: (oldPassword, newPassword, callback) =>
    dispatch(changePassword(oldPassword, newPassword, callback)),
  resetApiFetchErrors: type => dispatch(resetApiFetchErrors(type)),
})

export default connect(mapStateToProps, mapDispatchToProps)
