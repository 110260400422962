import React, { ReactText } from 'react'
import { I18n } from 'react-i18nify'

import IconLabel from 'components/common/IconLabel'

import { ORDER_SWITCH_LABELS } from '../../constants'

/**
 * @description This method can be used in a history.push or window.open. It
 * returns a url leading to the OrderDetailPage of the given orderID and
 * further allows you to specify a label in the switcher that should be open
 * by default. If not switchLabel is defined, it will always open SHORT_OVERVIEW
 */
export const getOrderDetailURL = (
  orderID: ReactText,
  switchLabel: number | undefined = undefined,
) => {
  let url = `/order/${orderID}`
  if (switchLabel) url += `?show-label=${ORDER_SWITCH_LABELS.IMAGES}`

  return url
}

/**
 * @description Renders the order switcher nav component.
 */
export const OrderSwitcherNav = ({
  hideExecutionProofsTab = false,
  hideInvoiceCheckAttachmentsTab = false,
}: {
  hideExecutionProofsTab: boolean
  hideInvoiceCheckAttachmentsTab: boolean
}) => {
  const indexedLabelComponents = [
    {
      index: ORDER_SWITCH_LABELS.SHORT_OVERVIEW,
      component: (
        <IconLabel
          className='tab-btn'
          title={I18n.t('detailsSwitcher.shortOverview')}
        />
      ),
    },
    {
      index: ORDER_SWITCH_LABELS.ORDER,
      component: (
        <IconLabel
          className='tab-btn'
          icon='article'
          title={I18n.t('order.title')}
        />
      ),
    },
    {
      index: ORDER_SWITCH_LABELS.PARTIES,
      component: (
        <IconLabel
          className='tab-btn'
          icon='user'
          title={I18n.t('detailsSwitcher.parties')}
        />
      ),
    },
    {
      index: ORDER_SWITCH_LABELS.ORDER_DETAILS,
      component: (
        <IconLabel
          className='tab-btn'
          icon='document'
          title={I18n.t('orderDetails.details.title')}
        />
      ),
    },
    {
      index: ORDER_SWITCH_LABELS.ADDRESS,
      component: (
        <IconLabel
          className='tab-btn'
          icon='mail'
          title={I18n.t('general.address')}
        />
      ),
    },
    {
      index: ORDER_SWITCH_LABELS.IMAGES,
      component: (
        <IconLabel
          className='tab-btn'
          icon='photo-group'
          title={I18n.t('detailsSwitcher.images')}
        />
      ),
    },
    {
      index: ORDER_SWITCH_LABELS.EXECUTION_PROOFS,
      component: (
        <>
          {!hideExecutionProofsTab && (
            <IconLabel
              className='tab-btn'
              icon='document'
              title={I18n.t('general.executionProofs')}
            />
          )}
        </>
      ),
    },
    {
      index: ORDER_SWITCH_LABELS.INVOICE_CHECK_ATTACHMENTS,
      component: (
        <>
          {!hideInvoiceCheckAttachmentsTab && (
            <IconLabel
              className='tab-btn'
              icon='document'
              title={I18n.t('general.vouchers')}
            />
          )}
        </>
      ),
    },
  ]
  return indexedLabelComponents.map(item => item.component)
}
