import { connect } from 'react-redux'

import { cookiesAcceptedSelector } from 'selectors/app'

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  cookiesAccepted: cookiesAcceptedSelector(state),
})

export const connector = connect(mapStateToProps)
