import React, { Component } from 'react'
import { I18n } from 'react-i18nify'
import { arrayOf, shape } from 'prop-types'

import DetailRow from 'components/common/DetailSwitcher/components/DetailRow'
import DetailSection from 'components/common/DetailSwitcher/components/DetailSection'
import { SelectSecurityGroup } from 'components/common/SelectSecurityGroup'
import {
  getSelectedProtectionClassName,
  getSelectedSecurityLevelName,
} from 'components/common/SelectSecurityGroup/helpers'
import { getContainerName } from 'components/inquiry/helpers'
import { COLLECTION_CONTAINER_IDS } from 'constants/app'
import { get } from 'helper/general'
import { ContainerScheme } from 'schemes/container'
import { FractionScheme } from 'schemes/fraction'
import { OrderScheme } from 'schemes/order'

/**
 * @description This component renders the details component.
 */
export class DetailsComponent extends Component {
  static propTypes = {
    order: shape(OrderScheme).isRequired,
    fractionList: arrayOf(shape(FractionScheme)).isRequired,
    containerList: arrayOf(shape(ContainerScheme)).isRequired,
  }

  render() {
    // fraction/fine fraction and AVV are stored in order because they can be overridden by Execution Proof
    const fraction = this.props.fractionList.find(
      _fraction => _fraction.id === this.props.order.fraction,
    )
    const fineFraction = this.props.fractionList.find(
      _fraction => _fraction.id === this.props.order.fine_fraction,
    )

    const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
      Number(this.props.order.offer_object.container),
    )

    return (
      <>
        <DetailSection title={I18n.t('homepage.fractionSelectorDefault')}>
          <DetailRow
            label={I18n.t('inquiry.fraction')}
            value={get(() => fraction.name)}
          />
          <DetailRow
            label={I18n.t('inquiry.fineFraction')}
            value={
              fineFraction
                ? get(() => fineFraction.name)
                : I18n.t('offerForm.noFineFraction')
            }
          />
          {this.props.order.security_group && (
            <SelectSecurityGroup
              selectedSecurityGroup={this.props.order.security_group}
              renderProtectionClassInput={(
                { securityGroupList },
                { selectedProtectionClass },
              ) => (
                <DetailRow
                  label={I18n.t(
                    'selectSecurityGroupTranslations.protectionClass',
                  )}
                  value={getSelectedProtectionClassName(
                    securityGroupList,
                    selectedProtectionClass,
                  )}
                />
              )}
              renderSecurityLevelInput={(
                { securityGroupList },
                { selectedSecurityLevel },
              ) => (
                <DetailRow
                  label={I18n.t(
                    'selectSecurityGroupTranslations.securityLevel',
                  )}
                  value={getSelectedSecurityLevelName(
                    securityGroupList,
                    selectedSecurityLevel,
                  )}
                />
              )}
              onSelectionChange={() => undefined} // ReadOnly view doesn't need a change handler
            />
          )}
          <DetailRow
            label={I18n.t('offerForm.avvNumber.label')}
            value={this.props.order.avv_number}
          />
        </DetailSection>
        <br />
        <DetailSection title={I18n.t('inquiry.container')}>
          <DetailRow
            label={I18n.t('inquiry.container')}
            value={getContainerName(
              this.props.order.offer_object.container,
              this.props.containerList,
            )}
          />
          {!showQuantityInCubicMeters && (
            <DetailRow
              label={I18n.t('inquiry.numberOfContainers')}
              value={this.props.order.number_of_containers}
            />
          )}
          {showQuantityInCubicMeters && (
            <DetailRow
              label={I18n.t('inquiry.quantityInCubicMeters')}
              value={this.props.order.quantity_in_cubic_meters}
            />
          )}
          <DetailRow
            label={I18n.t('inquiry.withTop')}
            value={I18n.t(
              `general.${
                this.props.order.offer_object.with_top ? 'yes' : 'no'
              }`,
            )}
          />
        </DetailSection>
      </>
    )
  }
}

export default DetailsComponent
