import { useFormikContext } from 'formik'
import React, { FC, useContext } from 'react'
import { I18n } from 'react-i18nify'

import { FormStepSummaryRow } from 'components/common/FormSteps'
import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'

import {
  AddAgreementFormContext,
  AddAgreementFormValues,
} from '../AddAgreementFormSteps'

import { getValues, ROLES } from './helpers'

export const SelectCustomerAndPartnerSummary: FC = () => {
  const { values } = useFormikContext<AddAgreementFormValues>()
  const { editStep } = useContext(AddAgreementFormContext)

  return (
    <div className='maklerpremium-step-summary'>
      <div className='maklerpremium-step-summary_grid-body'>
        {ROLES.map(role => {
          const { selectedCompany } = getValues(role, values)
          return (
            <div key={role}>
              <FormStepSummaryRow
                label={I18n.t(
                  `addAgreementPageTranslations.steps.1.fields.${role}.company_name.label`,
                )}
                value={selectedCompany?.name}
              />
            </div>
          )
        })}
      </div>
      <div className='sidebar'>
        <ButtonLink
          className='sidebar__edit-link'
          linkColor={BUTTON_LINK_COLOR.PRIMARY}
          onClick={editStep}
        >
          {I18n.t('createInquiryTranslations.form.button.editStep')}
        </ButtonLink>
      </div>
    </div>
  )
}
