export const agreementDetailsTranslations = {
  changeDisposerButton: {
    buttonText: 'Wechsel des Entsorgungspartners',
  },
  postponeIntervalButton: {
    buttonText: 'Wochentage und/oder Rhythmus anpassen',
  },
  convertInquiryButton: {
    buttonText: 'Umwandlung in Dauerauftrag',
  },
  createNewOrderButton: {
    buttonText: 'Neuer Auftrag',
    tooltip:
      'Außerhalb des vereinbarten Zeitraumes können Sie keinen Auftrag erstellen.',
  },
  changeAgreementPeriodButton: {
    buttonText: 'Vereinbarungszeitraum verändern',
  },
  createNewOrderModal: {
    createNewOrderForm: {
      executionDateField: {
        label: 'Durchführung',
        placeholder: 'Durchführung',
      },
      timeOfDayField: {
        label: 'Zeitraum',
        placeholder: 'Zeitraum',
      },
      numberOfContainersField: {
        label: 'Anzahl der Behälter',
        placeholder: 'Anzahl der Behälter',
      },
      buttons: {
        cancel: 'Abbrechen',
        submit: 'Bestellung auslösen',
      },
    },
    modalTitle: 'Kostenpflichtige Bestellung',
    modalInfo: {
      text:
        '<p>Bitte wählen Sie Ihr gewünschtes Datum für diesen Entsorgungsvorgang.</p>' +
        '<p>Ihr Entsorgungspartner hat sich mit dem Abschluss dieser Vereinbarung verpflichtet, die Entsorgung ' +
        'innerhalb der unten angezeigten Reaktionszeit durchzuführen. Gerne können Sie Ihren Wunschtermin sowohl ' +
        'innerhalb als auch außerhalb dieser Reaktionszeit wählen.</p>' +
        '<p>Reaktionszeit/Leistungszeit: %{reactionTime} </p>',
      reactionTime: {
        hours24: '24 Stunden (Mo-Fr)',
        hours48: '48 Stunden (Mo-Fr)',
        hours120: '5 Werktage (Mo-Fr)',
      },
    },
  },
  showPreviousOrdersButton: {
    breadcrumb: 'Vereinbarungen',
    buttonText: 'Bisherige Aufträge',
  },
  banner: {
    accepted: '%{name} hat dieses Angebot am %{date} angenommen',
    cancelledByEmpto: 'empto hat dieses Angebot am %{date} storniert',
  },
  subtitle: 'Bis %{date}',
  pageTitle: 'Vereinbarung %{agreementId}',
  exportFilename: 'empto_Vereinbarungen_Uebersicht_%{date}.csv',
  breadcrumbTitle: 'Vereinbarung mit %{company}',
  teaser: {
    title: 'Vereinbarung %{agreementId} für %{address}',
    text:
      'Hier sehen Sie Ihre bestehende Vereinbarung mit Ihrem Partner und alle Details diese betreffend wie die ' +
      'Laufzeit der Anfrage, die Auftragsart und das Preismodell, sowie die genauen Auftragsdaten.',
  },
  section: {
    wasteDisposer: {
      title: 'Entsorger',
      fields: {
        offersCount: 'Angebote gesamt',
      },
    },
    agreement: {
      title: 'Vereinbarung',
      fields: {
        agreementId: 'Vereinbarungsnummer',
        inquiryId: 'Anfragenummer',
        offerId: 'Angebotsnummer',
      },
    },
    price: {
      title: 'Preismodell',
    },
    customer: {
      title: 'Kunde',
    },
    partner: {
      title: 'Partner',
    },
    activities: {
      title: 'Tätigkeiten',
    },
    newOrder: {
      title: 'Kostenpflichtige Bestellung',
      teaser: '',
    },
  },
  cta: {
    showCreate: {
      title: {
        wasteProducer: 'Soll Ihr Container gewechselt werden?',
        wasteDisposer: 'Sie suchen detailliertere Informationen?',
      },
      text: {
        wasteProducer:
          'Dann erstellen Sie ganz einfach einen neuen Auftrag zu dieser Vereinbarung.',
        wasteDisposer:
          'Die Übersicht aller Aufträge zu dieser Vereinbarung finden Sie hier.',
      },
    },
    createForm: {
      title: 'Kostenpflichtige Bestellung',
      text:
        '<p>Bitte wählen Sie Ihr gewünschtes Datum für diesen Entsorgungsvorgang.</p>' +
        '<p>Ihr Entsorgungspartner hat sich mit dem Abschluss dieser Vereinbarung verpflichtet, die Entsorgung ' +
        'innerhalb der unten angezeigten Reaktionszeit durchzuführen. Gerne können Sie Ihren Wunschtermin sowohl ' +
        'innerhalb als auch außerhalb dieser Reaktionszeit wählen.</p>' +
        '<p>Reaktionszeit/Leistungszeit: %{reactionTime} </p>',
      reactionTime: {
        hours24: '24 Stunden (Mo-Fr)',
        hours48: '48 Stunden (Mo-Fr)',
        hours120: '5 Werktage (Mo-Fr)',
      },
    },
    postponeInterval: {
      title: 'Möchten Sie die Wochentage oder den Rhythmus anpassen?',
    },
    buttons: {
      submit: 'Bestellung auslösen',
    },
  },
  errors: {
    headline: 'Bitte überprüfen Sie folgende Daten:',
  },
  label: {
    deliveryDate: 'Durchführung',
    timeOfDayDelivery: 'Zeitraum',
    numberOfContainers: 'Anzahl',
  },
  priceModels: {
    disposalFlatrate: 'Entsorgung Pauschalpreis (Brutto)',
    disposalSingle: 'Entsorgung Einzelpreis (Netto)',
    disposalContainer: 'Entsorgung Behälterpreis (Netto)',
    compensationContainer: 'Vergütung Behälterpreis (Netto)',
    compensationSingle: 'Vergütung Einzelpreis (Netto)',
  },
  creditLimitInfo:
    '<strong>Achtung</strong>: ' +
    '%{companyName} hat %{noOfUnpaidInvoices} Rechnungen in Höhe von %{sumOfUnpaidInvoices} € ' +
    'noch nicht bezahlt.',
}
