import './../common/interactiveTableTitleBar.scss'

import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import {
  BUTTON_BACKGROUND_COLOR,
  ICON_NAME,
  ICON_POSITION,
  IconButton,
} from 'components/common/IconButton'

interface interactiveTableProps {
  displayNoEntriesAvailableNotice?: boolean
  title: string | React.ReactNode
  icon: React.ReactNode
  buttonOnClick: () => void
}

export const InteractiveTable: FC<interactiveTableProps> = ({
  title,
  icon,
  children,
  buttonOnClick,
}) => {
  return (
    <div className='communicationcenter-interactive-table'>
      <div
        className='communicationcenterpage-interactive-table-title-bar communicationcenterpage-interactive-table-title-bar--blue'
        style={{ paddingBottom: children ? 0 : '20px' }}
      >
        <div className='communicationcenterpage-interactive-table-title-bar__title'>
          <div className='communicationcenterpage-interactive-table-title-bar__icon'>
            {icon}
          </div>
          <div className='communicationcenterpage-interactive-table-title-bar__title-name'>
            {title}
          </div>
        </div>
        {!children && (
          <Translate
            value='communicationCenterPageTranslations.noEntriesAvailable'
            className='communicationcenterpage-interactive-table-title-bar__notice'
          />
        )}
        <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
          <IconButton
            iconName={ICON_NAME.PLUS}
            iconPosition={ICON_POSITION.RIGHT}
            onClick={buttonOnClick}
            backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
            className='communicationcenterpage-interactive-table-title-bar__new-entry-button'
          >
            <Translate value='communicationCenterPageTranslations.addNewEntryButtonLabel' />
          </IconButton>
        </ButtonBar>
      </div>
      {children}
    </div>
  )
}
