import { bool, func, shape } from 'prop-types'

import { ControlProps } from '../../schemes'

const ActionsScheme = shape({
  form: shape({
    merge: func,
    filter: func,
  }),
})

const FileUploadControlProps = {
  ...ControlProps,
  actions: ActionsScheme.isRequired,
  singleFormField: bool, // If your form field directly contains the ID of an attachment, set this to true.
  uploadComponent: func, // If you want to use another component than "FileUpload", pass it here.
  addMapProps: shape({}), // Optional additional props to pass to DatePicker's "mapProps"
}

export default FileUploadControlProps
