import './style.scss'

import classNames from 'classnames'
import React, { FC, useEffect, useRef, useState } from 'react'
import Media from 'react-media'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'

import { BREAKPOINT } from 'constants/design'
import logo from 'assets/svg/empto-logo.svg'
import { getCurrentUserSelector, getLoggedInSelector } from 'selectors/user'

import AccountActivationNotice from '../../common/AccountActivationNotice'
import InfoBanner, {
  INFO_BANNER_BACKGROUND_COLOR,
} from '../../common/InfoBanner'
import { PAGE_DIMENSION_ID } from '../../common/PageDimensions'
import { COMPANY_STATUS } from '../../company/constants'

import { DesktopMenu } from './DesktopMenu'
import { OffCanvasMenu } from './OffCanvasMenu'
import { UserDropdown } from './UserDropdown'

/**
 * @description This component renders and controls a header component.
 */
export const Header: FC = () => {
  const [isUserDropDownOpen, setIsUserDropDownOpen] = useState(false)

  const userDropDownContainer = useRef<HTMLLIElement>(null)

  const location = useLocation()

  const currentUser = useSelector(getCurrentUserSelector)
  const isLoggedIn = !!useSelector(getLoggedInSelector)

  /**
   * @descriptions Sets the open state for all drop-downs to false.
   */
  const closeAllDropDowns = event => {
    if (
      !userDropDownContainer.current ||
      !userDropDownContainer.current.contains(event.target)
    ) {
      setIsUserDropDownOpen(false)
    }
  }

  /**
   * @descriptions Toggles the drop down open state for the user menu.
   */
  const toggleUserDropDownOpen = () => {
    setIsUserDropDownOpen(!isUserDropDownOpen)
  }

  useEffect(() => {
    document.addEventListener('click', closeAllDropDowns, true)
  }, [])

  useEffect(() => {
    // TODO check if this works or if it closes the dropdowns right away
    setIsUserDropDownOpen(false)
  }, [location])

  // breakpoint where the header should transform between mobile or tablet/desktop view
  const tabletMenuBreakpoint = BREAKPOINT.XLARGE

  const isOnPublicPage = [
    '/impressum',
    '/rechtliche-hinweise',
    '/anmelden',
    '/abfallmanager',
    '/datenschutz',
    '/registrieren',
    '/entsorger',
    '/gewerbetreibende',
    '/privatkunden',
    '/abfallarten',
    '/downloads',
  ].includes(location.pathname)

  // This hint should only be shown for logged-in users who haven't completed their company information yet as
  // this information is crucial for using the platform.
  // It should not be shown on public pages.
  const showAccountActivationNoticeInfoBanner =
    currentUser &&
    currentUser.company_object &&
    currentUser.company_object.status &&
    currentUser.company_object.status !== COMPANY_STATUS.STATUS_ACTIVE &&
    !location.pathname.startsWith('/company/profile') &&
    !isOnPublicPage

  return (
    <>
      <div
        data-page-dimension-id={PAGE_DIMENSION_ID.HEADER}
        style={{
          zIndex: 1000,
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        <header
          className={classNames('header', {
            'header--banner-below': showAccountActivationNoticeInfoBanner,
          })}
        >
          <div className='uk-container uk-container-large full-height'>
            <nav className='uk-navbar uk-height-1-1'>
              {/* LOGO */}
              <div className='uk-navbar-left uk-flex-1 single-flex-line'>
                <NavLink to='/'>
                  <img className='logo uk-logo' alt='empto' src={logo} />
                </NavLink>

                {/* MENU ITEMS */}
                <Media query={{ minWidth: tabletMenuBreakpoint }}>
                  <DesktopMenu />
                </Media>
              </div>

              {/* META */}
              <div className='uk-navbar-right'>
                {isLoggedIn && (
                  <ul
                    className='uk-navbar-nav dropdown-list'
                    data-uk-navbar='
                      mode: click,
                      delay-hide: 200,
                      delay-hide: 200,
                      boundary-align: true;
                      align: right;
                    '
                  >
                    <Media query={{ minWidth: tabletMenuBreakpoint }}>
                      <li
                        className='dropdown-list-item'
                        ref={userDropDownContainer}
                      >
                        <UserDropdown
                          isVisible={isUserDropDownOpen}
                          onClick={toggleUserDropDownOpen}
                        />
                      </li>
                    </Media>
                  </ul>
                )}
                <Media query={{ maxWidth: tabletMenuBreakpoint - 1 }}>
                  <OffCanvasMenu />
                </Media>
              </div>
            </nav>
          </div>
          {showAccountActivationNoticeInfoBanner && (
            <InfoBanner backgroundColor={INFO_BANNER_BACKGROUND_COLOR.WARNING}>
              <AccountActivationNotice />
            </InfoBanner>
          )}
        </header>
      </div>
    </>
  )
}
