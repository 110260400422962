import axios, { AxiosInstance, AxiosResponse, Method } from 'axios'
import Cookies from 'js-cookie'
import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'

import { logout } from '../actions/user'
import { APP_CONSTANTS } from '../constants/app'

export function storedLoggedIn() {
  return Cookies.get('loggedIn')
}

/**
 * @class
 * @description This service class handles all REST Calls
 */
class HttpServiceDefinition {
  tokenRefreshRunning = false // Simple way to prevent refresh token request to run in loop.

  service: AxiosInstance

  dispatch

  /**
   * @function
   * @description Init the axios library and register the interceptors.
   */
  constructor() {
    this.service = axios.create({
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })

    // This is a stub to be replaced with an actual dispatch method (from the store) during setup
    this.dispatch = action => {
      console.warn('HttpService can not dispatch action', action)
    }
  }

  setLoggedIn: (stayLoggedIn: boolean) => void = stayLoggedIn => {
    if (stayLoggedIn) {
      Cookies.set('loggedIn', true, { expires: 14, sameSite: 'Strict' })
    } else {
      Cookies.set('loggedIn', true, { sameSite: 'Strict' })
    }
  }

  removeLoggedIn = () => {
    Cookies.remove('loggedIn')
  }

  backOffLoop = async path => {
    // Backoff loop in case the access token is currently refreshing
    if (
      path !== `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/token/refresh/`
    ) {
      // for-loop for awaiting the token refresh
      for (let backoffCounter = 0; this.tokenRefreshRunning; backoffCounter++) {
        await new Promise(res => setTimeout(res, 1000)) // wait 1 second
        // Assume something went wrong during the token refresh if we have to wait more than 3 times and reset
        if (backoffCounter >= 3) {
          this.dispatch(logout())
          this.tokenRefreshRunning = false
        }
      }
    }
  }

  /**
   * @description Method to handle the request by method.
   * @param method {string} the request method type.
   * @param {string} path is a string of API path.
   * @param {*} data is an object with API data.
   * @param {*} params is an object with API params.
   * @return {Promise<AxiosResponse<any>>}
   */
  handleRequest: (
    method: Method,
    path: string,
    data?: any,
    params?: any,
  ) => Promise<AxiosResponse<any>> = (method, path, data = {}, params = {}) => {
    // Wait in case the access token is currently refreshing
    if (this.tokenRefreshRunning) {
      this.backOffLoop(path)
    }

    return this.service
      .request({
        url: path,
        method,
        data,
        params,
      })
      .catch(error => {
        if (
          error.response.status === 401 &&
          // Exclude refresh and login endpoint to avoid infinite loops when refreshing the access token
          path !==
            `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/token/refresh/` &&
          path !== `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/logout/`
        ) {
          // Try to refresh the access token in case the request got rejected with a 401
          return this.attemptTokenRefresh({ url: path, method, data, params })
        } else {
          return this.handleError(error)
        }
      })
  }

  /**
   * @function
   * @description Interceptor for the error response
   * @param {Object} error is an object with the error response from API.
   * @return {*}
   */
  handleError: (error: any) => Promise<any> | void = error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (
            error.request.responseURL ===
            `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/logout/`
          ) {
            this.removeLoggedIn()
          }
          break
        case 404:
          return Promise.reject(
            Object.assign(
              new Error('Request failed with status code 404'),
              error,
              { hideToastr: true },
            ),
          )
        default:
          break
      }

      return Promise.reject(error)
    }

    toastr.error('', I18n.t('message.error.networkError'))

    return Promise.reject(
      Object.assign(new Error(), error, { hideToastr: true }),
    )
  }

  /**
   * Function for refreshing the current access token. Gets triggered when a request returns a 401 and attempts to
   * send a request to the refresh endpoint. If the request was successful it retries the originally failed request.
   * Otherwise we assume that the refresh token is expired and logout the user.
   * @param requestConfig configuration of the originally failed request
   */
  attemptTokenRefresh = (requestConfig?: any) => {
    // Only attempt to refresh the access token if not another refresh is running
    if (!this.tokenRefreshRunning) {
      this.tokenRefreshRunning = true
      return this.post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/token/refresh/`,
        {},
      )
        .then(() => {
          this.tokenRefreshRunning = false
          // Resend the originally failed request
          return this.handleRequest(
            requestConfig.method,
            requestConfig.url,
            requestConfig.data,
            requestConfig.params,
          )
        })
        .catch(() => {
          // If the refresh fails we assume that the token is expired and logout the user
          this.tokenRefreshRunning = false
          this.dispatch(logout())
        })
    } else {
      // If the token refresh is already running send it back to the handleRequest into the backoff loop
      return this.handleRequest(
        requestConfig.method,
        requestConfig.url,
        requestConfig.data,
        requestConfig.params,
      )
    }
  }

  /**
   * @function
   * @description Fires the get request
   * @param {string} path is a string of API path.
   * @param {*} params is an object with API params.
   * @return {*}
   */
  get: (path: string, params: any) => Promise<AxiosResponse<any>> = (
    path,
    params,
  ) => this.handleRequest('get', path, {}, params)

  /**
   * @function
   * @description Fires the patch request
   * @param {string} path is a string of API path.
   * @param {Object} data is an object to submit to API.
   * @return {*}
   */
  patch: (path: string, data: any) => Promise<AxiosResponse<any>> = (
    path,
    data,
  ) => this.handleRequest('patch', path, data)

  /**
   * @function
   * @description Fires the put request
   * @param {string} path is a string of API path.
   * @param {Object} data is an object to submit to API.
   * @return {*}
   */
  put: (path: string, data: any) => Promise<AxiosResponse<any>> = (
    path,
    data,
  ) => this.handleRequest('put', path, data)

  /**
   * @function
   * @description Fires the put request
   * @param {string} path is a string of API path.
   * @param {Object} data is an object to submit to API.
   * @return {*}
   */
  post: (path: string, data: any) => Promise<AxiosResponse<any>> = (
    path,
    data,
  ) => this.handleRequest('post', path, data)

  /**
   * @function
   * @description Fires the put request
   * @param {string} path is a string of API path.
   * @param {Object} data is an object to submit to API.
   * @return {*}
   */
  delete: (path: string, data: any) => Promise<AxiosResponse<any>> = (
    path,
    data,
  ) => this.handleRequest('delete', path, data)
}

export const HttpService = new HttpServiceDefinition()
