import React, { FC } from 'react'

import { Input, INPUT_TYPE, InputProps, KEY_ENTER } from '../Input'

interface InputNumberProps extends InputProps {
  maxLength?: number
  minNumber?: number
  onSubmit?: React.KeyboardEventHandler
}

export const InputNumber: FC<InputNumberProps> = ({
  maxLength = 255,
  minNumber = 1,
  onChange,
  onSubmit,
  ...rest
}) => {
  const maxNumber = parseInt(Array(maxLength).fill('9').join(''), 10)

  /**
   * Evaluates if the onChange event should be triggered.
   * @param {Event} event
   */
  const onValueChange = event => {
    if (event.target.value.length <= maxLength) onChange(event)
  }

  /* Prevents that mouse-input on number-inputs does change the number value */
  const onWheelEvent = event => {
    event.target.blur()
  }

  /**
   * Tests that an input is always a number.
   * @param {Event} event
   */
  const allowOnlyNumbers = event => {
    if (/^\D+$/.test(event.key)) event.preventDefault()
  }

  /**
   * Submits the form if the user hits the enter key and an onSubmit method is provided.
   * @param {Event} event
   */
  const allowSubmissionByEnter = event => {
    if (onSubmit && event && event.key === KEY_ENTER) onSubmit(event)
  }

  /**
   * Combines the keypress event methods.
   * @param {Event} event
   */
  const keyPressEvents = event => {
    allowOnlyNumbers(event)
    allowSubmissionByEnter(event)
  }

  return (
    <Input
      max={maxNumber}
      min={minNumber}
      onChange={onValueChange}
      onKeyPress={keyPressEvents}
      onWheel={onWheelEvent}
      type={INPUT_TYPE.NUMBER}
      {...rest}
    />
  )
}
