export const CompanyNotesTranslations = {
  saveNote: 'Notiz speichern',
  collapseNote: 'Weniger anzeigen',
  expandNote: 'Mehr anzeigen',
  noNotes: 'Noch keine Notizen vorhanden.',
  moreNotes: 'Alle Notizen anzeigen',
  confirmDeleteHeader: 'Notiz löschen?',
  confirmDeleteText: 'Möchten Sie diese Notiz wirklich unwiderruflich löschen?',
  newNotePlaceholder: 'Ihre Notiz',
}
