import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as UserActions from 'actions/user'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getUserList } from 'selectors/user'

const loadingSelector = createLoadingSelector(['GET_MY_USERS'])
const errorSelector = createErrorSelector(['GET_MY_USERS'])

/**
 * @function
 * @param {Object} store the redux store
 * @return {Object}
 */
const mapStateToProps = state => ({
  company: state.company.item,
  companyForm: state.forms.company.item,
  userList: getUserList(state),
  isLoading: loadingSelector(state),
  error: errorSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getCompanyUsers: companyId =>
    dispatch(UserActions.getCompanyUsers(companyId)),
  actions: {
    user: bindActionCreators(UserActions, dispatch),
  },
  dispatch,
})

export default connect(mapStateToProps, mapDispatchToProps)
