import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import Spinner from 'react-spinkit'

import { Combobox, ComboboxProps } from '../Combobox'

import { filterAndSortOptions, isEmptyOrWhitespace } from './helpers'

export type Option = {
  label: string
  value: React.ReactText
}

export type StaticComboboxProps = Omit<
  ComboboxProps,
  'onInputChange' | 'inputValue'
>

export const StaticCombobox: FC<StaticComboboxProps> = ({
  className,
  dataTestId = '',
  error = '',
  isDisabled = false,
  isLoading = false,
  isRequired = false,
  label = '',
  name,
  noResultsText,
  onSelectionChange,
  options: initialOptions,
  placeholder = '',
  selectedOption: initialSelectedOption,
  showCheckmark = false,
  subLabel = '',
  tooltip,
  withCheckmark = false,
}) => {
  // Use options from props for initial rendering
  const [filteredOptions, setFilteredOptions] = useState<Option[] | undefined>()
  const [inputValue, setInputValue] = useState<string>('')
  const [selectedOption, setSelectedOption] = useState<Option>()

  useEffect(() => {
    // Show all values if input is empty or exact match
    if (
      isEmptyOrWhitespace(inputValue) ||
      (selectedOption && selectedOption.label === inputValue)
    ) {
      setFilteredOptions(initialOptions)
    } else {
      setFilteredOptions(filterAndSortOptions(initialOptions, inputValue))
    }
  }, [initialOptions, inputValue, selectedOption])

  useEffect(() => {
    if (initialSelectedOption && initialSelectedOption.label) {
      setSelectedOption(initialSelectedOption)
      setInputValue(initialSelectedOption.label)
    } else {
      setSelectedOption(undefined)
      setInputValue('')
    }
  }, [initialSelectedOption])

  const onInputChange = event => {
    setInputValue(event.target.value)
  }

  const handleSelectionChange = event => {
    setInputValue(event.target.value.label)
    setSelectedOption(event.target.value)

    onSelectionChange({
      ...event,
      target: {
        ...event.target,
        value: event.target.value.value,
      },
    })
  }

  const errorValue = typeof error === 'string' ? error : error(name)
  const showCheckmarkValue =
    typeof showCheckmark === 'boolean' ? showCheckmark : showCheckmark(name)

  if (typeof filteredOptions === 'undefined') {
    return <Spinner name='circle' />
  }

  return (
    <Combobox
      className={className}
      dataTestId={dataTestId}
      error={errorValue}
      inputValue={inputValue}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isRequired={isRequired}
      label={label}
      subLabel={subLabel}
      name={name}
      noResultsText={noResultsText}
      onInputChange={onInputChange}
      onSelectionChange={handleSelectionChange}
      options={filteredOptions}
      placeholder={placeholder || I18n.t('general.placeholder.all')}
      selectedOption={selectedOption}
      tooltip={tooltip}
      withCheckmark={withCheckmark}
      showCheckmark={showCheckmarkValue}
    />
  )
}
