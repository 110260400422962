export const offerDetailsTranslations = {
  pageTitle: 'Mein Angebot %{offerId}',
  breadcrumbTitle: 'Angebot von %{company}',
  teaser: {
    title: 'Ihr Angebot %{offerId} zur Anfrage %{inquiryId} für %{fraction}',
    text:
      'Hier können Sie Ihr veröffentlichtes Angebot komplett einsehen. Eine Stornierung des Angebots ist ' +
      'möglich, wenn der Anfrageersteller Ihr Angebot noch nicht angenommen hat. Bei Annahme eines Angebots werden ' +
      'Sie unmittelbar benachrichtigt, um die von Ihnen angebotene Dienstleistung rechtzeitig planen zu können.',
  },
  cta: {
    buttons: {
      cancel: 'Angebot stornieren',
      cancelAgreement: 'Widerruf der Vereinbarung',
      resend: 'Angebot erneut versenden',
      newOffer: 'Neues Angebot +',
      agreement: 'Vereinbarung öffnen',
    },
    cancelOffer: {
      title: 'Möchten Sie Ihr Angebot stornieren?',
      text: 'Eine Wiederherstellung der Daten ist anschließend nicht möglich.',
    },
    cancelAgreement: {
      title: 'Widerruf der Vereinbarung',
      text: 'Sind Sie sicher, dass Sie diese Vereinbarung stornieren möchten?',
    },
    resendOffer: {
      title: 'Möchten Sie das Angebot erneut versenden?',
      text: '-kein Text-',
    },
    newOffer: {
      title: '%{name} hat dieses Angebot am %{date} storniert',
    },
    expired: {
      title:
        'Das System hat dieses Angebot am %{date} storniert, da es abgelaufen war.',
    },
    accepted: {
      title: 'Dieses Angebot wurde vom Gewerbetreibenden akzeptiert',
      text: 'Alle notwendigen Informationen finden Sie nun in der Vereinbarung.',
    },
    closed: {
      inquiry: {
        cancelled: 'Die Anfrage wurde vom Gewerbetreibenden storniert.',
        closed:
          'Die Anfrage ist ausgelaufen, ohne dass Ihr Angebot angenommen wurde.',
        concluded: 'Ihr Angebot wurde vom Gewerbetreibenden abgelehnt.',
      },
    },
  },
}
