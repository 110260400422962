import ActionTypes from '../actiontypes/holiday'

export const initialHolidayState = {
  itemList: [],
}

/**
 * @description A reducer for the holiday package
 * @param {Object} state is the current globale state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
export const holidayReducer = (state = initialHolidayState, action) => {
  switch (action.type) {
    case ActionTypes.GET_HOLIDAYS_SUCCESS:
      return {
        ...state,
        itemList: action.payload,
      }
  }
  return state
}
