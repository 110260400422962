import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'

import { PausingModalForm } from './components/PausingModalForm'

interface PauseCompanyModalProps {
  callback: any
  showPausingModal: boolean
  company: Company
}

export const PauseCompanyModal: FC<PauseCompanyModalProps> = ({
  company,
  callback,
  showPausingModal,
}) => {
  const modalId = uniqueId() // from lodash package. Generate a unique ID

  return (
    <Modal
      ariaDescribedBy={modalId}
      isOpen={showPausingModal}
      onClose={callback}
    >
      <ModalHeader
        onClose={callback}
        title={I18n.t('pauseCompanyModalTranslations.modalTitle')}
        titleId={modalId}
      />
      <PausingModalForm company={company} onSuccess={callback} />
    </Modal>
  )
}
