import ActionTypes from '../actiontypes/workload'
import { TABLE_PAGE_SIZE_10 } from '../constants/app'

/**
 * @function
 * @name getWorkloads
 * @description Loads a list of workloads objects.
 * @return {Object}
 */
export const getWorkloads = (
  page?: number,
  filters?: Record<string, any>,
  pageSize = TABLE_PAGE_SIZE_10,
) => ({
  type: ActionTypes.GET_WORKLOADS_REQUEST,
  payload: {
    page,
    filters,
    pageSize,
  },
})

/**
 * @function
 * @name exportWorkload
 * @return {Object}
 */
export const exportWorkload = filters => ({
  type: ActionTypes.EXPORT_WORKLOAD_REQUEST,
  payload: {
    filters,
  },
})
