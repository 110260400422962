import { Form, Formik } from 'formik'
import moment from 'moment/moment'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { setETA } from 'actions/order'
import { BUTTON_BACKGROUND_COLOR, BUTTON_TYPE } from 'components/common/Button'
import { ApiValidationMessages } from 'components/common/Form/components/ApiValidationMessages'
import {
  Headline,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import InputDate from 'components/common/InputDate/index'
import { ProgressButton } from 'components/common/ProgressButton'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { UserPermission } from 'constants/user'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

interface SetETAComponentProps {
  order: Order
  headlineText?: string | undefined
  etaCollection?: boolean
  etaDelivery?: boolean
}

export const SetETA: FC<SetETAComponentProps> = ({
  order,
  headlineText = I18n.t('orderStatusTasks.setETA.headline'),
  etaCollection = false,
  etaDelivery = false,
}) => {
  const setETAErrorSelector = createErrorSelector(['SET_ETA_FOR_ORDER'])
  const setETALoadingSelector = createLoadingSelector(['SET_ETA_FOR_ORDER'])
  const dispatch = useDispatch()
  const submitSetEtaCollection = data =>
    dispatch(setETA({ eta_collection: data, orderId: order.id }))
  const submitSetEtaDelivery = data =>
    dispatch(setETA({ eta_delivery: data, orderId: order.id }))
  const isLoading = useSelector(state => setETALoadingSelector(state))
  const apiError = useSelector(state => setETAErrorSelector(state))

  return (
    <Formik
      onSubmit={values => {
        if (etaCollection) submitSetEtaCollection(values.eta)
        if (etaDelivery) submitSetEtaDelivery(values.eta)
      }}
      initialValues={{
        eta: '',
      }}
      validationSchema={() =>
        yup.object().shape({
          eta: yup
            .string()
            .required(I18n.t('message.validation.required'))
            .typeError(''),
        })
      }
    >
      {({
        errors,
        handleSubmit,
        isSubmitting,
        isValid,
        setFieldValue,
        submitCount,
        touched,
        values,
      }) => (
        <Form
          className=''
          data-testid='set-eta-form'
          noValidate // Disable browser validation
        >
          <RequiredPermissions
            requiredPermissions={[UserPermission.SET_ETA_FOR_ORDER]}
          >
            <div className='order-status-task--on-order-details'>
              <Headline tag={HEADLINE_TAG.H4} headlineStyle={HEADLINE_STYLE.H4}>
                {headlineText}
              </Headline>

              {/* This Api Validation message is needed here to get a customized error message
              (zB. Address already exists) */}
              <ApiValidationMessages error={apiError} />

              <div className='order-status-task__fields order-status-task__fields--single'>
                <InputDate
                  error={submitCount > 0 && touched.eta ? errors.eta : ''}
                  placeholder={I18n.t(
                    'orderStatusTasks.setETA.placeholder.eta',
                  )}
                  label={I18n.t('orderStatusTasks.setETA.placeholder.eta')}
                  isRequired={!values.eta}
                  minTime={moment().hours(6).minutes(0)}
                  maxTime={moment().hours(22).minutes(0)}
                  name='eta'
                  onChange={value => setFieldValue('eta', value)}
                  value={values.eta}
                  showTimeSelect
                  showTimeSelectOnly
                />
              </div>
              <ProgressButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                dataTestId='set-eta-form-submit'
                isDisabled={
                  (submitCount > 0 && !isValid) || (isSubmitting && isLoading)
                }
                isLoading={isSubmitting && isLoading}
                onClick={handleSubmit}
                type={BUTTON_TYPE.SUBMIT}
              >
                <Translate value='orderStatusTasks.setETA.submitButton' />
              </ProgressButton>
            </div>
          </RequiredPermissions>
        </Form>
      )}
    </Formik>
  )
}
