export const myOfferFilterTranslation = {
  company: 'Unternehmen',
  fraction: 'Abfallart',
  fractionEmpto: '(empto)',
  fractionEPD: '(EPD)',

  offerNumber: 'Angebotsnummer',
  orderType: 'Auftragsart',
  status: 'Status',
}
