import './FormStep.scss'

import classNames from 'classnames'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'
import { Element as ScrollElement } from 'react-scroll'

import Icon from '../Fontello'

interface FormStepProps {
  stepNumber?: number | boolean
  title: string
  showDivider?: boolean
  isDisabled?: boolean
  showProgressLine?: boolean
}

export const FormStep: FC<FormStepProps> = ({
  children = null,
  stepNumber,
  title,
  showDivider = false,
  isDisabled = false,
  showProgressLine = false,
}) => (
  <div className='form-step'>
    <div
      className={classNames('form-step__step-number', {
        'form-step__step-number--disabled': isDisabled,
      })}
    >
      {stepNumber || <Icon name='checkmark' />}
    </div>
    {showProgressLine && (
      <div
        className={classNames('form-step__progress-line', {
          'form-step__progress-line--disabled': isDisabled,
        })}
      />
    )}
    <div
      className={classNames('form-step__content', {
        'form-step__content--disabled': isDisabled,
      })}
    >
      <ScrollElement name={`form_step_${stepNumber}`} />
      <h3 className='form-step__headline'>{I18n.t(title)}</h3>
      {!isDisabled && children && (
        <div className='form-step__form'>{children}</div>
      )}
      {showDivider && (
        <hr
          className={classNames('form-step__divider', {
            'form-step__divider--no-content': isDisabled,
          })}
        />
      )}
    </div>
  </div>
)
