export enum DSZ_SHOPS {
  ZMART = 1,
  VERPACKUNGSLIZENZIERUNG,
  ZMART_CHINA,
}

export const DSZ_SHOPS_CHOICES = [
  {
    id: DSZ_SHOPS.ZMART,
    translationKey: 'dszGeneralTranslations.shops.zmart',
  },
  {
    id: DSZ_SHOPS.VERPACKUNGSLIZENZIERUNG,
    translationKey: 'dszGeneralTranslations.shops.verpackungslizensierung',
  },
  {
    id: DSZ_SHOPS.ZMART_CHINA,
    translationKey: 'dszGeneralTranslations.shops.zmartChina',
  },
]
