import classNames from 'classnames'
import React, { FC, Fragment, ReactNode } from 'react'
import { Translate } from 'react-i18nify'

import { CardDetailsListItem } from './CardDetailsListItem'

interface CardDetailsListProps {
  items: (string | ReactNode)[]
  className?: string
  hideCTA?: boolean
}

/**
 * A component which renders the details list section with its styles of our new cards.
 * @constructor
 */
export const CardDetailsList: FC<CardDetailsListProps> = ({
  items,
  className,
  hideCTA,
}) => (
  <ul className={classNames('new-card__details-list', className)}>
    {items.map((item, index) => (
      <Fragment
        key={`details-list-item-${index}`} // eslint-disable-line react/no-array-index-key
      >
        {item}
      </Fragment>
    ))}

    {!hideCTA && (
      <CardDetailsListItem withoutIcon>
        <span className='new-card__link'>
          <Translate value='general.button.details' />
        </span>
      </CardDetailsListItem>
    )}
  </ul>
)
