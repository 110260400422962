import { Form, Formik } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'
import * as yup from 'yup'

import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'
import {
  DOCUMENT_STATUS_TYPE,
  DOCUMENT_TYPE,
} from 'components/invoice/constants'
import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'
import { updateEPDDocument } from 'actions/invoice'

import { SetDocumentCancelledForm } from './SetDocumentCancelledForm'

interface SetDocumentCancelModalProps {
  isOpen?: boolean
  onClose?: () => void
  document?: Invoice
}

export type DocumentCancelFormValues = Partial<{
  cancel_reason_text: string
  cancel_reason: string
}>

export const SetDocumentCancelledModal: FC<SetDocumentCancelModalProps> = ({
  isOpen = false,
  onClose = () => undefined,
  document = undefined,
}) => {
  const dispatch = useDispatch()
  const updateStatus = useSelector(
    createRequestStatusSelector(['UPDATE_INVOICE']),
  )

  const [updateFired, setUpdateFired] = useState(false)

  const documentIsCreditNote =
    document?.document_type === DOCUMENT_TYPE.CREDIT_NOTE

  const idSetDocumentCancelModalHeadline = uniqueId()

  const handleSubmit = values => {
    dispatch(
      updateEPDDocument({
        ...values,
        ...(documentIsCreditNote
          ? // backend expects different keys depending on the documents type.
            { credit_note_id: document?.id ?? 0 }
          : { invoice_id: document?.id ?? 0 }),
        document_status: DOCUMENT_STATUS_TYPE.CANCELLED,
      }),
    )

    setUpdateFired(true)
  }

  useEffect(() => {
    if (
      [REQUEST_STATUS.SUCCESS, REQUEST_STATUS.ERROR].includes(
        updateStatus as REQUEST_STATUS,
      ) &&
      updateFired
    ) {
      setUpdateFired(false)
      onClose()
    }
  }, [onClose, updateFired, updateStatus])

  return (
    <Modal
      ariaDescribedBy={idSetDocumentCancelModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={
          documentIsCreditNote
            ? I18n.t(
                'bookkeepingTranslations.setDocumentCancelledModal.titleCreditNote',
              )
            : I18n.t(
                'bookkeepingTranslations.setDocumentCancelledModal.titleInvoice',
              )
        }
        titleId={idSetDocumentCancelModalHeadline}
      />
      {updateStatus === REQUEST_STATUS.LOADING ? (
        <div className='uk-flex uk-flex-center uk-padding'>
          <Spinner name='circle' />
        </div>
      ) : (
        <Formik
          initialValues={{
            cancel_reason_text: '',
            cancel_reason: '',
          }}
          onSubmit={(values: DocumentCancelFormValues) => handleSubmit(values)}
          validationSchema={() =>
            yup.object().shape({
              cancel_reason_text: yup.string(),
              cancel_reason: yup
                .string()
                .required(
                  I18n.t(
                    'bookkeepingTranslations.setDocumentCancelledModal.fields.cancelledCommentRequired',
                  ),
                ),
            })
          }
        >
          <Form
            className='set-document-cancel-form'
            data-testid='set-document-cancel-form'
            noValidate
          >
            <SetDocumentCancelledForm onClose={onClose} />
          </Form>
        </Formik>
      )}
    </Modal>
  )
}
