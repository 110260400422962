import { Form, Formik, FormikProps, FormikValues } from 'formik'
import { Location } from 'history'
import React, { FC, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { withRouter } from 'react-router'
import * as yup from 'yup'

import withErrorBoundary from 'helper/withErrorBoundary'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import { checkRequiredPermissions } from 'helper/permissions'
import { UserPermission } from 'constants/user'
import { COLLECTION_CONTAINER_IDS } from 'constants/app'

import { getContainerPackagePrice } from '../../common/DetailsPage/helper'
import { DocumentReview } from '../../common/DocumentReview'
import { ExecutionProofReview } from '../../common/DocumentReview/ExecutionProofReview'
import { isSecurityRequired } from '../../common/SelectSecurityGroup/helpers'
import {
  EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS,
  EXECUTION_PROOF_STATUS,
} from '../constants'

import { connector } from './connector'

interface ExecutionProofDetailsPageComponentProps extends FormikProps<any> {
  execution: ExecutionProof
  getFractions: () => void
  getContainers: () => void
  getIndexes: () => void
  getExecutionProof: (executionProofId: number) => void
  updateReviewedExecutionProof: (
    execution: FormikValues,
    history: History,
  ) => void
  resetExecutionProofItem: () => void
  match: {
    params: {
      executionProofId: string
    }
  }
  containerList: Container[]
  fractionList: Fraction[]
  indexList: OfferIndex[]
  user: User
  location: Location
  isLoading: {
    getExecutionProof?: boolean
    updateReviewedExecutionProof?: boolean
  }
  history: History
}

/**
 * @description This component displays the review page of certificates.
 */
export const ExecutionProofDetailsPageComponent: FC<
  ExecutionProofDetailsPageComponentProps
> = ({
  execution,
  getFractions,
  getContainers,
  getIndexes,
  getExecutionProof,
  updateReviewedExecutionProof,
  resetExecutionProofItem,
  match,
  containerList,
  fractionList,
  indexList,
  user,
  location,
  isLoading = {
    getExecutionProof: false,
    updateReviewedExecutionProof: false,
  },
  history,
}) => {
  // trigger reset certificate item on component unmount
  useEffect(() => () => resetExecutionProofItem(), [resetExecutionProofItem])

  useEffect(() => {
    if (fractionList.length === 0) getFractions()
  }, [fractionList.length, getFractions])

  useEffect(() => {
    if (containerList.length === 0) getContainers()
  }, [containerList.length, getContainers])

  useEffect(() => {
    if (indexList.length === 0) getIndexes()
  }, [getIndexes, indexList.length])

  useEffect(() => {
    const executionProofId = Number(match.params.executionProofId)
    getExecutionProof(executionProofId)
  }, [getExecutionProof, match.params.executionProofId])

  const isReview =
    !execution.reviewed_at &&
    checkRequiredPermissions(user.permission_codenames, [
      UserPermission.REVIEW_EXECUTION_PROOF,
    ])

  if (
    isLoading.getExecutionProof ||
    Number(match.params.executionProofId) !== execution.id
  )
    return null

  return (
    <div className='execution-review-page'>
      <Formik
        initialValues={{
          fraction_is_equal: '',
          fraction: '',
          fine_fraction: '',
          avv: '',
          weight_date: '',
          weight_or_number:
            EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.INPUT_WEIGHT,
          weight_object_list: [
            {
              weight_receipt: '',
              weight: '',
            },
          ],
          number_of_containers: '',
          quantity_in_cubic_meters: '',
          internal_note: '',
          status: String(EXECUTION_PROOF_STATUS.STATUS_PENDING),
          reject_reason: '',
          index: '',
          rent_price_container_month: '',
          disposal_cost_container: '',
          transport_price_piece: '',
          disposal_cost_ton: '',
          handle_cost_ton: '',
          compensation_container: '',
          surcharge: '',
          discount: '',
          security_group: '',
        }}
        validationSchema={() =>
          yup.object().shape({
            fraction_is_equal: yup.string().typeError(''),
            fraction: yup.string().typeError(''),
            fine_fraction: yup.string().typeError(''),
            avv: yup.string().typeError(''),
            weight_date: yup.string().typeError(''),
            weight_or_number: yup.number().typeError(''),
            weight_object_list: yup.array().of(
              yup.object().shape({
                weight_receipt: yup.string().typeError(''),
                weight: yup.string().typeError(''),
              }),
            ),
            number_of_containers: yup.string().typeError(''),
            // needs to be string because the input decimal field works with strings, otherwise, all the comma rules
            // will not work
            quantity_in_cubic_meters: yup.string().typeError(''),
            internal_note: yup.string().typeError(''),
            status: yup.string().typeError(''),
            reject_reason: yup.string().typeError(''),
            index: yup.string().typeError(''),
            rent_price_container_month: yup.string().typeError(''),
            disposal_cost_container: yup.string().typeError(''),
            transport_price_piece: yup.string().typeError(''),
            disposal_cost_ton: yup.string().typeError(''),
            handle_cost_ton: yup.string().typeError(''),
            compensation_container: yup.string().typeError(''),
            surcharge: yup.string().typeError(''),
            discount: yup.string().typeError(''),
          })
        }
        validate={values => {
          const containerHasPackagePrice = getContainerPackagePrice(
            execution.order_object.offer_object.container,
            containerList,
          )

          const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
            Number(execution.order_object.offer_object.container),
          )

          const isValidPrice = value =>
            typeof value === 'string'
              ? value !== ''
              : !Number.isNaN(Number(value)) && Number(value) >= 0

          const errors: {
            fraction_is_equal?: string
            fraction?: string
            fine_fraction?: string
            avv?: string
            weight_date?: string
            weight_or_number?: string
            number_of_containers?: string
            quantity_in_cubic_meters?: string
            internal_note?: string
            status?: string
            reject_reason?: string
            index?: string
            rent_price_container_month?: string
            disposal_cost_container?: string
            transport_price_piece?: string
            disposal_cost_ton?: string
            handle_cost_ton?: string
            compensation_container?: string
            surcharge?: string
            discount?: string
            weight_receipt?: string
            weight?: string
            security_group?: string
          } = {}

          if (
            Number(values.status) !== EXECUTION_PROOF_STATUS.STATUS_REJECTED
          ) {
            if (values.fraction_is_equal === '') {
              errors.fraction_is_equal = I18n.t(
                'executionProofDetailsPageTranslations.validation.fractionNotEqual',
              )
            }

            if (
              values.fraction_is_equal ===
              EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.FRACTION_IS_NOT_EQUAL
            ) {
              if (values.fraction === '') {
                errors.fraction = I18n.t(
                  'executionProofDetailsPageTranslations.validation.fraction',
                )
              }

              if (values.avv === '')
                errors.avv = I18n.t(
                  'executionProofDetailsPageTranslations.validation.avv',
                )

              if (!isValidPrice(values.rent_price_container_month)) {
                errors.rent_price_container_month = ' ' // errors in Formik must be strings
              }

              if (containerHasPackagePrice) {
                if (!isValidPrice(values.disposal_cost_container)) {
                  errors.disposal_cost_container = ' ' // errors in Formik must be strings
                }
              } else {
                if (!isValidPrice(values.transport_price_piece)) {
                  errors.transport_price_piece = ' ' // errors in Formik must be strings
                }

                if (
                  values.disposal_cost_ton &&
                  !isValidPrice(values.disposal_cost_ton)
                ) {
                  errors.disposal_cost_ton = ' ' // errors in Formik must be strings
                }

                if (values.compensation_container) {
                  if (
                    values.index &&
                    !isValidPrice(values.surcharge) &&
                    !isValidPrice(values.discount)
                  ) {
                    errors.surcharge = ' ' // errors in Formik must be strings
                    errors.discount = ' ' // errors in Formik must be strings
                  }

                  if (
                    !isValidPrice(values.index) &&
                    !isValidPrice(values.surcharge)
                  ) {
                    errors.surcharge = ' ' // errors in Formik must be strings
                  }

                  if (!isValidPrice(values.handle_cost_ton)) {
                    errors.handle_cost_ton = ' ' // errors in Formik must be strings
                  }
                }
              }
            }

            if (values.weight_date === '') {
              errors.weight_date = I18n.t(
                'executionProofDetailsPageTranslations.validation.weightDate',
              )
            }

            if (
              values.weight_or_number ===
              EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.INPUT_WEIGHT
            ) {
              values.weight_object_list.forEach(val => {
                if (val.weight_receipt === '') {
                  errors.weight_receipt = I18n.t(
                    'executionProofDetailsPageTranslations.validation.weightReceipt',
                  )
                }
                if (val.weight === '') {
                  errors.weight = I18n.t(
                    'executionProofDetailsPageTranslations.validation.fractionNotEqual',
                  )
                }
              })
            }

            if (
              values.weight_or_number ===
              EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.INPUT_NUMBER_OF_CONTAINERS
            ) {
              if (
                !showQuantityInCubicMeters &&
                values.number_of_containers === ''
              ) {
                errors.number_of_containers = I18n.t(
                  'executionProofDetailsPageTranslations.validation.numberOfContainers',
                )
              }

              if (showQuantityInCubicMeters) {
                if (
                  typeof values.quantity_in_cubic_meters === 'string' &&
                  values.quantity_in_cubic_meters === ''
                ) {
                  errors.quantity_in_cubic_meters = I18n.t(
                    'executionProofDetailsPageTranslations.validation.numberOfContainers',
                  )
                }
                if (
                  !Number.isNaN(Number(values.quantity_in_cubic_meters)) &&
                  Number(values.quantity_in_cubic_meters) <= 0
                ) {
                  errors.quantity_in_cubic_meters = I18n.t(
                    'executionProofDetailsPageTranslations.validation.numberOfContainers',
                  )
                }
              }
            }

            if (
              Number(values.status) === EXECUTION_PROOF_STATUS.STATUS_PENDING
            ) {
              errors.status = I18n.t(
                'executionProofDetailsPageTranslations.validation.status',
              )
            }
          }

          if (
            Number(values.status) === EXECUTION_PROOF_STATUS.STATUS_REJECTED
          ) {
            if (values.reject_reason === '') {
              errors.reject_reason = I18n.t(
                'executionProofDetailsPageTranslations.validation.rejectReason',
              )
            }
          }
          if (
            isSecurityRequired(null, fractionList, values) &&
            values.security_group === ''
          ) {
            errors.security_group = I18n.t(
              'executionProofDetailsPageTranslations.validation.securityGroup',
            )
          }

          return errors
        }}
        onSubmit={values => {
          const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
            Number(execution.order_object.offer_object.container),
          )

          const valuesToSend: Partial<FormikValues> = values

          if (
            values.weight_or_number ===
            EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.INPUT_WEIGHT
          ) {
            delete valuesToSend.number_of_containers
            delete valuesToSend.quantity_in_cubic_meters
          }
          if (
            values.weight_or_number ===
            EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.INPUT_NUMBER_OF_CONTAINERS
          ) {
            valuesToSend.weight_object_list = []

            if (!showQuantityInCubicMeters) {
              delete valuesToSend.quantity_in_cubic_meters
            }

            if (showQuantityInCubicMeters) {
              delete valuesToSend.number_of_containers
            }
          }

          if (
            values.fraction_is_equal ===
            EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.FRACTION_IS_EQUAL
          ) {
            delete valuesToSend.fraction
            delete valuesToSend.fine_fraction
            delete valuesToSend.avv
            delete valuesToSend.index
            delete valuesToSend.rent_price_container_month
            delete valuesToSend.disposal_cost_container
            delete valuesToSend.transport_price_piece
            delete valuesToSend.disposal_cost_ton
            delete valuesToSend.handle_cost_ton
            delete valuesToSend.compensation_container
            delete valuesToSend.surcharge
            delete valuesToSend.discount
          }

          if (
            Number(values.status) === EXECUTION_PROOF_STATUS.STATUS_REJECTED
          ) {
            delete valuesToSend.fraction_is_equal
            delete valuesToSend.fraction
            delete valuesToSend.fine_fraction
            delete valuesToSend.avv
            delete valuesToSend.weight_date
            delete valuesToSend.weight_or_number
            delete valuesToSend.number_of_containers
            delete valuesToSend.quantity_in_cubic_meters
            delete valuesToSend.index
            delete valuesToSend.rent_price_container_month
            delete valuesToSend.disposal_cost_container
            delete valuesToSend.transport_price_piece
            delete valuesToSend.disposal_cost_ton
            delete valuesToSend.handle_cost_ton
            delete valuesToSend.compensation_container
            delete valuesToSend.surcharge
            delete valuesToSend.discount
            valuesToSend.weight_object_list = []
          }

          updateReviewedExecutionProof(
            {
              ...values,
              id: Number(match.params.executionProofId),
              order: execution.order,
            },
            history,
          )
        }}
        render={formikProps => (
          <DocumentReview
            location={location}
            breadcrumb={{
              breadcrumbTitle: isReview
                ? I18n.t(
                    'executionProofDetailsPageTranslations.breadcrumb.title.review',
                  )
                : I18n.t(
                    'executionProofDetailsPageTranslations.breadcrumb.title.details',
                  ),
              prevLinkTitle: I18n.t(
                'executionProofDetailsPageTranslations.breadcrumb.prevLinkTitle',
              ),
              prevLinkTo: '/execution-proof/all',
              teaserText: '',
              teaserTitle: '',
            }}
            pageTitle={I18n.t('pageTitles.executionProofs')}
            documentTitle={
              isReview
                ? I18n.t(
                    'executionProofDetailsPageTranslations.pageTitle.review',
                    { number: execution.order },
                  )
                : I18n.t(
                    'executionProofDetailsPageTranslations.pageTitle.details',
                    { number: execution.order },
                  )
            }
            formSubmissionButton={{
              buttonText: I18n.t(
                'executionProofDetailsPageTranslations.submitButtonText',
              ),
              action: () => formikProps.handleSubmit(),
              isLoading:
                formikProps.isSubmitting &&
                isLoading.updateReviewedExecutionProof,
              isDisabled:
                (formikProps.submitCount > 0 && !formikProps.isValid) ||
                (formikProps.isSubmitting &&
                  isLoading.updateReviewedExecutionProof),
            }}
            showFormSubmitButton={isReview}
            showDocumentDetails
            showOrderDetails
            reviewDocument={execution}
          >
            <Form
              className='uk-panel execution-proof-review-form'
              data-testid='execution-proof-review-form'
              noValidate // Disable browser validation
            >
              <ExecutionProofReview
                execution={execution}
                fractionList={fractionList}
                containerList={containerList}
                indexList={indexList}
                isReview={isReview}
                formikProps={formikProps}
              />
            </Form>
          </DocumentReview>
        )}
      />
    </div>
  )
}

export const ExecutionProofDetailsPage = connector(
  withRouter(
    withErrorBoundary(withApiErrorHandling(ExecutionProofDetailsPageComponent)),
  ) as any,
)
