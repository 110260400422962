export const CardListSliderTranslations = {
  cardListSlider: {
    pagination: {
      paginationText: ' von ',
    },
    newNearInquiries: {
      headline: 'Neue Anfragen in',
      subline: 'Ihrer Nähe',
    },
    newOpenOffers: {
      headline: 'Offene Angebote',
      subline: 'noch nicht bestätigt',
    },
    openCompanyInquiries: {
      headline: 'Meine offenen',
      subline: 'Anfragen',
    },
    plannedCompanyOrders: {
      headline: 'Geplante Aufträge',
      subline: 'der kommenden 14 Tage',
    },
    newCompanyOrders: {
      headline: 'Neue Aufträge',
      subline: 'noch nicht disponiert',
    },
    currentAgreements: {
      headline: 'Aktive Vereinbarungen',
      subline: 'Daueraufträge auf Abruf',
    },
  },
}
