import './style.scss'

import React, { FC } from 'react'
import { useSelector } from 'react-redux'

import { ORDER_CLAIM_PROOF_STATUS } from 'components/common/DocumentReview/OrderClaimReview/OrderClaimDetails/constants'
import { PROGRESS_BAR_COLOR, ProgressBar } from 'components/common/ProgressBar'
import {
  ORDER_PROGRESS_STATES,
  ORDER_PROGRESS_TYPES,
  ORDER_STATES,
  ORDER_STATUS,
} from 'components/order/constants'
import { getLatestOrderStatusTranslation } from 'components/order/helpers'
import { getValue } from 'helper/general'
import { getStatusConf } from 'helper/orderstatus'
import { getCurrentUserSelector } from 'selectors/user'

import OrderStatusListItem from './components/OrderStatusListItem/index'

interface OrderStatusListProps {
  orderStates: OrderStatus[]
  order: Order
}

export const OrderStatusList: FC<OrderStatusListProps> = ({
  orderStates,
  order,
}) => {
  const user = useSelector(getCurrentUserSelector)

  if (!order || !order.order_status_objects.length) {
    return null
  }

  const [lastOrderStatus] = orderStates
    .filter(
      item => item.progressType === ORDER_PROGRESS_TYPES.ORDER_PROGRESS_STATUS,
    )
    .slice(-1)

  const progressStatusKey = getLatestOrderStatusTranslation(
    order.progress_status,
    order,
    user,
  )
    .split('.')
    .pop()
  const totalProgress = ORDER_PROGRESS_STATES[progressStatusKey]

  const getOrderStatusListItem = (orderStatus: OrderStatus, relabeledType) => (
    <OrderStatusListItem
      key={`${orderStatus.id}-${orderStatus.value}`}
      order={order}
      orderStatus={orderStatus}
      orderStatusConfig={getStatusConf(orderStatus, order, user, relabeledType)}
      isDone={
        (orderStatus.progressType !==
          ORDER_PROGRESS_TYPES.ORDER_PROGRESS_CLAIM &&
          (lastOrderStatus.id !== orderStatus.id ||
            orderStatus.value === ORDER_STATUS.AEZ_RATED_DISPOSER ||
            orderStatus.value === ORDER_STATUS.DISPOSER_RATED_PRODUCER ||
            orderStatus.value === ORDER_STATUS.INVOICING_DOCUMENTS_CREATED ||
            orderStatus.value ===
              ORDER_STATUS.OTHER_INVOICING_DOCUMENTS_CREATED ||
            orderStatus.value === ORDER_STATUS.DISPOSER_DID_POSTPONE_DELIVERY ||
            orderStatus.value ===
              ORDER_STATUS.DISPOSER_DID_POSTPONE_COLLECTION ||
            orderStatus.value === ORDER_STATUS.CLOSED)) ||
        orderStatus.claim_status === ORDER_CLAIM_PROOF_STATUS.STATUS_ACCEPTED ||
        orderStatus.claim_status === ORDER_CLAIM_PROOF_STATUS.STATUS_REJECTED
      }
      relabeledType={relabeledType}
    />
  )

  return (
    <div className='order-status-list'>
      <ProgressBar
        color={
          totalProgress >= 90
            ? PROGRESS_BAR_COLOR.GREEN
            : PROGRESS_BAR_COLOR.ORANGE
        }
        percent={totalProgress}
      />

      <div className='order-status-list-items uk-margin-top'>
        {orderStates
          .filter(
            orderStatus =>
              !getValue(orderStatus.value, 'hideIfNotLatest', ORDER_STATES) ||
              lastOrderStatus.id === orderStatus.id,
          )
          .map(orderStatus => {
            if (
              orderStatus.value !==
              ORDER_STATUS.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE
            ) {
              return getOrderStatusListItem(orderStatus, undefined)
            } else {
              return orderStatus.relabeled_types!.map(relabeledType =>
                getOrderStatusListItem(orderStatus, relabeledType),
              )
            }
          })}
      </div>
    </div>
  )
}
