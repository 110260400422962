export const EPDAddInvoiceFormStepsTranslations = {
  steps: {
    1: {
      title: 'Rechnungsempfänger und Leistungsadresse auswählen',
      fields: {
        // search fields
        search_field: {
          label: 'Suchfeld auswählen',
          placeholder: 'Suchfeld',
        },
        company_name: {
          label: 'Name des Kunden/Partners',
          noOptionsMessage: 'Kein Kunde/Partner mit diesem Namen gefunden.',
          placeholder: 'Name',
          error: 'Ungültiger oder leerer Name',
        },
        empto_external_number: {
          label: 'Kundennummer',
          noOptionsMessage: 'Kein Kunde mit dieser Kundennummer gefunden.',
          placeholder: 'Kundennummer',
          error: 'Ungültige oder leere Kundennummer',
        },
        zipcode: {
          label: 'Postleitzahl',
          noOptionsMessage: 'Kein Kunde mit dieser Postleitzahl gefunden.',
          placeholder: 'Postleitzahl',
          error: 'Ungültige oder leere Postleitzahl',
        },
        // readonly fields
        invoice_address: {
          label: 'Rechnungsadresse',
        },
        tax_id: {
          label: 'UST-ID',
        },
        different_service_address: {
          label: 'Abweichende Leistungsadresse',
        },
        service_address: {
          label: 'Leistungsadresse',
          noOptionsMessage:
            'Keine Leistungsadressen zu diesem Kunde/Partner gefunden.',
          placeholder: 'Leistungsadresse',
          errorOneOption:
            'Das Unternehmen hat nur eine Adresse. ' +
            'Bitte legen Sie weitere Adressen an, wenn Sie eine abweichende Leistungsadresse auswählen wollen.',
        },
      },
    },
    2: {
      title: 'Auftragszuordnung, Belegart und Steuersatz festlegen',
      fields: {
        assignable_to_order: {
          label: 'Kann dem Beleg ein konkreter Auftrag zugeordnet werden?',
          summary_label: 'Auftragszuordnung',
        },
        assigned_order: {
          label: 'Auftrag',
          noResultsText: 'Kein Auftrag gefunden',
          error: 'Ungültige oder leere Auftragsnummer ausgewählt',
        },
        is_not_allowed_on_collective_invoice: {
          label:
            'Möchten Sie sonstige Dienstleistungen (Leerfahrt, Sortierkosten) oder den Auftrag vollständig fakturieren (Ausschluss von zukünftiger Sammelfaktura) ?',
          summary_label: 'Faktura',
          options: {
            other_service: 'sonstige Dienstleistung',
            complete_billing: 'vollständig fakturieren',
          },
          info: 'Der Auftrag wurde bereits vollständig fakturiert oder für eine Faktura gesperrt.',
        },
        is_not_allowed_on_collective_invoice_comment: {
          label: 'Begründung für die Faktura über Beleg+',
          placeholder: 'Begründung eingeben',
        },

        assigned_order_number: {
          label: 'Auftragsnummer',
        },
        assigned_order_fraction: {
          label: 'Abfallart',
        },
        assigned_order_delivery_date: {
          label: 'Auftragsdatum',
        },
        iban: {
          label:
            'Optional: Bitte tragen Sie hier die IBAN des Gutschriftsempfängers ein',
          placeholder: 'IBAN',
          error: 'IBAN ungültig',
        },
        invoice_or_credit_note: {
          label:
            'Bitte wählen Sie aus, ob Sie eine Rechnung oder eine Gutschrift erstellen möchten.',
          invoice: 'Rechnung',
          credit_note: 'Gutschrift',
          summary_label: 'Belegart',
        },
        assigned_vat: {
          label: 'Bitte wählen Sie den Steuersatz für den Beleg aus.',
          summaryLabel: 'Steuersatz',
        },
        rcv: {
          label: 'Reverse Charge Hinweis auf Beleg anzeigen',
          summaryLabel: 'RCV',
        },
      },
    },
    3: {
      title: 'Positionen erfassen',
      fields: {
        service_date: {
          label: 'Leistungsdatum',
        },
        service: {
          label: 'Leistung',
          placeholder: 'Leistung',
        },
        amount: {
          label: 'Menge',
          placeholder: 'Menge',
        },
        unit: {
          label: 'Einheit',
          cbm: 'Kubikmeter',
          piece: 'Stück',
          ton: 'Tonne',
          hour: 'Stunde',
        },
        single_net_price: {
          label: 'Nettoeinzelpreis in €',
          placeholder: 'Nettoeinzelpreis in €',
        },
        total_net_price: {
          label: 'Nettogesamtpreis in €',
          placeholder: 'Nettogesamtpreis in €',
        },
      },
      buttons: {
        addRemovePosition: 'Position',
      },
    },
    4: {
      title: 'Eingaben prüfen',
      description:
        'Bitte überprüfen Sie Ihre Angaben sowie den Gesamtpreis bevor Sie die Belegerstellung abschließen.',
      fields: {
        total_net_price: {
          label: 'Rechnungssumme Netto in €',
        },
        vat_of_total_net: {
          label: 'Zzgl. %{vat}% Umsatzsteuer',
        },
        total_gross_price: {
          label: 'Gesamtsumme Brutto in €',
        },
      },
      buttons: {
        saveInvoice: 'Beleg erstellen',
        cancel: 'Abbrechen',
      },
      modal: {
        title: 'Beleg erstellen?',
        description:
          'Mit der Bestätigung wird der Beleg erstellt und es können keine Änderungen' +
          ' mehr vorgenommen werden.',
        accept: 'Bestätigen',
      },
    },
  },
}
