export const WasteManagerComparisonTableTranslations = {
  comparisonTable: {
    headline: 'Services im Überblick',
    headerServices: 'Service',
    headerExternal: 'empto Abfallmanager<br />(externe Vereinbarungen)',
    headerEmpto: 'empto Marktplatz<br />(empto Vereinbarungen)',
    contents: {
      automaticReview: 'Automatische Prüfung des Entsorgungsunternehmens',
      companyRating: 'Bewertung des Entsorgungsunternehmens',
      priceComparison: 'Preisvergleich',
      digitalAdministration: 'Digitale Verwaltung der Vereinbarung',
      digitalAssignment: 'Digitale Beauftragung',
      digitalManagement: 'Digitale Auftragsverwaltung',
      disposalGuarantee: 'Entsorgungsgarantie',
      digitalArchive: 'Digitales Archiv der Belege',
      automaticDocumentation:
        'Automatische Dokumentation gemäß Gewerbeabfallverordnung',
      automaticInvoicing: 'Automatische Rechnungsstellung',
    },
  },
}
