import { I18n } from 'react-i18nify'

import { EXECUTION_PROOF_STATUS } from './constants'

/**
 * @description Returns a matching icon name for the status of a execution proof
 * @param status
 * @returns {string}
 */
export const getIconNameForStatus = status => {
  if (status === EXECUTION_PROOF_STATUS.STATUS_PENDING) return 'search'
  if (status === EXECUTION_PROOF_STATUS.STATUS_ACCEPTED) return 'checkmark'
  return 'warning'
}

/**
 * @description Returns a matching string that can be used for i18n instead of the ID if the status
 * @param status
 * @returns {string}
 */
export const getStatusString = status => {
  if (status === EXECUTION_PROOF_STATUS.STATUS_PENDING) return 'pending'
  if (status === EXECUTION_PROOF_STATUS.STATUS_ACCEPTED) return 'accepted'
  return 'rejected'
}

/**
 * @description Returns a list of AVV from a possible fraction
 * @param fraction
 * @param fractionList
 */
export const getAvvList = (fraction = null, fractionList = []) => {
  // does it have a parent?
  if (fraction.parent) {
    const parentFraction = fractionList.find(
      _fraction => _fraction.id === fraction.parent,
    )
    return parentFraction ? parentFraction.avv_list : []
  }
  return fraction ? fraction.avv_list : []
}

/**
 * @description Returns the name (or in this case the string of the code) of the give AVV id and correspondent fraction
 * @param avvId
 * @param fractionId
 * @param fractionList
 */
export const getAvvValue = (avvId, fractionId, fractionList) => {
  const fraction = fractionList.find(_fraction => _fraction.id === fractionId)

  if (fraction) {
    const avvList = getAvvList(fraction, fractionList)

    if (avvList && avvList.length > 0) {
      return avvList.find(avv => avv.id === avvId)?.number
    }

    return I18n.t('offerForm.avvNumber.selector')
  }
  return I18n.t('offerForm.avvNumber.selector')
}

/**
 * @description Returns the name of the fraction or fine fraction of a given ID and array
 * @param fractionId
 * @param fractionList
 * @param isCoarseFractionField
 */
export const getFractionName = (
  fractionId,
  fractionList = [],
  isCoarseFractionField = false,
) => {
  const fraction = fractionList.find(_fraction => _fraction.id === fractionId)
  if (fraction) {
    if (fraction.parent && isCoarseFractionField) {
      const coarseFractionId = fraction.parent
      const coarseFraction = fractionList.find(
        _fraction => _fraction.id === coarseFractionId,
      )
      return coarseFraction.name
    }
    return fraction.name
  }
  return '--'
}

/**
 * @description Returns the name of the container of a given ID and array
 * @param containerId
 * @param containerList
 */
export const getContainerName = (containerId, containerList) => {
  const container = containerList.find(
    _container => _container.id === containerId,
  )
  return container ? container.display_name : ''
}

/**
 * @description Returns the shop name (without (Entsorgung.de)) of the container of a given ID and array
 * @param containerId
 * @param containerList
 */
export const getContainerNameForShops = (containerId, containerList) => {
  const container = containerList.find(
    _container => _container.id === containerId,
  )
  return container ? container.display_name_for_shops : ''
}

/**
 * @description Returns an array containing the weights but in a german format, if weights exist
 * @param weightList
 */
export const formatWeights = (weightList = []) => {
  // this checks first if the weight list contains anything
  const hasWeights = weightList.find(
    object => object.weight || object.weight_receipt,
  )
  return hasWeights
    ? // if so, then format the weight value. If not, just return an empty string
      weightList.map(object => ({
        ...object,
        weight: Number(object.weight).toLocaleString('de-DE', {
          minimumFractionDigits: 0,
        }),
      }))
    : []
}

export default {}
