export const enhanceError = (
  message,
  config,
  code,
  request,
  response,
  humanReadableError,
) => {
  const error = new Error(message)
  error.message = message
  error.config = config
  if (code) {
    error.code = code
  }
  error.request = request
  error.response = response
  error.humanReadableError = humanReadableError
  return error
}

export default enhanceError
