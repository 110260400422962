import { CREATE_DEMO_REQUEST } from '../actiontypes/demo'

/**
 * @function
 * @name createDemoRequest
 * @description An action method to create Demo request.
 * @return {Object}
 */
export const createDemoRequest = (demoRequest, callback) => ({
  type: CREATE_DEMO_REQUEST.CREATE_DEMO_REQUEST_REQUEST,
  payload: {
    demoRequest,
    callback,
  },
})
