export const userListFilterTranslation = {
  companySelection: {
    label: 'Unternehmen',
    noResults: 'Zu Ihrer Suchanfrage wurde kein Unternehmen gefunden',
    placeholder: 'Alle',
  },
  emptyMessage: {
    noUsers: 'Bei Ihre Unternehmen liegen aktuell keine Benutzer vor.',
    noUsersInCompany:
      'Zu der gewählten Unternhemen liegen aktuell keine Benutzer vor',
  },
  userSelection: {
    label: 'Benutzer',
    noResults: 'Zu Ihrer Suchanfrage wurde kein Benutzer gefunden',
    placeholder: 'Alle',
  },
}
