import './style.scss'

import classNames from 'classnames'
import React, { Component } from 'react'
import { Control } from 'react-redux-form'
import Spinner from 'react-spinkit'

import Icon from 'components/common/Fontello'

import { ControlDefaultProps } from '../../constants'
import FormField from '../../index'

import connector from './connector'
import ButtonControlProps from './schemes'

/**
 * @description Custom textbox component
 * see: http://davidkpiano.github.io/react-redux-form/docs/guides/custom-controls.html
 */
class ButtonControl extends Component {
  /**
   * @description Override this method in a child class to define an individual field.
   * @return {*}
   */
  getField() {
    if (!this.props.field) return null

    return (
      <div
        className={classNames(
          'button-control',
          { 'with-caption': this.props.symbol },
          { 'uk-disabled': this.props.disabled },
        )}
      >
        <Control.button // eslint-disable-line react/jsx-pascal-case
          type='button'
          className={this.props.className}
          data-testid={this.props.dataTestId}
          model={this.props.field.model}
          tabIndex={this.props.tabIndex}
          onClick={event => {
            this.props.onClick(event)
          }}
        >
          {this.props.field.value && !this.props.value && (
            <span>{this.props.field.value}</span>
          )}

          {this.props.field.value && this.props.value && (
            <span>{this.props.value}</span>
          )}

          {!this.props.field.value && this.props.placeholder && (
            <span className='placeholder'>{this.props.placeholder}</span>
          )}
        </Control.button>

        {this.props.symbol && !this.props.isLoading && (
          <div className='caption symbol'>
            <Icon name={this.props.symbol} />
          </div>
        )}

        {this.props.isLoading && (
          <div className='caption symbol'>
            <Spinner
              fadeIn='none' // Show immediately
              name='circle'
              color='grey'
            />
          </div>
        )}
      </div>
    )
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    return (
      <FormField
        {...this.props}
        fieldComponent={this.getField()}
        onLabelClick={() => {
          this.props.onClick()
        }}
      />
    )
  }
}

ButtonControl.propTypes = ButtonControlProps

ButtonControl.defaultProps = {
  ...ControlDefaultProps,
  value: null,
  symbol: null,
  onClick: () => undefined,
  isLoading: false,
}

export default connector(ButtonControl)
