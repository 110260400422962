import { useFormikContext } from 'formik'
import React from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { UniversalFileUpload } from 'components/common/UniversalFileUpload'
import { Textarea } from 'components/common/Textarea'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { getAddAgreementCustomerCompanySelector } from 'selectors/company'
import { createLoadingSelector } from 'selectors/loading'

import { AddAgreementFormValues } from '../AddAgreementFormSteps'

export const CommentAndImagesForm = () => {
  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    isValid,
    setFieldValue,
  } = useFormikContext<AddAgreementFormValues>()

  const isLoading = useSelector(
    createLoadingSelector(['CREATE_CUSTOM_AGREEMENT']),
  )

  const company = useSelector(getAddAgreementCustomerCompanySelector)

  // custom_agreement_optional_certificate allows companies to add a new agreement without certificates
  let isDisabledState = !(values.attachments.length > 0) || !isValid
  if (company?.custom_agreement_optional_certificate) {
    isDisabledState = !isValid
  }

  return (
    <>
      {/*OPTIONAL: BESONDERE HINWEISE*/}
      <div className='maklerpremium-agreementform__step5'>
        <Textarea
          name='comment'
          label={I18n.t(
            'addAgreementPageTranslations.steps.5.fields.comment.label',
          )}
          maxLength={200}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={I18n.t(
            'addAgreementPageTranslations.steps.5.fields.comment.placeholder',
          )}
          rows={25}
          showRemainingCharacters
          value={values.comment}
        />

        {/*IMAGES*/}
        <UniversalFileUpload
          name='attachments'
          label={I18n.t(
            'addAgreementPageTranslations.steps.5.fields.attachments.label',
          )}
          subLabel={I18n.t(
            'addAgreementPageTranslations.steps.5.fields.attachments.sublabel',
          )}
          allowedFileTypes='image/png, image/jpeg, application/pdf'
          allowComments
          maxFiles={3}
          initialValues={values.attachments}
          onChange={fileList => {
            setFieldValue('attachments', fileList)
          }}
        />
      </div>

      <GoToNextFormStepButton
        buttonText={I18n.t('addAgreementPageTranslations.saveAgreement')}
        onClick={handleSubmit}
        isDisabled={isDisabledState}
        isLoading={isLoading}
      />
    </>
  )
}
