import { itemInitialState as defaultAutoOffer } from '../reducers/autooffer'

/**
 * In the user interface, we make a distinction between fine_fraction and fraction. In the model we only save the
 * most accurate fraction we know (so fine fraction if available). This function combines the frontend data to one
 * property and returns a new object with this property set.
 */
export const combineCoarseAndFineFraction = offer => {
  const newFraction = offer.fine_fraction ? offer.fine_fraction : offer.fraction
  return { ...offer, fraction: newFraction }
}

/**
 * The api only accepts american decimal format with "." instead if "," decimal seperators. Since the user wants
 * to enter german format here, we need to replace the seperator.
 * @param offer
 * @param decimalProps an array with a list of property names
 * @returns {{}}
 */
export const replaceDecimalValues = (offer, decimalProps = []) => {
  const newOffer = { ...offer }
  decimalProps.forEach(propertyName => {
    if (Object.prototype.hasOwnProperty.call(newOffer, propertyName)) {
      newOffer[propertyName] = newOffer[propertyName]
        ? String(newOffer[propertyName]).replace(',', '.')
        : '0'
    }
  })
  return newOffer
}

export const getDefaultAutoOffer = phoneAutoAutoOffer => {
  if (phoneAutoAutoOffer)
    return { ...defaultAutoOffer, company: null, phone_user: null }
  return defaultAutoOffer
}
