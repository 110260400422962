import keyMirror from 'keymirror'

const GET_INSTRUCTIONS = keyMirror({
  GET_INSTRUCTIONS_REQUEST: null,
  GET_INSTRUCTIONS_SUCCESS: null,
  GET_INSTRUCTIONS_ERROR: null,
})

export default {
  ...GET_INSTRUCTIONS,
}
