import { useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC, Fragment, useContext, useMemo } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { FormStepSummaryRow } from 'components/common/FormSteps'
import { getFractionName } from 'components/executionproof/helpers'
import { INQUIRY_ORDER_TYPE } from 'components/inquiry/constants'
import {
  getContainerName,
  getFractionsFromFractionId,
} from 'components/inquiry/helpers'
import {
  INVOICE_CHECK_POSITIONS_CATEGORIES,
  INVOICE_CHECK_POSITIONS_CATEGORIES_CHOICES,
} from 'components/maklerpremium/constants'
import { InvoiceCheckDocumentType } from 'components/maklerpremium/InvoiceCheckPage/InvoiceCheckAcquisition'
import { PRICE_MODELS } from 'components/offer/constants'
import { DATE_FORMATS } from 'constants/app'
import {
  germanDecimalToInternationalFormat,
  localizeDate,
} from 'helper/general'
import { getName } from 'helper/translations'
import { getEPDContainersSelector } from 'selectors/container'
import { getEPDFractionsSelector } from 'selectors/fraction'
import { getIndexesSelector } from 'selectors/indexes'
import { getMyOffersSelector } from 'selectors/offer'
import { getOrdersForInvoiceCheckSelector } from 'selectors/order'
import { getSecurityGroupsSelector } from 'selectors/securitygroup'

import {
  InvoiceCheckPositionsFormContext,
  InvoiceCheckPositionsFormValues,
} from '../InvoiceCheckPositionsFormSteps'

import { SURCHARGE_REDUCTION } from './OpenOrderForm/CompensationPriceFields/CompensationPriceFields'
import { NetPriceType, OrderType, WeightOrNumber } from './OrderAndServicesForm'

export const OrderAndServicesSummary: FC = () => {
  const { editStep } = useContext(InvoiceCheckPositionsFormContext)
  const { values } = useFormikContext<InvoiceCheckPositionsFormValues>()
  const orders = useSelector(getOrdersForInvoiceCheckSelector)
  const offers = useSelector(getMyOffersSelector)
  const fractionList = useSelector(getEPDFractionsSelector)
  const containerList = useSelector(getEPDContainersSelector)
  const securityGroupList = useSelector(getSecurityGroupsSelector)
  const indexList = useSelector(getIndexesSelector)

  const order = useMemo(
    () => orders.find(item => `${item.id}` === `${values.order}`),
    [orders, values.order],
  )
  const offer = useMemo(
    () => offers.find(item => `${item.id}` === `${values.offer}`),
    [offers, values.offer],
  )
  const fraction = useMemo(
    () => getFractionName(order?.fraction, fractionList),
    [fractionList, order],
  )
  const container = useMemo(
    () => getContainerName(order?.container, containerList),
    [containerList, order],
  )
  const offerFraction = useMemo(
    () => getFractionName(offer?.fraction, fractionList),
    [fractionList, offer],
  )
  const offerContainer = useMemo(
    () => getContainerName(offer?.fraction, containerList),
    [containerList, offer],
  )

  const { fraction: summary_fine_fraction, coarseFraction: summary_fraction } =
    useMemo(() => {
      if (values.fine_fraction) {
        return getFractionsFromFractionId(values.fine_fraction, fractionList)
      } else {
        // If no fine_fraction is given only use coarseFraction because getFractionsFromFractionId returns the
        // coarseFraction for both fine and coarse fraction
        const { coarseFraction } = getFractionsFromFractionId(
          values.fraction,
          fractionList,
        )
        return { coarseFraction, fraction: undefined }
      }
    }, [fractionList, values.fine_fraction, values.fraction])
  const selectedContainerName = useMemo(
    () => getContainerName(values?.container, containerList),
    [containerList, values],
  )
  // Set order_date to be A: the actual date of B: collection for one time orders or delivery for standing orders
  // See: https://gitlab.ambient-innovation.com/zentek/empto/-/issues/2400
  const order_date =
    order?.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME
      ? order?.actual_collection_date
      : order?.actual_delivery_date
  const orderValue = `${order?.id} / ${fraction} / ${container} / ${moment(
    order_date,
    DATE_FORMATS,
  ).format('L')}`

  const service_start_date = moment(
    offer?.service_start_date,
    DATE_FORMATS,
  ).format('L')
  const service_end_date = moment(offer?.service_end_date, DATE_FORMATS).format(
    'L',
  )
  const offerValue = `${offer?.id} / ${offerFraction} / ${offerContainer} / ${service_start_date} - ${service_end_date}`

  let orderType = 'open_order'
  if (values.order_type === OrderType.OTHER_SERVICE) {
    orderType = 'other_service'
  } else if (values.order_type === OrderType.RENT) {
    orderType = 'rent'
  }

  const isOpenOrder = values.order_type === OrderType.OPEN_ORDER
  const isOtherService = values.order_type === OrderType.OTHER_SERVICE
  const isRent = values.order_type === OrderType.RENT

  const net_price = values?.net_price

  const security_group = values?.security_group

  const selectedSecurityGroup = useMemo(
    () =>
      securityGroupList
        .filter(item => item.id === Number(values.security_group))
        .map(
          item =>
            `${I18n.t('selectSecurityGroupTranslations.protectionClassNumber', {
              protectionClass: item.protection_class,
            })} / ${I18n.t(
              'selectSecurityGroupTranslations.securityLevelNumber',
              { securityLevel: item.security_level },
            )}`,
        ),
    [securityGroupList, values.security_group],
  )

  const selectedIndex = useMemo(
    () => indexList.filter(item => item.id === Number(values.index))[0],
    [indexList, values.index],
  )

  const surchargeReduction =
    values.index_surcharge_reduction === SURCHARGE_REDUCTION.SURCHARGE
      ? values.surcharge
      : values.reduction

  const { invoiceCheck } = useContext(InvoiceCheckPositionsFormContext)

  // @ts-ignore
  return (
    <div className='maklerpremium-step-summary'>
      <div>
        <FormStepSummaryRow
          label={I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.order_type.label',
          )}
          value={I18n.t(
            `invoiceCheckTranslations.positionsForm.steps.2.fields.order_type.${orderType}.label`,
          )}
        />
        {(isOpenOrder || isOtherService) && (
          <FormStepSummaryRow
            label={I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.2.fields.order.label',
            )}
            value={orderValue}
          />
        )}
        {isOpenOrder && (
          <>
            <FormStepSummaryRow
              label={I18n.t(
                'createMaklerPremiumOfferPageTranslations.steps.2.fields.fraction.label',
              )}
              value={summary_fraction?.name}
              key='maklerpremium-step-summary-fraction'
            />
            {summary_fine_fraction && (
              <FormStepSummaryRow
                label={I18n.t(
                  'createMaklerPremiumOfferPageTranslations.steps.2.fields.fineFraction.label',
                )}
                value={summary_fine_fraction?.name}
                key='maklerpremium-step-summary-fine_fraction'
              />
            )}
            {security_group && (
              <FormStepSummaryRow
                label={I18n.t(
                  'invoiceCheckTranslations.positionsForm.steps.2.fields.security_group.label',
                )}
                value={selectedSecurityGroup}
              />
            )}
            <FormStepSummaryRow
              label={I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.label',
              )}
              value={values.avv}
            />
            <FormStepSummaryRow
              label={I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.container.label',
              )}
              value={selectedContainerName}
            />
            <FormStepSummaryRow
              label={I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.number_of_containers.label',
              )}
              value={values.number_of_containers}
            />
            {(isOpenOrder || isOtherService) && (
              <FormStepSummaryRow
                label={
                  order?.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME
                    ? I18n.t('inquiry.date.deliveryDate')
                    : I18n.t('orderDetails.deliveryDate')
                }
                value={localizeDate(values.delivery_date)}
              />
            )}
            {order?.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME &&
              (isOpenOrder || isOtherService) && (
                <FormStepSummaryRow
                  label={I18n.t('inquiry.date.collectionDate')}
                  value={localizeDate(values.collection_date)}
                />
              )}
            {isRent && (
              <>
                <FormStepSummaryRow
                  label={I18n.t(
                    'invoiceCheckTranslations.positionsForm.steps.2.fields.offer.label',
                  )}
                  value={offerValue}
                />
                <FormStepSummaryRow
                  label={I18n.t(
                    'invoiceCheckTranslations.positionsForm.steps.2.fields.deliveryMonth.label',
                  )}
                  value={moment(values.delivery_date, DATE_FORMATS).format(
                    'MMMM YYYY',
                  )}
                />
              </>
            )}
            {values.price_model === PRICE_MODELS.DISPOSAL_CONTAINER && (
              <FormStepSummaryRow
                label={I18n.t(
                  'priceDetailFieldsTranslations.disposalCostContainer',
                )}
                value={Number(
                  germanDecimalToInternationalFormat(
                    values.disposal_cost_container as string,
                  ),
                ).toLocaleString('de-DE', {
                  minimumFractionDigits: 2,
                })}
              />
            )}
            {values.price_model === PRICE_MODELS.DISPOSAL_SINGLE && (
              <>
                <FormStepSummaryRow
                  label={I18n.t(
                    'priceDetailFieldsTranslations.transportPricePiece',
                  )}
                  value={values.transport_price_piece}
                />
                <FormStepSummaryRow
                  label={I18n.t(
                    'priceDetailFieldsTranslations.disposalCostTon',
                  )}
                  value={Number(
                    germanDecimalToInternationalFormat(
                      values.disposal_cost_ton as string,
                    ),
                  ).toLocaleString('de-DE', {
                    minimumFractionDigits: 2,
                  })}
                />
              </>
            )}
            {values.price_model === PRICE_MODELS.COMPENSATION_SINGLE && (
              <>
                {invoiceCheck?.document_type ===
                  InvoiceCheckDocumentType.INVOICE && (
                  <>
                    <FormStepSummaryRow
                      label={I18n.t(
                        'priceDetailFieldsTranslations.transportPricePiece',
                      )}
                      value={Number(
                        germanDecimalToInternationalFormat(
                          values.transport_price_piece as string,
                        ),
                      ).toLocaleString('de-DE', {
                        minimumFractionDigits: 2,
                      })}
                    />
                    <FormStepSummaryRow
                      label={I18n.t(
                        'priceDetailFieldsTranslations.handleCostTon',
                      )}
                      value={Number(
                        germanDecimalToInternationalFormat(
                          values.handle_cost_ton as string,
                        ),
                      ).toLocaleString('de-DE', {
                        minimumFractionDigits: 2,
                      })}
                    />
                  </>
                )}
                {invoiceCheck?.document_type ===
                  InvoiceCheckDocumentType.CREDIT_NOTE && (
                  <>
                    <FormStepSummaryRow
                      label={I18n.t(
                        'compensationPriceFieldsTranslations.index.label',
                      )}
                      value={
                        values.index
                          ? selectedIndex.name
                          : I18n.t(
                              'compensationPriceFieldsTranslations.index.noIndex',
                            )
                      }
                    />
                    {values.index_month && (
                      <FormStepSummaryRow
                        label={I18n.t(
                          'compensationPriceFieldsTranslations.indexMonth.label',
                        )}
                        value={I18n.t(
                          `compensationPriceFieldsTranslations.indexMonth.options.${values.index_month}`,
                        )}
                      />
                    )}
                    <FormStepSummaryRow
                      label={I18n.t(
                        'priceDetailFieldsTranslations.compensation.compensation',
                      )}
                      value={Number(
                        germanDecimalToInternationalFormat(
                          values.compensation_ton as string,
                        ),
                      ).toLocaleString('de-DE', {
                        minimumFractionDigits: 2,
                      })}
                    />
                    {values.index && (
                      <FormStepSummaryRow
                        label={I18n.t(
                          `compensationPriceFieldsTranslations.${values.index_surcharge_reduction}.label`,
                        )}
                        value={Number(
                          germanDecimalToInternationalFormat(
                            surchargeReduction as string,
                          ),
                        ).toLocaleString('de-DE', {
                          minimumFractionDigits: 2,
                        })}
                      />
                    )}
                  </>
                )}
              </>
            )}
            {values.price_model === PRICE_MODELS.COMPENSATION_CONTAINER && (
              <>
                {invoiceCheck?.document_type ===
                  InvoiceCheckDocumentType.INVOICE && (
                  <FormStepSummaryRow
                    label={I18n.t(
                      'disposalPriceFieldsTranslations.costContainer',
                    )}
                    value={Number(
                      germanDecimalToInternationalFormat(
                        values.cost_container as string,
                      ),
                    ).toLocaleString('de-DE', {
                      minimumFractionDigits: 2,
                    })}
                  />
                )}
                {invoiceCheck?.document_type ===
                  InvoiceCheckDocumentType.CREDIT_NOTE && (
                  <FormStepSummaryRow
                    label={I18n.t(
                      'compensationPriceFieldsTranslations.compensationContainer',
                    )}
                    value={Number(
                      germanDecimalToInternationalFormat(
                        values.compensation_container as string,
                      ),
                    ).toLocaleString('de-DE', {
                      minimumFractionDigits: 2,
                    })}
                  />
                )}
              </>
            )}
            {values.weight_or_number === WeightOrNumber.INPUT_WEIGHT &&
            values.weight_object_list ? (
              <div className='summary-grid uk-grid uk-child-width-1-2@m'>
                {values!.weight_object_list!.map(
                  ({ weight_receipt, weight }, index) => (
                    <Fragment key={weight_receipt}>
                      <div className='summary-grid__column-left'>
                        <FormStepSummaryRow
                          label={I18n.t(
                            'documentReviewPageTranslations.executionProofReview.weightReceipt',
                            { number: index + 1 },
                          )}
                          value={weight_receipt ?? ''}
                        />
                      </div>
                      <div className='summary-grid__column-right'>
                        <FormStepSummaryRow
                          label={I18n.t(
                            'documentReviewPageTranslations.executionProofReview.weight',
                            { number: index + 1 },
                          )}
                          value={weight ?? undefined}
                        />
                      </div>
                    </Fragment>
                  ),
                )}
              </div>
            ) : (
              <>
                {values.quantity_in_cubic_meters &&
                  values.quantity_in_cubic_meters > 0 && (
                    <FormStepSummaryRow
                      label={I18n.t(
                        'documentReviewPageTranslations.executionProofReview.quantityInCubicMeters',
                      )}
                      value={values.quantity_in_cubic_meters}
                    />
                  )}
                {values.number_of_containers &&
                  values.number_of_containers > 0 && (
                    <FormStepSummaryRow
                      label={I18n.t(
                        'documentReviewPageTranslations.executionProofReview.numberOfContainers',
                      )}
                      value={values.number_of_containers}
                    />
                  )}
              </>
            )}
          </>
        )}
        {(isRent || isOtherService) && (
          <>
            <FormStepSummaryRow
              label={I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.label',
              )}
              value={I18n.t(
                getName(
                  Number(values.category),
                  INVOICE_CHECK_POSITIONS_CATEGORIES_CHOICES,
                ),
              )}
            />
            {Number(values.category) ===
              INVOICE_CHECK_POSITIONS_CATEGORIES.OTHER && (
              <FormStepSummaryRow
                label={I18n.t(
                  'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.categoryText.label',
                )}
                value={values.other_service_other_text}
              />
            )}
            <FormStepSummaryRow
              label={I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.netPrice.label',
              )}
              value={`${net_price} €`.replace('.', ',')}
            />
            <FormStepSummaryRow
              label={I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.netPrice.burdenCreditLabel',
              )}
              value={
                values.net_price_type === NetPriceType.BURDEN
                  ? I18n.t(
                      'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.netPrice.burden',
                    )
                  : I18n.t(
                      'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.netPrice.credit',
                    )
              }
            />
          </>
        )}
      </div>
      <div className='sidebar'>
        <ButtonLink
          className='sidebar__edit-link'
          linkColor={BUTTON_LINK_COLOR.PRIMARY}
          onClick={editStep}
        >
          {I18n.t('createInquiryTranslations.form.button.editStep')}
        </ButtonLink>
      </div>
    </div>
  )
}
