import './style.scss'

import { useFormikContext } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, { FC, ReactText, useContext, useEffect, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getContainers } from 'actions/container'
import { getFractions } from 'actions/fraction'
import { getPriceComparisonTable } from 'actions/maklerpremium'
import { getSecurityGroups } from 'actions/securitygroup'
import { getCurrentContainer } from 'components/agreement/AgreementDetailsPage/helper'
import { Button } from 'components/common/Button'
import { DismissableInfo } from 'components/common/DismissableInfo'
import { DropDownInput } from 'components/common/DropDownInput'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { FractionAndContainerFormFields } from 'components/common/FractionAndContainerFormFields'
import { Modal } from 'components/common/Modal'
import { PriceComparisonTable } from 'components/common/PriceComparisonTable'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { contractApplies } from 'components/maklerpremium/helpers'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'
import { createLoadingSelector } from 'selectors/loading'
import { getFrameworkContractPositionsSelector } from 'selectors/maklerpremium'
import { getPriceComparisonTableSelector } from 'selectors/pricecalc'
import { getSecurityGroupsSelector } from 'selectors/securitygroup'

import {
  CreateMaklerPremiumOfferContext,
  MaklerPremiumOfferValues,
} from '../CreateMaklerPremiumOfferFormSteps'

import { PausedDisposersModal } from './PausedDisposersModal'

export const FractionAndContainerForm: FC = () => {
  const { handleBlur, handleChange, setFieldError, setFieldValue, values } =
    useFormikContext<MaklerPremiumOfferValues>()

  const { saveStep, setAutoOfferCompany, stepStatus } = useContext(
    CreateMaklerPremiumOfferContext,
  )
  const dispatch = useDispatch()

  const isFractionListLoading = useSelector<any, boolean>(state =>
    createLoadingSelector(['GET_FRACTIONS'])(state),
  )

  const isContainerListLoading = useSelector<any, boolean>(state =>
    createLoadingSelector(['GET_CONTAINERS'])(state),
  )
  const isSecuritygroupListLoading = useSelector<any, boolean>(state =>
    createLoadingSelector(['GET_SECURITY_GROUPS'])(state),
  )

  const fractionList = useSelector<any, Fraction[]>(getFractionsSelector)
  const containerList = useSelector<any, Container[]>(getContainersSelector)
  const securityGroupList = useSelector<any, SecurityGroup[]>(
    getSecurityGroupsSelector,
  )
  const securityGroupFilterOptions = [4, 8]
  const defaultSelectedSecurityGroupId = 4

  useEffect(() => {
    if (fractionList.length < 1) dispatch(getFractions())
  }, [dispatch, fractionList.length])

  useEffect(() => {
    if (containerList.length < 1) dispatch(getContainers())
  }, [containerList.length, dispatch])

  useEffect(() => {
    if (securityGroupList.length < 1) dispatch(getSecurityGroups())
  }, [securityGroupList.length, dispatch])

  const priceComparisonTable = useSelector(getPriceComparisonTableSelector)
  const [containerType, setContainerType] = useState<string>()

  useEffect(() => {
    const container = containerList.find(item => item.id === values.container)
    if (container && container.name !== containerType) {
      setContainerType(container.name)
    }
  }, [containerList, containerType, values.container])

  const [priceComparisonRequestParams, setPriceComparsionRequestParams] =
    useState<Record<string, ReactText | undefined>>()

  useEffect(() => {
    if (
      containerType &&
      values.fraction &&
      values.number_of_containers &&
      values.number_of_containers > 0 &&
      typeof values.position_on_public_ground !== 'undefined' &&
      values.zipcode &&
      `${values.zipcode}`.length === 5 // only refresh table when we have a valid zipcode
    ) {
      const valuesToSend = {
        zipcode: values.zipcode,
        containerType,
        fractionId: values.fine_fraction
          ? values.fine_fraction
          : values.fraction,
        positionOnPublicGround: Number(values.position_on_public_ground),
        security_group: values.security_group,
        customer_id: values.existing_customer,
      }
      setPriceComparsionRequestParams(valuesToSend)

      dispatch(
        getPriceComparisonTable(
          { ...valuesToSend },
          {
            setErrorCallback: (key, string) => setFieldError(key, string),
          },
        ),
      )
    }
  }, [
    containerType,
    dispatch,
    setFieldError,
    values.fine_fraction,
    values.fraction,
    values.number_of_containers,
    values.position_on_public_ground,
    values.security_group,
    values.zipcode,
    values.existing_customer,
  ])

  // Apply discount on end_price and set offer_end_price whenever an auto_offer is chosen
  useEffect(() => {
    if (
      priceComparisonTable &&
      priceComparisonTable.length > 0 &&
      typeof values.auto_offer !== 'undefined' &&
      typeof values.discount !== 'undefined' &&
      typeof values.number_of_containers !== 'undefined'
    ) {
      let endPrice: number | undefined
      priceComparisonTable.some(companyPrices =>
        Object.values(companyPrices.container_prices).some(containerPrice => {
          if (containerPrice.auto_offer_id === values.auto_offer) {
            endPrice = containerPrice.end_price
            setAutoOfferCompany([
              companyPrices.name,
              <br key='company-br' />,
              `${companyPrices.zip_code} ${companyPrices.city}`,
            ])
            return true
          }
          return false
        }),
      )
      if (endPrice) {
        const floatPrice =
          endPrice * values.number_of_containers * (1 - values.discount / 100)
        setFieldValue('offer_end_price', parseFloat(floatPrice.toFixed(2)))
        const floatContainerPrice = endPrice * (1 - values.discount / 100)
        setFieldValue(
          'offer_container_price',
          parseFloat(floatContainerPrice.toFixed(2)),
        )
      } else {
        setFieldValue('offer_end_price', undefined)
        setFieldValue('offer_container_price', undefined)
      }
    } else {
      setFieldValue('offer_end_price', undefined)
      setFieldValue('offer_container_price', undefined)
    }
  }, [
    priceComparisonTable,
    setAutoOfferCompany,
    setFieldValue,
    values.auto_offer,
    values.discount,
    values.number_of_containers,
  ])

  const [currentContainerCapacity, setCurrentContainerCapacity] =
    useState<string>()

  useEffect(() => {
    if (
      priceComparisonTable?.length &&
      containerList?.length &&
      values.container
    ) {
      const container = getCurrentContainer(containerList, values.container)
      if (container) {
        if (
          Object.prototype.hasOwnProperty.call(
            priceComparisonTable[0].container_prices,
            container.capacity,
          )
        ) {
          setCurrentContainerCapacity(container.capacity)
        }
      }
    }
  }, [containerList, priceComparisonTable, values.container])

  useEffect(() => {
    if (
      priceComparisonTable?.length &&
      containerList?.length &&
      currentContainerCapacity
    ) {
      const auto_offer =
        priceComparisonTable[0].container_prices[currentContainerCapacity]
      if (auto_offer) {
        setFieldValue('container', auto_offer.container_id)
      }
    }
  }, [
    containerList,
    currentContainerCapacity,
    priceComparisonTable,
    setFieldValue,
  ])

  const frameworkContractPositions = useSelector(
    getFrameworkContractPositionsSelector,
  )
  const frameworkContractApplies = contractApplies(
    frameworkContractPositions,
    values,
  )

  const pausedDisposersModalId = uniqueId()
  const [pausedDisposersModalIsOpen, setPausedDisposersModalIsOpen] =
    useState<boolean>(false)

  return (
    <div className='maklerpremium-offerform__step2'>
      <div className='maklerpremium-offer-form-field-container'>
        {!isContainerListLoading &&
          !isFractionListLoading &&
          !isSecuritygroupListLoading && (
            <FractionAndContainerFormFields
              frameworkContractApplies={frameworkContractApplies}
              showSecurityGroupField
              securityGroupFilterOptions={securityGroupFilterOptions}
              defaultSelectedSecurityGroupId={defaultSelectedSecurityGroupId}
            />
          )}
        <div className='uk-grid uk-child-width-1-2@xl'>
          <DropDownInput
            choices={[0, 5, 10, 15, 20].map(item => ({
              optionValue: item,
              optionLabel: `${item}%`,
            }))}
            containerClassName='uk-padding uk-padding-remove-vertical'
            label={I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.2.fields.discount',
            )}
            name='discount'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.discount}
          />
          {values.offer_end_price && (
            <ReadOnlyField
              className='uk-padding-remove-horizontal uk-padding-remove-bottom'
              formPadding
              label={I18n.t(
                'createMaklerPremiumOfferPageTranslations.steps.2.fields.endPrice',
              )}
              value={
                frameworkContractApplies
                  ? I18n.t(
                      'createMaklerPremiumOfferPageTranslations.steps.2.fields.frameworkContractPrice',
                    )
                  : `${I18n.l(values.offer_end_price, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}€`
              }
            />
          )}
        </div>
      </div>
      <div className='price-comparison-label'>
        {I18n.t(
          'createMaklerPremiumOfferPageTranslations.steps.2.fields.priceComparison',
        )}
      </div>
      <PriceComparisonTable
        containerList={containerList}
        currentContainerCapacity={currentContainerCapacity}
        priceComparisonTable={priceComparisonTable}
        setCurrentContainerCapacity={setCurrentContainerCapacity}
        setFieldValue={setFieldValue}
        values={values}
      />
      <Button onClick={() => setPausedDisposersModalIsOpen(true)}>
        <Translate value='createMaklerPremiumOfferPageTranslations.steps.2.pausedDisposersModal.modalHeader' />
      </Button>

      {typeof values.offer_end_price !== 'undefined' && (
        <div className='maklerpremium-offerform__column-2'>
          {values.best_container !== values.container && (
            <DismissableInfo
              className='dismissable-info--warning'
              onClick={() => null}
              title={I18n.t(
                'createInquiryTranslations.form.error.containerError',
              )}
              text={I18n.t(
                'createInquiryTranslations.form.error.containerErrorText',
              )}
            />
          )}
          <div className='maklerpremium-offer-form-field-container'>
            <GoToNextFormStepButton
              buttonText={
                stepStatus === STEP_STATUS.EDITING
                  ? I18n.t('general.button.save')
                  : I18n.t('createInquiryTranslations.form.button.nextStep')
              }
              onClick={saveStep}
              isDisabled={values.best_container !== values.container}
            />
          </div>
        </div>
      )}
      <Modal
        ariaDescribedBy={pausedDisposersModalId}
        isOpen={pausedDisposersModalIsOpen}
        onClose={() => {
          setPausedDisposersModalIsOpen(false)
        }}
      >
        <PausedDisposersModal
          onClose={() => {
            setPausedDisposersModalIsOpen(false)
          }}
          modalId={pausedDisposersModalId}
          priceComparisonRequestParams={priceComparisonRequestParams}
        />
      </Modal>
    </div>
  )
}
