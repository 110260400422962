import './style.scss'

import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

import { Divider, DividerColor, DividerWidth } from 'components/common/Divider'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'

export const CallToActionFooter: FC = () => (
  <section className='call-to-action-footer'>
    {/* REGISTER (disabled in #3228) */}
    {/*<LinkButton*/}
    {/*  backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}*/}
    {/*  fontColor={BUTTON_FONT_COLOR.GRAYISH_BROWN}*/}
    {/*  target={{*/}
    {/*    pathname: '/registrieren',*/}
    {/*    state: {*/}
    {/*      initialSelection: COMPANY_ROLE.WASTE_PRODUCER,*/}
    {/*    },*/}
    {/*  }}*/}
    {/*>*/}
    {/*  <Translate value='homepages.wasteManager.registerButton' />*/}
    {/*</LinkButton>*/}

    <Divider color={DividerColor.GrayishBrown} width={DividerWidth.Small} />

    <Headline
      color={HEADLINE_COLOR.GRAYISH_BROWN}
      fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
      fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
      headlineStyle={HEADLINE_STYLE.H3}
      tag={HEADLINE_TAG.H2}
      noMargin
    >
      <Translate value='homepages.wasteManager.anyQuestionsHeadline' />
    </Headline>

    <p className='call-to-action-footer__service'>
      <Translate value='homepages.wasteManager.customerService' dangerousHTML />
    </p>
  </section>
)
