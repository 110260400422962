export const orderClaimDetailsPageTranslations = {
  breadcrumb: {
    title: {
      review: 'Reklamationsprüfung',
      details: 'Reklamation',
    },
    prevLinkTitleEmpto: 'Leistungsnachweise',
    prevLinkTitleMaklerPremium: 'Arbeitsvorrat',
  },
  pageTitle: {
    review: 'Reklamationsprüfung für Auftrag %{number}',
    details: 'Reklamation für Auftrag %{number}',
  },
  submitButtonText: {
    isSaving: 'Prüfung speichern',
    isReviewing: 'Prüfung abschließen',
  },
  saveInternalNote: {
    confirm:
      'Möchten Sie die Reklamation zwischenspeichern? Der interne Kommentar wird beibehalten',
    warning: 'Bitte schreiben Sie eine Interner Kommentar',
  },
  validation: {
    waitingTimeDate: 'Bitte wählen Sie ein Datum aus.',
    effortDuration: 'Bitte wählen Sie der Dauer des Aufwands.',
    enabledWaitingTimeAccepting: 'Dieses Feld muss bestätigt werden.',
    serviceDate: 'Bitte wählen Sie ein Datum aus.',
    enabledDeadheadAccepting: 'Dieses Feld muss bestätigt werden.',
    status: 'Bitte ändern Sie den Status.',
    rejectReason: 'Bitte begründen Sie Ihre Reklamation.',
  },
}
