export const EXECUTION_PROOF_STATUS = {
  STATUS_PENDING: 1,
  STATUS_ACCEPTED: 2,
  STATUS_REJECTED: 3,
}

export const EXECUTION_PROOF_STATES = [
  {
    id: EXECUTION_PROOF_STATUS.STATUS_PENDING,
    name: 'executionProof.status.pending',
  },
  {
    id: EXECUTION_PROOF_STATUS.STATUS_ACCEPTED,
    name: 'executionProof.status.accepted',
  },
  {
    id: EXECUTION_PROOF_STATUS.STATUS_REJECTED,
    name: 'executionProof.status.rejected',
  },
]

export const EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS = {
  FRACTION_IS_NOT_EQUAL: 'false',
  FRACTION_IS_EQUAL: 'true',
  INPUT_WEIGHT: '0',
  INPUT_NUMBER_OF_CONTAINERS: '1',
}

export default {
  ...EXECUTION_PROOF_STATUS,
  ...EXECUTION_PROOF_STATES,
  ...EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS,
}
