import './styles.scss'

import React, { createRef, FC, useEffect, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getDszFractionPrices } from 'actions/dsz'
import { Filter } from 'components/common/Filter'
import { getCurrentValueObject } from 'components/common/Filter/helpers'
import Icon, { ICON_NAME } from 'components/common/Fontello'
import { InputNumber } from 'components/common/InputNumber'
import { PaginatedTable } from 'components/common/PaginatedTable'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { UpDownArrow } from 'components/common/UpDownArrow'
import {
  getFractionPricesPaginationSelector,
  getFractionPricesSelector,
} from 'selectors/dsz'
import { createLoadingSelector } from 'selectors/loading'

import { DSZ_SHOPS_CHOICES } from '../../constants'
import { getShop } from '../../helpers'

export const PricesTable: FC<{
  handleOpenCloseIsAddPriceModal: () => void
  setEditingInstance: (instance: DszFractionPrice) => void
}> = ({ handleOpenCloseIsAddPriceModal, setEditingInstance }) => {
  const dispatch = useDispatch()
  const fractionPrices = useSelector(getFractionPricesSelector)
  const pagination = useSelector(getFractionPricesPaginationSelector)
  const loading = useSelector(
    createLoadingSelector(['GET_DSZ_FRACTION_PRICES']),
  )
  const [currentFilters, setCurrentFilters] = useState({})
  const dszShopOptions = DSZ_SHOPS_CHOICES.slice(1).map(shop => ({
    label: I18n.t(getShop(shop.id)!.translationKey),
    value: shop.id,
  }))
  const yearFilterInputRef = createRef<HTMLInputElement>()

  // Sync currentFilter value with yearFilter
  useEffect(() => {
    yearFilterInputRef.current!.value = currentFilters['year']
  }, [currentFilters, yearFilterInputRef])

  useEffect(() => {
    dispatch(getDszFractionPrices(1, currentFilters))
  }, [dispatch, currentFilters])

  const pricesTableColumns = [
    {
      id: 'shop',
      Header: (
        <span>
          <Translate value='priceManagementPageTranslations.pricesTable.columns.shop' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'shop',
      maxWidth: 300,
      Cell: data => I18n.t(getShop(data.value)!.translationKey),
    },
    {
      id: 'year',
      Header: (
        <span>
          <Translate value='priceManagementPageTranslations.pricesTable.columns.year' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'year',
      maxWidth: 180,
    },
    {
      id: 'fraction',
      Header: (
        <span>
          <Translate value='priceManagementPageTranslations.pricesTable.columns.fraction' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'fraction_shop_name',
    },
    {
      id: 'actions',
      Header: (
        <Translate value='priceManagementPageTranslations.pricesTable.columns.actions' />
      ),
      maxWidth: 70,
      filterable: false,
      sortable: false,
      Cell: data => (
        <Link
          to='#'
          onClick={() => {
            setEditingInstance(data.original)
            handleOpenCloseIsAddPriceModal()
          }}
          title={I18n.t(
            'priceManagementPageTranslations.pricesTable.columns.editTitle',
          )}
        >
          <Icon name={ICON_NAME.PENCIL} />
        </Link>
      ),
    },
  ]

  return (
    <>
      <Filter isLoading={loading} length={fractionPrices.length}>
        {/*Using <> before the div because otherwise the Filter component throws several errors*/}
        <>
          <div className='dsz-prices-table--filters uk-grid uk-child-width-1-5@l uk-child-width-1-2@m'>
            <StaticCombobox
              isLoading={loading}
              name='shop'
              noResultsText={I18n.t('filterTranslations.noInputResults')}
              options={dszShopOptions}
              onSelectionChange={event => {
                setCurrentFilters({
                  ...currentFilters,
                  shop: event.target.value,
                })
              }}
              selectedOption={getCurrentValueObject(
                dszShopOptions,
                currentFilters['shop'],
              )}
              label={I18n.t(
                'priceManagementPageTranslations.pricesTable.filters.shop',
              )}
              placeholder={I18n.t(
                'priceManagementPageTranslations.pricesTable.filters.shop',
              )}
            />
            <InputNumber
              name='licenceYear'
              onChange={event => {
                if (
                  Number(event.target.value) >= 2000 &&
                  Number(event.target.value) <= 2999
                ) {
                  setCurrentFilters({
                    ...currentFilters,
                    year: event.target.value,
                  })
                }
              }}
              label={I18n.t(
                'priceManagementPageTranslations.pricesTable.filters.licenseYear',
              )}
              placeholder={I18n.t(
                'priceManagementPageTranslations.pricesTable.filters.licenseYear',
              )}
              inputRef={yearFilterInputRef}
              maxLength={4}
            />
            {(currentFilters['shop'] || currentFilters['year']) && (
              <div className='dsz-prices-table--filters--reset'>
                <Link
                  to='#'
                  onClick={() => setCurrentFilters({})}
                  className='icon-cross'
                >
                  <Translate value='priceManagementPageTranslations.pricesTable.filters.reset' />
                </Link>
              </div>
            )}
          </div>
        </>
      </Filter>
      <PaginatedTable
        serverSidePagination
        page={pagination.current}
        pages={pagination.count}
        loading={loading}
        handleShowPreviousPage={() => {
          dispatch(getDszFractionPrices(pagination.previous, currentFilters))
        }}
        handleShowNextPage={() => {
          dispatch(getDszFractionPrices(pagination.next, currentFilters))
        }}
        table={{
          columns: pricesTableColumns,
          data: fractionPrices,
          onFetchData: ({ page, sorted }) => {
            if (sorted && sorted.length > 0) {
              const newFilters = {
                ...currentFilters,
                order_by: JSON.stringify(sorted),
              }
              if (
                JSON.stringify(newFilters) !== JSON.stringify(currentFilters) ||
                !pagination.loaded
              ) {
                setCurrentFilters(newFilters)
                dispatch(getDszFractionPrices(page, newFilters))
              }
            }
          },
        }}
      />
    </>
  )
}
