import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'
import { Link } from 'react-scroll'

import Icon, { ICON_NAME } from 'components/common/Fontello'

interface NavigationBarProps {
  sections: Map<
    string | null,
    {
      offset: number
      title: React.ReactNode
    }
  >
  sticky?: boolean
  spaceBetween?: boolean
  noIcons?: boolean
}

export const NavigationBar: FC<NavigationBarProps> = props => {
  const {
    sections,
    sticky = false,
    spaceBetween = false,
    noIcons = false,
  } = props

  return (
    <div
      className={classNames('navigation-bar', {
        'navigation-bar--sticky': sticky,
        'navigation-bar--space-between': spaceBetween,
      })}
      data-uk-sticky={sticky ? 'offset: 91' : null}
    >
      <ul className='uk-list uk-height-1-1'>
        {Array.from(sections, ([key, child]) => {
          /*
          When no key is specified, the section will not be rendered in Navigation.
          Useful when section needs to be rendered, but should not be displayed in the navigation.
          */
          if (!key) {
            return null
          }

          return (
            <li key={key}>
              <Link
                activeClass='active'
                offset={child.offset}
                to={key}
                spy
                smooth
              >
                {child.title}
                {/* icon is hidden via CSS when not active */}
                {!noIcons && <Icon name={ICON_NAME.CHEVRON_DOWN} />}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
