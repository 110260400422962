import { arrayOf, bool, number, oneOfType, shape, string } from 'prop-types'

import { AddressSmallScheme } from './address'
import { UserSmallScheme } from './user'

export const CompanySmallScheme = {
  id: number,
  block_auto_offers: bool,
  created_at: string,
  empto_external_number: number,
  evaluation_rate: number,
  inquiries_count: number,
  main_address_object: shape(AddressSmallScheme),
  name: string,
  number_of_evaluations: number,
  offers_count: number,
  role: oneOfType([number, string]),
  slug: string,
  status: number,
  private_person: bool,
  custom_agreement_optional_certificate: bool,
}

export const CompanyScheme = {
  id: number,
  account_holder_name: string,
  block_auto_offers: bool,
  card: oneOfType([string, shape({})]),
  commercial_registry_number: string,
  contact_person: oneOfType([number, string]),
  contact_person_object: shape(UserSmallScheme),
  responsible_employee: oneOfType([number, string]),
  responsible_employee_object: shape(UserSmallScheme),
  created_at: string,
  empto_external_number: number,
  registration_number: string,
  efb_certificate_valid_until_date: string,
  eta_service: bool,
  evaluation_rate: number,
  iban: oneOfType([string, shape({})]),
  bic: string,
  inquiries: arrayOf(number),
  invoice_recipient: string,
  lastmodified_at: string, // Date
  lastmodified_by_name: string, // Name or email of person
  permanent_discount: oneOfType([number, string]),
  main_address_object: shape(AddressSmallScheme),
  postal_address_object: shape(AddressSmallScheme),
  name: string,
  number_of_evaluations: number,
  offers: arrayOf(number),
  payment_method: oneOfType([number, string]),
  role: oneOfType([number, string]),
  status: number,
  tax_number: string,
  tax_id: string,
  private_person: bool,
  custom_agreement_optional_certificate: bool,
}
