import { ReactText } from 'react'
import { I18n } from 'react-i18nify'

import { BUSINESS_SEGMENT } from 'constants/app'
import { MaklerPremiumGroups } from 'constants/user'

import { Option } from '../../common/StaticCombobox/StaticCombobox'

export const resetInputOption = [
  {
    value: '',
    label: 'Alle',
  },
]

export const getEmptoInputOption = [
  {
    value: 'empto',
    label: 'Leer',
  },
]

export const getCurrentValueObject = (array: Option[], value?: ReactText) =>
  array.find(item => item.value === value)

// --------------- MAP AND FILTER FRACTIONLIST ---------------

const userIsMaklerPremium = user => user.group_id in MaklerPremiumGroups

const getItemBusinessSegmentLabel = businessSegment =>
  businessSegment === BUSINESS_SEGMENT.BUSINESS_EMPTO
    ? I18n.t('filterTranslations.myOfferFilter.fractionEmpto')
    : I18n.t('filterTranslations.myOfferFilter.fractionEPD')

export const filterMapFractionList = (
  fractionList: Fraction[] = [],
  user: User,
) => {
  if (userIsMaklerPremium(user)) {
    return fractionList
      .filter(
        item => item.business_segment === user.company_object.business_segment,
      )
      .map(item => ({
        label: `${item.name} ${getItemBusinessSegmentLabel(
          item.business_segment,
        )}`,
        value: `${item.id}`,
      }))
  }
  return fractionList.map(item => ({
    label: `${item.name} ${getItemBusinessSegmentLabel(item.business_segment)}`,
    value: `${item.id}`,
  }))
}
