import ActionTypes from '../actiontypes/executionproof'
import { TABLE_PAGE_SIZE_10 } from '../constants/app'

/** @function
 * @name getExecutionProofs
 * @description Loads a list of execution proofs.
 * @return {Object}
 */
export const getExecutionProofs = (
  page,
  filters,
  pageSize = TABLE_PAGE_SIZE_10,
) => ({
  type: ActionTypes.GET_EXECUTION_PROOFS_REQUEST,
  payload: {
    page,
    filters,
    pageSize,
  },
})

/** @function
 * @name getExecutionProof
 * @description Loads a execution proof.
 * @param id of a execution proof.
 * @return {Object}
 */
export const getExecutionProof = id => ({
  type: ActionTypes.GET_EXECUTION_PROOF_REQUEST,
  payload: id,
})

/** @function
 * @name createExecutionProof
 * @description Creates a new version of a execution proof. This can only be used if there is no pending version of the
 * same type existing.
 * @return {Object}
 */
export const createExecutionProof = executionProof => ({
  type: ActionTypes.CREATE_EXECUTION_PROOF_REQUEST,
  payload: executionProof,
})

/** @function
 * @name updateReviewedExecutionProof
 * @description Updates a execution proof after review.
 * @return {Object}
 */
export const updateReviewedExecutionProof = (executionProof, history) => ({
  type: ActionTypes.UPDATE_REVIEWED_EXECUTION_PROOF_REQUEST,
  payload: {
    executionProof,
    history,
  },
})

/**
 * @function
 * @name exportExecutionProofs
 * @return {Object}
 */
export const exportExecutionProofs = () => ({
  type: ActionTypes.EXPORT_EXECUTION_PROOFS_REQUEST,
})

/**
 * @function
 * @name resetExecutionProofItem
 * @return {Object}
 */
export const resetExecutionProofItem = () => ({
  type: ActionTypes.RESET_EXECUTION_PROOF_ITEM_REQUEST,
})
