import keyMirror from 'keymirror'

const GET_SECURITY_GROUPS = keyMirror({
  GET_SECURITY_GROUPS_REQUEST: null,
  GET_SECURITY_GROUPS_SUCCESS: null,
  GET_SECURITY_GROUPS_ERROR: null,
})

export default {
  ...GET_SECURITY_GROUPS,
}
