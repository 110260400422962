import { connect } from 'react-redux'

import { exportCertificates, getCertificates } from 'actions/certificate'
import {
  getCertificatePaginationSelector,
  getCertificatesSelector,
} from 'selectors/certificate'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'

const certificatesLoadingSelector = createLoadingSelector(['GET_CERTIFICATES'])
const exportCertificatesLoadingSelector = createLoadingSelector([
  'EXPORT_CERTIFICATES',
])
const errorSelector = createErrorSelector([
  'GET_CERTIFICATES',
  'EXPORT_CERTIFICATES',
])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  user: getCurrentUserSelector(state),
  certificates: getCertificatesSelector(state),
  pagination: getCertificatePaginationSelector(state),
  isLoading: {
    certificates: certificatesLoadingSelector(state),
    exportCertificates: exportCertificatesLoadingSelector(state),
  },
  error: errorSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getCertificates: (page, filters, sortedBy) =>
    dispatch(getCertificates(page, filters, sortedBy)),
  exportCertificates: () => dispatch(exportCertificates()),
})

export default connect(mapStateToProps, mapDispatchToProps)
