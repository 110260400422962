import {
  FILE_CLASSES_CERTIFICATES,
  FILE_CLASSES_DOCUMENTS,
} from '../components/general/EmptoDownloads/contants'
/**
 * @description gets the list of security groups from the state
 * @param state
 * @return {*}
 */
export const getEmptoDownloadsCertificatesSelector: (
  any,
) => EmptoDownloads[] = state =>
  state.emptoDownloads.itemList.filter(item =>
    FILE_CLASSES_CERTIFICATES.includes(item.file_class),
  )

export const getEmptoDownloadsDocumentsSelector: (
  any,
) => EmptoDownloads[] = state =>
  state.emptoDownloads.itemList.filter(item =>
    FILE_CLASSES_DOCUMENTS.includes(item.file_class),
  )

export default {}
