import keyMirror from 'keymirror'

const GET_EXECUTION_PROOFS = keyMirror({
  GET_EXECUTION_PROOFS_REQUEST: null,
  GET_EXECUTION_PROOFS_SUCCESS: null,
  GET_EXECUTION_PROOFS_ERROR: null,
})

const GET_EXECUTION_PROOF = keyMirror({
  GET_EXECUTION_PROOF_REQUEST: null,
  GET_EXECUTION_PROOF_SUCCESS: null,
  GET_EXECUTION_PROOF_ERROR: null,
})

const CREATE_EXECUTION_PROOF = keyMirror({
  CREATE_EXECUTION_PROOF_REQUEST: null,
  CREATE_EXECUTION_PROOF_SUCCESS: null,
  CREATE_EXECUTION_PROOF_ERROR: null,
})

const UPDATE_REVIEWED_EXECUTION_PROOF = keyMirror({
  UPDATE_REVIEWED_EXECUTION_PROOF_REQUEST: null,
  UPDATE_REVIEWED_EXECUTION_PROOF_SUCCESS: null,
  UPDATE_REVIEWED_EXECUTION_PROOF_ERROR: null,
})

const EXPORT_EXECUTION_PROOFS = keyMirror({
  EXPORT_EXECUTION_PROOFS_REQUEST: null,
  EXPORT_EXECUTION_PROOFS_SUCCESS: null,
  EXPORT_EXECUTION_PROOFS_ERROR: null,
})

const RESET_EXECUTION_PROOF_ITEM = keyMirror({
  RESET_EXECUTION_PROOF_ITEM_REQUEST: null,
})

export default {
  ...GET_EXECUTION_PROOFS,
  ...GET_EXECUTION_PROOF,
  ...CREATE_EXECUTION_PROOF,
  ...UPDATE_REVIEWED_EXECUTION_PROOF,
  ...EXPORT_EXECUTION_PROOFS,
  ...RESET_EXECUTION_PROOF_ITEM,
}
