import { number, string } from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { USER_STATUS } from '../../user/constants'

const UserLinkComponent = ({ userId, userName, userStatus }) => {
  if (!userId || !userName) return null
  if (userStatus !== USER_STATUS.STATUS_ACTIVE) {
    return userName
  }
  return <Link to={`/user/${userId}`}>{userName}</Link>
}

UserLinkComponent.propTypes = {
  userId: number,
  userName: string,
  userStatus: number,
}

UserLinkComponent.defaultProps = {
  userId: null,
  userName: '',
  userStatus: null,
}

export default UserLinkComponent
