const orderClaimApiTranslations = {
  handleGetOrderClaimList: {
    error: 'Reklamationen konnten leider nicht geladen werden.',
  },
  handleGetOrderClaim: {
    error: 'Reklamation konnte leider nicht geladen werden.',
  },
  handleCreateOrderClaim: {
    success: 'Reklamation wurde erfolgreich hinzugefügt.',
    error: 'Hinzufügen der Reklamation leider fehlgeschlagen.',
  },
  handleUpdateReviewedOrderClaim: {
    success: 'Reklamation wurde erfolgreich gespeichert.',
    error: 'Speichern der Reklamation leider fehlgeschlagen.',
  },
  handleUpdateOrderClaimInternalNote: {
    success: 'Reklamation wurde erfolgreich gespeichert.',
    error: 'Speichern der Reklamation leider fehlgeschlagen.',
  },
  handleExportOrderClaims: {
    success: 'Export der Reklamationen wurde erfolgreich gestartet.',
    error: 'Export der Reklamationen leider fehlgeschlagen.',
  },
}

export default orderClaimApiTranslations
