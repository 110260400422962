import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import EmptoInternalDownloadsActionTypes from '../actiontypes/emptointernaldownloads'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetEmptoInternalDownloadsList
 * @description Loads all empto internal downloads from the api.
 */
export const handleGetEmptoInternalDownloadsList = createLogic({
  type: EmptoInternalDownloadsActionTypes.GET_EMPTO_INTERNAL_DOWNLOADS_REQUEST,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/emptointernaldownloads/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: EmptoInternalDownloadsActionTypes.GET_EMPTO_INTERNAL_DOWNLOADS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.emptoDownloads.handleGetEmptoInternalDownloadsList.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: EmptoInternalDownloadsActionTypes.GET_EMPTO_INTERNAL_DOWNLOADS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [handleGetEmptoInternalDownloadsList]
