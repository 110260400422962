import './style.scss'

import React, { Component } from 'react'
import { Control } from 'react-redux-form'

import { TooltipIcon } from 'components/common/TooltipIcon'

import { ControlDefaultProps } from '../../constants'
import FormField from '../../index'

import connector from './connector'
import CheckboxControlProps from './schemes'

/**
 * @description Custom textbox component
 * see: http://davidkpiano.github.io/react-redux-form/docs/guides/custom-controls.html
 */
class CheckboxControl extends Component {
  /**
   * @description Override this method in a child class to define an individual field.
   * @return {*}
   */
  getField() {
    if (!this.props.field) return null

    return (
      <div
        className={`checkbox-control${this.props.disabled ? ' disabled' : ''}`}
      >
        {/* eslint-disable-next-line jsx-a11y/label-has-for */}
        <label htmlFor={this.props.field.model}>
          <Control.checkbox // eslint-disable-line react/jsx-pascal-case
            className={this.props.className}
            data-testid={this.props.dataTestId}
            id={this.props.field.model}
            model={this.props.field.model}
            onChange={this.props.onChange}
            tabIndex={this.props.tabIndex}
            type='checkbox'
          />
          <span>{this.props.label}</span>
        </label>
        {this.props.tooltip && <TooltipIcon {...this.props.tooltip} />}
      </div>
    )
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    return (
      <FormField
        {...this.props}
        label={null}
        tooltip={null}
        showLabelColumn={false}
        fieldComponent={this.getField()}
      />
    )
  }
}

CheckboxControl.propTypes = CheckboxControlProps

CheckboxControl.defaultProps = {
  ...ControlDefaultProps,
  onChange: () => undefined,
}

export default connector(CheckboxControl)
