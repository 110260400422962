import React, { FC } from 'react'

import { Input, INPUT_TYPE, InputProps } from '../Input'

interface InputEmailProps extends InputProps {
  maxLength?: number
}

export const InputEmail: FC<InputEmailProps> = ({
  maxLength = 255,
  onChange,
  ...rest
}) => {
  const onValueChange = event => {
    if (event.target.value.length <= maxLength) onChange(event)
  }

  return <Input onChange={onValueChange} type={INPUT_TYPE.EMAIL} {...rest} />
}
