import { bool, number, string } from 'prop-types'

export const ContainerScheme = {
  id: number,
  name: string,
  capacity: string,
  image: string,
  reaction_time: number,
  with_top: bool,
  // Deprecated, can be removed when the empto marketplace components are being removed
  is_package_price: bool,
}
