import './style.scss'

import React from 'react'
import { useSelector } from 'react-redux'

import { getCurrentUserSelector } from 'selectors/user'

import UserImg from './components/UserImg/index'

/**
 * @description This component renders and controls a UserInfo component.
 * @function
 * @param {Object} props the component props
 */
export const UserInfo = ({ displayEmailAddress = true }) => {
  const user = useSelector(getCurrentUserSelector)

  return (
    user && (
      <section className='user-information uk-flex uk-width'>
        <UserImg profile_image_object={user.profile_image_object} />
        <div className='user-credentials uk-display-inline-block'>
          {user.first_name && user.last_name && (
            <div className='user-name'>
              {user.first_name}
              &nbsp;
              {user.last_name}
            </div>
          )}

          {displayEmailAddress && (
            <div className='user-email'>{user.email}</div>
          )}
        </div>
      </section>
    )
  )
}
