import { I18n } from 'react-i18nify'
import { actions } from 'react-redux-form'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import ActionTypes from '../actiontypes/company'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'
import { addHttpProtocol } from '../helper/general'

/**
 * @function
 * @name handleGetCompany
 * @description gets a single company by ID
 */
export const handleGetCompany = createLogic({
  type: ActionTypes.GET_COMPANY_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/${action.payload.id}/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COMPANY_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.company.handleGetCompany.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_COMPANY_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetActiveCompanies
 * @description gets a list of active companies
 */
export const handleGetActiveCompanies = createLogic({
  type: ActionTypes.GET_ACTIVE_COMPANIES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/active/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ACTIVE_COMPANIES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.company.handleGetCompany.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_ACTIVE_COMPANIES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetActiveCompaniesForFilter
 * @description gets a list of active companies
 */
export const handleGetActiveCompaniesForFilter = createLogic({
  type: ActionTypes.GET_ACTIVE_COMPANIES_FOR_FILTER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/active-for-filter/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ACTIVE_COMPANIES_FOR_FILTER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.company.handleGetCompany.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_ACTIVE_COMPANIES_FOR_FILTER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetAllCompaniesForFilter
 * @description gets a list of all companies
 */
export const handleGetAllCompaniesForFilter = createLogic({
  type: ActionTypes.GET_ALL_COMPANIES_FOR_FILTER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/all-for-filter/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ALL_COMPANIES_FOR_FILTER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.company.handleGetCompany.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_ALL_COMPANIES_FOR_FILTER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetActiveCustomerForFilter
 * @description gets a list of active customer companies
 */
export const handleGetActiveCustomerForFilter = createLogic({
  type: ActionTypes.GET_ACTIVE_CUSTOMER_FOR_FILTER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/active-for-filter/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ACTIVE_CUSTOMER_FOR_FILTER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.company.handleGetCompany.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_ACTIVE_CUSTOMER_FOR_FILTER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetActivePartnerForFilter
 * @description gets a list of active partner companies
 */
export const handleGetActivePartnerForFilter = createLogic({
  type: ActionTypes.GET_ACTIVE_PARTNER_FOR_FILTER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/active-for-filter/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ACTIVE_PARTNER_FOR_FILTER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.company.handleGetCompany.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_ACTIVE_PARTNER_FOR_FILTER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCompanies
 * @description gets a list of all companies
 */
export const handleGetCompanies = createLogic({
  type: ActionTypes.GET_COMPANIES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/`,
        action.payload.filters,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COMPANIES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.company.handleGetCompany.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_COMPANIES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetMaklerPremiumCompanies
 * @description gets a list of all companies of a makler premium
 */
export const handleGetMaklerPremiumCompanies = createLogic({
  type: ActionTypes.GET_MAKLER_PREMIUM_COMPANIES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/makler-premium-companies/`

    const data = {
      ...action.payload,
      page: action.payload.page || 1,
    }

    httpClient
      .get(url, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_MAKLER_PREMIUM_COMPANIES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.company.handleGetMaklerPremiumCompanies.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_MAKLER_PREMIUM_COMPANIES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCollectiveInvoiceCompanies
 * @description gets a list of collective invoicing companies of a makler premium
 */
export const handleGetCollectiveInvoiceCompanies = createLogic({
  type: ActionTypes.GET_COLLECTIVE_INVOICE_COMPANIES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/collective-invoices/`

    const data = {
      ...action.payload,
      page: action.payload.page || 1,
    }

    httpClient
      .get(url, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COLLECTIVE_INVOICE_COMPANIES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.company.handleGetCollectiveInvoiceCompanies.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_COLLECTIVE_INVOICE_COMPANIES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateCollectiveInvoice
 * @description create collecvtive invoice by given company
 */
export const handleCreateCollectiveInvoice = createLogic({
  type: ActionTypes.CREATE_COLLECTIVE_INVOICE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/${action.payload.companyId}/collective-invoice/`,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.CREATE_COLLECTIVE_INVOICE_SUCCESS,
          payload: res,
        })
        toastr.success(
          '',
          I18n.t('api.company.handleCreateCollectiveInvoice.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.company.handleCreateCollectiveInvoice.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.CREATE_COLLECTIVE_INVOICE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateCollectiveInvoice
 * @description create collecvtive invoice by given company
 */
export const handleCreateTotalCollectiveInvoice = createLogic({
  type: ActionTypes.CREATE_TOTAL_COLLECTIVE_INVOICE_REQUEST,
  latest: true,

  process({ httpClient }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/total-collective-invoice/`,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.CREATE_TOTAL_COLLECTIVE_INVOICE_SUCCESS,
          payload: res,
        })
        toastr.success(
          '',
          I18n.t('api.company.handleCreateTotalCollectiveInvoice.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.company.handleCreateTotalCollectiveInvoice.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.CREATE_TOTAL_COLLECTIVE_INVOICE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCombinedOfferEPDCompaniesForTVP
 * @description gets a list of all EPD companies for a TVP user
 */
export const handleGetCombinedOfferEPDCompaniesForTVP = createLogic({
  type: ActionTypes.GET_COMBINED_OFFER_EPD_COMPANIES_FOR_TVP_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/combined-offer-epd-companies-for-tvp/`

    const data = {
      ...action.payload,
      page: action.payload.page || 1,
    }

    httpClient
      .get(url, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COMBINED_OFFER_EPD_COMPANIES_FOR_TVP_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        // We use the same error message as is used in handleGetMaklerPremiumCompanies
        const humanReadableError = I18n.t(
          'api.company.handleGetMaklerPremiumCompanies.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_COMBINED_OFFER_EPD_COMPANIES_FOR_TVP_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCompanies
 * @description gets a list of all companies
 */
export const handleGetCompaniesForFilter = createLogic({
  type: ActionTypes.GET_COMPANIES_FOR_FILTER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/for-filter/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COMPANIES_FOR_FILTER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.company.handleGetCompany.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_COMPANIES_FOR_FILTER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateCompany
 * @description gets a single company by ID
 */
export const handleUpdateCompany = createLogic({
  type: ActionTypes.UPDATE_COMPANY_REQUEST,

  process({ httpClient, action }, dispatch, done) {
    const data = {
      ...action.payload,
      website_url: addHttpProtocol(action.payload.website_url),
    }
    const {
      isUpdatingCompanyInfo,
      isUpdatingCompanyStatus,
      isUpdatingStatusReason,
    } = data
    delete data.isUpdatingCompanyInfo

    // First we need to check that the object in the payload has an ID at least.
    const checkIdInObject = new Promise((resolve, reject) => {
      if (!data.id)
        return reject(
          new Error('Object must have an ID to perform an update action.'),
        )
      return resolve(
        httpClient.patch(
          `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/${data.id}/`,
          data,
        ),
      )
    })

    checkIdInObject
      .then(res => res.data)
      .then(res => {
        dispatch(actions.reset('company.item'))
        dispatch({
          type: ActionTypes.UPDATE_COMPANY_SUCCESS,
          payload: res,
        })

        if (isUpdatingCompanyInfo) {
          toastr.success('', I18n.t('api.company.handleUpdateCompany.success'))
        } else if (isUpdatingCompanyStatus || isUpdatingStatusReason) {
          toastr.success(
            '',
            I18n.t('api.company.handleUpdateCompanyStatus.success'),
          )
        } else {
          if (res.block_auto_offers)
            toastr.success(
              '',
              I18n.t('myAutoOffersPageTranslations.toastr.lock.success'),
            )
          if (!res.block_auto_offers)
            toastr.success(
              '',
              I18n.t('myAutoOffersPageTranslations.toastr.unlock.success'),
            )
        }
      })
      .then(done)
      .catch(err => {
        dispatch(actions.setPending('company.item', false))
        let humanReadableError = I18n.t('api.company.handleUpdateCompany.error')
        if (!isUpdatingCompanyInfo) {
          humanReadableError = I18n.t(
            'api.autoOffer.handleLockAutoOffers.error',
          )
        }

        if (
          err.response &&
          err.response.data &&
          Array.isArray(err.response.data.non_field_errors)
        ) {
          humanReadableError = `${humanReadableError} ${err.response.data.non_field_errors[0]}`
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.UPDATE_COMPANY_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handlePauseUnpauseCompany
 * @description pauses or unpauses a single company by ID
 */
export const handlePauseUnpauseCompany = createLogic({
  type: ActionTypes.PAUSE_COMPANY_REQUEST,

  process({ httpClient, action }, dispatch, done) {
    // First we need to check that the object in the payload has an ID at least.
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/${action.payload.id}/toggle_pause/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch(actions.reset('company.item'))
        dispatch({
          type: ActionTypes.PAUSE_COMPANY_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.company.handleGetCompany.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.PAUSE_COMPANY_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetMyOffersInquiredCompanies
 * @description get companies from my offers (to use in My Offers Company filter).
 */
export const handleGetMyOffersInquiredCompanies = createLogic({
  type: ActionTypes.GET_MY_OFFERS_INQUIRED_COMPANIES_REQUEST,
  latest: true,

  process({ httpClient }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/my-offer/inquired-companies/`
    httpClient
      .get(url)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_MY_OFFERS_INQUIRED_COMPANIES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.address.handleBlockAddress.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_MY_OFFERS_INQUIRED_COMPANIES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportMaklerPremiumCompanies
 * @description triggers the companies export.
 */
export const handleExportMaklerPremiumCompanies = createLogic({
  type: ActionTypes.EXPORT_MAKLER_PREMIUM_COMPANIES_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const payload = action.payload
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/export-makler-premium/`

    httpClient
      .get(url, payload)
      .then(() => {
        toastr.success('', I18n.t('api.company.handleExportCompanies.success'))
        dispatch({
          type: ActionTypes.EXPORT_MAKLER_PREMIUM_COMPANIES_SUCCESS,
        })
      })
      .then(done)
      .catch(err => {
        dispatch({
          type: ActionTypes.EXPORT_MAKLER_PREMIUM_COMPANIES_ERROR,
        })
        if (!err.hideToastr)
          toastr.error('', I18n.t('api.company.handleExportCompanies.error'))
        done()
      })
  },
})

/**
 * @function
 * @name handleGetCustomInvoiceCompanies
 * @description gets a list of companies needed for creating a custom invoice
 */
export const handleGetCustomInvoiceCompanies = createLogic({
  type: ActionTypes.GET_CUSTOM_INVOICE_COMPANIES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/custom-invoice-companies/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_CUSTOM_INVOICE_COMPANIES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.company.handleGetCompany.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_CUSTOM_INVOICE_COMPANIES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCompanyNotes
 * @description gets a list of notes for a company
 */
export const handleGetCompanyNotes = createLogic({
  type: ActionTypes.GET_COMPANY_NOTES_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/note/`, action.payload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COMPANY_NOTES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.company.handleGetCompanyNotes.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_COMPANY_NOTES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleDeleteCompanyNotes
 * @description deletes a specific company note
 */
export const handleDeleteCompanyNote = createLogic({
  type: ActionTypes.DELETE_COMPANY_NOTE_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .delete(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/note/${action.payload}/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.DELETE_COMPANY_NOTE_SUCCESS,
          payload: res,
        })
        toastr.success(
          '',
          I18n.t('api.company.handleDeleteCompanyNote.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.company.handleDeleteCompanyNote.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.DELETE_COMPANY_NOTE_ERROR,
          payload,
        })

        toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleAddCompanyNote
 * @description adds a new note to a company
 */
export const handleAddCompanyNote = createLogic({
  type: ActionTypes.ADD_COMPANY_NOTE_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const { resetField, ...payload } = action.payload

    httpClient
      .post(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/note/`, payload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.ADD_COMPANY_NOTE_SUCCESS,
          payload: res,
        })
        toastr.success('', I18n.t('api.company.handleAddCompanyNote.success'))
        resetField()
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.company.handleAddCompanyNote.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.ADD_COMPANY_NOTE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleAddComCenterContact
 * @description adds a new note to a company
 */
export const handleAddComCenterContact = createLogic({
  type: ActionTypes.ADD_COM_CENTER_CONTACT_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const payload = action.payload

    httpClient
      .post(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/com-center/`, payload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.ADD_COM_CENTER_CONTACT_SUCCESS,
          payload: res,
        })
        toastr.success(
          '',
          I18n.t('api.company.handleAddComCenterContact.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.company.handleAddComCenterContact.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.ADD_COM_CENTER_CONTACT_SUCCESS,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleDeleteComCenterContact
 * @description deletes a new note to a company
 */
export const handleDeleteComCenterContact = createLogic({
  type: ActionTypes.DELETE_COM_CENTER_CONTACT_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .delete(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/com-center/${action.payload}/`,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.DELETE_COM_CENTER_CONTACT_SUCCESS,
          payload: res,
        })
        toastr.success(
          '',
          I18n.t('api.company.handleDeleteComCenterContact.success'),
        )
      })
      .then(done)
      .catch(err => {
        let humanReadableError
        if (
          String(err.response.data).includes(
            'You have to have at least 1 com center contact in stage 1 of operational processing category',
          )
        ) {
          humanReadableError = I18n.t(
            'api.company.handleDeleteComCenterContact.lastEntry',
          )
        } else {
          humanReadableError = I18n.t(
            'api.company.handleDeleteComCenterContact.error',
          )
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.DELETE_COM_CENTER_CONTACT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCompanyNotes
 * @description gets a list of notes for a company
 */
export const handleGetComCenterContacts = createLogic({
  type: ActionTypes.GET_COM_CENTER_CONTACTS_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/com-center/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COM_CENTER_CONTACTS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.company.handleGetComCenterContact.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_COM_CENTER_CONTACTS_SUCCESS,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateComCenterContact
 * @description update an existing company communication contact
 */
export const handleUpdateComCenterContact = createLogic({
  type: ActionTypes.UPDATE_COM_CENTER_CONTACT_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .put(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/com-center/${action.payload.id}/`,
        { ...action.payload, is_modified: true },
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.UPDATE_COM_CENTER_CONTACT_SUCCESS,
          payload: res,
        })
        toastr.success(
          '',
          I18n.t('api.company.handleUpdateComCenterContact.success'),
        )
      })
      .then(done)
      .catch(err => {
        let humanReadableError
        if (
          String(err.response.data).includes(
            'You have to have at least 1 com center contact in stage 1 of operational processing category',
          )
        ) {
          humanReadableError = I18n.t(
            'api.company.handleDeleteComCenterContact.lastEntry',
          )
        } else {
          humanReadableError = I18n.t(
            'api.company.handleDeleteComCenterContact.error',
          )
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.UPDATE_COM_CENTER_CONTACT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export const handleGetComCenterLog = createLogic({
  type: ActionTypes.GET_COM_CENTER_LOG_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/com-center/get-com-center-log/${action.payload}/`,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COM_CENTER_LOG_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.company.handleGetComCenterLog.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_COM_CENTER_LOG_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export const handleGetTaskLogs = createLogic({
  type: ActionTypes.GET_TASK_LOGS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/task-log/`, action.payload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_TASK_LOGS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.company.handleGetCompany.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_TASK_LOGS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export const handleGetTaskLogMessages = createLogic({
  type: ActionTypes.GET_TASK_LOG_MESSAGES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/task-log-message/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_TASK_LOG_MESSAGES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.company.handleGetCompany.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_TASK_LOG_MESSAGES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export const handleGetCompanyHistory = createLogic({
  type: ActionTypes.GET_COMPANY_HISTORY_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/company/${action.payload.id}/history/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COMPANY_HISTORY_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.company.handleGetCompanyHistory.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_COMPANY_HISTORY_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [
  handleAddComCenterContact,
  handleAddCompanyNote,
  handleCreateCollectiveInvoice,
  handleCreateTotalCollectiveInvoice,
  handleDeleteComCenterContact,
  handleDeleteCompanyNote,
  handleExportMaklerPremiumCompanies,
  handleGetActiveCompanies,
  handleGetActiveCompaniesForFilter,
  handleGetActiveCustomerForFilter,
  handleGetActivePartnerForFilter,
  handleGetAllCompaniesForFilter,
  handleGetCollectiveInvoiceCompanies,
  handleGetCombinedOfferEPDCompaniesForTVP,
  handleGetComCenterContacts,
  handleGetComCenterLog,
  handleGetCompanies,
  handleGetCompaniesForFilter,
  handleGetCompany,
  handleGetCompanyNotes,
  handleGetCustomInvoiceCompanies,
  handleGetMaklerPremiumCompanies,
  handleGetMyOffersInquiredCompanies,
  handleGetTaskLogs,
  handleGetTaskLogMessages,
  handlePauseUnpauseCompany,
  handleUpdateComCenterContact,
  handleUpdateCompany,
  handleGetCompanyHistory,
]
