import './style.scss'

import React, {
  Children,
  FC,
  ReactChild,
  ReactNodeArray,
  useState,
} from 'react'
import { Translate } from 'react-i18nify'
import Media from 'react-media'

import { BUTTON_BACKGROUND_COLOR, BUTTON_SHAPE } from 'components/common/Button'
import { IconButton } from 'components/common/IconButton'
import { Tooltip, TOOLTIP_TRIGGER } from 'components/common/Tooltip'

interface HomepageCardsProps {
  children: ReactNodeArray | ReactChild
  className: string
  disableLastChildTooltip?: boolean
}

export const HomepageCards: FC<HomepageCardsProps> = ({
  children,
  className,
  disableLastChildTooltip,
}) => {
  const [activeCardIndex, setActiveCardIndex] = useState<number>(0)
  const childrenArray = Children.toArray(children)

  /**
   * @description Shows the previous card.
   */
  const showPreviousCard = () => {
    setActiveCardIndex(
      (activeCardIndex - 1 + childrenArray.length) % childrenArray.length,
    )
  }

  /**
   * @description Shows the next card.
   */
  const showNextCard = () => {
    setActiveCardIndex(
      (activeCardIndex + 1 + childrenArray.length) % childrenArray.length,
    )
  }

  /**
   * @description Render the tooltip button which explains the last item.
   */
  const renderTooltip = () => (
    <div className='uk-position-top-right'>
      <Tooltip
        distance={25}
        html={
          <div style={{ maxWidth: '300px' }}>
            <Translate value='homepages.wasteProducer.zipCode.tooltip' />
          </div>
        }
        tooltipId='zipCodeResultCard'
        trigger={TOOLTIP_TRIGGER.MANUAL}
      >
        <IconButton
          backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
          className={`${className}__card-help-icon`}
          iconName='question'
        />
      </Tooltip>
    </div>
  )

  if (!children) return null
  return (
    <Media query={{ maxWidth: 1150 }}>
      {isSmallScreen =>
        isSmallScreen ? (
          <div className='homepage-cards'>
            <div className='homepage-cards__button homepage-cards__button--left'>
              <IconButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                iconName='angle-left'
                onClick={showPreviousCard}
                shape={BUTTON_SHAPE.ROUNDED}
              />
            </div>

            <div className='homepage-cards__card uk-align-center uk-position-relative'>
              {
                /* Only on last item */
                !disableLastChildTooltip &&
                  activeCardIndex === childrenArray.length - 1 &&
                  renderTooltip()
              }

              {children[activeCardIndex]}
            </div>

            <div className='homepage-cards__button homepage-cards__button--right'>
              <IconButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                iconName='angle-right'
                onClick={showNextCard}
                shape={BUTTON_SHAPE.ROUNDED}
              />
            </div>
          </div>
        ) : (
          <div className='uk-flex uk-flex-center'>
            {childrenArray.map((child, index) => (
              /* eslint-disable react/no-array-index-key */
              <div
                key={index}
                className={`${className}__card uk-margin-medium-bottom uk-flex uk-animation-slide-bottom-medium`}
              >
                {
                  /* Only on last item */
                  !disableLastChildTooltip &&
                    index === childrenArray.length - 1 &&
                    renderTooltip()
                }
                {child}
              </div>
              /* eslint-enable react/no-array-index-key */
            ))}
          </div>
        )
      }
    </Media>
  )
}
