import './style.scss'

import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import Icon from 'components/common/Fontello'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { SectionHeader } from 'components/common/SectionHeader'

import { InvoiceCheckDocumentType } from '../../InvoiceCheckPage/InvoiceCheckAcquisition'
import { OrderType } from '../InvoiceCheckPositionsForm/Step2'
import { NetPriceType } from '../InvoiceCheckPositionsForm/Step2/OrderAndServicesForm'

interface InvoiceCheckOverviewInvoiceDataProps {
  invoiceCheck?: InvoiceCheck
  invoicePositionVat: number
  invoicePositionGrossSum: number
  invoicePositionNetSum: number
  difference: number
}

/**
 * @description This component displays the price summary for an InvoiceCheck
 * @function
 */
export const InvoiceCheckOverviewInvoiceOverview: FC<
  InvoiceCheckOverviewInvoiceDataProps
> = ({
  invoiceCheck,
  invoicePositionVat,
  invoicePositionGrossSum,
  invoicePositionNetSum,
  difference,
}) => {
  if (!invoiceCheck?.positions) return null

  const getInvoiceCheckPositionPrice = position => {
    const isOpenOrder = position.order_type === OrderType.OPEN_ORDER
    const isOtherServiceOrRent = [
      OrderType.OTHER_SERVICE,
      OrderType.RENT,
    ].includes(position.order_type)

    const isBurden = position.net_price_type === NetPriceType.BURDEN
    const isCredit = position.net_price_type === NetPriceType.CREDIT

    if (
      (isOpenOrder &&
        invoiceCheck.document_type === InvoiceCheckDocumentType.INVOICE) ||
      (isOtherServiceOrRent && isBurden)
    ) {
      return Number(position.net_price).toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })
    }
    if (
      (isOpenOrder &&
        invoiceCheck.document_type === InvoiceCheckDocumentType.CREDIT_NOTE) ||
      (isOtherServiceOrRent && isCredit)
    ) {
      return `- ${Number(position.net_price).toLocaleString('de-DE', {
        style: 'currency',
        currency: 'EUR',
      })}`
    }

    return ''
  }

  return (
    <section className='uk-card uk-padding-remove'>
      <SectionHeader>
        <Icon name='to-do' />
        <Translate value='invoiceCheckOverviewTranslations.section_header.invoice_overview' />
      </SectionHeader>
      <div className='uk-padding details-container'>
        <div className='uk-grid'>
          <div className='uk-width-1-1'>
            <ReadOnlyField
              className='uk-margin-medium-bottom'
              label={I18n.t(
                'invoiceCheckOverviewTranslations.section.invoice_overview.count_invoice_positions',
              )}
              value={invoiceCheck.positions.length}
            />
            <table className='uk-table uk-table-invoice-overview'>
              <tbody>
                {/* Rechnungsposition [Nummer] */}
                {invoiceCheck.positions.map((value, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={index}>
                    <td>
                      {I18n.t(
                        'invoiceCheckOverviewTranslations.section.invoice_overview.invoice_position',
                      )}
                      &nbsp;
                      {index + 1}
                    </td>
                    <td>{getInvoiceCheckPositionPrice(value)}</td>
                  </tr>
                ))}

                {/* Trennlinie*/}
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>

                {/* Zwischensumme */}
                <tr>
                  <td>
                    <Translate value='invoiceCheckOverviewTranslations.section.invoice_overview.intermediate_sum' />
                  </td>
                  <td>
                    {invoicePositionNetSum.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                </tr>

                {/* Zzgl. MwSt. */}
                <tr>
                  <td>
                    <Translate
                      value='invoiceCheckOverviewTranslations.section.invoice_overview.add_vat'
                      vat={parseFloat(`${invoiceCheck.vat}`).toLocaleString(
                        'de-DE',
                        { minimumFractionDigits: 0 },
                      )}
                    />
                  </td>
                  <td>
                    {invoicePositionVat.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                </tr>

                {/* Gesamtsumme */}
                <tr>
                  <td>
                    <Translate value='invoiceCheckOverviewTranslations.section.invoice_overview.net_sum' />
                  </td>
                  <td>
                    <b>
                      {invoicePositionGrossSum.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      })}
                    </b>
                  </td>
                </tr>

                {/* Trennlinie*/}
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>

                {/* Bruttosumme */}
                <tr>
                  <td>
                    <Translate value='invoiceCheckOverviewTranslations.section.invoice_overview.gross_sum' />
                  </td>
                  <td>
                    {parseFloat(
                      invoiceCheck.gross_price.toString(),
                    ).toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                </tr>

                {/* Differenz */}
                <tr>
                  <td>
                    <Translate value='invoiceCheckOverviewTranslations.section.invoice_overview.difference' />
                  </td>
                  <td className={difference !== 0 ? 'difference_warning' : ''}>
                    {difference.toLocaleString('de-DE', {
                      style: 'currency',
                      currency: 'EUR',
                    })}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  )
}
