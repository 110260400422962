export const WIDTH_BREAKEPOINT_MEDIUM = 960

/**
 * Invoicing document types
 */
export const INVOICING_DOCUMENT_TYPE = {
  TYPE_FINAL: 1,
  TYPE_DEADHEAD: 2,
}

export const INVOICING_DOCUMENT_TYPES = [
  {
    id: INVOICING_DOCUMENT_TYPE.TYPE_FINAL,
    name: 'invoice.type.final',
  },
  {
    id: INVOICING_DOCUMENT_TYPE.TYPE_DEADHEAD,
    name: 'invoice.type.deadhead',
  },
]

export enum ORDER_CLAIM_TYPE {
  TYPE_NOT_CARRIED = 1,
  TYPE_NOT_COMPLETED,
  TYPE_CREDIT_WRONG,
  TYPE_DEADHEAD,
  TYPE_OTHERS,
  TYPE_WAITING_TIME,
  TYPE_CREDIT_ALREADY_PAID,
  TYPE_CONTAINER_WRONG,
  TYPE_RELABELING,
}

const GENERAL_CHOICES = [
  ORDER_CLAIM_TYPE.TYPE_CREDIT_WRONG,
  ORDER_CLAIM_TYPE.TYPE_OTHERS,
]

export const AEZ_CHOICES = [
  ORDER_CLAIM_TYPE.TYPE_NOT_CARRIED,
  ORDER_CLAIM_TYPE.TYPE_NOT_COMPLETED,
  ...GENERAL_CHOICES,
]

export const ENTSORGER_CHOICES = [
  ORDER_CLAIM_TYPE.TYPE_DEADHEAD,
  ORDER_CLAIM_TYPE.TYPE_WAITING_TIME,
  ORDER_CLAIM_TYPE.TYPE_RELABELING,
  ...GENERAL_CHOICES,
]

export const ORDER_CLAIM_TYPES_OBJECT = {
  [ORDER_CLAIM_TYPE.TYPE_DEADHEAD]: {
    id: ORDER_CLAIM_TYPE.TYPE_DEADHEAD,
    positionOnSelection: 1,
    name: 'orderClaim.types.deadhead',
    message: 'orderClaim.types.messages.deadhead',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
  [ORDER_CLAIM_TYPE.TYPE_WAITING_TIME]: {
    id: ORDER_CLAIM_TYPE.TYPE_WAITING_TIME,
    positionOnSelection: 2,
    name: 'orderClaim.types.waitingTime',
    message: 'orderClaim.types.messages.waitingTime',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
  [ORDER_CLAIM_TYPE.TYPE_NOT_CARRIED]: {
    id: ORDER_CLAIM_TYPE.TYPE_NOT_CARRIED,
    positionOnSelection: 3,
    name: 'orderClaim.types.notCarried',
    message: 'orderClaim.types.messages.notCarried',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
  [ORDER_CLAIM_TYPE.TYPE_NOT_COMPLETED]: {
    id: ORDER_CLAIM_TYPE.TYPE_NOT_COMPLETED,
    positionOnSelection: 4,
    name: 'orderClaim.types.notCompleted',
    message: 'orderClaim.types.messages.notCompleted',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
  [ORDER_CLAIM_TYPE.TYPE_CREDIT_WRONG]: {
    id: ORDER_CLAIM_TYPE.TYPE_CREDIT_WRONG,
    positionOnSelection: 5,
    name: 'orderClaim.types.creditWrong',
    message: 'orderClaim.types.messages.creditWrong',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
  [ORDER_CLAIM_TYPE.TYPE_CREDIT_ALREADY_PAID]: {
    id: ORDER_CLAIM_TYPE.TYPE_CREDIT_ALREADY_PAID,
    positionOnSelection: 6,
    name: 'orderClaim.types.creditAlreadyPaid',
    message: 'orderClaim.types.messages.creditAlreadyPaid',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
  [ORDER_CLAIM_TYPE.TYPE_CONTAINER_WRONG]: {
    id: ORDER_CLAIM_TYPE.TYPE_CONTAINER_WRONG,
    positionOnSelection: 7,
    name: 'orderClaim.types.containerWrong',
    message: 'orderClaim.types.messages.containerWrong',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
  [ORDER_CLAIM_TYPE.TYPE_RELABELING]: {
    id: ORDER_CLAIM_TYPE.TYPE_RELABELING,
    positionOnSelection: 8,
    name: 'orderClaim.types.wasteTypeNotMatched',
    message: 'orderClaim.types.messages.wasteTypeNotMatched',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
  [ORDER_CLAIM_TYPE.TYPE_OTHERS]: {
    id: ORDER_CLAIM_TYPE.TYPE_OTHERS,
    positionOnSelection: 100, // unreasonably high number to ensure display last
    name: 'orderClaim.types.others',
    message: 'orderClaim.types.messages.others',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
}

export const ORDER_CLAIM_TYPES = Object.values(ORDER_CLAIM_TYPES_OBJECT)

export enum ORDER_SWITCH_LABELS {
  SHORT_OVERVIEW = 0,
  ORDER,
  PARTIES,
  ORDER_DETAILS,
  ADDRESS,
  IMAGES,
  EXECUTION_PROOFS,
  INVOICE_CHECK_ATTACHMENTS,
}
