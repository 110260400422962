import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

import Icon from 'components/common/Fontello/index'

export interface ItemDateProps {
  title: string
  date: string
  timeOfDay: string
  asList?: boolean
}

export const ItemDate: FC<ItemDateProps> = ({
  date,
  timeOfDay,
  title,
  asList = true,
}) => {
  const row = (
    <>
      <Icon name='calendar' />
      <Translate value={title} date={date} timeOfDay={timeOfDay} />
    </>
  )

  if (asList)
    return (
      <li className='information-card__body__properties--list--item'>{row}</li>
    )

  return row
}
