import { number, oneOfType, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { Errors } from 'react-redux-form'

import Icon from 'components/common/Fontello'
import ControlEditToggle from 'components/common/Form/components/ControlEditToggle'
import { TextboxControl } from 'components/common/Form/elements'
import { FieldScheme } from 'components/common/Form/schemes'
import { SectionHeader } from 'components/common/SectionHeader'

import { CompanyFormValidators } from '../../constants'

import connector from './connector'

export class TaxDetailsComponent extends Component {
  render() {
    return (
      <section className='uk-card uk-padding-remove tax-details'>
        <SectionHeader>
          <Icon name='checklist' />
          <Translate value='companyProfilePage.taxDetails' />
        </SectionHeader>

        <div className='uk-padding details-container'>
          <div className='uk-grid uk-child-width-1-1 uk-child-width-1-3@m'>
            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.taxNumber')}
              value={this.props.company.tax_number}
              errorClass={
                !this.props.companyForm.tax_number.valid
                  ? 'uk-text-danger'
                  : null
              }
              triggerEdit={!this.props.companyForm.tax_number.valid}
              editable
            >
              <TextboxControl
                className='uk-input'
                field={this.props.companyForm.tax_number}
                validators={CompanyFormValidators.tax_number}
                maxLength={20}
              />
            </ControlEditToggle>

            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.taxId')}
              value={this.props.company.tax_id}
              errorClass={
                !this.props.companyForm.tax_id.valid ? 'uk-text-danger' : null
              }
              triggerEdit={!this.props.companyForm.tax_id.valid}
              editable
            >
              <TextboxControl
                className='uk-input'
                field={this.props.companyForm.tax_id}
                placeholder={I18n.t('companyProfilePage.placeholder.taxId')}
                errorMessages={{
                  isGermanTaxId: I18n.t('message.validation.germanTaxId'),
                }}
                maxLength={11}
                noSpecialChars
              />
            </ControlEditToggle>

            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t(
                'companyProfilePage.label.commercialRegistryNumber',
              )}
              value={this.props.company.commercial_registry_number}
              errorClass={
                !this.props.companyForm.commercial_registry_number.valid
                  ? 'uk-text-danger'
                  : null
              }
              triggerEdit={
                !this.props.companyForm.commercial_registry_number.valid
              }
              editable
            >
              <TextboxControl
                className='uk-input'
                field={this.props.companyForm.commercial_registry_number}
                placeholder={I18n.t(
                  'companyProfilePage.placeholder.commercialRegistryNumber',
                )}
                validators={CompanyFormValidators.commercial_registry_number}
                maxLength={50}
              />
            </ControlEditToggle>
          </div>
          <Errors
            model='company.item'
            className='form-error'
            messages={{
              taxDataFilled: I18n.t(
                'companyProfilePage.validation.taxDataFilled',
              ),
              tax_id: I18n.t('message.validation.germanTaxId'),
            }}
          />
        </div>
      </section>
    )
  }
}

TaxDetailsComponent.propTypes = {
  company: shape({
    commercial_registry_number: string,
    tax_number: oneOfType([number, string]),
    tax_id: oneOfType([number, string]),
  }).isRequired,
  companyForm: shape({
    commercial_registry_number: FieldScheme,
    tax_number: FieldScheme,
    tax_id: FieldScheme,
  }).isRequired,
}

export default connector(TaxDetailsComponent)
