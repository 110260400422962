import { Form, Formik, useFormikContext } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, { ReactText } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import { inviteUser } from 'actions/user'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { UserFormValues } from 'components/user/UserForm/UserFormFormikWrapper'
import { MaklerPremiumGroups } from 'constants/user'
import { USER_GENDER_LABELS } from 'helper/user'

import { AddContactPersonForm } from './AddContactPersonForm'

type AddContactPersonModalPropsType = {
  isOpen: boolean
  onClose: () => void
  company?: number
}

export type AddContactPersonFormValues = {
  gender: ReactText
  first_name: string
  last_name: string
  preferred_address: ReactText
  email: string
  business_phone_number?: string
}

export const AddContactPersonModal = (
  props: AddContactPersonModalPropsType,
) => {
  const { setFieldValue } = useFormikContext<UserFormValues>()

  const { isOpen, onClose, company } = props

  const dispatch = useDispatch()

  const idAddContactPersonModalHeadline = uniqueId()

  return (
    <>
      <Modal
        ariaDescribedBy={idAddContactPersonModalHeadline}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalHeader
          onClose={onClose}
          title={I18n.t('inquiryForm.addEmailUser')}
          titleId={idAddContactPersonModalHeadline}
        />

        <Formik
          initialValues={{
            gender: '',
            first_name: '',
            last_name: '',
            preferred_address: 'all',
            business_phone_number: '',
            email: '',
          }}
          validationSchema={() =>
            yup.object().shape({
              gender: yup
                .string()
                .oneOf(
                  Object.keys(USER_GENDER_LABELS()),
                  I18n.t('message.validation.invalidSelection'),
                )
                .required(I18n.t('message.validation.selectionRequired')),
              first_name: yup
                .string()
                .required(I18n.t('message.validation.required')),
              last_name: yup
                .string()
                .required(I18n.t('message.validation.required')),
              preferred_address: yup.string(),
              business_phone_number: yup.string(),
              email: yup
                .string()
                .email(I18n.t('message.validation.email.isEmail'))
                .required(I18n.t('message.validation.required'))
                .test(
                  'Is not maklerpremium.example.com domain',
                  I18n.t(
                    'createMaklerPremiumOfferPageTranslations.steps.3.fields.invalidEmailDomain',
                  ),
                  value =>
                    (value &&
                      !value.includes('@maklerpremium.example.com')) as boolean,
                ),
            })
          }
          onSubmit={(values, { setFieldError }) => {
            const callbacksToApi = {
              success: () => {
                onClose()
              },
              createdUser: createdUser => {
                setFieldValue('customer_email', createdUser.email)
              },
              setError: (key, string) => setFieldError(key, string),
            }
            const { email } = values
            const requestToInvite = {
              ...values,
              user_company: company,
              group_id: MaklerPremiumGroups.MAKLER_PREMIUM_EPD_CONTACT_PERSON,
              email,
            }

            dispatch(inviteUser(requestToInvite, callbacksToApi))
          }}
        >
          <Form
            className='add-contact-person-form'
            data-testid='add-contact-person-form'
            noValidate
          >
            <AddContactPersonForm onClose={onClose} />
          </Form>
        </Formik>
      </Modal>
    </>
  )
}
