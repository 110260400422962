import './style.scss'

import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { I18n } from 'react-i18nify'
import Spinner from 'react-spinkit'

import { TooltipIcon, TooltipIconProps } from '../../common/TooltipIcon'
import { callbackOrType } from '../Input'
import { InputCheckmark } from '../InputCheckmark'
import { Option } from '../StaticCombobox/StaticCombobox'

import { supportsIntersectionObserver } from './helpers'

export const KEY_ARROW_DOWN = 'ArrowDown'
export const KEY_ARROW_UP = 'ArrowUp'
export const KEY_ENTER = 'Enter'
export const KEY_ESCAPE = 'Escape'

export interface ComboboxProps {
  className?: string
  error?: callbackOrType<string>
  dataTestId?: string
  inputValue: string
  isDisabled?: boolean
  isLoading?: boolean
  isNoResultsTextVisible?: boolean
  isRequired?: boolean
  label?: ReactNode | string
  subLabel?: ReactNode | string
  name: string
  noResultsText: string
  options: Option[]
  onInputChange: React.ChangeEventHandler<any>
  onSelectionChange: React.ChangeEventHandler<any>
  placeholder?: string
  selectedOption?: Option
  tooltip?: TooltipIconProps
  withCheckmark?: boolean
  showCheckmark?: callbackOrType<boolean>
  showRequiredDot?: boolean
}

export const Combobox: FC<ComboboxProps> = ({
  className,
  error = '',
  dataTestId = '',
  inputValue,
  isDisabled = false,
  isLoading = false,
  isNoResultsTextVisible = true,
  isRequired = false,
  name,
  noResultsText,
  onInputChange,
  onSelectionChange,
  options,
  placeholder = '',
  selectedOption = {},
  label = '',
  subLabel = '',
  tooltip = null,
  withCheckmark = false,
  showCheckmark = false,
  showRequiredDot = false,
}) => {
  /**
   * Former class variables
   */
  const componentId = useRef(uniqueId())
  // Required to avoid issues if the same component is inserted twice into a page
  const inputRef = useRef<HTMLInputElement>(null)
  const isBlurAllowed = useRef(false)
  // Not in state to avoid unnecessary rerenderings
  const isAutoScrollingEnabled = useRef(true)
  // Not in state to avoid unnecessary rerenderings
  const observer = useRef<IntersectionObserver>()
  const optionListRef = useRef<HTMLUListElement>(null)
  const intersectionObserverEntries = useRef<{
    [key: string]: IntersectionObserverEntry
  }>({})

  /**
   * Variables defined on render
   */
  const inputId = uniqueId()
  const optionListId = uniqueId()

  /**
   * State
   */
  const [highlightedIndex, setHighlightedIndex] = useState(-1)
  const [isOptionListOpen, setIsOptionListOpen] = useState(false)
  const [previousInputValue, setPreviousInputValue] = useState(inputValue)

  /**
   * Event handlers and helpers
   */
  const openOptionList = useCallback(
    (newHighlightedIndex?: number) => {
      if (isDisabled) {
        return
      }

      isAutoScrollingEnabled.current = true

      if (newHighlightedIndex !== undefined)
        setHighlightedIndex(newHighlightedIndex)
      setIsOptionListOpen(true)
    },
    [isDisabled],
  )

  const closeOptionList = () => {
    setHighlightedIndex(-1)
    setIsOptionListOpen(false)
  }

  const getOptionId = value => `${componentId.current}-${value}`

  const selectInputText = useCallback(() => {
    if (inputRef && inputRef.current && !isDisabled) {
      inputRef.current!.select()
    }
  }, [isDisabled])

  const selectLastOption = useCallback(() => {
    if (!isDisabled) {
      isAutoScrollingEnabled.current = true
      setHighlightedIndex(options.length - 1)
    }
  }, [isDisabled, options.length])

  const selectNextOption = useCallback(() => {
    if (!isDisabled) {
      isAutoScrollingEnabled.current = true
      setHighlightedIndex(highlightedIndex + 1)
    }
  }, [highlightedIndex, isDisabled])

  const selectPrevOption = useCallback(() => {
    if (!isDisabled) {
      isAutoScrollingEnabled.current = true
      setHighlightedIndex(highlightedIndex - 1)
    }
  }, [highlightedIndex, isDisabled])

  const onSelectOption = (event, option) => {
    onSelectionChange({
      ...event,
      target: {
        ...event.target,
        name,
        value: option,
      },
    })
    closeOptionList()
  }

  const onComponentBlur = () => {
    if (isBlurAllowed.current) {
      closeOptionList()
    }
  }

  const onComponentKeyDown = event => {
    switch (event.key) {
      case KEY_ESCAPE:
        isBlurAllowed.current = true
        onComponentBlur()

        // Simulate input change
        onInputChange({
          ...event,
          target: {
            ...event.target,
            value:
              selectedOption && selectedOption.label
                ? selectedOption.label
                : '',
          },
        })
        break

      case KEY_ARROW_DOWN:
        if (isOptionListOpen) {
          // Only if not last option: Select next option
          if (highlightedIndex < options.length - 1) {
            selectNextOption()
          }
        } else if (selectedOption && selectedOption.value) {
          // If an option is selected: Start with the selected option
          const selectedOptionIndex = options.findIndex(
            option => option.value === selectedOption.value,
          )

          openOptionList(selectedOptionIndex)
        } else {
          openOptionList(0)
        }
        break

      case KEY_ARROW_UP:
        if (isOptionListOpen) {
          // Only if not first option: Select prev option
          if (highlightedIndex > 0) {
            selectPrevOption()
          } else if (highlightedIndex === -1) {
            selectLastOption()
          }
        } else if (selectedOption && selectedOption.value) {
          // If an option is selected: Start with the selected option
          const selectedOptionIndex = options.findIndex(
            option => option.value === selectedOption.value,
          )

          openOptionList(selectedOptionIndex)
        } else {
          openOptionList(options.length - 1)
        }
        break

      case KEY_ENTER:
        if (highlightedIndex !== -1 && options.length > 0) {
          onSelectOption(event, options[highlightedIndex])
        }
        break

      default: // Do nothing
    }
  }

  const onComponentMouseDown = () => {
    isBlurAllowed.current = false // Disallow onBlur while mouse is down over current component
  }

  const onComponentMouseUp = () => {
    isBlurAllowed.current = true // Reallow onBlur after mouse left component
  }

  const onInputFocus = () => {
    selectInputText()
  }

  const onOptionListMouseOver = event => {
    const selectedOptionIndex = options.findIndex(
      option => getOptionId(option.value) === event.target.id,
    )

    isAutoScrollingEnabled.current = false

    setHighlightedIndex(selectedOptionIndex)
  }

  /**
   * Effects
   */

  // On Mount set up Intersection observer
  useEffect(() => {
    const intersectionOptions = {
      root: optionListRef.current,
      rootMargin: '0px',
      threshold: 1.0,
    }

    if (supportsIntersectionObserver()) {
      // Create a hashmap of all intersectionOptions (avoids looping through entries array multiple times). Changes to
      // highlightedIndex aren't reflected here because it is only updated on browser-internal scrolling (e.g. mouse
      // or scrollIntoView). Therefore, we store the entries and react to changes of highlightedIndex in
      // componentDidUpdate.
      // Make sure none of them are deleted otherwise auto-scrolling doesn't work
      observer.current = new IntersectionObserver(entries => {
        // eslint-disable-line no-undef
        entries.forEach(entry => {
          const key = entry.target.id
          intersectionObserverEntries.current[key] = entry
        })
      }, intersectionOptions)
    }
  }, [])

  useEffect(() => {
    selectInputText()
  }, [selectInputText, selectedOption.label, selectedOption.value])

  useEffect(() => {
    if (inputValue !== previousInputValue) {
      if (selectedOption.label !== inputValue) {
        openOptionList(0)
        setPreviousInputValue(inputValue)
      }
    }
  }, [inputValue, openOptionList, previousInputValue, selectedOption])

  useEffect(() => {
    if (
      observer &&
      observer.current &&
      optionListRef &&
      optionListRef.current
    ) {
      const comboOptions =
        optionListRef.current.querySelectorAll('.combobox__option')

      comboOptions.forEach(option => {
        observer.current!.observe(option)
      })
    }
  })

  useEffect(() => {
    const highlightedEntry = Object.entries(
      intersectionObserverEntries.current,
    ).find(entry =>
      entry[1].target.classList.contains('combobox__option--highlighted'),
    )

    if (highlightedEntry && highlightedEntry.length === 2) {
      // 2 = key + value
      const highlightedOption = highlightedEntry[1]

      // If an option is highlighted due to the mouse cursor, the option is already visible
      if (
        highlightedOption &&
        highlightedOption.intersectionRatio < 1 &&
        isAutoScrollingEnabled.current
      ) {
        highlightedOption.target.scrollIntoView({
          behavior: 'smooth',
        })
      }
    }
  }, [highlightedIndex])

  return (
    <div // eslint-disable-line jsx-a11y/no-static-element-interactions
      className={classNames(
        'combobox',
        { 'combobox--with-checkmark': withCheckmark },
        className,
      )}
      onBlur={onComponentBlur}
      onKeyDown={onComponentKeyDown}
      onMouseDown={onComponentMouseDown}
      onMouseUp={onComponentMouseUp}
    >
      <Row middle='xs'>
        <Col xs={12} sm={12}>
          <div className='combobox__label-container'>
            {label && (
              /* eslint-disable-next-line jsx-a11y/label-has-for */
              <label
                className='combobox__label-container__label'
                htmlFor={inputId}
              >
                {label}
                {subLabel && (
                  <span className='combobox__label-container__label__sub-label'>
                    {subLabel}
                  </span>
                )}
              </label>
            )}
            {tooltip && (
              <div className='combobox__label-container__tooltip'>
                <TooltipIcon {...tooltip} />
              </div>
            )}
          </div>
          {isRequired &&
            showRequiredDot &&
            !(selectedOption && selectedOption.value) && (
              <div
                className={classNames('combobox__required-dot', {
                  'combobox__required-dot--error': error !== '',
                })}
              />
            )}
        </Col>
        <Col xs={12} sm={12} style={{ position: 'relative' }}>
          <input
            aria-activedescendant={
              options.length > 0 &&
              highlightedIndex !== -1 &&
              options[highlightedIndex]
                ? getOptionId(options[highlightedIndex].value)
                : ''
            }
            aria-autocomplete='list' // User can choose from list of choices but input retains focus
            aria-expanded={isOptionListOpen}
            aria-haspopup='listbox' // Additional content will be provided in a listbox (element with "listbox" role)
            aria-owns={optionListId} // Controls the element with the specified ID
            autoComplete='off'
            className={classNames(
              'combobox__input',
              { 'combobox__input--loading': isLoading },
              {
                'combobox__input--list-expanded':
                  isOptionListOpen && options.length > 0,
              },
              'uk-select',
            )}
            data-testid={dataTestId}
            disabled={isDisabled}
            id={inputId}
            onChange={onInputChange}
            onClick={
              isOptionListOpen ? closeOptionList : () => openOptionList()
            }
            onFocus={onInputFocus}
            placeholder={placeholder || I18n.t('general.placeholder.all')}
            ref={inputRef}
            role='combobox' // eslint-disable-line jsx-a11y/role-has-required-aria-props
            type='text'
            value={inputValue}
          />

          {withCheckmark && <InputCheckmark isHidden={!showCheckmark} />}

          {isLoading ? (
            <>
              <div className='combobox__loading-indicator'>
                <Spinner name='circle' />
              </div>

              {isOptionListOpen && (
                <div className='combobox__results-loading'>
                  <Spinner name='circle' />
                </div>
              )}
            </>
          ) : (
            <>
              {isNoResultsTextVisible &&
              isOptionListOpen &&
              options.length === 0 ? (
                <div className='combobox__no-results' id={optionListId}>
                  {noResultsText}
                </div>
              ) : (
                <ul // eslint-disable-line jsx-a11y/mouse-events-have-key-events
                  className={classNames('combobox__option-list', {
                    'combobox__option-list--expanded':
                      isOptionListOpen && options.length > 0,
                  })}
                  id={optionListId}
                  onMouseOver={onOptionListMouseOver} // Highlight current row
                  ref={optionListRef}
                  role='listbox'
                >
                  {isOptionListOpen &&
                    options.map(({ label: optionLabel, value }, index) => (
                      <li // eslint-disable-line jsx-a11y/click-events-have-key-events
                        className={classNames(
                          'combobox__option',
                          {
                            'combobox__option--selected':
                              selectedOption.value === value,
                          },
                          {
                            'combobox__option--highlighted':
                              index === highlightedIndex,
                          },
                        )}
                        aria-selected={selectedOption.value === value}
                        id={getOptionId(value)}
                        data-value={value}
                        key={getOptionId(value)}
                        onClick={event =>
                          onSelectOption(event, { label: optionLabel, value })
                        }
                        role='option'
                      >
                        {optionLabel}
                      </li>
                    ))}
                </ul>
              )}
            </>
          )}
          {error !== '' && <div className='combobox__error'>{error}</div>}
        </Col>
      </Row>
    </div>
  )
}
