import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import { useLocation } from 'react-router'

import { BUTTON_BACKGROUND_COLOR } from '../Button'
import { LinkButton } from '../LinkButton'

/**
 * Button that changes between archive and back to the original page
 * Sets a location state property "archive" to true if on the archive page, false if on the recent page
 *
 * @param pathname Optional: override the pathname for the Archive page, default is current pathname from location
 * @constructor
 */
export const ArchiveButton: FC<{ pathname?: string }> = ({ pathname }) => {
  const location = useLocation<{ archive?: boolean }>()
  let path = pathname
  if (!pathname) {
    path = location.pathname
  }

  return (
    <LinkButton
      backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
      target={{
        pathname: path,
        state: { archive: !location.state?.archive },
      }}
    >
      <Translate
        value={`general.button.${location.state?.archive ? 'back' : 'archive'}`}
      />
    </LinkButton>
  )
}
