import './styles.scss'

import classNames from 'classnames'
import React, { FC, ReactElement } from 'react'

import { Headline, HEADLINE_STYLE, HEADLINE_TAG } from '../Headline'

interface SectionHeaderProps {
  className?: string
  subtitle?: ReactElement | string
}

export const SectionHeader: FC<SectionHeaderProps> = ({
  children,
  className = '',
  subtitle,
}) => (
  <>
    <header className={classNames('section-header', className)}>
      <Headline
        headlineStyle={HEADLINE_STYLE.H3}
        tag={HEADLINE_TAG.H2}
        noMargin
      >
        {children}
      </Headline>
    </header>
    {subtitle && <p className='subtitle'>{subtitle}</p>}
  </>
)
