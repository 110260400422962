import './style.scss'

import classNames from 'classnames'
import React, { ComponentType, FC } from 'react'

interface CardListProps {
  addFunc?: (item) => Record<string, unknown> // Additional function that can be used to generate props in the card content.
  addProps?: Record<string, unknown> // Additional properties that are passed to the card component.
  component: ComponentType<any> // The card component.
  isHalfWidth?: boolean // Display the grid only as half width.
  items: (Inquiry | Offer | OrderListItem)[] // An array of items to be displayed as cards.
}

/**
 * Displays a list of cards.
 * Pass the contents of the card as a component in the `component` property. This component will be passes
 * a property `item` for each Item. You can add additional properties to each item component with the `addProps` prop.
 */
export const CardList: FC<CardListProps> = ({
  addFunc = () => undefined,
  addProps = {},
  component,
  isHalfWidth,
  items,
}) => {
  const CardComponent = component

  return (
    <ul
      className={classNames('card-list', {
        'card-list--half-width': isHalfWidth,
      })}
    >
      {items &&
        items.map(item => (
          <li key={item.id} className='card-list__item'>
            <CardComponent item={item} {...addProps} {...addFunc(item)} />
          </li>
        ))}
    </ul>
  )
}
