import { Form, Formik, FormikProps } from 'formik'
import moment, { isMoment } from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { pauseUnpauseCompany } from 'actions/company'
import { BUTTON_BACKGROUND_COLOR, BUTTON_TYPE } from 'components/common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { ApiValidationMessages } from 'components/common/Form/components/ApiValidationMessages'
import InputDate from 'components/common/InputDate'
import { ProgressButton } from 'components/common/ProgressButton'
import { Textarea } from 'components/common/Textarea'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

interface PausingModalFormProps {
  company: Company
  onCancel?: () => void
  onSuccess?: () => void
}

/**
 * @description This component renders and controls the PausingModalForm
 * @param offer the agreement to be changed
 * @param onSuccess action to be executed when submit successes
 * @constructor
 */
export const PausingModalForm: FC<PausingModalFormProps> = ({
  company,
  onSuccess = () => undefined,
}) => {
  const dispatch = useDispatch()

  const error = useSelector(createErrorSelector(['PAUSE_COMPANY_REQUEST']))
  const isLoading = useSelector(
    createLoadingSelector(['PAUSE_COMPANY_REQUEST']),
  )

  type PausingValues = {
    start_date?: moment.Moment | string
    end_date?: moment.Moment | string
    reason?: string
  }

  return (
    <div className='pause-company-form-page'>
      <Formik
        initialValues={{
          start_date: moment(),
          end_date: undefined,
          reason: '',
        }}
        validationSchema={() =>
          yup.object().shape({
            start_date: yup
              .string()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('${value} ist nicht vom Typ ${type}'),
            end_date: yup
              .string()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('${value} ist nicht vom Typ ${type}'),
            reason: yup
              .string()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('${value} ist nicht vom Typ ${type}'),
          })
        }
        validate={() => {
          const errors: {
            start_date?: string
            end_date?: string
            reason?: string
          } = {}

          return errors
        }}
        onSubmit={(values: PausingValues) => {
          const start_date = isMoment(values.start_date)
            ? values.start_date.format('DD.MM.YYYY')
            : values.start_date
          const end_date = values.end_date
            ? isMoment(values.end_date)
              ? values.end_date.format('DD.MM.YYYY')
              : values.end_date
            : undefined
          dispatch(
            pauseUnpauseCompany(
              company.id,
              start_date,
              end_date,
              values.reason,
            ),
          )
          onSuccess()
        }}
      >
        {(props: FormikProps<PausingValues>) => {
          const {
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            isValid,
            setFieldTouched,
            setFieldValue,
            submitCount,
            touched,
            values,
          } = props

          return (
            <Form
              className='uk-modal-body pause-company-form'
              noValidate // Disable browser validation
            >
              <ApiValidationMessages error={error} />
              <InputDate
                error={
                  submitCount > 0 && touched.start_date ? errors.start_date : ''
                }
                dataTestId='start-date-input'
                label={I18n.t(
                  'pauseCompanyModalTranslations.pauseCompanyForm.startDate',
                )}
                minDate={moment()}
                name='start_date'
                onChange={value => {
                  setFieldValue('start_date', value.format('L'))
                  setFieldTouched('start_date')
                  if (moment(value, 'L') > moment(values.end_date, 'L')) {
                    setFieldValue('end_date', '')
                  }
                }}
                placeholder={I18n.t(
                  'pauseCompanyModalTranslations.pauseCompanyForm.startDate',
                )}
                value={values.start_date}
                validateOnMount={false} // allow initial before minDate, but disallow selecting dates in the past
                isRequired={!values.start_date}
              />

              <InputDate
                error={
                  submitCount > 0 && touched.end_date ? errors.end_date : ''
                }
                dataTestId='end-date-input'
                label={I18n.t(
                  'pauseCompanyModalTranslations.pauseCompanyForm.endDate',
                )}
                // eslint-disable-next-line import/no-named-as-default-member
                minDate={moment.max(moment(values.start_date, 'L'), moment())}
                name='end_date'
                onChange={value => {
                  setFieldValue('end_date', value.format('L'))
                  setFieldTouched('end_date')
                }}
                placeholder={I18n.t(
                  'pauseCompanyModalTranslations.pauseCompanyForm.endDate',
                )}
                value={values.end_date}
                validateOnMount={false} // allow initial before minDate, but disallow selecting dates in the past
              />

              <Textarea
                error={submitCount > 0 && touched.reason ? errors.reason : ''}
                label={I18n.t(
                  'pauseCompanyModalTranslations.pauseCompanyForm.reason.label',
                )}
                placeholder={I18n.t(
                  'pauseCompanyModalTranslations.pauseCompanyForm.reason.placeholder',
                )}
                maxLength={200}
                name='reason'
                onChange={handleChange}
                value={values.reason}
                showRemainingCharacters
              />

              <div className='uk-modal-footer upload-document-form__buttons'>
                <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                  <ProgressButton
                    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                    dataTestId='change-agreement-period-form-submit'
                    isDisabled={
                      (submitCount > 0 && !isValid) ||
                      (isSubmitting && isLoading)
                    }
                    isLoading={isSubmitting && isLoading}
                    onClick={handleSubmit}
                    type={BUTTON_TYPE.BUTTON}
                  >
                    {I18n.t(
                      'pauseCompanyModalTranslations.pauseCompanyForm.submit',
                    )}
                  </ProgressButton>
                </ButtonBar>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
