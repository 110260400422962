import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import withErrorBoundary from 'helper/withErrorBoundary'

import { Homepage } from '../../common/Homepage'

const HomepageWasteProducerComponent: FC = () => (
  <Homepage
    pageTitle={I18n.t('pageTitles.homepageWasteProducer')}
    pageDescription={I18n.t('pageDescriptions.homepageWasteProducer')}
    showWasteProducerContent
  />
)

export const HomepageWasteProducer = withErrorBoundary(
  HomepageWasteProducerComponent,
)
