import './style.scss'

import { shape, func } from 'prop-types'
import React, { Component } from 'react'

import Icon, { ICON_NAME } from '../../common/Fontello'
import { AttachmentScheme } from '../fileUpload/schemes'

/**
 * Displays a simple image with the ui-kit classes. The content of the class should be passed as prop.
 * @param props
 * @returns {*}
 */
class Image extends Component {
  render() {
    const { file: image } = this.props
    return (
      <div className='image-component uk-cover-container uk-height-1-1'>
        <button
          className='uk-height-1-1'
          onClick={event => this.props.onClick(event, image)}
          type='button'
        >
          {image.type === 'application/pdf' ? (
            <Icon name={ICON_NAME.FILE_PDF} />
          ) : (
            <img data-uk-img src={image.file} alt={image.name} data-uk-cover />
          )}
        </button>
      </div>
    )
  }
}

Image.propTypes = {
  file: shape(AttachmentScheme).isRequired, // The file to display
  onClick: func, // Is called when the user clicks in the file
}

Image.defaultProps = {
  onClick: () => undefined,
}

export default Image
