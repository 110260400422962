/**
 * @description selector to get a invoice.
 * @param state
 * @return {*}
 */
export const getInvoiceSelector: (state: any) => Invoice = state =>
  state.invoice.item

/**
 * @description selector to get a invoice form.
 * @param state
 * @return {*}
 */
export const getInvoiceFormSelector: (state: any) => Invoice = state =>
  state.forms.invoice.item

/**
 * @description selector to get my invoices list.
 * @param state
 * @return {*}
 */
export const getInvoicesSelector: (state: any) => Invoice[] = state =>
  state.invoice.itemList

/**
 * @description selector to get my invoices list pagination.
 * @param state
 * @return {*}
 */
export const getInvoicesPaginationSelector: (
  state: any,
) => Pagination = state => state.invoice.pagination
