import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as UserActions from '../actions/user'
import { cookiesAcceptedSelector } from '../selectors/app'
import { createErrorSelector } from '../selectors/error'
import { getLoggedInSelector } from '../selectors/user'

const errorSelector = createErrorSelector(['LOGOUT'])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  loggedIn: !!getLoggedInSelector(state),
  cookiesAccepted: cookiesAcceptedSelector(state),
  error: errorSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  actions: {
    user: bindActionCreators(UserActions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
