import { isString, useFormikContext } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'

import { DropDownInput } from 'components/common/DropDownInput'
import { InputDecimal } from 'components/common/InputDecimal'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { PRICE_MODELS } from 'components/offer/constants'
import { PriceDetailsValues } from 'components/offer/CreateOfferPage/constants'
import { PARTNER_PRICE_MODEL_CHOICES } from 'constants/app'
import { BREAKPOINT } from 'constants/design'

interface DisposalPriceFieldsProps {
  isEditing?: boolean
  showRequiredDot?: boolean
  withCheckmarks?: boolean
  showPriceModelField?: boolean
}

export const DisposalPriceFields: FC<DisposalPriceFieldsProps> = ({
  isEditing = true,
  showRequiredDot = false,
  withCheckmarks = false,
  showPriceModelField = false,
}) => {
  const { errors, handleChange, submitCount, values, setFieldValue } =
    useFormikContext<PriceDetailsValues>()

  const [isSmallScreen, setIsSmallScreen] = useState(
    window.innerWidth < BREAKPOINT.XLARGE - 1,
  )

  useEffect(() => {
    /**
     * @description method to get window width
     */
    const handleWindowResize = () => {
      setIsSmallScreen(window.innerWidth < BREAKPOINT.XLARGE - 1)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const showErrorMsg = fieldErr => {
    if (submitCount > 0 && isSmallScreen && fieldErr) {
      return isString(fieldErr)
        ? fieldErr
        : `${I18n.t('disposalPriceFieldsTranslations.validation.required')}`
    }
    return ''
  }

  const isFieldValid = name =>
    values[name] ? `${values[name]}`.length > 0 && !errors[name] : false

  const showTransportPricePiece = [
    PRICE_MODELS.DISPOSAL_SINGLE,
    PRICE_MODELS.COMPENSATION_SINGLE,
  ].includes(String(values.price_model))
  const showDisposalCostTon =
    values.price_model === PRICE_MODELS.DISPOSAL_SINGLE
  const showDisposalCostContainer =
    values.price_model === PRICE_MODELS.DISPOSAL_CONTAINER
  const showCostContainer =
    values.price_model === PRICE_MODELS.COMPENSATION_CONTAINER
  const showHandleCostTon =
    values.price_model === PRICE_MODELS.COMPENSATION_SINGLE

  return (
    <div className='price-detail-fields'>
      {showPriceModelField && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('priceDetailFieldsTranslations.priceModel')}
          value={values.price_model}
        >
          <DropDownInput
            choices={PARTNER_PRICE_MODEL_CHOICES.map(item => ({
              optionValue: item.optionValue,
              optionLabel: `${I18n.t(item.optionLabel)}`,
            }))}
            error={showErrorMsg(errors.price_model)}
            label={I18n.t(`priceDetailFieldsTranslations.priceModel`)}
            name='price_model'
            onChange={event => {
              handleChange(event)
              for (const priceField of [
                'disposal_cost_container',
                'cost_container',
                'transport_price_piece',
                'disposal_cost_ton',
                'compensation_ton',
                'compensation_container',
                'index',
                'index_month',
                'surcharge',
                'reduction',
                'handle_cost_ton',
              ]) {
                setFieldValue(priceField, undefined)
              }
            }}
            value={values.price_model}
            isRequired={showRequiredDot && !values.price_model}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}

      {showDisposalCostContainer && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t(
            'disposalPriceFieldsTranslations.disposalCostContainer',
          )}
          value={values.disposal_cost_container}
        >
          <InputDecimal
            dataTestId='disposal-cost-container-input'
            error={showErrorMsg(errors.disposal_cost_container)}
            label={I18n.t(
              'disposalPriceFieldsTranslations.disposalCostContainer',
            )}
            maxValue={5000}
            name='disposal_cost_container'
            onChange={handleChange}
            placeholder={I18n.t(
              'disposalPriceFieldsTranslations.disposalCostPlaceholder',
            )}
            value={values.disposal_cost_container}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.disposal_cost_container}
            withCheckmark={withCheckmarks}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}
      {showCostContainer && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('disposalPriceFieldsTranslations.costContainer')}
          value={values.cost_container}
        >
          <InputDecimal
            dataTestId='cost-container-input'
            error={showErrorMsg(errors.cost_container)}
            label={I18n.t('disposalPriceFieldsTranslations.costContainer')}
            maxValue={5000}
            name='cost_container'
            onChange={handleChange}
            placeholder={I18n.t(
              'disposalPriceFieldsTranslations.costContainerPlaceholder',
            )}
            value={values.cost_container}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.cost_container}
            withCheckmark={withCheckmarks}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}
      {showTransportPricePiece && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('disposalPriceFieldsTranslations.transportPricePiece')}
          value={values.transport_price_piece}
        >
          <InputDecimal
            dataTestId='transport-price-piece-input'
            error={showErrorMsg(errors.transport_price_piece)}
            label={I18n.t(
              'disposalPriceFieldsTranslations.transportPricePiece',
            )}
            maxValue={999.99}
            name='transport_price_piece'
            onChange={handleChange}
            placeholder={I18n.t(
              'disposalPriceFieldsTranslations.pricePlaceholder',
            )}
            value={values.transport_price_piece}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.transport_price_piece}
            withCheckmark={withCheckmarks}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}
      {showHandleCostTon && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('priceDetailFieldsTranslations.handleCostTon')}
          value={values.handle_cost_ton}
        >
          <InputDecimal
            dataTestId='handle-cost-ton-input'
            error={showErrorMsg(errors.handle_cost_ton)}
            label={I18n.t('priceDetailFieldsTranslations.handleCostTon')}
            maxValue={5000}
            name='handle_cost_ton'
            onChange={handleChange}
            placeholder={I18n.t('priceDetailFieldsTranslations.handleCostTon')}
            value={values.handle_cost_ton}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.handle_cost_ton}
            withCheckmark={withCheckmarks}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}
      {showDisposalCostTon && (
        <ReadOnlyField
          editable={isEditing}
          label={I18n.t('disposalPriceFieldsTranslations.disposalCostTon')}
          value={values.disposal_cost_ton}
        >
          <InputDecimal
            dataTestId='disposal-cost-ton-input'
            error={showErrorMsg(errors.disposal_cost_ton)}
            label={I18n.t('disposalPriceFieldsTranslations.disposalCostTon')}
            maxValue={5000}
            name='disposal_cost_ton'
            onChange={handleChange}
            placeholder={I18n.t(
              'disposalPriceFieldsTranslations.disposalCostTon',
            )}
            value={values.disposal_cost_ton}
            removeBrowserStyling
            isRequired={showRequiredDot && !values.disposal_cost_ton}
            withCheckmark={withCheckmarks}
            showCheckmark={isFieldValid}
          />
        </ReadOnlyField>
      )}
    </div>
  )
}
