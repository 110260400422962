import './style.scss'

import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

export interface FooterProps {
  systemName: string
  privacyLink: string
  termsLink: string
  imprintLink: string
}

export const Footer: FC<FooterProps> = ({
  systemName,
  privacyLink,
  termsLink,
  imprintLink,
}) => {
  return (
    <footer className='landingpage-footer__divider'>
      <div className='uk-container uk-container-large'>
        <div className='landingpage-footer'>
          <span>{systemName}</span>
          <div className='landingpage-footer__links'>
            <a href={privacyLink}>
              {I18n.t(
                'WasteDisposerOrderManagementPageTranslations.footer.privacy',
              )}
            </a>
            <a href={termsLink}>
              {I18n.t(
                'WasteDisposerOrderManagementPageTranslations.footer.terms',
              )}
            </a>
            <a href={imprintLink}>
              {I18n.t(
                'WasteDisposerOrderManagementPageTranslations.footer.imprint',
              )}
            </a>
          </div>
          <Translate
            value='WasteDisposerOrderManagementPageTranslations.footer.poweredBy'
            dangerousHTML
          />
        </div>
      </div>
    </footer>
  )
}
