import { TVPContractPage } from '../components/tvp/TVPContractPage'

/**
 * @constant
 * @description A constant to define maklerPremium routes.
 */
export const tvpRoutes = [
  {
    path: '/tvp/contract/create',
    component: TVPContractPage,
    exact: true,
    // @TODO add permissions block here once step 3 has created the permission
  },
]
