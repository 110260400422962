import './style.scss'

import uniqueId from 'lodash.uniqueid'
import React, { FC, useContext } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch } from 'react-redux'

import { deleteComCenterContact } from 'actions/company'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import {
  BUTTON_BACKGROUND_COLOR,
  ProgressButton,
} from 'components/common/ProgressButton'

import { CommunicationCenterPageContext } from '../../CommunicationCenterPage'

export const DeleteComCenterContactModal: FC<{
  onCloseModal: () => void
  isOpen: boolean
}> = ({ onCloseModal, isOpen }) => {
  const idDeleteComCenterContactModal = uniqueId()

  // Redux
  const dispatch = useDispatch()

  const { selectedContact } = useContext(CommunicationCenterPageContext)

  const handleSubmit = () => {
    if (!selectedContact) {
      return
    }

    dispatch(deleteComCenterContact(selectedContact.id))
  }

  return (
    <Modal
      ariaDescribedBy={idDeleteComCenterContactModal}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <ModalHeader
        onClose={onCloseModal}
        title={I18n.t(
          'communicationCenterPageTranslations.modals.deleteEntryModal.title',
        )}
        titleId={idDeleteComCenterContactModal}
      />

      <div className='uk-modal-body communicationcenterpage-delete-entry-modal-body'>
        <p className='communicationcenterpage-delete-entry-modal-body__notice'>
          {I18n.t(
            'communicationCenterPageTranslations.modals.deleteEntryModal.notice',
          )}
        </p>
        <div className='communicationcenterpage-delete-entry-modal-body__contact'>
          <span className='communicationcenterpage-delete-entry-modal-body__category-name'>
            {selectedContact?.category}
          </span>
          <div className='communicationcenterpage-delete-entry-modal-body__contact-details'>
            <span className='communicationcenterpage-delete-entry-modal-body__contact-detail-email'>
              <a href={`mailto:${selectedContact?.email}`}>
                {selectedContact?.email}
              </a>
            </span>
            <span className='communicationcenterpage-delete-entry-modal-body__contact-detail-type'>
              {selectedContact?.type}
            </span>
            <span className='communicationcenterpage-delete-entry-modal-body__contact-detail-full-name'>
              {selectedContact?.name}
            </span>
          </div>
        </div>
        <div className='communicationcenterpage-delete-entry-modal-body__button-wrapper'>
          <ProgressButton
            className='communicationcenterpage-delete-entry-modal-body__button'
            backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
            fullWidth
            onClick={() => {
              onCloseModal()
            }}
          >
            <Translate value='general.button.cancel' />
          </ProgressButton>
          <ProgressButton
            className='communicationcenterpage-delete-entry-modal-body__button'
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            fullWidth
            isDisabled={!selectedContact}
            onClick={() => {
              handleSubmit()
              onCloseModal()
            }}
          >
            <Translate value='general.button.delete' />
          </ProgressButton>
        </div>
      </div>
    </Modal>
  )
}
