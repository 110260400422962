import React from 'react'
import { I18n } from 'react-i18nify'

export const getContainerPackagePrice = (
  id: React.ReactText | undefined,
  containerList: Container[],
) => {
  if (id) {
    const item = containerList.find(_item => `${_item.id}` === `${id}`)
    if (item) return item.is_package_price
  }
  return null
}

/**
 * @description This function gets a list of indexes from the selected coarse or fine fraction.
 * @param fractionId
 * @param fineFractionId
 * @param fractionList
 * @param indexList
 */
export const getIndexes = (
  fractionId?: React.ReactText,
  fineFractionId?: React.ReactText,
  fractionList: Fraction[] = [],
  indexList: OfferIndex[] = [],
) => {
  // add "No Index" Option
  indexList.unshift({
    id: 1,
    name: I18n.t('general.noIndex'),
    fractions: [26, 25, 19, 20, 17, 18, 16, 28],
  })

  const fineFraction = fractionList.find(
    _fineFraction => _fineFraction.id === Number(fineFractionId),
  )
  const fraction = fractionList.find(
    _fraction => _fraction.id === Number(fractionId),
  )

  /* Case a Fine Fraction is Selected */
  // In this case we can directly return the array of indexes from this fine fraction.
  // Because no fine fraction is selected, fraction become the same value as coarse fraction. In this case
  // we also have to state that, in order for this condition to run, the object in this variable should have a parent
  // Also force sorting by id and remove duplicates. Otherwise, a new "No Index" is going to be added each time we
  // change the fine fraction because its common to all fine fractions that have indexes.
  if (fineFraction && fineFraction.parent !== null) {
    return (
      indexList
        .filter(index => index.fractions.includes(fineFraction.id))
        // remove duplicates
        .filter(
          (index, i, self) => self.findIndex(_i => _i.id === index.id) === i,
        )
        // force sorting by id
        .sort((a, b) => a.id - b.id)
    )
  }

  /* Case a Fine Fraction is not selected, only a Fraction (its parent) is selected */
  return fraction
    ? fractionList
        // Build a list of all the fine fractions for this coarse fraction and remove duplicates.
        .filter(_fraction => _fraction.parent === fraction.id)
        // now, for each fine fraction, we need to get the indexes associated with it
        .map(_fraction =>
          indexList.filter(index => index.fractions.includes(_fraction.id)),
        )
        // make flat array
        .reduce((accumulator, index) => accumulator.concat(index), [])
        // remove duplicates. Otherwise, common indexes between fine fractions will be repeated.
        .filter(
          (index, i, self) => self.findIndex(_i => _i.id === index.id) === i,
        )
        // force sorting by id
        .sort((a, b) => a.id - b.id)
    : []
}
