import { Form, useFormikContext } from 'formik'
import React, { FC, useEffect, useMemo } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { USER_GENDER_CHOICES } from 'helper/user'
import { MaklerPremiumGroups, UserPermission } from 'constants/user'
import { resetApiFetchErrors } from 'actions/app'
import { getAddresses } from 'actions/address'
import { getActiveOrderedAddressesSelector } from 'selectors/address'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from '../../common/Button'
import { DropDownInput } from '../../common/DropDownInput'
import { ApiValidationMessages } from '../../common/Form/components/ApiValidationMessages'
import { InputEmail } from '../../common/InputEmail'
import { InputTelephoneNumber } from '../../common/InputTelephoneNumber'
import { InputText } from '../../common/InputText'
import { ProgressButton } from '../../common/ProgressButton'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { UniversalFileUpload } from '../../common/UniversalFileUpload'
import { MAX_LENGTH } from '../../homepages/HomepageRegister/constants'

import { UserFormValues } from './UserFormFormikWrapper'

interface UserFormInnerFormProps {
  currentUser: User
  isCurrentUser: boolean
  onCancel: React.EventHandler<any>
  selectedUser: User
  filters: Record<string, unknown>
  maklerPremiumContactPage: boolean
}

export const UserFormInnerForm: FC<UserFormInnerFormProps> = ({
  currentUser,
  isCurrentUser,
  onCancel,
  selectedUser,
  filters,
  maklerPremiumContactPage,
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldValue,
    submitCount,
    touched,
    values,
  } = useFormikContext<UserFormValues>()

  const dispatch = useDispatch()
  const { companyId } = useParams<{ companyId: string }>()

  // Although there is no effect to clean up, we can use the "useEffect" capabilities to reset the ApiErrors on
  // general cleanup. This will behave like previously using "componentWillUnmount". A sole function is returned by
  // the effect with the desired action
  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors('GET_ADDRESSES'))
    },
    [dispatch],
  )

  const addressList = useSelector(getActiveOrderedAddressesSelector)
  const addressesLoading = useSelector(createLoadingSelector(['GET_ADDRESSES']))
  const formSubmissionLoading = useSelector(
    createLoadingSelector(['INVITE_USER', 'UPDATE_USER']),
  )
  const apiValidationError = useSelector(
    createErrorSelector(['INVITE_USER', 'UPDATE_USER']),
  )

  useEffect(() => {
    dispatch(
      getAddresses(
        null,
        [
          { id: 'street', desc: false },
          { id: 'house_number', desc: false },
          { id: 'zipcode', desc: false },
          { id: 'location', desc: false },
        ],
        filters,
      ),
    )
  }, [dispatch, filters])

  const fullAddressList = useMemo(() => {
    if (currentUser.group_id in MaklerPremiumGroups && !companyId) {
      return [{ id: 'all', display_name: I18n.t('user.allLocations') }]
    }
    return [
      { id: 'all', display_name: I18n.t('user.allLocations') },
      ...addressList,
    ]
  }, [addressList, companyId, currentUser.group_id])

  return (
    <Form
      className='uk-panel invite-user-form'
      data-testid='invite-user-form'
      onSubmit={handleSubmit}
      noValidate // Disable browser validation
    >
      <div className='uk-modal-body'>
        {/* This Api Validation message is needed here to get a customized error message
         (zB. If for some reason the button is visible after action has been done, user gets the info that the order
         eta was already set) */}
        <ApiValidationMessages error={apiValidationError} />

        {/* Profile image */}
        {/* only display if this is not an invitation form */}
        {isCurrentUser && (
          <div className='invite-user-form__profile-picture'>
            <UniversalFileUpload
              name='profile_image'
              allowedFileTypes='image/png, image/jpeg'
              initialValues={[values.profile_image as Attachment]}
              showButtonBelowPreview
              largePreviews
              onChange={([file]) => {
                setFieldValue('profile_image', file)
              }}
            />
          </div>
        )}

        {/* Gender */}
        <DropDownInput
          choices={USER_GENDER_CHOICES()}
          dataTestId='user-gender-input'
          dataTestIdError='form-group-error'
          error={submitCount > 0 && touched.gender ? errors.gender : ''}
          isRequired={!values.gender}
          label={I18n.t('general.placeholder.gender')}
          name='gender'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={I18n.t('general.placeholder.gender')}
          value={values.gender}
        />

        {/* First name */}
        <InputText
          dataTestId='user-first-name-input'
          dataTestIdError='form-group-error'
          error={submitCount > 0 && touched.first_name ? errors.first_name : ''}
          isRequired={!values.first_name}
          label={I18n.t('general.placeholder.firstName')}
          maxLength={MAX_LENGTH.FIRST_NAME}
          name='first_name'
          noNumbers
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={I18n.t('general.placeholder.firstName')}
          value={values.first_name}
        />

        {/* Last name */}
        <InputText
          dataTestId='user-last-name-input'
          dataTestIdError='form-group-error'
          error={submitCount > 0 && touched.last_name ? errors.last_name : ''}
          isRequired={!values.last_name}
          label={I18n.t('general.placeholder.lastName')}
          maxLength={MAX_LENGTH.LAST_NAME}
          name='last_name'
          noNumbers
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={I18n.t('general.placeholder.lastName')}
          value={values.last_name}
        />
        {maklerPremiumContactPage && (
          <InputTelephoneNumber
            dataTestId='user-phone-number-input'
            dataTestIdError='form-group-error'
            placeholder={I18n.t('userForm.placeholder.phoneNumber')}
            label={I18n.t('userForm.placeholder.phoneNumber')}
            maxLength={MAX_LENGTH.PHONE_NUMBER}
            name='business_phone_number'
            onBlur={handleBlur}
            onChange={handleChange}
            value={
              values.business_phone_number ? values.business_phone_number : ''
            }
          />
        )}
        {/* Location / Addresses */}
        <RequiredPermissions
          requiredPermissions={[UserPermission.ADD_ADDRESS]}
          notEmpto
        >
          <DropDownInput
            className='user-preferred-address-input'
            choices={fullAddressList.map(item => ({
              optionValue: item.id,
              optionLabel: item.display_name,
            }))}
            dataTestId='user-preferred-address-input'
            dataTestIdError='form-group-error'
            error={
              submitCount > 0 && touched.preferred_address
                ? errors.preferred_address
                : ''
            }
            isRequired={!values.preferred_address}
            label={I18n.t('general.location')}
            name='preferred_address'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t('userForm.placeholder.preferredAddress')}
            value={values.preferred_address}
            standardInputStyle
            isLoading={addressesLoading}
          />
        </RequiredPermissions>
        {/* Company permission groups */}
        {!maklerPremiumContactPage && (
          <DropDownInput
            choices={currentUser.company_groups.map(item => ({
              optionValue: item.id,
              optionLabel: item.name,
            }))}
            dataTestId='group-id-input'
            dataTestIdError='form-group-error'
            error={submitCount > 0 && touched.group_id ? errors.group_id : ''}
            isRequired={!values.group_id}
            label={I18n.t('userForm.labels.groups')}
            name='group_id'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t('userForm.placeholder.groups')}
            value={values.group_id}
            standardInputStyle
          />
        )}
        {/* Email */}
        {/* email can only be added when inviting. Editing is not allowed */}
        {!selectedUser.id && !maklerPremiumContactPage && (
          <InputEmail
            dataTestId='user-email-input'
            dataTestIdError='form-group-error'
            error={submitCount > 0 && touched.email ? errors.email : ''}
            isRequired={!values.email}
            label={I18n.t('general.placeholder.email')}
            maxLength={MAX_LENGTH.MAIL_ADDRESS}
            name='email'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t('general.placeholder.email')}
            value={values.email}
          />
        )}
        {maklerPremiumContactPage && (
          <InputEmail
            dataTestId='user-email-input'
            dataTestIdError='form-group-error'
            error={submitCount > 0 && touched.email ? errors.email : ''}
            label={I18n.t('general.placeholder.email')}
            maxLength={MAX_LENGTH.MAIL_ADDRESS}
            name='email'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t('general.placeholder.email')}
            value={
              values.email.endsWith('@maklerpremium.example.com')
                ? ''
                : values.email || ''
            }
          />
        )}
      </div>

      <div className='uk-modal-footer uk-text-right'>
        <span className='uk-margin-right'>
          <Button
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            onClick={onCancel}
          >
            <Translate value='general.button.cancel' />
          </Button>
        </span>

        <ProgressButton
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          dataTestId='invite-user-form-submit'
          isDisabled={
            (submitCount > 0 && !isValid) ||
            (isSubmitting && formSubmissionLoading)
          }
          isLoading={isSubmitting && formSubmissionLoading}
          onClick={() => undefined}
          type={BUTTON_TYPE.SUBMIT}
        >
          <Translate
            value={`general.button.${selectedUser.id ? 'save' : 'submit'}`}
          />
        </ProgressButton>
      </div>
    </Form>
  )
}
