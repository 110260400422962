import React from 'react'
import { Translate } from 'react-i18nify'

export type BenefitComparisonEntry = {
  key: string
  title: JSX.Element
  empto: boolean
  external: boolean
}

export const BenefitComparisonContent: BenefitComparisonEntry[] = [
  {
    key: 'automaticReview',
    title: <Translate value='comparisonTable.contents.automaticReview' />,
    external: false,
    empto: true,
  },
  {
    key: 'companyRating',
    title: <Translate value='comparisonTable.contents.companyRating' />,
    external: false,
    empto: true,
  },
  {
    key: 'priceComparison',
    title: <Translate value='comparisonTable.contents.priceComparison' />,
    external: false,
    empto: true,
  },
  {
    key: 'digitalAdministration',
    title: <Translate value='comparisonTable.contents.digitalAdministration' />,
    external: true,
    empto: true,
  },
  {
    key: 'digitalAssignment',
    title: <Translate value='comparisonTable.contents.digitalAssignment' />,
    external: true,
    empto: true,
  },
  {
    key: 'digitalManagement',
    title: <Translate value='comparisonTable.contents.digitalManagement' />,
    external: false,
    empto: true,
  },
  {
    key: 'disposalGuarantee',
    title: <Translate value='comparisonTable.contents.disposalGuarantee' />,
    external: false,
    empto: true,
  },
  {
    key: 'digitalArchive',
    title: <Translate value='comparisonTable.contents.digitalArchive' />,
    external: false,
    empto: true,
  },
  {
    key: 'automaticDocumentation',
    title: (
      <Translate value='comparisonTable.contents.automaticDocumentation' />
    ),
    external: false,
    empto: true,
  },
  {
    key: 'automaticInvoicing',
    title: <Translate value='comparisonTable.contents.automaticInvoicing' />,
    external: false,
    empto: true,
  },
]
