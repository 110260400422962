import React, { FC } from 'react'
import { Link } from 'react-router-dom'

export interface BreadcrumbNavigationProps {
  breadcrumbTitle: React.ReactNode
  prevLinkTitle: string
  prevLinkTo: string
}

export const BreadcrumbNavigation: FC<BreadcrumbNavigationProps> = ({
  prevLinkTo,
  prevLinkTitle,
  breadcrumbTitle,
}) => (
  <ul className='uk-breadcrumb'>
    <li>
      <Link to={prevLinkTo} key={prevLinkTo}>
        {prevLinkTitle}
      </Link>
    </li>

    <li>{breadcrumbTitle}</li>
  </ul>
)
