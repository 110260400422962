const containerApiTranslations = {
  handleGetContainer: {
    error: 'Behälter konnten leider nicht geladen werden.',
  },
  handleGetContainerContents: {
    error: 'Behälterinhalt konnten leider nicht geladen werden.',
  },
}

export default containerApiTranslations
