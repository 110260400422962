import React from 'react'

import ActionTypes from '../actiontypes/core'

/**
 * @function
 * @name getInstruction
 * @description Loads a list of instructions
 * @return {Object}
 */
export const getInstructions = (filters?: {
  [key: string]: React.ReactText
}) => ({
  type: ActionTypes.GET_INSTRUCTIONS_REQUEST,
  payload: {
    filters,
  },
})
