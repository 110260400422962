import React from 'react'
import { I18n } from 'react-i18nify'

import {
  ActionCard,
  ActionCardGrid,
  ActionCardIcon,
} from '../../components/ActionCard'
import {
  ClassificationGrid,
  ClassificationTile,
} from '../../components/Classification'
import { DashboardTileIcon } from '../../components/DashboardTileIcon/DashboardTileIcon'
import { getMetricsCaptionSingularOrPlural } from '../../helpers'

type DszDashboardPropsType = {
  dashboardApiResponse: Dashboard // eslint-disable-line react/no-unused-prop-types
  mapsComponent: () => JSX.Element
}

export const DSZDashboard = (props: DszDashboardPropsType) => {
  // TODO: Adjust these values once we know what the DSZ Dashboard should display
  const {
    contracts_today_last_week_count: contractsTodayLastWeekCount,
    contracts_today_count: contractsTodayCount,
    // orders_today_last_week_count: ordersTodayLastWeekCount,
    // orders_today_count: ordersTodayCount,
    // offer_pipeline_count: offerPipelineCount,
    sales_today: salesToday,
    // workload,
  } = props.dashboardApiResponse

  const ordersTodayCount = 0
  const ordersTodayLastWeekCount = 0
  const workload = 0

  const { mapsComponent } = props

  return (
    <>
      <ClassificationGrid>
        {/* DSZ 1. ROW */}
        <ClassificationTile
          buttonText={I18n.t('dashboard.MP.DSZ.contractDaily.buttonText')}
          buttonTarget='/'
          caption={I18n.t(
            `dashboard.MP.DSZ.contractDaily.caption.${getMetricsCaptionSingularOrPlural(
              contractsTodayCount,
            )}`,
          )}
          value={contractsTodayCount}
          offsetValue={contractsTodayLastWeekCount}
          icon={DashboardTileIcon.Document}
        />
        <ClassificationTile
          buttonText={I18n.t('dashboard.MP.EPD.ordersTodayCount.buttonText')}
          buttonTarget='/order'
          caption={I18n.t(
            `dashboard.MP.EPD.ordersTodayCount.caption.${getMetricsCaptionSingularOrPlural(
              ordersTodayCount,
            )}`,
          )}
          value={ordersTodayCount}
          offsetValue={ordersTodayLastWeekCount}
          icon={DashboardTileIcon.Truck}
        />
        <ClassificationTile
          buttonText={I18n.t('dashboard.MP.EPD.salesToday.buttonText')}
          buttonTarget='/maklerpremium/bookkeeping'
          caption={I18n.t(
            `dashboard.MP.EPD.salesToday.caption.${getMetricsCaptionSingularOrPlural(
              salesToday,
            )}`,
          )}
          value={Number(salesToday).toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          icon={DashboardTileIcon.MoneyGradient}
        />
        <ClassificationTile
          buttonText={I18n.t('dashboard.action.buttonText')}
          buttonTarget='/maklerpremium/workload'
          caption={I18n.t(
            `dashboard.action.caption.${getMetricsCaptionSingularOrPlural(
              workload,
            )}`,
          )}
          value={workload}
          icon={DashboardTileIcon.Checklist}
        />
      </ClassificationGrid>

      {/* DSZ 2. ROW */}
      <section className='dashboard__contents'>
        {/* MAPS */}
        {mapsComponent()}

        {/* ACTION CARDS */}
        <div className='dashboard__contents__card-list'>
          <ActionCardGrid>
            <ActionCard
              buttonText={I18n.t('dashboard.MP.customerManage.buttonText')}
              buttonTarget='/maklerpremium/customer'
              value={I18n.t('dashboard.MP.customerManage.value')}
              caption={I18n.t('dashboard.MP.customerManage.caption')}
              icon={ActionCardIcon.WASTE_PRODUCER}
            />
            <ActionCard
              buttonText={I18n.t('dashboard.MP.DSZ.priceManagement.buttonText')}
              buttonTarget='/dsz/prices'
              value={I18n.t('dashboard.MP.DSZ.priceManagement.value')}
              caption={I18n.t('dashboard.MP.DSZ.priceManagement.caption')}
              icon={ActionCardIcon.PRICE_MANAGEMENT}
            />
          </ActionCardGrid>
        </div>
      </section>
    </>
  )
}
