import './style.scss'

import classNames from 'classnames'
import { shape } from 'prop-types'
import React from 'react'

import { AttachmentScheme } from 'components/common/fileUpload/schemes'
import Icon from 'components/common/Fontello/index'

const UserImg = ({ profile_image_object: profileImageObject }) => (
  <div className='user-portrait uk-display-inline-block'>
    {/* If no image is available show this fallback instead */}
    {!profileImageObject ? (
      <div className='no-portrait-available-icon'>
        <Icon className='user-portrait__icon' name='user' />
      </div>
    ) : (
      <img
        src={profileImageObject.thumbnail}
        className={classNames('single-img uk-border-rounded profile-image')}
        alt={profileImageObject.name}
      />
    )}
  </div>
)

UserImg.propTypes = {
  profile_image_object: shape(AttachmentScheme),
}

UserImg.defaultProps = {
  profile_image_object: null,
}

export default UserImg
