import ActionTypes from '../actiontypes/app'

export const requestStatusReducer = (state = {}, action) => {
  const { type } = action
  const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type)

  if (type === ActionTypes.RESET_API_FETCH_LOADING) {
    return {
      ...state,
      [action.payload]: null,
    }
  }

  // not a *_REQUEST / *_SUCCESS / *_ERROR actions, so we ignore them
  if (!matches) return state

  const [, requestName, requestState] = matches
  return {
    ...state,
    // Store state of a Request (Error, success, loading)
    [requestName]: requestState === 'REQUEST' ? 'LOADING' : requestState,
  }
}
