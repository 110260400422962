import './style.scss'

import { Form, useFormikContext } from 'formik'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { createLoadingSelector } from '../../selectors/loading'
import { BUTTON_BACKGROUND_COLOR } from '../common/Button'
import { InputText } from '../common/InputText'
import { ProgressButton } from '../common/ProgressButton'
import { RadioButton } from '../common/RadioButton'

import { AddWasteDisposerFormValues } from './AddWasteDisposerWrapper'
import { TaxSectionFields } from './TaxSectionFields'

export const AddWasteDisposerInnerForm: FC = () => {
  const isLoading = useSelector(createLoadingSelector(['CREATE_PARTNER']))

  const {
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
    setFieldValue,
    setValues,
    submitCount,
    values,
  } = useFormikContext<AddWasteDisposerFormValues>()

  // Adds a space after every 4 spaces on input of IBAN
  const handleInputIban = e => {
    // Solution 2 from https://stackoverflow.com/a/29320829
    const { target } = e
    let position = target.selectionEnd
    const { length } = target.value

    target.value = target.value
      .replace(/[^\d\p{Uppercase_Letter}]/gu, '')
      .replace(/(.{4})/g, '$1 ')
      .trim()
    // eslint-disable-next-line no-multi-assign
    target.selectionEnd = position +=
      target.value.charAt(position - 1) === ' ' &&
      target.value.charAt(length - 1) === ' ' &&
      length !== target.value.length
        ? 1
        : 0
  }

  const isFieldValid = name => values[name].length > 0 && !errors[name]
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0
      ? (errors[name] as string)
      : undefined

  return (
    <>
      <div className='uk-modal-body'>
        <Form
          className='uk-panel makler-premium-form'
          onSubmit={handleSubmit}
          noValidate // Disable browser validation
        >
          <div className='uk-width-1-1 uk-margin-bottom'>
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteDisposerModal.form.company_name' />
              }
              name='company_name'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteDisposerModal.form.company_name',
              )}
              value={values.company_name}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteDisposerModal.form.street' />
              }
              name='street'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteDisposerModal.form.street',
              )}
              value={values.street}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteDisposerModal.form.house_number' />
              }
              name='house_number'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteDisposerModal.form.house_number',
              )}
              value={values.house_number}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteDisposerModal.form.postal_code' />
              }
              name='postal_code'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteDisposerModal.form.postal_code',
              )}
              value={values.postal_code}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteDisposerModal.form.city' />
              }
              name='city'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteDisposerModal.form.city',
              )}
              value={values.city}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            <div className='tax-section'>
              <TaxSectionFields />
            </div>
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteDisposerModal.form.iban' />
              }
              name='iban'
              onBlur={handleBlur}
              onChange={handleChange}
              onInput={handleInputIban}
              placeholder={I18n.t(
                'maklerpremium.addWasteDisposerModal.form.iban',
              )}
              value={values.iban}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteDisposerModal.form.bic' />
              }
              name='bic'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteDisposerModal.form.bic',
              )}
              value={values.bic}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            {/* eslint-disable jsx-a11y/label-has-associated-control */}
            {/* eslint-disable jsx-a11y/label-has-for */}
            <label className='read-only-field__label read-only-field__label-margin-top'>
              {I18n.t(
                'maklerpremium.addWasteDisposerModal.form.has_central_contact',
              )}
            </label>
            <div className='uk-grid uk-child-width-1-1 uk-child-width-1-2@m'>
              <RadioButton
                isChecked={values.has_central_contact}
                label={I18n.t('general.yes')}
                name='has_central_contact'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    setValues({
                      ...values,
                      has_central_contact: true,
                    })
                  }
                }}
              />
              <RadioButton
                isChecked={!values.has_central_contact}
                label={I18n.t('general.no')}
                name='has_central_contact'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    setValues({
                      ...values,
                      has_central_contact: false,
                    })
                  }
                }}
              />
            </div>
            {values.has_central_contact && (
              <>
                <InputText
                  error={getFieldError}
                  label={
                    <Translate value='maklerpremium.addWasteDisposerModal.form.central_contact_email' />
                  }
                  name='central_contact_email'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t(
                    'maklerpremium.addWasteDisposerModal.form.central_contact_email',
                  )}
                  value={values.central_contact_email}
                  showCheckmark={isFieldValid}
                  withCheckmark
                />
                <InputText
                  error={getFieldError}
                  label={
                    <Translate value='maklerpremium.addWasteDisposerModal.form.central_contact_phone' />
                  }
                  name='central_contact_phone'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t(
                    'maklerpremium.addWasteDisposerModal.form.central_contact_phone',
                  )}
                  value={values.central_contact_phone}
                  showCheckmark={isFieldValid}
                  withCheckmark
                />
              </>
            )}
            <p className='form-section-headline'>
              <Translate value='maklerpremium.addWasteDisposerModal.form.contact_person' />
            </p>
            <label className='read-only-field__label'>
              {I18n.t('maklerpremium.addWasteDisposerModal.form.salutation')}
            </label>
            <div className='uk-grid uk-child-width-1-1 uk-child-width-1-2@m'>
              <RadioButton
                isChecked={values.contact_person_is_male}
                label={I18n.t('constant.gender.mr')}
                name='contact_person_is_male'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    setFieldValue('contact_person_is_male', true)
                  }
                }}
              />
              <RadioButton
                isChecked={!values.contact_person_is_male}
                label={I18n.t('constant.gender.mrs')}
                name='contact_person_is_male'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    setFieldValue('contact_person_is_male', false)
                  }
                }}
              />
            </div>
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteDisposerModal.form.contact_person_first_name' />
              }
              name='contact_person_first_name'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteDisposerModal.form.contact_person_first_name',
              )}
              value={values.contact_person_first_name}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteDisposerModal.form.contact_person_last_name' />
              }
              name='contact_person_last_name'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteDisposerModal.form.contact_person_last_name',
              )}
              value={values.contact_person_last_name}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteDisposerModal.form.contact_person_position' />
              }
              name='contact_person_position'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteDisposerModal.form.contact_person_position',
              )}
              value={values.contact_person_position}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteDisposerModal.form.contact_person_phone' />
              }
              name='contact_person_phone'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteDisposerModal.form.contact_person_phone',
              )}
              value={values.contact_person_phone}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteDisposerModal.form.contact_person_email' />
              }
              name='contact_person_email'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteDisposerModal.form.contact_person_email',
              )}
              value={values.contact_person_email}
              showCheckmark={isFieldValid}
              withCheckmark
            />
          </div>
          <div className='uk-text-right'>
            <ProgressButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              isDisabled={
                (submitCount > 0 && !isValid) || isSubmitting || isLoading
              }
              isLoading={isSubmitting || isLoading}
              onClick={handleSubmit}
            >
              <Translate value='general.button.save' />
            </ProgressButton>
          </div>
        </Form>
      </div>
    </>
  )
}
