import './style.scss'

import classNames from 'classnames'
import { bool, shape, string } from 'prop-types'
import React from 'react'
import { Translate } from 'react-i18nify'

import { OfferFormScheme } from 'schemes/offer'

import { getErrorList } from './helpers'

/**
 * @description This component renders and controls the form submit error message. This can be a single generic message
 * stating there are fields missing, or list of the current invalid fields the user must check.
 * @param {Object} props JSX attributes to this component as a single object.
 * @return {*}
 */
const FormSubmitErrorMessage = ({
  className,
  form,
  onDemand,
  showErrorList,
  text,
}) =>
  !form.$form.valid &&
  form.$form.submitFailed && (
    <div
      className={classNames('submit-error-message', className)}
      data-testid='submit-error-message'
    >
      {/* if we want the show the error list by passing showErrorListProp */}
      {showErrorList && (
        <>
          <div className='submit-error-message__title'>
            <Translate value='message.validation.formFields.fillThese' />
          </div>
          <ul className='submit-error-message__list'>
            {getErrorList(form).map(error => (
              <li className='submit-error-message__list__item' key={error}>
                {/* The translation must be different for the same variable name in case the order type is ON_DEMAND
                OR BY TURN */}
                {onDemand &&
                (`${error}` === 'turn_end' || `${error}` === 'turn_begin') ? (
                  <Translate
                    value={`message.validation.formFields.${error}_agreement`}
                  />
                ) : (
                  <Translate value={`message.validation.formFields.${error}`} />
                )}
              </li>
            ))}
          </ul>
        </>
      )}

      {/* if we just want to show a generic error message */}
      {!showErrorList && (
        <div className='submit-error-message__title'>
          <Translate value={text} />
        </div>
      )}
    </div>
  )

FormSubmitErrorMessage.propTypes = {
  className: string,
  form: shape(OfferFormScheme).isRequired,
  onDemand: bool,
  showErrorList: bool,
  text: string,
}

FormSubmitErrorMessage.defaultProps = {
  className: '',
  onDemand: false,
  showErrorList: false,
  text: 'general.form.checkInputs',
}

export default FormSubmitErrorMessage
