import keyMirror from 'keymirror'

const CREATE_OFFER = keyMirror({
  CREATE_OFFER_REQUEST: null,
  CREATE_OFFER_SUCCESS: null,
  CREATE_OFFER_ERROR: null,
})

const GET_OFFERS = keyMirror({
  GET_OFFERS_REQUEST: null,
  GET_OFFERS_SUCCESS: null,
  GET_OFFERS_ERROR: null,
})

const GET_MY_OFFERS = keyMirror({
  GET_MY_OFFERS_REQUEST: null,
  GET_MY_OFFERS_SUCCESS: null,
  GET_MY_OFFERS_ERROR: null,
  GET_MY_OFFERS_RESET: null,
})

const GET_OFFER = keyMirror({
  GET_OFFER_REQUEST: null,
  GET_OFFER_SUCCESS: null,
  GET_OFFER_ERROR: null,
  GET_OFFER_RESET: null,
})

const CANCEL_OFFER = keyMirror({
  CANCEL_OFFER_REQUEST: null,
  CANCEL_OFFER_SUCCESS: null,
  CANCEL_OFFER_ERROR: null,
})

// Send offer email again - #2052
const RESEND_OFFER = keyMirror({
  RESEND_OFFER_REQUEST: null,
  RESEND_OFFER_SUCCESS: null,
  RESEND_OFFER_ERROR: null,
})

const ACCEPT_OFFER = keyMirror({
  ACCEPT_OFFER_REQUEST: null,
  ACCEPT_OFFER_SUCCESS: null,
  ACCEPT_OFFER_ERROR: null,
})

const POSTPONE_INTERVAL_OFFER = keyMirror({
  POSTPONE_INTERVAL_OFFER_REQUEST: null,
  POSTPONE_INTERVAL_OFFER_SUCCESS: null,
  POSTPONE_INTERVAL_OFFER_ERROR: null,
})

const CHANGE_INTERVAL_OFFER = keyMirror({
  CHANGE_INTERVAL_OFFER_REQUEST: null,
  CHANGE_INTERVAL_OFFER_SUCCESS: null,
  CHANGE_INTERVAL_OFFER_ERROR: null,
})

const EXPORT_MY_OFFERS = keyMirror({
  EXPORT_MY_OFFERS_REQUEST: null,
  EXPORT_MY_OFFERS_SUCCESS: null,
})

const GET_ACCEPTED_OFFERS = keyMirror({
  GET_ACCEPTED_OFFERS_REQUEST: null,
  GET_ACCEPTED_OFFERS_SUCCESS: null,
  GET_ACCEPTED_OFFERS_ERROR: null,
  GET_ACCEPTED_OFFERS_RESET: null,
})

const GET_MAKLER_PREMIUM_OFFER_TOTALS = keyMirror({
  GET_MAKLER_PREMIUM_OFFER_TOTALS_REQUEST: null,
  GET_MAKLER_PREMIUM_OFFER_TOTALS_SUCCESS: null,
  GET_MAKLER_PREMIUM_OFFER_TOTALS_ERROR: null,
})

const EXPORT_ACCEPTED_OFFERS = keyMirror({
  EXPORT_ACCEPTED_OFFERS_REQUEST: null,
  EXPORT_ACCEPTED_OFFERS_SUCCESS: null,
})

const CHANGE_DISPOSER_OFFER = keyMirror({
  CHANGE_DISPOSER_OFFER_REQUEST: null,
  CHANGE_DISPOSER_OFFER_SUCCESS: null,
  CHANGE_DISPOSER_OFFER_ERROR: null,
})

const CHANGE_AGREEMENT_PERIOD_FOR_STANDING_OFFERS = keyMirror({
  CHANGE_AGREEMENT_PERIOD_FOR_STANDING_OFFERS_REQUEST: null,
  CHANGE_AGREEMENT_PERIOD_FOR_STANDING_OFFERS_SUCCESS: null,
  CHANGE_AGREEMENT_PERIOD_FOR_STANDING_OFFERS_ERROR: null,
})

const AUTO_CHANGE_DISPOSER = keyMirror({
  AUTO_CHANGE_DISPOSER_REQUEST: null,
  AUTO_CHANGE_DISPOSER_SUCCESS: null,
  AUTO_CHANGE_DISPOSER_ERROR: null,
})

const EXPORT_RENT = keyMirror({
  EXPORT_RENT_REQUEST: null,
  EXPORT_RENT_SUCCESS: null,
  EXPORT_RENT_ERROR: null,
})

const UPDATE_REVIEWED_OFFER = keyMirror({
  UPDATE_REVIEWED_OFFER_REQUEST: null,
  UPDATE_REVIEWED_OFFER_SUCCESS: null,
  UPDATE_REVIEWED_OFFER_ERROR: null,
})

export default {
  ...AUTO_CHANGE_DISPOSER,
  ...CREATE_OFFER,
  ...GET_OFFERS,
  ...GET_MY_OFFERS,
  ...ACCEPT_OFFER,
  ...CANCEL_OFFER,
  ...RESEND_OFFER,
  ...GET_OFFER,
  ...EXPORT_MY_OFFERS,
  ...GET_ACCEPTED_OFFERS,
  ...GET_MAKLER_PREMIUM_OFFER_TOTALS,
  ...EXPORT_ACCEPTED_OFFERS,
  ...POSTPONE_INTERVAL_OFFER,
  ...CHANGE_INTERVAL_OFFER,
  ...CHANGE_DISPOSER_OFFER,
  ...CHANGE_AGREEMENT_PERIOD_FOR_STANDING_OFFERS,
  ...EXPORT_RENT,
  ...UPDATE_REVIEWED_OFFER,
}
