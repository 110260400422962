import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getEmptoInternalDownloads } from 'actions/emptointernaldownloads'
import { getEmptoInternalDownloadsSelector } from 'selectors/emptointernaldownloads'

export const EmptoInternalDownloads: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getEmptoInternalDownloads())
  }, [dispatch])

  const emptoInternalDownloadsList = useSelector(
    getEmptoInternalDownloadsSelector,
  )

  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.downloads')}</title>
        <meta
          name='Description'
          content={I18n.t('pageDescriptions.downloads')}
        />
      </Helmet>

      <article className='uk-article uk-margin-top'>
        <h3>{I18n.t('emptoInternalDownloadsTranslations.pageTitle')}</h3>
        <div className='uk-margin-left'>
          {emptoInternalDownloadsList.map(document => (
            <p key={document.id}>
              <Link
                to={{ pathname: document.file_link }}
                target='_blank'
                rel='noopener noreferrer'
              >
                {document.description}
              </Link>
            </p>
          ))}
        </div>
      </article>
    </>
  )
}
