import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'
import { Link } from 'react-router-dom'

import Icon, { ICON_NAME } from '../../common/Fontello'
import { IconButton } from '../../common/IconButton'

import { SLIDER_PAGINATOR_TYPES } from './constants'

interface SliderPaginatorProps {
  maxSlides: number
  currentSlide: number
  setPageCallback: (counterValue: number) => void
  mode: number
}

/**
 * Displays a paginator with buttons to switch between them
 */
export const SliderPaginator: FC<SliderPaginatorProps> = ({
  maxSlides,
  currentSlide,
  setPageCallback,
  mode,
}) => {
  const counterNext = () => {
    const newCounterValue = currentSlide + 1

    if (newCounterValue < maxSlides) setPageCallback(newCounterValue)
    if (newCounterValue === maxSlides) setPageCallback(0)
  }

  const counterPrev = () => {
    const newCounterValue = currentSlide - 1

    if (newCounterValue >= 0) setPageCallback(newCounterValue)
    if (newCounterValue < 0) setPageCallback(maxSlides - 1)
  }

  return (
    <div
      className={classNames(
        'slider-paginator',
        mode === SLIDER_PAGINATOR_TYPES.WITH_NUMBERS
          ? 'slider-paginator__with-numbers'
          : 'slider-paginator__with-dots',
      )}
    >
      {mode === SLIDER_PAGINATOR_TYPES.WITH_NUMBERS ? (
        <IconButton
          className='slider-paginator__button'
          onClick={() => counterPrev()}
          iconName={ICON_NAME.LEFT_OPEN}
          isDisabled={maxSlides === 1}
        />
      ) : (
        <Link
          to='/'
          className='uk-slidenav uk-slidenav-previous'
          data-uk-slider-item='previous'
        >
          <Icon name={ICON_NAME.LEFT_OPEN} />
        </Link>
      )}
      {mode === SLIDER_PAGINATOR_TYPES.WITH_NUMBERS ? (
        <span className='slider-paginator__current-position'>
          {currentSlide + 1}
          {I18n.t('cardListSlider.pagination.paginationText')}
          {maxSlides}
        </span>
      ) : (
        <ul className='uk-slider-nav uk-dotnav uk-flex-center uk-margin' />
      )}

      {mode === SLIDER_PAGINATOR_TYPES.WITH_NUMBERS ? (
        <IconButton
          className='slider-paginator__button uk-slidenav-next uk-slidenav'
          onClick={() => counterNext()}
          iconName={ICON_NAME.RIGHT_OPEN}
          isDisabled={maxSlides === 1}
        />
      ) : (
        <Link
          to='/'
          className='uk-slidenav uk-slidenav-next'
          data-uk-slider-item='next'
        >
          <Icon name={ICON_NAME.RIGHT_OPEN} />
        </Link>
      )}
    </div>
  )
}
