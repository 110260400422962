import './style.scss'

import classNames from 'classnames'
import { arrayOf, bool, node, oneOfType, string } from 'prop-types'
import React, { Component } from 'react'

import IconLabel from 'components/common/IconLabel'

/**
 * @description This component renders the detail section component.
 */
export class DetailSectionComponent extends Component {
  render() {
    return (
      <div
        className={classNames(
          { 'uk-accordion-title': this.props.showAccordion },
          'detail-section',
          'uk-margin-small-bottom',
          this.props.className,
        )}
      >
        {this.props.title && (
          <IconLabel
            className='title'
            icon={this.props.icon}
            title={this.props.title}
          />
        )}

        <div
          className={[
            'detail-rows uk-grid uk-child-width-1-1 uk-grid-small uk-flex-left',
            `${!this.props.fullWidth ? 'uk-child-width-1-2@s' : ''}`,
          ].join(' ')}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

DetailSectionComponent.propTypes = {
  title: string,
  icon: string,
  className: string,
  showAccordion: bool,
  fullWidth: bool,
  children: oneOfType([arrayOf(node), node]),
}

DetailSectionComponent.defaultProps = {
  title: null,
  icon: null,
  className: '',
  showAccordion: false,
  fullWidth: false,
  children: [],
}

export default DetailSectionComponent
