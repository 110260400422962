import './style.scss'

import { Formik } from 'formik'
import React, { createContext, FC, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { resetApiFetchErrors, resetApiFetchLoading } from 'actions/app'
import { addComCenterContact, updateComCenterContact } from 'actions/company'
import { getCompanySelector } from 'selectors/company'
import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'

import {
  COM_CENTER_ACTION_TYPES,
  INITIAL_VALUES,
  WASTE_CATEGORY_CHOICES,
} from '../../constants'
import { ComCenterContactContextType } from '../../types'

import { BaseForm } from './components/BaseForm'
import { validationSchema } from './validationSchema'

export const ComCenterContactFormContext =
  createContext<ComCenterContactContextType>({
    onCloseModal: () => undefined,
    isSubmitting: false,
    comCenterContact: undefined,
  })

/**
 * @description This component displays the main modal form of the communication center page
 */
export const ComCenterContactForm: FC<{
  onCloseModal: () => void
  comCenterContact?: ComCenterContact
  category?: number
}> = ({ onCloseModal, comCenterContact, category }) => {
  const action = useMemo(() => {
    return comCenterContact
      ? COM_CENTER_ACTION_TYPES.UPDATE
      : COM_CENTER_ACTION_TYPES.ADD
  }, [comCenterContact])

  const status = useSelector(createRequestStatusSelector([action]))
  const company = useSelector(getCompanySelector)
  const dispatch = useDispatch()

  //State
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors(action))
    },
    [dispatch, action],
  )

  useEffect(() => {
    setIsSubmitting(true)
    if (status === REQUEST_STATUS.SUCCESS) {
      dispatch(resetApiFetchLoading(action))
      setIsSubmitting(false)
    }
  }, [dispatch, action, status, company])

  const handleOnSubmit = async (values, callback) => {
    let action = addComCenterContact(values)
    if (comCenterContact && comCenterContact.id) {
      action = updateComCenterContact(values)
    }
    await dispatch(action)
    callback()
  }

  const initialValues = {
    id: comCenterContact?.id ?? undefined,
    company: comCenterContact?.company ?? company.id,
    // Select category from correct source
    // comCenterContact category > modal button category > empty category
    category: comCenterContact?.category
      ? String(comCenterContact?.category)
      : category
      ? String(category)
      : INITIAL_VALUES.category,
    email: comCenterContact?.email ?? INITIAL_VALUES.email,
    type: comCenterContact?.type
      ? String(comCenterContact?.type)
      : INITIAL_VALUES.type,
    fraction: comCenterContact?.fraction
      ? String(comCenterContact?.fraction)
      : INITIAL_VALUES.fraction,
    last_name: comCenterContact?.last_name ?? INITIAL_VALUES.last_name,
    first_name: comCenterContact?.first_name ?? INITIAL_VALUES.first_name,
    stage: comCenterContact?.stage
      ? String(comCenterContact?.stage)
      : INITIAL_VALUES.stage,
    waste_category: comCenterContact?.fraction
      ? String(WASTE_CATEGORY_CHOICES.CHOICE_SPECIFIC)
      : INITIAL_VALUES.waste_category,
    communities: comCenterContact?.communities ?? INITIAL_VALUES.communities,
    zipcodes: comCenterContact?.zipcodes ?? INITIAL_VALUES.zipcodes,
    customer: comCenterContact?.customer ?? INITIAL_VALUES.customer,
    container_type:
      comCenterContact?.container_type ?? INITIAL_VALUES.container_type,
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { resetForm, validateForm }) => {
        return handleOnSubmit(values, async () => {
          await resetForm()
          await validateForm()
        })
      }}
      validationSchema={validationSchema}
      validateOnMount
    >
      <ComCenterContactFormContext.Provider
        value={{
          onCloseModal,
          isSubmitting,
          comCenterContact,
        }}
      >
        <BaseForm />
      </ComCenterContactFormContext.Provider>
    </Formik>
  )
}
