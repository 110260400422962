import './style.scss'

import { string } from 'prop-types'
import React from 'react'

import Icon from '../../Fontello'

const IndicationIconComponent = ({ statusClass, statusIconName }) => (
  <div className='indication-icon-container indication-icon'>
    {statusClass && (
      <span className={`indication-status-icon-container ${statusClass}`}>
        {statusIconName && (
          <Icon
            className='indication-icon__status-icon'
            name={statusIconName}
          />
        )}
      </span>
    )}
  </div>
)

IndicationIconComponent.propTypes = {
  statusClass: string,
  statusIconName: string,
}

IndicationIconComponent.defaultProps = {
  statusClass: null,
  statusIconName: null,
}

export default IndicationIconComponent
