import { createLogic } from 'redux-logic'
import { toastr } from 'react-redux-toastr'
import { I18n } from 'react-i18nify'

import ActionTypes from '../actiontypes/address'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetAddresses
 * @description gets list of collection addresses.
 */
export const handleGetAddresses = createLogic({
  type: ActionTypes.GET_ADDRESSES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload) {
      data = { page: action.payload.page || 1 }

      if (action.payload.sortedBy && action.payload.sortedBy.length) {
        data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
      }

      if (action.payload.filters) {
        const filters = { ...action.payload.filters }
        data = { ...filters, ...data }
      }
    }
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/address/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ADDRESSES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.address.handleGetAddresses.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_ADDRESSES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetActiveAddresses
 * @description gets list of collection addresses with active zip code.
 */
export const handleGetActiveAddresses = createLogic({
  type: ActionTypes.GET_ACTIVE_ADDRESSES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/address/active/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ACTIVE_ADDRESSES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.address.handleGetAddresses.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_ACTIVE_ADDRESSES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetWastemanagerAddresses
 * @description gets list of collection addresses with active and inactive zip code.
 */
export const handleGetWastemanagerAddresses = createLogic({
  type: ActionTypes.GET_WASTEMANAGER_ADDRESSES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const newPayload = {
      ...action.payload,
      company: action.payload.company === 'empto' ? '' : action.payload.company,
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/address/wastemanager/`,
        newPayload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_WASTEMANAGER_ADDRESSES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.address.handleGetAddresses.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_WASTEMANAGER_ADDRESSES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetAddressesForCompany
 * @description gets list of collection addresses for a company.
 */
export const handleGetAddressesForCompany = createLogic({
  type: ActionTypes.GET_ADDRESSES_FOR_COMPANY_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}
    let companyId

    if (action.payload) {
      if (action.payload.filters) {
        data = { ...action.payload.filters }
      }
      if (action.payload.companyId) {
        companyId = action.payload.companyId
      }
    }

    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/address/company/${companyId}/`
    httpClient
      .get(url, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ADDRESSES_FOR_COMPANY_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.address.handleGetAddressesForCompany.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_ADDRESSES_FOR_COMPANY_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleAddAddress
 * @description saves collection address.
 */
export const handleAddAddress = createLogic({
  type: ActionTypes.ADD_ADDRESS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/address/`,
        action.payload.address,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.ADD_ADDRESS_SUCCESS,
          payload: res,
        })

        if (action.payload.callbacks && action.payload.callbacks.success)
          action.payload.callbacks.success(res)

        toastr.success('', I18n.t('api.address.handleAddAddress.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.address.handleAddAddress.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.ADD_ADDRESS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateAddress
 * @description updates a address.
 */
export const handleUpdateAddress = createLogic({
  type: ActionTypes.UPDATE_ADDRESS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/address/${action.payload.address.id}/`
    httpClient
      .patch(url, action.payload.address)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.UPDATE_ADDRESS_SUCCESS,
          payload: res,
        })

        if (action.payload.callbacks && action.payload.callbacks.success)
          action.payload.callbacks.success()

        // show a different message in the toaster, depending if we are just activating/deactivating the address or
        // updating the whole object
        // In case of activation / deactivation, we are just sending an object with 2 keys (id and status)
        if (Object.keys(action.payload.address).length === 2) {
          toastr.success(
            '',
            I18n.t('api.address.handleUpdateStatusAddress.activation.success'),
          )
        } else {
          toastr.success('', I18n.t('api.address.handleUpdateAddress.success'))
        }
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.address.handleUpdateAddress.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.UPDATE_ADDRESS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleBlockAddress
 * @description block a address.
 */
export const handleBlockAddress = createLogic({
  type: ActionTypes.BLOCK_ADDRESS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/address/${action.payload}/block/`
    httpClient
      .patch(url)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.BLOCK_ADDRESS_SUCCESS,
          payload: res,
        })

        toastr.success('', I18n.t('api.address.handleBlockAddress.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.address.handleBlockAddress.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.BLOCK_ADDRESS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetMyAgreementInquiredCollectionAddresses
 * @description get address from my agreements (To use in Agreements Collection address filter).
 */
export const handleGetMyAgreementInquiredCollectionAddresses = createLogic({
  type: ActionTypes.GET_MY_AGREEMENT_INQUIRED_COLLECTION_ADDRESSES_REQUEST,
  latest: true,

  process({ httpClient }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/address/agreement/inquired-collection-addresses/`
    httpClient
      .get(url)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_MY_AGREEMENT_INQUIRED_COLLECTION_ADDRESSES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.address.handleBlockAddress.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_MY_AGREEMENT_INQUIRED_COLLECTION_ADDRESSES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetMyOrderInquiredCollectionAddresses
 * @description get addresses from my orders (to use in Orders Collection address filter).
 */
export const handleGetMyOrderInquiredCollectionAddresses = createLogic({
  type: ActionTypes.GET_MY_ORDER_INQUIRED_COLLECTION_ADDRESSES_REQUEST,
  latest: true,

  process({ httpClient }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/address/order/inquired-collection-addresses/`
    httpClient
      .get(url)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_MY_ORDER_INQUIRED_COLLECTION_ADDRESSES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.address.handleBlockAddress.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_MY_ORDER_INQUIRED_COLLECTION_ADDRESSES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetAddressesWithOrders
 * @description get addresses with Order numbers
 */
export const handleGetAddressesWithOrders = createLogic({
  type: ActionTypes.GET_ADDRESSES_WITH_ORDERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload && action.payload.filters) {
      data = { ...action.payload.filters }
    }

    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/address/with-orders/`
    httpClient
      .get(url, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ADDRESSES_WITH_ORDERS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.address.handleGetAddressesWithOrders.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_ADDRESSES_WITH_ORDERS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleMaklerPremiumExportInvoices
 * @description triggers the invoice export for epd.
 */
export const handleExportAddresses = createLogic({
  type: ActionTypes.EXPORT_ADDRESSES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload.filters) {
      data = { ...data, ...action.payload.filters }
    }

    if (action.payload.sortedBy && action.payload.sortedBy.length) {
      data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
    }

    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/address/export/`, data)
      .then(() => {
        dispatch({
          type: ActionTypes.EXPORT_ADDRESSES_SUCCESS,
        })

        toastr.success('', I18n.t('api.address.handleExportAddresses.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.address.handleExportAddresses.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.EXPORT_ADDRESSES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [
  handleGetAddresses,
  handleGetActiveAddresses,
  handleGetWastemanagerAddresses,
  handleGetAddressesForCompany,
  handleAddAddress,
  handleUpdateAddress,
  handleBlockAddress,
  handleGetMyAgreementInquiredCollectionAddresses,
  handleGetMyOrderInquiredCollectionAddresses,
  handleGetAddressesWithOrders,
  handleExportAddresses,
]
