import './style.scss'

import { Form, Formik, FormikValues } from 'formik'
import React, { FC, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { withRouter } from 'react-router'
import { Element as ScrollElement, scroller } from 'react-scroll'

import withErrorBoundary from 'helper/withErrorBoundary'
import { isSnapshot } from 'helper/snapshots'

import PageContainer from '../../common/PageContainer'
import Paragraph, { PARAGRAPH_ALIGN } from '../../common/Paragraph'
import { CookieConsentAlert } from '../../general/CookieConsentAlert'

import IntroductionSection from './components/IntroductionSection'
import { RegisterFormSection } from './components/RegisterFormSection'
import { SelectRoleSection } from './components/SelectRoleSection'
import { connector } from './connector'
import { registerFormSchema } from './schemes'

/**
 * @description This component renders and controls a add homepage register component.
 * @function
 * @param {Object} props the component props
 */

interface HomepageRegisterComponentProps {
  registerUser: (
    userInfo: FormikValues,
    history: History,
    callback?: any,
  ) => void
  cookiesAccepted: boolean
  isLoading: boolean
  history: History
}

export const HomepageRegisterComponent: FC<
  HomepageRegisterComponentProps
> = props => {
  const { cookiesAccepted, isLoading, history } = props

  const [selectedRole, setSelectedRole] = useState<number | null>(null)

  /**
   * Handles change the role.
   */
  const onRoleChange = newRole => {
    setSelectedRole(newRole)

    scroller.scrollTo('selectRoleScrollPoint', {
      duration: 1000,
      offset: -90, // Scrolls to element + x pixels down the page
      smooth: true,
    })
  }

  /* REGISTER (disabled in #3228) */
  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.register')}</title>
        <meta
          name='Description'
          content={I18n.t('pageDescriptions.register')}
        />
      </Helmet>
      <Paragraph>
        <Translate value='homepages.register.explanation.registrationDisabled' />
      </Paragraph>
    </>
  )

  /* REGISTER (disabled in #3228) */
  // eslint-disable-next-line no-unreachable
  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.register')}</title>
        <meta
          name='Description'
          content={I18n.t('pageDescriptions.register')}
        />
      </Helmet>

      <PageContainer dataTestId='user-base-data-form-container'>
        {!cookiesAccepted && !isSnapshot() && (
          <CookieConsentAlert>
            <Paragraph align={PARAGRAPH_ALIGN.CENTER}>
              <Translate value='cookieConsent.error.signup' />
            </Paragraph>
          </CookieConsentAlert>
        )}

        {(cookiesAccepted || isSnapshot()) && (
          <Formik
            initialValues={{
              accept_policy: false,
              business_phone_number: '',
              company_name: '',
              email: '',
              first_name: '',
              gender: '',
              last_name: '',
              password: '',
              password_repeat: '',
            }}
            validationSchema={registerFormSchema}
            onSubmit={(values, formikProps) => {
              props.registerUser(
                {
                  ...values,
                  company_object: {
                    role: Number(selectedRole),
                  },
                },
                history,
                {
                  setError: (key, string) =>
                    formikProps.setFieldError(key, string),
                },
              )
            }}
            render={({
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              isValid,
              submitCount,
              touched,
              values,
            }) => (
              <Form
                className='homepage-register'
                data-testid='user-registration-form'
                noValidate
              >
                <IntroductionSection />

                <SelectRoleSection onRoleChange={onRoleChange} />

                <ScrollElement name='selectRoleScrollPoint' />

                {selectedRole && (
                  <RegisterFormSection
                    errors={errors}
                    isLoading={isLoading}
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onSubmit={handleSubmit}
                    submitCount={submitCount}
                    touched={touched}
                    values={values}
                  />
                )}
              </Form>
            )}
          />
        )}
      </PageContainer>
    </>
  )
}

export const HomepageRegister = connector(
  withRouter(withErrorBoundary(HomepageRegisterComponent)) as any,
)
