import MaklerpremiumAction from '../actiontypes/maklerpremium'

export const initialState: {
  priceComparisonTable: PriceComparisonCompany[]
  priceComparisonTablePaused?: PriceComparisonCompany[]
  customTax?: customTax[]
} = {
  priceComparisonTable: [],
  priceComparisonTablePaused: undefined,
  customTax: undefined,
}

export const priceCalcReducer = (state = initialState, action) => {
  switch (action.type) {
    case MaklerpremiumAction.GET_PRICE_COMPARISON_TABLE_SUCCESS:
      return {
        ...state,
        priceComparisonTable: Object.values(action.payload),
      }
    case MaklerpremiumAction.GET_PRICE_COMPARISON_TABLE_PAUSED_SUCCESS:
      return {
        ...state,
        priceComparisonTablePaused: Object.values(action.payload),
      }
    case MaklerpremiumAction.RESET_PRICE_COMPARISON_TABLE:
    case MaklerpremiumAction.GET_PRICE_COMPARISON_TABLE_ERROR:
      return {
        ...state,
        priceComparisonTable: initialState.priceComparisonTable,
      }
    case MaklerpremiumAction.GET_PRICE_COMPARISON_TABLE_PAUSED_ERROR:
      return {
        ...state,
        priceComparisonTablePaused: initialState.priceComparisonTablePaused,
      }
    case MaklerpremiumAction.GET_CUSTOM_TAX_SUCCESS:
      return {
        ...state,
        customTax: action.payload,
      }
    default:
      return {
        ...state,
      }
  }
}
