import { useFormikContext } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { FindAddressByFilterInput } from 'components/common/FindByFilterInput'
import { InputNumber } from 'components/common/InputNumber'
import { NoContentMessage } from 'components/common/NoContentMessage'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { Option } from 'components/common/StaticCombobox/StaticCombobox'
import { COMPANY_ROLE } from 'components/company/constants'
import { AGREEMENT_ACTIVATION_STATES } from 'components/offer/constants'
import { getCurrentUserSelector } from 'selectors/user'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'

import {
  filterMapFractionList,
  getCurrentValueObject,
  resetInputOption,
} from '../../helpers'
import { CommonFilterProps } from '../../types'
import { COMPANY_FILTER, CompanySearchFilter } from '../CompanySearchFilter'
import { FilterReset } from '../FilterReset'
import { cleanUpAndCountFiltersForReset } from '../FilterReset/helpers'

interface AgreementFilterProps extends CommonFilterProps {
  action: (page: any, filters: any) => any // sorry, Redux with redux-logics is untypeable
  fractionList: Fraction[]
  addressList: Address[]
  companyList: Company[]
  setCurrentFilterValues: (currentFilters) => void
  currentFilters: any
}

export const AgreementFilter = ({
  action,
  fractionList,
  handleBlur,
  handleChange,
  isLoading,
  length,
  resetForm,
  setFieldValue,
  setCurrentFilterValues,
  currentFilters,
  values,
}: AgreementFilterProps) => {
  const dispatch = useDispatch()
  const [filterWasReset, setFilterWasReset] = useState(false)
  const [currentSelectedFraction, setCurrentSelectedFraction] =
    useState<Option>()
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<Option>()

  const { setTouched, touched } = useFormikContext()

  const user = useSelector(getCurrentUserSelector)

  const selectionItems = useMemo(
    () => ({
      COARSE_FRACTION: [
        ...resetInputOption,
        ...filterMapFractionList(fractionList, user),
      ],
      STATUS: [
        ...resetInputOption,
        ...AGREEMENT_ACTIVATION_STATES.map(item => ({
          value: `${item.id}`,
          label: `${I18n.t(item.name)}`,
        })),
      ],
    }),
    [fractionList, user],
  )

  useEffect(() => {
    // makes sure that the default value (if set) does not get overwritten before the user actually
    // changed the values himself
    const touchedIsEmpy = Object.keys(touched).length === 0
    if (touchedIsEmpy && !filterWasReset) {
      return
    }

    const newFilters = { ...currentFilters }
    let changedFilters = false
    Object.entries(values).forEach(([property, value]) => {
      if (newFilters[property] !== value) {
        changedFilters = true
        newFilters[property] = value
      }
    })
    if (filterWasReset || changedFilters) {
      setCurrentFilterValues(newFilters)
      dispatch(action(null, newFilters))
      if (filterWasReset) {
        setFilterWasReset(false)
      }
    }
  }, [
    action,
    currentFilters,
    dispatch,
    filterWasReset,
    setCurrentFilterValues,
    values,
    touched,
  ])

  useEffect(() => {
    setCurrentSelectedFraction(
      getCurrentValueObject(
        selectionItems.COARSE_FRACTION,
        values.coarse_fraction,
      ),
    )
  }, [selectionItems.COARSE_FRACTION, values.coarse_fraction])

  useEffect(() => {
    setCurrentSelectedStatus(
      getCurrentValueObject(selectionItems.STATUS, values.agreement_status),
    )
  }, [selectionItems.STATUS, values.agreement_status])

  const noResultsText = I18n.t('filterTranslations.noInputResults')
  const placeholder = I18n.t('general.placeholder.all')

  const handleChangeEvent = (name, event) => {
    handleChange(event)
    setTouched({ [name]: true })
  }

  return (
    <>
      <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
        <FilterReset
          onResetFilter={() => {
            resetForm()
            setCurrentFilterValues({})
            setFilterWasReset(true)
          }}
          showResetFilterButton={
            cleanUpAndCountFiltersForReset(currentFilters) > 0
          }
        />
      </ButtonBar>
      <div className='uk-grid uk-child-width-1-5@l uk-child-width-1-2@m'>
        <FindAddressByFilterInput
          resultType={COMPANY_ROLE.WASTE_PRODUCER}
          label={I18n.t('filterTranslations.orderFilter.collectionAddress')}
          name='search'
          noResultsText={noResultsText}
          placeholder={placeholder}
          handleBlur={handleBlur}
          handleSelectionChange={value => {
            setFieldValue('collection_address', value)
            setTouched({ collection_address: true })
          }}
          isClearable
          onlyAddressLabel
          onlyUniqueOptions
          value={
            values.collection_address ? Number(values.collection_address) : 0
          }
        />

        <CompanySearchFilter
          currentFilters={currentFilters}
          setCurrentFilterValues={values => {
            setFieldValue('company', values.id_slug?.split(',')[0])
            setTouched({ company: true })
          }}
          noResultsText={noResultsText}
          placeholder={placeholder}
          label={I18n.t('filterTranslations.myAutoOfferFilter.company')}
          name='company'
          roleFilter={COMPANY_FILTER.ALL}
          isClearable
          defaultCompanyId={
            currentFilters && currentFilters.company
              ? Number(currentFilters.company)
              : undefined
          }
        />

        <StaticCombobox
          isLoading={fractionList.length < 1}
          label={I18n.t('filterTranslations.acceptedOfferFilter.fraction')}
          name='coarse_fraction'
          noResultsText={noResultsText}
          options={selectionItems.COARSE_FRACTION}
          onSelectionChange={event => {
            handleChangeEvent('coarse_fraction', event)
          }}
          placeholder={placeholder}
          selectedOption={currentSelectedFraction}
        />

        <StaticCombobox
          label={I18n.t('filterTranslations.acceptedOfferFilter.status')}
          name='agreement_status'
          noResultsText={noResultsText}
          options={selectionItems.STATUS}
          onSelectionChange={event => {
            handleChangeEvent('agreement_status', event)
          }}
          placeholder={placeholder}
          selectedOption={currentSelectedStatus}
        />

        <InputNumber
          label={I18n.t(
            'filterTranslations.acceptedOfferFilter.acceptedOfferNumber',
          )}
          maxLength={10}
          name='id'
          onBlur={handleBlur}
          onChange={event => {
            handleChangeEvent('id', event)
          }}
          value={values.id}
        />
      </div>

      <div className='filter__empty-message'>
        {!isLoading && length < 1 && (
          <NoContentMessage
            message={I18n.t('general.emptyFilterResultMessage')}
            showResetFilterButton={false}
          />
        )}
      </div>
    </>
  )
}
