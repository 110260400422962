import { DSZAddInvoiceFormStepsTranslations } from '../../dsz/DSZInvoiceFormSteps/DSZAddInvoiceFormStepsTranslations'
import { EPDAddInvoiceFormStepsTranslations } from '../../epd/EPDInvoiceFormSteps/EPDAddInvoiceFormStepsTranslations'

export const addInvoicePageTranslations = {
  header: {
    title: 'Beleg erstellen',
    description:
      'Hier können Sie manuell eine Rechnung oder Gutschrift für einen Kunden oder Partner erstellen. ' +
      'Bitte füllen Sie alle erforderlichen Felder aus.',
  },
  epd: EPDAddInvoiceFormStepsTranslations,
  dsz: DSZAddInvoiceFormStepsTranslations,
}
