import ActionTypes from '../actiontypes/inquiry'

/** @function
 * @name getAvvs
 * @description An action method to get a list of AVVs (can be filtered by number)
 * @return {Object}
 */
export const getAvvs = filters => ({
  type: ActionTypes.GET_AVVS_REQUEST,
  payload: {
    filters,
  },
})

export const sendConvertInquiryConfirmation = (offerId, userId) => ({
  type: ActionTypes.SEND_CONVERT_INQUIRY_CONFIRMATION_REQUEST,
  payload: {
    offer_id: offerId,
    user_id: userId,
  },
})
