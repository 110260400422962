export const UniversalFileUploadTranslations = {
  universalFileUpload: {
    buttonText: 'Datei hochladen +',
    previewLabel: 'Hochgeladene Dokumente: ',
    previewLabelCounter:
      'Hochgeladene Dokumente (%{currentFilesCount}/%{maxFiles}):',

    modalTitle: 'Vorschau',
    modalConfirmButton: 'Ok',
    modalPreviewButton: 'Datei öffnen',
    modalCommentPlaceholder:
      'Ergänzen Sie hier eine Beschreibung zu dem Anhang.',

    previewRotateButton: 'Bild nach rechts drehen',
    previewRemoveButton: 'Datei entfernen',

    noFileSelected: 'Datei-Auswahl. Derzeit ist keine Datei ausgewählt.',
    selectedFile: 'Datei-Auswahl. Ausgewählte Datei: %{selectedFileName}',
  },
}
