import classNames from 'classnames'
import React, { FC, RefObject } from 'react'

interface CardContainerProps {
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  onMouseOver?: () => void
  cardRef?: RefObject<HTMLDivElement>
  className?: string
  isHighlighted?: boolean
  itemId?: number
  testId?: string
}

/**
 * A component which renders a generic container around our new cards with its default styling.
 * @constructor
 */
export const CardContainer: FC<CardContainerProps> = ({
  cardRef,
  children,
  className,
  onMouseEnter,
  onMouseLeave,
  onMouseOver,
  isHighlighted,
  itemId,
  testId,
}) => (
  <article
    className={classNames(
      'new-card',
      { 'new-card--highlight-transition': isHighlighted },
      className,
    )}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onMouseOver={onMouseOver}
    onFocus={onMouseOver}
    data-testid={testId}
    data-item={itemId}
    ref={cardRef}
  >
    {children}
  </article>
)
