import ActionTypes from '../actiontypes/invoice'

/** @function
 * @name getInvoices
 * @description Loads a list of invoices.
 * @return {Object}
 */
export const getInvoices = (
  page?: number,
  filters?: { [key: string]: string },
  sortedBy?: { [key: string]: string },
) => ({
  type: ActionTypes.GET_INVOICES_REQUEST,
  payload: {
    page,
    filters,
    sortedBy,
  },
})

/** @function
 * @name getInvoice
 * @description Loads an invoice.
 * @param id of an invoice.
 * @return {Object}
 */
export const getInvoice = id => ({
  type: ActionTypes.GET_INVOICE_REQUEST,
  payload: id,
})

/**
 * @param values = {paid_at, comment, status}
 * @returns {{payload: *, type: null}}
 */
export const updateEPDDocument = values => ({
  type: ActionTypes.UPDATE_INVOICE_REQUEST,
  payload: values,
})

/**
 * @param values = {paid_at, comment, status}
 * @returns {{payload: *, type: null}}
 */
export const updateEPDCancelledDocument = values => ({
  type: ActionTypes.UPDATE_EPD_CANCELLED_DOCUMENT_REQUEST,
  payload: values,
})

/**
 * @function
 * @name exportInvoices
 * @return {Object}
 */
export const exportInvoices = () => ({
  type: ActionTypes.EXPORT_INVOICES_REQUEST,
})

/**
 * @function
 * @name exportEPDBatchFile
 * @return {Object}
 */
export const exportEPDBatchFile = (
  filters?: { [key: string]: string | number },
  sortedBy?: { [key: string]: string | number },
) => ({
  type: ActionTypes.EXPORT_BATCH_FILE_REQUEST,
  payload: {
    filters,
    sortedBy,
  },
})

/**
 * @function
 * @name exportEPDMaklerPremiumInvoices
 * @return {Object}
 */
export const exportEPDMaklerPremiumInvoices = (
  filters?: { [key: string]: string | number },
  sortedBy?: { [key: string]: string | number },
) => ({
  type: ActionTypes.EXPORT_EPD_MAKLER_PREMIUM_INVOICES_REQUEST,
  payload: {
    filters,
    sortedBy,
  },
})

/**
 * @function
 * @name exportDSZMaklerPremiumInvoices
 * @return {Object}
 */
export const exportDSZMaklerPremiumInvoices = (
  filters?: { [key: string]: string | number },
  sortedBy?: { [key: string]: string | number },
) => ({
  type: ActionTypes.EXPORT_DSZ_MAKLER_PREMIUM_INVOICES_REQUEST,
  payload: {
    filters,
    sortedBy,
  },
})

export const exportCollectiveInvoicingForecast = (filters?: {
  [key: string]: string | number
}) => ({
  type: ActionTypes.EXPORT_EPD_COLLECTIVE_INVOICING_FORECAST_REQUEST,
  payload: {
    filters,
  },
})

export const exportCollectiveInvoicing = (filters?: {
  [key: string]: string | number
}) => ({
  type: ActionTypes.EXPORT_EPD_COLLECTIVE_INVOICING_REQUEST,
  payload: {
    filters,
  },
})

export const exportCollectiveInvoicingActivities = (filters?: {
  [key: string]: string | number
}) => ({
  type: ActionTypes.EXPORT_EPD_COLLECTIVE_INVOICING_ACTIVITIES_REQUEST,
  payload: {
    filters,
  },
})
