import 'react-tippy/dist/tippy.css'

import uniqueId from 'lodash.uniqueid'
import React, {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react'
import {
  Tooltip as TippyTooltip,
  TooltipProps as TippyTooltipProps,
} from 'react-tippy'

// z-index value of the tooltip popover. Defaults to BEFORE_HEADER_BEHIND_MODAL
export enum TOOLTIP_ZINDEX {
  BEHIND_HEADER = 900,
  BEFORE_HEADER_BEHIND_MODAL = 8999,
  BEFORE_MODALS = 9999,
}

export enum TOOLTIP_POSITION {
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
}

export enum TOOLTIP_THEME {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum TOOLTIP_SIZE {
  SMALL = 'small',
  REGULAR = 'regular',
  BIG = 'big',
}

export enum TOOLTIP_TRIGGER {
  MOUSEENTER = 'mouseenter',
  FOCUS = 'focus',
  CLICK = 'click',
  MANUAL = 'manual',
}

export interface TooltipProps extends TippyTooltipProps {
  children?: ReactNode
  distance?: number
  inline?: boolean
  popperOptions?: Record<string, unknown>
  position?: TOOLTIP_POSITION
  size?: TOOLTIP_SIZE
  theme?: TOOLTIP_THEME
  tooltipId?: string | number
  trigger?: TOOLTIP_TRIGGER
  zIndex?: number
  open?: boolean
}

/**
 * This is a wrapper around the regular tippy Tooltip component that applies our default props and
 * adds the zIndex as prop, so we do not have to repeat these so often.
 * @constructor
 */
export const Tooltip: FC<TooltipProps> = ({
  children = null,
  distance = 20,
  inline = false,
  popperOptions = {},
  position = TOOLTIP_POSITION.TOP,
  size = TOOLTIP_SIZE.REGULAR,
  theme = TOOLTIP_THEME.DARK,
  trigger = TOOLTIP_TRIGGER.CLICK,
  tooltipId = uniqueId('tooltip_'),
  zIndex = TOOLTIP_ZINDEX.BEFORE_HEADER_BEHIND_MODAL,
  html,
  title,
  open = false,
}) => {
  const [openedTooltips, setOpenedTooltips] = useState({})

  const closeTooltip = useCallback(() => {
    if (tooltipId && openedTooltips[tooltipId]) {
      setOpenedTooltips({
        [tooltipId]: false,
      })
    }
  }, [openedTooltips, tooltipId])

  useEffect(() => {
    if (open !== openedTooltips[tooltipId]) {
      setOpenedTooltips({ [tooltipId]: open })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open])

  useLayoutEffect(() => {
    window.addEventListener('scroll', closeTooltip)
    window.addEventListener('resize', closeTooltip)

    return () => {
      window.removeEventListener('scroll', closeTooltip)
      window.removeEventListener('resize', closeTooltip)
    }
  }, [closeTooltip])

  const onClick = () => {
    if (trigger === TOOLTIP_TRIGGER.CLICK && tooltipId) {
      setOpenedTooltips({
        [tooltipId]: !openedTooltips[tooltipId],
      })
    }
  }

  const rest = {
    position,
    size,
    theme,
    trigger,
    html,
    title,
  }

  return (
    <TippyTooltip
      arrow
      open={openedTooltips[tooltipId]}
      onRequestClose={onClick}
      distance={distance}
      duration={0}
      popperOptions={{
        modifiers: {
          addZIndex: {
            enabled: true,
            order: 810,
            fn: data => ({
              ...data,
              styles: {
                ...data.styles,
                zIndex,
              },
            }),
          },
        },
        ...popperOptions,
      }}
      interactive
      {...rest}
      style={inline ? { display: 'inline-block' } : undefined}
    >
      {/* although not ideal, we need the onClick event to be set onto a div. The reason for this is because Tooltip
      can have children that are already button elements. There shouldn't be buttons nested inside other buttons.
      As an alternative, we could set this onClick event on every child defined on this app, but that can lead to
      future problems.
      The other props are required for linting purposes */}
      <div
        role='button'
        onKeyPress={() => undefined}
        onClick={onClick}
        tabIndex={-1} // avoid this element to be selected by pressing tab
        style={{ display: `${inline ? 'inline-block' : 'block'}` }}
      >
        {children}
      </div>
    </TippyTooltip>
  )
}
