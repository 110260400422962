import moment from 'moment'
import { I18n } from 'react-i18nify'

import {
  CATEGORY_CHOICES,
  CATEGORY_CHOICES_TRANSLATIONS,
  INITIAL_VALUES,
  REVISION_TYPES,
  REVISION_TYPES_TRANSLATIONS,
  STAGE_CHOICES,
  STAGE_CHOICES_TRANSLATIONS,
  TYPE_CHOICES,
  TYPE_CHOICES_TRANSLATIONS,
  WASTE_CATEGORY_CHOICES,
} from './constants'

export const isFieldValid = (name, formikContext) => {
  const { values, errors } = formikContext
  return (values[name] ?? '') && values[name].length > 0 && !errors[name]
}

export const getFieldError = (name, formikContext) => {
  const { errors, submitCount } = formikContext
  return !isFieldValid(name, formikContext) && submitCount > 0
    ? (errors[name] as string)
    : undefined
}

export const handleOnChange = async (
  e: React.ChangeEvent<any>,
  formikContext,
) => {
  const { validateForm, handleChange } = formikContext
  await handleChange(e)
  await validateForm()
}

export const setRadioButtonValue = async ({
  name,
  event,
  value,
  formikContext,
}) => {
  const { setFieldValue, validateForm } = formikContext
  if (event.target.value === 'on') {
    await setFieldValue(name, `${value}`)
    resetValues(name, value, formikContext)
  }
  await validateForm()
}

const resetValuesToInitial = (names, formikContext) => {
  const { setFieldValue } = formikContext

  names.forEach(name => {
    setFieldValue(name, INITIAL_VALUES[name])
  })
}

export const resetAllValues = formikContext => {
  resetValuesToInitial(
    [
      'category',
      'email',
      'type',
      'fraction',
      'last_name',
      'first_name',
      'stage',
      'waste_category',
      'communities',
      'zipcodes',
      'customer',
      'container_type',
    ],
    formikContext,
  )
}

export const resetValues = (name, value, formikContext) => {
  if (
    name === 'category' &&
    (value === CATEGORY_CHOICES.CHOICE_BOOKKEEPING ||
      CATEGORY_CHOICES.CHOICE_SALES)
  ) {
    resetValuesToInitial(
      [
        'fraction',
        'stage',
        'waste_category',
        'communities',
        'zipcodes',
        'customer',
        'container_type',
      ],
      formikContext,
    )
  }

  if (name === 'stage' && value === STAGE_CHOICES.CHOICE_STAGE1) {
    resetValuesToInitial(
      [
        'fraction',
        'waste_category',
        'communities',
        'zipcodes',
        'customer',
        'container_type',
      ],
      formikContext,
    )
  }

  if (name === 'stage' && value === STAGE_CHOICES.CHOICE_STAGE2) {
    resetValuesToInitial(['customer'], formikContext)
  }

  if (name === 'stage' && value === STAGE_CHOICES.CHOICE_STAGE3) {
    resetValuesToInitial(
      [
        'fraction',
        'waste_category',
        'communities',
        'zipcodes',
        'container_type',
      ],
      formikContext,
    )
  }

  if (
    name === 'waste_category' &&
    value === WASTE_CATEGORY_CHOICES.CHOICE_ALL
  ) {
    resetValuesToInitial(['fraction'], formikContext)
  }

  if (name === 'type' && value === TYPE_CHOICES.CHOICE_INBOX) {
    resetValuesToInitial(['last_name', 'first_name'], formikContext)
  }
}

export const mapContact = contact => ({
  ...contact,
  category: CATEGORY_CHOICES_TRANSLATIONS[contact.category],
  type: TYPE_CHOICES_TRANSLATIONS[contact.type],
  stage: STAGE_CHOICES_TRANSLATIONS[contact.stage],
  area:
    contact?.communities?.reduce(
      (acc, curr, i) => `${acc}${i > 0 ? ',' : ''} ${curr.name}`,
      '',
    ) +
    ',' +
    contact?.zipcodes?.reduce(
      (acc, curr, i) => `${acc}${i > 0 ? ',' : ''} ${curr.code}`,
      '',
    ),
  name:
    contact?.last_name && contact?.first_name
      ? `${contact.last_name}, ${contact.first_name}`
      : '',
})

export const mapLog = item => {
  const getValueString = (field, value) => {
    value = value === null || value === '' ? '-' : value

    switch (field) {
      case 'type':
        return TYPE_CHOICES_TRANSLATIONS[value]
      case 'category':
        return CATEGORY_CHOICES_TRANSLATIONS[value]
      case 'stage':
        return `Stufe ${value}`
      default:
        return value
    }
  }

  const getChangeString = item => {
    return `${I18n.t(
      'communicationCenterPageTranslations.log.before',
    )}: ${getValueString(item.field, item.value_before)}. ${I18n.t(
      'communicationCenterPageTranslations.log.after',
    )}: ${getValueString(item.field, item.value)}`
  }

  return {
    timestamp: item.log_date
      ? moment(item.log_date).format('DD.MM.YYYY HH:mm')
      : '',
    name:
      !!item.revision_user_last_name && !!item.revision_user_first_name
        ? `${item.revision_user_last_name}, ${item.revision_user_first_name}`
        : '',
    activity: item.category
      ? `${CATEGORY_CHOICES_TRANSLATIONS[item.category]}: ${
          REVISION_TYPES_TRANSLATIONS[item.revision_type]
        } (ID: ${item.id})`
      : '',
    change:
      item.revision_type === `${REVISION_TYPES.REVISION_TYPE_UPDATED}`
        ? getChangeString(item)
        : '',
  }
}
