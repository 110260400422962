import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import { getOrder } from '../actions/order'
import AppActionTypes from '../actiontypes/app'
import OfferActionTypes from '../actiontypes/offer'
import OrderActionTypes from '../actiontypes/order'
import { UpcomingOrderType } from '../components/maklerpremium/UpcomingOrders/UpcomingOrdersList'
import {
  APP_CONSTANTS,
  CARD_LIST_PAGE_SIZE,
  TABLE_PAGE_SIZE_10,
} from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetOrder
 * @description Loads order by id that were created by my company.
 */
export const handleGetOrder = createLogic({
  type: OrderActionTypes.GET_ORDER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/${action.payload.id}/`
    let data = {}

    if (action.payload?.expand) {
      data = { ...data, expand: action.payload.expand.join(',') }
    }
    httpClient
      .get(url, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.GET_ORDER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.order.handleGetOrder.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.GET_ORDER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetOrders
 * @description Loads all orders that were created     by my company.
 */
export const handleGetOrders = createLogic({
  type: OrderActionTypes.GET_ORDERS_REQUEST,
  latest: true,

  process: function ({ httpClient, action }, dispatch, done) {
    let data = { page_size: CARD_LIST_PAGE_SIZE }

    if (action.payload) {
      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }

      data = {
        ...data,
        page: action.payload.page || 1,
        page_size: action.payload.pageSize || CARD_LIST_PAGE_SIZE,
      }
      if (action.payload.expand) {
        data = { ...data, expand: action.payload.expand.join(',') }
      }
      data = Object.fromEntries(
        Object.entries(data).filter(
          ([, value]) => typeof value !== 'undefined' && value !== '',
        ),
      )
    }

    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.GET_ORDERS_SUCCESS,
          payload: res,
        })
        dispatch({
          type: AppActionTypes.RESET_API_FETCH_ERRORS,
          payload: 'GET_ORDER',
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.order.handleGetOrders.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.GET_ORDERS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetOrdersForInvoiceCheck
 * @description Loads all orders that were created by my company. Uses a specialised endpoint that only returns order
 * data specific to the InvoiceCheck context.
 */
export const handleGetOrdersForInvoiceCheck = createLogic({
  type: OrderActionTypes.GET_ORDERS_FOR_INVOICE_CHECK_REQUEST,
  latest: true,

  process: function ({ httpClient, action }, dispatch, done) {
    let data = { page_size: CARD_LIST_PAGE_SIZE }

    if (action.payload) {
      data = {
        ...data,
        page: action.payload.page || 1,
        page_size: action.payload.pageSize || CARD_LIST_PAGE_SIZE,
      }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }
      if (action.payload.expand) {
        data = { ...data, expand: action.payload.expand.join(',') }
      }
    }

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/for_invoice_check/`,
        data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.GET_ORDERS_FOR_INVOICE_CHECK_SUCCESS,
          payload: res,
        })
        dispatch({
          type: AppActionTypes.RESET_API_FETCH_ERRORS,
          payload: 'GET_SMALL_ORDER',
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.order.handleGetOrders.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.GET_ORDERS_FOR_INVOICE_CHECK_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateOrder
 * @description creates order.
 */
export const handleCreateOrder = createLogic({
  type: OrderActionTypes.CREATE_ORDER_REQUEST,
  latest: true,

  process: function ({ httpClient, action }, dispatch, done) {
    httpClient
      .post(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/`, action.payload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.CREATE_ORDER_SUCCESS,
          payload: res,
        })
        // this is needed to reset the page after creating a new order from the agreement details by clicking on the
        // button "+Neu Auftrag".
        // In case a order is made on the last week of the service end, the button should not be available anymore
        // for the user.
        dispatch({
          type: OfferActionTypes.GET_OFFER_REQUEST,
          payload: { id: res.offer },
        })
        toastr.success('', I18n.t('api.order.handleCreateOrder.success'))
      })
      .then(done)
      .catch(err => {
        let humanReadableError = I18n.t('api.order.handleCreateOrder.error')
        const badDeliveryDateError =
          'Es können keine Aufträge außerhalb des vereinbarten Zeitraum erstellt werden.'

        if (
          err.response.data &&
          err.response.data.includes(badDeliveryDateError)
        ) {
          humanReadableError = I18n.t(
            'api.order.handleCreateOrder.errorBadDeliveryDate',
          )
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.CREATE_ORDER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateOrderInternalComment
 * @description update internal_comment field
 */
export const handleUpdateOrderInternalComment = createLogic({
  type: OrderActionTypes.UPDATE_ORDER_INTERNAL_COMMENT_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/${action.payload.orderId}/internal-comment/`,
        { internal_comment: action.payload.orderInternalComment },
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.UPDATE_ORDER_INTERNAL_COMMENT_SUCCESS,
          payload: res,
        })
        toastr.success(
          '',
          I18n.t('api.order.updateOrderInternalComment.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.order.updateOrderInternalComment.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.UPDATE_ORDER_INTERNAL_COMMENT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateOrderAttachments
 * @description update attachments field
 */
export const handleUpdateOrderAttachments = createLogic({
  type: OrderActionTypes.UPDATE_ORDER_ATTACHMENTS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/${action.payload.orderId}/attachments/`,
        { ...action.payload.attachments },
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.UPDATE_ORDER_ATTACHMENTS_SUCCESS,
          payload: res,
        })
        toastr.success('', I18n.t('api.order.updateOrderAttachments.success'))
      })
      .then(() => {
        //
        dispatch(getOrder(action.payload.orderId))
      })
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.order.updateOrderAttachments.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.UPDATE_ORDER_ATTACHMENTS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateInvoicingDocuments
 * @description creates invoicing documents for an order.
 */
export const handleCreateInvoicingDocuments = createLogic({
  type: OrderActionTypes.CREATE_INVOICING_DOCUMENTS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/create-invoicing-documents/`,
        action.payload.data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.CREATE_INVOICING_DOCUMENTS_SUCCESS,
          payload: res,
        })
        if (action.payload.onSuccess) action.payload.onSuccess()
        toastr.success(
          '',
          I18n.t('api.order.handleCreateInvoicingDocuments.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.order.handleCreateInvoicingDocuments.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.CREATE_INVOICING_DOCUMENTS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleDisposeOrder
 * @description dispose order.
 */
export const handleDisposeOrder = createLogic({
  type: OrderActionTypes.DISPOSE_ORDER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/${action.payload.id}/dispose/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.DISPOSE_ORDER_SUCCESS,
          payload: res,
        })

        toastr.success('', I18n.t('api.order.handleDisposeOrder.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.order.handleDisposeOrder.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.DISPOSE_ORDER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handlePostponeOrder
 * @description postpone order.
 */
export const handlePostponeOrder = createLogic({
  type: OrderActionTypes.POSTPONE_ORDER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/${action.payload.orderId}/postpone/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.POSTPONE_ORDER_SUCCESS,
          payload: res,
        })

        if (action.payload.callbacks && action.payload.callbacks.success)
          action.payload.callbacks.success()

        toastr.success('', I18n.t('api.order.handlePostponeOrder.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.order.handlePostponeOrder.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.POSTPONE_ORDER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCancelOrder
 * @description cancel order.
 */
export const handleCancelOrder = createLogic({
  type: OrderActionTypes.CANCEL_ORDER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/${action.payload.orderId}/cancel/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.CANCEL_ORDER_SUCCESS,
          payload: res,
        })

        toastr.success('', I18n.t('api.order.handleCancelOrder.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.order.handleCancelOrder.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.CANCEL_ORDER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleSetETA
 * @description set ETA for order.
 */
export const handleSetETA = createLogic({
  type: OrderActionTypes.SET_ETA_FOR_ORDER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/${action.payload.orderId}/set_eta/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.SET_ETA_FOR_ORDER_SUCCESS,
          payload: res,
        })

        toastr.success('', I18n.t('api.order.handleSetETA.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.order.handleSetETA.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.SET_ETA_FOR_ORDER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleConfirmExecution
 * @description confirm execution of order.
 */
export const handleConfirmExecution = createLogic({
  type: OrderActionTypes.CONFIRM_EXECUTION_OF_ORDER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/${action.payload.orderId}/confirm_execution/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.CONFIRM_EXECUTION_OF_ORDER_SUCCESS,
          payload: res,
        })

        toastr.success('', I18n.t('api.order.handleConfirmExecution.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.order.handleConfirmExecution.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.CONFIRM_EXECUTION_OF_ORDER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateOrderEvaluations
 * @description create order evaluations.
 */
export const handleCreateOrderEvaluations = createLogic({
  type: OrderActionTypes.CREATE_ORDER_EVALUATIONS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order-evaluation/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        const orderId = action.payload[0].order

        if (orderId) {
          dispatch({
            type: OrderActionTypes.GET_ORDER_REQUEST,
            payload: {
              id: orderId,
              expand_invoice_files: true,
            },
          })
        }

        dispatch({
          type: OrderActionTypes.CREATE_ORDER_EVALUATIONS_SUCCESS,
          payload: res,
        })

        toastr.success(
          '',
          I18n.t('api.order.handleCreateOrderEvaluations.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.order.handleCreateOrderEvaluations.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.CREATE_ORDER_EVALUATIONS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportOrders
 * @description triggers order export.
 */
export const handleExportOrders = createLogic({
  type: OrderActionTypes.EXPORT_ORDERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { filters } = action.payload
    let data = {}

    if (filters) {
      data = { ...data, filters: JSON.stringify(filters) }
    }
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/export/`, data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.EXPORT_ORDERS_SUCCESS,
          payload: res,
        })
        toastr.success(
          '',
          I18n.t('api.invoice.handleExportInvoiceCheck.success'),
        )
      })
      .then(done)
      .catch(err => {
        if (!err.hideToastr)
          toastr.error('', I18n.t('api.order.handleExportOrders.error'))

        done()
      })
  },
})

/**
 * @function
 * @name handleGetOrdersCount
 * @description Loads all orders count by my company.
 */
export const handleGetOrdersCount = createLogic({
  type: OrderActionTypes.GET_ORDERS_COUNT_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/count/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.GET_ORDERS_COUNT_SUCCESS,
          payload: res,
        })
        dispatch({
          type: AppActionTypes.RESET_API_FETCH_ERRORS,
          payload: 'GET_ORDERS_COUNT',
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.order.handleGetOrders.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.GET_ORDERS_COUNT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetMaklerPremiumUpcomingOrders
 * @description Gets all upcoming Orders for the next two work-days (excludes holidays and weekends)
 */
export const handleGetMaklerPremiumUpcomingOrders = createLogic({
  type: OrderActionTypes.GET_MAKLER_PREMIUM_UPCOMING_ORDERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload) {
      data = {
        page: action.payload.page || 1,
        page_size: action.payload.pageSize || TABLE_PAGE_SIZE_10,
      }

      const filters = action.payload.filters
        ? {
            partner_company: action.payload.filters.partner_company,
            status: action.payload.filters.status,
            ...action.payload.filters,
          }
        : {}

      data = { ...data, ...filters }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/upcoming-orders/`,
        data,
      )
      .then(res => {
        dispatch({
          type: OrderActionTypes.GET_MAKLER_PREMIUM_UPCOMING_ORDERS_SUCCESS,
          payload: res.data,
        })
        dispatch({
          type: AppActionTypes.RESET_API_FETCH_ERRORS,
          payload: 'GET_OFFER_PIPELINE',
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.order.handleGetMaklerPremiumUpcomingOrders.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OrderActionTypes.GET_MAKLER_PREMIUM_UPCOMING_ORDERS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleSetMaklerPremiumUpcomingOrderToConfirmed
 * @description Confirms a specific upcoming order's delivery/collection
 */
export const handleSetMaklerPremiumUpcomingOrderToConfirmed = createLogic({
  type: OrderActionTypes.SET_MAKLER_PREMIUM_UPCOMING_ORDER_TO_CONFIRMED_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/upcoming-orders/${action.payload.order_id}/`
    let data = {}

    if (action.payload) {
      if (action.payload.upcoming_order_type === UpcomingOrderType.COLLECTION) {
        data = {
          upcoming_collection_status: action.payload.upcoming_order_status,
        }
      } else if (
        action.payload.upcoming_order_type === UpcomingOrderType.DELIVERY
      ) {
        data = {
          upcoming_delivery_status: action.payload.upcoming_order_status,
        }
      }
    }

    httpClient
      .patch(url, data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.SET_MAKLER_PREMIUM_UPCOMING_ORDER_TO_CONFIRMED_SUCCESS,
          payload: res.data,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.order.handleGetMaklerPremiumUpcomingOrders.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OrderActionTypes.SET_MAKLER_PREMIUM_UPCOMING_ORDER_TO_CONFIRMED_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetOrderForDisposerManagement
 * @description Loads order by id & orderHash
 */
export const handleGetOrderForDisposerManagement = createLogic({
  type: OrderActionTypes.GET_ORDER_FOR_DISPOSER_MANAGEMENT_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { order_id, order_hash } = action.payload

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/${order_id}/for-disposer-management/`,
        { order_hash },
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderActionTypes.GET_ORDER_FOR_DISPOSER_MANAGEMENT_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.order.handleGetOrder.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: OrderActionTypes.GET_ORDER_FOR_DISPOSER_MANAGEMENT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleSetDisposerManagementOrderToConfirmed
 * @description Confirms a specific upcoming order's delivery/collection
 */
export const handleSetDisposerManagementOrderToConfirmed = createLogic({
  type: OrderActionTypes.SET_DISPOSER_MANAGEMENT_ORDER_TO_CONFIRMED_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order/${action.payload.order_id}/confirm-disposer-management/`

    httpClient
      .patch(url, action.payload)
      .then(res => {
        dispatch({
          type: OrderActionTypes.SET_DISPOSER_MANAGEMENT_ORDER_TO_CONFIRMED_SUCCESS,
          payload: res.data,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.order.handleSetDisposerManagementOrderToConfirmed.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OrderActionTypes.SET_DISPOSER_MANAGEMENT_ORDER_TO_CONFIRMED_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [
  handleGetOrder,
  handleGetOrders,
  handleGetOrdersForInvoiceCheck,
  handleCreateOrder,
  handleUpdateOrderInternalComment,
  handleUpdateOrderAttachments,
  handleCreateInvoicingDocuments,
  handleDisposeOrder,
  handlePostponeOrder,
  handleCancelOrder,
  handleSetETA,
  handleConfirmExecution,
  handleCreateOrderEvaluations,
  handleExportOrders,
  handleGetOrdersCount,
  handleGetMaklerPremiumUpcomingOrders,
  handleSetMaklerPremiumUpcomingOrderToConfirmed,
  handleGetOrderForDisposerManagement,
  handleSetDisposerManagementOrderToConfirmed,
]
