import 'react-tippy/dist/tippy.css'
import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'

import Icon from '../../common/Fontello'
import { Tooltip, TooltipProps } from '../../common/Tooltip'

import createMarkup from './helpers'

export { TOOLTIP_POSITION } from '../../common/Tooltip'

export interface TooltipIconProps extends TooltipProps {
  description?: string
  iconClass?: string
  title?: string
  withClickCatcher?: boolean
  preventDefault?: boolean
}

/**
 * @description This component renders and controls the form group tooltip.
 * @param {Object} props JSX attributes to this component as a single object.
 * @return {*}
 */
export const TooltipIcon: FC<TooltipIconProps> = ({
  description = null,
  iconClass = 'question',
  title = null,
  withClickCatcher = false,
  preventDefault = false,
  ...rest
}) => (
  <div // eslint-disable-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    className={classNames('tooltip-icon', {
      'tooltip-icon--with-click-catcher': withClickCatcher,
    })}
    onClick={e => {
      if (preventDefault) e.preventDefault()
    }}
  >
    <Tooltip
      html={
        <div>
          {title && (
            // This is our only way to inject translated html. But we control the translation and it is static.
            // eslint-disable-next-line react/no-danger
            <h4 dangerouslySetInnerHTML={createMarkup(title)} />
          )}
          {description && (
            // This is our only way to inject translated html. But we control the translation and it is static.
            // eslint-disable-next-line react/no-danger
            <div dangerouslySetInnerHTML={createMarkup(description)} />
          )}
        </div>
      }
      popperOptions={{
        parentOverflow: {
          boundariesElement: '.uk-card-body',
        },
      }}
      {...rest}
    >
      <Icon name={iconClass} />
    </Tooltip>
  </div>
)
