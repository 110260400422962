import { Form, Formik } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, { useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'
import * as yup from 'yup'

import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'
import {
  DOCUMENT_STATUS_TYPE,
  DOCUMENT_TYPE,
} from 'components/invoice/constants'
import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'
import { updateEPDCancelledDocument } from 'actions/invoice'

import { SetCancelledDocumentDoneForm } from './SetCancelledDocumentDoneForm'

type SetCancelledDocumentDoneModalPropsType = {
  isOpen?: boolean
  onClose?: () => void
  document?: Invoice
}

export type SetCancelledDocumentDoneFormValues = Partial<{
  payment_fields_hidden: boolean
  payment_method: number
  comment: string
  paid_at: string
  transaction_key: string
}>

export const SetCancelledDocumentDoneModal = (
  props: SetCancelledDocumentDoneModalPropsType,
) => {
  const {
    isOpen = false,
    onClose = () => undefined,
    document = undefined,
  } = props

  const dispatch = useDispatch()

  const [updateFired, setUpdateFired] = useState(false)

  const updateStatus = useSelector(state =>
    createRequestStatusSelector(['UPDATE_EPD_CANCELLED_DOCUMENT'])(state),
  )

  const documentIsCancelledCreditNote =
    Number(document?.document_type) === DOCUMENT_TYPE.CANCELLATION_CREDIT_NOTE

  const idSetCancelledInvoiceDoneModal = uniqueId()

  const handleSubmit = values => {
    const valuesToSend = { ...values }
    delete valuesToSend.payment_fields_hidden

    dispatch(
      updateEPDCancelledDocument({
        ...valuesToSend,
        ...(documentIsCancelledCreditNote
          ? // backend expects different keys depending on the documents type.
            { credit_note_id: document?.id ?? 0 }
          : { invoice_id: document?.id ?? 0 }),
        document_status: DOCUMENT_STATUS_TYPE.DONE,
      }),
    )

    setUpdateFired(true)
  }

  // Handle closing the modal when update was fired and returned with either a SUCCESS or an ERROR
  useEffect(() => {
    if (
      [REQUEST_STATUS.SUCCESS, REQUEST_STATUS.ERROR].includes(
        updateStatus as REQUEST_STATUS,
      ) &&
      updateFired
    ) {
      setUpdateFired(false)
      onClose()
    }
  }, [onClose, updateFired, updateStatus])

  return (
    <Modal
      ariaDescribedBy={idSetCancelledInvoiceDoneModal}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={
          documentIsCancelledCreditNote
            ? I18n.t(
                'bookkeepingTranslations.setCancelledDocumentDoneModal.titleCreditNote',
              )
            : I18n.t(
                'bookkeepingTranslations.setCancelledDocumentDoneModal.titleInvoice',
              )
        }
        titleId={idSetCancelledInvoiceDoneModal}
      />
      {updateStatus === REQUEST_STATUS.LOADING ? (
        <div className='uk-flex uk-flex-center uk-padding'>
          <Spinner name='circle' />
        </div>
      ) : (
        <Formik
          initialValues={{
            payment_fields_hidden: false,
            payment_method: document?.payment_method,
            comment: '',
            paid_at: undefined,
            transaction_key: document?.transaction_key ?? '',
          }}
          onSubmit={(values: SetCancelledDocumentDoneFormValues) =>
            handleSubmit(values)
          }
          validationSchema={yup.object().shape({
            comment: yup.string(),
            payment_fields_hidden: yup.boolean(),
            transaction_key: yup.string(),
          })}
          validate={(values: SetCancelledDocumentDoneFormValues) => {
            const errors = {}

            if (!values.payment_fields_hidden && !values.paid_at) {
              errors['paid_at'] = I18n.t(
                'bookkeepingTranslations.setCancelledDocumentDoneModal.fields.paidAtRequired',
              )
            }

            return errors
          }}
        >
          <Form
            className='set-cancelled-document-open-form'
            data-testid='set-cancelled-document-open-form'
            noValidate
          >
            <SetCancelledDocumentDoneForm
              isCreditNote={documentIsCancelledCreditNote}
              onClose={onClose}
            />
          </Form>
        </Formik>
      )}
    </Modal>
  )
}
