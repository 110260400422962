import './style.scss'

import { Field, Form, Formik } from 'formik'
import React, { FC, useEffect } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-scroll'
import Spinner from 'react-spinkit'
import * as yup from 'yup'

import {
  addCompanyNote,
  getCompanyNotes,
  resetCompanyNotes,
} from 'actions/company'
import { BUTTON_TYPE } from 'components/common/Button'
import Icon, { ICON_NAME } from 'components/common/Fontello'
import {
  BUTTON_BACKGROUND_COLOR,
  ProgressButton,
} from 'components/common/ProgressButton'
import { Textarea } from 'components/common/Textarea'
import {
  getCompanyNotesPaginationSelector,
  getCompanyNotesSelector,
} from 'selectors/company'
import { createLoadingSelector } from 'selectors/loading'

import { Note } from './Note'

interface CompanyNotesProps {
  company: Company
  className: string
}

export const CompanyNotes: FC<CompanyNotesProps> = ({ company, className }) => {
  // Redux
  const dispatch = useDispatch()
  const isLoading = useSelector(createLoadingSelector(['GET_COMPANY_NOTES']))
  const notes = useSelector(getCompanyNotesSelector)
  const pagination = useSelector(getCompanyNotesPaginationSelector)

  useEffect(() => {
    if (!isLoading && !notes) {
      dispatch(getCompanyNotes({ companyId: company.id }))
    }
  }, [company.id, dispatch, isLoading, notes])

  useEffect(() => {
    dispatch(resetCompanyNotes())
  }, [company.id, dispatch])

  if (isLoading) {
    return <Spinner name='circle' />
  }

  return (
    <>
      <div id='company-note-new' className={className}>
        <Formik
          initialValues={{
            note_content: '',
          }}
          onSubmit={(values, formikBag) => {
            dispatch(
              addCompanyNote(company.id, values.note_content, () =>
                formikBag.setFieldValue('note_content', ''),
              ),
            )
          }}
          validationSchema={() =>
            yup.object().shape({
              note_content: yup.string().required(),
            })
          }
        >
          <Form>
            <Field
              as={Textarea}
              maxLength={Number.MAX_SAFE_INTEGER}
              rows={25}
              name='note_content'
              placeholder={I18n.t(
                'companyNotesTranslations.newNotePlaceholder',
              )}
            />
            <ProgressButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              className='uk-margin-top uk-float-right uk-width-1-1 uk-width-1-3@m'
              type={BUTTON_TYPE.SUBMIT}
              isLoading={isLoading}
              onClick={() => undefined}
            >
              <Translate value='companyNotesTranslations.saveNote' />
            </ProgressButton>
          </Form>
        </Formik>
      </div>
      <div className='uk-clearfix' />
      <div id='copmany-notes' className='uk-margin-top uk-width-1-1'>
        {notes ? (
          notes?.map(note => <Note note={note} key={note.id} />)
        ) : (
          <Translate value='companyNotesTranslations.noNotes' />
        )}
      </div>
      {pagination?.next && (
        <div className='uk-margin-top uk-width-1-1'>
          <Link
            className='more-notes-link'
            to='notes'
            offset={100}
            duration={1000}
            smooth
            onClick={() => {
              if (!isLoading) {
                dispatch(
                  getCompanyNotes({
                    companyId: company.id,
                    page: 1,
                    page_size: Number.MAX_SAFE_INTEGER,
                  }),
                )
              }
            }}
          >
            <Icon
              className='more-notes-icon uk-margin-right'
              name={ICON_NAME.PLUS}
            />
            <Translate value='companyNotesTranslations.moreNotes' />
          </Link>
        </div>
      )}
    </>
  )
}
