import './style.scss'

import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router'

import withErrorBoundary from 'helper/withErrorBoundary'
import { isSnapshot } from 'helper/snapshots'
import { getDefaultPageRedirect } from 'helper/router'
import { resetApiFetchErrors } from 'actions/app'
import { cookiesAcceptedSelector } from 'selectors/app'
import { getUserPermissionsSelector } from 'selectors/user'

import { Headline, HEADLINE_TAG } from '../../common/Headline'
import Paragraph from '../../common/Paragraph'
import { CookieConsentAlert } from '../../general/CookieConsentAlert'

import { LoginForm } from './LoginForm'

export const LoginComponent: FC = () => {
  const dispatch = useDispatch()
  const location = useLocation<any>()
  const permissions = useSelector<{ [key: string]: any }, string[]>(state =>
    getUserPermissionsSelector(state),
  )
  const cookiesAccepted = useSelector<any, boolean>(state =>
    cookiesAcceptedSelector(state),
  )

  if (permissions && permissions.length > 0) {
    dispatch(resetApiFetchErrors('LOGIN'))

    if (location && location.state && location.state.from !== '') {
      return <Redirect to={location.state.from} push />
    }
    return <Redirect to={getDefaultPageRedirect(permissions)} push />
  }

  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.login')}</title>
        <meta name='Description' content={I18n.t('pageDescriptions.login')} />
      </Helmet>

      <div className='login-container uk-align-center uk-width-1-1@s uk-width-3-5@l uk-width-1-3@xl'>
        <div className='uk-card uk-card-default'>
          <div className='uk-card-body'>
            <Headline noMargin={!cookiesAccepted} tag={HEADLINE_TAG.H2}>
              <Translate value='login.title' />
            </Headline>

            {/* We need this wrapper so the structure won't change in SSR. */}
            <div>{(cookiesAccepted || isSnapshot()) && <LoginForm />}</div>
          </div>
        </div>

        {!cookiesAccepted && !isSnapshot() && (
          <CookieConsentAlert>
            <Paragraph>
              <Translate value='cookieConsent.error.login' />
            </Paragraph>
          </CookieConsentAlert>
        )}
      </div>
    </>
  )
}

export const Login = withErrorBoundary(LoginComponent)
