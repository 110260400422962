import { useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getContracts } from 'actions/dsz'
import { resetValidateIBAN, validateIBAN } from 'actions/maklerpremium'
import { DropDownInput } from 'components/common/DropDownInput'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { InputNumber } from 'components/common/InputNumber'
import { InputText } from 'components/common/InputText'
import { RadioButton } from 'components/common/RadioButton'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { getShop } from 'components/dsz/helpers'
import { getDszContractsSelector } from 'selectors/dsz'
import { createLoadingSelector } from 'selectors/loading'
import { getCustomInvoiceValidatedIBANErrorMessage } from 'selectors/maklerpremium'
import { getCustomTaxSelector } from 'selectors/pricecalc'

import {
  DSZAddInvoiceFormContext,
  DSZAddInvoiceFormValues,
} from '../DSZAddInvoiceFormSteps'

export const AssignedContractForm: FC = () => {
  const { errors, setFieldValue, handleChange, submitCount, values } =
    useFormikContext<DSZAddInvoiceFormValues>()

  const dispatch = useDispatch()
  const customTaxObject = useSelector(getCustomTaxSelector)

  const ibanError = useSelector(getCustomInvoiceValidatedIBANErrorMessage)
  const ibanValidationIsLoading = useSelector(
    createLoadingSelector(['VALIDATE_IBAN']),
  )

  const { stepStatus, saveStep, isInvoice, toggleIsInvoice } = useContext(
    DSZAddInvoiceFormContext,
  )
  const [isAssignableToContract, setIsAssignableToContract] = useState(true)

  // Get assignable contracts
  useEffect(() => {
    const filters = {
      company: `${values.selected_company?.id}`,
    }
    dispatch(getContracts(undefined, filters, 1000))
  }, [dispatch, values.selected_company])

  // Get results of API-Request
  const contracts = useSelector(getDszContractsSelector)
  // Parse Order-objects into Option array for
  const contractOptions = useMemo(
    () =>
      contracts.map(contract => {
        const shop = getShop(contract.shop)
        return {
          label: `${contract.id}, ${I18n.t(shop!.translationKey) ?? 'k.A.'}, ${
            contract.year
          }, ${moment(contract.created_at).format('L LT')}`,
          value: `${contract.id}`,
        }
      }),
    [contracts],
  )

  // Find the selected order and the corresponding option object
  const selectedContractOption = useMemo(() => {
    if (values.assigned_contract) {
      const shop = getShop(values.assigned_contract.shop)
      return {
        label: `${values.assigned_contract.id}, ${
          I18n.t(shop!.translationKey) ?? 'k.A.'
        }, ${values.assigned_contract.year}, ${moment(
          values.assigned_contract.created_at,
        ).format('L LT')}`,
        value: `${values.assigned_contract.id}`,
      }
    }
    return contractOptions.find(
      contractOption => contractOption.value === `${values.assigned_contract}`,
    )
  }, [contractOptions, values.assigned_contract])

  const isFieldValid = name =>
    values[name] ? `${values[name]}`.length > 0 && !errors[name] : false
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0
      ? (errors[name] as string)
      : undefined

  const getIbanError = () => {
    if (!isInvoice) {
      // If the validate function of Formik throws an error, return it
      if (errors.iban) return errors.iban as string

      // If backend validated the IBAN and returned an error, display the error
      if (ibanError) return ibanError as string
    }
    return ''
  }

  return (
    <>
      {/* assignable to order*/}
      <label>
        {I18n.t(
          'addInvoicePageTranslations.dsz.steps.2.fields.assignable_to_contract.label',
        )}
      </label>
      <div className='uk-flex-left margin-regular-top' data-uk-grid='uk-grid'>
        <RadioButton
          isChecked={!isAssignableToContract}
          label={I18n.t('general.no')}
          name='assignable_to_order_no'
          onChange={() => {
            setIsAssignableToContract(!isAssignableToContract)
            setFieldValue('assigned_contract', undefined)
          }}
        />
        <RadioButton
          isChecked={isAssignableToContract}
          label={I18n.t('general.yes')}
          name='assignable_to_order_yes'
          onChange={() => {
            setIsAssignableToContract(!isAssignableToContract)
          }}
        />
      </div>

      {/* assigned contract */}
      {isAssignableToContract && (
        <StaticCombobox
          error={getFieldError}
          label={I18n.t(
            'addInvoicePageTranslations.dsz.steps.2.fields.assigned_contract.label',
          )}
          name='assigned_contract'
          noResultsText={I18n.t(
            'addInvoicePageTranslations.dsz.steps.2.fields.assigned_contract.noResultsText',
          )}
          options={contractOptions}
          onSelectionChange={e => {
            const contract = contracts.find(
              contract => `${contract.id}` === e.target.value,
            )

            setFieldValue('assigned_contract', contract, false)
            setFieldValue('licensing_year', contract!.year)
          }}
          selectedOption={selectedContractOption}
          showCheckmark={isFieldValid}
          withCheckmark
        />
      )}

      {/* invoice or credit note*/}
      <div className='uk-margin-top'>
        <label>
          {I18n.t(
            'addInvoicePageTranslations.dsz.steps.2.fields.invoice_or_credit_note.label',
          )}
        </label>
      </div>
      <div className='uk-flex-left margin-regular-top' data-uk-grid='uk-grid'>
        <RadioButton
          isChecked={isInvoice}
          isDisabled
          label={I18n.t(
            'addInvoicePageTranslations.dsz.steps.2.fields.invoice_or_credit_note.invoice',
          )}
          name='invoice_or_credit_note_invoice'
          onChange={() => {
            dispatch(resetValidateIBAN())
            setFieldValue('iban', '', false)
            toggleIsInvoice()
          }}
        />
        <RadioButton
          isChecked={!isInvoice}
          isDisabled
          label={I18n.t(
            'addInvoicePageTranslations.dsz.steps.2.fields.invoice_or_credit_note.credit_note',
          )}
          name='invoice_or_credit_note_credit_note'
          onChange={() => {
            toggleIsInvoice()
          }}
        />
      </div>

      {!isInvoice && (
        <InputText
          label={I18n.t(
            'addInvoicePageTranslations.dsz.steps.2.fields.iban.label',
          )}
          maxLength={40}
          name='iban'
          onChange={e => {
            setFieldValue('iban', e.target.value)
          }}
          onBlur={() => {
            if (values.iban) {
              dispatch(validateIBAN({ iban: values.iban }))
            } else {
              dispatch(resetValidateIBAN())
            }
          }}
          placeholder={I18n.t(
            'addInvoicePageTranslations.dsz.steps.2.fields.iban.placeholder',
          )}
          value={values.iban}
          error={getIbanError}
          withCheckmark
          showCheckmark={!getIbanError()}
        />
      )}

      <InputNumber
        name='licensing_year'
        onChange={handleChange}
        label={I18n.t(
          'addInvoicePageTranslations.dsz.steps.2.fields.licensing_year.label',
        )}
        withCheckmark
        value={values.licensing_year ?? ''}
        disabled={!!values.assigned_contract}
        showCheckmark={!errors.licensing_year}
      />

      {customTaxObject && values.assigned_vat && (
        <DropDownInput
          choices={
            customTaxObject.length !== 0
              ? Object.values(customTaxObject).map((choice: any) => ({
                  optionValue: choice.tax_in_percent,
                  optionLabel: `${parseFloat(
                    choice.tax_in_percent,
                  ).toLocaleString('de-DE', { minimumFractionDigits: 0 })} %`,
                }))
              : [
                  {
                    optionValue: values.assigned_vat,
                    optionLabel: `${values.assigned_vat.toLocaleString(
                      'de-DE',
                      { minimumFractionDigits: 0 },
                    )} %`,
                  },
                ]
          }
          name='assigned_vat'
          onChange={handleChange}
          label={I18n.t(
            'addInvoicePageTranslations.dsz.steps.2.fields.assigned_vat.label',
          )}
          value={values.assigned_vat}
          withCheckmark
        />
      )}

      <div className='uk-margin-top'>
        <GoToNextFormStepButton
          buttonText={
            stepStatus === STEP_STATUS.EDITING
              ? I18n.t('general.button.save')
              : I18n.t('createInquiryTranslations.form.button.nextStep')
          }
          onClick={() => {
            if (isInvoice && values.iban) {
              setFieldValue('iban', undefined, false)
            }
            saveStep()
          }}
          isLoading={ibanValidationIsLoading}
          isDisabled={
            !!values?.assigned_contract !== isAssignableToContract ||
            !!getIbanError() ||
            !!errors.licensing_year
          }
        />
      </div>
    </>
  )
}
