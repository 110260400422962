import './VisualBackgroundElement.scss'

import React, { FC } from 'react'

import squareLeft from './img/square-left.svg'

/**
 * Generates a full-width component with an square background like the earlier parallax but static.
 * All its content will be wrapped in an default container.
 * @constructor
 */
export const VisualBackgroundElement: FC = ({ children }) => (
  <div className='visual-background-element'>
    <img
      src={squareLeft}
      className='visual-background-element__image'
      alt=''
      aria-hidden='true'
    />
    <div className='visual-background-element__children'>{children}</div>
  </div>
)
