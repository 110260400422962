import ActionTypes from '../actiontypes/discount'

/** @function
 * @name getDiscounts
 * @description Retrieves discounts.
 * @return {Object}
 */
export const getDiscounts = (page, filters, sortedBy) => ({
  type: ActionTypes.GET_DISCOUNTS_REQUEST,
  payload: {
    page,
    filters,
    sortedBy,
  },
})

/**
 * @function
 * @name getDiscount
 * @param id
 * @return {Object}
 */
export const getDiscount = id => ({
  type: ActionTypes.GET_DISCOUNT_REQUEST,
  payload: {
    id,
  },
})

/** @function
 * @name createDiscount
 * @description An action method to create discount.
 * @return {Object}
 */
export const createDiscount = (discount, callback) => ({
  type: ActionTypes.CREATE_DISCOUNT_REQUEST,
  payload: {
    discount,
    callback,
  },
})

/** @function
 * @name redeemDiscount
 * @description An action method to redeem discount.
 * @return {Object}
 */
export const redeemDiscount = (code, callback) => ({
  type: ActionTypes.REDEEM_DISCOUNT_REQUEST,
  payload: {
    code,
    callback,
  },
})

/** @function
 * @name updateDiscountStatus
 * @description An action method to update discount status.
 * @return {Object}
 */
export const updateDiscountStatus = discount => ({
  type: ActionTypes.UPDATE_DISCOUNT_STATUS_REQUEST,
  payload: discount,
})

/** @function
 * @name exportRedeemedDiscounts
 * @description An action method to export redeemed discounts.
 * @return {Object}
 */
export const exportRedeemedDiscounts = filters => ({
  type: ActionTypes.EXPORT_REDEEMED_DISCOUNTS_REQUEST,
  payload: {
    filters,
  },
})
