import FileDownload from 'js-file-download'
import moment from 'moment/moment'
import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import InvoiceActionTypes from '../actiontypes/invoice'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetInvoices
 * @description gets list of invoices
 */
export const handleGetInvoices = createLogic({
  type: InvoiceActionTypes.GET_INVOICES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload) {
      data = { page: action.payload.page || 1 }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }

      if (action.payload.sortedBy && action.payload.sortedBy.length) {
        data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
      }
    }
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoice/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceActionTypes.GET_INVOICES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.invoice.handleGetDocuments.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceActionTypes.GET_INVOICES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetInvoice
 * @description gets a single invoice.
 */
export const handleGetInvoice = createLogic({
  type: InvoiceActionTypes.GET_INVOICE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoice/${action.payload}/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceActionTypes.GET_INVOICE_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.invoice.handleGetDocument.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceActionTypes.GET_INVOICE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateEPDDocument
 * @description handles updating an EPD document.
 */
export const handleUpdateEPDDocument = createLogic({
  type: InvoiceActionTypes.UPDATE_INVOICE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const document_id =
      action.payload.invoice_id ?? action.payload.credit_note_id
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoice/${document_id}/`

    httpClient
      .patch(url, action.payload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceActionTypes.UPDATE_INVOICE_SUCCESS,
          payload: res.document ? res.document : res,
        })

        if (!res.hideToastr && res.disposal && res.disposal.length > 0) {
          res.disposal.forEach(entry => {
            toastr.success('', entry)
          })
        }

        toastr.success(
          '',
          I18n.t('api.invoice.handleUpdateEPDDocument.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.invoice.handleUpdateEPDDocument.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceActionTypes.UPDATE_INVOICE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateEPDCancelledDocument
 * @description handles updating a cancelled EPD document.
 */
export const handleUpdateEPDCancelledDocument = createLogic({
  type: InvoiceActionTypes.UPDATE_EPD_CANCELLED_DOCUMENT_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const document_id =
      action.payload.invoice_id ?? action.payload.credit_note_id
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoice/cancelled-document/${document_id}/`

    httpClient
      .patch(url, action.payload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: InvoiceActionTypes.UPDATE_EPD_CANCELLED_DOCUMENT_SUCCESS,
          payload: res.document ? res.document : res,
        })

        if (!res.hideToastr && res.disposal && res.disposal.length > 0) {
          res.disposal.forEach(entry => {
            toastr.success('', entry)
          })
        }

        toastr.success(
          '',
          I18n.t('api.invoice.handleUpdateEPDDocument.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.invoice.handleUpdateEPDDocument.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceActionTypes.UPDATE_EPD_CANCELLED_DOCUMENT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportInvoices
 * @description triggers the invoice export.
 */
export const handleExportInvoices = createLogic({
  type: InvoiceActionTypes.EXPORT_INVOICES_REQUEST,
  latest: true,

  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoice/export/`)
      .then(res => {
        FileDownload(
          res.data,
          `empto_Belege_${moment().format('YYYYMMDD')}.csv`,
        )
        dispatch({
          type: InvoiceActionTypes.EXPORT_INVOICES_SUCCESS,
        })
      })
      .then(done)
      .catch(() => {
        done()
      })
  },
})

/**
 * @function
 * @name handleExportEPDBatchFile
 * @description triggers the invoice export for epd.
 */
export const handleExportEPDBatchFile = createLogic({
  type: InvoiceActionTypes.EXPORT_BATCH_FILE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { filters, sortedBy } = action.payload
    let data = {}

    if (filters) {
      data = { ...data, ...filters }
    }

    if (sortedBy && sortedBy.length) {
      data = { ...data, order_by: JSON.stringify(sortedBy) }
    }

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoice/export-batch-file/`,
        data,
      )
      .then(() => {
        dispatch({
          type: InvoiceActionTypes.EXPORT_BATCH_FILE_SUCCESS,
        })
        toastr.success(
          '',
          I18n.t('api.invoice.handleEPDExportBatchfile.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.invoice.handleEPDExportBatchfile.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceActionTypes.EXPORT_BATCH_FILE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportEPDMaklerPremiumInvoices
 * @description triggers the invoice export for epd.
 */
export const handleExportEPDMaklerPremiumInvoices = createLogic({
  type: InvoiceActionTypes.EXPORT_EPD_MAKLER_PREMIUM_INVOICES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload.filters) {
      data = { ...data, ...action.payload.filters }
    }

    if (action.payload.sortedBy && action.payload.sortedBy.length) {
      data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
    }

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoice/export-makler-premium-invoices/`,
        data,
      )
      .then(() => {
        dispatch({
          type: InvoiceActionTypes.EXPORT_EPD_MAKLER_PREMIUM_INVOICES_SUCCESS,
        })

        toastr.success(
          '',
          I18n.t('api.invoice.handleEPDExportInvoices.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.invoice.handleEPDExportInvoices.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceActionTypes.EXPORT_EPD_MAKLER_PREMIUM_INVOICES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportDSZMaklerPremiumInvoices
 * @description triggers the invoice export for epd.
 */
export const handleExportDSZMaklerPremiumInvoices = createLogic({
  type: InvoiceActionTypes.EXPORT_DSZ_MAKLER_PREMIUM_INVOICES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload.filters) {
      data = { ...data, ...action.payload.filters }
    }

    if (action.payload.sortedBy && action.payload.sortedBy.length) {
      data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
    }

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/dsz/invoice/export_makler_premium_invoices/`,
        data,
      )
      .then(() => {
        dispatch({
          type: InvoiceActionTypes.EXPORT_DSZ_MAKLER_PREMIUM_INVOICES_SUCCESS,
        })

        toastr.success('', I18n.t('general.handleCSVExport.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('general.handleCSVExport.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceActionTypes.EXPORT_DSZ_MAKLER_PREMIUM_INVOICES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportCollectiveInvoicingForecast
 * @description triggers the collective invoicing forecast export for epd.
 */
export const handleExportEPDCollectiveInvoicingForecast = createLogic({
  type: InvoiceActionTypes.EXPORT_EPD_COLLECTIVE_INVOICING_FORECAST_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload.filters) {
      data = { ...data, ...action.payload.filters }
    }

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoice/export-collective-invoicing-forecast/`,
        data,
      )
      .then(() => {
        dispatch({
          type: InvoiceActionTypes.EXPORT_EPD_COLLECTIVE_INVOICING_FORECAST_SUCCESS,
        })

        toastr.success('', I18n.t('general.handleCSVExport.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('general.handleCSVExport.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceActionTypes.EXPORT_EPD_COLLECTIVE_INVOICING_FORECAST_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportEPDCollectiveInvoicingActivities
 * @description triggers the collective invoicing activities export for epd.
 */
export const handleExportEPDCollectiveInvoicingActivities = createLogic({
  type: InvoiceActionTypes.EXPORT_EPD_COLLECTIVE_INVOICING_ACTIVITIES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload?.filters) {
      data = { ...data, ...action.payload.filters }
    }

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoice/export-collective-invoicing-activities/`,
        data,
      )
      .then(() => {
        dispatch({
          type: InvoiceActionTypes.EXPORT_EPD_COLLECTIVE_INVOICING_ACTIVITIES_SUCCESS,
        })

        toastr.success('', I18n.t('general.handleCSVExport.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('general.handleCSVExport.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceActionTypes.EXPORT_EPD_COLLECTIVE_INVOICING_ACTIVITIES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportEPDCollectiveInvoicing
 * @description triggers the collective invoicing export for epd.
 */
export const handleExportEPDCollectiveInvoicing = createLogic({
  type: InvoiceActionTypes.EXPORT_EPD_COLLECTIVE_INVOICING_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload?.filters) {
      data = { ...data, ...action.payload.filters }
    }

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/invoice/export-collective-invoicing/`,
        data,
      )
      .then(() => {
        dispatch({
          type: InvoiceActionTypes.EXPORT_EPD_COLLECTIVE_INVOICING_SUCCESS,
        })

        toastr.success('', I18n.t('general.handleCSVExport.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('general.handleCSVExport.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: InvoiceActionTypes.EXPORT_EPD_COLLECTIVE_INVOICING_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [
  handleGetInvoices,
  handleGetInvoice,
  handleUpdateEPDDocument,
  handleUpdateEPDCancelledDocument,
  handleExportInvoices,
  handleExportEPDBatchFile,
  handleExportEPDMaklerPremiumInvoices,
  handleExportDSZMaklerPremiumInvoices,
  handleExportEPDCollectiveInvoicingForecast,
  handleExportEPDCollectiveInvoicingActivities,
  handleExportEPDCollectiveInvoicing,
]
