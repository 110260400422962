import { connect } from 'react-redux'

import { getFractions } from 'actions/fraction'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'
import { getOrderSelector } from 'selectors/order'

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  order: getOrderSelector(state),
  fractionList: getFractionsSelector(state),
  containerList: getContainersSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getFractions: () => dispatch(getFractions()),
})

export default connect(mapStateToProps, mapDispatchToProps)
