import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from '../../common/Headline'

interface BenefitItemProps {
  imageUrl: string
  headlineTitle: string
  headlineText: string
  centered?: boolean
}

export const BenefitItem: FC<BenefitItemProps> = ({
  imageUrl,
  headlineTitle,
  headlineText,
  centered,
}) => (
  <div
    className={classNames({
      'benefit-item': true,
      'benefit-item--centered': centered,
    })}
  >
    <div className='benefit-item__image'>
      <img alt='' src={imageUrl} aria-hidden='true' />
    </div>
    <Headline
      className={classNames({
        'benefit-item__headline': true,
        'benefit-item__headline--centered': centered,
      })}
      color={HEADLINE_COLOR.GRAYISH_BROWN}
      fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
      fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
      headlineStyle={HEADLINE_STYLE.H3}
      tag={HEADLINE_TAG.H3}
      noMargin
    >
      <Translate value={headlineTitle} />
    </Headline>
    <p
      className={classNames({
        'benefit-item__caption': true,
        'benefit-item__caption--centered': centered,
      })}
    >
      <Translate value={headlineText} dangerousHTML />
    </p>
  </div>
)
