import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import * as UserActions from 'actions/user'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

const loadingSelectorGet = createLoadingSelector(['ACTIVATE_CURRENT_USER'])
const errorSelector = createErrorSelector(['ACTIVATE_CURRENT_USER'])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  activation: state.user.activation,
  isLoading: loadingSelectorGet(state),
  error: errorSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  actions: {
    user: bindActionCreators(UserActions, dispatch),
  },
})

export default connect(mapStateToProps, mapDispatchToProps)
