import { Form, Formik, FormikErrors } from 'formik'
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'
import * as yup from 'yup'

import withErrorBoundary from 'helper/withErrorBoundary'

import { FormStep } from '../../common/FormSteps'
import { progressStepStatus, STEP_STATUS } from '../../common/FormSteps/helper'

import { CustomerInfoForm, CustomerInfoSummary } from './Step1'
import { KeyDataForm, KeyDataSummary } from './Step2'
import { ConditionsForm } from './Step3'

type TVPContractContextType = {
  stepStatus: STEP_STATUS
  saveStep: () => void
  editStep: () => void
  contract?: TVPContract
  handleCloseModal: () => void
}

export const TVPContractFormContext = createContext<TVPContractContextType>({
  stepStatus: STEP_STATUS.INITIAL,
  saveStep: () => undefined,
  editStep: () => undefined,
  contract: undefined,
  handleCloseModal: () => undefined,
})

interface TVPContractFormStepsValues {
  non_field_errors: any
  // Step 1: CustomerInfo
  company_id: number
  // Step 2: KeyData
  // Step 3: Conditions
}

export type TVPContractFormValues = Partial<TVPContractFormStepsValues>

interface TVPContractFormStepsProps {
  contract?: TVPContract // @TODO need this for editing contracts after step 3 is done
}

const TVPContractFormStepsComponent = ({
  // @TODO need this for editing contracts after step 3 is done
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  contract,
}: TVPContractFormStepsProps) => {
  // @TODO dispatch redux logic in step 3
  // const dispatch = useDispatch();

  const initialStepsStatus = useMemo(
    () => [
      STEP_STATUS.COMPLETED,
      STEP_STATUS.CREATING,
      STEP_STATUS.INITIAL,
      STEP_STATUS.INITIAL,
    ],
    [],
  )

  const [stepsStatus, setStepsStatus] =
    useState<STEP_STATUS[]>(initialStepsStatus)

  // reset steps on submit / cancel
  const onCloseModal = useCallback(() => {
    setStepsStatus(initialStepsStatus)
  }, [initialStepsStatus])

  // @TODO step 3
  // const createRequestStatus = useSelector(createRequestStatusSelector(['CREATE_TVP_CONTRACT']));
  // useEffect(() => {
  //   if (createRequestStatus === REQUEST_STATUS.SUCCESS) {
  //     dispatch(resetApiFetchLoading('CREATE_TVP_CONTRACT'));
  //     onCloseModal();
  //   } else if (createRequestStatus === REQUEST_STATUS.ERROR) {
  //     dispatch(resetApiFetchLoading('CREATE_TVP_CONTRACT'));
  //   }
  // }, [createRequestStatus, dispatch, onCloseModal]);
  //
  // // reset loading state and close modal
  // const updateRequestStatus = useSelector(createRequestStatusSelector(['UPDATE_TVP_CONTRACT']));
  // useEffect(() => {
  //   if (updateRequestStatus === REQUEST_STATUS.SUCCESS) {
  //     dispatch(resetApiFetchLoading('UPDATE_TVP_CONTRACT'));
  //     onCloseModal();
  //   } else if (updateRequestStatus === REQUEST_STATUS.ERROR) {
  //     dispatch(resetApiFetchLoading('UPDATE_TVP_CONTRACT'));
  //   }
  // }, [dispatch, onCloseModal, updateRequestStatus]);
  //
  // // reset loading state and close modal
  // const deletePositionRequestStatus = useSelector(createRequestStatusSelector(['DELETE_TVP_CONTRACT']));
  // useEffect(() => {
  //   if (deletePositionRequestStatus === REQUEST_STATUS.SUCCESS) {
  //     dispatch(resetApiFetchLoading('DELETE_TVP_CONTRACT'));
  //     onCloseModal();
  //   } else if (deletePositionRequestStatus === REQUEST_STATUS.ERROR) {
  //     dispatch(resetApiFetchLoading('DELETE_TVP_CONTRACT'));
  //   }
  // }, [deletePositionRequestStatus, dispatch, onCloseModal]);

  const formSteps = {
    // Step 1: CollectionAddress
    1: { form: <CustomerInfoForm />, summary: <CustomerInfoSummary /> },
    // Step 2: OrderAndServices
    2: { form: <KeyDataForm />, summary: <KeyDataSummary /> },
    // Step 3: PositionsSummary
    3: { form: <ConditionsForm />, summary: <></> },
  }

  function renderStep(
    index: string,
    step: { summary: ReactNode; form: ReactNode },
  ) {
    return stepsStatus[Number(index)] > STEP_STATUS.INITIAL &&
      stepsStatus[Number(index)] === STEP_STATUS.COMPLETED
      ? step.summary
      : step.form
  }

  const initialValues = {
    non_field_errors: undefined, // dummy value for non-field-errors, DO NOT USE in Form
    // Step 1: CustomerInfo
    // Step 2: KeyData
    // Step 3: Conditions
  }

  const validationSchema = yup.object().shape({
    // Step 1: CustomerInfo
    // Step 2: KeyData
    // Step 3: Conditions
  })

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={(values: TVPContractFormValues) => {
        const errors: FormikErrors<TVPContractFormValues> = {}
        if (values) {
          // @TODO dummy error
          errors.non_field_errors = ''
        }

        return errors
      }}
      onSubmit={(values: TVPContractFormValues, formikHelpers) => {
        // @TODO dispatch logic
        // if (contract && contract.id) {
        //   dispatch(updateTVPContractPosition(values, invoiceCheckPosition.id));
        // } else {
        //   dispatch(createTVPContractPosition(values));
        // }

        formikHelpers.setSubmitting(false)

        // Reset Form Values
        formikHelpers.setValues({ ...initialValues })
      }}
    >
      <Form>
        <div className='uk-modal-body'>
          {Object.entries(formSteps).map(([index, step]) => (
            <FormStep
              key={`step_${index}`}
              isDisabled={
                stepsStatus[Number(index) - 1] < STEP_STATUS.COMPLETED
              }
              showDivider
              showProgressLine
              stepNumber={
                stepsStatus[Number(index)] < STEP_STATUS.COMPLETED &&
                Number(index)
              }
              title={`TVPContractPageTranslations.steps.${index}.title`}
            >
              <TVPContractFormContext.Provider
                value={{
                  stepStatus: stepsStatus[Number(index)],
                  saveStep: () => {
                    progressStepStatus(
                      stepsStatus,
                      setStepsStatus,
                      Number(index),
                    )
                  },
                  editStep: () => {
                    progressStepStatus(
                      stepsStatus,
                      setStepsStatus,
                      Number(index),
                    )
                  },
                  handleCloseModal: onCloseModal,
                }}
              >
                {renderStep(index, step)}
              </TVPContractFormContext.Provider>
            </FormStep>
          ))}
        </div>
      </Form>
    </Formik>
  )
}

export const TVPContractFormSteps: FC<TVPContractFormStepsProps> =
  withErrorBoundary(TVPContractFormStepsComponent)
