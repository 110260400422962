import 'react-table/react-table.css'

import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'
import Spinner from 'react-spinkit'

import { USER_GENDER_LABELS } from 'helper/user'
import { checkRequiredPermissions } from 'helper/permissions'
import { EmptoGroups, UserPermission } from 'constants/user'

import Icon from '../../common/Fontello'
import { PaginatedTable } from '../../common/PaginatedTable'
import { Switcher } from '../../common/Switcher'
import { UpDownArrow } from '../../common/UpDownArrow'
import UserLink from '../../common/UserLink'
import UserImg from '../../layout/Header/UserDropdown/components/UserInfo/components/UserImg'
import { USER_STATES, USER_STATUS } from '../constants'

/**
 * @description This component lists the company users editing features.
 * @function
 * @param {Object} props the component props
 */
interface UserListComponentProps {
  isLoading: boolean
  user: User
  userList: User[]
  page: number
  pages: number
  onFetchData: () => void
  onPreviousPageClick: () => void
  onNextPageClick: () => void
  onEditUser: (userData: any) => void
  onStatusChange: (usrData: any, id: number) => void
}

export const UserList: FC<UserListComponentProps> = ({
  isLoading,
  onEditUser,
  onFetchData,
  onNextPageClick,
  onPreviousPageClick,
  onStatusChange,
  page,
  pages,
  user,
  userList,
}) => {
  const isAllowedToChangeUsers = checkRequiredPermissions(
    user.permission_codenames,
    [UserPermission.CHANGE_OTHER_USERS],
  )
  const isAllowedToSeeAddresses = checkRequiredPermissions(
    user.permission_codenames,
    [UserPermission.ADD_ADDRESS],
  )
  const isEmptoAdmin =
    !user.company && user.group_id === EmptoGroups.EMPTO_ADMIN

  const profilePictureColumn = {
    id: 'userImage',
    Header: '',
    width: 60,
    sortable: false,
    accessor: 'profile_image_object',
    Cell: data => <UserImg profile_image_object={data.value} />,
  }

  const genderColumn = {
    Header: (
      <span>
        <Translate value='general.placeholder.gender' />
        <UpDownArrow />
      </span>
    ),
    width: 100,
    accessor: 'gender',
    Cell: data => USER_GENDER_LABELS()[Number(data.value)],
  }

  const userNameColumn = {
    id: 'last_name',
    Header: (
      <span>
        <Translate value='general.name' />
        <UpDownArrow />
      </span>
    ),
    accessor: data => (
      <UserLink
        userName={`${data.first_name} ${data.last_name}`}
        userStatus={data.status}
        userId={data.id}
      />
    ),
  }

  const companyColumn = {
    id: 'company__name',
    Header: (
      <span>
        <Translate value='general.company' />
        <UpDownArrow />
      </span>
    ),
    accessor: data =>
      data.company_object && (
        <Link to={`/company/${data.company_object.id}`}>
          {data.company_object.name}
        </Link>
      ),
  }

  const emailColumn = {
    id: 'email',
    Header: (
      <span>
        <Translate value='general.placeholder.email' />
        <UpDownArrow />
      </span>
    ),
    accessor: data =>
      data.email && data.email.endsWith('@maklerpremium.example.com') ? (
        <span />
      ) : (
        <span>{data.email}</span>
      ),
  }

  const addressColumn = isAllowedToSeeAddresses
    ? {
        id: 'preferred_address',
        Header: (
          <span>
            <Translate value='general.address' />
            <UpDownArrow />
          </span>
        ),
        accessor: data =>
          data.preferred_address_object
            ? data.preferred_address_object.display_name
            : I18n.t('user.allLocations'),
      }
    : {}

  const groupNameColumn = {
    id: 'groups__name',
    Header: (
      <span>
        <Translate value='general.permissions' />
        <UpDownArrow />
      </span>
    ),
    accessor: 'group_name',
  }

  const userStatusColumn = {
    Header: (
      <span>
        <Translate value='general.status' />
        <UpDownArrow />
      </span>
    ),
    accessor: 'status',
    Cell: data => {
      const allowedStates = USER_STATES.filter(
        state => state.id !== USER_STATUS.STATUS_INVITED,
      )
      const currentState = USER_STATES.find(state => state.id === data.value)
      const isSameCompany = data.original.company === user.company
      const isInvited = currentState!.id === USER_STATUS.STATUS_INVITED

      const canChangeUserStatus =
        isAllowedToChangeUsers && (isEmptoAdmin || !isInvited || isSameCompany)

      return !canChangeUserStatus ? (
        <span>{I18n.t(currentState!.name)}</span>
      ) : (
        <Switcher
          currentValue={currentState!}
          isActive={data.value === USER_STATUS.STATUS_ACTIVE}
          onValueChange={value => onStatusChange(data.original, value.id)}
          values={allowedStates}
        />
      )
    },
  }

  const actionColumn =
    isAllowedToChangeUsers || user.company
      ? {
          id: 'actions',
          Header: I18n.t('general.actions'),
          width: 100,
          sortable: false,
          filterable: false,
          Cell: data => (
            <span className='action-buttons'>
              <button
                type='button'
                className='icon-button'
                onClick={() => onEditUser(data.original)}
                data-uk-tooltip={`title: ${I18n.t('general.button.edit')}`}
                disabled={
                  data.original.company !== user.company &&
                  data.original.company_object.makler_premium_company !==
                    user.company
                }
              >
                <Icon name='pencil' />
              </button>
            </span>
          ),
        }
      : {}

  const columns = [
    profilePictureColumn,
    genderColumn,
    userNameColumn,
    companyColumn,
    emailColumn,
    addressColumn,
    groupNameColumn,
    userStatusColumn,
    actionColumn,
  ]

  return (
    <div className='user-list'>
      {isLoading && userList.length < 1 ? (
        <div className='uk-flex uk-flex-center uk-margin-large-top'>
          <Spinner name='circle' />
        </div>
      ) : (
        <PaginatedTable
          serverSidePagination
          page={page}
          pages={pages}
          loading={isLoading}
          handleShowPreviousPage={onPreviousPageClick}
          handleShowNextPage={onNextPageClick}
          handleChangeStatus={onStatusChange}
          handleEditUser={onEditUser}
          table={{
            columns,
            data: userList,
            onFetchData,
          }}
        />
      )}
    </div>
  )
}
