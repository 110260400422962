export const SquareMetricsTeaserTranslations = {
  squareMetricsTeaser: {
    cta: 'In Kürze hier verfügbar!',
    description:
      'Die Welt der Logistik hat sich durch neue Praktiken wie IoT gewandelt. Square Metrics hat eine ' +
      'Lösung entwickelt, die Ihnen die Verfolgung Ihrer Behälter und Fahrzeuge in Echtzeit ermöglicht.<br /><br />' +
      'In Verbindung mit empto können Sie Ihre Kunden kinderleicht über Statusveränderungen informieren – und auf ' +
      'Wunsch erledigt der digitale Abfallassistent die Abrechnung Ihrer Leistungen noch am gleichen Tag!',
  },
}
