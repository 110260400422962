import { bool, number, shape, string } from 'prop-types'

import { ControlProps } from '../../schemes'

const ActionsScheme = shape({
  form: shape({}),
})

const TextboxControlProps = {
  ...ControlProps,
  actions: ActionsScheme.isRequired,
  locale: string,
  maxLength: number,
  minLength: number,
  showCharsRemaining: bool,
  symbol: string,
  type: string,
  debounce: number,
  addMapProps: shape({}), // Optional additional props to pass to DatePicker's "mapProps"
  allowSubmissionByKey: bool,
  isPassword: bool,
  noNumbers: bool,
  noSpecialChars: bool,
}

export default TextboxControlProps
