import './style.scss'

import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import Media from 'react-media'
import { Element as ScrollElement, scroller } from 'react-scroll'

import { BREAKPOINT } from 'constants/design'

import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_COLOR,
  BUTTON_SHAPE,
  ICON_NAME,
  ICON_POSITION,
  IconButton,
} from '../IconButton'

interface ButtonLearnMoreProps {
  minWidth?: number
  scrollAnchor?: 'string'
}

export const ButtonLearnMore: FC<ButtonLearnMoreProps> = ({
  minWidth,
  scrollAnchor,
}) => {
  const scrollId = scrollAnchor || uniqueId('learn-more-anchor_')

  const onClick = (event: Event) => {
    if (event.currentTarget instanceof HTMLElement) event.currentTarget.blur()

    scroller.scrollTo(scrollId, {
      duration: 1000,
      smooth: true,
      offset: -125, // Scrolls to element + x pixels down the page
    })
  }

  return (
    <Media query={{ minWidth: minWidth || BREAKPOINT.XLARGE }}>
      <>
        <div className='button-learn-more'>
          <IconButton
            className='button-learn-more__button'
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            fontColor={BUTTON_FONT_COLOR.GRAYISH_BROWN}
            iconName={ICON_NAME.ARROW_THIN_DOWN}
            iconPosition={ICON_POSITION.RIGHT}
            onClick={onClick}
            shape={BUTTON_SHAPE.CORNERED}
          >
            <Translate value='buttonLearnMore' />
          </IconButton>
        </div>
        {!scrollAnchor && <ScrollElement name={scrollId} />}
      </>
    </Media>
  )
}
