import './UniversalFileUploadPreviewItem.scss'

import classNames from 'classnames'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import Icon, { ICON_NAME } from '../Fontello'

import { UploadItem } from './types'

interface UniversalFileUploadPreviewItemProps {
  allowRemove?: boolean
  allowRotate?: boolean
  file: UploadItem | Attachment
  onClick?: () => void
  onRemove?: () => void
  onRotate?: () => void
  previewAsCircle?: boolean
  showFilename?: boolean
}

export const UniversalFileUploadPreviewItem: FC<
  UniversalFileUploadPreviewItemProps
> = ({
  allowRemove,
  allowRotate,
  file,
  onClick = () => undefined,
  onRemove = () => undefined,
  onRotate = () => undefined,
  previewAsCircle = true,
  showFilename = false,
}) => (
  <div
    className='universal-file-upload-preview-item'
    title={showFilename ? file.name : undefined}
  >
    <button
      className='universal-file-upload-preview-item__mask'
      type='button'
      onClick={onClick}
    >
      {file.type ? (
        <>
          {/* display an image preview if file type matches any image type */}
          {file.type.includes('image/') && (
            <img
              className={classNames(
                'universal-file-upload-preview-item__image',
                {
                  'universal-file-upload-preview-item__image-circle':
                    previewAsCircle,
                },
              )}
              src={file.file}
              alt={file.name}
              style={{ transform: `rotate(${file.orientation}deg` }}
            />
          )}

          {/* display a document/pdf icon instead of an image preview if file type includes pdf */}
          {file.type.includes('pdf') && (
            <Icon
              className='universal-file-upload-preview-item__icon'
              name={ICON_NAME.FILE_PDF}
            />
          )}
        </>
      ) : (
        <div className='no-portrait-available-icon'>
          <Icon
            className='universal-file-upload-preview-item__icon'
            name='user'
          />
        </div>
      )}

      {/* currently there is no CSV icon - but maybe someday it should be here */}
    </button>

    {/* display an opaque overlay including an message icon to visualize available comments */}
    {file.text && file.text !== '' && (
      // eslint-disable-next-line
      <div
        className='universal-file-upload-preview-item__comment-overlay'
        onClick={onClick}
        aria-hidden
        tabIndex={-1}
      >
        <Icon
          className='universal-file-upload-preview-item__comment-icon'
          name={ICON_NAME.MESSAGE}
        />
      </div>
    )}

    {allowRotate && file.type.includes('image/') && (
      <button
        className={classNames(
          'universal-file-upload-preview-item__control',
          'universal-file-upload-preview-item__control--rotate',
        )}
        data-uk-tooltip={I18n.t('universalFileUpload.previewRotateButton')}
        onClick={onRotate}
        type='button'
      >
        <Icon
          className='universal-file-upload-preview-item__control-icon'
          name={ICON_NAME.CLOCKWISE}
        />
      </button>
    )}

    {allowRemove && (
      <button
        className={classNames(
          'universal-file-upload-preview-item__control',
          'universal-file-upload-preview-item__control--remove',
        )}
        data-uk-tooltip={I18n.t('universalFileUpload.previewRemoveButton')}
        onClick={onRemove}
        type='button'
      >
        <Icon
          className='universal-file-upload-preview-item__control-icon'
          name={ICON_NAME.CROSS}
        />
      </button>
    )}

    {showFilename && (
      <span className='universal-file-upload-preview-item__filename'>
        {file.name}
      </span>
    )}
  </div>
)
