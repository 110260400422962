import { createSelector } from 'reselect'

import { getCoarseFractionItems } from '../components/inquiry/helpers'
import { BUSINESS_SEGMENT } from '../constants/app'

/**
 * @description selector to get fractions list.
 * @param state
 * @return {*}
 */
export const getFractionsSelector: (any) => Fraction[] = state =>
  state.fraction.itemList

/**
 * @description selector to get EPD fractions list.
 * @param state
 * @return {*}
 */
export const getEPDFractionsSelector: (any) => Fraction[] = state =>
  state.fraction.itemList.filter(
    fraction => fraction.business_segment === BUSINESS_SEGMENT.BUSINESS_EPD,
  )

/**
 * Selector to get only the coarse fractions
 * @param state
 * @returns {*}
 */
export const getCoarseFractionsSelector = createSelector(
  getFractionsSelector,
  getCoarseFractionItems,
)

/**
 * Selector to get the content of a specific fraction
 * @param state
 * @param currentFraction
 * @returns {*}
 */
export const getFractionContentSelector = (state, currentFraction) =>
  state.fraction.content.filter(
    content => content.fraction_slug === currentFraction,
  )

/**
 * Selector to get the content of all fractions
 * @param state
 * @returns {*}
 */
export const getFractionsContentSelector = state => state.fraction.content

/**
 * @description selector to get a list of fractions for filter.
 * @param state
 * @return {*}
 */
export const getFractionForFilterSelector: (
  state: any,
) => FractionFilterResponse[] = state =>
  // remove null values
  state.fraction.filterList.filter(item => item && item.name)

export const getFractionIsLoadingForFilterSelector: (
  state: any,
) => boolean = state => state.fraction.filterListIsLoading
