import 'react-table/react-table.css'

import moment from 'moment/moment'
import { arrayOf, bool, func, number, shape } from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import Spinner from 'react-spinkit'

import { getName } from 'helper/translations'
import { checkRequiredPermissions } from 'helper/permissions'
import { UserPermission } from 'constants/user'
import { DiscountScheme } from 'schemes/discount'
import { UserScheme } from 'schemes/user'

import { BUTTON_LINK_COLOR, ButtonLink } from '../../common/ButtonLink'
import { PaginatedTable } from '../../common/PaginatedTable'
import { Switcher } from '../../common/Switcher'
import { UpDownArrow } from '../../common/UpDownArrow'
import {
  DISCOUNT_OCCASIONS,
  DISCOUNT_STATES,
  DISCOUNT_STATUS,
} from '../constants'

/**
 * @description This component lists the discounts.
 * @function
 * @param {Object} props the component props
 */
export class DiscountListComponent extends Component {
  static propTypes = {
    isLoading: bool.isRequired,
    discountList: arrayOf(shape(DiscountScheme)),
    page: number,
    pages: number,
    onFetchData: func.isRequired,
    onPreviousPageClick: func.isRequired,
    onNextPageClick: func.isRequired,
    onEditDiscount: func.isRequired,
    onShowDiscount: func.isRequired,
    onStatusChange: func.isRequired,
    user: shape(UserScheme),
  }

  static defaultProps = {
    discountList: [],
    page: null,
    pages: null,
    user: null,
  }

  /**
   * @description Renders an interactive discounts table
   * @return {*}
   */
  renderDiscountTable() {
    const {
      isLoading,
      onEditDiscount,
      onFetchData,
      onNextPageClick,
      onPreviousPageClick,
      onShowDiscount,
      onStatusChange,
      page,
      pages,
      discountList,
      user,
    } = this.props

    const columns = [
      {
        id: 'code',
        Header: (
          <span>
            <Translate value='discountOverview.fields.code' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'code',
        Cell: data => (
          <ButtonLink
            onClick={() => onShowDiscount(data.original.id)}
            linkColor={BUTTON_LINK_COLOR.PRIMARY}
          >
            {data.value}
          </ButtonLink>
        ),
      },
      {
        id: 'value',
        accessor: data => {
          if (data?.value) {
            return data.value.toLocaleString('de-DE', {
              style: 'currency',
              currency: 'EUR',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }
          if (data?.value_percent) {
            return (data.value_percent / 100).toLocaleString('de-DE', {
              style: 'percent',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          }
          return ''
        },
        Header: (
          <span>
            <Translate value='discountOverview.fields.value' />
            <UpDownArrow />
          </span>
        ),
        width: 70,
      },
      {
        id: 'company',
        Header: (
          <span>
            <Translate value='discountOverview.fields.discountType' />
            <UpDownArrow />
          </span>
        ),
        accessor: data =>
          data && data.company
            ? I18n.t('discountOverview.discountType.individual')
            : I18n.t('discountOverview.discountType.general'),
      },
      {
        id: 'occasion',
        Header: (
          <span>
            <Translate value='discountOverview.fields.occasion' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'occasion',
        Cell: data => I18n.t(getName(data.value, DISCOUNT_OCCASIONS)),
      },
      {
        id: 'valid_from',
        Header: (
          <span>
            <Translate value='discountOverview.fields.validFrom' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'valid_from',
        Cell: data => moment(data.value).format('L'),
      },
      {
        id: 'valid_until',
        Header: (
          <span>
            <Translate value='discountOverview.fields.validUntil' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'valid_until',
        Cell: data => moment(data.value).format('L'),
      },
      {
        id: 'created_by__last_name',
        Header: (
          <span>
            <Translate value='general.createdBy' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'created_by_name',
      },
      {
        id: 'company__empto_external_number',
        Header: (
          <span>
            <Translate value='discountOverview.fields.customerNo' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'company_object',
        Cell: data =>
          data.value && <span>{data.value.empto_external_number}</span>,
      },
      {
        id: 'company__name',
        width: 200,
        Header: (
          <span>
            <Translate value='discountOverview.fields.customerName' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'company_object',
        Cell: data => data.value && <span>{data.value.name}</span>,
      },
      {
        Header: (
          <span>
            <Translate value='general.status' />
            <UpDownArrow />
          </span>
        ),
        accessor: 'status',
        Cell: data => {
          const allowedStates = DISCOUNT_STATES.filter(
            state => state.id !== DISCOUNT_STATUS.STATUS_EXPIRED,
          )
          const currentState = DISCOUNT_STATES.find(
            state => state.id === data.value,
          )
          const isExpiredState =
            currentState.id === DISCOUNT_STATUS.STATUS_EXPIRED
          const isAllowedToChange = checkRequiredPermissions(
            user.permission_codenames,
            [UserPermission.CHANGE_DISCOUNT],
          )

          return !isAllowedToChange || isExpiredState ? (
            <span>{I18n.t(currentState.name)}</span>
          ) : (
            <Switcher
              currentValue={currentState}
              isActive={data.value === DISCOUNT_STATUS.STATUS_ACTIVE}
              onValueChange={value => onStatusChange(data.original, value.id)}
              values={allowedStates}
            />
          )
        },
      },
    ]

    return (
      <PaginatedTable
        serverSidePagination
        page={page}
        pages={pages}
        loading={isLoading}
        handleShowPreviousPage={onPreviousPageClick}
        handleShowNextPage={onNextPageClick}
        handleChangeStatus={onStatusChange}
        handleEditUser={onEditDiscount}
        table={{
          columns,
          data: discountList,
          onFetchData,
        }}
      />
    )
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    const { isLoading, discountList } = this.props

    return (
      <div className='discount-list'>
        {/* Loading Indicator */}
        {isLoading && !discountList.length && (
          <div className='uk-flex uk-flex-center uk-margin-large-top'>
            <Spinner name='circle' />
          </div>
        )}

        {/* Table */}
        {discountList.length > 0 && (
          <div className='uk-margin-large-top'>
            {this.renderDiscountTable()}
          </div>
        )}
      </div>
    )
  }
}

export default DiscountListComponent
