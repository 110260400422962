import './../common/interactiveTableTitle.scss'
import './../common/interactiveTableTitleBar.scss'

import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import ReactTable from 'react-table'

import { ReactComponent as Document } from 'assets/svg/detailspage/document.svg'

interface activitiesTableProps {
  data?: Record<string, unknown>[]
  loading?: boolean
  pageSize?: number
}

export const ActivitiesTable: FC<activitiesTableProps> = ({
  data = [],
  loading = false,
  pageSize = 10,
}) => {
  const columns = [
    {
      id: 'timestamp',
      Header: (
        <Translate value='communicationCenterPageTranslations.columns.timestamp' />
      ),
      accessor: data => data.timestamp,
      headerStyle: { padding: '0' },
      sortable: false,
      width: 150,
    },
    {
      id: 'name',
      Header: (
        <Translate value='communicationCenterPageTranslations.columns.name' />
      ),
      accessor: data => data.name,
      sortable: false,
      width: 400,
    },
    {
      id: 'activity',
      Header: (
        <Translate value='communicationCenterPageTranslations.columns.activity' />
      ),
      width: 300,
      sortable: false,
      accessor: data => data.activity,
    },
    {
      id: 'change',
      Header: (
        <Translate value='communicationCenterPageTranslations.columns.change' />
      ),
      sortable: false,
      accessor: data => data.change,
    },
  ]

  return (
    <div className='communicationcenter-interactive-table'>
      <div className='communicationcenterpage-interactive-table-title-bar'>
        <div className='communicationcenterpage-interactive-table-title-bar__title'>
          <div className='communicationcenterpage-interactive-table-title-bar__icon'>
            <Document />
          </div>
          <div className='communicationcenterpage-interactive-table-title-bar__title-name'>
            <Translate value='communicationCenterPageTranslations.section.activities.title' />
          </div>
        </div>
        {data.length === 0 && (
          <Translate value='communicationCenterPageTranslations.noActivityEntriesAvailable' />
        )}
      </div>
      {data.length > 0 && (
        <div className='communicationcenter-interactive-table-body'>
          <ReactTable
            columns={columns}
            data={data}
            minRows={0}
            loading={loading}
            defaultPageSize={pageSize}
            className={
              'table -highlight communicationcenter-interactive-table-body__table'
            }
            pageSize={pageSize}
            showPageSizeOptions={false}
            pageSizeOptions={[10, 20, 30, 40, 50]}
            showPaginationBottom
            previousText={I18n.t('general.button.back')}
            nextText={I18n.t('general.button.next')}
            pageText={I18n.t('general.page')}
            ofText={I18n.t('general.of')}
          />
        </div>
      )}
    </div>
  )
}
