import { connect } from 'react-redux'

import {
  getDiscount,
  getDiscounts,
  updateDiscountStatus,
} from 'actions/discount'
import {
  getDiscountSelector,
  getDiscountsPaginationSelector,
  getDiscountsSelector,
} from 'selectors/discount'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'

const exportRedeemedDiscountsLoadingSelector = createLoadingSelector([
  'EXPORT_REDEEMED_DISCOUNTS',
])
const discountListLoadingSelector = createLoadingSelector(['GET_DISCOUNTS'])
const discountLoadingSelector = createLoadingSelector(['GET_DISCOUNT'])
const errorSelector = createErrorSelector(['GET_DISCOUNTS', 'GET_DISCOUNT'])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  discount: getDiscountSelector(state),
  discountList: getDiscountsSelector(state),
  pagination: getDiscountsPaginationSelector(state),
  isLoading: {
    exportRedeemedDiscounts: exportRedeemedDiscountsLoadingSelector(state),
    discountList: discountListLoadingSelector(state),
    discount: discountLoadingSelector(state),
  },
  error: errorSelector(state),
  user: getCurrentUserSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getDiscounts: (page, filters, sortedBy) =>
    dispatch(getDiscounts(page, filters, sortedBy)),
  getDiscount: discountId => dispatch(getDiscount(discountId)),
  updateDiscountStatus: discount => dispatch(updateDiscountStatus(discount)),
})

export default connect(mapStateToProps, mapDispatchToProps)
