import { ROLE_KEYS } from '../Step1/helpers'

import { BILLING_MODEL, PRICING } from './DefineNetPriceForm'
import {
  getBillingModelValueAndSetterByRoleType,
  getContainerPriceFieldValuesByRoleType,
  getFieldsType,
  getPricingValueAndSetterByRoleType,
  getSinglePriceFieldValuesByRoleType,
} from './types'

/**
 * Returns price values from a formik form corresponding to a given role.
 *
 * @param {getSinglePriceFieldValuesByRolePropsType} props An object containing the role of a company (Either
 * 'customer' or 'partner') and Formik form values.
 * @return {getSinglePriceFieldValuesByRoleReturnType} An object containing the Formik forms' price values
 * corresponding to the role.
 */
export const getSinglePriceFieldValuesByRole: getSinglePriceFieldValuesByRoleType =
  ({ role, values }) => {
    switch (role) {
      case ROLE_KEYS.CUSTOMER:
        return {
          rentPrice: values.customer_rent_price,
          transportPrice: values.customer_transport_price,
          disposalCostTon: values.customer_disposal_cost_ton,
          index: values.customer_index,
          indexMonth: values.customer_index_month,
          surcharge: values.customer_surcharge,
          reduction: values.customer_reduction,
          compensationTon: values.customer_compensation_ton,
          handleCostTon: values.customer_handle_cost_ton,
        }
      case ROLE_KEYS.PARTNER:
        return {
          rentPrice: values.partner_rent_price,
          transportPrice: values.partner_transport_price,
          disposalCostTon: values.partner_disposal_cost_ton,
          index: values.partner_index,
          indexMonth: values.partner_index_month,
          surcharge: values.partner_surcharge,
          reduction: values.partner_reduction,
          compensationTon: values.partner_compensation_ton,
          handleCostTon: values.partner_handle_cost_ton,
        }
      default:
        // default case should never happen
        return {
          rentPrice: undefined,
          transportPrice: undefined,
          disposalCostTon: undefined,
          index: undefined,
          indexMonth: 0,
          surcharge: undefined,
          reduction: undefined,
          compensationTon: undefined,
          handleCostTon: undefined,
        }
    }
  }

export const getContainerPriceFieldValuesByRole: getContainerPriceFieldValuesByRoleType =
  ({ role, values }) => {
    switch (role) {
      case ROLE_KEYS.CUSTOMER:
        return {
          packageRentPrice: values.customer_rent_price,
          costContainer: values.customer_cost_container,
          compensationContainer: values.customer_compensation_container,
        }
      case ROLE_KEYS.PARTNER:
        return {
          packageRentPrice: values.partner_rent_price,
          costContainer: values.partner_cost_container,
          compensationContainer: values.partner_compensation_container,
        }
      default:
        // default case should never happen
        return {
          packageRentPrice: undefined,
          costContainer: undefined,
          compensationContainer: undefined,
        }
    }
  }

/**
 * Returns an array of an object containing useState value and setter for a given roles billingModel or undefined,
 * if the role prop could not be matched to the known roles.
 *
 * @param {getBillingModelValueAndSetterByRolePropsType} props An object containing a companies' role, setState
 *  * values and setState setters.
 * @return {getBillingModelValueAndSetterByRoleReturnType} An array containing an object consisting of useState
 * values and setters for a roles billingModel, or undefined.
 */
export const getBillingModelValueAndSetterByRole: getBillingModelValueAndSetterByRoleType =
  ({ role, useStateValues, useStateSetter }) => {
    // Extract values and setters from props
    const { customerBillingModel, partnerBillingModel } = useStateValues
    const { setCustomerBillingModel, setPartnerBillingModel } = useStateSetter

    switch (role) {
      case ROLE_KEYS.CUSTOMER:
        return {
          billingModel: customerBillingModel,
          setBillingModel: setCustomerBillingModel,
        }
      case ROLE_KEYS.PARTNER:
        return {
          billingModel: partnerBillingModel,
          setBillingModel: setPartnerBillingModel,
        }
      default:
        // default case should never happen
        return {
          billingModel: undefined,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          setBillingModel: () => {},
        }
    }
  }

export const getPricingValueAndSetterByRole: getPricingValueAndSetterByRoleType =
  ({ role, useStateValues, useStateSetter }) => {
    // Extract values and setters from props
    const { customerPricing, partnerPricing } = useStateValues
    const { setCustomerPricing, setPartnerPricing } = useStateSetter

    switch (role) {
      case ROLE_KEYS.CUSTOMER:
        return {
          pricing: customerPricing,
          setPricing: setCustomerPricing,
        }
      case ROLE_KEYS.PARTNER:
        return {
          pricing: partnerPricing,
          setPricing: setPartnerPricing,
        }
      default:
        // default case should never happen
        return {
          pricing: '',
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          setPricing: () => {},
        }
    }
  }

/**
 * Returns an array of varying amounts of objects containing value strings (to be used to access Formik fields) and
 * label strings (to be used as i18n translation strings).
 *
 * Returns undefined, if the incoming billingModel could not
 * be matched to known billingModels and if the incoming role could not be matched to known roles.
 *
 * @param {getFieldsPropsType} props An object containing the role of a company (either 'customer' or 'partner') and
 * useState values.
 * @return {getFieldsReturnType} An array containing a varying amount of objects consisting of Formik value strings
 * and i18n translation strings, or undefined.
 */
export const getFields: getFieldsType = ({
  role,
  billingModel,
  pricing,
  selectedIndex,
  surchargeSelected,
}) => {
  if (
    billingModel === BILLING_MODEL.SINGLE_PRICE &&
    pricing === PRICING.DISPOSAL
  ) {
    return [
      {
        value: `${role}_rent_price`,
        label: 'addAgreementPageTranslations.steps.4.fields.rentPrice.label',
      },
      {
        value: `${role}_transport_price`,
        label:
          'addAgreementPageTranslations.steps.4.fields.transportPrice.label',
      },
      {
        value: `${role}_disposal_cost_ton`,
        label:
          'addAgreementPageTranslations.steps.4.fields.disposalPrice.label',
      },
    ]
  } else if (
    billingModel === BILLING_MODEL.PACKAGE_PRICE &&
    pricing === PRICING.DISPOSAL
  ) {
    if (role === ROLE_KEYS.CUSTOMER) {
      return [
        {
          value: 'customer_package_price',
          label:
            'addAgreementPageTranslations.steps.4.fields.packagePrice.label',
        },
      ]
    }
  } else if (
    billingModel === BILLING_MODEL.CONTAINER_PRICE &&
    pricing === PRICING.DISPOSAL
  ) {
    return [
      {
        value: `${role}_rent_price`,
        label: 'addAgreementPageTranslations.steps.4.fields.rentPrice.label',
      },
      {
        value: `${role}_disposal_cost_container`,
        label:
          'addAgreementPageTranslations.steps.4.fields.containerDisposalPrice.label',
      },
    ]
  } else if (
    billingModel === BILLING_MODEL.SINGLE_PRICE &&
    pricing === PRICING.COMPENSATION
  ) {
    if (selectedIndex) {
      if (surchargeSelected) {
        return [
          {
            value: `${role}_rent_price`,
            label:
              'addAgreementPageTranslations.steps.4.fields.rentPrice.label',
          },
          {
            value: `${role}_transport_price`,
            label:
              'addAgreementPageTranslations.steps.4.fields.transportPrice.label',
          },
          {
            value: `${selectedIndex.name}`,
            label: 'addAgreementPageTranslations.steps.4.fields.index.label',
          },
          {
            value: `${role}_index_month`,
            label:
              'addAgreementPageTranslations.steps.4.fields.indexMonth.label',
          },
          {
            value: `${role}_surcharge`,
            label:
              'addAgreementPageTranslations.steps.4.fields.surcharge.label',
          },
          {
            value: `${role}_handle_cost_ton`,
            label:
              'addAgreementPageTranslations.steps.4.fields.handleCostTon.label',
          },
        ]
      } else {
        return [
          {
            value: `${role}_rent_price`,
            label:
              'addAgreementPageTranslations.steps.4.fields.rentPrice.label',
          },
          {
            value: `${role}_transport_price`,
            label:
              'addAgreementPageTranslations.steps.4.fields.transportPrice.label',
          },
          {
            value: `${selectedIndex.name}`,
            label: 'addAgreementPageTranslations.steps.4.fields.index.label',
          },
          {
            value: `${role}_index_month`,
            label:
              'addAgreementPageTranslations.steps.4.fields.indexMonth.label',
          },
          {
            value: `${role}_reduction`,
            label:
              'addAgreementPageTranslations.steps.4.fields.reduction.label',
          },
          {
            value: `${role}_handle_cost_ton`,
            label:
              'addAgreementPageTranslations.steps.4.fields.handleCostTon.label',
          },
        ]
      }
    } else {
      return [
        {
          value: `${role}_rent_price`,
          label: 'addAgreementPageTranslations.steps.4.fields.rentPrice.label',
        },
        {
          value: `${role}_transport_price`,
          label:
            'addAgreementPageTranslations.steps.4.fields.transportPrice.label',
        },
        {
          value: `${role}_compensation_ton`,
          label:
            'addAgreementPageTranslations.steps.4.fields.compensationTon.label',
        },
        {
          value: `${role}_handle_cost_ton`,
          label:
            'addAgreementPageTranslations.steps.4.fields.handleCostTon.label',
        },
      ]
    }
  } else if (
    billingModel === BILLING_MODEL.CONTAINER_PRICE &&
    pricing === PRICING.COMPENSATION
  ) {
    return [
      {
        value: `${role}_rent_price`,
        label:
          'addAgreementPageTranslations.steps.4.fields.packageRentPrice.label',
      },
      {
        value: `${role}_cost_container`,
        label:
          'addAgreementPageTranslations.steps.4.fields.costContainer.label',
      },
      {
        value: `${role}_compensation_container`,
        label:
          'addAgreementPageTranslations.steps.4.fields.compensationContainer.label',
      },
    ]
  }
  return undefined
}
