import './ClassificationGrid.scss'

import React, { FC } from 'react'

/**
 * Renders the ClassificationGrid
 * @constructor
 */
export const ClassificationGrid: FC = ({ children }) => (
  <section className='classification-grid'>{children}</section>
)
