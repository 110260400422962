import uniqueId from 'lodash.uniqueid'
import React, { useEffect, useState } from 'react'
import { Collapse } from 'react-collapse'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { localizeDateForBackend } from 'helper/general'
import { UserPermission } from 'constants/user'
import {
  exportInvoiceCheck,
  exportInvoiceCheckBatchFile,
  getInvoiceChecks,
} from 'actions/maklerpremium'
import { getActiveCompaniesForFilter } from 'actions/company'
import { getActiveCompaniesForFilterSelector } from 'selectors/company'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import {
  getInvoiceCheckPaginationSelector,
  getInvoiceChecksSelector,
} from 'selectors/maklerpremium'
import { getCurrentUserSelector } from 'selectors/user'

import { AsyncExportModal } from '../../common/AsyncExportModal'
import {
  DateForm,
  LabelType,
} from '../../common/AsyncExportModal/DateForm/DateForm'
import { BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { Filter } from '../../common/Filter'
import { InvoiceCheckFilter } from '../../common/Filter/components/InvoiceCheckFilter'
import { ICON_NAME } from '../../common/Fontello'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { COMPANY_ROLE } from '../../company/constants'
import { INITIAL_FILTER_OPEN_WIDTH } from '../../inquiry/constants'
import { PageHeader } from '../../layout/PageHeader'
import { PROOF_TYPE } from '../../proofs/constants'
import { ProofsList } from '../../proofs/ProofsList'

import { InvoiceCheckAcquisitionFormikWrapper } from './InvoiceCheckAcquisition'

/**
 * @description This component displays a list of invoices including the filter, loading indicator etc.
 * @function
 */
export const InvoiceCheckPage = () => {
  const dispatch = useDispatch()
  const partners = useSelector(getActiveCompaniesForFilterSelector)
  const user = useSelector(getCurrentUserSelector)
  const invoiceChecks = useSelector(getInvoiceChecksSelector)
  const pagination = useSelector(getInvoiceCheckPaginationSelector)
  const apiError = {
    invoices: useSelector(createErrorSelector(['GET_INVOICE_CHECKS'])),
    export: useSelector(createErrorSelector(['EXPORT_ADDRESSES'])),
  }
  const isLoading = {
    invoices: useSelector(createLoadingSelector(['GET_INVOICE_CHECKS'])),
    exportAddresses: useSelector(createLoadingSelector(['EXPORT_ADDRESSES'])),
    exportInvoiceCheck: useSelector(
      createLoadingSelector(['EXPORT_INVOICE_CHECK']),
    ),
  }

  // InvoiceCheckAcquisitionForm Modal
  const idInvoiceCheckAcquisitionFormModalHeadline = uniqueId()
  const [
    isAddInvoiceCheckAcquisitionFormModalOpen,
    setIsAddInvoiceCheckAcquisitionFormModalOpen,
  ] = useState(false)
  const handleOpenCloseInvoiceCheckAcquisitionFormModal = () => {
    setIsAddInvoiceCheckAcquisitionFormModalOpen(
      !isAddInvoiceCheckAcquisitionFormModalOpen,
    )
  }

  const [isExportInvoiceCheckModalOpen, setIsExportInvoiceCheckModalOpen] =
    useState(false)
  const [
    isExportInvoiceCheckBatchModalOpen,
    setIsExportInvoiceCheckBatchModalOpen,
  ] = useState(false)

  const [filterOpen, setFilterOpen] = useState(
    window.innerWidth >= INITIAL_FILTER_OPEN_WIDTH,
  )
  const location = useLocation<{ company?: string }>()

  const defaultFilters = {
    company: location?.state?.company,
  }
  const [currentFilters, setCurrentFilters] = useState<any>(defaultFilters)

  const [exportStartDate, setExportStartDate] = useState<string>('')
  const [exportEndDate, setExportEndDate] = useState<string>('')

  const getExportFilters = () => {
    let filters = {
      ...currentFilters,
    }

    Object.keys(filters).forEach(filter => {
      //remove empty filter
      if (currentFilters[filter] === '') {
        delete filters[filter]
        return
      }
    })

    if (exportStartDate) {
      filters = {
        ...filters,
        document_date__gte: localizeDateForBackend(exportStartDate),
      }
    }

    if (exportEndDate) {
      filters = {
        ...filters,
        document_date__lte: localizeDateForBackend(exportEndDate),
      }
    }

    return filters
  }

  const getCleanedExportFilters = () => {
    const filters = getExportFilters()
    delete filters.document_date__gte
    delete filters.document_date__lte

    return filters
  }

  useEffect(() => {
    dispatch(
      getActiveCompaniesForFilter({
        role: COMPANY_ROLE.WASTE_COMPANY,
        has_empto_agreements: true,
      }),
    )
    // reload once only on location change
  }, [dispatch, location.pathname])

  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.invoiceCheck')}</title>
      </Helmet>
      <PageHeader title={I18n.t('pageTitles.invoiceCheck')}>
        <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
          <IconButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            iconName={ICON_NAME.PLUS}
            iconPosition={ICON_POSITION.RIGHT}
            onClick={handleOpenCloseInvoiceCheckAcquisitionFormModal}
          >
            <Translate value='invoiceCheckTranslations.addButton' />
          </IconButton>
          <RequiredPermissions
            requiredPermissions={[UserPermission.EXPORT_INVOICE_CHECKS]}
          >
            <IconButton
              iconName='export'
              iconPosition={ICON_POSITION.RIGHT}
              isDisabled={isLoading.exportInvoiceCheck || !user}
              onClick={() => setIsExportInvoiceCheckModalOpen(true)}
              isLoading={isLoading.exportInvoiceCheck || isLoading.invoices}
            >
              <Translate value='invoiceCheckTranslations.exportInvoiceChecks.exportButton' />
            </IconButton>
          </RequiredPermissions>
          <RequiredPermissions
            requiredPermissions={[UserPermission.EXPORT_BATCH_FILE_EPD]}
          >
            <IconButton
              iconName='export'
              iconPosition={ICON_POSITION.RIGHT}
              isDisabled={isLoading.exportAddresses || !user}
              onClick={() => setIsExportInvoiceCheckBatchModalOpen(true)}
              isLoading={isLoading.exportAddresses}
            >
              <Translate value='invoiceCheckTranslations.exportBatch.exportButton' />
            </IconButton>
          </RequiredPermissions>
          <IconButton
            iconName={ICON_NAME.EXPERIMENT}
            iconPosition={ICON_POSITION.RIGHT}
            onClick={() => {
              setFilterOpen(!filterOpen)
            }}
          >
            <Translate
              value={filterOpen ? 'general.hideFilter' : 'general.showFilter'}
            />
          </IconButton>
        </ButtonBar>
      </PageHeader>
      <Collapse isOpened={filterOpen}>
        <Filter
          companyList={partners}
          isLoading={isLoading.invoices}
          length={invoiceChecks.length}
        >
          {/* Ignore TS explicitly and ignore the PyCharm error in your mind: required props passed via <Filter> */}
          {/* @ts-ignore */}
          <InvoiceCheckFilter
            action={getInvoiceChecks}
            setCurrentFilterValues={setCurrentFilters}
            currentFilterValues={currentFilters}
          />
        </Filter>
      </Collapse>

      <ProofsList
        apiError={apiError.invoices}
        proofsType={PROOF_TYPE.INVOICE_CHECK}
        proofsList={invoiceChecks}
        isLoading={isLoading.invoices}
        isReviewButtonVisible
        onFetchData={({ page, sorted }) => {
          if (sorted && sorted.length > 0) {
            const newFilters = {
              ...currentFilters,
              order_by: JSON.stringify(sorted),
            }
            if (
              JSON.stringify(newFilters) !== JSON.stringify(currentFilters) ||
              !pagination.loaded
            ) {
              setCurrentFilters(newFilters)
              dispatch(getInvoiceChecks(page, newFilters))
            }
          }
        }}
        onNextPageClick={() => {
          dispatch(getInvoiceChecks(pagination.next, currentFilters))
        }}
        onPreviousPageClick={() => {
          dispatch(getInvoiceChecks(pagination.previous, currentFilters))
        }}
        page={pagination.current}
        pages={pagination.count}
      />

      <Modal
        ariaDescribedBy={idInvoiceCheckAcquisitionFormModalHeadline}
        isOpen={isAddInvoiceCheckAcquisitionFormModalOpen}
        onClose={handleOpenCloseInvoiceCheckAcquisitionFormModal}
      >
        <ModalHeader
          onClose={handleOpenCloseInvoiceCheckAcquisitionFormModal}
          title={I18n.t(
            'invoiceCheckTranslations.acquisitionForm.header.title',
          )}
          titleId={idInvoiceCheckAcquisitionFormModalHeadline}
        />
        <InvoiceCheckAcquisitionFormikWrapper
          handleCloseModal={() => {
            handleOpenCloseInvoiceCheckAcquisitionFormModal()
            // reload table with new data
            dispatch(getInvoiceChecks(undefined, currentFilters))
            // reset company filter when closing modal
            dispatch(
              getActiveCompaniesForFilter({
                role: COMPANY_ROLE.WASTE_COMPANY,
                has_empto_agreements: true,
              }),
            )
          }}
        />
      </Modal>
      <AsyncExportModal
        isOpen={isExportInvoiceCheckModalOpen}
        onClose={() => setIsExportInvoiceCheckModalOpen(false)}
        reduxSelector='EXPORT_INVOICE_CHECK'
        title={I18n.t(
          'invoiceCheckTranslations.exportInvoiceChecks.modal.title',
        )}
        description_translation_key={
          'asyncExportModalTranslations.descriptionWithFilter'
        }
        notice_translation_key={
          Object.values(getCleanedExportFilters()).every(
            x => x === null || x === '',
          )
            ? ''
            : 'asyncExportModalTranslations.dateForm.filterNotice'
        }
        logic={exportInvoiceCheck(getExportFilters())}
        resetOnDispatch={() => {
          setExportStartDate('')
          setExportEndDate('')
        }}
      >
        <DateForm
          setStartDate={setExportStartDate}
          setEndDate={setExportEndDate}
          labelType={LabelType.DOCUMENT_DATE}
        />
      </AsyncExportModal>
      <AsyncExportModal
        isOpen={isExportInvoiceCheckBatchModalOpen}
        onClose={() => setIsExportInvoiceCheckBatchModalOpen(false)}
        reduxSelector='EXPORT_INVOICE_CHECK_BATCH_FILE'
        title={I18n.t('invoiceCheckTranslations.exportBatch.modal.title')}
        description_translation_key={
          'asyncExportModalTranslations.descriptionWithFilter'
        }
        notice_translation_key={
          Object.values(getCleanedExportFilters()).every(
            x => x === null || x === '',
          )
            ? ''
            : 'asyncExportModalTranslations.dateForm.filterNotice'
        }
        logic={exportInvoiceCheckBatchFile(
          { ...getExportFilters(), makler_premium_company: user.company },
          {
            order_by: JSON.stringify([{ id: 'id', desc: true }]),
          },
        )}
        resetOnDispatch={() => {
          setExportStartDate('')
          setExportEndDate('')
        }}
      >
        <DateForm
          setStartDate={setExportStartDate}
          setEndDate={setExportEndDate}
          labelType={LabelType.DOCUMENT_DATE}
        />
      </AsyncExportModal>
    </>
  )
}
