import ActionTypes from '../actiontypes/app'

const loadingReducer = (state = {}, action) => {
  const { type } = action
  const matches = /(.*)_(REQUEST|SUCCESS|ERROR)/.exec(type)

  if (type === ActionTypes.RESET_API_FETCH_LOADING) {
    return {
      ...state,
      [action.payload]: null,
    }
  }

  // not a *_REQUEST / *_SUCCESS / *_ERROR actions, so we ignore them
  if (!matches) return state

  const [, requestName, requestState] = matches
  return {
    ...state,
    // Store whether a request is happening at the moment or not
    // e.g. will be true when receiving GET_TODOS_REQUEST and false when receiving GET_TODOS_SUCCESS / GET_TODOS_ERROR
    [requestName]: requestState === 'REQUEST',
  }
}

export default loadingReducer
