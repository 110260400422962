import moment from 'moment/moment'
import { useEffect, useRef } from 'react'

import { getTimeOfDayNameIfNotAllDay } from '../components/inquiry/helpers'
import { DATE_FORMATS } from '../constants/app'

/**
 * @description Localize a date.
 * @param dateString
 * @return {string}
 */
export const localizeDate = dateString =>
  dateString ? moment(dateString, DATE_FORMATS).format('L') : undefined

/**
 * @description Localize a date for backend request.
 * @param dateString
 * @return {string}
 */
export const localizeDateForBackend = dateString =>
  dateString ? moment(dateString, DATE_FORMATS).format('YYYY-MM-DD') : undefined

/**
 * @description Localize a time.
 * @param timeString
 * @return {string}
 */
export const localizeTime = timeString =>
  timeString ? moment(timeString, ['HH:mm']).format('HH:mm') : undefined

/**
 * @description Localize a time.
 * @param dateTimeString
 * @return {string}
 */
export const localizeDateTime = dateTimeString =>
  dateTimeString ? moment(dateTimeString).format('L HH:mm') : undefined

/**
 * @description Localize a monetary value.
 * @param amount
 * @param fallback
 * @return {string}
 */
export const localizeMoney = (amount, { fallback = '' }) =>
  amount
    ? new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(amount)
    : fallback

/**
 * @description get date info with time of day.
 * @param date
 * @param timeOfDay
 * @return {*}
 */
export const getDateInfo = (date, timeOfDay) => {
  if (!date) return undefined

  return [localizeDate(date), getTimeOfDayNameIfNotAllDay(timeOfDay)].join(' ')
}

/**
 * @description Humanize a date. Returns relative localized date string (like 'today')
 * @param dateString
 * @return {string}
 */
export const humanDate = dateString =>
  dateString
    ? moment(dateString).calendar(null, {
        sameDay: '[Heute]',
        nextDay: '[Morgen]',
        nextWeek: 'dddd',
        lastDay: '[Gestern]',
        lastWeek: '[Letzten] dddd',
        sameElse: 'DD.MM.YYYY',
      })
    : undefined

/**
 * @description Humanize a date and time. Returns relative localized string with date and time information.
 * @param dateString
 * @return {string}
 */
export const humanDateTime = dateString =>
  dateString
    ? `${humanDate(dateString)}, ${moment(dateString).format('LT')}`
    : undefined

/**
 * @description Makes sure that a moment.js date is not on a weekend - shifts the date to next monday.
 * @param aMoment
 * @return Moment
 */
export const preventWeekend = aMoment => {
  // If maxDate is on a saturday or sunday, shift it to the next monday.
  // isoWeekday(): "ISO day of the week with 1 being Monday and 7 being Sunday."

  // Saturday, add two days
  if (aMoment.isoWeekday() === 6) return aMoment.add(2, 'day')
  // Sunday, add one day
  if (aMoment.isoWeekday() === 7) return aMoment.add(1, 'day')
  return aMoment
}

/**
 * @description Gets the earlier moment.js date
 */
export const minDate = (moment1, moment2) =>
  moment1.isBefore(moment2, 'day') ? moment1 : moment2

/**
 * @description Gets the later moment.js date
 */
export const maxDate = (moment1, moment2) =>
  moment1.isAfter(moment2, 'day') ? moment1 : moment2

/**
 * Returns whether a date is in the near future whereas near means `differenceSecs` seconds in the future from now.
 * This can be used to check if a token or inquiry will expire soon.
 * @param date The date which shall be checked
 * @param differenceSecs The definition of "soon" in seconds. Defaults to 1 hour.
 * @returns {boolean}
 */
export const dateIsWithinInterval = (date, differenceSecs = 3600) => {
  const currentTime = new Date()
  const differenceMilliSecs = date.getTime() - currentTime.getTime() // So many millisecs are left until the date.
  return differenceMilliSecs < differenceSecs * 1000 // If there are less millisecs than I want, the date is soon.
}

/**
 * Returns whether a date is in the past. Returns true if the passed date is in the past and false if it is now
 * or in the future.
 * @param date The date to be compared.
 * @returns {boolean}
 */
export const dateIsPast = date => {
  const currentTime = new Date()
  return date.getTime() < currentTime.getTime()
}

/**
 * @description transform string to boolean.
 * @param value
 * @return {Boolean}
 */
export const str2bool = value => {
  if (value && typeof value === 'string') {
    if (value.toLowerCase() === 'true') return true
    if (value.toLowerCase() === 'false') return false
  }
  return value
}

/**
 * @description get attribute of object safe or undefined.
 * example useage: get(() => this.props.inquiry.collection_address_object.display_name)
 * if inquiry or collection_address_object does not exists the javascript code will not crash. We will only get
 * undefined.
 * see: https://silvantroxler.ch/2017/avoid-cannot-read-property-of-undefined/
 * @param fn
 * @return {*}
 */
export const get = fn => {
  try {
    return fn()
  } catch (e) {
    return undefined
  }
}

/**
 * @description get a value for a constant array like ADDRESS_STATES
 * @param id
 * @param key
 * @param array
 * @return {string}
 */
export const getValue = (id, key, array = []) => {
  const item = array.find(_item => `${_item.id}` === `${id}`)

  if (!item) return ''
  return item[key]
}

/**
 * Formats given number according to given options.
 * @param {number|string} number The number to be formatted.
 * @param {!Object.<string, *>} options The formatting options.
 * @return {string} The formatted number string.
 */
export const priceFormat = number => {
  const options = {
    decimal: ',',
    grouping: '.',
    prefix: '',
    suffix: ' €',
  }
  const result = `${Number.parseFloat(number).toFixed(2)}`.split('.')
  return (
    options.prefix +
    result[0].replace(/\B(?=(\d{3})+(?!\d))/g, options.grouping) +
    (result[1] ? options.decimal + result[1] : '') +
    options.suffix
  )
}

/**
 * add http protocol to non protocol urls.
 * @param {string?} url
 * @return {string} url with a http protocol.
 */
export const addHttpProtocol = url => {
  if (!url) return ''
  if (!/^(f|ht)tps?:\/\//i.test(url)) {
    return `http://${url}`
  }
  return url
}

/**
 * @description transform a number to a locale string.
 * @param number
 * @return {string}
 */
export const numberToLocaleString = number =>
  !Number.isNaN(Number(number)) ? Number(number).toLocaleString() : ''

/**
 * @description Find same object in array and replace it with the new one.
 * @param object
 * @param array
 * @returns {*}
 */
export const replaceObjectInArray = (object, array) =>
  array.map(_object => (_object.id === object.id ? object : _object))

/**
 * @description Find multiple objects in array and replace them with the new one.
 * @param object
 * @param originalObjects
 * @returns {*}
 */
export const replaceObjectsInArray = (newObjects, originalObjects) =>
  originalObjects.map(originalObject => {
    const newObject = newObjects.filter(
      object => object.id === originalObject.id,
    )[0]
    return newObject ? newObject : originalObject
  })

/**
 * @description Find same object in array and replace the "internal_note" key with the new one.
 * @param object
 * @param array
 * @returns {*}
 */
export const replaceInternalNote = (object, array) =>
  array.map(_object =>
    _object.id === object.id
      ? { ..._object, internal_note: object.internal_note }
      : _object,
  )

/**
 * @description Get a random entry from an array.
 * @param {Array} array
 * @returns {*}
 */
export const getRandomArrayEntry = array =>
  array[Math.floor(Math.random() * array.length)]

/**
 * @description Converts a decimal number to the german format as a string.
 * @param {Number | String} decimalValue
 * @returns {*}
 */
export const decimalToGermanFormat = decimalValue =>
  String(decimalValue).replace('.', ',')

/**
 * @description Converts a decimal number to the german format as a string.
 * @param {String} decimalGermanValue
 * @returns {*}
 */
export const germanDecimalToInternationalFormat = decimalGermanValue =>
  Number(decimalGermanValue.replace(',', '.'))

export const useEffectDebugger = (effect, deps, dependencyNames = []) => {
  const usePrevious = (value, initialValue) => {
    const ref = useRef(initialValue)
    useEffect(() => {
      ref.current = value
    })
    return ref.current
  }

  const previousDeps = usePrevious(deps, [])

  const changedDeps = deps.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      }
    }

    return accum
  }, {})

  if (Object.keys(changedDeps).length) {
    // eslint-disable-next-line no-console
    console.log('[use-effect-debugger] ', changedDeps)
  }

  useEffect(effect, [effect])
}

export default {}
