import './style.scss'

import { arrayOf, bool, func, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'
import { compose } from 'recompose'

import withErrorBoundary from 'helper/withErrorBoundary'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import { ApiErrorScheme } from 'schemes/error'
import { FractionContentScheme, FractionScheme } from 'schemes/fraction'

import DescriptionHeader from '../../common/DescriptionHeader'
import Icon from '../../common/Fontello'
import { LinkButton } from '../../common/LinkButton'
import { NotFoundComponent } from '../../common/RoutesHandler/components/NotFoundComponent'

import WasteTypeAVVTable from './components/WasteTypeAVVTable'
import WasteTypeContact from './components/WasteTypeContact'
import WasteTypeContent from './components/WasteTypeContent'
import WasteTypeRulesComponent from './components/WasteTypeRules'
import connector from './connector'
import { CONTENT_TYPE } from './constants'
import { getContent, getTitle } from './helpers'

/**
 * @description This component renders and controls a waste type detail page component.
 * @function
 * @param {Object} props the component props
 */
export class WasteTypeDetailPageComponent extends Component {
  static propTypes = {
    fractionList: arrayOf(shape(FractionScheme)).isRequired,
    fractionContent: arrayOf(shape(FractionContentScheme)).isRequired,
    getFractions: func.isRequired,
    getFractionContent: func.isRequired,
    isLoading: shape({
      getFractions: bool,
      getFractionContent: bool,
    }),
    error: shape({
      getFractions: shape(ApiErrorScheme),
      getFractionContent: shape(ApiErrorScheme),
    }),
    match: shape({
      params: shape({
        wasteTypeSlug: string,
      }),
    }).isRequired,
  }

  static defaultProps = {
    isLoading: {
      getFractions: false,
      getFractionContent: false,
    },
    error: {
      getFractions: null,
      getFractionContent: null,
    },
  }

  state = {
    currentFraction: {},
    currentFractionContent: [],
    currentAVVList: [],
  }

  /**
   * @description Component “lifecycle method” componentDidMount
   */
  componentDidMount() {
    if (this.props.fractionList.length < 1) this.props.getFractions()
    this.props.getFractionContent()
  }

  /**
   * @description Component “lifecycle method” componentDidUpdate
   */
  componentDidUpdate(prevProps) {
    const { fractionContent, fractionList, isLoading, match } = this.props

    if (
      isLoading.getFractionContent !== prevProps.isLoading.getFractionContent ||
      isLoading.getFractions !== prevProps.isLoading.getFractions ||
      match.params.wasteTypeSlug !== prevProps.match.params.wasteTypeSlug
    ) {
      const currentFraction = fractionList.find(
        fraction => fraction.slug === match.params.wasteTypeSlug,
      )

      if (currentFraction) {
        this.setState({
          currentFraction,
          currentAVVList: currentFraction.avv_list,
          currentFractionContent: fractionContent,
        })
      } else {
        this.setState({
          currentFraction: null,
        })
      }
    }
  }

  renderTitle = contentType =>
    getTitle(this.state.currentFractionContent, contentType)

  renderContent = contentType => (
    <p
      className='content'
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: getContent(this.state.currentFractionContent, contentType),
      }}
    />
  )

  /**
   * @function
   * @return {*}
   */
  render() {
    if (
      this.props.isLoading.getFractions ||
      this.props.isLoading.getFractionContent
    )
      return null

    if (
      (!this.state.currentFraction &&
        (!this.props.isLoading.getFractions ||
          !this.props.isLoading.getFractionContent)) ||
      !this.state.currentFraction.visible_in_empto
    )
      return <NotFoundComponent />

    return (
      <>
        <Helmet>
          <title>{`${this.state.currentFraction.title} | empto`}</title>
          <meta
            property='og:title'
            content={`${this.state.currentFraction.title} | empto`}
          />
          <meta
            property='og:description'
            name='description'
            content={this.state.currentFraction.description}
          />
        </Helmet>

        <div className='waste-type-detail-page'>
          {/* Header */}
          <DescriptionHeader
            title={this.renderTitle(CONTENT_TYPE.CONTAINER_INFO)}
            content={this.renderContent(CONTENT_TYPE.CONTAINER_INFO)}
          >
            <img
              alt={this.state.currentFraction.slug}
              className='waste-type-header__image-fit'
              src={this.state.currentFraction.image}
            />
          </DescriptionHeader>

          {/* Content */}
          <WasteTypeContent
            title={this.renderTitle(CONTENT_TYPE.BELONGING)}
            content={this.renderContent(CONTENT_TYPE.BELONGING)}
          />

          {/* AVV */}
          {this.state.currentAVVList && (
            <WasteTypeAVVTable avvList={this.state.currentAVVList} />
          )}
          {/* Rules */}
          <WasteTypeRulesComponent
            allowedContent={this.renderContent(CONTENT_TYPE.BELONGING_ALLOWED)}
            forbiddenContent={this.renderContent(
              CONTENT_TYPE.BELONGING_FORBIDDEN,
            )}
          />
          {/* Rules */}
          <WasteTypeContact
            content={this.renderContent(CONTENT_TYPE.CONTACT)}
          />

          {/* Content */}
          <WasteTypeContent
            title={this.renderTitle(CONTENT_TYPE.ENVIRONMENT)}
            content={this.renderContent(CONTENT_TYPE.ENVIRONMENT)}
          >
            <LinkButton
              target={{
                pathname: '/abfallarten',
              }}
            >
              {I18n.t('general.backToOverview')}
              <Icon name='reply' />
            </LinkButton>
          </WasteTypeContent>
          {/* REGISTER (disabled in #3228) */}
          {/*<RegisterCallToActionSection />*/}
        </div>
      </>
    )
  }
}

export default compose(
  withErrorBoundary,
  connector,
  withApiErrorHandling,
)(WasteTypeDetailPageComponent)
