import React, { FC } from 'react'

import { ORDER_EVALUATION_RATING } from '../../constants'
import { EvaluationsType } from '../../helpers'

import { ThumbsSelector } from './ThumbsSelector'

interface EvaluationGroupProps {
  evaluations?: EvaluationsType[]
  disabled?: boolean
  onClick?: (evaluationType?: number, evaluationRating?: number) => void
  renderHeading?: () => void
}

export const EvaluationGroup: FC<EvaluationGroupProps> = ({
  evaluations = [],
  disabled = false,
  onClick = () => undefined,
  renderHeading = () => undefined,
}) => (
  <div>
    {renderHeading()}
    {evaluations.map(evaluation => (
      <div className='uk-margin-small-bottom' key={evaluation.type}>
        <ThumbsSelector
          thumbsUpSelected={
            evaluation.rating === ORDER_EVALUATION_RATING.POSITIVE
          }
          thumbsDownSelected={
            evaluation.rating === ORDER_EVALUATION_RATING.NEGATIVE
          }
          disabled={disabled || evaluation.disabled}
          onClick={thumbsUp => {
            onClick(
              evaluation.type,
              thumbsUp
                ? ORDER_EVALUATION_RATING.POSITIVE
                : ORDER_EVALUATION_RATING.NEGATIVE,
            )
          }}
        >
          {evaluation.label}
        </ThumbsSelector>
      </div>
    ))}
  </div>
)
