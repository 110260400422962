import '../../maklerpremium/CreateMaklerPremiumOfferPage/OfferFormSteps/Step2/style.scss'
import './styles.scss'

import { useFormikContext } from 'formik'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { BUSINESS_SEGMENT, COLLECTION_CONTAINER_IDS } from 'constants/app'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'
import { getAvvsSelector } from 'selectors/inquiry'
import { getSecurityGroupsSelector } from 'selectors/securitygroup'

import { numberOfContainersList } from '../../inquiry/constants'
import {
  getCoarseFractionItemsForSegment,
  getContainerItems,
  getFineFractionItems,
} from '../../inquiry/helpers'
import { MaklerPremiumOfferValues } from '../../maklerpremium/CreateMaklerPremiumOfferPage/OfferFormSteps/CreateMaklerPremiumOfferFormSteps'
import { DismissableInfo } from '../DismissableInfo'
import { DropDownInput } from '../DropDownInput'
import { FindAvvByFilterInput } from '../FindByFilterInput/FindAvvByFilterInput'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from '../Headline'
import { InputDecimal } from '../InputDecimal'
import { InputText } from '../InputText'
import { RadioPanel } from '../RadioPanel'
import { RadioPanelGroup } from '../RadioPanelGroup'
import { StaticCombobox } from '../StaticCombobox'
import { Option } from '../StaticCombobox/StaticCombobox'

import { containsWhiteSpace } from './helpers'

interface FractionAndContainerFormFieldsProps {
  hideDismissableInfos?: boolean
  hidePublicPlacement?: boolean
  showAvvField?: boolean
  invoiceCheckPositionForm?: boolean
  frameworkContractApplies?: boolean
  showSecurityGroupField?: boolean
  securityGroupFilterOptions?: number[]
  defaultSelectedSecurityGroupId?: number
  showSubLabel?: boolean
  useAvvAsyncSelect?: boolean
}

interface FractionAndContainerFormValues extends MaklerPremiumOfferValues {
  avv: string
  disposal_proof_number?: string
  disposer_number?: string
  transportation_company_number?: string
}

/**
 * @description: Component to render and control Address and Delivery form Group
 */
export const FractionAndContainerFormFields: FC<
  FractionAndContainerFormFieldsProps
> = ({
  hideDismissableInfos = false,
  hidePublicPlacement = false,
  showAvvField = false,
  invoiceCheckPositionForm = false,
  frameworkContractApplies = false,
  showSecurityGroupField = false,
  securityGroupFilterOptions = [],
  defaultSelectedSecurityGroupId = undefined,
  showSubLabel = true,
  useAvvAsyncSelect = true,
}) => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    submitCount,
    values,
  } = useFormikContext<FractionAndContainerFormValues>()

  const fractionList = useSelector<any, Fraction[]>(getFractionsSelector)
  const containerList = useSelector<any, Container[]>(getContainersSelector)
  const securityGroupList = useSelector<any, SecurityGroup[]>(
    getSecurityGroupsSelector,
  )
  const avvs = useSelector(getAvvsSelector)

  //useStates & useMemo

  // set up coarse Fraction Options
  const [coarseFractionOptions, setCoarseFractionOptions] = useState<Option[]>(
    [],
  )
  const [selectedCoarseFractionOption, setSelectedCoarseFractionOption] =
    useState<Option>()
  const [selectedAvvIsDangerousWaste, setSelectedAvvIsDangerousWaste] =
    useState<boolean>()
  const [avvFractionCombination, setAvvFractionCombination] = useState(true)
  const [avvFractionCombinationText, setAvvFractionCombinationText] =
    useState<string>()
  const [
    selectedFractionRequiresSecurityGroup,
    setSelectedFractionRequiresSecurityGroup,
  ] = useState<boolean>()
  const [avvList, setAvvList] = useState<Avv[]>()

  const [selectedAvvObject, setSelectedAvvObject] = useState<Avv>()
  // set up fine Fraction Options and a list of fine Fraction items for the fine fraction selected check
  const [fineFractionList, setFineFractionList] = useState<Fraction[]>([])
  const [fineFractionOptions, setFineFractionOptions] = useState<Option[]>([])
  const [selectedFineFractionOption, setSelectedFineFractionOption] =
    useState<Option>()
  // const [securityGroupOptions, setSecurityGroupOptions] = useState<Option[]>([])
  const securityGroupOptions = useMemo(() => {
    return securityGroupList
      .filter(item => {
        if (securityGroupFilterOptions?.length > 0) {
          return securityGroupFilterOptions?.includes(item.id)
        }
        return item
      })
      .map(
        item =>
          ({
            value: item.id,
            label: `${I18n.t(
              'selectSecurityGroupTranslations.protectionClassNumber',
              { protectionClass: item.protection_class },
            )} / ${I18n.t(
              'selectSecurityGroupTranslations.securityLevelNumber',
              { securityLevel: item.security_level },
            )}`,
          } as Option),
      )
  }, [securityGroupList, securityGroupFilterOptions])
  const [selectedSecurityGroup, setSelectedSecurityGroup] = useState<Option>()
  // Set up state variables for both the comment string and the dismissed state
  const [coarseFractionComment, setCoarseFractionComment] = useState<string>()
  const [coarseFractionCommentDismissed, setCoarseFractionCommentDismissed] =
    useState(true)
  const [fineFractionComment, setFineFractionComment] = useState<string>()
  const [fineFractionCommentDismissed, setFineFractionCommentDismissed] =
    useState(true)

  const [fractionContainerList, setFractionContainerList] = useState<
    Container[]
  >([])
  const [containerTypeOptions, setContainerTypeOptions] = useState<Option[]>([])
  const [selectedContainerTypeOption, setSelectedContainerTypeOption] =
    useState<Option>()
  const [containerCapacityOptions, setContainerCapacityOptions] = useState<
    Option[]
  >([])
  const [selectedContainerCapacityOption, setSelectedContainerCapacityOption] =
    useState<Option>()

  const isFieldValid = name => values[name]?.length && !errors[name]
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0
      ? (errors[name] as string)
      : undefined

  // Initial values
  const initialOptionsAvv = values.avv
    ? ([{ label: values.avv, value: values.avv }] as Option[])
    : ([] as Option[])

  const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
    Number(values.container),
  )

  const mapAvvsToOptions = (avvArray: Avv[]) =>
    avvArray.map(({ number }) => ({
      label: number,
      value: number,
    }))

  const fineFractionSelected =
    fineFractionList.length === 0 ||
    (!!values.fine_fraction && Number(values.fine_fraction) > 0)
  const showContainerSelector =
    fineFractionSelected &&
    coarseFractionCommentDismissed &&
    fineFractionCommentDismissed

  // useEffects

  // useEffect to set coarse fraction options
  useEffect(() => {
    // coarse Fractions from fractionList for EPD
    const coarseFractions = getCoarseFractionItemsForSegment(
      fractionList,
      BUSINESS_SEGMENT.BUSINESS_EPD,
    )
    // map to Option[]
    setCoarseFractionOptions(
      coarseFractions.map(item => ({
        value: item.id,
        label: item.name,
      })),
    )
  }, [fractionList])

  // useEffect to set fine fraction list and options
  // list = fraction objects from state
  // options = prepared key, values for dropdown
  useEffect(() => {
    // get fine fraction children from fraction or return an empty array
    const fineFractions = values.fraction
      ? getFineFractionItems(values.fraction, fractionList)
      : []
    setFineFractionList(fineFractions)
    setFineFractionOptions(
      fineFractions.map(item => ({
        value: item.id,
        label: item.name,
      })),
    )
    const coarseFraction = fractionList.find(
      fraction => fraction.id === Number(values.fraction),
    )
    setSelectedCoarseFractionOption({
      label: coarseFraction?.name ?? '',
      value: coarseFraction?.id ?? '',
    })
    setSelectedFineFractionOption(undefined)
  }, [fractionList, values.fraction])

  // useEffect to clear the 3 additional fields, if it's a non-dangerous waste
  useEffect(() => {
    if (selectedAvvObject) {
      setFieldValue('avv', selectedAvvObject.number)
    }

    const tmpSelectedAvvIsDangerousWaste =
      (values?.avv ?? '').indexOf('*') !== -1 &&
      !fractionList.find(
        fraction => fraction.id === selectedCoarseFractionOption?.value,
      )?.no_takeover_certificate
    if (!tmpSelectedAvvIsDangerousWaste) {
      setFieldValue('disposal_proof_number', '')
      setFieldValue('transportation_company_number', '')
      setFieldValue('disposer_number', '')
    }
    setSelectedAvvIsDangerousWaste(tmpSelectedAvvIsDangerousWaste)
  }, [
    selectedAvvObject,
    setFieldValue,
    values,
    selectedCoarseFractionOption,
    fractionList,
  ])

  // useEffect to set container list and options
  // list = fraction objects from state
  // options = prepared key, values for dropdown
  useEffect(() => {
    if (values.fraction) {
      let containers: Container[] = []
      if (invoiceCheckPositionForm) {
        // For InvoiceCheck Form, list all containers
        containers = containerList.filter(
          container =>
            Number(container.business_segment) ===
            BUSINESS_SEGMENT.BUSINESS_EPD,
        )
      } else {
        // In other forms, only list containers matching the selected (fine) fraction
        containers = getContainerItems(
          values.fraction,
          values.fine_fraction,
          fractionList,
          containerList,
          BUSINESS_SEGMENT.BUSINESS_EPD,
        )
      }

      setFractionContainerList(containers)
      // I'm pretty sure this list is not empty and doesn't contain undefined
      const containerTypes = new Set(
        containers.map(container => container!.name),
      )
      setContainerTypeOptions(
        Array.from(containerTypes.values()).map(item => ({
          value: item,
          label: item,
        })),
      )
    }
    if (values.fine_fraction && fineFractionOptions.length > 0) {
      setSelectedFineFractionOption(
        fineFractionOptions.find(
          item => item.value === Number(values.fine_fraction),
        ),
      )
    }
  }, [
    containerList,
    fineFractionOptions,
    fractionList,
    invoiceCheckPositionForm,
    values.fine_fraction,
    values.fraction,
  ])

  // useEffect to set container capacity options
  useEffect(() => {
    const selectedContainers = fractionContainerList.filter(
      container => container.name === selectedContainerTypeOption?.value,
    )
    setContainerCapacityOptions(
      selectedContainers.map(item => ({
        value: item.id,
        label: item.capacity,
      })),
    )
  }, [fractionContainerList, selectedContainerTypeOption])

  // useEffect to set the selected container
  useEffect(() => {
    const selectedContainer = fractionContainerList.find(
      container =>
        container.capacity === selectedContainerCapacityOption?.value &&
        container.name === selectedContainerTypeOption?.value,
    )
    if (selectedContainer) {
      setFieldValue('container', selectedContainer.id)
    }
  }, [
    fractionContainerList,
    selectedContainerCapacityOption,
    selectedContainerTypeOption,
    setFieldValue,
  ])

  // useEffect to set the selected container capacity and type option
  useEffect(() => {
    const selectedContainer = fractionContainerList.find(
      container => container.id === Number(values.container),
    )
    if (selectedContainer) {
      if (
        containerCapacityOptions.length > 0 &&
        selectedContainer.capacity !== selectedContainerCapacityOption?.label
      ) {
        setSelectedContainerCapacityOption(
          containerCapacityOptions.find(
            item => item.label === selectedContainer.capacity,
          ),
        )
      }
      if (
        containerTypeOptions.length > 0 &&
        selectedContainer.name !== selectedContainerTypeOption?.label
      ) {
        setSelectedContainerTypeOption(
          containerTypeOptions.find(
            item => item.label === selectedContainer.name,
          ),
        )
      }
    }
  }, [
    containerCapacityOptions,
    containerTypeOptions,
    fractionContainerList,
    selectedContainerCapacityOption,
    selectedContainerTypeOption,
    values.container,
  ])

  // useEffect to set the fractions comments and check if the security group is required
  useEffect(() => {
    if (values.fraction) {
      const currentFraction = fractionList.find(
        fraction => fraction.id === Number(values.fraction),
      )
      if (currentFraction && currentFraction.comment && !hideDismissableInfos) {
        setCoarseFractionComment(currentFraction.comment)
        setCoarseFractionCommentDismissed(false)
        setFineFractionComment(undefined)
        setFineFractionCommentDismissed(true)
      } else {
        setCoarseFractionComment(undefined)
        setCoarseFractionCommentDismissed(true)
        setFineFractionComment(undefined)
        setFineFractionCommentDismissed(true)
      }

      // check if fraction requires security group
      if (currentFraction && currentFraction.requires_securitygroup) {
        setSelectedFractionRequiresSecurityGroup(true)
      } else {
        setFieldValue('security_group', undefined)
        setSelectedFractionRequiresSecurityGroup(false)
      }

      if (currentFraction) {
        setAvvList(currentFraction.avv_list)
      }
    }
  }, [fractionList, hideDismissableInfos, setFieldValue, values.fraction])

  // useEffect to set the fine fractions comment
  useEffect(() => {
    if (values.fine_fraction) {
      const currentFraction = fractionList.find(
        fraction => fraction.id === Number(values.fine_fraction),
      )
      if (currentFraction && currentFraction.comment && !hideDismissableInfos) {
        setFineFractionComment(currentFraction.comment)
        setFineFractionCommentDismissed(false)
      } else {
        setFineFractionComment(undefined)
        setFineFractionCommentDismissed(true)
      }
    }
  }, [fractionList, hideDismissableInfos, values.fine_fraction])

  // useEffect to preselect the default security group
  useEffect(() => {
    // set default security group if fraction requires security group and no security group is selected by user
    if (selectedFractionRequiresSecurityGroup && !selectedSecurityGroup) {
      setSelectedSecurityGroup(
        securityGroupOptions.find(item => {
          if (item.value === defaultSelectedSecurityGroupId) {
            setFieldValue('security_group', Number(item.value))
            return true
          }
          return false
        }),
      )
    }
  }, [
    defaultSelectedSecurityGroupId,
    securityGroupOptions,
    setFieldValue,
    selectedFractionRequiresSecurityGroup,
    setSelectedSecurityGroup,
    selectedSecurityGroup,
  ])

  // UseEffect to set selected security group from values (edit modus)
  useEffect(() => {
    if (securityGroupOptions && values.security_group) {
      setSelectedSecurityGroup(
        securityGroupOptions.find(item => item.value === values.security_group),
      )
    }
  }, [securityGroupOptions, values.security_group])

  // useEffect to check avv and fraction combination
  useEffect(() => {
    if (showAvvField && values && values.avv) {
      const currentFraction = fractionList.find(
        fraction => fraction.id === Number(values.fraction),
      )
      if (currentFraction) {
        if (
          currentFraction.avv_list.filter(fraction =>
            values.avv.includes(fraction.number),
          ).length === 0
        ) {
          setAvvFractionCombination(false)
          const suggestedAvvs = currentFraction.avv_list
            .map(e => e.number)
            .join(', ')
          if (currentFraction.avv_list.length > 1) {
            setAvvFractionCombinationText(`Die eingegebene AVV-Nummer entspricht nicht der Abfallart des Auftrags.
           Meinten Sie vielleicht eine der AVV-Nummern: ${suggestedAvvs}? Dann passen Sie Ihre Eingabe bitte
           entsprechend an.`)
          } else {
            setAvvFractionCombinationText(`Die eingegebene AVV-Nummer entspricht nicht der Abfallart des Auftrags.
           Meinten Sie vielleicht die AVV-Nummer: ${suggestedAvvs}? Dann passen Sie Ihre Eingabe bitte
           entsprechend an.`)
          }
        } else {
          setAvvFractionCombination(true)
          setAvvFractionCombinationText('')
        }
      }
    }
  }, [avvs, showAvvField, values, fractionList])

  return (
    <div className='fraction-and-container-form-group'>
      <div className='fraction-and-container-form-group__button-element-container'>
        <StaticCombobox
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.fraction.label',
          )}
          name='fraction'
          noResultsText={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.fraction.noInputResults',
          )}
          options={coarseFractionOptions}
          onSelectionChange={event => {
            if (values.fine_fraction) {
              setFieldValue('fine_fraction', undefined)
            }
            if (values.container && !invoiceCheckPositionForm) {
              setFieldValue('container', undefined)
            }
            if (values.offer_end_price) {
              setFieldValue('offer_end_price', undefined)
            }
            if (values.avv && !useAvvAsyncSelect) {
              setFieldValue('avv', undefined)
            }
            setFieldValue('fraction', Number(event.target.value))
            if (invoiceCheckPositionForm) {
              if (event.target.value !== selectedCoarseFractionOption?.value) {
                setFieldValue('rent_price_container_month', '')
                setFieldValue('disposal_cost_container', '')
                setFieldValue('transport_price_piece', '')
                setFieldValue('disposal_cost_ton', '')
              }
              setFieldValue('index', undefined)
              setFieldValue('index_month', '')
              setFieldValue('surcharge', '')
              setFieldValue('reduction', '')
              setFieldValue('compensation_ton', '')
            }
          }}
          placeholder={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.fraction.label',
          )}
          selectedOption={selectedCoarseFractionOption}
          withCheckmark
          showCheckmark={
            (!!values.fraction &&
              !errors.fraction &&
              avvFractionCombination &&
              useAvvAsyncSelect) ||
            (!!values.fraction && !errors.fraction && !useAvvAsyncSelect)
          }
        />
      </div>

      {!coarseFractionCommentDismissed && (
        <DismissableInfo
          onClick={() => setCoarseFractionCommentDismissed(true)}
          title={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.infobox.title',
          )}
          text={coarseFractionComment}
          buttonText={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.infobox.buttonText',
          )}
        />
      )}

      {values.fraction && fineFractionList.length > 0 && (
        <StaticCombobox
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.fineFraction.label',
          )}
          name='fine_fraction'
          noResultsText={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.fraction.noInputResults',
          )}
          options={fineFractionOptions}
          onSelectionChange={event => {
            if (event.target.value === '') {
              setFieldValue('offer_end_price', undefined)
            }
            setFieldValue('fine_fraction', Number(event.target.value))
          }}
          placeholder={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.fineFraction.placeholder',
          )}
          selectedOption={selectedFineFractionOption}
          withCheckmark
          showCheckmark={!!values.fine_fraction}
        />
      )}

      {!fineFractionCommentDismissed && (
        <DismissableInfo
          onClick={() => setFineFractionCommentDismissed(true)}
          title={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.infobox.title',
          )}
          text={fineFractionComment}
          buttonText={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.infobox.buttonText',
          )}
        />
      )}

      {showSecurityGroupField && selectedFractionRequiresSecurityGroup && (
        <StaticCombobox
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.securityGroup.label',
          )}
          subLabel={
            showSubLabel && (
              <Translate
                value='selectSecurityGroupTranslations.securityGroup.protectionClassLink'
                dangerousHTML
              />
            )
          }
          name='security_group'
          noResultsText={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.securityGroup.noInputResults',
          )}
          options={securityGroupOptions}
          onSelectionChange={event => {
            setFieldValue('security_group', Number(event.target.value))
          }}
          placeholder={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.securityGroup.placeholder',
          )}
          selectedOption={selectedSecurityGroup}
          withCheckmark
          showCheckmark={!!values.security_group}
        />
      )}

      {/* AVV-Nummer mit ggf. weiteren Eingabemöglichkeiten für gefährliche Abfälle */}
      {showAvvField && useAvvAsyncSelect && (
        <>
          <div>
            <div>
              <FindAvvByFilterInput
                label={I18n.t(
                  'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.label',
                )}
                mapOptionLabel={mapAvvsToOptions}
                name='find_avv_by_filter_input'
                noOptionsMessage={I18n.t(
                  'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.noOptionsMessage',
                )}
                placeholder={I18n.t(
                  'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.label',
                )}
                handleSelectionChange={(value: string | undefined) => {
                  setFieldValue('avv', typeof value === 'string' ? value : '')
                  setSelectedAvvObject(
                    typeof value === 'string'
                      ? avvs.find(avvObject => avvObject.number === value)
                      : undefined,
                  )
                }}
                options={initialOptionsAvv}
                value={values.avv}
                error={getFieldError}
                showCheckmark={!!values.avv && avvFractionCombination}
                withCheckmark
                noResultsText={I18n.t(
                  'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.noOptionsMessage',
                )}
              />
            </div>
            {selectedAvvObject && (
              <div className='uk-margin-small-top avv-description'>
                {selectedAvvObject?.name}
              </div>
            )}
          </div>
          {!avvFractionCombination && (
            <DismissableInfo
              onClick={() => null}
              title={I18n.t(
                'createMaklerPremiumOfferPageTranslations.steps.2.fields.infobox.title',
              )}
              text={avvFractionCombinationText}
            />
          )}

          {selectedAvvIsDangerousWaste && avvFractionCombination && (
            <DismissableInfo
              className='dismissable-info--warning'
              onClick={() => null}
              title={I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.dangerousWasteInfo.title',
              )}
              text={I18n.t(
                'invoiceCheckTranslations.positionsForm.steps.2.dangerousWasteInfo.text',
              )}
              disableOnAnimationEnd
            >
              <InputText
                label={I18n.t(
                  'documentReviewPageTranslations.executionProofReview.disposal_proof_number.label',
                )}
                maxLength={12}
                name='disposal_proof_number'
                onBlur={handleBlur}
                onChange={e => {
                  // Do not allow any whitespaces as input
                  if (!containsWhiteSpace(e.target.value)) handleChange(e)
                }}
                placeholder={I18n.t(
                  'documentReviewPageTranslations.executionProofReview.disposal_proof_number.placeholder',
                )}
                value={values.disposal_proof_number}
                withCheckmark
                showCheckmark={values.disposal_proof_number?.length === 12}
                error={getFieldError}
              />

              <InputText
                label={I18n.t(
                  'documentReviewPageTranslations.executionProofReview.transportation_company_number.label',
                )}
                maxLength={9}
                name='transportation_company_number'
                onBlur={handleBlur}
                onChange={e => {
                  // Do not allow any whitespaces as input
                  if (!containsWhiteSpace(e.target.value)) handleChange(e)
                }}
                placeholder={I18n.t(
                  'documentReviewPageTranslations.executionProofReview.transportation_company_number.placeholder',
                )}
                value={values.transportation_company_number}
                withCheckmark
                showCheckmark={
                  values.transportation_company_number?.length === 9
                }
                error={getFieldError}
              />

              <InputText
                label={I18n.t(
                  'documentReviewPageTranslations.executionProofReview.disposer_number.label',
                )}
                maxLength={9}
                name='disposer_number'
                onBlur={handleBlur}
                onChange={e => {
                  // Do not allow any whitespaces as input
                  if (!containsWhiteSpace(e.target.value)) handleChange(e)
                }}
                placeholder={I18n.t(
                  'documentReviewPageTranslations.executionProofReview.disposer_number.placeholder',
                )}
                value={values.disposer_number}
                withCheckmark
                showCheckmark={values.disposer_number?.length === 9}
                error={getFieldError}
              />
            </DismissableInfo>
          )}
        </>
      )}

      {avvList && showAvvField && !useAvvAsyncSelect && (
        <>
          <div>
            <div>
              {avvList.length > 0 && (
                <DropDownInput
                  dataTestId='avv-input'
                  choices={avvList.map(item => ({
                    optionLabel: item.number,
                    optionValue: item.id,
                  }))}
                  error={submitCount > 0 ? (errors!.avv as string) : ''}
                  isPlaceholderClickable
                  label={I18n.t(
                    'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.label',
                  )}
                  name='avv'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t(
                    'invoiceCheckTranslations.positionsForm.steps.2.fields.avv.label',
                  )}
                  value={values.avv || ''}
                  withCheckmark
                  showCheckmark={!!values.avv && !errors.avv}
                />
              )}
            </div>
          </div>
        </>
      )}

      {showContainerSelector && (
        <>
          {containerTypeOptions && containerTypeOptions.length > 0 && (
            <StaticCombobox
              label={I18n.t(
                'createMaklerPremiumOfferPageTranslations.steps.2.fields.container.label',
              )}
              name='container'
              noResultsText={I18n.t(
                'createMaklerPremiumOfferPageTranslations.steps.2.fields.container.noInputResults',
              )}
              options={containerTypeOptions}
              onSelectionChange={event => {
                setSelectedContainerTypeOption({
                  value: event.target.value,
                  label: event.target.value,
                })
                setSelectedContainerCapacityOption(undefined)
                setFieldValue('container', undefined)
                if (values.offer_end_price) {
                  setFieldValue('offer_end_price', undefined)
                }

                if (invoiceCheckPositionForm) {
                  if (
                    event.target.value !== selectedContainerTypeOption?.value
                  ) {
                    setFieldValue('rent_price_container_month', '')
                    setFieldValue('transport_price_piece', '')
                  }
                }
              }}
              placeholder={I18n.t(
                'createMaklerPremiumOfferPageTranslations.steps.2.fields.container.label',
              )}
              withCheckmark
              selectedOption={selectedContainerTypeOption}
              showCheckmark={!!values.container && !errors.container}
            />
          )}
          {containerCapacityOptions && containerCapacityOptions.length > 0 && (
            <div className='uk-grid uk-child-width-1-2@xl'>
              <StaticCombobox
                className='uk-padding uk-padding-remove-vertical'
                label={I18n.t(
                  'createMaklerPremiumOfferPageTranslations.steps.2.fields.containerSize.label',
                )}
                name='container_type'
                noResultsText={I18n.t(
                  'createMaklerPremiumOfferPageTranslations.steps.2.fields.containerSize.noInputResults',
                )}
                options={containerCapacityOptions}
                onSelectionChange={event => {
                  setFieldValue('container', Number(event.target.value))
                  if (invoiceCheckPositionForm) {
                    if (
                      event.target.value !==
                      selectedContainerCapacityOption?.value
                    ) {
                      setFieldValue('rent_price_container_month', '')
                      setFieldValue('transport_price_piece', '')
                    }
                  }
                }}
                placeholder={I18n.t(
                  'createMaklerPremiumOfferPageTranslations.steps.2.fields.containerSize.label',
                )}
                selectedOption={selectedContainerCapacityOption}
              />
              {!showQuantityInCubicMeters && (
                <DropDownInput
                  choices={numberOfContainersList.map(item => ({
                    optionValue: String(item),
                    optionLabel: String(item),
                  }))}
                  containerClassName='uk-padding-remove-left'
                  label={I18n.t(
                    'createMaklerPremiumOfferPageTranslations.steps.2.fields.numberOfContainers.label',
                  )}
                  name='number_of_containers'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.number_of_containers}
                  withCheckmark
                  showCheckmark={
                    !!values.number_of_containers &&
                    !errors.number_of_containers &&
                    !!values.container &&
                    !errors.container
                  }
                />
              )}
              {showQuantityInCubicMeters && (
                <InputDecimal
                  dataTestId='quantity-in-cubic-meters-input'
                  label={I18n.t(
                    'createMaklerPremiumOfferPageTranslations.steps.2.fields.quantityInCubicMeters.label',
                  )}
                  placeholder={I18n.t(
                    'createMaklerPremiumOfferPageTranslations.steps.2.fields.quantityInCubicMeters.label',
                  )}
                  maxValue={99.99}
                  name='quantity_in_cubic_meters'
                  onChange={handleChange}
                  value={values.quantity_in_cubic_meters}
                  removeBrowserStyling
                  withCheckmark
                  showCheckmark={
                    // !smallView && !!values.container && !errors.container && !errors.quantity_in_cubic_meters
                    !!values.quantity_in_cubic_meters &&
                    !errors.quantity_in_cubic_meters &&
                    !!values.container &&
                    !errors.container
                  }
                />
              )}
            </div>
          )}
          {!hidePublicPlacement && (
            <div className='radio-panel-group-with-checkmark'>
              <RadioPanelGroup
                columns={2}
                initialSelection={
                  (values.position_on_public_ground ?? '') &&
                  values.position_on_public_ground
                    ? 'public_ground'
                    : 'private_ground'
                }
                label={I18n.t(
                  'createMaklerPremiumOfferPageTranslations.steps.2.fields.positionOnPublicGround.label',
                )}
                onChange={value =>
                  setFieldValue(
                    'position_on_public_ground',
                    value === 'public_ground',
                  )
                }
              >
                <RadioPanel value='public_ground'>
                  <Headline
                    color={HEADLINE_COLOR.DARK_GRAY}
                    fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                    fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
                    headlineStyle={HEADLINE_STYLE.H5}
                    noMargin
                    tag={HEADLINE_TAG.H3}
                  >
                    <Translate value='createMaklerPremiumOfferPageTranslations.steps.2.radiopanel.onPublicGround' />
                  </Headline>
                </RadioPanel>
                <RadioPanel value='private_ground'>
                  <Headline
                    color={HEADLINE_COLOR.DARK_GRAY}
                    fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                    fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
                    headlineStyle={HEADLINE_STYLE.H5}
                    noMargin
                    tag={HEADLINE_TAG.H3}
                  >
                    <Translate value='createMaklerPremiumOfferPageTranslations.steps.2.radiopanel.onPrivateGround' />
                  </Headline>
                </RadioPanel>
              </RadioPanelGroup>
            </div>
          )}
        </>
      )}

      {frameworkContractApplies && (
        <DismissableInfo
          className='dismissable-info'
          onClick={() => null}
          title={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.frameworkContractHint.title',
          )}
          text={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.frameworkContractHint.text',
          )}
        />
      )}
    </div>
  )
}
