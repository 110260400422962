import classNames from 'classnames'
import React, { FC, useRef, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import Icon, { ICON_NAME } from 'components/common/Fontello'
import { ImageSwatch } from 'components/common/ImageSwatch'
import {
  INQUIRY_ORDER_TYPE,
  INQUIRY_ORDER_TYPES,
} from 'components/inquiry/constants'
import {
  getFractionOrContainerDetailsById,
  getTimeOfDayNameIfNotAllDay,
} from 'components/inquiry/helpers'
import { getLatestOrderStatusTranslation } from 'components/order/helpers'
import { COLLECTION_CONTAINER_IDS } from 'constants/app'
import { decimalToGermanFormat, localizeDate } from 'helper/general'
import {
  getActualOrderCollectionDate,
  getActualOrderDeliveryDate,
  getActualOrderTimeOfDayCollection,
  getActualOrderTimeOfDayDelivery,
  getActualServiceDate,
} from 'helper/order'
import { getName } from 'helper/translations'
import { getCurrentUserSelector } from 'selectors/user'

import {
  CardDetailsList,
  CardDetailsListItem,
  CardDistinctArea,
  CardHeader,
} from '../Card'
import { CardHeaderIcon } from '../Card/CardHeaderIcon'

import { ItemDate } from './ItemDate'
import { OrderCardStatusAndProgress } from './OrderCardStatusAndProgress'

export interface OrderCardProps {
  coarseFraction: Fraction
  containerList: Container[]
  fraction: Fraction
  item: OrderListItem
  user?: User
}

export const OrderCard: FC<OrderCardProps> = ({
  coarseFraction,
  containerList,
  fraction,
  item,
}) => {
  const orderTileRef = useRef<HTMLElement>(null)

  const [cardBlurred, setCardBlurred] = useState<boolean>(true)
  const user = useSelector(getCurrentUserSelector)

  const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
    Number(item.container),
  )

  const detailRows: React.ReactElement[] = []
  // Location Details
  detailRows.push(
    <CardDetailsListItem>
      <Icon name={ICON_NAME.STORE} />
      <span className='two-lines break-all'>
        {item.customer_company_object.name}
      </span>
    </CardDetailsListItem>,
    <CardDetailsListItem>
      <Icon name={ICON_NAME.LOCATION} />
      {item.collection_address_object.display_name}
    </CardDetailsListItem>,
  )

  if (item.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME) {
    // Delivery and Collection Date
    detailRows.push(
      <CardDetailsListItem>
        <ItemDate
          title='order.deliveryDate'
          date={localizeDate(getActualOrderDeliveryDate(item))}
          timeOfDay={getTimeOfDayNameIfNotAllDay(
            getActualOrderTimeOfDayDelivery(item),
          )}
          asList={false}
        />
      </CardDetailsListItem>,
      <CardDetailsListItem>
        <ItemDate
          title='order.collectionDate'
          date={localizeDate(getActualOrderCollectionDate(item))}
          timeOfDay={getTimeOfDayNameIfNotAllDay(
            getActualOrderTimeOfDayCollection(item),
          )}
          asList={false}
        />
      </CardDetailsListItem>,
    )
  } else {
    // Execution Date
    detailRows.push(
      <CardDetailsListItem>
        <ItemDate
          title='order.executionDate'
          date={getActualServiceDate(item)}
          timeOfDay={getTimeOfDayNameIfNotAllDay(
            getActualOrderTimeOfDayDelivery(item),
          )}
          asList={false}
        />
      </CardDetailsListItem>,
    )
  }

  const { capacity: containerCapacity, name: containerName } =
    getFractionOrContainerDetailsById(item.container, containerList)

  detailRows.push(
    // Disposer Details
    <CardDetailsListItem>
      <Icon name={ICON_NAME.TRUCK} />
      <span className='two-lines break-all'>
        {item.offer_company_object.name}
      </span>
    </CardDetailsListItem>,
    // Container Details
    <CardDetailsListItem>
      <Icon name={ICON_NAME.CONTAINER_SKIP} />
      <Translate
        value={
          showQuantityInCubicMeters
            ? 'newInquiryCard.details.containerCubicMeters'
            : 'newInquiryCard.details.container'
        }
        capacity={containerCapacity}
        name={containerName}
        amount={
          showQuantityInCubicMeters
            ? decimalToGermanFormat(item.quantity_in_cubic_meters)
            : item.number_of_containers
        }
        dangerousHTML
      />
    </CardDetailsListItem>,
    <CardDetailsListItem>
      <Icon name={ICON_NAME.ARROW_RIGHT} />
      {getName(item.order_type, INQUIRY_ORDER_TYPES, true)}
    </CardDetailsListItem>,
  )

  if (detailRows.length < 5) {
    detailRows.push(<CardDetailsListItem>&nbsp;</CardDetailsListItem>)
  }

  return (
    <article
      className={classNames('new-card')}
      data-testid='order-card'
      data-order-id={item.id}
      onMouseEnter={() => setCardBlurred(false)}
      onMouseLeave={() => setCardBlurred(true)}
      ref={orderTileRef}
    >
      <Link className='new-card__click-area' to={`/order/${item.id}`}>
        <CardHeader
          title={coarseFraction && coarseFraction.name}
          subtitle={fraction && fraction.name}
        >
          {item.is_deduction && (
            <CardHeaderIcon
              iconName={ICON_NAME.WARNING}
              tooltip={I18n.t('general.deductionOrder')}
            />
          )}
        </CardHeader>
        <div className='new-card__swatch-container'>
          {coarseFraction && (
            <ImageSwatch
              blur={cardBlurred}
              className='new-card__swatch'
              image={coarseFraction.image}
              name={coarseFraction.name}
            />
          )}
        </div>
        <CardDistinctArea>
          <OrderCardStatusAndProgress
            headline={I18n.t(
              'orderCardTranslations.statusAndProgress.headline',
              { offerId: item.id },
            )}
            status={getLatestOrderStatusTranslation(
              item.progress_status,
              item,
              user,
            )
              .split('.')
              .pop()}
            latestOrderStatus={I18n.t(
              getLatestOrderStatusTranslation(item.status, item, user),
            )}
          />
        </CardDistinctArea>
        <CardDetailsList items={detailRows} />
      </Link>
    </article>
  )
}
