import './PriceComparisonTableColumn.scss'

import classNames from 'classnames'
import React, { FC, ReactElement } from 'react'

interface PriceComparisonTableColumnProps {
  headline?: string
  subline?: string
  active?: boolean
  rows: ReactElement[]
}

export const PriceComparisonTableColumn: FC<
  PriceComparisonTableColumnProps
> = ({ headline, subline, active, rows }) => (
  <div
    className={classNames({
      'price-comparison-table-column': true,
      'price-comparison-table-column--active': active,
    })}
  >
    <div className='price-comparison-table-column__header'>
      {headline && (
        <strong className='price-comparison-table-column__headline'>
          {headline}
        </strong>
      )}
      {subline && (
        <span className='price-comparison-table-column__headline'>{`${subline} (Brutto)`}</span>
      )}
    </div>
    {rows}
  </div>
)
