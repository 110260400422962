import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import ActionTypes from '../actiontypes/zipcode'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetZipcodes
 * @description gets a list of zicodes. You should use this with a filter only, because there are many zipcodes.
 */
export const handleGetZipcodes = createLogic({
  type: ActionTypes.GET_ZIPCODES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/zipcode/`, action.payload)
      .then(res => Promise.resolve(res.data.results))
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ZIPCODES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.zipcode.handleGetZipcodes.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_ZIPCODES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetZipcode
 * @description gets zipcode.
 */
export const handleGetZipcode = createLogic({
  type: ActionTypes.GET_ZIPCODE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const filters = action.payload
      ? {
          is_active: action.payload.is_active || null,
        }
      : {}

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/zipcode/${action.payload.code}/`,
        filters,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ZIPCODE_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        dispatch({
          type: ActionTypes.GET_ZIPCODE_ERROR,
        })

        // 404 is not an error but the intended status code to check if the zip code exists
        if (err.response.status !== 404 && !err.hideToastr) {
          toastr.error('', I18n.t('api.zipcode.handleGetZipcode.error'))
        }

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateInactiveZipcodeWishList
 * @description creates inactive zipcode wishlist.
 */
export const handleCreateInactiveZipcodeWishList = createLogic({
  type: ActionTypes.CREATE_INACTIVE_ZIPCODE_WISHLIST_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/inactive-zipcode-request/`,
        action.payload,
      )
      .then(() => {
        toastr.success('', I18n.t('addressForm.informMeModal.toastr.success'))
      })
      .then(done)
      .catch(err => {
        toastr.error(err.response.data.non_field_errors[0])
        done()
      })
  },
})

export default [
  handleGetZipcodes,
  handleGetZipcode,
  handleCreateInactiveZipcodeWishList,
]
