import React, { FC } from 'react'

import { isValidDecimal } from '../../common/Form/validators'
import { Input, INPUT_TYPE, InputProps, KEY_ENTER } from '../Input'

interface InputTextProps extends InputProps {
  maxLength?: number
  noNumbers?: boolean
  noSpecialChars?: boolean
  onlyDecimal?: boolean
  onlyNumbers?: boolean
  onSubmit?: () => void
}

export const InputText: FC<InputTextProps> = ({
  maxLength = 255,
  noNumbers = false,
  noSpecialChars = false,
  onChange,
  onlyDecimal = false,
  onlyNumbers = false,
  onSubmit = event => {
    event.preventDefault()
  }, // Needed for PaymentMethodsPage
  value,
  ...rest
}) => {
  const onValueChange = event => {
    // only trigger the change event if the resulting value is shorter than maxLength and not only spaces/empty
    if (
      event.target.value.length <= maxLength &&
      !/^\s+$/.test(event.target.value)
    )
      onChange(event)
  }

  const allowSubmissionByEnter = event => {
    if (event && event.key === KEY_ENTER) onSubmit(event)
  }

  const onKeyPress = event => {
    allowSubmissionByEnter(event)

    if (noNumbers) {
      if (!/^\D+$/.test(event.key)) event.preventDefault()
    }
    if (onlyNumbers) {
      if (/^\D+$/.test(event.key)) event.preventDefault()
    }
    if (noSpecialChars) {
      if (!/^[\p{Letter}\d_ ]*$/u.test(event.key)) event.preventDefault() // but allow spaces
    }

    if (onlyDecimal) {
      if (!isValidDecimal(value + event.key)) {
        event.preventDefault()
      }
    }

    // Disallow space as the first/only character
    if (/^\s*$/.test(event.target.value) && event.key === ' ')
      event.preventDefault()
  }

  return (
    <Input
      onChange={onValueChange}
      onKeyPress={onKeyPress}
      type={INPUT_TYPE.TEXT}
      value={value}
      {...rest}
    />
  )
}
