import { bool, func, number, oneOfType, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { Errors } from 'react-redux-form'

import Icon from 'components/common/Fontello'
import { ApiValidationMessages } from 'components/common/Form/components/ApiValidationMessages'
import ControlEditToggle from 'components/common/Form/components/ControlEditToggle'
import {
  CheckboxControl,
  SelectControl,
  TextboxControl,
} from 'components/common/Form/elements'
import { FieldScheme } from 'components/common/Form/schemes'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { SectionHeader } from 'components/common/SectionHeader'
import {
  COMPANY_ROLE,
  COMPANY_STATES,
  PRIVATE_PERSON_CHOICES,
} from 'components/company/constants'
import { BUSINESS_SEGMENT } from 'constants/app'
import { UserPermission } from 'constants/user'
import { getName } from 'helper/translations'
import { ApiErrorScheme } from 'schemes/error'

import { CompanyFormValidators } from '../../constants'

import connector from './connector'

/**
 * @description This component renders the form fields for basic company details.
 */
export class CompanyDetailsComponent extends Component {
  static propTypes = {
    company: shape({
      name: string,
      business_segment: number,
      central_contact_email: string,
      central_contact_phone: string,
      empto_external_number: oneOfType([string, number]),
      eta_service: bool,
      invoice_recipient: string,
      makler_premium_company: number,
      private_person: bool,
      reference_number: string,
      registration_number: number,
      role: oneOfType([number, string]),
      status: number,
      company_group: oneOfType([string, bool]),
    }).isRequired,
    companyForm: shape({
      name: FieldScheme.isRequired,
      central_contact_email: FieldScheme,
      central_contact_phone: FieldScheme,
      eta_service: FieldScheme,
      invoice_recipient: FieldScheme.isRequired,
      private_person: FieldScheme,
      reference_number: FieldScheme,
      registration_number: FieldScheme,
    }).isRequired,
    resetApiFetchErrors: func.isRequired,
    error: shape(ApiErrorScheme),
  }

  static defaultProps = {
    error: null,
  }

  /**
   * @description component lifecycle method "componentDidMount()
   */
  componentDidMount() {
    this.props.resetApiFetchErrors('UPDATE_COMPANY')
  }

  /**
   * @description component lifecycle method "componentDidUpdate"
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    if (
      this.props.company.central_contact_email !==
      prevProps.company.central_contact_email
    ) {
      this.props.resetApiFetchErrors('UPDATE_COMPANY')
    }
  }

  /**
   * @function
   * @returns {*}
   */
  render() {
    const { companyForm, company, error } = this.props

    const companyIsMPCustomer =
      this.props.company.makler_premium_company &&
      this.props.company.role === COMPANY_ROLE.WASTE_PRODUCER

    const companyIsMPPartner =
      this.props.company.makler_premium_company &&
      this.props.company.role === COMPANY_ROLE.WASTE_COMPANY

    const companyIsBusinessSegmentDSZ =
      this.props.company.business_segment === BUSINESS_SEGMENT.BUSINESS_DSZ

    const companyGroupText = company.company_group
      ? company.company_group > 1
        ? `Ja (Tochtergesellschaft von ${company.company_group})`
        : 'Ja (Muttergesellschaft)'
      : 'Nein'

    return (
      <section className='uk-card uk-padding-remove company-details'>
        <SectionHeader>
          <Icon name='store' />
          <Translate value='companyProfilePage.companyDetails' />
        </SectionHeader>

        <div className='uk-padding details-container'>
          <div className='uk-grid uk-child-width-1-1 uk-child-width-1-3@m'>
            {/* Vollständiger Name des Unternehmens*/}
            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.name')}
              value={company.name}
              errorClass={!companyForm.name.valid ? 'uk-text-danger' : null}
              triggerEdit={!companyForm.name.valid}
              editable
            >
              <TextboxControl
                className='uk-input'
                field={companyForm.name}
                placeholder={I18n.t('companyProfilePage.placeholder.name')}
                validators={CompanyFormValidators.name}
                maxLength={200}
                errorMessages={{
                  isRequired: I18n.t('message.validation.required'),
                }}
              />
            </ControlEditToggle>

            {/* Zentrale E-Mail*/}
            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.email')}
              value={company.central_contact_email}
              errorClass={
                !companyForm.central_contact_email.valid
                  ? 'uk-text-danger'
                  : null
              }
              triggerEdit={!companyForm.central_contact_email.valid}
              editable
            >
              <TextboxControl
                className='uk-input'
                field={companyForm.central_contact_email}
                placeholder={I18n.t('companyProfilePage.placeholder.email')}
                validators={CompanyFormValidators.central_contact_email}
                maxLength={50}
                errorMessages={{
                  isEmail: I18n.t('message.validation.email.isEmail'),
                }}
              />
              <ApiValidationMessages error={error} />
            </ControlEditToggle>

            {/* Zentrale Telefonnummer*/}
            {this.props.company.makler_premium_company && (
              <ControlEditToggle
                className='uk-margin-medium-bottom'
                label={I18n.t('companyProfilePage.label.central_contact_phone')}
                value={company.central_contact_phone}
                errorClass={
                  !companyForm.central_contact_phone.valid
                    ? 'uk-text-danger'
                    : null
                }
                triggerEdit={!companyForm.central_contact_phone.valid}
                editable
              >
                <TextboxControl
                  className='uk-input'
                  field={companyForm.central_contact_phone}
                  placeholder={I18n.t(
                    'companyProfilePage.placeholder.central_contact_phone',
                  )}
                  validators={CompanyFormValidators.central_contact_phone}
                  maxLength={50}
                />
                <ApiValidationMessages error={error} />
              </ControlEditToggle>
            )}

            {/* Empfängerzusatz */}
            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.invoiceRecipient')}
              value={company.invoice_recipient}
              errorClass={
                !companyForm.invoice_recipient.valid ? 'uk-text-danger' : null
              }
              triggerEdit={!companyForm.invoice_recipient.valid}
              editable
            >
              <TextboxControl
                className='uk-input'
                field={companyForm.invoice_recipient}
                placeholder={I18n.t(
                  'companyProfilePage.placeholder.invoiceRecipient',
                )}
                validators={CompanyFormValidators.invoice_recipient}
                maxLength={50}
              />
            </ControlEditToggle>

            {/* Referenznummer*/}
            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.referenceNumber')}
              value={company.reference_number}
              errorClass={
                !companyForm.reference_number.valid ? 'uk-text-danger' : null
              }
              triggerEdit={!companyForm.reference_number.valid}
              editable
            >
              <TextboxControl
                className='uk-input'
                field={companyForm.reference_number}
                placeholder={I18n.t(
                  'companyProfilePage.placeholder.referenceNumber',
                )}
                validators={CompanyFormValidators.reference_number}
                maxLength={15}
              />
            </ControlEditToggle>

            {/* ETA-Service*/}
            <RequiredPermissions
              requiredPermissions={[UserPermission.SET_COMPANY_ETA_SERVICE]}
            >
              <ControlEditToggle
                className='uk-margin-small-bottom'
                label={I18n.t('companyProfilePage.label.etaService')}
                value={I18n.t(`general.${company.eta_service ? 'yes' : 'no'}`)}
                errorClass={
                  !companyForm.eta_service.valid ? 'uk-text-danger' : null
                }
                triggerEdit={!companyForm.eta_service.valid}
                editable
              >
                <CheckboxControl
                  className='uk-checkbox'
                  field={companyForm.eta_service}
                  label={I18n.t('companyProfilePage.label.etaServiceCheckbox')}
                />
              </ControlEditToggle>
            </RequiredPermissions>

            {/* Kundennummer*/}
            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.customerNumber')}
              value={company.empto_external_number}
            />

            {/* Kundentyp*/}
            {companyIsMPCustomer && (
              <ControlEditToggle
                className='uk-margin-small-bottom'
                label={I18n.t('companyProfilePage.label.customerType')}
                value={
                  this.props.company.private_person
                    ? I18n.t(
                        'maklerpremium.addWasteProducerModal.form.is_company_false',
                      )
                    : I18n.t(
                        'maklerpremium.addWasteProducerModal.form.is_company_true',
                      )
                }
                errorClass={
                  !companyForm.private_person.valid ? 'uk-text-danger' : null
                }
                triggerEdit={
                  this.props.companyForm.private_person &&
                  !this.props.companyForm.private_person.valid
                }
                editable
              >
                <SelectControl
                  className='uk-select'
                  field={this.props.companyForm.private_person}
                  options={PRIVATE_PERSON_CHOICES.map(item => ({
                    id: item.id,
                    label: `${I18n.t(item.name)}`,
                  }))}
                  validators={CompanyFormValidators.private_person}
                />
              </ControlEditToggle>
            )}

            {/* Status*/}
            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.status')}
              value={getName(company.status, COMPANY_STATES, true)}
            />

            {companyIsBusinessSegmentDSZ && !companyIsMPPartner && (
              <>
                {/* Registrierungsnummer*/}
                <ControlEditToggle
                  className='uk-margin-medium-bottom'
                  label={I18n.t('companyProfilePage.label.registrationNumber')}
                  value={company.registration_number}
                  errorClass={
                    !companyForm.registration_number.valid
                      ? 'uk-text-danger'
                      : null
                  }
                  triggerEdit={!companyForm.registration_number.valid}
                  editable
                >
                  <TextboxControl
                    className='uk-input'
                    field={companyForm.registration_number}
                    placeholder={I18n.t(
                      'companyProfilePage.placeholder.registrationNumber',
                    )}
                    validators={CompanyFormValidators.registration_number}
                    maxLength={16}
                  />
                </ControlEditToggle>
              </>
            )}

            {/* Unternehmensgruppe */}
            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.companyGroup')}
              value={companyGroupText}
            />
          </div>

          {companyIsBusinessSegmentDSZ && !companyIsMPPartner && (
            <Errors
              model='company.item'
              className='form-error'
              messages={{
                registrationNumbersFilled: I18n.t(
                  'companyProfilePage.validation.registrationNumbersFilled',
                ),
              }}
            />
          )}
        </div>
      </section>
    )
  }
}

export default connector(CompanyDetailsComponent)
