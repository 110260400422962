import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'

import { BlockingModalForm } from './components/BlockingModalForm'

interface BlockCompanyModalProps {
  callback: () => void
  showModal: boolean
  company: Company
}

export const BlockCompanyModal: FC<BlockCompanyModalProps> = ({
  company,
  callback,
  showModal,
}) => {
  const modalId = uniqueId() // from lodash package. Generate a unique ID

  return (
    <Modal ariaDescribedBy={modalId} isOpen={showModal} onClose={callback}>
      <ModalHeader
        onClose={callback}
        title={I18n.t('blockCompanyModalTranslations.modalTitle')}
        titleId={modalId}
      />
      <BlockingModalForm company={company} onSuccess={callback} />
    </Modal>
  )
}
