import {
  any,
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import { ModelGetterFn } from 'react-redux-form'

import { TooltipIconProps } from '../TooltipIcon/schemes'

const ActionsScheme = shape({
  form: shape({
    focus: func.isRequired,
  }).isRequired,
}).isRequired

export const FieldScheme = shape({
  model: oneOfType([string, func]),
  value: any,
  initialValue: any,
  errors: oneOfType([shape({}), bool]),
  valid: bool,
  validated: bool,
  focus: bool,
  touched: bool,
  retouched: bool,
})

export interface FieldType {
  model: string | ModelGetterFn
  value?: any
  initialValue?: any
  errors?: { [key: string]: string } | boolean
  valid?: boolean
  validated?: boolean
  focus?: boolean
  touched?: boolean
  retouched?: boolean
}

export const FieldComponentScheme = shape({})

// @TODO **REFACTOR** declare at least all the built-in validators of react-redux-form as func
export const ValidatorScheme = shape({
  isRequired: func,
})

/**
 * @description use this const to import in specific control.
 */
export const ControlProps = {
  field: FieldScheme.isRequired,
  label: oneOfType([string, node]),
  placeholder: string,
  className: string,
  labelColSize: number,
  fieldColSize: number,
  showLabelColumn: bool,
  showTooltip: bool,
  tooltip: shape(TooltipIconProps),
  validators: ValidatorScheme,
  asyncValidators: ValidatorScheme,
  asyncValidateOn: arrayOf(string),
  errorMessages: shape({}),
  tabIndex: number,
  marginBottom: bool,
  clickableLabel: bool,
  disabled: bool,
  errorOnlyIfNotPristine: bool, // Show error message only for changed fields
  doNotShowIndividualError: bool, // Do not show error messages in this field
}

export const FormFieldProps = {
  ...ControlProps,
  actions: ActionsScheme,
  fieldComponent: FieldComponentScheme,
  onLabelClick: func,
  showRequiredDot: bool,
}
