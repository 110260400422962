import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_COLOR,
  BUTTON_SHAPE,
} from '../Button'
import { Tooltip, TOOLTIP_TRIGGER, TOOLTIP_ZINDEX } from '../Tooltip'

// export button constants to avoid importing Button for its props
export {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_FONT_COLOR,
  BUTTON_SHAPE,
} from '../Button'

type CommonLinkButtonProps = {
  dataTestid?: string
  backgroundColor?: BUTTON_BACKGROUND_COLOR
  className?: string
  fontColor?: BUTTON_FONT_COLOR
  fullWidth?: boolean
  isDisabled?: boolean
  tooltipText?: string
  shape?: BUTTON_SHAPE
  showTooltip?: boolean
}

type ConditionalLinkButtonProps =
  | {
      onClick?: never
      target?: string | { [key: string]: any }
    }
  | {
      onClick?: React.EventHandler<any>
      target?: never
    }
  | {
      onClick: React.EventHandler<any>
      target: string | { [key: string]: any }
    }

export type LinkButtonProps = CommonLinkButtonProps & ConditionalLinkButtonProps

/**
 * Renders a link with button appearance.
 */
export const LinkButton: FC<LinkButtonProps> = ({
  backgroundColor = BUTTON_BACKGROUND_COLOR.PRIMARY,
  className,
  children,
  dataTestid,
  fontColor = BUTTON_FONT_COLOR.DEFAULT,
  fullWidth,
  isDisabled,
  onClick,
  shape = BUTTON_SHAPE.DEFAULT,
  target,
  tooltipText = '',
  showTooltip = false,
}) => {
  const tooltipId = showTooltip ? uniqueId() : ''

  const disabledLinkButton = (
    <span
      className={classNames(
        'button',
        {
          'button--border-default button--disabled-style-secondary':
            backgroundColor === BUTTON_BACKGROUND_COLOR.SECONDARY ||
            backgroundColor === BUTTON_BACKGROUND_COLOR.DEFAULT ||
            backgroundColor === BUTTON_BACKGROUND_COLOR.WHITE ||
            backgroundColor === BUTTON_BACKGROUND_COLOR.TRANSPARENT,
        },
        {
          'button--border-none button--disabled-style-default':
            backgroundColor === BUTTON_BACKGROUND_COLOR.PRIMARY ||
            backgroundColor === BUTTON_BACKGROUND_COLOR.DANGER ||
            backgroundColor === BUTTON_BACKGROUND_COLOR.WARNING,
        },
        `button--color-${backgroundColor}`,
        'button--disabled',
        `button--shape-${shape}`,
        'uk-button',
        { 'button--full-width': fullWidth },
        {
          'uk-button-default':
            backgroundColor === BUTTON_BACKGROUND_COLOR.DEFAULT,
        },
        {
          'uk-button-primary':
            backgroundColor === BUTTON_BACKGROUND_COLOR.PRIMARY,
        },
        {
          'uk-button-secondary':
            backgroundColor === BUTTON_BACKGROUND_COLOR.SECONDARY,
        },
        {
          'uk-button-danger':
            backgroundColor === BUTTON_BACKGROUND_COLOR.DANGER,
        },
        className,
      )}
    >
      {children}
    </span>
  )

  const tooltipWithDisabledLinkButton = (
    <Tooltip
      duration={0}
      title={showTooltip ? tooltipText : undefined}
      trigger={TOOLTIP_TRIGGER.MOUSEENTER}
      tooltipId={`link-button-${tooltipId}`}
      zIndex={TOOLTIP_ZINDEX.BEFORE_HEADER_BEHIND_MODAL}
    >
      {disabledLinkButton}
    </Tooltip>
  )

  if (isDisabled)
    return showTooltip ? tooltipWithDisabledLinkButton : disabledLinkButton

  return (
    <Link
      className={classNames(
        'button',
        {
          'button--border-default button--disabled-style-secondary':
            backgroundColor === BUTTON_BACKGROUND_COLOR.SECONDARY ||
            backgroundColor === BUTTON_BACKGROUND_COLOR.DEFAULT ||
            backgroundColor === BUTTON_BACKGROUND_COLOR.WHITE ||
            backgroundColor === BUTTON_BACKGROUND_COLOR.TRANSPARENT,
        },
        {
          'button--border-none button--disabled-style-default':
            backgroundColor === BUTTON_BACKGROUND_COLOR.PRIMARY ||
            backgroundColor === BUTTON_BACKGROUND_COLOR.DANGER ||
            backgroundColor === BUTTON_BACKGROUND_COLOR.WARNING,
        },
        `button--background-color-${backgroundColor}`,
        `button--color-${backgroundColor}`,
        `button--font-color-${fontColor}`,
        `button--shape-${shape}`,
        'uk-button',
        { 'button--full-width': fullWidth },
        {
          'uk-button-default':
            backgroundColor === BUTTON_BACKGROUND_COLOR.DEFAULT,
        },
        {
          'uk-button-primary':
            backgroundColor === BUTTON_BACKGROUND_COLOR.PRIMARY,
        },
        {
          'uk-button-secondary':
            backgroundColor === BUTTON_BACKGROUND_COLOR.SECONDARY,
        },
        {
          'uk-button-danger':
            backgroundColor === BUTTON_BACKGROUND_COLOR.DANGER,
        },
        className,
      )}
      data-testid={dataTestid}
      onClick={onClick}
      to={target}
    >
      {children}
    </Link>
  )
}
