export const AgreementCardTranslations = {
  newAgreementCard: {
    startDate: 'Start der Vereinbarung am %{date}',
    endDate: 'Ende der Vereinbarung am %{date}',
    details: {
      title: 'Status der Vereinbarung',
      container: '%{capacity} %{name} (Anzahl:&nbsp;%{amount})',
      containerCubicMeters: '%{capacity} %{name} (Menge:&nbsp;%{amount})',
    },
  },
}
