import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import ActionTypes from '../actiontypes/dashboard'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetDashboard
 * @description gets dashboard content.
 */
const handleGetDashboard = createLogic({
  type: ActionTypes.GET_DASHBOARD_REQUEST,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/dashboard/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_DASHBOARD_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.dashboard.handleGetDashboard.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_DASHBOARD_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetDashboardEPD
 * @description gets dashboard epd content.
 */
const handleGetDashboardEPD = createLogic({
  type: ActionTypes.GET_DASHBOARD_EPD_REQUEST,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/epd-stakeholder-dashboard/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_DASHBOARD_EPD_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.dashboard.handleGetDashboardEPD.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_DASHBOARD_EPD_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [handleGetDashboard, handleGetDashboardEPD]
