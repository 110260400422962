import keyMirror from 'keymirror'

const CREATE_ORDER_CLAIM = keyMirror({
  CREATE_ORDER_CLAIM_REQUEST: null,
  CREATE_ORDER_CLAIM_SUCCESS: null,
  CREATE_ORDER_CLAIM_ERROR: null,
})

const GET_ORDER_CLAIM = keyMirror({
  GET_ORDER_CLAIM_REQUEST: null,
  GET_ORDER_CLAIM_SUCCESS: null,
  GET_ORDER_CLAIM_ERROR: null,
})

const GET_ORDER_CLAIM_LIST = keyMirror({
  GET_ORDER_CLAIM_LIST_REQUEST: null,
  GET_ORDER_CLAIM_LIST_SUCCESS: null,
  GET_ORDER_CLAIM_LIST_ERROR: null,
})

const UPDATE_REVIEWED_ORDER_CLAIM = keyMirror({
  UPDATE_REVIEWED_ORDER_CLAIM_REQUEST: null,
  UPDATE_REVIEWED_ORDER_CLAIM_SUCCESS: null,
  UPDATE_REVIEWED_ORDER_CLAIM_ERROR: null,
})

const UPDATE_ORDER_CLAIM_INTERNAL_NOTE = keyMirror({
  UPDATE_ORDER_CLAIM_INTERNAL_NOTE_REQUEST: null,
  UPDATE_ORDER_CLAIM_INTERNAL_NOTE_SUCCESS: null,
  UPDATE_ORDER_CLAIM_INTERNAL_NOTE_ERROR: null,
})

const EXPORT_ORDER_CLAIMS = keyMirror({
  EXPORT_ORDER_CLAIMS_REQUEST: null,
  EXPORT_ORDER_CLAIMS_SUCCESS: null,
  EXPORT_ORDER_CLAIMS_ERROR: null,
})

const RESET_ORDER_CLAIM_ITEM = keyMirror({
  RESET_ORDER_CLAIM_ITEM_REQUEST: null,
})

export default {
  ...CREATE_ORDER_CLAIM,
  ...GET_ORDER_CLAIM,
  ...GET_ORDER_CLAIM_LIST,
  ...UPDATE_REVIEWED_ORDER_CLAIM,
  ...UPDATE_ORDER_CLAIM_INTERNAL_NOTE,
  ...EXPORT_ORDER_CLAIMS,
  ...RESET_ORDER_CLAIM_ITEM,
}
