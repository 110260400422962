import React from 'react'

import ActionTypes from '../actiontypes/order'
import { OrderFilterValues } from '../components/order/OrdersPage'
import { TABLE_PAGE_SIZE_10 } from '../constants/app'

/**
 * @function
 * @name getOrder
 * @description Loads an order by id if it is created by my company.
 * @return {Object}
 */
export const getOrder = (id: number, expand?: string[]) => ({
  type: ActionTypes.GET_ORDER_REQUEST,
  payload: {
    id,
    expand,
  },
})

/**
 * @function
 * @name getOrders
 * @description Loads a list of orders.
 * @return {Object}
 */
export const getOrders = (
  page?: number,
  filters?: OrderFilterValues,
  pageSize = TABLE_PAGE_SIZE_10,
  expand?: string[],
) => ({
  type: ActionTypes.GET_ORDERS_REQUEST,
  payload: {
    page,
    filters,
    pageSize,
    expand,
  },
})

/**
 * @function
 * @name getOrdersForInvoiceCheck
 * @description Loads a list of orders. Uses a specialised endpoint that only returns order data specific to the
 * InvoiceCheck context
 * @return {Object}
 */
export const getOrdersForInvoiceCheck = (
  page?: number,
  filters?: { [key: string]: React.ReactText },
  pageSize = TABLE_PAGE_SIZE_10,
  expand?: string[],
) => ({
  type: ActionTypes.GET_ORDERS_FOR_INVOICE_CHECK_REQUEST,
  payload: {
    page,
    filters,
    pageSize,
    expand,
  },
})

export const resetOrdersForInvoiceCheckList = () => ({
  type: ActionTypes.RESET_ORDERS_FOR_INVOICE_CHECK_LIST,
})

/**
 * @name createOrder
 * @description creates a new order.
 * @return {Object}
 */
export const createOrder = order => ({
  type: ActionTypes.CREATE_ORDER_REQUEST,
  payload: order,
})

/**
 * @name updateOrderInternalComment
 * @description creates or updates internal comment for order
 * @return {Object}
 */
export const updateOrderInternalComment = (orderId, orderInternalComment) => ({
  type: ActionTypes.UPDATE_ORDER_INTERNAL_COMMENT_REQUEST,
  payload: { orderId, orderInternalComment },
})

/**
 * @name updateOrderAttachments
 * @description Updates attachments for order
 * @return {Object}
 */
export const updateOrderAttachments = (orderId, attachments) => ({
  type: ActionTypes.UPDATE_ORDER_ATTACHMENTS_REQUEST,
  payload: { orderId, attachments },
})

/**
 * @name disposeOrder
 * @description dispose an order.
 * @return {Object}
 */
export const disposeOrder = order => ({
  type: ActionTypes.DISPOSE_ORDER_REQUEST,
  payload: order,
})

/**
 * @name postponeOrder
 * @description postpone an order.
 * @return {Object}
 */
export const postponeOrder = orderPostpone => ({
  type: ActionTypes.POSTPONE_ORDER_REQUEST,
  payload: orderPostpone,
})

/**
 * @name cancelOrder
 * @description cancel an order.
 * @return {Object}
 */
export const cancelOrder = data => ({
  type: ActionTypes.CANCEL_ORDER_REQUEST,
  payload: data,
})

/**
 * @name setETA
 * @description set ETA for an order.
 * @return {Object}
 */
export const setETA = data => ({
  type: ActionTypes.SET_ETA_FOR_ORDER_REQUEST,
  payload: data,
})

/**
 * @name confirmExecution
 * @description confirm execution of an order.
 * @return {Object}
 */
export const confirmExecution = order => ({
  type: ActionTypes.CONFIRM_EXECUTION_OF_ORDER_REQUEST,
  payload: order,
})

/**
 * @name createOrderEvaluations
 * @description create order evaluations
 * @return {Object}
 */
export const createOrderEvaluations = evaluations => ({
  type: ActionTypes.CREATE_ORDER_EVALUATIONS_REQUEST,
  payload: evaluations,
})

/**
 * @function
 * @name exportOrders
 * @return {Object}
 */
export const exportOrders = filters => ({
  type: ActionTypes.EXPORT_ORDERS_REQUEST,
  payload: {
    filters,
  },
})

/**
 * @function
 * @name createInvoicingDocuments
 * @return {Object}
 */
export const createInvoicingDocuments = (
  data,
  callbacks?: { [key: string]: () => void },
) => ({
  type: ActionTypes.CREATE_INVOICING_DOCUMENTS_REQUEST,
  payload: {
    data,
    callbacks,
  },
})

/**
 * @function
 * @name getOrdersCount
 * @returns {Object}
 */
export const getOrdersCount = (data: {
  customer_company?: number
  offer_company?: number
}) => ({
  type: ActionTypes.GET_ORDERS_COUNT_REQUEST,
  payload: data,
})

/**
 * @function
 * @name resetOrders
 * @returns {Object}
 */
export const resetOrders = () => ({
  type: ActionTypes.GET_ORDERS_RESET,
})

/**
 * @function
 * @name getMaklerPremiumUpcomingOrders
 * @returns {Object}
 */
export const getMaklerPremiumUpcomingOrders = (
  page?: number,
  filters?: { [key: string]: React.ReactText },
  pageSize = TABLE_PAGE_SIZE_10,
) => ({
  type: ActionTypes.GET_MAKLER_PREMIUM_UPCOMING_ORDERS_REQUEST,
  payload: {
    page,
    filters,
    pageSize,
  },
})

/**
 * @function
 * @name setMaklerPremiumUpcomingOrderToConfirmed
 * @returns {Object}
 */
export const setMaklerPremiumUpcomingOrderToConfirmed = (
  order_id: number,
  upcoming_order_status: number,
  upcoming_order_type: number,
) => ({
  type: ActionTypes.SET_MAKLER_PREMIUM_UPCOMING_ORDER_TO_CONFIRMED_REQUEST,
  payload: {
    order_id,
    upcoming_order_status,
    upcoming_order_type,
  },
})

/**
 * @function
 * @name getOrderForDisposerManagement
 * @return {Object}
 */
export const getOrderForDisposerManagement = (
  order_id: number,
  order_hash: string,
) => ({
  type: ActionTypes.GET_ORDER_FOR_DISPOSER_MANAGEMENT_REQUEST,
  payload: {
    order_id,
    order_hash,
  },
})

/**
 * @function
 * @name setDisposerManagementOrderToConfirmed
 * @returns {Object}
 */
export const setDisposerManagementOrderToConfirmed = (
  order_hash: string,
  order_id: number,
  upcoming_order_status_value: number,
  upcoming_order_type: number[],
  user_id: number,
) => ({
  type: ActionTypes.SET_DISPOSER_MANAGEMENT_ORDER_TO_CONFIRMED_REQUEST,
  payload: {
    order_hash,
    order_id,
    upcoming_order_status_value,
    upcoming_order_type,
    user_id,
  },
})
