import { useFormikContext } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getCompaniesForFilter } from 'actions/company'
import { getCompanyUsers, getUsersByCompany } from 'actions/user'
import { BUSINESS_SEGMENT } from 'constants/app'
import { UserPermission } from 'constants/user'
import { createLoadingSelector } from 'selectors/loading'
import { getActiveUserList, getUsersByCompanyList } from 'selectors/user'

import {
  COMPANY_FILTER,
  CompanySearchFilter,
} from '../../common/Filter/components/CompanySearchFilter'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { COMPANY_ROLE } from '../../company/constants'
import { StaticCombobox } from '../StaticCombobox'
import { Option } from '../StaticCombobox/StaticCombobox'

interface EmptoPhoneUserSelectorProps {
  requiredPermissions: UserPermission[]
  showListForWasteCompanies?: boolean
  showOnlyActiveCompanies?: boolean
  // For the special case where the empto user is uploading a certificate for companyInfoPage component the user
  // selection must be rendered without selecting a company before. The company is preselected with preSelectedCompanyId
  preSelectedCompanyId?: number | string
  businessSegment?: BUSINESS_SEGMENT
  additionalCompanyChangeActions?: () => void
  mapToOptionCompany?: (company: any) => { label: string; value: string }
  // pass mapToOptionCompany if default mapToOption function can not be used
  onSelectionChangeCompany?: (company: CompanySearchFilterResponse) => void
  roleFilterCompany?: COMPANY_FILTER
}

export const EmptoPhoneUserSelector: FC<EmptoPhoneUserSelectorProps> = ({
  requiredPermissions,
  showListForWasteCompanies = false,
  showOnlyActiveCompanies = false,
  additionalCompanyChangeActions = () => undefined,
  preSelectedCompanyId = '',
  mapToOptionCompany,
  onSelectionChangeCompany,
  roleFilterCompany = COMPANY_FILTER.ALL,
}) => {
  const dispatch = useDispatch()
  const companyActiveUserList = useSelector(getActiveUserList)
  const preSelectedCompanyActiveUsersList = useSelector(getUsersByCompanyList)

  const loading = {
    companyActiveUserList: useSelector(
      createLoadingSelector(['GET_COMPANY_USERS']),
    ),
    // only in case the company is pre selected
    preSelectedCompanyActiveUsersList: useSelector(
      createLoadingSelector(['GET_USERS_BY_COMPANY']),
    ),
  }

  const [userOptions, setUserOptions] = useState<Option[]>([])

  const { errors, handleChange, setFieldValue, submitCount, values } =
    useFormikContext<{ company: string; phone_user: string }>()

  // only in case the company is pre selected
  useEffect(() => {
    if (preSelectedCompanyId) {
      dispatch(getUsersByCompany(Number(preSelectedCompanyId)))
    }
  }, [dispatch, preSelectedCompanyId])

  // only in case the company is pre selected
  useEffect(() => {
    if (preSelectedCompanyId) {
      setUserOptions(
        preSelectedCompanyActiveUsersList.map(item => ({
          label: `${item.first_name} ${item.last_name}`,
          value: `${item.id}`,
        })),
      )
    }
  }, [preSelectedCompanyActiveUsersList, preSelectedCompanyId])

  useEffect(() => {
    if (!preSelectedCompanyId && values.company) {
      dispatch(getCompanyUsers(Number(values.company)))
    }
  }, [dispatch, preSelectedCompanyId, values, values.company])

  useEffect(() => {
    if (!preSelectedCompanyId) {
      setUserOptions(
        companyActiveUserList.map(item => ({
          label: `${item.first_name} ${item.last_name}`,
          value: `${item.id}`,
        })),
      )
    }
  }, [companyActiveUserList, preSelectedCompanyId])

  const mapToOptionCompanyDefault = item => ({
    label: `${item.name} / ${item.empto_external_number} / ${item.street} / ${item.location} / ${item.central_contact}`,
    value: `${item.id}`,
  })

  const onSelectionChangeCompanyDefault = company => {
    additionalCompanyChangeActions()
    setFieldValue('phone_user', undefined)
    setFieldValue('company', company.id)
  }

  return (
    <RequiredPermissions requiredPermissions={requiredPermissions}>
      {/* COMPANY */}
      {/* If Company is pre-selected, do not show this field */}
      {!preSelectedCompanyId && (
        <CompanySearchFilter
          action={getCompaniesForFilter}
          noResultsText={I18n.t(
            'createInquiryTranslations.form.addressAndDeliveryFormGroup.noInputResults.company',
          )}
          placeholder={I18n.t(
            'createInquiryTranslations.form.addressAndDeliveryFormGroup.placeholder.company',
          )}
          label={I18n.t(
            'createInquiryTranslations.form.addressAndDeliveryFormGroup.label.company',
          )}
          name='company'
          resultType={
            showListForWasteCompanies
              ? COMPANY_ROLE.WASTE_COMPANY
              : COMPANY_ROLE.WASTE_PRODUCER
          }
          roleFilter={roleFilterCompany}
          dataTestId='empto-phone-company'
          onSelectionChange={
            onSelectionChangeCompany || onSelectionChangeCompanyDefault
          }
          mapToOption={mapToOptionCompany || mapToOptionCompanyDefault}
          error={submitCount > 0 ? errors.company : undefined}
          showOnlyActiveCompanies={showOnlyActiveCompanies}
          withCheckmark
          showCheckmark={!!values.company}
        />
      )}
      {/* PHONE USER */}
      {/* If Company is pre-selected, this field should be shown independent of the form value in company */}
      {((!preSelectedCompanyId && values.company) || preSelectedCompanyId) && (
        <StaticCombobox
          dataTestId='empto-phone-user'
          error={submitCount > 0 ? errors.phone_user : undefined}
          label={I18n.t(
            'createInquiryTranslations.form.addressAndDeliveryFormGroup.label.phoneUser',
          )}
          isLoading={
            loading.companyActiveUserList ||
            loading.preSelectedCompanyActiveUsersList
          }
          name='phone_user'
          noResultsText={I18n.t(
            'createInquiryTranslations.form.addressAndDeliveryFormGroup.noInputResults.phoneUser',
          )}
          options={userOptions}
          onSelectionChange={handleChange}
          placeholder={I18n.t(
            'createInquiryTranslations.form.addressAndDeliveryFormGroup.placeholder.phoneUser',
          )}
          selectedOption={userOptions.find(
            (option: Option) => option.value === `${values.phone_user}`,
          )}
          withCheckmark
          showCheckmark={!!values.phone_user}
        />
      )}
    </RequiredPermissions>
  )
}
