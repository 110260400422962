import { useFormikContext } from 'formik'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getOrders as getOrdersAction } from 'actions/order'
import { getCurrentUserSelector } from 'selectors/user'
import { COMPANY_ROLE } from 'components/company/constants'
import { ORDER_STATES } from 'components/order/constants'
import { OrderFilterValues } from 'components/order/OrdersPage'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { FindAddressByFilterInput } from 'components/common/FindByFilterInput'
import { InputNumber } from 'components/common/InputNumber'
import { NoContentMessage } from 'components/common/NoContentMessage'
import { StaticCombobox } from 'components/common/StaticCombobox'

import {
  filterMapFractionList,
  getCurrentValueObject,
  resetInputOption,
} from '../../helpers'
import { COMPANY_FILTER, CompanySearchFilter } from '../CompanySearchFilter'
import { FilterReset } from '../FilterReset'
import { cleanUpAndCountFiltersForReset } from '../FilterReset/helpers'

interface OrderFilterTypes {
  fractionList?: Fraction[]
  setCurrentFilterValues: (currentFilters: OrderFilterValues) => void
  handleBlur: React.EventHandler<any>
  handleChange: React.EventHandler<any>
  isLoading?: boolean
  length?: number
  resetForm: () => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  currentFilters?: OrderFilterValues
  values: OrderFilterValues
}

export const OrderFilter: FC<OrderFilterTypes> = ({
  fractionList,
  setCurrentFilterValues,
  currentFilters,
  handleBlur,
  handleChange,
  isLoading,
  length,
  resetForm,
  setFieldValue,
  values,
}) => {
  const dispatch = useDispatch()
  const getOrders = useCallback(
    (page, filters) => dispatch(getOrdersAction(page, filters)),
    [dispatch],
  )
  const user = useSelector(getCurrentUserSelector)
  const [currentSelectedFraction, setCurrentSelectedFraction] = useState<any>(
    {},
  )
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<any>({})

  const { setTouched } = useFormikContext()

  const getSelectionItems = useCallback(
    () => ({
      COARSE_FRACTION: [
        ...resetInputOption,
        ...filterMapFractionList(fractionList, user),
      ],
      STATUS: [
        ...resetInputOption,
        ...ORDER_STATES.filter(item => !item.excludeFromFilter).map(item => ({
          label: I18n.t(item.name),
          value: `${item.id}`,
        })),
      ],
    }),
    [fractionList, user],
  )

  useEffect(
    () => {
      setCurrentFilterValues(values)
      getOrders(null, values)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      values.id,
      values.page,
      values.page_size,
      values.company,
      values.created_at__gte,
      values.created_at__lte,
      values.offer,
      values.address,
      values.status,
      values.order_status,
      values.coarse_fraction,
      values.archive,
    ],
  )

  // prettier-ignore
  useEffect(
    () => {
      values.id               = currentFilters?.id              ? currentFilters.id               : ''
      values.page             = currentFilters?.page            ? currentFilters.page             : ''
      values.page_size        = currentFilters?.page_size       ? currentFilters.page_size        : ''
      values.company          = currentFilters?.company         ? currentFilters.company          : ''
      values.created_at__gte  = currentFilters?.created_at__gte ? currentFilters.created_at__gte  : ''
      values.created_at__lte  = currentFilters?.created_at__lte ? currentFilters.created_at__lte  : ''
      values.offer            = currentFilters?.offer           ? currentFilters.offer            : ''
      values.address          = currentFilters?.address         ? currentFilters.address          : ''
      values.status           = currentFilters?.status          ? currentFilters.status           : ''
      values.order_status     = currentFilters?.order_status    ? currentFilters.order_status     : ''
      values.coarse_fraction  = currentFilters?.coarse_fraction ? currentFilters.coarse_fraction  : ''
      values.archive          = currentFilters?.archive         ? currentFilters.archive          : false
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  useEffect(() => {
    setCurrentSelectedFraction(
      getCurrentValueObject(
        getSelectionItems().COARSE_FRACTION,
        values.coarse_fraction,
      ),
    )
  }, [getSelectionItems, values.coarse_fraction])

  useEffect(() => {
    setCurrentSelectedStatus(
      getCurrentValueObject(getSelectionItems().STATUS, values.order_status),
    )
  }, [getSelectionItems, values.order_status])

  const noResultsText = I18n.t('filterTranslations.noInputResults')
  const placeholder = I18n.t('general.placeholder.all')

  const handleChangeEvent = (name, event) => {
    handleChange(event)
    setTouched({ [name]: true })
  }

  return (
    <>
      <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
        <FilterReset
          onResetFilter={() => {
            resetForm()
            // resetting the form might lead to company being set if it already had an initial company
            setFieldValue('company', '')
          }}
          showResetFilterButton={
            cleanUpAndCountFiltersForReset(currentFilters) > 0 &&
            !currentFilters?.archive
          }
        />
      </ButtonBar>
      <div className='uk-grid uk-child-width-1-5@l uk-child-width-1-2@m'>
        <FindAddressByFilterInput
          resultType={COMPANY_ROLE.WASTE_PRODUCER}
          label={I18n.t('filterTranslations.orderFilter.collectionAddress')}
          name='search'
          noResultsText={noResultsText}
          placeholder={placeholder}
          handleBlur={handleBlur}
          handleSelectionChange={value => {
            if (value !== undefined) {
              setFieldValue('address', value)
              setTouched({ address: true })
            }
          }}
          isClearable
          onlyAddressLabel
          onlyUniqueOptions
          value={Number(values.address ?? 0)}
        />

        <CompanySearchFilter
          currentFilters={currentFilters}
          setCurrentFilterValues={values => {
            setFieldValue('company', values.id_slug?.split(',')[0])
            setTouched({ company: true })
          }}
          noResultsText={noResultsText}
          placeholder={placeholder}
          label={I18n.t('filterTranslations.myAutoOfferFilter.company')}
          name='company'
          roleFilter={COMPANY_FILTER.ALL}
          isClearable
          defaultCompanyId={Number(currentFilters?.company)}
        />

        <StaticCombobox
          isLoading={(fractionList ?? []).length < 1}
          label={I18n.t('filterTranslations.orderFilter.fraction')}
          name='coarse_fraction'
          noResultsText={noResultsText}
          options={getSelectionItems().COARSE_FRACTION}
          onSelectionChange={event => {
            handleChangeEvent('coarse_fraction', event)
          }}
          placeholder={placeholder}
          selectedOption={currentSelectedFraction}
        />

        <StaticCombobox
          label={I18n.t('filterTranslations.orderFilter.status')}
          name='order_status'
          noResultsText={noResultsText}
          options={getSelectionItems().STATUS}
          onSelectionChange={event => {
            handleChangeEvent('order_status', event)
          }}
          placeholder={placeholder}
          selectedOption={currentSelectedStatus}
        />

        <InputNumber
          label={I18n.t('filterTranslations.orderFilter.orderNumber')}
          maxLength={10}
          name='id'
          onBlur={handleBlur}
          onChange={event => {
            handleChangeEvent('id', event)
          }}
          value={values.id}
        />
      </div>

      <div className='filter__empty-message'>
        {!isLoading && (length ?? 0) < 1 && (
          <NoContentMessage
            message={I18n.t('general.emptyFilterResultMessage')}
            showResetFilterButton={false}
          />
        )}
      </div>
    </>
  )
}
