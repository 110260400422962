import { number, oneOfType, string } from 'prop-types'

export const AddressSmallScheme = {
  id: number,
  display_name: string,
  description: string,
  latitude: string,
  longitude: string,
  status: number,
  recipient: string,
  email_for_invoicing_documents: string,
}

export const AddressScheme = {
  id: number,
  contact_person: oneOfType([number, string]),
  company: oneOfType([number, string]),
  street: string,
  house_number: string,
  zipcode: string,
  description: string,
  location: string,
  latitude: string,
  longitude: string,
  status: number,
  created_at: string,
  lastmodified_at: string,
  status_lastmodified_at: string,
}
