import './MasonryGrid.scss'

import classNames from 'classnames'
import React, { Children, FC } from 'react'

interface MasonryGridProps {
  className?: string
  marginBottom?: boolean
  marginTop?: boolean
}

/**
 * Renders a MasonryGrid
 * @constructor
 */
export const MasonryGrid: FC<MasonryGridProps> = ({
  children,
  className,
  marginBottom,
  marginTop,
}) => (
  <section
    className={classNames(
      'uk-grid-collapse',
      'masonry-grid',
      { 'masonry-grid--margin-bottom': marginBottom },
      { 'masonry-grid--margin-top': marginTop },
      className,
    )}
    data-uk-grid='masonry: true'
  >
    {Children.toArray(children).map((child, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <div
        key={index}
        className='masonry-grid__item uk-width-1-1 uk-width-1-2@m uk-width-1-3@xl'
      >
        {child}
      </div>
    ))}
  </section>
)
