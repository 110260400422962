import classNames from 'classnames'
import React, { FC, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import ReactTable from 'react-table'

import { getCompanyLatestCertificates } from 'actions/certificate'
import {
  CERTIFICATE_TYPE,
  CERTIFICATE_TYPES,
} from 'components/certificate/constants'
import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import Icon, { ICON_NAME } from 'components/common/Fontello'
import { LinkButton } from 'components/common/LinkButton'
import { UpDownArrow } from 'components/common/UpDownArrow'
import { getName } from 'helper/translations'
import { getMyLatestCertificatesSelector } from 'selectors/certificate'

import { ArchiveModal } from './components/archiveModal'

// Type other needs another translation in this context
export const certificateTypes = CERTIFICATE_TYPES.map(type => {
  if (type.id === CERTIFICATE_TYPE.TYPE_OTHER) {
    return {
      id: CERTIFICATE_TYPE.TYPE_OTHER,
      name: 'documentUploadFormTranslations.choices.other',
    }
  } else {
    return type
  }
})

/**
 * @description This component renders the latest documents from company section of the extended company info page
 */
export const Documents: FC<{
  companyId: number
  archiveLink: string
}> = ({ companyId, archiveLink = [] }) => {
  // Redux
  const dispatch = useDispatch()

  // Selector
  const certificateList = useSelector(getMyLatestCertificatesSelector)

  // State
  const [certificateToArchive, setCertificateToArchive] = useState(undefined)

  const columns = [
    {
      Header: (
        <span>
          <Translate value='documents.table.header.number' />
          <UpDownArrow />
        </span>
      ),
      id: 'id_or_contract_id',
      accessor: data => data.id_or_contract_id,
    },
    {
      Header: (
        <span>
          <Translate value='documents.table.header.docType' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'type',
      Cell: data => I18n.t(getName(data.value, certificateTypes)),
    },
    {
      Header: (
        <span>
          <Translate value='documents.table.header.description' />
          <UpDownArrow />
        </span>
      ),
      id: 'description',
      accessor: data => data,
      Cell: data => {
        return (
          <div className='uk-flex'>
            {data.value.attachments &&
              data.value.attachments.map(file => (
                <Link
                  key={data.value.id}
                  to={{ pathname: file.file }}
                  onClick={event => {
                    event.preventDefault()
                    window.open(file.file)
                  }}
                >
                  {data.value.description ? data.value.description : file.name}
                </Link>
              ))}
          </div>
        )
      },
    },
    {
      id: 'archive',
      Header: '',
      sortable: false,
      accessor: data => data,
      Cell: data => (
        <div className='uk-width-1-1'>
          {data.value && (
            <div className='attachment uk-margin-auto-left'>
              <Link
                to='#'
                className='uk-float-right'
                onClick={event => {
                  event.preventDefault()
                  setCertificateToArchive(data.value)
                }}
              >
                <Icon name={ICON_NAME.ARCHIVE} />
                <Translate value='documents.table.archiveButton' />
              </Link>
            </div>
          )}
        </div>
      ),
    },
  ]

  return (
    <>
      <div className='documents contact-persons uk-grid' data-uk-grid=''>
        <div className='uk-width-1-1 uk-text-break'>
          <ReactTable
            manual
            data={certificateList}
            columns={columns}
            minRows={1}
            className={classNames('table -highlight')}
            showPagination={false}
            showPageSizeOptions={false}
            defaultSorted={[{ id: 'id_or_contract_id', desc: true }]}
            onFetchData={({ sorted }) => {
              if (sorted && sorted.length > 0) {
                dispatch(
                  getCompanyLatestCertificates(
                    companyId,
                    sorted,
                    // isMaklerPremiumDSZ ? ['id_or_contract_id'] : [],
                  ),
                )
              }
            }}
          />
        </div>
        <div className='uk-width-1-1@s uk-width-1-3@m'>
          <LinkButton
            target={{
              pathname: archiveLink,
            }}
            backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
            fullWidth
          >
            <Translate value='documents.archiveButton' />
          </LinkButton>
        </div>
      </div>
      <ArchiveModal
        certificate={certificateToArchive}
        onClose={() => setCertificateToArchive(undefined)}
      />
    </>
  )
}
