import { Form, Formik } from 'formik'
import moment from 'moment/moment'
import React, { FC, useEffect, useMemo } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { resetApiFetchErrors } from 'actions/app'
import { postponeOrder } from 'actions/order'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { DropDownInput } from 'components/common/DropDownInput'
import { ApiValidationMessages } from 'components/common/Form/components/ApiValidationMessages'
import InputDate from 'components/common/InputDate'
import { ProgressButton } from 'components/common/ProgressButton'
import {
  INQUIRY_ORDER_TYPE,
  INQUIRY_TIMES_OF_DAY,
} from 'components/inquiry/constants'
import { localizeDate } from 'helper/general'
import {
  getActualOrderCollectionDate,
  getActualOrderDeliveryDate,
  getActualOrderTimeOfDayCollection,
  getActualOrderTimeOfDayDelivery,
} from 'helper/order'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getOrderSelector } from 'selectors/order'

interface OrderPostponeFormProps {
  isDeliveryDisabled?: boolean
  isCollectionDisabled?: boolean
  onCancel?: () => void
  onSuccess?: () => void
}

/**
 * @description This component renders a form that allows the user to postpone an order.
 * @function
 */
export const OrderPostponeForm: FC<OrderPostponeFormProps> = ({
  isDeliveryDisabled = false,
  isCollectionDisabled = false,
  onCancel = () => undefined,
  onSuccess = () => undefined,
}) => {
  const dispatch = useDispatch()

  const order = useSelector(getOrderSelector)
  const error = useSelector(createErrorSelector(['POSTPONE_ORDER']))
  const isLoading = useSelector(createLoadingSelector(['POSTPONE_ORDER']))

  // Altough there is no effect to cleanup, we can use the "useEffect" capabilities to reset the ApiErrors on
  // general cleanup. This will behave like previously using "componentWillUnmount". A sole function is returned by
  // the effect with the desired action
  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors('POSTPONE_ORDER'))
    },
    [dispatch],
  )

  const actualOrderDeliveryDate = useMemo(
    () => getActualOrderDeliveryDate(order),
    [order],
  )

  const maxDate = useMemo(() => {
    if (
      order.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME &&
      moment(actualOrderDeliveryDate).isBefore()
    ) {
      return actualOrderDeliveryDate
    }
  }, [order, actualOrderDeliveryDate])

  return (
    <div className='order-postpone-form-page'>
      <Formik
        initialValues={{
          delivery_date_postponed: localizeDate(actualOrderDeliveryDate),
          time_of_day_delivery_postponed:
            getActualOrderTimeOfDayDelivery(order),
          collection_date_postponed: localizeDate(
            getActualOrderCollectionDate(order),
          ),
          time_of_day_collection_postponed:
            getActualOrderTimeOfDayCollection(order),
        }}
        validationSchema={() =>
          yup.object().shape({
            delivery_date_postponed: yup
              .string()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('${value} ist nicht vom Typ ${type}'),
            time_of_day_delivery_postponed: yup
              .string()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('${value} ist nicht vom Typ ${type}'),
            collection_date_postponed: yup
              .string()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('${value} ist nicht vom Typ ${type}'),
            time_of_day_collection_postponed: yup
              .string()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('${value} ist nicht vom Typ ${type}'),
          })
        }
        validate={values => {
          const errors: {
            delivery_date_postponed?: string
            time_of_day_delivery_postponed?: string
            collection_date_postponed?: string
            time_of_day_collection_postponed?: string
          } = {}

          if (values.collection_date_postponed === '') {
            errors.collection_date_postponed = I18n.t(
              'orderPostpone.collectionDateRequired',
            )
          }

          return errors
        }}
        onSubmit={values => {
          const callbacks = {
            success: () => onSuccess(),
          }

          dispatch(postponeOrder({ ...values, orderId: order.id, callbacks }))
        }}
      >
        {({
          errors,
          handleSubmit,
          handleChange,
          handleBlur,
          isSubmitting,
          isValid,
          setFieldTouched,
          setFieldValue,
          submitCount,
          touched,
          values,
        }) => (
          <Form
            className='uk-modal-body order-postpone-form'
            data-testid='order-postpone-form'
            noValidate // Disable browser validation
          >
            <ApiValidationMessages error={error} />
            {!isDeliveryDisabled && (
              <>
                <InputDate
                  error={
                    submitCount > 0 && touched.delivery_date_postponed
                      ? errors.delivery_date_postponed
                      : ''
                  }
                  dataTestId='delivery-date-postponed-input'
                  label={I18n.t(
                    `orderPostpone.${
                      !isCollectionDisabled ? 'deliveryDate' : 'executionDate'
                    }`,
                  )}
                  minDate={
                    order.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME
                      ? undefined
                      : order.offer_object.service_start_date
                  }
                  maxDate={maxDate}
                  name='delivery_date_postponed'
                  onChange={value => {
                    setFieldValue('delivery_date_postponed', value.format('L'))
                    setFieldTouched('delivery_date_postponed')
                    if (
                      moment(value, 'L') >
                      moment(values.collection_date_postponed, 'L')
                    ) {
                      setFieldValue('collection_date_postponed', '')
                    }
                  }}
                  placeholder={I18n.t(
                    `orderPostpone.${
                      !isCollectionDisabled ? 'deliveryDate' : 'executionDate'
                    }`,
                  )}
                  value={values.delivery_date_postponed}
                  validateOnMount={false} // allow initial before minDate, but disallow selecting dates in the past
                  isRequired={!values.delivery_date_postponed}
                />

                <DropDownInput
                  error={
                    submitCount > 0 && touched.time_of_day_delivery_postponed
                      ? (errors.time_of_day_delivery_postponed as string)
                      : ''
                  }
                  dataTestId='time-of-day-delivery-postponed-select'
                  choices={INQUIRY_TIMES_OF_DAY.map(item => ({
                    optionValue: item.id,
                    optionLabel: I18n.t(item.name),
                  }))}
                  label={I18n.t('general.timeOfDay')}
                  name='time_of_day_delivery_postponed'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t('general.timeOfDay')}
                  value={values.time_of_day_delivery_postponed}
                  isRequired={!values.time_of_day_delivery_postponed}
                />
              </>
            )}
            {!isCollectionDisabled && (
              <>
                <InputDate
                  error={
                    submitCount > 0 && touched.collection_date_postponed
                      ? errors.collection_date_postponed
                      : ''
                  }
                  dataTestId='collection-date-postponed-input'
                  label={I18n.t(
                    `orderPostpone.${
                      !isCollectionDisabled ? 'collectionDate' : 'executionDate'
                    }`,
                  )}
                  // eslint-disable-next-line import/no-named-as-default-member
                  minDate={moment.max(
                    moment(values.delivery_date_postponed, 'L'),
                    moment(),
                  )}
                  name='collection_date_postponed'
                  onChange={value => {
                    setFieldValue(
                      'collection_date_postponed',
                      value.format('L'),
                    )
                    setFieldTouched('collection_date_postponed')
                  }}
                  placeholder={I18n.t(
                    `orderPostpone.${
                      !isCollectionDisabled ? 'collectionDate' : 'executionDate'
                    }`,
                  )}
                  value={values.collection_date_postponed}
                  validateOnMount={false} // allow initial before minDate, but disallow selecting dates in the past
                  isRequired={!values.collection_date_postponed}
                />
                <DropDownInput
                  error={
                    submitCount > 0 && touched.time_of_day_collection_postponed
                      ? (errors.time_of_day_collection_postponed as string)
                      : ''
                  }
                  dataTestId='time-of-day-collection-postponed-select'
                  choices={INQUIRY_TIMES_OF_DAY.map(item => ({
                    optionValue: item.id,
                    optionLabel: I18n.t(item.name),
                  }))}
                  label={I18n.t('general.timeOfDay')}
                  name='time_of_day_collection_postponed'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t('general.timeOfDay')}
                  value={values.time_of_day_collection_postponed}
                  isRequired={!values.time_of_day_collection_postponed}
                />
              </>
            )}
            <div className='uk-modal-footer upload-document-form__buttons'>
              <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                <Button
                  backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                  onClick={onCancel}
                  dataTestId='order-postpone-form-cancel-button'
                >
                  {I18n.t('general.button.cancel')}
                </Button>

                <ProgressButton
                  backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                  dataTestId='invite-user-form-submit'
                  isDisabled={
                    (submitCount > 0 && !isValid) || (isSubmitting && isLoading)
                  }
                  isLoading={isSubmitting && isLoading}
                  onClick={handleSubmit}
                  type={BUTTON_TYPE.BUTTON}
                >
                  {I18n.t('general.button.save')}
                </ProgressButton>
              </ButtonBar>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
