import { arrayOf, number, shape } from 'prop-types'
import React from 'react'

import { ContainerScheme } from 'schemes/container'

import { getContainerName } from '../../inquiry/helpers'

import connector from './connector'

/**
 * This component displays the name of the container using its id (e.g. for an inquiry or offer). The API only contains
 * the ID. This happens for performance reasons: There is only a limited number of containers but a potentially large
 * number of inquiries, offers etc. We do not want to repeat this data as nested serializer in large number.
 * Therefore this application loads the list of containers once and then uses the id to find the container in the list.
 * @param props
 * @returns {*} span with the naem of the container
 * @constructor
 */
const ContainerName = props => (
  <span>{getContainerName(props.containerId, props.containerList)}</span>
)

ContainerName.propTypes = {
  containerId: number.isRequired,
  containerList: arrayOf(shape(ContainerScheme)).isRequired,
}

export default connector(ContainerName)
