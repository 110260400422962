import ActionTypes from '../actiontypes/dashboard'

/**
 * @function
 * @name getDashboard
 * @description Loads all dashboard data
 * @return [Object]
 */
export const getDashboard = () => ({
  type: ActionTypes.GET_DASHBOARD_REQUEST,
})

/**
 * @function
 * @name getDashboardEPD
 * @description Loads all dashboard epd data
 * @return [Object]
 */
export const getDashboardEPD = () => ({
  type: ActionTypes.GET_DASHBOARD_EPD_REQUEST,
})
