import { I18n } from 'react-i18nify'

export const getCurrentUser = (userList, userId) =>
  userList
    .map(item => ({
      value: item.id,
      label: `${item.first_name} ${item.last_name}`,
    }))
    .find(item => item.value === Number(userId))

/**
 * These are the genders for a user.
 */
export enum USER_GENDER {
  MAN = 1,
  WOMAN = 2,
}

export const USER_GENDER_CHOICES = () => [
  {
    optionLabel: I18n.t('constant.gender.mr'),
    optionValue: USER_GENDER.MAN,
  },
  {
    optionLabel: I18n.t('constant.gender.mrs'),
    optionValue: USER_GENDER.WOMAN,
  },
]

export const USER_GENDER_LABELS = () => ({
  [USER_GENDER.MAN]: I18n.t('constant.gender.mr'),
  [USER_GENDER.WOMAN]: I18n.t('constant.gender.mrs'),
})
