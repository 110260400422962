import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import Media from 'react-media'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import IndicationMessage from 'components/common/IndicationMessage'
import { BREAKPOINT } from 'constants/design'
import { localizeDate } from 'helper/general'

interface CompanyInfoPageWasteCompanyIndicationMessageProps {
  blockUnblockAutoOffersAction?: () => void
  blockAutoOffersStartDate?: string
  blockAutoOffersEndDate?: string
  blockAutoOffersReason?: string
}

export const CompanyInfoPageWasteCompanyIndicationMessage: FC<
  CompanyInfoPageWasteCompanyIndicationMessageProps
> = ({
  blockUnblockAutoOffersAction = () => undefined,
  blockAutoOffersStartDate,
  blockAutoOffersEndDate,
  blockAutoOffersReason,
}) => {
  const unblockButton = (
    <div className='company-info-page__indication-messages__cta uk-width-1-1 uk-width-1-5@m'>
      <div className='company-info-page__lock-unlock-modal-button'>
        <Button
          backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
          onClick={() => blockUnblockAutoOffersAction()}
        >
          <Translate value='companyInfoPageWasteCompanyIndicationMessage.buttonTextUnlock' />
        </Button>
      </div>
    </div>
  )
  return (
    <div className='company-info-page__indication-messages'>
      <IndicationMessage
        className='warning'
        statusClass='warning'
        statusIconName='information'
        footerContent={
          <>
            <Media query={{ maxWidth: BREAKPOINT.MEDIUM - 1 }}>
              {unblockButton}
            </Media>
          </>
        }
      >
        <div className='uk-grid'>
          <div className='uk-width-1-1 uk-width-4-5@m'>
            {!blockAutoOffersEndDate && blockAutoOffersStartDate && (
              <p className='company-info-page__indication-messages--bold'>
                <Translate
                  value='companyInfoPageWasteCompanyIndicationMessage.infoStart'
                  start_date={localizeDate(blockAutoOffersStartDate)}
                />
              </p>
            )}
            {blockAutoOffersStartDate && blockAutoOffersEndDate && (
              <p className='company-info-page__indication-messages--bold'>
                <Translate
                  value='companyInfoPageWasteCompanyIndicationMessage.infoStartEnd'
                  start_date={localizeDate(blockAutoOffersStartDate)}
                  end_date={localizeDate(blockAutoOffersEndDate)}
                />
              </p>
            )}
            <p>
              <Translate value='companyInfoPageWasteCompanyIndicationMessage.message' />
            </p>
            {blockAutoOffersReason && (
              <p>
                <Translate
                  value='companyInfoPageWasteCompanyIndicationMessage.reason'
                  reason={blockAutoOffersReason}
                />
              </p>
            )}
          </div>
          <Media query={{ minWidth: BREAKPOINT.MEDIUM }}>{unblockButton}</Media>
        </div>
      </IndicationMessage>
    </div>
  )
}
