import 'react-table/react-table.css'

import uniqueId from 'lodash.uniqueid'
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { compose } from 'recompose'

import withErrorBoundary from 'helper/withErrorBoundary'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import { localizeDateForBackend } from 'helper/general'
import { MaklerPremiumGroups, UserPermission } from 'constants/user'
import { getCompanyUsers } from 'actions/user'
import {
  blockAddress,
  exportAddresses,
  getAddresses,
  updateAddress,
} from 'actions/address'
import {
  getAddressesSelector,
  getAddressPaginationSelector,
} from 'selectors/address'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'

import { AddressForm } from '../../common/AddressForm'
import { AsyncExportModal } from '../../common/AsyncExportModal'
import { DateForm } from '../../common/AsyncExportModal/DateForm/DateForm'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { ProgressButton } from '../../common/ProgressButton'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import { PageHeader } from '../../layout/PageHeader'
import AddAddressButton from '../AddAddressButton'
import AddressList from '../AddressList'
import { ADDRESS_STATUS } from '../constants'

import { ADDRESS_MODALS } from './constants'

export const MyAddressesPageComponent = () => {
  const dispatch = useDispatch()

  const [addressToEdit, setAddressToEdit] = useState<Address>()
  const [isEditing, setIsEditing] = useState(false)
  const [openModal, setOpenModal] = useState<string>()
  const [sortedBy, setSortedBy] = useState([])
  const [filters, setFilters] = useState<{
    id?: React.ReactText
    no_children?: boolean
    created_at__gte?: React.ReactText
    created_at__lte?: React.ReactText
  }>({ no_children: true })
  const [currentAddress, setCurrentAddress] = useState<Address>()

  const loadingSelector = createLoadingSelector([
    'GET_ADDRESSES',
    'GET_COMPANY_USERS',
  ])
  const addressList = useSelector(getAddressesSelector)
  const pagination = useSelector(getAddressPaginationSelector)
  const isLoading = useSelector(loadingSelector)
  const isLoadingExport = useSelector(
    createLoadingSelector(['EXPORT_ADDRESSES']),
  )
  const currentUser = useSelector(getCurrentUserSelector)

  const userIsMaklerPremium = currentUser.group_id in MaklerPremiumGroups
  const { companyId } = useParams<{ companyId: string }>()

  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [exportStartDate, setExportStartDate] = useState<string>('')
  const [exportEndDate, setExportEndDate] = useState<string>('')

  const getExportFilters = useMemo(() => {
    let exportFilters = {
      ...filters,
      company__makler_premium_company: currentUser.company,
    }

    // Cleaning id filter
    if (exportFilters['id']) {
      exportFilters['company__id'] = exportFilters['id']
      delete exportFilters['id']
    }

    // cleaning no chilfren filter
    if (exportFilters['no_children']) delete exportFilters['no_children']

    Object.keys(filters).forEach(filter => {
      // remove empty filter
      if (filters[filter] === '') {
        delete filters[filter]
        return
      }
    })

    if (exportStartDate) {
      exportFilters = {
        ...exportFilters,
        created_at__gte: localizeDateForBackend(exportStartDate),
      }
    }

    if (exportEndDate) {
      exportFilters = {
        ...exportFilters,
        created_at__lte: localizeDateForBackend(exportEndDate),
      }
    }

    return exportFilters
  }, [filters, exportStartDate, exportEndDate, currentUser.company])

  useEffect(() => {
    if (userIsMaklerPremium || companyId) {
      setFilters({
        id: companyId,
        no_children: false,
      })
    }
  }, [companyId, userIsMaklerPremium])

  useEffect(() => {
    dispatch(getAddresses(undefined, sortedBy, filters))
    let companyFilter
    if (companyId) {
      companyFilter = companyId
    }
    dispatch(getCompanyUsers(companyFilter))
  }, [companyId, dispatch, filters, sortedBy])

  /**
   * @description call getAddresses and save sorting values in state
   */
  const getSortingValues = state => {
    if (state.sorted.length) {
      dispatch(getAddresses(undefined, state.sorted, state.filters))
      setSortedBy(state.sorted)
    }
  }

  /**
   * @description Toggle a modal
   * @param modal
   * @param event
   * @param address
   */
  const handleModalToggle = (
    modal?: string,
    event?: React.UIEvent,
    address?: Address,
  ) => {
    if (event) event.preventDefault()

    setOpenModal(modal)
    setCurrentAddress(address)
  }

  /**
   * @description Open modal to add an address
   */
  const handleAddAddress = () => {
    setOpenModal(ADDRESS_MODALS.ADDRESS_FORM_MODAL)
    setAddressToEdit(undefined)
    setIsEditing(false)
  }

  /**
   * @description Open modal to edit an address
   */
  const handleEditAddress = address => {
    setOpenModal(ADDRESS_MODALS.ADDRESS_FORM_MODAL)
    setAddressToEdit(address)
    if (userIsMaklerPremium) {
      setFilters({
        id: companyId,
        no_children: false,
      })
    }
    setIsEditing(true)
  }

  /**
   * @description Disable an address
   * @param address object
   */
  const handleDisableAddress = address => {
    dispatch(blockAddress(address.id))
    handleModalToggle(undefined)
  }

  /**
   * @description Changes the status of the address. This does a partial update call that changes only the status.
   * @param address object
   * @param status
   */
  const handleChangeStatus = (address, status) => {
    if (status === ADDRESS_STATUS.STATUS_INACTIVE) {
      handleModalToggle(ADDRESS_MODALS.BLOCK_ADDRESS_MODAL, undefined, address)
      return
    }
    dispatch(updateAddress({ id: address.id, status }))
  }

  /**
   * @description Renders the address form modal to add or edit addresses
   * @return {*}
   */
  const renderAddressFormModal = () => {
    const title = isEditing
      ? I18n.t('addressForm.header.titleEdit')
      : I18n.t('addressForm.header.title')
    const idAddressFormModalHeadline = uniqueId()

    return (
      <Modal
        ariaDescribedBy={idAddressFormModalHeadline}
        isOpen={openModal === ADDRESS_MODALS.ADDRESS_FORM_MODAL}
        onClose={handleModalToggle}
      >
        <ModalHeader
          onClose={handleModalToggle}
          title={title}
          titleId={idAddressFormModalHeadline}
        />

        <AddressForm
          onCancel={handleModalToggle}
          onSuccess={() => {
            handleModalToggle()
            getAddresses(null, null, filters)
          }}
          isEditing={isEditing}
          addressToEdit={addressToEdit}
          sendUserInfo={filters ? { company: filters?.id } : {}}
        />
      </Modal>
    )
  }

  /**
   * @description Renders the block address modal
   * @return {*}
   */
  const renderBlockAddressModal = () => {
    const idBlockAddressModalHeadline = uniqueId()

    return (
      <Modal
        ariaDescribedBy={idBlockAddressModalHeadline}
        isOpen={openModal === ADDRESS_MODALS.BLOCK_ADDRESS_MODAL}
        onClose={handleModalToggle}
      >
        <ModalHeader
          onClose={handleModalToggle}
          title={I18n.t('inquiryDetails.cancelInquiry.button')}
          titleId={idBlockAddressModalHeadline}
        />

        <div className='uk-text-center uk-text-lead uk-modal-body'>
          <div className='uk-margin-medium'>
            <Translate value='address.disable.info' />
          </div>
        </div>
        <div className='uk-modal-footer uk-text-right'>
          <Button
            className='uk-margin-right'
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            dataTestId='block-address-modal-close'
            onClick={handleModalToggle}
          >
            <Translate value='general.button.cancel' />
          </Button>
          <ProgressButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            dataTestId='block-address-modal-submit'
            isDisabled={isLoading}
            isLoading={isLoading}
            onClick={() => handleDisableAddress(currentAddress)}
            type={BUTTON_TYPE.SUBMIT}
          >
            <Translate value='general.button.submit' />
          </ProgressButton>
        </div>
      </Modal>
    )
  }

  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.companyAddresses')}</title>
      </Helmet>

      <div className='my-addresses-page'>
        <PageHeader title={I18n.t('myAddresses.heading')}>
          <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
            <RequiredPermissions
              requiredPermissions={[UserPermission.EXPORT_ADDRESSES]}
            >
              <IconButton
                iconName='export'
                iconPosition={ICON_POSITION.RIGHT}
                isDisabled={isLoading}
                onClick={() => setIsExportModalOpen(true)}
                isLoading={isLoadingExport}
              >
                <Translate value='myAddresses.exportButton' />
              </IconButton>
            </RequiredPermissions>
            <AddAddressButton
              onClick={handleAddAddress}
              isDisabled={isLoading}
            />
          </ButtonBar>
        </PageHeader>

        <AddressList
          pages={pagination.count}
          page={pagination.current}
          onPreviousPageClick={() =>
            dispatch(getAddresses(pagination.previous, sortedBy, filters))
          }
          onNextPageClick={() =>
            dispatch(getAddresses(pagination.next, sortedBy, filters))
          }
          addressList={addressList}
          onFetchData={getSortingValues}
          isLoading={isLoading}
          onStatusChange={handleChangeStatus}
          onModalToggle={handleModalToggle}
          onAddAddress={handleAddAddress}
          onEditAddress={handleEditAddress}
        />

        {/* Modals */}
        {renderAddressFormModal()}
        {renderBlockAddressModal()}
      </div>

      <AsyncExportModal
        isOpen={isExportModalOpen}
        onClose={() => setIsExportModalOpen(false)}
        reduxSelector='EXPORT_ADDRESSES'
        title={I18n.t('myAddresses.exportModalTitle')}
        description_translation_key={
          'asyncExportModalTranslations.descriptionWithFilter'
        }
        notice_translation_key={''}
        logic={exportAddresses(getExportFilters, {
          order_by: JSON.stringify([{ id: 'document_date', desc: true }]),
        })}
        resetOnDispatch={() => {
          setExportStartDate('')
          setExportEndDate('')
        }}
      >
        <DateForm
          setStartDate={setExportStartDate}
          setEndDate={setExportEndDate}
        />
      </AsyncExportModal>
    </>
  )
}

export const MyAddressesPage = compose(
  withErrorBoundary,
  withApiErrorHandling,
)(MyAddressesPageComponent)
