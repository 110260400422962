export const requestDemoTranslations = {
  title: 'Demo anfordern',
  subTitle:
    'Das Team von empto führt Sie gerne in einer Demo durch die Funktionen der Plattform. Füllen Sie dazu' +
    ' einfach das folgende Formular aus. Ein empto Mitarbeiter wird sich schnellstmöglich mit Ihnen in Verbindung ' +
    'setzen, um die Demo gemeinsam mit Ihnen durchzugehen und beantwortet Ihnen gern aufkommende Fragen.',
  form: {
    demoDateLabel: 'Optional: Ihr Wunschtermin',
    demoDatePlaceholder: 'Wunschtermin',
    timeOfDayLabel: 'Optional: Zeitraum',
    companyNameLabel: 'Optional: Name des Unternehmens',
    acceptConditions:
      'Ich bestätige die <a href="%{linkPrivacyStatement}" target="_blank">Datenschutzerklärung</a>.',
    submitButton: 'Demo anfordern',
  },
}
