import './style.scss'

import moment from 'moment'
import React, { FC, useContext, useEffect } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getMyAutoOffers, updateAutoOffer } from 'actions/autooffer'
import { BUSINESS_SEGMENT_CHOICES } from 'constants/app'
import { UserPermission } from 'constants/user'
import { checkRequiredPermissions } from 'helper/permissions'
import { getName } from 'helper/translations'
import {
  getAutoOffersPaginationSelector,
  getMyAutoOffersSelector,
} from 'selectors/autooffer'
import { getContainersSelector } from 'selectors/container'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'

import { BUTTON_TYPE } from '../../common/Button'
import ContainerName from '../../common/ContainerName'
import { ICON_NAME } from '../../common/Fontello'
import { IconButton } from '../../common/IconButton'
import { PaginatedTable } from '../../common/PaginatedTable'
import { Switcher } from '../../common/Switcher'
import { UpDownArrow } from '../../common/UpDownArrow'
import { INQUIRY_ORDER_TYPES } from '../../inquiry/constants'
import { AUTO_OFFER_STATES, AUTO_OFFER_STATUS } from '../constants'

import { MyAutoOffersListContext } from './MyAutoOffersPage'

interface MyAutoOffersListComponentProps {
  archiveMode?: boolean
  onEditClick?: (data?: Record<string, unknown>) => void
}

export const MyAutoOffersList: FC<MyAutoOffersListComponentProps> = ({
  archiveMode = false,
  onEditClick = () => undefined,
}) => {
  const dispatch = useDispatch()

  const { currentFilters, currentSorted, setCurrentFilters, setCurrentSorted } =
    useContext<MyAutoOffersListContext>(MyAutoOffersListContext)

  const isLoading = {
    getMyAutoOffers: useSelector(createLoadingSelector(['GET_MY_AUTO_OFFERS'])),
    getContainerList: useSelector(createLoadingSelector(['GET_CONTAINERS'])), // for filter
    updateAutoOffer: useSelector(createLoadingSelector(['UPDATE_AUTO_OFFER'])),
  }

  const apiError = {
    getMyAutoOffers: useSelector(createErrorSelector(['GET_MY_AUTO_OFFERS'])),
    getContainerList: useSelector(createErrorSelector(['GET_CONTAINERS'])), // for filter
    updateAutoOffer: useSelector(createErrorSelector(['UPDATE_AUTO_OFFER'])),
  }

  const autoOfferList = useSelector(getMyAutoOffersSelector)
  const pagination = useSelector(getAutoOffersPaginationSelector)
  const containerList = useSelector(getContainersSelector)
  const user = useSelector(getCurrentUserSelector)

  useEffect(() => {
    if (
      !isLoading.getMyAutoOffers &&
      !pagination.loaded &&
      !apiError.getMyAutoOffers
    ) {
      dispatch(getMyAutoOffers(null, currentFilters))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFilters, dispatch])

  useEffect(() => {
    const archiveFilter = {
      ...currentFilters,
      status: archiveMode
        ? AUTO_OFFER_STATUS.STATUS_INACTIVE
        : AUTO_OFFER_STATUS.STATUS_ACTIVE,
    }
    dispatch(getMyAutoOffers(null, archiveFilter))
    setCurrentFilters(archiveFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [archiveMode])

  const canFilterAndCheckCompany =
    checkRequiredPermissions(user.permission_codenames, [
      UserPermission.VIEW_AUTOOFFER,
    ]) && !user.company
  const canChangeAutoOffer =
    checkRequiredPermissions(user.permission_codenames, [
      UserPermission.CHANGE_AUTOOFFER,
    ]) && user.company

  const idColumn = [
    {
      id: 'id',
      accessor: 'id',
      Header: (
        <>
          <Translate value='general.id' />
          <UpDownArrow />
        </>
      ),
      width: 60,
    },
  ]

  const companyColumn = canFilterAndCheckCompany
    ? [
        {
          id: 'company__name',
          accessor: 'company_name',
          Header: (
            <>
              <Translate value='general.company' />
              <UpDownArrow />
            </>
          ),
          width: 250,
        },
      ]
    : []

  const fractionColumn = [
    {
      accessor: 'fraction_name',
      Header: (
        <>
          <Translate value='inquiry.fraction' />
          <UpDownArrow />
        </>
      ),
      id: 'fraction',
    },
  ]

  const avvColumn = [
    {
      id: 'avv',
      accessor: 'avv_number',
      Header: (
        <>
          <Translate value='general.avvShort' />
          <UpDownArrow />
        </>
      ),
      width: 70,
    },
  ]

  const orderTypeColumn = [
    {
      accessor: 'order_type',
      Cell: data => I18n.t(getName(data.value, INQUIRY_ORDER_TYPES)),
      Header: (
        <>
          <Translate value='inquiry.orderType' />
          <UpDownArrow />
        </>
      ),
    },
  ]
  const numberOfContainersColumn = [
    {
      accessor: 'number_of_containers',
      Header: (
        <>
          <Translate value='inquiry.numberOfContainersMaxAbbr' />
          <UpDownArrow />
        </>
      ),
      width: 60,
    },
  ]
  const containerColumn = [
    {
      id: 'container__name',
      accessor: 'container',
      Cell: data => (
        <ContainerName containerId={data.value} containerList={containerList} />
      ),
      Header: (
        <>
          <Translate value='inquiry.container' />
          <UpDownArrow />
        </>
      ),
    },
  ]
  const communitiesColumn = [
    {
      accessor: 'communities',
      Cell: data => data.value.map(community => community.name).join(', '),
      Header: (
        <>
          <Translate value='myAutoOffersPageTranslations.list.communities' />
          <UpDownArrow />
        </>
      ),
    },
  ]
  const zipcodesColumn = [
    {
      accessor: 'zipcodes',
      Cell: data => data.value.map(zipcode => zipcode.code).join(', '),
      Header: (
        <>
          <Translate value='myAutoOffersPageTranslations.list.zipcodes' />
          <UpDownArrow />
        </>
      ),
    },
  ]
  const createdByColumn = [
    {
      id: 'created_by__first_name',
      accessor: data => `${data.created_by_name}`,
      Header: (
        <>
          <Translate value='general.createdBy' />
          <UpDownArrow />
        </>
      ),
    },
  ]
  const createdAtColumn = [
    {
      accessor: 'created_at',
      Cell: data => moment(data.value).format('DD.MM.YYYY LT'),
      Header: (
        <>
          <Translate value='general.createdAt' />
          <UpDownArrow />
        </>
      ),
    },
  ]
  const buisnessSegmentColumn = [
    {
      accessor: 'business_segment',
      Cell: data => I18n.t(getName(data.value, BUSINESS_SEGMENT_CHOICES)),
      Header: (
        <>
          <Translate value='myAutoOffersPageTranslations.form.fields.businessSegment.label' />
          <UpDownArrow />
        </>
      ),
    },
  ]
  const validFromColumn = [
    {
      accessor: 'valid_from',
      Cell: data => moment(data.value).format('DD.MM.YYYY'),
      Header: (
        <>
          <Translate value='myAutoOffersPageTranslations.form.fields.validFrom.label' />
          <UpDownArrow />
        </>
      ),
    },
  ]
  const validUntilColumn = [
    {
      accessor: 'valid_until',
      Cell: data => moment(data.value).format('DD.MM.YYYY'),
      Header: (
        <>
          <Translate value='myAutoOffersPageTranslations.form.fields.validUntil.label' />
          <UpDownArrow />
        </>
      ),
    },
  ]
  const statusColumn = canChangeAutoOffer
    ? [
        {
          id: 'status',
          accessor: 'status',
          Cell: data => (
            <Switcher
              currentValue={
                AUTO_OFFER_STATES.find(state => state.id === data.value)!
              }
              isActive={data.value === AUTO_OFFER_STATUS.STATUS_ACTIVE}
              onValueChange={value =>
                dispatch(
                  updateAutoOffer({ id: data.original.id, status: value.id }),
                )
              }
              values={AUTO_OFFER_STATES}
            />
          ),
          Header: (
            <>
              <Translate value='myAddresses.table.header.status' />
              <UpDownArrow />
            </>
          ),
        },
      ]
    : []

  const editColumn =
    canChangeAutoOffer && !archiveMode
      ? [
          {
            id: 'actions',
            accessor: 'id',
            Cell: data => (
              <>
                <IconButton
                  showOnlyIcon
                  dataTestId='edit-auto-offer-button'
                  className='edit-auto-offer-button'
                  iconName={ICON_NAME.PENCIL}
                  onClick={() => onEditClick(data.original)}
                  type={BUTTON_TYPE.BUTTON}
                />
              </>
            ),
            filterable: false,
            Header: '', // To save space. Alternative : I18n.t('general.actions'),
            sortable: false,
            width: 40, // ... 100
          },
        ]
      : []

  const columns = [
    ...idColumn,
    ...companyColumn,
    ...fractionColumn,
    ...avvColumn,
    ...orderTypeColumn,
    ...numberOfContainersColumn,
    ...containerColumn,
    ...communitiesColumn,
    ...zipcodesColumn,
    ...createdByColumn,
    ...createdAtColumn,
    ...buisnessSegmentColumn,
    ...validFromColumn,
    ...validUntilColumn,
    ...statusColumn,
    ...editColumn,
  ]

  if (apiError.getMyAutoOffers || apiError.getContainerList) return null

  return (
    <div className='my-auto-offers-page__list uk-margin-large-top'>
      <PaginatedTable
        serverSidePagination
        page={pagination.current}
        pages={pagination.count}
        loading={isLoading.getMyAutoOffers}
        handleShowPreviousPage={() => {
          dispatch(getMyAutoOffers(pagination.previous, currentFilters))
        }}
        handleShowNextPage={() => {
          dispatch(getMyAutoOffers(pagination.next, currentFilters))
        }}
        table={{
          columns,
          data: autoOfferList,
          currentSorted,
          onFetchData: ({ page, sorted }) => {
            if (
              sorted &&
              sorted.length > 0 &&
              JSON.stringify(currentSorted) !== JSON.stringify(sorted)
            ) {
              setCurrentSorted(sorted)
              const newFilters = {
                ...currentFilters,
                order_by: JSON.stringify(sorted),
              }
              setCurrentFilters({
                ...currentFilters,
                order_by: JSON.stringify(sorted),
              })
              if (!isLoading.getMyAutoOffers) {
                dispatch(getMyAutoOffers(page, newFilters))
              }
            }
          },
        }}
      />
    </div>
  )
}
