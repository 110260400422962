import { MPDashboardTranslations } from './MaklerPremium/translation'

export const DashboardPageTranslations = {
  dashboard: {
    headline: 'Willkommen bei empto!',
    // used by wasteDisposer, wasteProducer and maklerPremium
    action: {
      buttonText: 'Aktionen öffnen',
      caption: {
        singular: 'Aktion erforderlich\nZuletzt aktualisiert: %{timestamp}',
        plural: 'Aktionen erforderlich\nZuletzt aktualisiert: %{timestamp}',
      },
    },
    MP: MPDashboardTranslations,
    empto: {
      rating: {
        buttonText: 'Unternehmensansicht öffnen',
        caption: 'positive Bewertungen',
        noRating: 'Es liegen noch keine Bewertungen vor.',
      },
      disposer: {
        inquiry: {
          buttonText: 'Anfrage ansehen',
          caption: {
            singular: 'Neue Anfrage seit dem letzten Besuch',
            plural: 'Neue Anfragen seit dem letzten Besuch',
          },
        },
      },
      producer: {
        offer: {
          buttonText: 'Angebote ansehen',
          caption: {
            singular: 'Neues Angebot seit dem letzten Besuch',
            plural: 'Neue Angebote seit dem letzten Besuch',
          },
        },
      },
    },
  },
}
