import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { ReadOnlyField } from 'components/common/ReadOnly'
import { PRICE_MODELS } from 'components/offer/constants'
import { getName } from 'helper/translations'

interface PriceListProps {
  indexList: OfferIndex[]
  pricesObject: AbstractPriceFields | Offer | InvoiceCheckPosition
  isMaklerpremium: boolean
  className?: string
}

export const PriceList: FC<PriceListProps> = ({
  indexList,
  pricesObject,
  isMaklerpremium,
  className = '',
}) => {
  const indexMonthsLabels = {
    0: I18n.t('compensationPriceFieldsTranslations.indexMonth.options.0'), // Vormonat
    1: I18n.t('compensationPriceFieldsTranslations.indexMonth.options.1'), // Vor-Vormonat
    2: I18n.t('compensationPriceFieldsTranslations.indexMonth.options.2'), // Leistungsmonat
  }

  const localizeNumber = value =>
    Number(value).toLocaleString('de-DE', { minimumFractionDigits: 2 })

  let showGrossRetailPriceContainer = false
  if ('gross_retail_price_container' in pricesObject) {
    showGrossRetailPriceContainer =
      pricesObject.price_model === PRICE_MODELS.DISPOSAL_FLATRATE &&
      isMaklerpremium &&
      !!pricesObject.gross_retail_price_container
  }

  const showRentPriceContainerMonth =
    [
      PRICE_MODELS.DISPOSAL_CONTAINER,
      PRICE_MODELS.DISPOSAL_SINGLE,
      PRICE_MODELS.COMPENSATION_CONTAINER,
      PRICE_MODELS.COMPENSATION_SINGLE,
    ].includes(pricesObject.price_model + '') &&
    pricesObject.rent_price_container_month !== null &&
    pricesObject.rent_price_container_month !== undefined

  const showTransportPricePiece =
    [PRICE_MODELS.DISPOSAL_SINGLE, PRICE_MODELS.COMPENSATION_SINGLE].includes(
      pricesObject.price_model + '',
    ) &&
    pricesObject.transport_price_piece !== null &&
    pricesObject.transport_price_piece !== undefined

  const showDisposalCostTon =
    pricesObject.price_model === PRICE_MODELS.DISPOSAL_SINGLE &&
    pricesObject.disposal_cost_ton !== null &&
    pricesObject.disposal_cost_ton !== undefined

  const showDisposalCostContainer =
    pricesObject.price_model === PRICE_MODELS.DISPOSAL_CONTAINER &&
    pricesObject.disposal_cost_container !== null &&
    pricesObject.disposal_cost_container !== undefined

  const showCostContainer =
    pricesObject.price_model === PRICE_MODELS.COMPENSATION_CONTAINER &&
    pricesObject.cost_container !== null &&
    pricesObject.cost_container !== undefined

  const showCompensationContainer =
    pricesObject.price_model === PRICE_MODELS.COMPENSATION_CONTAINER &&
    pricesObject.compensation_container !== null &&
    pricesObject.compensation_container !== undefined

  const showHandleCostTon =
    pricesObject.price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
    pricesObject.handle_cost_ton !== null &&
    pricesObject.handle_cost_ton !== undefined

  const showCompensationTon =
    pricesObject.price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
    !pricesObject.index &&
    pricesObject.compensation_ton !== null &&
    pricesObject.compensation_ton !== undefined

  const showIndexName =
    pricesObject.price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
    !!pricesObject.index

  const showIndexMonth =
    pricesObject.price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
    !!pricesObject.index &&
    pricesObject.index_month !== null &&
    pricesObject.index_month !== undefined

  const showSurcharge =
    pricesObject.price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
    !!pricesObject.index &&
    pricesObject.surcharge !== null &&
    pricesObject.surcharge !== undefined

  const showReduction =
    pricesObject.price_model === PRICE_MODELS.COMPENSATION_SINGLE &&
    !!pricesObject.index &&
    pricesObject.reduction !== null &&
    pricesObject.reduction !== undefined

  return (
    <>
      {showGrossRetailPriceContainer && (
        <div className={className}>
          <ReadOnlyField
            label={I18n.t(
              'addAgreementPageTranslations.steps.4.fields.packagePrice.label',
            )}
            value={
              'gross_retail_price_container' in pricesObject
                ? localizeNumber(pricesObject.gross_retail_price_container)
                : undefined
            }
          />
        </div>
      )}

      {showRentPriceContainerMonth && (
        <div className={className}>
          <ReadOnlyField
            label={I18n.t(
              'createOfferTranslations.label.rentPriceContainerMonth',
            )}
            value={localizeNumber(pricesObject.rent_price_container_month)}
          />
        </div>
      )}

      {showDisposalCostContainer && (
        <div className={className}>
          <ReadOnlyField
            label={I18n.t(
              'createOfferTranslations.label.disposalCostContainer',
            )}
            value={localizeNumber(pricesObject.disposal_cost_container)}
          />
        </div>
      )}

      {showTransportPricePiece && (
        <div className={className}>
          <ReadOnlyField
            label={I18n.t('createOfferTranslations.label.transportPricePiece')}
            value={localizeNumber(pricesObject.transport_price_piece)}
          />
        </div>
      )}

      {showDisposalCostTon && (
        <div className={className}>
          <ReadOnlyField
            label={I18n.t('createOfferTranslations.label.disposalCostTon')}
            value={localizeNumber(pricesObject.disposal_cost_ton)}
          />
        </div>
      )}

      {showCostContainer && (
        <div className={className}>
          <ReadOnlyField
            label={I18n.t('disposalPriceFieldsTranslations.costContainer')}
            value={localizeNumber(pricesObject.cost_container)}
          />
        </div>
      )}

      {showCompensationContainer && (
        <div className={className}>
          <ReadOnlyField
            label={I18n.t(
              'compensationPriceFieldsTranslations.compensationContainer',
            )}
            value={localizeNumber(pricesObject.compensation_container)}
          />
        </div>
      )}

      {showCompensationTon && (
        <div className={className}>
          <ReadOnlyField
            label={I18n.t(
              'compensationPriceFieldsTranslations.compensationTon',
            )}
            value={localizeNumber(pricesObject.compensation_ton)}
          />
        </div>
      )}

      {showIndexName && (
        <div className={className}>
          <ReadOnlyField
            label={I18n.t('offerForm.priceModel.index.label')}
            value={
              pricesObject.index
                ? getName(pricesObject.index, indexList)
                : I18n.t('offerForm.noIndex')
            }
          />
        </div>
      )}

      {showIndexMonth && (
        <div className={className}>
          <ReadOnlyField
            label={I18n.t(
              'compensationPriceFieldsTranslations.indexMonth.label',
            )}
            value={indexMonthsLabels[pricesObject.index_month as number]}
          />
        </div>
      )}

      {showSurcharge && (
        <div className={className}>
          <ReadOnlyField
            label={I18n.t(
              'compensationPriceFieldsTranslations.surcharge.label',
            )}
            value={localizeNumber(pricesObject.surcharge)}
          />
        </div>
      )}

      {showReduction && (
        <div className={className}>
          <ReadOnlyField
            label={I18n.t(
              'compensationPriceFieldsTranslations.reduction.label',
            )}
            value={localizeNumber(pricesObject.reduction)}
          />
        </div>
      )}

      {showHandleCostTon && (
        <div className={className}>
          <ReadOnlyField
            label={I18n.t('offerForm.priceModel.handleCostTon')}
            value={localizeNumber(pricesObject.handle_cost_ton)}
          />
        </div>
      )}
    </>
  )
}
