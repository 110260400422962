import React, { FC } from 'react'

import { germanDecimalToInternationalFormat } from 'helper/general'

import { Input, InputProps, KEY_ENTER } from '../Input'

interface InputDecimalProps extends InputProps {
  maxValue?: number
  minValue?: number
  onSubmit?: React.FormEventHandler<HTMLInputElement>
}

/**
 * @class
 * Input of type number.
 */
export const InputDecimal: FC<InputDecimalProps> = ({
  maxValue = 99.99,
  minValue = 0,
  onChange,
  onSubmit,
  ...rest
}) => {
  const allowOnlyNumbersAndDecimals = event => {
    // do not allow dot. Using dot will make the value of formik invalid. This might be because of localization.
    if (/^[^\d,]$/.test(event.key)) event.preventDefault()
  }

  const doNotAllowStartingWithComma = event => {
    if (event.target.value === '' && [','].includes(event.key))
      event.preventDefault()
  }

  const doNotAllowMoreThanOneComma = event => {
    if (String(event.target.value).includes(',') && event.key === ',')
      event.preventDefault()
  }

  const allowSubmissionByEnter = event => {
    if (onSubmit && event && event.key === KEY_ENTER) onSubmit(event)
  }

  const keyPressEvents = event => {
    allowOnlyNumbersAndDecimals(event)
    doNotAllowStartingWithComma(event)
    doNotAllowMoreThanOneComma(event)
    allowSubmissionByEnter(event)
  }

  const onValueChange = event => {
    const maxInteger = Number(String(maxValue).split('.')[0])
    const maxDecimal = Number.isNaN(Number(String(maxValue).split('.')[1]))
      ? '00'
      : Number(String(maxValue).split('.')[1])
    const splittedCurrentValue = event.target.value.includes(',')
      ? event.target.value.split(',')
      : [event.target.value, 0]

    const reachedMaxNumberOfIntegers =
      splittedCurrentValue.length > 0 &&
      String(splittedCurrentValue[0]).length > String(maxInteger).length
    const reachedMaxNumberOfDecimals =
      splittedCurrentValue.length > 1 &&
      String(splittedCurrentValue[1]).length > String(maxDecimal).length
    const numberIsStillValid =
      germanDecimalToInternationalFormat(event.target.value) <= maxValue

    if (
      numberIsStillValid &&
      !reachedMaxNumberOfIntegers &&
      !reachedMaxNumberOfDecimals
    )
      onChange(event)
  }

  return (
    <Input
      min={minValue}
      onChange={onValueChange}
      onKeyPress={keyPressEvents}
      {...rest}
    />
  )
}
