import { changePasswordFormTranslation } from './components/ChangePasswordForm/translation'
import { userProfileFormTranslation } from './components/UserProfileForm/translation'

const userProfilePageTranslations = {
  imgAlternateText: 'profilbild',
  changePasswordForm: changePasswordFormTranslation,
  userProfileForm: userProfileFormTranslation,
  title: 'Benutzerprofil',
}

export default userProfilePageTranslations
