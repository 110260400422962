import React from 'react'

import ActionTypes from '../actiontypes/dsz'

/** @function
 * @name getDszInvoices
 * @description Retrieves DSZ invoices.
 * @return {Object}
 */
export const getDszInvoices = (
  page?: number,
  filters?: { [key: string]: string | number },
  sortedBy?: { [key: string]: string | number },
) => ({
  type: ActionTypes.GET_DSZ_INVOICES_REQUEST,
  payload: {
    page,
    filters,
    sortedBy,
  },
})

/**
 * Update DSZ invoices
 * @param values = {paid_at, comment, status}
 * @returns {{payload: *, type: null}}
 */
export const updateDszDocument = values => ({
  type: ActionTypes.UPDATE_DSZ_INVOICE_REQUEST,
  payload: values,
})

/**
 * @function
 * @name createCustomInvoice
 * @param data
 * @return {payload: *}
 */
export const createCustomInvoice = data => ({
  type: ActionTypes.CREATE_CUSTOM_DSZ_INVOICE_REQUEST,
  payload: { data },
})

/**
 * @function
 * @name triggerMynatureExports
 * @description Export DSZ companies and quantities of dsz customers belonging to the logged in Makler Premium
 * @return {Object}
 */
export const triggerMynatureExports = (filters = null) => ({
  type: ActionTypes.TRIGGER_MY_NATURE_EXPORTS_REQUEST,
  payload: filters,
})

/**
 * @function
 * @name getContracts
 * @description Loads a list of contracts.
 * @return {Object}
 */
export const getContracts = (
  page?: number,
  filters?: { [key: string]: React.ReactText },
  pageSize?: number,
) => ({
  type: ActionTypes.GET_CONTRACTS_REQUEST,
  payload: {
    page,
    filters,
    pageSize,
  },
})

/**
 * @function
 * @name getDszFractions
 * @description Loads a list of fractions.
 * @return {Object}
 */
export const getDszFractions = (search?: string) => ({
  type: ActionTypes.GET_DSZ_FRACTIONS_REQUEST,
  payload: {
    search,
  },
})

/**
 * @function
 * @name getDszFractionPrices
 * @description Loads a list of fraction prices.
 * @return {Object}
 */
export const getDszFractionPrices = (
  page?: number,
  filters?: { [key: string]: React.ReactText },
) => ({
  type: ActionTypes.GET_DSZ_FRACTION_PRICES_REQUEST,
  payload: {
    page,
    filters,
  },
})

/**
 * @function
 * @name createDszFractionPrice
 * @param data
 * @return {payload: *}
 */
export const createDszFractionPrice = data => ({
  type: ActionTypes.CREATE_DSZ_FRACTION_PRICE_REQUEST,
  payload: { data },
})

export const updateDszFractionPrice = data => ({
  type: ActionTypes.UPDATE_DSZ_FRACTION_PRICE_REQUEST,
  payload: { data },
})
