import { Form, useFormikContext } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { checkRequiredPermissions } from 'helper/permissions'
import { UserPermission } from 'constants/user'
import { resetApiFetchErrors } from 'actions/app'
import { getCurrentUserSelector } from 'selectors/user'

import {
  CERTIFICATE_BUSINESS_SEGMENTS,
  CERTIFICATE_TYPE,
} from '../../certificate/constants'
import IndicationMessage from '../../common/IndicationMessage'
import Paragraph from '../../common/Paragraph'
import {
  AEZ_CHOICES,
  ENTSORGER_CHOICES,
  ORDER_CLAIM_TYPE,
  ORDER_CLAIM_TYPES,
} from '../../order/OrderDetailsPage/constants'
import { Button, BUTTON_BACKGROUND_COLOR, BUTTON_TYPE } from '../Button'
import { DropdownChoice, DropDownInput } from '../DropDownInput'
import { EmptoPhoneUserSelector } from '../EmptoPhoneUserSelector'
import { ApiValidationMessages } from '../Form/components/ApiValidationMessages'
import { ProgressButton } from '../ProgressButton'
import { RequiredPermissions } from '../RequiredPermissions'
import { Textarea } from '../Textarea'
import { UniversalFileUpload } from '../UniversalFileUpload'

import { UploadDocumentFormValues } from './index'

interface UploadDocumentFormFieldsComponentProps {
  acceptFileTypes: string
  aditionalApiFetchError?: string
  apiError: ApiError
  doNotShowNumberOfUploadedFiles: boolean
  document: Order | Certificate | Record<string, unknown>
  documentType?: number
  isLoading: boolean
  maxFiles: number
  onCloseForm: () => void
  preSelectedCompanyId?: number | string
  showApiValidationMessage?: boolean
  showJustFileName: boolean
  showOnlyActiveCompanies?: boolean
  showListForWasteCompanies: boolean
  showOrderClaimOptions: boolean
  showUploadWarningMessage: boolean
  subLabelNewLine: boolean
  uploadLabel: string
}

export const UploadDocumentFormFields: FC<
  UploadDocumentFormFieldsComponentProps
> = ({
  acceptFileTypes,
  aditionalApiFetchError = '',
  apiError,
  document,
  documentType,
  isLoading,
  maxFiles,
  onCloseForm,
  preSelectedCompanyId = '',
  showApiValidationMessage,
  showJustFileName,
  showOnlyActiveCompanies,
  showOrderClaimOptions,
  showUploadWarningMessage,
  uploadLabel,
}) => {
  const dispatch = useDispatch()

  const [isUploadButtonDisabled, setIsUploadButtonDisabled] =
    useState<boolean>(false)

  const user = useSelector(getCurrentUserSelector)

  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldValue,
    submitCount,
    touched,
    values,
  } = useFormikContext<UploadDocumentFormValues>()

  // Altough there is no effect to cleanup, we can use the "useEffect" capabilities to reset the ApiErrors on
  // general cleanup. This will behave like previously using "componentWillUnmount". A sole function is returned by
  // the effect with the desired action
  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors('UPLOAD_FILES'))
      if (aditionalApiFetchError) {
        dispatch(resetApiFetchErrors(aditionalApiFetchError))
      }
    },
    [aditionalApiFetchError, dispatch],
  )

  useEffect(() => {
    if (isLoading) {
      setIsUploadButtonDisabled(false)
    }
  }, [isLoading])

  useEffect(() => {
    if (!isSubmitting && isValid) {
      setIsUploadButtonDisabled(false)
    }
  }, [isSubmitting, isValid])

  const getClaimChoicesBasedOnUser = () => {
    let userChoices = Array(0)
    if (showOrderClaimOptions) {
      if (
        (document as Order).offer_object &&
        user.company === (document as Order).offer_object.company_object.id
      ) {
        userChoices = ENTSORGER_CHOICES
      }
      if (
        (document as Order).offer_object &&
        user.company ===
          (document as Order).offer_object.customer_company_object.id
      ) {
        userChoices = AEZ_CHOICES
      }
      if (
        checkRequiredPermissions(user.permission_codenames, [
          UserPermission.REPORT_MAKLER_PREMIUM_CLAIMS,
        ])
      ) {
        if (
          values.company ===
          `${(document as Order).offer_object?.customer_company}`
        ) {
          userChoices = [
            ORDER_CLAIM_TYPE.TYPE_NOT_CARRIED,
            ORDER_CLAIM_TYPE.TYPE_CONTAINER_WRONG,
            ORDER_CLAIM_TYPE.TYPE_CREDIT_ALREADY_PAID,
            ORDER_CLAIM_TYPE.TYPE_CREDIT_WRONG,
            ORDER_CLAIM_TYPE.TYPE_OTHERS,
          ]
        } else {
          userChoices = [
            ORDER_CLAIM_TYPE.TYPE_DEADHEAD,
            ORDER_CLAIM_TYPE.TYPE_RELABELING,
            ORDER_CLAIM_TYPE.TYPE_OTHERS,
          ]
        }
      }

      if (userChoices.length > 0) {
        userChoices = ORDER_CLAIM_TYPES.filter(type =>
          userChoices.includes(type.id),
        )
          // to sort with positionOnSelection from lower to bigger
          .sort((a, b) => a.positionOnSelection - b.positionOnSelection)
      } else {
        userChoices = ORDER_CLAIM_TYPES
      }
    }
    return userChoices
  }

  const getParticipatingCompanies = () => {
    const companies: DropdownChoice[] = []
    if (showOrderClaimOptions) {
      if ((document as Order).offer_object) {
        companies.push({
          optionValue: (document as Order).offer_object.company_object.id,
          optionLabel: `Entsorger (${
            (document as Order).offer_object.company_object.name
          })`,
        })
      }
      if ((document as Order).offer_object) {
        companies.push({
          optionValue: (document as Order).offer_object.customer_company_object
            .id,
          optionLabel: `Kunde (${
            (document as Order).offer_object.customer_company_object.name
          })`,
        })
      }
    }

    return companies
  }

  return (
    <>
      <Form
        className='upload-document-form__form-fields'
        data-testid='upload-document-form'
        noValidate
      >
        {showApiValidationMessage && <ApiValidationMessages error={apiError} />}
        <EmptoPhoneUserSelector
          requiredPermissions={[UserPermission.CREATE_PHONE_INQUIRIES]}
          showListForWasteCompanies
          showOnlyActiveCompanies={showOnlyActiveCompanies}
          preSelectedCompanyId={preSelectedCompanyId}
        />

        {/* CHOOSE ORDER CLAIM TYPE */}
        {showOrderClaimOptions && (
          <>
            <RequiredPermissions
              requiredPermissions={[
                UserPermission.REPORT_MAKLER_PREMIUM_CLAIMS,
              ]}
            >
              <DropDownInput
                error={
                  submitCount > 0 && touched.company
                    ? (errors.company as string)
                    : ''
                }
                isRequired={!values.company}
                dataTestId='claim-type-input'
                choices={getParticipatingCompanies()}
                label={I18n.t(
                  'uploadReviewDocumentTranslations.formFields.maklerPremiumCompany.label',
                )}
                placeholder={I18n.t(
                  'uploadReviewDocumentTranslations.formFields.maklerPremiumCompany.placeholder',
                )}
                name='company'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.company}
              />
            </RequiredPermissions>
            <DropDownInput
              error={
                submitCount > 0 && touched.claim_type
                  ? (errors.claim_type as string)
                  : ''
              }
              isRequired={!values.claim_type}
              dataTestId='claim-type-input'
              choices={getClaimChoicesBasedOnUser().map(item => ({
                optionValue: item.id,
                optionLabel: I18n.t(item.name),
              }))}
              label={I18n.t(
                'uploadReviewDocumentTranslations.formFields.claimType.label',
              )}
              placeholder={I18n.t(
                'uploadReviewDocumentTranslations.formFields.claimType.placeholder',
              )}
              name='claim_type'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.claim_type}
            />
          </>
        )}

        {documentType === CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT && (
          <DropDownInput
            choices={CERTIFICATE_BUSINESS_SEGMENTS.map(item => ({
              optionValue: item.id,
              optionLabel: `${I18n.t(item.name)}`,
            }))}
            label={I18n.t(
              'uploadReviewDocumentTranslations.formFields.businessSegment.label',
            )}
            name='business_segment'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t(
              'uploadReviewDocumentTranslations.formFields.businessSegment.placeholder',
            )}
            value={values.business_segment}
            error={
              submitCount > 0 && touched.business_segment
                ? errors.business_segment
                : ''
            }
          />
        )}
        {/* UPLOAD FILE */}
        <div className='upload-document-form__form-fields-upload-document'>
          <UniversalFileUpload
            name='attachments'
            error={
              submitCount > 0 && errors.attachments
                ? (errors.attachments as string)
                : ''
            }
            label={uploadLabel}
            subLabel={
              maxFiles > 1
                ? I18n.t(
                    'uploadReviewDocumentTranslations.formFields.attachments.subLabel',
                    { maxFiles },
                  )
                : I18n.t(
                    'uploadReviewDocumentTranslations.formFields.attachments.subLabelSingular',
                  )
            }
            allowedFileTypes={acceptFileTypes}
            initialValues={values.attachments}
            onChange={files => setFieldValue('attachments', files)}
            maxFiles={maxFiles}
            showPreviewAsTextItem={showJustFileName}
          />
        </div>

        {showUploadWarningMessage && (
          <IndicationMessage
            className='warning'
            iconName='document'
            statusClass='warning'
            statusIconName='warning'
          >
            <div className='upload-document-form__custom-api-validation-message-title'>
              {I18n.t(
                'uploadReviewDocumentTranslations.uploadWarningMessage.title',
              )}
            </div>
            <div className='upload-document-form__custom-api-validation-message-description'>
              <Paragraph>
                <Translate
                  value='uploadReviewDocumentTranslations.uploadWarningMessage.message'
                  lineNumbers={
                    apiError && apiError.response && apiError.response.data
                  }
                  dangerousHTML
                />
              </Paragraph>
            </div>
          </IndicationMessage>
        )}

        {/* TEXT WITH REASON FOR CLAIM */}
        {showOrderClaimOptions && (
          <Textarea
            dataTestId='order-claim-description'
            maxLength={500}
            name='claim_description'
            onChange={handleChange}
            label={I18n.t(
              'uploadReviewDocumentTranslations.formFields.claimDescription.label',
            )}
            showRemainingCharacters
            value={values.claim_description}
            isRequired={
              Number(values.claim_type) === ORDER_CLAIM_TYPE.TYPE_OTHERS
            }
            error={
              submitCount > 0 &&
              touched.claim_description &&
              Number(values.claim_type) === ORDER_CLAIM_TYPE.TYPE_OTHERS
                ? (errors.claim_description as string)
                : ''
            }
          />
        )}
      </Form>
      {/* BUTTONS */}
      <div className='uk-modal-footer uk-text-right upload-document-form__buttons'>
        <span className='uk-margin-right'>
          <Button
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            onClick={onCloseForm}
          >
            {I18n.t('general.button.cancel')}
          </Button>
        </span>
        <ProgressButton
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          isDisabled={
            (submitCount > 0 && !isValid) ||
            (isSubmitting && isLoading) ||
            isUploadButtonDisabled
          }
          isLoading={isSubmitting && isLoading}
          onClick={() => {
            setIsUploadButtonDisabled(true)
            handleSubmit()
          }}
          type={BUTTON_TYPE.SUBMIT}
          dataTestId='order-claim-submit-button'
        >
          {I18n.t('general.button.save')}
        </ProgressButton>
      </div>
    </>
  )
}
