import uniqueId from 'lodash.uniqueid'
import React, { FC, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { createExecutionProof } from 'actions/executionproof'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import { Modal } from 'components/common/Modal/index'
import ModalHeader from 'components/common/ModalHeader/index'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { UploadDocumentForm } from 'components/common/UploadDocumentForm'
import { EXECUTION_PROOF_STATUS } from 'components/executionproof/constants'
import { UserPermission } from 'constants/user'
import { createErrorSelector } from 'selectors/error'
import { getExecutionProofSelector } from 'selectors/executionproof'
import { createLoadingSelector } from 'selectors/loading'

interface UploadExecutionProofComponentProps {
  order: Order
}

export const UploadExecutionProof: FC<UploadExecutionProofComponentProps> = ({
  order,
}) => {
  const dispatch = useDispatch()
  const executionProof = useSelector(getExecutionProofSelector)
  const isLoading = useSelector(
    createLoadingSelector(['CREATE_EXECUTION_PROOF']),
  )
  const error = useSelector(createErrorSelector(['CREATE_EXECUTION_PROOF']))

  const [openModal, setOpenModal] = useState<boolean>(false)
  const idAddExecutionProofModalHeadline = uniqueId()

  return (
    <RequiredPermissions
      requiredPermissions={[
        UserPermission.ADD_EXECUTIONPROOF,
        UserPermission.ADD_MAKLER_PREMIUM_EXECUTION_PROOF,
      ]}
    >
      <div className='order-status-task--on-order-details'>
        <h4>
          <Translate value='orderStatusTasks.uploadExecutionProof.headline' />
        </h4>

        {!(executionProof && executionProof.id) && (
          <p>
            <Translate value='executionProofStatus.none' />
          </p>
        )}

        {/* If there was never an execution proof uploaded or the current one isn't pending, upload a new one. */}
        {(!(
          executionProof &&
          executionProof.id &&
          executionProof.order === order.id
        ) ||
          executionProof.status !== EXECUTION_PROOF_STATUS.STATUS_PENDING) && (
          <Button
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            onClick={() => setOpenModal(true)}
            type={BUTTON_TYPE.BUTTON}
          >
            <Translate value='executionProofStatus.addButton' />
          </Button>
        )}

        <Modal
          ariaDescribedBy={idAddExecutionProofModalHeadline}
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
        >
          <ModalHeader
            onClose={() => setOpenModal(false)}
            title={I18n.t(
              'orderStatusTasks.uploadExecutionProof.uploadModalTitle',
            )}
            titleId={idAddExecutionProofModalHeadline}
          />
          <UploadDocumentForm
            aditionalApiFetchError='CREATE_EXECUTION_PROOF'
            apiError={error}
            document={order}
            isLoading={isLoading}
            maxFiles={5}
            onCloseForm={() => setOpenModal(false)}
            onDocumentSubmit={values =>
              dispatch(createExecutionProof({ ...values, order: order.id }))
            }
            permissions={[
              UserPermission.ADD_EXECUTIONPROOF,
              UserPermission.ADD_MAKLER_PREMIUM_EXECUTION_PROOF,
            ]}
            showApiValidationMessage={!!error}
            subLabelNewLine
            uploadLabel={I18n.t(
              'uploadReviewDocumentTranslations.formFields.attachments.label.executionProof',
            )}
          />
        </Modal>
      </div>
    </RequiredPermissions>
  )
}
