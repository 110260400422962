import {
  Circle,
  GoogleMap,
  Marker,
  MarkerClusterer,
} from '@react-google-maps/api'
import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Translate } from 'react-i18nify'

import withErrorBoundary from 'helper/withErrorBoundary'
import { ZENTEK_COORDINATES } from 'constants/app'
import { CompanyScheme } from 'schemes/company'

import Icon from '../../common/Fontello'
import Paragraph from '../../common/Paragraph'
import { GoogleMapsMarkerType } from '../../inquiry/OpenInquiriesPage/constants'

const customStyleMap = [
  {
    featureType: 'all',
    elementType: 'geometry',
    stylers: [
      {
        lightness: '30',
      },
      {
        gamma: '1.14',
      },
      {
        saturation: '10',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#ffffff',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry.stroke',
    stylers: [
      {
        color: '#c7c7c7',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'all',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'transit.station',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
]

const MapsComponent = props => {
  const [stateCompanyMarker, setCompanyMarker] = useState([])
  const [stateTilesLoaded, setTilesLoaded] = useState()
  const mapRef = useRef()
  const circleRef = useRef()

  const handleSetCompanyMarkerPosition = (companyMarker = []) => {
    // since the company marker is customized, we need to anchor its center to where the actual coordinate is.
    // Otherwise the icon will be placed some pixels above the actual point. This is visible if we have the radius
    // filter activated.

    setCompanyMarker(
      // to set the correct position of the icon
      companyMarker.map(marker => ({
        ...marker,
        icon: {
          url: marker.icon,
          // size of the icon
          scaledSize: new window.google.maps.Size(60, 60),
          // offset the icon by half of its size on each side, to center of the icon at the marker origin
          anchor: new window.google.maps.Point(30, 30),
        },
      })),
    )
  }

  /**
   * @description handles the fit bounds of google maps.
   */
  const handleFitBounds = useCallback(() => {
    if (typeof mapRef.current === 'undefined') {
      return
    }

    let fitInquiriesToBounds = false
    let fitCompanyToBounds = false
    let fitDeliveryToBounds = false

    // if we just have one marker, we don't want to fit to its bounds (zoom is too big).
    // Just center the map in this icon coordinates
    if (props.markers.length === 1) {
      mapRef.current.panTo({
        lat: parseFloat(props.markers[0].latitude),
        lng: parseFloat(props.markers[0].longitude),
      })
    }

    if (props.markers.length > 1) {
      const inquiryMarkers = props.markers.filter(
        marker => marker.type === GoogleMapsMarkerType.INQUIRY,
      )
      const companyMarker = props.markers.find(
        marker => marker.type === GoogleMapsMarkerType.COMPANY,
      )
      const deliveryMarkers = props.markers.filter(
        marker =>
          marker.type === GoogleMapsMarkerType.COMPANY &&
          props.company &&
          props.company.main_address_object &&
          marker.id !== props.company.main_address_object.id,
      )
      const bounds = new window.google.maps.LatLngBounds()

      /* If a marker with an invalid address is on our mark cluster list, it will have invalid latitude or Longitude
      (NaN or null). If that occurs, the fit bounds will not work correctly. Here we are ignoring such markers */
      if (inquiryMarkers.length > 0) {
        inquiryMarkers.forEach(marker => {
          const lat = marker.latitude ? parseFloat(marker.latitude) : null
          const lng = marker.longitude ? parseFloat(marker.longitude) : null
          if (lat && lng) {
            bounds.extend({ lat, lng })
            fitInquiriesToBounds = true
          }
        })
      }

      /* If a marker with an invalid company address exists, it will have invalid latitude or Longitude
      (NaN or null). If that occurs, the fit bounds will not work correctly. Here we are ignoring such marker */
      if (companyMarker) {
        const lat = companyMarker.latitude
          ? parseFloat(companyMarker.latitude)
          : null
        const lng = companyMarker.longitude
          ? parseFloat(companyMarker.longitude)
          : null
        if (lat && lng) {
          bounds.extend({ lat, lng })
          fitCompanyToBounds = true
        }
      }

      /* If a marker with an invalid company address exists, it will have invalid latitude or Longitude
      (NaN or null). If that occurs, the fit bounds will not work correctly. Here we are ignoring such marker */
      if (deliveryMarkers.length > 0) {
        deliveryMarkers.forEach(marker => {
          const lat = marker.latitude ? parseFloat(marker.latitude) : null
          const lng = marker.longitude ? parseFloat(marker.longitude) : null
          if (lat && lng) {
            bounds.extend({ lat, lng })
            fitDeliveryToBounds = true
          }
        })
      }

      // fit bounds, but only if there are valid inquiry or company markers to fit to bounds
      if (fitInquiriesToBounds || fitCompanyToBounds || fitDeliveryToBounds)
        mapRef.current.fitBounds(bounds)
    }
  }, [props.company, props.markers])

  useEffect(() => {
    const companyMarker = props.markers.filter(
      marker =>
        !!marker.latitude &&
        !!marker.longitude &&
        marker.type === GoogleMapsMarkerType.COMPANY,
    )
    handleSetCompanyMarkerPosition(companyMarker)
  }, [props.markers])

  useEffect(() => {
    if (!props.triggerFitBounds && (!circleRef.current || props.radius === 0)) {
      handleFitBounds()
    }
  }, [handleFitBounds, props.markers, props.radius, props.triggerFitBounds])

  /**
   * @description handles the radius change event.
   */
  const handleChangeRadius = newCircle => {
    const mapsCircle = circleRef.current || newCircle

    if (mapsCircle && props.radius > 0) {
      const bounds = mapsCircle.getBounds()
      mapRef.current.fitBounds(bounds)
      mapRef.current.setCenter(mapsCircle.getCenter())
    }
  }

  /**
   * @description handles the click on a cluster.
   * @param cluster
   */
  const handleMarkerClusterClick = cluster => {
    const markerList = cluster.getMarkers().map(marker => marker.id)
    props.onMarkerClusterClick(markerList)
  }

  const hasCompanyMarker = stateCompanyMarker.find(
    marker =>
      marker.type === GoogleMapsMarkerType.COMPANY &&
      props.company &&
      props.company.main_address_object &&
      marker.id === props.company.main_address_object.id,
  )
  const inquiryMarkers = props.markers.filter(
    marker => marker.type === GoogleMapsMarkerType.INQUIRY,
  )
  const deliveryMarkers = props.markers.filter(
    marker =>
      marker.type === GoogleMapsMarkerType.COMPANY &&
      props.company &&
      props.company.main_address_object &&
      marker.id !== props.company.main_address_object.id,
  )

  return (
    <div className='maps-container'>
      <GoogleMap
        zoom={props.mapZoom}
        center={props.center}
        mapContainerStyle={{
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
        onLoad={newMap => {
          mapRef.current = newMap
          if (props.getMapRef) props.getMapRef(newMap)

          // Initialize MapType here, because change handlers fire before load handler
          props.onChangeMapType(newMap.getMapTypeId())
        }}
        onMapTypeIdChanged={() => {
          if (mapRef.current) {
            props.onChangeMapType(mapRef.current.getMapTypeId())
          }
        }}
        onTilesLoaded={() => {
          if (!stateTilesLoaded) {
            setTilesLoaded(true)
            handleFitBounds(props.markers)
          }
        }}
        // we dont't need the following map tools. They are redundant to the purpose of this app.
        // Also makes the map look cleaner
        options={{
          fullscreenControl: false,
          streetViewControl: true,
          zoomControl: true,
          mapTypeControl: true,
          draggable: true,
          // the two following need to be the opposite of useUIControls,
          // because the clause is telling us to disable
          disableDoubleClickZoom: false,
          disableDefaultUI: false,
          // Custom styles for the map layers
          styles: customStyleMap,
        }}
      >
        {/* Company Marker must be separated from the cluster */}
        {hasCompanyMarker && (
          <Marker
            key={`${hasCompanyMarker.type}_${hasCompanyMarker.id}`}
            position={{
              lat: parseFloat(hasCompanyMarker.latitude),
              lng: parseFloat(hasCompanyMarker.longitude),
            }}
            options={{ id: hasCompanyMarker.id, type: hasCompanyMarker.type }}
            icon={hasCompanyMarker.icon || null}
            clickable={hasCompanyMarker.clickable}
          />
        )}

        {props.isMarkerShown && (
          <MarkerClusterer
            averageCenter
            enableRetinaIcons
            gridSize={60}
            onClick={handleMarkerClusterClick}
          >
            {clusterer =>
              inquiryMarkers.map(marker => (
                <Marker
                  key={`${marker.type}_${marker.id}`}
                  position={{
                    lat: parseFloat(marker.latitude),
                    lng: parseFloat(marker.longitude),
                  }}
                  options={{ id: marker.id, type: marker.type }}
                  icon={marker.icon || null}
                  clickable={marker.clickable}
                  onClick={() =>
                    marker.clickable ? props.onMarkerClick(marker) : undefined
                  }
                  clusterer={clusterer}
                />
              ))
            }
          </MarkerClusterer>
        )}

        {props.isMarkerShown &&
          deliveryMarkers &&
          deliveryMarkers.map(marker => (
            <Marker
              key={`${marker.type}_${marker.id}`}
              position={{
                lat: parseFloat(marker.latitude),
                lng: parseFloat(marker.longitude),
              }}
              options={{ id: marker.id, type: marker.type }}
              icon={marker.icon || null}
              clickable={marker.clickable}
              onClick={() =>
                marker.clickable ? props.onMarkerClick(marker) : undefined
              }
            />
          ))}

        {props.radius > 0 && (
          <Circle
            radius={props.radius}
            // circle should be centered where the company coordinates are
            center={
              hasCompanyMarker
                ? {
                    lat: parseFloat(hasCompanyMarker.latitude),
                    lng: parseFloat(hasCompanyMarker.longitude),
                  }
                : props.center
            }
            options={{
              fillColor: '#00bbdd',
              fillOpacity: 0.3,
              strokeColor: '#00bbdd',
              strokeOpacity: 1,
            }}
            onLoad={newCircle => {
              circleRef.current = newCircle
              // Initialize circle bounds here, because change handlers fire before load handler
              handleChangeRadius(newCircle)
            }}
            onRadiusChanged={handleChangeRadius}
            onUnmount={() => {
              circleRef.current = undefined
            }}
          />
        )}
      </GoogleMap>

      {props.showMissingMarkersMessage && (
        <Paragraph>
          <Icon name='warning' />
          <Translate
            value='general.missingGoogleMarker'
            className='uk-margin-small-left'
          />
        </Paragraph>
      )}
    </div>
  )
}

MapsComponent.propTypes = {
  isMarkerShown: bool,
  markers: arrayOf(
    shape({
      id: number.isRequired,
      latitude: oneOfType([string, number]), // should not be required. Can be null
      longitude: oneOfType([string, number]), // should not be required. Can be null
      type: oneOfType([string, number]),
      icon: string,
    }),
  ),
  onMarkerClick: func,
  radius: number,
  getMapRef: func,
  onMarkerClusterClick: func,
  onChangeMapType: func,
  showMissingMarkersMessage: bool,
  mapZoom: number,
  center: shape({
    lat: number,
    lng: number,
  }),
  triggerFitBounds: bool,
  company: shape(CompanyScheme),
}

MapsComponent.defaultProps = {
  isMarkerShown: true,
  markers: [],
  onMarkerClick: null,
  radius: null,
  getMapRef: null,
  onMarkerClusterClick: null,
  onChangeMapType: null,
  showMissingMarkersMessage: false,
  mapZoom: 10,
  center: {
    lat: ZENTEK_COORDINATES.lat,
    lng: ZENTEK_COORDINATES.lng,
  },
  triggerFitBounds: false,
  company: null,
}

export const Maps = withErrorBoundary(MapsComponent)
