import './style.scss'

import classNames from 'classnames'
import React, { Component } from 'react'
import Spinner from 'react-spinkit'
import { bool, func, node, number, oneOf, string } from 'prop-types'

import { Button, BUTTON_BACKGROUND_COLOR } from '../Button'
import Icon, { ICON_COLOR } from '../Fontello'

export const ICON_POSITION = {
  LEFT: 'left',
  RIGHT: 'right',
}

// export icon constants to avoid importing Icon for its props
export { ICON_NAME } from '../Fontello'

// export button constants to avoid importing Button for its props
export {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_DIMENSIONS,
  BUTTON_FONT_COLOR,
  BUTTON_SHAPE,
  BUTTON_TYPE,
} from '../Button'

export const IconButtonProps = {
  asLink: bool,
  ariaLabel: string,
  backgroundColor: oneOf(Object.values(BUTTON_BACKGROUND_COLOR)),
  children: node,
  className: string, // This prop should only be used in very rare cases!!!
  dataTestId: string,
  iconName: string,
  iconPosition: oneOf(Object.values(ICON_POSITION)),
  onClick: func,
  renderIcon: func,
  showOnlyIcon: bool,
  isLoading: bool,
  isDisabled: bool,
  dataButtonCount: number,
}

export class IconButton extends Component {
  static propTypes = IconButtonProps

  static defaultProps = {
    asLink: false,
    ariaLabel: '',
    backgroundColor: BUTTON_BACKGROUND_COLOR.DEFAULT,
    children: null,
    className: '',
    dataTestId: '',
    iconName: null,
    iconPosition: ICON_POSITION.LEFT,
    onClick: () => undefined,
    renderIcon: null,
    showOnlyIcon: false,
    isLoading: false,
    isDisabled: false,
    dataButtonCount: null,
  }

  /**
   * @description renders the Icon component.
   * @param renderIcon
   * @param backgroundColor
   * @param iconName
   * @return {*}
   */
  renderIcon = ({ backgroundColor, iconName, renderIcon }) =>
    renderIcon ? (
      renderIcon(this.props)
    ) : (
      <Icon
        name={iconName}
        color={
          [
            BUTTON_BACKGROUND_COLOR.PRIMARY,
            BUTTON_BACKGROUND_COLOR.DANGER,
          ].includes(backgroundColor)
            ? ICON_COLOR.WHITE
            : ICON_COLOR.DEFAULT
        }
        className={classNames(
          {
            'uk-margin-small-right':
              this.props.children &&
              this.props.iconPosition === ICON_POSITION.LEFT,
          },
          {
            'uk-margin-small-left':
              this.props.children &&
              this.props.iconPosition === ICON_POSITION.RIGHT,
          },
        )}
      />
    )

  render() {
    const {
      ariaLabel,
      backgroundColor,
      children,
      className,
      dataTestId,
      dataButtonCount,
      iconName,
      iconPosition,
      renderIcon,
      showOnlyIcon,
      isLoading,
      isDisabled,
      ...rest
    } = this.props

    return (
      <Button
        ariaLabel={ariaLabel}
        backgroundColor={backgroundColor}
        className={classNames(
          { 'uk-icon-button button--border-none': !children },
          { 'uk-icon': showOnlyIcon },
          className,
        )}
        dataTestId={dataTestId}
        dataButtonCount={dataButtonCount}
        isDisabled={isDisabled}
        {...rest}
      >
        {iconPosition === ICON_POSITION.LEFT &&
          this.renderIcon({
            renderIcon,
            backgroundColor,
            iconName,
          })}

        {children}

        {iconPosition === ICON_POSITION.RIGHT &&
          this.renderIcon({
            renderIcon,
            backgroundColor,
            iconName,
          })}

        {isLoading && (
          <Spinner
            className='icon-button__spinner'
            fadeIn='none' // Show immediately
            name='circle'
          />
        )}
      </Button>
    )
  }
}
