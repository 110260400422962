import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'

import { Tooltip, TOOLTIP_TRIGGER, TOOLTIP_ZINDEX } from '../Tooltip'

export enum BUTTON_BACKGROUND_COLOR {
  DEFAULT = 'default',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DANGER = 'danger',
  WHITE = 'white',
  TRANSPARENT = 'transparent',
  WARNING = 'warning',
  GRADIENT = 'gradient',
}

export enum BUTTON_DIMENSIONS {
  DEFAULT = 'default',
  AUTO = 'auto',
  FULL_SIZE = 'full-size',
  SMALL = 'small',
}

export enum BUTTON_FONT_COLOR {
  DEFAULT = 'default',
  GRAYISH_BROWN = 'grayish-brown',
  PRIMARY = 'malachite',
  WHITE = 'white',
}

export enum BUTTON_SHAPE {
  CIRCULAR = 'circular',
  CORNERED = 'cornered',
  DEFAULT = 'default',
  ROUNDED = 'rounded',
  CIRCULAR_SPECIAL = 'circular-special',
}

export enum BUTTON_TYPE {
  BUTTON = 'button',
  SUBMIT = 'submit',
}

export interface ButtonProps {
  ariaLabel?: string
  asLink?: boolean // For the rare occurrence where you need to nest buttons, turn the inner button into a link
  backgroundColor?: BUTTON_BACKGROUND_COLOR
  className?: string // This prop should only be used in very rare cases!!!
  dataTestId?: string
  dataButtonCount?: number
  dimensions?: BUTTON_DIMENSIONS
  fontColor?: BUTTON_FONT_COLOR
  fullWidth?: boolean
  isDisabled?: boolean
  noHover?: boolean
  noPadding?: boolean
  onClick: (any) => void
  shape?: BUTTON_SHAPE
  showTooltip?: boolean
  tabIndex?: number
  tooltipHtml?: React.ReactElement<any>
  tooltipText?: string
  type?: BUTTON_TYPE
}

export const Button: FC<ButtonProps> = ({
  ariaLabel,
  asLink = false,
  backgroundColor = BUTTON_BACKGROUND_COLOR.DEFAULT,
  children,
  className = '',
  dataTestId,
  dataButtonCount,
  dimensions = BUTTON_DIMENSIONS.DEFAULT,
  fontColor,
  fullWidth,
  isDisabled = false,
  noHover,
  noPadding,
  onClick,
  shape = BUTTON_SHAPE.DEFAULT,
  showTooltip = false,
  tabIndex = 0,
  tooltipHtml = undefined,
  tooltipText = '',
  type = BUTTON_TYPE.BUTTON,
}) => {
  const onlyButtonProps = asLink ? {} : { disabled: isDisabled, type }

  const buttonProps = {
    'aria-label': ariaLabel,
    className: classNames(
      'button',
      `button--background-color-${backgroundColor}`,
      `button--dimensions-${dimensions}`,
      `button--font-color-${fontColor}`,
      `button--shape-${shape}`,
      {
        'button--border-default button--disabled-style-secondary':
          backgroundColor === BUTTON_BACKGROUND_COLOR.SECONDARY ||
          backgroundColor === BUTTON_BACKGROUND_COLOR.DEFAULT ||
          backgroundColor === BUTTON_BACKGROUND_COLOR.WHITE ||
          backgroundColor === BUTTON_BACKGROUND_COLOR.TRANSPARENT,
      },
      {
        'button--border-none button--disabled-style-default':
          backgroundColor === BUTTON_BACKGROUND_COLOR.PRIMARY ||
          backgroundColor === BUTTON_BACKGROUND_COLOR.DANGER ||
          backgroundColor === BUTTON_BACKGROUND_COLOR.WARNING,
      },
      { 'button--full-width': fullWidth },
      { 'button--with-count': dataButtonCount },
      { 'button--no-hover': noHover },
      { 'button--no-padding': noPadding },
      { 'uk-button': !className!.includes('uk-icon-button') }, // uikit specific condition
      {
        'uk-button-default':
          backgroundColor === BUTTON_BACKGROUND_COLOR.DEFAULT,
      },
      {
        'uk-button-primary':
          backgroundColor === BUTTON_BACKGROUND_COLOR.PRIMARY,
      },
      {
        'uk-button-secondary':
          backgroundColor === BUTTON_BACKGROUND_COLOR.SECONDARY,
      },
      {
        'uk-button-danger': backgroundColor === BUTTON_BACKGROUND_COLOR.DANGER,
      },
      {
        'uk-button-warning':
          backgroundColor === BUTTON_BACKGROUND_COLOR.WARNING,
      },
      className,
    ),
    'data-testid': dataTestId,
    'data-button-count': dataButtonCount,
    onClick: onClick,
    tabIndex: tabIndex,
    ...onlyButtonProps,
  }

  const buttonComponent = asLink ? (
    <a {...buttonProps}>{children}</a>
  ) : (
    <button {...buttonProps}>{children}</button>
  )

  if (showTooltip) {
    return (
      <Tooltip
        duration={0}
        hideOnClick={false}
        title={tooltipText}
        html={tooltipHtml}
        trigger={TOOLTIP_TRIGGER.MOUSEENTER}
        tooltipId='create-new-offer-button-tooltip'
        zIndex={TOOLTIP_ZINDEX.BEFORE_HEADER_BEHIND_MODAL}
      >
        {buttonComponent}
      </Tooltip>
    )
  }
  return buttonComponent
}
