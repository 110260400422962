import { useFormikContext } from 'formik'
import React, { useContext } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { getSelectedCompany } from 'components/maklerpremium/CreateMaklerPremiumOfferPage/OfferFormSteps/Step1/helpers'
import { FormStepSummaryRow } from 'components/common/FormSteps'
import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { getMaklerPremiumCompaniesSelector } from 'selectors/company'

import {
  TVPContractFormContext,
  TVPContractFormValues,
} from '../TVPContractFormSteps'

export const CustomerInfoSummary = () => {
  const companies = useSelector(getMaklerPremiumCompaniesSelector)
  const { values } = useFormikContext<TVPContractFormValues>()
  const { editStep } = useContext(TVPContractFormContext)
  return (
    <div className='maklerpremium-step-summary'>
      <div className='maklerpremium-step-summary_grid-body'>
        <>
          <FormStepSummaryRow
            label={I18n.t(
              'TVPContractPageTranslations.steps.1.fields.company.label',
            )}
            value={
              getSelectedCompany(Number(values.company_id), companies)!.name
            }
          />
          <FormStepSummaryRow
            label={I18n.t(
              'TVPContractPageTranslations.steps.1.fields.zipcode.label',
            )}
            value={
              getSelectedCompany(Number(values.company_id), companies)!
                .main_address_object.zipcode
            }
          />
          <FormStepSummaryRow
            label={I18n.t(
              'TVPContractPageTranslations.steps.1.fields.location.label',
            )}
            value={
              getSelectedCompany(Number(values.company_id), companies)!
                .main_address_object.location
            }
          />
        </>
      </div>
      <div className='sidebar'>
        <ButtonLink
          className='sidebar__edit-link'
          linkColor={BUTTON_LINK_COLOR.PRIMARY}
          onClick={editStep}
        >
          {I18n.t('createInquiryTranslations.form.button.editStep')}
        </ButtonLink>
      </div>
    </div>
  )
}
