import { useFormikContext } from 'formik'
import React from 'react'
import { I18n } from 'react-i18nify'

import { DismissableInfo } from 'components/common/DismissableInfo'
import { RadioButton } from 'components/common/RadioButton'
import { Textarea } from 'components/common/Textarea'
import { EPDAddInvoiceFormValues } from 'components/maklerpremium/epd/EPDInvoiceFormSteps/EPDAddInvoiceFormSteps'

export const BillingChoice = () => {
  const { setFieldValue, values, errors, handleChange } =
    useFormikContext<EPDAddInvoiceFormValues>()
  const isFieldValid = name =>
    (values[name] ?? '') && values[name].length > 0 && !errors[name]

  return (
    <>
      {(values.assigned_order?.is_not_allowed_on_collective_invoice ||
        values.assigned_order?.is_collectively_invoiced) && (
        <DismissableInfo
          title={I18n.t('general.information')}
          text={I18n.t(
            'addInvoicePageTranslations.epd.steps.2.fields.is_not_allowed_on_collective_invoice.info',
          )}
        />
      )}

      <div className='margin-regular-top'>
        <label>
          {I18n.t(
            'addInvoicePageTranslations.epd.steps.2.fields.is_not_allowed_on_collective_invoice.label',
          )}
        </label>
        <div className='uk-flex-left margin-regular-top' data-uk-grid='uk-grid'>
          <RadioButton
            isChecked={values.is_not_allowed_on_collective_invoice === false}
            label={I18n.t(
              'addInvoicePageTranslations.epd.steps.2.fields.is_not_allowed_on_collective_invoice.options.other_service',
            )}
            name='is_not_allowed_on_collective_invoice'
            onChange={(event: React.ChangeEvent<any>) => {
              if (event.target.value === 'on') {
                setFieldValue('is_not_allowed_on_collective_invoice', false)
                setFieldValue(
                  'is_not_allowed_on_collective_invoice_comment',
                  undefined,
                )
              }
            }}
          />
          <RadioButton
            isChecked={values.is_not_allowed_on_collective_invoice === true}
            label={I18n.t(
              'addInvoicePageTranslations.epd.steps.2.fields.is_not_allowed_on_collective_invoice.options.complete_billing',
            )}
            name='is_not_allowed_on_collective_invoice'
            onChange={(event: React.ChangeEvent<any>) => {
              if (event.target.value === 'on') {
                setFieldValue('is_not_allowed_on_collective_invoice', true)
                setFieldValue(
                  'is_not_allowed_on_collective_invoice_comment',
                  '',
                )
              }
            }}
            isDisabled={
              values.assigned_order?.is_not_allowed_on_collective_invoice ||
              values.assigned_order?.is_collectively_invoiced
            }
          />
        </div>
        {values.is_not_allowed_on_collective_invoice && (
          <Textarea
            label={I18n.t(
              'addInvoicePageTranslations.epd.steps.2.fields.is_not_allowed_on_collective_invoice_comment.label',
            )}
            maxLength={200}
            rows={5}
            name='is_not_allowed_on_collective_invoice_comment'
            onChange={e => {
              e.target.value.trim()
              handleChange(e)
            }}
            showRemainingCharacters
            value={values.is_not_allowed_on_collective_invoice_comment}
            withCheckmark
            showCheckmark={
              !!(
                isFieldValid('is_not_allowed_on_collective_invoice_comment') &&
                values.is_not_allowed_on_collective_invoice_comment &&
                values.is_not_allowed_on_collective_invoice_comment.trim()
                  .length > 0
              )
            }
          />
        )}
      </div>
    </>
  )
}
