import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { createInactiveZipcodeWishList, getZipcode } from 'actions/zipCode'
import { createLoadingSelector } from 'selectors/loading'

import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from '../../common/Button'
import { ProgressButton } from '../../common/ProgressButton'

interface InformMeModalContentProps {
  onCancel?: () => void
  onSuccess?: () => void
  zipcode?: number | string
}

/**
 * @description This component renders and controls a inform me modal form component.
 * @function
 * @param {Object} props the component props
 */
export const InformMeModalContent: FC<InformMeModalContentProps> = ({
  onCancel = () => undefined,
  onSuccess = () => undefined,
  zipcode = '',
}) => {
  const dispatch = useDispatch()

  const isLoading = {
    getRegionLoadingSelector: useSelector(
      createLoadingSelector(['GET_REGION']),
    ),
    createInactiveZipcodeLoadingSelector: useSelector(
      createLoadingSelector(['CREATE_INACTIVE_ZIPCODE']),
    ),
  }

  const handleCancel = () => {
    // This call should not be necessary, since the zipcode has not changed. For some reason, the validity of the
    // AddressForm.zipcode is reset otherwise and the validation message disappears.
    dispatch(getZipcode(Number(zipcode), { is_active: true }))
    onCancel()
  }

  const handleOk = () => {
    dispatch(createInactiveZipcodeWishList({ zipcode: Number(zipcode) }))
    dispatch(getZipcode(Number(zipcode), { is_active: true }))
    onSuccess()
  }

  return (
    <div>
      <div className='uk-modal-body uk-margin'>
        <Translate value='addressForm.informMeModal.content' />
      </div>
      <div className='uk-modal-footer uk-text-right'>
        <span className='uk-margin-right'>
          <Button
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            dataTestId='add-address-form-close'
            onClick={handleCancel}
          >
            <Translate value='general.button.cancel' />
          </Button>
        </span>
        <ProgressButton
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          dataTestId='add-address-form-submit'
          isLoading={
            isLoading.getRegionLoadingSelector ||
            isLoading.createInactiveZipcodeLoadingSelector
          }
          onClick={handleOk}
          type={BUTTON_TYPE.SUBMIT}
        >
          <Translate value='general.button.submit' />
        </ProgressButton>
      </div>
    </div>
  )
}
