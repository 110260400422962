import './style.scss'

import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { Translate } from 'react-i18nify'

import { callbackOrType } from '../Input'
import { InputCheckmark } from '../InputCheckmark'

export interface TextareaProps {
  className?: string
  dataTestId?: string
  dataTestIdError?: string
  error?: callbackOrType<string>
  isRequired?: boolean
  label?: React.ReactNode
  maxLength?: number
  name: string
  onBlur?: React.FocusEventHandler
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>
  placeholder?: string
  rows?: number
  showRemainingCharacters?: boolean
  value?: string
  subLabel?: string
  withCheckmark?: boolean
  showCheckmark?: callbackOrType<boolean>
}

export const Textarea: FC<TextareaProps> = ({
  className,
  dataTestId = '',
  dataTestIdError = '',
  error = '',
  isRequired = false,
  label = '',
  maxLength = 255,
  name,
  onBlur = () => undefined,
  onChange,
  placeholder = '',
  rows = undefined,
  showRemainingCharacters = false,
  value = '',
  subLabel = '', // lighter text in front of label, usually containing a small description
  withCheckmark = false,
  showCheckmark = false,
}) => {
  const id = uniqueId()
  const currentLength = value ? value.length : 0

  const errorValue =
    typeof error === 'string' || typeof error === 'undefined'
      ? error
      : error(name)
  const showCheckmarkValue =
    typeof showCheckmark === 'boolean' ? showCheckmark : showCheckmark(name)

  return (
    <div
      className={classNames(
        'textarea',
        { 'textarea--with-checkmark': withCheckmark },
        className,
      )}
    >
      <Row middle='xs'>
        <Col xs={12} sm={12}>
          {label && (
            <label // eslint-disable-line jsx-a11y/label-has-for
              className='textarea__label'
              htmlFor={id}
            >
              {label}
              {subLabel && (
                <span className='textarea__label__sub-label'>{subLabel}</span>
              )}
            </label>
          )}
        </Col>
        <Col xs={12} sm={12} style={{ position: 'relative' }}>
          {isRequired && (
            <div
              className={classNames('textarea__required-dot', {
                'textarea__required-dot--error': errorValue !== '',
              })}
            />
          )}
          <textarea
            className={classNames('uk-textarea', 'textarea__textarea')}
            data-testid={dataTestId}
            id={id}
            maxLength={maxLength}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            placeholder={placeholder}
            rows={rows}
            value={value}
          />

          {withCheckmark && <InputCheckmark isHidden={!showCheckmarkValue} />}
        </Col>
      </Row>

      {showRemainingCharacters && (
        <div
          className='uk-text-meta textarea__remaining-characters'
          data-testid={`${dataTestId}-remaining`}
        >
          <Translate
            number={maxLength - currentLength}
            value='general.charsRemaining'
          />
        </div>
      )}

      {errorValue !== '' && (
        <div className='textarea__error' data-testid={dataTestIdError}>
          {errorValue}
        </div>
      )}
    </div>
  )
}
