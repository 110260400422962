import { Formik } from 'formik'
import React, { useState } from 'react'
import { Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

import { getCompanySelector } from 'selectors/company'
import { COMPANY_ROLE } from 'components/company/constants'
import { updateCompany } from 'actions/company'

import { AboutUsForm } from '../AboutUsForm'

/**
 * @description This component displays the about us text of company
 */
export const AboutUs = () => {
  // Redux
  const dispatch = useDispatch()
  const company = useSelector(getCompanySelector)

  // State
  const [isEditing, setIsEditing] = useState(false)

  const companyRole =
    company.role === COMPANY_ROLE.WASTE_PRODUCER ? 'Kunden' : 'Partner'

  return (
    <div
      className='about-us uk-grid uk-width-1-1 uk-margin-small-top'
      data-uk-grid=''
    >
      <div className='uk-width-3-5 uk-text-break'>
        <h5 className='headline-muted uk-float-left'>
          <Translate value='companyInfoPage.aboutUs' role={companyRole} />
        </h5>
      </div>
      <div className='uk-width-2-5'>
        <div className='uk-float-right'>
          {!isEditing && (
            <Link to='#' onClick={() => setIsEditing(true)}>
              <Translate value='general.button.edit' />
            </Link>
          )}
        </div>
      </div>
      {isEditing && (
        <div className='uk-width-1-1 uk-margin-small-top'>
          <Formik
            initialValues={{
              about_us: company.about_us,
            }}
            onSubmit={values => {
              dispatch(
                updateCompany({
                  id: company.id,
                  ...values,
                  isUpdatingCompanyInfo: true,
                }),
              )
              setIsEditing(false)
            }}
            validationSchema={() =>
              yup.object().shape({
                about_us: yup.string().max(500),
              })
            }
          >
            <AboutUsForm />
          </Formik>
        </div>
      )}
      {!isEditing && (
        <div className='uk-width-1-1 uk-margin-small-top uk-margin-small-bottom uk-text-left'>
          <p>{company.about_us}</p>
        </div>
      )}
    </div>
  )
}
