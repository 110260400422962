import './NewsCard.scss'

import classNames from 'classnames'
import moment from 'moment'
import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'

interface NewsCardProps {
  className?: string
  date: string
  image?: string
  label: string
  link?: string
  title: string
  text: string
}

/**
 * Renders the NewsCard
 * @constructor
 */
export const NewsCard: FC<NewsCardProps> = ({
  className,
  date,
  label,
  link,
  title,
  text,
  image,
}) => (
  <article
    className={classNames(
      'news-card',
      { 'news-card--no-image': !image },
      { 'news-card--with-image': !!image },
      className,
    )}
  >
    {image && (
      <div
        className='news-card__image'
        style={{ backgroundImage: `url(${image})` }}
      />
    )}
    <div className='news-card__label'>{label}</div>
    <div className='news-card__date'>{moment(date).format('L')}</div>
    <div className='news-card__headline'>{title}</div>
    <div className='news-card__text'>{text}</div>
    {link && (
      <footer className='news-card__footer'>
        <Link className='news-card__link' to={link}>
          <Translate value='newsCard.button' />
        </Link>
      </footer>
    )}
  </article>
)
