import React, { Component } from 'react'
import { I18n } from 'react-i18nify'
import { shape } from 'prop-types'

import DetailRow from 'components/common/DetailSwitcher/components/DetailRow'
import DetailSection from 'components/common/DetailSwitcher/components/DetailSection'
import { OrderScheme } from 'schemes/order'

/**
 * @description This component renders the address component.
 */
export class AddressComponent extends Component {
  render() {
    return (
      <>
        <DetailSection className='uk-padding' title={I18n.t('general.address')}>
          <DetailRow
            label={I18n.t('inquiry.address')}
            value={
              this.props.order.offer_object.collection_address_object
                .display_name
            }
          />

          {this.props.order.offer_object.collection_address_object
            .description && (
            <DetailRow
              label={I18n.t('inquiry.addressDescription')}
              value={
                this.props.order.offer_object.collection_address_object
                  .description
              }
            />
          )}

          {this.props.order.offer_object.additional_contact_person && (
            <DetailRow
              label={I18n.t('inquiryForm.placeholder.contactPerson')}
              value={this.props.order.offer_object.additional_contact_person}
            />
          )}
        </DetailSection>
      </>
    )
  }
}

AddressComponent.propTypes = {
  order: shape(OrderScheme).isRequired,
}

export default AddressComponent
