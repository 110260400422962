import { Formik } from 'formik'
import moment, { isMoment } from 'moment'
import React, { FC, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { uploadFiles } from 'actions/attachment'
import {
  createInvoiceCheck,
  getCustomTax,
  updateInvoiceCheck,
} from 'actions/maklerpremium'
import FileUploadService from 'components/common/fileUpload/services'
import { DATE_FORMATS } from 'constants/app'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import withErrorBoundary from 'helper/withErrorBoundary'
import { createLoadingSelector } from 'selectors/loading'
import { getCustomTaxSelector } from 'selectors/pricecalc'

import { INVOICE_CHECK_STATUSES } from '../../constants'

import {
  InvoiceCheckAcquisitionForm,
  InvoiceCheckAcquisitionFormValues,
  InvoiceCheckDocumentType,
} from './index'

interface InvoiceCheckAcquisitionFormikWrapperComponentProps {
  handleCloseModal: () => void
  invoiceCheck?: InvoiceCheck
}

const InvoiceCheckAcquisitionFormikWrapperComponent: FC<
  InvoiceCheckAcquisitionFormikWrapperComponentProps
> = ({ handleCloseModal, invoiceCheck }) => {
  const dispatch = useDispatch()
  const initialAttachments = invoiceCheck?.attachments

  const isLoading = {
    customTax: useSelector(createLoadingSelector(['GET_CUSTOM_TAX'])),
  }
  const customTaxObject = useSelector(getCustomTaxSelector)

  useEffect(() => {
    if (!isLoading.customTax && !customTaxObject) {
      dispatch(getCustomTax())
    }
  })

  let customTax
  if (customTaxObject) {
    customTax = Object.values(customTaxObject).find(
      (tax: any) => tax.is_current_vat,
    )
  }

  if (isLoading.customTax || !customTax) return null

  const initialValues: InvoiceCheckAcquisitionFormValues = invoiceCheck
    ? {
        id: invoiceCheck.id,
        comment: invoiceCheck.comment,
        document_date: moment(invoiceCheck.document_date).format('L'),
        document_number: invoiceCheck.document_number,
        document_type: invoiceCheck.document_type,
        attachments: invoiceCheck.attachments,
        gross_price: Math.abs(invoiceCheck.gross_price)
          .toString()
          .replace('.', ','),
        vat: invoiceCheck.vat,
        company: invoiceCheck.company_object?.id,
        search_field: 'company_name',
        editMode: true,
        // Fields used to set search field for FindCompanyByFilterInput
        company_name: `${invoiceCheck.company_object?.name}, ${invoiceCheck.company_object?.main_address_object?.zipcode}`,
        tax_id: '',
        tax_number: '',
        zipcode: '',
        status: invoiceCheck.status,
        saveAndCheckWasClicked: false,
      }
    : {
        id: undefined,
        comment: '',
        document_date: '',
        document_number: '',
        document_type: InvoiceCheckDocumentType.INVOICE,
        attachments: [],
        gross_price: '',
        vat: customTax.tax_in_percent,
        company: undefined,
        search_field: 'company_name',
        editMode: false,
        // Fields used to set search field for FindCompanyByFilterInput
        company_name: '',
        tax_id: '',
        tax_number: '',
        zipcode: '',
        status: INVOICE_CHECK_STATUSES.STATUS_PENDING,
        saveAndCheckWasClicked: false,
      }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values: InvoiceCheckAcquisitionFormValues, formikProps) => {
        const uploadCallbacks = {
          onDocumentSubmit: attachments => {
            if (values.editMode) {
              dispatch(updateInvoiceCheck({ ...values, attachments }))
            } else {
              dispatch(createInvoiceCheck({ ...values, attachments }))
            }
          },
        }

        const newAttachments: { file: any }[] = []
        const currentAttachmentIds = values.attachments?.map(
          attachment => attachment!.id,
        )
        if (initialAttachments && currentAttachmentIds) {
          // Remove attachments that are not in values.attachment anymore
          initialAttachments.forEach(attachment => {
            if (!currentAttachmentIds.includes(attachment.id)) {
              FileUploadService.removeFile(attachment)
            }
          })
        }
        // Add new attachments to newAttachments array
        values.attachments.forEach(attachment => {
          if (attachment.data) {
            newAttachments.push({ file: attachment.data })
          }
        })

        // Do not send attachments that are empty (only appears in update for existing attachments)
        dispatch(uploadFiles(newAttachments, uploadCallbacks))
        formikProps.setSubmitting(false)
      }}
      validationSchema={() =>
        yup.object().shape({
          comment: yup
            .string()
            .trim()
            .min(
              1,
              I18n.t('invoiceCheckTranslations.acquisitionForm.comment.error'),
            )
            .max(
              1000,
              I18n.t('invoiceCheckTranslations.acquisitionForm.comment.error'),
            ),
          document_date: yup
            .string()
            .required(
              I18n.t(
                'invoiceCheckTranslations.acquisitionForm.document_date.error',
              ),
            )
            .test(
              'moment',
              I18n.t(
                'invoiceCheckTranslations.acquisitionForm.document_date.error',
              ),
              value => {
                const momentValue = moment(value, DATE_FORMATS)
                return (
                  isMoment(momentValue) &&
                  momentValue.isValid() &&
                  momentValue.isBefore(moment().endOf('day'))
                )
              },
            ),
          document_number: yup
            .string()
            .required(
              I18n.t(
                'invoiceCheckTranslations.acquisitionForm.document_number.error',
              ),
            ),
          attachments: yup
            .mixed()
            .required(
              I18n.t(
                'invoiceCheckTranslations.acquisitionForm.attachments.error',
              ),
            )
            .test(
              'length',
              I18n.t(
                'invoiceCheckTranslations.acquisitionForm.attachments.error',
              ),
              value => value?.length > 0,
            ),
          gross_price: yup
            .string()
            .required(
              I18n.t(
                'invoiceCheckTranslations.acquisitionForm.gross_price.error',
              ),
            )
            .test(
              'notNull',
              I18n.t(
                'invoiceCheckTranslations.acquisitionForm.gross_price.error',
              ),
              value => value !== '0',
            ),
          company: yup
            .number()
            .required(
              I18n.t(
                'invoiceCheckTranslations.acquisitionForm.company_name.error',
              ),
            ),
          vat: yup
            .number()
            .required(
              I18n.t('invoiceCheckTranslations.acquisitionForm.vat.error'),
            ),
        })
      }
      validateOnChange={false}
      validateOnBlur={false}
      initialTouched={{ vat: true, document_type: true }}
    >
      <InvoiceCheckAcquisitionForm handleCloseModal={handleCloseModal} />
    </Formik>
  )
}

export const InvoiceCheckAcquisitionFormikWrapper: FC<InvoiceCheckAcquisitionFormikWrapperComponentProps> =
  withErrorBoundary(
    withApiErrorHandling(InvoiceCheckAcquisitionFormikWrapperComponent),
  )
