import ActionTypes from '../actiontypes/securitygroup'

/**
 * @function
 * @name getSecurityGroups
 * @description Loads all security groups from the api.
 * @return [Object]
 */
export const getSecurityGroups = () => ({
  type: ActionTypes.GET_SECURITY_GROUPS_REQUEST,
})

export default {}
