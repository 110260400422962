import { createSelector } from 'reselect'

import { COMPANY_ROLE } from '../components/company/constants'

/**
 * @description selector to get the company.
 * @param state
 * @return {*}
 */
export const getInactiveCompanyWarningSelector: (
  state: any,
) => boolean = state => state.showInactiveCompanyWarning

/**
 * @description selector to get the company.
 * @param state
 * @return {*}
 */
export const getCompanySelector: (state: any) => Company = state =>
  state.company.item

/**
 * @description selector to get the company form.
 * @param state
 * @return {*}
 */
export const getCompanyFormSelector = state => state.forms.company.item

/**
 * @description selector to get a list of active companies.
 * @param state
 * @return {*}
 */
export const getActiveCompaniesListSelector: (
  state: any,
) => Company[] = state =>
  state.company.activeItemList
    // remove null values
    .filter(item => item.name && item)

/**
 * @description selector to get a list of all companies for a filter.
 * @param state
 * @return {*}
 */
export const getAllCompaniesForFilterSelector: (
  state: any,
) => BasicFilterResponse[] = state =>
  // remove null values
  state.company.filterList.filter(item => item.name && item)

/**
 * @description selector to get a list of active companies.
 * @param state
 * @return {*}
 */
export const getActiveCompaniesForFilterSelector: (
  state: any,
) => BasicFilterResponse[] = state =>
  // remove null values
  state.company.activeFilterList.filter(item => item.name && item)

/**
 * @description selector to get a list of active customer.
 * @param state
 * @return {*}
 */
export const getActiveCustomerForFilterSelector: (
  state: any,
) => BasicFilterResponse[] = state =>
  // remove null values
  state.company.activeCustomerFilterList.filter(item => item.name && item)

/**
 * @description selector to get a list of active partner.
 * @param state
 * @return {*}
 */
export const getActivePartnerForFilterSelector: (
  state: any,
) => BasicFilterResponse[] = state =>
  // remove null values
  state.company.activePartnerFilterList.filter(item => item.name && item)

/**
 * @description selector to get a list of all companies (active or not).
 * @param state
 * @return {*}
 */
export const getCompaniesListSelector: (state: any) => Company[] = state =>
  state.company.itemList
    // remove null values
    .filter(item => item.name && item)

/**
 * @description selector to get a list of active companies with the roles "Waste company" (TEB)
 * or Middle Man (Makler).
 */
export const getWasteCompanyAndMiddleMenCompaniesSelector: (
  state: any,
) => BasicFilterResponse[] = createSelector(
  getActiveCompaniesForFilterSelector,
  companies =>
    companies.filter(
      company => company.name && company.role !== COMPANY_ROLE.WASTE_PRODUCER,
    ),
)

/**
 * @description selector to get a list of active companies with the roles "Waste producer" (AEZ)
 * or Middle Man (Makler).
 */
export const getWasteProducerAndMiddleMenCompaniesSelector: (
  state: any,
) => BasicFilterResponse[] = createSelector(
  getActiveCompaniesForFilterSelector,
  companies =>
    companies.filter(
      company =>
        company.name &&
        company.role !== COMPANY_ROLE.WASTE_COMPANY &&
        company.makler_premium_company === null,
    ),
)

/**
 * @description selector to get a list of companies to use with offer filters.
 * (orders by company name and removes duplicates).
 * @param state
 * @return {*}
 */
export const getMyOffersCompaniesSelector: (state: any) => Company[] = state =>
  state.company.offersInquiredCompanyList as Company[]

export const getMaklerPremiumCompaniesSelector: (
  state: any,
) => Company[] = state => state.company.maklerPremiumCompaniesList

/**
 * @description selector to get a company list pagination.
 * @param state
 * @return {*}
 */
export const getMaklerPremiumCompaniesPaginationSelector: (
  state: any,
) => Pagination = state => state.company.maklerPremiumPagination

export const getCollectiveInvoiceCompanyListSelector: (
  state: any,
) => Company[] = state => state.company.collectiveInvoiceCompanyList

export const getCollectiveInvoiceCompanyListPaginationSelector: (
  state: any,
) => Pagination = state => state.company.collectiveInvoiceCompanyListPagination

/**
 * @description selector to get a list of companies, in a filter friendly small format
 * @param state
 * @return {*}
 */
export const getCompaniesForFilterSelector: (
  state: any,
) => CompanySearchFilterResponse[] = state =>
  // remove null values
  state.company.filterList.filter(item => item.name && item)

/**
 * @description selector to get a list of  companies with the roles "Waste producer" (AEZ).
 */
export const getAllWasteProducerCompaniesSelector: (state: any) => Company[] =
  createSelector(getMaklerPremiumCompaniesSelector, companies =>
    companies.filter(
      company => company.name && company.role === COMPANY_ROLE.WASTE_PRODUCER,
    ),
  )

/**
 * @description selector to get a list of  companies with the roles "Waste producer" (AEZ).
 */
export const getAllWasteCompanyCompaniesSelector: (state: any) => Company[] =
  createSelector(getMaklerPremiumCompaniesSelector, companies =>
    companies.filter(
      company => company.name && company.role === COMPANY_ROLE.WASTE_COMPANY,
    ),
  )

/**
 * @description selector to the customer company needed for the addAgreementPage
 * @param state
 * @return {*}
 */
export const getAddAgreementCustomerCompanySelector: (
  state: any,
) => Company = state => state.company.addAgreementCompanies.customerCompany

/**
 * @description selector to the partner company needed for the addAgreementPage
 * @param state
 * @return {*}
 */
export const getAddAgreementPartnerCompanySelector: (
  state: any,
) => Company = state => state.company.addAgreementCompanies.partnerCompany

export const getCompanyNotesSelector: (state: any) => CompanyNote[] = state =>
  state.company.notes?.data

export const getCompanyNotesPaginationSelector: (
  state: any,
) => Pagination = state => state.company.notes?.pagination

export const getComCenterContactsSelector: (
  state: any,
) => ComCenterContact[] | undefined = state => state.company.com_center_contacts

export const getComCenterLogSelector: (
  state: any,
) => ComCenterContact[] | undefined = state => state.company.com_center_log

export const getTaskLogsSelector: (state: any) => TaskLog[] = state =>
  state.company.taskLogs?.itemList

export const getTaskLogsPaginationSelector: (
  state: any,
) => Pagination = state => state.company.taskLogs?.pagination

export const getTaskLogMessagesSelector: (
  state: any,
) => TaskLogMessage[] = state => state.company.taskLogMessages?.itemList

export const getTaskLogMessagesPaginationSelector: (
  state: any,
) => Pagination = state => state.company.taskLogMessages?.pagination

export const getCompanyHistorySelector: (state: any) => [] = state =>
  state.company.history?.itemList

export const getCompanyHistoryPaginationSelector: (
  state: any,
) => Pagination = state => state.company.history?.pagination
