import { ExecutionProofDetailsPage } from '../components/executionproof/ExecutionProofDetailsPage'
import { ExecutionProofsPage } from '../components/executionproof/ExecutionProofsPage'
import { UserPermission } from '../constants/user'

/**
 * @constant
 * @description A constant to define execution proof components.
 */

export const executionProofRoutes = [
  {
    path: '/execution-proof/all',
    component: ExecutionProofsPage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_EXECUTIONPROOF],
    showErrorPage: true,
  },
  {
    path: '/execution-proof/:executionProofId',
    component: ExecutionProofDetailsPage,
    exact: true,
  },
]

export default {}
