export const ErrorMessageTranslations = {
  confirmation: {
    title: 'Achtung!',
    subTitle: 'Auftrag bestätigen ist fehlgeschlagen.',
    text: 'Der Auftrag kann momentan nicht bestätigt werden.<br />Bitte kontaktieren Sie unseren Kundenservice.',
  },
  cancellation: {
    title: 'Achtung!',
    subTitle: 'Auftrag stornieren ist fehlgeschlagen.',
    text: 'Der Auftrag kann momentan nicht storniert werden.<br />Bitte kontaktieren Sie unseren Kundenservice.',
  },
  general: {
    title: 'Achtung!',
    subTitle: 'Ein Fehler ist aufgetreten.',
    text: 'Die von Ihnen angeforderte Seite ist im Moment nicht verfügbar.<br />Bitte kontaktieren Sie unseren Kundenservice.',
  },
  expired: {
    title: 'Achtung!',
    subTitle: 'Ein Fehler ist aufgetreten.',
    text: 'Der Auftrag liegt in der Vergangenheit.<br />Bitte wenden Sie sich telefonisch an unseren Kundenservice.',
  },
}
