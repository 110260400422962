import { createContext } from 'react'

import { PageDimensions } from './PageDimensionsProvider'

export const initialDimensions: PageDimensions = {
  headerHeight: undefined,
  footerHeight: undefined,
  footerBottomPos: 0,
}

export const PageDimensionsContext =
  createContext<PageDimensions>(initialDimensions)
