import { useFormikContext } from 'formik'
import React from 'react'
import { I18n } from 'react-i18nify'

import { InputDecimal } from 'components/common/InputDecimal'

import { AddAgreementFormValues } from '../../AddAgreementFormSteps'
import { ROLE_KEYS } from '../../Step1/helpers'
import { PRICING } from '../DefineNetPriceForm'

import { inputIsValid } from './helpers'

type PackagePriceFormFieldsPropsType = {
  role: string
  pricing: string
}

export const PackagePriceFormFields = (
  props: PackagePriceFormFieldsPropsType,
) => {
  const { role, pricing } = props

  const translationString = 'addAgreementPageTranslations.steps.4.fields'

  const { values, handleChange } = useFormikContext<AddAgreementFormValues>()

  return (
    <>
      {/*PAUSCHALPREISE KUNDE UND PREISMODELL ENTSORGUNG*/}
      {role === ROLE_KEYS.CUSTOMER && pricing === PRICING.DISPOSAL && (
        <>
          {/*PAUSCHALPREIS (€ PRO BEHÄLTER)*/}
          <InputDecimal
            name={`customer_package_price`}
            label={I18n.t(`${translationString}.packagePrice.label`)}
            placeholder={I18n.t(
              `${translationString}.packagePrice.placeholder`,
            )}
            value={values.customer_package_price}
            maxValue={4999.99}
            onChange={e =>
              inputIsValid(values.customer_package_price, e.target.value) &&
              handleChange(e)
            }
          />
        </>
      )}
    </>
  )
}
