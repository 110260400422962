export const DSZDashboardTranslations = {
  // FIRST ROW
  contractDaily: {
    buttonText: 'Alle Verträge ansehen',
    caption: {
      singular: 'Vertrag heute',
      plural: 'Verträge heute',
    },
  },
  priceManagement: {
    buttonText: 'Preise verwalten',
    value: 'Preisverwaltung',
    caption: 'Übersicht über alle Preise und Verwaltungsmöglichkeiten',
  },
}
