import { arrayOf, number, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { Errors } from 'react-redux-form'

import Icon from 'components/common/Fontello'
import ControlEditToggle from 'components/common/Form/components/ControlEditToggle'
import TextboxTypes from 'components/common/Form/components/TextboxControl/constants'
import { SelectControl, TextboxControl } from 'components/common/Form/elements'
import { SectionHeader } from 'components/common/SectionHeader'
import { CompanyFormValidators } from 'components/company/CompanyProfilePage/constants'
import { countryIsGermany } from 'components/company/CompanyProfilePage/helpers'
import { CompanyFormScheme } from 'components/company/CompanyProfilePage/schemes'
import { BUSINESS_SEGMENT } from 'constants/app'
import { AddressScheme } from 'schemes/address'

import connector from '../connector'
import { getListLabelForCountry } from '../helper'

export class PostalAddressDetailsComponent extends Component {
  render() {
    if (this.props.company.business_segment !== BUSINESS_SEGMENT.BUSINESS_TVP)
      return null

    const selectedCountryIsGermany = countryIsGermany(
      this.props.company.postal_address_object.country,
    )

    const postalAddress = this.props.company.postal_address_object
    const formPostalAddress = this.props.companyForm.postal_address_object

    return (
      <section className='uk-card uk-padding-remove address-details'>
        <SectionHeader>
          <Icon name='map' />
          <Translate value='companyProfilePage.postalAddressDetails' />
        </SectionHeader>

        <div className='uk-padding details-container'>
          <div className='uk-grid uk-child-width-1-1 uk-child-width-1-4@m'>
            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.recipient')}
              value={postalAddress.recipient}
              errorClass={
                !formPostalAddress.recipient.valid ? 'uk-text-danger' : null
              }
              triggerEdit={!formPostalAddress.recipient.valid}
              editable
            >
              <TextboxControl
                className='uk-input'
                field={formPostalAddress.recipient}
                placeholder={I18n.t('companyProfilePage.label.recipient')}
                validators={CompanyFormValidators.name}
                maxLength={200}
                errorMessages={{
                  isRequired: I18n.t('message.validation.required'),
                }}
              />
            </ControlEditToggle>

            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.street')}
              value={postalAddress.street}
              triggerEdit={!formPostalAddress.street.valid}
              errorClass={
                !formPostalAddress.street.valid ? 'uk-text-danger' : null
              }
              editable
            >
              <TextboxControl
                className='uk-input'
                field={formPostalAddress.street}
                validators={CompanyFormValidators['main_address_object.street']}
                placeholder={I18n.t('general.placeholder.street')}
                errorMessages={{
                  isRequired: I18n.t('addressForm.validation.street'),
                }}
                maxLength={200}
              />
            </ControlEditToggle>

            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.houseNumber')}
              value={postalAddress.house_number}
              triggerEdit={!formPostalAddress.house_number.valid}
              errorClass={
                !formPostalAddress.house_number.valid ? 'uk-text-danger' : null
              }
              editable
            >
              <TextboxControl
                className='uk-input'
                field={formPostalAddress.house_number}
                validators={
                  CompanyFormValidators['main_address_object.house_number']
                }
                placeholder={I18n.t('general.placeholder.houseNumber')}
                errorMessages={{
                  isRequired: I18n.t('addressForm.validation.houseNumber'),
                }}
                maxLength={10}
              />
            </ControlEditToggle>

            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.zipcode')}
              value={postalAddress.zipcode}
              triggerEdit={!formPostalAddress.zipcode.valid}
              errorClass={
                !formPostalAddress.zipcode.valid ? 'uk-text-danger' : null
              }
              editable
            >
              <TextboxControl
                className='uk-input'
                field={formPostalAddress.zipcode}
                placeholder={I18n.t('general.placeholder.zipcode')}
                errorMessages={{
                  isRequired: I18n.t('addressForm.validation.zipcode'),
                  isValidZipcode: I18n.t('addressForm.validation.validZipcode'),
                }}
                maxLength={selectedCountryIsGermany ? 5 : 10}
                type={
                  selectedCountryIsGermany
                    ? TextboxTypes.NUMBER
                    : TextboxTypes.TEXT
                }
              />
            </ControlEditToggle>

            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t('companyProfilePage.label.location')}
              value={postalAddress.location}
              triggerEdit={!formPostalAddress.location.valid}
              errorClass={
                !formPostalAddress.location.valid ? 'uk-text-danger' : null
              }
              editable
            >
              <TextboxControl
                className='uk-input'
                field={formPostalAddress.location}
                validators={
                  CompanyFormValidators['main_address_object.location']
                }
                placeholder={I18n.t('general.placeholder.location')}
                errorMessages={{
                  isRequired: I18n.t('addressForm.validation.location'),
                }}
                maxLength={100}
              />
            </ControlEditToggle>

            <ControlEditToggle
              className='uk-margin-small-bottom'
              label={I18n.t('companyProfilePage.label.country')}
              value={getListLabelForCountry(
                this.props.countries.find(
                  country => country.id === postalAddress.country,
                ),
              )}
              triggerEdit={
                formPostalAddress.country && !formPostalAddress.country.valid
              }
              editable
            >
              <SelectControl
                className='uk-select'
                field={formPostalAddress.country}
                validators={
                  CompanyFormValidators['main_address_object.country']
                }
                options={this.props.countries.map(item => ({
                  id: item.id,
                  label: getListLabelForCountry(item),
                }))}
                errorMessages={{
                  isRequired: I18n.t('companyProfilePage.validation.country'),
                }}
                placeholder={I18n.t('companyProfilePage.label.country')}
              />
            </ControlEditToggle>

            <ControlEditToggle
              className='uk-margin-medium-bottom'
              label={I18n.t(
                'companyProfilePage.label.emailForInvoicingDocuments',
              )}
              value={postalAddress.email_for_invoicing_documents}
              errorClass={
                !formPostalAddress.email_for_invoicing_documents.valid
                  ? 'uk-text-danger'
                  : null
              }
              triggerEdit={
                !formPostalAddress.email_for_invoicing_documents.valid
              }
              editable
            >
              <TextboxControl
                className='uk-input'
                field={formPostalAddress.email_for_invoicing_documents}
                placeholder={I18n.t(
                  'companyProfilePage.label.emailForInvoicingDocuments',
                )}
                validators={CompanyFormValidators.central_contact_email}
                maxLength={50}
                errorMessages={{
                  isEmail: I18n.t('message.validation.email.isEmail'),
                }}
              />
            </ControlEditToggle>
          </div>

          <Errors
            model='company.item'
            className='form-error'
            messages={{
              postalAddressDataFilled: I18n.t(
                'companyProfilePage.validation.postalAddressDataFilled',
              ),
              postalAddressZipcode: I18n.t(
                'companyProfilePage.validation.postalAddressZipcode',
              ),
            }}
          />
        </div>
      </section>
    )
  }
}

PostalAddressDetailsComponent.propTypes = {
  countries: arrayOf(
    shape({
      id: number,
      name: string,
    }),
  ).isRequired,
  company: shape({
    business_segment: number,
    postal_address_object: shape(AddressScheme),
  }).isRequired,
  companyForm: CompanyFormScheme.isRequired,
}

export default connector(PostalAddressDetailsComponent)
