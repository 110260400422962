import React from 'react'
import { Translate } from 'react-i18nify'

/**
 * @description This is a method to get the AVV value to display the correct value on the details page.
 * This will display "Please select" in in the create offer page and the correct value in the other ones.
 * @param fractionId
 * @param fractionList
 * @param formFieldValue
 */
export const getAvvValue = (
  fractionId: number | undefined = undefined,
  fractionList: Fraction[] = [],
  formFieldValue: number | undefined = undefined,
) => {
  if (!fractionId || !fractionList)
    return <Translate value='offerForm.avvNumber.selector' />

  const fraction = fractionList.find(_fraction => _fraction.id === fractionId)
  const avvList = fraction && fraction.avv_list

  if (avvList && formFieldValue && avvList.length === 1) {
    return avvList[0].number
  }
  if (avvList && formFieldValue && avvList.length > 0) {
    const avvObject = avvList.find(avv => avv.id === Number(formFieldValue))
    return avvObject ? (
      avvObject.number
    ) : (
      <Translate value='offerForm.avvNumber.selector' />
    )
  }
  return <Translate value='offerForm.avvNumber.selector' />
}
