import { createSelector } from 'reselect'

import {
  CERTIFICATE_STATUS,
  CERTIFICATE_TYPE,
} from '../components/certificate/constants'

/* Helper functions (predicates etc.) */

/**
 * @description Returns a function that finds a certificate in an array by the provided type
 * @param type
 * @returns {function(*): *}
 */
const certificateTypePredicate = type => certificates =>
  certificates.find(certificate => certificate.type === type)

/**
 * @description Returns a function that finds a certificate in an array by the provided type ans current status
 * @param type
 * @param status
 * @returns {function(*): *}
 */
const certificateTypeStatusPredicate = (type, status) => certificates =>
  certificates.find(
    certificate => certificate.type === type && certificate.status === status,
  )

/* Selectors */

/**
 * @description selector to get the list of certificates.
 * @param state
 * @return {*}
 */
export const getCertificatesSelector: (state: any) => Certificate[] = state =>
  state.certificate.itemList

/**
 * @description selector to get a certificate.
 * @param state
 * @return {*}
 */
export const getCertificateSelector: (state: any) => Certificate = state =>
  state.certificate.item

/**
 * @description Gets the list of the latest certificate for each type of my company
 * @param state
 * @return {*}
 */
export const getMyLatestCertificatesSelector: (
  state: any,
) => Certificate[] = state => state.certificate.latestItemList

/**
 * @description Gets the list of the latest certificate for each type of my company
 * @param state
 * @return {*}
 */
export const getCompaniesPriceAgreementsSelector: (
  state: any,
) => Record<string, PriceAgreement> = state =>
  state.certificate.priceAgreementList

/**
 * @description selector to get an certificate pagination object.
 * @param state
 * @return {*}
 */
export const getCertificatePaginationSelector = state =>
  state.certificate.pagination

/**
 * @description selector to get a list of my certificates.
 * @param state
 * @return {*}
 */
export const getMyCertificatesSelector: (state: any) => Certificate[] = state =>
  state.certificate.myItemList

/**
 * @description A selector that gets the EFB certificate from the array of latest certificates.
 */
export const getEfbCertificateSelector = createSelector(
  getMyLatestCertificatesSelector,
  certificateTypePredicate(CERTIFICATE_TYPE.TYPE_EFB),
)

/**
 * @description A selector that gets the accepted EFB certificate from the array of latest certificates.
 */
export const getAcceptedEfbCertificateSelector = createSelector(
  getMyLatestCertificatesSelector,
  certificateTypeStatusPredicate(
    CERTIFICATE_TYPE.TYPE_EFB,
    CERTIFICATE_STATUS.STATUS_ACCEPTED,
  ),
)

/**
 * @description A selector that gets the EFB certificate from the array of latest certificates.
 */
export const getOtherCertificateSelector = createSelector(
  getMyLatestCertificatesSelector,
  certificateTypePredicate(CERTIFICATE_TYPE.TYPE_OTHER),
)

/**
 * @description A selector that gets the EFB certificate from the array of latest certificates.
 */
export const getCompanyCertificateSelector = createSelector(
  getMyLatestCertificatesSelector,
  certificateTypePredicate(CERTIFICATE_TYPE.TYPE_COMPANY),
)

/**
 * @description A selector that gets the Price Agreements from the array of latest certificates.
 */
export const getPriceAgreementSelector = createSelector(
  getMyLatestCertificatesSelector,
  certificateTypePredicate(CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT),
)

export const getArchivedCertificatesSelector: (
  state: any,
) => Certificate[] = state => state.certificate.archivedItemList

export const getArchivedCertificatePaginationSelector = state =>
  state.certificate.archivedPagination
