import keyMirror from 'keymirror'

const GET_DASHBOARD = keyMirror({
  GET_DASHBOARD_REQUEST: null,
  GET_DASHBOARD_SUCCESS: null,
  GET_DASHBOARD_ERROR: null,
})

const GET_DASHBOARD_EPD = keyMirror({
  GET_DASHBOARD_EPD_REQUEST: null,
  GET_DASHBOARD_EPD_SUCCESS: null,
  GET_DASHBOARD_EPD_ERROR: null,
})

export default {
  ...GET_DASHBOARD,
  ...GET_DASHBOARD_EPD,
}
