import keyMirror from 'keymirror'

export const DISCOUNT_MODALS = keyMirror({
  DISCOUNT_FORM_MODAL: null,
  DISCOUNT_DETAILS_MODAL: null,
})

export const DISCOUNT_TYPES = [
  {
    id: 1,
    label: 'discountOverview.discountType.individual',
    value: 'individual',
  },
  {
    id: 2,
    label: 'discountOverview.discountType.general',
    value: 'general',
  },
]

export const DISCOUNT_STATUS = {
  STATUS_ACTIVE: 1,
  STATUS_EXPIRED: 2,
  STATUS_BLOCKED: 3,
}

export const DISCOUNT_STATES = [
  {
    id: DISCOUNT_STATUS.STATUS_ACTIVE,
    name: 'constant.status.active',
    colorClass: 'green',
  },
  {
    id: DISCOUNT_STATUS.STATUS_EXPIRED,
    name: 'constant.status.expired',
    colorClass: 'gray',
  },
  {
    id: DISCOUNT_STATUS.STATUS_BLOCKED,
    name: 'constant.status.locked',
    colorClass: 'red',
  },
]

export const DISCOUNT_OCCASION = {
  OCCASION_CAMPAIGN: 1,
  OCCASION_CLAIM: 2,
  OCCASION_OTHER: 3,
}

export const DISCOUNT_OCCASIONS = [
  {
    id: DISCOUNT_OCCASION.OCCASION_CAMPAIGN,
    name: 'discountOverview.occasionType.campaign',
  },
  {
    id: DISCOUNT_OCCASION.OCCASION_CLAIM,
    name: 'discountOverview.occasionType.claim',
  },
  {
    id: DISCOUNT_OCCASION.OCCASION_OTHER,
    name: 'discountOverview.occasionType.other',
  },
]

export default {
  ...DISCOUNT_MODALS,
  ...DISCOUNT_STATUS,
  ...DISCOUNT_STATES,
  ...DISCOUNT_OCCASION,
  ...DISCOUNT_OCCASIONS,
}
