import keyMirror from 'keymirror'

const GET_WORKLOADS = keyMirror({
  GET_WORKLOADS_REQUEST: null,
  GET_WORKLOADS_SUCCESS: null,
  GET_WORKLOADS_ERROR: null,
})

const EXPORT_WORKLOAD = keyMirror({
  EXPORT_WORKLOAD_REQUEST: null,
  EXPORT_WORKLOAD_SUCCESS: null,
  EXPORT_WORKLOAD_ERROR: null,
})

export default {
  ...GET_WORKLOADS,
  ...EXPORT_WORKLOAD,
}
