export const CancelledPaidDocumentReviewPageTranslations = {
  breadcrumb: {
    title: 'Stornierter bezahlter Beleg',
    prevLinkTitle: 'Arbeitsvorrat',
  },
  pageTitle: 'Stornierung bezahlter Beleg',
  documentTitle: 'Ein bezahlter Beleg wurde storniert',
  validation: {
    status: 'Bitte ändern Sie den Status.',
    rejectReason: 'Bitte begründen Sie Ihre Reklamation.',
  },
  form: {
    // PAID DOCUMENT
    documentNumber: 'Belegnummer',
    documentType: 'Belegtyp',
    documentDate: 'Belegdatum',
    documentGrossPrice: 'Gesamtsumme Brutto',

    // CANCELLED DOCUMENT
    cancelledNumber: 'Storno Belegnummer',
    cancelledUser: 'Benutzer',
    cancelledReason: 'Stornierungsgrund',

    // REVIEW SECTION
    internalNote: 'Interner Kommentar',
    internalNotePlaceholder: 'Belegnummer angeben',
    rejectReason: 'Ablehnungsgrund',
    reviewedAt: 'Geprüft am',
    reviewedAtTime: 'Zeitstempel der Prüfung',
    reviewedBy: 'Geprüft von',
    status: 'Status',
  },
}
