import uniqueId from 'lodash.uniqueid'
import { bool, func, oneOfType, string } from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { ProgressButton } from 'components/common/ProgressButton'

class SaveInternalCommentModal extends Component {
  static propTypes = {
    openModal: oneOfType([string, bool]),
    handleSaveInternalNote: func.isRequired,
    handleModalToggle: func.isRequired,
    isRelabeled: bool,
    isContainerChanged: bool,
    isAdditionalRent: bool,
    isCancelledPaidDocument: bool,
  }

  static defaultProps = {
    openModal: false,
    isRelabeled: true,
    isContainerChanged: false,
    isAdditionalRent: false,
    isCancelledPaidDocument: false,
  }

  render() {
    const confirmInternalCommentSaveModalId = uniqueId()
    const {
      handleSaveInternalNote,
      handleModalToggle,
      openModal,
      isRelabeled,
      isContainerChanged,
      isAdditionalRent,
      isCancelledPaidDocument,
    } = this.props

    const getInternalNote = () => {
      if (isRelabeled) {
        return I18n.t(
          'InvoiceCheckPositionReviewPageTranslations.saveInternalNote.confirm.redeclaration',
        )
      }

      if (isContainerChanged) {
        return I18n.t(
          'InvoiceCheckPositionReviewPageTranslations.saveInternalNote.confirm.containerChanged',
        )
      }

      if (isAdditionalRent) {
        return I18n.t(
          'InvoiceCheckPositionReviewPageTranslations.saveInternalNote.confirm.additionalRent',
        )
      }

      if (isCancelledPaidDocument) {
        return I18n.t(
          'InvoiceCheckPositionReviewPageTranslations.saveInternalNote.confirm.cancelledPaidDocument',
        )
      }

      return I18n.t(
        'InvoiceCheckPositionReviewPageTranslations.saveInternalNote.confirm.otherService',
      )
    }

    return (
      <Modal
        ariaDescribedBy={confirmInternalCommentSaveModalId}
        isOpen={openModal}
        onClose={handleModalToggle}
      >
        <ModalHeader
          onClose={handleModalToggle}
          title={I18n.t(
            'InvoiceCheckPositionReviewPageTranslations.submitButtonText.isSaving',
          )}
          titleId={confirmInternalCommentSaveModalId}
        />
        <div className='uk-modal-body'>{getInternalNote()}</div>
        <div className='uk-modal-footer uk-text-right'>
          <span className='uk-margin-right'>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
              dataTestId='save-internal-comment-message-close'
              onClick={handleModalToggle}
            >
              <Translate value='general.button.cancel' />
            </Button>
          </span>
          <ProgressButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            dataTestId='save-internal-comment-message-submit'
            onClick={handleSaveInternalNote}
            type='button'
          >
            <Translate value='general.button.save' />
          </ProgressButton>
        </div>
      </Modal>
    )
  }
}

export default SaveInternalCommentModal
