import './style.scss'

import { useFormikContext } from 'formik'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { TaxSectionFields } from 'components/maklerpremium/TaxSectionFields'
import { InputText } from 'components/common/InputText'

import { MaklerPremiumOfferValues } from '../CreateMaklerPremiumOfferFormSteps'

export const NewBusinessCustomerFields: FC = () => {
  const { errors, handleChange, submitCount, values } =
    useFormikContext<MaklerPremiumOfferValues>()

  const isFieldValid = name =>
    values[name] && values[name].length > 0 && !errors[name]
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0 ? errors[name] : undefined

  return (
    <>
      <InputText
        className='maklerpremium-offerform__column-2'
        error={getFieldError}
        label={I18n.t(
          'createMaklerPremiumOfferPageTranslations.steps.3.fields.company_name.label',
        )}
        name='company_name'
        onChange={handleChange}
        placeholder={I18n.t(
          'createMaklerPremiumOfferPageTranslations.steps.3.fields.company_name.placeholder',
        )}
        showCheckmark={isFieldValid}
        value={values.company_name}
        withCheckmark
      />
      <div className='maklerpremium-offerform__tax-section uk-padding-small maklerpremium-offerform__column-2'>
        <TaxSectionFields />
      </div>
    </>
  )
}
