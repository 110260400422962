import { Form, withFormik } from 'formik'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { compose } from 'recompose'
import * as yup from 'yup'
import { bool, func, number, shape, string } from 'prop-types'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { ApiValidationMessages } from 'components/common/Form/components/ApiValidationMessages'
import { passwordIsSafe } from 'components/common/Form/validators'
import InputPassword from 'components/common/InputPassword'
import { ProgressButton } from 'components/common/ProgressButton'
import { TOOLTIP_ZINDEX } from 'components/common/Tooltip'
import { ApiErrorScheme } from 'schemes/error'

import connector from './connector'

export class ChangePasswordFormComponent extends Component {
  static propTypes = {
    error: shape(ApiErrorScheme),
    errors: shape({
      old_password: string,
      password: string,
      password_repeat: string,
    }).isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    handleSubmit: func.isRequired,
    isLoading: bool,
    isSubmitting: bool.isRequired,
    isValid: bool.isRequired,
    onCancel: func,
    resetApiFetchErrors: func.isRequired,
    submitCount: number.isRequired,
    touched: shape({
      old_password: bool,
      password: bool,
      password_repeat: bool,
    }).isRequired,
    values: shape({
      old_password: string,
      password: string,
      password_repeat: string,
    }).isRequired,
  }

  static defaultProps = {
    error: null,
    isLoading: false,
    onCancel: () => undefined,
  }

  /**
   * @description Component “lifecycle method” componentDidMount
   */
  componentDidMount() {
    this.props.resetApiFetchErrors('CHANGE_PASSWORD')
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    const {
      // this is the error from the store error reducer . Used to get the api error for ApiValidationMessages component
      error: apiError,
      // this is the error from the Formik api. Used to get the form validation errors
      errors: formikErrors,
      handleBlur,
      handleChange,
      handleSubmit,
      isLoading,
      isSubmitting,
      isValid,
      onCancel,
      submitCount,
      touched,
      values,
    } = this.props

    return (
      <Form
        className='user-profile-change-password-form'
        data-testid='user-profile-change-password-form'
        onSubmit={handleSubmit}
        noValidate // Disable browser validation
      >
        <div className='change-password-form uk-modal-body'>
          <ApiValidationMessages error={apiError} />
          <InputPassword
            dataTestId='change-password-form-old-password-input'
            dataTestIdError='change-password-form-group-error'
            error={
              submitCount > 0 && touched.old_password
                ? formikErrors.old_password
                : ''
            }
            isRequired={values.old_password === ''}
            label={I18n.t(
              'userProfilePageTranslations.changePasswordForm.oldPassword.label',
            )}
            maxLength={50}
            name='old_password'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t(
              'userProfilePageTranslations.changePasswordForm.oldPassword.placeholder',
            )}
            tooltipZIndex={TOOLTIP_ZINDEX.BEFORE_MODALS}
            value={values.old_password}
          />
          <InputPassword
            dataTestId='change-password-form-password-input'
            dataTestIdError='change-password-form-group-error'
            error={
              submitCount > 0 && touched.password ? formikErrors.password : ''
            }
            isRequired={values.password === ''}
            label={I18n.t(
              'userProfilePageTranslations.changePasswordForm.newPassword.label',
            )}
            maxLength={50}
            name='password'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t(
              'userProfilePageTranslations.changePasswordForm.newPassword.placeholder',
            )}
            tooltipZIndex={TOOLTIP_ZINDEX.BEFORE_MODALS}
            value={values.password}
          />

          <InputPassword
            dataTestId='change-password-form-password-repeat-input'
            dataTestIdError='change-password-form-group-error'
            error={
              submitCount > 0 && touched.password_repeat
                ? formikErrors.password_repeat
                : ''
            }
            isRequired={values.password_repeat === ''}
            label={I18n.t(
              'userProfilePageTranslations.changePasswordForm.repeatPassword.label',
            )}
            maxLength={50}
            name='password_repeat'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t(
              'userProfilePageTranslations.changePasswordForm.repeatPassword.placeholder',
            )}
            tooltipZIndex={TOOLTIP_ZINDEX.BEFORE_MODALS}
            value={values.password_repeat}
          />
        </div>

        <div className='uk-modal-footer uk-text-right'>
          <span className='uk-margin-right'>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
              onClick={onCancel}
            >
              <Translate value='general.button.cancel' />
            </Button>
          </span>

          <ProgressButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            dataTestId='user-profile-change-password-form-submit'
            isDisabled={
              (submitCount > 0 && !isValid) || (isSubmitting && isLoading)
            }
            isLoading={isSubmitting && isLoading}
            onClick={handleSubmit}
          >
            <Translate value='general.button.submit' />
          </ProgressButton>
        </div>
      </Form>
    )
  }
}

export default compose(
  connector,
  withFormik({
    handleSubmit: (values, { props, setFieldError }) => {
      // I am sending this logic to the redux logic because otherwise it is not possible to handle this correctly.
      const callback = {
        success: () => props.onSuccess(),
        setError: (key, string) => setFieldError(key, string),
      }
      props.changePassword(values.old_password, values.password, callback)
    },
    mapPropsToValues: () => ({
      old_password: '',
      password: '',
      password_repeat: '',
    }),
    validationSchema: () =>
      yup.object().shape({
        old_password: yup
          .string()
          .required(
            I18n.t(
              'userProfilePageTranslations.changePasswordForm.oldPassword.required',
            ),
          ),
        password: yup
          .string()
          .required(
            I18n.t(
              'userProfilePageTranslations.changePasswordForm.newPassword.required',
            ),
          )
          .test(
            'secure-password',
            I18n.t(
              'userProfilePageTranslations.changePasswordForm.newPassword.notSecure',
            ),
            passwordIsSafe,
          ),
        password_repeat: yup
          .string()
          // both error messages must be the same. User shall only see "password is not equal"
          .required(
            I18n.t(
              'userProfilePageTranslations.changePasswordForm.repeatPassword.notEqual',
            ),
          )
          .oneOf(
            [yup.ref('password')],
            I18n.t(
              'userProfilePageTranslations.changePasswordForm.repeatPassword.notEqual',
            ),
          ),
      }),
  }),
)(ChangePasswordFormComponent)
