import { bool, node, number, oneOf, oneOfType, shape, string } from 'prop-types'

export const TOOLTIP_ZINDEX = {
  BEHIND_HEADER: 900,
  BEFORE_HEADER_BEHIND_MODAL: 8999,
  BEFORE_MODALS: 9999,
}

export const TOOLTIP_POSITION = {
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
  TOP: 'top',
}

export const TOOLTIP_THEME = {
  DARK: 'dark',
  LIGHT: 'light',
}

export const TOOLTIP_SIZE = {
  SMALL: 'small',
  REGULAR: 'regular',
  BIG: 'big',
}

export const TOOLTIP_TRIGGER = {
  MOUSEENTER: 'mouseenter',
  FOCUS: 'focus',
  CLICK: 'click',
  MANUAL: 'manual',
}

export const TooltipProps = {
  children: node,
  distance: number,
  inline: bool,
  popperOptions: shape({}),
  position: oneOf(Object.values(TOOLTIP_POSITION)),
  size: oneOf(Object.values(TOOLTIP_SIZE)),
  theme: oneOf(Object.values(TOOLTIP_THEME)),
  tooltipId: oneOfType([string, number]),
  trigger: oneOf(Object.values(TOOLTIP_TRIGGER)),
  zIndex: number,
}

export const TooltipIconProps = {
  ...TooltipProps,
  description: string,
  iconClass: string,
  title: string,
  withClickCatcher: bool,
  preventDefault: bool,
}
