import './style.scss'

import classNames from 'classnames'
import { FieldArray, FormikProps } from 'formik'
import moment from 'moment'
import React, { FC, useEffect, useMemo } from 'react'
import { I18n } from 'react-i18nify'

import { DropDownInput } from 'components/common/DropDownInput'
import { FormFieldErrorMessage } from 'components/common/FormFieldErrorMessage'
import {
  BUTTON_BACKGROUND_COLOR,
  ICON_POSITION,
  IconButton,
} from 'components/common/IconButton'
import { InputChooseItem } from 'components/common/InputChooseItem'
import InputDate from 'components/common/InputDate'
import { InputDecimal } from 'components/common/InputDecimal'
import { InputNumber } from 'components/common/InputNumber'
import { InputText } from 'components/common/InputText'
import { RadioButton } from 'components/common/RadioButton'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { Textarea } from 'components/common/Textarea'
import { TOOLTIP_ZINDEX } from 'components/common/Tooltip'
import {
  EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS,
  EXECUTION_PROOF_STATES,
  EXECUTION_PROOF_STATUS,
} from 'components/executionproof/constants'
import {
  formatWeights,
  getAvvValue,
  getFractionName,
} from 'components/executionproof/helpers'
import { CONTENT_TYPES } from 'components/inquiry/CreateInquiryPage/FractionAndContainerFormGroup/constants'
import {
  getCoarseFractionItemsForSegment,
  getFineFractionItems,
} from 'components/inquiry/helpers'
import { PriceDetailFields } from 'components/offer/CreateOfferPage/PriceDetailFields'
import { COLLECTION_CONTAINER_IDS } from 'constants/app'
import { getName } from 'helper/translations'

import { SelectSecurityGroup } from '../../SelectSecurityGroup'
import {
  getSelectedProtectionClassName,
  getSelectedSecurityLevelName,
  isSecurityRequired,
} from '../../SelectSecurityGroup/helpers'
import { DetailsSection } from '../DetailsSection'

interface ExecutionProofReviewComponentProps {
  execution: ExecutionProof
  containerList: Container[]
  fractionList: Fraction[]
  indexList: OfferIndex[]
  formikProps: FormikProps<any>
  isReview?: boolean
}

export const ExecutionProofReview: FC<ExecutionProofReviewComponentProps> = ({
  execution,
  fractionList,
  isReview = false,
  formikProps,
}) => {
  const renderCriteria = isReview ? formikProps.values : execution
  const weightList = isReview
    ? formikProps.values.weight_object_list
    : formatWeights(execution.weight_object_list)

  const fraction = fractionList.find(
    _fraction => _fraction.id === Number(formikProps.values.fraction),
  )
  const fractionItems = getCoarseFractionItemsForSegment(fractionList)
  const fineFractionList = getFineFractionItems(
    Number(formikProps.values.fraction),
    fractionList,
  )
  const avvList = useMemo(() => (fraction ? fraction.avv_list : []), [fraction])

  const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
    Number(execution.order_object.offer_object.container),
  )

  const setAvvValue = formikProps.setFieldValue

  useEffect(() => {
    if (avvList.length === 1) {
      setAvvValue('avv', avvList[0].id)
    }
  }, [avvList, setAvvValue])

  const showRemoveWeightFieldButton =
    formikProps.values.weight_object_list.length > 1
  const executionProofNotRejected =
    Number(formikProps.values.status) !== EXECUTION_PROOF_STATUS.STATUS_REJECTED

  const NumberOfContainersOrQuantityInCubicMeters = (
    <ReadOnlyField
      editable={isReview}
      label={
        showQuantityInCubicMeters
          ? I18n.t(
              'documentReviewPageTranslations.executionProofReview.quantityInCubicMeters',
            )
          : I18n.t(
              'documentReviewPageTranslations.executionProofReview.numberOfContainers',
            )
      }
      value={
        showQuantityInCubicMeters
          ? execution.quantity_in_cubic_meters
          : execution.number_of_containers
      }
    >
      <>
        {!showQuantityInCubicMeters && (
          <InputNumber
            label={I18n.t(
              'documentReviewPageTranslations.executionProofReview.numberOfContainers',
            )}
            placeholder={I18n.t(
              'documentReviewPageTranslations.executionProofReview.numberOfContainers',
            )}
            maxLength={2}
            name='number_of_containers'
            onBlur={formikProps.handleBlur}
            onChange={formikProps.handleChange}
            value={formikProps.values.number_of_containers}
            isRequired={
              !formikProps.values.number_of_containers &&
              executionProofNotRejected
            }
            error={
              formikProps.submitCount > 0 &&
              formikProps.touched.number_of_containers
                ? (formikProps.errors.number_of_containers as string)
                : ''
            }
          />
        )}
        {showQuantityInCubicMeters && (
          <InputDecimal
            dataTestId='quantity-in-cubic-meters-input'
            label={I18n.t(
              'documentReviewPageTranslations.executionProofReview.quantityInCubicMeters',
            )}
            placeholder={I18n.t(
              'documentReviewPageTranslations.executionProofReview.quantityInCubicMeters',
            )}
            maxValue={99.99}
            name='quantity_in_cubic_meters'
            onChange={formikProps.handleChange}
            value={formikProps.values.quantity_in_cubic_meters}
            removeBrowserStyling
            isRequired={
              !formikProps.values.quantity_in_cubic_meters &&
              executionProofNotRejected
            }
            error={
              formikProps.submitCount > 0 &&
              formikProps.touched.quantity_in_cubic_meters
                ? (formikProps.errors.quantity_in_cubic_meters as string)
                : ''
            }
          />
        )}
      </>
    </ReadOnlyField>
  )

  const InternalNote = (
    <ReadOnlyField
      editable={isReview}
      label={I18n.t(
        'documentReviewPageTranslations.executionProofReview.internalNote',
      )}
      value={execution.internal_note}
    >
      <Textarea
        label={I18n.t(
          'documentReviewPageTranslations.executionProofReview.internalNote',
        )}
        maxLength={500}
        name='internal_note'
        onChange={formikProps.handleChange}
        placeholder={I18n.t(
          'documentReviewPageTranslations.executionProofReview.internalNote',
        )}
        showRemainingCharacters
        value={formikProps.values.internal_note}
      />
    </ReadOnlyField>
  )

  return (
    <DetailsSection
      title={I18n.t(
        'documentReviewPageTranslations.executionProofReview.sectionTitle',
      )}
    >
      <div className='execution-proof-review'>
        {/* SELECT IF FRACTION IS EQUAL OR NOT */}
        <ReadOnlyField
          editable={isReview}
          label={I18n.t(
            'documentReviewPageTranslations.executionProofReview.fractionIsEqual.small',
          )}
          value={
            String(execution.fraction_is_equal) ===
            EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.FRACTION_IS_EQUAL
              ? I18n.t('general.yes')
              : I18n.t('general.no')
          }
        >
          <>
            {/* eslint-disable jsx-a11y/label-has-associated-control */}
            {/* eslint-disable jsx-a11y/label-has-for */}
            <label className='read-only-field__label'>
              {I18n.t(
                'documentReviewPageTranslations.executionProofReview.fractionIsEqual.long',
              )}
            </label>
            <div className='execution-proof-review__radio-choices'>
              <RadioButton
                dataTestId='radio-same-fraction-yes'
                isChecked={
                  formikProps.values.fraction_is_equal ===
                  EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.FRACTION_IS_EQUAL
                }
                label={I18n.t('general.yes')}
                name='fraction_is_equal'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    formikProps.resetForm()
                    formikProps.setFieldValue(
                      'fraction_is_equal',
                      EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.FRACTION_IS_EQUAL,
                    )
                    formikProps.setFieldTouched('fraction_is_equal')
                  }
                }}
              />
              <RadioButton
                dataTestId='radio-same-fraction-no'
                isChecked={
                  formikProps.values.fraction_is_equal ===
                  EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.FRACTION_IS_NOT_EQUAL
                }
                label={I18n.t('general.no')}
                name='fraction_is_equal'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    formikProps.resetForm()
                    formikProps.setFieldValue(
                      'fraction_is_equal',
                      EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.FRACTION_IS_NOT_EQUAL,
                    )
                    formikProps.setFieldTouched('fraction_is_equal')
                  }
                }}
              />
            </div>
            {formikProps.errors.fraction_is_equal && (
              <FormFieldErrorMessage
                error={
                  formikProps.submitCount > 0 &&
                  formikProps.touched.fraction_is_equal
                    ? (formikProps.errors.fraction_is_equal as string)
                    : ''
                }
              />
            )}
          </>
        </ReadOnlyField>

        {/* PART 1: IF FRACTION IS EQUAL */}
        {renderCriteria.fraction_is_equal ===
          EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.FRACTION_IS_NOT_EQUAL && (
          <>
            <div
              className={classNames({
                'execution-proof-review__details-group': !isReview,
              })}
            >
              {/* FRACTION */}
              <ReadOnlyField
                editable={isReview}
                label={I18n.t(
                  'documentReviewPageTranslations.executionProofReview.fraction',
                )}
                value={getFractionName(execution.fraction, fractionList, true)}
              >
                <InputChooseItem
                  error={
                    formikProps.submitCount > 0 && formikProps.touched.fraction
                      ? (formikProps.errors.fraction as string)
                      : ''
                  }
                  contentType={CONTENT_TYPES.FRACTION}
                  name='fraction'
                  label={I18n.t(
                    'documentReviewPageTranslations.executionProofReview.fraction',
                  )}
                  placeholder={I18n.t(
                    'documentReviewPageTranslations.executionProofReview.fraction',
                  )}
                  itemList={fractionItems}
                  showSelectionImage
                  title={I18n.t('inquiryForm.addFractionModal.header.title')}
                  handleChange={(value: React.ChangeEvent<any>) => {
                    formikProps.setFieldValue('fraction', String(value))
                    formikProps.setFieldValue('fine_fraction', '')
                    formikProps.setFieldValue('avv', '')
                    formikProps.setFieldTouched('fraction')
                    formikProps.setFieldTouched('fine_fraction')
                    formikProps.setFieldTouched('avv')

                    // also reset price fields
                    formikProps.setFieldValue('rent_price_container_month', '')
                    formikProps.setFieldValue('disposal_cost_container', '')
                    formikProps.setFieldValue('transport_price_piece', '')
                    formikProps.setFieldValue('disposal_cost_ton', '')
                    formikProps.setFieldValue('compensation_ton', '')
                    formikProps.setFieldValue('compensation_container', '')
                    formikProps.setFieldValue('index', '')
                    formikProps.setFieldValue('surcharge', '')
                    formikProps.setFieldValue('discount', '')
                    formikProps.setFieldValue('handle_cost_ton', '')
                    formikProps.setFieldTouched('rent_price_container_month')
                    formikProps.setFieldTouched('disposal_cost_container')
                    formikProps.setFieldTouched('transport_price_piece')
                    formikProps.setFieldTouched('disposal_cost_ton')
                    formikProps.setFieldTouched('index')
                    formikProps.setFieldTouched('surcharge')
                    formikProps.setFieldTouched('discount')
                    formikProps.setFieldTouched('handle_cost_ton')
                    formikProps.setFieldTouched('credit_material')
                  }}
                  value={formikProps.values.fraction}
                  confirmText={I18n.t(
                    'inquiryForm.addFractionModal.details.confirmSelection',
                  )}
                  backToSelectionText={I18n.t(
                    'inquiryForm.addFractionModal.details.backToSelection',
                  )}
                  isRequired={!formikProps.values.fraction}
                />
              </ReadOnlyField>

              {/* FINE FRACTION */}
              {((isReview && formikProps.values.fraction !== '') ||
                !isReview) && (
                <ReadOnlyField
                  editable={isReview}
                  label={I18n.t(
                    'documentReviewPageTranslations.executionProofReview.fineFraction',
                  )}
                  value={getFractionName(execution.fine_fraction, fractionList)}
                >
                  <>
                    {formikProps.values.fraction !== '' &&
                      fineFractionList.length > 0 && (
                        <DropDownInput
                          choices={fineFractionList.map(item => ({
                            optionValue: item.id,
                            optionLabel: item.name,
                          }))}
                          isPlaceholderClickable
                          label={I18n.t(
                            'documentReviewPageTranslations.executionProofReview.fineFraction',
                          )}
                          subLabel={I18n.t(
                            'createInquiryTranslations.form.fractionAndContainerFormGroup.subLabel.fineFraction',
                          )}
                          name='fine_fraction'
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                          placeholder={I18n.t('general.placeholder.empty')}
                          value={formikProps.values.fine_fraction}
                        />
                      )}
                  </>
                </ReadOnlyField>
              )}

              {/* SECURITY GROUPS */}
              {isSecurityRequired(null, fractionList, renderCriteria) &&
                isReview && (
                  <SelectSecurityGroup
                    error={
                      formikProps.submitCount > 0 &&
                      formikProps.touched.security_group
                        ? (formikProps.errors.security_group as string)
                        : ''
                    }
                    selectedSecurityGroup={formikProps.values.security_group}
                    onSelectionChange={(groupId: number | null) =>
                      formikProps.setFieldValue(
                        'security_group',
                        Number(groupId) || '',
                      )
                    }
                    tooltipZIndex={TOOLTIP_ZINDEX.BEFORE_HEADER_BEHIND_MODAL}
                  />
                )}

              {!isReview && execution.security_group && (
                <SelectSecurityGroup
                  selectedSecurityGroup={execution.security_group}
                  renderProtectionClassInput={(
                    { securityGroupList },
                    { selectedProtectionClass },
                  ) => (
                    <ReadOnlyField
                      label={I18n.t(
                        'selectSecurityGroupTranslations.protectionClass',
                      )}
                      value={getSelectedProtectionClassName(
                        securityGroupList,
                        selectedProtectionClass,
                      )}
                    />
                  )}
                  renderSecurityLevelInput={(
                    { securityGroupList },
                    { selectedSecurityLevel },
                  ) => (
                    <ReadOnlyField
                      label={I18n.t(
                        'selectSecurityGroupTranslations.securityLevel',
                      )}
                      value={getSelectedSecurityLevelName(
                        securityGroupList,
                        selectedSecurityLevel,
                      )}
                    />
                  )}
                  onSelectionChange={() => undefined} // ReadOnly view doesn't need a change handler
                />
              )}

              {/* AVV NUMBER */}
              {((isReview && formikProps.values.fraction !== '') ||
                (!isReview && execution.avv)) && (
                <ReadOnlyField
                  editable={isReview}
                  label={I18n.t(
                    'documentReviewPageTranslations.executionProofReview.avv',
                  )}
                  value={getAvvValue(
                    execution.avv,
                    execution.fraction,
                    fractionList,
                  )}
                >
                  <>
                    {formikProps.values.fraction !== '' &&
                      avvList.length > 0 && (
                        <DropDownInput
                          dataTestId='avv-input'
                          choices={avvList.map(item => ({
                            optionLabel: item.number,
                            optionValue: item.id,
                          }))}
                          error={
                            formikProps.submitCount > 0 &&
                            formikProps.touched.avv
                              ? (formikProps.errors.avv as string)
                              : ''
                          }
                          isPlaceholderClickable
                          label={I18n.t(
                            'documentReviewPageTranslations.executionProofReview.avv',
                          )}
                          name='avv'
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                          placeholder={I18n.t('offerForm.avvNumber.selector')}
                          value={formikProps.values.avv}
                          isRequired={!formikProps.values.avv}
                        />
                      )}
                  </>
                </ReadOnlyField>
              )}
            </div>
            <div>
              {/* PRICE DETAILS */}
              {renderCriteria.fraction_is_equal ===
                EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.FRACTION_IS_NOT_EQUAL &&
                (!isReview || (isReview && formikProps.values.avv !== '')) && (
                  <PriceDetailFields
                    {...formikProps}
                    reviewDocument={execution}
                    isEditing={isReview}
                    reviewLayout
                    showErrors
                    showRequiredDot
                    reviewDetailsLayout={!isReview}
                  />
                )}
            </div>
          </>
        )}

        {/* PART 2: ACTUAL REVIEW INFORMATION */}
        {((renderCriteria.fraction_is_equal ===
          EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.FRACTION_IS_NOT_EQUAL &&
          renderCriteria.avv !== '') ||
          renderCriteria.fraction_is_equal ===
            EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.FRACTION_IS_EQUAL) && (
          <>
            {/* WEIGHT DATE */}
            <ReadOnlyField
              editable={isReview}
              label={I18n.t(
                'documentReviewPageTranslations.executionProofReview.weightDate',
              )}
              value={moment(execution.weight_date).format('L')}
            >
              <InputDate
                dataTestId='weight-date-input'
                label={I18n.t(
                  'documentReviewPageTranslations.executionProofReview.weightDate',
                )}
                maxDate={moment()}
                name='weight_date'
                onChange={value => {
                  formikProps.setFieldValue('weight_date', value.format('L'))
                  formikProps.setFieldTouched('weight_date')
                }}
                placeholder={I18n.t(
                  'documentReviewPageTranslations.executionProofReview.weightDate',
                )}
                value={formikProps.values.weight_date}
                isRequired={
                  !formikProps.values.weight_date && executionProofNotRejected
                }
                error={
                  formikProps.submitCount > 0 && formikProps.touched.weight_date
                    ? formikProps.errors.weight_date
                    : ''
                }
              />
            </ReadOnlyField>

            {/* NUMBER OF CONTAINERS (Show here on details) */}
            {!isReview &&
              renderCriteria.weight_object_list.length < 1 &&
              NumberOfContainersOrQuantityInCubicMeters}

            {/* CHOOSE BETWEEN INPUT WEIGHT OF EACH CONTAINER OR JUST TOTAL NUMBER OF CONTAINERS */}
            {isReview && (
              <div
                className='execution-proof-review__radio-choices
                execution-proof-review__radio-choices--margin-regular-top'
              >
                <RadioButton
                  dataTestId='weight-or-number-weight'
                  isChecked={
                    formikProps.values.weight_or_number ===
                    EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.INPUT_WEIGHT
                  }
                  label={I18n.t(
                    'documentReviewPageTranslations.executionProofReview.chooseWeight',
                  )}
                  name='weight_or_number'
                  onChange={(event: React.ChangeEvent<any>) => {
                    if (event.target.value === 'on') {
                      formikProps.setFieldValue('weight_object_list', [
                        { weight_receipt: '', weight: '' },
                      ])
                      formikProps.setFieldValue('number_of_containers', '')
                      formikProps.setFieldValue(
                        'weight_or_number',
                        EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.INPUT_WEIGHT,
                      )
                      formikProps.setFieldTouched('weight_or_number')
                    }
                  }}
                />
                <RadioButton
                  dataTestId='weight-or-number-number'
                  isChecked={
                    formikProps.values.weight_or_number ===
                    EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.INPUT_NUMBER_OF_CONTAINERS
                  }
                  label={I18n.t(
                    'documentReviewPageTranslations.executionProofReview.chooseNumber',
                  )}
                  name='weight_or_number'
                  onChange={(event: React.ChangeEvent<any>) => {
                    if (event.target.value === 'on') {
                      formikProps.setFieldValue(
                        'weight_or_number',
                        EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.INPUT_NUMBER_OF_CONTAINERS,
                      )
                      formikProps.setFieldTouched('weight_or_number')
                    }
                  }}
                />
              </div>
            )}

            {/* WEIGHTS */}
            {/* OPTION 1: ADD CONTAINER WEIGHTS */}
            {((isReview &&
              renderCriteria.weight_or_number ===
                EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.INPUT_WEIGHT) ||
              (!isReview && renderCriteria.weight_object_list.length > 0)) && (
              <FieldArray
                name='weight_object_list'
                render={({ push, pop }) => (
                  <>
                    {weightList.map((weight, index) => (
                      <div
                        className={classNames(
                          { 'execution-proof-review--side-by-side': isReview },
                          {
                            'execution-proof-review__weights-group': !isReview,
                          },
                        )}
                        key={String(index)}
                      >
                        {/* WEIGHT RECEIPT NUMBER */}
                        <ReadOnlyField
                          editable={isReview}
                          label={I18n.t(
                            'documentReviewPageTranslations.executionProofReview.weightReceipt',
                            { number: index + 1 },
                          )}
                          value={weight.weight_receipt}
                        >
                          <>
                            {isReview && (
                              <InputText
                                dataTestId={`weight-receipt-input-${index + 1}`}
                                isRequired={
                                  !formikProps.values.weight_object_list[index]
                                    .weight_receipt && executionProofNotRejected
                                }
                                label={I18n.t(
                                  'documentReviewPageTranslations.executionProofReview.weightReceipt',
                                  { number: index + 1 },
                                )}
                                maxLength={20}
                                name={`weight_object_list.${index}.weight_receipt`}
                                onBlur={formikProps.handleBlur}
                                onChange={formikProps.handleChange}
                                placeholder={I18n.t(
                                  'documentReviewPageTranslations.executionProofReview.weightReceipt',
                                  { number: index + 1 },
                                )}
                                value={
                                  formikProps.values.weight_object_list[index]
                                    .weight_receipt
                                }
                                error={
                                  formikProps.submitCount > 0 &&
                                  formikProps.values.weight_object_list[index]
                                    .weight_receipt === '' &&
                                  !!formikProps.errors.weight_receipt
                                    ? (formikProps.errors
                                        .weight_receipt as string)
                                    : ''
                                }
                              />
                            )}
                          </>
                        </ReadOnlyField>

                        {/* WEIGHT */}
                        <ReadOnlyField
                          editable={isReview}
                          label={I18n.t(
                            'documentReviewPageTranslations.executionProofReview.weight',
                            { number: index + 1 },
                          )}
                          value={weight.weight}
                        >
                          <>
                            {isReview && (
                              <InputDecimal
                                dataTestId={`weight-input-${index + 1}`}
                                isRequired={
                                  !formikProps.values.weight_object_list[index]
                                    .weight && executionProofNotRejected
                                }
                                label={I18n.t(
                                  'documentReviewPageTranslations.executionProofReview.weight',
                                  { number: index + 1 },
                                )}
                                placeholder={I18n.t(
                                  'documentReviewPageTranslations.executionProofReview.weight',
                                  { number: index + 1 },
                                )}
                                maxValue={9999.99}
                                name={`weight_object_list.${index}.weight`}
                                onChange={formikProps.handleChange}
                                value={
                                  formikProps.values.weight_object_list[index]
                                    .weight
                                }
                                removeBrowserStyling
                                error={
                                  formikProps.submitCount > 0 &&
                                  formikProps.values.weight_object_list[index]
                                    .weight === '' &&
                                  !!formikProps.errors.weight
                                    ? (formikProps.errors.weight as string)
                                    : ''
                                }
                              />
                            )}
                          </>
                        </ReadOnlyField>
                      </div>
                    ))}

                    {/* BUTTONS TO ADD OR REMOVE CONTAINER WEIGHTS */}
                    {isReview && (
                      <div
                        className={classNames(
                          'execution-proof-review--side-by-side-buttons',
                          {
                            'execution-proof-review--side-by-side-buttons-single-button':
                              !showRemoveWeightFieldButton,
                          },
                        )}
                      >
                        <div>
                          <IconButton
                            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                            iconName='plus'
                            iconPosition={ICON_POSITION.RIGHT}
                            onClick={() =>
                              push({ weight_receipt: '', weight: '' })
                            }
                          >
                            {I18n.t(
                              'documentReviewPageTranslations.executionProofReview.button.addMoreContainerWeight',
                            )}
                          </IconButton>
                        </div>
                        {showRemoveWeightFieldButton && (
                          <div>
                            <IconButton
                              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                              iconName='trash'
                              iconPosition={ICON_POSITION.RIGHT}
                              onClick={() => pop()}
                            >
                              {I18n.t(
                                'documentReviewPageTranslations.executionProofReview.button.removeLastContainerWeight',
                              )}
                            </IconButton>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              />
            )}

            {/* NUMBER OF CONTAINERS (Show here in form) */}
            {isReview &&
              renderCriteria.weight_or_number ===
                EXECUTION_PROOF_FORM_SELECTABLE_OPTIONS.INPUT_NUMBER_OF_CONTAINERS &&
              NumberOfContainersOrQuantityInCubicMeters}

            {/* INTERNAL NOTE (show here if review form) */}
            {isReview && InternalNote}

            <div
              className={classNames({
                'execution-proof-review__details-group': !isReview,
              })}
            >
              {/* STATUS */}
              <ReadOnlyField
                editable={isReview}
                label={I18n.t(
                  'documentReviewPageTranslations.executionProofReview.status',
                )}
                value={
                  !isReview
                    ? I18n.t(getName(execution.status, EXECUTION_PROOF_STATES))
                    : undefined
                }
              >
                <DropDownInput
                  dataTestId='status-select'
                  choices={EXECUTION_PROOF_STATES.map(item => ({
                    optionValue: item.id,
                    optionLabel: I18n.t(item.name),
                  }))}
                  label={I18n.t(
                    'documentReviewPageTranslations.executionProofReview.status',
                  )}
                  name='status'
                  onBlur={formikProps.handleBlur}
                  onChange={formikProps.handleChange}
                  placeholder={I18n.t(
                    'documentReviewPageTranslations.executionProofReview.status',
                  )}
                  value={formikProps.values.status}
                  isRequired={
                    Number(formikProps.values.status) ===
                    EXECUTION_PROOF_STATUS.STATUS_PENDING
                  }
                  error={
                    formikProps.submitCount > 0 && formikProps.touched.status
                      ? (formikProps.errors.status as string)
                      : ''
                  }
                />
              </ReadOnlyField>
            </div>
            <div
              className={classNames({
                'execution-proof-review__review-message-group': !isReview,
              })}
            >
              {/* REJECT REASON */}
              {Number(renderCriteria.status) ===
                EXECUTION_PROOF_STATUS.STATUS_REJECTED && (
                <ReadOnlyField
                  editable={isReview}
                  label={I18n.t(
                    'documentReviewPageTranslations.executionProofReview.rejectReason.label',
                  )}
                  value={execution.reject_reason}
                >
                  <Textarea
                    error={
                      formikProps.submitCount > 0 &&
                      formikProps.touched.reject_reason
                        ? (formikProps.errors.reject_reason as string)
                        : ''
                    }
                    isRequired={!formikProps.values.reject_reason}
                    label={I18n.t(
                      'documentReviewPageTranslations.executionProofReview.rejectReason.label',
                    )}
                    placeholder={I18n.t(
                      'documentReviewPageTranslations.executionProofReview.rejectReason.placeholder',
                    )}
                    maxLength={500}
                    name='reject_reason'
                    onChange={formikProps.handleChange}
                    showRemainingCharacters
                    value={formikProps.values.reject_reason}
                  />
                </ReadOnlyField>
              )}

              {/* INTERNAL NOTE (show here if just details) */}
              {!isReview && renderCriteria.internal_note && InternalNote}
            </div>
            <div
              className={classNames({
                'execution-proof-review__details-group': !isReview,
              })}
            >
              {/* REVIEWED BY */}
              {!isReview && (
                <ReadOnlyField
                  label={I18n.t(
                    'documentReviewPageTranslations.executionProofReview.reviewedBy',
                  )}
                  value={execution.reviewed_by_name}
                />
              )}

              {/* REVIEWED AT */}
              {!isReview && (
                <ReadOnlyField
                  label={I18n.t(
                    'documentReviewPageTranslations.executionProofReview.reviewedAt',
                  )}
                  value={moment(execution.reviewed_at).format('L')}
                />
              )}
            </div>
          </>
        )}
      </div>
    </DetailsSection>
  )
}
