export const OrderManagementFormTranslations = {
  confirm: 'Auftrag bestätigen',
  cancel: {
    buttonText: 'Stornierung bestätigen',
    placeholder: 'Grund der Stornierung auswählen',
    options: {
      noContainer: 'Kein Behälter vorhanden',
      vehicleDefective: 'Fahrzeug defekt',
      facilityAtCapacity: 'Anlage ausgelastet',
      badCustomerExperience: 'Schlechte Erfahrungen mit dem Kunden',
      other: 'Sonstiges',
    },
    optionOtherPlaceholder: 'Grund für sonstiges eingeben (optional)',
  },
  postpone: {
    title: 'Liefertermin verschieben?',
    text: 'Rufen Sie uns an!',
    hotline: 'Kostenlose Hotline 0800 638 25 53',
  },
  title: 'Auftrag verwalten',
  orderDetails: {
    title: 'Ihr neuer Auftrag',
    id: 'Auftrags-Nr.',
    customer: 'Kunde',
    street: 'Straße',
    plzAndTown: 'PLZ und Ort',
    container: 'Behälter',
    amount: 'Anzahl',
    fraction: 'Abfallart',
    fineFraction: 'Feinfraktion',
    delivery: 'Stellung',
    location: 'Stellplatz',
    approval: 'Stellgenehmigung',
    approvalText: 'Bitte beantragen',
  },
  form: {
    confirm: 'Bestätigen',
    cancel: 'Stornieren',
    postpone: 'Verschieben',
  },
}
