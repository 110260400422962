import keyMirror from 'keymirror'

const CHANGE_PASSWORD = keyMirror({
  CHANGE_PASSWORD_REQUEST: null,
  CHANGE_PASSWORD_SUCCESS: null,
  CHANGE_PASSWORD_ERROR: null,
})

const ACTIVATE_CURRENT_USER = keyMirror({
  ACTIVATE_CURRENT_USER_REQUEST: null,
  ACTIVATE_CURRENT_USER_SUCCESS: null,
  ACTIVATE_CURRENT_USER_ERROR: null,
})

const EXPORT_USERS = keyMirror({
  EXPORT_USERS_REQUEST: null,
  EXPORT_USERS_SUCCESS: null,
  EXPORT_USERS_ERROR: null,
})

const GET_COMPANY_USERS = keyMirror({
  GET_COMPANY_USERS_REQUEST: null,
  GET_COMPANY_USERS_SUCCESS: null,
  GET_COMPANY_USERS_ERROR: null,
})

const GET_COMBINED_OFFER_EPD_USERS_FOR_TVP = keyMirror({
  GET_COMBINED_OFFER_EPD_USERS_FOR_TVP_REQUEST: null,
  GET_COMBINED_OFFER_EPD_USERS_FOR_TVP_SUCCESS: null,
  GET_COMBINED_OFFER_EPD_USERS_FOR_TVP_ERROR: null,
})

const GET_CURRENT_USER = keyMirror({
  GET_CURRENT_USER_REQUEST: null,
  GET_CURRENT_USER_SUCCESS: null,
  GET_CURRENT_USER_ERROR: null,
})

const GET_USER = keyMirror({
  GET_USER_REQUEST: null,
  GET_USER_SUCCESS: null,
  GET_USER_ERROR: null,
})

const GET_MY_USERS = keyMirror({
  GET_MY_USERS_REQUEST: null,
  GET_MY_USERS_SUCCESS: null,
  GET_MY_USERS_ERROR: null,
})

const INVITE_USER = keyMirror({
  INVITE_USER_REQUEST: null,
  INVITE_USER_SUCCESS: null,
  INVITE_USER_ERROR: null,
  INVITE_USER_EMAIL_EXISTS: null,
})

const LOGIN = keyMirror({
  LOGIN_REQUEST: null,
  LOGIN_SUCCESS: null,
  LOGIN_ERROR: null,
})

const LOGOUT = keyMirror({
  LOGOUT_REQUEST: null,
  LOGOUT_SUCCESS: null,
  LOGOUT_ERROR: null,
})

const PASSWORD_RESET = keyMirror({
  PASSWORD_RESET_REQUEST: null,
  PASSWORD_RESET_SUCCESS: null,
  PASSWORD_RESET_ERROR: null,
})

const PASSWORD_RESET_CONFIRM = keyMirror({
  PASSWORD_RESET_CONFIRM_REQUEST: null,
  PASSWORD_RESET_CONFIRM_SUCCESS: null,
  PASSWORD_RESET_CONFIRM_ERROR: null,
})

const REGISTER_USER = keyMirror({
  REGISTER_USER_REQUEST: null,
  REGISTER_USER_SUCCESS: null,
  REGISTER_USER_ERROR: null,
  REGISTER_USER_EMAIL_EXISTS: null,
})

const SET_PASSWORD = keyMirror({
  SET_PASSWORD_REQUEST: null,
  SET_PASSWORD_SUCCESS: null,
  SET_PASSWORD_ERROR: null,
})

const UPDATE_CURRENT_USER = keyMirror({
  UPDATE_CURRENT_USER_REQUEST: null,
  UPDATE_CURRENT_USER_SUCCESS: null,
  UPDATE_CURRENT_USER_ERROR: null,
})

const UPDATE_USER = keyMirror({
  UPDATE_USER_REQUEST: null,
  UPDATE_USER_SUCCESS: null,
  UPDATE_USER_ERROR: null,
})

const GET_USERS_BY_COMPANY = keyMirror({
  GET_USERS_BY_COMPANY_REQUEST: null,
  GET_USERS_BY_COMPANY_SUCCESS: null,
  GET_USERS_BY_COMPANY_ERROR: null,
})

const SET_USER_IS_CONTACT_PERSON = keyMirror({
  SET_USER_IS_CONTACT_PERSON: null,
})

const RESET_USER_IS_CONTACT_PERSON = keyMirror({
  RESET_USER_IS_CONTACT_PERSON: null,
})

const RESET_USER = keyMirror({
  RESET_USER: null,
})

export default {
  ...ACTIVATE_CURRENT_USER,
  ...CHANGE_PASSWORD,
  ...EXPORT_USERS,
  ...GET_COMPANY_USERS,
  ...GET_CURRENT_USER,
  ...GET_MY_USERS,
  ...GET_USER,
  ...INVITE_USER,
  ...LOGIN,
  ...LOGOUT,
  ...PASSWORD_RESET,
  ...PASSWORD_RESET_CONFIRM,
  ...REGISTER_USER,
  ...SET_PASSWORD,
  ...UPDATE_CURRENT_USER,
  ...UPDATE_USER,
  ...GET_USERS_BY_COMPANY,
  ...SET_USER_IS_CONTACT_PERSON,
  ...RESET_USER_IS_CONTACT_PERSON,
  ...RESET_USER,
  ...GET_COMBINED_OFFER_EPD_USERS_FOR_TVP,
}
