import React, { FC, useEffect } from 'react'

import { addBackgroundGradient } from 'effects'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'

import { BenefitComparison } from './BenefitComparison'
import { CallToActionFooter } from './CallToActionFooter'
import { connector } from './connector'
import { ExplanationSection } from './ExplanationSection/ExplanationSection'
import { WastemanagerBenefitsSection } from './WastemanagerBenefitsSection'
import { WastemanagerHeroSection } from './WastemanagerHeroSection'

export interface LandingPageContent {
  id: number
  title: string
  content: string
  type: WasteManagerLandingPageType
}

export interface HomepageWastemanagerPropsType {
  getLandingPageContent: () => void
  landingPageContent: LandingPageContent[]
}

/**
 * These are the landing page content types.
 */
export enum WasteManagerLandingPageType {
  Wastemanager = 1,
  ExternalAgreement = 2,
  EmptoAgreement = 3,
}

const HomepageWastemanagerComponent: FC<HomepageWastemanagerPropsType> = ({
  getLandingPageContent,
  landingPageContent,
}) => {
  useEffect(() => addBackgroundGradient(), [])
  useEffect(getLandingPageContent, [getLandingPageContent])

  return (
    <main>
      <WastemanagerHeroSection />
      <WastemanagerBenefitsSection />
      {landingPageContent && (
        <ExplanationSection sections={landingPageContent} />
      )}
      <BenefitComparison />
      <CallToActionFooter />
    </main>
  )
}

export const HomepageWastemanager = connector(
  withApiErrorHandling(HomepageWastemanagerComponent),
)
