import ActionTypes from '../actiontypes/container'

/** @function
 * @name getContainers
 * @description Retrieves a list of containers from the API.
 * @return {Object}
 */
export const getContainers = () => ({
  type: ActionTypes.GET_CONTAINERS_REQUEST,
})

/** @function
 * @name getContainerContent
 * @description An action method to get the content from fractions.
 * @return {Object}
 */
export const getContainerContent = () => ({
  type: ActionTypes.GET_CONTAINER_CONTENT_REQUEST,
})

/**
 * @function
 * @name getContainerForFilter
 * @description An action method to get a list of all Container serialized for filter usage
 * @return {Object}
 */
export const getContainerForFilter = filters => ({
  type: ActionTypes.GET_CONTAINER_FOR_FILTER_REQUEST,
  payload: {
    ...filters,
  },
})

export default {}
