import React, { createContext, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'

import { ActivityTable } from './components/ActivityTable'
import { CompanyTable } from './components/CompanyTable'
import { Filter } from './components/Filter'
import { Header } from './components/Header'
import { Modals } from './components/Modals'

export type CollectiveInvoicingPageContextType = {
  currentFilters: any
  setCurrentFilters: (any) => void
  currentActivityFilters: any
  setCurrentActivityFilters: (any) => void
  isCollectiveInvoicingForecastModalOpen: boolean
  setIsCollectiveInvoicingForecastModalOpen: (any) => void
  isCollectiveInvoicingModalOpen: boolean
  setIsCollectiveInvoicingModalOpen: (any) => void
  isCollectiveInvoicingActivitiesModalOpen: boolean
  setIsCollectiveInvoicingActivitiesModalOpen: (any) => void
  isTotalInvoiceModalOpen: boolean
  setIsTotalInvoiceModalOpen: (any) => void
  companyIdToInvoice: number
  setCompanyIdToInvoice: (any) => void
}

export const CollectiveInvoicingPageContext =
  createContext<CollectiveInvoicingPageContextType>({
    currentFilters: undefined,
    setCurrentFilters: () => undefined,
    currentActivityFilters: undefined,
    setCurrentActivityFilters: () => undefined,
    isCollectiveInvoicingForecastModalOpen: false,
    setIsCollectiveInvoicingForecastModalOpen: () => undefined,
    isCollectiveInvoicingModalOpen: false,
    setIsCollectiveInvoicingModalOpen: () => undefined,
    isCollectiveInvoicingActivitiesModalOpen: false,
    setIsCollectiveInvoicingActivitiesModalOpen: () => undefined,
    isTotalInvoiceModalOpen: false,
    setIsTotalInvoiceModalOpen: () => undefined,
    companyIdToInvoice: 0,
    setCompanyIdToInvoice: () => undefined,
  })

/**
 * @description This component displays a list of Company
 * and it's last state of collective invoice including the filter,
 * loading indicator etc.
 * @function
 */
export const CollectiveInvoicingPage = () => {
  const [currentFilters, setCurrentFilters] = useState<any>({})
  const [currentActivityFilters, setCurrentActivityFilters] = useState<any>({})
  const [
    isCollectiveInvoicingForecastModalOpen,
    setIsCollectiveInvoicingForecastModalOpen,
  ] = useState(false)
  const [isCollectiveInvoicingModalOpen, setIsCollectiveInvoicingModalOpen] =
    useState(false)
  const [
    isCollectiveInvoicingActivitiesModalOpen,
    setIsCollectiveInvoicingActivitiesModalOpen,
  ] = useState(false)
  const [isTotalInvoiceModalOpen, setIsTotalInvoiceModalOpen] = useState(false)
  const [companyIdToInvoice, setCompanyIdToInvoice] = useState(0)

  return (
    <CollectiveInvoicingPageContext.Provider
      value={{
        currentFilters: currentFilters,
        setCurrentFilters: setCurrentFilters,
        currentActivityFilters: currentActivityFilters,
        setCurrentActivityFilters: setCurrentActivityFilters,
        isCollectiveInvoicingForecastModalOpen,
        setIsCollectiveInvoicingForecastModalOpen,
        isCollectiveInvoicingModalOpen,
        setIsCollectiveInvoicingModalOpen,
        isCollectiveInvoicingActivitiesModalOpen,
        setIsCollectiveInvoicingActivitiesModalOpen,
        isTotalInvoiceModalOpen,
        setIsTotalInvoiceModalOpen,
        companyIdToInvoice,
        setCompanyIdToInvoice,
      }}
    >
      <Helmet>
        <title>{I18n.t('pageTitles.collectiveInvoicing')}</title>
      </Helmet>
      <Header />
      <Filter />
      <CompanyTable />
      <ActivityTable />
      <Modals />
    </CollectiveInvoicingPageContext.Provider>
  )
}
