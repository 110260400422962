import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { ReactComponent as Graph } from 'assets/svg/detailspage/graph.svg'
import AccordionItem from 'components/common/Accordion/components/AccordionItem'

import { PriceList } from './PriceList'

interface PriceSectionProps {
  addScrollElement: string
  offer: Offer
  indexList: OfferIndex[]
}

export const PriceSection: FC<PriceSectionProps> = ({
  addScrollElement,
  offer,
  indexList,
}) => {
  const iconTitle = (
    <>
      <Graph className='inquiry-details-page__section-header' />
      <Translate value='agreementDetailsTranslations.section.price.title' />
    </>
  )

  const priceModelNames = {
    disposalFlatrate: I18n.t(
      'agreementDetailsTranslations.priceModels.disposalFlatrate',
    ),
    disposalSingle: I18n.t(
      'agreementDetailsTranslations.priceModels.disposalSingle',
    ),
    disposalContainer: I18n.t(
      'agreementDetailsTranslations.priceModels.disposalContainer',
    ),
    compensationContainer: I18n.t(
      'agreementDetailsTranslations.priceModels.compensationContainer',
    ),
    compensationSingle: I18n.t(
      'agreementDetailsTranslations.priceModels.compensationSingle',
    ),
  }

  const customerPriceModelTitle = `${I18n.t(
    'agreementDetailsTranslations.section.customer.title',
  )}: ${priceModelNames[offer.price_model]}`

  const partnerPriceModelTitle = `${I18n.t(
    'agreementDetailsTranslations.section.partner.title',
  )}: ${priceModelNames[offer.disposer_prices?.price_model]}`

  const isMaklerpremium = !!offer.customer_company_object.makler_premium_company

  return (
    <AccordionItem
      addScrollElement={addScrollElement}
      title={iconTitle}
      key='priceFields'
    >
      <h4 className='uk-text-bold uk-margin-medium-top'>
        {customerPriceModelTitle}
      </h4>
      <div
        className='inquiry-details-page__section uk-child-width-1-2@m'
        data-uk-grid=''
      >
        <PriceList
          indexList={indexList}
          pricesObject={offer}
          isMaklerpremium={isMaklerpremium}
          key='customerPriceFields'
        ></PriceList>
      </div>

      {offer.disposer_prices ? (
        <>
          <h4 className='uk-text-bold uk-margin-medium-top'>
            {partnerPriceModelTitle}
          </h4>
          <div
            className='inquiry-details-page__section uk-child-width-1-2@m'
            data-uk-grid=''
          >
            <PriceList
              indexList={indexList}
              pricesObject={offer.disposer_prices}
              isMaklerpremium={isMaklerpremium}
              key='partnerPriceFields'
            ></PriceList>
          </div>
        </>
      ) : undefined}
    </AccordionItem>
  )
}
