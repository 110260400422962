import React, { createContext, FC, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import { ReactComponent as GearGradient } from 'assets/svg/detailspage/gear_gradient.svg'
import { ReactComponent as Handshake } from 'assets/svg/detailspage/handshake.svg'
import { ReactComponent as MoneyGradient } from 'assets/svg/detailspage/money-gradient.svg'
import withErrorBoundary from 'helper/withErrorBoundary'
import { getComCenterContactsSelector } from 'selectors/company'

import { NotFoundComponent } from '../../common/RoutesHandler/components/NotFoundComponent'

import { ActivitiesTable } from './components/ActivitiesTable'
import { ComCenterContactModal } from './components/ComCenterContactModal'
import { DeleteComCenterContactModal } from './components/DeleteComCenterContactModal'
import { Header } from './components/Header'
import { InteractiveTable } from './components/InteractiveTable'
import { InteractiveTableBody } from './components/InteractiveTableBody'
import { Sections } from './components/Sections'
import { CATEGORY_CHOICES, COMMUNICATION_CENTER_MODALS } from './constants'
import { mapContact } from './helper'
import { useComCenterContacts } from './hooks/useComCenterContacts'
import { useComCenterContactsFilter } from './hooks/useComCenterContactsFilter'
import { useComCenterLog } from './hooks/useComCenterLog'
import { useCompany } from './hooks/useCompany'
import { useIsMaklerPremiumCustomer } from './hooks/useIsMaklerPremiumCustomer'
import { OperationsStage2Columns } from './OperationsStage2Columns'
import { OperationsStage3Columns } from './OperationsStage3Columns'
import { CommunicationCenterPageContextType } from './types'

export const CommunicationCenterPageContext =
  createContext<CommunicationCenterPageContextType>({
    onEditButtonClick: () => undefined,
    onDeleteButtonClick: () => undefined,
    selectedContact: undefined,
  })

const CommunicationCenterPageComponent: FC<
  RouteComponentProps<{ slug: string; companyId: string }>
> = ({
  match: {
    params: { slug, companyId },
  },
}) => {
  useCompany({ companyId, slug })
  useComCenterContacts({ companyId })
  const isMaklerPremiumCustomer = useIsMaklerPremiumCustomer()

  const comCenterContacts = useSelector(getComCenterContactsSelector)
  const {
    emailDataBookkeeping,
    emailDataSales,
    emailDataOperationsStage1,
    emailDataOperationsStage2,
    emailDataOperationsStage3,
  } = useComCenterContactsFilter()

  const comCenterLog = useComCenterLog({ companyId })

  // State
  const [openModal, setOpenModal] = useState<string | boolean>(false)
  const [comCenterContact, setComCenterContact] = useState<
    ComCenterContact | undefined
  >()
  const [selectedContactId, setSelectedContactId] = useState<
    number | undefined
  >(undefined)
  const [selectedComCenterType, setSelectedComCenterType] = useState<
    number | undefined
  >(undefined)

  const emailDataOperationsIsEmpty =
    (!emailDataOperationsStage1 || emailDataOperationsStage1.length === 0) &&
    (!emailDataOperationsStage2 || emailDataOperationsStage2.length === 0) &&
    (!emailDataOperationsStage3 || emailDataOperationsStage3.length === 0)

  const getSelectedContact = () => {
    if (!comCenterContacts) {
      return undefined
    }

    return comCenterContacts
      .filter(contact => contact.id === selectedContactId)
      .map(mapContact)[0]
  }

  const handleEditButtonClick = (contactId: number) => {
    if (comCenterContacts) {
      setComCenterContact(
        comCenterContacts.find(contact => {
          return contact?.id === contactId
        }),
      )
    }
    setOpenModal(COMMUNICATION_CENTER_MODALS.EDIT_ENTRY_MODAL)
  }

  const handleDeleteButtonClick = (contactId: number) => {
    setSelectedContactId(contactId)
    setOpenModal(COMMUNICATION_CENTER_MODALS.DELETE_ENTRY_MODAL)
  }

  if (isMaklerPremiumCustomer) {
    return <NotFoundComponent />
  }

  return (
    <CommunicationCenterPageContext.Provider
      value={{
        onEditButtonClick: handleEditButtonClick,
        onDeleteButtonClick: handleDeleteButtonClick,
        selectedContact: getSelectedContact(),
      }}
    >
      <Header
        onAddNewEntryButtonClick={() => {
          setSelectedComCenterType(undefined)
          setComCenterContact(undefined)
          setOpenModal(COMMUNICATION_CENTER_MODALS.NEW_ENTRY_ALL_LEVELS_MODAL)
        }}
      />
      <Sections
        bookkeepingComponent={
          <InteractiveTable
            title={
              <Translate value='communicationCenterPageTranslations.section.bookkeeping.title' />
            }
            icon={<MoneyGradient />}
            buttonOnClick={() => {
              setSelectedComCenterType(CATEGORY_CHOICES.CHOICE_BOOKKEEPING)
              setOpenModal(
                COMMUNICATION_CENTER_MODALS.NEW_ENTRY_ALL_LEVELS_MODAL,
              )
            }}
          >
            {emailDataBookkeeping && emailDataBookkeeping.length > 0 && (
              <InteractiveTableBody data={emailDataBookkeeping} />
            )}
          </InteractiveTable>
        }
        salesComponent={
          <InteractiveTable
            title={
              <Translate value='communicationCenterPageTranslations.section.sales.title' />
            }
            icon={<Handshake />}
            buttonOnClick={() => {
              setSelectedComCenterType(CATEGORY_CHOICES.CHOICE_SALES)
              setOpenModal(
                COMMUNICATION_CENTER_MODALS.NEW_ENTRY_ALL_LEVELS_MODAL,
              )
            }}
          >
            {emailDataSales && emailDataSales.length > 0 && (
              <InteractiveTableBody data={emailDataSales} />
            )}
          </InteractiveTable>
        }
        operationsComponent={
          <InteractiveTable
            title={
              <Translate value='communicationCenterPageTranslations.section.operations.title' />
            }
            icon={<GearGradient />}
            buttonOnClick={() => {
              setSelectedComCenterType(CATEGORY_CHOICES.CHOICE_OPERATIONS)
              setOpenModal(
                COMMUNICATION_CENTER_MODALS.NEW_ENTRY_ALL_LEVELS_MODAL,
              )
            }}
          >
            {!emailDataOperationsIsEmpty && (
              <>
                <InteractiveTableBody
                  data={emailDataOperationsStage1}
                  title={I18n.t(
                    'communicationCenterPageTranslations.operations_level_1_title',
                  )}
                  id='operations_1'
                />
                <InteractiveTableBody
                  data={emailDataOperationsStage2}
                  title={I18n.t(
                    'communicationCenterPageTranslations.operations_level_2_title',
                  )}
                  notice={I18n.t(
                    'communicationCenterPageTranslations.operations_level_2_notice',
                  )}
                  id='operations_2'
                  columns={OperationsStage2Columns}
                />
                <InteractiveTableBody
                  data={emailDataOperationsStage3}
                  title={I18n.t(
                    'communicationCenterPageTranslations.operations_level_3_title',
                  )}
                  notice={I18n.t(
                    'communicationCenterPageTranslations.operations_level_3_notice',
                  )}
                  id='operations_3'
                  columns={OperationsStage3Columns}
                />
              </>
            )}
          </InteractiveTable>
        }
        activitiesComponent={<ActivitiesTable data={comCenterLog} />}
      />
      <DeleteComCenterContactModal
        onCloseModal={() => setOpenModal(false)}
        isOpen={openModal === COMMUNICATION_CENTER_MODALS.DELETE_ENTRY_MODAL}
      />
      <ComCenterContactModal
        onCloseModal={() => setOpenModal(false)}
        isOpen={
          openModal === COMMUNICATION_CENTER_MODALS.NEW_ENTRY_ALL_LEVELS_MODAL
        }
        category={selectedComCenterType}
      />
      <ComCenterContactModal
        onCloseModal={() => {
          setOpenModal(false)
        }}
        isOpen={openModal === COMMUNICATION_CENTER_MODALS.EDIT_ENTRY_MODAL}
        comCenterContact={comCenterContact}
      />
    </CommunicationCenterPageContext.Provider>
  )
}

export const CommunicationCenterPage = withErrorBoundary(
  CommunicationCenterPageComponent,
)
