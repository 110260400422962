import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import Media from 'react-media'

import EmptoMarketplaceYoutubeImage from 'assets/img/homepage/empto-marktplatz-abfallentsorger-youtube.png'
import InquiriesInYourAreaImage from 'assets/img/homepage/inquiries-in-your-area.png'
import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import { LinkButton } from 'components/common/LinkButton'
import Paragraph from 'components/common/Paragraph'
import { COMPANY_ROLE } from 'components/company/constants'
import { BREAKPOINT } from 'constants/design'

import { ComputerFrame } from './components/ComputerFrame'

const VIDEO_URL =
  'https://www.youtube.com/embed/giY8Bz2omwI?rel=0&amp;showinfo=0'

interface WhatIsEmptoSectionProps {
  showWasteDisposerContent?: boolean
  showWasteProducerContent?: boolean
}

export const WhatIsEmptoSection: FC<WhatIsEmptoSectionProps> = ({
  showWasteProducerContent = false,
  showWasteDisposerContent = false,
}) => {
  const renderRegisterButtonAndHeadline = (centerButton = false) => (
    <>
      <Headline
        fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
        fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
        headlineStyle={HEADLINE_STYLE.H5}
        tag={HEADLINE_TAG.H4}
      >
        {showWasteProducerContent && (
          <Translate value='homepages.wasteProducer.whatIsEmpto.registerText' />
        )}
        {showWasteDisposerContent && (
          <Translate value='homepages.wasteDisposer.whatIsEmpto.registerText' />
        )}
      </Headline>

      <div
        className={classNames({
          'what-is-empto-section__description-center-button': centerButton,
        })}
      >
        <LinkButton
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          target={{
            pathname: '/registrieren',
            state: {
              initialSelection: showWasteProducerContent
                ? COMPANY_ROLE.WASTE_PRODUCER
                : COMPANY_ROLE.WASTE_COMPANY,
            },
          }}
        >
          <Translate value='homepages.wasteProducer.whatIsEmpto.button' />
        </LinkButton>
      </div>
    </>
  )

  return (
    <section className='what-is-empto-section__content'>
      <div>
        <Headline
          color={HEADLINE_COLOR.DARK_GRAY}
          fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
          fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
          headlineStyle={HEADLINE_STYLE.H2}
          tag={HEADLINE_TAG.H2}
        >
          {showWasteProducerContent && (
            <Translate value='homepages.wasteProducer.whatIsEmpto.headline' />
          )}
          {showWasteDisposerContent && (
            <Translate value='homepages.wasteDisposer.whatIsEmpto.headline' />
          )}
        </Headline>
        <Headline
          color={HEADLINE_COLOR.BLUE}
          fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
          fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
          headlineStyle={HEADLINE_STYLE.H4}
          tag={HEADLINE_TAG.H3}
        >
          {showWasteProducerContent && (
            <Translate value='homepages.wasteProducer.whatIsEmpto.subline' />
          )}
        </Headline>

        <div>
          <div className='what-is-empto-section__description'>
            <Paragraph>
              {showWasteProducerContent && (
                <Translate value='homepages.wasteProducer.whatIsEmpto.text' />
              )}
              {showWasteDisposerContent && (
                <Translate value='homepages.wasteDisposer.whatIsEmpto.text' />
              )}
            </Paragraph>

            {/* REGISTER (disabled in #3228) */}
            {/*<Media query={{ minWidth: BREAKPOINT.LARGE }}>*/}
            {/*  {isBigScreen => isBigScreen && renderRegisterButtonAndHeadline()}*/}
            {/*</Media>*/}
          </div>
        </div>
      </div>
      <div>
        <ComputerFrame
          showImage={showWasteDisposerContent}
          imageUrl={InquiriesInYourAreaImage}
          showYouTubeVideo={showWasteProducerContent}
          videoPoster={EmptoMarketplaceYoutubeImage}
          videoPosterAlt={I18n.t(
            'homepages.wasteProducer.whatIsEmpto.videoPosterAlt',
          )}
          videoTitle={I18n.t(
            'homepages.wasteProducer.whatIsEmpto.videoDescription',
          )}
          videoUrl={VIDEO_URL}
        />
      </div>
      <div>
        <Media query={{ minWidth: BREAKPOINT.LARGE }}>
          {isBigScreen =>
            !isBigScreen && renderRegisterButtonAndHeadline(!isBigScreen)
          }
        </Media>
      </div>
    </section>
  )
}
