import moment from 'moment/moment'
import { I18n } from 'react-i18nify'

/**
 * @description get the correct month object for current index values and month index.
 * @param indexValues
 * @param indexObject
 * @param date
 * @return {{value: string, id: null, index: *, month: *, year: *}}
 */
export const getMonthObject = (indexValues, indexObject, date) => {
  const month = Number(date.format('M'))
  const year = Number(date.format('YYYY'))

  const indexValue = indexValues.find(
    _indexValue =>
      _indexValue.index_object.id === indexObject.id &&
      _indexValue.month === month &&
      _indexValue.year === year,
  )

  return {
    id: indexValue ? indexValue.id : null,
    index: indexObject.name,
    month,
    value:
      indexValue && indexValue.value !== null
        ? Number(indexValue.value).toLocaleString('de-DE', {
            minimumFractionDigits: 2,
          })
        : null,
    year,
  }
}

/**
 * @description Method to get the data structure for the data table
 * @param {Array} indexValues
 * @param {Array} dates
 */
export const getTableData = (indexValues, dates) =>
  indexValues
    .map(indexValue => {
      const indexObject = indexValue.index_object

      const data = {}
      dates.forEach((date, i) => {
        data[i] = getMonthObject(indexValues, indexObject, date)
      })

      return {
        indexId: indexObject.id,
        indexName: indexObject.name,
        data,
      }
    })
    .filter(
      (object, index, self) =>
        self.findIndex(t => t.indexName === object.indexName) === index,
    )
    .sort((a, b) => (a.indexId > b.indexId ? 1 : -1))

/**
 * @description Method to get the all the values of an index for a given month and year
 * @param {Object} indexValues
 * @param date
 */
export const getIndexValuesByDate = (indexValues, date) =>
  indexValues.filter(
    indexValue =>
      indexValue.month === Number(date.format('M')) &&
      indexValue.year === Number(date.format('YYYY')),
  )

/**
 * @description Method to get the monthly gran total of the index values
 * @param {Object} indexValues
 * @param date
 */
export const getMonthSum = (indexValues, date) => {
  const indexValuesByDate = getIndexValuesByDate(indexValues, date)

  if (!indexValuesByDate.length) {
    return 0
  }

  return indexValuesByDate
    .map(indexValue => Number(indexValue.value))
    .reduce((accumulator, currentValue) => accumulator + currentValue)
    .toLocaleString('de-DE', { minimumFractionDigits: 2 })
}

/**
 * @description Method to get the monthly gran total of the index values to show when inputing new values
 * @param {Object} data
 */
export const showNextMonthSum = data =>
  // replace function is needed because if we add a comma to some number, the sum function will not recognize it
  data
    .map(object => Number(object.value.replace(',', '.')))
    .reduce((accumulator, currentValue) => accumulator + currentValue)
    .toLocaleString('de-DE', { minimumFractionDigits: 2 })

/**
 * @description Method to get the date and user who last made a change in the values from the current month
 * @param {Object} indexValues
 * @param date
 */
export const getLatestChange = (indexValues, date) => {
  const indexValuesByDate = getIndexValuesByDate(indexValues, date)

  if (!indexValuesByDate.length) {
    return {
      date: I18n.t('indexValuesPage.footer.noLatestChangeDate'),
      user: I18n.t('indexValuesPage.footer.noLatestChangeUser'),
    }
  }

  const latestChangedIndexValue = indexValuesByDate.reduce((prev, current) =>
    moment(prev.lastmodified_at).isSameOrAfter(moment(current.lastmodified_at))
      ? prev
      : current,
  )
  const lastModifiedBy =
    latestChangedIndexValue.lastmodified_by_object ||
    latestChangedIndexValue.created_by_object

  return {
    date: moment(latestChangedIndexValue.lastmodified_at).format('L HH:mm'),
    user: `${lastModifiedBy.full_name}`,
  }
}

export const getMonthOptions = () => {
  const months = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ]
  const date = new Date()
  const actual_month = date.getMonth()
  const previous_month = actual_month - 1

  return [
    { label: months[previous_month], value: previous_month },
    { label: months[actual_month], value: actual_month },
  ]
}

export const getActualMonth = () => {
  const date = new Date()
  const month = date.toLocaleString('default', { month: 'long' })
  return { label: month, value: date.getMonth() }
}

export const convertDataToFormat = (indexList, indexValues) => {
  const current_date = new Date()
  const previous_date = new Date(
    new Date().getFullYear(),
    new Date().getMonth() - 1,
  )

  const filtered_values_current_month = indexList.map(index => {
    let obj = indexValues.find(
      item =>
        item.month === current_date.getMonth() + 1 &&
        item.year === current_date.getFullYear() &&
        item.index_object.id === index.id,
    )
    return {
      id: obj ? obj.id : null,
      index: index.name,
      index_id: index.id,
      value: obj?.value ? obj.value : '',
    }
  })

  const filtered_values_previous_month = indexList.map(index => {
    let obj = indexValues.find(
      item =>
        item.month === previous_date.getMonth() + 1 &&
        item.year === previous_date.getFullYear() &&
        item.index_object.id === index.id,
    )
    return {
      id: obj ? obj.id : null,
      index: index.name,
      index_id: index.id,
      value: obj?.value ? obj.value : '',
    }
  })

  const data = {
    count: 2,
    results: [
      {
        id: 0,
        indexes: filtered_values_previous_month,
        month: previous_date.getMonth() + 1,
        month_name: previous_date.toLocaleString('de-De', { month: 'long' }),
        year: previous_date.getFullYear(),
      },
      {
        id: 1,
        indexes: filtered_values_current_month,
        month: current_date.getMonth() + 1,
        month_name: current_date.toLocaleString('de-De', { month: 'long' }),
        year: current_date.getFullYear(),
      },
    ],
  }
  return data
}

export default {}
