import React from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'

import withErrorBoundary from 'helper/withErrorBoundary'

const PasswordResetDone = () => (
  <>
    <Helmet>
      <title>{I18n.t('pageTitles.forgotPasswordDone')}</title>
    </Helmet>

    <div className='login-container uk-align-center uk-width-1-1@s uk-width-3-5@l uk-width-1-3@xl'>
      <h1>
        <Translate value='forgetPasswordTranslations.passwordResetDone.title' />
      </h1>
      <h3>
        <Translate value='forgetPasswordTranslations.passwordResetDone.message' />
      </h3>
      <div className='uk-margin'>
        <Link to='/anmelden'>
          <Translate value='forgetPasswordTranslations.passwordResetDone.back' />
        </Link>
      </div>
    </div>
  </>
)

export default withErrorBoundary(PasswordResetDone)
