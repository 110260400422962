import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'moment/locale/de' // without this line setting the moment to de (or any other language) does not work
// THIRD PARTY STYLES FROM NODE-MODULES
import 'reset-css' // eslint-disable-line import/extensions
// THIRD PARTY JAVASCRIPT FROM NODE-MODULES
import '../node_modules/uikit/dist/js/uikit.min.js' // eslint-disable-line import/extensions
// REACT DATE-PICKER STYLES
import 'react-datepicker/dist/react-datepicker.css'
// OUR CUSTOM STYLES
import './assets/styles/uikit.scss'
import './assets/styles/fontello.css'
import './assets/styles/empto.scss'

import { locale } from 'moment'
import React from 'react'
import { hydrate, render } from 'react-dom'
import { Provider } from 'react-redux'

import App from './components/index'
import { store } from './config/store'

locale('de') // define moment locale globally

const rootElement = document.getElementById('root')
const app = (
  <Provider store={store}>
    <App />
  </Provider>
)

if (rootElement.hasChildNodes()) {
  hydrate(app, rootElement)
} else {
  render(app, rootElement)
}
