import { I18n } from 'react-i18nify'

import { ORDER_CLAIM_TYPE } from 'components/order/OrderDetailsPage/constants'

export const claimAcceptedStatusMessage = (
  claimCompanyId,
  inquiryCompanyId,
  claimType,
  claimMadeByCompany,
) => {
  let message
  switch (claimType) {
    case ORDER_CLAIM_TYPE.TYPE_NOT_CARRIED:
      if (claimCompanyId && claimCompanyId === inquiryCompanyId) {
        message = I18n.t(
          'orderClaimStatusMessage.accepted.aez.TYPE_NOT_CARRIED',
          { companyName: claimMadeByCompany },
        )
      } else {
        message = I18n.t(
          'orderClaimStatusMessage.accepted.teb.TYPE_NOT_CARRIED',
          { companyName: claimMadeByCompany },
        )
      }
      break
    case ORDER_CLAIM_TYPE.TYPE_NOT_COMPLETED:
      message = I18n.t(
        'orderClaimStatusMessage.accepted.aez.TYPE_NOT_COMPLETED',
        { companyName: claimMadeByCompany },
      )
      break
    case ORDER_CLAIM_TYPE.TYPE_CREDIT_WRONG:
      if (claimCompanyId && claimCompanyId === inquiryCompanyId) {
        message = I18n.t(
          'orderClaimStatusMessage.accepted.aez.TYPE_CREDIT_WRONG',
          { companyName: claimMadeByCompany },
        )
      } else {
        message = I18n.t(
          'orderClaimStatusMessage.accepted.teb.TYPE_CREDIT_WRONG',
          { companyName: claimMadeByCompany },
        )
      }
      break
    case ORDER_CLAIM_TYPE.TYPE_DEADHEAD:
      message = I18n.t('orderClaimStatusMessage.accepted.teb.TYPE_DEADHEAD', {
        companyName: claimMadeByCompany,
      })
      break
    case ORDER_CLAIM_TYPE.TYPE_OTHERS:
      if (claimCompanyId && claimCompanyId === inquiryCompanyId) {
        message = I18n.t('orderClaimStatusMessage.accepted.aez.TYPE_OTHERS', {
          companyName: claimMadeByCompany,
        })
      } else {
        message = I18n.t('orderClaimStatusMessage.accepted.teb.TYPE_OTHERS', {
          companyName: claimMadeByCompany,
        })
      }
      break
    case ORDER_CLAIM_TYPE.TYPE_WAITING_TIME:
      message = I18n.t(
        'orderClaimStatusMessage.accepted.teb.TYPE_WAITING_TIME',
        { companyName: claimMadeByCompany },
      )
      break
    default:
      message = ''
  }
  return message
}

export const claimRejectedStatusMessage = (
  currentUserCompanyId,
  inquiryCompanyId,
  claimType,
  claimMadeByCompany,
) => {
  let message
  switch (claimType) {
    case ORDER_CLAIM_TYPE.TYPE_NOT_CARRIED:
      if (currentUserCompanyId && currentUserCompanyId === inquiryCompanyId) {
        message = I18n.t(
          'orderClaimStatusMessage.rejected.aez.TYPE_NOT_CARRIED',
          { companyName: claimMadeByCompany },
        )
      } else {
        message = I18n.t(
          'orderClaimStatusMessage.rejected.teb.TYPE_NOT_CARRIED',
          { companyName: claimMadeByCompany },
        )
      }
      break
    case ORDER_CLAIM_TYPE.TYPE_NOT_COMPLETED:
      message = I18n.t(
        'orderClaimStatusMessage.rejected.aez.TYPE_NOT_COMPLETED',
        { companyName: claimMadeByCompany },
      )
      break
    case ORDER_CLAIM_TYPE.TYPE_CREDIT_WRONG:
      if (currentUserCompanyId && currentUserCompanyId === inquiryCompanyId) {
        message = I18n.t(
          'orderClaimStatusMessage.rejected.aez.TYPE_CREDIT_WRONG',
          { companyName: claimMadeByCompany },
        )
      } else {
        message = I18n.t(
          'orderClaimStatusMessage.rejected.teb.TYPE_CREDIT_WRONG',
          { companyName: claimMadeByCompany },
        )
      }
      break
    case ORDER_CLAIM_TYPE.TYPE_DEADHEAD:
      message = I18n.t('orderClaimStatusMessage.rejected.teb.TYPE_DEADHEAD', {
        companyName: claimMadeByCompany,
      })
      break
    case ORDER_CLAIM_TYPE.TYPE_OTHERS:
      if (currentUserCompanyId && currentUserCompanyId === inquiryCompanyId) {
        message = I18n.t('orderClaimStatusMessage.rejected.aez.TYPE_OTHERS', {
          companyName: claimMadeByCompany,
        })
      } else {
        message = I18n.t('orderClaimStatusMessage.rejected.teb.TYPE_OTHERS', {
          companyName: claimMadeByCompany,
        })
      }
      break
    case ORDER_CLAIM_TYPE.TYPE_WAITING_TIME:
      message = I18n.t(
        'orderClaimStatusMessage.rejected.teb.TYPE_WAITING_TIME',
        { companyName: claimMadeByCompany },
      )
      break
    default:
      message = ''
  }
  return message
}
export default {}
