import { arrayOf, shape, string } from 'prop-types'

/**
 * @description: This is an error as it is returned by the API.
 * The most basic kind of error (e.g. Network errors) contain only the message. You can display the message in a toastr
 * etc. as last resort. If the API returns real validation results, it will be in response.data. Global form errors will
 * be in "non_field_errors" and should be displayed on top of the form as global messages. All other server-side
 * validation messages will have properties called like the field and include an array of strings.
 */
export const ApiErrorScheme = {
  message: string.isRequired,
  response: shape({
    data: shape({
      non_field_errors: arrayOf(string),
    }),
  }),
}
