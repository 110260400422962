/* eslint-disable camelcase */
import { INQUIRY_ORDER_TYPE } from '../components/inquiry/constants'

import { localizeDate } from './general'

export const getActualOrderDeliveryDate = ({
  delivery_date,
  delivery_date_disposed,
  delivery_date_postponed,
  delivery_date_executed,
}) => {
  if (delivery_date_executed) {
    return delivery_date_executed
  }
  if (delivery_date_postponed) {
    return delivery_date_postponed
  }
  if (delivery_date_disposed) {
    return delivery_date_disposed
  }
  return delivery_date
}

export const getActualOrderTimeOfDayDelivery = ({
  delivery_date_executed,
  time_of_day_delivery,
  time_of_day_delivery_disposed,
  time_of_day_delivery_postponed,
}) => {
  if (delivery_date_executed) {
    return undefined
  }
  if (time_of_day_delivery_postponed) {
    return time_of_day_delivery_postponed
  }
  if (time_of_day_delivery_disposed) {
    return time_of_day_delivery_disposed
  }
  return time_of_day_delivery
}

/**
 * @param {OrderListItem|Order} props
 * @returns {undefined|*}
 */
export const getActualOrderCollectionDate = props => {
  const {
    collection_date_executed,
    collection_date_postponed,
    collection_date,
    offer_object,
  } = props
  if (collection_date_executed) {
    return collection_date_executed
  }
  if (collection_date_postponed) {
    return collection_date_postponed
  }
  if (collection_date) {
    return collection_date
  }
  if (offer_object) {
    return offer_object.collection_date
  }

  return undefined
}

export const getActualOrderTimeOfDayCollection = ({
  collection_date_executed,
  time_of_day_collection_postponed,
  time_of_day_collection,
}) => {
  if (collection_date_executed) {
    return undefined
  }
  if (time_of_day_collection_postponed) {
    return time_of_day_collection_postponed
  }

  return time_of_day_collection
}

/**
 * Depending on the type of the order, the service date "Leistungsdatum" is either the collection or the delivery date.
 * Also these dates can be postponed etc. This is a convenience function to get the date that we call service date from
 * an order object.
 * @param order
 * @returns {string}
 */
export const getActualServiceDate = order => {
  if (order.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME) {
    return localizeDate(getActualOrderCollectionDate(order))
  }
  return localizeDate(getActualOrderDeliveryDate(order))
}

export default {}
