import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import SecurityGroupActionTypes from '../actiontypes/securitygroup'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetSecurityGroupList
 * @description Loads all security groups from the api.
 */
export const handleGetSecurityGroupList = createLogic({
  type: SecurityGroupActionTypes.GET_SECURITY_GROUPS_REQUEST,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/security-group/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: SecurityGroupActionTypes.GET_SECURITY_GROUPS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.securityGroup.handleGetSecurityGroupList.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: SecurityGroupActionTypes.GET_SECURITY_GROUPS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [handleGetSecurityGroupList]
