export const OfferCardTranslations = {
  newOfferCard: {
    dashboard: {
      title: 'Angebot',
      informationStatusOpen: 'Die Anfrage läuft bis zum %{date}',
    },
    details: {
      title: 'Status des Angebots',
      headline: '<strong>Anfrage %{id}</strong> vom %{date}',
      informationStatusOpen: 'Laufzeit der Anfrage %{date}',
      informationStatusAccepted: 'Ihr Angebot wurde am %{date} angenommen',
      informationStatusAcceptedWithoutDate: 'Ihr Angebot wurde angenommen',
      informationStatusRejected: 'Ihr Angebot wurde am %{date} abgelehnt',
      informationStatusRejextedWithoutDate: 'Ihr Angebot wurde abgelehnt',
      informationStatusExpired: 'Die Anfrage ist am %{date} ausgelaufen',
      informationStatusInquiryCancelled:
        'Die Anfrage wurde am %{date} storniert',
      informationStatusInquiryCancelledWithoutDate:
        'Die Anfrage wurde storniert',
      informationStatusCancelled: 'Das Angebot wurde am %{date} storniert',
      informationStatusCancelledWithoutDate: 'Das Angebot wurde storniert',
      noText: 'Kein Text verfügbar',
      noDate: 'Datum unbekannt',
      container: '%{capacity} %{name} (Anzahl:&nbsp;%{amount})',
      containerCubicMeters: '%{capacity} %{name} (Menge:&nbsp;%{amount})',
      serviceBegin: 'Start der Vereinbarung am %{date}',
    },
    stepIndicator: {
      stepOneTitle: 'Angebot abgeben',
      stepTwoTitle: 'Angebot angenommen',
    },
  },
}
