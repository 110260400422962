import { connect } from 'react-redux'

import { getContainers } from 'actions/container'
import { getFractions } from 'actions/fraction'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  fractionList: getFractionsSelector(state),
  containerList: getContainersSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getContainers: () => dispatch(getContainers()),
  getFractions: () => dispatch(getFractions()),
})

export const connector = connect(mapStateToProps, mapDispatchToProps)
