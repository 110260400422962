import uniqueID from 'lodash.uniqueid'
import React, { FC, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import DetailSection from 'components/common/DetailSwitcher/components/DetailSection'
import { FilesViewer } from 'components/common/FilesViewer'
import { ICON_NAME } from 'components/common/Fontello'
import { ICON_POSITION, IconButton } from 'components/common/IconButton'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { Slider as DefaultSlider } from 'components/common/Slider'
import { OrderAttachmentForm } from 'components/order/OrderDetailsPage/components/OrderAttachmentForm/OrderAttachmentForm'
import { MaklerPremiumGroups } from 'constants/user'
import { getCurrentUserSelector } from 'selectors/user'

interface SliderComponentProps {
  order: Order
}

/**
 * @description This component renders the slider component.
 */
export const Slider: FC<SliderComponentProps> = ({ order }) => {
  const user = useSelector(getCurrentUserSelector)
  const isMaklerPremium = user.group_id in MaklerPremiumGroups
  const [isImageFormModalOpen, setIsImageFormModalOpen] = useState(false)
  const modalId = uniqueID()

  return (
    <>
      <DetailSection
        className='uk-padding'
        title={I18n.t('detailsSwitcher.images')}
        fullWidth
        dataTestId='order-details-page__slider'
      >
        {order.offer_object.attachments.length > 0 ? (
          <DefaultSlider images={order.offer_object.attachments} />
        ) : (
          <Translate value='general.emptyImageSet' />
        )}
      </DetailSection>
      {isMaklerPremium && (
        <DetailSection
          className='uk-padding order-attachments'
          title={I18n.t('detailsSwitcher.orderImages')}
          fullWidth
          dataTestId='order-details-page__slider_orderimages'
        >
          {order.attachments.length > 0 ? (
            <FilesViewer
              files={order.attachments}
              showPreview={false}
              withFilenameTooltip
            />
          ) : (
            <Translate value='general.emptyImageSet' />
          )}
          <div className='uk-grid uk-margin-bottom uk-margin-top'>
            <div
              className='uk-width-1-1 universal-file-upload--with-checkmark'
              data-uk-margin=''
            >
              <IconButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                iconName={ICON_NAME.PLUS}
                iconPosition={ICON_POSITION.RIGHT}
                onClick={() => setIsImageFormModalOpen(!isImageFormModalOpen)}
              >
                <Translate value='orderDetails.details.images.orderImagesButton' />
              </IconButton>
            </div>
          </div>

          <Modal
            ariaDescribedBy={''}
            isOpen={isImageFormModalOpen}
            onClose={() => setIsImageFormModalOpen(!isImageFormModalOpen)}
          >
            <ModalHeader
              onClose={() => setIsImageFormModalOpen(false)}
              title={I18n.t('orderDetails.details.modal.title')}
              titleId={modalId}
            />
            <OrderAttachmentForm
              handleCloseModal={() => {
                setIsImageFormModalOpen(!isImageFormModalOpen)
              }}
            />
          </Modal>
        </DetailSection>
      )}
    </>
  )
}
