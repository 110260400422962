import { Form, Formik } from 'formik'
import moment from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { confirmExecution } from 'actions/order'
import { BUTTON_BACKGROUND_COLOR, BUTTON_TYPE } from 'components/common/Button'
import { ApiValidationMessages } from 'components/common/Form/components/ApiValidationMessages'
import {
  Headline,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import InputDate from 'components/common/InputDate/index'
import { ProgressButton } from 'components/common/ProgressButton'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { UserPermission } from 'constants/user'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

interface ConfirmExecutionComponentProps {
  order: Order
  buttonText?: string
  headlineText?: string
  confirmCollection?: boolean
  confirmDelivery?: boolean
}

export const ConfirmExecution: FC<ConfirmExecutionComponentProps> = ({
  order,
  buttonText = I18n.t('orderStatusTasks.confirmExecution.submit'),
  headlineText = I18n.t('orderStatusTasks.confirmExecution.headline'),
  confirmCollection = false,
  confirmDelivery = false,
}) => {
  const confirmExecutionErrorSelector = createErrorSelector([
    'CONFIRM_EXECUTION_OF_ORDER',
  ])
  const confirmExecutionLoadingSelector = createLoadingSelector([
    'CONFIRM_EXECUTION_OF_ORDER',
  ])
  const dispatch = useDispatch()
  const submitConfirmExecutionCollection = date =>
    dispatch(
      confirmExecution({
        collection_date_executed: date,
        orderId: order.id,
      }),
    )
  const submitConfirmExecutionDelivery = date =>
    dispatch(
      confirmExecution({
        delivery_date_executed: date,
        orderId: order.id,
      }),
    )
  const isLoading = useSelector(state => confirmExecutionLoadingSelector(state))
  const apiError = useSelector(state => confirmExecutionErrorSelector(state))

  return (
    <RequiredPermissions
      requiredPermissions={[
        UserPermission.CONFIRM_EXECUTION_OF_ORDER,
        UserPermission.CONFIRM_MAKLER_PREMIUM_EXECUTION_OF_ORDER,
      ]}
    >
      <Formik
        onSubmit={values => {
          if (confirmCollection)
            submitConfirmExecutionCollection(moment(values.date).format('L'))
          if (confirmDelivery)
            submitConfirmExecutionDelivery(moment(values.date).format('L'))
        }}
        initialValues={{
          date: moment(),
        }}
        validationSchema={() =>
          yup.object().shape({
            date: yup
              .string()
              .required(I18n.t('message.validation.required'))
              .typeError(''),
          })
        }
      >
        {({
          errors,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          submitCount,
          touched,
          values,
        }) => (
          <Form
            className='order-status-task--on-order-details'
            data-testid='order-status-task__confirm-execution-form'
            noValidate // Disable browser validation
          >
            <Headline tag={HEADLINE_TAG.H4} headlineStyle={HEADLINE_STYLE.H4}>
              {headlineText}
            </Headline>

            {/* This Api Validation message is needed here to get a customized error message
              (zB. Address already exists) */}
            <ApiValidationMessages error={apiError} />

            <div className='order-status-task__fields order-status-task__fields--single'>
              <InputDate
                error={submitCount > 0 && touched.date ? errors.date : ''}
                placeholder={I18n.t('order.createOrder.labels.executionDate')}
                label={I18n.t('order.createOrder.labels.executionDate')}
                isRequired={!values.date}
                maxDate={moment()}
                name='date'
                onChange={value => setFieldValue('date', value)}
                value={values.date}
              />
            </div>
            <ProgressButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              dataTestId='confirm-execution-form-button'
              isDisabled={
                (submitCount > 0 && !isValid) || (isSubmitting && isLoading)
              }
              isLoading={isSubmitting && isLoading}
              onClick={handleSubmit}
              type={BUTTON_TYPE.SUBMIT}
            >
              {buttonText}
            </ProgressButton>
          </Form>
        )}
      </Formik>
    </RequiredPermissions>
  )
}
