import './style.scss'

import { Form, Formik } from 'formik'
import moment from 'moment'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import * as yup from 'yup'

import { createLoadingSelector } from 'selectors/loading'
import { USER_GENDER_CHOICES, USER_GENDER_LABELS } from 'helper/user'
import { createDemoRequest } from 'actions/demo'

import { Checkbox } from '../../common/Checkbox'
import { Divider } from '../../common/Divider'
import { DropDownInput } from '../../common/DropDownInput'
import InputDate from '../../common/InputDate'
import { InputEmail } from '../../common/InputEmail'
import { InputTelephoneNumber } from '../../common/InputTelephoneNumber'
import { InputText } from '../../common/InputText'
import PageContainer from '../../common/PageContainer'
import {
  BUTTON_BACKGROUND_COLOR,
  ProgressButton,
} from '../../common/ProgressButton'
import {
  INQUIRY_TIME_OF_DAY,
  INQUIRY_TIMES_OF_DAY,
} from '../../inquiry/constants'
import { PageHeader } from '../../layout/PageHeader'
import {
  LINK_CONDITIONS_OF_PARTICIPATION,
  LINK_PRIVACY_STATEMENT,
  MAX_LENGTH,
} from '../HomepageRegister/constants'

export const RequestDemo: FC<RouteComponentProps> = ({ history }) => {
  const isLoading = useSelector(createLoadingSelector(['CREATE_DEMO_REQUEST']))
  const dispatch = useDispatch()

  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.requestDemo')}</title>
      </Helmet>
      <PageContainer>
        <div className='request-demo'>
          <PageHeader
            title={I18n.t('homepages.requestDemo.title')}
            subtitle={<Translate value='homepages.requestDemo.subTitle' />}
          />
          <Formik
            initialValues={{
              gender: '',
              first_name: '',
              last_name: '',
              company_name: '',
              email: '',
              business_phone_number: '',
              demo_date: undefined,
              demo_time_of_day: 3,
              accept_policy: false,
            }}
            onSubmit={values => {
              const callback = {
                success: () => {
                  history.go(-1)
                },
              }
              dispatch(createDemoRequest(values, callback))
            }}
            validationSchema={() =>
              yup.object().shape({
                gender: yup
                  .mixed()
                  .oneOf(
                    Object.keys(USER_GENDER_LABELS()),
                    I18n.t('message.validation.invalidSelection'),
                  )
                  .required(I18n.t('message.validation.selectionRequired')),
                first_name: yup
                  .string()
                  .max(
                    MAX_LENGTH.FIRST_NAME,
                    I18n.t('message.validation.invalidSelection'),
                  )
                  .matches(
                    /[^\d]+/,
                    I18n.t('message.validation.invalidSelection'),
                  )
                  .required(I18n.t('message.validation.required')),
                last_name: yup
                  .string()
                  .max(
                    MAX_LENGTH.LAST_NAME,
                    I18n.t('message.validation.invalidSelection'),
                  )
                  .matches(
                    /[^\d]+/,
                    I18n.t('message.validation.invalidSelection'),
                  )
                  .required(I18n.t('message.validation.required')),
                company_name: yup
                  .string()
                  .max(
                    MAX_LENGTH.COMPANY_NAME,
                    I18n.t('message.validation.invalidSelection'),
                  ),
                email: yup
                  .string()
                  .max(
                    MAX_LENGTH.MAIL_ADDRESS,
                    I18n.t('message.validation.invalidSelection'),
                  )
                  .email(I18n.t('message.validation.email.isEmail'))
                  .required(I18n.t('message.validation.email.required')),
                business_phone_number: yup
                  .string()
                  .max(
                    MAX_LENGTH.PHONE_NUMBER,
                    I18n.t('message.validation.invalidSelection'),
                  )
                  .matches(
                    /^\+?[\d \-/]*$/,
                    I18n.t('message.validation.invalidSelection'),
                  )
                  .required(I18n.t('message.validation.required')),
                // demo_date: yup.date()
                //   .min(new Date(new Date().setHours(0, 0, 0, 0)), I18n.t('message.validation.date')),
                demo_date: yup.mixed(), // Proper validation once we switch to dateFns or similar
                demo_time_of_day: yup.number().oneOf(
                  INQUIRY_TIMES_OF_DAY.map(item => item.id),
                  I18n.t('message.validation.invalidSelection'),
                ),
                accept_policy: yup
                  .boolean()
                  .oneOf(
                    [true],
                    I18n.t('message.validation.policy.isCheckedPrivacy'),
                  ),
              })
            }
          >
            {({
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
              isSubmitting,
              setFieldTouched,
              setFieldValue,
              submitCount,
              touched,
              values,
            }) => (
              <Form>
                <div className='request-demo__inputs'>
                  <DropDownInput
                    choices={USER_GENDER_CHOICES()}
                    dataTestId='demo-user-gender-input'
                    dataTestIdError='form-group-error'
                    error={
                      submitCount > 0 && touched.gender ? errors.gender : ''
                    }
                    label={
                      <Translate value='homepages.register.form.titleLabel' />
                    }
                    name='gender'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={I18n.t(
                      'homepages.register.form.titlePlaceholder',
                    )}
                    value={values.gender}
                  />

                  <InputText
                    dataTestId='demo-user-first-name-input'
                    dataTestIdError='form-group-error'
                    error={
                      submitCount > 0 && touched.first_name
                        ? errors.first_name
                        : ''
                    }
                    label={
                      <Translate value='homepages.register.form.firstNameLabel' />
                    }
                    maxLength={MAX_LENGTH.FIRST_NAME}
                    name='first_name'
                    noNumbers
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={I18n.t(
                      'homepages.register.form.firstNamePlaceholder',
                    )}
                    value={values.first_name}
                  />

                  <InputText
                    dataTestId='demo-user-last-name-input'
                    dataTestIdError='form-group-error'
                    error={
                      submitCount > 0 && touched.last_name
                        ? errors.last_name
                        : ''
                    }
                    label={
                      <Translate value='homepages.register.form.lastNameLabel' />
                    }
                    maxLength={MAX_LENGTH.LAST_NAME}
                    name='last_name'
                    noNumbers
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={I18n.t(
                      'homepages.register.form.lastNamePlaceholder',
                    )}
                    value={values.last_name}
                  />

                  <InputText
                    dataTestId='demo-user-company-name-input'
                    dataTestIdError='form-group-error'
                    error={
                      submitCount > 0 && touched.company_name
                        ? errors.company_name
                        : ''
                    }
                    label={
                      <Translate value='homepages.requestDemo.form.companyNameLabel' />
                    }
                    maxLength={MAX_LENGTH.COMPANY_NAME}
                    name='company_name'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={I18n.t(
                      'homepages.register.form.companyNamePlaceholder',
                    )}
                    value={values.company_name}
                  />

                  <InputEmail
                    dataTestId='demo-user-email-input'
                    dataTestIdError='form-group-error'
                    error={submitCount > 0 && touched.email ? errors.email : ''}
                    label={
                      <Translate value='homepages.register.form.mailAddressLabel' />
                    }
                    maxLength={MAX_LENGTH.MAIL_ADDRESS}
                    name='email'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={I18n.t(
                      'homepages.register.form.mailAddressPlaceholder',
                    )}
                    value={values.email}
                  />

                  <InputTelephoneNumber
                    dataTestId='demo-user-phone-input'
                    dataTestIdError='form-group-error'
                    error={
                      submitCount > 0 && touched.business_phone_number
                        ? errors.business_phone_number
                        : ''
                    }
                    label={
                      <Translate value='homepages.register.form.phoneNumberLabel' />
                    }
                    maxLength={MAX_LENGTH.PHONE_NUMBER}
                    name='business_phone_number'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={I18n.t(
                      'homepages.register.form.phoneNumberPlaceholder',
                    )}
                    value={values.business_phone_number}
                  />

                  <InputDate
                    dataTestId='demo-date-input'
                    dataTestIdError='form-group-error'
                    error={
                      submitCount > 0 && touched.demo_date
                        ? errors.demo_date
                        : ''
                    }
                    label={
                      <Translate value='homepages.requestDemo.form.demoDateLabel' />
                    }
                    minDate={moment()}
                    name='demo_date'
                    onChange={value => {
                      setFieldValue('demo_date', moment(value).format('L'))
                      setFieldTouched('demo_date')
                    }}
                    placeholder={I18n.t(
                      'homepages.requestDemo.form.demoDatePlaceholder',
                    )}
                    popperPlacement='top-start'
                    value={values.demo_date}
                  />

                  <DropDownInput
                    choices={INQUIRY_TIMES_OF_DAY.map(item => ({
                      optionValue: item.id,
                      optionLabel: `${I18n.t(item.name)}`,
                    }))}
                    dataTestId='demo-time-of-day'
                    dataTestIdError='form-group-error'
                    error={
                      submitCount > 0 && touched.demo_time_of_day
                        ? errors.demo_time_of_day
                        : ''
                    }
                    label={
                      <Translate value='homepages.requestDemo.form.timeOfDayLabel' />
                    }
                    name='demo_time_of_day'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={I18n.t('general.timeOfDay')}
                    value={
                      values.demo_time_of_day ||
                      String(INQUIRY_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY)
                    }
                  />
                </div>

                <Divider />

                <div className='register-form-section__checkbox'>
                  <Checkbox
                    dataTestId='user-check-policy-input'
                    dataTestIdError='form-group-error'
                    error={
                      submitCount > 0 && touched.accept_policy
                        ? errors.accept_policy
                        : ''
                    }
                    isChecked={values.accept_policy}
                    isRequired
                    justifyCenter
                    label={
                      <Translate
                        dangerousHTML
                        linkConditionsOfParticipation={
                          LINK_CONDITIONS_OF_PARTICIPATION
                        }
                        linkPrivacyStatement={LINK_PRIVACY_STATEMENT}
                        value='homepages.requestDemo.form.acceptConditions'
                      />
                    }
                    name='accept_policy'
                    onChange={handleChange}
                  />
                </div>
                <div className='request-demo__submit-errors'>
                  <ProgressButton
                    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                    dataTestId='request-demo-form-submit'
                    isDisabled={
                      (submitCount > 0 && !isValid) ||
                      (isSubmitting && isLoading)
                    }
                    isLoading={isSubmitting && isLoading}
                    onClick={handleSubmit}
                  >
                    <Translate
                      dangerousHTML
                      value='homepages.requestDemo.form.submitButton'
                    />
                  </ProgressButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </PageContainer>
    </>
  )
}
