import { useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC, useEffect, useMemo } from 'react'

import { INQUIRY_ORDER_TYPE } from '../../inquiry/constants'
import InputDate from '../InputDate/index'

import { OrderTypeDetailsValues } from './OrderTypeDetailsGroup'

// Don't need all Formik Values, but we need to add one to our companion component
export interface RuntimeOfInquiryValues extends OrderTypeDetailsValues {
  runtime_of_inquiry: string
}

interface RuntimeOfInquiryProps {
  label: string
  placeholder: string
}

export const RuntimeOfInquiry: FC<RuntimeOfInquiryProps> = ({
  label,
  placeholder,
}) => {
  const { errors, setFieldValue, values } =
    useFormikContext<RuntimeOfInquiryValues>()

  const maxRuntimeDate = useMemo(() => {
    switch (Number(values.order_type)) {
      case INQUIRY_ORDER_TYPE.TYPE_RECURRING:
      case INQUIRY_ORDER_TYPE.TYPE_ON_DEMAND:
        return values.turn_begin ? moment(values.turn_begin, 'L') : moment()
      case INQUIRY_ORDER_TYPE.TYPE_QUICK:
      case INQUIRY_ORDER_TYPE.TYPE_ONE_TIME:
        return values.delivery_date
          ? moment(values.delivery_date, 'L')
          : moment()
      default:
        return moment()
    }
  }, [values.delivery_date, values.order_type, values.turn_begin])

  useEffect(() => {
    if (maxRuntimeDate) setFieldValue('runtime_of_inquiry', '')
  }, [maxRuntimeDate, setFieldValue])

  // condition to set the minimum selectable date. If user is selecting a date after hour 22,
  // he will not be able to select the current day anymore. The default value will be the next day at 8:00
  // when selecting a date, the default hour should be the current hour plus one.
  const minDate =
    moment().hours() >= 22
      ? moment().add(1, 'days').hours(8).minutes(0)
      : moment().add(1, 'hours').startOf('hour')
  // condition to set the minimum hour a user can select in the Runtime of Inquiry
  const minRuntimeInquiryHour =
    // the default value before introducing a value is the current day, so, if there is no value, then show only
    // the possible picking hours counting from now until the end of the working day
    (!values.runtime_of_inquiry ||
      // if there is a value and the select day is today, then show only the possible picking hours counting from
      // now until the end of the working day
      moment(values.runtime_of_inquiry, 'L').isSame(moment().format('L'))) &&
    // if the user picks a date outside the working hours (z.B: user is creating an inquiry at 2 in the morning.
    // If this condition is not set, then the user can select a hour of runtime outside the working hours.
    moment().hours() > 8 &&
    moment().hours() < 22
      ? moment()
      : moment().hours(8).minutes(0)

  return (
    <InputDate
      label={label}
      maxTime={moment().hours(22).minutes(0)}
      maxDate={maxRuntimeDate}
      minDate={minDate}
      minTime={minRuntimeInquiryHour}
      showTimeSelect
      name='runtime_of_inquiry'
      onChange={value => setFieldValue('runtime_of_inquiry', value)}
      placeholder={placeholder}
      value={values.runtime_of_inquiry}
      withCheckmark
      showCheckmark={!!values.runtime_of_inquiry && !errors.runtime_of_inquiry}
    />
  )
}
