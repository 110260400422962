import './style.scss'

import moment from 'moment'
import { bool, func, number, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { I18n } from 'react-i18nify'

import { DropDownInput } from 'components/common/DropDownInput'
import { InputTelephoneNumber } from 'components/common/InputTelephoneNumber'
import { InputText } from 'components/common/InputText'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { USER_GENDER_CHOICES, USER_GENDER_LABELS } from 'helper/user'
import { UserScheme } from 'schemes/user'

class UserProfileFormFields extends Component {
  static propTypes = {
    errors: shape({
      business_phone_number: string,
      first_name: string,
      gender: string,
      last_name: string,
      position: string,
    }).isRequired,
    handleChange: func.isRequired,
    handleBlur: func.isRequired,
    isCurrentUser: bool.isRequired,
    submitCount: number.isRequired,
    touched: shape({
      business_phone_number: bool,
      first_name: bool,
      gender: bool,
      last_name: bool,
      position: bool,
    }).isRequired,
    user: shape(UserScheme).isRequired,
    values: shape({
      business_phone_number: string,
      first_name: string,
      gender: string,
      last_name: string,
      position: string,
    }).isRequired,
  }

  getValueForNull = (value, name) => {
    if (!value)
      return I18n.t(
        `userProfilePageTranslations.userProfileForm.${name}.noValueGiven`,
      )
    return value
  }

  render() {
    const {
      errors,
      handleChange,
      handleBlur,
      isCurrentUser,
      submitCount,
      touched,
      user,
      values,
    } = this.props

    return (
      <div className='user-profile-form-fields'>
        {/* GENDER SELECTION */}
        <div className='user-profile-form-fields__input-group'>
          <div className='user-profile-form-fields__input-group--gender'>
            <ReadOnlyField
              editable={isCurrentUser}
              label={I18n.t(
                'userProfilePageTranslations.userProfileForm.gender.label',
              )}
              value={
                isCurrentUser
                  ? values.gender || ''
                  : USER_GENDER_LABELS()[Number(user.gender)]
              }
            >
              <DropDownInput
                choices={USER_GENDER_CHOICES()}
                dataTestId='user-profile-form-fields-gender-input'
                dataTestIdError='user-profile-form-fields-group-error'
                error={submitCount > 0 && touched.gender ? errors.gender : ''}
                isRequired={isCurrentUser && values.gender === ''}
                label={I18n.t(
                  'userProfilePageTranslations.userProfileForm.gender.label',
                )}
                name='gender'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={I18n.t(
                  'userProfilePageTranslations.userProfileForm.gender.placeholder',
                )}
                value={
                  isCurrentUser
                    ? values.gender || ''
                    : USER_GENDER_LABELS[Number(user.gender)]
                }
              />
            </ReadOnlyField>
          </div>

          {/* FIRST NAME */}
          <div className='user-profile-form-fields__input-group--first-name'>
            <ReadOnlyField
              editable={isCurrentUser}
              label={I18n.t(
                'userProfilePageTranslations.userProfileForm.firstName.label',
              )}
              value={isCurrentUser ? values.first_name || '' : user.first_name}
            >
              <InputText
                dataTestId='user-profile-form-fields-first-name-input'
                dataTestIdError='user-profile-form-fields-group-error'
                error={
                  submitCount > 0 && touched.first_name ? errors.first_name : ''
                }
                isRequired={isCurrentUser && values.first_name === ''}
                label={I18n.t(
                  'userProfilePageTranslations.userProfileForm.firstName.label',
                )}
                maxLength={50}
                name='first_name'
                noNumbers
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={I18n.t(
                  'userProfilePageTranslations.userProfileForm.firstName.placeholder',
                )}
                value={
                  isCurrentUser ? values.first_name || '' : user.first_name
                }
              />
            </ReadOnlyField>
          </div>

          {/* LAST NAME */}
          <div className='user-profile-form-fields__input-group--last-name'>
            <ReadOnlyField
              editable={isCurrentUser}
              label={I18n.t(
                'userProfilePageTranslations.userProfileForm.lastName.label',
              )}
              value={isCurrentUser ? values.last_name || '' : user.last_name}
            >
              <InputText
                dataTestId='user-profile-form-fields-last-name-input'
                dataTestIdError='user-profile-form-fields-group-error'
                error={
                  submitCount > 0 && touched.last_name ? errors.last_name : ''
                }
                isRequired={isCurrentUser && values.last_name === ''}
                label={I18n.t(
                  'userProfilePageTranslations.userProfileForm.lastName.label',
                )}
                maxLength={50}
                name='last_name'
                noNumbers
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={I18n.t(
                  'userProfilePageTranslations.userProfileForm.lastName.placeholder',
                )}
                value={isCurrentUser ? values.last_name || '' : user.last_name}
              />
            </ReadOnlyField>
          </div>

          {/* BUSINESS PHONE NUMBER */}
          <div className='user-profile-form-fields__input-group--business-phone-number'>
            <ReadOnlyField
              editable={isCurrentUser}
              label={I18n.t(
                'userProfilePageTranslations.userProfileForm.phoneNumber.label',
              )}
              value={
                isCurrentUser
                  ? values.business_phone_number || ''
                  : this.getValueForNull(
                      user.business_phone_number,
                      'phoneNumber',
                    )
              }
            >
              <InputTelephoneNumber
                dataTestId='user-profile-form-fields-phone-number-input'
                dataTestIdError='user-profile-form-fields-group-error'
                label={I18n.t(
                  'userProfilePageTranslations.userProfileForm.phoneNumber.label',
                )}
                maxLength={20}
                name='business_phone_number'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={I18n.t(
                  'userProfilePageTranslations.userProfileForm.phoneNumber.placeholder',
                )}
                value={
                  isCurrentUser
                    ? values.business_phone_number || ''
                    : this.getValueForNull(
                        user.business_phone_number,
                        'phoneNumber',
                      )
                }
              />
            </ReadOnlyField>
          </div>

          {/* POSITION */}
          <div className='user-profile-form-fields__input-group--position'>
            <ReadOnlyField
              editable={isCurrentUser}
              label={I18n.t(
                'userProfilePageTranslations.userProfileForm.position.label',
              )}
              value={
                isCurrentUser
                  ? values.position || ''
                  : this.getValueForNull(user.position, 'position')
              }
            >
              <InputText
                dataTestId='user-profile-form-fields-position-input'
                dataTestIdError='user-profile-form-fields-group-error'
                error={
                  submitCount > 0 && touched.position ? errors.position : ''
                }
                label={I18n.t(
                  'userProfilePageTranslations.userProfileForm.position.label',
                )}
                maxLength={50}
                name='position'
                noNumbers
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={I18n.t(
                  'userProfilePageTranslations.userProfileForm.position.placeholder',
                )}
                value={
                  isCurrentUser
                    ? values.position || ''
                    : this.getValueForNull(user.position, 'position')
                }
              />
            </ReadOnlyField>
          </div>
        </div>
        <div className='user-profile-form-fields__info-group'>
          {/* COMPANY NAME */}
          <div className='user-profile-form-fields__info-group--company'>
            <ReadOnlyField
              label={I18n.t(
                'userProfilePageTranslations.userProfileForm.company.label',
              )}
              value={
                user && user.company_object
                  ? user.company_object.name
                  : I18n.t(
                      'userProfilePageTranslations.userProfileForm.company.platformAdminCompany',
                    )
              }
            />
          </div>

          {/* MEMBER SINCE */}
          <div className='user-profile-form-fields__info-group--member-since'>
            <ReadOnlyField
              label={I18n.t(
                'userProfilePageTranslations.userProfileForm.memberSince.label',
              )}
              value={moment(user.member_since).format('L') || ''}
            />
          </div>

          {/* ROLE */}
          <div className='user-profile-form-fields__info-group--role'>
            <ReadOnlyField
              label={I18n.t(
                'userProfilePageTranslations.userProfileForm.role.label',
              )}
              value={user.group_name || ''}
            />
          </div>
          {/* EMAIL */}
          <div className='user-profile-form-fields__info-group--email'>
            <ReadOnlyField
              label={I18n.t(
                'userProfilePageTranslations.userProfileForm.email.label',
              )}
              value={
                user.email.endsWith('@maklerpremium.example.com')
                  ? ''
                  : user.email || ''
              }
            />
          </div>
        </div>
      </div>
    )
  }
}

export default UserProfileFormFields
