const addressApiTranslations = {
  handleGetAddresses: {
    error: 'Adressen konnten leider nicht geladen werden.',
  },
  handleGetAddressesForCompany: {
    error: 'Adressen konnten leider nicht geladen werden.',
  },
  handleAddAddress: {
    success: 'Adresse wurde erfolgreich hinzugefügt.',
    error: 'Hinzufügen der Adresse leider fehlgeschlagen.',
  },
  handleUpdateAddress: {
    success: 'Adresse wurde erfolgreich gespeichert.',
    error: 'Speichern der Adresse leider fehlgeschlagen.',
  },
  handleBlockAddress: {
    success: 'Adresse wurde erfolgreich gesperrt.',
    error: 'Sperren der Adresse leider fehlgeschlagen.',
  },
  handleUpdateStatusAddress: {
    activation: {
      success: 'Adresse wurde erfolgreich entsperrt',
    },
    deactivation: {
      success: 'Adresse wurde erfolgreich gesperrt',
    },
  },
  handleGetAddressesWithOrders: {
    error: 'Adressen konnten leider nicht geladen werden.',
  },
  handleExportAddresses: {
    success: 'Der CSV Export der Standorte wurde erfolgreich gestartet.',
    error: 'Der CSV Export der Standorte konnte nicht gestartet werden',
  },
}

export default addressApiTranslations
