/**
 * @function
 * @description Checks if the given form field has errors
 * @param {FormField} field the form field to check
 * @return {boolean}
 */
const fieldHasErrors = field => {
  if (!field || !field.errors) return false

  return Object.keys(field.errors).find(key => field.errors[key] === true)
}

export default fieldHasErrors
