import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'

interface ImageSwatchProps {
  blur?: boolean
  className?: string
  image: string
  name: string
  square?: boolean
}

/**
 * @description ImageSwatch is a component which generates a rounded image
 */
export const ImageSwatch: FC<ImageSwatchProps> = ({
  blur,
  className,
  image,
  name,
  square,
}) => (
  <div
    className={classNames(
      'image-swatch',
      { 'image-swatch--blurred': blur },
      { 'image-swatch--squared': square },
      className,
    )}
  >
    <img
      className='image-swatch__image'
      src={image}
      alt={name}
      aria-hidden={!name}
    />
  </div>
)
