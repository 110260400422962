import { connect } from 'react-redux'
import { actions } from 'react-redux-form'

import { getZipcode, submitZipcode } from 'actions/zipCode'
import { getZipcodeSelector } from 'selectors/zipCode'

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  zipCode: getZipcodeSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  resetZipCodeField: field => dispatch(actions.resetValidity(field)),
  getZipcode: value => dispatch(getZipcode(value)),
  submitZipcode: () => dispatch(submitZipcode()),
})

export const connector = connect(mapStateToProps, mapDispatchToProps)
