import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, useLocation } from 'react-router-dom'
import Spinner from 'react-spinkit'

import { UserPermission } from 'constants/user'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { BaseDataLoader } from 'components/common/BaseDataLoader'
import { createLoadingSelector } from 'selectors/loading'
import { getLoggedInSelector } from 'selectors/user'

interface PrivateRouteProps {
  path: string | string[]
  key: number
  exact: boolean
  component: React.ReactNode
  requiredPermissions: UserPermission[]
  showErrorPage: boolean
}

/**
 * @description this is a component to handle private routes.
 * @ref https://reacttraining.com/react-router/web/example/auth-workflow
 */
export const PrivateRoute: FC<PrivateRouteProps> = ({
  component: ComponentFromProps,
  requiredPermissions,
  showErrorPage,
  ...rest
}) => {
  const isLoggingIn = useSelector(createLoadingSelector(['LOGIN']))
  const isGetCurrentUserLoading = useSelector(
    createLoadingSelector(['GET_CURRENT_USER']),
  )
  const isLoggedIn = !!useSelector(getLoggedInSelector)
  const location = useLocation()

  return (
    <Route
      {...rest}
      render={props => {
        if (isLoggingIn || isGetCurrentUserLoading) {
          return <Spinner name='circle' />
        }
        if (isLoggedIn) {
          return (
            <BaseDataLoader>
              <RequiredPermissions
                requiredPermissions={requiredPermissions}
                showErrorPage={showErrorPage}
              >
                {/* Render correct component for route */}
                {/* @ts-ignore */}
                <ComponentFromProps {...props} />
              </RequiredPermissions>
            </BaseDataLoader>
          )
        }

        // Deep-link to a private route (after the login, the user will be redirected to the actual target)
        return (
          <Redirect
            to={{
              pathname: '/anmelden',
              state: { from: location.pathname },
            }}
          />
        )
      }}
    />
  )
}
