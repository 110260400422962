import { useEffect } from 'react'
import { useLocation } from 'react-router'

/**
 * Handles the archive toggle by adding/removing an archive filter to `currentFilters` depending on location state
 * If `currentFilters.archive` exists and `location.state.archive` is not set, remove it
 * If `currentFilters.archive` does not exist and `location.state.archive` is set, add it
 * Else keep everything as-is
 *
 * Needs useEffect to react to changes in location state
 * The location state is local to this hook for self-containment purposes
 *
 * @param currentFilters state variable representing the current filters object
 * @param setCurrentFilters state-function to update the current filters object
 */
export const useArchive = ({ currentFilters, setCurrentFilters }) => {
  const location = useLocation<{ archive?: boolean }>()
  useEffect(() => {
    if (!currentFilters?.archive && location.state?.archive) {
      setCurrentFilters({ ...currentFilters, archive: true })
    }
    if (currentFilters?.archive && !location.state?.archive) {
      setCurrentFilters({ ...currentFilters, archive: false })
    }
  }, [location.state, currentFilters, setCurrentFilters])
}
