import { Form, useFormikContext } from 'formik'
import React, {
  Children,
  cloneElement,
  FC,
  ReactElement,
  useEffect,
} from 'react'

import { FilterProps, FilterValues } from './types'

export const FilterForm: FC<Required<Omit<FilterProps, 'status'>>> = ({
  addressList,
  children,
  companyList,
  containerList,
  fractionList,
  isLoading,
  shouldResetForm,
  length,
  initialValues,
}) => {
  const { handleBlur, handleChange, resetForm, setFieldValue, values } =
    useFormikContext<Partial<FilterValues>>()

  useEffect(() => {
    for (const elem in initialValues) {
      if (`${initialValues[elem] ?? ''}`.length > 0) {
        setFieldValue(elem, initialValues[elem])
      }
    }
  }, [initialValues, setFieldValue])

  useEffect(() => {
    if (shouldResetForm) {
      resetForm()
    }
  }, [resetForm, shouldResetForm])

  const childrenWithExtraProps = Children.map(children, child =>
    cloneElement(child as ReactElement, {
      addressList,
      companyList,
      containerList,
      fractionList: fractionList.filter(fraction => !fraction.parent),
      handleBlur,
      handleChange,
      isLoading,
      length,
      resetForm,
      shouldResetForm,
      setFieldValue,
      values,
    }),
  )

  return (
    <div className='filter'>
      <div className='filter__container'>
        <Form noValidate>{childrenWithExtraProps}</Form>
      </div>
    </div>
  )
}
