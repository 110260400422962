import React, { FC, useEffect, useState } from 'react'
import { Translate } from 'react-i18nify'
import Media from 'react-media'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { usePageDimensions } from 'components/common/PageDimensions'
import { BREAKPOINT } from 'constants/design'

import { CallToActionType, PageType } from '../../constants'

import { CallToAction } from './CallToAction'

interface CallToActionSectionProps {
  callToAction: CallToActionType
  offer?: Offer
  pageType: PageType
  user?: User
  renameBtnToAction?: boolean
  onCloseMobileActions?: () => void
}

/**
 * Component for handling different display modes of the call-to-action section in InquiryDetails and OrderCreate pages
 */
export const CallToActionSection: FC<CallToActionSectionProps> = ({
  callToAction,
  offer,
  pageType,
  user,
  renameBtnToAction = false,
  onCloseMobileActions = () => undefined,
}) => {
  const [ctaOpen, setCtaOpen] = useState(false)
  const { footerBottomPos } = usePageDimensions()

  useEffect(() => {
    if (callToAction.hideMoreInfo === false) setCtaOpen(true)
  }, [callToAction.hideMoreInfo])

  // prematurely return and show no CTA Section at all if this is the create offer page and it's your own offer
  if (
    (pageType === PageType.OFFER || pageType === PageType.CREATE_OFFER) &&
    offer &&
    user &&
    offer.customer_company === user.company
  ) {
    return <></>
  }

  return (
    <Media query={{ maxWidth: BREAKPOINT.XLARGE - 1 }}>
      {isSmallScreen =>
        isSmallScreen ? (
          // create Form visible = either show Agreement/New Order Form or New Offer Form
          <div
            style={{
              bottom: footerBottomPos,
            }}
            className='inquiry-details-page__cta--sticky-bottom uk-width-1-1'
          >
            {ctaOpen || !!callToAction.hideMoreInfo ? (
              <CallToAction
                callToAction={callToAction}
                handleCtaToggle={() => {
                  setCtaOpen(false)
                  onCloseMobileActions()
                }}
                hideMoreInfo={!!callToAction.hideMoreInfo}
                isSmallScreen
                renameBtnToAction={renameBtnToAction}
              />
            ) : (
              <Button
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                onClick={() => setCtaOpen(true)}
                fullWidth
                dataTestId='inquiry-details-show-more-button'
              >
                <Translate
                  value={`inquiryDetailsTranslations.cta.buttons.${
                    !renameBtnToAction ? 'showInfo' : 'showAction'
                  }`}
                />
              </Button>
            )}
          </div>
        ) : (
          <div
            className='inquiry-details-page__cta'
            data-uk-sticky='top: 10; offset: 100'
          >
            <CallToAction
              callToAction={callToAction}
              hideMoreInfo={!!callToAction.hideMoreInfo}
              renameBtnToAction={renameBtnToAction}
            />
          </div>
        )
      }
    </Media>
  )
}
