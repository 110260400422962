import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import ContainerActionTypes from '../actiontypes/container'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetContainer
 * @description gets list of containers.
 */
const handleGetContainer = createLogic({
  type: [ContainerActionTypes.GET_CONTAINERS_REQUEST],
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/container/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ContainerActionTypes.GET_CONTAINERS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.container.handleGetContainer.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ContainerActionTypes.GET_CONTAINERS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetContainerContent
 * @description gets content text of a container.
 */
export const handleGetContainerContent = createLogic({
  type: ContainerActionTypes.GET_CONTAINER_CONTENT_REQUEST,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/container-content/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ContainerActionTypes.GET_CONTAINER_CONTENT_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.container.handleGetContainerContents.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ContainerActionTypes.GET_CONTAINER_CONTENT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetContainerForFilter
 * @description gets a list of all container
 */
export const handleGetContainerForFilter = createLogic({
  type: ContainerActionTypes.GET_CONTAINER_FOR_FILTER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/container/for-filter/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ContainerActionTypes.GET_CONTAINER_FOR_FILTER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.container.handleGetContainer.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ContainerActionTypes.GET_CONTAINER_FOR_FILTER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [
  handleGetContainer,
  handleGetContainerContent,
  handleGetContainerForFilter,
]
