import { Form, Formik } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'
import { updateEPDDocument } from 'actions/invoice'

import { ToggleDunningBlockForm } from './ToggleDunningBlockForm'

interface ToggleDunningBlockModalProps {
  isOpen?: boolean
  onClose?: () => void
  invoice?: Invoice
  dunningBlock?: boolean
}

export type ToggleDunningBlockFormValues = Partial<{
  dunning_block_reason_text: string
  dunning_block_reason: string
}>

export const ToggleDunningBlockModal: FC<ToggleDunningBlockModalProps> = ({
  isOpen = false,
  onClose = () => undefined,
  invoice = undefined,
  dunningBlock = false,
}) => {
  const dispatch = useDispatch()

  const idToggleDunningBlockModalHeadline = uniqueId()

  const handleSubmit = values => {
    onClose()
    dispatch(
      updateEPDDocument({
        ...values,
        invoice_id: invoice ? invoice.id : 0,
        dunning_block: !dunningBlock,
      }),
    )
  }

  return (
    <Modal
      ariaDescribedBy={idToggleDunningBlockModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={
          dunningBlock
            ? I18n.t(
                'bookkeepingTranslations.toggleDunningBlockModal.titleDeactivate',
              )
            : I18n.t('bookkeepingTranslations.toggleDunningBlockModal.title')
        }
        titleId={idToggleDunningBlockModalHeadline}
      />
      <Formik
        initialValues={{
          dunning_block_reason_text: '',
          dunning_block_reason: '',
        }}
        onSubmit={(values: ToggleDunningBlockFormValues) =>
          handleSubmit(values)
        }
        validationSchema={() => {
          if (!dunningBlock) {
            return yup.object().shape({
              dunning_block_reason_text: yup.string(),
              dunning_block_reason: yup
                .string()
                .required(
                  I18n.t(
                    'bookkeepingTranslations.toggleDunningBlockModal.fields.changeDunningBlockCommentRequired',
                  ),
                ),
            })
          }
          return yup.object().shape({})
        }}
      >
        <Form
          className='set-dunning-block-form'
          data-testid='set-dunning-block-form'
          noValidate
        >
          <ToggleDunningBlockForm
            onClose={onClose}
            dunning_block={dunningBlock}
          />
        </Form>
      </Formik>
    </Modal>
  )
}
