import { connect } from 'react-redux'

import { getDiscounts } from 'actions/discount'

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getDiscounts: (page, filters, sortedBy) =>
    dispatch(getDiscounts(page, filters, sortedBy)),
})

export default connect(null, mapDispatchToProps)
