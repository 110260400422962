import classNames from 'classnames'
import german from 'hyphenation.de'
import Hypher from 'hypher'
import {
  shape,
  func,
  bool,
  string,
  arrayOf,
  oneOfType,
  number,
} from 'prop-types'
import React, { Component } from 'react'
import { I18n } from 'react-i18nify'
import Select from 'react-select'
import Spinner from 'react-spinkit'

import FormField from '../../index'
import { FieldScheme } from '../../schemes'

import connector from './connector'

export const RESET_FILTER = -1

export class SearchableSelect extends Component {
  static propTypes = {
    actions: shape({
      form: shape({
        change: func.isRequired,
      }),
    }),
    disabled: bool,
    isLoading: bool,
    label: string.isRequired,
    field: FieldScheme.isRequired,
    options: arrayOf(
      shape({
        id: oneOfType([number.isRequired, string.isRequired]).isRequired,
        label: string.isRequired,
      }),
    ),
    onInputChange: func,
  }

  static defaultProps = {
    actions: null,
    disabled: false,
    isLoading: false,
    options: [],
    onInputChange: null,
  }

  state = {
    activeItem: {},
  }

  /**
   * Handles changes of the active item.
   *
   * @param {object} activeItem New active item.
   */
  onActiveItemChange = activeItem => {
    const { actions, field } = this.props

    if (activeItem && activeItem.key === RESET_FILTER) {
      this.setState({ activeItem: {} }) // Reset filter

      actions.form.change(field.model, null)
    } else {
      this.setState({ activeItem })

      actions.form.change(field.model, activeItem.key)
    }
  }

  /**
   * Maps the received props to the required internal structure and prepends an option to reset the filter.
   *
   * @param {object[]} options Options received from parent component.
   *
   * @returns {object[]} Mapped options.
   */
  mapOptions = options => {
    const allOption = {
      label: 'Alle',
      key: RESET_FILTER,
    }

    const h = new Hypher(german)

    const mappedOptions = options.map(option => ({
      label: h.hyphenateText(option.label),
      key: option.id,
    }))

    return [allOption].concat(mappedOptions)
  }

  /**
   * Returns whether the passed in item is currently selected.
   *
   * @param {object} itemToCheck Item to check (only key property is required).
   *
   * @returns {boolean} True = Checked item is currently selected.
   */
  isOptionSelected = ({ key }) =>
    this.state.activeItem && this.state.activeItem.key === key

  /**
   * Renders the field component.
   *
   * @returns {*} Field component.
   */
  renderFieldComponent = () => {
    const { field, options, onInputChange } = this.props

    if (!field.model) return null

    return (
      <div
        className={classNames(
          { 'react-select--show-arrow': !this.props.isLoading },
          { 'uk-disabled': this.props.disabled },
          { 'with-caption': this.props.isLoading },
          { 'button-control': this.props.isLoading },
        )}
      >
        <Select
          className='react-select-container'
          classNamePrefix='react-select'
          options={this.mapOptions(options)}
          // Fix to show placeholder initially. In order to be able to reset the value when the filter reset button
          // is pressed, we also need to check the current field value
          value={
            this.state.activeItem.key && this.props.field.value
              ? this.state.activeItem
              : null
          }
          placeholder={I18n.t('general.placeholder.all')}
          noOptionsMessage={() => I18n.t('general.form.noOptions')}
          backspaceRemovesValue={false}
          isOptionSelected={this.isOptionSelected}
          isSearchable
          onChange={this.onActiveItemChange}
          onInputChange={value => {
            if (onInputChange) onInputChange(value)
          }}
        />

        {this.props.isLoading && (
          <div className='caption symbol'>
            <Spinner
              fadeIn='none' // Show immediately
              name='circle'
              color='grey'
            />
          </div>
        )}
      </div>
    )
  }

  render() {
    const { actions, field, label, ...rest } = this.props

    return (
      <FormField
        actions={actions}
        field={field}
        fieldComponent={this.renderFieldComponent()}
        label={label}
        {...rest}
      />
    )
  }
}

export default connector(SearchableSelect)
