import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ConditionsFormProps {}

// @TODO remove when filled with content
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ConditionsForm = (props: ConditionsFormProps) => {
  const placeholder = 'Placeholder'
  return <>{placeholder}</>
}
