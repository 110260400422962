import { I18n } from 'react-i18nify'

export const FILE_CLASSES = {
  CLASS_QM_CERTIFICATE: 1,
  CLASS_ENM_CERTIFICATE: 2,
  CLASS_EFB_CERTIFICATE: 3,
  CLASS_CONDITIONS: 4,
  CLASS_DATA_PROTECTION: 5,
  CLASS_FLYER: 6,
  CLASS_CRITERIA: 7,
  CLASS_DESTRUCTION_CONDITIONS: 8,
  CLASS_EMPTO_LOGO: 9,
  CLASS_PRICE_SHEET: 10,
}

export const FILE_CLASSES_CERTIFICATES = [
  FILE_CLASSES.CLASS_EFB_CERTIFICATE,
  FILE_CLASSES.CLASS_QM_CERTIFICATE,
  FILE_CLASSES.CLASS_ENM_CERTIFICATE,
]

export const FILE_CLASSES_DOCUMENTS = [
  FILE_CLASSES.CLASS_CONDITIONS,
  FILE_CLASSES.CLASS_DATA_PROTECTION,
  FILE_CLASSES.CLASS_CRITERIA,
  FILE_CLASSES.CLASS_DESTRUCTION_CONDITIONS,
  FILE_CLASSES.CLASS_EMPTO_LOGO,
  FILE_CLASSES.CLASS_FLYER,
  FILE_CLASSES.CLASS_PRICE_SHEET,
]

export const DOCUMENT_LIST = {
  [FILE_CLASSES.CLASS_QM_CERTIFICATE]: {
    name: `${I18n.t('emptoDownloadsTranslations.documentTitle.qmCertificate')}`,
  },
  [FILE_CLASSES.CLASS_ENM_CERTIFICATE]: {
    name: `${I18n.t(
      'emptoDownloadsTranslations.documentTitle.enmCertificate',
    )}`,
  },
  [FILE_CLASSES.CLASS_EFB_CERTIFICATE]: {
    name: `${I18n.t(
      'emptoDownloadsTranslations.documentTitle.efbCertificate',
    )}`,
  },
  [FILE_CLASSES.CLASS_CONDITIONS]: {
    name: `${I18n.t(
      'emptoDownloadsTranslations.documentTitle.participationConditions',
    )}`,
  },
  [FILE_CLASSES.CLASS_DATA_PROTECTION]: {
    name: `${I18n.t(
      'emptoDownloadsTranslations.documentTitle.dataProtection',
    )}`,
  },
  [FILE_CLASSES.CLASS_FLYER]: {
    name: `${I18n.t('emptoDownloadsTranslations.documentTitle.flyer')}`,
  },
  [FILE_CLASSES.CLASS_CRITERIA]: {
    name: `${I18n.t(
      'emptoDownloadsTranslations.documentTitle.sortingCriteria',
    )}`,
  },
  [FILE_CLASSES.CLASS_DESTRUCTION_CONDITIONS]: {
    name: `${I18n.t(
      'emptoDownloadsTranslations.documentTitle.destructionConditions',
    )}`,
  },
  [FILE_CLASSES.CLASS_EMPTO_LOGO]: {
    name: `${I18n.t('emptoDownloadsTranslations.documentTitle.emptoLogo')}`,
  },
  [FILE_CLASSES.CLASS_PRICE_SHEET]: {
    name: `${I18n.t('emptoDownloadsTranslations.documentTitle.priceSheet')}`,
  },
}

export default {}
