import { ROLE_KEYS } from '../../Step1/helpers'
import { BILLING_MODEL, PRICING } from '../DefineNetPriceForm'

import { SURCHARGE_REDUCTION } from './SinglePriceFormFields'
import { clearFieldsType } from './types'

/**
 * Clears a Formik forms' billing model field values, depending on the incoming role.
 *
 * This function is supposed to be called each time the billingModel fields for a role change, so that the fields of
 * the _other_ billingModel are cleared and not unnecessarily stored in the form.
 *
 * @param {clearFieldsPropsType} props An object containing a string value (one of the billingModel choices), the role
 * of a company (Either 'customer' or 'partner') and the Formik setFieldValue function.
 */
export const clearBillingModelFields: clearFieldsType = ({
  value,
  role,
  setFieldValue,
}) => {
  if (value === BILLING_MODEL.SINGLE_PRICE) {
    // clear customer package price fields
    if (role === ROLE_KEYS.CUSTOMER) {
      setFieldValue('customer_cost_container', undefined)
      setFieldValue('customer_package_price', undefined)
      setFieldValue('customer_compensation_container', undefined)
      setFieldValue('customer_disposal_cost_container', undefined)
    }

    // clear partner package price fields
    if (role === ROLE_KEYS.PARTNER) {
      setFieldValue('partner_disposal_cost_container', undefined)
      setFieldValue('partner_cost_container', undefined)
      setFieldValue('partner_compensation_container', undefined)
    }
  } else if (value === BILLING_MODEL.PACKAGE_PRICE) {
    // clear customer single price fields
    if (role === ROLE_KEYS.CUSTOMER) {
      setFieldValue('customer_rent_price', undefined)
      setFieldValue('customer_transport_price', undefined)
      setFieldValue('customer_disposal_cost_ton', undefined)
      setFieldValue('customer_disposal_cost_container', undefined)
      setFieldValue('customer_index', undefined)
      setFieldValue('customer_index_surcharge_reduction', 'surcharge')
      setFieldValue('customer_compensation_ton', undefined)
      setFieldValue('customer_handle_cost_ton', undefined)
    }
  } else if (value === BILLING_MODEL.CONTAINER_PRICE) {
    if (role === ROLE_KEYS.CUSTOMER) {
      // clear customer package price fields
      setFieldValue('customer_package_price', undefined)

      // clear customer single price fields
      setFieldValue('customer_transport_price', undefined)
    }
    // clear partner single price fields
    if (role === ROLE_KEYS.PARTNER) {
      setFieldValue('partner_transport_price', undefined)
      setFieldValue('partner_disposal_cost_ton', undefined)
      setFieldValue('partner_index', undefined)
      setFieldValue('partner_index_surcharge_reduction', 'surcharge')
      setFieldValue('partner_compensation_ton', undefined)
      setFieldValue('partner_handle_cost_ton', undefined)
    }
  }
}

/**
 * Clears a Formik forms' pricing field values, depending on the incoming role.
 *
 * This function is supposed to be called each time the pricing fields for a role change, so that the fields of
 * the _other_ pricing are cleared and not unnecessarily stored in the form.
 *
 * @param {clearFieldsPropsType} props An object containing a string value (one of the pricing choices), the role
 * of a company (Either 'customer' or 'partner') and the Formik setFieldValue function.
 */
export const clearPricingFields: clearFieldsType = ({
  value,
  role,
  setFieldValue,
}) => {
  if (value === PRICING.DISPOSAL) {
    // clear customer compensation price fields
    if (role === ROLE_KEYS.CUSTOMER) {
      setFieldValue('customer_index', undefined)
      setFieldValue('customer_surcharge', undefined)
      setFieldValue('customer_reduction', undefined)
      setFieldValue('customer_compensation_ton', undefined)
      setFieldValue('customer_handle_cost_ton', undefined)
      setFieldValue('customer_cost_container', undefined)
      setFieldValue('customer_compensation_container', undefined)
    }

    // clear partner compensation price fields
    if (role === ROLE_KEYS.PARTNER) {
      setFieldValue('partner_index', undefined)
      setFieldValue('partner_surcharge', undefined)
      setFieldValue('partner_reduction', undefined)
      setFieldValue('partner_compensation_ton', undefined)
      setFieldValue('partner_handle_cost_ton', undefined)
      setFieldValue('partner_cost_container', undefined)
      setFieldValue('partner_compensation_container', undefined)
    }
  } else if (value === PRICING.COMPENSATION) {
    // clear customer disposal price fields
    if (role === ROLE_KEYS.CUSTOMER) {
      setFieldValue('customer_package_price', undefined)
      setFieldValue('customer_disposal_cost_ton', undefined)
      setFieldValue('customer_disposal_cost_container', undefined)
    }

    // clear partner disposal price fields
    if (role === ROLE_KEYS.PARTNER) {
      setFieldValue('partner_disposal_cost_ton', undefined)
      setFieldValue('partner_disposal_cost_container', undefined)
    }
  }
}

export const resetAllPricingFields = (values, setValues) => {
  setValues({
    ...values,
    // CUSTOMER
    customer_pricing: PRICING.DISPOSAL,
    customer_billing_model: BILLING_MODEL.SINGLE_PRICE,
    customer_index: undefined,
    customer_index_month: 0,
    customer_surcharge: undefined,
    customer_reduction: undefined,
    customer_compensation_ton: undefined,
    customer_handle_cost_ton: undefined,
    customer_cost_container: undefined,
    customer_compensation_container: undefined,
    customer_package_price: undefined,
    customer_disposal_cost_ton: undefined,
    customer_disposal_cost_container: undefined,
    customer_rent_price: undefined,
    customer_transport_price: undefined,
    customer_index_surcharge_reduction: SURCHARGE_REDUCTION.SURCHARGE,

    //PARTNER
    partner_pricing: PRICING.DISPOSAL,
    partner_billing_model: BILLING_MODEL.CONTAINER_PRICE,
    partner_index: undefined,
    partner_index_month: 0,
    partner_surcharge: undefined,
    partner_reduction: undefined,
    partner_compensation_ton: undefined,
    partner_handle_cost_ton: undefined,
    partner_cost_container: undefined,
    partner_compensation_container: undefined,
    partner_rent_price: undefined,
    partner_disposal_cost_ton: undefined,
    partner_disposal_cost_container: undefined,
    partner_transport_price: undefined,
    partner_index_surcharge_reduction: SURCHARGE_REDUCTION.SURCHARGE,
  })
}

/**
 * Returns a boolean depending on whether the input is valid or not.
 *
 * Validates, that the input is never more than just one zero. Allows any other input.
 *
 * @param {string} formValue
 * @param {string} inputValue
 */
export const inputIsValid = (formValue?: string, inputValue?: string) => {
  return !(
    formValue &&
    formValue === '0' &&
    inputValue &&
    inputValue[0] === '0'
  )
}
