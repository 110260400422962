import { Form, withFormik } from 'formik'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import * as yup from 'yup'
import { bool, func, number, shape, string } from 'prop-types'

import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import Icon from 'components/common/Fontello/index'
import { ApiValidationMessages } from 'components/common/Form/components/ApiValidationMessages'
import { passwordIsSafe } from 'components/common/Form/validators'
import InputPassword from 'components/common/InputPassword'
import { ProgressButton } from 'components/common/ProgressButton'
import withErrorBoundary from 'helper/withErrorBoundary'
import { ApiErrorScheme } from 'schemes/error'

import connector from './connector'

export class PasswordResetConfirm extends Component {
  static propTypes = {
    error: shape(ApiErrorScheme),
    errors: shape({
      password: string,
      password_repeat: string,
    }).isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    handleSubmit: func.isRequired,
    isLoading: bool,
    isSubmitting: bool.isRequired,
    isValid: bool.isRequired,
    submitCount: number.isRequired,
    touched: shape({
      password: bool,
      password_repeat: bool,
    }).isRequired,
    values: shape({
      password: string,
      password_repeat: string,
    }).isRequired,
  }

  static defaultProps = {
    error: null,
    isLoading: false,
  }

  render() {
    const {
      // this is the error from the store error reducer . Used to get the api error for ApiValidationMessages component
      error,
      // this is the error from the Formik api. Used to get the form validation errors
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      isLoading,
      isSubmitting,
      isValid,
      submitCount,
      touched,
      values,
    } = this.props

    return (
      <>
        <Helmet>
          <title>{I18n.t('pageTitles.forgotPasswordReset')}</title>
        </Helmet>

        <div className='password-reset-container uk-align-center uk-width-1-1@s uk-width-3-5@l uk-width-1-3@xl'>
          <div className='uk-card uk-card-default'>
            <div className='uk-card-body'>
              <div>
                <h2>
                  <Translate value='forgetPasswordTranslations.passwordResetForm.title' />
                </h2>
              </div>

              <Form
                className='password-reset-form'
                data-testid='password-reset-form'
                onSubmit={handleSubmit}
                noValidate // Disable browser validation
              >
                {/* This Api Validation message is needed here to get a customized error message
                 (z.B. Reason for not being possible to change password) */}
                <ApiValidationMessages error={error} />

                <div className='uk-position-relative uk-width-1-1'>
                  <InputPassword
                    dataTestId='password-reset-form-password-input'
                    dataTestIdError='password-reset-form-group-error'
                    error={
                      submitCount > 0 && touched.password ? errors.password : ''
                    }
                    isRequired={values.password === ''}
                    label={I18n.t(
                      'forgetPasswordTranslations.passwordResetForm.password.label',
                    )}
                    maxLength={50}
                    name='password'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={I18n.t(
                      'forgetPasswordTranslations.passwordResetForm.password.placeholder',
                    )}
                    value={values.password}
                  />
                </div>

                <div className='uk-position-relative uk-width-1-1'>
                  <InputPassword
                    dataTestId='password-reset-password-repeat-input'
                    dataTestIdError='password-reset-form-group-error'
                    error={
                      submitCount > 0 && touched.password_repeat
                        ? errors.password_repeat
                        : ''
                    }
                    isRequired={values.password_repeat === ''}
                    label={I18n.t(
                      'forgetPasswordTranslations.passwordResetForm.passwordRepeat.label',
                    )}
                    maxLength={50}
                    name='password_repeat'
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder={I18n.t(
                      'forgetPasswordTranslations.passwordResetForm.passwordRepeat.placeholder',
                    )}
                    value={values.password_repeat}
                  />
                </div>
                <div className='uk-margin'>
                  <ProgressButton
                    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                    dataTestId='user-profile-change-password-form-submit'
                    fullWidth
                    isDisabled={
                      (submitCount > 0 && !isValid) ||
                      (isSubmitting && isLoading)
                    }
                    isLoading={isSubmitting && isLoading}
                    onClick={handleSubmit}
                  >
                    <Icon name='enter' />
                    <Translate value='forgetPasswordTranslations.passwordResetForm.submit' />
                  </ProgressButton>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default compose(
  withRouter,
  withErrorBoundary,
  connector,
  // @TODO bb use <Formik> instead
  withFormik({
    handleSubmit: (values, { props, setFieldError }) => {
      props.confirmNewPassword(
        props.match.params.uid,
        props.match.params.token,
        values.password,
        values.password_repeat,
        props.history,
        { setError: (key, string) => setFieldError(key, string) },
      )
    },
    mapPropsToValues: () => ({
      password: '',
      password_repeat: '',
    }),
    validationSchema: () =>
      yup.object().shape({
        password: yup
          .string()
          .required(
            I18n.t(
              'forgetPasswordTranslations.passwordResetForm.password.required',
            ),
          )
          .test(
            'secure-password',
            I18n.t(
              'forgetPasswordTranslations.passwordResetForm.password.notSecure',
            ),
            passwordIsSafe,
          ),
        password_repeat: yup
          .string()
          .required(
            I18n.t(
              'forgetPasswordTranslations.passwordResetForm.passwordRepeat.required',
            ),
          )
          .oneOf(
            [yup.ref('password')],
            I18n.t(
              'forgetPasswordTranslations.passwordResetForm.passwordRepeat.notEqual',
            ),
          ),
      }),
  }),
)(PasswordResetConfirm)
