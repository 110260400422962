import '../../styles.scss'

import { Form, useFormikContext } from 'formik'
import React, { FC, useEffect, useMemo, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getDszFractions } from 'actions/dsz'
import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { InputDecimal } from 'components/common/InputDecimal'
import { InputNumber } from 'components/common/InputNumber'
import { ProgressButton } from 'components/common/ProgressButton'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { Option } from 'components/common/StaticCombobox/StaticCombobox'
import { getDszFractionsSelector } from 'selectors/dsz'

import { AddPriceModalFormValues, DszPriceManagementShopType } from './index'

export const AddPriceModalForm: FC<{
  handleCloseModal: () => void
  isEditing?: boolean
}> = ({ handleCloseModal, isEditing = false }) => {
  const dispatch = useDispatch()

  const {
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
    resetForm,
    setFieldValue,
    setFieldTouched,
    submitCount,
    touched,
    validateForm,
    values,
  } = useFormikContext<AddPriceModalFormValues>()

  const dszShopOptions = useMemo(
    () => [
      {
        value: DszPriceManagementShopType.SHOP_VERPACKUNGSLIZENZIERUNG,
        label: 'verpackungslizenzierung.de',
      },
      { value: DszPriceManagementShopType.SHOP_ZMART_CHINA, label: 'zmart.cn' },
    ],
    [],
  )

  const dszFractionList = useSelector<any, DszFraction[]>(
    getDszFractionsSelector,
  )
  const [dszFractionOptions, setDszFractionOptions] = useState<Option[]>([])
  const [selectedDszFractionOption, setSelectedDszFractionOption] =
    useState<Option>()
  const [selectedDszShopOption, setSelectedDszShopOption] = useState<Option>()

  const onChange = async (e: React.ChangeEvent<any>) => {
    handleChange(e)
    if (submitCount > 0) {
      await validateForm()
    }
  }

  const isFractionOrShopValid = name => !isNaN(values[name])

  const isYearValid = name =>
    Number(values[name]) >= 2019 && Number(values[name]) <= 2050

  const isFieldValid = name =>
    (values[name] ?? '') && values[name].length > 0 && !errors[name]

  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0
      ? (errors[name] as string)
      : undefined

  const requiredTouched =
    touched.fraction &&
    touched.price_xs &&
    touched.price_s &&
    touched.price_m &&
    touched.price_l &&
    touched.price_xl &&
    touched.price_xxl &&
    touched.shop &&
    touched.year

  useEffect(() => {
    dispatch(getDszFractions())
  }, [dispatch])

  useEffect(() => {
    const shopSelection = dszShopOptions.find(
      item => item.value === Number(values.shop),
    )
    setSelectedDszShopOption({
      label: shopSelection?.label ?? '',
      value: shopSelection?.value ?? '',
    })
  }, [dszShopOptions, values.shop])

  useEffect(() => {
    setDszFractionOptions(
      dszFractionList.map(item => ({
        value: item.id,
        label: item.shop_name,
      })),
    )
  }, [dszFractionList])

  useEffect(() => {
    const coarseFraction = dszFractionList.find(
      item => item.id === Number(values.fraction),
    )
    setSelectedDszFractionOption({
      label: coarseFraction?.shop_name ?? '',
      value: coarseFraction?.id ?? '',
    })
  }, [dszFractionList, values.fraction])

  return (
    <div className='uk-modal-body'>
      <Form
        className='uk-panel makler-premium-form add-price-modal-form'
        onSubmit={handleSubmit}
        noValidate // Disable browser validation
      >
        <div className='uk-width-1-1 uk-margin-bottom'>
          <StaticCombobox
            label={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.shop',
            )}
            name='shop'
            noResultsText={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.error.noInputResultsShop',
            )}
            options={dszShopOptions}
            onSelectionChange={async event => {
              setFieldTouched('shop')
              setFieldValue('shop', Number(event.target.value))
              if (submitCount > 0) await validateForm()
            }}
            placeholder={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.shop',
            )}
            selectedOption={selectedDszShopOption}
            withCheckmark
            showCheckmark={isFractionOrShopValid}
            isDisabled={isEditing}
          />
        </div>
        <div className='uk-width-1-1 uk-margin-bottom'>
          <InputNumber
            error={getFieldError}
            label={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.year',
            )}
            minNumber={2019}
            maxLength={4}
            name='year'
            onBlur={handleBlur}
            onChange={async event => {
              await onChange(event)
              setFieldTouched('year')
              if (submitCount > 0) await validateForm()
            }}
            placeholder={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.year',
            )}
            value={values.year}
            showCheckmark={isYearValid}
            withCheckmark
            disabled={isEditing}
          />
          {touched.year && errors.year ? (
            <p style={{ color: 'red' }}>
              {I18n.t(
                'priceManagementPageTranslations.addPriceModal.modalFields.error.year',
              )}
            </p>
          ) : null}
        </div>
        <div className='uk-width-1-1 uk-margin-bottom'>
          <StaticCombobox
            label={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.fraction',
            )}
            name='fraction'
            noResultsText={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.error.noInputResultsFraction',
            )}
            options={dszFractionOptions}
            onSelectionChange={async event => {
              setFieldTouched('fraction')
              setFieldValue('fraction', Number(event.target.value))
              if (submitCount > 0) await validateForm()
            }}
            placeholder={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.fraction',
            )}
            selectedOption={selectedDszFractionOption}
            withCheckmark
            showCheckmark={isFractionOrShopValid}
            isDisabled={isEditing}
          />
        </div>
        <div className='uk-width-1-1 uk-margin-bottom'>
          <InputDecimal
            error={getFieldError}
            label={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.price_xs',
            )}
            name='price_xs'
            onBlur={handleBlur}
            onChange={async event => {
              await onChange(event)
              setFieldTouched('price_xs')
              if (submitCount > 0) await validateForm()
            }}
            placeholder={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.price_xs',
            )}
            value={values.price_xs}
            showCheckmark={isFieldValid}
            withCheckmark
            maxValue={99999.99}
            minValue={-99999.99}
          />
        </div>
        <div className='uk-width-1-1 uk-margin-bottom'>
          <InputDecimal
            error={getFieldError}
            label={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.price_s',
            )}
            name='price_s'
            onBlur={handleBlur}
            onChange={async event => {
              await onChange(event)
              setFieldTouched('price_s')
              if (submitCount > 0) await validateForm()
            }}
            placeholder={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.price_s',
            )}
            value={values.price_s}
            showCheckmark={isFieldValid}
            withCheckmark
            maxValue={99999.99}
            minValue={-99999.99}
          />
        </div>
        <div className='uk-width-1-1 uk-margin-bottom'>
          <InputDecimal
            error={getFieldError}
            label={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.price_m',
            )}
            name='price_m'
            onBlur={handleBlur}
            onChange={async event => {
              await onChange(event)
              setFieldTouched('price_m')
              if (submitCount > 0) await validateForm()
            }}
            placeholder={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.price_m',
            )}
            value={values.price_m}
            showCheckmark={isFieldValid}
            withCheckmark
            maxValue={99999.99}
            minValue={-99999.99}
          />
        </div>
        <div className='uk-width-1-1 uk-margin-bottom'>
          <InputDecimal
            error={getFieldError}
            label={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.price_l',
            )}
            name='price_l'
            onBlur={handleBlur}
            onChange={async event => {
              await onChange(event)
              setFieldTouched('price_l')
              if (submitCount > 0) await validateForm()
            }}
            placeholder={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.price_l',
            )}
            value={values.price_l}
            showCheckmark={isFieldValid}
            withCheckmark
            maxValue={99999.99}
            minValue={-99999.99}
          />
        </div>
        <div className='uk-width-1-1 uk-margin-bottom'>
          <InputDecimal
            error={getFieldError}
            label={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.price_xl',
            )}
            name='price_xl'
            onBlur={handleBlur}
            onChange={async event => {
              await onChange(event)
              setFieldTouched('price_xl')
              if (submitCount > 0) await validateForm()
            }}
            placeholder={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.price_xl',
            )}
            value={values.price_xl}
            showCheckmark={isFieldValid}
            withCheckmark
            maxValue={99999.99}
            minValue={-99999.99}
          />
        </div>
        <div className='uk-width-1-1 uk-margin-bottom'>
          <InputDecimal
            error={getFieldError}
            label={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.price_xxl',
            )}
            name='price_xxl'
            onBlur={handleBlur}
            onChange={async event => {
              await onChange(event)
              setFieldTouched('price_xxl')
              if (submitCount > 0) await validateForm()
            }}
            placeholder={I18n.t(
              'priceManagementPageTranslations.addPriceModal.modalFields.text.price_xxl',
            )}
            value={values.price_xxl}
            showCheckmark={isFieldValid}
            withCheckmark
            maxValue={99999.99}
            minValue={-99999.99}
          />
        </div>
        <div className='uk-grid uk-flex-right uk-margin-bottom uk-margin-top'>
          <div className='uk-flex uk-margin-bottom uk-margin-top'>
            <div>
              <Button
                backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                className='uk-margin-right'
                onClick={() => {
                  resetForm()
                  handleCloseModal()
                }}
              >
                {I18n.t('general.button.cancel')}
              </Button>
            </div>
            <div
              className='uk-width-2-3 universal-file-upload--with-checkmark'
              data-uk-margin=''
            >
              <ProgressButton
                isLoading={isSubmitting}
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                fullWidth
                isDisabled={
                  // in create mode, disable if required fields weren't touched
                  !requiredTouched || !isValid || isSubmitting
                }
                onClick={handleSubmit}
              >
                <Translate value='general.button.save' />
              </ProgressButton>
            </div>
          </div>
        </div>
      </Form>
    </div>
  )
}
