import { Formik, FormikErrors } from 'formik'
import { electronicFormatIBAN, isValidBIC, isValidIBAN } from 'ibantools'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import { createPartner } from '../../actions/maklerpremium'
import { withApiErrorHandling } from '../../helper/withApiErrorHandling'
import withErrorBoundary from '../../helper/withErrorBoundary'

import { AddWasteDisposerInnerForm } from './AddWasteDisposerForm'
import { MAX_LENGTH } from './constants'

export type AddWasteDisposerFormValues = {
  company_name: string
  tax_number: string
  tax_id: string
  iban: string
  bic: string
  street: string
  house_number: string
  postal_code: string
  city: string
  // Central Contact Data for EPD
  has_central_contact: boolean
  central_contact_email: string
  central_contact_phone: string
  // Contact Person
  contact_person_is_male: boolean
  contact_person_first_name: string
  contact_person_last_name: string
  contact_person_position: string
  contact_person_phone: string
  contact_person_email: string
}

const AddWasteDisposerWrapperComponent: FC<{ onSuccess: () => void }> = ({
  onSuccess,
}) => {
  const dispatch = useDispatch()
  return (
    <Formik
      initialValues={{
        company_name: '',
        tax_number: '',
        tax_id: '',
        iban: '',
        bic: '',
        street: '',
        house_number: '',
        postal_code: '',
        city: '',
        // Central Contact Data for EPD
        has_central_contact: false,
        central_contact_email: '',
        central_contact_phone: '',
        // Contact Person
        contact_person_is_male: true,
        contact_person_first_name: '',
        contact_person_last_name: '',
        contact_person_position: '',
        contact_person_phone: '',
        contact_person_email: '',
      }}
      onSubmit={(values, formikProps) => {
        dispatch(
          createPartner(
            { ...values },
            {
              setErrorCallback: (key, string) =>
                formikProps.setFieldError(key, string),
              onSuccessCallback: () => onSuccess(),
            },
          ),
        )
        formikProps.setSubmitting(false)
      }}
      validationSchema={() =>
        yup.object().shape({
          company_name: yup.string(),
          iban: yup
            .string()
            .transform(value => electronicFormatIBAN(value))
            .test(
              'is-iban',
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.iban_length_invalid',
              ),
              value => isValidIBAN(value ?? ''),
            )
            .required(
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.iban',
              ),
            ),
          bic: yup
            .string()
            .test(
              'is-bic',
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.bic_length_invalid',
              ),
              value => isValidBIC(value ?? ''),
            )
            .required(
              I18n.t('maklerpremium.addWasteDisposerModal.form.validation.bic'),
            ),
          street: yup
            .string()
            .required(
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.street',
              ),
            )
            .max(
              MAX_LENGTH.STREET,
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.too_long_message',
              ),
            ),
          house_number: yup
            .string()
            .required(
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.house_number',
              ),
            )
            .max(
              MAX_LENGTH.HOUSE_NUMBER,
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.too_long_message',
              ),
            ),
          postal_code: yup
            .string()
            .matches(
              /^\d{5}$/,
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.postal_code_invalid',
              ),
            )
            .required(
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.postal_code',
              ),
            ),
          city: yup
            .string()
            .required(
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.city',
              ),
            )

            .max(
              MAX_LENGTH.CITY,
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.too_long_message',
              ),
            ),
          // Central Contact Data for EPD
          has_central_contact: yup
            .boolean()
            .required(
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.has_central_contact',
              ),
            ),
          central_contact_email: yup
            .string()
            .email(I18n.t('message.validation.email.isEmail'))
            .max(
              MAX_LENGTH.MAIL_ADDRESS,
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.too_long_message',
              ),
            ),
          central_contact_phone: yup.string(),
          // Contact Person
          contact_person_is_male: yup.boolean(),
          contact_person_first_name: yup
            .string()
            .required(
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.contact_person_first_name',
              ),
            )
            .max(
              MAX_LENGTH.FIRST_NAME,
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.too_long_message',
              ),
            ),
          contact_person_last_name: yup
            .string()
            .required(
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.contact_person_last_name',
              ),
            )
            .max(
              MAX_LENGTH.LAST_NAME,
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.too_long_message',
              ),
            ),
          contact_person_position: yup
            .string()
            .max(
              MAX_LENGTH.POSITION,
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.too_long_message',
              ),
            ),
          contact_person_phone: yup
            .string()
            .max(
              MAX_LENGTH.PHONE_NUMBER,
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.too_long_message',
              ),
            ),
          contact_person_email: yup
            .string()
            .email(I18n.t('message.validation.email.isEmail'))
            .required(
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.contact_person_email',
              ),
            )
            .max(
              MAX_LENGTH.MAIL_ADDRESS,
              I18n.t(
                'maklerpremium.addWasteDisposerModal.form.validation.too_long_message',
              ),
            ),
          tax_id: yup
            .string()
            .matches(/^DE\d{9}$/i, I18n.t('message.validation.germanTaxId')),
          tax_number: yup
            .string()
            .matches(
              /^\d{10,11}|[\d/]{12,13}$/,
              I18n.t('message.validation.germanTaxNumber'),
            ),
        })
      }
      validate={(values: AddWasteDisposerFormValues) => {
        const errors: FormikErrors<AddWasteDisposerFormValues> = {}

        if (!values.tax_id && !values.tax_number) {
          errors.tax_id = I18n.t('message.validation.required')
          errors.tax_number = I18n.t('message.validation.required')
        }
        if (values.company_name.length === 0) {
          errors.company_name = I18n.t(
            'maklerpremium.addWasteDisposerModal.form.validation.company_name',
          )
        }
        if (
          values.has_central_contact &&
          values.central_contact_email.length === 0 &&
          values.central_contact_phone.length === 0
        ) {
          errors.central_contact_email = I18n.t(
            'maklerpremium.addWasteDisposerModal.form.validation.central_contact_email',
          )
          errors.central_contact_phone = I18n.t(
            'maklerpremium.addWasteDisposerModal.form.validation.central_contact_phone',
          )
        }
        return errors
      }}
    >
      <AddWasteDisposerInnerForm />
    </Formik>
  )
}

export const AddWasteDisposerWrapper = withErrorBoundary(
  withApiErrorHandling(AddWasteDisposerWrapperComponent),
)
