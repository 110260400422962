import moment from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { DropDownInput } from 'components/common/DropDownInput'
import InputDate from 'components/common/InputDate/index'
import { InputDecimal } from 'components/common/InputDecimal'
import { Textarea } from 'components/common/Textarea'
import {
  INQUIRY_ORDER_TYPE,
  INQUIRY_TIME_OF_DAY,
  INQUIRY_TIMES_OF_DAY,
} from 'components/inquiry/constants'
import { DATE_FORMATS } from 'constants/app'

import { NewOrderFormPropsType } from '../constants'

import { getMinDate } from './helper'

interface NewOrderFieldsProps extends NewOrderFormPropsType {
  offer: Offer
  showQuantityInCubicMeters?: boolean
}

export const NewOrderFields: FC<NewOrderFieldsProps> = ({
  handleBlur,
  handleChange,
  offer,
  setFieldTouched,
  setFieldValue,
  values,
  showQuantityInCubicMeters = false,
}) => {
  const isOnDemand =
    offer &&
    String(offer.order_type) === String(INQUIRY_ORDER_TYPE.TYPE_ON_DEMAND)

  let inputDateMinDate
  if (offer && !isOnDemand) {
    inputDateMinDate = getMinDate(offer.turn_begin)
  }

  return (
    <>
      {/* Durchführung*/}
      <InputDate
        label={I18n.t('order.createOrder.labels.executionDate')}
        maxDate={moment(offer.turn_end, DATE_FORMATS)}
        {...(!isOnDemand && { minDate: { inputDateMinDate } })}
        name='delivery_date'
        onChange={value => {
          setFieldValue('delivery_date', moment(value).format('L'))
          setFieldTouched('delivery_date')
        }}
        placeholder={I18n.t('order.createOrder.labels.executionDate')}
        value={values.delivery_date}
        excludeWeekends
      />

      {/* Zeitraum*/}
      <DropDownInput
        choices={INQUIRY_TIMES_OF_DAY.map(item => ({
          optionValue: item.id,
          optionLabel: `${I18n.t(item.name)}`,
        }))}
        label={I18n.t(
          'createInquiryTranslations.form.addressAndDeliveryFormGroup.label.timeOfDayDelivery',
        )}
        name='time_of_day_delivery'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t('general.timeOfDay')}
        value={
          values.time_of_day_delivery !== ''
            ? values.time_of_day_delivery
            : String(INQUIRY_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY)
        }
      />

      {/* Anzahl*/}
      {!showQuantityInCubicMeters && (
        <DropDownInput
          choices={Array(offer.number_of_containers)
            .fill('')
            .map((item, index) => ({
              optionValue: index + 1,
              optionLabel: `${index + 1}`,
            }))}
          label={I18n.t('inquiry.numberOfContainers')}
          name='number_of_containers'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={I18n.t('inquiry.numberOfContainers')}
          value={values.number_of_containers}
        />
      )}

      {/* Menge in m³*/}
      {showQuantityInCubicMeters && (
        <InputDecimal
          dataTestId='quantity-in-cubic-meters-input'
          label={I18n.t('createOfferTranslations.label.quantityInCubicMeters')}
          placeholder={I18n.t(
            'createOfferTranslations.label.quantityInCubicMeters',
          )}
          maxValue={99.99}
          name='quantity_in_cubic_meters'
          onChange={handleChange}
          value={values.quantity_in_cubic_meters}
          removeBrowserStyling
        />
      )}

      {/* Information zum Auftrag*/}
      <Textarea
        name='additional_information'
        label={I18n.t('createOfferTranslations.label.additionalInformation')}
        maxLength={500}
        onBlur={handleBlur}
        onChange={handleChange}
        rows={25}
        showRemainingCharacters
        value={values.additional_information}
      />
    </>
  )
}
