import classNames from 'classnames'
import { bool, oneOf, shape, string } from 'prop-types'
import React from 'react'

import { FILE_TYPE_IMAGE } from 'components/common/fileUpload/constants'
import { AttachmentScheme } from 'components/common/fileUpload/schemes'
import Icon from 'components/common/Fontello/index'

export const FILETHUMBNAIL_BORDER = {
  NONE: '',
  ROUNDED: 'uk-border-rounded',
}

/**
 * This is a small container that displays an image or "document" icon, depending on the file type.
 * @param props
 * @returns {*}
 * @constructor
 */
const FileThumbnail = props => (
  <>
    {props.file.type && props.file.type.split('/')[0] === FILE_TYPE_IMAGE ? (
      <img
        src={props.showFileThumbnail ? props.file.thumbnail : props.file.file}
        className={classNames(props.className, props.border)}
        alt={props.file.name}
      />
    ) : (
      <div className='uk-flex-center uk-margin-auto'>
        <Icon name='file-pdf' />
      </div>
    )}
  </>
)

FileThumbnail.propTypes = {
  border: oneOf(Object.values(FILETHUMBNAIL_BORDER)),
  className: string,
  file: shape(AttachmentScheme).isRequired, // The file to display
  showFileThumbnail: bool,
}

FileThumbnail.defaultProps = {
  className: '',
  border: FILETHUMBNAIL_BORDER.ROUNDED,
  showFileThumbnail: false,
}

export default FileThumbnail
