import { arrayOf, bool, func, shape } from 'prop-types'

import { PaginationScheme } from 'schemes/pagination'
import { UserScheme } from 'schemes/user'

const UserOverviewPagePropsProps = {
  resetUser: func.isRequired,
  getMyUsers: func.isRequired,
  exportUsers: func.isRequired,
  resetUserIsContactPerson: func.isRequired,
  pagination: shape(PaginationScheme),
  user: shape(UserScheme),
  userList: arrayOf(shape(UserScheme)),
  isLoading: shape({
    exportUsers: bool,
    userList: bool,
  }),
}

export default UserOverviewPagePropsProps
