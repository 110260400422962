import React, { FC, useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router'

import {
  initialDimensions,
  PageDimensionsContext,
} from './PageDimensionsContext'

export interface PageDimensions {
  headerHeight?: number
  footerHeight?: number
  footerBottomPos?: number
}

export const PAGE_DIMENSION_ID = {
  HEADER: 'header',
  FOOTER: 'footer',
}

/**
 * calculate the bottom position of footer, if not visible return zero
 */
const calculateFooterPos = (footer: Element) => {
  const scroll = window.scrollY || window.pageYOffset
  const footerTop = footer.getBoundingClientRect().top + scroll
  const bottom = scroll + window.innerHeight - footerTop

  return bottom < 0 ? 0 : bottom
}

/**
 * provide default values for page dimensions as context,
 * @constructor
 */
export const PageDimensionsProvider: FC = ({ children }) => {
  const history = useHistory()
  const [dimensions, setDimensions] =
    useState<PageDimensions>(initialDimensions)

  const handleChanges = useCallback<any>(() => {
    const header = document.querySelector(
      `[data-page-dimension-id="${PAGE_DIMENSION_ID.HEADER}"]`,
    )
    const footer = document.querySelector(
      `[data-page-dimension-id="${PAGE_DIMENSION_ID.FOOTER}"]`,
    )

    if (header && footer) {
      setDimensions({
        headerHeight: header.getBoundingClientRect().height,
        footerHeight: footer.getBoundingClientRect().height,
        footerBottomPos: calculateFooterPos(footer),
      })
    }

    if (!header && footer) {
      setDimensions({
        headerHeight: undefined,
        footerHeight: footer.getBoundingClientRect().height,
        footerBottomPos: calculateFooterPos(footer),
      })
    }

    if (header && !footer) {
      setDimensions({
        headerHeight: header.getBoundingClientRect().height,
        footerHeight: undefined,
        footerBottomPos: 0,
      })
    }
  }, [])

  /**
   * bind to window events
   */
  useEffect(() => {
    handleChanges()

    window.addEventListener('resize', handleChanges)
    window.addEventListener('orientationchange', handleChanges)
    window.addEventListener('scroll', handleChanges)

    return () => {
      window.removeEventListener('resize', handleChanges)
      window.removeEventListener('orientationchange', handleChanges)
      window.addEventListener('scroll', handleChanges)
    }
  }, [handleChanges])

  /**
   * bind to history changes
   */
  useEffect(() => {
    const unsubscribe = history.listen(() => {
      setTimeout(handleChanges, 150)
    })
    return () => unsubscribe()
  }, [handleChanges, history])

  /**
   * provide values as css custom properties
   */
  useEffect(() => {
    Object.keys(dimensions).forEach(identifier => {
      document.documentElement.style.setProperty(
        `--page-${identifier}`,
        `${dimensions[identifier] || 0}px`,
      )
    })
  }, [dimensions])

  return (
    <PageDimensionsContext.Provider value={dimensions}>
      {children}
    </PageDimensionsContext.Provider>
  )
}
