import React, { FC } from 'react'

import Icon, { ICON_NAME } from 'components/common/Fontello'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'

import {
  LandingPageContent,
  WasteManagerLandingPageType,
} from '../HomepageWastemanager'

interface ExplanationSectionChildProps {
  section: LandingPageContent
}

export const ExplanationSectionChild: FC<ExplanationSectionChildProps> = ({
  section,
}) => (
  <article className='explanation-section__child'>
    {section.type === WasteManagerLandingPageType.EmptoAgreement && (
      <Icon name={ICON_NAME.DEVICE_DESKTOP} />
    )}
    {section.type === WasteManagerLandingPageType.ExternalAgreement && (
      <Icon name={ICON_NAME.DOCUMENT} />
    )}
    <div className='explanation-section__child-content'>
      <Headline
        color={HEADLINE_COLOR.GRAYISH_BROWN}
        fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
        fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
        headlineStyle={HEADLINE_STYLE.H3}
        tag={HEADLINE_TAG.H2}
        noMargin
      >
        {section.title}
      </Headline>
      <p className='explanation-section__text'>{section.content}</p>
    </div>
  </article>
)
