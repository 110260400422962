import './style.scss'

import classNames from 'classnames'
import { FormikProps } from 'formik'
import moment from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import {
  CERTIFICATE_PRICE_AGREEMENT_DISPOSITE_CHOICE,
  CERTIFICATE_STATES,
  CERTIFICATE_STATUS,
  CERTIFICATE_TYPE,
} from 'components/certificate/constants'
import { DropDownInput } from 'components/common/DropDownInput'
import InputDate from 'components/common/InputDate/index'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { Textarea } from 'components/common/Textarea'
import { getName } from 'helper/translations'

import { DetailsSection } from '../DetailsSection'

interface CertificateReviewDetailsComponentProps {
  certificate: Certificate
  isReview?: boolean
  formikProps: FormikProps<any>
}

export const CertificateReviewDetails: FC<
  CertificateReviewDetailsComponentProps
> = props => {
  const { certificate, isReview = false, formikProps } = props

  const renderCriteria = isReview ? formikProps.values : certificate
  const certificateNotRejected =
    Number(formikProps.values.status) !== CERTIFICATE_STATUS.STATUS_REJECTED

  return (
    <DetailsSection
      title={I18n.t(
        'documentReviewPageTranslations.certificateReview.sectionTitle',
      )}
    >
      <div className='certificate-review'>
        <div
          className={classNames({
            'certificate-review__review_details-group': !isReview,
          })}
        >
          {/* VALID UNTIL */}
          {((isReview &&
            (certificate.type === CERTIFICATE_TYPE.TYPE_EFB ||
              certificate.type === CERTIFICATE_TYPE.TYPE_OTHER)) ||
            (!isReview && certificate.valid_until)) && (
            <ReadOnlyField
              editable={isReview}
              label={I18n.t(
                'documentReviewPageTranslations.certificateReview.validUntil',
              )}
              value={moment(certificate.valid_until).format('L')}
            >
              <InputDate
                dataTestId='valid-until-input'
                label={I18n.t(
                  'documentReviewPageTranslations.certificateReview.validUntil',
                )}
                minDate={moment()}
                name='valid_until'
                onChange={value => {
                  formikProps.setFieldValue('valid_until', value.format('L'))
                  formikProps.setFieldTouched('valid_until')
                }}
                placeholder={I18n.t(
                  'documentReviewPageTranslations.certificateReview.validUntil',
                )}
                value={formikProps.values.valid_until}
                error={
                  formikProps.submitCount > 0 && formikProps.touched.valid_until
                    ? formikProps.errors.valid_until
                    : ''
                }
                isRequired={
                  !formikProps.values.valid_until && certificateNotRejected
                }
              />
            </ReadOnlyField>
          )}

          {/* ISSUED AT */}
          {((isReview &&
            certificate.type !== CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT) ||
            (!isReview && certificate.issued_at)) && (
            <ReadOnlyField
              editable={isReview}
              label={I18n.t(
                'documentReviewPageTranslations.certificateReview.issuedAt',
              )}
              value={moment(certificate.issued_at).format('L')}
            >
              <InputDate
                dataTestId='issued-at-input'
                label={I18n.t(
                  'documentReviewPageTranslations.certificateReview.issuedAt',
                )}
                maxDate={moment()}
                name='issued_at'
                onChange={value => {
                  formikProps.setFieldValue('issued_at', value.format('L'))
                  formikProps.setFieldTouched('issued_at')
                }}
                placeholder={I18n.t(
                  'documentReviewPageTranslations.certificateReview.issuedAt',
                )}
                value={formikProps.values.issued_at}
                error={
                  formikProps.submitCount > 0 && formikProps.touched.issued_at
                    ? formikProps.errors.issued_at
                    : ''
                }
                isRequired={
                  !formikProps.values.issued_at && certificateNotRejected
                }
              />
            </ReadOnlyField>
          )}

          {/* PRICES FOR PRICEAGREEMENT DESPOSITED */}
          {((isReview &&
            certificate.type === CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT) ||
            (!isReview && certificate.disposited_prices !== null)) && (
            <ReadOnlyField
              editable={isReview}
              label={I18n.t(
                'documentReviewPageTranslations.certificateReview.dispositedPricesShort',
              )}
              value={
                !isReview && certificate.disposited_prices !== null
                  ? getName(
                      Number(certificate.disposited_prices),
                      CERTIFICATE_PRICE_AGREEMENT_DISPOSITE_CHOICE,
                      true,
                    )
                  : undefined
              }
            >
              <DropDownInput
                dataTestId='disposited_prices-select'
                choices={CERTIFICATE_PRICE_AGREEMENT_DISPOSITE_CHOICE.map(
                  item => ({
                    optionValue: Number(item.id),
                    optionLabel: I18n.t(item.name),
                  }),
                )}
                label={I18n.t(
                  'documentReviewPageTranslations.certificateReview.dispositedPrices',
                )}
                name='disposited_prices'
                onBlur={formikProps.handleBlur}
                onChange={formikProps.handleChange}
                placeholder={I18n.t('general.pleaseChoose')}
                value={formikProps.values.disposited_prices}
                error={
                  formikProps.submitCount > 0 &&
                  formikProps.touched.disposited_prices
                    ? (formikProps.errors.disposited_prices as string)
                    : ''
                }
                isRequired={formikProps.values.disposited_prices === ''}
              />
            </ReadOnlyField>
          )}

          {/* INTERNAL NOTE */}
          {(isReview || (!isReview && certificate.internal_note)) && (
            <ReadOnlyField
              editable={isReview}
              label={I18n.t(
                'documentReviewPageTranslations.certificateReview.internalNote',
              )}
              value={certificate.internal_note}
            >
              <Textarea
                label={I18n.t(
                  'documentReviewPageTranslations.certificateReview.internalNote',
                )}
                maxLength={500}
                name='internal_note'
                onChange={formikProps.handleChange}
                placeholder={I18n.t(
                  'documentReviewPageTranslations.certificateReview.internalNote',
                )}
                showRemainingCharacters
                value={formikProps.values.internal_note}
              />
            </ReadOnlyField>
          )}

          {/* STATUS */}
          <ReadOnlyField
            editable={isReview}
            label={I18n.t(
              'documentReviewPageTranslations.certificateReview.status',
            )}
            value={
              !isReview
                ? I18n.t(getName(certificate.status, CERTIFICATE_STATES))
                : undefined
            }
          >
            <DropDownInput
              dataTestId='status-select'
              choices={CERTIFICATE_STATES.map(item => ({
                optionValue: item.id,
                optionLabel: I18n.t(item.name),
              }))}
              label={I18n.t(
                'documentReviewPageTranslations.certificateReview.status',
              )}
              name='status'
              onBlur={formikProps.handleBlur}
              onChange={formikProps.handleChange}
              placeholder={I18n.t(
                'documentReviewPageTranslations.certificateReview.status',
              )}
              value={formikProps.values.status}
              error={
                formikProps.submitCount > 0 && formikProps.touched.status
                  ? (formikProps.errors.status as string)
                  : ''
              }
              isRequired={
                Number(formikProps.values.status) ===
                CERTIFICATE_STATUS.STATUS_PENDING
              }
            />
          </ReadOnlyField>
        </div>

        <div
          className={classNames({
            'certificate-review__review_message-group': !isReview,
          })}
        >
          {/* REJECT REASON */}
          {Number(renderCriteria.status) ===
            CERTIFICATE_STATUS.STATUS_REJECTED && (
            <ReadOnlyField
              editable={isReview}
              label={I18n.t(
                'documentReviewPageTranslations.certificateReview.rejectReason.label',
              )}
              value={certificate.reject_reason}
            >
              <Textarea
                error={
                  formikProps.submitCount > 0 &&
                  formikProps.touched.reject_reason
                    ? (formikProps.errors.reject_reason as string)
                    : ''
                }
                isRequired={!formikProps.values.reject_reason}
                label={I18n.t(
                  'documentReviewPageTranslations.certificateReview.rejectReason.label',
                )}
                placeholder={I18n.t(
                  'documentReviewPageTranslations.certificateReview.rejectReason.placeholder',
                )}
                maxLength={500}
                name='reject_reason'
                onChange={formikProps.handleChange}
                showRemainingCharacters
                value={formikProps.values.reject_reason}
              />
            </ReadOnlyField>
          )}
        </div>
        <div
          className={classNames({
            'certificate-review__review_details-group': !isReview,
          })}
        >
          {/* REVIEWED BY */}
          {!isReview && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.certificateReview.reviewedBy',
              )}
              value={certificate.reviewed_by_name}
            />
          )}

          {/* REVIEWED AT */}
          {!isReview && (
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.certificateReview.reviewedAt',
              )}
              value={moment(certificate.reviewed_at).format('L')}
            />
          )}
        </div>
      </div>
    </DetailsSection>
  )
}
