import moment from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { ReactComponent as Document } from 'assets/svg/detailspage/document.svg'
import AccordionItem from 'components/common/Accordion/components/AccordionItem'
import { ReadOnlyField } from 'components/common/ReadOnly'

interface AgreementSectionProps {
  addScrollElement: string
  offer: Offer
}

export const AgreementSection: FC<AgreementSectionProps> = ({
  addScrollElement,
  offer,
}) => {
  const iconTitle = (
    <>
      <Document className='inquiry-details-page__section-header' />
      {I18n.t('agreementDetailsTranslations.section.agreement.title')}
    </>
  )

  return (
    <AccordionItem addScrollElement={addScrollElement} title={iconTitle}>
      <div
        className='inquiry-details-page__section uk-child-width-1-2@m'
        data-uk-grid=''
      >
        {/* AGREEMENT ID */}
        <ReadOnlyField
          label={I18n.t(
            'agreementDetailsTranslations.section.agreement.fields.agreementId',
          )}
          value={offer.id}
        />
        {/* AGREEMENT DATE */}
        <ReadOnlyField
          label={I18n.t('general.date')}
          value={moment(offer.status_changed_at).format('L')}
        />
        {/* AGREEMENT CREATOR */}
        <ReadOnlyField
          className='uk-width-1-1'
          label={I18n.t('general.createdBy')}
          value={
            offer.status_changed_by_object
              ? [
                  offer.status_changed_by_object.first_name,
                  offer.status_changed_by_object.last_name,
                ].join(' ')
              : ''
          }
        />
        {/* INQUIRY ID */}
        <ReadOnlyField
          label={I18n.t(
            'agreementDetailsTranslations.section.agreement.fields.inquiryId',
          )}
          value={offer.id}
        />
        {/* INQUIRY DATE */}
        <ReadOnlyField
          label={I18n.t('general.date')}
          value={moment(offer.created_at).format('L')}
        />
        {/* OFFER ID */}
        <ReadOnlyField
          label={I18n.t(
            'agreementDetailsTranslations.section.agreement.fields.offerId',
          )}
          value={offer.id}
        />
        {/* OFFER DATE */}
        <ReadOnlyField
          label={I18n.t('general.date')}
          value={moment(offer.created_at).format('L')}
        />
      </div>
    </AccordionItem>
  )
}
