import { connect } from 'react-redux'

import { createLoadingSelector } from 'selectors/loading'
import { getUserPermissionsSelector, getUserRoleSelector } from 'selectors/user'

const mapStateToProps = state => ({
  isUserLoading: createLoadingSelector(['GET_CURRENT_USER'])(state),
  permissions: getUserPermissionsSelector(state),
  userRole: getUserRoleSelector(state),
})

export default connect(mapStateToProps)
