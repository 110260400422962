const dashboardApiTranslations = {
  handleGetDashboard: {
    error: 'Dashboard konnte leider nicht geladen werden.',
  },
  handleGetDashboardEPD: {
    error: 'Dashboard konnte leider nicht geladen werden.',
  },
}

export default dashboardApiTranslations
