import ActionTypes from '../actiontypes/certificate'
import { replaceCertificateOfTypeOrAdd } from '../helper/certificate'
import { replaceObjectInArray } from '../helper/general'

export const initialState = {
  item: {},
  itemList: [], // list of all certificates that admin can see
  latestItemList: [], // list of latest certificates for each type for my company
  myItemList: [], // list of my company certificates
  archivedItemList: [], // list of archived certificates
  pagination: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
  archivedPagination: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
  priceAgreementList: { initial: true },
}

/**
 * @description A reducer for company certificates.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const certificateReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.ADD_CERTIFICATE_TYPE_REQUEST:
      return {
        ...state,
        item: {
          ...state.item,
          type: action.payload,
        },
      }
    case ActionTypes.GET_MY_CERTIFICATES_SUCCESS:
      return {
        ...state,
        myItemList: action.payload.data,
        pagination: { loaded: true, ...action.payload.pagination },
      }
    case ActionTypes.GET_CERTIFICATES_SUCCESS:
      return {
        ...state,
        itemList: action.payload.data,
        pagination: { loaded: true, ...action.payload.pagination },
      }
    case ActionTypes.GET_CERTIFICATE_SUCCESS:
      return {
        ...state,
        item: action.payload,
      }
    // Action triggered after reviewing a certificate
    case ActionTypes.UPDATE_REVIEWED_CERTIFICATE_SUCCESS:
      return {
        ...state,
        item: initialState.item,
        itemList: replaceObjectInArray(action.payload, state.itemList),
        latestItemList: replaceObjectInArray(
          action.payload,
          state.latestItemList,
        ),
      }
    case ActionTypes.GET_MY_LATEST_CERTIFICATES_SUCCESS:
      return {
        ...state,
        latestItemList: action.payload,
      }
    case ActionTypes.GET_COMPANY_PRICE_AGREEMENTS_SUCCESS:
      return {
        ...state,
        priceAgreementList: action.payload,
      }
    case ActionTypes.GET_COMPANY_PRICE_AGREEMENTS_REQUEST:
      return {
        ...state,
        priceAgreementList: { initial: false },
      }
    case ActionTypes.GET_COMPANY_LATEST_CERTIFICATES_SUCCESS:
      return {
        ...state,
        latestItemList: action.payload,
      }
    case ActionTypes.CREATE_CERTIFICATE_SUCCESS:
      action.payload.id_or_contract_id = action.payload.contract_id
        ? action.payload.contract_id
        : action.payload.id
      return {
        ...state,
        item: action.payload,
        itemList: [action.payload, ...state.itemList],
        latestItemList: replaceCertificateOfTypeOrAdd(
          action.payload,
          state.latestItemList,
        ),
      }
    case ActionTypes.UPDATE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        item: action.payload,
        itemList: replaceObjectInArray(action.payload, state.itemList),
        latestItemList: replaceCertificateOfTypeOrAdd(
          action.payload,
          state.latestItemList,
        ),
      }
    case ActionTypes.RESET_CERTIFICATE_ITEM_REQUEST:
      return {
        ...state,
        item: initialState.item,
      }
    case ActionTypes.ARCHIVE_CERTIFICATE_SUCCESS:
      return {
        ...state,
        // Remove archived certificates from state
        latestItemList: state.latestItemList.filter(
          entry =>
            !action.payload
              .map(certificate => certificate.id)
              .includes(entry.id),
        ),
      }
    case ActionTypes.GET_ARCHIVED_CERTIFICATES_SUCCESS:
      return {
        ...state,
        archivedItemList: action.payload.data,
        archivedPagination: { loaded: true, ...action.payload.pagination },
      }
    default:
      return state
  }
}

export default certificateReducer
