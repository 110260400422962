import './style.scss'

import { useFormikContext } from 'formik'
import React, { FC, useContext } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { FormStepSummaryRow } from 'components/common/FormSteps'
import {
  getContainerName,
  getFractionsFromFractionId,
} from 'components/inquiry/helpers'
import { contractApplies } from 'components/maklerpremium/helpers'
import { getContainersSelector } from 'selectors/container'
import { getFractionsSelector } from 'selectors/fraction'
import { getFrameworkContractPositionsSelector } from 'selectors/maklerpremium'

import {
  CreateMaklerPremiumOfferContext,
  MaklerPremiumOfferValues,
} from '../CreateMaklerPremiumOfferFormSteps'

/**
 * @description Functional component to render FractionAndContainerSummaryComponent
 * @returns {*}
 * @constructor
 */
export const FractionAndContainerSummary: FC = () => {
  const { values } = useFormikContext<MaklerPremiumOfferValues>()
  const { editStep, autoOfferCompany } = useContext(
    CreateMaklerPremiumOfferContext,
  )
  const fractionList = useSelector<any, Fraction[]>(getFractionsSelector)
  const containerList = useSelector<any, Container[]>(getContainersSelector)

  const { fraction: summary_fine_fraction, coarseFraction: summary_fraction } =
    getFractionsFromFractionId(
      values.fine_fraction ?? values.fraction,
      fractionList,
    )
  const summary_container_and_size = getContainerName(
    values.container,
    containerList,
  )
  const summary_on_public_ground = values.position_on_public_ground
    ? 'onPublicGround'
    : 'onPrivateGround'

  const frameworkContractPositions = useSelector(
    getFrameworkContractPositionsSelector,
  )
  const frameworkContractApplies = contractApplies(
    frameworkContractPositions,
    values,
  )

  return (
    <div className='maklerpremium-step-summary'>
      <div className='maklerpremium-step-summary_grid-body'>
        <FormStepSummaryRow
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.fraction.label',
          )}
          value={summary_fraction?.name}
          key='maklerpremium-step-summary-fine_fraction'
        />
        <FormStepSummaryRow
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.fineFraction.label',
          )}
          value={summary_fine_fraction?.name}
          key='maklerpremium-step-summary-fraction'
        />
        <FormStepSummaryRow
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.container.label',
          )}
          value={summary_container_and_size}
          key='maklerpremium-step-summary-container_and_size'
        />
        <FormStepSummaryRow
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.numberOfContainers.label',
          )}
          value={values.number_of_containers}
          key='maklerpremium-step-summary-number_of_containers'
        />
        <FormStepSummaryRow
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.positionOnPublicGround.label',
          )}
          value={I18n.t(
            `createMaklerPremiumOfferPageTranslations.steps.2.radiopanel.${summary_on_public_ground}`,
          )}
          key='maklerpremium-step-summary-on_public_ground'
        />
        <FormStepSummaryRow
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.2.fields.discount',
          )}
          value={`${values.discount}%`}
          key='maklerpremium-step-summary-discount'
        />
        <FormStepSummaryRow
          label='Endpreis (Brutto)'
          value={
            frameworkContractApplies
              ? I18n.t(
                  'createMaklerPremiumOfferPageTranslations.steps.2.fields.frameworkContractPrice',
                )
              : `${I18n.l(values.offer_end_price ?? '', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}€`
          }
          key='maklerpremium-step-summary-offer_end_price'
        />
        <FormStepSummaryRow
          label='Entsorger'
          value={autoOfferCompany}
          key='maklerpremium-step-summary-auto_offer_company'
        />
      </div>
      <div className='sidebar'>
        <ButtonLink
          className='sidebar__edit-link'
          linkColor={BUTTON_LINK_COLOR.PRIMARY}
          onClick={editStep}
        >
          {I18n.t('createInquiryTranslations.form.button.editStep')}
        </ButtonLink>
      </div>
    </div>
  )
}
