import cancelInquiryModalTranslations from './CancelInquiryModal/translations'

const inquiryDetailsTranslations = {
  cancelInquiryModal: cancelInquiryModalTranslations,
  pageTitle: 'Anfrage %{id}',
  breadcrumbTitle: 'Anfrage von %{inquiryCompany}',
  teaser: {
    title: 'Anfrage %{id} für %{fraction}',
    text:
      'Überprüfen Sie hier die einzelnen Details Ihrer Anfragen. Wenn Sie Änderungen vornehmen wollen, ' +
      'klicken Sie auf den Button „Anfrage stornieren“ und erstellen Sie Ihre Entsorgungsanfrage erneut. ' +
      'Sie haben mehrere Angebote erhalten? Dann vergleichen Sie diese, indem Sie auf den Button ' +
      '„Angebote vergleichen“ klicken. Wenn die Anfrage bereits abgeschlossen wurde, ist eine Stornierung nicht mehr ' +
      'nötig. In diesem Fall kann direkt eine neue Entsorgungsanfrage erstellt werden.',
  },
  section: {
    wasteProducer: {
      title: 'Gewerbetreibender',
      fields: {
        name: 'Name',
        createdAt: 'Mitglied seit',
        positiveReviews: 'Positive Bewertungen',
        inquiriesCount: 'Anfragen gesamt',
        numberAndStatus: 'Anfragenummer, Status',
        createdBy: 'Name des Erstellers',
        receiverEmailAddress: 'Empfängermail',
      },
    },
    address: {
      title: 'Adressinformationen',
      fields: {
        address: 'Leistungsadresse',
        description: 'Bezeichnung der Leistungsadresse',
      },
    },
    inquiry: {
      title: 'Auftragsdaten',
      fields: {
        fraction: 'Abfallart',
        fineFraction: 'Feinfraktion',
        noFineFraction: 'Keine Feinfraktion',
        container: 'Behälter',
        numberOfContainers: 'Anzahl',
        quantityInCubicMeters: 'Menge in m\u00B3',
        withTop: 'Deckelausführung gewünscht',
        positionOnPublicGround: 'Stellung auf öffentlichem Grund',
        withETA: 'Mit ETA-Service (Estimated Time of Arrival)',
        avvNumber: 'AVV Nummer',
        avvNumberDefault: 'ausstehend',
        specialNote: 'Besondere Hinweise (Anfahrt, Stellplatz, etc)',
        attachments: 'Bilder',
      },
    },
    time: {
      title: 'Zeitliche Informationen',
      fields: {
        runtimeOfInquiry: 'Laufzeit der Anfrage',
        orderType: 'Auftragsart',
        deliveryDateAndTime: 'Lieferdatum, Zeitraum',
        collectionDateAndTime: 'Abholdatum, Zeitraum',
        turnBegin: 'Turnusbeginn',
        agreementBegin: 'Vereinbarungsbeginn',
        turnEnd: 'Turnusende',
        agreementEnd: 'Vereinbarungsende',
        serviceInterval: 'Intervall',
        serviceIntervalSecondWeekday: 'Zweiter Wochentag',
        serviceIntervalWeekdays: 'Wochentage',
        serviceIntervalWeekdays_1: 'Wochentag',
        serviceIntervalWeekdays_2: 'Wochentage',
        reactionTime: 'Reaktionszeit/Leistungsfrist',
      },
    },
  },
  cta: {
    buttons: {
      cancel: 'Anfrage stornieren',
      compare: 'Angebote vergleichen',
      showInfo: 'Mehr Informationen',
      closeInfo: 'Information schließen',
      showAction: 'Aktionen',
      closeAction: 'Aktionen schließen',
      agreement: 'Vereinbarung öffnen',
    },
    openNoOffers: {
      title: 'Möchten Sie Ihre Anfrage stornieren?',
      text:
        'Wenn Sie Ihre Anfrage stornieren möchten, können Sie dies tun, solange Sie kein Angebot angenommen ' +
        'haben. Eine Wiederherstellung der Daten ist anschließend nicht möglich.',
    },
    openOffers: {
      title: 'Es liegen Angebote vor',
      text:
        'Zu Ihrer Anfrage existieren Angebote, die Sie im Angebotsvergleich ansehen können. Wenn Sie Ihre ' +
        'Anfrage jedoch stornieren möchten, können Sie dies tun, solange Sie kein Angebot angenommen haben. ' +
        'Eine Wiederherstellung der Daten ist anschließend nicht möglich.',
    },
    cancelled: {
      title: 'Sie haben diese Anfrage bereits storniert',
    },
    closed: {
      title: 'Diese Anfrage wurde am %{expired} abgeschlossen (ohne Vergabe)',
    },
    concluded: {
      title: 'Angebot angenommen',
      text:
        'Sie haben zu dieser Anfrage bereits ein Angebot angenommen. Alle notwendigen Informationen finden Sie ' +
        'nun in der Vereinbarung.',
    },
  },
}

export default inquiryDetailsTranslations
