import './style.scss'

import moment from 'moment'
import { bool, func, shape } from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import Spinner from 'react-spinkit'

import Icon from 'components/common/Fontello/index'
import { AttachmentScheme } from 'components/common/fileUpload/schemes'

import FileThumbnail from '../FileThumbnail'

/**
 * Displays a small preview of a file, which can be an image or a PDF document.
 * If the file is completely uploaded (has an ID), it can be removed, if the property "allowRemove" is set to true.
 */
class FilePreview extends Component {
  render() {
    return (
      <div className='filepreview-file' onDragOver={() => false}>
        <button
          type='button'
          className={`preview-document
          uk-flex
          uk-flex-middle
          uk-border-rounded
          ${this.props.file.id ? '' : 'uploading'}`}
          onClick={event => this.props.onClick(event, this.props.file)}
        >
          <FileThumbnail file={this.props.file} showFileThumbnail />
        </button>

        {this.props.showName && this.props.file.name && (
          <div className='file-meta uk-text-meta'>{this.props.file.name}</div>
        )}

        {this.props.showMetaData && this.props.file.lastmodified_at && (
          <div className='file-meta uk-text-meta'>
            <Translate
              value='fileUpload.lastmodifiedAt'
              date={moment(this.props.file.lastmodified_at).format('L')}
            />
          </div>
        )}

        <div className='actions'>
          {this.props.file.text && (
            <span
              className='has-text-icon'
              title={I18n.t('fileUpload.textIconTitle')}
            >
              <Icon name='message' />
            </span>
          )}

          {this.props.file.id ? (
            this.props.allowRemove && (
              <button
                type='button'
                className='uk-icon-button uk-margin-small-left'
                onClick={event =>
                  this.props.onRemovedFile(event, this.props.file)
                }
                disabled={!this.props.file.id}
              >
                <Icon name='cross' />
              </button>
            )
          ) : (
            <Spinner name='circle' color='white' />
          )}
        </div>
      </div>
    )
  }
}

FilePreview.propTypes = {
  allowRemove: bool, // Whether the "remove" button shall be displayed
  onRemovedFile: func, // Is called when the "remove" button was clicked
  onClick: func, // Is called when the user clicks in the file
  file: shape(AttachmentScheme).isRequired, // The file to display
  showName: bool,
  showMetaData: bool, // Whether to show meta data like the upload date, default false
}

FilePreview.defaultProps = {
  allowRemove: true,
  onClick: () => undefined,
  onRemovedFile: () => undefined,
  showName: false,
  showMetaData: false,
}

export default FilePreview
