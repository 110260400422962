import './PriceComparisonTable.scss'

import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { MaklerPremiumOfferValues } from '../../maklerpremium/CreateMaklerPremiumOfferPage/OfferFormSteps/CreateMaklerPremiumOfferFormSteps'

import { PriceComparisonTableCheckbox } from './PriceComparisonTableCheckbox'
import { PriceComparisonTableColumn } from './PriceComparisonTableColumn'
import { PriceComparisonTableCompany } from './PriceComparisonTableCompany'

interface PriceComparisonTableProps {
  containerList?: Container[]
  currentContainerCapacity?: string
  priceComparisonTable?: PriceComparisonCompany[]
  setCurrentContainerCapacity: (capacity?: string) => void
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  values: MaklerPremiumOfferValues
}

export const PriceComparisonTable: FC<PriceComparisonTableProps> = ({
  containerList,
  currentContainerCapacity,
  priceComparisonTable,
  setCurrentContainerCapacity,
  setFieldValue,
  values,
}) => {
  const availableCapacities = useMemo(
    () =>
      priceComparisonTable?.length
        ? Object.keys(priceComparisonTable[0].container_prices)
        : [],
    [priceComparisonTable],
  )

  // get Index for a given capacity to determine column position in table
  const getCapacityIndex = useCallback(
    (capacity: string) => {
      if (priceComparisonTable?.length && availableCapacities?.length) {
        // First option: Get the index of the selected capacity
        const capacityIndex = availableCapacities.indexOf(capacity)
        // If that isn't possible (i.e.: because we switched position/containers and there is no offer for that capacity)
        // Use the second capacity column if exists or fall back to the first column if it's the only one
        return capacityIndex >= 0
          ? capacityIndex
          : Number(availableCapacities.length > 1)
      }
      return 0
    },
    [availableCapacities, priceComparisonTable],
  )

  const [bestContainer, setBestContainer] = useState<number>()

  // auto select the "best" autoOffer (best margin/first in list for current capacity)
  const autoSelectBestAutoOffer = useCallback(
    (capacity: string) => {
      // only works if we have a price table with data
      if (priceComparisonTable?.length) {
        // Filter all priceComparisonCompanyObjects container_prices for a given capacity for an auto_offer,
        // which has the preselected attribute set to true
        const bestPriceComparisonCompanyObject = priceComparisonTable.filter(
          priceComparisonCompanyObject =>
            priceComparisonCompanyObject.container_prices[capacity]
              ?.preselected,
        )[0]

        // Set the offer of the found best priceComparisonCompanyObject as bestOffer
        const bestOffer: PriceComparisonContainer | undefined =
          bestPriceComparisonCompanyObject?.container_prices[capacity]

        // have we found a best offer? If so: Set auto_offer
        if (bestOffer) {
          setFieldValue('auto_offer', bestOffer.auto_offer_id)
          setFieldValue('best_container', bestOffer.container_id)
          setBestContainer(bestOffer.container_id)
        }
      }
    },
    [priceComparisonTable, setFieldValue],
  )

  useEffect(() => {
    if (bestContainer !== values.container) {
      setFieldValue('container', bestContainer)
    }

    // We intentionally don't set values.container here, as otherwise we'd overwrite a newly selected container
    // with the previous best offer
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFieldValue, bestContainer])

  // Build slider table for 3 items
  const [visibleContainerSizeKeys, setVisibleContainerSizeKeys] = useState<
    (string | null)[]
  >([])
  useEffect(() => {
    const newVisibleContainerSizeKeys: (string | null)[] = []
    // obviously this only works if we got raw pricing data
    if (priceComparisonTable?.length && availableCapacities?.length) {
      // by default set the containerSizeIndex to the newly selected one
      let containerSizeIndex = 0
      containerSizeIndex = availableCapacities.indexOf(
        currentContainerCapacity ?? '',
      )
      if (containerSizeIndex === -1) {
        containerSizeIndex = Number(availableCapacities.length > 1)
        setCurrentContainerCapacity(availableCapacities[containerSizeIndex])
      }
      const selectedCapacity = availableCapacities[containerSizeIndex]

      // Left Column
      if (containerSizeIndex === 0) {
        newVisibleContainerSizeKeys.push(null)
      } else {
        newVisibleContainerSizeKeys.push(
          availableCapacities[containerSizeIndex - 1],
        )
      }

      // Middle Column
      newVisibleContainerSizeKeys.push(availableCapacities[containerSizeIndex])

      // Right Column
      if (containerSizeIndex === availableCapacities.length - 1) {
        newVisibleContainerSizeKeys.push(null)
      } else {
        newVisibleContainerSizeKeys.push(
          availableCapacities[containerSizeIndex + 1],
        )
      }
      // set visible size keys and select the best offer for this capacity
      setVisibleContainerSizeKeys(newVisibleContainerSizeKeys)
      autoSelectBestAutoOffer(selectedCapacity)
    }
  }, [
    autoSelectBestAutoOffer,
    availableCapacities,
    containerList,
    currentContainerCapacity,
    priceComparisonTable,
    setCurrentContainerCapacity,
    values.container,
  ])

  // next button
  const showNextContainerSize = () => {
    if (currentContainerCapacity && availableCapacities?.length) {
      const capacityIndex = availableCapacities.indexOf(
        currentContainerCapacity,
      )

      if (capacityIndex < availableCapacities.length - 1) {
        setCurrentContainerCapacity(availableCapacities[capacityIndex + 1])
      }
    }
  }
  // prev button
  const showPrevContainerSize = () => {
    if (currentContainerCapacity && availableCapacities?.length) {
      const capacityIndex = availableCapacities.indexOf(
        currentContainerCapacity,
      )

      if (capacityIndex > 0) {
        setCurrentContainerCapacity(availableCapacities[capacityIndex - 1])
      }
    }
  }

  // If we have no price data, don't render a useless table
  if (!priceComparisonTable || priceComparisonTable.length === 0) {
    if (values.offer_end_price) {
      setFieldValue('offer_end_price', undefined)
    }
    return <></>
  }

  // Make sure to only render the priceTable if the visibleContainerSizeKeys have been updated yet
  // To do so check if some visible items are not null AND not one of the available capacities
  if (
    visibleContainerSizeKeys.some(
      el => el !== null && !availableCapacities.includes(el),
    )
  ) {
    // If we find at least one "not null item" that's not in availableContainerCapacities, don't render the price table
    setFieldValue('offer_end_price', undefined)
    return <></>
  }

  return (
    <div className='price-comparison-table'>
      <PriceComparisonTableColumn
        rows={priceComparisonTable.map(company => (
          <PriceComparisonTableCompany key={company.slug} company={company} />
        ))}
      />
      {visibleContainerSizeKeys.map((containerSize, containerIndex) =>
        containerSize &&
        priceComparisonTable[0].container_prices[containerSize] ? (
          <Fragment key={containerIndex}>
            <PriceComparisonTableColumn
              active={visibleContainerSizeKeys[1] === containerSize}
              headline={containerSize}
              key={containerSize}
              subline={`${priceComparisonTable[0].container_prices[
                containerSize
              ].end_price.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}€`}
              rows={priceComparisonTable.map(company =>
                typeof company.container_prices[containerSize] ===
                'undefined' ? (
                  <div
                    key={company.slug}
                    className='price-comparison-table-column__row'
                  >
                    <PriceComparisonTableCheckbox />
                  </div>
                ) : (
                  <div
                    key={company.slug}
                    className='price-comparison-table-column__row'
                  >
                    <PriceComparisonTableCheckbox
                      margin={company.container_prices[containerSize].margin}
                      onClick={() => {
                        setFieldValue(
                          'auto_offer',
                          company.container_prices[containerSize].auto_offer_id,
                        )
                        setCurrentContainerCapacity(containerSize)
                      }}
                      checked={
                        company.container_prices[containerSize]
                          .auto_offer_id === values.auto_offer
                      }
                      price={company.container_prices[containerSize].price}
                      recommended={
                        company.container_prices[containerSize].preselected
                      }
                    />
                  </div>
                ),
              )}
            />
          </Fragment>
        ) : (
          // Leere Spalte - wird nur angezeigt, wenn die kleinste oder größte Containergröße ausgewählt wurde
          <PriceComparisonTableColumn
            headline=''
            key={`empty${containerIndex === 0 ? 'First' : 'Last'}`}
            rows={priceComparisonTable.map(company => (
              <div
                key={company.slug}
                className='price-comparison-table-column__row'
              >
                <PriceComparisonTableCheckbox />
              </div>
            ))}
          />
        ),
      )}

      <button
        className='price-comparison-table__btn price-comparison-table__btn--prev'
        type='button'
        aria-label='previous'
        onClick={showPrevContainerSize}
        disabled={getCapacityIndex(currentContainerCapacity ?? '') === 0}
      />
      <button
        className='price-comparison-table__btn price-comparison-table__btn--next'
        type='button'
        aria-label='next'
        onClick={showNextContainerSize}
        disabled={
          getCapacityIndex(currentContainerCapacity ?? '') ===
          availableCapacities.length - 1
        }
      />
    </div>
  )
}
