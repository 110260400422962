import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import Media from 'react-media'
import { scroller } from 'react-scroll'

import { BREAKPOINT } from 'constants/design'
import ButtonBar from 'components/common/ButtonBar'
import { Button } from 'components/common/Button'

import { connector } from './connector'

interface ZipCodeButtonBarProps {
  resetZipCodeField: (field: string) => void
  resetZipCode: () => void
  scrollTarget?: string
}

const ZipCodeButtonBarComponent: FC<ZipCodeButtonBarProps> = ({
  resetZipCodeField,
  resetZipCode,
  scrollTarget = '',
}) => (
  <ButtonBar>
    <Media query={{ maxWidth: BREAKPOINT.LARGE }}>
      {matches => (
        <Button
          onClick={() => {
            resetZipCodeField('zipcode.item')
            resetZipCode()
            // perform a scroll to the results table only on mobile layout (<Media ... />)
            if (scrollTarget && matches) {
              scroller.scrollTo(scrollTarget, {
                duration: 1000,
                smooth: true,
                offset: -70,
              })
            }
          }}
        >
          <Translate value='homepages.wasteProducer.zipCode.newSearch' />
        </Button>
      )}
    </Media>
  </ButtonBar>
)

export const ZipCodeButtonBar = connector(ZipCodeButtonBarComponent)
