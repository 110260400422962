import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'

import Icon, { ICON_NAME } from 'components/common/Fontello'
import { PaginatedTable } from 'components/common/PaginatedTable'
import { UpDownArrow } from 'components/common/UpDownArrow'

import { getShop } from '../../helpers'

export const MinimumTurnoverTable: FC = () => {
  const minimumTurnoverTableColumns = [
    {
      id: 'shop',
      Header: (
        <span>
          <Translate value='priceManagementPageTranslations.minimumTurnoverTable.columns.shop' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'shop',
      maxWidth: 300,
      Cell: data => I18n.t(getShop(data.value)!.translationKey),
    },
    {
      id: 'minimumTurnover',
      Header: (
        <span>
          <Translate value='priceManagementPageTranslations.minimumTurnoverTable.columns.minimumTurnover' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'minimum_turnover',
      Cell: data =>
        new Intl.NumberFormat('de-DE', {
          style: 'currency',
          currency: 'EUR',
        }).format(Number(data.value)),
    },
    {
      id: 'actions',
      Header: (
        <Translate value='priceManagementPageTranslations.minimumTurnoverTable.columns.actions' />
      ),
      maxWidth: 70,
      filterable: false,
      sortable: false,
      Cell: () => (
        <Link to='/' onClick={() => undefined}>
          <Icon
            name={ICON_NAME.PENCIL}
            className='communicationcenter-interactive-table-body__icon'
          />
        </Link>
      ),
    },
  ]

  const testData = [
    {
      shop: 2,
      minimum_turnover: 10.0,
    },
    {
      shop: 3,
      minimum_turnover: 20.0,
    },
  ]

  return (
    <PaginatedTable
      serverSidePagination
      page={1}
      pages={1}
      loading={false}
      handleShowPreviousPage={() => undefined}
      handleShowNextPage={() => undefined}
      table={{
        columns: minimumTurnoverTableColumns,
        data: testData,
        onFetchData: () => undefined,
      }}
    />
  )
}
