import { I18n } from 'react-i18nify'

import { ORDER_EVALUATION_RATING, ORDER_EVALUATION_TYPES } from './constants'

export interface EvaluationsType extends Partial<OrderEvaluation> {
  type: number
  order: number
  rating: number
  label: string
  disabled: boolean
}

/**
 * @description get order evaluations or create new evaluations
 * @param order
 * @return {*}
 */
export const getOrderEvaluations: (order: Order) => EvaluationsType[] = order =>
  ORDER_EVALUATION_TYPES.map(type => {
    const evaluation = {
      type: type.id,
      order: order.id,
      rating: ORDER_EVALUATION_RATING.NEUTRAL,
      label: I18n.t(type.name),
    }

    const existingEvaluation = order.evaluations.find(e => e.type === type.id)

    return {
      ...evaluation,
      ...existingEvaluation,
      disabled: !!existingEvaluation,
    }
  })
