import './OfferResults.scss'

import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

import { Headline, HEADLINE_FONT_TYPE } from 'components/common/Headline'
import { RegisterOrRequestDemoButtons } from 'components/common/RegisterOrRequestDemoButtons'

interface OfferResultsProps {
  results: number
  zipCode: number
}

/**
 * Renders an interaction are for the user after looking up a given zipCode
 * @param {number} results the amount of results found for a given zipCode
 * @param {number} zipCode the zipCode used for searching results
 * @constructor
 */
export const OfferResults: FC<OfferResultsProps> = ({ results, zipCode }) => (
  <div className='offer-results'>
    <Headline
      className='offer-results__headline'
      fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
    >
      <Translate
        value='offerResults.headline'
        results={results}
        zipCode={zipCode}
        dangerousHTML
      />
    </Headline>
    <div className='offer-results__description'>
      <Translate value='offerResults.description' />
    </div>

    <RegisterOrRequestDemoButtons />
  </div>
)
