import { BUSINESS_SEGMENT } from '../constants/app'

/**
 * @description selector to get container list.
 * @param state
 * @return {*}
 */
export const getContainersSelector: (state: any) => Container[] = state =>
  state.container.itemList

/**
 * @description selector to get container list.
 * @param state
 * @return {*}
 */
export const getEPDContainersSelector: (state: any) => Container[] = state =>
  state.container.itemList.filter(
    container => container.business_segment === BUSINESS_SEGMENT.BUSINESS_EPD,
  )

/**
 * Selector to get the content of all containers
 * @param state
 * @returns {*}
 */
export const getContainerContentSelector = state => state.container.content

/**
 * @description selector to get a list of container for filter.
 * @param state
 * @return {*}
 */
export const getContainerForFilterSelector: (
  state: any,
) => ContainerFilterResponse[] = state =>
  // remove null values
  state.container.filterList.filter(item => item.name && item)
