import ActionTypes from '../actiontypes/order'

export const itemInitialState = {
  order_status_objects: [], // used by the form to update the status,
}

export const initialState = {
  item: itemInitialState,
  orderList: {
    data: [],
    pagination: {
      count: 0,
      next: 0,
      current: 0,
      previous: 0,
      total_results: 0,
      loaded: false,
    },
  },
  orderForInvoiceCheckList: {
    data: [],
    pagination: {
      count: 0,
      next: 0,
      current: 0,
      previous: 0,
      total_results: 0,
      loaded: false,
    },
  },
  ordersCount: 0,
  maklerPremiumUpcomingOrders: [],
  orderForDisposerManagement: {},
  pagination: {},
}

export const replaceUpcomgingOrderInArray = (upcomingOrder, array) =>
  array.map(_object =>
    _object.id === upcomingOrder.id && _object.type === upcomingOrder.type
      ? upcomingOrder
      : _object,
  )

/**
 * @description A reducer for order.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
export const orderReducer = (state = initialState, action) => {
  const filterCompanies = action.payload?.pagination?.filter_companies
  const filterContainers = action.payload?.pagination?.filter_containers
  const filterTimestamp = action.payload?.pagination?.filter_timestamp

  switch (action.type) {
    case ActionTypes.GET_ORDERS_SUCCESS:
      return {
        ...state,
        orderList: {
          data: action.payload.data,
          pagination: { loaded: true, ...action.payload.pagination },
        },
      }
    case ActionTypes.GET_ORDERS_FOR_INVOICE_CHECK_SUCCESS:
      return {
        ...state,
        orderForInvoiceCheckList: {
          data: action.payload.data,
          pagination: { loaded: true, ...action.payload.pagination },
        },
      }
    case ActionTypes.RESET_ORDERS_FOR_INVOICE_CHECK_LIST:
    case ActionTypes.GET_ORDERS_FOR_INVOICE_CHECK_ERROR:
      return {
        ...state,
        orderForInvoiceCheckList: initialState.orderForInvoiceCheckList,
      }
    case ActionTypes.CANCEL_ORDER_SUCCESS:
    case ActionTypes.GET_ORDER_SUCCESS:
    case ActionTypes.DISPOSE_ORDER_SUCCESS:
    case ActionTypes.POSTPONE_ORDER_SUCCESS:
    case ActionTypes.SET_ETA_FOR_ORDER_SUCCESS:
    case ActionTypes.CONFIRM_EXECUTION_OF_ORDER_SUCCESS:
      return {
        ...state,
        item: action.payload,
      }
    case ActionTypes.CREATE_INVOICING_DOCUMENTS_SUCCESS:
      return {
        ...state,
        createInvoicingDocuments: action.payload,
      }
    case ActionTypes.GET_ORDERS_COUNT_SUCCESS:
      return {
        ...state,
        ordersCount: action.payload.orders_count,
      }
    case ActionTypes.GET_ORDERS_RESET:
      return {
        ...state,
        orderList: initialState.orderList,
        ordersCount: initialState.ordersCount,
      }
    case ActionTypes.GET_MAKLER_PREMIUM_UPCOMING_ORDERS_SUCCESS:
      return {
        ...state,
        maklerPremiumUpcomingOrders: action.payload.data,
        filterCompanies,
        filterContainers,
        filterTimestamp,
        pagination: { loaded: true, ...action.payload.pagination },
      }
    case ActionTypes.SET_MAKLER_PREMIUM_UPCOMING_ORDER_TO_CONFIRMED_SUCCESS:
      return {
        ...state,
        maklerPremiumUpcomingOrders: replaceUpcomgingOrderInArray(
          action.payload,
          state.maklerPremiumUpcomingOrders,
        ),
      }
    case ActionTypes.GET_ORDER_FOR_DISPOSER_MANAGEMENT_SUCCESS:
      return {
        ...state,
        orderForDisposerManagement: action.payload,
      }
    default:
      return state
  }
}
