export const RegisterOrRequestDemoButtonsTranslations = {
  RegisterOrRequestDemoButtons: {
    registerBtn: 'Jetzt kostenlos registrieren!',
    divider: 'Oder',
    demoBtn: 'Demo anfragen',
    tooltipCaption: 'Was ist eine Demo?',
    tooltipContent:
      'Möchten Sie empto kennenlernen? In der Demo haben Sie die Möglichkeit, von einem unserer ' +
      'Mitarbeiter Schritt für Schritt über die Funktionen von empto aufgeklärt zu werden.',
  },
}
