import React, { Component } from 'react'
import { Control } from 'react-redux-form'

import FileUpload from 'components/common/fileUpload/FileUpload'

import { ControlDefaultProps } from '../../constants'
import FormField from '../../index'

import connector from './connector'
import FileUploadControlProps from './schemes'

/**
 * @description Custom textbox component
 * see: http://davidkpiano.github.io/react-redux-form/docs/guides/custom-controls.html
 */
class FileUploadControl extends Component {
  static propTypes = FileUploadControlProps

  static defaultProps = {
    ...ControlDefaultProps,
    uploadComponent: FileUpload,
    singleFormField: false,
    addMapProps: {},
  }

  /**
   * @description Override this method in a child class to define an individual field.
   * @return {*}
   */
  getField() {
    if (!this.props.field) return null

    return (
      <div
        className={`file-upload-control${
          this.props.disabled ? ' disabled' : ''
        }`}
      >
        <Control
          className={this.props.className}
          model={this.props.field.model}
          placeholder={this.props.placeholder || ''}
          component={this.props.uploadComponent}
          onFilesUploaded={this.handleFilesUpload}
          onFileRemoved={this.handleFileRemoved}
          onFileEdited={this.handleFileEdit}
          mapProps={{
            ...this.props.addMapProps,
            initialFile: () => this.props.addMapProps.initialFile,
            displayEditIcon: () => this.props.addMapProps.displayEditIcon,
          }}
        />
      </div>
    )
  }

  /**
   * @description handles the files upload of FileUpload (Multi).
   * @param uploadedFiles
   */
  handleFilesUpload = uploadedFiles => {
    if (this.props.singleFormField) {
      this.props.actions.form.change(
        this.props.field.model,
        uploadedFiles[0].id,
      )
    } else {
      this.props.actions.form.merge(this.props.field.model, [
        ...this.props.field.value,
        ...uploadedFiles,
      ])
    }
  }

  /**
   * @description handles the file remove of FileUpload.
   * @param fileToRemove
   */
  handleFileRemoved = fileToRemove => {
    if (this.props.singleFormField) {
      this.props.actions.form.change(this.props.field.model, null)
    } else {
      // can not use remove here: https://github.com/davidkpiano/react-redux-form/issues/958
      this.props.actions.form.filter(
        this.props.field.model,
        item => item.id !== fileToRemove.id,
      )
    }
  }

  /**
   * @description handles the file edit of FileUpload.
   * @param fileToEdit
   */
  handleFileEdit = fileToEdit => {
    if (!this.props.singleFormField) {
      this.props.actions.form.map(this.props.field.model, item => {
        if (item.id === fileToEdit.id) return fileToEdit

        return item
      })
    }
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    return <FormField {...this.props} fieldComponent={this.getField()} />
  }
}

export default connector(FileUploadControl)
