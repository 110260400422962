import uniqueId from 'lodash.uniqueid'
import React, { useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch } from 'react-redux'

import { updateCustomOffer } from 'actions/maklerpremium'
import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { ProgressButton } from 'components/common/ProgressButton'
import { RadioButton } from 'components/common/RadioButton'
import { UploadItem } from 'components/common/UniversalFileUpload/types'

export type CustomOfferCreateFormValues = Partial<{
  attachments: UploadItem[]
  company: number
  custom_offer_number: string
  issued_at: string
  price: string
  runtime_of_custom_offer: string
  search_field: string
}>

type CustomOfferRuntimeModalPropsType = {
  isOpen: boolean
  onClose: () => void
  customOfferID: number | undefined
}

export const CustomOfferRuntiemModal = (
  props: CustomOfferRuntimeModalPropsType,
) => {
  const { isOpen, onClose, customOfferID } = props

  enum STATUS_CHOICES {
    // These correspond to the STATUS_CHOICES of the CustomOffer model
    STATUS_REJECTED = 2,
    STATUS_ACCEPTED,
  }

  const [accepted, setAccepted] = useState(false)
  const [rejected, setRejected] = useState(false)

  const dispatch = useDispatch()

  const idCustomRuntimeModalHeadline = uniqueId()

  const handleSubmit = () => {
    if (customOfferID) {
      dispatch(
        updateCustomOffer(
          customOfferID,
          accepted
            ? STATUS_CHOICES.STATUS_ACCEPTED
            : STATUS_CHOICES.STATUS_REJECTED,
        ),
      )
      setAccepted(false)
      setRejected(false)
      onClose()
    }
  }

  const toggleAcceptedRejected = (constant: STATUS_CHOICES) => {
    if (accepted !== rejected) {
      setAccepted(!accepted)
      setRejected(!rejected)
    }

    if (constant === STATUS_CHOICES.STATUS_ACCEPTED) {
      setAccepted(!accepted)
    }

    if (constant === STATUS_CHOICES.STATUS_REJECTED) {
      setRejected(!rejected)
    }
  }

  return (
    <Modal
      ariaDescribedBy={idCustomRuntimeModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={I18n.t(
          'offerPipelineTranslations.customOfferRuntimeModal.title',
        )}
        titleId={idCustomRuntimeModalHeadline}
      />
      <div className='uk-modal-body'>
        <div
          className='uk-flex-center margin-regular-top'
          data-uk-grid='uk-grid'
        >
          <RadioButton
            isChecked={accepted}
            label={I18n.t(
              'offerPipelineTranslations.customOfferRuntimeModal.accepted',
            )}
            name='accepted'
            onChange={() => {
              toggleAcceptedRejected(STATUS_CHOICES.STATUS_ACCEPTED)
            }}
          />
          <RadioButton
            isChecked={rejected}
            label={I18n.t(
              'offerPipelineTranslations.customOfferRuntimeModal.rejected',
            )}
            name='rejected'
            onChange={() => {
              toggleAcceptedRejected(STATUS_CHOICES.STATUS_REJECTED)
            }}
          />
        </div>
      </div>
      <div className='uk-modal-footer uk-text-center uk-padding-remove-right'>
        <Button
          className='uk-button uk-margin-medium-right'
          backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
          onClick={onClose}
        >
          <Translate value='general.button.cancel' />
        </Button>

        <ProgressButton
          className='uk-button'
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          isDisabled={accepted === rejected}
          onClick={handleSubmit}
        >
          <Translate value='general.button.save' />
        </ProgressButton>
      </div>
    </Modal>
  )
}
