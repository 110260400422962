export const InquiryOpenDashboardCardTranslations = {
  inquiryOpenDashboardCard: {
    statusHeader: {
      title: 'Anfrage %{id} vom %{date}',
      state: '%{offersCount} %{name} erhalten',
      offer: 'Angebot',
      offers: 'Angebote',
    },
    execution: 'Ausführung am %{date}',
  },
}
