import ActionTypes from '../actiontypes/indexes'
import { replaceObjectsInArray } from '../helper/general'

const itemInitialState = {
  value: null,
}

export const initialState = {
  item: itemInitialState, // This is the structure of the index values object, as in the API serializer
  itemList: [], // this is the list of all the exiting indexes
  nextMonthIndexList: [], // this is a list that will contain as many objects as there are indexes (except "NO INDEX"),
  valueList: [], // this is the list of the existing index values
}

/**
 * @description A reducer for Indexes.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const indexesReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_INDEXES_SUCCESS:
      return {
        ...state,
        itemList: action.payload,
      }
    case ActionTypes.GET_INDEX_VALUES_SUCCESS:
      return {
        ...state,
        valueList: action.payload,
      }
    case ActionTypes.CREATE_INDEX_VALUE_SUCCESS:
      return {
        ...state,
        valueList: [...state.valueList, ...action.payload],
      }
    case ActionTypes.UPDATE_INDEX_VALUES_SUCCESS:
      return {
        ...state,
        valueList: replaceObjectsInArray(action.payload, state.valueList),
      }
    case ActionTypes.GET_INDEX_VALUES_RESET:
      return {
        ...state,
        valueList: initialState.valueList,
      }
    default:
      return state
  }
}

export default indexesReducer
