import { useFormikContext } from 'formik'
import React, { useMemo } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { InputText } from 'components/common/InputText'
import {
  BUTTON_BACKGROUND_COLOR,
  ProgressButton,
} from 'components/common/ProgressButton'
import { RadioButton } from 'components/common/RadioButton'
import { StaticCombobox } from 'components/common/StaticCombobox'
import {
  CATEGORY_CHOICES,
  NEW_ENTRY_CATEGORY_CHOICES,
  STAGE_CHOICES,
  TYPE_CHOICES,
} from 'components/maklerpremium/CommunicationCenterPage/constants'
import {
  getFieldError,
  handleOnChange,
  isFieldValid,
  resetValues,
  setRadioButtonValue,
} from 'components/maklerpremium/CommunicationCenterPage/helper'
import { NewEntryValues } from 'components/maklerpremium/CommunicationCenterPage/types'

import { ComCenterContactFormContext } from '../index'

import { CategoryOperationsForm } from './CategoryOperationsForm'
import { CategoryOperationsStage2Form } from './CategoryOperationsStage2Form'
import { CategoryOperationsStage3Form } from './CategoryOperationsStage3Form'

/**
 * @description This component displays base form of the main modal form of the communication center page
 */
export const BaseForm = () => {
  const {
    values,
    errors,
    submitCount,
    isValid,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    validateForm,
    handleChange,
  } = useFormikContext<NewEntryValues>()

  const formikContext = {
    values,
    errors,
    submitCount,
    isValid,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    validateForm,
    handleChange,
  }

  const selectedCategory = useMemo(() => {
    if (values.category) {
      return NEW_ENTRY_CATEGORY_CHOICES.find(
        category => category.value === Number(values.category),
      )
    }
  }, [values.category])

  return (
    <ComCenterContactFormContext.Consumer>
      {({ onCloseModal, isSubmitting, comCenterContact }) => (
        <div className='uk-modal-body communicationcenterpage-new-entry-all-stages-form'>
          <StaticCombobox
            label={I18n.t(
              'communicationCenterPageTranslations.fields.category.label',
            )}
            onSelectionChange={async event => {
              await setFieldValue('category', String(event.target.value))
              resetValues('category', event.target.value, formikContext)
              await validateForm()
            }}
            name='category'
            // "No results" Text is not needed in this case, because options are static
            noResultsText=''
            options={NEW_ENTRY_CATEGORY_CHOICES}
            selectedOption={selectedCategory}
            placeholder={I18n.t(
              'communicationCenterPageTranslations.fields.category.placeholder',
            )}
            withCheckmark
            showCheckmark={() => isFieldValid('category', formikContext)}
          />
          {values.category === `${CATEGORY_CHOICES.CHOICE_OPERATIONS}` && (
            <>
              <CategoryOperationsForm />
              {values.stage === `${STAGE_CHOICES.CHOICE_STAGE2}` && (
                <CategoryOperationsStage2Form />
              )}
              {values.stage === `${STAGE_CHOICES.CHOICE_STAGE3}` && (
                <CategoryOperationsStage3Form />
              )}
            </>
          )}
          <InputText
            error={getFieldError('email', formikContext)}
            label={I18n.t(
              'communicationCenterPageTranslations.fields.email.label',
            )}
            name='email'
            onBlur={() => undefined}
            onChange={event => handleOnChange(event, formikContext)}
            placeholder={I18n.t(
              'communicationCenterPageTranslations.fields.email.placeholder',
            )}
            value={values.email}
            showCheckmark={() => isFieldValid('email', formikContext)}
            withCheckmark
          />
          <div className='communicationcenterpage-new-entry-all-stages-form__radio-button-group'>
            <Translate
              value='communicationCenterPageTranslations.fields.type.label'
              className='communicationcenterpage-new-entry-all-stages-form__radio-button-group-label'
            />
            <div className='communicationcenterpage-new-entry-all-stages-form__radio-button-group-buttons'>
              <RadioButton
                isChecked={Number(values.type) === TYPE_CHOICES.CHOICE_INBOX}
                label={I18n.t(
                  'communicationCenterPageTranslations.fields.type.options.inbox',
                )}
                name='type'
                onChange={async (event: React.ChangeEvent<any>) => {
                  await setRadioButtonValue({
                    name: 'type',
                    event,
                    value: TYPE_CHOICES.CHOICE_INBOX,
                    formikContext,
                  })
                }}
              />
              <RadioButton
                isChecked={Number(values.type) === TYPE_CHOICES.CHOICE_PRIVATE}
                label={I18n.t(
                  'communicationCenterPageTranslations.fields.type.options.private',
                )}
                name='type'
                onChange={async (event: React.ChangeEvent<any>) => {
                  await setRadioButtonValue({
                    name: 'type',
                    event,
                    value: TYPE_CHOICES.CHOICE_PRIVATE,
                    formikContext,
                  })
                }}
              />
            </div>
          </div>
          {/* Will be shown if private address was selected, instead of inbox. */}
          {Number(values.type) === TYPE_CHOICES.CHOICE_PRIVATE && (
            <>
              <InputText
                error={getFieldError('first_name', formikContext)}
                label={I18n.t(
                  'communicationCenterPageTranslations.fields.first_name.label',
                )}
                name='first_name'
                onBlur={() => undefined}
                onChange={event => handleOnChange(event, formikContext)}
                placeholder={I18n.t(
                  'communicationCenterPageTranslations.fields.first_name.placeholder',
                )}
                value={values.first_name}
                showCheckmark={() => isFieldValid('first_name', formikContext)}
                withCheckmark
              />
              <InputText
                error={getFieldError('last_name', formikContext)}
                label={I18n.t(
                  'communicationCenterPageTranslations.fields.last_name.label',
                )}
                name='last_name'
                onBlur={() => undefined}
                onChange={event => handleOnChange(event, formikContext)}
                placeholder={I18n.t(
                  'communicationCenterPageTranslations.fields.last_name.placeholder',
                )}
                value={values.last_name}
                showCheckmark={() => isFieldValid('last_name', formikContext)}
                withCheckmark
              />
            </>
          )}
          <div className='communicationcenterpage-new-entry-all-stages-form__submit-button-wrapper'>
            {!comCenterContact && (
              <ProgressButton
                className='communicationcenterpage-new-entry-all-stages-form__submit-button'
                backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
                isDisabled={!isSubmitting || !isValid}
                onClick={() => {
                  handleSubmit()
                }}
                isLoading={!isSubmitting}
              >
                <Translate value='communicationCenterPageTranslations.buttons.saveAndNewEntry' />
              </ProgressButton>
            )}
            <ProgressButton
              className='communicationcenterpage-new-entry-all-stages-form__submit-button'
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              isDisabled={!isSubmitting || !isValid}
              onClick={() => {
                handleSubmit()
                onCloseModal()
              }}
              isLoading={!isSubmitting}
            >
              <Translate value='communicationCenterPageTranslations.buttons.save' />
            </ProgressButton>
          </div>
        </div>
      )}
    </ComCenterContactFormContext.Consumer>
  )
}
