import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'

import Icon, { ICON_NAME } from 'components/common/Fontello'

interface PaginationButtonsProps {
  className?: string
  page: number
  pages: number
  onPreviousPageClick: () => void
  onNextPageClick: () => void
}

export const PaginationButtons: FC<PaginationButtonsProps> = ({
  className,
  page,
  pages,
  onPreviousPageClick,
  onNextPageClick,
}) => (
  <div className={classNames('pagination-buttons', className)}>
    <button
      className='uk-button pagination-buttons__button'
      onClick={onPreviousPageClick}
      disabled={page === 1}
      type='button'
    >
      <Icon name={ICON_NAME.CHEVRON_LEFT} />
    </button>
    <button
      className='uk-button pagination-buttons__button'
      onClick={onNextPageClick}
      disabled={page >= pages}
      type='button'
    >
      <Icon name={ICON_NAME.CHEVRON_RIGHT} />
    </button>
  </div>
)
