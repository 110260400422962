/**
 * @description selector to get DSZ invoices list.
 * @param state
 * @return {*}
 */
export const getDszInvoicesSelector: (state: {
  [key: string]: { [key: string]: any }
}) => DszInvoice[] = state => state.dsz.invoice.dszInvoicesList

export const getDszInvoicesPaginationSelector = state =>
  state.dsz.invoice.pagination

/**
 * @description selector to get DSZ contract.
 * @param state
 * @return {*}
 */
export const getDszContractsSelector: (state: {
  [key: string]: { [key: string]: any }
}) => Contract[] = state => state.dsz.contract.contractList

export const getContractPaginationSelector = state =>
  state.dsz.contract.pagination

export const getDszFractionsSelector: (
  state: Record<string, Record<string, any>>,
) => DszFraction[] = state => state.dsz.fraction.fractionList

export const getDszFractionsPaginationSelector = state =>
  state.dsz.fraction.pagination

export const getFractionPricesSelector = state =>
  state.dsz.fractionPrices.fractionPriceList
export const getFractionPricesPaginationSelector = state =>
  state.dsz.fractionPrices.pagination
