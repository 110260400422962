import { Field, FieldArray, Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'
import { setLocale } from 'yup'

import { IndexDataProps, TrackedNewIndexValues } from 'types/indexes'
import { REGEX_PATTERN_TWO_DIGITS_AFTER_COMMA } from 'constants/app'
import {
  BUTTON_BACKGROUND_COLOR,
  ProgressButton,
} from 'components/common/ProgressButton'
import { BUTTON_TYPE } from 'components/common/Button'
import { addIndexValue, changeIndexValues } from 'actions/indexes'

import { convertDataToFormat } from '../../helpers'

const AddMonthValuesModalComponent = ({
  indexList,
  indexValues,
  handleModal,
}) => {
  const [indexData, setIndexData] = useState<IndexDataProps[]>([])
  const [editedIndexIds, setEditedIndexIds] = useState<number[]>([])
  const [newIndexes, setNewIndexes] = useState<TrackedNewIndexValues[]>([])
  const dispatch = useDispatch()

  useEffect(() => {
    const data = convertDataToFormat(indexList, indexValues)
    setIndexData(data.results)
  }, [indexList, indexValues])

  // indexData returns an array of months, which contain indexes. This function
  // helps to iterate over each month and maps the indexes to initial values.
  // This is a helper function for generateInitialValuesForIndexData.
  const generateInitialValuesForMonth = (indexData, monthIndex: number) =>
    indexData[monthIndex].indexes.reduce((resultObject, index) => {
      if (index.id) {
        return (
          (resultObject[`${index.id}`] = index.value ? index.value : ''),
          resultObject
        )
      }

      // New fields do not have an id yet, so for them a different fieldname is generated.
      // index_id is is a seperate id provided by indexData and does not represent
      // a database record.
      return (
        (resultObject[`${monthIndex}_${index.index_id}`] = index.value
          ? index.value
          : ''),
        resultObject
      )
    }, {})

  // Maps the index ids found in the indexData Array into an object
  // of the following format: {[fieldname]: '', [fieldname]: '', ...}
  // It's easier to handle values in Formik when they come in this format.
  const generateInitialValuesForIndexData = indexData => {
    let initialValues = {}

    for (let i = 0; i < indexData.length; i++) {
      initialValues = {
        ...initialValues,
        ...generateInitialValuesForMonth(indexData, i),
      }
    }

    return initialValues
  }

  const initialValues = indexData
    ? {
        id: '',
        ...generateInitialValuesForIndexData(indexData),
      }
    : {
        id: '',
      }

  const handleSubmit = values => {
    const newIndexesPayload = newIndexes.map(index => ({
      index: index.index,
      value: values[index.fieldName],
      month: index.month,
      year: index.year,
    }))

    if (newIndexes.length > 0) {
      dispatch(addIndexValue(newIndexesPayload))
    }

    const changeIndexValuesPayload = editedIndexIds.map(indexId => ({
      id: indexId,
      value: values[`${indexId}`],
    }))

    if (editedIndexIds.length > 0) {
      dispatch(changeIndexValues(changeIndexValuesPayload))
    }

    handleModal()
  }

  setLocale({
    number: {
      min: I18n.t(
        'indexValuesPage.addValueForm.index.errorMessage.fieldTooShort',
      ),
      max: I18n.t(
        'indexValuesPage.addValueForm.index.errorMessage.fieldTooBig',
      ),
    },
  })

  const validationSchema = yup.object().shape({
    index: yup
      .number()
      .transform(value => (isNaN(value) ? null : value))
      .nullable()
      .test(
        'is-decimal',
        I18n.t(
          'indexValuesPage.addValueForm.index.errorMessage.tooManyDecimalPlaces',
        ),
        (value: any) => {
          if (value) {
            return REGEX_PATTERN_TWO_DIGITS_AFTER_COMMA.test(value)
          }
          return true
        },
      )
      .min(-999)
      .max(999),
  })

  const validateIndex = async (index: number) => {
    try {
      await validationSchema.validate({ index }, { abortEarly: false })
    } catch (error: any) {
      return error.errors
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validateOnChange
    >
      {({
        isSubmitting,
        values,
        setFieldValue,
        handleChange,
        isValid,
        errors,
        submitForm,
      }) => {
        const selectedIndex = values.id

        return (
          <Form className='formik-form-indexes'>
            <div className='formik-form-select-months'>
              <label htmlFor='status'>Monat auswählen</label>
              <br />
              <Field
                className='form-control'
                as='select'
                name='id'
                onChange={event => {
                  handleChange(event)
                  const selectedObject = indexData.find(
                    item => item.id === Number(event.target.value),
                  )

                  setFieldValue(
                    'indexes.step',
                    // @ts-ignore
                    selectedObject.indexes.step,
                  )
                }}
              >
                <option value='' disabled>
                  ...
                </option>
                {indexData &&
                  indexData.map(i => (
                    <option key={i.id} value={i.id}>
                      {i.month_name}
                    </option>
                  ))}
              </Field>
            </div>
            <div>
              <FieldArray name={`indexData.${selectedIndex}.indexes`}>
                {() => (
                  <ul className='formik-form-select-months-list'>
                    {values.id !== '' &&
                      indexData[selectedIndex].indexes.map(
                        (item, itemIndex) => {
                          const fieldName = item.id
                            ? `${item.id}`
                            : `${selectedIndex}_${item.index_id}`

                          const getErrorMessages = () => {
                            if (errors) {
                              const errorMessages = errors[fieldName]
                              return Array.isArray(errorMessages)
                                ? errorMessages
                                : undefined
                            }

                            return []
                          }

                          const errorMessages = getErrorMessages()

                          return (
                            <li
                              key={itemIndex}
                              className='formik-form-select-months-list__field-item'
                            >
                              <div className='formik-form-select-months-list__index-field'>
                                <div className='formik-form-select-months-list__wrapper'>
                                  <label htmlFor={fieldName}>
                                    {
                                      indexData[selectedIndex].indexes[
                                        itemIndex
                                      ].index
                                    }
                                  </label>
                                  <Field
                                    className='form-control formik-form-select-months-list__input-field'
                                    name={fieldName}
                                    type='number'
                                    step='0.01'
                                    validate={async (value: number) =>
                                      await validateIndex(value)
                                    }
                                    onChange={event => {
                                      handleChange(event)

                                      const newIndexNotTracked =
                                        newIndexes.filter(
                                          newIndex =>
                                            newIndex.fieldName === fieldName,
                                        ).length === 0

                                      const editedIndexNotTracked =
                                        !editedIndexIds.includes(item.id)

                                      if (!item.id && newIndexNotTracked) {
                                        setNewIndexes([
                                          ...newIndexes,
                                          {
                                            index: item.index_id,
                                            fieldName,
                                            month: Number(
                                              indexData[selectedIndex].month,
                                            ),
                                            year: Number(
                                              indexData[selectedIndex].year,
                                            ),
                                          },
                                        ])
                                        return
                                      }

                                      if (item.id && editedIndexNotTracked) {
                                        setEditedIndexIds([
                                          ...editedIndexIds,
                                          item.id,
                                        ])
                                      }
                                    }}
                                  />
                                </div>
                                <ul className='formik-form-select-months-list__error-messages-list'>
                                  {errorMessages?.map((errorMessage, index) => (
                                    <li
                                      className='formik-form-select-months-list__error-messages-list-item'
                                      key={index}
                                    >
                                      {errorMessage}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </li>
                          )
                        },
                      )}
                  </ul>
                )}
              </FieldArray>
              <div id='button-footer'>
                <ProgressButton
                  backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                  isDisabled={isSubmitting || !isValid}
                  type={BUTTON_TYPE.SUBMIT}
                  onClick={async () => await submitForm()}
                >
                  {I18n.t('indexValuesPage.addValueForm.addButton')}
                </ProgressButton>
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddMonthValuesModalComponent
