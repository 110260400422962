import { func, shape } from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'

import { CERTIFICATE_TYPE } from 'components/certificate/constants'
import Icon from 'components/common/Fontello'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { SectionHeader } from 'components/common/SectionHeader'
import { COMPANY_ROLE } from 'components/company/constants'
import { UserPermission } from 'constants/user'
import { CertificateScheme } from 'schemes/certificate'
import { CompanyScheme } from 'schemes/company'
import { UserScheme } from 'schemes/user'

import { CertificateStatus } from '../CertificateStatus'

import connector from './connector'

/**
 * This component renders the certificates section of the company profile.
 */
export class CertificatesComponent extends Component {
  static propTypes = {
    company: shape(CompanyScheme).isRequired,
    user: shape(UserScheme).isRequired,
    efbCertificate: shape(CertificateScheme),
    otherCertificate: shape(CertificateScheme),
    companyCertificate: shape(CertificateScheme),
    priceAgreement: shape(CertificateScheme),
    onCertificateAdd: func.isRequired,
    onPreviewClick: func.isRequired,
  }

  static defaultProps = {
    efbCertificate: null,
    otherCertificate: null,
    companyCertificate: null,
    priceAgreement: null,
  }

  render() {
    const {
      company,
      companyCertificate,
      efbCertificate,
      otherCertificate,
      onCertificateAdd,
      onPreviewClick,
      priceAgreement,
      user,
    } = this.props

    let myMaklerPremiumIsLooking = false
    if (company.makler_premium_company && user.company_object) {
      myMaklerPremiumIsLooking =
        company.makler_premium_company === user.company_object.id
    }

    const userIsEmptoAdmin = user.company_object == null

    return (
      <section className='uk-card uk-padding-remove certificates'>
        <SectionHeader className='uk-float-left'>
          <Icon name='checklist' />
          <Translate value='companyProfilePage.verificationDocuments' />
        </SectionHeader>
        {!company.makler_premium_company && (
          <div className='uk-float-right uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom'>
            <RequiredPermissions
              requiredPermissions={[UserPermission.VIEW_MY_CERTIFICATE]}
            >
              <Link
                className='uk-button uk-button-primary'
                to='/certificate/mine'
              >
                <Translate value='certificateArchive.heading' />
                <Icon name='copy' />
              </Link>
            </RequiredPermissions>
          </div>
        )}

        <div className='uk-clearfix' />

        <div className='uk-padding details-container'>
          <div className='uk-grid uk-child-width-1-1 uk-child-width-1-3@m'>
            {(company.role === COMPANY_ROLE.WASTE_COMPANY ||
              company.role === COMPANY_ROLE.MIDDLE_MAN) && (
              <RequiredPermissions
                requiredPermissions={[UserPermission.ADD_EFB_CERTIFICATE]}
              >
                <CertificateStatus
                  id='efb-certificate-status'
                  certificate={efbCertificate}
                  title={I18n.t('companyProfilePage.certificates.label.efb')}
                  onAddClick={() => onCertificateAdd(CERTIFICATE_TYPE.TYPE_EFB)}
                  onPreviewClick={onPreviewClick}
                />
              </RequiredPermissions>
            )}

            {company.role === COMPANY_ROLE.WASTE_COMPANY && (
              <RequiredPermissions
                requiredPermissions={[UserPermission.ADD_OTHER_CERTIFICATE]}
              >
                <CertificateStatus
                  id='other-certificate-status'
                  certificate={otherCertificate}
                  title={I18n.t('companyProfilePage.certificates.label.other')}
                  onAddClick={() =>
                    onCertificateAdd(CERTIFICATE_TYPE.TYPE_OTHER)
                  }
                  onPreviewClick={onPreviewClick}
                />
              </RequiredPermissions>
            )}

            {company.role === COMPANY_ROLE.WASTE_PRODUCER && (
              <RequiredPermissions
                requiredPermissions={[UserPermission.ADD_COMPANY_CERTIFICATE]}
              >
                <CertificateStatus
                  id='company-certificate-status'
                  certificate={companyCertificate}
                  title={I18n.t(
                    'companyProfilePage.certificates.label.company',
                  )}
                  onAddClick={() =>
                    onCertificateAdd(CERTIFICATE_TYPE.TYPE_COMPANY)
                  }
                  onPreviewClick={onPreviewClick}
                />
              </RequiredPermissions>
            )}

            {(company.makler_premium_company ||
              myMaklerPremiumIsLooking ||
              userIsEmptoAdmin) &&
              (company.role === COMPANY_ROLE.WASTE_COMPANY ||
                company.role === COMPANY_ROLE.WASTE_PRODUCER) && (
                <RequiredPermissions
                  requiredPermissions={[UserPermission.ADD_PRICE_AGREEMENT]}
                >
                  <CertificateStatus
                    id='price_agreement-status'
                    certificate={priceAgreement}
                    title={I18n.t(
                      'companyProfilePage.certificates.label.priceAgreement',
                    )}
                    onAddClick={() =>
                      onCertificateAdd(CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT)
                    }
                    onPreviewClick={onPreviewClick}
                  />
                </RequiredPermissions>
              )}
          </div>
        </div>
      </section>
    )
  }
}

export default connector(CertificatesComponent)
