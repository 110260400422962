import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'

import { ChangeDisposerForm } from './ChangeDisposerForm'

interface ChangeDisposerModalProps {
  callback: any
  showChangeDisposerModal: boolean
  offer: Offer
  containerList: Container[]
}

export const ChangeDisposerModal: FC<ChangeDisposerModalProps> = ({
  callback,
  showChangeDisposerModal,
  offer,
  containerList,
}) => {
  const id = uniqueId() // from lodash package. Generate a unique ID

  return (
    <Modal
      ariaDescribedBy={id}
      isOpen={showChangeDisposerModal}
      onClose={callback}
    >
      <ModalHeader
        onClose={callback}
        title={I18n.t('changeDisposerModalTranslations.modalTitle')}
        titleId={id}
      />
      <div className='uk-modal-body'>
        <p>
          <Translate value='changeDisposerModalTranslations.modalInfo' />
        </p>

        <ChangeDisposerForm
          onCancel={callback}
          offer={offer}
          containerList={containerList}
        />
      </div>
    </Modal>
  )
}
