import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import ActionTypes from '../actiontypes/holiday'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetHolidays
 * @description Loads all holidays.
 */
export const handleGetHolidays = createLogic({
  type: ActionTypes.GET_HOLIDAYS_REQUEST,
  latest: true,

  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/holiday/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_HOLIDAYS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.holiday.handleGetHolidays.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_HOLIDAYS_ERROR,
          payload,
        })

        if (!err.hideToastr) {
          toastr.error('', humanReadableError)
        }

        done()
      })
  },
})

export default [handleGetHolidays]
