export const userProfileFormTranslation = {
  gender: {
    label: 'Anrede',
    placeholder: 'Anrede',
    required: 'Bitte wählen Sie eine Anrede aus.',
  },
  firstName: {
    label: 'Vorname',
    placeholder: 'Vorname',
    required: 'Bitte geben Sie Ihren Vorname ein.',
  },
  lastName: {
    label: 'Nachname',
    placeholder: 'Nachname',
    required: 'Bitte geben Sie Ihren Nachnamen ein.',
  },
  dateOfBirth: {
    label: 'Geburtsdatum (Kontoeröffner / Ansprechpartner)',
    placeholder: '01.01.1970',
    required: 'Bitte geben Sie Ihr Geburtsdatum ein.',
  },
  position: {
    label: 'Position',
    placeholder: 'Position',
    noValueGiven: 'Es wurde keine Position von diesem Benutzer angegeben.',
  },
  phoneNumber: {
    label: 'Geschäftliche Telefonnummer',
    placeholder: 'Bitte geben Sie Ihr Telefonnummer ein.',
    noValueGiven: 'Es wurde keine Telefonnummer von diesem Benutzer angegeben.',
  },
  company: {
    label: 'Unternehmen',
    placeholder: 'Unternehmen',
    platformAdminCompany: 'empto',
  },
  memberSince: {
    label: 'Mitglied seit',
    placeholder: 'Mitglied',
  },
  role: {
    label: 'Rolle',
    placeholder: 'Rolle',
  },
  email: {
    label: 'Mail',
    placeholder: 'E-Mail',
  },
}

export default {}
