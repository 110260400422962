import './style.scss'

import React, { FC, ReactText, useEffect } from 'react'
import { Col, Grid, Row } from 'react-flexbox-grid'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'

import { localizeMoney } from 'helper/general'
import ModalHeader from 'components/common/ModalHeader'
import { getPriceComparisonTablePaused } from 'actions/maklerpremium'
import { createLoadingSelector } from 'selectors/loading'
import { getPriceComparisonTablePausedSelector } from 'selectors/pricecalc'

interface PausedDisposersModalProps {
  onClose: () => void
  modalId: string
  priceComparisonRequestParams?: Record<string, ReactText | undefined>
}

export const PausedDisposersModal: FC<PausedDisposersModalProps> = ({
  onClose,
  modalId,
  priceComparisonRequestParams,
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (priceComparisonRequestParams) {
      dispatch(
        getPriceComparisonTablePaused({ ...priceComparisonRequestParams }),
      )
    }
  }, [dispatch, priceComparisonRequestParams])

  const pausedDisposerPrices = useSelector(
    getPriceComparisonTablePausedSelector,
  )

  const isLoading = useSelector(
    createLoadingSelector(['GET_PRICE_COMPARISON_TABLE_PAUSED']),
  )

  if (isLoading) {
    return <Spinner name='circle' />
  }

  return (
    <div className='paused-disposers-modal'>
      <ModalHeader
        onClose={onClose}
        title={I18n.t(
          'createMaklerPremiumOfferPageTranslations.steps.2.pausedDisposersModal.modalHeader',
        )}
        titleId={modalId}
      />
      <Grid className='uk-overflow-auto'>
        {pausedDisposerPrices?.map(disposer => {
          return (
            <Row
              key={`paused-disposer-price-${disposer.id}`}
              className='uk-child-width-auto uk-padding-small nowrap'
            >
              <Col className='uk-width-small'>
                <Row className='uk-width-small uk-text-bold uk-padding-small uk-padding-remove-top break-all'>
                  {disposer.name}
                </Row>
                <Row className='uk-padding-small uk-padding-remove-top break-all'>
                  {disposer.zip_code} {disposer.city}
                </Row>
              </Col>
              {Object.entries(disposer.container_prices).map(([key, val]) => (
                <Col
                  key={`price-column-${disposer.id}-${key}`}
                  className='fixed-width-price-column'
                >
                  <Row
                    key={`container-row-${disposer.id}-${key}`}
                    className='uk-padding-small uk-padding-remove-top'
                  >
                    {key}
                  </Row>
                  <Row
                    key={`price-row-${disposer.id}-${key}`}
                    className='uk-padding-small price-border'
                  >
                    {localizeMoney(val.price, { fallback: '-' })}
                  </Row>
                </Col>
              ))}
            </Row>
          )
        })}
      </Grid>
      {(pausedDisposerPrices?.length ?? 0) === 0 && (
        <Translate
          value='createMaklerPremiumOfferPageTranslations.steps.2.pausedDisposersModal.noResults'
          className='uk-padding-small'
          tag='div'
        />
      )}
    </div>
  )
}
