import './DashboardTile.scss'

import classNames from 'classnames'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { DashboardTileIconComponentProps } from '../DashboardTileIcon/DashboardTileIcon'

import { DashboardTileContent } from './DashboardTileContent'

export interface DashboardTileContentProps
  extends DashboardTileIconComponentProps {
  hideIcon?: boolean
  children: JSX.Element | JSX.Element[]
}

interface DashboardTileProps extends DashboardTileContentProps {
  buttonText?: string
  buttonTarget?: string
  className?: string
  removePadding?: boolean
  span?: boolean
}

/**
 * Renders the DashboardTile
 * @constructor
 */
export const DashboardTile: FC<DashboardTileProps> = ({
  buttonText,
  buttonTarget,
  children,
  className,
  icon,
  removePadding = false,
}) => (
  <article className={classNames('dashboard-tile', className)}>
    <div
      className={classNames('dashboard-tile__container', {
        'uk-padding-remove-bottom': removePadding,
      })}
    >
      <DashboardTileContent icon={icon}>{children}</DashboardTileContent>
      {buttonText && buttonTarget && (
        <div className='dashboard-tile__button'>
          <Link className='dashboard-tile__button-link' to={buttonTarget}>
            {buttonText}
          </Link>
        </div>
      )}
    </div>
  </article>
)
