import { connect } from 'react-redux'

import { getCertificates, getMyCertificates } from 'actions/certificate'

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getCertificates: (page, filters, sortedBy) =>
    dispatch(getCertificates(page, filters, sortedBy)),
  getMyCertificates: (page, filters, sortedBy) =>
    dispatch(getMyCertificates(page, filters, sortedBy)),
})

export default connect(null, mapDispatchToProps)
