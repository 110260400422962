import React, { FC, ReactNode } from 'react'

import Icon, { ICON_NAME } from 'components/common/Fontello'

interface CardDetailsSubListProps {
  items: (string | ReactNode)[]
}

/**
 * A component which renders the details sub-list section with its styles of our new cards.
 * @constructor
 */
export const CardDetailsSubList: FC<CardDetailsSubListProps> = ({ items }) => (
  <ul className='new-card__details-sub-list'>
    {items.map((item, index) => (
      <li
        className='new-card__details-sub-list-item'
        key={`details-sublist-item-${index}`} // eslint-disable-line react/no-array-index-key
      >
        <Icon name={ICON_NAME.MINUS} />
        {item}
      </li>
    ))}
  </ul>
)
