import React, { Component } from 'react'
import { I18n } from 'react-i18nify'
import { func, shape, string, bool, number } from 'prop-types'

import { isValidZipCode } from 'helper/zipCode'

import SearchNumberInput from '../IntroSection/components/SearchNumberInput'

import { connector } from './connector'

class ZipCodeInput extends Component {
  static propTypes = {
    resetZipCodeField: func.isRequired,
    getZipcode: func.isRequired,
    submitZipcode: func.isRequired,
    onClick: func.isRequired,
    zipCode: shape({
      code: string,
      disposer_count: number,
      is_active: bool,
      showValidationMessage: bool,
    }),
  }

  static defaultProps = {
    zipCode: null,
  }

  state = {
    searchInputCallback: null,
  }

  /**
   * @description Component “lifecycle method” UNSAFE_componentWillReceiveProps
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps({ zipCode }) {
    const { searchInputCallback } = this.state

    if (zipCode !== this.props.zipCode && searchInputCallback) {
      searchInputCallback(zipCode.is_active && zipCode.disposer_count > 0)
    }
  }

  /**
   * @description handles the change of search input.
   * @param value
   * @param callback
   */
  handleChange = (value, callback) => {
    const isValidCode = isValidZipCode(value)

    this.setState(
      {
        searchInputCallback: isValidCode ? callback : null,
      },
      () => {
        if (!isValidCode) {
          callback(false)
        } else {
          this.props.getZipcode(value)
        }
      },
    )
  }

  submitInput = () => {
    this.props.submitZipcode()
    this.props.onClick(this.props.zipCode)

    if (this.props.zipCode && this.props.zipCode.is_active) {
      this.props.resetZipCodeField('zipcode.item')
    }
  }

  render() {
    const { zipCode } = this.props

    return (
      <SearchNumberInput
        minLength={5}
        maxLength={5}
        onChange={this.handleChange}
        onClick={this.submitInput}
        placeholder={I18n.t('homepages.wasteProducer.zipCode.insertZipCode')}
        zipCode={zipCode}
      />
    )
  }
}

export default connector(ZipCodeInput)
