import './style.scss'

import { arrayOf, bool, func, shape } from 'prop-types'
import React, { Component } from 'react'

import { AttachmentScheme } from 'components/common/fileUpload/schemes'
import { get } from 'helper/general'

import FileThumbnail from './components/FileThumbnail'

/**
 * @description This component displays files.
 */
export class FilesPreviewerComponent extends Component {
  render() {
    return (
      <div className='files-previewer'>
        <ul className='uk-thumbnav'>
          {this.props.files.map(file => (
            <li
              key={file.id}
              className={`uk-thumbnav-item ${
                file.id === get(() => this.props.selectedFile.id)
                  ? 'uk-active'
                  : ''
              }`}
              data-uk-tooltip={
                this.props.withFilenameTooltip ? file.name : undefined
              }
            >
              <button
                type='button'
                className='uk-button'
                onClick={() => this.props.onClick(file)}
              >
                <FileThumbnail file={file} />
              </button>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

FilesPreviewerComponent.propTypes = {
  files: arrayOf(shape(AttachmentScheme)),
  selectedFile: shape(AttachmentScheme),
  onClick: func,
  withFilenameTooltip: bool,
}

FilesPreviewerComponent.defaultProps = {
  files: [],
  selectedFile: null,
  onClick: () => undefined,
  withFilenameTooltip: false,
}

export default FilesPreviewerComponent
