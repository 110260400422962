import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { ReactComponent as Truck } from 'assets/svg/detailspage/truck.svg'
import AccordionItem from 'components/common/Accordion/components/AccordionItem'
import { COLLECTION_CONTAINER_IDS } from 'constants/app'

import { NewOrderFormPropsType } from '../../constants'
import { getCurrentContainer } from '../../helper'
import { NewOrderFields } from '../../NewOrderFields'

export interface NewOrderSectionProps {
  addScrollElement: string
  containerList: Container[]
  formProps: NewOrderFormPropsType
  offer: Offer
}

export const NewOrderSection: FC<NewOrderSectionProps> = props => {
  const { addScrollElement, containerList, formProps, offer } = props

  const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
    Number(offer.container),
  )

  const iconTitle = (
    <>
      <Truck className='inquiry-details-page__section-header' />
      <Translate value='agreementDetailsTranslations.cta.createForm.title' />
    </>
  )

  return (
    <AccordionItem addScrollElement={addScrollElement} title={iconTitle}>
      <div
        className='inquiry-details-page__section uk-child-width-1-1'
        data-uk-grid=''
      >
        <span className='uk-width-1-1'>
          <Translate
            value='agreementDetailsTranslations.cta.createForm.text'
            reactionTime={I18n.t(
              `constant.date.interval.hours${
                getCurrentContainer(containerList, offer.container)!
                  .reaction_time
              }`,
            )}
            dangerousHTML
          />
        </span>
        <NewOrderFields
          key='offer-new-order-fields'
          offer={offer}
          showQuantityInCubicMeters={showQuantityInCubicMeters}
          {...formProps}
        />
      </div>
    </AccordionItem>
  )
}
