import classNames from 'classnames'
import React, { FC } from 'react'

interface CardHeaderProps {
  className?: string
  title: string
  titleIsHighlighted?: boolean
  subtitle?: string
  subtitleIsHighlighted?: boolean
}

/**
 * A component which renders the header section with its styles of our new cards.
 * @constructor
 */
export const CardHeader: FC<CardHeaderProps> = ({
  className,
  title,
  titleIsHighlighted,
  subtitle,
  subtitleIsHighlighted,
  children,
}) => (
  <header className={classNames('new-card__header', className)}>
    <h3
      className={classNames('new-card__header-title', {
        'new-card__header-title--highlighted': titleIsHighlighted,
      })}
    >
      {title}
    </h3>
    <h4
      className={classNames('new-card__header-subtitle', {
        'new-card__header-subtitle--highlighted': subtitleIsHighlighted,
      })}
    >
      {title !== subtitle && subtitle}
    </h4>
    {children}
  </header>
)
