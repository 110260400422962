import './style.scss'

import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'

import withErrorBoundary from 'helper/withErrorBoundary'
import { addBackgroundGradient } from 'effects'

import { HomepageBenefitsSection } from './components/HomepageBenefitsSection'
import IntroSection from './components/IntroSection'
import { KnownFromSection } from './components/KnownFromSection'
import { SealBar } from './components/SealBar'
import { WhatIsEmptoSection } from './components/WhatIsEmptoSection'

interface HomepageComponentProps {
  pageTitle?: string
  pageDescription?: string
  showPrivateCustomerContent?: boolean
  showWasteProducerContent?: boolean
  showWasteDisposerContent?: boolean
}

const HomepageComponent: FC<HomepageComponentProps> = props => {
  const {
    pageTitle = I18n.t('pageTitles.homepageWasteProducer'),
    pageDescription = I18n.t('pageDescriptions.homepageWasteProducer'),
    showPrivateCustomerContent = false,
    showWasteProducerContent = false,
    showWasteDisposerContent = false,
  } = props

  useEffect(
    () =>
      addBackgroundGradient(
        showWasteDisposerContent,
        showPrivateCustomerContent,
      ),
    [showPrivateCustomerContent, showWasteDisposerContent],
  )

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name='Description' content={pageDescription} />
      </Helmet>

      <div className='homepage-waste-producer'>
        <div className='homepage-waste-producer__content'>
          <IntroSection
            showPrivateCustomerContent={showPrivateCustomerContent}
            showWasteProducerContent={showWasteProducerContent}
            showWasteDisposerContent={showWasteDisposerContent}
          />
          {(showWasteProducerContent || showWasteDisposerContent) && (
            <>
              <KnownFromSection />
              <WhatIsEmptoSection
                showWasteProducerContent={showWasteProducerContent}
                showWasteDisposerContent={showWasteDisposerContent}
              />

              <HomepageBenefitsSection
                showWasteProducerContent={showWasteProducerContent}
                showWasteDisposerContent={showWasteDisposerContent}
              />

              {/* REGISTER (disabled in #3228) */}
              {/*<RegisterCallToActionSection*/}
              {/*  showWasteProducerContent={showWasteProducerContent}*/}
              {/*  showWasteDisposerContent={showWasteDisposerContent}*/}
              {/*/>*/}
              <SealBar />
            </>
          )}
        </div>
      </div>
    </>
  )
}

export const Homepage = withErrorBoundary(HomepageComponent)
