import './WasteTypeCard.scss'

import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'

interface WasteTypeCardProps {
  fraction: Fraction
}

/**
 * WasteTypeCard displays the card for the external visible waste types
 */
export const WasteTypeCard: FC<WasteTypeCardProps> = ({ fraction }) => {
  const { name, image, slug } = fraction

  return (
    <Link className='waste-type-card' to={`/abfallarten/${slug}`}>
      {image && (
        <img className='waste-type-card__image' src={image} alt={name} />
      )}
      <div className='waste-type-card__rectangle '>
        <Button
          className='waste-type-card__button'
          onClick={() => undefined}
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          fullWidth
        >
          {name}
        </Button>
      </div>
    </Link>
  )
}
