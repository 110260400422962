import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getCompaniesForFilter } from 'actions/company'
import {
  AsyncMultiselectCombobox,
  AsyncMultiselectComboboxProps,
} from 'components/common/AsyncMultiselectCombobox/AsyncMultiselectCombobox'
import { COMPANY_ROLE } from 'components/company/constants'
import { DisposerProducerFilters } from 'components/maklerpremium/DisposerProducerPage/DisposerProducerPage'
import { getCompaniesForFilterSelector } from 'selectors/company'
import { createLoadingSelector } from 'selectors/loading'

import { CommonFilterProps } from '../../types'

const emptyCompanyObject = {
  id: undefined,
  block_auto_offers: false,
  central_contact: '',
  central_contact_email: '',
  empto_external_number: '',
  location: '',
  name: '',
  slug: '',
  street: '',
  zipcode: '',
}

const filterOptionAll = {
  ...emptyCompanyObject,
  id: 0,
  name: I18n.t('general.placeholder.all'),
}

export enum COMPANY_FILTER {
  EMPTO = 'empto_and_role',
  MAKLER_PREMIUM = 'maklerpremium_and_role',
  ALL = 'role',
}

// This type accommodates both the getMaklerPremiumCompanies and the getCompaniesForFilter actions
type ActionType =
  | ((page: number | null, currentFilters: DisposerProducerFilters) => any)
  | ((filters: any) => any)

interface CompanySearchFilterProps
  extends Partial<CommonFilterProps>,
    Omit<
      Partial<AsyncMultiselectComboboxProps>,
      | 'errors'
      | 'handleBlur'
      | 'handleChange'
      | 'isLoading'
      | 'loadOptions'
      | 'options'
    > {
  action?: ActionType
  currentFilters?: DisposerProducerFilters | Record<string, any> // Special case for CopmanyAndIdFilter
  label: string
  minCharacters?: number
  name?: string
  noResultsText: string
  onSelectionChange?: (company: any) => void
  placeholder: string
  showOnlyActiveCompanies?: boolean
  filter?: Record<string, unknown>
  resultType?: COMPANY_ROLE
  roleFilter?: COMPANY_FILTER
  setCurrentFilterValues?: (currentFilters: any) => void // only used if component is used in filter
  mapToOption?: (company: any) => { label: string; value: string }
  // pass mapToOption if default mapToOption function can not be used
  loadOptions?: (value?: string) => void
  options?: Record<string, any>[]
  isClearable?: boolean
  defaultCompanyId?: number | undefined // will be used to determine the default option
  additionalFilterForLoadOptions?: any
}

export const CompanySearchFilter: FC<CompanySearchFilterProps> = ({
  action,
  currentFilters = {},
  label,
  mapToOption,
  minCharacters = 3,
  name = 'company_search',
  noResultsText,
  onSelectionChange,
  placeholder,
  resultType,
  roleFilter = COMPANY_FILTER.MAKLER_PREMIUM,
  setCurrentFilterValues,
  dataTestId,
  showOnlyActiveCompanies,
  withCheckmark,
  showCheckmark,
  error,
  isClearable = false,
  defaultCompanyId,
  additionalFilterForLoadOptions,
}) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(
    createLoadingSelector(['GET_COMPANIES_FOR_FILTER']),
  )

  const reduxCompanies = useSelector(getCompaniesForFilterSelector)
  const [companies, setCompanies] = useState<CompanySearchFilterResponse[]>([])
  const [defaultCompany, setDefaultCompany] =
    useState<CompanySearchFilterResponse>(emptyCompanyObject)

  useEffect(() => {
    if (defaultCompanyId) {
      dispatch(getCompaniesForFilter({ id: defaultCompanyId }))
    }
  }, [defaultCompanyId, dispatch])

  useEffect(() => {
    if (!isLoading) {
      setCompanies(reduxCompanies)
    }

    if (
      !isLoading &&
      defaultCompanyId &&
      defaultCompany?.id === undefined &&
      reduxCompanies.length > 0
    ) {
      setDefaultCompany(
        reduxCompanies.filter(company => company.id === defaultCompanyId)[0],
      )
    }
  }, [isLoading, reduxCompanies, defaultCompanyId, defaultCompany])

  const loadOptionsFromApi = (inputValue?: string) => {
    if (inputValue && inputValue.length >= minCharacters) {
      // if not explicitly passed as prop, action will default to getMaklerPremiumCompanies
      let filter = {
        search: inputValue,
        onlyActive: false,
      }
      if (showOnlyActiveCompanies) {
        filter.onlyActive = showOnlyActiveCompanies
      }
      if (additionalFilterForLoadOptions) {
        filter = {
          ...filter,
          ...additionalFilterForLoadOptions,
        }
      }
      filter[roleFilter] = resultType
      dispatch(getCompaniesForFilter(filter))
    }
  }

  const onSelectionChangeForFilter = (companyId, companySlug) => {
    const id_slug = companyId !== 0 ? `${companyId},${companySlug}` : ''

    const newFilters = {
      ...currentFilters,
      page: 1,
      id_slug: id_slug,
    }
    if (setCurrentFilterValues) {
      setCurrentFilterValues(newFilters)
    }
    if (action) {
      dispatch(action(null, newFilters as DisposerProducerFilters))
    }
  }

  const defaultLabel = (company: CompanySearchFilterResponse) =>
    company.id === 0
      ? company.name
      : `${company.name} / ${company.empto_external_number} / ${company.street} / ${company.location} /
      ${company.central_contact} / ${company.central_contact_email}`

  const defaultValue = (company: CompanySearchFilterResponse) =>
    `${company?.id},${company?.slug}`

  // prepend filter option for all companies
  useEffect(() => {
    if (!mapToOption) {
      reduxCompanies.unshift(filterOptionAll)
    }
  }, [reduxCompanies, mapToOption])

  return (
    <AsyncMultiselectCombobox
      dataTestId={dataTestId}
      error={error}
      getOptionLabel={company =>
        mapToOption
          ? mapToOption(company).label
          : defaultLabel(company as CompanySearchFilterResponse)
      }
      getOptionValue={company =>
        mapToOption
          ? mapToOption(company).value
          : defaultValue(company as CompanySearchFilterResponse)
      }
      handleBlur={() => undefined}
      handleChange={company => {
        // resets company to filterOptionAll after clearing the select component
        const companyIsEmptyArray =
          Array.isArray(company) && company.length === 0
        company = companyIsEmptyArray ? filterOptionAll : company

        if (onSelectionChange) {
          onSelectionChange(company)
        } else {
          onSelectionChangeForFilter(company.id, company.slug)
        }
      }}
      isLoading={isLoading}
      label={label}
      loadingMessage={''}
      loadOptions={loadOptionsFromApi}
      multiSelect={false}
      name={name}
      noInputMessage={placeholder}
      noOptionsMessage={noResultsText}
      options={companies}
      placeholder={placeholder}
      showCheckmark={showCheckmark}
      withCheckmark={withCheckmark}
      isClearable={isClearable}
      defaultValue={
        defaultCompanyId && defaultCompany?.id !== undefined
          ? defaultCompany
          : undefined
      }
    />
  )
}
