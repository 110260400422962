import { number, oneOfType, shape, string } from 'prop-types'

import { OrderHistoryScheme, OrderSmallScheme } from './order'

export const OrderStatusSmallScheme = {
  id: number,
  value: number,
  created_at: string,
  created_by_name: string,
  order: number,
  order_history: shape(OrderHistoryScheme),
}

export const OrderStatusConfigScheme = {
  id: oneOfType([number, string]),
  name: string,
  message: string,
  progress: number,
  totalProgress: number,
}

export const OrderStatusUniScheme = {
  id: number,
  value: number,
  created_at: string,
  created_by_name: string,
  order_object: shape(OrderSmallScheme),
  type: string,
  claim_status: number,
}
