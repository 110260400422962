import uniqueId from 'lodash.uniqueid'
import React, { FC, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Action } from 'redux'

import { resetApiFetchErrors, resetApiFetchLoading } from 'actions/app'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'
import { getCurrentUserSelector } from 'selectors/user'

import { Button, BUTTON_BACKGROUND_COLOR, BUTTON_TYPE } from '../Button'
import { Modal } from '../Modal'
import ModalHeader from '../ModalHeader'
import { ProgressButton } from '../ProgressButton'

interface AsyncExportModalProps {
  isOpen: boolean
  onClose: () => void
  resetOnDispatch?: () => void
  reduxSelector: string
  title: string
  description_translation_key?: string
  notice_translation_key?: string
  logic: Action
  children?: any
}

export const AsyncExportModal: FC<AsyncExportModalProps> = ({
  isOpen,
  onClose,
  reduxSelector,
  title,
  description_translation_key,
  notice_translation_key,
  logic,
  children,
  resetOnDispatch,
}) => {
  const dispatch = useDispatch()

  const apiError = useSelector(createErrorSelector([reduxSelector])) as
    | ApiError
    | undefined
  const isLoading = useSelector(createLoadingSelector([reduxSelector]))
  const status = useSelector(createRequestStatusSelector([reduxSelector]))

  const user = useSelector(getCurrentUserSelector)

  const idAsyncExportModalHeadline = uniqueId()

  // Although there is no effect to cleanup, we can use the 'useEffect' capabilities to reset the ApiErrors on
  // general cleanup. This will behave like previously using 'componentWillUnmount'. A sole function is returned by
  // the effect with the desired action
  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors(reduxSelector))
    },
    [dispatch, reduxSelector],
  )

  useEffect(() => {
    if (status === REQUEST_STATUS.SUCCESS) {
      onClose()
      // reset loading status so the modal doesn't autoclose immediately
      dispatch(resetApiFetchLoading(reduxSelector))
    }
  }, [dispatch, onClose, reduxSelector, status])

  return (
    <Modal
      ariaDescribedBy={idAsyncExportModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={title}
        titleId={idAsyncExportModalHeadline}
      />
      <div className='uk-padding'>
        {/* DESCRIPTION */}
        <p
          className='uk-text'
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: I18n.t(
              description_translation_key ??
                'asyncExportModalTranslations.description',
              {
                userEmail: user.email,
              },
            ),
          }}
        />
        {children}
      </div>

      <div className='uk-modal-footer uk-text-right'>
        {notice_translation_key && (
          <p className='uk-text form-info'>{I18n.t(notice_translation_key)}</p>
        )}
        {/* CANCEL */}
        <span className='uk-margin-right'>
          <Button
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            onClick={onClose}
          >
            {I18n.t('general.button.cancel')}
          </Button>
        </span>

        {/* SUBMIT */}
        <ProgressButton
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          isDisabled={isLoading || !!apiError}
          isLoading={isLoading}
          onClick={() => {
            dispatch(logic)
            if (resetOnDispatch) resetOnDispatch()
          }}
          type={BUTTON_TYPE.SUBMIT}
          dataTestId='export-submit-button'
        >
          {I18n.t('general.button.submit')}
        </ProgressButton>
      </div>
    </Modal>
  )
}
