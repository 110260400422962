import { agreementRoute } from './agreement'
import { autoOfferRoute } from './autooffer'
import { certificateRoutes } from './certificate'
import { claimProofRoutes } from './claimproof'
import { companyRoutes } from './company'
import { dashboardRoutes } from './dashboard'
import { discountRoute } from './discount'
import { dsztRoute } from './dsz'
import { executionProofRoutes } from './executionproof'
import { generalRoutes } from './general'
import { homepageRoutes } from './homepages'
import { maklerpremiumRoutes } from './maklerpremium'
import { offerRoute } from './offer'
import { orderRoutes } from './order'
import { tvpRoutes } from './tvp'
import { userRoute } from './user'

/**
 * @constant
 * @description A constant to define all routes.
 */
export const routes = [
  ...agreementRoute,
  ...autoOfferRoute,
  ...certificateRoutes,
  ...claimProofRoutes,
  ...companyRoutes,
  ...dashboardRoutes,
  ...discountRoute,
  ...dsztRoute,
  ...executionProofRoutes,
  ...generalRoutes,
  ...homepageRoutes,
  ...maklerpremiumRoutes,
  ...offerRoute,
  ...orderRoutes,
  ...tvpRoutes,
  ...userRoute,
]
