import './style.scss'

import React, { FC, useEffect } from 'react'
import { Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getInstructions } from 'actions/core'
import { getInstructionsSelector } from 'selectors/core'

import { BUTTON_BACKGROUND_COLOR, BUTTON_FONT_COLOR } from '../../common/Button'
import { ICON_NAME } from '../../common/Fontello'
import { ICON_POSITION, IconButton } from '../../common/IconButton'

export const Instruction: FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const path = location.pathname

  const instructions = useSelector(getInstructionsSelector)

  useEffect(() => {
    dispatch(getInstructions({ path: path }))
  }, [dispatch, path])

  return (
    <>
      {instructions.length > 0 && path === instructions[0].path && (
        <div className='instruction'>
          <IconButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.GRADIENT}
            fontColor={BUTTON_FONT_COLOR.WHITE}
            iconPosition={ICON_POSITION.RIGHT}
            iconName={ICON_NAME.QUESTION}
            to={{ pathname: instructions[0].file }}
            onClick={event => {
              event.preventDefault()
              window.open(instructions[0].file)
            }}
          >
            <Translate value={'instruction.button.text'} />
          </IconButton>
        </div>
      )}
    </>
  )
}
