import keyMirror from 'keymirror'

export const GET_LANDING_PAGE_WASTE_MANAGER_CONTENT = keyMirror({
  GET_LANDING_PAGE_WASTE_MANAGER_CONTENT_REQUEST: null,
  GET_LANDING_PAGE_WASTE_MANAGER_CONTENT_SUCCESS: null,
  GET_LANDING_PAGE_WASTE_MANAGER_CONTENT_ERROR: null,
})

export const GET_LANDING_PAGE_WASTE_PRODUCER_CONTENT = keyMirror({
  GET_LANDING_PAGE_WASTE_PRODUCER_CONTENT_REQUEST: null,
  GET_LANDING_PAGE_WASTE_PRODUCER_CONTENT_SUCCESS: null,
  GET_LANDING_PAGE_WASTE_PRODUCER_CONTENT_ERROR: null,
})
