import './style.scss'

import React, { Component } from 'react'
import Spinner from 'react-spinkit'
import { func, node } from 'prop-types'

import Paragraph from 'components/common/Paragraph'

class OfferSearchIndicator extends Component {
  static propTypes = {
    children: node.isRequired,
    onComplete: func,
  }

  static defaultProps = {
    onComplete: () => undefined,
  }

  componentDidMount() {
    // Fake for searching offers. There is no real API call.
    setTimeout(this.props.onComplete, 3000)
  }

  render() {
    const { children } = this.props

    return (
      <div className='offer-search-indicator'>
        <div className='uk-margin-medium-bottom'>
          <Spinner
            name='ball-pulse-sync'
            fadeIn='none'
            overrideSpinnerClassName='offer-search-indicator__spinner'
          />
        </div>

        <div className='offer-search-indicator__content'>
          <Paragraph>{children}</Paragraph>
        </div>
      </div>
    )
  }
}

export default OfferSearchIndicator
