import * as React from 'react'

import { ValueChange } from 'components/common/ValueChange'

import { DashboardTileIconComponent } from '../DashboardTileIcon/DashboardTileIcon'

import {
  ClassificationTileContentProps,
  NUMBER_FORMAT,
} from './ClassificationTile'

export const ClassificationTileContent = ({
  caption,
  value,
  offsetValue,
  icon,
  hideIcon = false,
  format = NUMBER_FORMAT.INTEGER,
}: ClassificationTileContentProps) => {
  const captions = caption.split(/\n/).map((cap, idx, arr) => (
    <div key={cap} className='classification-tile__caption-line'>
      {cap}
      {idx < arr.length && <br />}
    </div>
  ))

  let style: any = { style: 'decimal', minimumFractionDigits: 0 }
  if (format === NUMBER_FORMAT.DECIMAL) {
    style = {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  } else if (format === NUMBER_FORMAT.CURRENCY) {
    style = { style: 'currency', currency: 'EUR' }
  }

  return (
    <div className='classification-tile__content'>
      {!hideIcon && (
        <div className='dashboard-tile__icon'>
          <DashboardTileIconComponent icon={icon} />
        </div>
      )}
      <div className='classification-tile__value'>
        {typeof value === 'string'
          ? value
          : new Intl.NumberFormat('de-DE', style).format(Number(value))}
      </div>
      {!Number.isNaN(Number(value)) &&
        !Number.isNaN(Number(offsetValue)) &&
        Number(value) - Number(offsetValue) !== 0 && (
          <div className='classification-tile__value-change'>
            <ValueChange
              value={Number(value)}
              offsetValue={Number(offsetValue)}
              format={format}
            />
          </div>
        )}
      <div className='classification-tile__caption'>
        {captions as React.ReactNode}
      </div>
    </div>
  )
}
