import { Formik, FormikErrors } from 'formik'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import { createCustomer } from '../../actions/maklerpremium'
import { withApiErrorHandling } from '../../helper/withApiErrorHandling'
import withErrorBoundary from '../../helper/withErrorBoundary'

import { AddWasteProducerInnerForm } from './AddWasteProducerForm'
import { MAX_LENGTH } from './constants'
import { CustomerAddressValues } from './CustomerAddressFields'
import { PrivateCustomerValues } from './PrivateCustomerFields'
import { TaxSectionValues } from './TaxSectionFields'

export interface AddWasteProducerFormValues
  extends CustomerAddressValues,
    PrivateCustomerValues,
    TaxSectionValues {
  is_company: boolean
  // Geschäftskunde
  company_name: string
  // Privatkunde
  permanent_discount: number
  // Central Contact Data for EPD
  has_central_contact: boolean
  central_contact_email: string
  central_contact_phone: string
  // Contact Person
  contact_person_is_male: boolean
  contact_person_first_name: string
  contact_person_last_name: string
  contact_person_position: string
  contact_person_phone: string
  contact_person_email: string
}

const AddWasteProducerWrapperComponent: FC<{ onSuccess: () => void }> = ({
  onSuccess,
}) => {
  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={{
        is_company: true,
        // Geschäftskunde
        company_name: '',
        tax_number: '',
        tax_id: '',
        // Privatkunde
        is_male: true,
        first_name: '',
        last_name: '',
        // Geschäftskunde + Privatkunde
        street: '',
        house_number: '',
        postal_code: '',
        city: '',
        permanent_discount: 0,
        // Central Contact Data for EPD
        has_central_contact: false,
        central_contact_email: '',
        central_contact_phone: '',
        // Contact Person
        contact_person_is_male: true,
        contact_person_first_name: '',
        contact_person_last_name: '',
        contact_person_position: '',
        contact_person_phone: '',
        contact_person_email: '',
      }}
      onSubmit={(values, formikProps) => {
        dispatch(
          createCustomer(
            { ...values },
            {
              setErrorCallback: (key, string) =>
                formikProps.setFieldError(key, string),
              onSuccessCallback: () => onSuccess(),
            },
          ),
        )
        formikProps.setSubmitting(false)
      }}
      validationSchema={() =>
        yup.object().shape({
          is_company: yup.boolean().required(),
          // Geschäftskunde
          company_name: yup.string(),
          // Privatkunde
          is_male: yup
            .boolean()
            .required(
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.is_male',
              ),
            ),
          first_name: yup
            .string()
            .max(
              MAX_LENGTH.FIRST_NAME,
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.too_long_message',
              ),
            ),
          last_name: yup
            .string()
            .max(
              MAX_LENGTH.LAST_NAME,
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.too_long_message',
              ),
            ),
          // Geschäftskunde + Privatkunde
          street: yup
            .string()
            .required(
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.street',
              ),
            )
            .max(
              MAX_LENGTH.STREET,
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.too_long_message',
              ),
            ),
          house_number: yup
            .string()
            .required(
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.house_number',
              ),
            )
            .max(
              MAX_LENGTH.HOUSE_NUMBER,
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.too_long_message',
              ),
            ),
          postal_code: yup
            .string()
            .matches(
              /^\d{5}$/,
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.postal_code_invalid',
              ),
            )
            .required(
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.postal_code',
              ),
            ),
          city: yup
            .string()
            .required(
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.city',
              ),
            )
            .max(
              MAX_LENGTH.CITY,
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.too_long_message',
              ),
            ),
          permanent_discount: yup
            .number()
            .required(
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.permanent_discount',
              ),
            ),
          // Central Contact Data for EPD
          has_central_contact: yup
            .boolean()
            .required(
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.has_central_contact',
              ),
            ),
          central_contact_email: yup
            .string()
            .email(I18n.t('message.validation.email.isEmail'))
            .max(
              MAX_LENGTH.MAIL_ADDRESS,
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.too_long_message',
              ),
            ),
          central_contact_phone: yup
            .string()
            .max(
              MAX_LENGTH.PHONE_NUMBER,
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.too_long_message',
              ),
            ),
          // Contact Person
          contact_person_is_male: yup.boolean(),
          contact_person_first_name: yup
            .string()
            .required(
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.contact_person_first_name',
              ),
            )
            .max(
              MAX_LENGTH.FIRST_NAME,
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.too_long_message',
              ),
            ),
          contact_person_last_name: yup
            .string()
            .required(
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.contact_person_last_name',
              ),
            )
            .max(
              MAX_LENGTH.LAST_NAME,
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.too_long_message',
              ),
            ),
          contact_person_position: yup
            .string()
            .max(
              MAX_LENGTH.POSITION,
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.too_long_message',
              ),
            ),
          contact_person_phone: yup
            .string()
            .max(
              MAX_LENGTH.PHONE_NUMBER,
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.too_long_message',
              ),
            ),
          contact_person_email: yup
            .string()
            .email(I18n.t('message.validation.email.isEmail'))
            .required(
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.contact_person_email',
              ),
            )
            .max(
              MAX_LENGTH.MAIL_ADDRESS,
              I18n.t(
                'maklerpremium.addWasteProducerModal.form.validation.too_long_message',
              ),
            ),
          tax_id: yup
            .string()
            .matches(/^DE\d{9}$/i, I18n.t('message.validation.germanTaxId')),
          tax_number: yup
            .string()
            .matches(
              /^\d{10,11}|[\d/]{12,13}$/,
              I18n.t('message.validation.germanTaxNumber'),
            ),
        })
      }
      validate={(values: AddWasteProducerFormValues) => {
        const errors: FormikErrors<AddWasteProducerFormValues> = {}
        if (values.is_company) {
          if (values.company_name.length === 0) {
            errors.company_name = I18n.t(
              'maklerpremium.addWasteProducerModal.form.validation.company_name',
            )
          }
        } else {
          if (values.first_name.length === 0) {
            errors.first_name = I18n.t(
              'maklerpremium.addWasteProducerModal.form.validation.first_name',
            )
          }

          if (values.last_name.length === 0) {
            errors.last_name = I18n.t(
              'maklerpremium.addWasteProducerModal.form.validation.last_name',
            )
          }
        }
        if (values.has_central_contact) {
          if (values.central_contact_email.length === 0) {
            errors.central_contact_email = I18n.t(
              'maklerpremium.addWasteProducerModal.form.validation.central_contact_email',
            )
          }
          if (values.central_contact_phone.length === 0) {
            errors.central_contact_phone = I18n.t(
              'maklerpremium.addWasteProducerModal.form.validation.central_contact_phone',
            )
          }
        }
        return errors
      }}
    >
      <AddWasteProducerInnerForm />
    </Formik>
  )
}

export const AddWasteProducerWrapper = withErrorBoundary(
  withApiErrorHandling(AddWasteProducerWrapperComponent),
)
