export const OrderDisposeTranslation = {
  orderDispose: {
    headline: 'Bestätigen Sie den Auftrag',
    submitButton: 'Auftrag bestätigen',
    finalDeliveryHeadline: 'Bestätigen Sie den Behälterabzug',
    text: 'Bei Bedarf können Sie das von Ihrem Kunden gewünschte Leistungsdatum noch verändern.',
    finalDeliverySubmitButton: 'Abzug bestätigen',
    executionDate: 'Durchführung',
  },
}
