import { I18n } from 'react-i18nify'

import { ORDER_CLAIM_TYPES } from 'components/order/OrderDetailsPage/constants'

export const getReasonValue = reasonId => {
  const orderClaim = ORDER_CLAIM_TYPES.find(
    claimType => claimType.id === reasonId,
  )
  if (orderClaim) {
    return I18n.t(orderClaim.name)
  }
  return undefined
}

export default {}
