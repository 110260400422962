import classNames from 'classnames'
import React, { FC } from 'react'

interface CardDistinctAreaProps {
  className?: string
}

/**
 * A component which renders the distinct area section with its styles of our new cards.
 * @constructor
 */
export const CardDistinctArea: FC<CardDistinctAreaProps> = ({
  className,
  children,
}) => (
  <section className={classNames('new-card__distinct-area', className)}>
    {children}
  </section>
)
