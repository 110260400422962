import './styles.scss'

import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { ReactComponent as TruckGradient } from 'assets/svg/detailspage/truck_gradient.svg'
import { getCompanySelector } from 'selectors/company'

export const DisposerCustomerInfo: FC = () => {
  const company = useSelector(getCompanySelector)

  const getCompanyProfileImage = () => {
    if (company.profile_image_object) {
      return (
        <img src={company.profile_image_object.thumbnail} alt={company.name} />
      )
    } else {
      return <TruckGradient />
    }
  }

  return (
    <div className='disposer-customer-info'>
      <div className='disposer-customer-info__logo-wrapper'>
        <div className='disposer-customer-info__logo'>
          {getCompanyProfileImage()}
        </div>
      </div>
      <div className='disposer-customer-info__details'>
        <span className='disposer-customer-info__disposer-customer-name'>
          {company.name}
        </span>
        <dl className='disposer-customer-info__disposer-customer-details-list'>
          <dt>
            <Translate value='disposerCustomerInfoTranslations.disposerCustomerNumber' />
          </dt>
          <dd>{company.empto_external_number}</dd>
        </dl>
      </div>
    </div>
  )
}
