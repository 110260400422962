export const executionProofDetailsPageTranslations = {
  breadcrumb: {
    title: {
      review: 'Leistungsnachweisprüfung',
      details: 'Leistungsnachweis',
    },
    prevLinkTitle: 'Leistungsnachweise',
  },
  pageTitle: {
    review: 'Leistungsnachweisprüfung für Auftrag %{number}',
    details: 'Leistungsnachweis für Auftrag %{number}',
  },
  submitButtonText: 'Prüfung abschließen',
  validation: {
    fractionNotEqual: 'Bitte wählen Sie eine Option.',
    fraction: 'Bitte wählen Sie eine Abfallart aus.',
    avv: 'Bitte wählen Sie eine AVV Nummer aus.',
    weightDate: 'Bitte wählen Sie ein Datum aus.',
    weightReceipt: 'Dieses Feld muss befüllt sein.',
    weight: 'Dieses Feld muss befüllt sein.',
    numberOfContainers: 'Dieses Feld muss befüllt sein.',
    status: 'Bitte ändern Sie den Status.',
    rejectReason: 'Bitte begründen Sie Ihre Reklamation.',
    securityGroup: 'Dieses Feld muss befüllt sein.',
  },
}
