const userApiTranslations = {
  handleRegisterUser: {
    success: 'Benutzer wurde erfolgreich gespeichert.',
    error: 'Hinzufügen des Benutzers fehlgeschlagen.',
  },
  handleInviteUser: {
    success: 'Benutzereinladung erfolgreich gespeichert.',
    contact_person_success: 'Ansprechpartner erfolgreich hinzugefügt.',
    error: 'Hinzufügen der Benutzereinladung fehlgeschlagen.',
  },
  handleActivateCurrentUser: {
    error: 'Benutzer konnte leider nicht aktiviert werden.',
  },
  handleLogin: {
    error: 'Login fehlgeschlagen.',
  },
  handleGetCurrentUser: {
    error: 'Aktueller Benutzer konnte leider nicht geladen werden.',
  },
  handleGetMyUsers: {
    error: 'Ihre Benutzer konnten leider nicht geladen werden.',
  },
  handleForgetPassword: {
    error: 'Passwort vergessen E-Mail konnte leider nicht versendet werden.',
  },
  handlePasswordResetConfirm: {
    success: 'Sie können sich nun mit Ihrem neuen Passwort anmelden.',
    error: 'Passwort konnte nicht zurückgesetzt werden.',
  },
  handleSetPassword: {
    success: 'Passwort wurde erfolgreich gespeichert.',
    error: 'Hinzufügen des Passworts fehlgeschlagen.',
  },
  handleLogout: {
    error: 'Logout fehlgeschlagen.',
  },
  handleUpdateAccount: {
    success: 'Account wurde erfolgreich gespeichert.',
    error: 'Speichern des Accounts fehlgeschlagen.',
  },
  handleUpdateUser: {
    success: 'User wurde erfolgreich gespeichert.',
    error: 'Speichern des Users fehlgeschlagen.',
  },
  handleUpdateStatusUser: {
    activation: {
      success: 'Benutzer wurde erfolgreich entsperrt',
    },
    deactivation: {
      success: 'Benutzer wurde erfolgreich gesperrt',
    },
  },
  handleChangePassword: {
    success: 'Passwort wurde erfolgreich gespeichert.',
    error: 'Speichern des Passworts fehlgeschlagen.',
  },
  superUserNotAllowedError: 'Login fehlgeschlagen.',
  handleExportUsers: {
    success: 'Der CSV Export der Benutzer wurde erfolgreich gestartet.',
    error: 'Der CSV Export der Benutzer konnte nicht gestartet werden',
  },
  handleGetUsersByCompany: {
    error: 'Benutzer konnten leider nicht geladen werden.',
  },
  handleUserCommonValidationError: {
    email: 'User mit dieser E-Mail-Adresse existiert bereits.',
  },
}

export default userApiTranslations
