import './style.scss'

import React from 'react'

import FormGroupLabelProps from './schemes'

/**
 * @description This component renders and controls the form group label.
 * @param {Object} props JSX attributes to this component as a single object.
 * @return {*}
 */
const FormGroupLabel = props => {
  if (props.clickable) {
    return (
      <button
        type='button'
        onClick={() => props.onClick()}
        tabIndex={-1}
        className={[
          'form-group-label',
          `${props.required ? 'required' : ''}`,
          `${props.errors ? 'errors' : ''}`,
        ].join(' ')}
      >
        <p>{props.label}</p>
      </button>
    )
  }

  return (
    <div
      className={[
        'form-group-label',
        `${props.required ? 'required' : ''}`,
        `${props.errors ? 'errors' : ''}`,
      ].join(' ')}
    >
      <p>{props.label}</p>
    </div>
  )
}

FormGroupLabel.propTypes = FormGroupLabelProps

FormGroupLabel.defaultProps = {
  clickable: true,
  errors: null,
  onClick: () => undefined,
  required: false,
}

export default FormGroupLabel
