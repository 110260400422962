export const ORDER_EVALUATION_TYPE = {
  PUNCTUALITY: 10,
  FRIENDLINESS: 20,
  STATE: 30,
  SITUATION: 40,
  WAIT: 50,
}

export const ORDER_EVALUATION_TYPES: { id: number; name: string }[] = [
  {
    id: ORDER_EVALUATION_TYPE.PUNCTUALITY,
    name: 'orderEvaluation.type.punctuality',
  },
  {
    id: ORDER_EVALUATION_TYPE.FRIENDLINESS,
    name: 'orderEvaluation.type.friendliness',
  },
  {
    id: ORDER_EVALUATION_TYPE.STATE,
    name: 'orderEvaluation.type.state',
  },
  {
    id: ORDER_EVALUATION_TYPE.SITUATION,
    name: 'orderEvaluation.type.situation',
  },
  {
    id: ORDER_EVALUATION_TYPE.WAIT,
    name: 'orderEvaluation.type.wait',
  },
]

export enum ORDER_EVALUATION_RATING {
  NEUTRAL = 10,
  POSITIVE = 20,
  NEGATIVE = 30,
}

export const CUSTOMER_COMPANY_EVALUATION_TYPES = [
  ORDER_EVALUATION_TYPE.SITUATION,
  ORDER_EVALUATION_TYPE.WAIT,
]

export const OFFER_COMPANY_EVALUATION_TYPES = [
  ORDER_EVALUATION_TYPE.PUNCTUALITY,
  ORDER_EVALUATION_TYPE.FRIENDLINESS,
  ORDER_EVALUATION_TYPE.STATE,
]
