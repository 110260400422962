import { APP_CONSTANTS } from 'constants/app'
import { HttpService } from 'services/http'

/**
 * This service handles API requests by the file upload component.
 * The FileUpload class makes API calls (uploading and removing files), which is a little unusual fpr non-redux
 * components, but we do not want to write anything in the store.
 */
class FileUploadService {
  constructor() {
    this.httpClient = HttpService
  }

  /**
   * @function
   * @description Uploads one or several files to the API and returns a promise, which resolves when all files
   * were uploaded.
   * @param files {array<object>} the files to be uploaded.
   * @returns {*|PromiseLike<any>|Promise<any>}
   */
  uploadFiles(files) {
    const apiCalls = files.map(file => {
      // Files must be sent via multipart form, so we need to construct the request like this.
      const data = new FormData()
      data.append('file', file)

      return this.httpClient
        .post(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/attachment/`, data)
        .then(res => res.data)
    })

    return Promise.all(apiCalls)
  }

  /**
   * @description Deletes a file on the server and returns a promise.
   * @param file {object} the file to be deleted.
   * @returns {*|PromiseLike<any>|Promise<any>}
   */
  removeFile(file) {
    return this.httpClient
      .delete(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/attachment/${file.id}/`)
      .then(res => res.data)
  }
}

export default new FileUploadService()
