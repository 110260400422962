import './style.scss'

import moment from 'moment'
import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

import { RatingCaption } from 'components/common/RatingCaption'

interface InquiryOfferCardDetailsHeaderProps {
  id: number
  date: string
  company: string
  rating: number
}

/**
 * A component which renders the details header section of the InquiryOfferCard.
 * @constructor
 */
export const InquiryOfferCardDetailsHeader: FC<
  InquiryOfferCardDetailsHeaderProps
> = ({ company, date, id, rating }) => (
  <header className='inquiry-offer-card-details-header'>
    <div className='inquiry-offer-card-details-header__row'>
      <Translate
        value='newInquiryOfferCard.offerDescription'
        id={id}
        date={moment(date).format('L')}
        dangerousHTML
      />
    </div>
    <div className='inquiry-offer-card-details-header__row'>{company}</div>
    <div className='inquiry-offer-card-details-header__row'>
      <RatingCaption rating={rating} />
    </div>
  </header>
)
