import './DashboardPage.scss'

import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router'
import Spinner from 'react-spinkit'

import { getContainers } from '../../actions/container'
import { getDashboard } from '../../actions/dashboard'
import { getFractions } from '../../actions/fraction'
import { getDefaultPageRedirect } from '../../helper/router'
import { withApiErrorHandling } from '../../helper/withApiErrorHandling'
import withErrorBoundary from '../../helper/withErrorBoundary'
import { getContainersSelector } from '../../selectors/container'
import { dashboardSelector } from '../../selectors/dashboard'
import { getFractionsSelector } from '../../selectors/fraction'
import { createLoadingSelector } from '../../selectors/loading'
import {
  getCurrentUserSelector,
  getUserPermissionsSelector,
} from '../../selectors/user'
import { getRoles } from '../maklerpremium/helpers'

import { MasonryGrid } from './components/MasonryGrid'
import { NewsCard } from './components/NewsCard'
import { MaklerPremiumDashboard } from './MaklerPremium/MaklerPremiumDashboard'

/**
 * method to render the dashboard page component
 */
const DashboardPageComponent: FC = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const permissions = useSelector(getUserPermissionsSelector)
  const user = useSelector(getCurrentUserSelector)

  const fractionList = useSelector(getFractionsSelector)
  const fractionListLoading = useSelector(
    createLoadingSelector(['GET_FRACTIONS']),
  )
  const containerList = useSelector(getContainersSelector)
  const containerListLoading = useSelector(
    createLoadingSelector(['GET_CONTAINERS']),
  )

  // TODO = useSelector: remove when informations calculated from api and check information from
  //   createdCompanyOffersToday,
  //   createdCompanyOffersYesterday,
  //   sumActiveAcceptedOffersAndOrdersToday,
  //   sumActiveAcceptedOffersAndOrdersYesterday,
  const dashboardApiResponse = useSelector(dashboardSelector)
  const dashboardLoading = useSelector(createLoadingSelector(['GET_DASHBOARD']))
  const { news } = dashboardApiResponse

  useEffect(() => {
    dispatch(getDashboard())
    // reload only once on location change
  }, [dispatch, location.pathname])

  useEffect(() => {
    if (typeof fractionList === 'undefined' && !fractionListLoading) {
      dispatch(getFractions())
    }
  }, [dispatch, fractionList, fractionListLoading])

  useEffect(() => {
    if (typeof containerList === 'undefined' && !containerListLoading) {
      dispatch(getContainers())
    }
  }, [containerList, containerListLoading, dispatch])

  if (
    dashboardApiResponse.loading ||
    !user ||
    !fractionList.length ||
    !containerList.length
  ) {
    return (
      <>
        <Helmet>
          <title>{I18n.t('dashboard.headline')}</title>
        </Helmet>
        <main className='dashboard'>
          <div className='uk-flex uk-flex-center uk-margin-large-top'>
            <Spinner name='circle' />
          </div>
        </main>
      </>
    )
  }

  // define user booleans
  const { isMiddleMan, isMaklerPremium } = getRoles(user)

  // redirect the middleMan to its default routes when accessing this url directly
  if ((!user.company || isMiddleMan) && !isMaklerPremium) {
    return <Redirect to={getDefaultPageRedirect(permissions)} />
  }

  const dashboardComponent = (
    <>
      {isMaklerPremium && (
        <MaklerPremiumDashboard
          dashboardApiResponse={dashboardApiResponse}
          user={user}
        />
      )}
    </>
  )

  const newsComponent = news.map(({ id, date, image, label, text, title }) => (
    <NewsCard
      key={id}
      date={date}
      label={label}
      title={title}
      text={text}
      image={image}
    />
  ))

  return (
    <>
      <Helmet>
        <title>{I18n.t('dashboard.headline')}</title>
      </Helmet>
      <main className='dashboard'>
        {dashboardLoading ? (
          <div className='uk-flex uk-flex-center uk-margin-large-top'>
            <Spinner name='circle' />
          </div>
        ) : (
          <>
            {dashboardComponent}
            <MasonryGrid marginTop>{newsComponent}</MasonryGrid>
          </>
        )}
      </main>
    </>
  )
}

export const DashboardPage = withErrorBoundary(
  withApiErrorHandling(
    DashboardPageComponent,
    ['GET_DASHBOARD', 'GET_FRACTIONS', 'GET_CONTAINERS'],
    ['GET_DASHBOARD', 'GET_FRACTIONS', 'GET_CONTAINERS'],
  ),
)
