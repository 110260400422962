import keyMirror from 'keymirror'

const GET_COUNTRIES = keyMirror({
  GET_COUNTRIES_REQUEST: null,
  GET_COUNTRIES_SUCCESS: null,
  GET_COUNTRIES_ERROR: null,
})

export default {
  ...GET_COUNTRIES,
}
