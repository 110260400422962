export const emptoDownloadsTranslations = {
  pageTitle: 'Downloads (extern)',
  certificates: 'Zertifikate',
  documents: 'empto Dokumente',
  documentTitle: {
    qmCertificate: 'DIN EN ISO 9001:2015',
    enmCertificate: 'DIN EN ISO 50001:2011',
    efbCertificate: 'Entsorgungsfachbetrieb',
    participationConditions: 'Teilnahmebedingungen',
    dataProtection: 'Datenschutzerklärung',
    flyer: 'Flyer',
    sortingCriteria: 'Sortierkriterien',
    destructionConditions: 'Vernichtung gemäß DIN 66399',
    emptoLogo: 'empto Logo',
    priceSheet: 'Preisblatt für Entsorgungsunternehmen',
  },
}
