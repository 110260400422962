import { DSZDashboardTranslations } from './DSZ/translation'
import { EPDDashboardTranslations } from './EPD/translation'
import { TVPDashboardTranslations } from './TVP/translation'

export const MPDashboardTranslations = {
  TVP: TVPDashboardTranslations,
  EPD: EPDDashboardTranslations,
  DSZ: DSZDashboardTranslations,
  headline: 'Hallo %{firstname} %{lastname}!',
  customerManage: {
    buttonText: 'Kunden verwalten',
    value: 'Kundenverwaltung',
    caption: 'Übersicht über alle Kunden und Verwaltungsmöglichkeiten',
  },
}
