import './styles.scss'

import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import LogoDin90001Image from 'assets/img/homepage/logo-din-90001.svg'
import LogoEntsorgungFachbetriebImage from 'assets/img/homepage/logo-entsorgung-fachbetrieb.svg'
import LogoSSLImage from 'assets/img/homepage/logo-ssl.svg'

export const SealBar: FC = () => (
  <section className='seal-bar'>
    <img
      className='seal-bar__seal'
      src={LogoEntsorgungFachbetriebImage}
      alt={I18n.t('homepages.wasteProducer.sealBar.altTexts.disposalCompany')}
    />
    <img
      className='seal-bar__seal'
      src={LogoDin90001Image}
      alt={I18n.t('homepages.wasteProducer.sealBar.altTexts.din90001')}
    />
    <img
      className='seal-bar__seal'
      src={LogoSSLImage}
      alt={I18n.t('homepages.wasteProducer.sealBar.altTexts.ssl')}
    />
  </section>
)
