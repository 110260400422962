import './style.scss'

import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'

import { Button, BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import IndicationMessage from '../../common/IndicationMessage'

interface MyAutoOffersIndicationMessagesProps {
  isCompanyInactive?: boolean
  areAutoOffersLocked?: boolean
  lockUnlockAutoOffersAction?: () => void
}

export const MyAutoOffersIndicationMessages: FC<
  MyAutoOffersIndicationMessagesProps
> = ({
  isCompanyInactive = false,
  areAutoOffersLocked = false,
  lockUnlockAutoOffersAction = () => undefined,
}) => (
  <div className='my-auto-offers-page__indication-messages'>
    {isCompanyInactive && (
      <IndicationMessage
        className='warning'
        iconName='lock'
        statusIconName='clock'
      >
        <p className='my-auto-offers-page__indication-messages--bold'>
          <Translate value='myAutoOffersPageTranslations.inactiveCompany.title' />
        </p>

        <p>
          <Translate value='myAutoOffersPageTranslations.inactiveCompany.message.start' />
          <Link to='/company/profile'>
            <Translate value='myAutoOffersPageTranslations.inactiveCompany.message.link' />
          </Link>
          <Translate value='myAutoOffersPageTranslations.inactiveCompany.message.end' />
        </p>
      </IndicationMessage>
    )}

    {areAutoOffersLocked && (
      <IndicationMessage
        className='warning'
        iconName='document'
        statusClass='warning'
        statusIconName='information'
      >
        <p className='my-auto-offers-page__indication-messages--bold'>
          <Translate value='myAutoOffersPageTranslations.unlockAutoOffers.title' />
        </p>
        <p>
          <Translate value='myAutoOffersPageTranslations.unlockAutoOffers.message' />
        </p>
        <div className='my-auto-offers-page__lock-unlock-modal-button'>
          <Button
            backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
            onClick={() => lockUnlockAutoOffersAction()}
          >
            <Translate value='myAutoOffersPageTranslations.unlockAutoOffers.button' />
          </Button>
        </div>
      </IndicationMessage>
    )}
  </div>
)
