import React, { Component } from 'react'
import { I18n } from 'react-i18nify'
import { shape } from 'prop-types'

import DetailRow from 'components/common/DetailSwitcher/components/DetailRow'
import DetailSection from 'components/common/DetailSwitcher/components/DetailSection'
import { COMPANY_ROLES } from 'components/company/constants'
import { getName } from 'helper/translations'
import { OrderScheme } from 'schemes/order'

/**
 * @description This component renders the order parties component.
 */
export class PartiesComponent extends Component {
  render() {
    const { order } = this.props
    return (
      <>
        <DetailSection title={I18n.t('detailsSwitcher.aez')}>
          <DetailRow
            label={I18n.t('general.name')}
            value={order.offer_object.customer_company_object.name}
            path={`/company/${order.offer_object.customer_company_object.id}`}
          />
          <DetailRow
            label={I18n.t('inquiryDetails.company.role')}
            value={I18n.t(
              getName(
                order.offer_object.customer_company_object.role,
                COMPANY_ROLES,
              ),
            )}
          />
        </DetailSection>
        <br />
        <DetailSection title={I18n.t('detailsSwitcher.disposer')}>
          <DetailRow
            label={I18n.t('general.name')}
            value={order.offer_object.company_object.name}
            path={`/company/${order.offer_object.company_object.id}`}
          />
          <DetailRow
            label={I18n.t('inquiryDetails.company.role')}
            value={I18n.t(
              getName(order.offer_object.company_object.role, COMPANY_ROLES),
            )}
          />
        </DetailSection>
      </>
    )
  }
}

PartiesComponent.propTypes = {
  order: shape(OrderScheme).isRequired,
}

export default PartiesComponent
