import './styles.scss'

import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'

import { ReactComponent as PersonIcon } from 'assets/svg/detailspage/person.svg'
import Icon from 'components/common/Fontello'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'

import { ResponsibleEmployee } from '../ResponsibleEmployee'

/**
 * @description This component displays company contact information
 */
export const ContactInformation: FC<{
  company?: Company
}> = ({ company }) => {
  if (!company) return null

  const contactPerson = company.contact_person_object

  /**
   * The hCard 1.0 microformat is applied for the address / contact information
   * See http://microformats.org/wiki/hcard
   */

  return (
    <div className='contact-information vcard uk-width-1-1@l uk-width-1-3@m'>
      <div className='contact-information__block'>
        <Headline
          className='company-info-page__contact-headline'
          headlineStyle={HEADLINE_STYLE.H5}
          fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
          fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
          tag={HEADLINE_TAG.H5}
          color={HEADLINE_COLOR.BLACK}
        >
          <Icon name='location' />
          <Translate value='companyInfoPage.contact' />
        </Headline>

        <p className='uk-margin-small-bottom'>
          {company.main_address_object.street}{' '}
          {company.main_address_object.house_number},{' '}
          {company.main_address_object.zipcode}{' '}
          {company.main_address_object.location}
          <br />
          {company.invoice_recipient && (
            <>
              {company.invoice_recipient}
              <br />
            </>
          )}
          {company.central_contact_email && (
            <>
              <a href={`mailto:${company.central_contact_email}`}>
                {company.central_contact_email}
              </a>
              <br />
            </>
          )}
          {company.central_contact_phone && (
            <>
              <a href={`tel:${company.central_contact_phone}`}>
                {company.central_contact_phone}
              </a>
              <br />
            </>
          )}
          {company.website_url && (
            <>
              <a
                href={company.website_url}
                target='_blank'
                rel='noopener noreferrer'
              >
                {company.website_url}
              </a>
              <br />
            </>
          )}
        </p>

        {contactPerson && (
          <>
            <Headline
              className='company-info-page__contact-headline'
              headlineStyle={HEADLINE_STYLE.H5}
              fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
              fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
              tag={HEADLINE_TAG.H5}
              color={HEADLINE_COLOR.BLACK}
            >
              <PersonIcon className='icon ' />
              <Translate value='companyInfoPage.contact_person' />
            </Headline>
            <p className='uk-margin-small-bottom'>
              <span className='given-name'>{contactPerson.first_name}</span>
              &nbsp;
              <span className='family-name'>{contactPerson.last_name}</span>
              <br />
              {contactPerson.email && (
                <>
                  <a href={`mailto:${contactPerson.email}`}>
                    {contactPerson.email}
                  </a>
                  <br />
                </>
              )}
              {contactPerson.business_phone_number && (
                <>
                  <a href={`tel:${contactPerson.business_phone_number}`}>
                    {contactPerson.business_phone_number}
                  </a>
                  <br />
                </>
              )}
              <Link to={company ? `/user/company/${company.id}` : ''}>
                <Translate value='allContactPersons.link' />
              </Link>
            </p>
          </>
        )}
        <hr />
        <ResponsibleEmployee />
      </div>
    </div>
  )
}
