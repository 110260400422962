import ActionTypes from '../actiontypes/country'

/** @function
 * @name getCountries
 * @description An action method to load countries
 * @return {Object}
 */
export const getCountries = () => ({
  type: ActionTypes.GET_COUNTRIES_REQUEST,
})

export default {}
