import './style.scss'

import React, { Component } from 'react'
import { Translate } from 'react-i18nify'
import { arrayOf, shape } from 'prop-types'

import { WIDTH_BREAKEPOINT_MEDIUM } from 'components/order/OrderDetailsPage/constants'
import { ContainerScheme } from 'schemes/container'
import { FractionScheme } from 'schemes/fraction'
import { OrderScheme } from 'schemes/order'

import connector from '../../connector'

import Address from './components/Address'
import Details from './components/Details'
import Order from './components/Order'
import Parties from './components/Parties'
import Slider from './components/Slider'

/**
 * @description This component renders the order switcher short overview component
 */
export class OrderSwitcherShortOverviewComponent extends Component {
  /**
   * @function
   * @param {Object} props given component props
   */
  constructor(props) {
    super(props)

    this.state = {
      showAccordion: false,
    }
  }

  /**
   * @description Component “lifecycle method” componentDidMount
   */
  componentDidMount() {
    window.addEventListener('resize', this.getInnerWidth.bind(this))
    this.getInnerWidth()
  }

  /**
   * @description method to get window width
   */
  getInnerWidth = () => {
    this.setState({
      showAccordion: window.innerWidth <= WIDTH_BREAKEPOINT_MEDIUM,
    })
  }

  render() {
    return (
      <div className='short-overview uk-padding'>
        <div className=''>
          {this.state.showAccordion && (
            <div className='overview-header'>
              <Translate value='detailsSwitcher.shortOverview' />
            </div>
          )}
          <div
            data-uk-accordion='multiple: true'
            className='accordion-container'
          >
            {/* BEGIN ORDER */}
            <div className='accordion-list'>
              <Order
                order={this.props.order}
                showAccordion={this.state.showAccordion}
              />
            </div>
            {/* END ORDER */}

            {/* BEGIN PARTIES */}
            <div className='accordion-list'>
              <Parties
                order={this.props.order}
                showAccordion={this.state.showAccordion}
              />
            </div>
            {/* END PARTIES */}

            {/* BEGIN DETAILS */}
            <div className='accordion-list'>
              <Details
                order={this.props.order}
                fractionList={this.props.fractionList}
                containerList={this.props.containerList}
                showAccordion={this.state.showAccordion}
              />
            </div>
            {/* END DETAILS */}

            {/* BEGIN ADDRESS */}
            <div className='accordion-list'>
              <Address
                order={this.props.order}
                showAccordion={this.state.showAccordion}
              />
            </div>
            {/* END ADDRESS */}

            {/* BEGIN SLIDER */}
            <div className='accordion-list'>
              <Slider
                order={this.props.order}
                showAccordion={this.state.showAccordion}
              />
            </div>
            {/* END SLIDER */}
          </div>
        </div>
      </div>
    )
  }
}

OrderSwitcherShortOverviewComponent.propTypes = {
  order: shape(OrderScheme),
  fractionList: arrayOf(shape(FractionScheme)),
  containerList: arrayOf(shape(ContainerScheme)),
}

OrderSwitcherShortOverviewComponent.defaultProps = {
  order: null,
  fractionList: [],
  containerList: [],
}

export default connector(OrderSwitcherShortOverviewComponent)
