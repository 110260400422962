import './style.scss'

import { func } from 'prop-types'
import React, { Component } from 'react'

import { WIDTH_BREAKEPOINT_MEDIUM } from '../../order/OrderDetailsPage/constants'

/**
 * @description This component renders the details switcher.
 */
export class DetailSwitcher extends Component {
  /**
   * @constructor
   * @param {Object} props given component props
   */
  constructor(props) {
    super(props)

    this.state = { disabled: window.innerWidth <= WIDTH_BREAKEPOINT_MEDIUM }
  }

  /**
   * @description Component “lifecycle method” UNSAFE_componentWillMount
   */
  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.handleWindowResize.bind(this))
  }

  /**
   * @description method to get window width
   */
  handleWindowResize() {
    this.setState({ disabled: window.innerWidth <= WIDTH_BREAKEPOINT_MEDIUM })
  }

  render() {
    return (
      <div className='detail-switcher uk-grid'>
        <div className='uk-width-auto@m uk-visible@l'>
          <div
            className='uk-nav uk-nav-default'
            data-uk-switcher={[
              'connect: #component-nav;',
              'animation: uk-animation-fade;',
              'toggle: > :not(.uk-nav-header);',
              // active and its value controls, which label is opened by default
              `active: ${
                this.state.disabled ? '0' : this.props.getDefaultActive()
              }`,
            ].join(' ')}
          >
            {this.props.renderSwitcherNav(this.props)}
          </div>
        </div>

        <div className='uk-width-expand@m switcher-content'>
          <div id='component-nav' className='uk-switcher'>
            {this.props.renderSwitcherContent({
              ...this.props,
              disabled: this.state.disabled,
            })}
          </div>
        </div>
      </div>
    )
  }
}

DetailSwitcher.propTypes = {
  renderSwitcherNav: func,
  renderSwitcherContent: func,
  getDefaultActive: func,
}

DetailSwitcher.defaultProps = {
  renderSwitcherNav: () => undefined,
  renderSwitcherContent: () => undefined,
  getDefaultActive: () => undefined,
}
