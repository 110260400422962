const createOfferTranslations = {
  pageTitle: 'Angebot abgeben',
  breadcrumbTitle: 'Anfrage von %{inquiryCompany}',
  teaser: {
    title: 'Anfrage %{inquiryId} für %{fraction}',
    text:
      'Hier haben Sie die Möglichkeit, alle Details der Entsorgungsanfrage einzusehen. Geben Sie Ihr ' +
      'individuelles Angebot auf die offene Anfrage ab, nachdem Sie alle Informationen überprüft haben.',
  },
  section: {
    inquiry: {
      teaser:
        'Hier sehen Sie alle wichtigen Angaben zur Abfallart und den gewünschten Behältern. Sie haben den ' +
        'gewünschten Behälter nicht vorrätig? Dann passen Sie diesen und gegebenenfalls die Anzahl an Ihren ' +
        'Behälterbestand an.',
    },
    time: {
      teaser:
        'Hier sehen Sie alle wichtigen Zeitangaben der Anfrage, wie Fristen, Laufzeit oder Stelldaten.',
    },
    note: {
      title: 'Hinweise',
      teaser:
        'Hier haben Sie die Möglichkeit, dem Gewerbetreibenden explizite Hinweise und Informationen zu Ihrem ' +
        'Angebot mitzuteilen.',
      placeholder: 'Erlaubt sind Buchstaben, Zahlen, Sonderzeichen',
    },
    phoneUser: {
      title: 'Benutzer auswählen',
      teaser: '',
    },
  },
  cta: {
    price: {
      title: 'Preismodell',
      text:
        'Bitte wählen Sie das von Ihnen favorisierte Preismodell und tragen Ihre Preise in die dafür ' +
        'vorgesehenen Felder ein. empto wird Ihre Preise unverändert anbieten.',
    },
    activeOffer: {
      title: 'Es liegt bereits ein Angebot vor',
      textWithDate:
        'Am %{date} wurde von Ihnen bereits ein Angebot zu dieser Anfrage veröffentlicht.',
      text: 'Es wurde von Ihnen bereits ein Angebot zu dieser Anfrage veröffentlicht.',
    },
    showCreateOffer: {
      title: 'Sie sind an dieser Anfrage interessiert?',
      text:
        'Dann erstellen Sie ganz einfach ein Angebot für diese Anfrage. Mit einem Klick auf "Angebot erstellen" ' +
        'werden Ihnen die Felder zur Eingabe Ihrer Preise angezeigt.',
    },
    buttons: {
      submit: 'Angebot veröffentlichen',
      submitDisabled: 'Bitte vervollständigen Sie Ihre Stammdaten.',
      offer: 'Angebot öffnen',
      showCreateOffer: 'Angebot erstellen',
      informWasteDisposer: 'Entsorger informieren',
    },
  },
  errors: {
    headline: 'Bitte überprüfen Sie folgende Daten:',
  },
  label: {
    avv: 'AVV Nummer',
    name: 'Name',
    createdAt: 'Mitglied seit',
    positiveReviews: 'Positive Bewertungen',
    inquiriesCount: 'Anfragen gesamt',
    numberAndStatus: 'Anfragenummer, Status',
    createdBy: 'Name des Erstellers',
    fraction: 'Abfallart',
    fineFraction: 'Feinfraktion',
    noFineFraction: 'Keine Feinfraktion',
    container: 'Behälter',
    numberOfContainers: 'Anzahl',
    quantityInCubicMeters: 'Menge in m\u00B3',
    withTop: 'Deckelausführung gewünscht',
    positionOnPublicGround: 'Stellung auf öffentlichem Grund',
    withETA: 'Mit ETA-Service (Estimated Time of Arrival)',
    avvNumber: 'AVV Nummer',
    avvNumberDefault: 'ausstehend',
    specialNote: 'Besondere Hinweise (Anfahrt, Stellplatz, etc)',
    attachments: 'Bilder',
    company: 'Unternehmen',
    phoneUser: 'Benutzer',
    orderType: 'Auftragsart',
    collectionAddress: 'Leistungsadresse',
    address: 'Leistungsadresse',
    deliveryDate: 'Lieferdatum',
    collectionDate: 'Abholdatum',
    timeOfDayDelivery: 'Zeitraum',
    timeOfDayCollection: 'Zeitraum',
    turnBegin: 'Turnusbeginn',
    turnEnd: 'Turnusende',
    agreementBegin: 'Vereinbarungsbeginn',
    agreementEnd: 'Vereinbarungsende',
    interval: 'Intervall',
    intervalWeekdayFirst: 'Wochentag',
    intervalWeekdaySecond: 'Zweiter Wochentag',
    runtimeOfInquiry: 'Laufzeit der Anfrage',
    serviceInterval: 'Intervall',
    reactionTime: 'Reaktionszeit/Leistungsfrist',
    privateGround: 'Privatgelände',
    withEstimatedTimeOfArrival: 'Mit ETA-Service (Estimated Time of Arrival)',
    rentPriceContainerMonth: 'Mietpreis (€ pro Behälter und Monat)',
    disposalCostContainer: 'Transport- und Entsorgungspreis (€ pro Behälter)',
    transportPricePiece: 'Transportpreis (€ pro Stück)',
    retailPrice: 'Verkaufspreis (€ pro Bestellung)',
    retailPriceContainer: 'Einzelpreis (€ pro Behälter)',
    disposalCost: 'Entsorgungskosten (€ pro Tonne)',
    recyclePrice: 'Verwertungspreis (€ pro Tonne)',
    disposalCostTon: 'Entsorgungskosten (€ pro Tonne)',
    handleCostTon: 'Handlingskosten (€ pro Tonne)',
    price_placeholder: 'Wert zwischen 0 € und 999,99 €',
    index: 'Index',
    compensation: 'Vergütung (€ pro Tonne)',
    surcharge: 'Zuschlag (€ pro Tonne)',
    discount: 'Abschlag (€ pro Tonne)',
    additionalInformation: 'Information zum Auftrag',
  },
  requiredError: 'Bitte geben Sie einen gültigen Wert für %{field} an.',
}

export default createOfferTranslations
