import moment from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import Icon, { ICON_NAME } from 'components/common/Fontello'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import {
  Tooltip,
  TOOLTIP_POSITION,
  TOOLTIP_SIZE,
} from 'components/common/Tooltip'
import { INQUIRY_ORDER_TYPE } from 'components/inquiry/constants'
import { OFFER_STATUS } from 'components/offer/constants'
import { DATE_FORMATS } from 'constants/app'
import { UserPermission } from 'constants/user'

interface CreateNewOrderButtonProps {
  fullWidth?: boolean
  offer: Offer
  onClick?: (any) => void
  user: User
  dataTestId?: string
}

export const CreateNewOrderButton: FC<CreateNewOrderButtonProps> = ({
  fullWidth,
  offer,
  onClick = () => undefined,
  user,
  dataTestId,
}) => {
  const isOnDemand =
    INQUIRY_ORDER_TYPE.TYPE_ON_DEMAND === Number(offer.order_type)
  const isConvertedInquiry = offer.type_change_at && offer.type_change_by

  let canCreateOrder =
    Number(offer.status) === OFFER_STATUS.STATUS_ACCEPTED &&
    moment().isSameOrAfter(moment(offer.turn_begin, DATE_FORMATS), 'days') &&
    moment().isSameOrBefore(
      moment(offer.turn_end, DATE_FORMATS).add(12, 'M'),
      'days',
    )

  if (isConvertedInquiry) {
    canCreateOrder =
      moment().isSameOrAfter(moment(offer.turn_begin, DATE_FORMATS), 'days') &&
      moment().isSameOrBefore(
        moment(offer.latest_order_date, DATE_FORMATS),
        'days',
      )
  }

  const isWasteProducer =
    offer.customer_company_object &&
    user.company === offer.customer_company_object.id
  const isMaklerpremium =
    offer.customer_company_object.makler_premium_company === user.company

  const button = (
    <Button
      backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
      fullWidth={fullWidth}
      isDisabled={
        isOnDemand && !canCreateOrder && (isWasteProducer || isMaklerpremium)
      }
      onClick={
        isOnDemand && canCreateOrder && (isWasteProducer || isMaklerpremium)
          ? onClick
          : () => undefined
      }
      dataTestId={dataTestId}
    >
      {I18n.t('offerDetails.agreementOffer.newOrder')}
      <Icon name={ICON_NAME.PLUS} />
    </Button>
  )

  const tooltipButton = (
    <div className='create-new-order-button'>
      <Tooltip
        distance={30}
        html={
          <p>
            {I18n.t(
              'offerDetails.agreementOffer.newOrderOutsideAgreementDatesError',
            )}
          </p>
        }
        position={TOOLTIP_POSITION.BOTTOM}
        size={TOOLTIP_SIZE.BIG}
        tooltipId='createNewOrder'
      >
        {button}
      </Tooltip>
    </div>
  )

  return (
    <RequiredPermissions requiredPermissions={[UserPermission.ADD_ORDER]}>
      <>
        {isOnDemand &&
          canCreateOrder &&
          (isWasteProducer || isMaklerpremium) &&
          button}
        {isOnDemand &&
          !canCreateOrder &&
          (isWasteProducer || isMaklerpremium) &&
          tooltipButton}
      </>
    </RequiredPermissions>
  )
}
