import './style.scss'

import { func, number, oneOfType, string } from 'prop-types'
import React from 'react'

import Icon from '../Fontello'

function ModalHeader({ titleId, onClose, title, dataTestId }) {
  return (
    <div className='uk-modal-header modal-header' data-testid={dataTestId}>
      <h2 className='uk-modal-title modal-title' id={titleId}>
        {title}
      </h2>

      <button
        className='uk-modal-close-default uk-close uk-icon uk-text-large modal-close'
        onClick={onClose}
        type='button'
      >
        <Icon name='cross' />
      </button>
    </div>
  )
}

ModalHeader.propTypes = {
  onClose: func.isRequired,
  title: string.isRequired,
  // The titleId allows to use `aria-describedby` to reference the headline in modals
  titleId: oneOfType([string, number]).isRequired,
  dataTestId: string,
}

ModalHeader.defaultProps = {
  dataTestId: '',
}

export default ModalHeader
