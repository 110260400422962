import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'
import Spinner from 'react-spinkit'
import { compose } from 'recompose'
import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types'

import { getRouterHistoryAsArray } from 'helper/router'
import withErrorBoundary from 'helper/withErrorBoundary'
import { ContainerScheme } from 'schemes/container'
import { FractionScheme } from 'schemes/fraction'
import { LocationScheme } from 'schemes/location'
import { OfferScheme } from 'schemes/offer'
import { OrderListScheme } from 'schemes/order'
import { PaginationScheme } from 'schemes/pagination'

import { BackButton } from '../../common/BackButton'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { CardList, OrderCard } from '../../common/NewCards'
import PaginatedContent from '../../common/PaginatedContent'
import { getFractionsFromItem } from '../../inquiry/helpers'
import { PageHeader } from '../../layout/PageHeader'

import connector from './connector'

/**
 * @description This component displays a page that shows all orders that were created for the agreement
 */
export class PreviousOrdersPageComponent extends Component {
  static propTypes = {
    location: shape(LocationScheme),
    match: shape({
      params: shape({
        offerId: oneOfType([number, string]),
      }).isRequired,
    }).isRequired,
    actions: shape({
      order: shape({
        getOrders: func.isRequired,
      }).isRequired,
      offer: shape({
        getOffer: func.isRequired,
      }).isRequired,
      fraction: shape({
        getFractions: func.isRequired,
      }).isRequired,
    }).isRequired,
    offer: shape(OfferScheme),
    orderList: arrayOf(shape(OrderListScheme)),
    ordersPagination: shape(PaginationScheme),
    fractionList: arrayOf(shape(FractionScheme)),
    containerList: arrayOf(shape(ContainerScheme)).isRequired,
    isLoading: bool,
  }

  static defaultProps = {
    location: null,
    offer: null,
    ordersPagination: {},
    orderList: [],
    fractionList: [],
    isLoading: false,
  }

  state = {
    offerId: this.props.match.params.offerId
      ? Number(this.props.match.params.offerId)
      : null,
  }

  /**
   * @description Component “lifecycle method” componentDidMount
   */
  componentDidMount() {
    if (this.props.fractionList.length === 0)
      this.props.actions.fraction.getFractions()

    if (this.state.offerId) {
      this.props.actions.offer.getOffer(this.state.offerId)
      this.props.actions.order.getOrders(null, { offer: this.state.offerId })
    }
  }

  render() {
    const {
      actions,
      containerList,
      fractionList,
      isLoading,
      location,
      offer,
      orderList,
      ordersPagination,
    } = this.props

    if (!offer.id) return null

    return (
      <>
        <Helmet>
          <title>
            {I18n.t('pageTitles.pastAgreements', {
              agreementId: offer.id,
            })}
          </title>
        </Helmet>

        <div className='previous-orders-page'>
          {location.state && (
            <ul className='uk-breadcrumb'>
              {/* eslint-disable react/no-array-index-key */}
              {getRouterHistoryAsArray(location.state.from).map(
                (path, index) => (
                  <li key={index}>
                    <Link to={path.pathname} key={path.pathname}>
                      {path.breadcrumb}
                    </Link>
                  </li>
                ),
              )}
              <li>
                <Translate
                  value='offerDetails.agreementOffer.previousOrdersFor'
                  number={offer.id}
                />
              </li>
            </ul>
          )}

          <PageHeader
            title={I18n.t('offerDetails.agreementOffer.previousOrdersFor', {
              number: offer.id,
            })}
          >
            {location.state && (
              <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                <BackButton />
              </ButtonBar>
            )}
          </PageHeader>

          <PaginatedContent
            page={ordersPagination.current}
            pages={ordersPagination.count}
            onPreviousPageClick={() =>
              actions.order.getOrders(ordersPagination.previous, {
                offer: this.state.offerId,
              })
            }
            onNextPageClick={() =>
              actions.order.getOrders(ordersPagination.next, {
                offer: this.state.offerId,
              })
            }
          >
            {/* Loading Indicator */}
            {isLoading ? (
              <div className='uk-flex uk-flex-center uk-margin-large-top'>
                <Spinner name='circle' />
              </div>
            ) : (
              <CardList
                component={OrderCard}
                items={orderList}
                className='uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l'
                addFunc={item => getFractionsFromItem(item, fractionList)}
                addProps={{ containerList }}
              />
            )}
          </PaginatedContent>
        </div>
      </>
    )
  }
}

export default compose(
  withErrorBoundary,
  connector,
)(PreviousOrdersPageComponent)
