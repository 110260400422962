import './style.scss'

import moment from 'moment/moment'
import React, { FC, useEffect } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getSecurityGroups } from 'actions/securitygroup'
import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { PriceList } from 'components/common/DetailsPage/sections/PriceSection/PriceList'
import Icon, { ICON_NAME } from 'components/common/Fontello'
import { ICON_POSITION, IconButton } from 'components/common/IconButton'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { SectionHeader } from 'components/common/SectionHeader'
import { getFractionName } from 'components/executionproof/helpers'
import { INQUIRY_ORDER_TYPE } from 'components/inquiry/constants'
import { getContainerName } from 'components/inquiry/helpers'
import { DATE_FORMATS, PARTNER_PRICE_MODEL_CHOICES } from 'constants/app'
import { localizeDate } from 'helper/general'
import { getName } from 'helper/translations'
import { getEPDContainersSelector } from 'selectors/container'
import { getEPDFractionsSelector } from 'selectors/fraction'
import { getIndexesSelector } from 'selectors/indexes'
import { getSecurityGroupsSelector } from 'selectors/securitygroup'

import {
  INVOICE_CHECK_POSITIONS_CATEGORIES,
  INVOICE_CHECK_POSITIONS_CATEGORIES_CHOICES,
  INVOICE_CHECK_STATUS,
} from '../../constants'
import { OrderType } from '../InvoiceCheckPositionsForm/Step2'
import { NetPriceType } from '../InvoiceCheckPositionsForm/Step2/OrderAndServicesForm'

interface InvoiceCheckOverviewInvoiceDataProps {
  invoiceCheck: InvoiceCheck
  handleOpenCloseInvoiceCheckPositionsFormModal: () => void
  setInvoiceCheckPositionData: (item?: InvoiceCheckPosition) => void
  handleOpenCloseDeletePositionModal: (item?: InvoiceCheckPosition) => void
}

/**
 * @description This component displays a list of invoices including the filter, loading indicator etc.
 * @function
 */
export const InvoiceCheckOverviewInvoicePositions: FC<
  InvoiceCheckOverviewInvoiceDataProps
> = ({
  invoiceCheck,
  handleOpenCloseInvoiceCheckPositionsFormModal,
  handleOpenCloseDeletePositionModal,
  setInvoiceCheckPositionData,
}) => {
  const dispatch = useDispatch()
  const fractionList = useSelector(getEPDFractionsSelector)
  const containerList = useSelector(getEPDContainersSelector)
  const securityGroupList = useSelector(getSecurityGroupsSelector)
  const indexList = useSelector(getIndexesSelector)

  useEffect(() => {
    if (securityGroupList.length < 1) dispatch(getSecurityGroups())
  }, [securityGroupList.length, dispatch])

  const isRent = (invoiceCheckPosition: InvoiceCheckPosition) =>
    invoiceCheckPosition.order_type === OrderType.RENT
  const isOtherService = (invoiceCheckPosition: InvoiceCheckPosition) =>
    invoiceCheckPosition.order_type === OrderType.OTHER_SERVICE
  const isOtherServiceOrRent = (invoiceCheckPosition: InvoiceCheckPosition) =>
    isOtherService(invoiceCheckPosition) || isRent(invoiceCheckPosition)
  const isOpenOrder = (invoiceCheckPosition: InvoiceCheckPosition) =>
    invoiceCheckPosition.order_type === OrderType.OPEN_ORDER

  const getOrderType = (invoiceCheckPosition: InvoiceCheckPosition) => {
    if (isOtherService(invoiceCheckPosition)) {
      return I18n.t(
        'invoiceCheckTranslations.positionsForm.steps.2.fields.order_type.other_service.label',
      )
    }
    if (isOpenOrder(invoiceCheckPosition)) {
      return I18n.t(
        'invoiceCheckTranslations.positionsForm.steps.2.fields.order_type.open_order.label',
      )
    }
    if (isRent(invoiceCheckPosition)) {
      return I18n.t(
        'invoiceCheckTranslations.positionsForm.steps.2.fields.order_type.rent.label',
      )
    }

    return ''
  }

  const getNetPrice = (position: InvoiceCheckPosition) => {
    return Number(position.net_price).toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR',
    })
  }

  const getPriceDisplayName = (priceModel: string) => {
    const label = PARTNER_PRICE_MODEL_CHOICES.find(
      element => element.optionValue === priceModel,
    )?.optionLabel
    if (label) {
      return I18n.t(label)
    }
    return ''
  }

  const getSecurityGroup = (position: InvoiceCheckPosition) => {
    if (isOpenOrder(position)) {
      return securityGroupList
        .filter(item => item.id === Number(position.security_group))
        .map(
          item =>
            `${I18n.t('selectSecurityGroupTranslations.protectionClassNumber', {
              protectionClass: item.protection_class,
            })} / ${I18n.t(
              'selectSecurityGroupTranslations.securityLevelNumber',
              { securityLevel: item.security_level },
            )}`,
        )
        .shift()
    }

    return ''
  }

  return (
    <section className='uk-card uk-padding-remove'>
      <SectionHeader className='uk-float-left'>
        <Icon name='to-do' />
        <Translate value='invoiceCheckOverviewTranslations.section_header.invoice_positions' />
      </SectionHeader>
      {[
        INVOICE_CHECK_STATUS.INVOICE_CHECK_PENDING,
        INVOICE_CHECK_STATUS.INVOICE_CHECK_STATUS_REOPENED,
      ].includes(invoiceCheck.status) && (
        <div className='uk-float-right uk-padding-small uk-padding-remove-horizontal uk-padding-remove-bottom'>
          <IconButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            iconPosition={ICON_POSITION.RIGHT}
            iconName='plus'
            onClick={() => {
              setInvoiceCheckPositionData(undefined)
              handleOpenCloseInvoiceCheckPositionsFormModal()
            }}
          >
            <Translate value='invoiceCheckOverviewTranslations.section.invoice_positions.add_button' />
          </IconButton>
        </div>
      )}

      {/* Positionen */}
      <div className='uk-clearfix' />
      {invoiceCheck.positions?.map((position, idx) => (
        <div
          className='uk-padding uk-grid-margin-small details-container'
          key={idx}
        >
          <div className='uk-grid'>
            {/* Leistungsadresse */}
            {(isOpenOrder(position) || isOtherService(position)) && (
              <div className='uk-width-1-2'>
                <ReadOnlyField
                  className='uk-margin-medium-bottom'
                  label={I18n.t(
                    'invoiceCheckOverviewTranslations.section.invoice_positions.service_address',
                  )}
                  value={
                    position.order_object
                      ? position.order_object.collection_address_object
                          .display_name
                      : ''
                  }
                />
              </div>
            )}
            {isRent(position) && (
              <div className='uk-width-1-2'>
                <ReadOnlyField
                  className='uk-margin-medium-bottom'
                  label={I18n.t(
                    'invoiceCheckOverviewTranslations.section.invoice_positions.service_address',
                  )}
                  value={
                    position.offer_object
                      ? position.offer_object.collection_address_object
                          .display_name
                      : ''
                  }
                />
              </div>
            )}

            {/* Bearbeiten */}
            <div className='uk-width-1-2'>
              {[
                INVOICE_CHECK_STATUS.INVOICE_CHECK_PENDING,
                INVOICE_CHECK_STATUS.INVOICE_CHECK_STATUS_REOPENED,
              ].includes(invoiceCheck.status) && (
                <div className='invoicecheck-overview-positions-button-row'>
                  <ButtonLink
                    onClick={() => {
                      setInvoiceCheckPositionData(position)
                      handleOpenCloseInvoiceCheckPositionsFormModal()
                    }}
                    linkColor={BUTTON_LINK_COLOR.PRIMARY}
                  >
                    <Translate value='general.button.edit' />
                  </ButtonLink>
                  <ButtonLink
                    className='invoicecheck-overview-positions-delete-button'
                    onClick={() => {
                      setInvoiceCheckPositionData(position)
                      handleOpenCloseDeletePositionModal()
                    }}
                    linkColor={BUTTON_LINK_COLOR.PRIMARY}
                  >
                    <Icon name={ICON_NAME.TRASH} />
                  </ButtonLink>
                </div>
              )}
            </div>

            {/* Auftragsnummer */}
            {(isOpenOrder(position) || isOtherService(position)) && (
              <div className='uk-width-1-1 uk-width-1-3@l'>
                <ReadOnlyField
                  className='uk-margin-medium-bottom'
                  label={I18n.t(
                    'invoiceCheckOverviewTranslations.section.invoice_positions.order_numbers',
                  )}
                  value={position.order_object ? position.order_object.id : ''}
                />
              </div>
            )}

            {/* Vereinbarungsnummer */}
            {isRent(position) && (
              <div className='uk-width-1-1 uk-width-1-3@l'>
                <ReadOnlyField
                  className='uk-margin-medium-bottom'
                  label={I18n.t(
                    'invoiceCheckOverviewTranslations.section.invoice_positions.offer_number',
                  )}
                  value={position.offer_object ? position.offer_object.id : ''}
                />
              </div>
            )}

            {/* Auftragsdatum */}
            {(isOpenOrder(position) || isOtherService(position)) && (
              <div className='uk-width-1-1 uk-width-1-3@l'>
                <ReadOnlyField
                  className='uk-margin-medium-bottom'
                  label={I18n.t(
                    'invoiceCheckOverviewTranslations.section.invoice_positions.order_date',
                  )}
                  value={
                    position.order_object
                      ? localizeDate(position.order_object.created_at)
                      : ''
                  }
                />
              </div>
            )}

            {/* Leistungsmonat */}
            {isRent(position) && (
              <div className='uk-width-1-1 uk-width-1-3@l'>
                <ReadOnlyField
                  className='uk-margin-medium-bottom'
                  label={I18n.t(
                    'invoiceCheckOverviewTranslations.section.invoice_positions.service_month',
                  )}
                  value={
                    position.delivery_date
                      ? moment(position.delivery_date, DATE_FORMATS).format(
                          'MMMM YYYY',
                        )
                      : ''
                  }
                />
              </div>
            )}

            {/* Leistungsdatum */}
            {(isOtherService(position) || isOpenOrder(position)) && (
              <div className='uk-width-1-1 uk-width-1-3@l'>
                <ReadOnlyField
                  className='uk-margin-medium-bottom'
                  label={I18n.t(
                    'invoiceCheckOverviewTranslations.section.invoice_positions.service_date',
                  )}
                  value={
                    position.order_object.order_type ===
                      INQUIRY_ORDER_TYPE.TYPE_ONE_TIME && isOpenOrder(position)
                      ? localizeDate(position.collection_date)
                      : localizeDate(position.delivery_date)
                  }
                />
              </div>
            )}

            {/* Abfallart, Behälter, Anzahl Behälter - Felder für Offener Auftrag*/}
            {isOpenOrder(position) && (
              <>
                {/* Abfallart */}
                <div className='uk-width-1-1 uk-width-1-3@l'>
                  <ReadOnlyField
                    className='uk-margin-medium-bottom'
                    label={I18n.t(
                      'invoiceCheckOverviewTranslations.section.invoice_positions.fraction',
                    )}
                    value={getFractionName(position.fraction, fractionList)}
                  />
                </div>

                {/* Behälter */}
                <div className='uk-width-1-1 uk-width-1-3@l'>
                  <ReadOnlyField
                    className='uk-margin-medium-bottom'
                    label={I18n.t(
                      'invoiceCheckOverviewTranslations.section.invoice_positions.container',
                    )}
                    value={getContainerName(position.container, containerList)}
                  />
                </div>

                {/* Anzahl der Behälter */}
                <div className='uk-width-1-1 uk-width-1-3@l'>
                  <ReadOnlyField
                    className='uk-margin-medium-bottom'
                    label={I18n.t(
                      'invoiceCheckOverviewTranslations.section.invoice_positions.number_of_containers',
                    )}
                    value={`${position.number_of_containers} Stück`}
                  />
                </div>

                {/* Schutzklasse und Sicherheitsstufe */}
                {position.security_group && (
                  <div className='uk-width-1-1 uk-width-1-3@l'>
                    <ReadOnlyField
                      className='uk-margin-medium-bottom'
                      label={I18n.t(
                        'invoiceCheckOverviewTranslations.section.invoice_positions.security_group',
                      )}
                      value={getSecurityGroup(position)}
                    />
                  </div>
                )}
                {/* Preismodell */}
                {position.price_model_changed && (
                  <>
                    <div className='uk-width-1-1 uk-width-1-3@l'>
                      <ReadOnlyField
                        className='uk-margin-medium-bottom'
                        label={I18n.t(
                          'invoiceCheckOverviewTranslations.section.invoice_positions.price_model_changed',
                        )}
                        value={getPriceDisplayName(
                          position.price_model_effective,
                        )}
                      />
                    </div>
                    <PriceList
                      className='uk-width-1-1 uk-width-1-3@l'
                      indexList={indexList}
                      pricesObject={position}
                      isMaklerpremium
                    ></PriceList>
                  </>
                )}
              </>
            )}

            {/* Auftragsart*/}
            <div
              className={
                isOpenOrder(position)
                  ? 'uk-width-1-1'
                  : 'uk-width-1-1 uk-width-1-3@l'
              }
            >
              <ReadOnlyField
                className='uk-margin-medium-bottom'
                label={I18n.t(
                  'invoiceCheckTranslations.positionsForm.steps.2.fields.order_type.label',
                )}
                value={getOrderType(position)}
              />
            </div>

            {/* Kategorie, ggf Beschreibung - Felder für Sonstige Dienstleistung*/}
            {isOtherService(position) && (
              <>
                {/* Kategorie*/}
                <div className='uk-width-1-1 uk-width-1-2@l'>
                  <ReadOnlyField
                    className='uk-margin-medium-bottom'
                    label={I18n.t(
                      'invoiceCheckOverviewTranslations.section.invoice_positions.category',
                    )}
                    value={I18n.t(
                      getName(
                        position.category,
                        INVOICE_CHECK_POSITIONS_CATEGORIES_CHOICES,
                      ),
                    )}
                  />
                </div>

                {/* Beschreibung*/}
                {position.category ===
                  INVOICE_CHECK_POSITIONS_CATEGORIES.OTHER && (
                  <div className='uk-width-1-1'>
                    <ReadOnlyField
                      className='uk-margin-medium-bottom'
                      label={I18n.t(
                        'invoiceCheckOverviewTranslations.section.invoice_positions.categoryText',
                      )}
                      value={position.other_service_other_text}
                    />
                  </div>
                )}
              </>
            )}

            {/* Nettobetrag in € */}
            <div
              className={
                isOpenOrder(position)
                  ? 'uk-width-1-1'
                  : 'uk-width-1-1 uk-width-1-3@l'
              }
            >
              <ReadOnlyField
                className='uk-margin-medium-bottom'
                label={I18n.t(
                  'invoiceCheckOverviewTranslations.section.invoice_positions.net_price',
                )}
                value={getNetPrice(position)}
              />
            </div>

            {/* Art der Verrechnung*/}
            {isOtherServiceOrRent(position) && (
              <>
                <div className='uk-width-1-1 uk-width-1-3@l'>
                  <ReadOnlyField
                    className='uk-margin-medium-bottom'
                    label={I18n.t(
                      'invoiceCheckOverviewTranslations.section.invoice_positions.burdenCreditLabel',
                    )}
                    value={
                      position.net_price_type === NetPriceType.BURDEN
                        ? I18n.t(
                            'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.netPrice.burden',
                          )
                        : I18n.t(
                            'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.netPrice.credit',
                          )
                    }
                  />
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </section>
  )
}
