import './style.scss'

import React, { FC, useState } from 'react'
import { Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'

import mapMarkerBlack from 'assets/svg/map/mapMarkerBlack.svg'
import mapMarkerBlue from 'assets/svg/map/mapMarkerBlue.svg'
import { GoogleMaps } from 'components/common/GoogleMaps'
import { GoogleMapsMarkerType } from 'components/inquiry/OpenInquiriesPage/constants'

export const AddressesMap: FC<{
  addressList: AddressSmall[]
  company: Company
  addressCount: number
}> = ({ addressList, company, addressCount }) => {
  const [mapMarkerBlueIcon, setMapMarkerBlueIcon] = useState(mapMarkerBlue)
  const [mapMarkerBlackIcon, setMapMarkerBlackIcon] = useState(mapMarkerBlack)

  /**
   * @description changes the marker color when changing between map layers
   * @param currentMapTypeId
   */
  const handleChangeMapTypeId = currentMapTypeId => {
    if (currentMapTypeId === 'satellite' || currentMapTypeId === 'hybrid') {
      setMapMarkerBlueIcon(mapMarkerBlue)
      setMapMarkerBlackIcon(mapMarkerBlack)
    }
  }

  if (!addressList || !addressList.length) return null

  const companyMarkers = addressList
    .filter(
      address =>
        !!address.latitude &&
        !!address.longitude &&
        company.main_address_object &&
        address.id === company.main_address_object.id,
    )
    .map(address => ({
      id: address.id,
      latitude: address.latitude,
      longitude: address.longitude,
      type: GoogleMapsMarkerType.COMPANY,
      icon: mapMarkerBlueIcon,
      clickable: false,
    }))

  const deliveryMarkers = addressList
    .filter(
      address =>
        !!address.latitude &&
        !!address.longitude &&
        company.main_address_object &&
        address.id !== company.main_address_object.id,
    )
    .map(address => ({
      id: address.id,
      latitude: address.latitude,
      longitude: address.longitude,
      type: GoogleMapsMarkerType.COMPANY,
      icon: mapMarkerBlackIcon,
      clickable: false,
    }))

  const combinedMarkers = [...companyMarkers, ...deliveryMarkers]

  return (
    <div className='addresses-map uk-width-1-1@l uk-width-2-3@m'>
      <GoogleMaps
        company={company}
        mapZoom={12}
        markers={combinedMarkers}
        showMissingMarkersMessage={
          combinedMarkers.length !== addressList.length
        }
        onChangeMapType={handleChangeMapTypeId}
      />
      <Link to={company ? `/address/${company.id}` : ''}>
        <Translate value='allAddresses.headline' />: {addressCount}
      </Link>
    </div>
  )
}
