import moment from 'moment'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { ReactComponent as Document } from 'assets/svg/detailspage/document.svg'
import AccordionItem from 'components/common/Accordion/components/AccordionItem'
import { ReadOnlyField } from 'components/common/ReadOnly'

interface ActivitiesSectionProps {
  addScrollElement: string
  offer: Offer
}

export const ActivitiesSection: FC<ActivitiesSectionProps> = ({
  addScrollElement,
  offer,
}) => {
  const iconTitle = (
    <>
      <Document className='inquiry-details-page__section-header' />
      {I18n.t('agreementDetailsTranslations.section.activities.title')}
    </>
  )

  const getActivityValue = (activity: Activity) => {
    const displayUserName = (user: User) =>
      `${user.first_name} ${user.last_name}`
    const displayDate = (dateString: string) => moment(dateString).format('L')
    const displayString = `${displayDate(activity.created_at)} ${I18n.t(
      'activitiesSectionTranslations.through',
    )} ${displayUserName(activity.created_by)}`
    // Only set companyDisplayString if the activity got executed by a waste producer
    const companyDisplayString = activity.created_by.company_object
      ?.makler_premium_company
      ? `(${activity.created_by.company_object?.name})`
      : ''

    switch (activity.action) {
      case 'Rythmusänderung':
        return `${I18n.t(
          'activitiesSectionTranslations.rhythmChanged',
        )} ${displayString}`
      case 'Zeitraumänderung':
        return `${I18n.t(
          'activitiesSectionTranslations.agreementPeriodChanged',
        )} ${displayString}`
      case 'Wochentagsänderung':
        return `${I18n.t(
          'activitiesSectionTranslations.weekdayChanged',
        )} ${displayString}`
      case 'Entsorgerwechsel':
        return `${I18n.t(
          'activitiesSectionTranslations.disposerChanged',
        )} ${displayString} ${companyDisplayString}`
      case 'Auftragserstellung':
        return `${I18n.t(
          'activitiesSectionTranslations.orderCreated',
        )} ${displayString}`
      case 'Umwandlung angefragt':
        return `${I18n.t(
          'activitiesSectionTranslations.conversionRequested',
        )} ${displayString}`
      case 'Umwandlung':
        return `${I18n.t(
          'activitiesSectionTranslations.conversionExecuted',
        )} ${displayString}`
    }
  }

  return (
    <AccordionItem addScrollElement={addScrollElement} title={iconTitle}>
      <div className='inquiry-details-page__section'>
        {offer.activities.map((activity, index) => (
          <ReadOnlyField
            label={activity.action}
            value={getActivityValue(activity)}
            key={`activity${index}`}
          />
        ))}
      </div>
    </AccordionItem>
  )
}
