import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { ReactComponent as Pin } from 'assets/svg/detailspage/pin.svg'
import AccordionItem from 'components/common/Accordion/components/AccordionItem'
import { ReadOnlyField } from 'components/common/ReadOnly'

import { OldAndNewValuesSectionProps } from '../scheme'

export const AddressSection: FC<OldAndNewValuesSectionProps> = props => {
  const { addScrollElement, offer } = props

  const iconTitle = (
    <>
      <Pin className='inquiry-details-page__section-header' />
      {I18n.t('inquiryDetailsTranslations.section.address.title')}
    </>
  )

  return (
    <AccordionItem addScrollElement={addScrollElement} title={iconTitle}>
      <div
        className='inquiry-details-page__section uk-child-width-1-2@m'
        data-uk-grid=''
      >
        {/* COLLECTION ADDRESS */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.address.fields.address',
          )}
          value={offer.collection_address_object.display_name}
        />
        {/* COLLECTION ADDRESS DESCRIPTION */}
        {offer.collection_address_object.description && (
          <ReadOnlyField
            label={I18n.t(
              'inquiryDetailsTranslations.section.address.fields.description',
            )}
            value={offer.collection_address_object.description}
          />
        )}
      </div>
    </AccordionItem>
  )
}
