import { BookkeepingList } from '../components/maklerpremium/BookkeepingList'
import { AddInvoicePage } from '../components/maklerpremium/BookkeepingList/AddInvoicePage'
import { CancelledPaidDocumentReviewPageComponent } from '../components/maklerpremium/CancelPaidDocumentReview/CancelledPaidDocumentReviewPage'
import { CollectiveInvoicingPage } from '../components/maklerpremium/CollectiveInvoicing'
import { CommunicationCenterPage } from '../components/maklerpremium/CommunicationCenterPage'
import { CreateMaklerPremiumOfferPage } from '../components/maklerpremium/CreateMaklerPremiumOfferPage'
import { DisposerProducerPage } from '../components/maklerpremium/DisposerProducerPage'
import { InvoiceCheckOverview } from '../components/maklerpremium/InvoiceCheckOverview'
import { InvoiceCheckPage } from '../components/maklerpremium/InvoiceCheckPage'
import { InvoiceCheckPositionReviewPageComponent } from '../components/maklerpremium/InvoiceCheckPositionReviewPage'
import { OfferPipelinePage } from '../components/maklerpremium/OfferPipelinePage'
import { UpcomingOrdersList } from '../components/maklerpremium/UpcomingOrders'
import { WorkloadPage } from '../components/maklerpremium/WorkloadPage'
import { UserPermission } from '../constants/user'
import { OfferReviewPageComponent } from '../components/maklerpremium/OfferReviewPage'

/**
 * @constant
 * @description A constant to define maklerPremium routes.
 */
export const maklerpremiumRoutes = [
  {
    path: ['/maklerpremium/customer', '/maklerpremium/partner'],
    component: DisposerProducerPage,
    exact: true,
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/offer/create',
    component: CreateMaklerPremiumOfferPage,
    exact: true,
  },
  {
    path: '/maklerpremium/workload',
    component: WorkloadPage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_MAKLER_PREMIUM_WORKLOAD],
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/workload/cancel_paid_document/:cancelPaidDocumentID',
    component: CancelledPaidDocumentReviewPageComponent,
    requiredPermissions: [UserPermission.VIEW_CANCELLEDPAIDDOCUMENT],
    exact: true,
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/workload/invoice_check_position/:invoiceCheckPositionId',
    component: InvoiceCheckPositionReviewPageComponent,
    requiredPermissions: [UserPermission.VIEW_INVOICECHECKPOSITION],
    exact: true,
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/workload/offer/:offerId',
    component: OfferReviewPageComponent,
    requiredPermissions: [UserPermission.VIEW_ACCEPTED_OFFER],
    exact: true,
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/offer/pipeline',
    component: OfferPipelinePage,
    exact: true,
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/invoice_check',
    component: InvoiceCheckPage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_INVOICECHECK],
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/invoice_check/overview/:invoiceCheckId/',
    component: InvoiceCheckOverview,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_INVOICECHECK],
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/bookkeeping',
    component: BookkeepingList,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_MAKLER_PREMIUM_INVOICE],
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/bookkeeping/add_invoice',
    component: AddInvoicePage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_MAKLER_PREMIUM_INVOICE],
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/upcoming_orders',
    component: UpcomingOrdersList,
    exact: true,
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/communicationcenter/:companyId/',
    component: CommunicationCenterPage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_COMMUNICATIONCENTER],
    showErrorPage: true,
  },
  {
    path: '/maklerpremium/collective-invoicing',
    component: CollectiveInvoicingPage,
    exact: true,
    requiredPermissions: [UserPermission.VIEW_COLLECTIVE_INVOICING],
    showErrorPage: true,
  },
]
