import keyMirror from 'keymirror'

const GET_ADDRESSES = keyMirror({
  GET_ADDRESSES_REQUEST: null,
  GET_ADDRESSES_SUCCESS: null,
  GET_ADDRESSES_ERROR: null,
})

const GET_ACTIVE_ADDRESSES = keyMirror({
  GET_ACTIVE_ADDRESSES_REQUEST: null,
  GET_ACTIVE_ADDRESSES_SUCCESS: null,
  GET_ACTIVE_ADDRESSES_ERROR: null,
})

const GET_WASTEMANAGER_ADDRESSES = keyMirror({
  GET_WASTEMANAGER_ADDRESSES_REQUEST: null,
  GET_WASTEMANAGER_ADDRESSES_SUCCESS: null,
  GET_WASTEMANAGER_ADDRESSES_ERROR: null,
})

const GET_ADDRESSES_FOR_COMPANY = keyMirror({
  GET_ADDRESSES_FOR_COMPANY_REQUEST: null,
  GET_ADDRESSES_FOR_COMPANY_SUCCESS: null,
  GET_ADDRESSES_FOR_COMPANY_ERROR: null,
})

const ADD_ADDRESS = keyMirror({
  ADD_ADDRESS_REQUEST: null,
  ADD_ADDRESS_SUCCESS: null,
  ADD_ADDRESS_ERROR: null,
})

const UPDATE_ADDRESS = keyMirror({
  UPDATE_ADDRESS_REQUEST: null,
  UPDATE_ADDRESS_SUCCESS: null,
  UPDATE_ADDRESS_ERROR: null,
})

const BLOCK_ADDRESS = keyMirror({
  BLOCK_ADDRESS_REQUEST: null,
  BLOCK_ADDRESS_SUCCESS: null,
  BLOCK_ADDRESS_ERROR: null,
})

const GET_MY_AGREEMENT_INQUIRED_COLLECTION_ADDRESSES = keyMirror({
  GET_MY_AGREEMENT_INQUIRED_COLLECTION_ADDRESSES_REQUEST: null,
  GET_MY_AGREEMENT_INQUIRED_COLLECTION_ADDRESSES_SUCCESS: null,
  GET_MY_AGREEMENT_INQUIRED_COLLECTION_ADDRESSES_ERROR: null,
})

const GET_MY_ORDER_INQUIRED_COLLECTION_ADDRESSES = keyMirror({
  GET_MY_ORDER_INQUIRED_COLLECTION_ADDRESSES_REQUEST: null,
  GET_MY_ORDER_INQUIRED_COLLECTION_ADDRESSES_SUCCESS: null,
  GET_MY_ORDER_INQUIRED_COLLECTION_ADDRESSES_ERROR: null,
})

const GET_ADDRESSES_WITH_ORDERS = keyMirror({
  GET_ADDRESSES_WITH_ORDERS_REQUEST: null,
  GET_ADDRESSES_WITH_ORDERS_SUCCESS: null,
  GET_ADDRESSES_WITH_ORDERS_ERROR: null,
})

const EXPORT_ADDRESSES = keyMirror({
  EXPORT_ADDRESSES_REQUEST: null,
  EXPORT_ADDRESSES_SUCCESS: null,
  EXPORT_ADDRESSES_ERROR: null,
})

export default {
  ...GET_ADDRESSES,
  ...GET_ACTIVE_ADDRESSES,
  ...GET_WASTEMANAGER_ADDRESSES,
  ...GET_ADDRESSES_FOR_COMPANY,
  ...ADD_ADDRESS,
  ...UPDATE_ADDRESS,
  ...BLOCK_ADDRESS,
  ...GET_MY_AGREEMENT_INQUIRED_COLLECTION_ADDRESSES,
  ...GET_MY_ORDER_INQUIRED_COLLECTION_ADDRESSES,
  ...GET_ADDRESSES_WITH_ORDERS,
  ...EXPORT_ADDRESSES,
}
