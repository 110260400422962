import './style.scss'

import { string } from 'prop-types'
import React from 'react'
import { I18n } from 'react-i18nify'

const PdfViewer = ({ url }) => (
  <iframe
    className='pdf-viewer'
    // sandbox='allow-same-origin' // If set: PDF won't be shown in Chrome 71
    src={url}
    title={I18n.t('pdfViewer.pdfViewerTitle')}
  />
)

PdfViewer.propTypes = {
  url: string.isRequired,
}

export default PdfViewer
