import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'

import { updateOrderInternalComment } from 'actions/order'
import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { Textarea } from 'components/common/Textarea'
import { UserPermission } from 'constants/user'

type InternalCommentTextAreaProps = {
  requiresPermissions: UserPermission[]
  order: Order
  label: string
  maxLength?: number
  rows?: number
  showRemainingCharacters?: boolean
  value?: string
  buttonBackgroundColor?: BUTTON_BACKGROUND_COLOR
  buttonValue
}

export const InternalCommentTextArea: FC<InternalCommentTextAreaProps> = ({
  requiresPermissions,
  order,
  label,
  maxLength = 1000,
  rows = 5,
  showRemainingCharacters = true,
  value,
  buttonBackgroundColor = BUTTON_BACKGROUND_COLOR.PRIMARY,
  buttonValue,
}) => {
  const dispatch = useDispatch()
  const [orderInternalComment, setOrderInternalComment] = useState(value ?? '')

  return (
    <RequiredPermissions requiredPermissions={requiresPermissions}>
      <div className='detail-row uk-margin-small-bottom'>{label}</div>
      <div className='detail-row detail-row-value uk-margin-small-bottom'>
        <Textarea
          name='order_internal_comment'
          maxLength={maxLength}
          rows={rows}
          onChange={comment => {
            setOrderInternalComment(comment.target.value)
          }}
          showRemainingCharacters={showRemainingCharacters}
          value={orderInternalComment}
        />
        <Button
          backgroundColor={buttonBackgroundColor}
          onClick={() =>
            dispatch(updateOrderInternalComment(order.id, orderInternalComment))
          }
        >
          {buttonValue}
        </Button>
      </div>
    </RequiredPermissions>
  )
}
