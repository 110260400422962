import { I18n } from 'react-i18nify'

import { CERTIFICATE_TYPE } from 'components/certificate/constants'
import { COMPANY_ROLE } from 'components/company/constants'

const getTypeChoices = company =>
  company.role === COMPANY_ROLE.WASTE_PRODUCER
    ? [
        {
          label: I18n.t('certificateArchive.type.priceAgreement'),
          value: `${CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT}`,
        },
        {
          label: I18n.t('documentUploadFormTranslations.choices.other'),
          value: `${CERTIFICATE_TYPE.TYPE_OTHER}`,
        },
      ]
    : [
        {
          label: I18n.t(
            'documentUploadFormTranslations.choices.efbCertificate',
          ),
          value: `${CERTIFICATE_TYPE.TYPE_EFB}`,
        },
        {
          label: I18n.t(
            'documentUploadFormTranslations.choices.priceAgreement',
          ),
          value: `${CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT}`,
        },
        {
          label: I18n.t('documentUploadFormTranslations.choices.other'),
          value: `${CERTIFICATE_TYPE.TYPE_OTHER}`,
        },
      ]

export { getTypeChoices }
