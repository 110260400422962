import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import WorkloadActionTypes from '../actiontypes/workload'
import { APP_CONSTANTS, TABLE_PAGE_SIZE_10 } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetWorkloads
 * @description gets list of workloads
 */
export const handleGetWorkloads = createLogic({
  type: WorkloadActionTypes.GET_WORKLOADS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}
    if (action.payload) {
      data = {
        page: action.payload.page || 1,
        page_size: action.payload.pageSize || TABLE_PAGE_SIZE_10,
      }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/workload/`,
        data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: WorkloadActionTypes.GET_WORKLOADS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerpremium.handleGetWorkloads.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: WorkloadActionTypes.GET_WORKLOADS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportWorkload
 * @description triggers the workload export for epd.
 */
export const handleExportWorkload = createLogic({
  type: WorkloadActionTypes.EXPORT_WORKLOAD_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { filters } = action.payload
    let data = {}

    if (filters) {
      data = { ...data, filters: JSON.stringify(filters) }
    }

    if (action.payload.sortedBy && action.payload.sortedBy.length) {
      data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
    }

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/maklerpremium/workload/export/`,
        data,
      )
      .then(() => {
        dispatch({
          type: WorkloadActionTypes.EXPORT_WORKLOAD_SUCCESS,
        })

        toastr.success(
          '',
          I18n.t('api.maklerPremium.handleExportWorkload.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.maklerPremium.handleExportWorkload.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: WorkloadActionTypes.EXPORT_WORKLOAD_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [handleGetWorkloads, handleExportWorkload]
