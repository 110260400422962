import './style.scss'

import React from 'react'

import Dropdown from 'components/common/Dropdown'

import { USER_DROPDOWN_ID } from '../constants'

import { UserInfo } from './components/UserInfo'
import { UserMenu } from './components/UserMenu'

export const UserDropdown = ({ onClick, isVisible }) => (
  <Dropdown
    iconName='user'
    onClick={onClick}
    containerId={USER_DROPDOWN_ID}
    isVisible={isVisible}
  >
    <div className='user-info-container uk-padding-small'>
      <UserInfo />
    </div>

    <nav
      className='user-menu-container uk-padding-small'
      data-uk-navbar='uk-navbar'
    >
      <UserMenu />
    </nav>
  </Dropdown>
)
