import { UserPermission } from '../constants/user'

/**
 * @description transform the history to an array.
 * @param _history
 * @param results
 * @return {*[]}
 */
export const getRouterHistoryAsArray = (_history, results = []) => {
  if (!_history) return []

  const manipulatedHistory = { ..._history }
  let updatedResults = [...results]

  if (manipulatedHistory.from) {
    updatedResults = [
      ...getRouterHistoryAsArray(manipulatedHistory.from, updatedResults),
    ]
    delete manipulatedHistory.from
  }

  updatedResults.push(manipulatedHistory)

  return updatedResults
}

/**
 * @description default page redirect after login.
 * @param permissions
 */
export const getDefaultPageRedirect = (permissions = []) => {
  const permissionsToCheck = [
    {
      id: UserPermission.VIEW_DASHBOARD_MAKLER_PREMIUM,
      redirect: '/dashboard',
    },
    {
      id: UserPermission.VIEW_NEWS,
      redirect: '/dashboard',
    },
    {
      id: UserPermission.VIEW_ORDER,
      redirect: '/order',
    },
    {
      id: UserPermission.VIEW_ACCEPTED_OFFER,
      redirect: '/agreement',
    },
    {
      id: UserPermission.VIEW_STAKEHOLDER_DASHBOARD_EPD,
      redirect: '/status',
    },
  ]

  const matchingPermission = permissionsToCheck.find(p =>
    permissions.includes(p.id.toLowerCase()),
  )

  return matchingPermission ? matchingPermission.redirect : '/user/profile'
}

export default {}
