import './style.scss'

import moment from 'moment'
import React, { FC, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getSecurityGroups } from 'actions/securitygroup'
import { ReadOnlyField } from 'components/common/ReadOnly'
import {
  getContainerNameForShops,
  getFractionName,
} from 'components/executionproof/helpers'
import { INQUIRY_ORDER_TYPE } from 'components/inquiry/constants'
import { INVOICE_CHECK_POSITIONS_CATEGORIES_CHOICES } from 'components/maklerpremium/constants'
import { OrderType } from 'components/maklerpremium/InvoiceCheckOverview/InvoiceCheckPositionsForm/Step2'
import { COLLECTION_CONTAINER_IDS, DATE_FORMATS } from 'constants/app'
import { decimalToGermanFormat } from 'helper/general'
import { getSecurityGroupsSelector } from 'selectors/securitygroup'

import { DetailsSection } from '../../DetailsSection'

interface OrderDetailsComponentProps {
  order: Order
  invoiceCheckPosition: InvoiceCheckPositionReview
  fractionList: Fraction[]
  containerList: Container[]
}

export const OrderDetails: FC<OrderDetailsComponentProps> = ({
  order,
  invoiceCheckPosition,
  fractionList = [],
  containerList = [],
}) => {
  const dispatch = useDispatch()
  const showQuantityInCubicMeters = COLLECTION_CONTAINER_IDS.includes(
    Number(order.offer_object.container),
  )
  const fraction = getFractionName(order.fraction, fractionList)
  const container = getContainerNameForShops(order.container, containerList)
  const date = moment(order.delivery_date, DATE_FORMATS).format('L')
  const securityGroupList = useSelector(getSecurityGroupsSelector)
  useEffect(() => {
    if (securityGroupList.length < 1) dispatch(getSecurityGroups())
  }, [securityGroupList.length, dispatch])

  const getSecurityGroup = security_group => {
    return securityGroupList
      .filter(item => item.id === Number(security_group))
      .map(
        item =>
          `${I18n.t('selectSecurityGroupTranslations.protectionClassNumber', {
            protectionClass: item.protection_class,
          })} / ${I18n.t(
            'selectSecurityGroupTranslations.securityLevelNumber',
            { securityLevel: item.security_level },
          )}`,
      )
      .shift()
  }

  const initialSecurityGroupDisplay = order.offer_object.security_group
    ? ' / ' + getSecurityGroup(order.offer_object.security_group)
    : ''
  const initialNumberOfContainersDisplay = order.offer_object
    .number_of_containers
    ? `${order.offer_object.number_of_containers}x `
    : ''

  const permanent_discount =
    order.offer_object.customer_company_object.permanent_discount

  const getInvoiceCheckPositionCategoryValue = () => {
    const possibleCategoryChoice =
      INVOICE_CHECK_POSITIONS_CATEGORIES_CHOICES.find(
        item => item.id === invoiceCheckPosition.category,
      )
    return possibleCategoryChoice ? I18n.t(possibleCategoryChoice.name) : '-'
  }

  return (
    <DetailsSection
      title={I18n.t(
        'InvoiceCheckPositionReviewTranslations.orderDetails.sectionTitle',
      )}
    >
      <div className='document-review-section-order-details'>
        {/* ORDER NUMBER */}
        <div className='document-review-section-order-details__producer-section'>
          {!!order.id && (
            <ReadOnlyField
              label={I18n.t(
                'InvoiceCheckPositionReviewTranslations.orderDetails.orderNumber',
              )}
              value={`${order.id} / ${fraction} / ${initialNumberOfContainersDisplay}${container} ${initialSecurityGroupDisplay} / ${date}`}
            />
          )}
        </div>

        {/* AEZ */}
        {!!order.offer_object.customer_company_object.name && (
          <div className='document-review-section-order-details__disposer-section'>
            <ReadOnlyField
              label={I18n.t(
                'InvoiceCheckPositionReviewTranslations.orderDetails.aez',
              )}
              value={order.offer_object.customer_company_object.name}
            />
          </div>
        )}

        {/* BILLING INTERVAL & PERMANENT DISCOUNT */}
        {(invoiceCheckPosition.relabeled ||
          invoiceCheckPosition.additional_rent ||
          invoiceCheckPosition.container_changed ||
          invoiceCheckPosition.order_type === OrderType.OTHER_SERVICE) &&
          !!order.offer_object.customer_company_object.billing_interval && (
            <div className='uk-grid uk-grid-1-2' data-uk-grid=''>
              <div className='document-review-section-order-details__disposer-section'>
                <ReadOnlyField
                  label={I18n.t(
                    'InvoiceCheckPositionReviewTranslations.orderDetails.billingInterval',
                  )}
                  value={
                    order.offer_object.customer_company_object.billing_interval
                  }
                />
              </div>
              <div className='document-review-section-order-details__disposer-section'>
                <ReadOnlyField
                  label={I18n.t(
                    'InvoiceCheckPositionReviewTranslations.orderDetails.permanentDiscount',
                  )}
                  value={
                    permanent_discount && permanent_discount > 0
                      ? `${permanent_discount} %`
                      : 'Nicht vorhanden'
                  }
                />
              </div>
            </div>
          )}

        {/* DISPOSER */}
        {!!order.offer_object.company_object.name && (
          <div className='document-review-section-order-details__disposer-section'>
            <ReadOnlyField
              label={I18n.t(
                'InvoiceCheckPositionReviewTranslations.orderDetails.disposer',
              )}
              value={order.offer_object.company_object.name}
            />
          </div>
        )}

        {/* COLLECTION ADDRESS */}
        {!!order.collection_address_object.display_name && (
          <div className='document-review-section-order-details__disposer-section'>
            <ReadOnlyField
              label={I18n.t(
                'InvoiceCheckPositionReviewTranslations.orderDetails.collectionAddress',
              )}
              value={order.collection_address_object.display_name}
            />
          </div>
        )}

        {/* INVOICE CHECK DOCUMENT NUMBER */}
        {invoiceCheckPosition.relabeled &&
          !!invoiceCheckPosition.invoice_check.document_number && (
            <div className='document-review-section-order-details__disposer-section'>
              <ReadOnlyField
                label={I18n.t(
                  'InvoiceCheckPositionReviewTranslations.orderDetails.invoiceCheckNumber',
                )}
                value={invoiceCheckPosition.invoice_check.document_number}
                linkTo={`/maklerpremium/invoice_check/overview/${invoiceCheckPosition.invoice_check.id}`}
              />
            </div>
          )}

        {/* INVOICE CHECK COMPANY */}
        {invoiceCheckPosition.relabeled &&
          !!invoiceCheckPosition.invoice_check.company_object.name && (
            <div className='document-review-section-order-details__disposer-section'>
              <ReadOnlyField
                label={I18n.t(
                  'InvoiceCheckPositionReviewTranslations.orderDetails.invoiceCheckCompany',
                )}
                value={invoiceCheckPosition.invoice_check.company_object.name}
              />
            </div>
          )}

        <div className='document-review-section-order-details__waste-section'>
          {invoiceCheckPosition.order_type === OrderType.OPEN_ORDER &&
            !!invoiceCheckPosition?.fraction &&
            !invoiceCheckPosition.container_changed && (
              <ReadOnlyField
                label={I18n.t(
                  'InvoiceCheckPositionReviewTranslations.orderDetails.fraction',
                )}
                value={getFractionName(
                  invoiceCheckPosition.fraction.parent ??
                    invoiceCheckPosition.fraction.id,
                  fractionList,
                )}
              />
            )}

          {/* FINE FRACTION */}
          {invoiceCheckPosition.order_type === OrderType.OPEN_ORDER &&
            !!invoiceCheckPosition?.fraction?.parent &&
            !invoiceCheckPosition.container_changed && (
              <ReadOnlyField
                label={I18n.t(
                  'InvoiceCheckPositionReviewTranslations.orderDetails.fineFraction',
                )}
                value={getFractionName(
                  invoiceCheckPosition.fraction.id,
                  fractionList,
                )}
              />
            )}

          {!!invoiceCheckPosition.security_group && (
            <ReadOnlyField
              label={I18n.t(
                'InvoiceCheckPositionReviewTranslations.orderDetails.securityGroup',
              )}
              value={getSecurityGroup(invoiceCheckPosition.security_group)}
            />
          )}

          {/* AVV */}
          {!!invoiceCheckPosition.avv &&
            invoiceCheckPosition.order_type !== OrderType.OTHER_SERVICE &&
            !invoiceCheckPosition.container_changed && (
              <ReadOnlyField
                label={I18n.t(
                  'InvoiceCheckPositionReviewTranslations.orderDetails.avv',
                )}
                value={invoiceCheckPosition.avv}
              />
            )}

          {/* CONTAINER */}
          {invoiceCheckPosition.order_type === OrderType.OPEN_ORDER &&
            !!invoiceCheckPosition.container && (
              <ReadOnlyField
                label={I18n.t(
                  'InvoiceCheckPositionReviewTranslations.orderDetails.container',
                )}
                value={getContainerNameForShops(
                  invoiceCheckPosition.container,
                  containerList,
                )}
              />
            )}

          {/* NUMBER OF CONTAINERS */}
          {/* Here we are interested in the number of containers in the order, not the offer (because it could have been
              changed in case of Auftrag auf Abruf) */}
          {invoiceCheckPosition.order_type === OrderType.OPEN_ORDER &&
            !!invoiceCheckPosition.number_of_containers &&
            !showQuantityInCubicMeters && (
              <ReadOnlyField
                label={I18n.t(
                  'InvoiceCheckPositionReviewTranslations.orderDetails.numberOfContainers',
                )}
                value={invoiceCheckPosition.number_of_containers}
              />
            )}

          {/* QUANTITY IN CUBIC METERS*/}
          {invoiceCheckPosition.order_type === OrderType.OPEN_ORDER &&
            !!invoiceCheckPosition.quantity_in_cubic_meters &&
            showQuantityInCubicMeters && (
              <ReadOnlyField
                label={I18n.t(
                  'InvoiceCheckPositionReviewTranslations.orderDetails.quantityInCubicMeters',
                )}
                value={decimalToGermanFormat(
                  invoiceCheckPosition.quantity_in_cubic_meters as React.ReactText,
                )}
              />
            )}

          {/* Liefer- / Leistungsdatum */}
          {invoiceCheckPosition.delivery_date && (
            <ReadOnlyField
              label={
                order?.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME
                  ? I18n.t(
                      'InvoiceCheckPositionReviewTranslations.orderDetails.deliveryDate',
                    )
                  : I18n.t(
                      'InvoiceCheckPositionReviewTranslations.orderDetails.serviceDate',
                    )
              }
              value={moment(
                invoiceCheckPosition.delivery_date,
                DATE_FORMATS,
              ).format('L')}
            />
          )}

          {/* Abholdatum */}
          {order.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME &&
            invoiceCheckPosition.collection_date && (
              <ReadOnlyField
                label={I18n.t(
                  'InvoiceCheckPositionReviewTranslations.orderDetails.collectionDate',
                )}
                value={moment(
                  invoiceCheckPosition.collection_date,
                  DATE_FORMATS,
                ).format('L')}
              />
            )}

          {/* Kategorie */}
          {invoiceCheckPosition.category && (
            <ReadOnlyField
              label={I18n.t(
                'InvoiceCheckPositionReviewTranslations.orderDetails.category',
              )}
              value={getInvoiceCheckPositionCategoryValue()}
            />
          )}

          {/* Beschreibung */}
          {invoiceCheckPosition.other_service_other_text && (
            <ReadOnlyField
              label={I18n.t(
                'InvoiceCheckPositionReviewTranslations.orderDetails.text',
              )}
              value={invoiceCheckPosition.other_service_other_text}
            />
          )}
        </div>
      </div>
    </DetailsSection>
  )
}
