import DiscountPageComponent from '../components/discount/DiscountPage'
import { UserPermission } from '../constants/user'

/**
 * @constant
 * @description A constant to define discount routes.
 */
export const discountRoute = [
  {
    path: '/discount',
    component: DiscountPageComponent,
    requiredPermissions: [UserPermission.IS_MAKLER_PREMIUM],
    exact: true,
    showErrorPage: true,
  },
]

export default {}
