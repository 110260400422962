import './PriceComparisonTableCompany.scss'

import classNames from 'classnames'
import React, { FC, MouseEvent } from 'react'

import { RatingCaption } from '../../common/RatingCaption'
import { Button } from '../Button'

interface PriceComparisonTableCompanyProps {
  className?: string
  company: PriceComparisonCompany
}

/**
 * renders the company information field
 * @constructor
 */
export const PriceComparisonTableCompany: FC<
  PriceComparisonTableCompanyProps
> = ({ className, company }) => {
  /**
   * handles the go-to profile button by opening the profile in a new tab
   */
  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    window.open(`/company/${company.id}`, '_blank')
  }

  return (
    <div className={classNames('price-comparison-table-company', className)}>
      <strong className='price-comparison-table-company__name'>
        {company.name}
      </strong>
      <span className='price-comparison-table-company__address'>{`${company.zip_code} ${company.city}`}</span>
      <RatingCaption
        className='price-comparison-table-company__rating'
        rating={company.rating || 0}
      />
      <Button
        className='price-comparison-table-company__button'
        onClick={handleButtonClick}
      >
        Zum Profil
      </Button>
    </div>
  )
}
