import React, { FC, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getArchivedCertificates } from 'actions/certificate'
import { PaginatedTable } from 'components/common/PaginatedTable'
import { UpDownArrow } from 'components/common/UpDownArrow'
import { certificateTypes } from 'components/company/CompanyInfoPage/components/Documents'
import { localizeDate } from 'helper/general'
import { getName } from 'helper/translations'
import { getArchivedCertificatePaginationSelector } from 'selectors/certificate'

interface CompanyArchiveTableProps {
  data?: Certificate[]
  loading?: boolean
  pageSize?: number
  filters?: any
}

export const CompanyArchiveTable: FC<CompanyArchiveTableProps> = ({
  data = [],
  loading = false,
  filters = {},
}) => {
  const dispatch = useDispatch()
  const pagination = useSelector(getArchivedCertificatePaginationSelector)

  const [currentFilters, setCurrentFilters] = useState(filters)

  const columns = [
    {
      id: 'id_or_contract_id',
      width: 75,
      Header: (
        <>
          <Translate value='companyArchiveTranslations.table.columns.id' />
          <UpDownArrow />
        </>
      ),
      accessor: data => data.id_or_contract_id,
    },
    {
      id: 'type',
      width: 150,
      Header: (
        <span>
          <Translate value='companyArchiveTranslations.table.columns.type' />
          <UpDownArrow />
        </span>
      ),
      accessor: 'type',
      Cell: data => I18n.t(getName(data.value, certificateTypes)),
    },
    {
      id: 'description_display',
      minWidth: 300,
      Header: (
        <span>
          <Translate value='companyArchiveTranslations.table.columns.description' />
          <UpDownArrow />
        </span>
      ),
      accessor: data => data,
      Cell: data => {
        if (data.value.attachments.length > 0) {
          return (
            <div className='uk-flex'>
              {data.value.attachments.map(file => (
                <Link
                  key={data.value.id}
                  to={{ pathname: file.file }}
                  onClick={event => {
                    event.preventDefault()
                    window.open(file.file)
                  }}
                >
                  {data.value.description ? data.value.description : file.name}
                </Link>
              ))}
            </div>
          )
        } else {
          return <div className='uk-flex'>{data.value.description}</div>
        }
      },
    },
    {
      id: 'archived_at',
      minWidth: 250,
      Header: (
        <>
          <Translate value='companyArchiveTranslations.table.columns.archivedAt' />
          <UpDownArrow />
        </>
      ),
      accessor: data => localizeDate(data.archived_at),
    },
    {
      id: 'archived_by__name',
      minWidth: 250,
      Header: (
        <>
          <Translate value='companyArchiveTranslations.table.columns.archivedBy' />
          <UpDownArrow />
        </>
      ),
      accessor: data => data.archived_by_name,
    },
  ]

  return (
    <PaginatedTable
      serverSidePagination
      page={pagination.current}
      pages={pagination.count}
      loading={loading}
      handleShowPreviousPage={() => {
        dispatch(
          getArchivedCertificates(pagination.previous, currentFilters, null),
        )
      }}
      handleShowNextPage={() => {
        dispatch(getArchivedCertificates(pagination.next, currentFilters, null))
      }}
      table={{
        columns,
        data: data,
        defaultSorted: [{ id: 'archived_at', desc: true }],
        onFetchData: ({ page, sorted }) => {
          if (sorted && sorted.length > 0) {
            const newFilters = {
              ...currentFilters,
              order_by: JSON.stringify(sorted),
            }
            if (
              JSON.stringify(newFilters) !== JSON.stringify(currentFilters) ||
              !pagination.loaded
            ) {
              setCurrentFilters(newFilters)
              dispatch(getArchivedCertificates(page, newFilters, null))
            }
          }
        },
      }}
    />
  )
}
