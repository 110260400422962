import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'

import { ComCenterContactForm } from '../ComCenterContactForm'

export const ComCenterContactModal: FC<{
  onCloseModal: () => void
  isOpen: boolean
  comCenterContact?: ComCenterContact
  category?: number
}> = ({ onCloseModal, isOpen, comCenterContact, category }) => {
  const idNewComCenterContactModal = uniqueId()

  return (
    <Modal
      ariaDescribedBy={idNewComCenterContactModal}
      isOpen={isOpen}
      onClose={onCloseModal}
    >
      <ModalHeader
        onClose={() => onCloseModal()}
        title={I18n.t(
          'communicationCenterPageTranslations.modals.newEntryAllStagesModal.title',
        )}
        titleId={idNewComCenterContactModal}
      />
      <ComCenterContactForm
        onCloseModal={onCloseModal}
        comCenterContact={comCenterContact}
        category={category}
      />
    </Modal>
  )
}
