import React from 'react'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface KeyDataFormProps {}

// @TODO remove when filled with content
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const KeyDataForm = (props: KeyDataFormProps) => {
  const placeholder = 'Placeholder'
  return <>{placeholder}</>
}
