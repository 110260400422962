import { connect } from 'react-redux'
import { actions } from 'react-redux-form'

import { resetZipcode } from 'actions/zipCode'
import { getZipcodeSubmittedSelector } from 'selectors/zipCode'
/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  zipCodeSubmitted: getZipcodeSubmittedSelector(state),
})

/**
 * @function
 * @description method to map the dispatchers to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  resetZipCodeField: field => dispatch(actions.reset(field)),
  resetZipCode: () => dispatch(resetZipcode()),
})

export const connector = connect(mapStateToProps, mapDispatchToProps)
