import './style.scss'

import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'

interface FilterResetProps {
  showResetFilterButton: boolean | undefined
  onResetFilter?: () => void
  className?: string | undefined
}

export const FilterReset: FC<FilterResetProps> = ({
  onResetFilter = () => undefined,
  showResetFilterButton,
}) => {
  return (
    <div className='filter-reset'>
      {showResetFilterButton && (
        <Link to='#' onClick={onResetFilter} className='icon-cross'>
          <Translate value='filterResetTranslation.resetFilter' />
        </Link>
      )}
    </div>
  )
}
