import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import {
  GET_LANDING_PAGE_WASTE_MANAGER_CONTENT as ActionTypesWasteManager,
  GET_LANDING_PAGE_WASTE_PRODUCER_CONTENT as ActionTypesWasteProducerLandingPage,
} from '../actiontypes/landingpage'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetLandingPageContent
 * @description gets content for the landing page.
 */
const handleGetLandingPageContent = createLogic({
  type: ActionTypesWasteManager.GET_LANDING_PAGE_WASTE_MANAGER_CONTENT_REQUEST,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/landing-page-content/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypesWasteManager.GET_LANDING_PAGE_WASTE_MANAGER_CONTENT_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.landingpage.handleGetLandingPageContent.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypesWasteManager.GET_LANDING_PAGE_WASTE_MANAGER_CONTENT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetLandingPageWasteProducerContent
 * @description gets content for the waste-producer-landing page.
 */
const handleGetLandingPageWasteProducerContent = createLogic({
  type: ActionTypesWasteProducerLandingPage.GET_LANDING_PAGE_WASTE_PRODUCER_CONTENT_REQUEST,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/landingpage/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypesWasteProducerLandingPage.GET_LANDING_PAGE_WASTE_PRODUCER_CONTENT_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.landingpage.handleGetLandingPageContent.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypesWasteProducerLandingPage.GET_LANDING_PAGE_WASTE_PRODUCER_CONTENT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [
  handleGetLandingPageContent,
  handleGetLandingPageWasteProducerContent,
]
