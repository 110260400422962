import { connect } from 'react-redux'

import {
  getCertificate,
  resetCertificateItem,
  updateReviewedCertificate,
} from 'actions/certificate'
import { getCertificateSelector } from 'selectors/certificate'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'

const getCertificateLoadingSelector = createLoadingSelector(['GET_CERTIFICATE'])
const updateCertificateLoadingSelector = createLoadingSelector([
  'UPDATE_REVIEWED_CERTIFICATE',
])
const errorSelector = createErrorSelector([
  'GET_CERTIFICATE',
  'UPDATE_REVIEWED_CERTIFICATE',
])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  user: getCurrentUserSelector(state),
  certificate: getCertificateSelector(state),
  isLoading: {
    getCertificate: getCertificateLoadingSelector(state),
    updateCertificate: updateCertificateLoadingSelector(state),
  },
  error: errorSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getCertificate: certificateId => dispatch(getCertificate(certificateId)),
  updateReviewedCertificate: (certificate, history, user) =>
    dispatch(updateReviewedCertificate(certificate, history, user)),
  resetCertificateItem: () => dispatch(resetCertificateItem()),
})

export const connector = connect(mapStateToProps, mapDispatchToProps)
