import ActionTypes from '../actiontypes/community'

export const initialState = {
  itemList: [],
  isLoading: false,
}

/**
 * @description A reducer for community.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const communityReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_COMMUNITIES_SUCCESS:
      return {
        ...state,
        itemList: action.payload,
      }
    default:
      return state
  }
}

export default communityReducer
