import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import ContainerActionTypes from '../actiontypes/container'
import FractionActionTypes from '../actiontypes/fraction'
import IndexActionTypes from '../actiontypes/indexes'
import ActionTypes from '../actiontypes/user'
import { USER_STATUS } from '../components/user/constants'
import { APP_CONSTANTS } from '../constants/app'
import { MaklerPremiumGroups, USER_GROUP_IS_SUPERUSER } from '../constants/user'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleRegisterUser
 * @description creates a user.
 */
export const handleRegisterUser = createLogic({
  type: ActionTypes.REGISTER_USER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/`,
        action.payload.userInfo,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.REGISTER_USER_SUCCESS,
          payload: res,
        })
        action.payload.history.push('/registrieren/done')

        toastr.success('', I18n.t('api.user.handleRegisterUser.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.user.handleRegisterUser.error')

        // here we are setting the async validation for the email field based in Formik.
        if (err.response && err.response.data) {
          const errorData = err.response.data
          const callbackData = action.payload.callback.setError
          // Apparently the translated message coming from the server has a wrong declination
          // (check feedback on ticket #944). I am passing here the same message but with the correct declination
          const customErrorEmailMessage = I18n.t(
            'api.user.handleUserCommonValidationError.email',
          )
          Object.keys(errorData).forEach(errorKey => {
            let errorMessage = errorData[errorKey][0]
            if (errorKey === 'email') errorMessage = customErrorEmailMessage
            callbackData(errorKey, errorMessage)
          })
        }

        // messages for wrong password or email are currently being shown as formik error. So we need to avoid
        // showing them with ApiValidationMessages component.
        // Only dispatch error if none of the matches is for email or password
        const errorResponse = err.response
        if (errorResponse.data.email) {
          delete errorResponse.data.email
        }
        if (errorResponse.data.password) {
          delete errorResponse.data.password
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          errorResponse,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.REGISTER_USER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleInviteUser
 * @description invites a user.
 */
export const handleInviteUser = createLogic({
  type: ActionTypes.INVITE_USER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    // replace "all" option with null. Api takes null for "all addresses", but the select needs a specific value.
    const allOrSingleAddress =
      !action.payload.userInfo.preferred_address ||
      action.payload.userInfo.preferred_address === 'all'
        ? null
        : action.payload.userInfo.preferred_address
    const newPayload = {
      ...action.payload.userInfo,
      preferred_address: allOrSingleAddress,
    }

    httpClient
      .post(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/user/`, newPayload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.INVITE_USER_SUCCESS,
          payload: res,
        })

        if (action.payload.callback) {
          if (action.payload.callback.createdUser)
            action.payload.callback.createdUser(res)
          if (action.payload.callback.success) action.payload.callback.success()
        }
        if (
          res.group_id === MaklerPremiumGroups.MAKLER_PREMIUM_EPD_CONTACT_PERSON
        ) {
          toastr.success(
            '',
            I18n.t('api.user.handleInviteUser.contact_person_success'),
          )
        } else {
          toastr.success('', I18n.t('api.user.handleInviteUser.success'))
        }
      })
      .then(done)
      .catch(err => {
        // If email address is duplicate, display in right place of form
        // since this is the most common server side validation cause.
        const humanReadableError = I18n.t('api.user.handleRegisterUser.error')

        // here we are setting the async validation for the email field based in Formik.
        if (err.response && err.response.data) {
          const errorData = err.response.data
          if (action.payload.callback && action.payload.callback.setError) {
            const callbackData = action.payload.callback.setError

            // Apparently the translated message coming from the server has a wrong declination
            // (check feedback on ticket #944). I am passing here the same message but with the correct declination
            const customErrorEmailMessage = I18n.t(
              'api.user.handleUserCommonValidationError.email',
            )
            const businessCustomerEmailWrongDomainErrorMessage =
              'Die Mailadresse weicht von der Domain ' +
              'der anderen Ansprechpartner'
            Object.keys(errorData).forEach(errorKey => {
              let errorMessage = errorData[errorKey][0]
              if (errorKey === 'email') {
                errorMessage = errorMessage.includes(
                  businessCustomerEmailWrongDomainErrorMessage,
                )
                  ? errorMessage
                  : customErrorEmailMessage
              }
              callbackData(errorKey, errorMessage)
            })
          }
        }

        // messages for wrong password or email are currently being shown as formik error. So we need to avoid
        // showing them with ApiValidationMessages component.
        // Only dispatch error if none of the matches is for email or password
        const errorResponse = err.response
        if (errorResponse.data && errorResponse.data.email) {
          delete errorResponse.data.email
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          errorResponse,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.INVITE_USER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleActivateCurrentUser
 * @description activates a user.
 */
export const handleActivateCurrentUser = createLogic({
  type: ActionTypes.ACTIVATE_CURRENT_USER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/activate/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.ACTIVATE_CURRENT_USER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.user.handleActivateCurrentUser.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.ACTIVATE_CURRENT_USER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleLogin
 * @description login a user.
 */
export const handleLogin = createLogic({
  type: ActionTypes.LOGIN_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient.removeLoggedIn() // reset cookie to avoid side effects
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/login/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        // Set the cookie in the http client service (which also handles login persistence)
        httpClient.setLoggedIn(action.payload.stayLogged)

        dispatch({ type: ContainerActionTypes.GET_CONTAINERS_REQUEST })
        dispatch({ type: FractionActionTypes.GET_FRACTIONS_REQUEST })
        dispatch({ type: IndexActionTypes.GET_INDEXES_REQUEST })

        dispatch({
          type: ActionTypes.LOGIN_SUCCESS,
          payload: {
            user: res.user,
          },
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.user.handleLogin.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.LOGIN_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCurrentUser
 * @description gets a user.
 */
export const handleGetCurrentUser = createLogic({
  type: [ActionTypes.GET_CURRENT_USER_REQUEST],
  latest: true,

  process({ httpClient }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/user/`

    httpClient
      .get(url)
      .then(res => res.data)
      .then(res => {
        // Reject login as SuperUser
        if (res.group_id === USER_GROUP_IS_SUPERUSER) {
          // TODO to we need this special case anymore? Superuser login is denied via backend now
          const humanReadableError = I18n.t('api.user.superUserNotAllowedError')

          // Logout Superuser after login attempt
          dispatch({
            type: ActionTypes.LOGOUT_REQUEST,
            payload: res.id,
          })

          // for now just show a toastr with an error message
          toastr.error('', humanReadableError)
        } else {
          dispatch({
            type: ActionTypes.GET_CURRENT_USER_SUCCESS,
            payload: res,
          })
        }
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.user.handleGetCurrentUser.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_CURRENT_USER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetUser
 * @description gets a user.
 */
export const handleGetUser = createLogic({
  type: ActionTypes.GET_USER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/user/${action.payload}/`

    httpClient
      .get(url)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_USER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.user.handleGetCurrentUser.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_USER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetMyUsers
 * @description get users of current company.
 */
export const handleGetMyUsers = createLogic({
  type: ActionTypes.GET_MY_USERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload) {
      const initialPage = { page: action.payload.page || 1 }

      // we are sending empto_company filter with a condition based on the value company we selected because we cannot
      // simply pass an NON existing id or a string to the backend as company (the parameter the API will
      // receive to fetch the results). In case we are selecting "empto" as a value, then we are forcing company to be
      // null in the eyes of the API
      const filters = action.payload.filters
        ? {
            id: action.payload.filters.id,
            company:
              action.payload.filters.company === 'empto'
                ? ''
                : action.payload.filters.company,
            empto_company: action.payload.filters.company,
            group_id: action.payload.filters.group_id,
            name: action.payload.filters.name,
            status: action.payload.filters.status,
          }
        : {}

      const orderBy =
        action.payload.sortedBy && action.payload.sortedBy.length
          ? {
              order_by: JSON.stringify(action.payload.sortedBy),
            }
          : {}

      data = { ...initialPage, ...orderBy, ...filters }
    }

    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/user/mine/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_MY_USERS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.user.handleGetMyUsers.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_MY_USERS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCompanyUsers
 * @description get users of a specified company.
 */
export const handleGetCompanyUsers = createLogic({
  type: ActionTypes.GET_COMPANY_USERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    // new payload is being sent only for the case we select "Leer" empty company in the users filter, meaning this
    // will show only the empto users in the selection dropdown
    const newPayload = {
      ...action.payload,
      company: action.payload.company === 'empto' ? '' : action.payload.company,
      empto_company: action.payload.company,
    }
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/user/mine/`, newPayload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COMPANY_USERS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.user.handleGetMyUsers.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_COMPANY_USERS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCombinedOfferEPDUsersForTVP
 * @description get EPD users to create a combined offer for as TVP
 */
export const handleGetCombinedOfferEPDUsersForTVP = createLogic({
  type: ActionTypes.GET_COMBINED_OFFER_EPD_USERS_FOR_TVP_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    // new payload is being sent only for the case we select "Leer" empty company in the users filter, meaning this
    // will show only the empto users in the selection dropdown
    const newPayload = {
      ...action.payload,
      company: action.payload.company === 'empto' ? '' : action.payload.company,
      empto_company: action.payload.company,
    }
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/user/combined-offer-epd-users-for-tvp/`,
        newPayload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COMBINED_OFFER_EPD_USERS_FOR_TVP_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.user.handleGetMyUsers.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_COMBINED_OFFER_EPD_USERS_FOR_TVP_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleForgetPassword
 * @description send email to reset a password for user.
 */
export const handleForgetPassword = createLogic({
  type: ActionTypes.PASSWORD_RESET_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/password/reset/`,
        action.payload.email,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.PASSWORD_RESET_SUCCESS,
          payload: res,
        })
        action.payload.history.push('/forget-password/done')
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.user.handleForgetPassword.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.PASSWORD_RESET_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handlePasswordResetConfirm
 * @description set new password to a user.
 */
export const handlePasswordResetConfirm = createLogic({
  type: ActionTypes.PASSWORD_RESET_CONFIRM_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/password/reset/confirm/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.PASSWORD_RESET_CONFIRM_SUCCESS,
          payload: res,
        })

        if (action.payload.callback) {
          if (action.payload.callback.success) action.payload.callback.success()
        }
        toastr.success(
          '',
          I18n.t('api.user.handlePasswordResetConfirm.success'),
        )

        action.payload.history.push('/anmelden')
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.user.handlePasswordResetConfirm.error',
        )

        // here we are setting the async validation for the email field based in Formik.
        if (err.response && err.response.data) {
          const errorData = err.response.data
          const callbackData = action.payload.callback.setError
          Object.keys(errorData).forEach(errorKey => {
            callbackData('password', errorData[errorKey][0])
          })
        }

        // messages for wrong password or email are currently being shown as formik error. So we need to avoid
        // showing them with ApiValidationMessages component.
        // Only dispatch error if none of the matches is for email or password
        const errorResponse = err.response
        if (errorResponse.data) {
          if (
            errorResponse.data.new_password1 ||
            errorResponse.data.new_password2
          ) {
            delete errorResponse.data.new_password1
            delete errorResponse.data.new_password2
          }
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          errorResponse,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.PASSWORD_RESET_CONFIRM_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleSetPassword
 * @description sets password for a user.
 */
export const handleSetPassword = createLogic({
  type: ActionTypes.SET_PASSWORD_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/password/reset/confirm/`

    httpClient
      .post(url, action.payload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.SET_PASSWORD_SUCCESS,
          payload: res,
        })

        if (action.payload.callback) {
          if (action.payload.callback.success) action.payload.callback.success()
        }
        toastr.success('', I18n.t('api.user.handleSetPassword.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.user.handleSetPassword.error')

        // here we are setting the async validation for the email field based in Formik.
        if (err.response && err.response.data) {
          const errorData = err.response.data
          const callbackData = action.payload.callback.setError
          Object.keys(errorData).forEach(errorKey => {
            callbackData('password', errorData[errorKey][0])
          })
        }

        // messages for wrong password or email are currently being shown as formik error. So we need to avoid
        // showing them with ApiValidationMessages component.
        // Only dispatch error if none of the matches is for email or password
        const errorResponse = err.response
        if (errorResponse.data && errorResponse.data.new_password1) {
          delete errorResponse.data.new_password1
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          errorResponse,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.SET_PASSWORD_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleLogout
 * @description Clears the auth token from storage and http service
 */
export const handleLogout = createLogic({
  type: ActionTypes.LOGOUT_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/logout/`)
      .then(() => {
        dispatch({
          type: ActionTypes.LOGOUT_SUCCESS,
        })
        // Remove the cookie from the http client service (which will also remove login persistence)
        // Logout is only available for logged in users. Users without Tokens are considered not logged in.
        httpClient.removeLoggedIn()
        action.payload.history.replace('/anmelden')
        done()
      })
      .catch(err => {
        const humanReadableError = I18n.t('api.user.handleLogout.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.LOGOUT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateAccount
 * @description updates the currently logged in user.
 */
export const handleUpdateAccount = createLogic({
  type: ActionTypes.UPDATE_CURRENT_USER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/user/`

    httpClient
      .patch(url, action.payload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.UPDATE_CURRENT_USER_SUCCESS,
          payload: res,
        })
        dispatch({
          type: ActionTypes.GET_CURRENT_USER_REQUEST,
          payload: res,
        })
        toastr.success('', I18n.t('api.user.handleUpdateAccount.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.user.handleUpdateAccount.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.UPDATE_CURRENT_USER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateUser
 * @description updates a user object using partial update (PATCH)
 */
export const handleUpdateUser = createLogic({
  type: ActionTypes.UPDATE_USER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    // replace "all" option with null. Api takes null for "all addresses", but the select needs a specific value.
    const allOrSingleAddress =
      action.payload.user.preferred_address === 'all'
        ? null
        : action.payload.user.preferred_address
    const newPayload = {
      ...action.payload.user,
      preferred_address: allOrSingleAddress,
    }
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/user/${action.payload.user.id}/`

    httpClient
      .patch(url, newPayload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.UPDATE_USER_SUCCESS,
          payload: res,
        })

        if (action.payload.callback) {
          if (action.payload.callback.success) action.payload.callback.success()
        }

        // show a different message in the toaster, depending if we are just activating/deactivating the user or
        // updating the whole object
        // In case of activation / deactivation, we are just sending an object with 2 keys (id and status)
        if (Object.keys(action.payload.user).length > 2) {
          toastr.success('', I18n.t('api.user.handleUpdateUser.success'))
        } else {
          if (
            action.payload.user.status &&
            action.payload.user.status === USER_STATUS.STATUS_ACTIVE
          ) {
            toastr.success(
              '',
              I18n.t('api.user.handleUpdateStatusUser.activation.success'),
            )
          }
          if (
            action.payload.user.status &&
            action.payload.user.status === USER_STATUS.STATUS_BLOCKED
          ) {
            toastr.success(
              '',
              I18n.t('api.user.handleUpdateStatusUser.deactivation.success'),
            )
          }
        }
      })
      .then(done)
      .catch(err => {
        if (err.response.status === 409) {
          dispatch({
            type: ActionTypes.SET_USER_IS_CONTACT_PERSON,
          })
        } else {
          const humanReadableError = I18n.t('api.user.handleUpdateUser.error')
          const payload = enhanceError(
            err.message,
            err.config,
            err.code,
            err.request,
            err.response,
            humanReadableError,
          )

          dispatch({
            type: ActionTypes.UPDATE_USER_ERROR,
            payload,
          })

          if (!err.hideToastr) {
            if (
              err.response.data &&
              err.response.data.status &&
              err.response.data.status.length > 0
            ) {
              toastr.error('', err.response.data.status[0])
            } else toastr.error('', humanReadableError)
          }
        }
        done()
      })
  },
})

/**
 * @function
 * @name handleChangePassword
 * @description updates a user.
 */
export const handleChangePassword = createLogic({
  type: ActionTypes.CHANGE_PASSWORD_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/account/password/change/`

    httpClient
      .post(url, action.payload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.CHANGE_PASSWORD_SUCCESS,
          payload: res,
        })
        // this is used for the change password form. If the user has a correct password, submit the form and
        // close modal
        if (action.payload.callback) {
          if (action.payload.callback.success) action.payload.callback.success()
        }
        toastr.success('', I18n.t('api.user.handleChangePassword.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.user.handleChangePassword.error')

        // here we are setting the async validation for the email and password field errors based in Formik.
        if (err.response && err.response.data) {
          const errorData = err.response.data
          const callbackData = action.payload.callback.setError
          Object.keys(errorData).forEach(errorKey => {
            // because in the action object, the key is 'new_password', but in formik is just 'password'
            if (errorKey === 'new_password')
              callbackData('password', errorData[errorKey][0])
            callbackData(errorKey, errorData[errorKey][0])
          })
        }

        // messages for wrong password or email are currently being shown as formik error. So we need to avoid
        // showing them with ApiValidationMessages component.
        // Only dispatch error if none of the matches is for email or password
        const errorResponse = err.response
        if (errorResponse.data) {
          if (
            errorResponse.data.old_password ||
            errorResponse.data.new_password
          ) {
            delete errorResponse.data.old_password
            delete errorResponse.data.new_password
          }
        }

        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          errorResponse,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.CHANGE_PASSWORD_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportUsers
 * @description triggers user export.
 */
export const handleExportUsers = createLogic({
  type: ActionTypes.EXPORT_USERS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { filters } = action.payload
    let data = {}

    if (filters) {
      data = { ...data, filters: JSON.stringify(filters) }
    }
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/user/export/`, data)
      .then(() => {
        toastr.success('', I18n.t('api.user.handleExportUsers.success'))
        dispatch({
          type: ActionTypes.EXPORT_USERS_SUCCESS,
        })
      })
      .then(done)
      .catch(err => {
        dispatch({
          type: ActionTypes.EXPORT_USERS_ERROR,
        })

        if (!err.hideToastr)
          toastr.error('', I18n.t('api.user.handleExportUsers.error'))

        done()
      })
  },
})

/**
 * @function
 * @name handleGetUsersByCompany
 * @description gets a users by company.
 */
export const handleGetUsersByCompany = createLogic({
  type: ActionTypes.GET_USERS_BY_COMPANY_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/user/company/${action.payload}/`

    httpClient
      .get(url)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_USERS_BY_COMPANY_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.user.handleGetUsersByCompany.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_USERS_BY_COMPANY_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [
  handleRegisterUser,
  handleInviteUser,
  handleActivateCurrentUser,
  handleLogin,
  handleGetCurrentUser,
  handleGetUser,
  handleGetMyUsers,
  handleGetCompanyUsers,
  handleUpdateAccount,
  handleUpdateUser,
  handleChangePassword,
  handleSetPassword,
  handleForgetPassword,
  handlePasswordResetConfirm,
  handleLogout,
  handleExportUsers,
  handleGetUsersByCompany,
  handleGetCombinedOfferEPDUsersForTVP,
]
