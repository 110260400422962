import ActionTypes from '../actiontypes/autooffer'
import { BUSINESS_SEGMENT } from '../constants/app'
import { replaceObjectInArray } from '../helper/general'

export const itemInitialState = {
  avv: null,
  business_segment: BUSINESS_SEGMENT.BUSINESS_EMPTO,
  container: null,
  communities: [],
  compensation: '0',
  compensation_container: null,
  compensation_ton: null,
  discount: '',
  disposal_cost_container: '',
  disposal_cost_ton: '',
  fine_fraction: null,
  fraction: null,
  handle_cost_ton: '',
  index: null,
  number_of_containers: 1,
  order_type: null,
  position_on_public_ground: false,
  rent_price_container_month: '',
  security_group: null,
  surcharge: '',
  transport_price_piece: '',
  with_estimated_time_of_arrival: false,
  with_top: false,
  valid_from: '',
  valid_until: '',
  zipcodes: [],
}

// This is the default state for the auto offers filter
export const filterInitialState = {
  communities: [],
  zipcodes: [],
}

export const initialState = {
  filter: filterInitialState, // filter for auto offers are the same as offers,
  item: itemInitialState,
  myItemList: [], // The auto offers that were created by my company
  updatedItem: null,
  autoOfferList: {
    data: [],
    pagination: {
      count: 0,
      next: 0,
      current: 0,
      previous: 0,
      total_results: 0,
      loaded: false,
    },
  },
}

/**
 * @description A reducer for auto offers.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
export const autoOfferReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_AUTO_OFFER_SUCCESS:
      return {
        ...state,
        updatedItem: action.payload,
        myItemList: [action.payload, ...state.myItemList],
      }
    case ActionTypes.UPDATE_AUTO_OFFER_SUCCESS:
      return {
        ...state,
        updatedItem: action.payload,
        myItemList: replaceObjectInArray(action.payload, state.myItemList),
        autoOfferList: {
          data: replaceObjectInArray(action.payload, state.autoOfferList.data),
          pagination: { loaded: true, ...action.payload.pagination },
        },
      }
    case ActionTypes.GET_MY_AUTO_OFFERS_SUCCESS:
      return {
        ...state,
        autoOfferList: {
          data: action.payload.data,
          pagination: { loaded: true, ...action.payload.pagination },
        },
      }
    case ActionTypes.GET_AUTO_OFFER_SUCCESS:
      return {
        ...state,
        item: action.payload,
      }
    case ActionTypes.UPLOAD_AUTO_OFFERS_SUCCESS: {
      // Get items to update
      const itemsToUpdate = action.payload.filter(item =>
        (state.myItemList as AutoOffer[])
          .map(_item => _item.id)
          .includes(item.id),
      )
      // Remove items to update from items to add
      const itemsToAdd = action.payload.filter(
        item => !itemsToUpdate.map(_item => _item.id).includes(item.id),
      )
      // Remove items to update from original values
      const newList = (state.myItemList as AutoOffer[]).filter(
        item => !itemsToUpdate.map(_item => _item.id).includes(item.id),
      )

      return {
        ...state,
        myItemList: [...itemsToAdd, ...itemsToUpdate, ...newList],
      }
    }
    case ActionTypes.GET_MY_AUTO_OFFERS_RESET:
    case ActionTypes.GET_MY_AUTO_OFFERS_REQUEST:
      return {
        ...state,
        autoOfferList: initialState.autoOfferList,
      }
    default:
      return state
  }
}
