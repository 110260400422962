export default {
  title: 'Datenschutzerklärung',
  meta: 'Stand 2. Mai 2019',
  /* eslint-disable no-irregular-whitespace */
  text: `
    <h4>1. Allgemeines</h4>
    <p>
    Die unter www.empto.de und allen hierauf ggf. verweisenden weiteren Internetadressen zugängliche
    Internetseite („Website“) wird von der Zentek Services GmbH & Co. KG („Zentek“) betrieben; die Kontaktdaten
    entnehmen Sie bitte dem Impressum. Zentek nimmt den Datenschutz und die diesem Zweck dienenden gesetzlichen
    Verpflichtungen sehr ernst. In dieser Datenschutzerklärung erfahren Sie, welche Daten auf der Website bei der
    Kontaktaufnahme zu Zentek und in unseren Geschäftsabläufen erhoben, verarbeitet, in welcher Weise genutzt
    werden und wie Zentek den Schutz Ihrer Daten gewährleistet.
    <br />
    <br />
    Wir verarbeiten personenbezogene Daten, die wir für die Erfüllung von Verträgen oder zur Durchführung
    vorvertraglicher Maßnahmen von unseren Kunden oder anderen Betroffenen erhalten. Zudem verarbeiten wir -
    soweit für die Erbringung unserer Dienstleistung erforderlich - personenbezogene Daten, die wir aus
    öffentlich zugänglichen Quellen (z.B. Schuldnerverzeichnisse, Handels- und Vereinsregister, Presse, Internet)
    zulässigerweise gewinnen oder die uns von sonstigen Dritten (z.B. Wirtschaftsauskunfteien)
    berechtigt übermittelt werden.
    <br />
    <br />
    Für weiterführende Informationen oder Fragen zu der Verwendung Ihrer Daten, die durch diese
    Datenschutzerklärung nicht beantwortet werden, wenden Sie sich bitte an den Datenschutzbeauftragten:
    <br />
    <br />
    Hans-Ulrich Förster, Zentek Services GmbH & Co. KG, Ettore-Bugatti-Str. 6-14, 51149 Köln
    </p>
   
    <h4>2. Personenbezogene Daten</h4>
    <p>
    Personenbezogene Daten erlauben die Feststellung der Identität des Nutzers dieser Website bzw. des Kunden.
    Hierunter fallen u.a. Name, Vorname, Anschrift, Telefonnummer und E-Mail-Adresse. Die Nutzung unserer Website
    ist grundsätzlich möglich, ohne dass Sie Ihre Identität ganz oder teilweise offenlegen müssen.
    <br />
    <br />
    Erst bei einer vertraglichen oder vorvertraglichen Maßnahme oder der Kontaktaufnahme zu Zentek, z.B. über die
    Website, ist die Offenlegung Ihrer Identität und die Preisgabe personenbezogener Daten erforderlich.
    Ohne die Offenlegung Ihrer Identität und die Preisgabe personenbezogener Daten ist eine Bearbeitung
    Ihrer Anfrage durch Zentek nicht möglich. Es ist deshalb Ihre freie Entscheidung, ob Sie zu Zwecken der
    Kontaktaufnahme mit uns personenbezogene Daten an uns übermitteln
     wollen.
    </p>
 
    <h4>3. Automatische Datenverarbeitung auf der Website</h4>
    <p>
    Die Website wird von einem hierauf spezialisierten Computer („Server“) ausgeliefert, der automatisch bei
    jedem Aufruf der Website Informationen erhebt und in Protokolldateien („Log-Files“) speichert. Hierbei handelt
    es sich um (1) den verwendeten Browser einschließlich der Versionsnummer, (2) das verwendete Betriebssystem,
    (3) die Adresse der von Ihnen zuvor besuchten Internetseite („Referrer URL“), (4) die Adresse des Computers,
    mit der Sie auf die Website zugegriffen haben („IP-Adresse“) sowie (5) Uhrzeit und Datum des Abrufs dieser
    Website.
    <br />
    <br />
    Die vorgenannten Informationen werden von Ihrem Browser übermittelt, sofern Sie dies nicht durch eine
    abweichende Konfiguration Ihres Browsers oder eine von Ihnen eingesetzte Sicherheitssoftware unterbinden.
    Zentek kann die in den Log-Files vom Server gespeicherten Daten einer bestimmten Person nicht zuordnen.
    Es erfolgt auch keine Zusammenführung der Daten in den Log-Files des Servers mit anderen Datenquellen, die
    bei uns ggf. zur Verfügung stehen.
    </p>
   
    <h4>4. Verwendung Ihrer Daten</h4>
    <p>
    Wir verarbeiten personenbezogene Daten im Einklang mit den Bestimmungen der EU-Datenschutz-Grundverordnung
    (DS-GVO) und dem Bundesdatenschutzgesetz (BDSG) in folgenden Fällen:
    <br />
    <br />
    <ul class='uk-list uk-list-bullet'>
      <li>
      nach Ihrer Einwilligung
      </li>
      <li>
      aufgrund eines Vertrages oder zur Durchführung vorvertraglicher Maßnahmen (ggf. auf Anfrage)
      </li>
      <li>
      die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung erforderlich
      </li>
      <li>
      die Verarbeitung ist erforderlich, um lebenswichtige Interessen der betroffenen Person oder einer anderen
      natürlichen Person zu schützen
      </li>
      <li>
      die Verarbeitung ist für die Wahrnehmung einer Aufgabe erforderlich, die im öffentlichen Interesse liegt
      </li>
      <li>
      die Verarbeitung ist zur Wahrung unserer berechtigten Interessen oder eines Dritten erforderlich,
      sofern nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person,
      die den Schutz personenbezogener Daten erfordern, überwiegen.
      </li>
    </ul>
    Nehmen Sie über die Website Kontakt zu uns auf, werden die von Ihnen abgefragten personenbezogenen
    Daten ausschließlich zum Zweck der Bearbeitung Ihrer Kontaktaufnahme erhoben, gespeichert und genutzt,
    also insbesondere zur Bearbeitung Ihrer allgemeinen Anfrage an uns über das Kontaktformular,
    Ihrer Anfrage nach einer Registrierung für einen geschützten Zugang oder zur Bearbeitung und Abwicklung
    einer von Ihnen über die Website übermittelten Vertragsanfrage oder eines zuvor abgeschlossenen Vertrags.
    <br />
    <br />
    Auch Ihre E-Mail-Adresse verwenden wir ausschließlich zu dem Ihrer Kontaktaufnahme über die Website
    dienenden Zweck. Nur falls dies von Ihnen ausdrücklich gewünscht wird, übermitteln wir an Sie im Rahmen
    der von Ihnen erteilten Einwilligung auch über den Zweck der Kontaktaufnahme hinausgehende E-Mails mit
    allgemeinen Informationen und Werbung an Sie. Ihre Einwilligung in diese weitergehende Verwendung Ihrer
    E-Mail-Adresse können Sie selbstverständlich jederzeit per E-Mail oder schriftlich an die im Impressum
    genannte Anschrift widerrufen, worauf wir Sie auch in jeder E-Mail mit allgemeinen Informationen oder
    werblichem Inhalt gesondert hinweisen werden.
    </p>
   
    <h4>5. Weitergabe der Daten</h4>
    <p>
    Unsere Mitarbeiter erhalten Zugriff auf Ihre Daten, wenn sie sie zur Erfüllung vertraglicher und
    gesetzlicher Pflichten brauchen. Auch von uns eingesetzte Dienstleister und Erfüllungsgehilfen können
    zu diesen Zwecken Daten erhalten, wenn insoweit die Datenschutzbestimmungen gewahrt werden.
    Dies sind Unternehmen in den Kategorien Entsorgung, Entsorgungs-Dienstleistung, Beratung sowie Vertrieb
    und Marketing.
    <br />
    <br />
    Im Hinblick auf die Datenweitergabe an Empfänger außerhalb der Zentek ist zu beachten, dass Daten
    grundsätzlich nur weitergegeben werden, wenn gesetzliche Bestimmungen dies vorschreiben, ein
    Auftragsverarbeitungsverhältnis oder eine Einwilligung vorliegt.
    <br />
    <br />
    Eine Übermittlung Ihrer Daten in
    ein Drittland oder an eine internationale Organisation erfolgt nach den gesetzlichen Bestimmungen.
    Der Umfang der Übermittlung ist beschränkt auf das zur Zweckerreichung erforderliche Minimum.
    </p>
   
    <h4>6. Speicherdauer</h4>
    <p>
    Wir verarbeiten und speichern Ihre personenbezogenen Daten, solange dies für die Erfüllung unserer
    vertraglichen und gesetzlichen Pflichten (handels- und steuerrechtliche Aufbewahrungspflichten - in der
    Regel zwei bis zehn Jahre -, gesetzliche Verjährungsvorschriften – 3 bis 30 Jahre -) erforderlich ist. 
    Danach werden Daten regelmäßig gelöscht.
    </p>
   
    <h4>7. Erstellung von Nutzungsprofilen</h4>
    <p>
    Um die Website zu verbessern sowie zu Zwecken der Werbung und zur Marktforschung analysieren wir das
    Verhalten der Nutzer dieser Website. Zu diesem Zweck erstellt Zentek anonymisierte Nutzungsprofile,
    sofern Sie dem nicht widersprechen. Zentek stellt sicher, dass die anonymisierten Nutzungsprofile
    nicht mit Ihren personenbezogenen Daten zusammengeführt werden. Der Widerruf gegen die Erstellung
    anonymisierter Nutzungsprofile kann per E-Mail oder schriftlich an die im Impressum genannte Person
    gerichtet werden.
    </p>
   
    <h4>8. Datenschutzrechte</h4>
    <p>
    Wir erteilen Ihnen jederzeit unentgeltlich und unverzüglich gemäß den gesetzlichen Bestimmungen Auskunft
    über die zu Ihnen durch uns gespeicherten personenbezogenen Daten. Wenden Sie sich zu diesem Zweck bitte
    an die im Impressum genannten Personen oder an den Datenschutzbeauftragten.
    <br />
    <br />
    Jede betroffene Person hat außerdem das Recht auf Berichtigung, das Recht auf Löschung, das Recht auf
    Einschränkung der Verarbeitung, das Recht auf einzelfallbezogenen Widerspruch, das Recht auf Widerspruch
    gegen eine Verarbeitung von Daten für Zwecke der Direktwerbung sowie das Recht auf Datenübertragbarkeit.
    <br />
    <br />
    Betroffene Personen können jederzeit Beschwerden bei einer zuständigen Datenschutzaufsichtsbehörde vorbringen.
    <br />
    <br />
    Eine erteilte Einwilligung in die Verarbeitung personenbezogener Daten kann jederzeit uns gegenüber
    widerrufen werden. Dies gilt auch für den Widerruf von Einwilligungserklärungen, die vor der Geltung
    der DSGVO, also vor dem 25. Mai 2018, uns gegenüber erteilt worden sind.
    </p>
   
    <h4>9. Hinweis zu Google Analytics</h4>
    <p>
    Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“).
    Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden
    und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den
    Cookie erzeugten Informationen über Ihre Benutzung dieser Website werden in der Regel an einen Server
    von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der
    IP-Anonymisierung auf dieser Webseite, wird Ihre IP-Adresse von Google jedoch innerhalb von
    Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den
    Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an
    einen Server von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
    Website wird Google diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
    Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der
    Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu erbringen.
    Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen
    Daten von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende
    Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem
    Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
    Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
    Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch
    Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen
    und installieren. Der aktuelle Link ist 
    <a href="https://tools.google.com/dlpage/gaoptout">tools.google.com/dlpage/gaoptout</a>.
    <br />
    <br />
    IP-Adressen werden durch Einsatz von anonymizeIP nur gekürzt übermittelt und sind daher anonym.
    </p>
    `,
}
