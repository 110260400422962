import './style.scss'

import { node, oneOfType, string } from 'prop-types'
import React from 'react'

import WasteTypeRulesCardComponent, {
  ICON_COLOR,
} from './components/WasteTypeRulesCard'

/**
 * @description This component renders and controls a waste type header component.
 * @function
 * @param {Object} props the component props
 */
const WasteTypeRules = props => (
  <div className='waste-type-rules'>
    <WasteTypeRulesCardComponent
      title='Erlaubt'
      icon='checkmark'
      content={props.allowedContent}
    />
    <WasteTypeRulesCardComponent
      title='Verboten'
      icon='cross'
      content={props.forbiddenContent}
      iconColor={ICON_COLOR.RED}
    />
  </div>
)

WasteTypeRules.propTypes = {
  allowedContent: oneOfType([string, node]),
  forbiddenContent: oneOfType([string, node]),
}

WasteTypeRules.defaultProps = {
  allowedContent: '',
  forbiddenContent: '',
}

export default WasteTypeRules
