import uniqueId from 'lodash.uniqueid'
import React from 'react'
import { I18n } from 'react-i18nify'

import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'

import { CancelOfferForm } from './CancelOfferForm'

export const CancelOfferModal = ({
  callback,
  isAgreement,
  offer,
  state,
  user,
}) => {
  const cancelOfferModalId = uniqueId() // from lodash package. Generate a unique ID

  return (
    <Modal
      ariaDescribedBy={cancelOfferModalId}
      isOpen={state}
      onClose={callback}
    >
      <ModalHeader
        onClose={callback}
        title={
          isAgreement
            ? I18n.t('offerForm.cancelOffer.buttonCancelAgreement')
            : I18n.t('offerForm.cancelOffer.button')
        }
        titleId={cancelOfferModalId}
      />
      <CancelOfferForm
        isAgreement={isAgreement}
        offer={offer}
        onClose={callback}
        user={user}
      />
    </Modal>
  )
}
