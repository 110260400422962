import React, { Component } from 'react'
import { I18n } from 'react-i18nify'
import { bool, shape } from 'prop-types'

import DetailRow from 'components/common/DetailSwitcher/components/DetailRow'
import DetailSection from 'components/common/DetailSwitcher/components/DetailSection'
import { COMPANY_ROLES } from 'components/company/constants'
import { getName } from 'helper/translations'
import { OrderScheme } from 'schemes/order'

/**
 * @description This component renders the parties component.
 */
export class PartiesComponent extends Component {
  render() {
    const { order, showAccordion } = this.props
    return (
      <>
        <DetailSection
          title={I18n.t('detailsSwitcher.parties')}
          icon={`${showAccordion ? 'user' : ''}`}
          showAccordion={showAccordion}
        >
          <DetailRow
            label={I18n.t('detailsSwitcher.aez')}
            value={order.offer_object.customer_company_object.name}
            path={`/company/${order.offer_object.customer_company_object.id}`}
          />
          <DetailRow
            label={I18n.t('detailsSwitcher.disposer')}
            value={order.offer_object.company_object.name}
            path={`/company/${order.offer_object.company_object.id}`}
          />
        </DetailSection>

        <div
          className={`uk-accordion-content ${
            !showAccordion ? 'uk-hidden' : ''
          }`}
        >
          <DetailSection title={I18n.t('detailsSwitcher.aez')}>
            <DetailRow
              label={I18n.t('general.name')}
              value={order.offer_object.customer_company_object.name}
              path={`/company/${order.offer_object.customer_company_object.id}`}
            />
            <DetailRow
              label={I18n.t('inquiryDetails.company.role')}
              value={I18n.t(
                getName(
                  order.offer_object.customer_company_object.role,
                  COMPANY_ROLES,
                ),
              )}
            />
          </DetailSection>
          <br />
          <DetailSection title={I18n.t('detailsSwitcher.disposer')}>
            <DetailRow
              label={I18n.t('general.name')}
              value={order.offer_object.company_object.name}
              path={`/company/${order.offer_object.company_object.id}`}
            />
            <DetailRow
              label={I18n.t('inquiryDetails.company.role')}
              value={I18n.t(
                getName(order.offer_object.company_object.role, COMPANY_ROLES),
              )}
            />
          </DetailSection>
        </div>
      </>
    )
  }
}

PartiesComponent.propTypes = {
  order: shape(OrderScheme).isRequired,
  showAccordion: bool.isRequired,
}

export default PartiesComponent
