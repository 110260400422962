import './style.scss'

import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { Link } from 'react-router-dom'
import ReactTable, { Column } from 'react-table'

import Icon, { ICON_NAME } from 'components/common/Fontello'
import { UpDownArrow } from 'components/common/UpDownArrow'

import { CommunicationCenterPageContext } from '../../CommunicationCenterPage'

interface interactiveTableBodyProps {
  data?: ComCenterContact[]
  loading?: boolean
  pageSize?: number
  title?: string | React.ReactNode
  notice?: string | React.ReactNode
  id?: string
  columns?: Column<ComCenterContact>[]
  onEditButtonClick?: (comCenterContactId: number) => void
  onDeleteButtonClick?: (comCenterContactId: number) => void
}

export const InteractiveTableBody: FC<interactiveTableBodyProps> = ({
  data = [],
  loading = false,
  pageSize = 0,
  title,
  notice,
  id,
  columns = [],
  onEditButtonClick,
  onDeleteButtonClick,
}) => {
  const defaultColumns = [
    {
      id: 'id',
      Header: (
        <>
          <Translate value='communicationCenterPageTranslations.columns.id' />
          <UpDownArrow />
        </>
      ),
      width: 75,
      accessor: data => data.id,
    },
    {
      id: 'email',
      Header: (
        <>
          <Translate value='communicationCenterPageTranslations.columns.email' />
          <UpDownArrow />
        </>
      ),
      width: 300,
      Cell: data => {
        return (
          <a href={`mailto:${data.original.email}`}>{data.original.email}</a>
        )
      },
      headerStyle: { padding: '0' },
    },
    {
      id: 'type',
      Header: (
        <>
          <Translate value='communicationCenterPageTranslations.columns.email_type' />
          <UpDownArrow />
        </>
      ),
      width: 125,
      accessor: data => data.type,
    },
    {
      id: 'name',
      Header: (
        <>
          <Translate value='communicationCenterPageTranslations.columns.name' />
          <UpDownArrow />
        </>
      ),
      minWidth: 300,
      accessor: data => data.name,
    },
  ]

  const actionColumn = [
    {
      id: 'actions',
      Header: (
        <Translate value='communicationCenterPageTranslations.columns.actions' />
      ),
      Cell: data => (
        <CommunicationCenterPageContext.Consumer>
          {context => (
            <div className='communicationcenter-interactive-table-body__action-icons'>
              <Link
                to='/'
                onClick={event => {
                  // onEditButtonClick is a prop and overwrites the
                  // default behavior in the page context if set
                  if (onEditButtonClick) {
                    onEditButtonClick(data.original.id)
                  } else {
                    context.onEditButtonClick(data.original.id)
                  }

                  event.preventDefault()
                }}
              >
                <Icon
                  name={ICON_NAME.PENCIL}
                  className='communicationcenter-interactive-table-body__icon'
                />
              </Link>
              <Link
                to='/'
                onClick={event => {
                  // onDeleteButtonClick is a prop and overwrites the
                  // default behavior in the page context if set
                  if (onDeleteButtonClick) {
                    onDeleteButtonClick(data.original.id)
                  } else {
                    context.onDeleteButtonClick(data.original.id)
                  }

                  event.preventDefault()
                }}
              >
                <Icon
                  name={ICON_NAME.TRASH}
                  className='communicationcenter-interactive-table-body__icon'
                />
              </Link>
            </div>
          )}
        </CommunicationCenterPageContext.Consumer>
      ),
      className: 'communicationcenter-interactive-table-body__action-column',
      headerClassName:
        'communicationcenter-interactive-table-body__action-column',
      filterable: false,
      sortable: false,
      resizable: false,
    },
  ]

  const combinedColumns =
    columns.length > 0
      ? [...columns, ...actionColumn]
      : [...defaultColumns, ...actionColumn]

  return (
    <div
      className='communicationcenter-interactive-table-body communicationcenter-interactive-table-body--blue'
      id={id}
    >
      {(title || notice) && (
        <div className='communicationcenter-interactive-table-body__title-bar'>
          {title && (
            <span className='communicationcenter-interactive-table-body__title'>
              {title}
            </span>
          )}
          {notice && (
            <span className='communicationcenter-interactive-table-body__notice'>
              {notice}
            </span>
          )}
        </div>
      )}
      {data.length > 0 ? (
        <ReactTable
          columns={combinedColumns}
          data={data}
          noDataText={I18n.t(
            'communicationCenterPageTranslations.noEntriesAvailable',
          )}
          defaultSorted={[{ id: 'email', desc: true }]}
          // Forces table not to paginate or sort automatically, so we can handle it server-side
          manual
          minRows={0}
          loading={loading}
          defaultPageSize={pageSize}
          className={
            'table -highlight communicationcenter-interactive-table-body__table'
          }
          showPagination={false}
          showPageSizeOptions={false}
        />
      ) : (
        <div className='communicationcenter-interactive-table-body__no-entries'>
          <Translate
            value='communicationCenterPageTranslations.noEntriesAvailable'
            className=''
          />
        </div>
      )}
    </div>
  )
}
