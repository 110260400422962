export const PROOF_TYPE = {
  EXECUTION_PROOF: 1,
  CERTIFICATE: 2,
  CLAIM_PROOF: 3,
  WORKLOAD: 4,
  MY_CERTIFICATE: 5,
  INVOICE_CHECK: 6,
}

export default {
  ...PROOF_TYPE,
}
