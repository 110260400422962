import './style.scss'

import { Formik } from 'formik'
import React, { FC } from 'react'
import * as yup from 'yup'

import withErrorBoundary from 'helper/withErrorBoundary'

import { FilterForm } from './FilterForm'
import { FilterProps } from './types'

export const FilterComponent: FC<FilterProps> = ({
  addressList = [],
  children,
  companyList = [],
  containerList = [],
  fractionList = [],
  isLoading = false,
  shouldResetForm = false,
  length = 0,
  status = '',
  initialValues = {},
  customerList = [],
  partnerList = [],
}) => (
  <Formik
    initialValues={{
      address: '',
      avv: '',
      coarse_fraction: '',
      collection_address: '',
      communities: '',
      company: '',
      container: '',
      empto_external_number: '',
      delivery_date: '',
      direction: 'asc',
      document_number: '',
      id: '',
      occasion: '',
      order: '',
      order_id: '',
      order_type: '',
      price: 'price',
      radius: '',
      status,
      type: '',
    }}
    validationSchema={() =>
      yup.object().shape({
        avv: yup.string(),
        coarse_fraction: yup.string(),
        collection_address: yup.string(),
        communities: yup.string(),
        company: yup.string(),
        customer_company: yup.string(),
        container: yup.string(),
        empto_external_number: yup.string(),
        delivery_date__lte: yup.string(),
        document_number: yup.string(),
        id: yup.string(),
        occasion: yup.string(),
        order: yup.string(),
        order_type: yup.string(),
        radius: yup.string(),
        status: yup.string(),
        type: yup.string(),
      })
    }
    onSubmit={() => undefined}
  >
    <FilterForm
      addressList={addressList}
      companyList={companyList}
      containerList={containerList}
      fractionList={fractionList}
      isLoading={isLoading}
      initialValues={initialValues}
      shouldResetForm={shouldResetForm}
      length={length}
      customerList={customerList}
      partnerList={partnerList}
    >
      {children}
    </FilterForm>
  </Formik>
)

export const Filter = withErrorBoundary(FilterComponent)
