export const setPasswordFormTranslation = {
  password: {
    label: 'Wählen Sie ein Passwort',
    notSecure:
      'Mindestens 14 Zeichen: Groß- sowie Kleinbuchstaben, Ziffern und Sonderzeichen.',
    placeholder: 'Wählen Sie ein Passwort.',
    required: 'Bitte geben Sie Ihr Passwort ein.',
  },
  repeatPassword: {
    label: 'Passwort wiederholen',
    notEqual: 'Die Passwörter müssen übereinstimmen.',
    placeholder: 'Wiederholen Sie Ihr Passwort.',
    required: 'Bitte holen Sie Ihr neue Passwort wieder.',
  },
  submit: 'Registrierung abschließen',
}

export default {}
