import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import AppActionTypes from '../actiontypes/app'
import DiscountActionTypes from '../actiontypes/discount'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetDiscounts
 * @description Loads all discounts.
 */
const handleGetDiscounts = createLogic({
  type: DiscountActionTypes.GET_DISCOUNTS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload) {
      data = { page: action.payload.page || 1 }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }

      if (action.payload.sortedBy && action.payload.sortedBy.length) {
        data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
      }
    }

    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/discount/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: DiscountActionTypes.GET_DISCOUNTS_SUCCESS,
          payload: res,
        })
        dispatch({
          type: AppActionTypes.RESET_API_FETCH_ERRORS,
          payload: 'GET_DISCOUNTS',
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.discount.handleGetDiscounts.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: DiscountActionTypes.GET_DISCOUNTS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetDiscount
 * @description get a discount.
 */
const handleGetDiscount = createLogic({
  type: DiscountActionTypes.GET_DISCOUNT_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/discount/${action.payload.id}/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: DiscountActionTypes.GET_DISCOUNT_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.discount.handleGetDiscounts.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: DiscountActionTypes.GET_DISCOUNT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateDiscount
 * @description creates discount.
 */
const handleCreateDiscount = createLogic({
  type: DiscountActionTypes.CREATE_DISCOUNT_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/discount/`,
        action.payload.discount,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: DiscountActionTypes.CREATE_DISCOUNT_SUCCESS,
          payload: res,
        })

        if (action.payload.callback) {
          action.payload.callback.success()
        }
        toastr.success('', I18n.t('api.discount.handleCreateDiscount.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.discount.handleCreateDiscount.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: DiscountActionTypes.CREATE_DISCOUNT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleRedeemDiscount
 * @description redeem discount.
 */
const handleRedeemDiscount = createLogic({
  type: DiscountActionTypes.REDEEM_DISCOUNT_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/discount/redeem/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: DiscountActionTypes.REDEEM_DISCOUNT_SUCCESS,
          payload: res,
        })

        if (action.payload.callback) {
          action.payload.callback.success()
        }

        toastr.success('', I18n.t('api.discount.handleRedeemDiscount.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.discount.handleRedeemDiscount.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: DiscountActionTypes.REDEEM_DISCOUNT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateDiscountStatus
 * @description updates discount status.
 */
const handleUpdateDiscountStatus = createLogic({
  type: DiscountActionTypes.UPDATE_DISCOUNT_STATUS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .patch(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/discount/${action.payload.id}/update-status/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: DiscountActionTypes.UPDATE_DISCOUNT_STATUS_SUCCESS,
          payload: res,
        })

        toastr.success(
          '',
          I18n.t('api.discount.handleUpdateDiscountStatus.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.discount.handleUpdateDiscountStatus.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: DiscountActionTypes.UPDATE_DISCOUNT_STATUS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportRedeemedDiscounts
 * @description triggers the redeemed discounts export.
 */
export const handleExportRedeemedDiscounts = createLogic({
  type: DiscountActionTypes.EXPORT_REDEEMED_DISCOUNTS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { filters } = action.payload
    let data = {}

    if (filters) {
      data = { ...data, filters: JSON.stringify(filters) }
    }
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/discount/export/`, data)
      .then(() => {
        toastr.success('', I18n.t('api.discount.handleExportDiscounts.success'))
        dispatch({
          type: DiscountActionTypes.EXPORT_REDEEMED_DISCOUNTS_SUCCESS,
        })
      })
      .then(done)
      .catch(err => {
        if (!err.hideToastr)
          toastr.error('', I18n.t('api.discount.handleExportDiscounts.error'))

        done()
      })
  },
})

export default [
  handleGetDiscounts,
  handleGetDiscount,
  handleCreateDiscount,
  handleRedeemDiscount,
  handleUpdateDiscountStatus,
  handleExportRedeemedDiscounts,
]
