import './CookieConsentBanner.scss'

import React, { FC, useEffect } from 'react'
import CookieConsent, { Cookies } from 'react-cookie-consent'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { isSnapshot } from 'helper/snapshots'
import { acceptCookies } from 'actions/app'
import { cookiesAcceptedSelector } from 'selectors/app'

const COOKIE_NAME = 'emptoCookiesAccepted'

export const CookieConsentBanner: FC = () => {
  const dispatch = useDispatch()
  const cookiesAccepted: boolean = useSelector(cookiesAcceptedSelector)

  useEffect(() => {
    if (!cookiesAccepted && Cookies.get(COOKIE_NAME)) {
      dispatch(acceptCookies())
    }
  }, [cookiesAccepted, dispatch])

  return (
    <>
      {!isSnapshot() && (
        <CookieConsent
          buttonText={I18n.t('cookieConsent.banner.acceptButton')}
          buttonWrapperClasses='uk-grid uk-child-width-expand@m uk-child-width-1-2@s uk-margin-auto'
          declineButtonText={I18n.t('cookieConsent.banner.denyButton')}
          cookieName={COOKIE_NAME}
          disableStyles
          location='bottom'
          onAccept={() => dispatch(acceptCookies())}
          containerClasses='cookie-consent-banner'
          buttonClasses='cookie-consent-banner__button uk-button uk-button-primary button--color-warning
            e2e-cookie-consent-banner-button uk-margin-small-top'
          contentClasses='cookie-consent-banner__content'
          declineButtonClasses='cookie-consent-banner__button uk-button uk-button-default button--color-warning
            uk-margin-small-top'
          enableDeclineButton
          setDeclineCookie={false}
          sameSite='strict'
        >
          <Translate
            value='cookieConsent.banner.text'
            privacyLink='/datenschutz'
            dangerousHTML
          />
        </CookieConsent>
      )}
    </>
  )
}
