import { EmptoDownloads } from '../components/general/EmptoDownloads'
import { EmptoInternalDownloads } from '../components/general/EmptoInternalDownloads'
import Imprint from '../components/general/Imprint'
import LegalNotice from '../components/general/LegalNotice'
import Privacy from '../components/general/Privacy'
import { Login } from '../components/user/Login'

/**
 * @constant
 * @description A constant to define general components.
 */
export const generalRoutes = [
  {
    path: '/impressum',
    exact: true,
    public: true,
    component: Imprint,
  },
  {
    path: '/datenschutz',
    exact: true,
    public: true,
    component: Privacy,
  },
  {
    path: '/rechtliche-hinweise',
    exact: true,
    public: true,
    component: LegalNotice,
  },
  {
    path: '/anmelden',
    exact: true,
    publicOnly: true,
    component: Login,
  },
  {
    path: '/downloads',
    component: EmptoDownloads,
    public: true,
    exact: true,
  },
  {
    path: '/internaldownloads',
    component: EmptoInternalDownloads,
    exact: true,
  },
]

export default {}
