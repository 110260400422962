export const DOCUMENT_TYPE = {
  INVOICE: 1,
  CREDIT_NOTE: 2,
  CANCELLATION_INVOICE: 3,
  CANCELLATION_CREDIT_NOTE: 4,
}

export const DOCUMENT_TYPES = [
  {
    id: DOCUMENT_TYPE.INVOICE,
    name: 'invoice.documentFilter.isInvoice',
  },
  {
    id: DOCUMENT_TYPE.CREDIT_NOTE,
    name: 'invoice.documentFilter.isCreditNote',
  },
  {
    id: DOCUMENT_TYPE.CANCELLATION_INVOICE,
    name: 'invoice.documentFilter.isCancellationInvoice',
  },
  {
    id: DOCUMENT_TYPE.CANCELLATION_CREDIT_NOTE,
    name: 'invoice.documentFilter.isCancellationCreditNote',
  },
]

export const DOCUMENT_STATUS_TYPE = {
  OPEN: 1,
  PAID: 2,
  PAYMENT_REMINDER: 3,
  OVERDUE_WARNING: 4,
  CREDITREFORM: 5,
  CANCELLED: 6,
  DONE: 7,
  DATEV: 8,
  DSZ_OPEN: 'open',
  DSZ_PAID: 'paid',
  DSZ_CONFIRMATION_SENT: 'confirmation sent',
  DSZ_CANCELLED: 'cancelled',
}

export const EPD_DOCUMENT_STATUS_TYPES = [
  {
    id: DOCUMENT_STATUS_TYPE.OPEN,
    name: 'invoice.documentPaymentStatus.open',
  },
  {
    id: DOCUMENT_STATUS_TYPE.PAID,
    name: 'invoice.documentPaymentStatus.paid',
  },
  {
    id: DOCUMENT_STATUS_TYPE.PAYMENT_REMINDER,
    name: 'invoice.documentPaymentStatus.paymentReminder',
  },
  {
    id: DOCUMENT_STATUS_TYPE.OVERDUE_WARNING,
    name: 'invoice.documentPaymentStatus.overdueWarning',
  },
  {
    id: DOCUMENT_STATUS_TYPE.CREDITREFORM,
    name: 'invoice.documentPaymentStatus.creditreform',
  },
  {
    id: DOCUMENT_STATUS_TYPE.CANCELLED,
    name: 'invoice.documentPaymentStatus.cancelled',
  },
  {
    id: DOCUMENT_STATUS_TYPE.DONE,
    name: 'invoice.documentPaymentStatus.done',
  },
  {
    id: DOCUMENT_STATUS_TYPE.DATEV,
    name: 'invoice.documentPaymentStatus.datev',
  },
]

export const DSZ_DOCUMENT_STATUS_TYPES = [
  {
    id: DOCUMENT_STATUS_TYPE.DSZ_OPEN,
    name: 'invoice.documentPaymentStatus.open',
  },
  {
    id: DOCUMENT_STATUS_TYPE.DSZ_CONFIRMATION_SENT,
    name: 'invoice.documentPaymentStatus.confirmationSent',
  },
  {
    id: DOCUMENT_STATUS_TYPE.DSZ_PAID,
    name: 'invoice.documentPaymentStatus.paid',
  },
  {
    id: DOCUMENT_STATUS_TYPE.DSZ_CANCELLED,
    name: 'invoice.documentPaymentStatus.cancelled',
  },
]

export const DOCUMENT_STATUS_TYPES = [
  ...EPD_DOCUMENT_STATUS_TYPES,
  ...DSZ_DOCUMENT_STATUS_TYPES,
]

export const INVOICE_TYPE = {
  DOCUMENT_TYPE_FINAL: 1,
  DOCUMENT_TYPE_DEADHEAD: 2,
  DOCUMENT_TYPE_WAITING_TIME: 3,
}

export const DOCUMENT_CANCELLATION_REASON = {
  CANCEL_WRONG_PRICES: 1,
  CANCEL_WRONG_NUMBER: 2,
  CANCEL_WRONG_WEIGHT: 3,
  CANCEL_WRONG_INVOICE_RECIPIENT: 4,
  CANCEL_OTHER: 5,
  CANCEL_WITHDRAWAL: 6,
}

export const DOCUMENT_CANCELLATION_REASONS = [
  {
    id: DOCUMENT_CANCELLATION_REASON.CANCEL_WRONG_PRICES,
    name: 'invoice.documentCancelReason.wrong_prices',
  },
  {
    id: DOCUMENT_CANCELLATION_REASON.CANCEL_WRONG_NUMBER,
    name: 'invoice.documentCancelReason.wrong_number',
  },
  {
    id: DOCUMENT_CANCELLATION_REASON.CANCEL_WRONG_WEIGHT,
    name: 'invoice.documentCancelReason.wrong_weight',
  },
  {
    id: DOCUMENT_CANCELLATION_REASON.CANCEL_WRONG_INVOICE_RECIPIENT,
    name: 'invoice.documentCancelReason.wrong_invoice_recipient',
  },
  {
    id: DOCUMENT_CANCELLATION_REASON.CANCEL_OTHER,
    name: 'invoice.documentCancelReason.other',
  },
  {
    id: DOCUMENT_CANCELLATION_REASON.CANCEL_WITHDRAWAL,
    name: 'invoice.documentCancelReason.withdrawal',
  },
]

export const DOCUMENT_CANCELLATION_REASON_DSZ = {
  CANCEL_WRONG_PRICES: 1,
  CANCEL_WRONG_NUMBER: 2,
  CANCEL_WRONG_WEIGHT: 3,
  CANCEL_WRONG_INVOICE_RECIPIENT: 4,
  CANCEL_OTHER: 5,
  CANCEL_WITHDRAWAL: 6,
}

export const DOCUMENT_CANCELLATION_REASONS_DSZ = [
  {
    id: DOCUMENT_CANCELLATION_REASON_DSZ.CANCEL_WRONG_PRICES,
    name: 'invoice.documentCancelReason.wrong_prices',
  },
  {
    id: DOCUMENT_CANCELLATION_REASON_DSZ.CANCEL_WRONG_NUMBER,
    name: 'invoice.documentCancelReason.wrong_number',
  },
  {
    id: DOCUMENT_CANCELLATION_REASON_DSZ.CANCEL_WRONG_WEIGHT,
    name: 'invoice.documentCancelReason.wrong_weight',
  },
  {
    id: DOCUMENT_CANCELLATION_REASON_DSZ.CANCEL_WRONG_INVOICE_RECIPIENT,
    name: 'invoice.documentCancelReason.wrong_invoice_recipient',
  },
  {
    id: DOCUMENT_CANCELLATION_REASON_DSZ.CANCEL_OTHER,
    name: 'invoice.documentCancelReason.other',
  },
  {
    id: DOCUMENT_CANCELLATION_REASON_DSZ.CANCEL_WITHDRAWAL,
    name: 'invoice.documentCancelReason.withdrawal',
  },
]

export const INVOICE_DUNNING_REASON = {
  WRONG_INVOICE: 1,
  CUSTOMER_COMPLAINT: 2,
  CANCEL_OTHER: 3,
}

export const INVOICE_DUNNING_REASONS = [
  {
    id: INVOICE_DUNNING_REASON.WRONG_INVOICE,
    name: 'invoice.invoiceDunningReason.wrong_invoice',
  },
  {
    id: INVOICE_DUNNING_REASON.CUSTOMER_COMPLAINT,
    name: 'invoice.invoiceDunningReason.customer_complaint',
  },
  {
    id: INVOICE_DUNNING_REASON.CANCEL_OTHER,
    name: 'invoice.invoiceDunningReason.other',
  },
]

export const DOCUMENT_UNPAID_REASON = {
  CUSTOMER_REFUND: 1,
  ACCIDENTALLY_MARKED_PAID: 2,
  OTHER: 3,
}

export const DOCUMENT_UNPAID_REASONS = [
  {
    id: DOCUMENT_UNPAID_REASON.CUSTOMER_REFUND,
    name: 'invoice.documentUnpaidReason.customer_refund',
  },
  {
    id: DOCUMENT_UNPAID_REASON.ACCIDENTALLY_MARKED_PAID,
    name: 'invoice.documentUnpaidReason.accidentally_marked_paid',
  },
  {
    id: DOCUMENT_UNPAID_REASON.OTHER,
    name: 'invoice.documentUnpaidReason.other',
  },
]

export const COLLECTIVE_INVOICING_STATUS_TYPE = {
  SUCCESS: 'Erfolgreich',
  ERROR: 'Fehlgeschlagen',
  RUNNING: 'läuft...',
}

export const COLLECTIVE_INVOICING_STATUS_TYPES = [
  {
    id: COLLECTIVE_INVOICING_STATUS_TYPE.SUCCESS,
    name: 'taskLog.status.success',
  },
  {
    id: COLLECTIVE_INVOICING_STATUS_TYPE.RUNNING,
    name: 'taskLog.status.running',
  },
  {
    id: COLLECTIVE_INVOICING_STATUS_TYPE.ERROR,
    name: 'taskLog.status.error',
  },
]
