import './Switcher.scss'

import classNames from 'classnames'
import React, { FC, MouseEvent, useState } from 'react'
import { I18n } from 'react-i18nify'

interface SwitcherValue {
  id: number
  name: string
}

interface SwitcherProps {
  className?: string
  currentValue: SwitcherValue
  disabled?: boolean
  isActive: boolean
  onValueChange: (value: SwitcherValue) => void
  testId?: string
  values: SwitcherValue[]
}

export const Switcher: FC<SwitcherProps> = ({
  className,
  currentValue,
  disabled = false,
  isActive,
  onValueChange,
  testId,
  values,
}) => {
  const [animating, setAnimating] = useState<boolean>(false)

  const handleToggle = (event: MouseEvent<HTMLDivElement>): void => {
    event.preventDefault()
    const newValue = values.find(value => value.id !== currentValue.id)

    if (!animating && !disabled && newValue) {
      onValueChange(newValue)
      setAnimating(true)
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={classNames(
        {
          switcher: true,
          'switcher--active': isActive,
          'switcher--inactive': !isActive,
          'switcher--disabled': disabled,
        },
        className,
      )}
      onClick={handleToggle}
      role='switch'
      aria-checked={isActive}
      aria-disabled={disabled}
      aria-label={
        currentValue && currentValue.name && I18n.t(currentValue.name)
      }
      tabIndex={disabled ? -1 : 0}
      data-testid={testId}
    >
      <div
        className='switcher__status'
        onAnimationEnd={() => setAnimating(false)}
        style={{ animationName: animating ? undefined : 'none' }}
      >
        <span className='switcher__label'>
          {currentValue && currentValue.name && I18n.t(currentValue.name)}
        </span>
      </div>
    </div>
  )
}
