import { shape, func, any, arrayOf, string, bool } from 'prop-types'

import { ControlProps } from '../../schemes'

const ActionsScheme = shape({
  form: shape({
    reset: func,
  }),
})

export const SelectOptionScheme = shape({
  id: any.isRequired,
  label: any.isRequired,
})

const SelectControlProps = {
  ...ControlProps,
  actions: ActionsScheme.isRequired,
  options: arrayOf(SelectOptionScheme),
  addOptionLabel: string,
  enablePlaceholder: bool, // Whether the placeholder is hidden
  parser: func,
  isBoolean: bool,
  onChange: func,
  onAdd: func,
  isLoading: bool, // Whether the loading animation is shown or not
  dataTestId: string,
}

export default SelectControlProps
