import { useFormikContext } from 'formik'
import React, { FC, useContext, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getCompany, resetCustomInvoiceCompanies } from 'actions/company'
import { DropDownInput } from 'components/common/DropDownInput'
import { FindCompanyByFilterInput } from 'components/common/FindByFilterInput'
import { FindCompanyByFilterInputActions } from 'components/common/FindByFilterInput/FindCompanyByFilterInput'
import {
  FormStepSummaryRow,
  GoToNextFormStepButton,
} from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { getCompanySelector } from 'selectors/company'

import {
  DSZAddInvoiceFormContext,
  DSZAddInvoiceFormValues,
} from '../DSZAddInvoiceFormSteps'

export const DSZInvoiceReceiverForm: FC = () => {
  const { errors, handleBlur, handleChange, setValues, setFieldValue, values } =
    useFormikContext<DSZAddInvoiceFormValues>()

  const dispatch = useDispatch()
  const company = useSelector(getCompanySelector)

  const { stepStatus, saveStep, isInvoice, toggleIsInvoice } = useContext(
    DSZAddInvoiceFormContext,
  )

  useEffect(() => {
    setFieldValue('selected_company', company)
    setFieldValue('company_name', company.name)
    setFieldValue('dsz_external_number', company.dsz_external_number)
    setFieldValue('invoice_address', company.main_address_object)
  }, [company, setFieldValue])

  const searchFields = [
    {
      optionLabel: I18n.t(
        'addInvoicePageTranslations.dsz.steps.1.fields.company_name.label',
      ),
      optionValue: 'company_name',
    },
    {
      optionLabel: I18n.t(
        'addInvoicePageTranslations.dsz.steps.1.fields.dsz_external_number.label',
      ),
      optionValue: 'dsz_external_number',
    },
    {
      optionLabel: I18n.t(
        'addInvoicePageTranslations.dsz.steps.1.fields.zipcode.label',
      ),
      optionValue: 'zipcode',
    },
  ]

  const mapCompanyToOption = company => ({
    label: `${company.dsz_external_number ?? 'k.A.'}, ${company.name}, ${
      company.zipcode ?? 'k.A.'
    } ${company.location ?? 'k.A.'}`,
    value: company.id,
  })

  return (
    <>
      {/*SELECT SEARCHFIELD*/}
      <DropDownInput
        choices={searchFields}
        label={I18n.t(
          'addInvoicePageTranslations.dsz.steps.1.fields.search_field.label',
        )}
        name='search_field'
        onChange={e => {
          setValues(
            {
              ...values,
              company_name: '',
              dsz_external_number: undefined,
              zipcode: undefined,
              selected_company: undefined,
            },
            false,
          )
          dispatch(resetCustomInvoiceCompanies()) // Reset filtered results
          handleChange(e)
        }}
        showCheckmark
        value={values.search_field}
        withCheckmark
      />

      {/*SEARCH*/}
      <FindCompanyByFilterInput
        action={FindCompanyByFilterInputActions.CUSTOM_INVOICE_COMPANIES}
        label={I18n.t(
          `addInvoicePageTranslations.dsz.steps.1.fields.${values.search_field}.label`,
        )}
        mapOptionLabel={mapCompanyToOption}
        name={values.search_field}
        noOptionsMessage={I18n.t('general.form.noOptions')}
        placeholder={I18n.t(
          `addInvoicePageTranslations.dsz.steps.1.fields.${values.search_field}.placeholder`,
        )}
        handleBlur={handleBlur}
        handleSelectionChange={(value, companies) => {
          const selectedCompanyOption = companies.find(
            item => item.id === value,
          )
          dispatch(getCompany({ id: value, slug: selectedCompanyOption?.slug }))

          if (!isInvoice) {
            toggleIsInvoice()
          }
        }}
        value={values.selected_company?.id}
        error={errors.selected_company}
        withCheckmark
      />

      {/*SUMMARY FIELDS*/}
      <div className='add-invoice-step-summary'>
        {values.selected_company && (
          <>
            <div className='add-invoice-step-summary_grid-body'>
              <FormStepSummaryRow
                label={I18n.t(
                  'addInvoicePageTranslations.dsz.steps.1.fields.dsz_external_number.label',
                )}
                value={values.dsz_external_number}
              />
              <FormStepSummaryRow
                label={I18n.t(
                  'addInvoicePageTranslations.dsz.steps.1.fields.company_name.label',
                )}
                value={values.company_name}
              />
              <FormStepSummaryRow
                label={I18n.t(
                  'addInvoicePageTranslations.dsz.steps.1.fields.invoice_address.label',
                )}
                value={`${values.invoice_address?.display_name_dsz}`}
              />
              {values.selected_company?.tax_id && (
                <FormStepSummaryRow
                  label={I18n.t(
                    'addInvoicePageTranslations.dsz.steps.1.fields.tax_id.label',
                  )}
                  value={values.selected_company.tax_id}
                />
              )}
            </div>
          </>
        )}
      </div>
      <GoToNextFormStepButton
        buttonText={
          stepStatus === STEP_STATUS.EDITING
            ? I18n.t('general.button.save')
            : I18n.t('createInquiryTranslations.form.button.nextStep')
        }
        onClick={saveStep}
        isDisabled={!values?.selected_company}
      />
    </>
  )
}
