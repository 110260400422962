import { DashboardPage } from '../components/dashboard/DashboardPage'
import { EPDStakeHolderDashboardPage } from '../components/dashboard/MaklerPremium/EPD/EPDStakeHolderDashboardPage'
import { UserPermission } from '../constants/user'

export const dashboardRoutes = [
  {
    path: '/dashboard',
    component: DashboardPage,
  },
  {
    path: '/status',
    component: EPDStakeHolderDashboardPage,
    requiredPermissions: [UserPermission.VIEW_STAKEHOLDER_DASHBOARD_EPD],
  },
]

export default {}
