import { bool, func, string } from 'prop-types'

import { ErrorsScheme } from '../FormGroupError/schemes'

const FormGroupLabelProps = {
  label: string.isRequired,
  clickable: bool,
  errors: ErrorsScheme,
  onClick: func,
  required: bool,
}

export default FormGroupLabelProps
