import './style.scss'

import React, { FC } from 'react'

import Icon, { ICON_NAME } from '../Fontello'

interface InputCheckmarkProps {
  isHidden?: boolean
}

/**
 * @description CheckmarkIcon for input fields
 */

export const InputCheckmark: FC<InputCheckmarkProps> = ({
  isHidden = false,
}) => (
  <>
    {!isHidden && (
      <span className='input-checkmark'>
        <Icon name={ICON_NAME.CHECKMARK} />
      </span>
    )}
  </>
)
