export const TVPContractPageTranslations = {
  header: {
    title: 'Vertrag anlegen',
    description:
      'Erstellen Sie für Ihre Kunden einfach einen neuen Vertrag, indem Sie die Schritte des Formulars ' +
      'durchlaufen und alle notwendigen Fragen klären.',
  },
  steps: {
    1: {
      title: 'Kundeninformationen',
      fields: {
        company: {
          label: 'Unternehmen',
          placeholder: 'Suchen nach Unternehmen, PLZ, Ort',
          noOptionsMessage: 'Kein passendes Unternehmen gefunden',
        },
        zipcode: { label: 'PLZ' },
        location: { label: 'Ort' },
      },
    },
    2: {
      title: 'Eckdaten',
    },
    3: {
      title: 'Verpackungen & Konditionen',
    },
  },
}
