import { I18n } from 'react-i18nify'
import { actions } from 'react-redux-form'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import IndexesActionTypes from '../actiontypes/indexes'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetIndexes
 * @description gets list of Indexes.
 */
export const handleGetIndexes = createLogic({
  type: [IndexesActionTypes.GET_INDEXES_REQUEST],
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/index/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: IndexesActionTypes.GET_INDEXES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t('api.indexes.handleGetIndexes.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: IndexesActionTypes.GET_INDEXES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetIndexValues
 * @description gets the Index Values.
 */
export const handleGetIndexValues = createLogic({
  type: IndexesActionTypes.GET_INDEX_VALUES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/index-values/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: IndexesActionTypes.GET_INDEX_VALUES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.indexes.handleGetIndexValues.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: IndexesActionTypes.GET_INDEX_VALUES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleChangeIndexValues
 * @description updates multiple index values
 */
export const handleChangeIndexValues = createLogic({
  type: IndexesActionTypes.UPDATE_INDEX_VALUES_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const newPayload = action.payload.map(object => ({
      ...object,
      value:
        object.value || object.value === 0
          ? String(object.value).replace(',', '.')
          : null,
    }))
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/index-values/bulk_change/`,
        newPayload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: IndexesActionTypes.UPDATE_INDEX_VALUES_SUCCESS,
          payload: res,
        })

        toastr.success(
          '',
          I18n.t('api.indexes.handleChangeIndexValues.success'),
        )
      })
      .then(() => dispatch(actions.reset('indexes.item')))
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.indexes.handleChangeIndexValues.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: IndexesActionTypes.UPDATE_INDEX_VALUES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleAddIndexValue
 * @description gets the Index Values.
 */
export const handleAddIndexValue = createLogic({
  type: IndexesActionTypes.CREATE_INDEX_VALUE_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    const newPayload = action.payload.map(object => ({
      ...object,
      value: object.value ? String(object.value).replace(',', '.') : null,
    }))
    httpClient
      .post(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/index-values/`, newPayload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: IndexesActionTypes.CREATE_INDEX_VALUE_SUCCESS,
          payload: res,
        })

        toastr.success('', I18n.t('api.indexes.handleAddIndexValue.success'))
      })
      .then(() => dispatch(actions.reset('indexes.item')))
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.indexes.handleAddIndexValue.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: IndexesActionTypes.CREATE_INDEX_VALUE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportIndexValues
 * @description triggers the index value export.
 */
export const handleExportIndexValues = createLogic({
  type: IndexesActionTypes.EXPORT_INDEX_VALUES_REQUEST,
  latest: true,

  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/index-values/export/`)
      .then(() => {
        toastr.success(
          '',
          I18n.t('api.indexes.handleExportIndexValues.success'),
        )
        dispatch({
          type: IndexesActionTypes.EXPORT_INDEX_VALUES_SUCCESS,
        })
      })
      .then(done)
      .catch(err => {
        if (!err.hideToastr)
          toastr.error('', I18n.t('api.indexes.handleExportIndexValues.error'))

        dispatch({
          type: IndexesActionTypes.EXPORT_INDEX_VALUES_ERROR,
        })

        done()
      })
  },
})

export default [
  handleGetIndexes,
  handleGetIndexValues,
  handleChangeIndexValues,
  handleAddIndexValue,
  handleExportIndexValues,
]
