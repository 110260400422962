import './style.scss'

import uniqueId from 'lodash.uniqueid'
import React, { FC, useEffect, useRef, useState, createContext } from 'react'

interface RadioPanelGroupProps {
  className?: string
  columns: number
  initialSelection?: string
  label?: string
  onChange: (selection: string) => void
  onReset?: (selection: string) => void
  resetText?: string
  /**
   * Default is false. Should reset the initial selection when new initialValues changed
   * */
  enableReinitialize?: boolean
}

type RadioPanelGroupContextType = {
  onReset?: (selection: string) => void
  onSelected: (value: any) => void
  radioButtonGroupName: string
  resetText?: string
  selectedValue?: string
}

export const RadioPanelGroupContext = createContext<RadioPanelGroupContextType>(
  {
    onReset: () => undefined,
    onSelected: () => undefined,
    radioButtonGroupName: '',
    resetText: undefined,
    selectedValue: undefined,
  },
)

export const RadioPanelGroup: FC<RadioPanelGroupProps> = ({
  children,
  className = '',
  columns,
  initialSelection,
  label,
  onChange = () => undefined,
  onReset = () => undefined,
  resetText,
  enableReinitialize = false,
}) => {
  const radioButtonGroupName = useRef<string>(uniqueId('radiopanel_'))
  const [selectedValue, setSelectedValue] = useState('')

  useEffect(() => {
    if (
      initialSelection &&
      initialSelection !== 'undefined' &&
      initialSelection !== selectedValue &&
      (selectedValue === '' || enableReinitialize)
    ) {
      setSelectedValue(initialSelection)
      onChange(initialSelection)
    }
  }, [initialSelection, onChange, selectedValue, enableReinitialize])

  /**
   * Handles select radio button.
   */
  const onSelected = (value: string) => {
    if (value !== selectedValue) {
      setSelectedValue(value)
      onChange(value)
    }
  }

  if (children) {
    return (
      <RadioPanelGroupContext.Provider
        value={{
          selectedValue,
          onSelected,
          onReset,
          radioButtonGroupName: radioButtonGroupName.current,
          resetText,
        }}
      >
        <div className='radiopanelgroup'>
          {label && <div className='radiopanelgroup__label'>{label}</div>}
          <div
            className={`radiopanelgroup__container ${className}`}
            style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}
          >
            {children}
          </div>
        </div>
      </RadioPanelGroupContext.Provider>
    )
  }
  return null
}
