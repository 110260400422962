import moment, { Moment } from 'moment'

import { DATE_FORMATS } from 'constants/app'
import { maxDate, preventWeekend } from 'helper/general'

/**
 * @description Get the first possible date for a new order.
 */
export const getMinDate: (any) => Moment = turn_begin =>
  maxDate(
    moment().add(1, 'day'), // tomorrow
    moment(turn_begin, DATE_FORMATS), // turn begin
  )

/**
 * @description Get the date to be used if no date has been explicitly selected
 */
export const getDefaultDate: (number) => Moment = reactionTime =>
  preventWeekend(moment().add(reactionTime, 'hours'))

/**
 * @description Get the last possible date for a new order.
 */
export const getMaxDate: (any) => Moment = turn_end =>
  preventWeekend(moment(turn_end, DATE_FORMATS).add(1, 'day'))
