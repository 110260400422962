import { Form, Formik } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import * as yup from 'yup'

import { cancelOffer } from 'actions/offer'
import { getCompanyUsers, getUsersByCompany } from 'actions/user'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import { DropDownInput } from 'components/common/DropDownInput'
import { ProgressButton } from 'components/common/ProgressButton'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { Textarea } from 'components/common/Textarea'
import {
  AGREEMENT_CANCELLATION_REASONS,
  OFFER_CANCELLATION_REASON,
  OFFER_CANCELLATION_REASONS,
} from 'components/inquiry/constants'
import {
  EmptoGroups,
  MaklerPremiumGroups,
  UserPermission,
} from 'constants/user'
import { checkRequiredPermissions } from 'helper/permissions'
import { getCurrentUser } from 'helper/user'
import { createLoadingSelector } from 'selectors/loading'
import { getActiveUserList, getUsersByCompanyList } from 'selectors/user'

interface CancelOfferFormProps {
  offer: Offer
  onClose: () => void
  user: User
  isAgreement?: boolean
}

export const CancelOfferForm: FC<CancelOfferFormProps> = ({
  offer,
  onClose = () => undefined,
  user,
  isAgreement = false,
}) => {
  const dispatch = useDispatch()
  const companyActiveUserList = useSelector(getActiveUserList)
  const usersByCompanyList = useSelector(getUsersByCompanyList)

  const getUsersLoadingSelector = createLoadingSelector(['GET_COMPANY_USERS'])
  const getUsersByCompanyLoadingSelector = createLoadingSelector([
    'GET_USERS_BY_COMPANY',
  ])
  const cancelOfferLoadingSelector = createLoadingSelector(['CANCEL_OFFER'])

  const isLoading = {
    companyActiveUserList: useSelector(getUsersLoadingSelector),
    usersByCompanyList: useSelector(getUsersByCompanyLoadingSelector),
    cancelOffer: useSelector(cancelOfferLoadingSelector),
  }

  const history = useHistory()

  const [currentPhoneUser, setCurrentPhoneUser] = useState<{
    label: string
    value: string | number
  }>()

  const userIsMaklerPremium = user.group_id in MaklerPremiumGroups
  const userIsEmpto = user.group_id in EmptoGroups
  const isMaklerPremiumOffer =
    offer.customer_company_object.makler_premium_company === user.company

  useEffect(() => {
    if (offer && offer.company_object) {
      // Gets all users of company. which created the offer (this is for empto functionality)
      dispatch(getCompanyUsers(offer.company_object.id))
    }

    if (offer && offer.customer_company_object) {
      // Gets all users of company, which created the inquiry (this is for Makler-Premium functionality)
      dispatch(getUsersByCompany(offer.customer_company_object.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (offer && offer.company_object) {
      if (companyActiveUserList.length && userIsEmpto) {
        setCurrentPhoneUser(
          getCurrentUser(
            companyActiveUserList,
            Number(offer.created_by_object.id),
          ),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyActiveUserList, offer, usersByCompanyList])

  const canCreatePhoneOffers = checkRequiredPermissions(
    user.permission_codenames,
    [UserPermission.CREATE_PHONE_OFFERS],
  )

  const cancellationReasons = isAgreement
    ? AGREEMENT_CANCELLATION_REASONS
    : OFFER_CANCELLATION_REASONS

  if (
    !offer.id &&
    isLoading.companyActiveUserList &&
    isLoading.usersByCompanyList
  )
    return null

  return (
    <Formik
      onSubmit={values => {
        const offerObject = {
          id: offer.id,
        }

        if (userIsEmpto) {
          dispatch(
            cancelOffer(
              {
                ...offerObject,
                status_changed_by: values.phone_user,
              },
              history,
            ),
          )
        } else if (userIsMaklerPremium && isMaklerPremiumOffer) {
          dispatch(
            cancelOffer(
              {
                ...offerObject,
                status_changed_by: values.phone_user,
                reason: values.reason,
                reason_text: values.reason_text,
              },
              history,
            ),
          )
        } else {
          dispatch(cancelOffer(offer, history))
        }
        onClose()
      }}
      validate={values => {
        const errors: {
          phone_user?: string
          reason?: string
          reason_text?: string
        } = {}

        // phone_user validation should be considered for both empto and makler-premium users
        if (userIsEmpto || userIsMaklerPremium) {
          if (String(values.phone_user) === '') {
            errors.phone_user = I18n.t('message.validation.selectionRequired')
          }
        }

        // if the user is makler-premium, he has extra fields, which empto doesn't have
        if (userIsMaklerPremium && isMaklerPremiumOffer) {
          if (String(values.reason) === '') {
            errors.reason = I18n.t('message.validation.selectionRequired')
          }

          if (
            values.reason === String(OFFER_CANCELLATION_REASON.REASON_OTHER) &&
            String(values.reason_text.trim()) === ''
          ) {
            errors.reason = I18n.t('message.validation.missingContent')
          }
        }
        return errors
      }}
      initialValues={{
        phone_user: '',
        reason: '',
        reason_text: '',
      }}
      validationSchema={() =>
        yup.object().shape({
          phone_user: yup.string().typeError(''),
          reason: yup.string(),
          reason_text: yup.string(),
        })
      }
    >
      {({
        setFieldValue,
        handleSubmit,
        handleChange,
        submitCount,
        touched,
        errors: formikErrors,
        values,
        isValid,
        isSubmitting,
      }) => (
        <Form>
          <div className='cancel-offer-form uk-text-center uk-text-lead uk-modal-body'>
            {/* INFO */}
            <div className='uk-margin-medium'>
              {isAgreement
                ? I18n.t('offerForm.cancelOffer.infoAgreement')
                : I18n.t('offerForm.cancelOffer.info')}
            </div>
          </div>
          {/* EMPTO CANCELLATION FORM FIELDS */}
          {userIsEmpto && canCreatePhoneOffers && (
            <div className='uk-modal-body'>
              <StaticCombobox
                error={
                  submitCount > 0 && touched.phone_user
                    ? formikErrors.phone_user
                    : ''
                }
                isRequired={!values.phone_user}
                dataTestId='cancel-offer-form-user'
                label={I18n.t('inquiry.phone_user')}
                isLoading={isLoading.companyActiveUserList}
                name='phone_user'
                noResultsText={I18n.t('inquiryForm.noInputResults.noUsers')}
                options={companyActiveUserList.map(item => ({
                  value: `${item.id}`,
                  label: `${item.first_name} ${item.last_name}`,
                }))}
                onSelectionChange={value => {
                  setFieldValue('phone_user', value.target.value)
                  setCurrentPhoneUser(
                    getCurrentUser(companyActiveUserList, value.target.value),
                  )
                }}
                placeholder={I18n.t('inquiry.phone_user')}
                selectedOption={currentPhoneUser}
              />
            </div>
          )}

          {/* MAKLER PREMIUM CANCELLATION FORM FIELDS */}
          {userIsMaklerPremium && isMaklerPremiumOffer && (
            <>
              <div className='uk-margin uk-modal-body'>
                <StaticCombobox
                  error={
                    submitCount > 0 && touched.phone_user
                      ? formikErrors.phone_user
                      : ''
                  }
                  isRequired={!values.phone_user}
                  dataTestId='cancel-offer-form-user'
                  label={I18n.t('general.placeholder.contactPerson')}
                  isLoading={isLoading.usersByCompanyList}
                  name='phone_user'
                  noResultsText={I18n.t('inquiryForm.noInputResults.noUsers')}
                  options={usersByCompanyList.map(item => ({
                    value: `${item.id}`,
                    label: `${item.first_name} ${item.last_name}`,
                  }))}
                  onSelectionChange={value => {
                    setFieldValue('phone_user', value.target.value)
                    setCurrentPhoneUser(
                      getCurrentUser(usersByCompanyList, value.target.value),
                    )
                  }}
                  placeholder={I18n.t('general.placeholder.contactPerson')}
                  selectedOption={currentPhoneUser}
                />

                <DropDownInput
                  error={
                    submitCount > 0 && touched.reason ? formikErrors.reason : ''
                  }
                  choices={cancellationReasons.map(item => ({
                    optionValue: item.id,
                    optionLabel: `${I18n.t(item.name)}`,
                  }))}
                  label={I18n.t('constant.offerCancellation.label')}
                  name='reason'
                  onChange={handleChange}
                  placeholder={I18n.t('constant.offerCancellation.placeholder')}
                  value={values.reason}
                />

                {values.reason ===
                  String(OFFER_CANCELLATION_REASON.REASON_OTHER) && (
                  <Textarea
                    label={I18n.t('constant.offerCancellation.otherTextLabel')}
                    maxLength={500}
                    name='reason_text'
                    onChange={handleChange}
                    placeholder={
                      isAgreement
                        ? I18n.t(
                            'constant.offerCancellation.otherTextAgreementPlaceholder',
                          )
                        : I18n.t(
                            'constant.offerCancellation.otherTextPlaceholder',
                          )
                    }
                    value={values.reason_text}
                    showRemainingCharacters
                  />
                )}
              </div>
            </>
          )}
          {/* BUTTONS */}
          <div className='uk-modal-footer uk-text-right'>
            <span className='uk-margin-right'>
              <Button
                backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                onClick={onClose}
                type={BUTTON_TYPE.BUTTON}
              >
                {I18n.t('general.no')}
              </Button>
            </span>

            <ProgressButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              dataTestId='cancel-offer-form-submit'
              isDisabled={
                (submitCount > 0 && !isValid) ||
                (isSubmitting && isLoading.cancelOffer)
              }
              isLoading={isSubmitting && isLoading.cancelOffer}
              onClick={handleSubmit}
            >
              {I18n.t('general.yes')}
            </ProgressButton>
          </div>
        </Form>
      )}
    </Formik>
  )
}
