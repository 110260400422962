import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import classNames from 'classnames'
import { oneOf, string } from 'prop-types'
import React from 'react'

export const ICON_BACKGROUND_COLOR = {
  DEFAULT: 'default',
  GREEN: 'green',
}

export const ICON_COLOR = {
  DEFAULT: 'default',
  WHITE: 'white',
  BRAND: 'brand',
  GRADIENT: 'gradient',
}

export const ICON_NAME = {
  ALARM: 'alarm',
  ALIGN_CENTER: 'align-center',
  ALIGN_JUSTIFY: 'align-justify',
  ALIGN_LEFT: 'align-left',
  ALIGN_RIGHT: 'align-right',
  ANCHOR: 'anchor',
  ARCHIVE: 'archive',
  ARROW_DOWN: 'arrow-down',
  ARROW_LEFT: 'arrow-left',
  ARROW_RIGHT: 'arrow-right',
  ARROW_THIN_DOWN: 'arrow-thin-down',
  ARROW_THIN_LEFT: 'arrow-thin-left',
  ARROW_THIN_RIGHT: 'arrow-thin-right',
  ARROW_THIN_UP: 'arrow-thin-up',
  ARROW_UP: 'arrow-up',
  ARTICLE: 'article',
  BACKSPACE: 'backspace',
  BASKET: 'basket',
  BASKETBALL: 'basketball',
  BATTERY_EMPTY: 'battery-empty',
  BATTERY_FULL: 'battery-full',
  BATTERY_LOW: 'battery-low',
  BATTERY_MEDIUM: 'battery-medium',
  BELL: 'bell',
  BLOG: 'blog',
  BLUETOOTH: 'bluetooth',
  BOLD: 'bold',
  BOOKMARK: 'bookmark',
  BOOKMARKS: 'bookmarks',
  BOX: 'box',
  BRIEFCASE: 'briefcase',
  BRIGHTNESS_LOW: 'brightness-low',
  BRIGHTNESS_MAX: 'brightness-max',
  BRIGHTNESS_MEDIUM: 'brightness-medium',
  BROADCAST: 'broadcast',
  BROWSER_UPLOAD: 'browser-upload',
  BROWSER: 'browser',
  BRUSH: 'brush',
  CALENDAR: 'calendar',
  CAMCORDER: 'camcorder',
  CAMERA: 'camera',
  CARD: 'card',
  CART: 'cart',
  CHECKLIST: 'checklist',
  CHECKMARK: 'checkmark',
  CHEVRON_DOWN: 'chevron-down',
  CHEVRON_LEFT: 'chevron-left',
  CHEVRON_RIGHT: 'chevron-right',
  CHEVRON_UP: 'chevron-up',
  CLIPBOARD: 'clipboard',
  CLOCK: 'clock',
  CLOCKWISE: 'clockwise',
  CLOUD_DOWNLOAD: 'cloud-download',
  CLOUD_UPLOAD: 'cloud-upload',
  LEFT_OPEN: 'left-open',
  RIGHT_OPEN: 'right-open',
  CLOUD: 'cloud',
  CODE: 'code',
  CONTRACT_2: 'contract-2',
  CONTRACT: 'contract',
  CONVERSATION: 'conversation',
  COPY: 'copy',
  CROP: 'crop',
  CROSS: 'cross',
  CROSSHAIR: 'crosshair',
  CUTLERY: 'cutlery',
  DEVICE_DESKTOP: 'device-desktop',
  DEVICE_MOBILE: 'device-mobile',
  DEVICE_TABLET: 'device-tablet',
  DIRECTION: 'direction',
  DISC: 'disc',
  DOCUMENT_DELETE: 'document-delete',
  DOCUMENT_EDIT: 'document-edit',
  DOCUMENT_NEW: 'document-new',
  DOCUMENT_REMOVE: 'document-remove',
  DOCUMENT: 'document',
  DOT: 'dot',
  DOTS_2: 'dots-2',
  DOTS_3: 'dots-3',
  DOWNLOAD: 'download',
  DUPLICATE: 'duplicate',
  ENTER: 'enter',
  EXIT: 'exit',
  EXPAND_2: 'expand-2',
  EXPAND: 'expand',
  EXPERIMENT: 'experiment',
  EXPORT: 'export',
  FEED: 'feed',
  FLAG: 'flag',
  FLASHLIGHT: 'flashlight',
  FOLDER_OPEN: 'folder-open',
  FOLDER: 'folder',
  FORWARD: 'forward',
  GAMING: 'gaming',
  GEAR: 'gear',
  GRADUATION: 'graduation',
  GRAPH_BAR: 'graph-bar',
  GRAPH_LINE: 'graph-line',
  GRAPH_PIE: 'graph-pie',
  HEADSET: 'headset',
  HEART: 'heart',
  HELP: 'help',
  HOME: 'home',
  HOURGLASS: 'hourglass',
  INBOX: 'inbox',
  INFORMATION: 'information',
  ITALIC: 'italic',
  JEWEL: 'jewel',
  LIFTING: 'lifting',
  LIGHTBULB: 'lightbulb',
  LINK_BROKEN: 'link-broken',
  LINK: 'link',
  LIST: 'list',
  LOADING: 'loading',
  LOCATION: 'location',
  LOCK_OPEN: 'lock-open',
  LOCK: 'lock',
  MAIL: 'mail',
  MAP: 'map',
  MEDIA_LOOP: 'media-loop',
  MEDIA_NEXT: 'media-next',
  MEDIA_PAUSE: 'media-pause',
  MEDIA_PLAY: 'media-play',
  MEDIA_PREVIOUS: 'media-previous',
  MEDIA_RECORD: 'media-record',
  MEDIA_SHUFFLE: 'media-shuffle',
  MEDIA_STOP: 'media-stop',
  MEDICAL: 'medical',
  MENU: 'menu',
  MESSAGE: 'message',
  METER: 'meter',
  MICROPHONE: 'microphone',
  MINUS: 'minus',
  MONITOR: 'monitor',
  MOVE: 'move',
  MUSIC: 'music',
  NETWORK_1: 'network-1',
  NETWORK_2: 'network-2',
  NETWORK_3: 'network-3',
  NETWORK_4: 'network-4',
  NETWORK_5: 'network-5',
  PAMPHLET: 'pamphlet',
  PAPERCLIP: 'paperclip',
  PENCIL: 'pencil',
  PHONE: 'phone',
  PHOTO_GROUP: 'photo-group',
  PHOTO: 'photo',
  PILL: 'pill',
  PIN: 'pin',
  PLUS: 'plus',
  POWER: 'power',
  PREVIEW: 'preview',
  PRINT: 'print',
  PULSE: 'pulse',
  QUESTION: 'question',
  REPLY_ALL: 'reply-all',
  REPLY: 'reply',
  RETURN: 'return',
  RETWEET: 'retweet',
  ROCKET: 'rocket',
  SCALE: 'scale',
  SEARCH: 'search',
  SHOPPING_BAG: 'shopping-bag',
  SKIP: 'skip',
  STACK: 'stack',
  STAR: 'star',
  STOPWATCH: 'stopwatch',
  STORE: 'store',
  SUITCASE: 'suitcase',
  SWAP: 'swap',
  TAG_DELETE: 'tag-delete',
  TAG: 'tag',
  TAGS: 'tags',
  THUMBS_DOWN: 'thumbs-down',
  THUMBS_UP: 'thumbs-up',
  TICKET: 'ticket',
  TIME_REVERSE: 'time-reverse',
  TO_DO: 'to-do',
  TOGGLES: 'toggles',
  TRASH: 'trash',
  TROPHY: 'trophy',
  UPLOAD: 'upload',
  USER_GROUP: 'user-group',
  USER_ID: 'user-id',
  USER: 'user',
  VIBRATE: 'vibrate',
  VIEW_APPS: 'view-apps',
  VIEW_LIST_LARGE: 'view-list-large',
  VIEW_LIST: 'view-list',
  VIEW_THUMB: 'view-thumb',
  VOLUME_FULL: 'volume-full',
  VOLUME_LOW: 'volume-low',
  VOLUME_MEDIUM: 'volume-medium',
  VOLUME_OFF: 'volume-off',
  WALLET: 'wallet',
  WARNING: 'warning',
  WEB: 'web',
  WEIGHT: 'weight',
  WIFI: 'wifi',
  WRONG: 'wrong',
  ZOOM_IN: 'zoom-in',
  ZOOM_OUT: 'zoom-out',
  TRUCK: 'truck',
  PROCESSED: 'processed',
  CONTAINER_SKIP: 'container-skip',
  DOCUMENT_CHECK: 'document-check',
  PLUG: 'plug',
  CHECKED: 'checked',
  PIPE: 'pipe',
  ANGLE_LEFT: 'angle-left',
  ANGLE_RIGHT: 'angle-right',
  FILE_PDF: 'file-pdf',
  HANDSHAKE_O: 'handshake-o',
}

const Icon = ({ backgroundColor, className, color, name }) => (
  <i
    className={classNames(
      'icon',
      `icon-${name}`,
      `icon--color-${color}`,
      `icon--background-color-${backgroundColor}`,
      className,
    )}
    aria-hidden='true'
  />
)

Icon.propTypes = forbidExtraProps({
  backgroundColor: oneOf(Object.values(ICON_BACKGROUND_COLOR)),
  className: string,
  color: oneOf(Object.values(ICON_COLOR)),
  name: oneOf(Object.values(ICON_NAME)),
})

Icon.defaultProps = {
  backgroundColor: ICON_COLOR.DEFAULT,
  className: '',
  color: ICON_COLOR.DEFAULT,
  name: ICON_NAME.ALARM,
}

export default Icon
