export const companyArchiveTranslations = {
  page: {
    headline: 'Dokumentenarchiv',
    introduction: `Hier finden Sie alle archivierten Dokumente des Unternehmens. Ein Dokument kann nicht mehr aus 
        dem Archiv zurückgeholt jedoch hier eingesehen werden. Wurde ein Dokument versehentlich archiviert, so laden 
        Sie bitte das betreffende Dokument erneut im Profil über den Button "Dokument hochladen +" hoch.`,
  },
  table: {
    columns: {
      id: 'Nr.',
      type: 'Typ',
      description: 'Bezeichnung',
      archivedAt: 'Archiviert am',
      archivedBy: 'Archiviert von',
    },
  },
}
