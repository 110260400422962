/**
 * These are the times of day (day slots) for quick orders.
 */
export enum COMPANY_STATUS {
  STATUS_INACTIVE = 1,
  STATUS_INACTIVE_BY_EMPTO = 2,
  STATUS_ACTIVE = 3,
  STATUS_INACTIVE_BY_EPD = 4,
}

export const COMPANY_STATES = [
  {
    id: COMPANY_STATUS.STATUS_INACTIVE,
    name: 'constant.status.inactive',
  },
  {
    id: COMPANY_STATUS.STATUS_INACTIVE_BY_EMPTO,
    name: 'constant.status.inactiveByEmpto',
  },
  {
    id: COMPANY_STATUS.STATUS_ACTIVE,
    name: 'constant.status.active',
  },
  {
    id: COMPANY_STATUS.STATUS_INACTIVE_BY_EPD,
    name: 'constant.status.inactiveByEpd',
  },
]

/**
 * These are the roles for a company.
 */
export enum COMPANY_ROLE {
  NONE = -1,
  WASTE_PRODUCER = 1,
  WASTE_COMPANY = 2,
  MIDDLE_MAN = 3,
}

export const COMPANY_ROLES = [
  {
    id: COMPANY_ROLE.WASTE_PRODUCER,
    name: 'homepages.company.role.wasteProducer.title',
  },
  {
    id: COMPANY_ROLE.WASTE_COMPANY,
    name: 'homepages.company.role.wasteManagementCompany.title',
  },
  {
    id: COMPANY_ROLE.MIDDLE_MAN,
    name: 'homepages.company.role.middleMan.title',
  },
]

/**
 * Address status options
 */
export enum ADDRESS_STATUS {
  STATUS_ACTIVE = 1, // The address is active and may be used
  STATUS_INACTIVE = 2, // The address is inactive, location closed, may not be used
}

/**
 * Address status setings
 */
export const ADDRESS_STATES = [
  {
    id: ADDRESS_STATUS.STATUS_ACTIVE,
    name: 'constant.status.active',
    colorClass: 'green',
  },
  {
    id: ADDRESS_STATUS.STATUS_INACTIVE,
    name: 'constant.status.locked',
    colorClass: 'gray',
  },
]

export const PRIVATE_PERSON_CHOICES = [
  {
    name: 'maklerpremium.addWasteProducerModal.form.is_company_empty',
  },
  {
    id: 0,
    name: 'maklerpremium.addWasteProducerModal.form.is_company_true',
  },
  {
    id: 1,
    name: 'maklerpremium.addWasteProducerModal.form.is_company_false',
  },
]

/**
 * Payment methods
 */

export enum PAYMENT_METHOD {
  INVOICE = 1,
  EPD_INVOICE = 4, // 2 and 3 must be skipped because they were used for stripe.
  EPD_MICROPAYMENT = 5,
  EPD_IN_ADVANCE = 6,
  EPD_RATEPAY = 7,
  EPD_IMMEDIATE_BANK_TRANSFER = 8,
  EPD_PAYPAL = 9,
  EPD_ELV = 10,
  EPD_STRIPE_PAYPAL = 11,
  EPD_STRIPE_CARD = 12,
}

export const PAYMENT_METHODS = [
  {
    id: PAYMENT_METHOD.INVOICE,
    name: 'constant.paymentMethod.invoice',
  },
  {
    id: PAYMENT_METHOD.EPD_INVOICE,
    name: 'constant.paymentMethod.epd_invoice',
  },
  {
    id: PAYMENT_METHOD.EPD_MICROPAYMENT,
    name: 'constant.paymentMethod.epd_micropayment',
  },
  {
    id: PAYMENT_METHOD.EPD_IN_ADVANCE,
    name: 'constant.paymentMethod.epd_inAdvance',
  },
  {
    id: PAYMENT_METHOD.EPD_RATEPAY,
    name: 'constant.paymentMethod.epd_ratepay',
  },
  {
    id: PAYMENT_METHOD.EPD_IMMEDIATE_BANK_TRANSFER,
    name: 'constant.paymentMethod.epd_immediateBankTransfer',
  },
  {
    id: PAYMENT_METHOD.EPD_PAYPAL,
    name: 'constant.paymentMethod.epd_paypal',
  },
  {
    id: PAYMENT_METHOD.EPD_ELV,
    name: 'constant.paymentMethod.epd_elv',
  },
  {
    id: PAYMENT_METHOD.EPD_STRIPE_PAYPAL,
    name: 'constant.paymentMethod.epd_stripe_paypal',
  },
  {
    id: PAYMENT_METHOD.EPD_STRIPE_CARD,
    name: 'constant.paymentMethod.epd_stripe_card',
  },
]

export const PAYMENT_METHODS_EPD = [
  {
    id: PAYMENT_METHOD.EPD_INVOICE,
    name: 'constant.paymentMethod.epd_invoice',
  },
  {
    id: PAYMENT_METHOD.EPD_MICROPAYMENT,
    name: 'constant.paymentMethod.epd_micropayment',
  },
  {
    id: PAYMENT_METHOD.EPD_IN_ADVANCE,
    name: 'constant.paymentMethod.epd_inAdvance',
  },
  {
    id: PAYMENT_METHOD.EPD_PAYPAL,
    name: 'constant.paymentMethod.epd_paypal',
  },
  {
    id: PAYMENT_METHOD.EPD_ELV,
    name: 'constant.paymentMethod.epd_elv',
  },
  {
    id: PAYMENT_METHOD.EPD_STRIPE_PAYPAL,
    name: 'constant.paymentMethod.epd_stripe_paypal',
  },
  {
    id: PAYMENT_METHOD.EPD_STRIPE_CARD,
    name: 'constant.paymentMethod.epd_stripe_card',
  },
]

/**
 * Company discount in %
 */
export const DISCOUNT_CHOICES = [0, 5, 10, 15, 20]

export enum BILLING_INTERVAL {
  MONTH = 'pro Monat',
  ORDER = 'pro Auftrag',
}

export enum MONTH_PREVIOUS_CHOICES {
  MONTH_PREVIOUS = 0,
  MONTH_PREVIOUS_BEFORE = 1,
  MONTH_DELIVERY = 2,
}

export enum COLLECTIVE_INVOICING_MODE {
  PER_CLIENT = 'per_client', // The address is active and may be used
  PER_ADDRESS = 'per_address', // The address is inactive, location closed, may not be used
}

/**
 * Address status setings
 */
export const COLLECTIVE_INVOICING_MODES = [
  {
    id: COLLECTIVE_INVOICING_MODE.PER_CLIENT,
    name: 'constant.collectiveInvoicingMode.perClient',
  },
  {
    id: COLLECTIVE_INVOICING_MODE.PER_ADDRESS,
    name: 'constant.collectiveInvoicingMode.perAddress',
  },
]
