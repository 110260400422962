import React, { FC } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import Spinner from 'react-spinkit'

import { getDefaultPageRedirect } from 'helper/router'
import { routes } from 'routes/root'
import { createLoadingSelector } from 'selectors/loading'
import { getLoggedInSelector, getUserPermissionsSelector } from 'selectors/user'

import { Header } from '../../layout/Header'
import { PageFooter } from '../../layout/PageFooter'
import { WasteDisposerOrderManagementPage } from '../../wasteDisposerOrderManagementPage/wasteDisposerOrderManagementPage'

import { NotFoundComponent } from './components/NotFoundComponent'
import { PrivateRoute } from './components/PrivateRoute'

/**
 * RoutesHandler is the entrypoint for routing the empto aaplication
 * @constructor
 */
export const RoutesHandler: FC = () => {
  const isLoadingSelector = createLoadingSelector([
    'LOGIN',
    'GET_CONTAINERS',
    'GET_FRACTIONS',
    'GET_INDEXES',
  ])
  const isLoading = useSelector<any, boolean>(isLoadingSelector)
  const permissions = useSelector<any, string[]>(getUserPermissionsSelector)
  const isLoggedIn = !!useSelector(getLoggedInSelector)
  const location = useLocation()

  /**
   * @function
   * @description A method to get all content component routes.
   * @return {Array}
   */
  const getRoutes = () => {
    let key = 0

    return routes.map(route => {
      key += 1

      if (route.public || route.publicOnly) {
        return (
          <Route
            path={route.path}
            key={key}
            exact={route.exact}
            component={route.component}
          />
        )
      }

      return (
        <PrivateRoute
          path={route.path}
          key={key}
          exact={route.exact}
          component={route.component}
          requiredPermissions={route.requiredPermissions}
          showErrorPage={route.showErrorPage}
        />
      )
    })
  }

  const externalRoute = ['/landingpage'].includes(location.pathname)

  const renderLoggedInRedirect = () => {
    if (isLoading) {
      return <Spinner name='circle' />
    }

    if (isLoggedIn) {
      return (
        <Redirect exact from='/' to={getDefaultPageRedirect(permissions)} />
      )
    }

    return null
  }

  return (
    (!externalRoute && (
      <>
        <Header />

        <div
          className='page'
          style={{ paddingTop: 'var(--page-headerHeight)' }}
        >
          <div className='uk-container uk-container-large'>
            <Switch>
              {getRoutes()}

              {renderLoggedInRedirect()}

              {/* Default redirect. If logged in, another redirect will match before (see above) */}
              <Redirect exact from='/' to='/gewerbetreibende' />
              <Redirect exact from='/index.html' to='/gewerbetreibende' />

              {/* Redirect due to routes renaming from english to german. */}
              <Redirect exact from='/waste-producer' to='/gewerbetreibende' />

              <Route component={NotFoundComponent} />
            </Switch>
          </div>
        </div>
        <PageFooter />
      </>
    )) || (
      <Switch>
        <Route
          path='/landingpage'
          key={999}
          exact
          component={WasteDisposerOrderManagementPage}
        />
      </Switch>
    )
  )
}
