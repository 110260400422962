import './style.scss'

import { string } from 'prop-types'
import React, { Component } from 'react'

import Icon from '../../common/Fontello'

/**
 * @description This component renders the details switcher button.
 */
export class IconLabelComponent extends Component {
  render() {
    return (
      <div className={`icon-label ${this.props.className}`}>
        {this.props.icon && <Icon name={this.props.icon} />}

        {this.props.title}
      </div>
    )
  }
}

IconLabelComponent.propTypes = {
  className: string,
  title: string.isRequired,
  icon: string,
}

IconLabelComponent.defaultProps = {
  className: null,
  icon: null,
}

export default IconLabelComponent
