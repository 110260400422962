import { ReactText } from 'react'
import { I18n } from 'react-i18nify'

import { BUSINESS_SEGMENT } from '../../constants/app'
import { getName } from '../../helper/translations'

import { INQUIRY_TIME_OF_DAY, INQUIRY_TIMES_OF_DAY } from './constants'

/**
 * @description This function finds the fraction for an inquiry.
 */
export const getFractionFromItem = (
  item?: {
    fraction?: number | string
    fine_fraction?: number | string
    [key: string]: any
  },
  fractionList: Fraction[] = [],
) => {
  if (!item || !fractionList || fractionList.length < 1) return undefined
  if (item.fine_fraction)
    return fractionList.find(
      fraction => fraction.id === Number(item.fine_fraction),
    )
  return fractionList.find(fraction => fraction.id === item.fraction)
}

/**
 * @description Finds the coarse (parent) fraction for a given fraction. If the given fraction is already a coarse
 * fraction, it is returned directly.
 */
export const getCoarseFractionFromFraction = (
  fraction?: Fraction,
  fractionList: Fraction[] = [],
) => {
  if (!fraction || !fractionList || fractionList.length < 1) return undefined
  if (!fraction.parent) return fraction // This is already a coarse fraction
  return fractionList.find(f => f.id === fraction.parent)
}

/**
 * @description Finds the fraction (coarse or fine) and the coarse fraction (in case fraction is a fine fraction)
 */
export const getFractionsFromItem = (
  item: Inquiry | Offer | Order,
  fractionList: Fraction[] = [],
) => {
  const fraction = getFractionFromItem(item, fractionList)
  const coarseFraction = getCoarseFractionFromFraction(fraction, fractionList)
  return { fraction, coarseFraction }
}

/**
 * @description Finds the fraction (coarse or fine) and the coarse fraction (in case fraction is a fine fraction) by an
 * id of a fraction
 */
export const getFractionsFromFractionId = (
  fractionId,
  fractionList: Fraction[] = [],
) => {
  const fraction = fractionList.find(_fraction => _fraction.id === fractionId)
  const coarseFraction = getCoarseFractionFromFraction(fraction, fractionList)
  return { fraction, coarseFraction }
}

/**
 * @description Function to get only the Coarse fraction fractions.
 */
export const getCoarseFractionItems = (fractionList: Fraction[] = []) =>
  fractionList.filter(fraction => !fraction.parent && fraction.visible_in_empto)

/**
 * @description This function gets a list of fraction objects. Used for selecting the fraction in the forms and can
 * be dependent of the selected business segment
 */
export const getCoarseFractionItemsForSegment = (
  fractionList: Fraction[] = [],
  businessSegment = BUSINESS_SEGMENT.BUSINESS_EMPTO,
) => {
  const path = window.location.pathname

  if (path === '/maklerpremium/offer/create') {
    return fractionList.filter(
      fraction =>
        !fraction.parent &&
        fraction.visible_in_empto &&
        fraction.visible_in_offer_plus &&
        [businessSegment, BUSINESS_SEGMENT.BUSINESS_ALL].includes(
          Number(fraction.business_segment),
        ),
    )
  } else {
    return fractionList.filter(
      fraction =>
        !fraction.parent &&
        fraction.visible_in_empto &&
        [businessSegment, BUSINESS_SEGMENT.BUSINESS_ALL].includes(
          Number(fraction.business_segment),
        ),
    )
  }
}

/**
 * @description Function to get only the fine fraction fractions, depending on the selected coarse fraction.
 */
export const getFineFractionItems = (
  fractionId: number,
  fractionList: Fraction[] = [],
) => {
  const path = window.location.pathname

  if (path === '/maklerpremium/offer/create') {
    return fractionList.filter(
      fraction =>
        fraction.parent &&
        fraction.parent === fractionId &&
        fraction.visible_in_empto &&
        fraction.visible_in_offer_plus,
    )
  } else {
    return fractionList.filter(
      fraction =>
        fraction.parent &&
        fraction.parent === fractionId &&
        fraction.visible_in_empto,
    )
  }
}

/**
 * Auxiliary method to be used by getContainerItems() method
 */
const getContainersById = (
  containerIdList: number[] = [],
  containerList: Container[],
) =>
  containerIdList
    // we now have the right containers, but only the ids. Get details from the container list
    .map(container => containerList.find(c => c.id === container))
    // remove possible undefined values ind no id was found
    .filter(
      (container): container is Container => typeof container !== 'undefined',
    )
    // sort contents
    .sort((x, y) => x!.id - y!.id)

/**
 * @description This function gets a list of container objects from the selected coarse or fine fraction.
 * If fraction is passed, the list of containers comprises all containers from all fine fraction of the passed
 * coarse fraction. If a fine fraction is passed, all containers of this fine fraction are returned directly.
 */
export const getContainerItems = (
  fractionId,
  fineFractionId?: ReactText,
  fractionList: Fraction[] = [],
  containerList: Container[] = [],
  businessSegment = BUSINESS_SEGMENT.BUSINESS_EMPTO,
) => {
  // we need to filter the list based on the selected business segment.
  // Rules:
  // - When selecting "BUSINESS_ALL", show only generic containers
  // - When selecting "BUSINESS_EMPTO", show generic ones + empto containers
  // - When selecting "BUSINESS_EPD", show generic ones + epd containers
  const filteredContainerList = containerList.filter(container =>
    [businessSegment, BUSINESS_SEGMENT.BUSINESS_ALL].includes(
      Number(container.business_segment),
    ),
  )

  // Case a Fine Fraction is Selected
  if (fineFractionId && Number(fractionId) !== Number(fineFractionId)) {
    // We can directly return the array of containers from this fine fraction.
    const currentFineFraction = fractionList.find(
      fraction => fraction.id === Number(fineFractionId),
    )
    // get all the container ids associated with the current fine fraction
    return currentFineFraction
      ? getContainersById(currentFineFraction.containers, filteredContainerList)
      : []
  }
  // Case a Fine Fraction is not selected, only a Fraction (its parent)
  if (fractionId) {
    // Build a list of all containers of the fine fractions for this coarse fraction and remove duplicates.
    const fineFractions = fractionList.filter(
      fraction => fraction.parent && fraction.parent === fractionId,
    )

    // Some fraction in the list do not have child fractions (Coarse fractions without Fine Fractions)
    // That means that the containers are directly associated with this fraction and not with its child
    // In that case, the length of "fineFractions" variable is 0. We need to get the containers directly from the
    // Fraction
    if (fineFractions.length === 0) {
      const currentFraction = fractionList.find(
        fraction => Number(fractionId) === Number(fraction.id),
      )
      // get all the container ids associated with the current fraction
      return currentFraction
        ? getContainersById(currentFraction.containers, filteredContainerList)
        : []
    }

    // In case the fractions have associated fine fractions (child fractions)
    // Array of array of container
    const containerArrays = fineFractions.map(
      fractionItem => fractionItem.containers,
    )
    // Make flat array with containers and remove duplicates
    const arrayPrototype: number[] = Array.prototype
      .concat(...containerArrays)
      .filter(
        (container, index, self) =>
          self.findIndex(c => c === container) === index,
      )
    return getContainersById(arrayPrototype, filteredContainerList)
  }

  return [] // If neither fine not coarse fraction are passed, we have no containers.
}

/**
 * Gets the human readable name from the id of the constants for "time of day" if it is not "all day".
 * @param timeOfDay id of the constant
 */
export const getTimeOfDayNameIfNotAllDay = timeOfDay => {
  if (!timeOfDay || timeOfDay === INQUIRY_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY)
    return ''
  return I18n.t(getName(timeOfDay, INQUIRY_TIMES_OF_DAY))
}

/**
 * @description Gets the name of the container including its capacity from an array of containers
 */
export const getContainerName = (id, containers: Container[] = []) => {
  const item = containers.find(_item => `${_item.id}` === `${id}`)

  return item ? `${item.display_name}` : ''
}

/**
 * @description Gets the container|fraction object by id from the container list
 */
export const getFractionOrContainerDetailsById = <
  T extends AbstractInquiryItem,
>(
  id,
  array: T[],
) => array.find(_item => `${_item.id}` === `${id}`) as T

/**
 * @description Checks if a given container has top
 */
export const getContainerTop = (id, containers: Container[] = []) => {
  const item = containers.find(_item => `${_item.id}` === `${id}`)

  return item && item.with_top
}

/**
 * @description This function gets a list of indexes from the selected coarse or fine fraction.
 * @param {Object} offer
 * @param fractionList
 * @param indexList
 */
export const getIndexes = (
  offer: Offer,
  fractionList: Fraction[] = [],
  indexList: OfferIndex[] = [],
) => {
  const { fraction, coarseFraction } = getFractionsFromItem(offer, fractionList)

  /* Case a Fine Fraction is Selected */
  // In this case we can directly return the array of indexes from this fine fraction.
  // Because no fine fraction is selected, fraction become the same value as coarse fraction. In this case
  // we also have to state that, in order for this condition to run, the object in this variable should have a parent
  if (fraction && fraction.parent !== null)
    return indexList.filter(index => index.fractions.includes(fraction.id))

  /* Case a Fine Fraction is not selected, only a Fraction (its parent) is selected */
  return coarseFraction
    ? fractionList
        // Build a list of all the fine fractions for this coarse fraction and remove duplicates.
        .filter(_fraction => _fraction.parent === coarseFraction.id)
        // now, for each fine fraction, we need to get the indexes associated with it
        .map(_fraction =>
          indexList.filter(index => index.fractions.includes(_fraction.id)),
        )
        // make flat array
        .reduce((accumulator, index) => accumulator.concat(index), [])
        // remove duplicates
        .filter(
          (index, i, self) => self.findIndex(_i => _i.id === index.id) === i,
        )
    : []
}
