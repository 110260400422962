import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

import HeroSectionComponent from 'components/common/HeroSectionComponent'

import ImageDeviceDesktop from './img/device-desktop.png'
import ImageDeviceMobile from './img/device-mobile.png'
import ImageDeviceTablet from './img/device-tablet.png'

export const WastemanagerHeroSection: FC = () => (
  <HeroSectionComponent
    imageDeviceMobile={ImageDeviceMobile}
    imageDeviceTablet={ImageDeviceTablet}
    imageDeviceDesktop={ImageDeviceDesktop}
    headline={<Translate value='wastemanagerHeroSection.headline' />}
    content={
      <>
        <p className='wastemanager-hero-section__description'>
          <Translate value='wastemanagerHeroSection.description' />
        </p>

        {/* REGISTER (disabled in #3228) */}
        {/*<LinkButton*/}
        {/*  backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}*/}
        {/*  fontColor={BUTTON_FONT_COLOR.WHITE}*/}
        {/*  target={{*/}
        {/*    pathname: '/registrieren',*/}
        {/*    state: {*/}
        {/*      initialSelection: COMPANY_ROLE.WASTE_PRODUCER,*/}
        {/*    },*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <Translate value='wastemanagerHeroSection.callToAction' />*/}
        {/*  <Icon name={ICON_NAME.ROCKET} />*/}
        {/*</LinkButton>*/}
      </>
    }
  />
)
