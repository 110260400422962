import { I18n } from 'react-i18nify'

import { INQUIRY_STATUS } from '../components/inquiry/constants'
import { OFFER_STATUS } from '../components/offer/constants'

/**
 * @description get the translation for a constant array like COMPANY_ROLES
 * @param id
 * @param array
 * @param translate
 * @return {string}
 */
export const getName = (id, array = [], translate = false) => {
  const item = array.find(_item => `${_item.id}` === `${id}`)

  if (!item) return ''
  if (item && translate) return I18n.t(item.name)

  return item.name
}

/**
 * @description get the translation for the display name of an item in an array like the containers
 * @param id
 * @param array
 * @param translate
 * @return {string}
 */
export const getDisplayName = (id, array = [], translate = false) => {
  const item = array.find(_item => Number(_item.id) === Number(id))

  if (!item) return ''
  if (item && translate) return I18n.t(item.display_name)

  return item.display_name
}

/**
 * @description get the translation for the current status of an item
 * @param statusId
 * @param statusArray
 * @param [canceledByEmpto]
 * @return {string}
 */
export const getStatusName = (statusId, statusArray, canceledByEmpto) => {
  if (
    canceledByEmpto &&
    (statusId === OFFER_STATUS.STATUS_CANCELLED ||
      statusId === INQUIRY_STATUS.STATUS_CANCELLED)
  ) {
    return I18n.t('constant.status.cancelledByEmpto')
  }
  return getName(statusId, statusArray, true)
}

/**
 * @description method to change gender order
 * @param array
 * @param id
 * @return array
 */
export const changeGenderOrder = (array, id) => {
  array.unshift(
    array.splice(
      array.findIndex(item => item.id === id),
      1,
    )[0],
  )

  return array
}

export default {}
