import keyMirror from 'keymirror'

const UPLOAD_FILES = keyMirror({
  UPLOAD_FILES_REQUEST: null,
  UPLOAD_FILES_SUCCESS: null,
  UPLOAD_FILES_ERROR: null,
})

const UPLOAD_IMAGE_PROPERTIES = keyMirror({
  UPLOAD_IMAGE_PROPERTIES: null,
})

export default {
  ...UPLOAD_FILES,
  ...UPLOAD_IMAGE_PROPERTIES,
}
