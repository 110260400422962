import 'components/offer/MyAutoOffersPage/style.scss'

import { useFormikContext } from 'formik'
import moment from 'moment/moment'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import InputDate from 'components/common/InputDate'
import { ProgressButton } from 'components/common/ProgressButton'
import { Textarea } from 'components/common/Textarea'

import { SetCreditreformFormWrapperValues } from './SetCreditreformFormWrapper'

interface SetCreditreformFormProps {
  onClose?: () => void
}

export const SetCreditreformForm: FC<SetCreditreformFormProps> = ({
  onClose = () => undefined,
}) => {
  const {
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldTouched,
    setFieldValue,
    submitCount,
    touched,
    values,
  } = useFormikContext<SetCreditreformFormWrapperValues>()

  return (
    <>
      <div
        className='uk-modal-body bookkeeping-list__form_modal-fields'
        data-testid='bookkeeping-list__form_modal-fields'
      >
        <InputDate
          error={
            submitCount > 0 &&
            values.status_change_set_at === undefined &&
            touched.status_change_set_at &&
            errors.status_change_set_at
              ? errors.status_change_set_at
              : ''
          }
          placeholder={I18n.t(
            'bookkeepingTranslations.creditreformModal.fields.creditreformSetAtPlaceHolder',
          )}
          label={I18n.t(
            'bookkeepingTranslations.creditreformModal.fields.creditreformSetAt',
          )}
          isRequired={!values.status_change_set_at}
          maxDate={moment()}
          name='status_change_set_at'
          onChange={value => {
            setFieldValue('status_change_set_at', value.format('L'))
            setFieldTouched('status_change_set_at')
          }}
          value={values.status_change_set_at}
        />
        <Textarea
          label={I18n.t(
            'bookkeepingTranslations.creditreformModal.fields.creditreformComment',
          )}
          maxLength={500}
          name='status_change_comment'
          onChange={handleChange}
          placeholder={I18n.t(
            'bookkeepingTranslations.creditreformModal.fields.creditreformCommentPlaceHolder',
          )}
          showRemainingCharacters
          value={values.status_change_comment}
        />
      </div>

      <div className='uk-modal-footer uk-text-right uk-padding-remove-right'>
        <Button
          className='uk-button uk-margin-medium-right'
          backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
          onClick={onClose}
        >
          <Translate value='general.button.cancel' />
        </Button>

        <ProgressButton
          className='uk-button uk-margin-medium-right'
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          isDisabled={
            submitCount > 0 &&
            !isValid &&
            values.status_change_set_at === undefined
          }
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          <Translate value='general.button.save' />
        </ProgressButton>
      </div>
    </>
  )
}
