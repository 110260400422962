import './style.scss'

import React, { FC } from 'react'
import { Translate } from 'react-i18nify'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'

import withErrorBoundary from 'helper/withErrorBoundary'

import { Instruction } from '../../common/Instruction'
import { PAGE_DIMENSION_ID } from '../../common/PageDimensions'

import connector from './connector'

interface PageFooterComponentProps extends RouteComponentProps {
  isUserLoading: boolean
  userRole: number
  permissions: string[]
}

const PageFooterComponent: FC<PageFooterComponentProps> = ({
  isUserLoading,
}) => {
  if (isUserLoading) {
    return null
  }

  return (
    <footer
      className='page-footer'
      data-page-dimension-id={PAGE_DIMENSION_ID.FOOTER}
    >
      <div className='uk-container uk-container-large'>
        <div className='page-footer__inner'>
          <nav>
            <ul>
              <li>
                <NavLink to='/impressum' className='uk-link-text'>
                  <Translate value='footer.imprint' />
                </NavLink>
              </li>
              <li>
                <NavLink to='/datenschutz' className='uk-link-text'>
                  <Translate value='footer.privacy' />
                </NavLink>
              </li>
              <li>
                <NavLink to='/rechtliche-hinweise' className='uk-link-text'>
                  <Translate value='footer.legalHint' />
                </NavLink>
              </li>
              <li>
                <NavLink to='/downloads' className='uk-link-text'>
                  <Translate value='menu.downloads' />
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Instruction />
    </footer>
  )
}

export const PageFooter = withRouter(
  withErrorBoundary(connector(PageFooterComponent)),
)
