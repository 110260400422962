import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { ICON_NAME } from 'components/common/Fontello'
import { ICON_POSITION, IconButton } from 'components/common/IconButton'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { PageHeader } from 'components/layout/PageHeader'
import { UserPermission } from 'constants/user'
import { createLoadingSelector } from 'selectors/loading'

import { CollectiveInvoicingPageContext } from '../../CollectiveInvoicingPage'

export const Header: FC = () => {
  const isLoading = {
    CollectiveInvoicingForecastExport: useSelector(
      createLoadingSelector(['EXPORT_EPD_COLLECTIVE_INVOICING_FORECAST']),
    ),
    CollectiveInvoicingExport: useSelector(
      createLoadingSelector(['EXPORT_EPD_COLLECTIVE_INVOICING']),
    ),
    CollectiveInvoicingActivitiesExport: useSelector(
      createLoadingSelector(['EXPORT_EPD_COLLECTIVE_INVOICING_ACTIVITIES']),
    ),
  }

  return (
    <CollectiveInvoicingPageContext.Consumer>
      {context => (
        <div className='page-header'>
          <PageHeader title={I18n.t('pageTitles.collectiveInvoicing')}>
            <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
              <RequiredPermissions
                requiredPermissions={[
                  UserPermission.CREATE_COLLECTIVE_INVOICING,
                ]}
              >
                <IconButton
                  backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                  iconName={ICON_NAME.PLUS}
                  iconPosition={ICON_POSITION.RIGHT}
                  onClick={() => {
                    context.setIsTotalInvoiceModalOpen(true)
                  }}
                >
                  <Translate value='collectiveInvoicingTranslations.totalInvoice.button' />
                </IconButton>
              </RequiredPermissions>
              <RequiredPermissions
                requiredPermissions={[
                  UserPermission.EXPORT_EPD_COLLECTIVE_INVOICING,
                ]}
              >
                <IconButton
                  iconName='export'
                  iconPosition={ICON_POSITION.RIGHT}
                  onClick={() => {
                    context.setIsCollectiveInvoicingModalOpen(true)
                  }}
                  isLoading={isLoading.CollectiveInvoicingExport}
                >
                  <Translate value='collectiveInvoicingTranslations.exportCollectiveInvoicingCompanies.exportButton' />
                </IconButton>
              </RequiredPermissions>
              <RequiredPermissions
                requiredPermissions={[
                  UserPermission.EXPORT_EPD_COLLECTIVE_INVOICING,
                ]}
              >
                <IconButton
                  iconName='export'
                  iconPosition={ICON_POSITION.RIGHT}
                  onClick={() => {
                    context.setIsCollectiveInvoicingActivitiesModalOpen(true)
                  }}
                  isLoading={isLoading.CollectiveInvoicingActivitiesExport}
                >
                  <Translate value='collectiveInvoicingTranslations.exportCollectiveInvoicingActivities.exportButton' />
                </IconButton>
              </RequiredPermissions>
              <RequiredPermissions
                requiredPermissions={[
                  UserPermission.EXPORT_EPD_COLLECTIVE_INVOICING_FORECAST,
                ]}
              >
                <IconButton
                  iconName='export'
                  iconPosition={ICON_POSITION.RIGHT}
                  onClick={() => {
                    context.setIsCollectiveInvoicingForecastModalOpen(true)
                  }}
                  isLoading={isLoading.CollectiveInvoicingForecastExport}
                >
                  <Translate value='collectiveInvoicingTranslations.collectiveInvoicingForecastExport.exportButton' />
                </IconButton>
              </RequiredPermissions>
            </ButtonBar>
          </PageHeader>
        </div>
      )}
    </CollectiveInvoicingPageContext.Consumer>
  )
}
