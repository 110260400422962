import ActionTypes from '../actiontypes/core'

export const initialState = {
  item: {},
  instructionList: {
    data: [],
  },
}

/**
 * @description A reducer for instruction.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
export const instructionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_INSTRUCTIONS_SUCCESS:
      return {
        ...state,
        instructionList: {
          data: action.payload,
        },
      }
    default:
      return state
  }
}
