import ActionTypes from '../actiontypes/certificate'

/** @function
 * @name addCertificateType
 * @description Loads the current certificate type to the store. This is used when clicking on the button to
 * upload a certificate, to know which type the user is uploading. Used in company info and profile, this as
 * previously handled by react-redux-forms
 * @return {Object}
 */
export const addCertificateType = certificateType => ({
  type: ActionTypes.ADD_CERTIFICATE_TYPE_REQUEST,
  payload: certificateType,
})

/** @function
 * @name getMyCertificates
 * @description Loads a list of certificates that were created by my company.
 * @return {Object}
 */
export const getMyCertificates = (page, filters, sortedBy) => ({
  type: ActionTypes.GET_MY_CERTIFICATES_REQUEST,
  payload: {
    page,
    filters,
    sortedBy,
  },
})

/** @function
 * @name getMyLatestCertificates
 * @description Gets an array with the latest version of each type of certificate (if existing) for my company.
 * This is used to display the current status of each certificate in the company details page.
 * @return {Object}
 */
export const getMyLatestCertificates = () => ({
  type: ActionTypes.GET_MY_LATEST_CERTIFICATES_REQUEST,
})

/** @function
 * @name getCompaniesPriceAgreements
 * @description Gets an array with a boolean value if each company has a price agreement.
 * This is used to display the priceAgreement Icon on the MaklerPremium Customer Pages.
 * @return {Object}
 */
export const getCompaniesPriceAgreements = companyList => ({
  type: ActionTypes.GET_COMPANY_PRICE_AGREEMENTS_REQUEST,
  payload: companyList,
})

/** @function
 * @name getCompanyLatestCertificates
 * @description Gets an array with the latest version of each type of certificate (if existing) filtered by company ID.
 * This is used to display the current status of each certificate in the company info page.
 * @return {Object}
 */
export const getCompanyLatestCertificates = (
  companyId,
  sortedBy = [{ id: 'id', desc: true }],
) => ({
  type: ActionTypes.GET_COMPANY_LATEST_CERTIFICATES_REQUEST,
  payload: {
    companyId,
    sortedBy,
  },
})

/** @function
 * @name getCertificates
 * @description Loads a list of certificates.
 * @return {Object}
 */
export const getCertificates = (page, filters, sortedBy) => ({
  type: ActionTypes.GET_CERTIFICATES_REQUEST,
  payload: {
    page,
    filters,
    sortedBy,
  },
})

/** @function
 * @name getCertificate
 * @description Loads a certificate.
 * @param id of a certificate.
 * @return {Object}
 */
export const getCertificate = id => ({
  type: ActionTypes.GET_CERTIFICATE_REQUEST,
  payload: id,
})

/** @function
 * @name createCertificate
 * @description Creates a new version of a certificate. This can only be used if there is no pending version of the
 * same type existing.
 * @return {Object}
 */
export const createCertificate = (
  certificate,
  documentType,
  isDocument = false,
) => ({
  documentType,
  isDocument,
  type: ActionTypes.CREATE_CERTIFICATE_REQUEST,
  payload: certificate,
})

/** @function
 * @name updateCertificate
 * @description Updates a certificate.
 * This can only be used if there is no pending version of this type yet.
 * @param certificate
 * @return {Object}
 */
export const updateCertificate = certificate => ({
  type: ActionTypes.UPDATE_CERTIFICATE_REQUEST,
  payload: certificate,
})

/** @function
 * @name updateReviewedCertificate
 * @description Updates a certificate after review.
 * @return {Object}
 */
export const updateReviewedCertificate = (certificate, history, user) => ({
  type: ActionTypes.UPDATE_REVIEWED_CERTIFICATE_REQUEST,
  payload: {
    certificate,
    history,
    user,
  },
})

/**
 * @function
 * @name exportCertificates
 * @return {Object}
 */
export const exportCertificates = () => ({
  type: ActionTypes.EXPORT_CERTIFICATES_REQUEST,
})

/**
 * @function
 * @name resetCertificateItem
 * @return {Object}
 */
export const resetCertificateItem = () => ({
  type: ActionTypes.RESET_CERTIFICATE_ITEM_REQUEST,
})

export const archiveCertificate = certificate => ({
  type: ActionTypes.ARCHIVE_CERTIFICATE_REQUEST,
  payload: certificate,
})

export const getArchivedCertificates = (page, filters, sortedBy) => ({
  type: ActionTypes.GET_ARCHIVED_CERTIFICATES_REQUEST,
  payload: {
    page,
    filters,
    sortedBy,
  },
})
