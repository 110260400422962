import './style.scss'

import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'

import { TooltipIcon, TooltipIconProps } from '../../common/TooltipIcon'

interface RadioButtonProps {
  dataTestId?: string
  dataTestIdError?: string
  error?: string
  isChecked?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  justifyCenter?: boolean
  label: React.ReactNode
  name: string
  onChange: React.ChangeEventHandler
  tooltip?: TooltipIconProps
}

/**
 * @description This component renders and controls a radiobutton component.
 */
export const RadioButton: FC<RadioButtonProps> = ({
  error,
  dataTestId,
  dataTestIdError,
  isChecked = false,
  isDisabled = false,
  isRequired = false,
  justifyCenter = false,
  label,
  name,
  onChange,
  tooltip,
}) => {
  const id = uniqueId()

  return (
    <div
      className={classNames(
        'radiobutton',
        { 'radiobutton--justify-center': justifyCenter },
        { 'radiobutton--with-error': error },
      )}
    >
      <input
        className='uk-radio radiobutton__input'
        checked={isChecked}
        data-testid={dataTestId}
        disabled={isDisabled}
        id={id}
        name={name}
        onChange={onChange}
        required={isRequired}
        type='radio'
        tabIndex={0}
      />

      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label className='radiobutton__label' htmlFor={id}>
        {label}
      </label>

      {tooltip && (
        <div className='radiobutton__tooltip'>
          <TooltipIcon {...tooltip} />
        </div>
      )}
      {error && error !== '' && (
        <div className='input__error' data-testid={dataTestIdError}>
          {error}
        </div>
      )}
    </div>
  )
}
