import ActionTypes from '../actiontypes/app'

/** @function
 * @name acceptCookies
 * @description An action method to set the cookieAccepted flag.
 * @return {Object}
 */
export const acceptCookies = () => ({
  type: ActionTypes.ACCEPT_COOKIES,
})

/** @function
 * @name toggleMobileMenu
 * @description An action method to toggle mobile menu.
 * @return {Object}
 */
export const toggleMobileMenu = isVisible => ({
  type: ActionTypes.TOGGLE_MOBILE_MENU,
  payload: isVisible,
})

/** @function
 * @name resetApiFetchErrors
 * @description An action method to reset API fetch errors on form reset.
 * @return {Object}
 */
export const resetApiFetchErrors = type => ({
  type: ActionTypes.RESET_API_FETCH_ERRORS,
  payload: type,
})

/** @function
 * @name resetApiFetchLoading
 * @description An action method to reset API fetch loading state on form reset.
 * @return {Object}
 */
export const resetApiFetchLoading = type => ({
  type: ActionTypes.RESET_API_FETCH_LOADING,
  payload: type,
})
