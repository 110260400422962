import keyMirror from 'keymirror'

import {
  ORDER_CLAIM_TYPE,
  ORDER_CLAIM_TYPES_OBJECT,
} from './OrderDetailsPage/constants'

export const ORDER_DETAILS_MODALS = keyMirror({
  SHOW_PRICE_FIELDS_MODAL: null,
})

export const UPCOMING_ORDER_STATUS = {
  STATUS_OPEN: 1,
  STATUS_CONFIRMED_ACTIVE: 2,
  STATUS_CONFIRMED_INQUIRED: 3,
}

export const UPCOMING_ORDER_STATES = [
  {
    id: UPCOMING_ORDER_STATUS.STATUS_OPEN,
    name: 'upcomingOrdersTranslations.upcomingOrderStatus.open',
  },
  {
    id: UPCOMING_ORDER_STATUS.STATUS_CONFIRMED_ACTIVE,
    name: 'upcomingOrdersTranslations.upcomingOrderStatus.confirmedActive',
  },
  {
    id: UPCOMING_ORDER_STATUS.STATUS_CONFIRMED_INQUIRED,
    name: 'upcomingOrdersTranslations.upcomingOrderStatus.confirmedInquired',
  },
]

export const UPCOMING_ORDER_TYPE_ACTIONS = {
  COLLECTION: 'Abholung',
  DELIVERY: 'Stellung',
  SWITCH: 'Tausch/Leerung',
  DEPARTURE: 'Abzug',
  QUICK: 'Sofortbefüllung',
}

export const UPCOMING_ORDER_TYPE_ACTIONS_CHOICES = [
  {
    id: UPCOMING_ORDER_TYPE_ACTIONS.COLLECTION,
    name: UPCOMING_ORDER_TYPE_ACTIONS.COLLECTION,
  },
  {
    id: UPCOMING_ORDER_TYPE_ACTIONS.DELIVERY,
    name: UPCOMING_ORDER_TYPE_ACTIONS.DELIVERY,
  },
  {
    id: UPCOMING_ORDER_TYPE_ACTIONS.SWITCH,
    name: UPCOMING_ORDER_TYPE_ACTIONS.SWITCH,
  },
  {
    id: UPCOMING_ORDER_TYPE_ACTIONS.DEPARTURE,
    name: UPCOMING_ORDER_TYPE_ACTIONS.DEPARTURE,
  },
  {
    id: UPCOMING_ORDER_TYPE_ACTIONS.QUICK,
    name: UPCOMING_ORDER_TYPE_ACTIONS.QUICK,
  },
]

/**
 * every key with a number as value is a status from the backend. Every key with a string-value is a bridge to
 * set different translations for different order types
 */
export const ORDER_STATUS = {
  AEZ_ORDERED: 10,

  DISPOSER_WILL_EXECUTE: 20,
  DISPOSER_WILL_EXECUTE_ONE_TIME: 'DISPOSER_WILL_EXECUTE_ONE_TIME',
  DISPOSER_WILL_EXECUTE_QUICK: 'DISPOSER_WILL_EXECUTE_QUICK',
  DISPOSER_WILL_EXECUTE_REGULAR: 'DISPOSER_WILL_EXECUTE_REGULAR',

  DISPOSER_DID_NOT_DISPOSE: 30,
  DISPOSER_DID_DISPOSE: 31,
  DISPOSER_DID_DISPOSE_WITH_DATE: 32,

  STATUS_PRODUCER_DID_POSTPONE_WEEKDAY: 33,
  STATUS_PRODUCER_DID_POSTPONE_SECOND_WEEKDAY: 34,
  DISPOSER_DID_POSTPONE_DELIVERY: 35,
  DISPOSER_DID_POSTPONE_DELIVERY_ONE_TIME:
    'DISPOSER_DID_POSTPONE_DELIVERY_ONE_TIME',
  DISPOSER_DID_POSTPONE_COLLECTION: 36,
  DISPOSER_CHANGED: 37,

  DISPOSER_WILL_ARRIVE_DELIVERY: 40,
  DISPOSER_WILL_ARRIVE_DELIVERY_ONE_TIME:
    'DISPOSER_WILL_ARRIVE_DELIVERY_ONE_TIME',
  DISPOSER_WILL_ARRIVE_COLLECTION: 41,

  DISPOSER_DID_EXECUTE_DELIVERY: 50,
  DISPOSER_DID_EXECUTE_DELIVERY_ONE_TIME:
    'DISPOSER_DID_EXECUTE_DELIVERY_ONE_TIME',
  DISPOSER_DID_EXECUTE_COLLECTION: 51,
  DISPOSER_SUBMITTED_EXECUTION_PROOFS: 59,

  EXECUTION_PROOFS_PENDING: 60,
  EXECUTION_PROOFS_REJECTED: 61,
  EXECUTION_PROOFS_REJECTED_PRODUCER: 62,
  EXECUTION_PROOFS_ACCEPTED: 65,

  AEZ_RATED_DISPOSER: 100,
  DISPOSER_RATED_PRODUCER: 101,

  AEZ_CANCELLED: 110,
  CANCELLED_EMPTO: 111,
  CANCELLED_EPD: 112,

  COMPLAINED_ORDER_PRODUCER: 120,
  COMPLAINED_MISSING_EXECUTION_REPORT_EMPTO: 121,
  COMPLAINED_MISSING_EXECUTION_REPORT_PRODUCER: 122,
  COMPLAINED_MISSING_EXECUTION_PROOF_EMPTO: 123,
  COMPLAINED_MISSING_CERTIFICATES_EMPTO: 125,
  COMPLAINED_BILLING_PRODUCER: 128,
  COMPLAINED_BILLING_DISPOSER: 129,

  EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE: 130,

  INVOICING_DOCUMENTS_CREATED: 140,
  OTHER_INVOICING_DOCUMENTS_CREATED: 145,

  CLOSED: 9000,
  ...ORDER_CLAIM_TYPE,
  OTHER: -9999,
}

export const RELABELED_TYPES = {
  RELABELED: 'relabeled',
  CONTAINER_CHANGED: 'container_changed',
  CONTAINER_COUNT_CHANGED: 'container_count_changed',
  ADDITIONAL_RENT: 'additional_rent',
}

export const EXECUTION_REVIEW_TYPES_OBJECT = {
  [ORDER_STATUS.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE]: {
    id: ORDER_STATUS.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE,
    name: 'constant.orderStatus.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE',
    message:
      'constant.orderStatusMessage.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
  [RELABELED_TYPES.RELABELED]: {
    id: ORDER_STATUS.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE,
    name: 'constant.orderStatus.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE',
    message:
      'constant.orderStatusMessage.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE_RELABELED',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
  [RELABELED_TYPES.CONTAINER_CHANGED]: {
    id: ORDER_STATUS.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE,
    name: 'constant.orderStatus.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE',
    message:
      'constant.orderStatusMessage.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE_CONTAINER_CHANGED',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
  [RELABELED_TYPES.CONTAINER_COUNT_CHANGED]: {
    id: ORDER_STATUS.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE,
    name: 'constant.orderStatus.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE',
    message:
      'constant.orderStatusMessage.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE_CONTAINER_AMOUNT_CHANGED',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
  [RELABELED_TYPES.ADDITIONAL_RENT]: {
    id: ORDER_STATUS.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE,
    name: 'constant.orderStatus.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE',
    message:
      'constant.orderStatusMessage.EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE_ADDITIONAL_RENT',
    progress: 100,
    totalProgress: 0,
    excludeFromFilter: true,
  },
}

export const EXECUTION_REVIEW_TYPES = Object.values(
  EXECUTION_REVIEW_TYPES_OBJECT,
)

export const ORDER_STATES_OBJECT = {
  [ORDER_STATUS.AEZ_ORDERED]: {
    id: ORDER_STATUS.AEZ_ORDERED,
    name: 'constant.orderStatus.AEZ_ORDERED',
    message: 'constant.orderStatusMessage.AEZ_ORDERED',
    progress: 100,
    totalProgress: 10,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_WILL_EXECUTE]: {
    id: ORDER_STATUS.DISPOSER_WILL_EXECUTE,
    name: 'constant.orderStatus.DISPOSER_WILL_EXECUTE',
    message: 'constant.orderStatusMessage.DISPOSER_WILL_EXECUTE',
    progress: 100,
    totalProgress: 20,
  },
  [ORDER_STATUS.DISPOSER_WILL_EXECUTE_ONE_TIME]: {
    id: ORDER_STATUS.DISPOSER_WILL_EXECUTE_ONE_TIME,
    name: 'constant.orderStatus.DISPOSER_WILL_EXECUTE',
    message: 'constant.orderStatusMessage.DISPOSER_WILL_EXECUTE_ONE_TIME',
    progress: 100,
    totalProgress: 20,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_WILL_EXECUTE_QUICK]: {
    id: ORDER_STATUS.DISPOSER_WILL_EXECUTE_QUICK,
    name: 'constant.orderStatus.DISPOSER_WILL_EXECUTE',
    message: 'constant.orderStatusMessage.DISPOSER_WILL_EXECUTE_QUICK',
    progress: 100,
    totalProgress: 20,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_WILL_EXECUTE_REGULAR]: {
    id: ORDER_STATUS.DISPOSER_WILL_EXECUTE_REGULAR,
    name: 'constant.orderStatus.DISPOSER_WILL_EXECUTE',
    message: 'constant.orderStatusMessage.DISPOSER_WILL_EXECUTE_REGULAR',
    progress: 100,
    totalProgress: 20,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_DID_NOT_DISPOSE]: {
    id: ORDER_STATUS.DISPOSER_DID_NOT_DISPOSE,
    name: 'constant.orderStatus.DISPOSER_DID_NOT_DISPOSE',
    message: 'constant.orderStatusMessage.DISPOSER_DID_NOT_DISPOSE',
    hideIfNotLatest: true,
    progress: 50,
    totalProgress: 20,
  },
  [ORDER_STATUS.DISPOSER_DID_DISPOSE]: {
    id: ORDER_STATUS.DISPOSER_DID_DISPOSE,
    name: 'constant.orderStatus.DISPOSER_DID_DISPOSE',
    message: 'constant.orderStatusMessage.DISPOSER_DID_DISPOSE',
    progress: 100,
    totalProgress: 30,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_DID_DISPOSE_WITH_DATE]: {
    id: ORDER_STATUS.DISPOSER_DID_DISPOSE_WITH_DATE,
    name: 'constant.orderStatus.DISPOSER_DID_DISPOSE_WITH_DATE',
    message: 'constant.orderStatusMessage.DISPOSER_DID_DISPOSE_WITH_DATE',
    progress: 100,
    totalProgress: 30,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.STATUS_PRODUCER_DID_POSTPONE_WEEKDAY]: {
    id: ORDER_STATUS.STATUS_PRODUCER_DID_POSTPONE_WEEKDAY,
    name: 'constant.orderStatus.POSTPONE_WEEKDAY',
    message: 'constant.orderStatusMessage.POSTPONE_WEEKDAY',
    progress: 100,
    totalProgress: 30,
  },
  [ORDER_STATUS.STATUS_PRODUCER_DID_POSTPONE_SECOND_WEEKDAY]: {
    id: ORDER_STATUS.STATUS_PRODUCER_DID_POSTPONE_SECOND_WEEKDAY,
    name: 'constant.orderStatus.POSTPONE_WEEKDAY_SECOND',
    message: 'constant.orderStatusMessage.POSTPONE_WEEKDAY_SECOND',
    progress: 100,
    totalProgress: 30,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_DID_POSTPONE_DELIVERY]: {
    id: ORDER_STATUS.DISPOSER_DID_POSTPONE_DELIVERY,
    name: 'constant.orderStatus.DISPOSER_DID_POSTPONE_DELIVERY',
    message: 'constant.orderStatusMessage.DISPOSER_DID_POSTPONE_DELIVERY',
    progress: 100,
    totalProgress: 30,
  },
  [ORDER_STATUS.DISPOSER_DID_POSTPONE_DELIVERY_ONE_TIME]: {
    id: ORDER_STATUS.DISPOSER_DID_POSTPONE_DELIVERY_ONE_TIME,
    name: 'constant.orderStatus.DISPOSER_DID_POSTPONE_DELIVERY',
    message:
      'constant.orderStatusMessage.DISPOSER_DID_POSTPONE_DELIVERY_ONE_TIME',
    progress: 100,
    totalProgress: 30,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_DID_POSTPONE_COLLECTION]: {
    id: ORDER_STATUS.DISPOSER_DID_POSTPONE_COLLECTION,
    name: 'constant.orderStatus.DISPOSER_DID_POSTPONE_COLLECTION',
    message: 'constant.orderStatusMessage.DISPOSER_DID_POSTPONE_COLLECTION',
    progress: 100,
    totalProgress: 30,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_CHANGED]: {
    id: ORDER_STATUS.DISPOSER_CHANGED,
    name: 'constant.orderStatus.DISPOSER_CHANGED',
    message: 'constant.orderStatusMessage.DISPOSER_CHANGED',
    progress: 100,
    totalProgress: 30,
  },
  [ORDER_STATUS.DISPOSER_WILL_ARRIVE_DELIVERY]: {
    id: ORDER_STATUS.DISPOSER_WILL_ARRIVE_DELIVERY,
    name: 'constant.orderStatus.DISPOSER_WILL_ARRIVE_DELIVERY',
    message: 'constant.orderStatusMessage.DISPOSER_WILL_ARRIVE_DELIVERY',
    progress: 100,
    totalProgress: 40,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_WILL_ARRIVE_DELIVERY_ONE_TIME]: {
    id: ORDER_STATUS.DISPOSER_WILL_ARRIVE_DELIVERY_ONE_TIME,
    name: 'constant.orderStatus.DISPOSER_WILL_ARRIVE_DELIVERY_ONE_TIME',
    message:
      'constant.orderStatusMessage.DISPOSER_WILL_ARRIVE_DELIVERY_ONE_TIME',
    progress: 100,
    totalProgress: 40,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_WILL_ARRIVE_COLLECTION]: {
    id: ORDER_STATUS.DISPOSER_WILL_ARRIVE_COLLECTION,
    name: 'constant.orderStatus.DISPOSER_WILL_ARRIVE_COLLECTION',
    message: 'constant.orderStatusMessage.DISPOSER_WILL_ARRIVE_COLLECTION',
    progress: 100,
    totalProgress: 40,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_DID_EXECUTE_DELIVERY]: {
    id: ORDER_STATUS.DISPOSER_DID_EXECUTE_DELIVERY,
    name: 'constant.orderStatus.DISPOSER_DID_EXECUTE_DELIVERY',
    message: 'constant.orderStatusMessage.DISPOSER_DID_EXECUTE_DELIVERY',
    progress: 100,
    totalProgress: 60,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_DID_EXECUTE_DELIVERY_ONE_TIME]: {
    id: ORDER_STATUS.DISPOSER_DID_EXECUTE_DELIVERY_ONE_TIME,
    name: 'constant.orderStatus.DISPOSER_DID_EXECUTE_DELIVERY_ONE_TIME',
    message:
      'constant.orderStatusMessage.DISPOSER_DID_EXECUTE_DELIVERY_ONE_TIME',
    progress: 100,
    totalProgress: 60,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_DID_EXECUTE_COLLECTION]: {
    id: ORDER_STATUS.DISPOSER_DID_EXECUTE_COLLECTION,
    name: 'constant.orderStatus.DISPOSER_DID_EXECUTE_COLLECTION',
    message: 'constant.orderStatusMessage.DISPOSER_DID_EXECUTE_COLLECTION',
    progress: 100,
    totalProgress: 60,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_SUBMITTED_EXECUTION_PROOFS]: {
    id: ORDER_STATUS.DISPOSER_SUBMITTED_EXECUTION_PROOFS,
    name: 'constant.orderStatus.DISPOSER_SUBMITTED_EXECUTION_PROOFS',
    message: 'constant.orderStatusMessage.DISPOSER_SUBMITTED_EXECUTION_PROOFS',
    progress: 100,
    totalProgress: 70,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.EXECUTION_PROOFS_PENDING]: {
    id: ORDER_STATUS.EXECUTION_PROOFS_PENDING,
    name: 'constant.orderStatus.EXECUTION_PROOFS_PENDING',
    message: 'constant.orderStatusMessage.EXECUTION_PROOFS_PENDING',
    hideIfNotLatest: true,
    progress: 50,
    totalProgress: 70,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.EXECUTION_PROOFS_REJECTED]: {
    id: ORDER_STATUS.EXECUTION_PROOFS_REJECTED,
    name: 'constant.orderStatus.EXECUTION_PROOFS_REJECTED',
    message: 'constant.orderStatusMessage.EXECUTION_PROOFS_REJECTED',
    progress: 100,
    totalProgress: 80,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.EXECUTION_PROOFS_REJECTED_PRODUCER]: {
    id: ORDER_STATUS.EXECUTION_PROOFS_REJECTED_PRODUCER,
    name: 'constant.orderStatus.EXECUTION_PROOFS_REJECTED_PRODUCER',
    message: 'constant.orderStatusMessage.EXECUTION_PROOFS_REJECTED_PRODUCER',
    progress: 100,
    totalProgress: 80,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.EXECUTION_PROOFS_ACCEPTED]: {
    id: ORDER_STATUS.EXECUTION_PROOFS_ACCEPTED,
    name: 'constant.orderStatus.EXECUTION_PROOFS_ACCEPTED',
    message: 'constant.orderStatusMessage.EXECUTION_PROOFS_ACCEPTED',
    progress: 100,
    totalProgress: 90,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.AEZ_RATED_DISPOSER]: {
    id: ORDER_STATUS.AEZ_RATED_DISPOSER,
    name: 'constant.orderStatus.AEZ_RATED_DISPOSER',
    message: 'constant.orderStatusMessage.AEZ_RATED_DISPOSER',
    progress: 100,
    totalProgress: 95,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.DISPOSER_RATED_PRODUCER]: {
    id: ORDER_STATUS.DISPOSER_RATED_PRODUCER,
    name: 'constant.orderStatus.DISPOSER_RATED_PRODUCER',
    message: 'constant.orderStatusMessage.DISPOSER_RATED_PRODUCER',
    progress: 100,
    totalProgress: 95,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.AEZ_CANCELLED]: {
    id: ORDER_STATUS.AEZ_CANCELLED,
    name: 'constant.orderStatus.AEZ_CANCELLED',
    message: 'constant.orderStatusMessage.AEZ_CANCELLED',
    progress: 100,
    totalProgress: 100,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.COMPLAINED_ORDER_PRODUCER]: {
    id: ORDER_STATUS.COMPLAINED_ORDER_PRODUCER,
    name: 'constant.orderStatus.COMPLAINED_ORDER_PRODUCER',
    message: 'constant.orderStatusMessage.COMPLAINED_ORDER_PRODUCER',
    progress: 100,
    totalProgress: -1,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.COMPLAINED_MISSING_EXECUTION_REPORT_EMPTO]: {
    id: ORDER_STATUS.COMPLAINED_MISSING_EXECUTION_REPORT_EMPTO,
    name: 'constant.orderStatus.COMPLAINED_MISSING_EXECUTION_REPORT_EMPTO',
    message:
      'constant.orderStatusMessage.COMPLAINED_MISSING_EXECUTION_REPORT_EMPTO',
    progress: 100,
    totalProgress: -1,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.COMPLAINED_MISSING_EXECUTION_REPORT_PRODUCER]: {
    id: ORDER_STATUS.COMPLAINED_MISSING_EXECUTION_REPORT_PRODUCER,
    name: 'constant.orderStatus.COMPLAINED_MISSING_EXECUTION_REPORT_PRODUCER',
    message:
      'constant.orderStatusMessage.COMPLAINED_MISSING_EXECUTION_REPORT_PRODUCER',
    progress: 100,
    totalProgress: -1,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.COMPLAINED_MISSING_EXECUTION_PROOF_EMPTO]: {
    id: ORDER_STATUS.COMPLAINED_MISSING_EXECUTION_PROOF_EMPTO,
    name: 'constant.orderStatus.COMPLAINED_MISSING_EXECUTION_PROOF_EMPTO',
    message:
      'constant.orderStatusMessage.COMPLAINED_MISSING_EXECUTION_PROOF_EMPTO',
    progress: 100,
    totalProgress: -1,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.COMPLAINED_BILLING_PRODUCER]: {
    id: ORDER_STATUS.COMPLAINED_BILLING_PRODUCER,
    name: 'constant.orderStatus.COMPLAINED_BILLING_PRODUCER',
    message: 'constant.orderStatusMessage.COMPLAINED_BILLING_PRODUCER',
    progress: 100,
    totalProgress: -1,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.COMPLAINED_BILLING_DISPOSER]: {
    id: ORDER_STATUS.COMPLAINED_BILLING_DISPOSER,
    name: 'constant.orderStatus.COMPLAINED_BILLING_DISPOSER',
    message: 'constant.orderStatusMessage.COMPLAINED_BILLING_DISPOSER',
    progress: 100,
    totalProgress: -1,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.CANCELLED_EMPTO]: {
    id: ORDER_STATUS.CANCELLED_EMPTO,
    name: 'constant.orderStatus.CANCELLED_EMPTO',
    message: 'constant.orderStatusMessage.CANCELLED_EMPTO',
    progress: 100,
    totalProgress: -1,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.CANCELLED_EPD]: {
    id: ORDER_STATUS.CANCELLED_EPD,
    name: 'constant.orderStatus.CANCELLED_EPD',
    message: 'constant.orderStatusMessage.CANCELLED_EPD',
    progress: 100,
    totalProgress: -1,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.CLOSED]: {
    id: ORDER_STATUS.CLOSED,
    name: 'constant.orderStatus.CLOSED',
    message: 'constant.orderStatusMessage.CLOSED',
    progress: 100,
    totalProgress: 100,
    excludeFromFilter: true,
  },
  [ORDER_STATUS.INVOICING_DOCUMENTS_CREATED]: {
    id: ORDER_STATUS.INVOICING_DOCUMENTS_CREATED,
    name: 'constant.orderStatus.INVOICING_DOCUMENTS_CREATED',
    message: 'constant.orderStatusMessage.INVOICING_DOCUMENTS_CREATED',
    progress: ORDER_STATUS.INVOICING_DOCUMENTS_CREATED,
    totalProgress: ORDER_STATUS.INVOICING_DOCUMENTS_CREATED,
  },
  [ORDER_STATUS.OTHER_INVOICING_DOCUMENTS_CREATED]: {
    id: ORDER_STATUS.OTHER_INVOICING_DOCUMENTS_CREATED,
    name: 'constant.orderStatus.OTHER_INVOICING_DOCUMENTS_CREATED',
    message: 'constant.orderStatusMessage.OTHER_INVOICING_DOCUMENTS_CREATED',
    progress: ORDER_STATUS.OTHER_INVOICING_DOCUMENTS_CREATED,
    totalProgress: ORDER_STATUS.OTHER_INVOICING_DOCUMENTS_CREATED,
  },
  ...ORDER_CLAIM_TYPES_OBJECT,
  ...EXECUTION_REVIEW_TYPES_OBJECT,
  [ORDER_STATUS.OTHER]: {
    id: ORDER_STATUS.OTHER,
    name: 'constant.orderStatus.OTHER',
    message: 'constant.orderStatusMessage.OTHER',
    progress: ORDER_STATUS.OTHER_INVOICING_DOCUMENTS_CREATED,
    totalProgress: ORDER_STATUS.OTHER_INVOICING_DOCUMENTS_CREATED,
  },
}

export const ORDER_STATES = Object.values(ORDER_STATES_OBJECT)

export const ORDER_PROGRESS_TYPES = {
  ORDER_PROGRESS_STATUS: 1,
  ORDER_PROGRESS_CLAIM: 2,
}

export const ORDER_PROGRESS_STATES = {
  DISPOSER_WILL_EXECUTE: 0,
  DISPOSER_DID_NOT_DISPOSE: 0,
  DISPOSER_DID_EXECUTE_DELIVERY_ONE_TIME: 30,
  DISPOSER_DID_DISPOSE_WITH_DATE: 30,
  DISPOSER_DID_EXECUTE_COLLECTION: 60,
  DISPOSER_DID_EXECUTE_DELIVERY: 60,
  EXECUTION_PROOFS_REJECTED: 60,
  EXECUTION_PROOFS_PENDING: 80,
  EXECUTION_PROOFS_ACCEPTED: 90,
  INVOICING_DOCUMENTS_CREATED: 140,
  OTHER_INVOICING_DOCUMENTS_CREATED: 145,
}
