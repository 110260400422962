import { connect } from 'react-redux'

import { getFractionContent, getFractions } from 'actions/fraction'
import { createErrorSelector } from 'selectors/error'
import {
  getFractionContentSelector,
  getFractionsSelector,
} from 'selectors/fraction'
import { createLoadingSelector } from 'selectors/loading'

const loadingGetFractionsSelector = createLoadingSelector(['GET_FRACTIONS'])
const loadingGetFractionContentSelector = createLoadingSelector([
  'GET_FRACTION_CONTENT',
])
const errorGetFractionsSelector = createErrorSelector(['GET_FRACTIONS'])
const errorGetFractionContentSelector = createErrorSelector([
  'GET_FRACTION_CONTENT',
])

/**
 * @function
 * @param {Object} state
 * @param {Object} props
 * @return {Object}
 */
const mapStateToProps = (state, props) => ({
  fractionList: getFractionsSelector(state),
  fractionContent: getFractionContentSelector(
    state,
    props.match.params.wasteTypeSlug,
  ),
  isLoading: {
    getFractions: loadingGetFractionsSelector(state),
    getFractionContent: loadingGetFractionContentSelector(state),
  },
  error: {
    getFractions: errorGetFractionsSelector(state),
    getFractionContent: errorGetFractionContentSelector(state),
  },
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @return {Object}
 */

const mapDispatchToProps = dispatch => ({
  getFractions: () => dispatch(getFractions()),
  getFractionContent: slugName => dispatch(getFractionContent(slugName)),
})

export default connect(mapStateToProps, mapDispatchToProps)
