import { Form, Formik } from 'formik'
import React, { FC, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { resetApiFetchErrors } from 'actions/app'
import { createInvoicingDocuments } from 'actions/order'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { DropDownInput } from 'components/common/DropDownInput'
import { ApiValidationMessages } from 'components/common/Form/components/ApiValidationMessages'
import { ProgressButton } from 'components/common/ProgressButton'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

import { INVOICING_DOCUMENT_TYPES } from '../../constants'

interface CreateInvoicingDocumentsFormComponentProps {
  onCancel?: () => void
  onSuccess?: () => void
  orderId: number | string
}

/**
 * @description This component renders a form that creates invoicing documents for an order.
 * @function
 * @param {Object} props the component props
 */
export const CreateInvoicingDocumentsForm: FC<
  CreateInvoicingDocumentsFormComponentProps
> = ({ orderId, onCancel = () => undefined, onSuccess = () => undefined }) => {
  const dispatch = useDispatch()
  const error = useSelector<any, ApiError>(
    createErrorSelector(['CREATE_INVOICING_DOCUMENTS']),
  )
  const isLoading = useSelector<any, boolean>(
    createLoadingSelector(['CREATE_INVOICING_DOCUMENTS']),
  )

  // Altough there is no effect to cleanup, we can use the "useEffect" capabilities to reset the ApiErrors on
  // general cleanup. This will behave like previously using "componentWillUnmount". A sole function is returned by
  // the effect with the desired action
  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors('CREATE_INVOICING_DOCUMENTS'))
    },
    [dispatch],
  )

  return (
    <div className='create-invoicing-documents-form-page'>
      <Formik
        initialValues={{
          document_type: '',
        }}
        validationSchema={() =>
          yup.object().shape({
            document_type: yup
              .string()
              .required(I18n.t('invoice.createDocuments.validationMsg'))
              .typeError(''),
          })
        }
        onSubmit={values => {
          dispatch(
            createInvoicingDocuments(
              { ...values, order_id: orderId },
              { onSuccess },
            ),
          )
        }}
        render={({
          errors,
          handleChange,
          handleBlur,
          isSubmitting,
          isValid,
          submitCount,
          touched,
          values,
        }) => (
          <Form
            className='uk-modal-body order-postpone-form'
            data-testid='order-postpone-form'
            noValidate // Disable browser validation
          >
            <ApiValidationMessages error={error} />
            <DropDownInput
              error={
                submitCount > 0 && touched.document_type
                  ? errors.document_type
                  : ''
              }
              dataTestId='document-type-select'
              choices={INVOICING_DOCUMENT_TYPES.map(item => ({
                optionValue: item.id,
                optionLabel: I18n.t(item.name),
              }))}
              label={I18n.t('certificateArchive.table.docType')}
              name='document_type'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t('certificateArchive.table.docType')}
              value={values.document_type}
              isRequired={!values.document_type}
            />

            <div className='uk-modal-footer upload-document-form__buttons'>
              <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                <Button
                  backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                  onClick={onCancel}
                >
                  {I18n.t('general.button.cancel')}
                </Button>

                <ProgressButton
                  dataTestId='create-invoicing-documents-form-submit'
                  backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                  isDisabled={
                    (submitCount > 0 && !isValid) || (isSubmitting && isLoading)
                  }
                  isLoading={isSubmitting && isLoading}
                  onClick={() => undefined}
                  type={BUTTON_TYPE.SUBMIT}
                >
                  {I18n.t('general.button.create')}
                </ProgressButton>
              </ButtonBar>
            </div>
          </Form>
        )}
      />
    </div>
  )
}
