import './style.scss'

import React from 'react'
import { I18n } from 'react-i18nify'
import { Link } from 'react-router-dom'

const AccountActivationNotice = () => (
  <div className='account-activation-notice'>
    <span className='account-activation-notice__info-text'>
      {I18n.t('infoBannerTranslations.accountActivationNotice.infoText')}
    </span>
    <Link
      className='account-activation-notice__link-text'
      to='/company/profile'
    >
      {I18n.t('infoBannerTranslations.accountActivationNotice.linkText')}
    </Link>
  </div>
)

export default AccountActivationNotice
