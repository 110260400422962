const indexesApiTranslations = {
  handleGetIndexes: {
    error: 'Indizes konnten leider nicht geladen werden.',
  },
  handleGetIndexValues: {
    error: 'Indexwerte konnten leider nicht geladen werden.',
  },
  handleChangeIndexValues: {
    success: 'Indexwerte wurden erfolgreich gespeichert.',
    error: 'Speichern der Indexwerte leider fehlgeschlagen.',
  },
  handleAddIndexValue: {
    success: 'Indexwerte wurden erfolgreich hinzugefügt.',
    error: 'Speichern der Indexwerte leider fehlgeschlagen.',
  },
  handleExportIndexValues: {
    success: 'Der CSV Export der Indexwerte wurde erfolgreich gestartet.',
    error:
      'Der CSV Export der Indexwerte konnte leider nicht gestartet werden.',
  },
}

export default indexesApiTranslations
