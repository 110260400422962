import './style.scss'

import uniqueId from 'lodash.uniqueid'
import {
  arrayOf,
  bool,
  func,
  number,
  oneOfType,
  shape,
  string,
} from 'prop-types'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { SecurityGroupScheme } from 'schemes/securitygroup'

import { BUTTON_DIMENSIONS } from '../../common/Button'
import { DropDownInput } from '../../common/DropDownInput'
import { IconButton } from '../../common/IconButton'
import {
  Tooltip,
  TOOLTIP_POSITION,
  TOOLTIP_THEME,
  TOOLTIP_ZINDEX,
} from '../../common/Tooltip'
import createMarkup from '../TooltipIcon/helpers'

import connector from './connector'
import {
  getProtectionClasses,
  getSecurityGroupFromLevelAndClass,
  getSecurityLevelsInProtectionClass,
} from './helpers'

export class SelectSecurityGroupComponent extends Component {
  static propTypes = {
    className: string,
    disableRequiredDot: bool,
    error: string,
    getSecurityGroups: func,
    loading: bool,
    onSelectionChange: func.isRequired,
    renderProtectionClassInput: func,
    renderSecurityLevelInput: func,
    selectedSecurityGroup: oneOfType([number, string]),
    securityGroupList: arrayOf(shape(SecurityGroupScheme)),
    tooltipZIndex: number,
    validators: shape({
      protectionClass: shape({}),
      securityLevel: shape({}),
    }),
    withCheckmark: bool,
  }

  static defaultProps = {
    className: '',
    disableRequiredDot: false,
    error: '',
    loading: false,
    renderProtectionClassInput: null,
    renderSecurityLevelInput: null,
    selectedSecurityGroup: null,
    tooltipZIndex: TOOLTIP_ZINDEX.BEFORE_HEADER_BEHIND_MODAL,
    validators: {
      protectionClass: {},
      securityLevel: {},
    },
    withCheckmark: false,
    getSecurityGroups: () => undefined,
    securityGroupList: () => undefined,
  }

  state = {
    originalProtectionClass: '',
    initialSelectedSecurityGroup: '',
    protectionClassList: [],
    securityLevelList: [],
    selectedProtectionClass: '',
    selectedSecurityLevel: '',
    // the following states are needed so outside render form fields can use these instance methods
    // (protectionClassEdit and securityLevelEdit)
    onChangeProtectionClass: () => undefined,
    onChangeSecurityLevel: () => undefined,
  }

  /**
   * @description Component “lifecycle method” componentDidMount
   * pulls in the securitygroup list and splits the protectionclasses off.
   * If a group is preselected, make sure to set the correct state and adjust the selects and stuff
   */
  componentDidMount() {
    if (this.props.securityGroupList.length < 1) this.props.getSecurityGroups()
    this.getProtectionClasses(this.props)
    if (this.props.selectedSecurityGroup)
      this.selectClassAndLevelFromGroup(this.props)
    this.setState({
      initialSelectedSecurityGroup: this.props.selectedSecurityGroup,
      originalProtectionClass:
        this.props.selectedSecurityGroup &&
        this.props.selectedSecurityGroup.protection_class,
      originalSecurityLevel:
        this.props.selectedSecurityGroup &&
        this.props.selectedSecurityGroup.security_level,
      onChangeProtectionClass: this.handleProtectionClassSelected,
      onChangeSecurityLevel: this.handleSecurityLevelSelected,
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.securityGroupList !== this.props.securityGroupList) {
      this.selectClassAndLevelFromGroup(this.props)
    }

    if (prevProps.securityGroupList !== this.props.securityGroupList) {
      this.getProtectionClasses(this.props)
    }

    if (prevProps.selectedSecurityGroup !== this.props.selectedSecurityGroup) {
      this.selectClassAndLevelFromGroup(this.props)
    }
  }

  getProtectionClasses = props => {
    this.setState({
      protectionClassList: getProtectionClasses(props.securityGroupList),
    })
  }

  selectClassAndLevelFromGroup = props => {
    const selectedSecurityGroup = props.securityGroupList.find(
      group => group.id === props.selectedSecurityGroup,
    )
    if (selectedSecurityGroup) {
      this.setState({
        securityLevelList: getSecurityLevelsInProtectionClass(
          selectedSecurityGroup.protection_class,
          props.securityGroupList,
        ),
        selectedProtectionClass: selectedSecurityGroup.protection_class,
        selectedSecurityLevel: selectedSecurityGroup.security_level,
      })
    }
  }

  handleProtectionClassSelected = event => {
    // Trigger reset of RRF field so we don't accidentally submit an old selection
    this.props.onSelectionChange(null)

    this.setState({
      securityLevelList: getSecurityLevelsInProtectionClass(
        Number(event.target.value),
        this.props.securityGroupList,
      ),
      selectedProtectionClass: event.target.value,
      selectedSecurityLevel: '',
    })
  }

  handleSecurityLevelSelected = event => {
    if (this.state.selectedProtectionClass) {
      const selectedGroup = getSecurityGroupFromLevelAndClass(
        this.state.selectedProtectionClass,
        event.target.value,
        this.props.securityGroupList,
      )
      this.props.onSelectionChange(selectedGroup ? selectedGroup.id : null)
    } else {
      this.setState({ selectedSecurityLevel: event.target.value })
    }
  }

  renderTooltipLabel = (
    tooltipId,
    labelText,
    tooltipText,
    afterTooltipLabel = '',
  ) => (
    <div className='security-group--field-label'>
      {labelText}

      <div className='tooltip-icon'>
        <Tooltip
          html={
            // eslint-disable-next-line react/no-danger
            <div
              className='security-group__tooltip--inner-text'
              dangerouslySetInnerHTML={createMarkup(tooltipText)}
            />
          }
          tooltipId={tooltipId}
          position={TOOLTIP_POSITION.RIGHT}
          theme={TOOLTIP_THEME.LIGHT}
          zIndex={this.props.tooltipZIndex}
        >
          <IconButton
            border='none'
            className='security-group__tooltip--show-tooltip-icon'
            dimensions={BUTTON_DIMENSIONS.AUTO}
            iconName='question'
            noHover
          />
        </Tooltip>
      </div>
      {afterTooltipLabel && (
        <>
          <br />
          <Translate value={afterTooltipLabel} dangerousHTML />
        </>
      )}
    </div>
  )

  /**
   * @function
   * @return {*}
   */
  render() {
    const {
      className,
      disableRequiredDot,
      error,
      loading,
      renderProtectionClassInput,
      renderSecurityLevelInput,
      withCheckmark,
      // securityGroupList, // @TODO bb refactor in cleanup task for new offer
    } = this.props

    const {
      selectedProtectionClass,
      selectedSecurityLevel,
      securityLevelList,
      protectionClassList,
    } = this.state

    if (loading) return null

    const inputs = [
      renderProtectionClassInput ? (
        renderProtectionClassInput(this.props, this.state) // @TODO bb refactor in cleanup task for new offer
      ) : (
        // ? renderProtectionClassInput(securityGroupList, selectedProtectionClass)
        <div
          className={`security-group__protection-class ${className}`}
          key={String(uniqueId())}
        >
          <DropDownInput
            choices={protectionClassList.map(protectionClass => ({
              optionLabel: I18n.t(
                'selectSecurityGroupTranslations.protectionClassNumber',
                { protectionClass },
              ),
              optionValue: protectionClass,
            }))}
            dataTestId='protection-class-input'
            dataTestIdError='form-group-error'
            error={error}
            isRequired={!disableRequiredDot && !selectedProtectionClass}
            label={this.renderTooltipLabel(
              'protectionClass',
              I18n.t('selectSecurityGroupTranslations.protectionClass'),
              I18n.t(
                'selectSecurityGroupTranslations.securityGroup.protectionClassDescription',
              ),
              'selectSecurityGroupTranslations.securityGroup.protectionClassLink',
            )}
            name='protectionClass'
            onChange={this.handleProtectionClassSelected}
            placeholder={I18n.t(
              'selectSecurityGroupTranslations.protectionClass',
            )}
            showCheckmark={withCheckmark && !!selectedProtectionClass}
            value={selectedProtectionClass}
            withCheckmark={withCheckmark}
          />
        </div>
      ),
    ]

    if (securityLevelList.length > 0) {
      inputs.push(
        renderSecurityLevelInput ? (
          renderSecurityLevelInput(this.props, this.state) // @TODO bb refactor in cleanup task for new offer
        ) : (
          // ? renderSecurityLevelInput(securityGroupList, selectedSecurityLevel)
          <div
            className={`security-group__security-level ${className}`}
            key={String(uniqueId())}
          >
            <DropDownInput
              choices={securityLevelList.map(securityLevel => ({
                optionLabel: I18n.t(
                  'selectSecurityGroupTranslations.securityLevelNumber',
                  { securityLevel },
                ),
                optionValue: securityLevel,
              }))}
              dataTestId='security-level-input'
              dataTestIdError='form-group-error'
              error={error}
              isRequired={!disableRequiredDot && !selectedSecurityLevel}
              label={this.renderTooltipLabel(
                'securityLevel',
                I18n.t('selectSecurityGroupTranslations.securityLevel'),
                I18n.t(
                  'selectSecurityGroupTranslations.securityGroup.securityLevelDescription',
                ),
              )}
              name='securityLevel'
              onChange={this.handleSecurityLevelSelected}
              placeholder={I18n.t(
                'selectSecurityGroupTranslations.securityLevel',
              )}
              showCheckmark={withCheckmark && !!selectedSecurityLevel}
              value={selectedSecurityLevel}
              withCheckmark={withCheckmark}
            />
          </div>
        ),
      )
    }

    return <>{inputs}</>
  }
}

export const SelectSecurityGroup = connector(SelectSecurityGroupComponent)
