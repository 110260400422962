/**
 * @description selector to get indexes.
 * @param state
 * @return {*}
 */
export const getIndexValuesItemSelector = state => ({
  ...state.indexes.item,
  value: state.indexes.item.value
    ? Number(state.indexes.item.value.replace(/,/, '.'))
    : null,
})

/**
 * @description selector to get indexes.
 * @param state
 * @return {*}
 */
export const getIndexValuesItemFormSelector = state => state.forms.indexes.item

/**
 * @description selector to get indexes.
 * @param state
 * @return {*}
 */
export const getIndexesSelector = state => state.indexes.itemList

/**
 * @description selector to get index values.
 * @param state
 * @return {*}
 */
export const getIndexValuesSelector = state => state.indexes.valueList

/**
 * @description selector to get index values for next month.
 * @param state
 * @return {*}
 */
export const getNextMonthIndexValuesSelector = state =>
  state.indexes.nextMonthIndexList

/**
 * @description selector to get index values for next month.
 * @param state
 * @return {*}
 */
export const getNextMonthIndexValuesFormSelector = state =>
  state.forms.indexes.nextMonthIndexList
