import keyMirror from 'keymirror'

const GET_DISCOUNTS = keyMirror({
  GET_DISCOUNTS_REQUEST: null,
  GET_DISCOUNTS_SUCCESS: null,
  GET_DISCOUNTS_ERROR: null,
})

const GET_DISCOUNT = keyMirror({
  GET_DISCOUNT_REQUEST: null,
  GET_DISCOUNT_SUCCESS: null,
  GET_DISCOUNT_ERROR: null,
})

const CREATE_DISCOUNT = keyMirror({
  CREATE_DISCOUNT_REQUEST: null,
  CREATE_DISCOUNT_SUCCESS: null,
  CREATE_DISCOUNT_ERROR: null,
})

const REDEEM_DISCOUNT = keyMirror({
  REDEEM_DISCOUNT_REQUEST: null,
  REDEEM_DISCOUNT_SUCCESS: null,
  REDEEM_DISCOUNT_ERROR: null,
})

const UPDATE_DISCOUNT_STATUS = keyMirror({
  UPDATE_DISCOUNT_STATUS_REQUEST: null,
  UPDATE_DISCOUNT_STATUS_SUCCESS: null,
  UPDATE_DISCOUNT_STATUS_ERROR: null,
})

const EXPORT_REDEEMED_DISCOUNTS = keyMirror({
  EXPORT_REDEEMED_DISCOUNTS_REQUEST: null,
  EXPORT_REDEEMED_DISCOUNTS_SUCCESS: null,
  EXPORT_REDEEMED_DISCOUNTS_ERROR: null,
})

export default {
  ...GET_DISCOUNTS,
  ...GET_DISCOUNT,
  ...CREATE_DISCOUNT,
  ...REDEEM_DISCOUNT,
  ...UPDATE_DISCOUNT_STATUS,
  ...EXPORT_REDEEMED_DISCOUNTS,
}
