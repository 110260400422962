import { Formik } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { uploadFiles } from 'actions/attachment'
import { createCertificate } from 'actions/certificate'
import {
  CERTIFICATE_BUSINESS_SEGMENT,
  CERTIFICATE_TYPE,
} from 'components/certificate/constants'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { getCompanySelector } from 'selectors/company'

import { DocumentUploadForm } from '../DocumentUploadForm'
import { getTypeChoices } from '../DocumentUploadForm/helpers'
import { DocumentUploadFormValues } from '../DocumentUploadForm/types'

/**
 * @description This component displays the modal window for the document upload form
 */
export const DocumentUploadModal: FC<{
  isOpen?: boolean
  onClose: (...args) => void
}> = ({ isOpen = false, onClose }) => {
  // Redux
  const company = useSelector(getCompanySelector)

  const dispatch = useDispatch()

  const idDocumentUploadModal = uniqueId()

  const typeChoices = getTypeChoices(company)

  const initialValues: DocumentUploadFormValues = {
    type: `${typeChoices[0].value}`,
    attachments: [],
    description: '',
  }

  const validationSchema = () =>
    yup.object().shape({
      type: yup
        .string()
        .required(I18n.t('documentUploadFormTranslations.type.error')),
      attachments: yup
        .mixed()
        .required(I18n.t('documentUploadFormTranslations.attachments.error'))
        .test(
          'length',
          I18n.t('documentUploadFormTranslations.attachments.error'),
          value => value?.length === 1,
        ),
      description: yup
        .string()
        .trim()
        .required(I18n.t('documentUploadFormTranslations.description.error')),
    })

  const handleOnSubmit = values => {
    dispatch(
      uploadFiles([{ file: values.attachments[0].data }], {
        onDocumentSubmit: attachments => {
          dispatch(
            createCertificate(
              {
                ...values,
                // Efb-certificates belong to no particular business segment (empto is chosen as a default).
                // All other documents belong to the EPD business segment.
                business_segment:
                  values.type === `${CERTIFICATE_TYPE.TYPE_EFB}`
                    ? `${CERTIFICATE_BUSINESS_SEGMENT.SEGMENT_EMPTO}`
                    : `${CERTIFICATE_BUSINESS_SEGMENT.SEGMENT_EPD}`,
                company: `${company.id}`,
                attachments,
              },
              values.type,
              true,
            ),
          )
        },
      }),
    )
  }

  return (
    <Modal
      ariaDescribedBy={idDocumentUploadModal}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={I18n.t('documentUploadModalTranslations.modalTitle')}
        titleId={idDocumentUploadModal}
      />
      <Formik
        initialValues={initialValues}
        onSubmit={values => handleOnSubmit(values)}
        validationSchema={validationSchema}
        validateOnMount
      >
        <DocumentUploadForm onClose={onClose} />
      </Formik>
    </Modal>
  )
}
