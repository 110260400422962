import uniqueId from 'lodash.uniqueid'
import moment from 'moment'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { Form } from 'react-redux-form'
import { NavLink } from 'react-router-dom'
import Spinner from 'react-spinkit'
import { compose } from 'recompose'

import withErrorBoundary from 'helper/withErrorBoundary'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import { UserPermission } from 'constants/user'
import { BUSINESS_SEGMENT } from 'constants/app'

import { CERTIFICATE_TYPE } from '../../certificate/constants'
import { BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import FileComment from '../../common/fileUpload/FileUpload/components/FileComment'
import FormSubmitErrorMessage from '../../common/Form/components/FormSubmitErrorMessage'
import IndicationMessage from '../../common/IndicationMessage'
import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'
import { ProgressButton } from '../../common/ProgressButton'
import { RequiredPermissions } from '../../common/RequiredPermissions'
import UploadCompanyProofMessage from '../../common/UploadCompanyProofMessage'
import { UploadDocumentForm } from '../../common/UploadDocumentForm'
import { PageHeader } from '../../layout/PageHeader'
import { ADDRESS_STATUS, COMPANY_STATUS } from '../constants'

import { ActivityTable } from './components/ActivityTable'
import MainAddressDetails from './components/AddressDetails/MainAddressDetails'
import PostalAddressDetails from './components/AddressDetails/PostalAddressDetails'
import Certificates from './components/Certificates'
import CompanyDetails from './components/CompanyDetails'
import PaymentMethod from './components/PaymentMethod'
import ProfileInfos from './components/ProfileInfos'
import TaxDetails from './components/TaxDetails'
import connector from './connector'
import { COMPANY_MODALS, CompanyFormValidators } from './constants'
import { allPostalAddressFieldsAreEmpty } from './helpers'
import { CompanyProfilePageScheme } from './schemes'

/**
 * @description This component displays the company profile ("Stammdaten").
 * @function
 * @param {Object} props the component props
 */
export class CompanyProfilePageComponent extends Component {
  static propTypes = CompanyProfilePageScheme

  static defaultProps = {
    user: null,
    isLoading: {
      getCountries: false,
      getCompany: false,
      updateCompany: false,
      getCompanyLatestCertificates: false,
      createCertificate: false,
    },
    error: {
      getCountries: null,
      getCompany: null,
      updateCompany: null,
      getCompanyLatestCertificates: null,
      createCertificate: null,
    },
    company: null,
  }

  state = {
    openModal: false,
    selectedFile: null, // The currently selected file for the preview.
  }

  /**
   * @description Component “lifecycle method” componentDidMount
   */
  componentDidMount() {
    this.props.getCountries()
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.companyId
    ) {
      const { companyId } = this.props.match.params
      this.props.getCompany({ id: companyId })
      this.props.getCompanyLatestCertificates(companyId)
    } else if (
      this.props.user &&
      this.props.user.id &&
      this.props.user.company_object &&
      this.props.user.company_object.id
    ) {
      this.props.getCompany({
        id: this.props.user.company_object.id,
      })
      this.props.getCompanyLatestCertificates(this.props.user.company_object.id)
    }
    // If the user is already loaded at this time (the usual case), we load the company.
    // If this page was opened through the direct route, the user might not be loaded at this time, so we wait.
  }

  /**
   * @description Toggle a modal
   * @param modal
   * @param event
   */
  handleModalToggle = (modal = null, event = null) => {
    if (event) event.preventDefault()
    this.setState({
      openModal: modal,
    })
  }

  /**
   * @description Open modal to add a certificate
   */
  handleAddCertificate = type => {
    this.props.addCertificateType(type)
    this.setState({
      openModal: COMPANY_MODALS.CERTIFICATE_FORM_MODAL,
    })
  }

  /**
   * @description Method to submit profile form.
   */
  handleSubmit = () => {
    let updateCompanyPayload = {
      ...this.props.company,
      isUpdatingCompanyInfo: true,
    }

    if (
      this.props.company.business_segment === BUSINESS_SEGMENT.BUSINESS_TVP &&
      !allPostalAddressFieldsAreEmpty(this.props.company.postal_address_object)
    ) {
      const now = moment().format()
      const postalAddress = {
        ...this.props.company.postal_address_object,
        company: this.props.company.id,
        status: ADDRESS_STATUS.STATUS_ACTIVE,
        created_at: now,
        lastmodified_at: now,
        status_lastmodified_at: now,
      }

      // by default, the id is set to -1, that means that a new address is created, so we need to delete the id entry
      if (postalAddress.id === -1) delete postalAddress.id

      updateCompanyPayload = {
        ...updateCompanyPayload,
        postal_address_object: postalAddress,
      }
    } else {
      delete updateCompanyPayload.postal_address_object
    }

    this.props.updateCompany(updateCompanyPayload)
  }

  /**
   * Is called when a preview is clicked. We then display a modal with the preview.
   * @param event
   * @param clickedFile
   */
  handlePreviewClick = (event, clickedFile) => {
    this.setState({
      selectedFile: clickedFile,
      openModal: COMPANY_MODALS.FILE_PREVIEW_MODAL,
    })
  }

  render() {
    const {
      company,
      user,
      createCertificate,
      certificate,
      isLoading,
      error,
      certificateItem,
    } = this.props

    const { $form } = this.props.companyForm
    const idAddCertificateModalHeadline = uniqueId()
    const idFilePreviewModalHeadline = uniqueId()

    const showTakeActionToActivateNotice =
      user &&
      user.company_object &&
      user.company_object.status !== COMPANY_STATUS.STATUS_ACTIVE

    const upLoadLabel = certificateType => {
      switch (Number(certificateType)) {
        case CERTIFICATE_TYPE.TYPE_EFB:
          return I18n.t(
            'uploadReviewDocumentTranslations.formFields.attachments.label.certificate.efb',
          )
        case CERTIFICATE_TYPE.TYPE_OTHER:
          return I18n.t(
            'uploadReviewDocumentTranslations.formFields.attachments.label.certificate.other',
          )
        case CERTIFICATE_TYPE.TYPE_COMPANY:
          return I18n.t(
            'uploadReviewDocumentTranslations.formFields.attachments.label.certificate.company',
          )
        case CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT:
          return I18n.t(
            'uploadReviewDocumentTranslations.formFields.attachments.label.certificate.priceAgreement',
          )
        default:
          return ''
      }
    }

    if (!this.props.company.id || !this.props.user.id) return null
    if (
      this.props.isLoading.getCompany ||
      this.props.isLoading.getCompanyLatestCertificates ||
      this.props.isLoading.getCountries
    ) {
      return (
        <div className='uk-flex uk-flex-center uk-margin-large-top'>
          <Spinner name='circle' />
        </div>
      )
    }

    return (
      <>
        <Helmet>
          <title>{I18n.t('pageTitles.companyProfile')}</title>
        </Helmet>

        <div className='company-profile-page'>
          <Form
            model={$form.model}
            onSubmit={form => this.handleSubmit(form)}
            validators={CompanyFormValidators}
            validateOn={$form.submitFailed ? 'change' : 'submit'}
            data-pending={$form.pending}
          >
            <PageHeader title={I18n.t('companyProfilePage.heading')} sticky>
              <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                <NavLink
                  to={`/company/${company.id}`}
                  className='uk-button uk-button-default'
                >
                  <Translate value='companyProfilePage.viewProfile' />
                </NavLink>

                <RequiredPermissions
                  requiredPermissions={[UserPermission.CHANGE_COMPANY]}
                >
                  <ProgressButton
                    backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                    isDisabled={!$form.valid || $form.pending || $form.pristine}
                    isLoading={$form.pending}
                    onClick={() => undefined}
                    type='submit'
                  >
                    <Translate value='general.button.save' />
                  </ProgressButton>
                </RequiredPermissions>
              </ButtonBar>

              <FormSubmitErrorMessage
                className='uk-text-right@s'
                form={this.props.companyForm}
              />
            </PageHeader>

            {/* WARNINGS */}
            {showTakeActionToActivateNotice && (
              <IndicationMessage
                className='error'
                iconName='lock'
                statusIconName='clock'
              >
                <UploadCompanyProofMessage />
              </IndicationMessage>
            )}

            <div className='uk-margin-medium-bottom'>
              <CompanyDetails />
            </div>

            <div className='uk-margin-medium-bottom'>
              <TaxDetails />
            </div>

            <div className='uk-margin-medium-bottom'>
              <MainAddressDetails />
            </div>

            <div className='uk-margin-medium-bottom'>
              <PostalAddressDetails />
            </div>

            <div className='uk-margin-medium-bottom'>
              <PaymentMethod />
            </div>

            <div className='uk-margin-medium-bottom'>
              <ProfileInfos />
            </div>

            <RequiredPermissions
              requiredPermissions={[UserPermission.ADD_CERTIFICATE]}
            >
              <div className='uk-margin-medium-bottom'>
                <Certificates
                  company={company}
                  user={user}
                  onCertificateAdd={this.handleAddCertificate}
                  onPreviewClick={this.handlePreviewClick}
                />
              </div>
            </RequiredPermissions>

            <ActivityTable companyId={company.id} />
          </Form>

          <Modal
            ariaDescribedBy={idAddCertificateModalHeadline}
            isOpen={
              this.state.openModal === COMPANY_MODALS.CERTIFICATE_FORM_MODAL
            }
            onClose={this.handleModalToggle}
          >
            <ModalHeader
              onClose={this.handleModalToggle}
              title={I18n.t('companyProfilePage.certificates.modalTitle')}
              titleId={idAddCertificateModalHeadline}
            />

            <UploadDocumentForm
              document={certificate}
              documentType={certificateItem.type}
              onCloseForm={this.handleModalToggle}
              onDocumentSubmit={values => {
                createCertificate({
                  ...values,
                  min_load: 0,
                  type: certificateItem.type,
                  company: company.id,
                })
              }}
              apiError={error.createCertificate}
              permissions={[UserPermission.ADD_CERTIFICATE]}
              isLoading={isLoading.createCertificate}
              maxFiles={
                certificateItem.type &&
                certificateItem.type === CERTIFICATE_TYPE.TYPE_EFB
                  ? 3
                  : 1
              }
              uploadLabel={upLoadLabel(certificateItem.type)}
              showApiValidationMessage={!!error.createCertificate}
              aditionalApiFetchError='CREATE_CERTIFICATE'
            />
          </Modal>

          {this.state.selectedFile && (
            <Modal
              ariaDescribedBy={idFilePreviewModalHeadline}
              isOpen={
                this.state.openModal === COMPANY_MODALS.FILE_PREVIEW_MODAL
              }
              onClose={this.handleModalToggle}
            >
              <>
                <ModalHeader
                  onClose={this.handleModalToggle}
                  title={this.state.selectedFile.name}
                  titleId={idFilePreviewModalHeadline}
                />

                <FileComment
                  file={this.state.selectedFile}
                  onFormSubmit={this.handleModalToggle}
                  allowComment={false}
                />
              </>
            </Modal>
          )}
        </div>
      </>
    )
  }
}

export default compose(
  withErrorBoundary,
  connector,
  withApiErrorHandling,
)(CompanyProfilePageComponent)
