import './style.scss'

import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { Link } from 'react-router-dom'

import { InputCheckmark } from '../InputCheckmark'

export interface ReadOnlyFieldProps {
  children?: JSX.Element
  className?: string
  dataTestId?: string
  editable?: boolean
  formPadding?: boolean
  // label can be another component that renders hypertext (only special cases)
  label: string | JSX.Element
  linkTo?: string
  originalValue?: string | number
  value?: string | number
  withCheckmark?: boolean
}

export const ReadOnlyField: FC<ReadOnlyFieldProps> = ({
  children = <></>,
  className = '',
  dataTestId = '',
  editable = false,
  formPadding = false,
  label,
  linkTo = null,
  originalValue = null,
  value = '',
  withCheckmark = false,
}) => {
  const id = uniqueId()

  if (editable) return children

  return (
    <div className={`read-only-field ${className}`}>
      <Row middle='xs'>
        <Col xs={12} sm={12}>
          {/* eslint-disable-next-line jsx-a11y/label-has-for */}
          <label
            className={`read-only-field__label${
              formPadding ? '--form-padding' : ''
            }`}
            htmlFor={id}
          >
            {label}
          </label>
        </Col>
        <Col xs={12} sm={12} style={{ position: 'relative' }}>
          {originalValue && (
            <div className='read-only-field__original-value'>
              {originalValue}
            </div>
          )}
          <div className='read-only-field__value' data-testid={dataTestId}>
            {linkTo ? (
              <Link target='_blank' to={linkTo} key={linkTo}>
                {value}
              </Link>
            ) : (
              value
            )}
          </div>
          {withCheckmark && <InputCheckmark isHidden={false} />}
        </Col>
      </Row>
    </div>
  )
}
