import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'
import Media from 'react-media'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'

import { BREAKPOINT } from 'constants/design'
import { getDashboardEPD } from 'actions/dashboard'
import { dashboardEPDSelector } from 'selectors/dashboard'

import {
  ClassificationGrid,
  ClassificationTile,
  DualClassificationTile,
  Podium,
} from '../../components/Classification'
import { NUMBER_FORMAT } from '../../components/Classification/ClassificationTile'
import {
  TIME_FRAME,
  TimeFrameSwitcher,
} from '../../components/Classification/TimeFrameSwitcher'
import { DashboardTile } from '../../components/DashboardTile/DashboardTile'
import { DashboardTileIcon } from '../../components/DashboardTileIcon/DashboardTileIcon'

export const EPDStakeHolderDashboardPage = () => {
  const dispatch = useDispatch()
  const dashboardEPDData = useSelector(dashboardEPDSelector)

  useEffect(() => {
    if (!dashboardEPDData.loaded) {
      dispatch(getDashboardEPD())
    }
  })

  const tiles = [
    [
      {
        // SALES TODAY
        caption: I18n.t('dashboard.MP.EPD.stakeholder.salesToday', {
          salesTodayShop: new Intl.NumberFormat('de-DE', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(Number(dashboardEPDData.sales_today.sales_shop)),
        }),
        value: Number(dashboardEPDData.sales_today.sales),
        offsetValue: dashboardEPDData.sales_today.sales_to_compare,
        icon: DashboardTileIcon.Graph,
        format: NUMBER_FORMAT.CURRENCY,
      },
      {
        // SALES MONTH
        caption: I18n.t('dashboard.MP.EPD.stakeholder.salesMonth', {
          salesMonthShop: new Intl.NumberFormat('de-DE', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(Number(dashboardEPDData.sales_month.sales_shop)),
        }),
        value: Number(dashboardEPDData.sales_month.sales),
        offsetValue: dashboardEPDData.sales_month.sales_to_compare,
        icon: DashboardTileIcon.MoneyGradient,
        format: NUMBER_FORMAT.CURRENCY,
      },
    ],
    [
      {
        // OFFERS TODAY
        caption: I18n.t('dashboard.MP.EPD.stakeholder.offersToday', {
          offerSalesToday: new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
          }).format(Number(dashboardEPDData.offer_today.sales)),
        }),
        value: Number(dashboardEPDData.offer_today.count),
        offsetValue: dashboardEPDData.offer_today.count,
        icon: DashboardTileIcon.Handshake,
      },
      {
        // OFFERS MONTH
        caption: I18n.t('dashboard.MP.EPD.stakeholder.offersMonth', {
          offerSalesMonth: new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
          }).format(Number(dashboardEPDData.offer_month.sales)),
          changesOfSuccess: new Intl.NumberFormat('de-DE', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(Number(dashboardEPDData.offer_month.changes_of_success)),
        }),
        value: Number(dashboardEPDData.offer_month.count),
        offsetValue: dashboardEPDData.offer_month.count,
        icon: DashboardTileIcon.Handshake,
      },
    ],
    [
      {
        // ORDER TODAY
        caption: I18n.t('dashboard.MP.EPD.stakeholder.ordersToday'),
        value: Number(dashboardEPDData.order_today),
        offsetValue: dashboardEPDData.order_today,
        icon: DashboardTileIcon.Truck,
      },
      {
        // ORDER MONTH
        caption: I18n.t('dashboard.MP.EPD.stakeholder.ordersMonth'),
        value: Number(dashboardEPDData.order_month),
        offsetValue: dashboardEPDData.order_month,
        icon: DashboardTileIcon.Truck,
      },
    ],
  ]

  const ranking = dashboardEPDData.best_employees.map(entry => ({
    user: entry.user,
    amount: entry.sales,
    count_offers: entry.count,
  }))

  const pipelineTile = {
    value: dashboardEPDData.offer_pipeline.count,
    caption: I18n.t('dashboard.MP.EPD.stakeholder.offerPipeline', {
      offerPipelineForecast: new Intl.NumberFormat('de-DE', {
        style: 'currency',
        currency: 'EUR',
      }).format(Number(dashboardEPDData.offer_pipeline.forecast)),
    }),
  }

  const [timeFrame, setTimeFrame] = useState(TIME_FRAME.TODAY)
  const getCurrencyString = value =>
    new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
    }).format(Number(value))

  return (
    <div>
      <Helmet>
        <title>{I18n.t('dashboard.MP.EPD.stakeholder.headline')}</title>
      </Helmet>

      {!dashboardEPDData.loaded ? (
        <div className='uk-flex uk-flex-center uk-margin-large-top'>
          <Spinner name='circle' />
        </div>
      ) : (
        <Media
          queries={{
            large: { minWidth: BREAKPOINT.XLARGE },
            small: { maxWidth: BREAKPOINT.MEDIUM - 1 },
          }}
        >
          {matches => (
            <>
              {matches.small && (
                <TimeFrameSwitcher
                  timeFrame={timeFrame}
                  setTimeFrame={setTimeFrame}
                />
              )}
              <ClassificationGrid>
                <DualClassificationTile
                  tiles={matches.small ? [tiles[0][timeFrame]] : tiles[0]}
                />
                <ClassificationTile
                  caption={pipelineTile.caption}
                  className={!matches.large ? 'classification-tile--row-4' : ''}
                  icon={DashboardTileIcon.Document}
                  removePadding
                  value={pipelineTile.value}
                />
                <DashboardTile
                  className={!matches.large ? 'dashboard-tile--row-2' : ''}
                  icon={DashboardTileIcon.MoneyGradient}
                  removePadding
                >
                  <span>
                    {I18n.t('dashboard.MP.EPD.stakeholder.demandTotal')}:&nbsp;
                    <b>{getCurrencyString(dashboardEPDData.demand_total)}</b>
                  </span>
                  <br />
                  <span>
                    {I18n.t('dashboard.MP.EPD.stakeholder.demandMonth')}:&nbsp;
                    <b>{getCurrencyString(dashboardEPDData.demand_month)}</b>
                  </span>
                  <br />
                  <span>
                    {I18n.t('dashboard.MP.EPD.stakeholder.demandTwoMonths')}
                    :&nbsp;
                    <b>
                      {getCurrencyString(dashboardEPDData.demand_two_months)}
                    </b>
                  </span>
                  <br />
                  <span>
                    {I18n.t('dashboard.MP.EPD.stakeholder.demandCaption')}
                  </span>
                  <br />
                  <small>
                    {I18n.t('dashboard.MP.EPD.stakeholder.demandTimestamp')}
                    :&nbsp;
                    {moment(dashboardEPDData.demand_timestamp).format('lll')}
                  </small>
                </DashboardTile>
                <DualClassificationTile
                  tiles={matches.small ? [tiles[1][timeFrame]] : tiles[1]}
                />
                <Podium
                  ranking={ranking}
                  span={!matches.small}
                  last={!matches.large}
                />
                <DualClassificationTile
                  tiles={matches.small ? [tiles[2][timeFrame]] : tiles[2]}
                />
              </ClassificationGrid>
            </>
          )}
        </Media>
      )}
    </div>
  )
}
