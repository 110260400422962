import './style.scss'

import React, { FC } from 'react'

import { PROGRESS_BAR_COLOR, ProgressBar } from 'components/common/ProgressBar'
import { ORDER_PROGRESS_STATES } from 'components/order/constants'

interface OrderCardStatusAndProgressProps {
  headline: string
  status: string
  latestOrderStatus: string
}

export const OrderCardStatusAndProgress: FC<
  OrderCardStatusAndProgressProps
> = ({ headline, status, latestOrderStatus }) => (
  <div className='status-and-progress'>
    <div className='status-and-progress__headline'>{headline}</div>
    <ProgressBar
      color={
        ORDER_PROGRESS_STATES[status] >= 90
          ? PROGRESS_BAR_COLOR.GREEN
          : PROGRESS_BAR_COLOR.ORANGE
      }
      percent={ORDER_PROGRESS_STATES[status]}
    />
    <div className='status-and-progress__status-message'>
      {latestOrderStatus}
    </div>
  </div>
)
