import { connect } from 'react-redux'

import { requestPasswordReset } from 'actions/user'

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  requestPasswordReset: (email, history) =>
    dispatch(requestPasswordReset(email, history)),
})

export default connect(null, mapDispatchToProps)
