import './ClassificationTile.scss'

import classNames from 'classnames'
import React, { FC } from 'react'
import { Link } from 'react-router-dom'

import { DashboardTileIcon } from '../DashboardTileIcon/DashboardTileIcon'

import { ClassificationTileContent } from './ClassificationTileContent'

export enum NUMBER_FORMAT {
  INTEGER,
  DECIMAL,
  CURRENCY,
}

export interface ClassificationTileContentProps {
  caption: string
  hideIcon?: boolean
  icon?: DashboardTileIcon
  value: string | number
  offsetValue?: string | number
  format?: NUMBER_FORMAT
}

interface ClassificationTileProps extends ClassificationTileContentProps {
  buttonText?: string
  buttonTarget?: string
  className?: string
  removePadding?: boolean
  span?: boolean
}

/**
 * Renders the ClassificationTile
 * @constructor
 */
export const ClassificationTile: FC<ClassificationTileProps> = ({
  buttonText,
  buttonTarget,
  caption,
  className,
  icon,
  offsetValue,
  removePadding = false,
  span = false,
  value,
}) => (
  <article
    className={classNames('classification-tile', className, {
      'classification-tile--span': span,
    })}
  >
    <div
      className={classNames('classification-tile__container', {
        'uk-padding-remove-bottom': removePadding,
      })}
    >
      <ClassificationTileContent
        caption={caption}
        value={value}
        offsetValue={offsetValue}
        icon={icon}
      />
      {buttonText && buttonTarget && (
        <div className='classification-tile__button'>
          <Link className='classification-tile__button-link' to={buttonTarget}>
            {buttonText}
          </Link>
        </div>
      )}
    </div>
  </article>
)
