import { connect } from 'react-redux'

import {
  getMyUsers,
  getUser,
  resetUser,
  resetUserIsContactPerson,
  updateUser,
} from 'actions/user'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import {
  getCurrentUserSelector,
  getMyCompanyUserListSelector,
  getUserPaginationSelector,
  getUserSelector,
} from 'selectors/user'

const exportUsersLoadingSelector = createLoadingSelector(['EXPORT_USERS'])
const userListLoadingSelector = createLoadingSelector(['GET_MY_USERS'])
const getUserToEditLoadingSelector = createLoadingSelector(['GET_USER'])
const errorSelector = createErrorSelector(['GET_MY_USERS', 'UPDATE_USER'])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  user: getCurrentUserSelector(state),
  userItem: getUserSelector(state),
  userList: getMyCompanyUserListSelector(state),
  pagination: getUserPaginationSelector(state),
  userIsContactPerson: state.user.userIsContactPerson,
  isLoading: {
    exportUsers: exportUsersLoadingSelector(state),
    userList: userListLoadingSelector(state),
    getUserToEdit: getUserToEditLoadingSelector(state),
  },
  error: errorSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getMyUsers: (page, sortedBy, filters) =>
    dispatch(getMyUsers(page, sortedBy, filters)),
  updateUser: (user, callback = null) => dispatch(updateUser(user, callback)),
  getUser: userId => dispatch(getUser(userId)),
  resetUserIsContactPerson: () => dispatch(resetUserIsContactPerson()),
  resetUser: () => dispatch(resetUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)
