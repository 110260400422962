import './style.scss'

import moment from 'moment'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { COMPANY_ROLE, COMPANY_ROLES } from 'components/company/constants'
import { BUSINESS_SEGMENT } from 'constants/app'
import { getName } from 'helper/translations'

import { CustomerNumbers } from './CustomerNumbers'

interface BasicInformationProps {
  company: Company
  companyValidEfbCertificate: Certificate
  isMaklerPremium?: boolean
  hasAgreements?: boolean
}

export const BasicInformation: FC<BasicInformationProps> = ({
  company,
  companyValidEfbCertificate,
  isMaklerPremium,
  hasAgreements,
}) => {
  let efbValidUntilDate
  if (company.efb_certificate_valid_until_date) {
    efbValidUntilDate = moment(company.efb_certificate_valid_until_date).format(
      'L',
    )
  }

  const hasValidEfbCertificate =
    companyValidEfbCertificate &&
    companyValidEfbCertificate.attachments.length > 0

  let companyRole

  if (
    company.business_segment !== BUSINESS_SEGMENT.BUSINESS_EMPTO &&
    company.role === COMPANY_ROLE.MIDDLE_MAN
  ) {
    companyRole = I18n.t('homepages.company.role.premium.title')
  } else if (company.private_person) {
    companyRole = I18n.t('homepages.company.role.privatePerson.title')
  } else {
    companyRole = getName(company.role, COMPANY_ROLES, true)
  }

  if (!company) return null
  return (
    <div className='basic-information uk-width-1-1'>
      <p className='basic-information__role'>{companyRole}</p>
      <h3 className='basic-information__name uk-float-left uk-width-1-1 uk-width-auto@l uk-margin-small-right uk-text-bolder'>
        {company.name}
      </h3>
      <div className='uk-clearfix' />
      <CustomerNumbers
        company={company}
        showCustomerNumbers={isMaklerPremium}
        showExternalNumber={isMaklerPremium || hasAgreements}
      />
      {/* EfB Certificate information */}
      {hasValidEfbCertificate && (
        <p className='basic-information__meta'>
          <Translate
            value='companyInfoPage.efbCertificateValidUntil'
            validUntilDate={efbValidUntilDate}
            dangerousHTML
          />
        </p>
      )}
      <div className='uk-clearfix' />
    </div>
  )
}
