import './style.scss'

import uniqueId from 'lodash.uniqueid'
import React, { FC, useState } from 'react'
import { I18n } from 'react-i18nify'

import { ReactComponent as Document } from 'assets/svg/detailspage/document.svg'
import AccordionItem from 'components/common/Accordion/components/AccordionItem'
import FilePreviewComponent from 'components/common/FilePreview'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { SelectSecurityGroup } from 'components/common/SelectSecurityGroup'
import {
  getSelectedProtectionClassName,
  getSelectedSecurityLevelName,
  isSecurityRequired,
} from 'components/common/SelectSecurityGroup/helpers'
import {
  COLLECTION_CONTAINER_IDS,
  EPD_USER_POSITION_IDENTIFIER,
} from 'constants/app'
import { decimalToGermanFormat } from 'helper/general'
import { getDisplayName, getName } from 'helper/translations'

import { OldAndNewValuesSectionProps } from '../scheme'

import { PreviewAttachmentModal } from './PreviewAttachmentModal'

interface InquirySectionProps {
  containerList: Container[]
  fractionList: Fraction[]
}

export const InquirySection: FC<
  OldAndNewValuesSectionProps & InquirySectionProps
> = ({ addScrollElement, containerList, fractionList, offer }) => {
  const [openModal, setOpenModal] = useState(false)
  const [selectedFile, setSelectedFile] = useState(null)

  const handlePreviewClick = file => {
    setOpenModal(!openModal)
    setSelectedFile(file)
  }

  const id = uniqueId()
  const securityLevelId = uniqueId()
  const protectionClassId = uniqueId()

  let accepted = 'accepted'
  if (
    offer.position_on_public_ground &&
    offer.position_on_public_ground !==
      offer.position_on_public_ground_by_auto_offer &&
    offer.created_by_object.position === EPD_USER_POSITION_IDENTIFIER
  ) {
    accepted = 'acceptedByCustomer'
  } else if (
    offer.position_on_public_ground &&
    offer.position_on_public_ground ===
      offer.position_on_public_ground_by_auto_offer &&
    offer.created_by_object.position === EPD_USER_POSITION_IDENTIFIER
  ) {
    accepted = 'acceptedByEPD'
  } else if (
    offer.position_on_public_ground &&
    offer.position_on_public_ground ===
      offer.position_on_public_ground_by_auto_offer &&
    offer.company_object.makler_premium_company !== null
  ) {
    accepted = 'acceptedByEPD'
  }

  const iconTitle = (
    <>
      <Document className='inquiry-details-page__section-header' />
      {I18n.t('inquiryDetailsTranslations.section.inquiry.title')}
    </>
  )

  const securityIsRequired = offer
    ? isSecurityRequired(null, fractionList, offer)
    : false

  const specialNote = (offer && offer.special_note) || offer.special_note

  // if there is an offer present, use the container from the offer. If not (inquiry details) use the inquiry container
  const showQuantityInCubicMeters =
    offer && COLLECTION_CONTAINER_IDS.includes(Number(offer.container))

  return (
    <AccordionItem addScrollElement={addScrollElement} title={iconTitle}>
      <div
        className='inquiry-details-page__section uk-child-width-1-2@m'
        data-uk-grid=''
      >
        {/* FRACTION */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.inquiry.fields.fraction',
          )}
          value={getName(offer && offer.fraction, fractionList)}
        />
        {/* FINE FRACTION */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.inquiry.fields.fineFraction',
          )}
          value={
            offer && offer.fine_fraction
              ? getName(offer && offer.fine_fraction, fractionList)
              : I18n.t(
                  'inquiryDetailsTranslations.section.inquiry.fields.noFineFraction',
                )
          }
        />
        {/* SECURITY GROUP */}
        {securityIsRequired && (
          <SelectSecurityGroup
            selectedSecurityGroup={offer.security_group}
            renderProtectionClassInput={(
              { securityGroupList },
              { selectedProtectionClass },
            ) => (
              <ReadOnlyField
                key={protectionClassId}
                label={I18n.t(
                  'selectSecurityGroupTranslations.protectionClass',
                )}
                value={getSelectedProtectionClassName(
                  securityGroupList,
                  selectedProtectionClass,
                )}
              />
            )}
            renderSecurityLevelInput={(
              { securityGroupList },
              { selectedSecurityLevel },
            ) => (
              <ReadOnlyField
                key={securityLevelId}
                label={I18n.t('selectSecurityGroupTranslations.securityLevel')}
                value={getSelectedSecurityLevelName(
                  securityGroupList,
                  selectedSecurityLevel,
                )}
              />
            )}
            onSelectionChange={() => undefined} // ReadOnly view doesn't need a change handler
          />
        )}
        {/* AVV */}
        {offer && offer.avv_number && (
          <ReadOnlyField
            label={I18n.t(
              'inquiryDetailsTranslations.section.inquiry.fields.avvNumber',
            )}
            value={offer.avv_number}
          />
        )}

        {/* CONTAINER */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.inquiry.fields.container',
          )}
          value={getDisplayName(offer && offer.container, containerList)}
        />

        {/* NUMBER OF CONTAINERS */}
        {!showQuantityInCubicMeters && (
          <ReadOnlyField
            label={I18n.t(
              'inquiryDetailsTranslations.section.inquiry.fields.numberOfContainers',
            )}
            value={offer && offer.number_of_containers}
          />
        )}

        {/* QUANTITY IN CUBIC METERS */}
        {showQuantityInCubicMeters && (
          <ReadOnlyField
            label={I18n.t(
              'inquiryDetailsTranslations.section.inquiry.fields.quantityInCubicMeters',
            )}
            value={
              offer && decimalToGermanFormat(offer.quantity_in_cubic_meters)
            }
          />
        )}

        {/* CONTAINER WITH TOP */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.inquiry.fields.withTop',
          )}
          value={I18n.t(`general.${offer.with_top ? 'yes' : 'no'}`)}
        />
        {/* POSITION ON PUBLIC GROUNDS */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.inquiry.fields.positionOnPublicGround',
          )}
          value={
            offer.position_on_public_ground
              ? I18n.t(`inquiry.positionOnPublicGround.${accepted}`)
              : I18n.t('general.no')
          }
        />
        {/* ETA */}
        <ReadOnlyField
          label={I18n.t(
            'inquiryDetailsTranslations.section.inquiry.fields.withETA',
          )}
          value={I18n.t(
            `general.${
              offer && offer.with_estimated_time_of_arrival ? 'yes' : 'no'
            }`,
          )}
        />
        {specialNote && (
          <div className='uk-width-1-1'>
            <ReadOnlyField
              label={I18n.t(
                'inquiryDetailsTranslations.section.inquiry.fields.specialNote',
              )}
              value={specialNote}
            />
          </div>
        )}
        {offer.attachments && offer.attachments.length > 0 && (
          <div className='uk-width-1-1'>
            {/* UPLOADED FILES PREVIEW */}
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label className='read-only-field__label' htmlFor={id}>
              {I18n.t(
                'inquiryDetailsTranslations.section.inquiry.fields.attachments',
              )}
            </label>
            <div
              className='inquiry-details-page__attachments-container uk-child-width-1-3@l'
              data-uk-grid=''
            >
              {offer.attachments.map(file => (
                <FilePreviewComponent
                  alt={file.thumbnail}
                  key={file.thumbnail}
                  modalTitle={file.name}
                  src={file.file}
                  file={file}
                  showMultiple
                  onGetComment={() => file.text || ''}
                />
              ))}
            </div>
          </div>
        )}
        <PreviewAttachmentModal
          callback={handlePreviewClick}
          file={selectedFile}
          modalVisible={openModal}
        />
      </div>
    </AccordionItem>
  )
}
