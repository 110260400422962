import { FormikErrors, isString } from 'formik'
import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

import Paragraph, { PARAGRAPH_COLOR } from '../Paragraph/index'

interface ErrorMessagesProps {
  submitCount: number
  isSubmitting: boolean
  isValid: boolean
  translationBase: string
  errors: FormikErrors<any>
}

export const ErrorMessages: FC<ErrorMessagesProps> = ({
  submitCount,
  isSubmitting,
  isValid,
  translationBase,
  errors,
}) => {
  if (submitCount > 0 && !isSubmitting && !isValid) {
    const toCamel = s =>
      s.replace(/([-_]\p{Lowercase_Letter})/giu, $1 =>
        $1.toUpperCase().replace('-', '').replace('_', ''),
      )

    const errorMessages = Object.entries(errors).map(([key, value]) =>
      key !== '' && !isString(value) ? (
        <li key={key} data-testid='error-messages__error'>
          <Translate value={`${translationBase}.label.${toCamel(key)}`} />
        </li>
      ) : (
        <li key={key} data-testid='error-messages__error'>
          <Translate value={`${translationBase}.label.${toCamel(key)}`} />
          &nbsp;-&nbsp;
          {`${value}`}
        </li>
      ),
    )

    return (
      <div className='errormessages' data-testid='error-messages'>
        <Paragraph color={PARAGRAPH_COLOR.BLUE}>
          <Translate value={`${translationBase}.errors.headline`} />
        </Paragraph>
        <ul
          className='uk-list uk-list-space uk-margin-remove'
          data-testid='error-messages__list'
        >
          {errorMessages}
        </ul>
      </div>
    )
  }

  return null
}
