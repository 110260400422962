import moment from 'moment'

/**
 * @function
 * @param {string} val the input value
 * @return {boolean}
 */
export const isRequired = val =>
  val !== undefined && val != null && `${val}`.length > 0

/**
 * @function
 * @param {string} val the input value
 * @return {boolean}
 */
export const isNotEmpty = val =>
  val !== undefined && val != null && `${val}`.trim().length > 0

/**
 * @function
 * @description check that the password is safe.
 * @param {string} val the input value
 * @return {boolean}
 */
export const isEmail = val => {
  // For required there is an extra validation
  if (!val) return true

  const pattern = new RegExp(
    '^[\\p{Letter}\\d.!#$%&’*+/=?^_`{|}~-]+@(([\\p{Letter}\\d-]+[.])+)+([\\p{Letter}\\d-]+)$',
    'u',
  )

  return pattern.test(val)
}

/**
 * @function
 * @description check that the password is safe.
 * @param {string} val the input value
 * @return {boolean}
 */
export const passwordIsSafe = val => {
  // For required there is an extra validation
  if (!val) return true

  // The password must
  // - contain at least one upper case letter
  // - contain at least one lower case letter
  // - contain at least one number
  // - have at least 14 characters
  // - have at least one special character (#?!@$%^&*-)
  const pattern = new RegExp(
    '^(?=.*?\\p{Uppercase_Letter})(?=.*?\\p{Lowercase_Letter})(?=.*?\\p{Number})(?=.*?[#?!@$%^&*-])(?=.*?\\d).{14,}$',
    'u',
  )

  return pattern.test(val)
}

export const isValidDecimal = val => {
  // For required there is an extra validation
  if (!val) return true

  // The field must
  // - be in range 1,00 - 99,99
  // - have 4 digits
  // - have 2 decimal places
  const pattern = new RegExp('^([1-9]\\d?)(?:,\\d{0,2})?$', '')

  return pattern.test(val)
}

/**
 * @function
 * @description check that the passwords are equal
 * @param {string} val the input value
 * @param {string | null} password
 * @return {boolean}
 */
export const passwordIsEqual = (val, password) => {
  // For required there is an extra validation
  if (!val) return true

  return val === password
}

/**
 * @function
 * @description check that the float numbers equals 000.00 format
 * @param {string} val the input value
 * @param {string | null} float
 * @return {boolean}
 */
export const isValidFloat = val => {
  // For required there is an extra validation
  if (!val) return true

  const pattern = new RegExp('([1-9]\\d?\\d?|0)(,\\d{1,2})?', 'i')
  return pattern.test(val)
}

/**
 * @function
 * @description check the valid phone number.
 * @param {string} val the input value
 * @return {boolean}
 */
export const isPhoneNumber = val => {
  if (!val) return true

  const pattern = new RegExp('^\\+?\\d+[\\d\\- \\/]+$', 'iu')

  return pattern.test(val)
}

/**
 * @function
 * @param {string} val the input value
 * @return {boolean}
 */
export const isURL = val => {
  // For required there is an extra validation
  if (!val) return true

  const pattern = new RegExp(
    '^((ftp|http|https):\\/\\/)?' +
      '(www.)?(?!.*(ftp|http|https|www.))' +
      '[\\p{Letter}\\d_-]+(\\.[\\p{Letter}]{2,})+' +
      '((\\/)[\\w#]+)*(\\/\\w+\\?[\\p{Letter}\\d_]+=\\w+(&[\\p{Letter}\\d_]+=\\w+)*)?$',
    'iu',
  )

  return pattern.test(val)
}

/**
 * @function
 * @param {string} val input value
 * @param {number} min the min length
 * @param {number} max the max length
 * @return {boolean}
 */
export const hasLength = (val, min, max) => {
  // For required there is an extra validation
  if (!val) return true

  return val.length >= min && val.length <= max
}

/**
 * @function
 * @param {string} val input value
 * @return {boolean}
 */
export const onlyLettersAndNumbers = val => {
  if (!val) return true

  const pattern = new RegExp('^[\\p{Letter}\\d]*$', 'u') // Fragment locator

  return pattern.test(val)
}

/**
 * @function
 * @description Validates an input to be a German tax ID.
 * @param val
 * @returns {boolean}
 */
export const isGermanTaxId = val => {
  if (!val) return true

  const pattern = new RegExp('^DE\\d{9}$', 'i') // Check german tax ID (DE followed by 9 numbers)

  return pattern.test(val)
}

/**
 * @function
 * @description Validates an input to be a German commercial registry number (HRA/HRB).
 * @param val
 * @returns {boolean}
 */
export const isCommercialRegistryNumber = val => {
  if (!val) return true

  const pattern = new RegExp(
    '(HRA|HRB)\\s*(\\p{Letter}+)*\\s*(\\d+)\\s*(\\d*)\\s*(\\d*)\\s*(\\d*)',
    'iu',
  )

  return pattern.test(val)
}

/**
 * @function
 * @description Validates an input to be a German registration number (DE - 13 digits).
 * @param val
 * @returns {boolean}
 */
export const isRegistrationNumber = val => {
  if (!val) return true

  const pattern = new RegExp('^\\s?(DE)\\s?\\d{13}\\s?$', 'i')

  return pattern.test(val)
}

/**
 * @function
 * @description check that the end date is smaller then start date
 * @param {string | null} endDate
 * @param {string | null} startDate
 * @return {boolean}
 */
export const isEndDateSmallerThenStartDate = (endDate, startDate) => {
  if (!endDate) return true
  const end = moment(endDate, 'DD.MM.YYYY')
  const start = moment(startDate, 'DD.MM.YYYY')
  return end >= start
}
