import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getComCenterContacts } from 'actions/company'
import { getComCenterContactsSelector } from 'selectors/company'

export const useComCenterContacts = ({ companyId }) => {
  const comCenterContacts = useSelector(getComCenterContactsSelector)
  const dispatch = useDispatch()

  useEffect(() => {
    if (companyId) {
      /// use page_size=Number.MAX_SAFE_INTEGER to get all contacts for the current company
      dispatch(
        getComCenterContacts(Number(companyId), 1, Number.MAX_SAFE_INTEGER),
      )
    }
  }, [dispatch, companyId])

  return comCenterContacts
}
