import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getComCenterContactsSelector } from 'selectors/company'
import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'

import { CATEGORY_CHOICES, STAGE_CHOICES } from '../constants'
import { mapContact } from '../helper'

export const useComCenterContactsFilter = () => {
  const [emailDataBookkeeping, setEmailDataBookkeeping] =
    useState<ComCenterContact[]>()
  const [emailDataSales, setEmailDataSales] = useState<ComCenterContact[]>()
  const [emailDataOperationsStage1, setEmailDataOperationsStage1] =
    useState<ComCenterContact[]>()
  const [emailDataOperationsStage2, setEmailDataOperationsStage2] =
    useState<ComCenterContact[]>()
  const [emailDataOperationsStage3, setEmailDataOperationsStage3] =
    useState<ComCenterContact[]>()

  const comCenterContacts = useSelector(getComCenterContactsSelector)

  const status = useSelector(
    createRequestStatusSelector(['GET_COM_CENTER_CONTACTS']),
  )
  const dispatch = useDispatch()

  useEffect(() => {
    if (status === REQUEST_STATUS.SUCCESS && comCenterContacts) {
      setEmailDataBookkeeping(
        comCenterContacts
          ?.filter(
            contact => contact.category === CATEGORY_CHOICES.CHOICE_BOOKKEEPING,
          )
          .map(mapContact),
      )

      setEmailDataSales(
        comCenterContacts
          ?.filter(
            contact => contact.category === CATEGORY_CHOICES.CHOICE_SALES,
          )
          .map(mapContact),
      )

      setEmailDataOperationsStage1(
        comCenterContacts
          ?.filter(
            contact =>
              contact.category === CATEGORY_CHOICES.CHOICE_OPERATIONS &&
              contact.stage === STAGE_CHOICES.CHOICE_STAGE1,
          )
          .map(mapContact),
      )

      setEmailDataOperationsStage2(
        comCenterContacts
          ?.filter(
            contact =>
              contact.category === CATEGORY_CHOICES.CHOICE_OPERATIONS &&
              contact.stage === STAGE_CHOICES.CHOICE_STAGE2,
          )
          .map(mapContact),
      )

      setEmailDataOperationsStage3(
        comCenterContacts
          ?.filter(
            contact =>
              contact.category === CATEGORY_CHOICES.CHOICE_OPERATIONS &&
              contact.stage === STAGE_CHOICES.CHOICE_STAGE3,
          )
          .map(mapContact),
      )
    }
  }, [dispatch, comCenterContacts, status])

  return {
    emailDataBookkeeping,
    emailDataSales,
    emailDataOperationsStage1,
    emailDataOperationsStage2,
    emailDataOperationsStage3,
  }
}
