import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import classNames from 'classnames'
import { bool, element, node, oneOfType, string } from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Element } from 'react-scroll'

import { BREAKPOINT } from 'constants/design'

/**
 * @description This component renders a accordion item
 */
const AccordionItem = ({ children, isOpen, title, addScrollElement }) => {
  const [hidden, setHidden] = useState(false)

  useEffect(() => {
    /**
     * @description method to get window width
     */
    const handleWindowResize = () => {
      setHidden(window.innerWidth < BREAKPOINT.XLARGE)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  const itemContent = (
    <div
      className={classNames(
        { 'uk-accordion-content ': !addScrollElement },
        'accordion-item__content',
      )}
    >
      <div className='uk-width-1-1'>{children}</div>
    </div>
  )

  return (
    <li className={`accordion-item ${isOpen && 'uk-open'}`}>
      <button
        type='button'
        className='uk-accordion-title accordion-item__title uk-width-1-1'
      >
        {title}
      </button>
      {addScrollElement ? (
        <Element
          name={addScrollElement}
          className='uk-accordion-content'
          data-attribute={hidden ? 'hidden' : ''}
          hidden={hidden ? 'hidden' : ''}
        >
          {itemContent}
        </Element>
      ) : (
        itemContent
      )}
    </li>
  )
}

AccordionItem.propTypes = forbidExtraProps({
  addScrollElement: string,
  children: node.isRequired,
  isOpen: bool,
  title: oneOfType([string, element]).isRequired,
})

AccordionItem.defaultProps = {
  addScrollElement: '',
  isOpen: false,
}

export default AccordionItem
