import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { DOCUMENT_STATUS_TYPE } from 'components/invoice/constants'

import { ResetCreditreformForm } from './ResetCreditreformForm'
import { SetCreditreformFormWrapper } from './SetCreditreformFormWrapper'

interface SetCreditreformModalProps {
  isOpen?: boolean
  onClose?: () => void
  document?: Invoice
}

export const CreditreformModal: FC<SetCreditreformModalProps> = ({
  isOpen = false,
  onClose = () => undefined,
  document = undefined,
}) => {
  const idSetDocumentCreditreformModalHeadline = uniqueId()

  const getHeadline = document => {
    if (document?.document_status !== DOCUMENT_STATUS_TYPE.CREDITREFORM) {
      return I18n.t(
        'bookkeepingTranslations.creditreformModal.setCreditreform.title',
      )
    } else {
      return I18n.t(
        'bookkeepingTranslations.creditreformModal.resetCreditreform.title',
      )
    }
  }

  return (
    <Modal
      ariaDescribedBy={idSetDocumentCreditreformModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={getHeadline(document)}
        titleId={idSetDocumentCreditreformModalHeadline}
      />
      {document?.document_status !== DOCUMENT_STATUS_TYPE.CREDITREFORM ? (
        <SetCreditreformFormWrapper onClose={onClose} document={document} />
      ) : (
        <ResetCreditreformForm onClose={onClose} document={document} />
      )}
    </Modal>
  )
}
