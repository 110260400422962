import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'

import { ChangeAgreementPeriodForm } from './ChangeAgreementPeriodForm'

interface ChangeAgreementPeriodModalProps {
  callback: any
  showChangeAgreementIntervalModal: boolean
  offer: Offer
}

export const ChangeAgreementPeriodModal: FC<
  ChangeAgreementPeriodModalProps
> = ({ offer, callback, showChangeAgreementIntervalModal }) => {
  const modalId = uniqueId() // from lodash package. Generate a unique ID

  return (
    <Modal
      ariaDescribedBy={modalId}
      isOpen={showChangeAgreementIntervalModal}
      onClose={callback}
    >
      <ModalHeader
        onClose={callback}
        title={I18n.t('changeAgreementPeriodModalTranslations.modalTitle')}
        titleId={modalId}
      />
      <ChangeAgreementPeriodForm
        offer={offer}
        onSuccess={callback}
        onCancel={callback}
      />
    </Modal>
  )
}
