import { connect } from 'react-redux'

import { getContainers } from 'actions/container'
import { getFractions } from 'actions/fraction'
import {
  getOrderClaim,
  resetOrderClaimItem,
  updateOrderClaimInternalNote,
  updateReviewedOrderClaim,
} from 'actions/orderclaim'
import { getContainersSelector } from 'selectors/container'
import { createErrorSelector } from 'selectors/error'
import { getFractionsSelector } from 'selectors/fraction'
import { createLoadingSelector } from 'selectors/loading'
import { getOrderClaimSelector } from 'selectors/orderclaim'
import { getCurrentUserSelector } from 'selectors/user'

const loadingSelector = createLoadingSelector(['GET_ORDER_CLAIM'])
const saveInternalNoteLoadingSelector = createLoadingSelector([
  'UPDATE_ORDER_CLAIM_INTERNAL_NOTE',
])

const errorSelector = createErrorSelector([
  'GET_ORDER_CLAIM',
  'GET_CURRENT_USER',
  'GET_FRACTIONS',
  'GET_CONTAINERS',
  'UPDATE_REVIEWED_ORDER_CLAIM',
  'UPDATE_ORDER_CLAIM_INTERNAL_NOTE',
])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  user: getCurrentUserSelector(state),
  claim: getOrderClaimSelector(state),
  fractionList: getFractionsSelector(state),
  containerList: getContainersSelector(state),
  isLoading: loadingSelector(state),
  // before clicking on the button for the first time, there is yet no UPDATE_ORDER_CLAIM_INTERNAL_NOTE.
  // This will result in having the loading animation when the component is loaded for the first time.
  // In order to avoid that, this condition is necessary.
  isSaving:
    state.loading.UPDATE_ORDER_CLAIM_INTERNAL_NOTE &&
    saveInternalNoteLoadingSelector(state),
  error: errorSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getFractions: () => dispatch(getFractions()),
  getContainers: () => dispatch(getContainers()),
  getOrderClaim: orderClaimObject => dispatch(getOrderClaim(orderClaimObject)),
  updateReviewedOrderClaim: (orderClaimObject, history) =>
    dispatch(updateReviewedOrderClaim(orderClaimObject, history)),
  updateOrderClaimInternalNote: orderClaimObject =>
    dispatch(updateOrderClaimInternalNote(orderClaimObject)),
  resetOrderClaimItem: () => dispatch(resetOrderClaimItem()),
})

export const connector = connect(mapStateToProps, mapDispatchToProps)
