export const changePasswordFormTranslation = {
  title: 'Passwort ändern',
  oldPassword: {
    label: 'Aktuelles Passwort',
    placeholder: 'Geben Sie Ihr aktuelles Passwort ein.',
    wrongPassword: 'Falsches Passwort',
    required: 'Bitte geben Sie Ihr Passwort ein.',
    notSecure:
      'Mindestens 14 Zeichen: Groß- sowie Kleinbuchstaben, Ziffern und Sonderzeichen.',
  },
  newPassword: {
    label: 'Neues Passwort',
    placeholder: 'Geben Sie ein neues Passwort ein.',
    required: 'Bitte geben Sie Ihr neues Passwort ein.',
    notSecure:
      'Mindestens 14 Zeichen: Groß- sowie Kleinbuchstaben, Ziffern und Sonderzeichen.',
  },
  repeatPassword: {
    label: 'Neues Passwort wiederholen',
    placeholder: 'Wiederholen Sie das neue Passwort.',
    required: 'Bitte holen Sie Ihr neue Passwort wieder.',
    notEqual: 'Die Passwörter müssen übereinstimmen.',
  },
}

export default {}
