import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { InputText } from 'components/common/InputText'
import { NoContentMessage } from 'components/common/NoContentMessage'
import { COMPANY_ROLE } from 'components/company/constants'
import { getCurrentUserSelector } from 'selectors/user'

import { CommonFilterProps } from '../../types'
import { CompanySearchFilter } from '../CompanySearchFilter'
import { FilterReset } from '../FilterReset'
import { cleanUpAndCountFiltersForReset } from '../FilterReset/helpers'

interface CompanyAndIdFilterProps extends CommonFilterProps {
  action: (page: any, filters: any) => any // sorry, Redux with redux-logics is untypeable
  idField?: string
  idTranslation: string
  idFilterActive: boolean
  companyTranslation: string
  companyFilterActive: boolean
  setCurrentFilterValues: (currentFilters: any) => void
  currentFilterValues: any
  resultType?: COMPANY_ROLE
}

export const CompanyAndIdFilter: FC<CompanyAndIdFilterProps> = ({
  action,
  handleBlur,
  handleChange,
  idField = 'id',
  idTranslation,
  idFilterActive = true,
  companyFilterActive = true,
  isLoading,
  length,
  resetForm,
  resultType,
  setCurrentFilterValues,
  currentFilterValues,
  setFieldValue,
  values,
}) => {
  const dispatch = useDispatch()
  const [filterWasReset, setFilterWasReset] = useState(false)
  const [currentCompanyFilter, setCurrentCompanyFilter] =
    useState<Record<string, any>>()

  const user = useSelector(getCurrentUserSelector)

  useEffect(() => {
    const requestValues = {
      ...currentFilterValues,
      company: values.company,
      [idField]: values.id,
      makler_premium_company: user.company,
    }
    if (
      filterWasReset ||
      JSON.stringify(currentFilterValues) !== JSON.stringify(requestValues)
    ) {
      setCurrentFilterValues(requestValues)
      dispatch(action(0, requestValues))
      if (filterWasReset) {
        setFilterWasReset(false)
      }
    }
  }, [
    action,
    currentFilterValues,
    dispatch,
    filterWasReset,
    idField,
    resultType,
    setCurrentFilterValues,
    user.company,
    values,
  ])

  return (
    <>
      <div className='uk-grid uk-child-width-1-5@l uk-child-width-1-2@m'>
        {companyFilterActive && (
          <CompanySearchFilter
            currentFilters={currentCompanyFilter}
            resultType={resultType}
            setCurrentFilterValues={newValues => {
              setFieldValue('company', newValues.id_slug.split(',')[0])
              setCurrentCompanyFilter(newValues)
            }}
            noResultsText={I18n.t(
              'filterTranslations.userListFilter.companySelection.noResults',
            )}
            placeholder={I18n.t(
              'filterTranslations.userListFilter.companySelection.placeholder',
            )}
            label={I18n.t(
              'filterTranslations.userListFilter.companySelection.label',
            )}
            name='company'
            isClearable
            defaultCompanyId={Number(values.company ?? 0)}
          />
        )}
        {idFilterActive && (
          <InputText
            label={idTranslation}
            maxLength={15}
            name='id'
            onBlur={handleBlur}
            onChange={e => {
              handleChange(e)
            }}
            value={values.id}
          />
        )}
        <FilterReset
          onResetFilter={() => {
            setFieldValue('company', '')
            resetForm()
            setFilterWasReset(true)
            setCurrentCompanyFilter({})
            setCurrentFilterValues({})
          }}
          showResetFilterButton={
            cleanUpAndCountFiltersForReset(currentFilterValues) > 0
          }
        />
      </div>
      <div className='filter__empty-message'>
        {!isLoading && length < 1 && (
          <NoContentMessage
            message={I18n.t('general.emptyFilterResultMessage')}
            showResetFilterButton={false}
          />
        )}
      </div>
    </>
  )
}
