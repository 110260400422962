import { createSelector } from 'reselect'

import { ORDER_PROGRESS_TYPES } from '../components/order/constants'

/**
 * @description selector to get an order-claim list.
 * @param state
 * @return {*}
 */
export const getOrderClaimsSelector = createSelector(
  state => state.orderClaim.itemList,
  claimList =>
    claimList.map(claim => ({
      ...claim,
      order_object: claim.order_object
        ? {
            ...claim.order_object,
            offer_object: claim.order_object.offer_object
              ? {
                  ...claim.order_object.offer_object,
                  net_cost: Number(claim.order_object.offer_object.net_cost),
                  total_cost: Number(
                    claim.order_object.offer_object.total_cost,
                  ),
                  total_revenue: Number(
                    claim.order_object.offer_object.total_revenue,
                  ),
                }
              : null,
          }
        : null,
    })),
)

/**
 * @description selector to get an order-claim item.
 * @param state
 * @return {*}
 */
export const getOrderClaimSelector = state => state.orderClaim.item

/**
 * @description selector to get an order-claim pagination.
 * @param state
 * @return {*}
 */
export const getOrderClaimPaginationSelector = state =>
  state.orderClaim.pagination

/**
 * @description selector to get an order-status and claims list.
 * @param state
 * @return {*}
 */
export const getOrderClaimUniFormatSelector = createSelector(
  getOrderClaimsSelector,
  claims =>
    claims.map(claim => ({
      claim_company: claim.company_object?.id,
      claim_review_date: claim.reviewed_at,
      claim_status: claim.status,
      claim_type: claim.claim_type,
      claimed_by: claim.claimed_by_company_name,
      created_at: claim.created_at,
      created_by_name: claim.created_by_name,
      company_name: claim.company_object ? `${claim.company_object.name}` : '',
      id: claim.id,
      order: claim.order,
      progressType: ORDER_PROGRESS_TYPES.ORDER_PROGRESS_CLAIM,
      value: claim.claim_type,
    })),
)

export default {}
