import React, { FC } from 'react'

import { Input, INPUT_TYPE, InputProps } from '../Input'

interface InputTelephoneNumberProps extends InputProps {
  maxLength?: number
}

export const InputTelephoneNumber: FC<InputTelephoneNumberProps> = ({
  maxLength = 255,
  onChange,
  ...rest
}) => {
  const onValueChange = event => {
    if (event.target.value.length <= maxLength) onChange(event)
  }

  const keyPressEvents = event => {
    // only numbers, spaces, "+", "-" and "/" are allowed
    if (!/^\+?[\d \-/]*$/.test(event.key)) event.preventDefault()
  }

  return (
    <Input
      onChange={onValueChange}
      onKeyPress={keyPressEvents}
      type={INPUT_TYPE.TEL}
      {...rest}
    />
  )
}
