const executionProofApiTranslations = {
  handleGetMyExecutionProofs: {
    error: 'Ihre Leistungsnachweise konnten leider nicht geladen werden.',
  },
  handleGetExecutionProofs: {
    error: 'Leistungsnachweise konnten nicht leider geladen werden.',
  },
  handleGetExecutionProof: {
    error: 'Leistungsnachweis konnte leider nicht geladen werden.',
  },
  handleCreateExecutionProof: {
    success: 'Leistungsnachweis wurde erfolgreich gespeichert.',
    error: 'Hinzufügen des Leistungsnachweises leider fehlgeschlagen.',
  },
  handleUpdateExecutionProof: {
    success: 'Leistungsnachweis wurde erfolgreich gespeichert.',
    error: 'Speichern des Leistungsnachweises leider fehlgeschlagen.',
  },
  handleUpdateReviewedExecutionProof: {
    success: 'Prüfbericht für den Leistungsnachweis erfolgreich gespeichert.',
    error:
      'Speichern des Prüfberichts für den Leistungsnachweis leider fehlgeschlagen.',
  },
  handleExportExecutionProofs: {
    success: 'Export der Leistungsnachweise wurde erfolgreich gestartet.',
    error: 'Export der Leistungsnachweise leider fehlgeschlagen.',
  },
}

export default executionProofApiTranslations
