import './style.scss'

import React from 'react'

import { MenuItems } from 'components/layout/Header/MenuItems'
import { MenuType } from 'components/layout/Header/MenuItems/constants'

export const UserMenu = () => {
  return (
    <ul className='uk-nav uk-navbar-dropdown-nav limit-height'>
      <MenuItems showIcon menuType={MenuType.User} />
    </ul>
  )
}
