import { Form, withFormik } from 'formik'
import React, { Component } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import * as yup from 'yup'
import { bool, func, number, shape, string } from 'prop-types'

import withErrorBoundary from 'helper/withErrorBoundary'

import { BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import Icon from '../../common/Fontello/index'
import { isEmail } from '../../common/Form/validators'
import { InputEmail } from '../../common/InputEmail'
import { ProgressButton } from '../../common/ProgressButton'
import { MAX_LENGTH } from '../../homepages/HomepageRegister/constants'

import connector from './connector'

/**
 * @description This component renders and controls a forget password component.
 * @function
 * @param {Object} props the component props
 */
export class ForgetPassword extends Component {
  static propTypes = {
    errors: shape({
      email: string,
    }).isRequired,
    handleBlur: func.isRequired,
    handleChange: func.isRequired,
    handleSubmit: func.isRequired,
    isLoading: bool,
    isSubmitting: bool.isRequired,
    isValid: bool.isRequired,
    submitCount: number.isRequired,
    touched: shape({
      email: bool,
    }).isRequired,
    values: shape({
      email: string,
    }).isRequired,
  }

  static defaultProps = {
    isLoading: false,
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      isLoading,
      isSubmitting,
      isValid,
      submitCount,
      touched,
      values,
    } = this.props

    return (
      <>
        <Helmet>
          <title>{I18n.t('pageTitles.forgotPassword')}</title>
        </Helmet>

        <div className='forget-password-container uk-align-center uk-width-1-1@s uk-width-3-5@l uk-width-1-3@xl'>
          <div className='uk-card uk-card-default'>
            <div className='uk-card-body'>
              <h2>
                <Translate value='forgetPassword.title' />
              </h2>

              <Form
                className='forget-password-form'
                data-testid='forget-password-form'
                onSubmit={handleSubmit}
                noValidate // Disable browser validation
              >
                <fieldset className='uk-fieldset'>
                  <div className='uk-margin'>
                    <div className='uk-position-relative uk-width-1-1'>
                      <InputEmail
                        dataTestId='forget-password-email-input'
                        dataTestIdError='form-group-error'
                        error={
                          submitCount > 0 && touched.email ? errors.email : ''
                        }
                        isRequired
                        label={I18n.t('forgetPasswordTranslations.email.label')}
                        maxLength={MAX_LENGTH.MAIL_ADDRESS}
                        name='email'
                        onBlur={handleBlur}
                        onChange={handleChange}
                        placeholder={I18n.t(
                          'forgetPasswordTranslations.email.placeholder',
                        )}
                        value={values.email}
                      />
                    </div>
                  </div>
                  <div className='uk-margin'>
                    <ProgressButton
                      backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                      dataTestId='forget-password-form-submit'
                      fullWidth
                      isDisabled={
                        (submitCount > 0 && !isValid) ||
                        (isSubmitting && isLoading)
                      }
                      isLoading={isSubmitting && isLoading}
                      onClick={handleSubmit}
                    >
                      <Icon name='enter' />
                      <Translate value='forgetPasswordTranslations.submit' />
                    </ProgressButton>
                  </div>
                </fieldset>
              </Form>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default compose(
  withRouter,
  withErrorBoundary,
  connector,
  // @TODO bb use <Formik> component instead
  withFormik({
    handleSubmit: (values, { props }) => {
      props.requestPasswordReset(values, props.history)
    },
    mapPropsToValues: () => ({
      email: '',
    }),
    validationSchema: () =>
      yup.object().shape({
        email: yup
          .string()
          .required(I18n.t('forgetPasswordTranslations.email.required'))
          .test(
            'valid-email',
            I18n.t('forgetPasswordTranslations.email.invalid'),
            isEmail,
          ),
      }),
  }),
)(ForgetPassword)
