import { HomepagePrivateCustomer } from '../components/homepages/HomepagePrivateCustomer'
import { HomepageRegister } from '../components/homepages/HomepageRegister'
import RegistrationDone from '../components/homepages/HomepageRegister/components/RegistrationDone'
import { HomepageWasteDisposer } from '../components/homepages/HomepageWasteDisposer'
import { HomepageWastemanager } from '../components/homepages/HomepageWastemanager'
import { HomepageWasteProducer } from '../components/homepages/HomepageWasteProducer'
import HomepageWasteTypeComponent from '../components/homepages/HomepageWasteType'
import { RequestDemo } from '../components/homepages/RequestDemo'
import WasteTypeDetailPage from '../components/homepages/WasteTypeDetailPage'

export const homepageRoutes = [
  {
    path: '/gewerbetreibende',
    component: HomepageWasteProducer,
    public: true,
    exact: true,
  },
  {
    path: '/privatkunden',
    component: HomepagePrivateCustomer,
    public: true,
    exact: true,
  },
  {
    path: '/entsorger',
    component: HomepageWasteDisposer,
    public: true,
    exact: true,
  },
  {
    path: '/registrieren',
    component: HomepageRegister,
    public: true,
    exact: true,
  },
  {
    path: '/registrieren/done',
    component: RegistrationDone,
    exact: true,
    public: true,
  },
  {
    path: '/abfallmanager',
    component: HomepageWastemanager,
    public: true,
    exact: true,
  },
  {
    path: '/abfallarten',
    component: HomepageWasteTypeComponent,
    public: true,
    exact: true,
  },
  {
    path: '/abfallarten/:wasteTypeSlug',
    component: WasteTypeDetailPage,
    public: true,
    exact: true,
  },
  {
    path: '/demo-anfordern',
    component: RequestDemo,
    public: true,
    exact: true,
  },
]

export default {}
