export const convertInquiryFormTranslations = {
  contactPerson: {
    label: 'Ansprechpartner',
    placeholder: 'Ansprechpartner',
  },
  email: {
    label: 'E-Mail',
  },
  buttons: {
    submit: 'Umwandlung versenden',
  },
}
