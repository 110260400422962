import { Formik } from 'formik'
import React, { ReactText, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import { useParams } from 'react-router'

import { resetApiFetchErrors } from 'actions/app'
import { uploadFiles } from 'actions/attachment'
import { inviteUser, updateUser } from 'actions/user'
import { MaklerPremiumGroups, UserPermission } from 'constants/user'
import { checkRequiredPermissions } from 'helper/permissions'
import { USER_GENDER_LABELS } from 'helper/user'
import { getCurrentUserSelector, getUserSelector } from 'selectors/user'

import {
  replaceAllWhitespaces,
  replaceUmlauts,
} from '../../common/StaticCombobox/helpers'

import { UserFormInnerForm } from './UserFormInnerForm'

export type UserFormValues = {
  gender: ReactText
  first_name: string
  last_name: string
  preferred_address: ReactText
  group_id?: ReactText
  email: string
  business_phone_number?: string
  profile_image?: File | Attachment
  orientation?: string
}

export type UserFormErrors = {
  [key in keyof UserFormValues]?: string
}

export const UserFormFormikWrapper = ({
  onSuccess,
  onCancel,
  isCurrentUser,
  filters,
  maklerPremiumContactPage,
}) => {
  const dispatch = useDispatch()

  const { companyId } = useParams<{ companyId: string }>()
  // Altough there is no effect to cleanup, we can use the "useEffect" capabilities to reset the ApiErrors on
  // general cleanup. This will behave like previously using "componentWillUnmount". A sole function is returned by
  // the effect with the desired action
  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors('INVITE_USER'))
    },
    [dispatch],
  )

  useEffect(
    () => () => {
      dispatch(resetApiFetchErrors('UPDATE_USER'))
    },
    [dispatch],
  )

  const currentUser = useSelector(getCurrentUserSelector)
  const selectedUser = useSelector(getUserSelector)

  /* eslint-disable-next-line no-undef */
  const emptyFile = new File([''], '')
  return (
    <>
      {!maklerPremiumContactPage && (
        <Formik
          initialValues={{
            gender: selectedUser.id ? selectedUser.gender : '',
            first_name: selectedUser.id ? selectedUser.first_name : '',
            last_name: selectedUser.id ? selectedUser.last_name : '',
            preferred_address:
              selectedUser.id && selectedUser.preferred_address
                ? selectedUser.preferred_address
                : 'all',
            group_id: selectedUser.id ? selectedUser.group_id : '',
            email: selectedUser.id ? selectedUser.email : '',
            profile_image: selectedUser.profile_image_object
              ? selectedUser.profile_image_object
              : emptyFile,
          }}
          validate={(values: UserFormValues) => {
            const errors: UserFormErrors = {}
            // in case the user has no address input field, validation should not be considered
            if (
              checkRequiredPermissions(currentUser.permission_codenames, [
                UserPermission.ADD_ADDRESS,
              ]) &&
              values.preferred_address === ''
            ) {
              errors.preferred_address = I18n.t(
                'message.validation.selectionRequired',
              )
            }

            return { ...errors, ...errors }
          }}
          validationSchema={() =>
            yup.object().shape({
              gender: yup
                .string()
                .oneOf(
                  Object.keys(USER_GENDER_LABELS()),
                  I18n.t('message.validation.invalidSelection'),
                )
                .required(I18n.t('message.validation.selectionRequired')),
              first_name: yup
                .string()
                .required(I18n.t('message.validation.required')),
              last_name: yup
                .string()
                .required(I18n.t('message.validation.required')),
              orientation: yup.string(),
              preferred_address: yup.string(),
              group_id: yup
                .string()
                .required(I18n.t('message.validation.selectionRequired')),
              email: yup
                .string()
                .email(I18n.t('message.validation.email.isEmail'))
                .required(I18n.t('message.validation.email.required')),
              profile_image: yup.object().nullable(),
            })
          }
          onSubmit={(values, { setFieldError }) => {
            const callbacksToApi = {
              success: () => onSuccess(),
              setError: (key, string) => setFieldError(key, string),
            }
            // If no new image was set to be uploaded, send the id of the current image to the updateAccount api call
            const noUserPicture =
              values.profile_image && values.profile_image.size === 0
            const requestToInvite = {
              ...values,
              profile_image: noUserPicture
                ? null
                : (values.profile_image as Attachment).id,
              skip_email_check: true,
            }
            const requestToUpdate = { id: selectedUser.id, ...requestToInvite }

            if (selectedUser.id) {
              // Profile picture logic only if user is current user
              if (isCurrentUser) {
                // if user has a profile picture but no new image was introduced in the form
                if (
                  !noUserPicture &&
                  !(values.profile_image as Attachment).id
                ) {
                  const callbacks = {
                    updateAccount: uploadedPictureId =>
                      dispatch(
                        updateUser({
                          ...requestToUpdate,
                          profile_image: uploadedPictureId,
                        }),
                      ),
                    // send the orientation with the picture,
                    // so we know hoe to rotate it when calling the image from the backend
                    orientation: values.orientation,
                  }
                  dispatch(
                    uploadFiles(
                      [
                        {
                          file: (values.profile_image as Attachment).data,
                          text: (values.profile_image as Attachment).text,
                        },
                      ],
                      callbacks,
                    ),
                  )
                }
              }

              dispatch(updateUser(requestToUpdate, callbacksToApi))
            } else {
              dispatch(inviteUser(requestToInvite, callbacksToApi))
            }
          }}
        >
          <UserFormInnerForm
            isCurrentUser={isCurrentUser}
            currentUser={currentUser}
            selectedUser={selectedUser}
            onCancel={onCancel}
            filters={filters}
            maklerPremiumContactPage={maklerPremiumContactPage}
          />
        </Formik>
      )}

      {maklerPremiumContactPage && (
        <Formik
          initialValues={{
            gender: selectedUser.id ? selectedUser.gender : '',
            first_name: selectedUser.id ? selectedUser.first_name : '',
            last_name: selectedUser.id ? selectedUser.last_name : '',
            preferred_address:
              selectedUser.id && selectedUser.preferred_address
                ? selectedUser.preferred_address
                : 'all',
            business_phone_number:
              selectedUser.id && selectedUser.business_phone_number
                ? selectedUser.business_phone_number
                : '',
            email: selectedUser.id ? selectedUser.email : '',
          }}
          validate={(values: UserFormValues) => {
            const errors: UserFormErrors = {}
            // in case the user has no address input field, validation should not be considered
            if (
              checkRequiredPermissions(currentUser.permission_codenames, [
                UserPermission.ADD_ADDRESS,
              ]) &&
              values.preferred_address === ''
            ) {
              errors.preferred_address = I18n.t(
                'message.validation.selectionRequired',
              )
            }

            return { ...errors, ...errors }
          }}
          validationSchema={() =>
            yup.object().shape({
              gender: yup
                .string()
                .oneOf(
                  Object.keys(USER_GENDER_LABELS()),
                  I18n.t('message.validation.invalidSelection'),
                )
                .required(I18n.t('message.validation.selectionRequired')),
              first_name: yup
                .string()
                .required(I18n.t('message.validation.required')),
              last_name: yup
                .string()
                .required(I18n.t('message.validation.required')),
              preferred_address: yup.string(),
              business_phone_number: yup.string(),
              email: yup
                .string()
                .email(I18n.t('message.validation.email.isEmail')),
            })
          }
          onSubmit={(values, { setFieldError }) => {
            const callbacksToApi = {
              success: () => onSuccess(),
              setError: (key, string) => setFieldError(key, string),
            }
            let { email } = values
            if (!values.email) {
              email = replaceAllWhitespaces(
                replaceUmlauts(
                  `${
                    values.first_name + values.last_name + new Date().getTime()
                  }@maklerpremium.example.com`,
                ),
              )
            }
            const requestToInvite = {
              ...values,
              user_company: filters.company,
              group_id: MaklerPremiumGroups.MAKLER_PREMIUM_EPD_CONTACT_PERSON,
              email,
              skip_email_check: true,
            }
            if (companyId) {
              requestToInvite.user_company = companyId
            }
            const requestToUpdate = { id: selectedUser.id, ...requestToInvite }

            if (selectedUser.id) {
              dispatch(updateUser(requestToUpdate, callbacksToApi))
            } else {
              dispatch(inviteUser(requestToInvite, callbacksToApi))
            }
          }}
        >
          <UserFormInnerForm
            isCurrentUser={isCurrentUser}
            currentUser={currentUser}
            selectedUser={selectedUser}
            onCancel={onCancel}
            filters={filters}
            maklerPremiumContactPage={maklerPremiumContactPage}
          />
        </Formik>
      )}
    </>
  )
}
