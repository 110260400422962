import { getStatusConf } from '../../helper/orderstatus'

type GetOrderStatusProps = (
  status: number | string,
  order: Order | OrderListItem,
  user: User,
) => string | any

/**
 * @description Returns the progress key of an order, given its progress status id, user and the order
 */
export const getLatestOrderStatusTranslation: GetOrderStatusProps = (
  status,
  order,
  user,
) => {
  if (status > 0) {
    return getStatusConf(status, order, user).name
  }
  return 'order.status.ordered'
}
