import { Form, Formik } from 'formik'
import moment from 'moment/moment'
import React from 'react'
import { I18n } from 'react-i18nify'
import * as yup from 'yup'

import InputDate from 'components/common/InputDate'

type ExportInvoiceBatchFormPropsType = {
  setStartDate: (startDate: string) => void
  setEndDate: (endDate: string) => void
}

export const ExportInvoiceBatchForm = (
  props: ExportInvoiceBatchFormPropsType,
) => {
  const { setStartDate, setEndDate } = props

  const minDate = moment('01.01.2017', 'DD.MM.YYYY')

  const getMinDateForEndDate = values => {
    if (values.startDate)
      // eslint-disable-next-line import/no-named-as-default-member
      return moment.max(moment(values.startDate, 'L'), minDate)
    return minDate
  }

  return (
    <>
      <Formik
        initialValues={{
          startDate: '',
          endDate: '',
        }}
        validationSchema={() =>
          yup.object().shape({
            startDate: yup
              .string()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('${value} ist nicht vom Typ ${type}'),
            endDate: yup
              .string()
              // eslint-disable-next-line no-template-curly-in-string
              .typeError('${value} ist nicht vom Typ ${type}'),
          })
        }
        validate={() => {
          const errors: {
            startDate?: string
            endDate?: string
          } = {}

          return errors
        }}
        onSubmit={() => {
          return
        }}
      >
        {({
          errors,
          setFieldTouched,
          setFieldValue,
          submitCount,
          touched,
          values,
        }) => (
          <Form
            noValidate // Disable browser validation
          >
            <InputDate
              showYearDropdown
              error={
                submitCount > 0 && touched.startDate ? errors.startDate : ''
              }
              dataTestId='service-start-date-input'
              label={I18n.t(
                'bookkeepingTranslations.exportBatchModal.form.startDateLabel',
              )}
              minDate={minDate}
              maxDate={moment()}
              name='startDate'
              onChange={value => {
                setFieldValue('startDate', value.format('L'))
                setStartDate(value.format('L'))
                setFieldTouched('startDate')

                if (
                  values.endDate &&
                  moment(value, 'L') > moment(values.endDate, 'L')
                ) {
                  setFieldValue('endDate', '')
                  setEndDate('')
                }
              }}
              placeholder={I18n.t(
                'bookkeepingTranslations.exportBatchModal.form.fillOrOptional',
              )}
              value={values.startDate}
              validateOnMount={false} // allow initial before minDate, but disallow selecting dates in the past
            />

            <InputDate
              showYearDropdown
              error={submitCount > 0 && touched.endDate ? errors.endDate : ''}
              dataTestId='service-end-date-input'
              label={I18n.t(
                'bookkeepingTranslations.exportBatchModal.form.endDateLabel',
              )}
              minDate={getMinDateForEndDate(values)}
              maxDate={moment()}
              name='endDate'
              onChange={value => {
                setFieldValue('endDate', value.format('L'))
                setEndDate(value.format('L'))
                setFieldTouched('endDate')
              }}
              placeholder={I18n.t(
                'bookkeepingTranslations.exportBatchModal.form.fillOrOptional',
              )}
              value={values.endDate}
              validateOnMount={false} // allow initial before minDate, but disallow selecting dates in the past
            />
          </Form>
        )}
      </Formik>
      <p className='uk-text'>
        {I18n.t('bookkeepingTranslations.exportBatchModal.form.explanation')}
      </p>
    </>
  )
}
