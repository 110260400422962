import ActionTypes from '../actiontypes/attachment'

/** @function
 * @name uploadFiles
 * @description An action method to upload files.
 * @return {Object}
 */
export const uploadFiles = (files, callbacks) => ({
  type: ActionTypes.UPLOAD_FILES_REQUEST,
  payload: {
    files,
    callbacks, // methods that will be passed to the logic
  },
})

export default {}
