import { useFormikContext } from 'formik'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { Checkbox } from 'components/common/Checkbox'
import { DropDownInput } from 'components/common/DropDownInput'
import InputDate from 'components/common/InputDate'
import { ProgressButton } from 'components/common/ProgressButton'
import { Textarea } from 'components/common/Textarea'
import { PAYMENT_METHODS_EPD } from 'components/company/constants'

import { SetCancelledDocumentDoneFormValues } from './SetCancelledDocumentDoneModal'

type SetCancelledInvoiceDoneFormPropsType = {
  onClose?: () => void
  isCreditNote: boolean
}

export const SetCancelledDocumentDoneForm = (
  props: SetCancelledInvoiceDoneFormPropsType,
) => {
  const { onClose = () => undefined } = props

  const {
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldTouched,
    setFieldValue,
    submitCount,
    touched,
    values,
  } = useFormikContext<SetCancelledDocumentDoneFormValues>()

  const [paymentFieldsHidden, setPaymentFieldsHidden] = useState<boolean>(false)

  return (
    <>
      <div
        className='uk-modal-body bookkeeping-list__form_modal-fields'
        data-testid='bookkeeping-list__form_modal-fields'
      >
        <Checkbox
          isChecked={paymentFieldsHidden}
          name='payment_fields_hidden'
          label={I18n.t(
            'bookkeepingTranslations.setCancelledDocumentDoneModal.fields.togglePaymentFieldsText',
          )}
          onChange={() => {
            setFieldValue('payment_fields_hidden', !paymentFieldsHidden)
            setPaymentFieldsHidden(!paymentFieldsHidden)
          }}
        />
        {!values.payment_fields_hidden && (
          <>
            <InputDate
              error={
                submitCount > 0 &&
                values.paid_at === undefined &&
                touched.paid_at &&
                errors.paid_at
                  ? errors.paid_at
                  : ''
              }
              placeholder={I18n.t(
                'bookkeepingTranslations.setCancelledDocumentDoneModal.fields.paidAtPlaceholder',
              )}
              label={I18n.t(
                'bookkeepingTranslations.setCancelledDocumentDoneModal.fields.paidAt',
              )}
              isRequired={!values.paid_at}
              maxDate={moment()}
              name='paid_at'
              onChange={value => {
                setFieldValue('paid_at', value.format('L'))
                setFieldTouched('paid_at')
              }}
              value={values.paid_at}
            />
            <DropDownInput
              choices={PAYMENT_METHODS_EPD.sort((a, b) => {
                const nameA = I18n.t(a.name).toLowerCase()
                const nameB = I18n.t(b.name).toLowerCase()
                return nameA > nameB ? 1 : -1
              }).map(item => ({
                optionValue: item.id,
                optionLabel: `${I18n.t(item.name)}`,
              }))}
              error={submitCount > 0 ? errors.payment_method : ''}
              label={I18n.t(
                'bookkeepingTranslations.setCancelledDocumentDoneModal.fields.actualPaymentMethod',
              )}
              name='payment_method'
              onChange={handleChange}
              value={values.payment_method}
            />
            <Textarea
              label={I18n.t(
                'bookkeepingTranslations.setCancelledDocumentDoneModal.fields.transactionKey',
              )}
              maxLength={100}
              name='transaction_key'
              onChange={handleChange}
              placeholder={I18n.t(
                'bookkeepingTranslations.setCancelledDocumentDoneModal.fields.transactionKeyPlaceholder',
              )}
              showRemainingCharacters
              value={values.transaction_key}
            />
          </>
        )}
        <Textarea
          label={I18n.t(
            'bookkeepingTranslations.setCancelledDocumentDoneModal.fields.comment',
          )}
          maxLength={500}
          name='comment'
          onChange={handleChange}
          placeholder={I18n.t(
            'bookkeepingTranslations.setCancelledDocumentDoneModal.fields.commentPlaceholder',
          )}
          showRemainingCharacters
          value={values.comment}
        />
      </div>

      <div className='uk-modal-footer uk-text-right uk-padding-remove-right'>
        <Button
          className='uk-button uk-margin-medium-right'
          backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
          onClick={onClose}
        >
          <Translate value='general.button.cancel' />
        </Button>

        <ProgressButton
          className='uk-button uk-margin-medium-right'
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          isDisabled={
            submitCount > 0 &&
            !isValid &&
            !values.payment_fields_hidden &&
            values.paid_at === undefined
          }
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          <Translate value='general.button.save' />
        </ProgressButton>
      </div>
    </>
  )
}
