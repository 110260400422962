import addressApiTranslations from './endpoints/address'
import attachmentApiTranslations from './endpoints/attachment'
import autoOfferApiTranslations from './endpoints/autooffer'
import certificateApiTranslations from './endpoints/certificate'
import communityApiTranslations from './endpoints/community'
import companyApiTranslations from './endpoints/company'
import containerApiTranslations from './endpoints/container'
import { coreApiTranslations } from './endpoints/core'
import { countryApiTranslations } from './endpoints/country'
import dashboardApiTranslations from './endpoints/dashboard'
import { demoApiTranslations } from './endpoints/demo'
import discountApiTranslations from './endpoints/discount'
import { dszApiTranslations } from './endpoints/dsz'
import { emptoDownloadsApiTranslations } from './endpoints/emptodownloads'
import { emptoInternalDownloadsApiTranslations } from './endpoints/emptointernaldownloads'
import executionProofApiTranslations from './endpoints/executionproof'
import fractionApiTranslations from './endpoints/fraction'
import { holidayApiTranslations } from './endpoints/holiday'
import indexesApiTranslations from './endpoints/indexes'
import inquiryApiTranslations from './endpoints/inquiry'
import invoiceApiTranslations from './endpoints/invoice'
import landingPageApiTranslations from './endpoints/landingpage'
import { maklerPremiumApiTranslations } from './endpoints/maklerpremium'
import offerApiTranslations from './endpoints/offer'
import orderApiTranslations from './endpoints/order'
import orderClaimApiTranslations from './endpoints/orderclaim'
import orderStatusApiTranslations from './endpoints/orderstatus'
import securityGroupApiTranslations from './endpoints/securitygroup'
import userApiTranslations from './endpoints/user'
import zipcodeApiTranslations from './endpoints/zipcode'

const apiTranslations = {
  address: addressApiTranslations,
  attachment: attachmentApiTranslations,
  autoOffer: autoOfferApiTranslations,
  certificate: certificateApiTranslations,
  community: communityApiTranslations,
  company: companyApiTranslations,
  country: countryApiTranslations,
  container: containerApiTranslations,
  core: coreApiTranslations,
  discount: discountApiTranslations,
  dashboard: dashboardApiTranslations,
  demo: demoApiTranslations,
  dsz: dszApiTranslations,
  emptoDownloads: emptoDownloadsApiTranslations,
  emptoInternalDownloads: emptoInternalDownloadsApiTranslations,
  executionProof: executionProofApiTranslations,
  fraction: fractionApiTranslations,
  holiday: holidayApiTranslations,
  indexes: indexesApiTranslations,
  inquiry: inquiryApiTranslations,
  invoice: invoiceApiTranslations,
  landingpage: landingPageApiTranslations,
  maklerPremium: maklerPremiumApiTranslations,
  offer: offerApiTranslations,
  order: orderApiTranslations,
  orderClaim: orderClaimApiTranslations,
  orderStatus: orderStatusApiTranslations,
  securityGroup: securityGroupApiTranslations,
  user: userApiTranslations,
  zipcode: zipcodeApiTranslations,
}

export default apiTranslations
