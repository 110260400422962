import { Form, Formik } from 'formik'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { uploadFiles } from 'actions/attachment'
import { updateOrderAttachments } from 'actions/order'
import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import FileUploadService from 'components/common/fileUpload/services'
import { ProgressButton } from 'components/common/ProgressButton'
import { UniversalFileUpload } from 'components/common/UniversalFileUpload'
import { getOrderSelector } from 'selectors/order'

export interface OrderAttachmentValues {
  attachments: Attachment[]
}

export interface OrderAttachmentFormProps {
  handleCloseModal: () => void
}

export const OrderAttachmentForm: FC<OrderAttachmentFormProps> = ({
  handleCloseModal,
}) => {
  const dispatch = useDispatch()
  const order = useSelector(getOrderSelector)
  const initialAttachments = order?.attachments

  return (
    <Formik
      onSubmit={(values: OrderAttachmentValues) => {
        const uploadCallbacks = {
          onDocumentSubmit: attachments => {
            dispatch(
              updateOrderAttachments(order.id, {
                ...values,
                attachments,
              }),
            )
          },
        }

        const newAttachments: { file: any }[] = []
        const currentAttachmentIds = values.attachments?.map(
          attachment => attachment!.id,
        )
        if (initialAttachments && currentAttachmentIds) {
          // Remove attachments that are not in values.attachment anymore
          initialAttachments.forEach(attachment => {
            if (!currentAttachmentIds.includes(attachment.id)) {
              FileUploadService.removeFile(attachment)
            }
          })
        }
        // Add new attachments to newAttachments array
        values.attachments.forEach(attachment => {
          if (attachment.data) {
            newAttachments.push({ file: attachment.data })
          }
        })

        // Do not send attachments that are empty (only appears in update for existing attachments)
        dispatch(uploadFiles(newAttachments, uploadCallbacks))
      }}
      initialValues={{
        attachments: order.attachments,
      }}
      validationSchema={yup.object().shape({
        attachments: yup.object().shape({}).nullable(),
      })}
    >
      {({
        values,
        handleSubmit,
        setFieldValue,
        validateForm,
        submitCount,
        isValid,
      }) => (
        <div className='uk-modal-body'>
          <Form
            className='uk-panel makler-premium-form'
            onSubmit={handleSubmit}
            noValidate // Disable browser validation
          >
            <UniversalFileUpload
              name='attachments'
              label={I18n.t('orderDetails.details.modal.form.uploadField')}
              allowedFileTypes='image/png, image/jpeg, application/pdf'
              maxFiles={128} // Should be unlimited
              initialValues={values.attachments}
              onChange={async fileList => {
                setFieldValue('attachments', fileList, true)
                if (submitCount > 0) await validateForm()
              }}
              withCheckmark
              showFilename
            />
            <div className='uk-grid uk-margin-bottom uk-margin-top'>
              <div
                className='uk-width-1-1 universal-file-upload--with-checkmark'
                data-uk-margin=''
              >
                <ProgressButton
                  backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                  fullWidth
                  isDisabled={!isValid}
                  onClick={() => {
                    handleSubmit()
                    handleCloseModal()
                  }}
                >
                  <Translate value='general.button.save' />
                </ProgressButton>
              </div>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  )
}
