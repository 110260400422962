import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import { CREATE_DEMO_REQUEST } from '../actiontypes/demo'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

export const handleCreateDemoRequest = createLogic({
  type: CREATE_DEMO_REQUEST.CREATE_DEMO_REQUEST_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/demo/`,
        action.payload.demoRequest,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: CREATE_DEMO_REQUEST.CREATE_DEMO_REQUEST_SUCCESS,
          payload: res,
        })

        if (action.payload.callback) {
          action.payload.callback.success()
        }
        toastr.success('', I18n.t('api.demo.handleCreateDemoRequest.success'))
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.demo.handleCreateDemoRequest.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: CREATE_DEMO_REQUEST.CREATE_DEMO_REQUEST_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export const demoLogic = [handleCreateDemoRequest]
