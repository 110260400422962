import DashboardActionTypes from '../actiontypes/dashboard'
import OrderActionTypes from '../actiontypes/order'
import UserActionTypes from '../actiontypes/user'
import { isUpcomingPlannedOrder } from '../helper/dashboard'

/**
 * @description The initialState-properties must be undefined, cause of the loading-spinner in Dashboard
 */
export const initialState = {
  dashboard: {
    action_required_count: undefined,
    active_accepted_offers: undefined,
    orders_today_count: undefined,
    orders_today_last_week_count: undefined,
    orders_this_month_count: undefined,
    orders_last_month_count: undefined,
    offers_today_count: undefined,
    offers_today_last_week_count: undefined,
    news: undefined,
    new_company_orders: undefined,
    offer_pipeline_count: undefined,
    open_company_offers: undefined,
    offer_companies_this_month_maps: [],
    planned_company_orders: undefined,
    waste_producer_planned_orders: undefined,
    loading: true,
  },
  dashboardEPD: {
    offer_pipeline: {
      count: undefined,
      forecast: '',
    },
    sales_today: {
      sales: '',
      sales_to_compare: '',
      sales_shop: '',
    },
    sales_month: {
      sales: '',
      sales_to_compare: '',
      sales_shop: '',
    },
    offer_today: {
      count: undefined,
      sales: '',
      changes_of_success: '',
    },
    offer_month: {
      count: undefined,
      sales: '',
      changes_of_success: '',
    },
    order_today: 9,
    order_month: 176,
    best_employees: [
      {
        user: undefined,
        sales: '',
        count: undefined,
      },
      {
        user: undefined,
        sales: '',
        count: undefined,
      },
      {
        user: undefined,
        sales: '',
        count: undefined,
      },
    ],
    loaded: false,
  },
}

/**
 * @description A reducer for dashboard.
 * @param state is the current global state from store.
 * @param action is the called action.
 * @return
 */
export const dashboardReducer = (
  state: any = initialState,
  action: {
    type: keyof (typeof DashboardActionTypes | typeof OrderActionTypes)
    payload?: Dashboard | Order
  },
) => {
  switch (action.type) {
    case UserActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        dashboard: initialState.dashboard,
        dashboardEPD: initialState.dashboardEPD,
      }
    case DashboardActionTypes.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboard: {
          ...action.payload,
          loading: false,
        },
      }
    case OrderActionTypes.DISPOSE_ORDER_SUCCESS:
      return {
        ...state,
        dashboard: {
          ...action.payload,
          // Move disposed order from new orders to planned orders
          new_company_orders:
            state.new_company_orders &&
            state.new_company_orders.filter(
              order => order.id !== (action.payload as Order).id,
            ),
          // when updating the planned list we cannot simply add the new object to the said list.
          // This is because the delivery date can be set to a date that is actually more than 14 days from today.
          // Reloading the page will show the right list but we don't want to reload the page when disposing a new order
          // We also don't want to accidentally add objects that are set in the past.
          planned_company_orders: isUpcomingPlannedOrder(
            (action.payload as Order).delivery_date_disposed,
          )
            ? [action.payload, ...state.planned_company_orders]
            : state.planned_company_orders,
        },
      }
    case DashboardActionTypes.GET_DASHBOARD_EPD_SUCCESS:
      return {
        ...state,
        dashboardEPD: {
          ...state.dashboardEPD,
          ...action.payload,
          loaded: true,
        },
      }
    default:
      return state
  }
}
