import keyMirror from 'keymirror'

const GET_ORDER = keyMirror({
  GET_ORDER_REQUEST: null,
  GET_ORDER_SUCCESS: null,
  GET_ORDER_ERROR: null,
})

const GET_ORDERS = keyMirror({
  GET_ORDERS_REQUEST: null,
  GET_ORDERS_SUCCESS: null,
  GET_ORDERS_ERROR: null,
  GET_ORDERS_RESET: null,
})

const GET_ORDERS_FOR_INVOICE_CHECK = keyMirror({
  GET_ORDERS_FOR_INVOICE_CHECK_REQUEST: null,
  GET_ORDERS_FOR_INVOICE_CHECK_SUCCESS: null,
  GET_ORDERS_FOR_INVOICE_CHECK_ERROR: null,
})

const RESET_ORDERS_FOR_INVOICE_CHECK_LIST = keyMirror({
  RESET_ORDERS_FOR_INVOICE_CHECK_LIST: null,
})

const CREATE_ORDER = keyMirror({
  CREATE_ORDER_REQUEST: null,
  CREATE_ORDER_SUCCESS: null,
  CREATE_ORDER_ERROR: null,
})

const UPDATE_ORDER_INTERNAL_COMMENT = keyMirror({
  UPDATE_ORDER_INTERNAL_COMMENT_REQUEST: null,
  UPDATE_ORDER_INTERNAL_COMMENT_SUCCESS: null,
  UPDATE_ORDER_INTERNAL_COMMENT_ERROR: null,
})

const UPDATE_ORDER_ATTACHMENTS = keyMirror({
  UPDATE_ORDER_ATTACHMENTS_REQUEST: null,
  UPDATE_ORDER_ATTACHMENTS_SUCCESS: null,
  UPDATE_ORDER_ATTACHMENTS_ERROR: null,
})

const DISPOSE_ORDER = keyMirror({
  DISPOSE_ORDER_REQUEST: null,
  DISPOSE_ORDER_SUCCESS: null,
  DISPOSE_ORDER_ERROR: null,
})

const POSTPONE_ORDER = keyMirror({
  POSTPONE_ORDER_REQUEST: null,
  POSTPONE_ORDER_SUCCESS: null,
  POSTPONE_ORDER_ERROR: null,
})

const CANCEL_ORDER = keyMirror({
  CANCEL_ORDER_REQUEST: null,
  CANCEL_ORDER_SUCCESS: null,
  CANCEL_ORDER_ERROR: null,
})

const SET_ETA_FOR_ORDER = keyMirror({
  SET_ETA_FOR_ORDER_REQUEST: null,
  SET_ETA_FOR_ORDER_SUCCESS: null,
  SET_ETA_FOR_ORDER_ERROR: null,
})

const CONFIRM_EXECUTION_OF_ORDER = keyMirror({
  CONFIRM_EXECUTION_OF_ORDER_REQUEST: null,
  CONFIRM_EXECUTION_OF_ORDER_SUCCESS: null,
  CONFIRM_EXECUTION_OF_ORDER_ERROR: null,
})

const CREATE_ORDER_EVALUATIONS = keyMirror({
  CREATE_ORDER_EVALUATIONS_REQUEST: null,
  CREATE_ORDER_EVALUATIONS_SUCCESS: null,
  CREATE_ORDER_EVALUATIONS_ERROR: null,
})

const EXPORT_ORDERS = keyMirror({
  EXPORT_ORDERS_REQUEST: null,
  EXPORT_ORDERS_SUCCESS: null,
})

const CREATE_INVOICING_DOCUMENTS = keyMirror({
  CREATE_INVOICING_DOCUMENTS_REQUEST: null,
  CREATE_INVOICING_DOCUMENTS_SUCCESS: null,
  CREATE_INVOICING_DOCUMENTS_ERROR: null,
})

const GET_ORDERS_COUNT = keyMirror({
  GET_ORDERS_COUNT_REQUEST: null,
  GET_ORDERS_COUNT_SUCCESS: null,
  GET_ORDERS_COUNT_ERROR: null,
})

const GET_MAKLER_PREMIUM_UPCOMING_ORDERS = keyMirror({
  GET_MAKLER_PREMIUM_UPCOMING_ORDERS_REQUEST: null,
  GET_MAKLER_PREMIUM_UPCOMING_ORDERS_SUCCESS: null,
  GET_MAKLER_PREMIUM_UPCOMING_ORDERS_ERROR: null,
})

const SET_MAKLER_PREMIUM_UPCOMING_ORDER_TO_CONFIRMED = keyMirror({
  SET_MAKLER_PREMIUM_UPCOMING_ORDER_TO_CONFIRMED_REQUEST: null,
  SET_MAKLER_PREMIUM_UPCOMING_ORDER_TO_CONFIRMED_SUCCESS: null,
  SET_MAKLER_PREMIUM_UPCOMING_ORDER_TO_CONFIRMED_ERROR: null,
})

const GET_ORDER_FOR_DISPOSER_MANAGEMENT = keyMirror({
  GET_ORDER_FOR_DISPOSER_MANAGEMENT_REQUEST: null,
  GET_ORDER_FOR_DISPOSER_MANAGEMENT_SUCCESS: null,
  GET_ORDER_FOR_DISPOSER_MANAGEMENT_ERROR: null,
})

const SET_DISPOSER_MANAGEMENT_ORDER_TO_CONFIRMED = keyMirror({
  SET_DISPOSER_MANAGEMENT_ORDER_TO_CONFIRMED_REQUEST: null,
  SET_DISPOSER_MANAGEMENT_ORDER_TO_CONFIRMED_SUCCESS: null,
  SET_DISPOSER_MANAGEMENT_ORDER_TO_CONFIRMED_ERROR: null,
})

export default {
  ...GET_ORDER,
  ...GET_ORDERS,
  ...GET_ORDERS_FOR_INVOICE_CHECK,
  ...RESET_ORDERS_FOR_INVOICE_CHECK_LIST,
  ...CREATE_ORDER,
  ...UPDATE_ORDER_INTERNAL_COMMENT,
  ...UPDATE_ORDER_ATTACHMENTS,
  ...DISPOSE_ORDER,
  ...POSTPONE_ORDER,
  ...CANCEL_ORDER,
  ...SET_ETA_FOR_ORDER,
  ...CONFIRM_EXECUTION_OF_ORDER,
  ...CREATE_ORDER_EVALUATIONS,
  ...EXPORT_ORDERS,
  ...CREATE_INVOICING_DOCUMENTS,
  ...GET_ORDERS_COUNT,
  ...GET_MAKLER_PREMIUM_UPCOMING_ORDERS,
  ...SET_MAKLER_PREMIUM_UPCOMING_ORDER_TO_CONFIRMED,
  ...GET_ORDER_FOR_DISPOSER_MANAGEMENT,
  ...SET_DISPOSER_MANAGEMENT_ORDER_TO_CONFIRMED,
}
