import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { DOCUMENT_STATUS_TYPE } from 'components/invoice/constants'

import { ResetDatevForm } from './ResetDatevForm'
import { SetDatevFormWrapper } from './SetDatevFormWrapper'

interface SetDatevModalProps {
  isOpen?: boolean
  onClose?: () => void
  document?: Invoice
}

export const DatevModal: FC<SetDatevModalProps> = ({
  isOpen = false,
  onClose = () => undefined,
  document = undefined,
}) => {
  const idSetDocumentDatevModalHeadline = uniqueId()

  const getHeadline = document => {
    if (document?.document_status !== DOCUMENT_STATUS_TYPE.DATEV) {
      return I18n.t('bookkeepingTranslations.datevModal.setDatev.title')
    } else {
      return I18n.t('bookkeepingTranslations.datevModal.resetDatev.title')
    }
  }

  return (
    <Modal
      ariaDescribedBy={idSetDocumentDatevModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={getHeadline(document)}
        titleId={idSetDocumentDatevModalHeadline}
      />
      {document?.document_status !== DOCUMENT_STATUS_TYPE.DATEV ? (
        <SetDatevFormWrapper onClose={onClose} document={document} />
      ) : (
        <ResetDatevForm onClose={onClose} document={document} />
      )}
    </Modal>
  )
}
