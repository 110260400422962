import { Form, Formik, FormikErrors } from 'formik'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { cancelOrder } from 'actions/order'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import { DropDownInput } from 'components/common/DropDownInput'
import { ProgressButton } from 'components/common/ProgressButton'
import { Textarea } from 'components/common/Textarea'
import { createLoadingSelector } from 'selectors/loading'

interface OrderCancelFormProps {
  order: Order
  onCloseForm: () => void
}

type OrderCancelFormValues = {
  reason: string
  reason_text: string
}

export enum ORDER_CANCELLATION_REASON {
  REASON_CUSTOMER_CHANGED_MIND = 1,
  REASON_DUPLICATE_ORDER,
  REASON_DISPOSER_DID_NOT_DISPOSE,
  REASON_OTHER,
}

export const ORDER_CANCELLATION_REASONS = [
  {
    id: ORDER_CANCELLATION_REASON.REASON_CUSTOMER_CHANGED_MIND,
    name: 'constant.orderCancellation.customerChangedMind',
  },
  {
    id: ORDER_CANCELLATION_REASON.REASON_DUPLICATE_ORDER,
    name: 'constant.orderCancellation.duplicateOrder',
  },
  {
    id: ORDER_CANCELLATION_REASON.REASON_DISPOSER_DID_NOT_DISPOSE,
    name: 'constant.orderCancellation.disposerDidNotDispose',
  },
  {
    id: ORDER_CANCELLATION_REASON.REASON_OTHER,
    name: 'constant.orderCancellation.other',
  },
]

export const OrderCancelForm: FC<OrderCancelFormProps> = ({
  order,
  onCloseForm,
}) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(createLoadingSelector(['CANCEL_ORDER']))

  return (
    <Formik
      initialValues={{
        reason: '',
        reason_text: '',
      }}
      validationSchema={() =>
        yup.object().shape({
          reason: yup.string(),
          reason_text: yup.string(),
        })
      }
      validate={(values: OrderCancelFormValues) => {
        const errors: FormikErrors<OrderCancelFormValues> = {}
        if (values.reason === '') {
          errors.reason = I18n.t('message.validation.selectionRequired')
        }
        if (
          values.reason === ORDER_CANCELLATION_REASON.REASON_OTHER.toString() &&
          values.reason_text.trim() === ''
        ) {
          errors.reason_text = I18n.t('message.validation.required')
        }
        return errors
      }}
      onSubmit={values => {
        dispatch(
          cancelOrder({
            orderId: order.id,
            reason: values.reason,
            reason_text: values.reason_text,
          }),
        )
        onCloseForm()
      }}
    >
      {({
        handleSubmit,
        handleChange,
        submitCount,
        touched,
        errors: formikErrors,
        values,
        isValid,
        isSubmitting,
      }) => (
        <Form>
          <div className='cancel-offer-form uk-text-center uk-text-lead uk-modal-body'>
            {/* INFO */}
            <div className='uk-margin-medium'>
              <Translate value='orderCancel.info' />
            </div>
          </div>
          <div className='uk-modal-body'>
            <DropDownInput
              error={
                submitCount > 0 && touched.reason ? formikErrors.reason : ''
              }
              choices={ORDER_CANCELLATION_REASONS.map(item => ({
                optionValue: item.id,
                optionLabel: `${I18n.t(item.name)}`,
              }))}
              label={I18n.t('constant.offerCancellation.label')}
              name='reason'
              onChange={handleChange}
              placeholder={I18n.t('constant.offerCancellation.placeholder')}
              value={values.reason}
            />

            {values.reason ===
              String(ORDER_CANCELLATION_REASON.REASON_OTHER) && (
              <Textarea
                error={
                  submitCount > 0 && touched.reason_text
                    ? formikErrors.reason_text
                    : ''
                }
                label={I18n.t('constant.offerCancellation.otherTextLabel')}
                maxLength={500}
                name='reason_text'
                onChange={handleChange}
                placeholder={I18n.t(
                  'constant.orderCancellation.otherTextPlaceholder',
                )}
                value={values.reason_text}
                showRemainingCharacters
              />
            )}

            {/* BUTTONS */}
            <div className='uk-modal-footer uk-text-right'>
              <span className='uk-margin-right'>
                <Button
                  backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                  onClick={onCloseForm}
                  type={BUTTON_TYPE.BUTTON}
                >
                  {I18n.t('general.no')}
                </Button>
              </span>

              <ProgressButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                dataTestId='cancel-offer-form-submit'
                isDisabled={
                  (submitCount > 0 && !isValid) || (isSubmitting && isLoading)
                }
                isLoading={isSubmitting && isLoading}
                onClick={handleSubmit}
              >
                {I18n.t('general.yes')}
              </ProgressButton>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}
