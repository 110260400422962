import { Form, Formik } from 'formik'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as yup from 'yup'

import { login } from 'actions/user'
import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { Checkbox } from 'components/common/Checkbox'
import Icon from 'components/common/Fontello/index'
import { ApiValidationMessages } from 'components/common/Form/components/ApiValidationMessages'
import InputPassword from 'components/common/InputPassword'
import { InputText } from 'components/common/InputText'
import { ProgressButton } from 'components/common/ProgressButton'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'

export const LoginForm: FC = () => {
  const isLoading = useSelector<any, boolean>(state =>
    createLoadingSelector([
      'LOGIN',
      'GET_FRACTIONS',
      'GET_CONTAINERS',
      'GET_INDEXES',
    ])(state),
  )
  const apiErrors = useSelector<any, ApiError>(state =>
    createErrorSelector([
      'LOGIN',
      'GET_FRACTIONS',
      'GET_CONTAINERS',
      'GET_INDEXES',
    ])(state),
  )
  const dispatch = useDispatch()

  return (
    <Formik
      onSubmit={values => {
        dispatch(login(values.email, values.password, values.stayLogged))
      }}
      initialValues={{
        email: '',
        password: '',
        stayLogged: false,
      }}
      validationSchema={() =>
        yup.object().shape({
          email: yup
            .string()
            .email(I18n.t('message.validation.email.isEmail'))
            .required(I18n.t('message.validation.email.required')),
          password: yup
            .string()
            .required(I18n.t('message.validation.password.required')),
          stayLogged: yup.boolean(),
        })
      }
    >
      {({
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValid,
        submitCount,
        touched,
        values,
      }) => (
        <Form
          className='uk-panel homepage-register'
          data-testid='login-form'
          noValidate // Disable browser validation
        >
          <fieldset className='uk-fieldset'>
            {/* This Api Validation message is needed here to get a customized error message
              (e.g. User does not exist) */}
            <ApiValidationMessages
              data-testid='login-api-error-message'
              error={apiErrors}
            />

            <div className='uk-margin'>
              <InputText
                dataTestId='login-email-input'
                dataTestIdError='form-group-error'
                error={submitCount > 0 && touched.email ? errors.email : ''}
                isRequired={!values.email}
                label={I18n.t('general.placeholder.email')}
                maxLength={255}
                name='email'
                onBlur={handleBlur}
                onChange={handleChange}
                onSubmit={handleSubmit}
                placeholder={I18n.t('general.placeholder.email')}
                value={values.email}
              />
            </div>

            <div className='uk-margin uk-position-relative'>
              <InputPassword
                dataTestId='login-password-input'
                dataTestIdError='form-group-error'
                error={
                  submitCount > 0 && touched.password ? errors.password : ''
                }
                isRequired={!values.password}
                label={I18n.t('general.placeholder.passwordLabel')}
                maxLength={255}
                name='password'
                onBlur={handleBlur}
                onChange={handleChange}
                onSubmit={handleSubmit}
                placeholder={I18n.t('general.placeholder.passwordLogin')}
                value={values.password}
              />
            </div>

            <Checkbox
              dataTestId='login-stay-logged-input'
              isChecked={values.stayLogged}
              label={I18n.t('login.stayLogged')}
              name='stayLogged'
              onChange={handleChange}
            />

            <div className='uk-margin'>
              <Link to='/forget-password' data-testid='forget-password-link'>
                <Translate value='login.forgotPassword' />
              </Link>
            </div>

            <div className='uk-margin'>
              <ProgressButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                dataTestId='user-registration-form-submit'
                isDisabled={
                  (submitCount > 0 && !isValid) || (isSubmitting && isLoading)
                }
                isLoading={isSubmitting && isLoading}
                onClick={handleSubmit}
              >
                <Icon name='enter' />
                <span className='ion-forward' />
                &nbsp;
                <Translate value='general.login' />
              </ProgressButton>
            </div>

            <hr />

            {/* REGISTER (disabled in #3228) */}
            {/*<div className='uk-text-center'>*/}
            {/*  <Translate value='login.noAccount' />*/}
            {/*  &nbsp;*/}
            {/*  <Link to='/registrieren'>*/}
            {/*    <Translate value='login.register' />*/}
            {/*  </Link>*/}
            {/*</div>*/}
          </fieldset>
        </Form>
      )}
    </Formik>
  )
}
