const companyApiTranslations = {
  handleGetCompany: {
    error: 'Stammdaten konnten leider nicht geladen werden.',
  },
  handleGetMaklerPremiumCompanies: {
    error:
      'Leider konnte kein passender Kunde zu diesem Filter gefunden werden.',
  },
  handleGetCollectiveInvoiceCompanies: {
    error:
      'Leider konnte kein passender Kunde zu diesem Filter gefunden werden.',
  },
  handleCreateCollectiveInvoice: {
    error: 'Leider konnte die Sammelrechnung nicht generiert werden',
    success: 'Die Sammelrechnungsgenerierung wurde erfolgreich angestoßen',
  },
  handleCreateTotalCollectiveInvoice: {
    error: 'Leider konnte die Gesamtfaktura nicht gestartet werden',
    success: 'Die Gesamtfaktura wurde erfolgreich angestoßen',
  },
  handleGetMaklerPremiumPartners: {
    error:
      'Leider konnte kein passender Partner zu diesem Filter gefunden werden.',
  },
  handleUpdateCompany: {
    success: 'Stammdaten wurden erfolgreich gespeichert.',
    error: 'Speichern der Stammdaten leider fehlgeschlagen.',
  },
  handleUpdateCompanyStatus: {
    success: 'Status wurde erfolgreich gespeichert.',
    error: 'Speichern der Status leider fehlgeschlagen.',
  },
  handleExportCompanies: {
    success: 'Der CSV Export der Unternehmen wurde erfolgreich gestartet.',
    error: 'Der CSV Export der Unternehmen konnte nicht gestartet werden',
  },
  handleGetCompanyNotes: {
    error: 'Notizen konnten leider nicht geladen werden.',
  },
  handleDeleteCompanyNote: {
    success: 'Notiz erfolgreich gelöscht.',
    error: 'Notiz konnte nicht gelöscht werden.',
  },
  handleAddCompanyNote: {
    success: 'Notiz erfolgreich hinzugefügt.',
    error: 'Notiz konnte nicht hinzugefügt werden.',
  },
  handleAddComCenterContact: {
    success: 'Eintrag wurde erfolgreich erstellt.',
    error: 'Eintrag konnte nicht erstellt werden.',
  },
  handleDeleteComCenterContact: {
    success: 'Eintrag wurde erfolgreich gelöscht.',
    error: 'Eintrag konnte nicht gelöscht werden.',
    lastEntry:
      'Es muss mindestens eine E-Mail Adresse in Stufe 1 in der Operativen Abwicklung existieren',
  },
  handleGetComCenterContact: {
    error: 'Leider konnten keine passenden Kontakte geladen werden.',
  },
  handleGetComCenterLog: {
    error: 'Leider konnten keine passenden Aktivitäten geladen werden.',
  },
  handleUpdateComCenterContact: {
    success: 'Ihre Änderungen wurden erfolgreich übernommen.',
    error: 'Ihre Änderungen konnten nicht übernommen werden.',
  },
  handleGetCompanyHistory: {
    error: 'Die Unternehmenshistorie konnte nicht geladen werden.',
  },
}

export default companyApiTranslations
