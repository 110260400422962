import * as React from 'react'

import { ReactComponent as BellFitIcon } from 'assets/svg/detailspage/bell-fit.svg'
import { ReactComponent as ChecklistIcon } from 'assets/svg/detailspage/checklist.svg'
import { ReactComponent as DocumentIcon } from 'assets/svg/detailspage/document.svg'
import { ReactComponent as GraphIcon } from 'assets/svg/detailspage/graph.svg'
import { ReactComponent as HandshakeIcon } from 'assets/svg/detailspage/handshake.svg'
import { ReactComponent as MoneyGradientIcon } from 'assets/svg/detailspage/money-gradient.svg'
import { ReactComponent as ThumbsUpIcon } from 'assets/svg/detailspage/thumbs-up.svg'
import { ReactComponent as TruckIcon } from 'assets/svg/detailspage/truck.svg'

export enum DashboardTileIcon {
  BellFit = 'bell-fit',
  Checklist = 'checklist',
  Document = 'document',
  ThumbsUp = 'thumbs-up',
  MoneyGradient = 'money-gradient',
  Handshake = 'handshake',
  Truck = 'truck',
  Graph = 'graph',
}

export interface DashboardTileIconComponentProps {
  icon?: DashboardTileIcon
}

export const DashboardTileIconComponent = ({
  icon,
}: DashboardTileIconComponentProps): JSX.Element => {
  const iconClassName = 'dashboard-tile__icon-svg'

  let iconComponent = <></>
  if (icon === DashboardTileIcon.BellFit)
    iconComponent = <BellFitIcon className={iconClassName} />
  if (icon === DashboardTileIcon.Checklist)
    iconComponent = <ChecklistIcon className={iconClassName} />
  if (icon === DashboardTileIcon.Document)
    iconComponent = <DocumentIcon className={iconClassName} />
  if (icon === DashboardTileIcon.ThumbsUp)
    iconComponent = <ThumbsUpIcon className={iconClassName} />
  if (icon === DashboardTileIcon.Truck)
    iconComponent = <TruckIcon className={iconClassName} />
  if (icon === DashboardTileIcon.MoneyGradient)
    iconComponent = <MoneyGradientIcon className={iconClassName} />
  if (icon === DashboardTileIcon.Handshake)
    iconComponent = <HandshakeIcon className={iconClassName} />
  if (icon === DashboardTileIcon.Graph)
    iconComponent = <GraphIcon className={iconClassName} />

  return iconComponent
}
