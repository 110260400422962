/**
 * @description builds the google maps API-URL
 * @param params
 * @return {string}
 */
export const getGoogleMapsURL = params => {
  let url = `${params.baseUrl}`
  url += `?v=${params.v}`
  url += `&libraries=${params.libraries.join(',')}`
  url += `&key=${params.key}`

  return url
}

export default {}
