import { useFormikContext } from 'formik'
import React from 'react'
import { I18n, Translate } from 'react-i18nify'

import { RadioButton } from 'components/common/RadioButton'
import { STAGE_CHOICES } from 'components/maklerpremium/CommunicationCenterPage/constants'
import { setRadioButtonValue } from 'components/maklerpremium/CommunicationCenterPage/helper'
import { NewEntryValues } from 'components/maklerpremium/CommunicationCenterPage/types'

/**
 * @description This component displays the operations part of the main modal form of the communication center page
 */
export const CategoryOperationsForm = () => {
  const { values, setFieldValue, validateForm } =
    useFormikContext<NewEntryValues>()
  const formikContext = { values, setFieldValue, validateForm }

  return (
    <div className='communicationcenterpage-new-entry-all-stages-form__radio-button-group'>
      <Translate
        value='communicationCenterPageTranslations.fields.stage.label'
        className='communicationcenterpage-new-entry-all-stages-form__radio-button-group-label'
      />
      <div className='communicationcenterpage-new-entry-all-stages-form__radio-button-group-buttons'>
        <RadioButton
          isChecked={values.stage === `${STAGE_CHOICES.CHOICE_STAGE1}`}
          label={I18n.t(
            'communicationCenterPageTranslations.fields.stage.options.stage1',
          )}
          name='stage'
          onChange={async (event: React.ChangeEvent<any>) => {
            await setRadioButtonValue({
              name: 'stage',
              event,
              value: STAGE_CHOICES.CHOICE_STAGE1,
              formikContext,
            })
          }}
        />
        <RadioButton
          isChecked={values.stage === `${STAGE_CHOICES.CHOICE_STAGE2}`}
          label={I18n.t(
            'communicationCenterPageTranslations.fields.stage.options.stage2',
          )}
          name='stage'
          onChange={async (event: React.ChangeEvent<any>) => {
            await setRadioButtonValue({
              name: 'stage',
              event,
              value: STAGE_CHOICES.CHOICE_STAGE2,
              formikContext,
            })
          }}
        />
        <RadioButton
          isChecked={values.stage === `${STAGE_CHOICES.CHOICE_STAGE3}`}
          label={I18n.t(
            'communicationCenterPageTranslations.fields.stage.options.stage3',
          )}
          name='stage'
          onChange={async (event: React.ChangeEvent<any>) => {
            await setRadioButtonValue({
              name: 'stage',
              event,
              value: STAGE_CHOICES.CHOICE_STAGE3,
              formikContext,
            })
          }}
        />
      </div>
    </div>
  )
}
