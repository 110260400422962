import { useFormikContext } from 'formik'
import React from 'react'

import { CommunityControl } from 'components/common/CommunityControl/CommunityControl'
import { ZipcodeControl } from 'components/common/ZipcodeControl/ZipcodeControl'
import { isFieldValid } from 'components/maklerpremium/CommunicationCenterPage/helper'
import { NewEntryValues } from 'components/maklerpremium/CommunicationCenterPage/types'

/**
 * @description This component displays the form community and zip form of the main modal form of the communicaton center page
 */
export const CommunityZipcodeForm = () => {
  const {
    values,
    submitCount,
    setFieldValue,
    errors,
    setFieldTouched,
    touched,
    validateForm,
    handleChange,
  } = useFormikContext<NewEntryValues>()

  const formikContext = {
    values,
    errors,
    submitCount,
    validateForm,
    handleChange,
    setFieldValue,
  }

  return (
    <>
      <CommunityControl
        dataTestId='community-select'
        getOptionLabel={option => option.name}
        getOptionValue={option => option.id}
        name='communities'
        error={
          submitCount > 0 && touched.communities && errors.communities
            ? (errors.communities as string)
            : ''
        }
        value={values.communities}
        onBlur={setFieldTouched}
        onChange={setFieldValue}
        withCheckmark
        showCheckmark={() => isFieldValid('communities', formikContext)}
      />
      {/* TODO @devTeam: this is a workaround and should be integrated into a new selected async component.
          For some reason, passing a prop with the error and showing this within, is not working */}
      {submitCount > 0 && touched.communities && !!errors.communities && (
        <div
          className='my-auto-offers-page__form_modal-fields-communities-select-field-error'
          data-testid=''
        >
          {errors.communities}
        </div>
      )}
      <ZipcodeControl
        dataTestId='zipcodes-select'
        getOptionLabel={option => option.code}
        getOptionValue={option => option.id}
        name='zipcodes'
        onBlur={setFieldTouched}
        onChange={setFieldValue}
        error={
          submitCount > 0 && touched.zipcodes && errors.zipcodes
            ? (errors.zipcodes as string)
            : ''
        }
        value={values.zipcodes}
        withCheckmark
        showCheckmark={() => isFieldValid('zipcodes', formikContext)}
      />
      {/* TODO @devTeam: this is a workaround and should be integrated into a new selected async component.
          For some reason, passing a prop with the error and showing this within, is not working */}
      {submitCount > 0 && touched.zipcodes && !!errors.zipcodes && (
        <div
          className='my-auto-offers-page__form_modal-fields-zipcodes-select-field-error'
          data-testid=''
        >
          {errors.zipcodes}
        </div>
      )}
    </>
  )
}
