import './style.scss'

import React, { FC } from 'react'
import Spinner from 'react-spinkit'

import { Button, BUTTON_BACKGROUND_COLOR, ButtonProps } from '../Button'

export {
  BUTTON_BACKGROUND_COLOR,
  BUTTON_DIMENSIONS,
  BUTTON_FONT_COLOR,
  BUTTON_SHAPE,
} from '../Button'

interface ProgressButtonProps extends ButtonProps {
  isLoading?: boolean
}

export const ProgressButton: FC<ProgressButtonProps> = ({
  backgroundColor,
  children,
  dataTestId,
  isLoading = false,
  ...rest
}) => (
  <Button
    backgroundColor={backgroundColor} // Object destructuring removes property --> Pass down explicitly
    dataTestId={dataTestId}
    {...rest}
  >
    {children}
    {isLoading && (
      <Spinner
        className='progress-button__spinner'
        color={
          backgroundColor === BUTTON_BACKGROUND_COLOR.PRIMARY
            ? 'white'
            : undefined
        } // Avoid contrast issues
        fadeIn='none' // Show immediately
        name='circle'
      />
    )}
  </Button>
)
