import './styles.scss'

import uniqueId from 'lodash.uniqueid'
import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'

import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'
import { getTaskLogMessages } from 'actions/company'
import { getTaskLogMessagesSelector } from 'selectors/company'
import { createLoadingSelector } from 'selectors/loading'

interface DetailsModalProps {
  callback: any
  showDetailsModal: boolean
  taskLogId: number | undefined
  companyEmptoExternalNumber: string | undefined
  companyName: string | undefined
}

export const DetailsModal: FC<DetailsModalProps> = ({
  callback,
  showDetailsModal,
  taskLogId,
  companyEmptoExternalNumber,
  companyName,
}) => {
  const dispatch = useDispatch()
  const taskLogMessages = useSelector(getTaskLogMessagesSelector)
  const loading = useSelector(createLoadingSelector(['GET_TASK_LOG_MESSAGES']))
  const [taskLogErrorMessages, setTaskLogErrorMessages] = useState<
    TaskLogMessage[]
  >([])
  const [taskLogWarningMessages, setTaskLogWarningMessages] = useState<
    TaskLogMessage[]
  >([])
  const [taskLogInfoMessages, setTaskLogInfoMessages] = useState<
    TaskLogMessage[]
  >([])
  const [taskLogSuccessMessages, setTaskLogSuccessMessages] = useState<
    TaskLogMessage[]
  >([])

  useEffect(() => {
    // Using Number.MAX_SAFE_INTEGER as page_size to retrieve all available messages for the current task log
    if (taskLogId)
      dispatch(
        getTaskLogMessages(undefined, Number.MAX_SAFE_INTEGER, {}, taskLogId),
      )
  }, [dispatch, taskLogId])

  useEffect(() => {
    // trigger filtering when taskLogMessages updates
    setTaskLogErrorMessages(
      taskLogMessages.filter(
        taskLogMessage => taskLogMessage.level === 'Fehler',
      ),
    )
    setTaskLogWarningMessages(
      taskLogMessages.filter(
        taskLogMessage => taskLogMessage.level === 'Warnung',
      ),
    )
    setTaskLogInfoMessages(
      taskLogMessages.filter(taskLogMessage => taskLogMessage.level === 'Info'),
    )
    setTaskLogSuccessMessages(
      taskLogMessages.filter(
        taskLogMessage => taskLogMessage.level === 'Erfolg',
      ),
    )
  }, [taskLogMessages])

  return (
    <Modal
      ariaDescribedBy={uniqueId()}
      isOpen={showDetailsModal}
      onClose={callback}
    >
      <ModalHeader
        onClose={callback}
        title={I18n.t('taskLogDetailsModalTranslations.modalTitle')}
        titleId={uniqueId()}
      />
      <div className='uk-modal-body task-log-details-modal'>
        {loading ? (
          <Spinner name='circle' />
        ) : (
          <>
            <div className='uk-margin-medium-bottom task-log-details-modal--section--title'>
              {companyEmptoExternalNumber} {companyName}
            </div>
            <span className='task-log-details-modal--section--title'>
              {I18n.t('taskLogDetailsModalTranslations.errorTitle')}:
            </span>
            <ul>
              {taskLogErrorMessages.length
                ? taskLogErrorMessages.map(taskLogMessage => (
                    <li key={uniqueId()}>{taskLogMessage.message}</li>
                  ))
                : I18n.t('taskLogDetailsModalTranslations.noMessages')}
            </ul>
            <span className='task-log-details-modal--section--title'>
              {I18n.t('taskLogDetailsModalTranslations.warningTitle')}:
            </span>
            <ul>
              {taskLogWarningMessages.length
                ? taskLogWarningMessages.map(taskLogMessage => (
                    <li key={uniqueId()}>{taskLogMessage.message}</li>
                  ))
                : I18n.t('taskLogDetailsModalTranslations.noMessages')}
            </ul>
            <span className='task-log-details-modal--section--title'>
              {I18n.t('taskLogDetailsModalTranslations.infoTitle')}:
            </span>
            <ul>
              {taskLogInfoMessages.length
                ? taskLogInfoMessages.map(taskLogMessage => (
                    <li key={uniqueId()}>{taskLogMessage.message}</li>
                  ))
                : I18n.t('taskLogDetailsModalTranslations.noMessages')}
            </ul>
            <span className='task-log-details-modal--section--title'>
              {I18n.t('taskLogDetailsModalTranslations.successTitle')}:
            </span>
            <ul>
              {taskLogSuccessMessages.length
                ? taskLogSuccessMessages.map(taskLogMessage => (
                    <li key={uniqueId()}>{taskLogMessage.message}</li>
                  ))
                : I18n.t('taskLogDetailsModalTranslations.noMessages')}
            </ul>
          </>
        )}
      </div>
    </Modal>
  )
}
