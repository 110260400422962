export const bookkeepingTranslations = {
  headline: 'Buchhaltung',
  addInvoice: 'Beleg',
  pageHeader: {
    buttons: {
      accountingExport: 'Buchungsstapel Export',
      export: 'CSV Export',
    },
  },
  columns: {
    paymentMethod: 'Zahlungsart',
    actions: 'Aktionen',
  },
  setCancelledDocumentDoneModal: {
    titleInvoice: 'Rechnung auf erledigt setzen',
    titleCreditNote: 'Gutschrift auf erledigt setzen',
    fields: {
      togglePaymentFieldsText:
        'Geld wurde nicht überwiesen bzw. zurückerstattet, da der Ursprungsbeleg nicht bezahlt wurde.',
      actualPaymentMethod: 'Zahlungsart',
      paidAt: 'Datum der Überweisung',
      paidAtRequired: 'Datum ist ein Pflichtfeld',
      paidAtPlaceholder: 'Bezahlt am',
      comment: 'Optional: Bemerkung',
      commentPlaceholder: 'Erlaubt sind Buchstaben, Zahlen und Sonderzeichen',
      transactionKey: 'Optional: Transaktionsnummer',
      transactionKeyPlaceholder:
        'Tragen Sie hier die Transaktionsnummer der Rechnung ein',
    },
  },
  setDocumentPaidModal: {
    titleInvoice: 'Rechnung auf bezahlt setzen',
    titleCreditNote: 'Gutschrift auf bezahlt setzen',
    fields: {
      actualPaymentMethod: 'Zahlungsart',
      paidAtInvoice: 'Datum des Geldeingangs',
      paidAtCreditNote: 'Datum der Überweisung',
      paidAtRequired: 'Datum ist ein Pflichtfeld',
      paidAtPlaceholder: 'Bezahlt am',
      comment: 'Optional: Bemerkung',
      commentPlaceholder: 'Erlaubt sind Buchstaben, Zahlen und Sonderzeichen',
      transactionKey: 'Optional: Transaktionsnummer',
      transactionKeyPlaceholder:
        'Tragen Sie hier die Transaktionsnummer der Rechnung ein',
    },
  },
  setDocumentUnpaidModal: {
    titleInvoice: 'Rechnung auf unbezahlt setzen',
    titleCreditNote: 'Gutschrift auf unbezahlt setzen',
    fields: {
      unpaidReason: 'Begründung',
      unpaidReasonRequired: 'Bitte wählen Sie einen Grund',
      comment: 'Sonstiges (Begründung)',
      commentPlaceholder: 'Erlaubt sind Buchstaben, Zahlen und Sonderzeichen',
    },
  },
  setDocumentCancelledModal: {
    titleInvoice: 'Rechnung auf storniert setzen',
    titleCreditNote: 'Gutschrift auf storniert setzen',
    tooltipInvoice: 'Rechnung stornieren',
    tooltipCreditNote: 'Gutschrift stornieren',
    fields: {
      cancelledAt: 'Datum der Stornierung',
      cancelReason: 'Stornierungsgrund',
      cancelledAtRequired: 'Datum ist ein Pflichtfeld',
      cancelledCommentRequired: 'Bitte wählen Sie einen Stornierungsgrund',
      cancelledAtPlaceholder: 'Storniert am',
      comment: 'Sonstiges (Begründung)',
      commentPlaceholder:
        'Bitte geben Sie hier an, aus welchem Grund die Rechnung storniert werden soll.',
    },
  },
  creditreformModal: {
    setCreditreform: {
      title: 'An Creditreform übergeben',
    },
    resetCreditreform: {
      title: 'Status Creditreform zurückziehen',
    },
    resetInformation:
      'Sind Sie sicher, dass Sie den Status Creditreform zurückziehen möchten?',
    fields: {
      creditreformSetAt: 'Datum der Übergabe an Creditreform',
      creditreformSetAtPlaceHolder: 'Übergeben am',
      creditreformComment: 'Bemerkung (optional)',
      creditreformCommentPlaceHolder:
        'Erlaubt sind Buchstaben, Zahlen und Sonderzeichen',
    },
  },
  datevModal: {
    setDatev: {
      title: 'In DATEV ausgebucht',
      iconDescription: 'Beleg ausbuchen',
    },
    resetDatev: {
      title: 'Status Ausgebucht zurückziehen',
      iconDescription: 'Status Ausgebucht in DATEV zurückziehen',
    },
    resetInformation:
      'Sind Sie sicher, dass Sie den Status Ausgebucht zurückziehen möchten?',
    fields: {
      datevSetAt: 'Datum der Ausbuchung in DATEV',
      datevSetAtPlaceHolder: 'Übergeben am',
      datevComment: 'Bemerkung (optional)',
      datevCommentPlaceHolder:
        'Erlaubt sind Buchstaben, Zahlen und Sonderzeichen',
    },
  },
  toggleDunningBlockModal: {
    title: 'Mahnsperre aktivieren',
    tooltip: 'Mahnsperre aktivieren',
    titleDeactivate: 'Mahnsperre deaktivieren',
    tooltipDeactivate: 'Mahnsperre deaktivieren',
    fields: {
      changeDunningBlockAt: 'Datum der Mahnsperre',
      changeDunningBlockReason: 'Begründung',
      changeDunningBlockAtRequired: 'Datum ist ein Pflichtfeld',
      changeDunningBlockCommentRequired: 'Bitte wählen Sie einen Grund',
      changeDunningBlockAtPlaceholder: 'Mahnsperre geändert am',
      comment: 'Sonstiges (Begründung)',
      commentPlaceholder:
        'Bitte geben Sie hier an, aus welchem Grund die Mahnsperre gesetzt wird.',
    },
  },
  exportModal: {
    title: 'Buchhaltung CSV Export',
  },
  exportBatchModal: {
    title: 'Buchungsstapel CSV Export',
    form: {
      explanation:
        'Mit den obigen Feldern kann der Zeitraum des Exports eingegrenzt' +
        ' werden. Soll die Zeit nicht eingegrenzt werden, können die Felder einfach leer gelassen werden.',
      startDateLabel: 'Frühestes Belegdatum',
      endDateLabel: 'Spätestes Belegdatum',
      fillOrOptional: 'Auswählen oder leer lassen',
    },
  },
}
