import 'components/offer/MyAutoOffersPage/style.scss'

import React, { FC, useCallback } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch } from 'react-redux'

import { updateEPDCancelledDocument, updateEPDDocument } from 'actions/invoice'
import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { ProgressButton } from 'components/common/ProgressButton'
import { DOCUMENT_TYPE } from 'components/invoice/constants'

interface ResetCreditreformFormProps {
  onClose?: () => void
  document?: Invoice
}

export const ResetCreditreformForm: FC<ResetCreditreformFormProps> = ({
  onClose = () => undefined,
  document = undefined,
}) => {
  const dispatch = useDispatch()
  const documentIsCreditNote =
    document?.document_type === DOCUMENT_TYPE.CREDIT_NOTE ||
    document?.document_type === DOCUMENT_TYPE.CANCELLATION_CREDIT_NOTE
  const updateRequest =
    document?.document_type === DOCUMENT_TYPE.INVOICE ||
    document?.document_type === DOCUMENT_TYPE.CREDIT_NOTE
      ? updateEPDDocument
      : updateEPDCancelledDocument
  const updateDocument = useCallback(() => {
    return updateRequest({
      ...(documentIsCreditNote
        ? // backend expects different keys depending on the documents type.
          { credit_note_id: document?.id ?? 0 }
        : { invoice_id: document?.id ?? 0 }),
      document_status: document?.status_change_before_status,
    })
  }, [document, documentIsCreditNote, updateRequest])
  const handleSubmit = () => {
    onClose()
    dispatch(updateDocument())
  }
  return (
    <div
      className='uk-modal-body bookkeeping-list__form_modal-fields'
      data-testid='bookkeeping-list__form_modal-fields'
    >
      <div className='uk-modal-footer uk-text-right uk-padding-remove-right'>
        <p className='uk-text-left'>
          {I18n.t('bookkeepingTranslations.creditreformModal.resetInformation')}
        </p>
        <Button
          className='uk-button uk-margin-right'
          backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
          onClick={onClose}
        >
          <Translate value='general.no' />
        </Button>

        <ProgressButton
          className='uk-button'
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          onClick={handleSubmit}
        >
          <Translate value='general.yes' />
        </ProgressButton>
      </div>
    </div>
  )
}
