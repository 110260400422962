export const uploadReviewDocumentTranslations = {
  validation: {
    selectedCompany: 'Bitte wählen Sie einen Unternehmen aus.',
    selectedBusinessSegment: 'Bitte wählen Sie einen Geschäftsbereich aus.',
    selectedUser: 'Bitte wählen Sie einen Benutzer aus.',
    claimType: 'Bitte wählen Sie einen Grund für Ihr Anliegen aus.',
    claimDescription: 'Bitte beschreiben Sie den Grund der Reklamation.',
    attachments: 'Bitte laden Sie eine Datei hoch.',
  },
  uploadWarningMessage: {
    title: 'Hinweis',
    message:
      'Es liegen Fehler in folgenden Zeilen vor: <b>%{lineNumbers}</b>. Die Datei kann nicht ' +
      'gespeichert werden.',
  },
  formFields: {
    maklerPremiumCompany: {
      label: 'Melder der Reklamation',
      placeholder: 'Melder der Reklamation',
    },
    selectedCompany: {
      label: 'Unternehmen',
      placeholder: 'Unternehmen',
    },
    selectedUser: {
      label: 'Benutzer',
      placeholder: 'Benutzer',
    },
    claimType: {
      label: 'Grund für Ihr Anliegen',
      placeholder: 'Bitte auswählen',
    },
    businessSegment: {
      placeholder: 'Bitte wählen Sie einen Bereich aus',
      label: 'Bereich',
    },
    attachments: {
      label: {
        orderClaim: 'Optional: Bilder / Dokumente',
        executionProof: 'Laden Sie hier Ihre Nachweise zur Durchführung hoch',
        certificate: {
          efb: 'Efb Zertifikat',
          other: 'Weitere Zertifikate',
          company: 'Gewerbenachweis',
          priceAgreement: 'Preisvereinbarung',
        },
      },
      subLabel: '(Hier können Sie bis zu %{maxFiles} Dateien hochladen)',
      subLabelSingular: '(Hier können Sie eine Datei hochladen)',
      tooltip: '',
    },
    claimDescription: {
      label: 'Weitere Informationen',
    },
  },
}
