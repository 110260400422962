import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getFractionsForFilter } from 'actions/fraction'
import { AsyncMultiselectCombobox } from 'components/common/AsyncMultiselectCombobox/AsyncMultiselectCombobox'
import { COMPANY_ROLE } from 'components/company/constants'
import { MyAutoOfferListFilters } from 'components/offer/MyAutoOffersPage/MyAutoOffersPage'
import { getFractionForFilterSelector } from 'selectors/fraction'
import { createLoadingSelector } from 'selectors/loading'

import { CommonFilterProps } from '../../types'

interface FractionSearchFilterProps extends Partial<CommonFilterProps> {
  action?: (page: number | null, currentFilters: MyAutoOfferListFilters) => any
  currentFilters: MyAutoOfferListFilters
  label: string
  name?: string
  noResultsText: string
  placeholder: string
  resultType?: COMPANY_ROLE
  setCurrentFilterValues: (currentFilters: any) => void
  isClearable?: boolean
}

export const FractionSearchFilter: FC<FractionSearchFilterProps> = ({
  action,
  currentFilters,
  label,
  name = 'coarse_fraction',
  noResultsText,
  placeholder,
  setCurrentFilterValues,
  isClearable = false,
}) => {
  const dispatch = useDispatch()

  const isLoading = useSelector(
    createLoadingSelector(['GET_FRACTIONS_FOR_FILTER']),
  )
  const fractionList = useSelector(getFractionForFilterSelector)
  const [options, setOptions] = useState<{ label: string; value: string }[]>([])

  const loadOptionsFromApi = (inputValue?: string) => {
    dispatch(
      getFractionsForFilter({
        name: inputValue,
      }),
    )
  }

  const handleSelectionChange = fractionId => {
    const newFilters = { ...currentFilters, page: 1, id: fractionId }
    setCurrentFilterValues(newFilters)
    if (action) {
      dispatch(action(null, newFilters as MyAutoOfferListFilters))
    }
  }

  // Generate options List when fractions are loaded
  useEffect(() => {
    if (!isLoading) {
      const newOptions = fractionList?.map(fraction => ({
        label: fraction.display_name_frontend,
        value: `${fraction.id}`,
      }))
      // prepend filter option for all fractions
      newOptions.unshift({
        label: I18n.t('general.placeholder.all'),
        value: '',
      })

      setOptions(newOptions)
    }
  }, [isLoading, fractionList])

  return (
    <AsyncMultiselectCombobox
      handleBlur={() => undefined}
      handleChange={fraction => handleSelectionChange(fraction.value)}
      isLoading={isLoading}
      label={label}
      loadingMessage={''}
      loadOptions={loadOptionsFromApi}
      multiSelect={false}
      name={name}
      noInputMessage={placeholder}
      noOptionsMessage={noResultsText}
      options={options}
      placeholder={placeholder}
      isClearable={isClearable}
    />
  )
}
