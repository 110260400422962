import * as React from 'react'

import { DashboardTileIconComponent } from '../DashboardTileIcon/DashboardTileIcon'

import { DashboardTileContentProps } from './DashboardTile'

export const DashboardTileContent = ({
  children,
  icon,
  hideIcon = false,
}: DashboardTileContentProps) => {
  return (
    <div className='dashboard-tile__content'>
      {!hideIcon && (
        <div className='dashboard-tile__icon'>
          <DashboardTileIconComponent icon={icon} />
        </div>
      )}
      <div className='dashboard-tile__children'>{children}</div>
    </div>
  )
}
