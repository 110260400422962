import './style.scss'

import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import Media from 'react-media'

import { BREAKPOINT } from 'constants/design'

import Accordion from '../../common/Accordion'
import { BreadcrumbTeaser } from '../../common/Breadcrumb'

import { CallToActionSection } from './components/CallToActionSection'
import { NavigationBar } from './components/NavigationBar'
import { CallToActionType, FormPropsType, PageType } from './constants'

interface DetailsPageProps {
  breadcrumb: {
    breadcrumbTitle: React.ReactNode
    prevLinkTitle: string
    prevLinkTo: string
    teaserText: React.ReactNode
    teaserTitle: React.ReactNode
  }
  callToAction: CallToActionType
  formProps?: FormPropsType
  hideCreateForm?: boolean
  offer?: Offer
  pageTitle: string
  pageType?: PageType
  sections: Map<
    string,
    {
      offset: number
      title: React.ReactNode
      component: React.ReactNode
    }
  >
  user?: User
  ctaRenameBtnToAction?: boolean
  onCloseMobileActions?: () => void
}

export const DetailsPage: FC<DetailsPageProps> = ({
  breadcrumb,
  callToAction,
  formProps,
  hideCreateForm,
  offer,
  pageTitle,
  pageType = PageType.NONE,
  sections,
  user,
  ctaRenameBtnToAction = false,
  onCloseMobileActions = () => undefined,
}) => {
  const errorCount =
    formProps &&
    formProps.submitCount > 0 &&
    !formProps.isSubmitting &&
    !formProps.isValid
      ? Object.keys(formProps.errors).length
      : 0

  return (
    <div
      className='inquiry-details-page'
      data-uk-grid=''
      data-testid='inquiry-details-page'
    >
      {/* Helmet runs into an endless loop with dynamic titles as child-component, therefore we use the prop variant */}
      <Helmet title={pageTitle} />
      <div className='uk-width-1-1 uk-width-2-3@xl'>
        {/* Breadcrumb navigation (page-level) and Teaser-Title/Text */}
        <BreadcrumbTeaser
          breadcrumbTitle={breadcrumb.breadcrumbTitle}
          prevLinkTitle={breadcrumb.prevLinkTitle}
          prevLinkTo={breadcrumb.prevLinkTo}
          teaserText={breadcrumb.teaserText}
          teaserTitle={breadcrumb.teaserTitle}
        />
        {/* Navigation bar (Desktop only) */}
        <Media query={{ minWidth: BREAKPOINT.XLARGE }}>
          <NavigationBar sections={sections} sticky />
        </Media>
        {/* Accordion of sections, always-open on Desktop, individually collapsible on mobile */}
        <Media query={{ maxWidth: BREAKPOINT.XLARGE - 1 }}>
          {matches => (
            <div
              className={`inquiry-details-page__sections${
                errorCount > 0 ? `--errors-${errorCount}` : ''
              }`}
              data-testid={!hideCreateForm && 'create-offer-form-open'}
            >
              <Accordion collapsible={matches} multiple={matches} active={0}>
                {Array.from(sections, value => value[1].component)}
              </Accordion>
            </div>
          )}
        </Media>
      </div>
      <div className='uk-width-1-1 uk-width-1-3@xl'>
        {/* Mobile and Desktop Call-To-Action section */}
        <CallToActionSection
          renameBtnToAction={ctaRenameBtnToAction}
          callToAction={callToAction}
          offer={offer}
          pageType={pageType}
          user={user}
          onCloseMobileActions={onCloseMobileActions}
        />
      </div>
    </div>
  )
}
