import './PriceComparisonTableCheckbox.scss'

import classNames from 'classnames'
import React, { FC, KeyboardEvent } from 'react'

interface PriceComparisonTableCheckboxProps {
  checked?: boolean
  className?: string
  margin?: number
  onClick?: () => void
  price?: number
  recommended?: boolean
}

/**
 * renders the company information field
 * @constructor
 */
export const PriceComparisonTableCheckbox: FC<
  PriceComparisonTableCheckboxProps
> = ({ checked, className, margin, onClick, price, recommended }) =>
  typeof price === 'undefined' ? (
    <div className='price-comparison-table-item' />
  ) : (
    <div
      className={classNames(
        {
          'price-comparison-table-item': true,
          'price-comparison-table-item-checkbox': true,
          'price-comparison-table-item-checkbox--checked': checked,
          'price-comparison-table-item-checkbox--recommended': recommended,
        },
        className,
      )}
      onClick={onClick}
      onKeyUp={({ key }: KeyboardEvent<HTMLDivElement>) => {
        if (key === ' ' && onClick) {
          onClick()
        }
      }}
      role='checkbox'
      aria-checked={checked}
      tabIndex={0}
    >
      {typeof price !== 'undefined' && (
        <span className='price-comparison-table-item-checkbox__text'>
          {`Einkaufspreis (Brutto) ${price.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
          })} €`}
        </span>
      )}
      {typeof margin !== 'undefined' && (
        <span className='price-comparison-table-item-checkbox__text'>
          {`Marge ${margin.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })} %`}
        </span>
      )}
    </div>
  )
