import React from 'react'
import { I18n } from 'react-i18nify'

import {
  ActionCard,
  ActionCardGrid,
  ActionCardIcon,
} from '../../components/ActionCard'
import {
  ClassificationGrid,
  ClassificationTile,
} from '../../components/Classification'
import { DashboardTileIcon } from '../../components/DashboardTileIcon/DashboardTileIcon'
import { getMetricsCaptionSingularOrPlural } from '../../helpers'

type TvpDashboardPropsType = {
  dashboardApiResponse: Dashboard
  mapsComponent: () => JSX.Element
}

export const TvpDashboard = (props: TvpDashboardPropsType) => {
  const {
    orders_today_count: ordersTodayCount,
    orders_today_last_week_count: ordersTodayLastWeekCount,
    orders_this_month_count: ordersThisMonthCount,
    orders_last_month_count: ordersLastMonthCount,
    sales_last_month: salesLastMonth,
    workload,
  } = props.dashboardApiResponse

  const workloadTimestampFormatted = new Date(
    workload?.timestamp ?? 0,
  ).toLocaleTimeString()
  const workloadCaption = I18n.t(
    `dashboard.action.caption.${getMetricsCaptionSingularOrPlural(
      workload?.count ?? 0,
    )}`,
    { timestamp: workloadTimestampFormatted },
  )

  const { mapsComponent } = props
  return (
    <>
      <ClassificationGrid>
        {/* TVP  1. ROW */}
        <ClassificationTile
          buttonText={I18n.t('dashboard.MP.TVP.ordersTodayCount.buttonText')}
          buttonTarget='/offer'
          caption={I18n.t(
            `dashboard.MP.TVP.ordersTodayCount.caption.${getMetricsCaptionSingularOrPlural(
              ordersTodayCount,
            )}`,
          )}
          value={ordersTodayCount}
          offsetValue={ordersTodayLastWeekCount}
          icon={DashboardTileIcon.Truck}
        />
        <ClassificationTile
          buttonText={I18n.t(
            'dashboard.MP.TVP.ordersThisMonthCount.buttonText',
          )}
          buttonTarget='/order'
          caption={I18n.t(
            `dashboard.MP.TVP.ordersThisMonthCount.caption.${getMetricsCaptionSingularOrPlural(
              ordersThisMonthCount,
            )}`,
          )}
          value={ordersThisMonthCount}
          offsetValue={ordersLastMonthCount}
          icon={DashboardTileIcon.Truck}
        />
        <ClassificationTile
          buttonText={I18n.t('dashboard.MP.TVP.salesMonthly.buttonText')}
          buttonTarget='/maklerpremium/bookkeeping'
          caption={I18n.t('dashboard.MP.TVP.salesMonthly.caption')}
          value={Number(salesLastMonth).toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          icon={DashboardTileIcon.MoneyGradient}
        />
        <ClassificationTile
          buttonText={I18n.t('dashboard.action.buttonText')}
          buttonTarget='/maklerpremium/workload'
          caption={workloadCaption}
          className='caption--faded'
          value={workload?.count ?? ''}
          icon={DashboardTileIcon.Checklist}
        />
      </ClassificationGrid>

      {/* TVP 2. ROW */}
      <section className='dashboard__contents'>
        {/* MAPS */}
        {mapsComponent()}

        {/* ACTION CARDS */}
        <div className='dashboard__contents__card-list'>
          <ActionCardGrid>
            <ActionCard
              buttonText={I18n.t('dashboard.MP.TVP.upcomingOrders.buttonText')}
              // buttonTarget='/maklerpremium/upcoming_orders'
              buttonTarget='/'
              value={I18n.t('dashboard.MP.TVP.upcomingOrders.value')}
              caption={I18n.t('dashboard.MP.TVP.upcomingOrders.caption')}
            />
            <ActionCard
              buttonText={I18n.t(
                'dashboard.MP.TVP.missingQuantityReports.buttonText',
              )}
              // buttonTarget='/maklerpremium/offer/pipeline'
              buttonTarget='/'
              value={I18n.t('dashboard.MP.TVP.missingQuantityReports.value')}
              caption={I18n.t(
                'dashboard.MP.TVP.missingQuantityReports.caption',
              )}
            />
            <ActionCard
              buttonText={I18n.t('dashboard.MP.customerManage.buttonText')}
              // buttonTarget='/maklerpremium/customer'
              buttonTarget='/'
              value={I18n.t('dashboard.MP.customerManage.value')}
              caption={I18n.t('dashboard.MP.customerManage.caption')}
              icon={ActionCardIcon.WASTE_PRODUCER}
            />
            <ActionCard
              buttonText={I18n.t(
                'dashboard.MP.TVP.partnerCommissioning.buttonText',
              )}
              // buttonTarget='/maklerpremium/partner'
              buttonTarget='/'
              value={I18n.t('dashboard.MP.TVP.partnerCommissioning.value')}
              caption={I18n.t('dashboard.MP.TVP.partnerCommissioning.caption')}
              icon={ActionCardIcon.WASTE_DISPOSER}
            />
          </ActionCardGrid>
        </div>
      </section>
    </>
  )
}
