const certificateApiTranslations = {
  handleGetMyCertificates: {
    error: 'Nachweise konnten leider nicht geladen werden.',
  },
  handleGetCertificates: {
    error: 'Nachweise konnten leider nicht geladen werden.',
  },
  handleGetMyLatestCertificates: {
    error: 'Nachweise konnten leider nicht geladen werden.',
  },
  handleGetCompaniesPriceAgreements: {
    error: 'Preisvereinbarungen konnten leider nicht geladen werden.',
  },
  handleCreateCertificate: {
    success: 'Nachweis wurde erfolgreich hinzugefügt.',
    successDocumentTypeEfb:
      'Das Dokument wurde zur Pflege erfolgreich in den Nachweisen hinterlegt.',
    successDocumentTypePriceAgreement:
      'Das Dokument wurde zur Pflege erfolgreich im Arbeitsvorrat hinterlegt.',
    successDocument: 'Dokument wurde erfolgreich hochgeladen.',
    error: 'Hinzufügen des Nachweises leider fehlgeschlagen.',
    errorDocument: 'Hochladen des Dokumentes leider fehlgeschlagen.',
  },
  handleUpdateCertificate: {
    success: 'Nachweis wurde erfolgreich gespeichert.',
    error: 'Speichern des Nachweises leider fehlgeschlagen.',
  },
  handleUpdateReviewedCertificate: {
    success: 'Nachweisprüfung wurde erfolgreich gespeichert.',
    error: 'Speichern der Nachweisprüfung leider fehlgeschlagen.',
  },
  handleExportCertificates: {
    error: 'Exportieren der Nachweise leider fehlgeschlagen.',
  },
  handleArchiveCertificate: {
    success: 'Dieses Dokument wurde erfolgreich archiviert.',
    error: 'Dieses Dokument konnte nicht archiviert werden.',
  },
}

export default certificateApiTranslations
