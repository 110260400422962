import './style.scss'

import { Form, Formik, FormikErrors } from 'formik'
import React, { createContext, FC, ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { createCustomInvoice } from 'actions/dsz'
import { getCustomTax } from 'actions/maklerpremium'
import { FormStep } from 'components/common/FormSteps'
import {
  progressStepStatus,
  STEP_STATUS,
} from 'components/common/FormSteps/helper'
import { germanDecimalToInternationalFormat } from 'helper/general'
import { createLoadingSelector } from 'selectors/loading'
import { getCustomTaxSelector } from 'selectors/pricecalc'

import { EPDAddInvoicePosition } from '../../epd/EPDInvoiceFormSteps/EPDAddInvoiceFormSteps'
import { CheckPriceSummary } from '../../epd/EPDInvoiceFormSteps/Step4'

import { DSZInvoiceReceiverForm, DSZInvoiceReceiverSummary } from './Step1'
import { AssignedContractForm, AssignedContractSummary } from './Step2'
import { DSZAddInvoicePositionForm } from './Step3'

interface DSZAddInvoiceContextType {
  stepStatus: STEP_STATUS
  saveStep: () => void
  editStep: () => void
  isInvoice: boolean
  toggleIsInvoice: () => void
}

export const DSZAddInvoiceFormContext = createContext<DSZAddInvoiceContextType>(
  {
    stepStatus: STEP_STATUS.INITIAL,
    saveStep: () => undefined,
    editStep: () => undefined,
    isInvoice: true,
    toggleIsInvoice: () => undefined,
  },
)

export interface DSZAddInvoicePosition
  extends Omit<EPDAddInvoicePosition, 'service'> {
  service?: Record<string, any>
}

export type DSZAddInvoiceFormValues = {
  // Step 1
  // search fields
  search_field: string
  selected_company?: Company
  company_name?: string
  dsz_external_number?: number
  zipcode?: number

  // readonly fields
  invoice_address?: Address
  tax_id?: string

  // Step 2
  assigned_contract?: Contract
  assigned_vat: number
  iban?: string
  licensing_year?: number

  // Step 3
  positions: DSZAddInvoicePosition[]
}

export const DSZAddInvoiceFormSteps: FC = () => {
  const dispatch = useDispatch()
  const [isInvoice, setIsInvoice] = useState<boolean>(true)

  const toggleIsInvoice = () => {
    setIsInvoice(!isInvoice)
  }

  const isLoading = useSelector(createLoadingSelector(['GET_CUSTOM_TAX']))
  const customTaxObject = useSelector(getCustomTaxSelector)

  useEffect(() => {
    if (!isLoading && !customTaxObject) {
      dispatch(getCustomTax())
    }
  })

  let customTax
  if (customTaxObject) {
    customTax = Object.values(customTaxObject).find(
      (tax: any) => tax.is_current_vat,
    )
  }

  const initialValues = {
    search_field: 'company_name',
    assigned_vat: customTax?.tax_in_percent,
    positions: [],
  }

  const validationSchema = yup.object().shape({
    // Step 1: CustomerInfo
    search_field: yup.string(),
    company_name: yup.string(),
    dsz_external_number: yup.number(),
    zipcode: yup.string(),
    // Step 2
    assigned_vat: yup.number().required(),
    iban: yup.string(),
    licensing_year: yup.number().min(2018).required(),
  })

  const [stepsStatus, setStepsStatus] = useState<STEP_STATUS[]>([
    STEP_STATUS.COMPLETED,
    STEP_STATUS.CREATING,
    STEP_STATUS.INITIAL,
    STEP_STATUS.INITIAL,
    STEP_STATUS.INITIAL,
  ])

  const formSteps = {
    // Step 1: InvoiceReceiverInfo
    1: {
      form: <DSZInvoiceReceiverForm />,
      summary: <DSZInvoiceReceiverSummary />,
    },
    // Step 2: Offer and InvoiceType
    2: {
      form: <AssignedContractForm />,
      summary: <AssignedContractSummary />,
    },
    // Step 3: InvoicePositions
    3: {
      form: <DSZAddInvoicePositionForm />,
      summary: <DSZAddInvoicePositionForm />,
    },
    // Step 4: Inputcheck
    4: { form: <CheckPriceSummary />, summary: <CheckPriceSummary /> },
  }

  function renderStep(
    index: string,
    step: { summary: ReactNode; form: ReactNode },
  ) {
    return stepsStatus[Number(index)] > STEP_STATUS.INITIAL &&
      stepsStatus[Number(index)] === STEP_STATUS.COMPLETED
      ? step.summary
      : step.form
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      validate={() => {
        const errors: FormikErrors<DSZAddInvoiceFormValues> = {}
        return errors
      }}
      onSubmit={(values: DSZAddInvoiceFormValues) => {
        const valuesToSend = {
          items: values.positions.map(position => {
            return {
              ...position,
              fraction: position.service?.id,
              quantity: germanDecimalToInternationalFormat(position.amount),
              net_unit_price: germanDecimalToInternationalFormat(
                position.single_net_price,
              ),
            }
          }),
          contract_id: values.assigned_contract
            ? values.assigned_contract.id
            : undefined,
          vat: values.assigned_vat ?? undefined,
          iban: values.iban,
          is_invoice: isInvoice,
          company_id: values.selected_company?.id,
        }
        dispatch(createCustomInvoice(valuesToSend))
      }}
    >
      <Form>
        {Object.entries(formSteps).map(([index, step]) => (
          <FormStep
            key={`step_${index}`}
            isDisabled={stepsStatus[Number(index) - 1] < STEP_STATUS.COMPLETED}
            showDivider
            showProgressLine
            stepNumber={
              stepsStatus[Number(index)] < STEP_STATUS.COMPLETED &&
              Number(index)
            }
            title={`addInvoicePageTranslations.dsz.steps.${index}.title`}
          >
            <DSZAddInvoiceFormContext.Provider
              value={{
                stepStatus: stepsStatus[Number(index)],
                saveStep: () => {
                  progressStepStatus(stepsStatus, setStepsStatus, Number(index))
                },
                editStep: () => {
                  progressStepStatus(stepsStatus, setStepsStatus, Number(index))
                },
                isInvoice,
                toggleIsInvoice,
              }}
            >
              {renderStep(index, step)}
            </DSZAddInvoiceFormContext.Provider>
          </FormStep>
        ))}
      </Form>
    </Formik>
  )
}
