import ActionTypes from '../actiontypes/zipcode'

export const initialState = {
  item: {
    id: null,
    code: null,
    is_active: false,
    showValidationMessage: false,
  },
  ignoreZipcodeRequest: false,
  itemList: [],
  isLoading: false,
  submitted: false,
}

/**
 * @description A reducer for zipcode.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const zipcodeReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_ZIPCODE_REQUEST:
      return {
        ...state,
        ignoreZipcodeRequest: false,
      }
    case ActionTypes.GET_ZIPCODES_SUCCESS:
      return {
        ...state,
        itemList: action.payload,
      }
    case ActionTypes.GET_ZIPCODE_SUCCESS:
      if (state.ignoreZipcodeRequest) {
        return {
          ...state,
          ignoreZipcodeRequest: false,
        }
      }
      return {
        ...state,
        item: { ...action.payload, showValidationMessage: false },
      }
    case ActionTypes.GET_ZIPCODE_ERROR:
      if (state.ignoreZipcodeRequest) {
        return {
          ...state,
          item: { ...action.payload, showValidationMessage: true },
          ignoreZipcodeRequest: false,
        }
      }

      return {
        ...state,
        item: {
          id: null,
          code: null,
          is_active: false,
          showValidationMessage: true,
        },
      }
    case ActionTypes.RESET_ZIPCODE:
      return {
        ...state,
        item: initialState.item,
        ignoreZipcodeRequest: true,
        submitted: initialState.submitted,
      }
    case ActionTypes.SUBMIT_ZIPCODE:
      return {
        ...state,
        submitted: true,
      }
    case ActionTypes.HIDE_ZIPCODE_VALIDATION_MESSAGE:
      return {
        ...state,
        item: initialState.item,
      }
    default:
      return state
  }
}

export default zipcodeReducer
