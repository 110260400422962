import './Divider.styles.scss'

import classNames from 'classnames'
import React, { FC } from 'react'

import { DividerColor, DividerWidth } from './index'

interface DividerProps {
  className?: string
  color?: DividerColor
  width?: DividerWidth
}

export const Divider: FC<DividerProps> = ({
  className,
  color = DividerColor.Gray,
  width = DividerWidth.Small,
}) => (
  <hr
    className={classNames(
      'divider',
      { 'divider--color-black': color === DividerColor.Black },
      { 'divider--color-gray': color === DividerColor.Gray },
      { 'divider--color-grayish-brown': color === DividerColor.GrayishBrown },
      { 'divider--width-small': width === DividerWidth.Small },
      { 'divider--width-extra-small': width === DividerWidth.ExtraSmall },
      className,
    )}
  />
)
