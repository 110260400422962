import ActionTypes from '../actiontypes/offer'

export const CREDIT_MATERIAL_RECYCLE = '0'
export const CREDIT_MATERIAL_DISPOSE = '1'

export const initialState = {
  item: {},
  itemList: [],
  myItemList: [], // The offers that were created by my company
  pagination: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
  hasOrders: false,
  hasArchive: false,
  isFiltered: false,
}

/**
 * @description A reducer for address.
 * @param {Object} state is the current globale state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const offerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CHANGE_INTERVAL_OFFER_SUCCESS:
    case ActionTypes.POSTPONE_INTERVAL_OFFER_SUCCESS:
    case ActionTypes.CREATE_OFFER_SUCCESS:
    case ActionTypes.GET_OFFER_SUCCESS:
      return {
        ...state,
        item: {
          ...action.payload,
        },
      }
    case ActionTypes.GET_OFFERS_SUCCESS:
      return {
        ...state,
        itemList: action.payload,
      }
    case ActionTypes.GET_MY_OFFERS_SUCCESS:
    case ActionTypes.GET_ACCEPTED_OFFERS_SUCCESS:
      return {
        ...state,
        myItemList: action.payload.data,
        pagination: { loaded: true, ...action.payload.pagination },
        hasArchive: action.payload.has_archive,
        hasOrders: action.payload.has_orders,
        isFiltered: action.payload.is_filtered,
      }
    case ActionTypes.CANCEL_OFFER_SUCCESS:
    case ActionTypes.ACCEPT_OFFER_SUCCESS:
      return {
        ...state,
        item: {
          ...action.payload,
        },
      }
    case ActionTypes.GET_OFFER_ERROR:
    case ActionTypes.GET_OFFER_RESET:
      return {
        ...state,
        item: initialState.item,
      }
    case ActionTypes.GET_ACCEPTED_OFFERS_RESET:
      return {
        ...state,
        myItemList: initialState.myItemList,
        pagination: initialState.pagination,
        hasArchive: initialState.hasArchive,
        hasOrders: initialState.hasOrders,
        isFiltered: initialState.isFiltered,
      }
    case ActionTypes.CHANGE_DISPOSER_OFFER_SUCCESS:
    case ActionTypes.CHANGE_AGREEMENT_PERIOD_FOR_STANDING_OFFERS_SUCCESS:
      return {
        ...state,
        item: action.payload,
      }
    default:
      return state
  }
}

export default offerReducer
