import './style.scss'

import React, { Component } from 'react'
import { Translate } from 'react-i18nify'
import NumberFormat from 'react-number-format'
import { Control } from 'react-redux-form'

import Icon from 'components/common/Fontello'

import { ControlDefaultProps } from '../../constants'
import FormField from '../../index'

import connector from './connector'
import TextboxTypes from './constants'
import TextboxControlProps from './schemes'

/**
 * @description Custom textbox component
 * see: http://davidkpiano.github.io/react-redux-form/docs/guides/custom-controls.html
 */
class TextboxControl extends Component {
  static propTypes = TextboxControlProps

  static defaultProps = {
    ...ControlDefaultProps,
    addMapProps: {},
    allowSubmissionByKey: false,
    debounce: 0,
    isPassword: false,
    maxLength: 255,
    noNumbers: false,
    noSpecialChars: false,
    showCharsRemaining: false,
    symbol: null,
    type: TextboxTypes.TEXT,
  }

  state = {
    toggleShowPassword: false,
  }

  /**
   * @description Get the numeric field if type is number.
   * @param {Object} props component props
   * @return {*}
   */
  getNumericField = props => (
    <NumberFormat
      allowNegative={props.allowNegative}
      decimalScale={props.decimalScale}
      decimalSeparator=','
      {...props}
    />
  )

  /**
   * @description Override this method in a child class to define an individual field.
   */
  getField = () => {
    if (!this.props.field) return null

    return (
      <div
        className={`textbox-control${this.props.disabled ? ' disabled' : ''}`}
      >
        {this.props.type === TextboxTypes.NUMBER && (
          <Control
            asyncValidateOn={this.props.asyncValidateOn}
            asyncValidators={this.props.asyncValidators}
            className={this.props.className}
            component={this.getNumericField}
            data-testid={this.props.dataTestId}
            debounce={this.props.debounce}
            disabled={this.props.disabled}
            mapProps={{
              decimalScale: 2,
              ...this.props.addMapProps,
            }}
            maxLength={this.props.maxLength}
            minLength={this.props.minLength}
            model={this.props.field.model}
            onKeyPress={this.avoidKeys}
            placeholder={this.props.placeholder || ''}
            tabIndex={this.props.tabIndex}
          />
        )}

        {this.props.type !== TextboxTypes.NUMBER && (
          <Control
            asyncValidateOn={this.props.asyncValidateOn}
            asyncValidators={this.props.asyncValidators}
            className={this.props.className}
            data-testid={this.props.dataTestId}
            debounce={this.props.debounce}
            disabled={this.props.disabled}
            mapProps={{
              ...this.props.addMapProps,
            }}
            maxLength={this.props.maxLength}
            minLength={this.props.minLength}
            model={this.props.field.model}
            onKeyPress={this.avoidKeys}
            placeholder={this.props.placeholder || ''}
            tabIndex={this.props.tabIndex}
            type={
              this.props.isPassword && !this.state.toggleShowPassword
                ? 'password'
                : this.props.type
            }
            value={this.props.field.value ? this.props.field.value : ''} // to be a controlled component
          />
        )}

        {this.props.isPassword && (
          <div className='caption'>
            <button
              className='uk-icon'
              onClick={this.toggleShowPassword}
              tabIndex='-1' // this will avoid the selection of this button when the user presses TAB to change input
              type='button'
            >
              <Icon name='preview' />
            </button>
          </div>
        )}

        {this.props.symbol && (
          <div className='caption symbol'>{this.props.symbol}</div>
        )}

        {this.props.showCharsRemaining && (
          <div className='caption text'>
            <Translate
              number={this.props.maxLength - this.props.field.value.length}
              value='general.charsRemaining'
            />
          </div>
        )}
      </div>
    )
  }

  /**
   * @description change between showing the password or not
   */
  toggleShowPassword = () => {
    this.setState({ toggleShowPassword: !this.state.toggleShowPassword })
  }

  /**
   * @description avoid certain keys to be pressed when inputing something on the text field
   * @params event
   */
  avoidKeys = event => {
    if (!this.props.allowSubmissionByKey) {
      if (event.key === 'Enter') event.preventDefault()
    }
    if (this.props.noNumbers) {
      const pattern = /^\D+$/
      if (!pattern.test(event.key)) event.preventDefault()
    }
    if (this.props.noSpecialChars) {
      const pattern = /^[\p{Letter}\d_ ]*$/u // also allow spaces
      if (!pattern.test(event.key)) event.preventDefault()
    }
    if (this.props.isPhoneNumber) {
      const pattern = /^\+?[\d \-/]*$/ // spaces,  "+", "-" and "/" are allowed
      if (!pattern.test(event.key)) event.preventDefault()
    }
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    return <FormField {...this.props} fieldComponent={this.getField()} />
  }
}

export default connector(TextboxControl)
