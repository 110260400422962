export const EXIF_ORIENTATION_CONSTANTS = {
  ROTATION_0: 0,
  ROTATION_90: 90,
  ROTATION_180: 180,
  ROTATION_270: 270,
}

export const getOrientationClass = (orientationValue = null) => {
  let orientationClass = ''
  if (orientationValue) {
    switch (orientationValue) {
      default:
      case EXIF_ORIENTATION_CONSTANTS.ROTATION_0:
        orientationClass = 'rotation_0'
        break
      case EXIF_ORIENTATION_CONSTANTS.ROTATION_90:
        orientationClass = 'rotation_90'
        break
      case EXIF_ORIENTATION_CONSTANTS.ROTATION_180:
        orientationClass = 'rotation_180'
        break
      case EXIF_ORIENTATION_CONSTANTS.ROTATION_270:
        orientationClass = 'rotation_270'
        break
    }
  }
  return orientationClass
}

export default {}
