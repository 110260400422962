export const ORDER_CLAIM_PROOF_STATUS = {
  STATUS_PENDING: 1,
  STATUS_ACCEPTED: 2,
  STATUS_REJECTED: 3,
}

export const ORDER_CLAIM_PROOF_STATES = [
  {
    id: ORDER_CLAIM_PROOF_STATUS.STATUS_PENDING,
    name: 'executionProof.status.pending',
  },
  {
    id: ORDER_CLAIM_PROOF_STATUS.STATUS_ACCEPTED,
    name: 'executionProof.status.accepted',
  },
  {
    id: ORDER_CLAIM_PROOF_STATUS.STATUS_REJECTED,
    name: 'executionProof.status.rejected',
  },
]

export default {
  ...ORDER_CLAIM_PROOF_STATUS,
  ...ORDER_CLAIM_PROOF_STATES,
}
