/**
 * @function
 * @description checks user permissions.
 * @param userPermissions
 * @param requiredPermissions
 * @return boolean
 */
export const checkRequiredPermissions = (
  userPermissions: string[] = [],
  requiredPermissions: string[] = [],
) =>
  requiredPermissions.length === 0 ||
  (userPermissions &&
    // check if user has at least one of the given permissions. If so, return true
    userPermissions.some(permission =>
      requiredPermissions.includes(permission.toUpperCase()),
    ))
