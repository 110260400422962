import './style.scss'

import React from 'react'

import { MenuItems } from '../MenuItems'
import { MenuType } from '../MenuItems/constants'

/**
 * @description This component renders and controls a menu component.
 * @function
 * @param {Object} props the component props
 */
export const DesktopMenu = () => (
  <ul className='uk-navbar-nav uk-height-1-1 uk-flex-1'>
    <MenuItems activeClassName='active' menuType={MenuType.Main} />
  </ul>
)
