import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getComCenterLog } from 'actions/company'
import {
  getComCenterContactsSelector,
  getComCenterLogSelector,
} from 'selectors/company'
import {
  createRequestStatusSelector,
  REQUEST_STATUS,
} from 'selectors/requestStatus'

import { mapLog } from '../helper'

export const useComCenterLog = ({ companyId }) => {
  const status = useSelector(
    createRequestStatusSelector(['GET_COM_CENTER_CONTACTS']),
  )
  const log = useSelector(getComCenterLogSelector)
  const dispatch = useDispatch()
  const comCenterContacts = useSelector(getComCenterContactsSelector)

  useEffect(() => {
    if (status === REQUEST_STATUS.SUCCESS) {
      dispatch(getComCenterLog(companyId))
    }
  }, [dispatch, status, comCenterContacts, companyId])

  return log?.map(mapLog)
}
