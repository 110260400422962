import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

export interface HomepageCardProps {
  className?: string
  title?: string | ReactNode
  text?: string | ReactNode
  footer?: string | ReactNode
}

export const HomepageCard: FC<HomepageCardProps> = ({
  title,
  text,
  footer,
  className,
}) => (
  <article
    className={classNames('card', 'uk-card-body', 'uk-text-center', className)}
  >
    {title}

    <div className='uk-text-left'>{text}</div>

    {footer && <div className='uk-card-body__footer'>{footer}</div>}
  </article>
)
