import './style.scss'

import React, { FC } from 'react'

interface FormFieldErrorMessageComponentProps {
  error?: string
  inputStyle?: string
  dataTestId?: string
}

const FormFieldErrorMessageComponent: FC<
  FormFieldErrorMessageComponentProps
> = props => {
  const { error = '', inputStyle = 'input', dataTestId = '' } = props
  return (
    <div className='form-field-error-message'>
      {error !== '' && (
        <div
          className={`form-field-error-message__${inputStyle}__error`}
          data-testid={dataTestId}
        >
          {error}
        </div>
      )}
    </div>
  )
}

export const FormFieldErrorMessage = FormFieldErrorMessageComponent
