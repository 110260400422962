import { bool, func, number, oneOfType, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { I18n } from 'react-i18nify'

import { InputNumber } from 'components/common/InputNumber'
import { NoContentMessage } from 'components/common/NoContentMessage/index'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { EXECUTION_PROOF_STATES } from 'components/executionproof/constants'

import { getCurrentValueObject, resetInputOption } from '../../helpers'

import connector from './connector'

class ExecutionProofFilter extends Component {
  static propTypes = {
    // eslint-disable-next-line react/no-unused-prop-types
    getCurrentFilterValues: func.isRequired,
    currentFilterValues: shape({}).isRequired,

    // eslint-disable-next-line react/require-default-props
    handleBlur: func,
    // eslint-disable-next-line react/require-default-props
    handleChange: func,
    isLoading: bool,
    length: number,
    // eslint-disable-next-line react/require-default-props
    resetForm: func,
    // eslint-disable-next-line react/require-default-props
    values: shape({
      empto_external_number: oneOfType([number, string]),
      id: oneOfType([number, string]),
      status: string,
    }),
  }

  static defaultProps = {
    isLoading: false,
    length: 0,
  }

  state = {
    currentSelectedExecutionState: {},
    filterWasReset: false,
  }

  componentDidUpdate({ values: prevValues }) {
    const { values } = this.props

    if (values !== prevValues) this.handleValueChange(this.props)

    if (values.status !== prevValues.status) {
      this.setState({
        currentSelectedExecutionState: getCurrentValueObject(
          this.getSelectionItems().EXECUTION_PROOF_STATES,
          values.status,
        ),
      })
    }
  }

  getSelectionItems = () => ({
    EXECUTION_PROOF_STATES: [
      ...resetInputOption,
      ...EXECUTION_PROOF_STATES.map(item => ({
        value: `${item.id}`,
        label: `${I18n.t(item.name)}`,
      })),
    ],
  })

  handleValueChange = props => {
    const requestValues = {
      ...props.currentFilterValues,
      empto_external_number: props.values.empto_external_number,
      order_id: props.values.id,
      status: props.values.status,
    }
    if (
      this.state.filterWasReset ||
      JSON.stringify(props.currentFilterValues) !==
        JSON.stringify(requestValues)
    ) {
      props.getCurrentFilterValues(requestValues)
      props.getExecutionProofs(null, requestValues, props.sortedBy)
    }
  }

  render() {
    const { handleBlur, handleChange, isLoading, length, resetForm, values } =
      this.props

    return (
      <>
        <div className='uk-grid uk-child-width-1-1 uk-child-width-1-3@m'>
          <StaticCombobox
            label={I18n.t('filterTranslations.executionProofFilter.status')}
            name='status'
            dataTestId='execution-proof-filter-status-combobox'
            noResultsText={I18n.t('filterTranslations.noInputResults')}
            options={this.getSelectionItems().EXECUTION_PROOF_STATES}
            onSelectionChange={handleChange}
            placeholder={I18n.t('general.placeholder.all')}
            selectedOption={this.state.currentSelectedExecutionState}
          />

          <InputNumber
            label={I18n.t(
              'filterTranslations.executionProofFilter.customerNumber',
            )}
            maxLength={15}
            name='empto_external_number'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.empto_external_number}
          />

          <InputNumber
            label={I18n.t(
              'filterTranslations.executionProofFilter.orderNumber',
            )}
            maxLength={15}
            name='id'
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.id}
          />
        </div>

        <div className='filter__empty-message'>
          {!isLoading && length < 1 && (
            <NoContentMessage
              message={I18n.t('general.emptyFilterResultMessage')}
              onResetFilter={() => {
                resetForm()
                this.setState({ filterWasReset: true })
              }}
            />
          )}
        </div>
      </>
    )
  }
}

export default connector(ExecutionProofFilter)
