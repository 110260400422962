import { useFormikContext } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getCombinedOfferEPDUsersForTVP, getCompanyUsers } from 'actions/user'
import { DismissableInfo } from 'components/common/DismissableInfo'
import { DropdownChoice, DropDownInput } from 'components/common/DropDownInput'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import {
  OrderTypeDetailsGroup,
  RuntimeOfInquiry,
} from 'components/common/GenericInquiryFields'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import { RadioPanel } from 'components/common/RadioPanel'
import { RadioPanelGroup } from 'components/common/RadioPanelGroup'
import { Textarea } from 'components/common/Textarea'
import { ADD_EMAIL_USER_OPTION } from 'components/inquiry/constants'
import { contractNoLongerApplies } from 'components/maklerpremium/helpers'
import { BUSINESS_SEGMENT } from 'constants/app'
import { getMaklerPremiumCompaniesSelector } from 'selectors/company'
import { getFrameworkContractPositionsSelector } from 'selectors/maklerpremium'
import { getActiveUserList, getCurrentUserSelector } from 'selectors/user'

import {
  CUSTOMER_SUBTYPE,
  CUSTOMER_TYPE,
  MaklerPremiumOfferValues,
} from '../CreateMaklerPremiumOfferFormSteps'

import { AddContactPersonModal } from './AddContactPersonModal/AddContactPersonModal'
import { NewBusinessCustomerFields } from './NewBusinessCustomerFields'
import { NewCommonCustomerFields } from './NewCommonCustomerFields'

export const GenerateOfferForm: FC = () => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    setFieldValue,
    submitCount,
    values,
  } = useFormikContext<MaklerPremiumOfferValues>()

  const dispatch = useDispatch()
  const currentUser = useSelector(getCurrentUserSelector)
  const customers = useSelector(getMaklerPremiumCompaniesSelector)
  const users = useSelector(getActiveUserList)

  const [userEmails, setUserEmails] = useState<DropdownChoice[]>([])
  const [addContactPersonModalOpen, setAddContactPersonModalOpen] =
    useState<boolean>(false)

  const isTVP: boolean =
    Number(currentUser.company_object.business_segment) ===
    BUSINESS_SEGMENT.BUSINESS_TVP

  useEffect(() => {
    if (
      values.customer_type === CUSTOMER_TYPE.RETURNING_CUSTOMER &&
      values.existing_customer
    ) {
      if (isTVP) {
        dispatch(getCombinedOfferEPDUsersForTVP(values.existing_customer))
      } else {
        dispatch(getCompanyUsers(values.existing_customer))
      }
    }
  }, [
    customers,
    dispatch,
    isTVP,
    values.customer_type,
    values.existing_customer,
  ])

  useEffect(() => {
    if (
      values.customer_type === CUSTOMER_TYPE.RETURNING_CUSTOMER &&
      users &&
      users.length > 0
    ) {
      const emails = users
        .filter(
          item =>
            !item.email.endsWith('maklerpremium.example.com') &&
            !item.email.endsWith('epd-fake-email.de'),
        )
        .map(user => ({ optionLabel: user.email, optionValue: user.email }))
      emails.unshift({
        optionLabel: I18n.t(ADD_EMAIL_USER_OPTION.label),
        optionValue: ADD_EMAIL_USER_OPTION.value,
      })
      setUserEmails(emails)
    }
  }, [users, values.customer_type])

  const isFieldValid = name =>
    values[name] && values[name].length > 0 && !errors[name]

  let compulsoryOfferValid =
    values.customer_type === CUSTOMER_TYPE.RETURNING_CUSTOMER
      ? isValid
      : isValid &&
        ['street', 'house_number', 'city'].every(val => isFieldValid(val))
  // zipcode is included in Formik Validation and is arbitrarily casted to a number by Formik/yup

  if (values.customer_subtype === CUSTOMER_SUBTYPE.BUSINESS_CUSTOMER) {
    compulsoryOfferValid = compulsoryOfferValid && isFieldValid('company_name')
  }

  const frameworkContractPositions = useSelector(
    getFrameworkContractPositionsSelector,
  )
  const frameworkContractNoLongerApplies = contractNoLongerApplies(
    frameworkContractPositions,
    values,
  )

  return (
    <>
      <div className='maklerpremium-offerform__step'>
        <OrderTypeDetailsGroup
          showRentTimeExtensionWarning
          holidayRestrictionEnabled
        />
        <RuntimeOfInquiry
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.3.fields.runtimeOfInquiry.label',
          )}
          placeholder={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.3.fields.runtimeOfInquiry.placeholder',
          )}
        />
        {values.customer_type === CUSTOMER_TYPE.RETURNING_CUSTOMER && (
          <DropDownInput
            choices={userEmails}
            name='customer_email'
            onBlur={handleBlur}
            onChange={e => {
              if (e.target.value === ADD_EMAIL_USER_OPTION.value) {
                setFieldValue('customer_email', '')
                setAddContactPersonModalOpen(true)
              } else {
                handleChange(e)
              }
            }}
            label={I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.3.fields.customer_email.label',
            )}
            placeholder={I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.3.fields.customer_email.placeholder',
            )}
            showCheckmark={isFieldValid}
            value={values.customer_email}
            withCheckmark
          />
        )}
        {values.customer_type === CUSTOMER_TYPE.NEW_CUSTOMER && (
          <>
            <RadioPanelGroup
              initialSelection={`${values.customer_subtype}`}
              className='radiopanelgroup--with-padding'
              columns={2}
              onChange={(value: string) => {
                setFieldValue('customer_subtype', Number(value))
              }}
            >
              <RadioPanel
                ariaLabel={I18n.t('general.button.select')}
                buttonLabel={I18n.t('general.button.select')}
                showButton
                value={`${CUSTOMER_SUBTYPE.PRIVATE_CUSTOMER}`}
              >
                <Headline
                  color={HEADLINE_COLOR.DARK_GRAY}
                  fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                  fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
                  headlineStyle={HEADLINE_STYLE.H5}
                  noMargin
                  tag={HEADLINE_TAG.H3}
                >
                  <Translate value='createMaklerPremiumOfferPageTranslations.steps.3.radiopanel.privateCustomer' />
                </Headline>
              </RadioPanel>
              <RadioPanel
                ariaLabel={I18n.t('general.button.select')}
                buttonLabel={I18n.t('general.button.select')}
                showButton
                value={`${CUSTOMER_SUBTYPE.BUSINESS_CUSTOMER}`}
              >
                <Headline
                  color={HEADLINE_COLOR.DARK_GRAY}
                  fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                  fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
                  headlineStyle={HEADLINE_STYLE.H5}
                  noMargin
                  tag={HEADLINE_TAG.H3}
                >
                  <Translate value='createMaklerPremiumOfferPageTranslations.steps.3.radiopanel.businessCustomer' />
                </Headline>
              </RadioPanel>
            </RadioPanelGroup>
            {!Number.isNaN(values.customer_subtype) && (
              <div className='maklerpremium-offerform__grid elements-container'>
                {values.customer_subtype ===
                  CUSTOMER_SUBTYPE.BUSINESS_CUSTOMER && (
                  <NewBusinessCustomerFields />
                )}
                <NewCommonCustomerFields />
              </div>
            )}
          </>
        )}
        <Textarea
          name='email_note'
          label={`Optional: ${I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.3.fields.email_note.label',
          )}`}
          maxLength={500}
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.3.fields.email_note.placeholder',
          )}
          rows={25}
          showCheckmark={
            !!(
              values.email_note &&
              values.email_note.trim().length > 0 &&
              !errors.email_note
            )
          }
          showRemainingCharacters
          value={values.email_note}
          withCheckmark
        />
        {submitCount > 0 && errors.non_field_errors && (
          <span className='non_field_errors'>{errors.non_field_errors}</span>
        )}

        {frameworkContractNoLongerApplies && (
          <DismissableInfo
            className='dismissable-info--warning framework-contract-warning'
            onClick={() => null}
            title={I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.3.frameworkContractWarning.title',
            )}
            text={I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.3.frameworkContractWarning.text',
            )}
          />
        )}

        <GoToNextFormStepButton
          buttonText={
            compulsoryOfferValid
              ? I18n.t(
                  'createMaklerPremiumOfferPageTranslations.button.compulsory',
                )
              : I18n.t(
                  'createMaklerPremiumOfferPageTranslations.button.optional',
                )
          }
          onClick={() => {
            setFieldValue('is_compulsory_offer', compulsoryOfferValid)
            handleSubmit()
          }}
          isLoading={isSubmitting}
          isDisabled={!isValid}
        />
      </div>

      <AddContactPersonModal
        isOpen={addContactPersonModalOpen}
        onClose={() => {
          if (isTVP) {
            dispatch(getCombinedOfferEPDUsersForTVP(values.existing_customer))
          } else {
            dispatch(getCompanyUsers(values.existing_customer))
          }
          setAddContactPersonModalOpen(false)
        }}
        company={values.existing_customer}
      />
    </>
  )
}
