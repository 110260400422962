import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import EmptoDownloadsActionTypes from '../actiontypes/emptodownloads'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetEmptoDownloadsList
 * @description Loads all empto downloads from the api.
 */
export const handleGetEmptoDownloadsList = createLogic({
  type: EmptoDownloadsActionTypes.GET_EMPTO_DOWNLOADS_REQUEST,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/emptodownloads/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: EmptoDownloadsActionTypes.GET_EMPTO_DOWNLOADS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.emptoDownloads.handleGetEmptoDownloadsList.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: EmptoDownloadsActionTypes.GET_EMPTO_DOWNLOADS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [handleGetEmptoDownloadsList]
