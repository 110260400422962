import './style.scss'

import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import Spinner from 'react-spinkit'

import { TooltipIcon, TooltipIconProps } from '../../common/TooltipIcon'
import { callbackOrType } from '../Input'
import { InputCheckmark } from '../InputCheckmark'

export const DEFAULT_VALUE = ''

export type DropdownChoice = {
  optionLabel: string
  optionValue: React.ReactText
}

interface DropDownInputProps {
  choices: DropdownChoice[]
  className?: string
  containerClassName?: string
  dataTestId?: string
  dataTestIdError?: string
  error?: callbackOrType<string>
  isDisabled?: boolean
  isLoading?: boolean
  isPlaceholderClickable?: boolean
  isRequired?: boolean
  label?: string | React.ReactNode // can have a tool tip and link
  name: string
  onBlur?: (e: any) => void
  onChange: React.ChangeEventHandler<HTMLSelectElement>
  overwrittenValue?: boolean
  placeholder?: string
  showCheckmark?: callbackOrType<boolean>
  standardInputStyle?: boolean
  subLabel?: string
  tooltip?: TooltipIconProps
  value?: React.ReactText
  withCheckmark?: boolean
}

/**
 * @description This component renders and controls a add drop down input component.
 */
export const DropDownInput: FC<DropDownInputProps> = ({
  choices,
  className,
  containerClassName,
  dataTestId = '',
  dataTestIdError = '',
  error = '',
  isDisabled = false,
  isLoading = false,
  isPlaceholderClickable = false,
  isRequired = false,
  label = '',
  name,
  onBlur = () => undefined,
  onChange,
  overwrittenValue = '',
  placeholder = '',
  showCheckmark = false,
  standardInputStyle = false,
  subLabel = '', // lighter text in front of label, usually containing a small description
  tooltip = null,
  value = '',
  withCheckmark = false,
}) => {
  const id = uniqueId()

  const showCheckmarkValue =
    typeof showCheckmark === 'boolean' ? showCheckmark : showCheckmark(name)
  const errorValue = typeof error === 'string' ? error : error(name)

  // For register page, adjust style to use standard input spacing instead of Dropdown spacing
  // Attn: Makes use of ES2015 computed property names
  const inputStyle = standardInputStyle ? 'input' : 'drop-down-input'
  const inputClasses = [
    `${inputStyle}__required-dot`,
    {
      [`${inputStyle}__required-dot--overwritten-value`]:
        overwrittenValue && error === '',
    },
    {
      [`${inputStyle}__required-dot--overwritten-value-with-error`]:
        error !== '' && overwrittenValue,
    },
    { [`${inputStyle}__required-dot--error`]: error !== '' },
  ]
  const selectClasses = [
    `${inputStyle}__select`,
    { [`${inputStyle}__select--placeholder-shown`]: value === '' },
  ]

  return (
    <div
      className={classNames(
        inputStyle,
        { [`${inputStyle}--with-checkmark`]: withCheckmark },
        containerClassName,
      )}
    >
      <Row middle='xs'>
        <Col xs={12} sm={12} className='label-col label-col-top'>
          {label && (
            <div className={`${inputStyle}__label-container`}>
              {/* eslint-disable-next-line jsx-a11y/label-has-for */}
              <label className={`${inputStyle}__label`} htmlFor={id}>
                {label}
              </label>
              {subLabel && (
                <span className='combobox__label-container__label__sub-label'>
                  {subLabel}
                </span>
              )}
              {tooltip && (
                <div className={`${inputStyle}__label-container__tooltip`}>
                  <TooltipIcon {...tooltip} />
                </div>
              )}
            </div>
          )}
        </Col>
        <Col xs={12} sm={12} style={{ position: 'relative' }}>
          {isRequired && <div className={classNames(...inputClasses)} />}

          <select
            className={classNames(className, 'uk-select', ...selectClasses, {
              'uk-disabled': isLoading,
            })}
            data-testid={dataTestId}
            disabled={isDisabled}
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          >
            {placeholder !== '' && (
              <option
                disabled={!isPlaceholderClickable}
                hidden={!isPlaceholderClickable}
                value={DEFAULT_VALUE}
              >
                {placeholder}
              </option>
            )}

            {choices.map(({ optionLabel, optionValue }) => (
              <option key={String(optionValue)} value={optionValue}>
                {optionLabel}
              </option>
            ))}
          </select>

          {isLoading && (
            <div className={`${inputStyle}__loading`}>
              <Spinner
                fadeIn='none' // Show immediately
                name='circle'
                color='grey'
              />
            </div>
          )}

          {withCheckmark && <InputCheckmark isHidden={!showCheckmarkValue} />}
        </Col>
      </Row>
      {errorValue !== '' && (
        <div className={`${inputStyle}__error`} data-testid={dataTestIdError}>
          {errorValue}
        </div>
      )}
    </div>
  )
}
