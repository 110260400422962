import React, { FC, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getEmptoDownloads } from 'actions/emptodownloads'
import {
  getEmptoDownloadsCertificatesSelector,
  getEmptoDownloadsDocumentsSelector,
} from 'selectors/emptodownloads'

import { DOCUMENT_LIST } from './contants'

export const EmptoDownloads: FC = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getEmptoDownloads())
  }, [dispatch])

  const emptoDownloadsDocumentsList = useSelector(
    getEmptoDownloadsDocumentsSelector,
  )
  const emptoDownloadsCertificatesList = useSelector(
    getEmptoDownloadsCertificatesSelector,
  )

  return (
    <>
      <Helmet>
        <title>{I18n.t('pageTitles.downloads')}</title>
        <meta
          name='Description'
          content={I18n.t('pageDescriptions.downloads')}
        />
      </Helmet>

      <article className='uk-article uk-margin-top'>
        <h3>{I18n.t('emptoDownloadsTranslations.pageTitle')}</h3>

        <h5>{I18n.t('emptoDownloadsTranslations.certificates')}</h5>
        <div className='uk-margin-left'>
          {emptoDownloadsCertificatesList.map(document => (
            <p key={document.file_class}>
              <Link
                to={{ pathname: document.file }}
                target='_blank'
                rel='noopener noreferrer'
              >
                {I18n.t(
                  `emptoDownloadsTranslations.documentTitle.${
                    DOCUMENT_LIST[document.file_class].name
                  }`,
                )}
              </Link>
            </p>
          ))}
        </div>

        <h5>{I18n.t('emptoDownloadsTranslations.documents')}</h5>
        <div className='uk-margin-left'>
          {emptoDownloadsDocumentsList.map(document => (
            <p key={document.file_class}>
              <Link
                to={{ pathname: document.file }}
                target='_blank'
                rel='noopener noreferrer'
              >
                {I18n.t(
                  `emptoDownloadsTranslations.documentTitle.${
                    DOCUMENT_LIST[document.file_class].name
                  }`,
                )}
              </Link>
            </p>
          ))}
        </div>
      </article>
    </>
  )
}
