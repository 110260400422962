import ActionTypes from '../actiontypes/maklerpremium'

const itemInitialState = {
  is_company: true,
  // Geschäftskunde
  company_name: '',
  tax_number: '',
  tax_id: '',
  // Privatkunde
  is_male: true,
  first_name: '',
  last_name: '',
  // Geschäftskunde + Privatkunde
  street: '',
  house_number: '',
  postal_code: '',
  city: '',
  payment_on_account: false,
  permanent_discount: 0,
  // Central Contact Data for EPD
  has_central_contact: false,
  central_contact_email: '',
  central_contact_phone: '',
  // Contact Person
  contact_person_is_male: true,
  contact_person_first_name: '',
  contact_person_last_name: '',
  contact_person_position: '',
  contact_person_phone: '',
  contact_person_email: '',
}

export type OfferPipelinePagination = Pagination & {
  total_offers_count: number
  total_retail_price_forecast: number
}

type MaklerPremiumStateType = {
  item: Partial<Company>
  invoiceCheck?: InvoiceCheck
  invoiceCheckError?: string
  invoiceChecks: { pagination: Pagination; list: InvoiceCheck[] }
  invoiceCheckListForAlreadyCaptchuredOrder: InvoiceCheck[]
  invoiceCheckPositionNetPrice?: number
  invoiceCheckPosition?: {
    loaded: boolean
    item?: InvoiceCheckPosition | InvoiceCheckPositionReview
  }
  invoiceCheckPositions: {
    pagination: Pagination
    list: InvoiceCheckPosition[] | InvoiceCheckPositionReview[]
  }
  frameworkContractPosition: {
    positions: [FrameworkContractPosition] | []
    loaded: boolean
  }
  frameworkContractPositionsCount?: number
  cancelledPaidDocument: undefined | CancelledPaidDocument
  offerPipeline: {
    pagination: OfferPipelinePagination
    list: CustomOffer[]
  }
}

export const initialState: MaklerPremiumStateType = {
  item: itemInitialState,
  invoiceCheck: undefined,
  invoiceCheckError: undefined,
  invoiceChecks: {
    list: [],
    pagination: {
      count: 0,
      next: 0,
      current: 0,
      previous: 0,
      total_results: 0,
      loaded: false,
    },
  },
  invoiceCheckListForAlreadyCaptchuredOrder: [],
  invoiceCheckPositionNetPrice: undefined,
  invoiceCheckPosition: {
    loaded: false,
    item: undefined,
  },
  invoiceCheckPositions: {
    list: [],
    pagination: {
      count: 0,
      next: 0,
      current: 0,
      previous: 0,
      total_results: 0,
      loaded: false,
    },
  },
  frameworkContractPosition: { positions: [], loaded: false },
  frameworkContractPositionsCount: undefined,
  cancelledPaidDocument: undefined,
  offerPipeline: {
    list: [],
    pagination: {
      count: 0,
      next: 0,
      current: 0,
      previous: 0,
      total_results: 0,
      loaded: false,
      total_offers_count: 0,
      total_retail_price_forecast: 0,
    },
  },
}

/**
 * @description A reducer for createCustomer.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
export const maklerpremiumReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
      }
    case ActionTypes.CREATE_PARTNER_SUCCESS:
      return {
        ...state,
      }
    case ActionTypes.CREATE_INVOICE_CHECK_REQUEST:
      return {
        ...state,
        invoiceCheck: undefined,
      }
    case ActionTypes.CREATE_INVOICE_CHECK_SUCCESS:
      return {
        ...state,
        invoiceCheck: action.payload,
      }
    case ActionTypes.CREATE_INVOICE_CHECK_ERROR:
      return {
        ...state,
        invoiceCheckError: action.payload.humanReadableError,
      }
    case ActionTypes.RESET_INVOICE_CHECK_ERROR:
      return {
        ...state,
        invoiceCheckError: undefined,
      }
    case ActionTypes.UPDATE_INVOICE_CHECK_SUCCESS:
      return {
        ...state,
        invoiceCheck: action.payload,
      }
    case ActionTypes.GET_INVOICE_CHECKS_SUCCESS:
    case ActionTypes.GET_INVOICE_CHECK_FOR_ALREADY_COVERED_ORDER_SUCCESS:
      return {
        ...state,
        invoiceChecks: {
          list: action.payload.data,
          pagination: { loaded: true, ...action.payload.pagination },
        },
      }
    case ActionTypes.GET_INVOICE_CHECK_SUCCESS:
      return {
        ...state,
        invoiceCheck: action.payload,
      }
    case ActionTypes.GET_INVOICE_CHECK_POSITION_SUCCESS:
      return {
        ...state,
        invoiceCheckPosition: {
          loaded: true,
          item: action.payload,
        },
      }
    case ActionTypes.GET_INVOICE_CHECK_POSITIONS_SUCCESS:
      return {
        ...state,
        invoiceCheckPositions: {
          list: action.payload.data,
          pagination: { loaded: true, ...action.payload.pagination },
        },
      }
    case ActionTypes.CREATE_INVOICE_CHECK_POSITION_SUCCESS:
    case ActionTypes.UPDATE_INVOICE_CHECK_POSITION_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { invoiceCheck } = state
      if (invoiceCheck?.positions) {
        // eslint-disable-next-line no-case-declarations
        const checkPosition = invoiceCheck?.positions?.findIndex(
          item => item.id === action.payload.id,
        )

        if (typeof checkPosition !== 'undefined' && checkPosition >= 0) {
          // find position in invoiceCheck and replace it with the updated one
          invoiceCheck.positions[checkPosition] = action.payload
        } else {
          // add created invoiceCheck to positions
          invoiceCheck.positions.push(action.payload)
        }
      }

      return {
        ...state,
        invoiceCheck,
      }
    case ActionTypes.DELETE_INVOICE_CHECK_POSITION_SUCCESS:
      // Refresh the state of the current invoiceCheck
      // eslint-disable-next-line no-case-declarations
      const { invoiceCheck: currentInvoiceCheck } = state
      // Find the deleted position in the positions of the current invoiceCheck
      // eslint-disable-next-line no-case-declarations
      const index =
        currentInvoiceCheck?.positions?.findIndex(
          item => item.id === action.payload.id,
        ) ?? -1
      // If the deleted position is found, remove it from the positions of the current invoiceCheck
      if (index >= 0 && currentInvoiceCheck?.positions?.[index]) {
        currentInvoiceCheck.positions.splice(index, 1)
      }

      // Refresh the state of the current invoiceCheckList. Contains invoiceChecks for the selected order
      // eslint-disable-next-line no-case-declarations
      const { invoiceChecks } = state
      // Find the current invoiceCheck in the invoiceCheckList
      // eslint-disable-next-line no-case-declarations
      const invoiceCheckIndex =
        invoiceChecks.list?.findIndex(
          item => item.id === currentInvoiceCheck?.id,
        ) ?? -1
      // If the current invoiceCheck is found update it
      if (
        invoiceCheckIndex >= 0 &&
        invoiceChecks.list[invoiceCheckIndex] &&
        currentInvoiceCheck
      ) {
        invoiceChecks.list[invoiceCheckIndex] = currentInvoiceCheck
      }

      return {
        ...state,
        invoiceCheck: currentInvoiceCheck,
        invoiceChecks: invoiceChecks,
      }
    case ActionTypes.GET_INVOICE_CHECK_POSITION_PRICE_SUCCESS:
      return {
        ...state,
        invoiceCheckPositionNetPrice: action.payload.net_price,
      }
    case ActionTypes.GET_CUSTOM_INVOICE_NET_GROSS_PRICES_VAT_SUCCESS:
      return {
        ...state,
        customInvoiceNetGrossPricesAndVat: action.payload,
      }
    case ActionTypes.VALIDATE_IBAN_RESET:
    case ActionTypes.VALIDATE_IBAN_SUCCESS:
      return {
        ...state,
        customInvoiceValidatedIBANErrorMessage: undefined,
      }
    case ActionTypes.VALIDATE_IBAN_ERROR:
      return {
        ...state,
        customInvoiceValidatedIBANErrorMessage:
          action.payload.response.data.iban[0],
      }
    case ActionTypes.CREATE_CUSTOM_INVOICE_SUCCESS:
      return {
        ...state,
        item: action.payload,
      }
    case ActionTypes.GET_FRAMEWORKCONTRACT_SUCCESS:
      return {
        ...state,
        frameworkContractPosition: { positions: action.payload, loaded: true },
      }
    case ActionTypes.GET_FRAMEWORKCONTRACT_POSITIONS_COUNT_SUCCESS:
      return {
        ...state,
        frameworkContractPositionsCount: action.payload.count,
      }
    case ActionTypes.RESET_FRAMEWORKCONTRACT:
      return {
        ...state,
        frameworkContractPosition: initialState.frameworkContractPosition,
      }
    case ActionTypes.GET_CANCELLEDPAIDDOCUMENT_SUCCESS:
    case ActionTypes.UPDATE_CANCELLEDPAIDDOCUMENT_SUCCESS:
      return {
        ...state,
        cancelledPaidDocument: action.payload,
      }
    case ActionTypes.UPDATE_CANCELLEDPAIDDOCUMENT_RESET:
      return {
        ...state,
        cancelledPaidDocument: null,
      }
    case ActionTypes.GET_OFFER_PIPELINE_SUCCESS:
      return {
        ...state,
        offerPipeline: {
          list: action.payload.data,
          pagination: { loaded: true, ...action.payload.pagination },
        },
      }
    case ActionTypes.GET_OFFER_PIPELINE_ERROR:
      return {
        ...state,
        offerPipeline: {
          ...state.offerPipeline,
          pagination: {
            ...state.offerPipeline.pagination,
            loaded: true,
          },
        },
      }
    case ActionTypes.REOPEN_INVOICE_CHECK_SUCCESS:
      return {
        ...state,
        invoiceCheck: action.payload,
      }

    default:
      return {
        ...state,
      }
  }
}
