import classNames from 'classnames'
import { useFormikContext } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getCompanyUsers } from 'actions/user'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector, getUserList } from 'selectors/user'
import {
  AsyncMultiselectCombobox,
  AsyncMultiselectComboboxProps,
} from 'components/common/AsyncMultiselectCombobox/AsyncMultiselectCombobox'

import { CommonFilterProps, FilterValues } from '../../types'
import { CompanySearchFilter } from '../CompanySearchFilter'
import { FilterReset } from '../FilterReset'
import { cleanUpAndCountFiltersForReset } from '../FilterReset/helpers'

import connector from './connector'

interface UserSearchFilterProps
  extends Partial<CommonFilterProps>,
    Omit<
      Partial<AsyncMultiselectComboboxProps>,
      | 'errors'
      | 'handleBlur'
      | 'handleChange'
      | 'isLoading'
      | 'loadOptions'
      | 'options'
    > {
  currentFilterValues?: Record<string, any>
  label: string
  name?: string
  minCharacters?: number
  noResultsText: string
  placeholder: string
  onSelectionChange?: (filters: any) => void
  setCurrentFilterValues: (currentFilters: any) => void // only used if component is used in filter
  mapToOption?: (user: any) => { label: string; value: string }
  // pass mapToOption if default mapToOption function can not be used
  loadOptions?: (value?: string) => void
  // options?: Record<string, any>[]
  isClearable?: boolean
  defaultUserId?: number | undefined // will be used to determine the default option
}

export const UserFilter: FC<UserSearchFilterProps> = ({
  currentFilterValues,
  label = I18n.t('filterTranslations.userListFilter.userSelection.label'),
  name = 'id',
  minCharacters = 3,
  noResultsText = I18n.t(
    'filterTranslations.userListFilter.userSelection.noResults',
  ),
  placeholder = I18n.t(
    'filterTranslations.userListFilter.userSelection.placeholder',
  ),
  onSelectionChange,
  setCurrentFilterValues,
  isClearable,
}) => {
  const { handleChange, setFieldValue, values } =
    useFormikContext<FilterValues>()

  const dispatch = useDispatch()

  const currentUser = useSelector(getCurrentUserSelector)
  const userList = useSelector(getUserList)
  const isLoading = useSelector(
    createLoadingSelector(['GET_COMPANY_USERS_REQUEST']),
  )

  const [options, setOptions] = useState<{ label: string; value: string }[]>([])

  const loadOptionsFromApi = (inputValue?: string) => {
    if (inputValue && inputValue.length >= minCharacters) {
      dispatch(getCompanyUsers(values.company, true, inputValue))
    }
  }

  useEffect(() => {
    if (values.company) {
      dispatch(getCompanyUsers(values.company, true))
    }
  }, [dispatch, values.company])

  // Generate options List when fractions are loaded
  useEffect(() => {
    if (!isLoading) {
      const newOptions = userList?.map(user => ({
        label: `${user.first_name} ${user.last_name}`,
        value: `${user.id}`,
      }))
      // prepend filter option for all fractions
      newOptions.unshift({
        label: I18n.t('general.placeholder.all'),
        value: '',
      })

      setOptions(newOptions)
    }
  }, [isLoading, userList])

  return (
    <>
      <div
        className={classNames(
          'user-list-filter',
          'uk-grid',
          'uk-child-width-1-1 uk-child-width-1-4@l uk-child-width-1-2@s',
        )}
      >
        {/* !user.company  => business segment empto */}
        {!currentUser.company && (
          <CompanySearchFilter
            currentFilters={currentFilterValues}
            onSelectionChange={company => {
              const newFilters = {
                ...currentFilterValues,
                page: 1,
                company: company.id !== 0 ? company.id : '',
              }
              setFieldValue('company', company.id !== 0 ? company.id : '')
              setCurrentFilterValues(newFilters)
              if (onSelectionChange) {
                onSelectionChange(newFilters)
              } else {
                handleChange(company)
              }
            }}
            noResultsText={I18n.t(
              'filterTranslations.userListFilter.companySelection.noResults',
            )}
            placeholder={I18n.t(
              'filterTranslations.userListFilter.companySelection.placeholder',
            )}
            label={I18n.t(
              'filterTranslations.userListFilter.companySelection.label',
            )}
            name='company'
            isClearable
          />
        )}
        <AsyncMultiselectCombobox
          handleBlur={() => undefined}
          handleChange={user => {
            const newFilters = {
              ...currentFilterValues,
              page: 1,
              id: user.value,
            }
            setCurrentFilterValues(newFilters)

            if (onSelectionChange) {
              onSelectionChange(newFilters)
            } else {
              handleChange(user.value)
            }
          }}
          isLoading={isLoading}
          label={label}
          loadingMessage={''}
          loadOptions={loadOptionsFromApi}
          multiSelect={false}
          name={name}
          noInputMessage={placeholder}
          noOptionsMessage={noResultsText}
          options={options}
          placeholder={placeholder}
          isClearable={isClearable}
        />
        <FilterReset
          onResetFilter={() => {
            setCurrentFilterValues({})

            if (onSelectionChange) {
              onSelectionChange({})
            }
          }}
          showResetFilterButton={
            cleanUpAndCountFiltersForReset(currentFilterValues) > 0
          }
        />
      </div>
    </>
  )
}

export default connector(UserFilter)
