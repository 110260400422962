import './style.scss'

import { Formik } from 'formik'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import * as yup from 'yup'

import { getUserProfileItem } from 'selectors/user'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import { updateAccount } from 'actions/user'

import { UserProfilePageFields } from './UserProfilePageFields'

export interface UserProfilePageValues {
  gender: string
  first_name: string
  last_name: string
  position: string
  business_phone_number: string
  profile_image: Attachment
}

/**
 * @description This component renders and controls a user profile page component.
 */
export const UserProfilePageComponent: FC = () => {
  const dispatch = useDispatch()
  const { userId } = useParams<{ userId: string }>()
  const userItem = useSelector<any, User>(state =>
    getUserProfileItem(state, userId),
  )

  return (
    <Formik
      onSubmit={(values: UserProfilePageValues) => {
        // If no new image was set to be uploaded, send the id of the current image to the updateAccount api call
        const request = {
          ...values,
          profile_image: (values.profile_image as Attachment).id,
        }
        dispatch(updateAccount(request))
      }}
      initialValues={{
        gender: String(userItem.gender),
        first_name: userItem.first_name,
        last_name: userItem.last_name,
        position: userItem.position,
        business_phone_number: userItem.business_phone_number,
        profile_image: userItem.profile_image_object,
      }}
      validationSchema={yup.object().shape({
        gender: yup
          .string()
          .required(
            I18n.t(
              'userProfilePageTranslations.userProfileForm.gender.required',
            ),
          ),
        first_name: yup
          .string()
          .required(
            I18n.t(
              'userProfilePageTranslations.userProfileForm.firstName.required',
            ),
          ),
        last_name: yup
          .string()
          .required(
            I18n.t(
              'userProfilePageTranslations.userProfileForm.lastName.required',
            ),
          ),
        orientation: yup.string(),
        position: yup.string().nullable(),
        business_phone_number: yup.string().nullable(),
        profile_image: yup.object().shape({}).nullable(),
      })}
    >
      <UserProfilePageFields />
    </Formik>
  )
}

export const UserProfilePage = withApiErrorHandling(UserProfilePageComponent)
