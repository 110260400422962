import './style.scss'

import React, { FC, ReactNode } from 'react'

import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_SHADOW,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'

interface CallToActionHeaderProps {
  title: ReactNode
  shadow?: HEADLINE_SHADOW
  uppercase?: boolean
  headlineTag?: HEADLINE_TAG
  icon?: ReactNode
}

export const CallToActionHeader: FC<CallToActionHeaderProps> = ({
  title,
  shadow,
  uppercase,
  headlineTag,
  icon,
}) => (
  <div className='call-to-action-header__title'>
    {icon}
    <Headline
      tag={headlineTag || HEADLINE_TAG.H2}
      noMargin
      fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
      headlineStyle={HEADLINE_STYLE.H3}
      color={HEADLINE_COLOR.GRAYISH_BROWN}
      fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
      shadow={shadow}
      uppercase={uppercase || false}
    >
      {title}
    </Headline>
  </div>
)
