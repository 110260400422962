import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import withErrorBoundary from 'helper/withErrorBoundary'

import { Homepage } from '../../common/Homepage'

const HomepagePrivateCustomerComponent: FC = () => (
  <Homepage
    pageTitle={I18n.t('pageTitles.homepagePrivateCustomer')}
    pageDescription=''
    showPrivateCustomerContent
  />
)

export const HomepagePrivateCustomer = withErrorBoundary(
  HomepagePrivateCustomerComponent,
)
