import React, { FC } from 'react'

import { menuItems, MenuType } from './constants'
import { MenuItem } from './MenuItem'

export const MenuItems: FC<{
  menuType: MenuType
  activeClassName?: string
  showIcon?: boolean
}> = ({ menuType, activeClassName = '', showIcon }) => (
  <>
    {menuItems.map(item => (
      <MenuItem
        key={'linkTo' in item ? item.linkTo : 'logout'}
        {...item}
        menuType={menuType}
        activeClassName={activeClassName}
        showIcon={showIcon}
      />
    ))}
  </>
)
