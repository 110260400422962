import { connect } from 'react-redux'

import { getContainers } from 'actions/container'
import {
  getExecutionProof,
  resetExecutionProofItem,
  updateReviewedExecutionProof,
} from 'actions/executionproof'
import { getFractions } from 'actions/fraction'
import { getIndexes } from 'actions/indexes'
import { getContainersSelector } from 'selectors/container'
import { createErrorSelector } from 'selectors/error'
import {
  getExecutionProofSelector,
  getExecutionProofsSelector,
} from 'selectors/executionproof'
import { getFractionsSelector } from 'selectors/fraction'
import { getIndexesSelector } from 'selectors/indexes'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector } from 'selectors/user'

const getExecutionProofLoadingSelector = createLoadingSelector([
  'GET_EXECUTION_PROOF',
])
const updateReviewedExecutionProofLoadingSelector = createLoadingSelector([
  'UPDATE_REVIEWED_EXECUTION_PROOF',
])
const errorSelector = createErrorSelector([
  'GET_EXECUTION_PROOF',
  'GET_CURRENT_USER',
  'UPDATE_REVIEWED_EXECUTION_PROOF',
])

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  user: getCurrentUserSelector(state),
  execution: getExecutionProofSelector(state),
  executionList: getExecutionProofsSelector(state),
  fractionList: getFractionsSelector(state),
  containerList: getContainersSelector(state),
  isLoading: {
    getExecutionProo: getExecutionProofLoadingSelector(state),
    updateReviewedExecutionProof:
      updateReviewedExecutionProofLoadingSelector(state),
  },
  error: errorSelector(state),
  indexList: getIndexesSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getContainers: () => dispatch(getContainers()),
  getFractions: () => dispatch(getFractions()),
  getIndexes: () => dispatch(getIndexes()),
  getExecutionProof: executionId => dispatch(getExecutionProof(executionId)),
  updateReviewedExecutionProof: (executionObject, history) =>
    dispatch(updateReviewedExecutionProof(executionObject, history)),
  resetExecutionProofItem: () => dispatch(resetExecutionProofItem()),
})

export const connector = connect(mapStateToProps, mapDispatchToProps)
