import './styles.scss'

import classNames from 'classnames'
import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

interface PaginationInfoProps {
  className?: string
  page: number
  pages: number
}

export const PaginationInfo: FC<PaginationInfoProps> = ({
  className,
  page,
  pages,
}) => (
  <div className={classNames('pagination-info', className)}>
    <Translate value='pagination.paginationInfo' page={page} pages={pages} />
  </div>
)
