import classNames from 'classnames'
import React, { FC } from 'react'

export const CookieConsentAlert: FC = ({ children }) => (
  <div
    className={classNames(
      'cookie-consent-alert',
      'uk-alert uk-alert-warning',
      'uk-text-bold uk-padding',
    )}
    data-testid='cookie-consent-alert'
  >
    {children}
  </div>
)
