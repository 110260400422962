import './style.scss'

import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { I18n, Translate } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'

import Icon from 'components/common/Fontello/index'

import FilePreview from '../FileUpload/components/FilePreview'
import { filePreviewAsUrl } from '../helpers'
import { SingleFileUploadProps } from '../schemes'
import FileUploadService from '../services'

/**
 * Renders a Dropzone (https://github.com/react-dropzone/react-dropzone). When files are uploaded, the files are sent
 * to the API and returned with their new ID. Then the onFilesUploaded prop is called. Files are displayed with a
 * preview (even before uopload is done) and can be removed again (will trigger DELETE call to API).
 */
class SingleFileUpload extends Component {
  static propTypes = SingleFileUploadProps

  static defaultProps = {
    className: null,
    initialFile: null,
    accept: 'image/*',
    maxSize: 5 * 1024 * 1024,
    iconName: 'plus',
    displayEditIcon: false,
    showPreviewMetaData: false,
  }

  state = {
    files: this.props.initialFile ? [this.props.initialFile] : [], // These are the file which are uploaded.
  }

  /**
   * Component “lifecycle method” componentDidUpdate
   */
  componentDidUpdate(prevProps) {
    const { initialFile } = this.props
    const { initialFile: prevInitialFile } = prevProps

    if (initialFile !== prevInitialFile) {
      if (initialFile !== null && initialFile.id) {
        this.setState({ files: [initialFile] })
      } else {
        this.setState({ files: [] })
      }
    }
  }

  /**
   * @description Uploads one or several file. This is called by Dropzone when file were dropped.
   * @param accepted {array} file that were accepted based on the mime type
   * @param rejected {array} file that were rejected based on the mime type
   */
  handleUpload = (accepted, rejected) => {
    // We remember the file we had before uploading for later.
    const filesBeforeUpload = this.state.files

    // Display error after validation of mime type and size
    if (rejected.length > 0) {
      // If there were rejected file, we display a toast.
      toastr.error(
        I18n.t('fileUpload.rejectedToast.title'),
        I18n.t('fileUpload.rejectedToast.text', {
          maxSize: Math.round(this.props.maxSize / 1000000),
        }),
      )
    }

    // First we convert all file previews to base64, so we can display the file even before uploading it.
    accepted.forEach(files => {
      filePreviewAsUrl(files)
        .then(result => {
          const fileWithPreview = files
          fileWithPreview.file = result
          this.setState({ files: [fileWithPreview] })
        })
        .catch(() => {
          // We do not need to do anything here, we just do not display a preview.
        })
    })

    // Now we upload the file and inform our listeners.
    return FileUploadService.uploadFiles(accepted)
      .then(uploadedFiles => {
        // The files now have IDs.
        this.setState({ files: [...uploadedFiles] })
        if (this.props.onFilesUploaded)
          this.props.onFilesUploaded(uploadedFiles)
      })
      .catch(err => {
        this.setState({ files: filesBeforeUpload }) // Upload did not work, so remove file from displayed list

        /* This error was previously shown on a toastr and was appearing when the attachments are undefined. This also
        happens when uploading a picture with more than the allowed size. Problem is, there is already an error message
        for this specific case. So, when that happens, both toastrs shown up.
        This error is now being shown in the console */
        console.error(
          I18n.t('fileUpload.uploadErrorToast.title'),
          I18n.t('fileUpload.uploadErrorToast.text', { description: err }),
        )
      })
  }

  render() {
    return (
      <div className={`fileupload single ${this.props.className}`}>
        <Dropzone
          className={`dropzone uk-border-rounded ${
            this.props.displayEditIcon ? 'pointer' : ''
          }`}
          onDrop={this.handleUpload}
          accept={this.props.accept}
          maxSize={this.props.maxSize}
          activeClassName='drag-active'
          rejectClassName='drag-reject'
          disabled={!this.props.displayEditIcon}
        >
          {this.props.displayEditIcon && (
            <div className='edit-icon'>
              <Icon name='pencil' />
            </div>
          )}

          {/* Will be hidden by css when dragging active */}
          {!this.state.files.length > 0 && (
            <div className='cta cta-upload uk-flex-center'>
              <Icon name={this.props.iconName} />
            </div>
          )}

          {this.state.files.map(file => (
            <FilePreview
              key={file.id || null} // when changing picture, this key becomes undefined before loading the image.
              // That will raise a PropType warning, because the key cannot be undefined. We are just adding null to
              // avoid the key being undefined
              file={file}
              allowRemove={false}
              showMetaData={this.props.showPreviewMetaData}
            />
          ))}

          {/* Will be shown by css when dragging active */}
          <div className='cta cta-drop'>
            <Icon name='upload' />
            <p className='uk-text-contrast'>
              <Translate value='fileUpload.dropHint' />
            </p>
          </div>
          {/* Will be shown by css when dragging rejected */}
          <div className='cta cta-reject'>
            <Icon name='warning' />
            <p className='uk-text-contrast'>
              <Translate value='fileUpload.rejectHint' />
            </p>
          </div>
        </Dropzone>
      </div>
    )
  }
}

export default SingleFileUpload
