import { useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { resetValidateIBAN, validateIBAN } from 'actions/maklerpremium'
import { getOrders } from 'actions/order'
import { Checkbox } from 'components/common/Checkbox'
import { DropDownInput } from 'components/common/DropDownInput'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { InputText } from 'components/common/InputText'
import { RadioButton } from 'components/common/RadioButton'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { BILLING_INTERVAL, COMPANY_ROLE } from 'components/company/constants'
import { getFractionName } from 'components/executionproof/helpers'
import { getContainerName } from 'components/inquiry/helpers'
import { DATE_FORMATS } from 'constants/app'
import { getEPDContainersSelector } from 'selectors/container'
import { getEPDFractionsSelector } from 'selectors/fraction'
import { createLoadingSelector } from 'selectors/loading'
import { getCustomInvoiceValidatedIBANErrorMessage } from 'selectors/maklerpremium'
import { getOrdersSelector } from 'selectors/order'
import { getCustomTaxSelector } from 'selectors/pricecalc'

import {
  EPDAddInvoiceFormContext,
  EPDAddInvoiceFormValues,
} from '../EPDAddInvoiceFormSteps'

import { BillingChoice } from './components/BillingChoice'

export const AssignedOrderForm: FC = () => {
  const { errors, setFieldValue, submitCount, values } =
    useFormikContext<EPDAddInvoiceFormValues>()

  const dispatch = useDispatch()
  const fractionList = useSelector(getEPDFractionsSelector)
  const containerList = useSelector(getEPDContainersSelector)
  const customTaxObject = useSelector(getCustomTaxSelector)

  const ibanError = useSelector(getCustomInvoiceValidatedIBANErrorMessage)
  const ibanValidationIsLoading = useSelector(
    createLoadingSelector(['VALIDATE_IBAN']),
  )

  const { stepStatus, saveStep, isInvoice, toggleIsInvoice } = useContext(
    EPDAddInvoiceFormContext,
  )
  const [isAssignableToOrder, setIsAssignableToOrder] = useState<boolean>(false)
  const customerIsBilledPerMonth =
    values.selected_company?.billing_interval === BILLING_INTERVAL.MONTH

  // Get assignable order
  // Request orders for collection address from API
  useEffect(() => {
    let filters
    if (values.selected_company?.role === COMPANY_ROLE.WASTE_PRODUCER) {
      filters = {
        address: `${
          values.different_service_address
            ? values.different_service_address.id
            : values.selected_company?.main_address_object.id
        }`,
        customer_company: `${values.selected_company?.id}`,
      }
    } else if (values.selected_company?.role === COMPANY_ROLE.WASTE_COMPANY) {
      filters = {
        offer_company: `${values.selected_company?.id}`,
      }
    } else {
      filters = {}
    }

    dispatch(getOrders(undefined, filters, 1000, ['is_collectively_invoiced']))
  }, [dispatch, values.different_service_address, values.selected_company])

  // Get results of API-Request
  const orders = useSelector(getOrdersSelector)
  // Parse Order-objects into Option array for
  const orderOptions = useMemo(
    () =>
      orders.map(order => {
        const fraction = getFractionName(order.fraction, fractionList)
        const container = getContainerName(order.container, containerList)
        return {
          label: `${order.id}, ${fraction}, ${container}, ${moment(
            order.delivery_date,
            DATE_FORMATS,
          ).format('L')}`,
          value: `${order.id}`,
        }
      }),
    [containerList, fractionList, orders],
  )

  useMemo(() => {
    if (!!values.assigned_order && !isAssignableToOrder) {
      setIsAssignableToOrder(!isAssignableToOrder)
    }
  }, [isAssignableToOrder, values.assigned_order])

  // Find the selected order and the corresponding option object
  const selectedOrderOption = useMemo(() => {
    if (values.assigned_order) {
      const fraction = getFractionName(
        values.assigned_order.fraction,
        fractionList,
      )
      const container = getContainerName(
        values.assigned_order.container,
        containerList,
      )
      return {
        // eslint-disable-next-line max-len
        label: `${
          values.assigned_order.id
        }, ${fraction}, ${container}, ${moment(
          values.assigned_order.delivery_date,
          DATE_FORMATS,
        ).format('L')}`,
        value: `${values.assigned_order.id}`,
      }
    }
    return orderOptions.find(
      orderOption => orderOption.value === `${values.assigned_order}`,
    )
  }, [containerList, fractionList, orderOptions, values.assigned_order])

  const isFieldValid = name =>
    values[name] ? `${values[name]}`.length > 0 && !errors[name] : false
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0
      ? (errors[name] as string)
      : undefined

  const getIbanError = () => {
    if (!isInvoice) {
      // If the validate function of Formik throws an error, return it
      if (errors.iban) return errors.iban as string

      // If backend validated the IBAN and returned an error, display the error
      if (ibanError) return ibanError as string
    }
    return ''
  }

  return (
    <>
      {/* assignable to order*/}

      {/* eslint-disable jsx-a11y/label-has-associated-control */}
      {/* eslint-disable jsx-a11y/label-has-for */}
      <label>
        {I18n.t(
          'addInvoicePageTranslations.epd.steps.2.fields.assignable_to_order.label',
        )}
      </label>
      <div className='uk-flex-left margin-regular-top' data-uk-grid='uk-grid'>
        <RadioButton
          isChecked={!isAssignableToOrder}
          label={I18n.t('general.no')}
          name='assignable_to_order_no'
          onChange={() => {
            setIsAssignableToOrder(!isAssignableToOrder)
            setFieldValue('assigned_order', undefined, false)
          }}
        />
        <RadioButton
          isChecked={isAssignableToOrder}
          label={I18n.t('general.yes')}
          name='assignable_to_order_yes'
          onChange={() => {
            setIsAssignableToOrder(!isAssignableToOrder)
          }}
        />
      </div>

      {/* assigned order */}
      {isAssignableToOrder && (
        <StaticCombobox
          error={getFieldError}
          label={I18n.t(
            'addInvoicePageTranslations.epd.steps.2.fields.assigned_order.label',
          )}
          name='assigned_order'
          noResultsText={I18n.t(
            'addInvoicePageTranslations.epd.steps.2.fields.assigned_order.noResultsText',
          )}
          options={orderOptions}
          onSelectionChange={e => {
            setFieldValue(
              'assigned_order',
              orders.find(order => `${order.id}` === e.target.value),
              false,
            )
            setFieldValue('is_not_allowed_on_collective_invoice', false)
            setFieldValue(
              'is_not_allowed_on_collective_invoice_comment',
              undefined,
            )
          }}
          selectedOption={selectedOrderOption}
          showCheckmark={isFieldValid}
          withCheckmark
        />
      )}

      {customerIsBilledPerMonth && values.assigned_order && <BillingChoice />}

      {/* invoice or credit note*/}
      {/* eslint-disable jsx-a11y/label-has-associated-control */}
      {/* eslint-disable jsx-a11y/label-has-for */}
      <div className='uk-margin-top'>
        <label>
          {I18n.t(
            'addInvoicePageTranslations.epd.steps.2.fields.invoice_or_credit_note.label',
          )}
        </label>
      </div>
      <div className='uk-flex-left margin-regular-top' data-uk-grid='uk-grid'>
        <RadioButton
          isChecked={isInvoice}
          label={I18n.t(
            'addInvoicePageTranslations.epd.steps.2.fields.invoice_or_credit_note.invoice',
          )}
          name='invoice_or_credit_note_invoice'
          onChange={() => {
            dispatch(resetValidateIBAN())
            setFieldValue('iban', '', false)
            toggleIsInvoice()
          }}
        />
        <RadioButton
          isChecked={!isInvoice}
          label={I18n.t(
            'addInvoicePageTranslations.epd.steps.2.fields.invoice_or_credit_note.credit_note',
          )}
          name='invoice_or_credit_note_credit_note'
          onChange={() => {
            toggleIsInvoice()
          }}
        />
      </div>

      {!isInvoice && (
        <InputText
          label={I18n.t(
            'addInvoicePageTranslations.epd.steps.2.fields.iban.label',
          )}
          maxLength={40}
          name='iban'
          onChange={e => {
            setFieldValue('iban', e.target.value)
          }}
          onBlur={() => {
            if (values.iban) {
              dispatch(validateIBAN({ iban: values.iban }))
            } else {
              dispatch(resetValidateIBAN())
            }
          }}
          placeholder={I18n.t(
            'addInvoicePageTranslations.epd.steps.2.fields.iban.placeholder',
          )}
          value={values.iban}
          error={getIbanError}
          withCheckmark
          showCheckmark={!getIbanError()}
        />
      )}

      {customTaxObject && values.assigned_vat && (
        <DropDownInput
          choices={
            customTaxObject.length !== 0
              ? Object.values(customTaxObject).map((choice: any) => ({
                  optionValue: choice.tax_in_percent,
                  optionLabel: `${parseFloat(
                    choice.tax_in_percent,
                  ).toLocaleString('de-DE', { minimumFractionDigits: 0 })} %`,
                }))
              : [
                  {
                    optionValue: values.assigned_vat,
                    optionLabel: `${values.assigned_vat.toLocaleString(
                      'de-DE',
                      { minimumFractionDigits: 0 },
                    )} %`,
                  },
                ]
          }
          name='assigned_vat'
          onChange={e => {
            setFieldValue('assigned_vat', e.target.value)
            setFieldValue('rcv', false)
          }}
          label={I18n.t(
            'addInvoicePageTranslations.epd.steps.2.fields.assigned_vat.label',
          )}
          value={values.assigned_vat}
          withCheckmark
        />
      )}

      {values.assigned_vat.toLocaleString('de') === '0.00' && (
        <>
          <br />
          <Checkbox
            label={I18n.t(
              'addInvoicePageTranslations.epd.steps.2.fields.rcv.label',
            )}
            name='rcv'
            onChange={() => {
              setFieldValue('rcv', !values.rcv)
            }}
            isChecked={values.rcv}
          />
        </>
      )}

      <div className='uk-margin-top'>
        <GoToNextFormStepButton
          buttonText={
            stepStatus === STEP_STATUS.EDITING
              ? I18n.t('general.button.save')
              : I18n.t('createInquiryTranslations.form.button.nextStep')
          }
          onClick={() => {
            if (!isInvoice && values.iban) {
              dispatch(validateIBAN({ iban: values.iban }))
            } else {
              setFieldValue('iban', undefined, false)
            }
            saveStep()
          }}
          isLoading={ibanValidationIsLoading}
          isDisabled={
            !!values?.assigned_order !== isAssignableToOrder ||
            !!getIbanError() ||
            (values.is_not_allowed_on_collective_invoice === true &&
              (values.is_not_allowed_on_collective_invoice_comment?.trim() ===
                '' ||
                values.is_not_allowed_on_collective_invoice_comment?.length ===
                  0))
          }
        />
      </div>
    </>
  )
}
