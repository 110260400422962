import './style.scss'

import classNames from 'classnames'
import React, { Component } from 'react'
import { Control } from 'react-redux-form'
import Spinner from 'react-spinkit'

import { ControlDefaultProps } from '../../constants'
import FormField from '../../index'

import connector from './connector'
import SelectControlProps from './schemes'

/**
 * @description Custom textbox component
 * see: http://davidkpiano.github.io/react-redux-form/docs/guides/custom-controls.html
 */
class SelectControl extends Component {
  static propTypes = SelectControlProps

  static defaultProps = {
    ...ControlDefaultProps,
    options: [],
    addOptionLabel: 'addOption',
    enablePlaceholder: false,
    isBoolean: false,
    parser: null,
    onAdd: null,
    onChange: () => undefined,
    isLoading: false,
    dataTestId: '',
  }

  /**
   * @description Override this method in a child class to define an individual field.
   * @return {*}
   */
  getField() {
    if (!this.props.field) return null

    return (
      <div
        className={classNames(
          'select-control',
          { 'uk-disabled': this.props.disabled },
          // the following properties are part of the ButtonControl component, but since the necessary behaviour is
          // needed, instead of repeating the code to the select control css, it is called here
          { 'button-control': this.props.isLoading },
          { 'with-caption': this.props.isLoading },
        )}
      >
        <Control.select // eslint-disable-line react/jsx-pascal-case
          data-testid={this.props.dataTestId}
          ref={ref => {
            this.select = ref
          }}
          className={classNames(
            this.props.className,
            // the next one is necessary so the loading icon does not overlap the arrow. While loading, the down arrow
            // of the dropdown select box must be hidden or removed
            { 'remove-arrow': this.props.isLoading },
            {
              placeholder:
                this.props.field.value === undefined ||
                this.props.field.value === null ||
                this.props.field.value === '' ||
                this.props.field.value === 'placeholder',
            },
          )}
          model={this.props.field.model}
          value={`${this.props.field.value}`}
          tabIndex={this.props.tabIndex}
          parser={this.props.parser ? this.props.parser : value => value}
          onChange={this.handleChange}
        >
          {this.props.placeholder && (
            <option value='' hidden={!this.props.enablePlaceholder}>
              {this.props.placeholder}
            </option>
          )}

          {this.props.onAdd && (
            <option
              ref={ref => {
                this.addOption = ref
              }}
            >
              {this.props.addOptionLabel}
            </option>
          )}

          {this.props.options.map(option => (
            <option value={option.value || option.id} key={option.id}>
              {option.label}
            </option>
          ))}
        </Control.select>

        {this.props.isLoading && (
          <div className='caption symbol'>
            <Spinner
              fadeIn='none' // Show immediately
              name='circle'
              color='grey'
            />
          </div>
        )}
      </div>
    )
  }

  /**
   * @description handles the select change event
   * @param event
   */
  handleChange = event => {
    if (this.addOption && event.target.value === this.addOption.value) {
      this.props.onAdd(event)
    } else {
      this.props.onChange(event)
    }
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    return <FormField {...this.props} fieldComponent={this.getField()} />
  }
}

export default connector(SelectControl)
