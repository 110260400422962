export const WastemanagerBenefitSectionTranslation = {
  wastemanagerBenefitSection: {
    item1: {
      headline: 'Kostenlos',
      subline:
        'Die Nutzung des empto Abfallmanagers ist für Sie komplett kostenfrei',
    },
    item2: {
      headline: 'Einfach',
      subline:
        'Der Abfallmanager erlaubt eine einfache Integration der bestehenden Entsorger',
    },
    item3: {
      headline: 'Digital',
      subline:
        'Ab sofort alle Entsorgungsvorgänge digital abwickeln - mit nur einem Klick',
    },
  },
}
