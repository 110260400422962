import 'components/offer/MyAutoOffersPage/style.scss'

import { useFormikContext } from 'formik'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { DropDownInput } from 'components/common/DropDownInput'
import { ProgressButton } from 'components/common/ProgressButton'
import { Textarea } from 'components/common/Textarea'
import {
  DOCUMENT_UNPAID_REASON,
  DOCUMENT_UNPAID_REASONS,
} from 'components/invoice/constants'

import { DocumentUnpaidFormValues } from './SetDocumentUnpaidModal'

interface SetDocumentUnpaidFormProps {
  onClose?: () => void
}

export const SetDocumentUnpaidForm: FC<SetDocumentUnpaidFormProps> = ({
  onClose = () => undefined,
}) => {
  const {
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    submitCount,
    touched,
    values,
  } = useFormikContext<DocumentUnpaidFormValues>()

  return (
    <>
      <div
        className='uk-modal-body bookkeeping-list__form_modal-fields'
        data-testid='bookkeeping-list__form_modal-fields'
      >
        <DropDownInput
          error={
            submitCount > 0 && touched.reopened_reason
              ? errors.reopened_reason
              : ''
          }
          choices={DOCUMENT_UNPAID_REASONS.map(item => ({
            optionValue: item.id,
            optionLabel: `${I18n.t(item.name)}`,
          }))}
          label={I18n.t(
            'bookkeepingTranslations.setDocumentUnpaidModal.fields.unpaidReason',
          )}
          name='reopened_reason'
          onChange={handleChange}
          placeholder={I18n.t(
            'bookkeepingTranslations.setDocumentUnpaidModal.fields.unpaidReasonRequired',
          )}
          value={values.reopened_reason}
        />

        {values.reopened_reason === String(DOCUMENT_UNPAID_REASON.OTHER) && (
          <Textarea
            label={I18n.t(
              'bookkeepingTranslations.setDocumentUnpaidModal.fields.comment',
            )}
            maxLength={500}
            name='reopened_reason_text'
            onChange={handleChange}
            placeholder={I18n.t(
              'bookkeepingTranslations.setDocumentUnpaidModal.fields.commentPlaceholder',
            )}
            showRemainingCharacters
            value={values.reopened_reason_text}
          />
        )}
      </div>

      <div className='uk-modal-footer uk-text-right uk-padding-remove-right'>
        <Button
          className='uk-button uk-margin-medium-right'
          backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
          onClick={onClose}
        >
          <Translate value='general.button.cancel' />
        </Button>

        <ProgressButton
          className='uk-button uk-margin-medium-right'
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          isDisabled={submitCount > 0 && !isValid}
          isLoading={isSubmitting}
          onClick={handleSubmit}
        >
          <Translate value='general.button.save' />
        </ProgressButton>
      </div>
    </>
  )
}
