import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import classNames from 'classnames'
import { node, oneOf, string } from 'prop-types'
import React from 'react'

export const PAGE_CONTAINER_TYPE = {
  DEFAULT: 'DEFAULT',
  FLUID_WIDTH: 'FLUID_WIDTH',
}

/**
 * @description This component renders and controls a add page container component.
 * @function
 * @param {Object} props the component props
 */
const PageContainer = ({ children, dataTestId, pageContainerType }) => (
  <div
    className={classNames('page-container', {
      'page-container--fluid-width':
        pageContainerType === PAGE_CONTAINER_TYPE.FLUID_WIDTH,
    })}
    data-testid={dataTestId}
  >
    {children}
  </div>
)

PageContainer.propTypes = forbidExtraProps({
  children: node,
  dataTestId: string,
  pageContainerType: oneOf(Object.values(PAGE_CONTAINER_TYPE)),
})

PageContainer.defaultProps = {
  children: null,
  dataTestId: '',
  pageContainerType: PAGE_CONTAINER_TYPE.DEFAULT,
}

export default PageContainer
