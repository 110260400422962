export const postponeIntervalFormTranslations = {
  interval: {
    label: 'Intervall',
    placeholder: 'Intervall',
  },
  weekday_first: {
    label: 'Wochentag',
    placeholder: 'Wochentag',
  },
  weekday_second: {
    label: 'Zweiter Wochentag',
    placeholder: 'Zweiter Wochentag',
  },
  rythm_begin: {
    label: 'Rhythmus Startdatum (1. Leerung)',
    placeholder: 'Rhythmus Startdatum (1. Leerung)',
  },
  buttons: {
    submit: 'Speichern',
  },
}
