import './style.scss'

import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { ReadOnlyField } from 'components/common/ReadOnly'

import { DetailsSection } from '../DetailsSection'

interface CompanyDetailsComponentProps {
  company: Company
}

export const CompanyDetails: FC<CompanyDetailsComponentProps> = props => {
  const { company } = props

  return (
    <DetailsSection
      title={I18n.t(
        'documentReviewPageTranslations.companyDetails.sectionTitle',
      )}
    >
      <div className='document-review-section-company-details'>
        {/* COMPANY NAME */}
        <div className='document-review-section-company-details__costumer-section'>
          <ReadOnlyField
            label={I18n.t(
              'documentReviewPageTranslations.companyDetails.companyName',
            )}
            value={company.name}
          />
          {/* COSTUMER NUMBER */}
          <ReadOnlyField
            label={I18n.t(
              'documentReviewPageTranslations.companyDetails.costumerNumber',
            )}
            value={company.empto_external_number}
          />
        </div>
        {/* COMPANY ADDRESS */}
        {company.main_address_object && (
          <div className='document-review-section-company-details__address-section'>
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.companyDetails.companyAddress.street',
              )}
              value={company.main_address_object.street}
            />
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.companyDetails.companyAddress.houseNumber',
              )}
              value={company.main_address_object.house_number}
            />
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.companyDetails.companyAddress.zipCode',
              )}
              value={company.main_address_object.zipcode}
            />
            <ReadOnlyField
              label={I18n.t(
                'documentReviewPageTranslations.companyDetails.companyAddress.location',
              )}
              value={company.main_address_object.location}
            />
          </div>
        )}
      </div>
    </DetailsSection>
  )
}
