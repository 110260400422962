import './style.scss'

import classNames from 'classnames'
import React, { FC, Fragment } from 'react'
import { Translate } from 'react-i18nify'
import Media from 'react-media'

import Icon, { ICON_NAME } from 'components/common/Fontello'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import { BREAKPOINT } from 'constants/design'

import {
  BenefitComparisonContent as values,
  BenefitComparisonEntry,
} from './BenefitComparisonContent'

export const BenefitComparison: FC = () => (
  <section className='benefit-comparison'>
    <Headline
      color={HEADLINE_COLOR.GRAYISH_BROWN}
      fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
      fontWeight={HEADLINE_FONT_WEIGHT.BOLD}
      headlineStyle={HEADLINE_STYLE.H3}
      tag={HEADLINE_TAG.H3}
    >
      <Translate value='comparisonTable.headline' />
    </Headline>

    <Media query={{ minWidth: BREAKPOINT.LARGE }}>
      {isLargeScreen =>
        isLargeScreen ? (
          <div className='benefit-comparison__table' role='table'>
            <div
              className={classNames(
                'benefit-comparison__table-cell',
                'benefit-comparison__table-cell--header',
                'benefit-comparison__table-cell--align-left',
              )}
              role='columnheader'
            >
              <Translate value='comparisonTable.headerServices' dangerousHTML />
            </div>
            <div
              className={classNames(
                'benefit-comparison__table-cell',
                'benefit-comparison__table-cell--header',
              )}
              role='columnheader'
            >
              <Translate value='comparisonTable.headerExternal' dangerousHTML />
            </div>
            <div
              className={classNames(
                'benefit-comparison__table-cell',
                'benefit-comparison__table-cell--header',
              )}
              role='columnheader'
            >
              <Translate value='comparisonTable.headerEmpto' dangerousHTML />
            </div>
            {values.map((value: BenefitComparisonEntry) => (
              <Fragment key={value.key}>
                <div
                  className={classNames(
                    'benefit-comparison__table-cell',
                    'benefit-comparison__table-cell--align-left',
                    'benefit-comparison__table-cell--service-name',
                  )}
                  role='cell'
                >
                  {value.title}
                </div>
                <div className='benefit-comparison__table-cell' role='cell'>
                  <Icon
                    className={classNames('benefit-comparison__table-icon', {
                      'benefit-comparison__table-icon--highlighted':
                        value.external,
                    })}
                    name={
                      value.external ? ICON_NAME.CHECKMARK : ICON_NAME.MINUS
                    }
                  />
                </div>
                <div className='benefit-comparison__table-cell' role='cell'>
                  <Icon
                    className={classNames('benefit-comparison__table-icon', {
                      'benefit-comparison__table-icon--highlighted':
                        value.empto,
                    })}
                    name={value.empto ? ICON_NAME.CHECKMARK : ICON_NAME.MINUS}
                  />
                </div>
              </Fragment>
            ))}
          </div>
        ) : (
          <>
            <div className='benefit-comparison__table' role='table'>
              <div
                className='benefit-comparison__table-cell benefit-comparison__table-cell--header'
                role='columnheader'
              >
                <Translate
                  value='comparisonTable.headerExternal'
                  dangerousHTML
                />
              </div>
              {values.map(
                (value: BenefitComparisonEntry) =>
                  value.external && (
                    <div
                      key={value.key}
                      className={classNames(
                        'benefit-comparison__table-cell',
                        'benefit-comparison__table-cell--align-left',
                      )}
                      role='cell'
                    >
                      <Icon
                        className={classNames(
                          'benefit-comparison__table-icon',
                          'benefit-comparison__table-icon--highlighted',
                        )}
                        name={
                          value.empto ? ICON_NAME.CHECKMARK : ICON_NAME.MINUS
                        }
                      />
                      <span>{value.title}</span>
                    </div>
                  ),
              )}
            </div>

            <div className='benefit-comparison__table' role='table'>
              <div
                className='benefit-comparison__table-cell benefit-comparison__table-cell--header'
                role='columnheader'
              >
                <Translate value='comparisonTable.headerEmpto' dangerousHTML />
              </div>
              {values.map(
                (value: BenefitComparisonEntry) =>
                  value.empto && (
                    <div
                      key={value.key}
                      className={classNames(
                        'benefit-comparison__table-cell',
                        'benefit-comparison__table-cell--align-left',
                      )}
                      role='cell'
                    >
                      <Icon
                        className={classNames(
                          'benefit-comparison__table-icon',
                          'benefit-comparison__table-icon--highlighted',
                        )}
                        name={
                          value.empto ? ICON_NAME.CHECKMARK : ICON_NAME.MINUS
                        }
                      />
                      <span>{value.title}</span>
                    </div>
                  ),
              )}
            </div>
          </>
        )
      }
    </Media>
  </section>
)
