import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import withErrorBoundary from 'helper/withErrorBoundary'

import { Homepage } from '../../common/Homepage'

const HomepageWasteDisposerComponent: FC = () => (
  <Homepage
    pageTitle={I18n.t('pageTitles.homepageWasteDisposer')}
    pageDescription=''
    showWasteDisposerContent
  />
)

export const HomepageWasteDisposer = withErrorBoundary(
  HomepageWasteDisposerComponent,
)
