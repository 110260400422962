import ActionTypes from '../actiontypes/discount'
import {
  DISCOUNT_OCCASION,
  DISCOUNT_STATUS,
} from '../components/discount/constants'
import { replaceObjectInArray } from '../helper/general'

export const itemInitialState = {
  company: null,
  redeemed_by: null,
  redeemed_at: null,
  value: null,
  code: null,
  status: DISCOUNT_STATUS.STATUS_ACTIVE,
  occasion: DISCOUNT_OCCASION.OCCASION_CAMPAIGN,
  description: '',
  valid_from: null,
  valid_until: null,
}

export const initialState = {
  item: itemInitialState,
  itemList: [], // list of all discounts
  pagination: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
}

/**
 * @description A reducer for discounts.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const discountReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DISCOUNTS_SUCCESS:
      return {
        ...state,
        itemList: action.payload.data,
        pagination: { loaded: true, ...action.payload.pagination },
      }
    case ActionTypes.GET_DISCOUNT_SUCCESS:
      return {
        ...state,
        item: action.payload,
      }
    case ActionTypes.CREATE_DISCOUNT_SUCCESS: {
      return {
        ...state,
        item: action.payload,
        itemList: [action.payload, ...state.itemList],
      }
    }
    case ActionTypes.UPDATE_DISCOUNT_STATUS_SUCCESS:
      return {
        ...state,
        item: action.payload,
        itemList: replaceObjectInArray(action.payload, state.itemList),
      }
    default:
      return state
  }
}

export default discountReducer
