import { changeAgreementPeriodFormTranslations } from '../../components/agreement/AgreementDetailsPage/ChangeAgreementPeriodModal/ChangeAgreementPeriodForm/translations'
import { changeAgreementPeriodModalTranslations } from '../../components/agreement/AgreementDetailsPage/ChangeAgreementPeriodModal/translations'
import { changeDisposerFormTranslations } from '../../components/agreement/AgreementDetailsPage/ChangeDisposerModal/ChangeDisposerForm/translations'
import { changeDisposerModalTranslations } from '../../components/agreement/AgreementDetailsPage/ChangeDisposerModal/translations'
import { convertInquiryFormTranslations } from '../../components/agreement/AgreementDetailsPage/ConvertInquiryModal/ConvertInquiryForm/translations'
import { convertInquiryModalTranslations } from '../../components/agreement/AgreementDetailsPage/ConvertInquiryModal/translations'
import { postponeIntervalFormTranslations } from '../../components/agreement/AgreementDetailsPage/PostponeIntervalModal/PostponeIntervalForm/translations'
import { postponeIntervalModalTranslations } from '../../components/agreement/AgreementDetailsPage/PostponeIntervalModal/translations'
import { activitiesSectionTranslations } from '../../components/agreement/AgreementDetailsPage/sections/ActivitiesSection/translations'
import { agreementDetailsTranslations } from '../../components/agreement/AgreementDetailsPage/translations'
import { certificateDetailsPageTranslations } from '../../components/certificate/CertificateDetailsPage/translations'
import accountActivationNoticeTranslation from '../../components/common/AccountActivationNotice/translation'
import { asyncExportModalTranslations } from '../../components/common/AsyncExportModal'
import { ButtonLearnMoreTranslations } from '../../components/common/ButtonLearnMore'
import { disposerCustomerInfoTranslations } from '../../components/common/DisposerCustomerInfo/translations'
import { InvoiceCheckPositionReviewTranslations } from '../../components/common/DocumentReview/InvoiceCheckPositionReview/translations'
import { documentReviewPageTranslations } from '../../components/common/DocumentReview/translations'
import filePreview from '../../components/common/FilePreview/translations'
import pdfViewer from '../../components/common/FilesViewer/components/PdfViewer/translations'
import { filterResetTranslation } from '../../components/common/Filter/components/FilterReset/translation'
import { filterTranslations } from '../../components/common/Filter/translation'
import formGroupErrorTranslations from '../../components/common/Form/components/FormGroupError/translations'
import { orderTypeDetailsGroupTranslations } from '../../components/common/GenericInquiryFields/translations'
import { OfferResultsTranslations } from '../../components/common/Homepage/components/IntroSection/components/OfferResults'
import inactiveCompanyMessageTranslation from '../../components/common/InactiveCompanyMessage/translation'
import { inputChooseItemTranslations } from '../../components/common/InputChooseItem/translations'
import { AgreementCardTranslations } from '../../components/common/NewCards/AgreementCard'
import { AgreementDashboardCardTranslations } from '../../components/common/NewCards/AgreementDashboardCard'
import { InquiryCardTranslations } from '../../components/common/NewCards/InquiryCard/translations'
import { InquiryOfferCardTranslations } from '../../components/common/NewCards/InquiryOfferCard/translations'
import { InquiryOpenCardTranslations } from '../../components/common/NewCards/InquiryOpenCard/translations'
import { InquiryOpenDashboardCardTranslations } from '../../components/common/NewCards/InquiryOpenDashboardCard'
import { OfferCardTranslations } from '../../components/common/NewCards/OfferCard/translations'
import { orderCardTranslations } from '../../components/common/NewCards/OrderCard/translations'
import { OrderDashboardCardTranslations } from '../../components/common/NewCards/OrderDashboardCard'
import { noContentMessageTranslation } from '../../components/common/NoContentMessage/translation'
import { RatingCaptionTranslations } from '../../components/common/RatingCaption/translations'
import { RegisterOrRequestDemoButtonsTranslations } from '../../components/common/RegisterOrRequestDemoButtons/translations'
import selectSecurityGroupTranslations from '../../components/common/SelectSecurityGroup/translations'
import { UniversalFileUploadTranslations } from '../../components/common/UniversalFileUpload'
import uploadCompanyProofMessageTranslation from '../../components/common/UploadCompanyProofMessage/translation'
import { uploadReviewDocumentTranslations } from '../../components/common/UploadDocumentForm/translations'
import { companyArchiveTranslations } from '../../components/company/CompanyArchivePage/translations'
import { AboutUsFormTranslations as aboutUsFormTranslations } from '../../components/company/CompanyInfoPage/components/AboutUsForm/translations'
import { blockCompanyModalTranslations } from '../../components/company/CompanyInfoPage/components/BlockCompanyModal/translations'
import { CompanyNotesTranslations as companyNotesTranslations } from '../../components/company/CompanyInfoPage/components/CompanyNotes/translation'
import { archiveModalTranslations } from '../../components/company/CompanyInfoPage/components/Documents/components/archiveModal/translations'
import { DocumentUploadFormTranslations as documentUploadFormTranslations } from '../../components/company/CompanyInfoPage/components/DocumentUploadForm/translations'
import { DocumentUploadModalTranslations as documentUploadModalTranslations } from '../../components/company/CompanyInfoPage/components/DocumentUploadModal/translations'
import { pauseCompanyModalTranslations } from '../../components/company/CompanyInfoPage/components/PausingModal/translations'
import { ResponsibleEmployeeTranslations as responsibleEmployeeTranslations } from '../../components/company/CompanyInfoPage/components/ResponsibleEmployee/translations'
import { CompanyInfoPageTranslations as companyInfoPageTranslations } from '../../components/company/CompanyInfoPage/translations'
import { CardListSliderTranslations } from '../../components/dashboard/components/CardListSlider/translation'
import { NewsCardTranslations } from '../../components/dashboard/components/NewsCard'
import { SquareMetricsTeaserTranslations } from '../../components/dashboard/components/SquareMetricsTeaser'
import { DashboardPageTranslations } from '../../components/dashboard/translation'
import { priceManagementPageTranslations } from '../../components/dsz/priceManagementPage/translations'
import { dszGeneralTranslations } from '../../components/dsz/translations'
import { executionProofDetailsPageTranslations } from '../../components/executionproof/ExecutionProofDetailsPage/translations'
import { emptoDownloadsTranslations } from '../../components/general/EmptoDownloads/translations'
import { emptoInternalDownloadsTranslations } from '../../components/general/EmptoInternalDownloads/translations'
import { WasteManagerComparisonTableTranslations } from '../../components/homepages/HomepageWastemanager/BenefitComparison/translation'
import { WastemanagerBenefitSectionTranslation } from '../../components/homepages/HomepageWastemanager/WastemanagerBenefitsSection/translation'
import { WastemanagerHeroSectionTranslation } from '../../components/homepages/HomepageWastemanager/WastemanagerHeroSection'
import { requestDemoTranslations } from '../../components/homepages/RequestDemo/translations'
import { CreateInquiryButtonTranslations } from '../../components/inquiry/CreateInquiryButton/translations.ts'
import { createInquiryTranslations } from '../../components/inquiry/CreateInquiryPage/translations'
import { InactiveCompanyModalTranslations } from '../../components/inquiry/InactiveCompanyModal/translations.ts'
import inquiryDetailsTranslations from '../../components/inquiry/InquiryDetailsPage/translations'
import { addInvoicePageTranslations } from '../../components/maklerpremium/BookkeepingList/AddInvoicePage/translations'
import { bookkeepingTranslations } from '../../components/maklerpremium/BookkeepingList/translations'
import { CancelledPaidDocumentReviewPageTranslations } from '../../components/maklerpremium/CancelPaidDocumentReview/translations'
import { taskLogDetailsModalTranslations } from '../../components/maklerpremium/CollectiveInvoicing/components/ActivityTable/DetailsModal/translations'
import { collectiveInvoicingTranslations } from '../../components/maklerpremium/CollectiveInvoicing/translations'
import { communicationCenterPageTranslations } from '../../components/maklerpremium/CommunicationCenterPage/translations'
import { createMaklerPremiumOfferPageTranslations } from '../../components/maklerpremium/CreateMaklerPremiumOfferPage/translations'
import disposerProducerPageTranslations from '../../components/maklerpremium/DisposerProducerPage/translations'
import { addAgreementPageTranslations } from '../../components/maklerpremium/epd/AddAgreementPage/translations'
import { compensationPriceFieldsTranslations } from '../../components/maklerpremium/InvoiceCheckOverview/InvoiceCheckPositionsForm/Step2/OpenOrderForm/CompensationPriceFields/translations'
import { disposalPriceFieldsTranslations } from '../../components/maklerpremium/InvoiceCheckOverview/InvoiceCheckPositionsForm/Step2/OpenOrderForm/DisposalPriceFields/translations'
import { invoiceCheckOverviewTranslations } from '../../components/maklerpremium/InvoiceCheckOverview/translations'
import { invoiceCheckTranslations } from '../../components/maklerpremium/InvoiceCheckPage/translations'
import { InvoiceCheckPositionReviewPageTranslations } from '../../components/maklerpremium/InvoiceCheckPositionReviewPage/translations'
import { offerPipelineTranslations } from '../../components/maklerpremium/OfferPipelinePage'
import { upcomingOrdersTranslations } from '../../components/maklerpremium/UpcomingOrders/translations'
import { priceDetailFieldsTranslations } from '../../components/offer/CreateOfferPage/PriceDetailFields/translations'
import createOfferTranslations from '../../components/offer/CreateOfferPage/translations'
import myAutoOffersPageTranslations from '../../components/offer/MyAutoOffersPage/translations'
import { cancelOfferFormTranslations } from '../../components/offer/OfferDetailsPage/CancelOfferModal/CancelOfferForm/translations'
import { offerDetailsTranslations } from '../../components/offer/OfferDetailsPage/translations'
import { orderCancelTranslations } from '../../components/order/OrderDetailsPage/components/OrderCancelForm/translation'
import { orderPostPoneTranslations } from '../../components/order/OrderDetailsPage/components/OrderPostponeForm/translations'
import orderClaimStatusMessages from '../../components/order/OrderDetailsPage/components/OrderStatusList/components/OrderStatusListItem/translation'
import { OrderDisposeTranslation } from '../../components/order/OrderDetailsPage/components/OrderStatusTasks/translation'
import { orderTranslations } from '../../components/order/translations'
import { orderClaimDetailsPageTranslations } from '../../components/orderclaimproof/OrderClaimProofDetailsPage/translations'
import { TVPContractPageTranslations } from '../../components/tvp/TVPContractPage/translations'
import { forgetPasswordTranslations } from '../../components/user/ForgetPassword/translations'
import { userActivationTranslations } from '../../components/user/UserActivation/translations'
import userProfilePageTranslations from '../../components/user/UserProfilePage/translation'
import { WasteDisposerOrderManagementPageTranslations } from '../../components/wasteDisposerOrderManagementPage/translations'
import { OfferReviewTranslations } from '../../components/common/DocumentReview/OfferReview/translations'
import { OfferReviewPageTranslations } from '../../components/maklerpremium/OfferReviewPage/translations'

import apiTranslations from './api'
import disposersSlidersTranslations from './general/disposersSliders'
import help, { pdfLink } from './help/de'
import imprint from './imprint/de'
import legalNotice from './legalNotice/de'
import privacy from './privacy/de'

// Reused these multiple times
const phoneString = '0800&nbsp00&nbsp56356'
const phoneValue = '0800-00-56356'
const email = 'service@empto.de'

export const translationsObject = {
  de: {
    inputChooseItemTranslations,
    priceDetailFieldsTranslations,
    certificateDetailsPageTranslations,
    executionProofDetailsPageTranslations,
    orderClaimDetailsPageTranslations,
    uploadReviewDocumentTranslations,
    documentReviewPageTranslations,
    ...AgreementDashboardCardTranslations,
    agreementDetailsTranslations, // new details page
    ...ButtonLearnMoreTranslations,
    asyncExportModalTranslations,
    ...CreateInquiryButtonTranslations,
    ...CardListSliderTranslations,
    ...DashboardPageTranslations,
    ...OrderDisposeTranslation,
    createOfferTranslations,
    offerDetailsTranslations,
    disposersSlidersTranslations,
    filterTranslations,
    help,
    filePreview,
    selectSecurityGroupTranslations,
    emptoDownloadsTranslations,
    emptoInternalDownloadsTranslations,
    createInquiryTranslations,
    orderTypeDetailsGroupTranslations,
    createMaklerPremiumOfferPageTranslations,
    TVPContractPageTranslations,
    inquiryDetailsTranslations,
    indicationMessageTranslation: {
      inactiveCompanyMessage: inactiveCompanyMessageTranslation,
      uploadCompanyProofMessage: uploadCompanyProofMessageTranslation,
    },
    infoBannerTranslations: {
      accountActivationNotice: accountActivationNoticeTranslation,
    },
    companyNotesTranslations,
    responsibleEmployeeTranslations,
    imprint,
    ...InactiveCompanyModalTranslations,
    ...InquiryCardTranslations,
    ...InquiryOfferCardTranslations,
    ...InquiryOpenCardTranslations,
    ...InquiryOpenDashboardCardTranslations,
    ...NewsCardTranslations,
    noContentMessageTranslation,
    filterResetTranslation,
    ...OfferResultsTranslations,
    ...OrderDashboardCardTranslations,
    orderCardTranslations,
    userProfilePageTranslations,
    legalNotice,
    pdfViewer,
    privacy,
    ...RatingCaptionTranslations,
    ...RegisterOrRequestDemoButtonsTranslations,
    ...SquareMetricsTeaserTranslations,
    userActivationTranslations,
    forgetPasswordTranslations,
    bookkeepingTranslations,
    communicationCenterPageTranslations,
    myAutoOffersPageTranslations,
    formGroupErrorTranslations,
    disposerProducerPageTranslations,
    offerPipelineTranslations,
    convertInquiryModalTranslations,
    convertInquiryFormTranslations,
    changeDisposerModalTranslations,
    changeDisposerFormTranslations,
    postponeIntervalModalTranslations,
    postponeIntervalFormTranslations,
    upcomingOrdersTranslations,
    addInvoicePageTranslations,
    addAgreementPageTranslations,
    ...WastemanagerBenefitSectionTranslation,
    ...WasteManagerComparisonTableTranslations,
    ...WastemanagerHeroSectionTranslation,
    ...AgreementCardTranslations,
    ...OfferCardTranslations,
    ...UniversalFileUploadTranslations,
    InvoiceCheckPositionReviewPageTranslations,
    InvoiceCheckPositionReviewTranslations,
    OfferReviewPageTranslations,
    OfferReviewTranslations,
    CancelledPaidDocumentReviewPageTranslations,
    changeAgreementPeriodModalTranslations,
    changeAgreementPeriodFormTranslations,
    pauseCompanyModalTranslations,
    blockCompanyModalTranslations,
    aboutUsFormTranslations,
    companyInfoPageTranslations,
    documentUploadModalTranslations,
    documentUploadFormTranslations,
    dszGeneralTranslations,
    priceManagementPageTranslations,
    WasteDisposerOrderManagementPageTranslations,
    api: apiTranslations,
    activitiesSectionTranslations,
    companyArchiveTranslations,
    disposerCustomerInfoTranslations,
    archiveModalTranslations,
    disposalPriceFieldsTranslations,
    compensationPriceFieldsTranslations,
    collectiveInvoicingTranslations,
    taskLogDetailsModalTranslations,
    general: {
      id: 'ID',
      actions: 'Aktionen',
      address: 'Adresse',
      allTheSame: 'Egal',
      attention: 'Achtung',
      asc: 'Aufsteigend',
      alreadyOffered:
        'Für diese Anfrage haben Sie bereits ein Angebot abgegeben.',
      attachments: 'Dokumente',
      autoGeneratedOffer: 'Angebot wurde automatisch generiert',
      autoOffer: 'Angebotsassistent',
      number: 'Nummer',
      avv: 'AVV Nummer',
      avvShort: 'AVV',
      before: 'Vorher',
      back: 'Zurück',
      backToOverview: 'Zurück zur Übersicht',
      businessPhoneNumber: 'Geschäftliche Telefonnummer',
      page: 'Seite',
      of: 'von',
      button: {
        archive: 'Archiv',
        back: 'Zurück',
        backToLogin: 'Zurück zur Anmeldung',
        cancel: 'Abbrechen',
        changePassword: 'Passwort ändern',
        create: 'Erstellen',
        details: 'Details',
        delete: 'Löschen',
        edit: 'Bearbeiten',
        done: 'Erledigt',
        informMe: 'Informieren',
        next: 'Weiter',
        save: 'Speichern',
        send: 'Senden',
        submit: 'Ok',
        select: 'Auswählen',
      },
      callbackPhoneNumber: 'Telefonnummer für Rückfragen',
      capsLockOn: 'Feststelltaste ist aktiviert',
      certificates: 'Nachweise',
      changeStatus: 'Status ändern',
      charsRemaining: '%{number} verbleibende Zeichen',
      company: 'Unternehmen',
      companyProfile: 'Stammdaten',
      createdAt: 'Erstellt am',
      createdBy: 'Ersteller/in',
      lastmodifiedAt: 'Geändert am',
      lastmodifiedBy: 'Bearbeiter/in',
      date: 'Datum',
      desc: 'Absteigend',
      displayOffCanvasNavigation: 'mobile Navigation anzeigen',
      deductionOrder: 'Abzug',
      download: {
        doc: 'Dokument herunterladen',
      },
      editButton: 'Bearbeiten',
      emailAddress: 'E-Mail-Adresse',
      emptyImageSet: 'Es wurden keine Bilder hinterlegt.',
      emptyFilterResultMessage:
        'Zu der gewählten Filtereinstellung liegen aktuell keine Ergebnisse vor.',
      etaService: 'ETA-Service',
      executionProofs: 'Leistungsnachweise',
      vouchers: 'Belege',
      invoiceCheckAttachments: 'Belege Entsorger',
      invoices: 'Belege Kunde',
      export: 'CSV Export',
      failedToLoadPdf: 'PDF konnte nicht geladen werden!',
      fieldError: 'Ungültige Eingabe',
      form: {
        checkInput: 'Bitte überprüfen Sie Ihre Eingabe.',
        checkInputs: 'Bitte überprüfen Sie Ihre Eingaben.',
        noOptions: 'Keine Einträge vorhanden',
      },
      hideFilter: 'Filter ausblenden',
      hideOffCanvasNavigation: 'mobile Navigation ausblenden',
      indexValues: 'Indexwerte',
      information: 'Hinweis',
      inquiry: 'Anfrage',
      inquiryCreatedBy: 'Ersteller/in Anfrage',
      invalidDate: 'Ungültiges Datum',
      invoiceCheck: 'Belegprüfung',
      label: {
        specialNote: 'Bezeichnung der Adresse',
      },
      location: 'Standort',
      locations: 'Standorte',
      login: 'Anmelden',
      logout: 'Abmelden',
      members: 'Benutzer',
      memberSince: 'Mitglied seit',
      missingGoogleMarker:
        'Es konnten leider nicht alle Standorte bei Google ermittelt werden.',
      name: 'Name',
      nameOfAez: 'Name des Entsorgers',
      none: 'Keine',
      noIndex: 'Kein Index',
      offer: 'Angebot',
      offers: 'Angebote',
      orderClaims: 'Reklamationen',
      orderClaimsAndExecutionproofs: 'Leistungsnachweise /<br>Reklamationen',
      paymentMethods: 'Zahlungsarten',
      permissions: 'Berechtigungen',
      phoneInquiry: 'Anfrage wurde telefonisch erstellt.',
      mailOffer: 'Angebot wurde per E-Mail erstellt.',
      price: 'Preis',
      placeholder: {
        all: 'Alle',
        authorization: 'Berechtigung',
        date_of_birth: '01.01.1970',
        contactPerson: 'Ansprechpartner',
        contactPersonOnSite: 'Ansprechpartner vor Ort',
        email: 'E-Mail',
        emailOnSite: 'E-Mail (Standort)',
        empty: 'Leer',
        firstName: 'Vorname',
        gender: 'Anrede',
        houseNumber: 'Hausnummer',
        lastName: 'Nachname',
        location: 'Ort',
        password: 'Wählen Sie ein Passwort',
        passwordLabel: 'Passwort',
        passwordLogin: 'Geben Sie Ihr Passwort ein',
        passwordRepeat: 'Wiederholen Sie Ihr Passwort',
        passwordSet: 'Wählen Sie ein Passwort',
        passwordSetRepeat: 'Wiederholen Sie Ihr Passwort',
        specialNote: 'Filialnummer, Name des Bauprojekts...',
        street: 'Straße',
        telephoneNumberOnSite: 'Telefonnummer (Standort)',
        zipcode: 'PLZ',
      },
      platformCompany: 'empto',
      pleaseChoose: 'Bitte auswählen',
      position: 'Position',
      previewFileModal: 'Datei Vorschau',
      profile: 'Mein Profil',
      rating: 'Bewertung',
      reactionTime: 'Reaktionszeit/Leistungsfrist',
      resetFilter: 'Filter zurücksetzen',
      serviceAddress: 'Leistungsadresse',
      showFilter: 'Filter einblenden',
      status: 'Status',
      themes: 'Themenbereiche',
      time: 'Uhrzeit',
      timeOfDay: 'Zeitraum',
      toastr: {
        saveSuccess: 'Die Daten wurden erfolgreich gespeichert.',
        saveError:
          'Leider ist ein Fehler beim Speichern der Daten aufgetreten.',
      },
      userProfile: 'Benutzerprofil',
      userRole: 'Rolle',
      validUntilDate: 'Gültig bis %{date}',
      yes: 'Ja',
      no: 'Nein',
      handleCSVExport: {
        success: 'CSV Export wurde erfolgreich erstellt.',
        error: 'CSV Export konnte nicht erstellt werden.',
      },
    },
    routeError: {
      error: {
        404: 'Diese Seite wurde nicht gefunden',
      },
      goHome: 'Zurück zur Startseite',
    },
    cookieConsent: {
      banner: {
        text:
          'Wir verwenden Cookies, um Ihnen den bestmöglichen Service zu bieten. ' +
          'Weitere Informationen erhalten Sie in unserer <a href="%{privacyLink}">Datenschutzerklärung</a>.',
        acceptButton: 'Erlauben',
        denyButton: 'Ablehnen',
      },
      error: {
        login:
          'Bevor Sie sich anmelden können, müssen Sie der Verwendung von Cookies zustimmen. ' +
          'Bitte benutzen Sie dazu die Schaltfläche unten rechts auf dieser Seite.',
        signup:
          'Bevor Sie sich registrieren können, müssen Sie der Verwendung von Cookies zustimmen. ' +
          'Bitte benutzen Sie dazu die Schaltfläche unten rechts auf dieser Seite.',
      },
    },
    maklerpremium: {
      addWasteDisposerModal: {
        form: {
          validation: {
            too_long_message: 'Zu viele Zeichen',
            company_name: 'Bitte eingeben',
            street: 'Bitte eingeben',
            house_number: 'Bitte eingeben',
            postal_code: 'Bitte eingeben',
            postal_code_invalid: 'PLZ ungültig',
            city: 'Bitte eingeben',
            tax_number: 'Bitte eingeben',
            tax_number_invalid: 'Steuernummer ungültig',
            tax_id: 'Bitte eingeben',
            tax_id_invalid: 'USt.-IdNr. ungültig',
            iban: 'Bitte eingeben',
            iban_length_invalid: 'IBAN hat die falsche Länge',
            bic: 'Bitte eingeben',
            bic_length_invalid: 'BIC hat die falsche Länge',
            is_male: 'Bitte auswählen',
            payment_on_account: 'Bitte auswählen',
            permanent_discount: 'Bitte eingeben',
            central_contact_email: 'Bitte eingeben',
            central_contact_phone: 'Bitte eingeben',
            contact_person: 'Bitte eingeben',
            has_central_contact: 'Bitte auswählen',
            contact_person_is_male: 'Bitte auswählen',
            contact_person_first_name: 'Bitte eingeben',
            contact_person_last_name: 'Bitte eingeben',
            contact_person_position: 'Bitte eingeben',
            contact_person_email: 'Bitte eingeben',
            contact_person_email_already_exists:
              'Es existiert bereits ein Benutzer mit dieser E-Mail-Adresse',
          },
          salutation: 'Anrede',
          customer_type: 'Kundentyp',
          company_name: 'Name des Unternehmens',
          street: 'Straße',
          house_number: 'Hausnummer',
          postal_code: 'Postleitzahl',
          city: 'Ort',
          is_company_true: 'Geschäftskunde',
          is_company_false: 'Privatkunde',
          tax_number: 'Steuernummer',
          tax_id: 'Umsatzsteuer-Identifikationsnummer',
          iban: 'IBAN',
          bic: 'BIC',
          central_contact_email: 'Optional: Zentrale E-Mailadresse',
          central_contact_phone: 'Optional: Zentrale Telefonnummer',
          contact_person: 'Ansprechpartner',
          has_central_contact: 'Zentrale Kontaktdaten für EPD?',
          contact_person_salutation: 'Anrede',
          contact_person_first_name: 'Vorname',
          contact_person_last_name: 'Nachname',
          contact_person_position: 'Optional: Position',
          contact_person_phone: 'Optional: Telefonnummer',
          contact_person_email: 'E-Mail-Adresse',
          header: {
            title: 'Entsorger anlegen',
          },
        },
      },
      addWasteProducerModal: {
        form: {
          taxDetails: {
            line1: 'Steuerinformationen',
            line2:
              'Bitte füllen Sie je nach Verfügbarkeit eines der folgenden Felder aus.',
          },
          validation: {
            too_long_message: 'Zu viele Zeichen',
            company_name: 'Bitte eingeben',
            first_name: 'Bitte eingeben',
            last_name: 'Bitte eingeben',
            street: 'Bitte eingeben',
            house_number: 'Bitte eingeben',
            postal_code: 'Bitte eingeben',
            postal_code_invalid: 'PLZ ungültig',
            city: 'Bitte eingeben',
            is_company_true: 'Bitte Auswählen',
            is_company_false: 'Bitte Auswählen',
            tax_number: 'Bitte eingeben',
            tax_number_invalid: 'Steuernummer ungültig',
            tax_id: 'Bitte eingeben',
            tax_id_invalid: 'USt.-IdNr. ungültig',
            is_male: 'Bitte auswählen',
            payment_on_account: 'Bitte auswählen',
            permanent_discount: 'Bitte eingeben',
            central_contact_email: 'Bitte eingeben',
            central_contact_phone: 'Bitte eingeben',
            contact_person: 'Bitte eingeben',
            has_central_contact: 'Bitte auswählen',
            contact_person_is_male: 'Bitte auswählen',
            contact_person_first_name: 'Bitte eingeben',
            contact_person_last_name: 'Bitte eingeben',
            contact_person_position: 'Bitte eingeben',
            contact_person_email: 'Bitte eingeben',
            contact_person_email_already_exists:
              'Es existiert bereits ein Benutzer mit dieser E-Mail-Adresse',
          },
          salutation: 'Anrede',
          customer_type: 'Kundentyp',
          company_name: 'Name des Unternehmens',
          street: 'Straße',
          house_number: 'Hausnummer',
          postal_code: 'Postleitzahl',
          city: 'Ort',
          is_company_empty: '-',
          is_company_true: 'Geschäftskunde',
          is_company_false: 'Privatkunde',
          first_name: 'Vorname',
          last_name: 'Nachname',
          tax_number: 'Steuernummer',
          tax_id: 'Umsatzsteuer-Identifikationsnummer',
          payment_on_account: 'Zahlung auf Rechnnung',
          permanent_discount: 'Dauerrabatt',
          central_contact_email: 'zentrale E-Mailadresse',
          central_contact_phone: 'zentrale Telefonnummer',
          contact_person: 'Ansprechpartner',
          has_central_contact: 'Zentrale Kontaktdaten für EPD?',
          contact_person_salutation: 'Anrede',
          contact_person_first_name: 'Vorname',
          contact_person_last_name: 'Nachname',
          contact_person_position: 'Optional: Position',
          contact_person_phone: 'Optional: Telefonnummer',
          contact_person_email: 'E-Mail-Adresse',
          header: {
            title: 'Kunde anlegen',
          },
        },
      },
    },
    homepages: {
      register: {
        acceptConditions:
          'Ich bestätige die <a href="%{linkPrivacyStatement}" ' +
          'target="_blank">Datenschutzerklärung</a> und <a ' +
          'href="%{linkConditionsOfParticipation}" target="_blank">Teilnahmebedingungen</a>.',
        broker: 'Makler',
        explanation: {
          broker:
            'Ich möchte als Vermittler zwischen Entsorgern und Gewerbetreibenden agieren.',
          newAccount:
            'Wir freuen uns, dass Sie ein kostenloses Benutzerkonto für empto erstellen möchten.<br /> Dazu' +
            ' führen wir Sie durch zwei Schritte, damit Sie im Handumdrehen den Markplatz von empto verwenden können.',
          wasteDisposer:
            'Ich möchte als Entsorgungspartner für empto Abfall abholen.',
          wasteProducer: 'Ich möchte Abfall bei mir vor Ort abholen lassen.',
          registrationDisabled:
            'Vielen Dank für Ihr Interesse,' +
            ' die Registrierung für neue Benutzer steht im Moment leider nicht zur Verfügung.',
        },
        form: {
          companyNameLabel: 'Name des Unternehmens',
          companyNamePlaceholder: 'Vollständiger Name des Unternehmens',
          firstNameLabel: 'Vorname',
          firstNamePlaceholder: 'Vorname',
          lastNameLabel: 'Nachname',
          lastNamePlaceholder: 'Nachname',
          mailAddressLabel: 'Ihre E-Mail-Adresse',
          mailAddressPlaceholder: 'E-Mail-Adresse für Ihren Account',
          passwordConfirmationLabel: 'Passwort bestätigen',
          passwordConfirmationPlaceholder: 'Passwort bestätigen',
          passwordLabel: 'Passwort',
          passwordPlaceholder: 'Passwort',
          phoneNumberLabel: 'Ihre Telefonnummer (für Rückfragen)',
          phoneNumberPlaceholder: 'Ihre Telefonnummer (für Rückfragen)',
          titleLabel: 'Anrede',
          titlePlaceholder: 'Anrede',
        },
        headline: {
          fillOutForm: 'Geben Sie Ihre Daten an',
          selectRole: 'Wählen Sie eine Rolle',
        },
        newAccount: 'Neues Konto erstellen',
        registerNow: 'Jetzt kostenlos registrieren',
        selectBroker: 'Rolle Makler auswählen',
        selectRole: 'Rolle auswählen',
        selectWasteDisposer: 'Rolle Entsorger auswählen',
        selectWasteProducer: 'Rolle Gewerbetreibender auswählen',
        tooltip: {
          wasteDisposer:
            'empto steht allen Entsorgungsunternehmen offen, die über einen gültigen Nachweis verfügen, ' +
            'dass das Unternehmen gemäß §56 KrWG in Verbindung mit der Entsorgungsfachbetriebeverordnung die ' +
            'Bezeichnung Entsorgungsfachbetrieb führen darf.',
          wasteProducer:
            'empto steht allen Unternehmen offen, bei denen durch ihre Tätigkeit Abfälle anfallen. ' +
            'Privatpersonen können die Dienstleistungen von empto leider nicht in Anspruch nehmen.',
          broker:
            'empto steht allen Maklern offen, die über einen gültigen Nachweis verfügen, dass das Unternehmen ' +
            'die Anforderungen der LAGA Vollzugshilfe "Zertifizierung von Händlern und Vermittlern" erfüllt und ' +
            'berechtigt ist nach §56 KrWG die Bezeichnung Entsorgungsfachbetrieb für die Tätigkeiten Handeln und ' +
            'Makeln von allen Abfällen nach der Verordnung über das Europäische Abfallverzeichnis (AVV - ' +
            'Abfallverzeichnis-Verordnung) zu führen.',
        },
        wasteProducer: 'Gewerbetreibender',
        wasteDisposer: 'Entsorger',
      },
      company: {
        role: {
          wasteManagementCompany: {
            title: 'Entsorger',
          },
          wasteProducer: {
            title: 'Gewerbetreibender',
          },
          middleMan: {
            title: 'Makler',
          },
          premium: {
            title: 'Makler Premium',
          },
          privatePerson: {
            title: 'Privatperson',
          },
        },
      },
      wasteManager: {
        registerButton: 'Jetzt registrieren',
        anyQuestionsHeadline: 'Haben Sie Fragen?',
        // &#8209; Non-Breaking Hyphen
        customerService:
          'Unser freundliches und kompetentes Service&#8209;Team unterstützt Sie gerne von  ' +
          'Mo&#8209;Fr zwischen 8&#8209;18 Uhr bei Fragen zu empto. Sie erreichen uns unter der kostenlosen ' +
          `Telefonnummer <a href='tel:${phoneValue}'>${phoneString}</a>`,
      },
      privateCustomer: {
        intro: {
          headline: 'Einfach online Container bestellen - Für Privatkunden',
          description2:
            'Containerdienst.de gehört wie empto zur Zentek Gruppe und ist bundesweit für Privatkunden ' +
            'tätig. Wenn Sie bis 12 Uhr einen Container bestellen, dann erfolgt die Lieferung bereits am Folgetag!',
          containerdienstButton: 'Zum Containerdienst',
        },
      },
      wasteDisposer: {
        intro: {
          headline: 'Willkommen bei empto',
          description2:
            'Über empto finden Sie spielend leicht neue Geschäftskunden für Ihr Unternehmen. ' +
            'Abfallerzeuger stellen Anfragen, auf die Sie als Entsorger unkompliziert, zeitnah und digital ein ' +
            'individuelles Angebot abgeben können. Denn mit empto verwalten Sie Ihre gesamten Entsorgungsvorgänge ' +
            'online, jederzeit und von überall.',
          testEmptoButton: 'empto kostenlos testen',
        },
        whatIsEmpto: {
          button: 'Jetzt registrieren',
          headline: 'Ihre Vorteile mit empto',
          text:
            'Mit empto behalten Sie alle Ihre Aufträge stets im Blick. Sie können Ihre Vereinbarungen digital ' +
            'organisieren und sparen so Zeit und Papier. Die Abrechnung Ihrer Leistungen erfolgt transparent und' +
            ' schon innerhalb von 7 Tagen. empto unterstützt Sie während der gesamten Abwicklung - von der' +
            ' Anmeldung über die Rechnungsstellung bis hin zur Dokumentation sämtlicher Belege. Auf Wunsch kann' +
            ' das Team von empto auch Angebote nach Ihren Vorgaben erstellen - einfach, zuverlässig und automatisiert!',
          registerText:
            'Registrieren Sie sich jetzt kostenlos und digitalisieren Sie Ihre Prozesse.',
        },
        benefits: {
          headline: 'Darum empto',
          bodytext:
            'Mit empto kann jedes Entsorgungsunternehmen die Prozesse planen und sämtliche Vorgänge' +
            ' digitalisieren. Um die Funktionen und Vorteile von empto kennenzulernen, können Sie eine Demo ' +
            'anfragen. Einfach, unkompliziert und unverbindlich!',
          button: 'Demo anfragen',
        },
        registerCallToAction: {
          subline:
            'empto hilft Ihnen dabei neue Kunden zu gewinnen und einen Schritt in die digitale Zukunft ' +
            'zu gehen',
        },
      },
      wasteProducer: {
        benefits: {
          headline: 'empto Demo',
          bodytext:
            'Möchten Sie empto kennenlernen? In der Demo haben Sie die Möglichkeit, von einem unserer ' +
            'Mitarbeiter Schritt für Schritt über die Funktionen von empto aufgeklärt zu werden. Klicken Sie dafür ' +
            'auf das Feld „Demo anfragen“, füllen Sie das folgende Formular aus, und wir melden uns ' +
            'schnellstmöglich bei Ihnen.',
          requestDemo: 'Demo anfragen',
          buttonText: 'Ist empto auch in meiner Nähe? Sehen Sie nach!',
          comparability: {
            headline: 'Vergleichbarkeit',
            paragraph:
              'Mit empto erhalten Sie Angebote mehrerer Entsorger. Dank Angebotsvergleich und qualifizierter' +
              ' Bewertungen wählen Sie den für Ihre Bedürfnisse besten Partner.',
          },
          customerService: {
            headline: 'Exzellenter Kundenservice',
            paragraph:
              'Unser freundliches und kompetentes Service-Team unterstützt Sie gerne von Mo-Fr ' +
              'zwischen 8-18 Uhr bei Fragen zu empto. Sie erreichen uns unter der kostenlosen Telefonnummer ' +
              `<a href='tel:${phoneValue}'>${phoneString}</a>`,
          },
          transparency: {
            headline: 'Transparente Prozesse',
            paragraph:
              'Von der Erstellung der Anfrage über die Auftragsabwicklung bis zur Abrechnung - mit empto' +
              ' behalten Sie stets den Überblick!',
          },
          legalSecurity: {
            headline: 'Rechtssicherheit',
            paragraph:
              'empto arbeitet nur mit zertifizierten Entsorgern - alle relevanten Nachweise können Sie' +
              ' jederzeit online abrufen.',
          },
          startDirectly: {
            headline: 'Direkt loslegen!',
            paragraph:
              'Mit dem empto <a href="/abfallmanager">Abfallmanager</a> können Sie innerhalb weniger ' +
              'Minuten alle Ihre bestehenden Entsorger in empto integrieren und sämtliche Entsorgungsvorgänge' +
              ' bequem digital beauftragen.',
          },
          warrantyService: {
            headline: 'Garantierte Leistung',
            paragraph:
              'Der von Ihnen ausgewählte Entsorger kann vorübergehend oder dauerhaft nicht leisten? Kein ' +
              'Problem! Mit einem Klick kümmert sich empto umgehend um einen qualifizierten Ersatz.',
          },
        },
        confidence: {
          headline: 'Wir wissen, wie der Markt funktioniert.',
          paragraph:
            '<b>empto</b> ist ein Produkt der <a href="https://www.zentek.de/">' +
            'Zentek Services GmbH & Co. KG</a>. Seit 1995 agieren wir auf dem Markt für bundesweite ' +
            'Entsorgungsdienstleistungen - rechtssicher, zuverlässig und erfolgreich!',
        },
        knownFrom: {
          headline: 'Bekannt aus',
          logos: {
            '320grad': '320 Grad',
            deutschesHandwerksblatt: 'Deutsches Handwerksblatt',
            handwerksblattDe: 'handwerksblatt.de',
            recyclingMagazin: 'Recycling Magazin',
          },
        },
        registerCallToAction: {
          headline: 'Jetzt kostenlos registrieren',
          button: 'Konto erstellen',
        },
        registerCallToActionStartpage: {
          headline: 'Registrieren Sie sich jetzt!',
          subline:
            'empto hilft Ihnen dabei Ihren Abfall fachgerecht zu entsorgen.',
          button: 'Jetzt Konto erstellen',
        },
        whatIsEmpto: {
          button: 'Jetzt registrieren',
          headline: 'Kennen Sie das?',
          subline:
            'Der Abfall muss entsorgt werden? Am besten einfach, rechtssicher und zu fairen Preisen?',
          text:
            'empto macht´s möglich! Erhalten Sie individuelle Angebote von qualifizierten Entsorgern auf Ihre ' +
            'Anfragen. empto unterstützt Sie während der gesamten Abwicklung - von der Anmeldung über die ' +
            'Rechnungsstellung bis hin zur Dokumentation sämtlicher Belege. Alle Vorgänge sind jederzeit online ' +
            'abrufbar! empto bietet Ihnen flexible Entsorgungsmöglichkeiten. Egal ob Sie einmalig einen Behälter ' +
            'benötigen oder die regelmäßige Entsorgung Ihrer Abfälle wünschen - wir richten uns nach Ihren ' +
            'Bedürfnissen. Sie haben bereits bestehende Entsorgungspartner? Kein Problem! empto ermöglicht Ihnen die ' +
            'schnelle und kostenlose Integration Ihrer bestehenden Entsorgungsvereinbarungen.',
          registerText:
            'Registrieren Sie sich jetzt kostenlos und  digitalisieren Sie ihre Abfallentsorgung.',
          videoDescription: 'Video über empto',
          videoPosterAlt:
            'Zum Abspielen dieses Videos benötigen wir Ihre Erlaubnis für die Verwendung von Cookies.',
        },
        zipCode: {
          averageCosts: 'Durchschnittliche Kosten',
          description1: 'Holen Sie sich Ihren Entsorgungsexperten ins Team.',
          description2:
            'Abfallentsorgung schnell und einfach handhaben, ohne lästigen Papierkram, zeitsparend und ' +
            'übersichtlich. Mit empto verwalten Sie Ihre gesamten Entsorgungsvorgänge online, jederzeit und von ' +
            'überall.',
          disposableFractions: 'Entsorgbare Abfall-Fraktionen',
          emptyNotActiveInRegion:
            'Leider gibt es für dieses Postleitzahlengebiet noch keine Entsorger.',
          getOffers: 'Angebote einholen',
          headline: 'empto - Ihr digitaler Abfallassistent',
          insertZipCode: 'Postleitzahl eingeben',
          isInactiveZipCode:
            'Leider sind wir in diesem Postleitzahlengebiet noch nicht tätig.',
          isInvalidZipCode: 'Die eingegebene Postleitzahl ist leider ungültig.',
          insertValidZipCode: 'Bitte geben Sie eine gültige Postleitzahl an.',
          newSearch: 'Neue Suchanfrage',
          offerResults:
            'empto hat passende Angebote von Entsorgern im Gebiet "%{code}" gefunden.',
          offerSearch: 'Wir suchen nach Angeboten in der Region %{code}',
          noOfferResults:
            'Leider ist empto in Ihrem Gebiet noch nicht operativ tätig.<br>Unsere Alternativen:',
          searchInputLabel: 'Postleitzahl eingeben',
          subHeadline: 'Jetzt unkompliziert Abfall abholen lassen.',
          tooltip:
            'Der angezeigte Preis gilt für die Abfallart Bauschutt in einem 7 m³ Absetzbehälter (Gesamtgewicht' +
            ' 7 Tonnen)',
          wasteDisposersInRegion: 'Entsorger in Ihrem Gebiet',
          emptoWastemanager: {
            title: 'Abfallmanager',
            text:
              'Verwalten Sie auch externe Entsorgungsvereinbarungen über den empto Abfallmanager und ' +
              'organisieren Sie alle Ihre Aufträge – digital und übersichtlich an einem Ort.',
            buttonText: 'Abfallmanager entdecken',
          },
          containerdienst: {
            text:
              'Bestellen Sie jetzt Ihren gewünschten Container über containerdienst.de und vereinbaren ' +
              'Sie eine Stellung für den nächsten Werktag - schnell und einfach!',
            buttonText: 'Zum Containerdienst',
          },
          regionMap: 'Karte der Pilotregion',
          regionMapAlternativeText:
            'Aachen, Bergisch Gladbach, Duisburg, Düren, Düsseldorf, Erftkreis, Essen,' +
            'Kleve, Gelsenkirchen, Heinsberg, Köln, Krefeld, Leverkusen, Mettmann, Mönchengladbach, Mühlheim,' +
            'Neuss, Wesel, Viersen, Münster, Berlin, Brandenburg, Frankfurt, München',
        },
        sealBar: {
          altTexts: {
            ssl: 'SSL Datensicherheit Siegel',
            din90001: 'DIN 900001:2015 Siegel',
            din50001: 'DIN 500001:2001 Siegel',
            disposalCompany: 'Entsorgungs Fachbetrieb Siegel',
          },
        },
      },
      requestDemo: requestDemoTranslations,
    },
    constant: {
      gender: {
        mr: 'Herr',
        mrs: 'Frau',
      },
      authorization: {
        admin: 'Administrator',
        staff: 'Mitarbeiter',
      },
      status: {
        active: 'Aktiv',
        expired: 'Abgelaufen',
        inactive: 'Inaktiv',
        inactiveByEmpto: 'Inaktiv durch empto',
        inactiveByEpd: 'Gesperrt durch EPD',
        paused: 'Pausiert durch EPD',
        invited: 'Eingeladen',
        open: 'Offen',
        valid: 'Gültig',
        cancelled: 'Storniert',
        cancelledByEmpto: 'Storniert durch empto',
        cancelledByEPD: 'Storniert durch EPD',
        commitment: 'Zusage',
        refusal: 'Absage',
        closed: 'Abgeschlossen (ohne Vergabe)',
        concluded: 'Abgeschlossen (mit Vergabe)',
        locked: 'Gesperrt',
        accepted: 'Akzeptiert',
        rejected: 'Reklamiert',
        pending: 'In Prüfung',
        createdByEmpto: 'Erstellt durch empto',
      },
      date: {
        interval: {
          twiceWeek: '2x pro Woche',
          weekly: 'Wöchentlich',
          fortnightly: '14-tägig',
          monthly: '4 Wochen',
          sixWeeks: '6 Wochen',
          eightWeeks: '8 Wochen',
          hours0: '-',
          hours24: '24 Stunden (Mo-Fr)',
          hours48: '48 Stunden (Mo-Fr)',
          hours120: '5 Werktage (Mo-Fr)',
        },
        timeOfDay: {
          morning: 'vormittags',
          afternoon: 'nachmittags',
          allDay: 'egal',
        },
        months: {
          January: 'Januar %{year}',
          February: 'Februar %{year}',
          March: 'März %{year}',
          April: 'April %{year}',
          May: 'Mai %{year}',
          June: 'Juni %{year}',
          July: 'Juli %{year}',
          August: 'August %{year}',
          September: 'September %{year}',
          October: 'Oktober %{year}',
          November: 'November %{year}',
          December: 'Dezember %{year}',
        },
        weekdays: {
          monday: 'Montag',
          tuesday: 'Dienstag',
          wednesday: 'Mittwoch',
          thursday: 'Donnerstag',
          friday: 'Freitag',
          saturday: 'Samstag',
          sunday: 'Sonntag',
        },
        month: 'Monat',
        today: 'Heute',
      },
      groups: {
        admin: 'Administrator',
      },
      orderStatus: {
        AEZ_ORDERED: 'Gewerbetreibender/Makler hat den Auftrag erteilt',
        DISPOSER_WILL_EXECUTE: 'Entsorger wird den Auftrag ausführen',
        DISPOSER_DID_NOT_DISPOSE:
          'Entsorger hat den Auftrag noch nicht disponiert.',
        DISPOSER_DID_DISPOSE:
          'Entsorger wird den Auftrag innerhalb der Leistungsfrist ausführen.',
        DISPOSER_DID_DISPOSE_WITH_DATE:
          'Entsorger hat den Auftrag auf ein Datum disponiert.',
        POSTPONE_WEEKDAY: 'Wochentag geändert',
        POSTPONE_WEEKDAY_SECOND: 'Zweiter Wochentag geändert',
        DISPOSER_DID_POSTPONE_DELIVERY: 'Leistungsdatum verschoben',
        DISPOSER_DID_POSTPONE_COLLECTION: 'Leistungsdatum verschoben',
        DISPOSER_CHANGED: 'Entsorger gewechselt.',
        DISPOSER_WILL_ARRIVE_DELIVERY:
          'Entsorger hat die geplante Ankunftszeit gesetzt.',
        DISPOSER_WILL_ARRIVE_DELIVERY_ONE_TIME:
          'Entsorger hat die geplante Ankunftszeit der Gestellung gesetzt.',
        DISPOSER_WILL_ARRIVE_COLLECTION:
          'Entsorger hat die geplante Ankunftszeit der Abholung gesetzt.',
        DISPOSER_DID_EXECUTE_DELIVERY:
          'Entsorger hat die Auftragsdurchführung gemeldet.',
        DISPOSER_DID_EXECUTE_DELIVERY_ONE_TIME:
          'Entsorger hat die Gestellung gemeldet.',
        DISPOSER_DID_EXECUTE_COLLECTION: 'Entsorger hat die Abholung gemeldet.',
        DISPOSER_SUBMITTED_EXECUTION_PROOFS:
          'Entsorger hat die Leistungsbelege zur Prüfung übermittelt.',
        EXECUTION_PROOFS_PENDING: 'Prüfung der Nachweise durch empto',
        EXECUTION_PROOFS_REJECTED: 'Nachweise wurden von empto reklamiert',
        EXECUTION_PROOFS_REJECTED_PRODUCER:
          'Nachweise wurden von empto reklamiert',
        EXECUTION_PROOFS_ACCEPTED: 'Nachweise wurden von empto akzeptiert',
        AEZ_RATED_DISPOSER: 'Gewerbetreibender hat Entsorger bewertet',
        DISPOSER_RATED_PRODUCER: 'Entsorger hat Gewerbetreibenden bewertet',
        AEZ_CANCELLED: 'Gewerbetreibender hat den Auftrag storniert',
        COMPLAINED_ORDER_PRODUCER:
          'Gewerbetreibender hat den Auftrag reklamiert',
        COMPLAINED_MISSING_EXECUTION_REPORT_EMPTO:
          'empto reklamiert unterlassene Meldung zur Durchführung',
        COMPLAINED_MISSING_EXECUTION_REPORT_PRODUCER:
          'Gewerbetreibender hat die Bestätigung der Durchführung ' +
          'reklamiert',
        COMPLAINED_MISSING_EXECUTION_PROOF_EMPTO:
          'empto reklamiert fehlende Nachweise',
        COMPLAINED_BILLING_PRODUCER:
          'Gewerbetreibender hat die Abrechnung reklamiert',
        COMPLAINED_BILLING_DISPOSER: 'Entsorger hat die Abrechnung reklamiert',
        CANCELLED_EMPTO: 'empto hat den Auftrag storniert',
        CANCELLED_EPD: 'EPD hat den Auftrag storniert',
        EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE:
          'empto hat eine gemeldete Umdeklarierung akzeptiert.',
        INVOICING_DOCUMENTS_CREATED: 'Abrechnungsbelege wurden erzeugt.',
        OTHER_INVOICING_DOCUMENTS_CREATED: 'Entsorgerbeleg wurde abgerechnet.',
        CLOSED: 'Auftrag abgeschlossen',
        OTHER: 'Sonstiges',
      },
      orderStatusMessage: {
        AEZ_ORDERED: '<b>%{aez}</b> hat den Auftrag erteilt.',
        DISPOSER_WILL_EXECUTE:
          '<b>%{disposer}</b> wird den Auftrag am <b>%{deliveryDate}</b> durchführen.',
        DISPOSER_WILL_EXECUTE_QUICK:
          '<b>%{disposer}</b> wird die Gestellung am <b>%{deliveryDate}</b> durchführen.',
        DISPOSER_WILL_EXECUTE_ONE_TIME:
          '<b>%{disposer}</b> wird die Gestellung am <b>%{deliveryDate}</b> und die ' +
          'Abholung am <b>%{collectionDate}</b> durchführen.',
        DISPOSER_WILL_EXECUTE_REGULAR:
          '<b>%{disposer}</b> wird den Auftrag am <b>%{deliveryDate}</b> durchführen.',
        DISPOSER_DID_NOT_DISPOSE:
          '<b>%{disposer}</b> hat den Auftrag noch nicht disponiert.',
        DISPOSER_DID_DISPOSE:
          '<b>%{disposer}</b> wird den Auftrag am <b>%{deliveryDateDisposed}</b> ausführen.',
        DISPOSER_DID_DISPOSE_WITH_DATE:
          '<b>%{disposer}</b> hat den Auftrag auf den <b>%{deliveryDateDisposed}</b>' +
          ' disponiert.',
        POSTPONE_WEEKDAY:
          '<b>Wochentag auf %{interval_weekday_first} geändert</b>',
        POSTPONE_WEEKDAY_SECOND:
          '<b>Zweiter Wochentag auf %{interval_weekday_second} geändert</b>',
        DISPOSER_DID_POSTPONE_DELIVERY:
          '<b>%{disposer}</b> hat den Auftrag verschoben. Das neue Leistungsdatum ist ' +
          '<b>%{deliveryDatePostpone}</b>.',
        DISPOSER_DID_POSTPONE_DELIVERY_ONE_TIME:
          '<b>%{disposer}</b> hat den Auftrag verschoben. Die neue Gestellung ' +
          'ist am <b>%{deliveryDatePostpone}</b>.',
        DISPOSER_DID_POSTPONE_COLLECTION:
          '<b>%{disposer}</b> hat den Auftrag verschoben. Die neue Abholung ist am ' +
          '<b>%{collectionDatePostpone}</b>.',
        DISPOSER_CHANGED:
          '<b>Entsorgerwechsel:</b> Der Auftrag wird von <b>%{newDisposerName}</b> durchgeführt. ' +
          'Der Wechsel vom ursprünglichen Entsorger %{previousDisposerName} wurde von %{name} vorgenommen.',
        DISPOSER_WILL_ARRIVE_DELIVERY:
          'Die geplante Ankunftszeit des Fahrzeugs ist um ' +
          '<b><nobr>%{etaDelivery} Uhr</nobr></b>.',
        DISPOSER_WILL_ARRIVE_DELIVERY_ONE_TIME:
          'Die geplante Ankunftszeit des Fahrzeugs für die Gestellung ist um ' +
          '<b><nobr>%{etaDelivery} Uhr</nobr></b>.',
        DISPOSER_WILL_ARRIVE_COLLECTION:
          'Die geplante Ankunftszeit des Fahrzeugs für die Abholung ist um ' +
          '<b><nobr>%{etaCollection} Uhr</nobr></b>.',
        DISPOSER_DID_EXECUTE_DELIVERY:
          '<b>%{disposer}</b> hat die Auftragsdurchführung für den ' +
          '<b>%{deliveryDateExecuted}</b> gemeldet. empto wartet auf Leistungsnachweise.',
        DISPOSER_DID_EXECUTE_DELIVERY_ONE_TIME:
          '<b>%{disposer}</b> hat die Gestellung ' +
          'für den <b>%{deliveryDateExecuted}</b> gemeldet.',
        DISPOSER_DID_EXECUTE_COLLECTION:
          '<b>%{disposer}</b> hat die Auftragsdurchführung der Abholung ' +
          'für den <b>%{collectionDateExecuted}</b> gemeldet. empto wartet auf Leistungsnachweise.',
        DISPOSER_SUBMITTED_EXECUTION_PROOFS:
          '<b>%{disposer}</b> hat die Leistungsbelege zur Prüfung übermittelt.',
        EXECUTION_PROOFS_PENDING: 'Prüfung der Nachweise durch empto.',
        EXECUTION_PROOFS_REJECTED:
          'Nachweise wurden von empto reklamiert. ' +
          'Bitte stellen Sie neue Nachweise zur Verfügung.',
        EXECUTION_PROOFS_REJECTED_PRODUCER:
          'Nachweise wurden von empto reklamiert. ' +
          'empto wartet auf neue Leistungsnachweise.',
        EXECUTION_PROOFS_ACCEPTED:
          'Nachweise wurden von empto akzeptiert. Die Abrechnungsbelege werden erzeugt.',
        AEZ_RATED_DISPOSER: '<b>%{aez}</b> hat <b>%{disposer}</b> bewertet.',
        DISPOSER_RATED_PRODUCER:
          '<b>%{disposer}</b> hat <b>%{aez}</b> bewertet.',
        AEZ_CANCELLED: '<b>%{aez}</b> hat den Auftrag storniert.',
        COMPLAINED_ORDER_PRODUCER: '<b>%{aez}</b> hat den Auftrag reklamiert.',
        COMPLAINED_MISSING_EXECUTION_REPORT_EMPTO:
          'empto reklamiert unterlassene Meldung zur Durchführung.',
        COMPLAINED_MISSING_EXECUTION_REPORT_PRODUCER:
          '<b>%{aez}</b> hat die Bestätigung der Durchführung reklamiert.',
        COMPLAINED_MISSING_EXECUTION_PROOF_EMPTO:
          'empto reklamiert fehlende Nachweise',
        COMPLAINED_BILLING_PRODUCER:
          '<b>%{aez}</b> hat die Abrechnung reklamiert.',
        COMPLAINED_BILLING_DISPOSER:
          '<b>%{disposer}</b> hat die Abrechnung reklamiert.',
        CANCELLED_EMPTO: 'empto hat den Auftrag storniert',
        CANCELLED_EPD:
          'Der Auftrag wurde mit folgender Begründung storniert: <b>%{cancellation_reason}</b>.%{cancellation_reason_text}',
        EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE:
          '<b>empto</b> hat eine gemeldete Umdeklarierung akzeptiert.',
        EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE_RELABELED:
          '<b>empto</b> hat eine gemeldete <b>Umdeklarierung</b> akzeptiert.',
        EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE_CONTAINER_CHANGED:
          '<b>empto</b> hat eine gemeldete <b>Behälteränderung</b> akzeptiert.',
        EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE_CONTAINER_AMOUNT_CHANGED:
          '<b>empto</b> hat eine gemeldete <b>Änderung der Behälteranzahl</b> akzeptiert.',
        EMPTO_DID_ACCEPT_NOT_MATCHED_EXECUTION_REVIEW_WASTE_ADDITIONAL_RENT:
          '<b>empto</b> hat eine gemeldete <b>Stellzeitänderung</b> akzeptiert.',
        INVOICING_DOCUMENTS_CREATED: 'Abrechnungsbelege wurden erzeugt.',
        MAKLERPREMIUM_INVOICING_DOCUMENTS_CREATED:
          'Abrechnungsbelege wurden erzeugt.<br />Partner: <a' +
          ' href="/company/%{invoice_check_partner_id}/">%{invoice_check_partner_name}</a><br /> Belegnummer: <a' +
          ' href="/maklerpremium/invoice_check/overview/%{invoice_check_id}">%{invoice_check_document_number}</a>',
        MAKLERPREMIUM_INVOICING_DOCUMENTS_CREATED_OTHER_SERVICES:
          'Entsorgerbeleg mit %{invoice_check_category} wurde abgerechnet.<br />Partner: <a' +
          ' href="/company/%{invoice_check_partner_id}/">%{invoice_check_partner_name}</a><br /> Belegnummer: <a' +
          ' href="/maklerpremium/invoice_check/overview/%{invoice_check_id}">%{invoice_check_document_number}</a>',
        CLOSED: 'Auftrag abgeschlossen',
        OTHER: 'Sonstiger Status',
      },
      paymentMethod: {
        invoice: 'Zahlung per Rechnung',
        epd_invoice: 'Auf Rechnung',
        epd_micropayment: 'Kreditkarte',
        epd_inAdvance: 'Vorkasse',
        epd_ratepay: 'RatePAY',
        epd_immediateBankTransfer: 'Sofortüberweisung',
        epd_paypal: 'PayPal',
        epd_elv: 'Lastschrift',
        epd_stripe_paypal: 'Stripe: PayPal',
        epd_stripe_card: 'Stripe: Kreditkarte',
      },
      offerCancellation: {
        label: 'Begründung',
        placeholder: 'Bitte wählen Sie eine Begründung aus',
        over: 'Hat sich erledigt',
        clientUnreachable: 'Kunde nicht erreichbar',
        clientHasBetterOffer: 'Kunde hat besseres Angebot vorliegen',
        other: 'Sonstiges',
        otherTextLabel: 'Sonstiges (Begründung)',
        otherTextPlaceholder:
          'Bitte geben Sie hier an, aus welchem Grund das Angebot storniert werden soll',
        otherTextAgreementPlaceholder:
          'Bitte geben Sie hier an, aus welchem Grund die Vereinbarung' +
          ' storniert werden soll',
      },
      orderCancellation: {
        customerChangedMind: 'Kunde hat es sich anders überlegt',
        duplicateOrder: 'Doppelte Bestellung',
        disposerDidNotDispose: 'Entsorger hat Auftrag nicht durchgeführt',
        other: 'Sonstiges',
        otherTextPlaceholder:
          'Bitte geben Sie hier an, aus welchem Grund der Auftrag storniert werden soll',
      },
      collectiveInvoicingMode: {
        perClient: 'pro Kunde',
        perAddress: 'pro Leistungsaddresse',
      },
    },
    message: {
      validation: {
        date: 'Bitte geben Sie ein gültiges Datum ein.',
        validDecimal: 'Der mögliche Rabattwert liegt zwischen 1,00 und 99,99.',
        isPhoneNumber: 'Bitte geben Sie eine gültige Telefonnummer ein.',
        email: {
          isEmail: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
          isUnique: 'E-Mail-Adresse existiert bereits.',
          required: 'Bitte geben Sie Ihre E-Mail Adresse ein.',
          isEmpty: 'Bitte hinterlegen Sie eine gültige E-Mail-Adresse.',
        },
        password: {
          confirmationRequired: 'Bitte bestätigen Sie Ihr Passwort.',
          hidePassword: 'Passwort verstecken',
          passwordIsEqual: 'Die Passwörter müssen übereinstimmen.',
          passwordIsSafe:
            'Mindestens 14 Zeichen: Groß- sowie Kleinbuchstaben, Ziffern und Sonderzeichen.',
          required: 'Bitte geben Sie Ihr Passwort ein.',
          showPassword: 'Passwort anzeigen',
          passwordHint:
            'Mindestens 14 Zeichen: Groß- sowie Kleinbuchstaben, Ziffern und Sonderzeichen.',
          tooWeak: 'Dieses Passwort ist zu üblich.',
        },
        required: 'Dieses Feld muss befüllt sein.',
        notZero: 'Dieses Feld darf nicht 0 sein.',
        check: 'Dieses Feld muss bestätigt werden.',
        selectionRequired: 'Bitte wählen Sie eine Option.',
        missingContent:
          'Textfeld darf nicht leer sein oder ausschließlich Leerzeichen enthalten.',
        invalidSelection: 'Bitte wählen Sie eine gültige Option aus.',
        wrongRegex: 'Falsches Format.',
        role: {
          isRequired: 'Bitte wählen Sie eine Rolle aus.',
        },
        policy: {
          isChecked:
            'Bitte bestätigen Sie die Datenschutzerklärung und Teilnahmebedingungen.',
          isCheckedPrivacy: 'Bitte bestätigen Sie die Datenschutzerklärung.',
        },
        germanTaxId: 'Bitte geben Sie eine deutsche Umsatzsteuer-ID ein.',
        germanTaxNumber: 'Bitte geben Sie eine deutsche Steuernummer ein.',
        formFields: {
          // the name must have this format, since they need to be the same as the returned error name variable
          avv: 'AVV Nummer',
          collection_date: 'Abholdatum',
          container: 'Behälter',
          delivery_date: 'Lieferdatum',
          discount: 'Abschlag',
          disposal_cost_container: 'Transport- und Entsorgungspreis',
          disposal_cost_ton: 'Entsorgungskosten',
          fillThese: 'Bitte überprüfen Sie:',
          fraction: 'Abfallart',
          handle_cost_ton: 'Handlingskosten',
          index: 'Index',
          interval: 'Intervall',
          interval_weekday_first: 'Wochentag',
          interval_weekday_second: 'Zweiter Wochentag',
          rent_price_container_month: 'Mietpreis',
          surcharge: 'Zuschlag',
          transport_price_piece: 'Transportpreis',
          turn_begin: 'Turnusbeginn',
          turn_begin_agreement: 'Vereinbarungsbeginn',
          turn_end: 'Turnusende',
          turn_end_agreement: 'Vereinbarungsende',
          company: 'Unternehmen',
          phone_user: 'Benutzer',
          security_group: 'Schutzklasse und Sicherheitsstufe',
        },
      },
      info: {
        sessionExpired: 'Ihre Session ist abgelaufen.',
      },
      error: {
        networkError:
          'Es ist ein Netzwerkfehler aufgetreten. Bitte versuchen Sie es später erneut.',
      },
      unknownErrorDevelopersNotified:
        'Ein unbekannter Fehler ist aufgetreten. Wir wurden über diesen Fehler ' +
        'automatisiert benachrichtigt und werden ihn versuchen so schnell wie möglich zu beheben.',
    },
    pagination: {
      paginationInfo: 'Seite %{page} von %{pages}',
    },
    containerType: {
      abrollcontainer: 'Abrollcontainer',
      absetzcontainer: 'Absetzcontainer',
      big_bag: 'Big Bag',
      presscontainer: 'Presscontainer',
      umleerbehaelter: 'Umleerbehälter',
      abfalltonne: 'Abfalltonne',
      aktenbehaelter: 'Aktenbehälter',
      kunststoffdeckelfass: 'Kunststoffdeckelfass',
      metallfass: 'Metallfass',
      sonderabfallbehaelter: 'Sonderabfallbehälter',
      gitterbox: 'Gitterbox',
      kunststoffbox: 'Kunststoffbox',
      anderer_container:
        'Anderer Container (bitte bei den Hinweisen genauer bestimmen)',
    },
    containerSizeUnit: {
      cbm: 'm³',
      liter: 'Liter',
    },
    orderType: {
      oneTime: 'Einmalauftrag',
      regular: 'Dauerauftrag im Turnus',
      onDemand: 'Dauerauftrag auf Abruf',
      quick: 'Sofortbefüllung',
    },
    login: {
      title: 'Anmeldung',
      forgotPassword: 'Haben Sie Ihr Passwort vergessen?',
      noAccount: 'Sie haben noch kein Konto?',
      register: 'Kostenlos registrieren',
      stayLogged: 'Angemeldet bleiben',
    },
    forgetPassword: {
      title: 'Passwort vergessen',
      resetPassword: 'Passwort zurücksetzen',
      confirmPassword: 'Bestätige das Passwort',
      emailSentMessage:
        'Wir haben Ihnen eine E-Mail mit einer Anleitung geschickt,' +
        ' wie Sie Ihr Kennwort zurücksetzen können.',
      emailSentTitle: 'Sie haben Post!',
      PasswordResetSuccess:
        'Du kannst dich nun mit deinem neuen Passwort anmelden.',
    },
    menu: {
      inquiries: 'Anfragen',
      openInquiries: 'Offene Anfragen',
      myInquiries: 'Meine Anfragen',
      newInquiry: 'Neue Anfrage',
      myOffers: 'Meine Angebote',
      agreement: 'Vereinbarungen',
      execution: 'Durchführungen',
      dashboard: 'Dashboard',
      orders: 'Aufträge',
      myOrders: 'Meine Aufträge',
      forProducers: 'Für Gewerbetreibende',
      forPrivateCustomers: 'Für Privatkunden',
      forDisposers: 'Für Entsorger',
      register: 'Registrieren',
      login: 'Anmelden',
      wasteType: 'Abfallarten',
      wasteManager: 'Abfallmanager',
      downloads: 'Downloads',
    },
    homepage: {
      fractionSelectorDefault: 'Abfall',
      locationSelectorForm: {
        zipcode: 'Postleitzahl des Abholortes',
        required: 'Bitte geben Sie eine Postleitzahl ein.',
        invalid: 'Bitte geben Sie eine gültige Postleitzahl ein.',
      },
      locationSelectorDefault: 'Ort',
      hero: {
        interactiveHeadline: {
          start: 'Ich möchte',
          middle: 'in',
          end: 'abholen lassen.',
        },
        button: 'Anfrage stellen',
      },
      steps: {
        headline: 'ist ein Marktplatz zur einfachen Abfall-Entsorgung:',
        step1: 'Einfach gute Angebote einholen & beauftragen',
        step2: 'Einfach die garantierte Abholung erleben',
        step3: 'Einfach die korrekte Abrechnung erhalten',
      },
    },
    youTubePlayer: {
      cookiesNotAcceptedHint:
        'Zum Abspielen dieses Videos benötigen wir Ihre Erlaubnis für die Verwendung von ' +
        'Cookies.',
    },
    footer: {
      imprint: 'Impressum',
      privacy: 'Datenschutz',
      legalHint: 'Rechtliche Hinweise',
    },
    user: {
      toastr: {
        getDetailsError: 'Benutzerdetails nicht erhalten.',
        updateSuccess: 'Benutzer wurde erfolgreich gespeichert.',
        updateError: 'Speichern des Benutzers fehlgeschlagen.',
        passwordChangeSuccess: 'Passwort wurde erfolgreich geändert.',
        passwordChangeError: 'Passwort ändern fehlgeschlagen.',
        passwordSetSuccess: 'Passwort wurde erfolgreich gesichert.',
        passwordSetError: 'Passwort nicht gespeichert.',
      },
      invalidPassword: 'Falsches Passwort',
      changePassword: 'Passwort ändern',
      oldPassword: 'Aktuelles Passwort',
      password: 'Neues Passwort',
      passwordRepeat: 'Neues Passwort wiederholen',
      setPassword: 'Passwort',
      setPasswordRepeat: 'Passwort wiederholen',
      placeholder: {
        oldPassword: 'Geben Sie Ihr aktuelles Passwort ein.',
        password: 'Geben Sie ein neues Passwort ein.',
        passwordRepeat: 'Wiederholen Sie das neue Passwort.',
      },
      allLocations: 'Alle Standorte',
    },
    userOverview: {
      heading: 'Benutzerverwaltung',
      userExportModalTitle: 'Benutzer CSV Export',
      inviteButton: 'Benutzer einladen',
      addButton: 'Ansprechpartner anlegen',
      contactPersonInfoModal: {
        message: {
          start:
            'Dieser Benutzer wird aktuell als Ansprechpartner verwendet. Um diesen Benutzer zu sperren, ' +
            'ändern Sie bitte den Ansprechpartner in Ihren ',
          link: 'Stammdaten. ',
          end: 'Vielen Dank.',
        },
      },
    },
    contactPersonOverview: {
      heading: 'Ansprechpartner',
      addButton: 'Ansprechpartner anlegen',
    },
    userRegistrationForm: {
      done: {
        emailSentTitle: 'Registrierung erfolgreich!',
        emailSentMessage:
          'Wir haben Ihnen eine Bestätigung per E-Mail geschickt. Bitte klicken Sie auf den Link' +
          ' in dieser E-Mail, um Ihr Konto freizuschalten.',
        goBack: 'Zum Login',
      },
      activationFeedback: {
        success: {
          title: 'Aktivierung erfolgreich!',
          message: 'Sie können sich jetzt mit Ihrem Konto anmelden.',
          button: ' Zum Login',
        },
        error: {
          title: 'Aktivierungsfehler!',
          message: 'Der angegebene Link ist nicht gültig.',
          button: ' Zurück zur Registrierung',
        },
        password: {
          title: 'Herzlich Willkommen!',
          message:
            'Bitte vergeben Sie ein persönliches Passwort für die Anmeldung bei empto.',
          button: 'Registrierung abschließen',
        },
      },
    },
    userForm: {
      nav: {
        complete: 'Einladung senden',
      },
      placeholder: {
        preferredAddress: 'Standort wählen',
        groups: 'Berechtigungen wählen',
        phoneNumber: 'Telefonnummer',
      },
      toastr: {
        success: 'Einladung erfolgreich',
        error: 'Einladung fehlgeschlagen.',
      },
      header: {
        title: 'Neuen Benutzer einladen',
        titleEdit: 'Benutzer bearbeiten',
      },
      labels: {
        groups: 'Berechtigung',
      },
    },
    inquiry: {
      address: 'Leistungsadresse',
      addressDescription: 'Bezeichnung der Leistungsadresse',
      company: 'Unternehmen',
      container: 'Behälter',
      date: {
        agreementBegin: 'Vereinbarungsbeginn',
        agreementEnd: 'Vereinbarungsende',
        collectionDate: 'Abholdatum',
        deliveryDate: 'Lieferdatum',
        turnBegin: 'Turnusbeginn',
        turnEnd: 'Turnusende',
        reactionTime: {
          title: 'Reaktionszeit/Leistungsfrist ',
        },
      },
      fineFraction: 'Feinfraktion',
      fraction: 'Abfallart',
      numberOfContainers: 'Anzahl',
      numberOfContainersAbbr: 'Anz.',
      numberOfContainersMax: 'Maximale Anzahl',
      numberOfContainersMaxAbbr: 'Max. Anzahl',
      quantityInCubicMeters: 'Menge in m\u00B3',
      orderType: 'Auftragsart',
      phone_user: 'Benutzer',
      positionOnPublicGround: {
        accepted: 'Ja - Stellplatzgenehmigung ist vom Entsorger zu beantragen',
        acceptedByCustomer: 'Ja (durch Kunde)',
        acceptedByEPD: 'Ja',
        description:
          'Stellung auf öffentlichem Grund - Stellplatzgenehmigung ist vom ' +
          'Entsorger zu beantragen',
        short: 'Stellung auf öffentlichem Grund',
      },
      serviceInterval: 'Intervall',
      serviceIntervalSecondWeekday: 'Zweiter Wochentag',
      serviceIntervalWeekdays: 'Wochentage',
      serviceIntervalWeekdays_1: 'Wochentag',
      serviceIntervalWeekdays_2: 'Wochentage',
      withEstimatedTimeOfArrival: 'Mit ETA-Service (Estimated Time of Arrival)',
      withTop: 'Deckelausführung gewünscht',
    },
    addressForm: {
      header: {
        title: 'Neue Adresse anlegen',
        titleEdit: 'Adresse bearbeiten',
      },
      validation: {
        description: 'Ungültige oder zu große Bezeichnung der Adresse.',
        street: 'Bitte geben Sie eine Straße an.',
        houseNumber: 'Bitte geben Sie eine Hausnummer an.',
        zipcode: 'Bitte geben Sie eine Postleitzahl an.',
        validZipcode: 'Bitte geben Sie eine gültige Postleitzahl an.',
        activeZipcode:
          'Leider sind wir in diesem Postleitzahlengebiet ' +
          'noch nicht tätig. Gerne informieren wir Sie, sobald wir unseren Service auch in dieser Gegend anbieten.',
        activeZipcodeWastemanager:
          'Für dieses Postleitzahlengebiet können Sie aktuell noch keine ' +
          'Entsorgungsanfragen einstellen. Ihre bestehenden Entsorgungsvereinbarungen können Sie jedoch direkt in ' +
          'unserem Abfallmanager hinterlegen und Ihren Entsorgungspartner digital beauftragen. Gerne informieren wir ' +
          'Sie, sobald wir unseren vollständigen Service auch in der angefragten Region anbieten.',
        location: 'Bitte geben Sie den Ort an.',
        contactPerson: 'Bitte wählen Sie einen Ansprechpartner aus.',
      },
      informMeModal: {
        header: {
          title: 'Per E-Mail informieren.',
        },
        content:
          'Möchten Sie über die Aktivierung der PLZ per E-Mail informiert werden?',
        toastr: {
          success: 'Die Postleitzahl wurde erfolgreich gespeichert.',
        },
      },
    },
    inquiryForm: {
      addAddress: 'Neue Leistungsadresse hinzufügen',
      addEmailUser: 'Neuen Ansprechpartner anlegen',
      date: {
        errorReactionTime: 'Bitte Behälter auswählen',
      },
      addImageBtn: 'Bild zur Anfrage hochladen',
      submitInquiryBtn: 'Anfrage einstellen',
      toastr: {
        success: 'Anfrage wurde erfolgreich gespeichert.',
        error: 'Speichern der Anfrage fehlgeschlagen.',
      },
      placeholder: {
        address: 'Abholadresse',
        specialNote: 'Besondere Hinweise (Anfahrt, Stellplatz, etc)',
        contactPerson: 'Ansprechpartner',
        runtimeOfInquiry: 'Laufzeit der Anfrage',
      },
      addFractionModal: {
        header: {
          title: 'Bitte Abfallart auswählen',
        },
        details: {
          backToSelection: 'Abfallart ändern',
          confirmSelection: 'Abfallart auswählen',
        },
      },
      addContainerModal: {
        header: {
          title: 'Bitte Behälter auswählen',
          information:
            'Im nächsten Schritt werden Ihnen nähere Informationen zu dem gewählten Behälter angezeigt. ' +
            'Dort können Sie den Behälter entweder bestätigen oder sich für einen anderen Behälter entscheiden.',
          error: 'Bitte wählen Sie zunächst Ihre Abfallart',
        },
        details: {
          backToSelection: 'Behälter ändern',
          confirmSelection: 'Behälter auswählen',
        },
      },
      noInputResults: {
        noCompany: 'Zu Ihrer Suchanfrage wurde kein Unternehmen gefunden',
        noUsers: 'Zu Ihrer Suchanfrage wurde kein Benutzer gefunden',
        noAddresses:
          'Zu Ihrer Suchanfrage wurde keine Leistungsadresse gefunden',
        noProtectionClasses: 'Die Schutzklassen konnte nicht geladen werden.',
        noSecurityLevels: 'Die Sicherheitsstufen konnten nicht geladen werden.',
      },
      sortingCriteria: `${pdfLink}`,
      helpModal: {
        text: {
          address:
            '<p>Standort der Leistungsdurchführung. An dieser Adresse wird beispielsweise der Behälter ' +
            'aufgestellt oder getauscht.</p>' +
            '<p>Neue Leistungsadresse hinzufügen: Bitte achten Sie auf die korrekte Schreibweise der ' +
            'Adresse. Im Feld Bezeichnung der Adresse dürfen Sie den Namen des Standortes oder die Filialnummer ' +
            'dokumentieren.</p>',
          fraction:
            '<p> Bitte wählen Sie eine der angezeigten Abfallarten unter Berücksichtigung der ' +
            `${pdfLink}.` +
            'Ist Ihre Abfallart nicht dabei? Bitte rufen Sie uns einfach an unter ' +
            `<a href="tel:${phoneValue}">${phoneString}</a> oder ` +
            `schicken Sie eine kurze Mail an <a href="mailto:${email}">${email}</a></p>`,
          fineFraction:
            '<p> Mit der Bestimmung der Feinfraktion können Sie die Angebotserstellung für die ' +
            'Entsorgungsunternehmen vereinfachen, Rückfragen reduzieren und Ihren Preis optimieren. ' +
            'Unsicher mit der exakten Auswahl der Feinfraktion? ' +
            'Wir empfehlen Ihnen das Hochladen von Fotos Ihrer Abfälle.',
          container:
            '<p> Bitte wählen Sie den von Ihnen gewünschten Behälter. ' +
            'Unsicher bei der Auswahl des geeigneten Behälters? Bitte rufen Sie uns einfach an unter ' +
            `<a href="tel:${phoneValue}">${phoneString}</a> oder ` +
            `schicken Sie eine kurze Mail an <a href="mailto:${email}">${email}</a></p>`,
          numberOfContainer:
            '<p> Bitte wählen Sie die von Ihnen benötigte Stückzahl des Behälters. ' +
            'Unsicher bei der Auswahl der Stückzahl? Bitte rufen Sie uns einfach an unter ' +
            `<a href="tel:${phoneValue}">${phoneString}</a> oder ` +
            `schicken Sie eine kurze Mail an <a href="mailto:${email}">${email}</a></p>`,
          orderType:
            '<p><b>Einmalauftrag: </b>' +
            'Einmalige Aufstellung und Abholung eines Behälters inklusive Entsorgung bzw. Verwertung der Abfälle. ' +
            'Die maximale Mietdauer pro Behälter beträgt sechs (6) Wochen.</p>' +
            '<p><b>Dauerauftrag im Turnus: </b>' +
            'Längerfristige Miete eines oder mehrerer Behälter inklusive Entsorgung bzw. Verwertung der Abfälle. ' +
            'Der Tausch des Behälters bzw. der Behälter erfolgt im ausgewählten Intervall.</p>' +
            '<p><b>Dauerauftrag auf Abruf: </b> ' +
            'Längerfristige Miete eines oder mehrerer Behälter inklusive Entsorgung bzw. Verwertung der Abfälle. ' +
            'Der Tausch des Behälters bzw. der Behälter erfolgt im Bedarfsfall. ' +
            'Die marktübliche <u>Reaktionszeit</u> des Entsorgungsunternehmers wird angezeigt. ' +
            'Unter Reaktionszeit wird in diesem Kontext der Zeitraum zwischen Bestellung und Tausch bzw. ' +
            'Abholung des Behälters verstanden.</p>' +
            '<p><b>Sofortbefüllung: </b> ' +
            'Einmalige Anfahrt und Direktbeladung des Fahrzeuges mit den von Ihnen beauftragten Abfällen. ' +
            'Im Standard wird das Entsorgungsunternehmen so kalkulieren, ' +
            'dass Sie als Gewerbetreibender mit eigenem Personal das Fahrzeug beladen.</p>',
          positionOnPublicGround:
            '<p>Wenn der Behälter auf einer öffentlichen Fläche abgestellt werden soll, ' +
            'muss vorher eine entsprechende Stellplatzgenehmigung eingeholt werden. ' +
            'Diesen Service kann das Entsorgungsunternehmen übernehmen und direkt in der Angebotsgestaltung ' +
            'berücksichtigen. Bitte bedenken Sie bei der Wahl des Leistungsbeginns, ' +
            'dass die Einholung der Stellplatzgenehmigung etwas Zeit beansprucht.</p>' +
            '<p><b>Eine Gestellung auf privater Fläche ist selbstverständlich jederzeit möglich ' +
            'und nicht mit zusätzlichen Kosten verbunden.<b></p>',
          eta:
            '<p>Eine steigende Anzahl an Entsorgungsunternehmen ist in der Lage, ' +
            'die exakte Ankunftszeit ihrer Entsorgungsfahrzeuge an der ' +
            'Leistungsadresse automatisiert und elektronisch zu übermitteln. ' +
            'empto unterstützt die Anbindung dieser Systeme. ' +
            'An dieser Stelle können Sie Ihren Wunsch dokumentieren, ' +
            'dass Sie einen Dienstleister mit ETA-Service eventuell bevorzugen würden.</p>',
          contactPerson:
            '<p>In dieses Feld können Sie die vor Ort verantwortliche Person eintragen.</p>',
          runtimeOfInquiry:
            '<p>Abgabefrist für Entsorgungsunternehmen. Bitte berücksichtigen Sie, ' +
            'dass zwischen der Abgabefrist und dem von Ihnen gewünschten Leistungsbeginn ein zeitlicher Abstand von ' +
            'mindestens 48 Stunden liegen sollte. ' +
            'Eine kürzere Reaktionszeit ist für zahlreiche Entsorgungsunternehmens grundsätzlich möglich, ' +
            'jedoch nicht immer realisierbar.</p>',
          attachments:
            '<p>Hier können Sie bis zu drei (3) Dokumente hochladen. Verwenden Sie bitte nur übliche ' +
            'Formate für Fotos sowie PDF-Dateien für textliche Informationen. Die maximale Dateigröße beträgt 5 MB.' +
            '</p>',
          specialNote:
            'Bitte verwenden Sie dieses Textfeld für nützliche Informationen, die das ' +
            'Entsorgungsunternehmen dabei unterstützen, die von Ihnen gewünschte operative Abwicklung ohne ' +
            'Komplikationen durchführen zu können.',
        },
      },
      validation: {
        addressError: 'Bitte wählen Sie eine Adresse aus.',
        fractionError: 'Bitte wählen Sie eine Abfallart aus.',
        containerError: 'Bitte wählen Sie einen Behälter aus.',
        orderTypeError: 'Bitte wählen Sie eine Auftragsart aus.',
        deliveryDateError: 'Bitte geben Sie das Lieferdatum an.',
        collectionDateError: 'Bitte geben Sie das Abholdatum an.',
        agreementBeginError: 'Bitte geben Sie den Vereinbarungsbeginn an.',
        agreementEndError: 'Bitte geben Sie das Vereinbarungsende an.',
        securityGroupError:
          'Bitte wählen Sie eine Schutzklasse und Sicherheitsstufe aus.',
        turnBeginError: 'Bitte geben Sie den Turnusbeginn an.',
        turnEndError: 'Bitte geben Sie das Turnusende an.',
        intervalError: 'Bitte wählen Sie ein Intervall aus.',
        AgreementStartAfterEndDateError:
          'Vereinbarungsbeginn kann nicht nach Vereinbarungsende sein.',
        AgreementEndBeforeStartDateError:
          'Vereinbarungsende kann nicht vor Vereinbarungsbeginn sein.',
        TurnStartAfterEndDateError:
          'Turnusbeginn kann nicht nach Turnusende sein.',
        TurnEndBeforeStartDateError:
          'Turnusende kann nicht vor Turnusbeginn sein.',
        runtimeOfInquiry: 'Bitte wählen Sie die Laufzeit der Anfrage aus.',
        collectionAfterDelivery:
          'Das Abholdatum darf maximal 6 Wochen nach dem Lieferdatum liegen.',
        companyError: 'Bitte wählen Sie ein Unternehmen aus',
        phoneUserError: 'Bitte wählen Sie einen Kunden aus',
      },
    },
    inquiryFilter: {
      radius: 'Umkreis',
      markerFilterReset: 'Marker-Filter zurücksetzen',
      number: 'Anfragenummer',
      dateOptions: {
        thisWeek: 'Diese Woche',
        nextWeek: 'bis Ende nächster Woche',
      },
    },
    inquiryDetails: {
      toOffer: 'Zum Angebot',
      inquiryOf: 'Anfrage von %{name}',
      inquiryFor: 'Anfrage für %{name}',
      until: 'Bis %{date}',
      metaBlock: {
        title: 'Anfrage',
        until: 'Gültig bis %{date}',
      },
      address: {
        heading: 'Adressinformation',
      },
      date: {
        heading: 'Zeitliche Informationen',
      },
      company: {
        heading: '%{role}/Makler',
        name: 'Name',
        role: 'Rolle',
        createdAt: 'Mitglied seit',
        positiveReviews: 'Positive Bewertungen',
        inquiriesCount: 'Anzahl Anfragen Gesamt',
        offersCount: 'Anzahl Angebote Gesamt',
        inquiryNumberStatus: 'Anfragenummer, Status',
      },
      details: {
        heading: 'Details',
        inactiveAddressWarning:
          'Die Leistungsadresse dieser Anfrage ist nicht aktiv.',
      },
      banners: {
        canceled: '%{name} hat diese Anfrage am %{date} storniert',
        concluded:
          '%{name} hat diese Anfrage am %{date} abgeschlossen (mit Vergabe)',
        closed: 'Diese Anfrage wurde am %{date} abgeschlossen (ohne Vergabe)',
      },
      toastr: {
        error: 'Laden der Anfrage fehlgeschlagen.',
      },
      cancelInquiry: {
        button: 'Anfrage stornieren',
        reasonLabel: 'Begründung',
        info: 'Möchten Sie Ihre Anfrage wirklich stornieren? Sie haben aktuell %{numberOfOffers} %{offer}!',
        toastr: {
          success: 'Diese Anfrage wurde erfolgreich storniert.',
        },
      },
      showOffersBtn: 'Angebote ansehen',
    },
    myInquiries: {
      heading: 'Meine Anfragen',
      subtitle:
        'Unter diesem Menüpunkt finden Sie alle von Ihnen erstellten Anfragen. Dort können Sie neben der ' +
        'Kurzübersicht einsehen, wie der Status Ihrer Anfrage ist, wie viele Angebote Sie darauf erhalten haben, ' +
        'oder ob die Anfrage noch offen ist oder bereits von Ihnen vergeben wurde.',
    },
    myOffers: {
      heading: 'Meine Angebote',
      subtitle:
        'Hier finden Sie die von Ihnen erstellten Angebote. Dort können Sie neben der Kurzübersicht ' +
        'einsehen, ob Sie eine Zusage oder Absage auf Ihr Angebot erhalten haben, oder ob die Anfrage offen ist und ' +
        'noch nicht vergeben wurde. Über den Punkt Details gelangen Sie in die detaillierte Ansicht Ihres Angebots. ' +
        'Dort haben Sie die Möglichkeit dieses einzusehen und gegebenenfalls zu stornieren.',
      exportModal: {
        title: 'Angebote CSV-Export',
      },
    },
    inquiryOffers: {
      acceptedHeading: 'Vereinbarung %{offer} für Leistungsadresse %{address}',
      heading: 'Angebotsvergleich für Leistungsadresse %{address}',
      subtitle:
        'Bitte beachten Sie, dass es sich bei den gezeigten Gesamtkosten um die prognostizierte finanzielle ' +
        'Gesamtbelastung pro Vorgang handelt. Die angezeigten Einzelpreise sind für die Rechnungsstellung bindend. ' +
        'Die vom Entsorger vorgenommenen Änderungen werden in Blau hervorgehoben.',
      orderBy: 'Sortieren nach',
      orderDirection: 'Richtung',
    },
    acceptedOffers: {
      heading: 'Vereinbarungen',
      headingArchive: 'Vereinbarungen (Archiv)',
      subtitle:
        'Hier finden Sie eine Übersicht der aktiven und zukünftigen Vereinbarungen sowie ausgelaufene Vereinbarungen der letzten 12 Monate. Ältere Vereinbarungen finden Sie im Archiv.',
      subtitleArchive:
        'Hier sehen Sie alle Vereinbarungen, bei denen das Enddatum mindestens 13 Monate zurückliegt.',
      addAgreement: 'Vereinbarung',
      number: 'Vereinbarungsnummer',
      status: 'Status der Vereinbarung',
      startDate: 'Start der Vereinbarung',
      endDate: 'Ende der Vereinbarung',
      exportModal: {
        title: 'Vereinbarungen CSV-Export',
      },
      expiringExportModal: {
        buttonText: 'V. Ende < 8 Wochen',
        title: 'Auslaufende Vereinbarungen CSV-Export',
      },
      rentExportModal: {
        buttonText: 'Mieten CSV-Export',
        title: 'Mieten',
      },
    },
    order: {
      heading: 'Aufträge',
      headingArchive: 'Aufträge (Archiv)',
      subtitle:
        'Hier sehen Sie alle Aufträge mit einem Leistungsdatum innerhalb der letzten 12 Monate. ' +
        'Ältere Aufträge finden Sie im Archiv.',
      subtitleArchive:
        'Hier sehen Sie alle Aufträge, bei denen das Leistungsdatum mindestens 13 Monate zurückliegt.',
      number: 'Auftragsnummer',
      title: 'Auftrag',
      address: 'Leistungsadresse: %{address}',
      executionDate: 'Datum der Durchführung: %{date} %{timeOfDay}',
      deliveryDate: 'Datum der Gestellung: %{date} %{timeOfDay}',
      collectionDate: 'Datum der Abholung: %{date} %{timeOfDay}',
      new_order: 'Neuer Auftrag',
      status: {
        ordered: 'Auftrag erteilt',
      },
      toastr: {
        errors: {
          getOrder: 'Laden des Auftrags fehlgeschlagen.',
          getOrders: 'Laden der Aufträge fehlgeschlagen.',
          createOrder: 'Abgeben des Auftrags fehlgeschlagen.',
          disposeOrder: 'Disposition des Auftrags fehlgeschlagen',
          setETA: 'Speichern der ETA fehlgeschlagen.',
          confirmExecution: 'Bestätigung der Durchführung fehlgeschlagen.',
          createExecutionProof: 'Nachweise hochladen fehlgeschlagen.',
        },
        success: {
          createOrder: 'Auftrag wurde erfolgreich abgegeben.',
          disposeOrder: 'Disposition des Auftrags abgeschlossen.',
          setETA: 'Die ETA zum Auftrag wurde gespeichert.',
          confirmExecution: 'Durchführung des Auftrags wurde bestätigt.',
          createExecutionProof: 'Nachweise hochgeladen.',
        },
      },
      createOrder: {
        header: 'Kostenpflichtige Bestellung',
        infoText:
          '<p>Bitte wählen Sie Ihr gewünschtes Datum für diesen Entsorgungsvorgang.</p>' +
          '<p>Ihr Entsorgungspartner hat sich mit dem Abschluss dieser Vereinbarung verpflichtet, die Entsorgung ' +
          'innerhalb der unten angezeigten Reaktionszeit durchzuführen. Gerne können Sie Ihren Wunschtermin sowohl ' +
          'innerhalb als auch außerhalb dieser Reaktionszeit wählen.</p>' +
          '<p>Reaktionszeit/Leistungszeit: %{reactionTime} </p>',
        labels: {
          executionDate: 'Durchführung',
        },
        button: {
          create: 'Bestellung auslösen',
        },
      },
      export: {
        modal: {
          title: 'Aufträge CSV-Export',
        },
      },
    },
    orderDetails: {
      orderCreate: 'Neuer Auftrag',
      orderDate: 'Auftragsdatum',
      deliveryDate: 'Leistungsdatum',
      additionalInformation: 'Information zum Auftrag',
      heading: {
        title: 'Ihr Auftrag',
        breadcrumb: {
          title: 'Auftrag %{orderId}',
        },
      },
      details: {
        title: 'Details',
        modal: {
          title: 'Auftragsbilder bearbeiten',
          form: {
            uploadField: 'Auftragsbilder',
          },
        },
        images: {
          orderImagesButton: 'Auftragsbilder bearbeiten',
        },
      },
      status: {
        title: 'Status',
      },
      indicationMessage:
        '<b>Bitte beachten Sie</b>: Dies ist der <b>letzte Auftrag</b> der Vereinbarung. ' +
        '<b>Die gestellten Behälter werden mit diesem Auftrag abgezogen</b> und stehen für weitere Leerungen ' +
        'nicht mehr zur Verfügung.',
      internalComment: 'Kommentar',
    },
    detailsSwitcher: {
      shortOverview: 'Kurzübersicht',
      images: 'Bilder',
      orderImages: 'Auftragsbilder',
      parties: 'Parteien',
      aez: 'Gewerbetreibender/Makler',
      disposer: 'Abfallentsorger/Makler',
    },
    detailsCard: {},
    inquiryCard: {
      createdAt: 'Erstellt am %{date}',
      serviceBegin: 'Beginnt am %{date} %{timeOfDay}',
      alreadyOffered:
        'Für diese Anfrage haben Sie bereits ein Angebot abgegeben.',
      numberOfActiveOffers: 'Angebote für diese Anfrage',
      totalRevenue: 'Gesamterlöse ca. %{totalRevenue}',
      totalRevenueInfo:
        '<p>Für die Berechnung der Erlöse werden alle Angebote mit generischen Details berechnet. ' +
        'Der hier angezeigte Wert ist eine ungefähre Berechnung und ' +
        '<strong>nicht der verbindliche Erlös</strong>.</p>',
      totalCost: 'Gesamtkosten ca. %{totalCost}',
      totalCostInfo:
        '<p>Für die Berechnung des Preises werden alle Angebote mit generischen Details berechnet. ' +
        'Der hier angezeigte Preis ist eine ungefähre Berechnung und ' +
        '<strong>nicht der verbindliche Endpreis</strong>.</p>',
      transportCost: 'Kosten pro Transport %{transportCost}',
    },
    inquiryOfferCard: {
      accepted: 'Dieses Angebot wurde bereits von Ihnen akzeptiert.',
      evaluationInfo: 'Anzahl Bewertungen ist in Klammern angegeben.',
    },
    offerFilter: {
      number: 'Angebotsnummer',
    },
    offerForm: {
      submitOffer: 'Angebot abgeben',
      cancelOffer: cancelOfferFormTranslations,
      resendOffer: {
        button: 'Angebot erneut versenden',
        info: 'An welche E-Mail Adresse soll das Angebot geschickt werden?',
        runtimeLabel: 'Laufzeit des Angebots',
        toastr: {
          success: 'Dieses Angebot wurde erneut versendet',
        },
      },
      hint: {
        heading: 'Hinweise',
        specialNote:
          'Hinweise zum Angebot: Erlaubt sind Buchstaben, Zahlen, Sonderzeichen.',
      },
      offer: {
        heading: 'Angebotsstatus',
      },
      noFineFractions: 'Keine Feinfraktion auswählbar.',
      noFineFraction: 'Keine Feinfraktion',
      noIndexes: 'Keine Indizes auswählbar.',
      noIndex: 'Kein Index',
      priceModel: {
        positionOnPublicGroundWarning:
          'Bitte berücksichtigen Sie die Kosten der Stellgenehmigung bei Ihrer ' +
          'Preisgestaltung.',
        heading: 'Preismodell',
        rentPriceContainerMonth: 'Mietpreis (€ pro Behälter und Monat)',
        disposalCostContainer:
          'Transport- und Entsorgungspreis (€ pro Behälter)',
        transportPricePiece: 'Transportpreis (€ pro Stück)',
        disposalCost: 'Entsorgungskosten (€ pro Tonne)',
        recyclePrice: 'Verwertungspreis (€ pro Tonne)',
        disposalCostTon: 'Entsorgungskosten (€ pro Tonne)',
        handleCostTon: 'Handlingskosten (€ pro Tonne)',
        price_placeholder: 'Wert zwischen 0 € und 999,99 €',
        price_placeholder_not_zero: 'Wert zwischen 1 € und 999,99 €',
        surcharge: '<p>Zuschlag <br> (€ pro Tonne)</p>',
        discount: '<p>Abschlag <br> (€ pro Tonne)</p>',
        surchargeSwitch: 'Zuschlag',
        discountSwitch: 'Abschlag',
        surchargeDiscountSwitch: 'Zuschlag / Abschlag',
        unitSwitch: '(€ pro Tonne)',
        compensation: 'Vergütung (€ pro Tonne)',
        index: {
          label: 'Index',
          selector: 'Bitte auswählen',
        },
      },
      avvNumber: {
        label: 'AVV Nummer',
        selector: 'Bitte auswählen',
      },
      toastr: {
        success: 'Angebot wurde erfolgreich abgegeben.',
        error: 'Abgeben des Angebotes fehlgeschlagen.',
      },
    },
    orderStatusList: {
      viewReportButton: 'Prüfungsbericht ansehen',
      viewUpdatedPriceFields: 'Details',
      relabeledModal: {
        relabeledTitle: 'Details zur Umdeklarierung',
        relabeledInfo:
          '<p>Dieser Auftrag enthielt nicht die vereinbarte Abfallart. ' +
          'Aus diesem Grund wurde der Auftrag als <b>%{fraction} %{fineFraction}</b> abgefahren und unter der ' +
          'AVV Nummer <b>%{avv}</b> entsorgt.</p>',
        containerChangedTitle: 'Details zur Behälteränderung',
        containerChangedInfo:
          '<p>Dieser Auftrag enthielt nicht den vereinbarten Behälter. ' +
          'Aus diesem Grund wurde der Auftrag mit dem Behälter <b>%{containerName}</b> abgefahren und entsorgt.</p>',
        containerCountChangedTitle: 'Details zur Änderung der Behälteranzahl',
        containerCountChangedInfo:
          '<p>Dieser Auftrag enthielt nicht die vereinbarte Behälteranzahl. ' +
          'Aus diesem Grund wurde der Auftrag mit <b>%{containerCount} Behälter(n)</b> abgefahren und entsorgt.</p>',
        additionalRentTitle: 'Details zur Stellzeitänderung',
        additionalRentInfo:
          '<p>Dieser Auftrag enthielt nicht die vereinbarte Stellzeit. ' +
          'Die Gestellung erfolgte am <b>%{deliveryDate}</b>. Die Abholung erfolgte am <b>%{collectionDate}</b></p>',
      },
    },
    orderClaimStatusMessage: orderClaimStatusMessages,
    orderStatusTasks: {
      dispose: {
        headline: 'Bestätigen Sie den Auftrag',
        submitButton: 'Auftrag bestätigen',
        finalDeliveryHeadline: 'Bestätigen Sie den Behälterabzug',
        finalDeliverySubmitButton: 'Abzug bestätigen',
      },
      setETA: {
        headline: 'Legen Sie die Ankunftszeit fest',
        headlineDelivery: 'Legen Sie die Ankunftszeit für die Gestellung fest',
        headlineCollection: 'Legen Sie die Ankunftszeit für die Abholung fest',
        submitButton: 'Ankunftszeit festlegen',
        placeholder: {
          eta: 'Ankunftszeit (ETA)',
        },
      },
      confirmExecution: {
        headline: 'Bestätigen Sie die Durchführung',
        submit: 'Auftrag durchgeführt',
        headlineDelivery: 'Bestätigen Sie die Gestellung',
        submitDelivery: 'Gestellung durchgeführt',
        headlineCollection: 'Bestätigen Sie die Abholung',
        submitCollection: 'Abholung durchgeführt',
      },
      uploadExecutionProof: {
        headline: 'Nachweise hochladen',
        uploadModalTitle: 'Nachweise hochladen',
        uploadButton: 'Nachweise hochladen',
      },
    },
    offerDetails: {
      offerFor: 'Angebot %{offerNumber} zu Ihrer Anfrage für %{address}',
      offerForAccepted: 'Vereinbarung %{offerNumber} für %{address}',
      offerForOwn: 'Ihr Angebot %{offerNumber} zur Anfrage von %{address}',
      offerForOwnAccepted: 'Vereinbarung %{offerNumber} für %{address}',
      offerAcceptedGeneral: 'Vereinbarung %{offerNumber}',
      offerOf: 'Angebot von %{name}',
      specialNote: 'Hinweise zum Angebot',
      acceptOffer: {
        button: 'Angebot annehmen',
        info: 'Möchten Sie dieses Angebot annehmen?',
        success: 'Angebot wurde erfolgreich angenommen.',
      },
      agreementOffer: {
        newOrder: 'Neuer Auftrag',
        newOrderOutsideAgreementDatesError:
          'Außerhalb des vereinbarten Zeitraumes können Sie keinen Auftrag erstellen.',
        previousOrders: 'Bisherige Aufträge',
        tooltip: {
          noPreviousOrders: 'Es liegen keine Aufträge vor.',
        },
        previousOrdersFor: 'Bisherige Aufträge zu Vereinbarung %{number}',
        details: {
          title: 'Vereinbarung',
          number: 'Vereinbarungsnummer',
          inquiryNumber: 'Anfragenummer',
          offerNumber: 'Angebotsnummer',
        },
      },
      banners: {
        cancelled: '%{name} hat dieses Angebot am %{date} storniert',
        cancelledByEmpto: 'empto hat dieses Angebot am %{date} storniert',
        rejected: '%{name} hat dieses Angebot am %{date} abgesagt',
        accepted: '%{name} hat dieses Angebot am %{date} angenommen',
      },
    },
    offer: {
      toastr: {
        success: 'Angebot wurde erfolgreich abgegeben.',
        errors: {
          getOffers: 'Laden der Angebote fehlgeschlagen.',
          getOffer: 'Laden des Angebotes fehlgeschlagen.',
        },
      },
    },
    offerCard: {
      offerStatus: 'Status des Angebots: ',
      inquiryStatus: 'Status der Anfrage: ',
    },
    fileUpload: {
      uploadButtonTitle: 'Datei hochladen',
      dropCallToAction: 'Oder einfach per Drag & Drop hier draufziehen.',
      dropHint: 'Loslassen zum Hochladen.',
      textIconTitle: 'Zu dieser Datei gibt es einen Text.',
      rejectHint: 'Nur Bilder und PDF-Dokumente',
      lastmodifiedAt: 'Hochgeladen am %{date}',
      openFileButtonTitle: 'Datei öffnen',
      hint: 'Es können nur PDF- und Bildformate hochgeladen werden.',
      rejectedToast: {
        title: 'Fehler beim Hochladen',
        text: 'Es können nur Bilder und PDF-Dateien unter %{maxSize} MB hochgeladen werden.',
      },
      tooManyToast: {
        title: 'Fehler beim Hochladen',
        text: 'Es dürfen maximal %{max} Dateien hochgeladen werden.',
        textSingle: 'Es darf maximal eine Datei hochgeladen werden.',
      },
      uploadErrorToast: {
        title: 'Fehler beim Hochladen',
        text: 'Leider konnten nicht alle Dateien hochgeladen werden %{description}.',
      },
      removeErrorToast: {
        title: 'Fehler beim Hochladen',
        text: 'Leider konnten nicht alle Dateien entfernt werden %{description}.',
      },
      fileCommentForm: {
        placeholder: {
          text: 'Ergänzen Sie hier eine Beschreibung zu dem Anhang.',
        },
      },
    },
    myAddresses: {
      heading: 'Standortverwaltung',
      emptyResultSet: 'Es wurden noch keine Standorte hinterlegt.',
      addButton: 'Neue Leistungsadresse',
      exportButton: 'CSV Export',
      exportModalTitle: 'Adressen CSV Export',
      table: {
        header: {
          id: 'ID',
          description: 'Bezeichnung',
          created_at: 'Erstellt am',
          status_lastmodified_at: 'Zuletzt geändert am',
          address: 'Adresse',
          status: 'Status',
          actions: 'Aktionen',
        },
        activateButton: 'Aktivieren',
        deactivateButton: 'Sperren',
        editButton: 'Bearbeiten',
      },
    },
    address: {
      disable: {
        info:
          'Möchten Sie diese Leistungsadresse wirklich sperren? ' +
          'Alle offenen Anfragen zu dieser Leistungsadresse werden automatisch storniert!',
      },
    },
    indexValuesPage: {
      heading: 'Indexwerte (€ pro Tonne)',
      exportModalTitle: 'CSV Export Indexwerte',
      changeIndexValue: 'Indexwert bearbeiten',
      checkNextMonth: '%{month} Indexwerte Überblick',
      hideCheckNextMonth: '%{month} Indexwerte verbergen',
      addNextMonth: '%{month} Indexwerte hinzufügen',
      modalHeader: 'Indexwerte hinzufügen',
      buttonText: 'Indexwerte',
      noIndexValues: 'Aktuell gibt es keine Indexwerte.',
      table: {
        header: {
          index: 'Index',
          units: '€ pro Tonne',
        },
        footer: {
          total: 'Prüfsumme',
          lastChange: 'Letzte Änderung',
          changedBy: 'Ersteller/in',
        },
      },
      footer: {
        noLatestChangeDate: 'kein Änderungsdatum',
        noLatestChangeUser: 'kein Benutzerwechsel',
      },
      addValueForm: {
        changeButton: 'Ändern',
        addButton: 'Hinzufügen',
        indexSelect: 'Index wählen',
        monthSelect: 'Monat wählen',
        yearSelect: 'Jahr wählen',
        value: 'Indexwert',
        noAvailableIndexes: 'Keine Indizes auswählbar',
        editButton: 'Bearbeiten',
        index: {
          errorMessage: {
            fieldTooShort: 'Indizes dürfen nicht kleiner als -999 sein.',
            fieldTooBig: 'Indizes dürfen nicht größer als 999 sein.',
            tooManyDecimalPlaces:
              'Indizes dürfen nicht mehr als zwei Nachkommastellen haben.',
          },
        },
      },
      toastr: {
        success: 'Indexwert erfolgreich geändert',
        error: 'Fehler beim Ändern des Indexwert',
      },
    },
    companyProfilePage: {
      certificateNeeded: 'Nachweis ausstehend',
      heading: 'Stammdaten',
      viewProfile: 'So sehen mich andere Unternehmen',
      companyDetails: 'Unternehmensdaten',
      mainAddressDetails: 'Hauptadresse',
      postalAddressDetails: 'Abweichende Postanschrift',
      taxDetails: 'Steuerinformationen',
      verificationDocuments: 'Belege',
      profileInfos: 'Profilinformationen',
      paymentMethod: 'Zahlungsart',
      lastChange:
        'Die Stammdaten (ohne Belege) wurden zuletzt %{fromNow} durch %{name} geändert (Datum %{date}).',
      noChange: 'Die Stammdaten wurden bislang nicht bearbeitet.',
      label: {
        aboutUs: 'Über uns',
        bic: 'BIC',
        central_contact_phone: 'Zentrale Telefonnummer',
        collectiveInvoicingAutoSendEmail: 'Automatischer Rechnungsversand',
        collectiveInvoicingMode: 'Sammelrechnungsgenerierung',
        commercialRegistryNumber: 'Handelsregisternummer',
        companyGroup: 'Unternehmensgruppe',
        contactPerson: 'Ansprechpartner',
        country: 'Land',
        customerNumber: 'Kundennummer',
        customerType: 'Kundentyp',
        director_first_name: 'Vertretungsberechtigte Person (Vorname)',
        director_last_name: 'Vertretungsberechtigte Person (Nachname)',
        email: 'Zentrale E-Mail',
        emailForInvoicingDocuments:
          'E-Mail (Zustellung der Rechnungen/Gutschriften)',
        etaService: 'ETA-Service',
        etaServiceCheckbox: 'Wir bieten ETA-Service an',
        houseNumber: 'Hausnummer',
        iban: 'IBAN',
        invoiceRecipient: 'Empfängerzusatz',
        location: 'Ort',
        name: 'Vollständiger Name des Unternehmens',
        paymentMethod: 'Zahlungsart',
        paymentMethods: {
          account_holder_name: 'Kontoinhaber',
          sepa: 'SEPA-Lastschrift',
          iban: 'IBAN',
        },
        permanentDiscount: 'Dauerrabatt',
        recipient: 'Empfänger',
        referenceNumber: 'Referenznummer',
        registrationNumber: 'Registrierungsnummer',
        status: 'Status',
        street: 'Straße',
        taxId: 'Umsatzsteuer-Identifikationsnummer',
        taxNumber: 'Steuernummer',
        websiteUrl: 'Webseite',
        zipcode: 'PLZ',
      },
      placeholder: {
        aboutUs:
          'Ein allgemeiner Text über Ihr Unternehmen, interessante Fakten, etc.',
        bic: 'BIC',
        central_contact_phone: 'Zentrale Telefonnummer',
        collectiveInvoicingAutoSendEmail: 'Automatischer Rechnungsversand',
        collectiveInvoicingMode: 'Sammelrechnungsgenerierung',
        commercialRegistryNumber: 'HRB000000000',
        contactPerson: 'Ansprechpartner',
        director_first_name: 'Vertretungsberechtigte Person (Vorname)',
        director_last_name: 'Vertretungsberechtigte Person (Nachname)',
        email: 'Zentrale E-Mail',
        iban: 'IBAN',
        invoiceRecipient: 'Person, Abteilung …',
        name: 'Mustermann GmbH & Co. KG',
        paymentMethod: 'Zahlungsart',
        permanentDiscount: 'Dauerrabatt',
        referenceNumber: 'Referenznummer',
        registrationNumber: 'Registrierungsnummer',
        taxId: 'DE123456789',
        websiteUrl: 'URL der Webseite',
      },
      validation: {
        taxDataFilled:
          'Es muss entweder die Steuernummer oder die Umsatzsteuer-ID ausgefüllt werden.',
        postalAddressDataFilled:
          'Bitte befüllen Sie alle Felder der abweichenden Postanschrift. ' +
          'Das Feld E-Mail ist kein Pflichtfeld.',
        mainAddressZipcode: 'Bitte geben Sie eine deutsche PLZ an.',
        postalAddressZipcode: 'Bitte geben Sie eine deutsche PLZ an.',
        contactPerson: 'Bitte wählen Sie einen Kontakt aus.',
        websiteUrl: 'Bitte geben Sie eine gültige Adresse ein.',
        paymentDataFilled: 'Bitte wählen Sie eine Zahlungsart aus.',
        selectedUserError: 'Bitte wählen Sie einen Benutzer aus.',
        director_first_name:
          'Bitte geben Sie den Vornamen einer vertretungsberechtigten Person an.',
        director_last_name:
          'Bitte geben Sie den Nachnamen einer vertretungsberechtigten Person an.',
        iban: 'Bitte geben Sie eine gültige IBAN ein.',
        bic: 'Bitte geben Sie eine gültige BIC ein.',
        country: 'Bitte geben Sie ein Land ein.',
        registrationNumbersFilled:
          'Bitte geben Sie mindestens eine der beiden Registrierungsnummern an.',
      },
      certificates: {
        pending:
          'Am %{date} wurde von %{user} ein Nachweis hochgeladen. Er befindet sich gerade in Prüfung.',
        accepted:
          'Der aktuelle Nachweis (hochgeladen am %{date} von %{user}) wurde akzeptiert. ' +
          'Sie können jederzeit einen neuen Nachweis hochladen.',
        rejected:
          'Der am %{date} von %{user} hochgeladene Nachweis wurde reklamiert. Bitte laden Sie einen neuen ' +
          'Nachweis hoch.',
        none: 'Sie haben bislang noch keinen Nachweis hochgeladen.',
        addButton: 'Jetzt hochladen',
        modalTitle: 'Nachweis hochladen',
        required: 'Bitte laden Sie eine Datei hoch.',
        label: {
          company: 'Gewerbenachweis',
          efb: 'Efb-Zertifikat',
          priceAgreement: 'Preisvereinbarung',
          other: 'Weitere Zertifikate',
        },
        tooltip: {
          efb:
            '<p>Hier können Sie mehrere Dokumente hochladen. Verwenden Sie bitte nur übliche Formate' +
            ' für Fotos sowie PDF-Dateien für textliche Informationen. Die maximale Dateigröße beträgt 10 MB.</p>',
          other:
            '<p>Hier können Sie ein Dokument hochladen. Verwenden Sie bitte nur übliche Formate für ' +
            'Fotos sowie PDF-Dateien für textliche Informationen. Die maximale Dateigröße beträgt 10 MB.</p>',
          company:
            '<p>empto ist eine B2B Plattform. An dieser Stelle können Sie den Nachweis für die Existenz ' +
            'Ihres Unternehmens bequem per Upload erbringen. Wir akzeptieren eines der folgenden Dokumente: ' +
            'Handelsregisterauszug, IHK-Mitgliedbescheinigung, Stromrechnung oder Telefonrechnung. ' +
            'Die Prüfung Ihres Dokuments erfolgt werktags innerhalb von 24 Stunden.</p>',
        },
      },
      priceAgreement: {
        pending:
          'Am %{date} wurde von %{user} eine Preisvereinbarung hochgeladen. ' +
          'Sie befindet sich gerade in Prüfung.',
        accepted:
          'Die aktuelle Preisvereinbarung (hochgeladen am %{date} von %{user}) wurde akzeptiert. ' +
          'Sie können jederzeit eine neue Preisverinbarung hochladen.',
        rejected:
          'Die am %{date} von %{user} hochgeladene Preisvereinbarung wurde reklamiert. ' +
          'Bitte laden Sie eine neue Preisvereinbarung hoch.',
        none: 'Sie haben bislang noch keine Preisvereinbarung hochgeladen.',
        addButton: 'Jetzt hochladen',
        modalTitle: 'Preisvereinbarung hochladen',
        required: 'Bitte laden Sie eine Datei hoch.',
      },
      activityTable: {
        columns: {
          timestamp: 'Zeitstempel',
          name: 'Name',
          activity: 'Tätigkeit',
          changes: 'Änderungen',
        },
        activityTypes: {
          '+': 'Hinzugefügt',
          '~': 'Geändert',
          '-': 'Gelöscht',
        },
      },
    },
    invoiceCheckTranslations,
    invoiceCheckOverviewTranslations,
    certificateArchive: {
      heading: 'Dokumentenarchiv',
      type: {
        efb: 'Efb Zertifikat',
        other: 'Weitere Zertifikate',
        company: 'Gewerbenachweis',
        priceAgreement: 'Preisvereinbarung',
        dszLicenseConfirmation: 'Lizenzierungsbestätigung',
        dszParticipationConfirmation: 'Systembeteiligungsbestätigung',
        dszXml: 'XML',
      },
      status: {
        pending: 'In Prüfung',
        accepted: 'Akzeptiert',
        rejected: 'Reklamiert',
      },
      segment: {
        empto: 'Empto',
        epd: 'Entsorgung.de',
      },
      table: {
        customerNo: 'Kundennummer',
        companyName: 'Unternehmen',
        docType: 'Dokumententyp',
        number: 'Anzahl',
        order: 'Auftrag',
        uploadDate: 'Hochgeladen am',
        statusDate: 'Geprüft am',
        statusUser: 'Geprüft von',
        uploadUser: 'Hochgeladen von',
        fileLink: 'Link zum Dokument',
        review: 'prüfen',
        view: 'ansehen',
        category: 'Kategorie',
      },
      details: {
        reviewHeading: 'Nachweisprüfung für %{name} (%{type})',
        heading: 'Nachweis für %{name} (%{type})',
        reviewBreadcrumbs: 'Nachweisprüfung',
        breadcrumbs: 'Nachweis',
        company: 'Unternehmen',
        validUntil: 'Gültig bis',
        issuedAt: 'Ausgestellt am',
        internalNote: 'Interner Kommentar',
        rejectReason:
          'Bitte begründen Sie dem empto Nutzer (extern) Ihre Reklamation',
        status: 'Status',
        complete: 'Prüfung abschließen',
        documentsDetails: 'Dokumente',
        documentsDetails_1: 'Dokument',
        certificateDetails: 'Nachweis-Informationen',
        reviewedBy: 'Geprüft von',
        reviewedAt: 'Geprüft am',
        validation: {
          validUntil: 'Bitte wählen Sie ein Datum aus.',
          issuedAt: 'Bitte wählen Sie ein Datum aus.',
          rejectReason: 'Bitte begründen Sie Ihre Reklamation.',
          status: 'Bitte ändern Sie den Status.',
        },
        success: 'Die Nachweisprüfung wurde erfolgreich gespeichert.',
      },
      emptyResultSet:
        'Zu der gewählten Filtereinstellung gibt es aktuell keine Dokumente.',
      uploadedBySystemAdmin: 'Systemadministrator',
    },
    companyInfoPageIndicationMessage: {
      title: 'Kunde ist gesperrt',
      message:
        'Aktuell können keine Angebote für diesen Kunden erstellt werden. Grund der Sperrung: %{reason}',
      buttonTextUnlock: 'Sperre aufheben',
    },
    companyInfoPageWasteCompanyIndicationMessage: {
      infoStartEnd: 'Der Entsorger pausiert vom %{start_date} bis %{end_date}',
      infoStart: 'Der Entsorger pausiert ab dem %{start_date}.',
      message:
        'In diesem Zeitraum werden keine Preise dieses Entsorgers bei der Angebotserstellung berücksichtigt. Es können keine Aufträge an diesen Entsorger vergeben werden.',
      buttonTextUnlock: 'Entsorger aktivieren',
      reason: 'Grund der Pausierung: %{reason}',
    },
    companyInfoPage: {
      aboutUs: 'Über den %{role}',
      addCertificates: 'Nachweise hochladen',
      availableLocations: '%{count} Standorte aktiv',
      availableLocations_0: 'Kein Standort verfügbar',
      availableLocations_1: '1 Standort verfügbar',
      centralData: 'zentrale Daten',
      contact: 'Kontakt / Zentrale',
      continueAutoOffersButtonText: 'Entsorger Pausierung aufheben',
      customerNumber: 'Kundennr.: <strong>%{customerNumber}</strong>',
      editButton: 'Stammdaten bearbeiten',
      comCenterButton: 'Kommunikationscenter',
      efbCertificateDownload:
        'EfB-Zertifikat %{certificateNumber} herunterladen',
      efbCertificateValidUntil:
        'EfB-Zertifikat gültig bis zum %{validUntilDate}',
      exportModal: {
        title: 'Kundenbericht CSV Export',
      },
      externalNumber: ' (ehm. Kundennr.: %{externalNumber})',
      lockCompanyButtonText: 'Kunde sperren',
      noRatingYet: 'Es liegen noch keine Bewertungen vor',
      pauseAutoOffersButtonText: 'Entsorger pausieren',
      rating:
        '%{positivePercentage}% positive Bewertungen (%{numberEvaluations})',
      subheader:
        '<strong>%{companyRole}</strong> in der Umgebung von' +
        ' <strong>%{companyLocation}</strong>.<br>',
      memberSince: 'Mitglied seit %{memberSince}',
      actions: 'Aktionen',
      contact_person: 'Ansprechpartner:in',
      ctaActionButton: 'Aktionen',
      ctaActionButtonShowLess: 'Weitere Aktionen schließen',
    },
    communicationsCenterPage: {
      exportModal: {
        title: 'Kommunikationscenter CSV Export',
      },
    },
    agreementsAndOrders: {
      headline: 'Vereinbarungen und Aufträge',
      buttonAgreements: 'Vereinbarungen ansehen',
      buttonOrders: 'Aufträge ansehen',
      buttonFrameworks: 'Rahmenverträge ansehen',
      allAgreements: 'Vereinbarungen gesamt',
      allOrders: 'Aufträge gesamt',
      frameworkPositions: 'Rahmenvertragspositionen',
      offerJobs: 'Angebotsjobs',
      buttonOfferJobs: 'Angebotsjobs anzeigen',
    },
    contact: {
      headline: 'Alle Ansprechpartner',
      button: 'Alle anzeigen',
    },
    documents: {
      headline: 'Dokumente',
      table: {
        header: {
          number: 'Nr.',
          docType: 'Typ',
          description: 'Bezeichnung',
        },
        viewButton: 'Ansehen',
        archiveButton: 'Archivieren',
      },
      uploadButton: 'Dokumente hochladen',
      archiveButton: 'Archiv ansehen',
    },
    allContactPersons: {
      headline: 'Alle Ansprechpartner',
      button: 'Alle anzeigen',
      link: 'Alle Ansprechpartner anzeigen',
      countContactPersons_one: 'Es ist %{count} Ansprechpartner vorhanden.',
      countContactPersons_multiple:
        'Es sind insgesamt %{count} Ansprechpartner vorhanden.',
    },
    allAddresses: {
      headline: 'Alle Standorte',
      button: 'Alle anzeigen',
      countAddresses_one: 'Es ist %{count} Standort vorhanden.',
      countAddresses_multiple:
        'Es sind insgesamt %{count} Standorte vorhanden.',
    },
    paymentAndDiscount: {
      headline: 'Abrechnung',
      button: 'Belege ansehen',
      partnerButton: 'Belegprüfungen anzeigen',
      permanentDiscount: 'Dauerrabatt',
      permanentDiscountNone: 'Nicht vorhanden',
      paymentType: 'Zahlungsart',
      numberOfInvoiceChecks: 'Belegprüfungen gesamt',
      tooltip: {
        description:
          'Die Zahlungsart bezieht sich auf die letzte gewählte Zahlungsart des Kunden',
      },
      billingInterval: 'Abrechnungsintervall',
      collectiveInvoicingAutoSendEmail: 'Automatischer Rechnungsversand',
      collectiveInvoicingMode: 'Sammelrechnungsgenerierung',
    },
    notes: {
      headline: 'Notizen',
      placeholder: 'Ihre Notizen',
      button: 'Notiz speichern',
      allNotes: 'Alle Notizen anzeigen',
    },
    orderPostpone: orderPostPoneTranslations,
    orderClaim: orderTranslations.orderClaimTranslations,
    orderCancel: orderCancelTranslations,
    executionProof: {
      completion: 'Leistungsnachweise',
      status: {
        pending: 'In Prüfung',
        accepted: 'Akzeptiert',
        rejected: 'Reklamiert',
      },
      type: {
        proof: 'Leistungsnachweis',
        complain: 'Reklamation',
      },
    },
    executionProofUploadForm: {
      label: 'Laden Sie hier Ihre Nachweise zur Durchführung hoch',
      tooltip: 'Wählen Sie Dateien zum Upload von Ihrem Computer aus.',
      required: 'Sie müssen mindestens eine Datei auswählen.',
    },
    executionProofStatus: {
      pending:
        'Am %{date} wurde von %{user} ein Nachweis hochgeladen. Er befindet sich gerade in Prüfung.',
      accepted:
        'Der aktuelle Nachweis (hochgeladen am %{date} von %{user}) wurde akzeptiert.',
      rejected:
        'Der am %{date} von %{user} hochgeladene Nachweis wurde reklamiert. ' +
        'Bitte laden Sie einen neuen Nachweis hoch.',
      none: 'Sie haben noch keinen Nachweis hochgeladen.',
      addButton: 'Jetzt hochladen',
    },
    executionProofArchive: {
      heading: 'Leistungsnachweisarchiv',
      table: {
        customerNo: 'Kundennummer',
        companyName: 'Unternehmen',
        number: 'Anzahl',
        uploadDate: 'Hochgeladen am',
        statusDate: 'Geprüft am',
        statusUser: 'Geprüft von',
        uploadUser: 'Hochgeladen von',
        fileLink: 'Link zum Dokument',
        review: 'prüfen',
        view: 'ansehen',
      },
      details: {
        reviewHeading: 'Leistungsnachweisprüfung für Auftrag %{number}',
        heading: 'Leistungsnachweis für Auftrag %{number}',
        reviewBreadcrumbs: 'Leistungsnachweisprüfung',
        breadcrumbs: 'Leistungsnachweis',
        order: {
          heading: 'Auftrag',
          groupOne: {
            orderNumber: 'Auftragsnummer',
            aez: 'Gewerbetreibender/Makler',
            serviceAddress: 'Leistungsadresse',
            deliveryDate: 'Leistungsdatum',
          },
          groupTwo: {
            disposer: 'Abfallentsorger/Makler',
            avvNumber: 'AVV Nummer',
            fraction: 'Abfallart',
            fineFraction: 'Feinfraktion',
            container: 'Behälter',
            numberOfContainers: 'Anzahl der Behälter',
          },
        },
        form: {
          sameFraction:
            'Sind die Abfallart und die AVV-Nummer im Auftrag und im Nachweis identisch?',
          sameFractionSmall: 'Abfallart und die AVV-Nummer identisch?',
          fineFraction: 'Feinfraktion',
          weightHeader: 'Gewicht (in kg)',
          weightDate: 'Verwiegedatum',
          weight: 'Gewicht des Behälter %{number} (in kg)',
          weightReceipt: 'Wiegescheinnummer %{number}',
          addMoreContainers: 'Behälter hinzufügen',
          removeLastContainer: 'Behälter entfernen ',
          numberOfContainers: 'Behälteranzahl (in Stück)',
          reject_reason: 'Begründung für Reklamation',
        },
        internalNote: 'Interner Kommentar',
        rejectReason:
          'Bitte begründen Sie dem empto Nutzer (extern) Ihre Reklamation',
        status: 'Status',
        complete: 'Prüfung abschließen',
        documentsDetails: 'Dokumente',
        documentsDetails_1: 'Dokument',
        executionDetails: 'Leistungsnachweis-Informationen',
        validation: {
          rejectReason: 'Bitte begründen Sie Ihre Reklamation.',
          status: 'Bitte ändern Sie den Status.',
        },
        success: 'Die Leistungsnachweisprüfung wurde erfolgreich gespeichert.',
      },
      emptyResultSet:
        'Zu der gewählten Filtereinstellung gibt es aktuell keine Dokumente.',
    },
    orderClaimProof: {
      reviewHeading: 'Reklamationsprüfung für Auftrag %{number}',
      heading: 'Reklamation für Auftrag %{number}',
      reviewBreadcrumbs: 'Reklamationsprüfung',
      breadcrumbs: 'Reklamation',
      orderClaimDetailsHeading: 'Mitteilung des Kunden',
      orderClaimProofDetailsHeading: 'Prüfungsergebnis',
      confirmSaveInternalNote:
        'Möchten Sie die Reklamation zwischenspeichern? Der interne Kommentar wird beibehalten',
      details: {
        companyName: 'Unternehmen',
        name: 'Name',
        position: 'Position',
        phoneNumber: 'Telefonnummer',
        eMail: 'E-Mail Adresse',
        timestamp: 'Zeitstempel der Reklamation',
        reason: 'Grund',
        message: 'Nachricht',
        proofedOnDate: 'Geprüft am',
        proofedOnTime: 'Zeitstempel der Prüfung',
        proofedBy: 'Name des Prüfers',
        proofComment: 'Begründung',
        proofRejectText: 'Begründung',
        proofStatus: 'Status',
      },
      form: {
        heading: 'Reklamationsprüfung',
        saveInternalNote: 'Prüfung speichern',
        reviewText: 'Nachricht des Prüfungsergebnisses',
        serviceDate: 'Datum der Leerfahrt',
        reject_reason: 'Ablehnungsgrund',
        writeInternalNote: 'Bitte schreiben Sie eine Interner Kommentar',
        deadheadInfo:
          'Mit der Freigabe dieses Problems wird die Fakturierung der Leerfahrt angestoßen.',
        waitingTimeInfo:
          'Mit der Freigabe dieses Problems wird die Fakturierung der Wartezeit/Sortierkosten ' +
          'angestoßen.',
        waitingTimes: {
          selectLabels: {
            fifteenMinutes: '15 Minuten (1 Einheit)',
            thirtyMinutes: '30 Minuten (2 Einheiten)',
            fortyFiveMinutes: '45 Minuten (3 Einheiten)',
            sixtyMinutes: '60 Minuten (4 Einheiten)',
            seventyFiveMinutes: '75 Minuten (5 Einheiten)',
            ninetyMinutes: '90 Minuten (6 Einheiten)',
          },
          title:
            'Mit der Freigabe dieses Problems wird die Fakturierung der Wartezeiten/Sortierkosten angestoßen.',
          effortDurationLabel: 'Dauer des Aufwands',
          waitingTimeDateLabel: 'Datum der Wartezeit/Sortierkosten',
        },
      },
      validation: {
        rejectReason: 'Bitte begründen Sie Ihre Reklamation.',
        status: 'Bitte ändern Sie den Status.',
      },
      toastr: {
        reviewSubmit: {
          success: 'Die Reklamationsprüfung wurde erfolgreich gespeichert.',
          error: 'Speichern der Reklamationsprüfung fehlgeschlagen.',
        },
        internalNote: {
          success: 'Interner Kommentar wurde erfolgreich gespeichert.',
          error: 'Speichern der Interner Kommentar fehlgeschlagen.',
        },
      },
    },
    orderEvaluation: {
      evaluate: 'Bewerten',
      receivingEvaluations: 'So wurden Sie bewertet.',
      submitEvaluations: 'Bitte geben Sie eine Bewertung ab.',
      tooltip:
        'Bitte bewerten Sie über ' +
        '<i class="icon icon-thumbs-up" /> oder <i class="icon icon-thumbs-down" />.',
      submittedEvaluations: 'So haben Sie bewertet.',
      submittedByAt: 'Abgegeben von %{name} am %{date}.',
      type: {
        punctuality: 'Pünktlichkeit',
        friendliness: 'Freundlichkeit des Fahrers',
        state: 'Zustand von Fahrzeug & Container',
        situation: 'Situation vor Ort gemäß Auftrag',
        wait: 'Wartezeit',
      },
      success: 'Bewertung erfolgreich gespeichert.',
      error: 'Speichern der Bewertung fehlgeschlagen.',
    },
    invoice: {
      createDocuments: {
        title: 'Rechnungen/Gutschriften erstellen',
        validationMsg: 'Bitte wählen Sie einen Dokumententyp aus',
      },
      type: {
        final: 'Finale Rechnung/Gutschrift',
        deadhead: 'Leerfahrt',
      },
      toastr: {
        error: {
          getInvoices: 'Laden der Rechnungen fehlgeschlagen.',
          getInvoice: 'Laden der Rechnung fehlgeschlagen.',
        },
      },

      documentFilter: {
        typeLabel: 'Belegart',
        isInvoice: 'Rechnung',
        isCreditNote: 'Gutschrift',
        isCancellationInvoice: 'Storno Rechnung',
        isCancellationCreditNote: 'Storno Gutschrift',
        order: 'Auftragsnummer',
        receiptNumber: 'Belegnummer',
      },
      documentPaymentStatus: {
        statusLabel: 'Status', // used in BookkeepingList'
        open: 'Offen',
        paid: 'Bezahlt',
        paymentReminder: '1. Erinnerung',
        overdueWarning: '1. Mahnung',
        creditreform: 'Creditreform',
        cancelled: 'Storniert',
        done: 'Erledigt',
        confirmationSent: 'Bestätigungsmail versandt',
        datev: 'Datev',
      },

      documentCancelReason: {
        wrong_prices: 'Falsche Preise',
        wrong_number: 'Falsche Anzahl',
        wrong_weight: 'Falsches Gewicht',
        wrong_invoice_recipient: 'Falscher Rechnungsempfänger',
        other: 'Sonstiges',
        withdrawal: 'Widerruf',
      },

      invoiceDunningReason: {
        wrong_invoice: 'Rechnung eventuell falsch (in Prüfung)',
        customer_complaint: 'Kunde droht mit Klage',
        other: 'Sonstiges',
      },

      documentUnpaidReason: {
        customer_refund: 'Kunde hat Betrag zurückgebucht',
        accidentally_marked_paid: 'Beleg versehentlich auf bezahlt gesetzt',
        other: 'Sonstiges',
      },

      invoiceList: {
        address: 'Leistungsadresse',
        canceled: 'Storno',
        containerWeight: 'Gewicht (in Kilogramm)',
        creditNote: 'Gutschrift',
        deliveryDate: 'Leistungsdatum',
        executionProof: 'Leistungsnachweis',
        fraction: 'Abfallart',
        heading: 'Rechnungen/Gutschriften',
        invoice: 'Rechnung',
        netAmount: 'Nettobetrag',
        grossAmount: 'Bruttobetrag', // used in BookkeepingList
        numberOfContainers: 'Anzahl der Behälter',
        orderNumber: 'Auftrag',
        receipt: 'Beleg',
        receiptDate: 'Belegdatum',
        receiptNumber: 'Belegnummer',
        receiptType: 'Belegart',
        paidAt: 'Bezahlt am', // used in BookkeepingList
      },
    },
    invoiceCheckPosition: {
      status: {
        pending: 'In Prüfung',
        accepted: 'Akzeptiert',
        rejected: 'Reklamiert',
      },
    },
    discountOverview: {
      heading: 'Rabatte',
      exportModalTitle: 'Rabatte CSV Export',
      discount: 'Rabatt',
      redeemDiscount: 'Rabatt einlösen',
      addDiscount: 'Rabatt hinzufügen',
      note:
        'Bitte tragen Sie hier Ihren Rabattcode ein. Gültige Codes werden direkt mit der nächsten Rechnung' +
        ' verarbeitet und als eigene Position auf dem Beleg ausgewiesen.',
      discountType: {
        individual: 'Individuell',
        general: 'Allgemein',
      },
      occasionType: {
        campaign: 'Kampagne',
        claim: 'Reklamation',
        other: 'Sonstiges',
      },
      discountDetails: {
        title: 'Details des Rabattes',
      },
      fields: {
        discountId: 'Rabatt-ID',
        description: 'Begründung',
        value: 'Wert',
        code: 'Rabattcode',
        discountType: 'Rabatttyp',
        occasion: 'Anlass',
        validFrom: 'Gültig ab',
        validUntil: 'Gültig bis',
        customerNo: 'Kundennummer',
        customerName: 'Name des Kunden',
        redeemedAt: 'Eingelöst am',
        redeemedBy: 'Eingelöst von',
      },
      noInputResults: {
        noCompany: 'Zu Ihrer Suchanfrage wurde kein Unternehmen gefunden',
      },
    },
    anyQuestionsArea: {
      // &#8209; Non-Breaking Hyphen
      text:
        '<p> <b>Haben Sie Fragen?</b><br/><br/> Unser freundliches und kompetentes Service&#8209;Team ' +
        'unterstützt  Sie gerne von Mo&#8209;Fr zwischen 8&#8209;18 Uhr bei Fragen zu empto. Sie erreichen uns ' +
        'unterstützt  Sie gerne von Mo&#8209;Fr zwischen 8&#8209;18 Uhr bei Fragen zu empto. Sie erreichen uns ' +
        `unter der kostenlosen Telefonnummer <a href='tel:${phoneValue}'>${phoneString}</a>.</p>`,
    },
    wasteType: {
      heading: 'Unsere Abfallarten',
    },
    pageDescriptions: {
      downloads:
        'Alle wichtigen Zertifikate und Dokumente stehen hier zum Download zur Verfügung. empto - Ihr ' +
        'digitaler Abfallassistent. Einfach, transparent und rechtssicher!',
      help:
        'Sie haben Fragen? Wir haben die Antwort! empto - Ihr digitaler Abfallassistent. Wir bieten ' +
        'Unternehmen die Möglichkeit zur einfachen Organisation ihrer Entsorgungsvorgänge.',
      homepageWasteProducer:
        'empto® ist Ihr digitaler Abfallassistent. Wir bieten Unternehmen die Möglichkeit ' +
        'zur Organisation ihrer Entsorgungsvorgänge - einfach, transparent und rechtssicher!',
      homepageWasteDisposer: 'Lorem ipsum',
      legalNotice:
        'empto - Ihr digitaler Abfallassistent. Wir bieten Unternehmen die Möglichkeit zur ' +
        'Organisation ihrer Entsorgungsvorgänge - einfach, transparent und rechtssicher!',
      imprint:
        'Das Impressum und die Kontaktdaten von empto - Ihrem digitalen Abfallassistenten, finden Sie hier.',
      login:
        'empto - Ihr digitaler Abfallassistent. Wir bieten Unternehmen die Möglichkeit zur Organisation ' +
        'Ihrer Entsorgungsvorgänge - einfach, transparent und rechtssicher!',
      privacy:
        'empto - Ihr digitaler Abfallassistent. Hier finden Sie die komplette Datenschutzerklärung ' +
        'von empto im Überblick.',
      register:
        'Registrieren Sie sich noch heute bei empto und genießen Sie alle Vorteile Ihres digitalen ' +
        'Abfallassistenten. Wir bieten Unternehmen die Möglichkeit zur Organisation ihrer Entsorgungsvorgänge - ' +
        'einfach, transparent und rechtssicher!',
      wasteType:
        'Hier finden Sie eine Übersicht der Abfallarten, die über empto entsorgt werden. empto - Ihr ' +
        'digitaler Abfallassistent. Einfach, transparent und rechtssicher!',
    },

    pageTitles: {
      agreementDetails: 'Vereinbarung %{agreementId}',
      agreements: 'Vereinbarungen',
      agreementsArchive: 'Vereinbarungen (Archiv)',
      autoOffers: 'Angebotsassistent',
      certificateArchive: 'Dokumentenarchiv',
      certificateDetails: 'Nachweise',
      certificateDetailsReview: 'Nachweise',
      certificates: 'Nachweise',
      claimProofDetails: 'Reklamationen',
      claimProofs: 'Reklamationen',
      companyAddresses: 'Standorte',
      companyProfile: 'Stammdaten',
      companyProfileDetails: 'Stammdaten',
      collectiveInvoicing: 'Sammelabrechnung',
      createInquiry: 'Anfrage erstellen',
      createOffer: 'Angebot abgeben',
      discounts: 'Rabatte',
      downloads: 'Downloads | empto',
      dszPriceManagement: 'Preisverwaltung',
      executionProofDetails: 'Leistungsnachweise',
      executionProofs: 'Leistungsnachweise',
      forgotPassword: 'Passwort vergessen',
      forgotPasswordDone: 'Passwort vergessen',
      forgotPasswordReset: 'Passwort vergessen',
      homepageWasteDisposer: 'empto | Ihr digitaler Abfallassistent',
      homepageWasteProducer: 'empto | Ihr digitaler Abfallassistent',
      homepagePrivateCustomer: 'empto | Ihr digitaler Abfallassistent',
      imprint: 'Impressum | empto',
      indexValues: 'Indexwerte',
      internalDownloads: 'Interne Downloads',
      inquiryDetails: 'Anfrage %{inquiryId}',
      inquiryOfferDetails: 'Anfrage %{inquiryId} - Angebot %{offerId}',
      inquiryOffers: 'Anfrage %{inquiryId} - Angebotsvergleich',
      invoices: 'Rechnungen/Gutschriften',
      invoiceCheck: 'Belegprüfung',
      invoiceCheckOverview: 'Prüfung für Beleg',
      legalNotice: 'Rechtliche Hinweise | empto',
      login: 'Anmelden | empto',
      maklerpremiumCreateOffer: 'Angebot erstellen',
      maklerpremiumAddAgreement: 'Vereinbarung erstellen',
      maklerpremiumAddInvoice: 'Beleg erstellen',
      maklerpremiumWasteProducer: 'Kundenverwaltung',
      maklerpremiumWasteDisposer: 'Partnerverwaltung',
      myInquiries: 'Meine Anfragen',
      myUser: 'Benutzer',
      contactPerson: 'Ansprechpartner',
      offerDetails: 'Mein Angebot %{offerId}',
      offerOverview: 'Mein Angebot %{offerId}',
      offers: 'Meine Angebote',
      openInquiries: 'Offene Anfragen',
      orderDetails: 'Auftrag %{orderId}',
      orders: 'Aufträge',
      ordersArchive: 'Aufträge (Archiv)',
      pastAgreements: 'Vereinbarung %{agreementId} - Aufträge',
      paymentMethods: 'Zahlungsarten',
      privacy: 'Datenschutz | empto',
      register: 'Registrieren | empto',
      registerDone: 'Registrierung erfolgreich',
      requestDemo: 'Demo anfordern | empto',
      tvpCreateContract: 'Vertrag anlegen',
      userActivate: 'Aktivierung',
      userProfile: 'Mein Profil',
      wastemanager: 'Abfallmanager',
      wastemanagerArchive: 'Abfallmanager Archiv',
      wasteType: 'Abfallarten | empto',
      workload: 'Arbeitsvorrat',
      comCenter: 'Kommunikationscenter',
    },
    wasteTypeDetailsPage: {
      contact: {
        label:
          'Dies ist eine Beispielliste, die nicht abschließend ist. ' +
          'Bei weiteren Fragen zu Ihrem Abfall, kontaktieren Sie uns gern unter ' +
          `<a href='tel:${phoneValue}'>${phoneString}</a>` +
          ` oder <a href='mailto:${email}'>${email}</a>, das Team von ` +
          'empto hilft Ihnen gerne weiter.',
      },
    },
    legacyBrowserNotification: {
      headline: 'www.empto.de ist für Ihre Browserversion nicht optimiert.',
      recommend:
        'Bitte aktualisieren Sie Ihren Browser oder verwenden Sie einen von ' +
        "<a href='http://outdatedbrowser.com/de' target='_blank' rel='noopener noreferrer'>" +
        'outdatedbrowser.com</a> empfohlenen Browser um alle Funktionen nutzen zu können.',
      contact: {
        information:
          'Das empto Team steht Ihnen für weitere Fragen gerne zur Verfügung.',
        phone: `Telefon: <a href='tel:${phoneValue}'>${phoneString}</a> (Montag - Freitag, 8 Uhr - 18 Uhr)`,
        email: `E-Mail: <a href='mailto:${email}'>${email}</a>`,
      },
      button: 'Weiter zu empto',
    },
    workload: {
      subtitle:
        'Beachten Sie, dass nur 50 Datensätze pro Kategorie ausgeliefert werden. Nutzen Sie die Filter um konkretere Daten zu erhalten.',
      totalEntries: 'Anzahl aller Einträge: ',
      totalEntriesTimestamp: 'Zuletzt aktualisiert: %{timestamp}',
      customerName: 'Kunde',
      partnerName: 'Partner',
      completion: 'Arbeitsvorrat',
      status: {
        pending: 'In Prüfung',
        accepted: 'Akzeptiert',
        declined: 'Reklamiert',
      },
      category: {
        containerChanged: 'Behälteränderung',
        priceagreement: 'Preisvereinbarung',
        claim: 'Reklamation',
        otherService: 'Sonstige Dienstleistung',
        cancelledPaidDocument: 'Stornierung (bezahlter Beleg)',
        relabeling: 'Umdeklarierung',
        additionalRent: 'Stellzeit',
        efb: 'Efb Zertifikat',
        epdRotation: 'Turnusbearbeitung',
      },
      exportModal: {
        title: 'Arbeitsvorrat CSV Export',
      },
    },
    instruction: {
      button: {
        text: 'Anleitung',
      },
    },
    taskLog: {
      status: {
        success: 'Erfolgreich',
        running: 'läuft...',
        error: 'Fehlgeschlagen',
      },
    },
  },
}
