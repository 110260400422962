import './style.scss'

import React, { FC, useMemo, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import Select from 'react-select'
import Spinner from 'react-spinkit'

import { localizeDate } from 'helper/general'
import { Textarea } from 'components/common/Textarea'
import { Button } from 'components/common/Button'
import { createLoadingSelector } from 'selectors/loading'

import { ErrorMessage } from '../ErrorMessage'

export interface OrderManagementFormProps {
  handleConfirmation: () => any
  handleCancellation: (
    offer_id: number,
    offer_hash: string,
    user_id: number,
    reason: string,
    reasonText?: string,
  ) => void
  orderDetails: OrderForDisposerManagement
}

export const OrderManagementForm: FC<OrderManagementFormProps> = ({
  handleConfirmation,
  handleCancellation,
  orderDetails,
}) => {
  const location = useLocation()
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search],
  )
  const isLoading = useSelector(
    createLoadingSelector(['GET_ORDER_FOR_DISPOSER_MANAGEMENT']),
  )

  const cancelReasons = [
    {
      value: 'noContainer',
      label: I18n.t(
        'WasteDisposerOrderManagementPageTranslations.cancel.options.noContainer',
      ),
    },
    {
      value: 'vehicleDefective',
      label: I18n.t(
        'WasteDisposerOrderManagementPageTranslations.cancel.options.vehicleDefective',
      ),
    },
    {
      value: 'facilityAtCapacity',
      label: I18n.t(
        'WasteDisposerOrderManagementPageTranslations.cancel.options.facilityAtCapacity',
      ),
    },
    {
      value: 'badCustomerExperience',
      label: I18n.t(
        'WasteDisposerOrderManagementPageTranslations.cancel.options.badCustomerExperience',
      ),
    },
    {
      value: 'other',
      label: I18n.t(
        'WasteDisposerOrderManagementPageTranslations.cancel.options.other',
      ),
    },
  ]

  const [selectedForm, setSelectedForm] = useState('confirm')
  const [cancelReason, setCancelReason] = useState('')
  const [otherReasonText, setOtherReasonText] = useState('')

  const switchForm = event => {
    setSelectedForm(event.target.value)
  }

  // using react-select's styling to prevent styles from getting overwritten
  const customStyles = {
    option: provided => ({
      ...provided,
      // border color $color-geyser
      borderBottom: '1px solid #d7e1e1',
      padding: 15,
      textAlign: 'center',
    }),
    control: provided => ({
      ...provided,
      padding: 10,
      lineHeight: 1.25,
      border: 'none',
      // color $color-geyser
      boxShadow: '0 0 10px 0 #d7e1e1',
      '&:hover': {
        border: 'none',
      },
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    placeholder: provided => ({
      ...provided,
      textAlign: 'center',
    }),
    singleValue: provided => ({
      ...provided,
      textAlign: 'center',
    }),
  }

  const getForm = form => {
    switch (form) {
      // Confirm button
      case 'confirm':
        return (
          <Button
            onClick={handleConfirmation}
            className='submit-button backgroundColor'
          >
            {I18n.t('WasteDisposerOrderManagementPageTranslations.confirm')}
          </Button>
        )
      // Cancel form
      case 'cancel':
        return (
          <div className='order-management-form__forms__cancel'>
            <Select
              styles={customStyles}
              classNamePrefix='themedSelect'
              isSearchable={false}
              placeholder={I18n.t(
                'WasteDisposerOrderManagementPageTranslations.cancel.placeholder',
              )}
              defaultValue={null}
              onChange={option => option && setCancelReason(option.value)}
              options={cancelReasons}
            />
            {cancelReason === 'other' && (
              <Textarea
                name={'cancelReason'}
                className='margin-regular-top'
                onChange={event => setOtherReasonText(event.target.value)}
                placeholder={I18n.t(
                  'WasteDisposerOrderManagementPageTranslations.cancel.optionOtherPlaceholder',
                )}
                maxLength={300}
                value={otherReasonText}
              />
            )}
            <Button
              onClick={() => {
                handleCancellation(
                  Number(searchParams.get('order_id') ?? -1),
                  searchParams.get('order_hash') ?? '',
                  Number(searchParams.get('user_id') ?? -1),
                  cancelReason,
                  otherReasonText,
                )
              }}
              key='2'
              className='submit-button margin-regular-top backgroundColor'
              isDisabled={cancelReason === ''}
            >
              {I18n.t(
                'WasteDisposerOrderManagementPageTranslations.cancel.buttonText',
              )}
            </Button>
          </div>
        )
      // Postpone info
      case 'postpone':
        return (
          <div className='order-management-form__forms__postpone'>
            <p>
              <b>
                {I18n.t(
                  'WasteDisposerOrderManagementPageTranslations.postpone.title',
                )}
              </b>
            </p>
            <p>
              {I18n.t(
                'WasteDisposerOrderManagementPageTranslations.postpone.text',
              )}
            </p>
            <p>
              {I18n.t(
                'WasteDisposerOrderManagementPageTranslations.postpone.hotline',
              )}
            </p>
          </div>
        )
    }
  }

  // Add a loading spinner. This should usually only be visible for a fraction of a second, but it's better than
  // showing an error message and then switching to the right layout
  if (isLoading) {
    return <Spinner name='circle' />
  }

  return (
    <div className='order-management-form__container'>
      <h1 className='headline headline--font-type-sans-serif uk-h2'>
        <b>{I18n.t('WasteDisposerOrderManagementPageTranslations.title')}</b>
      </h1>
      {orderDetails?.id ? (
        <div className='order-management-form'>
          <div className='order-management-form__section'>
            <span>
              <b>
                {I18n.t(
                  'WasteDisposerOrderManagementPageTranslations.orderDetails.title',
                )}
              </b>
            </span>
          </div>
          <div className='order-management-form__section'>
            <div className='order-management-form__section__content'>
              <p className='order-management-form__order-row'>
                <b>
                  {I18n.t(
                    'WasteDisposerOrderManagementPageTranslations.orderDetails.id',
                  )}
                  :
                </b>
                <span>{orderDetails.id}</span>
              </p>
              <p className='order-management-form__order-row'>
                <b>
                  {I18n.t(
                    'WasteDisposerOrderManagementPageTranslations.orderDetails.customer',
                  )}
                  :
                </b>
                <span>{orderDetails.customer_name}</span>
              </p>
              <p className='order-management-form__order-row'>
                <b>
                  {I18n.t(
                    'WasteDisposerOrderManagementPageTranslations.orderDetails.street',
                  )}
                  :
                </b>
                <span>{orderDetails.delivery_address_street}</span>
              </p>
              <p className='order-management-form__order-row'>
                <b>
                  {I18n.t(
                    'WasteDisposerOrderManagementPageTranslations.orderDetails.plzAndTown',
                  )}
                  :
                </b>
                <span>{orderDetails.delivery_address}</span>
              </p>
              <p className='order-management-form__order-row'>
                <b>
                  {I18n.t(
                    'WasteDisposerOrderManagementPageTranslations.orderDetails.container',
                  )}
                  :
                </b>
                <span>
                  {orderDetails.container_capacity}{' '}
                  {orderDetails.container_name}
                </span>
              </p>
              <p className='order-management-form__order-row'>
                <b>
                  {I18n.t(
                    'WasteDisposerOrderManagementPageTranslations.orderDetails.amount',
                  )}
                  :
                </b>
                <span>{orderDetails.number_of_containers}</span>
              </p>
              <p className='order-management-form__order-row'>
                <b>
                  {I18n.t(
                    'WasteDisposerOrderManagementPageTranslations.orderDetails.fraction',
                  )}
                  :
                </b>
                <span>
                  {orderDetails.fraction ?? orderDetails.fine_fraction}
                </span>
              </p>
              {orderDetails.fraction && (
                <p className='order-management-form__order-row'>
                  <b>
                    {I18n.t(
                      'WasteDisposerOrderManagementPageTranslations.orderDetails.fineFraction',
                    )}
                    :
                  </b>
                  <span>{orderDetails.fine_fraction}</span>
                </p>
              )}
              <p className='order-management-form__order-row'>
                <b>
                  {I18n.t(
                    'WasteDisposerOrderManagementPageTranslations.orderDetails.delivery',
                  )}
                  :
                </b>
                <span>{localizeDate(orderDetails.delivery_date)}</span>
              </p>
              <p className='order-management-form__order-row'>
                <b>
                  {I18n.t(
                    'WasteDisposerOrderManagementPageTranslations.orderDetails.location',
                  )}
                  :
                </b>
                <span>{orderDetails.position_on_public_ground_text}</span>
              </p>
              {orderDetails.position_on_public_ground_text === 'öffentlich' && (
                <p className='order-management-form__order-row'>
                  <b>
                    {I18n.t(
                      'WasteDisposerOrderManagementPageTranslations.orderDetails.approval',
                    )}
                    :
                  </b>
                  <span>
                    {I18n.t(
                      'WasteDisposerOrderManagementPageTranslations.orderDetails.approvalText',
                    )}
                  </span>
                </p>
              )}
            </div>
          </div>
          <div className='order-management-form__section'>
            <div className='order-management-form__form-switcher'>
              <label className='container'>
                <span>
                  {I18n.t(
                    'WasteDisposerOrderManagementPageTranslations.form.confirm',
                  )}
                </span>
                <input
                  type='radio'
                  value='confirm'
                  name='form-switcher'
                  checked={selectedForm === 'confirm'}
                  onClick={switchForm}
                  onChange={() => undefined} // only defined to avoid log warning
                />
                <span className='checkmark afterBackgroundColor' />
              </label>
              <label className='container'>
                <span>
                  {I18n.t(
                    'WasteDisposerOrderManagementPageTranslations.form.cancel',
                  )}
                </span>
                <input
                  type='radio'
                  value='cancel'
                  name='form-switcher'
                  checked={selectedForm === 'cancel'}
                  onClick={switchForm}
                  onChange={() => undefined} // only defined to avoid log warning
                />
                <span className='checkmark afterBackgroundColor' />
              </label>
              <label className='container'>
                <span>
                  {I18n.t(
                    'WasteDisposerOrderManagementPageTranslations.form.postpone',
                  )}
                </span>
                <input
                  type='radio'
                  value='postpone'
                  name='form-switcher'
                  checked={selectedForm === 'postpone'}
                  onClick={switchForm}
                  onChange={() => undefined} // only defined to avoid log warning
                />
                <span className='checkmark afterBackgroundColor' />
              </label>
            </div>
          </div>
          <div className='order-management-form__section order-management-form__forms'>
            {getForm(selectedForm)}
          </div>
        </div>
      ) : (
        <ErrorMessage
          title={I18n.t(
            'WasteDisposerOrderManagementPageTranslations.errorMessage.general.title',
          )}
          subTitle={I18n.t(
            'WasteDisposerOrderManagementPageTranslations.errorMessage.general.subTitle',
          )}
          text={I18n.t(
            'WasteDisposerOrderManagementPageTranslations.errorMessage.general.text',
          )}
        />
      )}
    </div>
  )
}
