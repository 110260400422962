import keyMirror from 'keymirror'

const GET_DSZ_INVOICES = keyMirror({
  GET_DSZ_INVOICES_REQUEST: null,
  GET_DSZ_INVOICES_SUCCESS: null,
  GET_DSZ_INVOICES_ERROR: null,
})

const UPDATE_DSZ_INVOICE = keyMirror({
  UPDATE_DSZ_INVOICE_REQUEST: null,
  UPDATE_DSZ_INVOICE_SUCCESS: null,
  UPDATE_DSZ_INVOICE_ERROR: null,
})

const CREATE_CUSTOM_DSZ_INVOICE = keyMirror({
  CREATE_CUSTOM_DSZ_INVOICE_REQUEST: null,
  CREATE_CUSTOM_DSZ_INVOICE_SUCCESS: null,
  CREATE_CUSTOM_DSZ_INVOICE_ERROR: null,
})

const TRIGGER_MY_NATURE_EXPORTS = keyMirror({
  TRIGGER_MY_NATURE_EXPORTS_REQUEST: null,
  TRIGGER_MY_NATURE_EXPORTS_SUCCESS: null,
  TRIGGER_MY_NATURE_EXPORTS_ERROR: null,
})

const GET_CONTRACTS = keyMirror({
  GET_CONTRACTS_REQUEST: null,
  GET_CONTRACTS_SUCCESS: null,
  GET_CONTRACTS_ERROR: null,
})

const GET_DSZ_FRACTIONS = keyMirror({
  GET_DSZ_FRACTIONS_REQUEST: null,
  GET_DSZ_FRACTIONS_SUCCESS: null,
  GET_DSZ_FRACTIONS_ERROR: null,
})

const GET_DSZ_FRACTION_PRICES = keyMirror({
  GET_DSZ_FRACTION_PRICES_REQUEST: null,
  GET_DSZ_FRACTION_PRICES_SUCCESS: null,
  GET_DSZ_FRACTION_PRICES_ERROR: null,
})

const CREATE_DSZ_FRACTION_PRICE = keyMirror({
  CREATE_DSZ_FRACTION_PRICE_REQUEST: null,
  CREATE_DSZ_FRACTION_PRICE_SUCCESS: null,
  CREATE_DSZ_FRACTION_PRICE_ERROR: null,
})

const UPDATE_DSZ_FRACTION_PRICE = keyMirror({
  UPDATE_DSZ_FRACTION_PRICE_REQUEST: null,
  UPDATE_DSZ_FRACTION_PRICE_SUCCESS: null,
  UPDATE_DSZ_FRACTION_PRICE_ERROR: null,
})

export default {
  ...CREATE_CUSTOM_DSZ_INVOICE,
  ...CREATE_DSZ_FRACTION_PRICE,
  ...GET_CONTRACTS,
  ...GET_DSZ_FRACTION_PRICES,
  ...GET_DSZ_FRACTIONS,
  ...GET_DSZ_INVOICES,
  ...TRIGGER_MY_NATURE_EXPORTS,
  ...UPDATE_DSZ_FRACTION_PRICE,
  ...UPDATE_DSZ_INVOICE,
}
