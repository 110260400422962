import './style.scss'

import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import moment from 'moment'
import React, { Component } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { arrayOf, shape } from 'prop-types'

import FilePreviewComponent from 'components/common/FilePreview'
import FileComment from 'components/common/fileUpload/FileUpload/components/FileComment'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { EXECUTION_PROOF_STATES } from 'components/executionproof/constants'
import { getName } from 'helper/translations'
import { ExecutionProofSmallScheme } from 'schemes/executionproof'

export class OrderExecutionProofsComponent extends Component {
  static propTypes = {
    executionProofs: arrayOf(shape(ExecutionProofSmallScheme)),
  }

  static defaultProps = {
    executionProofs: [],
  }

  state = {
    selectedFile: null,
  }

  /**
   * @description Is called when a preview is clicked. We then display a modal with the preview.
   * @param file
   */
  handlePreviewClick = file => {
    this.setState({
      selectedFile: file,
    })
  }

  render() {
    const idSelectedFileModalHeadline = uniqueId()

    return (
      <div className='order-execution-proofs'>
        {this.props.executionProofs
          .sort((a, b) => moment(a.created_at).isSameOrAfter(b.created_at))
          .map((proof, i) => (
            <div
              key={proof.id}
              className={classNames({ 'uk-margin-small-top': i !== 0 })}
            >
              <p className='uk-margin-remove'>
                {`${I18n.t('general.status')}: ${getName(
                  proof.status,
                  EXECUTION_PROOF_STATES,
                  true,
                )}`}
              </p>

              <p className='uk-margin-remove'>
                <Translate
                  value='fileUpload.lastmodifiedAt'
                  date={moment(proof.created_at).format('L HH:mm')}
                />
              </p>

              <div className='uk-grid uk-grid-small uk-child-width-1-3 uk-child-width-1-5@m uk-margin-small-top'>
                {proof.attachments.map(attachment => (
                  <FilePreviewComponent
                    alt={attachment.thumbnail}
                    key={attachment.id}
                    modalTitle={
                      attachment.name
                        ? attachment.name
                        : I18n.t('general.previewFileModal')
                    }
                    src={attachment.thumbnail}
                    file={attachment}
                    showMultiple
                    onGetComment={() => attachment.text || ''}
                  />
                ))}
              </div>
            </div>
          ))}

        {this.state.selectedFile && (
          <Modal
            ariaDescribedBy={idSelectedFileModalHeadline}
            isOpen={!!this.state.selectedFile}
            onClose={() => this.setState({ selectedFile: null })}
          >
            <ModalHeader
              onClose={() => this.setState({ selectedFile: null })}
              title={this.state.selectedFile.name}
              titleId={idSelectedFileModalHeadline}
            />

            <FileComment
              file={this.state.selectedFile}
              onFormSubmit={() => this.setState({ selectedFile: null })}
              allowComment={false}
            />
          </Modal>
        )}
      </div>
    )
  }
}

export default OrderExecutionProofsComponent
