import { useFormikContext } from 'formik'
import React, { FC, useContext } from 'react'
import { I18n } from 'react-i18nify'

import { FormStepSummaryRow } from 'components/common/FormSteps'
import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'

import {
  EPDAddInvoiceFormContext,
  EPDAddInvoiceFormValues,
} from '../EPDAddInvoiceFormSteps'

export const InvoiceReceiverSummary: FC = () => {
  const { values } = useFormikContext<EPDAddInvoiceFormValues>()
  const { editStep } = useContext(EPDAddInvoiceFormContext)

  return (
    <div className='maklerpremium-step-summary'>
      <div className='maklerpremium-step-summary_grid-body'>
        <FormStepSummaryRow
          label={I18n.t(
            'addInvoicePageTranslations.epd.steps.1.fields.empto_external_number.label',
          )}
          value={values.empto_external_number}
        />
        <FormStepSummaryRow
          label={I18n.t(
            'addInvoicePageTranslations.epd.steps.1.fields.company_name.label',
          )}
          value={values.company_name}
        />
        <FormStepSummaryRow
          label={I18n.t(
            'addInvoicePageTranslations.epd.steps.1.fields.invoice_address.label',
          )}
          value={`${values.invoice_address?.display_name}`}
        />
        {values.selected_company?.tax_id && (
          <FormStepSummaryRow
            label={I18n.t(
              'addInvoicePageTranslations.epd.steps.1.fields.tax_id.label',
            )}
            value={values.selected_company.tax_id}
          />
        )}
        {values.different_service_address && (
          <FormStepSummaryRow
            label={I18n.t(
              'addInvoicePageTranslations.epd.steps.1.fields.different_service_address.label',
            )}
            value={values.different_service_address.display_name}
          />
        )}
      </div>
      <div className='sidebar'>
        <ButtonLink
          className='sidebar__edit-link'
          linkColor={BUTTON_LINK_COLOR.PRIMARY}
          onClick={editStep}
        >
          {I18n.t('createInquiryTranslations.form.button.editStep')}
        </ButtonLink>
      </div>
    </div>
  )
}
