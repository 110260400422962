import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'

import { NUMBER_FORMAT } from '../../dashboard/components/Classification/ClassificationTile'

export const VALUE_CHANGE_STATUS = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
}

export interface ValueChangeProps {
  value: number
  offsetValue: number
  format?: NUMBER_FORMAT
}

export const ValueChange: FC<ValueChangeProps> = ({
  value,
  offsetValue,
  format = NUMBER_FORMAT.INTEGER,
}) => {
  const valueDifference = value - offsetValue
  const status = (valueCheck: number): string => {
    switch (Math.sign(valueCheck)) {
      case 1:
        return VALUE_CHANGE_STATUS.POSITIVE
      case -1:
        return VALUE_CHANGE_STATUS.NEGATIVE
      default:
        return VALUE_CHANGE_STATUS.NEUTRAL
    }
  }

  let style: any = { style: 'decimal', minimumFractionDigits: 0 }
  if (format === NUMBER_FORMAT.DECIMAL) {
    style = {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  } else if (format === NUMBER_FORMAT.CURRENCY) {
    style = { style: 'currency', currency: 'EUR' }
  }

  return (
    <div
      className={classNames(
        'value-change',
        `value-change--${status(valueDifference)}`,
      )}
    >
      {new Intl.NumberFormat('de-DE', style).format(valueDifference)}
    </div>
  )
}
