import { useFormikContext } from 'formik'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { InputText } from '../common/InputText'
import { RadioButton } from '../common/RadioButton'

export interface PrivateCustomerValues {
  is_male: boolean
  first_name: string
  last_name: string
}

export const PrivateCustomerFields: FC = () => {
  const {
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    submitCount,
    values,
  } = useFormikContext<PrivateCustomerValues>()

  const isFieldValid = name =>
    values[name] && values[name].length > 0 && !errors[name]
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0 ? errors[name] : undefined

  return (
    <>
      {/* eslint-disable jsx-a11y/label-has-associated-control */}
      {/* eslint-disable jsx-a11y/label-has-for */}
      <label className='read-only-field__label read-only-field__label-margin-top'>
        {I18n.t('maklerpremium.addWasteProducerModal.form.salutation')}
      </label>
      <div className='uk-grid uk-child-width-1-1 uk-child-width-1-2@m'>
        <RadioButton
          isChecked={values.is_male}
          label={I18n.t('constant.gender.mr')}
          name='is_male'
          onChange={(event: React.ChangeEvent<any>) => {
            if (event.target.value === 'on') {
              setFieldValue('is_male', true)
            }
          }}
        />
        <RadioButton
          isChecked={!values.is_male}
          label={I18n.t('constant.gender.mrs')}
          name='is_male'
          onChange={(event: React.ChangeEvent<any>) => {
            if (event.target.value === 'on') {
              setFieldValue('is_male', false)
            }
          }}
        />
      </div>
      <InputText
        error={getFieldError}
        label={
          <Translate value='maklerpremium.addWasteProducerModal.form.first_name' />
        }
        name='first_name'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t(
          'maklerpremium.addWasteProducerModal.form.first_name',
        )}
        value={values.first_name}
        showCheckmark={isFieldValid}
        withCheckmark
      />
      <InputText
        error={getFieldError}
        label={
          <Translate value='maklerpremium.addWasteProducerModal.form.last_name' />
        }
        name='last_name'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t(
          'maklerpremium.addWasteProducerModal.form.last_name',
        )}
        value={values.last_name}
        showCheckmark={isFieldValid}
        withCheckmark
      />
    </>
  )
}
