import './style.scss'

import { Form, useFormikContext } from 'formik'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { createErrorSelector } from '../../selectors/error'
import { createLoadingSelector } from '../../selectors/loading'
import { BUTTON_BACKGROUND_COLOR } from '../common/Button'
import { DropDownInput } from '../common/DropDownInput'
import { ApiValidationMessages } from '../common/Form/components/ApiValidationMessages'
import { InputText } from '../common/InputText'
import { ProgressButton } from '../common/ProgressButton'
import { RadioButton } from '../common/RadioButton'

import { AddWasteProducerFormValues } from './AddWasteProducerWrapper'
import { CustomerAddressFields } from './CustomerAddressFields'
import { PrivateCustomerFields } from './PrivateCustomerFields'
import { TaxSectionFields } from './TaxSectionFields'

export const AddWasteProducerInnerForm: FC = () => {
  const isLoading = useSelector(createLoadingSelector(['CREATE_CUSTOMER']))
  const apiError = useSelector<any, ApiError>(
    createErrorSelector(['GET_COMPANY', 'UPDATE_COMPANY']),
  )

  const {
    errors,
    handleSubmit,
    handleChange,
    handleBlur,
    isValid,
    isSubmitting,
    setFieldValue,
    setValues,
    submitCount,
    touched,
    values,
  } = useFormikContext<AddWasteProducerFormValues>()

  const isFieldValid = name => values[name].length > 0 && !errors[name]
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0 ? errors[name] : undefined
  return (
    <>
      <div className='uk-modal-body'>
        <Form
          className='uk-panel makler-premium-form'
          onSubmit={handleSubmit}
          noValidate // Disable browser validation
        >
          {/* This Api Validation message is needed here to get a customized error message
             (i.e.: If for some reason the button is visible after action has been done,
             user gets the info that the order eta was already set) */}
          <ApiValidationMessages error={apiError} />
          <div className='uk-width-1-1 uk-margin-bottom'>
            {/* eslint-disable jsx-a11y/label-has-associated-control */}
            {/* eslint-disable jsx-a11y/label-has-for */}
            <label className='read-only-field__label'>
              {I18n.t('maklerpremium.addWasteProducerModal.form.customer_type')}
            </label>
            <div className='uk-grid uk-child-width-1-1 uk-child-width-1-2@m'>
              <RadioButton
                isChecked={values.is_company}
                label={I18n.t(
                  'maklerpremium.addWasteProducerModal.form.is_company_true',
                )}
                name='is_company'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    setValues({
                      ...values,
                      is_company: true,
                    })
                  }
                }}
              />
              <RadioButton
                isChecked={!values.is_company}
                label={I18n.t(
                  'maklerpremium.addWasteProducerModal.form.is_company_false',
                )}
                name='is_company'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    setValues({
                      ...values,
                      is_company: false,
                    })
                  }
                }}
              />
            </div>
            {values.is_company ? (
              <>
                <InputText
                  error={getFieldError}
                  label={
                    <Translate value='maklerpremium.addWasteProducerModal.form.company_name' />
                  }
                  name='company_name'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t(
                    'maklerpremium.addWasteProducerModal.form.company_name',
                  )}
                  value={values.company_name}
                  showCheckmark={isFieldValid}
                  withCheckmark
                />
              </>
            ) : (
              <PrivateCustomerFields />
            )}
            <CustomerAddressFields />
            {values.is_company && (
              <div className='tax-section'>
                <TaxSectionFields />
              </div>
            )}
            <DropDownInput
              choices={[0, 5, 10, 15, 20].map(item => ({
                optionValue: item,
                optionLabel: `${item} %`,
              }))}
              error={
                submitCount > 0 && touched.permanent_discount
                  ? (errors.permanent_discount as string)
                  : ''
              }
              label={I18n.t(
                'maklerpremium.addWasteProducerModal.form.permanent_discount',
              )}
              name='permanent_discount'
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.permanent_discount}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            {/* eslint-disable jsx-a11y/label-has-associated-control */}
            {/* eslint-disable jsx-a11y/label-has-for */}
            <label className='read-only-field__label read-only-field__label-margin-top'>
              {I18n.t(
                'maklerpremium.addWasteProducerModal.form.has_central_contact',
              )}
            </label>
            <div className='uk-grid uk-child-width-1-1 uk-child-width-1-2@m'>
              <RadioButton
                isChecked={values.has_central_contact}
                label={I18n.t('general.yes')}
                name='has_central_contact'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    setValues({
                      ...values,
                      has_central_contact: true,
                    })
                  }
                }}
              />
              <RadioButton
                isChecked={!values.has_central_contact}
                label={I18n.t('general.no')}
                name='has_central_contact'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    setValues({
                      ...values,
                      has_central_contact: false,
                    })
                  }
                }}
              />
            </div>
            {values.has_central_contact && (
              <>
                <InputText
                  error={getFieldError}
                  label={
                    <Translate value='maklerpremium.addWasteProducerModal.form.central_contact_email' />
                  }
                  name='central_contact_email'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t(
                    'maklerpremium.addWasteProducerModal.form.central_contact_email',
                  )}
                  value={values.central_contact_email}
                  showCheckmark={isFieldValid}
                  withCheckmark
                />
                <InputText
                  error={getFieldError}
                  label={
                    <Translate value='maklerpremium.addWasteProducerModal.form.central_contact_phone' />
                  }
                  name='central_contact_phone'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder={I18n.t(
                    'maklerpremium.addWasteProducerModal.form.central_contact_phone',
                  )}
                  value={values.central_contact_phone}
                  showCheckmark={isFieldValid}
                  withCheckmark
                />
              </>
            )}
            <p className='form-section-headline'>
              <Translate value='maklerpremium.addWasteProducerModal.form.contact_person' />
            </p>
            <label className='read-only-field__label'>
              {I18n.t('maklerpremium.addWasteProducerModal.form.salutation')}
            </label>
            <div className='uk-grid uk-child-width-1-1 uk-child-width-1-2@m'>
              <RadioButton
                isChecked={values.contact_person_is_male}
                label={I18n.t('constant.gender.mr')}
                name='contact_person_is_male'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    setFieldValue('contact_person_is_male', true)
                  }
                }}
              />
              <RadioButton
                isChecked={!values.contact_person_is_male}
                label={I18n.t('constant.gender.mrs')}
                name='contact_person_is_male'
                onChange={(event: React.ChangeEvent<any>) => {
                  if (event.target.value === 'on') {
                    setFieldValue('contact_person_is_male', false)
                  }
                }}
              />
            </div>
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteProducerModal.form.contact_person_first_name' />
              }
              name='contact_person_first_name'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteProducerModal.form.contact_person_first_name',
              )}
              value={values.contact_person_first_name}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteProducerModal.form.contact_person_last_name' />
              }
              name='contact_person_last_name'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteProducerModal.form.contact_person_last_name',
              )}
              value={values.contact_person_last_name}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            {values.is_company && (
              <InputText
                error={getFieldError}
                label={
                  <Translate value='maklerpremium.addWasteProducerModal.form.contact_person_position' />
                }
                name='contact_person_position'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder={I18n.t(
                  'maklerpremium.addWasteProducerModal.form.contact_person_position',
                )}
                value={values.contact_person_position}
                showCheckmark={isFieldValid}
                withCheckmark
              />
            )}
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteProducerModal.form.contact_person_phone' />
              }
              name='contact_person_phone'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteProducerModal.form.contact_person_phone',
              )}
              value={values.contact_person_phone}
              showCheckmark={isFieldValid}
              withCheckmark
            />
            <InputText
              error={getFieldError}
              label={
                <Translate value='maklerpremium.addWasteProducerModal.form.contact_person_email' />
              }
              name='contact_person_email'
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={I18n.t(
                'maklerpremium.addWasteProducerModal.form.contact_person_email',
              )}
              value={values.contact_person_email}
              showCheckmark={isFieldValid}
              withCheckmark
            />
          </div>
          <div className='uk-text-right'>
            <ProgressButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              isDisabled={
                (submitCount > 0 && !isValid) || isLoading || isSubmitting
              }
              isLoading={isLoading || isSubmitting}
              onClick={handleSubmit}
            >
              <Translate value='general.button.save' />
            </ProgressButton>
          </div>
        </Form>
      </div>
    </>
  )
}
