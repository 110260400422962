import keyMirror from 'keymirror'

const GET_MY_AUTO_OFFERS = keyMirror({
  GET_MY_AUTO_OFFERS_REQUEST: null,
  GET_MY_AUTO_OFFERS_SUCCESS: null,
  GET_MY_AUTO_OFFERS_ERROR: null,
  GET_MY_AUTO_OFFERS_RESET: null,
})

const GET_AUTO_OFFER = keyMirror({
  GET_AUTO_OFFER_REQUEST: null,
  GET_AUTO_OFFER_SUCCESS: null,
  GET_AUTO_OFFER_ERROR: null,
})

const CREATE_AUTO_OFFER = keyMirror({
  CREATE_AUTO_OFFER_REQUEST: null,
  CREATE_AUTO_OFFER_SUCCESS: null,
  CREATE_AUTO_OFFER_ERROR: null,
})

const UPDATE_AUTO_OFFER = keyMirror({
  UPDATE_AUTO_OFFER_REQUEST: null,
  UPDATE_AUTO_OFFER_SUCCESS: null,
  UPDATE_AUTO_OFFER_ERROR: null,
})

const UPLOAD_AUTO_OFFERS = keyMirror({
  UPLOAD_AUTO_OFFERS_REQUEST: null,
  UPLOAD_AUTO_OFFERS_SUCCESS: null,
  UPLOAD_AUTO_OFFERS_ERROR: null,
})

const EXPORT_AUTO_OFFERS = keyMirror({
  EXPORT_AUTO_OFFERS_REQUEST: null,
  EXPORT_AUTO_OFFERS_SUCCESS: null,
})

export default {
  ...GET_MY_AUTO_OFFERS,
  ...GET_AUTO_OFFER,
  ...CREATE_AUTO_OFFER,
  ...UPDATE_AUTO_OFFER,
  ...UPLOAD_AUTO_OFFERS,
  ...EXPORT_AUTO_OFFERS,
}
