import React, { FC, useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useParams } from 'react-router'

import { exportCommunicationcenter } from 'actions/maklerpremium'
import { AsyncExportModal } from 'components/common/AsyncExportModal'
import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { DisposerCustomerInfo } from 'components/common/DisposerCustomerInfo'
import { ICON_NAME } from 'components/common/Fontello'
import { ICON_POSITION, IconButton } from 'components/common/IconButton'
import { PageHeader } from 'components/layout/PageHeader'

export const Header: FC<{
  onAddNewEntryButtonClick: (event: React.MouseEvent<HTMLElement>) => void
}> = ({ onAddNewEntryButtonClick }) => {
  const { companyId } = useParams<{ companyId: string }>()

  const [
    isCommunicationcenterExportModalOpen,
    setIsCommunicationcenterExportModalOpen,
  ] = useState(false)

  return (
    <div className='communicationcenter-page-header'>
      <PageHeader
        title={I18n.t('communicationCenterPageTranslations.headline')}
        subtitle={
          <>
            <p>{I18n.t('communicationCenterPageTranslations.introduction')}</p>
            <DisposerCustomerInfo />
          </>
        }
      >
        <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
          <IconButton
            iconName='export'
            iconPosition={ICON_POSITION.RIGHT}
            backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
            onClick={() => setIsCommunicationcenterExportModalOpen(true)}
          >
            <Translate value='general.export' />
          </IconButton>
          <IconButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            iconName={ICON_NAME.PLUS}
            iconPosition={ICON_POSITION.RIGHT}
            onClick={onAddNewEntryButtonClick}
          >
            <Translate value='communicationCenterPageTranslations.addNewEntryButtonLabel' />
          </IconButton>
        </ButtonBar>
      </PageHeader>

      <AsyncExportModal
        isOpen={isCommunicationcenterExportModalOpen}
        onClose={() => setIsCommunicationcenterExportModalOpen(false)}
        reduxSelector='EXPORT_COMMUNICATIONCENTER'
        title={I18n.t('communicationsCenterPage.exportModal.title')}
        logic={exportCommunicationcenter(Number(companyId))}
      />
    </div>
  )
}
