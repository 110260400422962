import keyMirror from 'keymirror'

import { WEEKDAYS } from '../../constants/app'
import { BREAKPOINT } from '../../constants/design'

export const INITIAL_FILTER_OPEN_WIDTH = BREAKPOINT.LARGE // expand the filter on displays > x pixels.

/**
 * These are the types that inquiries can have.
 */
export const INQUIRY_TYPE = {
  OPEN: 'open',
  MINE: 'mine',
  OVERVIEW: 'overview',
}

/**
 * These are the types of orders that inquiries can have.
 */
export enum INQUIRY_ORDER_TYPE {
  TYPE_ONE_TIME = 1,
  TYPE_RECURRING = 2,
  TYPE_ON_DEMAND = 3,
  TYPE_QUICK = 4,
}

export const INQUIRY_STANDING_ORDER_TYPES = [
  INQUIRY_ORDER_TYPE.TYPE_ON_DEMAND,
  INQUIRY_ORDER_TYPE.TYPE_RECURRING,
]

export const INQUIRY_ORDER_TYPES = [
  {
    id: INQUIRY_ORDER_TYPE.TYPE_ONE_TIME, // order just happens one time
    name: 'orderType.oneTime',
  },
  {
    id: INQUIRY_ORDER_TYPE.TYPE_RECURRING, // order is automatically triggered in intervals
    name: 'orderType.regular',
  },
  {
    id: INQUIRY_ORDER_TYPE.TYPE_ON_DEMAND, // There is a contract for a certain period but order is triggered manually.
    name: 'orderType.onDemand',
  },
  {
    id: INQUIRY_ORDER_TYPE.TYPE_QUICK, // Container is delivered, directly filled and collected.
    name: 'orderType.quick',
  },
]

/**
 * These are the service intervals for the "regular" order type.
 */
export enum INQUIRY_SERVICE_INTERVAL {
  INTERVAL_TWICE_WEEK = 1,
  INTERVAL_WEEKLY,
  INTERVAL_FORTNIGHTLY,
  INTERVAL_MONTHLY,
  INTERVAL_SIX_WEEKS,
  INTERVAL_EIGHT_WEEKS,
}

export const INQUIRY_SERVICE_INTERVALS = [
  {
    id: INQUIRY_SERVICE_INTERVAL.INTERVAL_TWICE_WEEK,
    name: 'constant.date.interval.twiceWeek',
  },
  {
    id: INQUIRY_SERVICE_INTERVAL.INTERVAL_WEEKLY,
    name: 'constant.date.interval.weekly',
  },
  {
    id: INQUIRY_SERVICE_INTERVAL.INTERVAL_FORTNIGHTLY,
    name: 'constant.date.interval.fortnightly',
  },
  {
    id: INQUIRY_SERVICE_INTERVAL.INTERVAL_MONTHLY,
    name: 'constant.date.interval.monthly',
  },
  {
    id: INQUIRY_SERVICE_INTERVAL.INTERVAL_SIX_WEEKS,
    name: 'constant.date.interval.sixWeeks',
  },
  {
    id: INQUIRY_SERVICE_INTERVAL.INTERVAL_EIGHT_WEEKS,
    name: 'constant.date.interval.eightWeeks',
  },
]

export const INQUIRY_SERVICE_INTERVAL_WEEKDAYS = WEEKDAYS.slice(0, 5)

/**
 * These are the times of day (day slots) for quick orders.
 */
export enum INQUIRY_TIME_OF_DAY {
  TIME_OF_DAY_MORNING = 1,
  TIME_OF_DAY_AFTERNOON,
  TIME_OF_DAY_ALL_DAY,
}

export const INQUIRY_TIMES_OF_DAY = [
  {
    id: INQUIRY_TIME_OF_DAY.TIME_OF_DAY_ALL_DAY,
    name: 'constant.date.timeOfDay.allDay',
  },
  {
    id: INQUIRY_TIME_OF_DAY.TIME_OF_DAY_MORNING,
    name: 'constant.date.timeOfDay.morning',
  },
  {
    id: INQUIRY_TIME_OF_DAY.TIME_OF_DAY_AFTERNOON,
    name: 'constant.date.timeOfDay.afternoon',
  },
]

/**
 * These are the times of day (day slots) for quick orders.
 */
export const INQUIRY_STATUS = {
  STATUS_OPEN: 1,
  STATUS_CANCELLED: 2,
  STATUS_CLOSED: 3,
  STATUS_CONCLUDED: 4,
}

export const INQUIRY_STATES = [
  {
    id: INQUIRY_STATUS.STATUS_OPEN,
    name: 'constant.status.open',
  },
  {
    id: INQUIRY_STATUS.STATUS_CANCELLED,
    name: 'constant.status.cancelled',
  },
  {
    id: INQUIRY_STATUS.STATUS_CLOSED,
    name: 'constant.status.closed',
  },
  {
    id: INQUIRY_STATUS.STATUS_CONCLUDED,
    name: 'constant.status.concluded',
  },
]

export const numberOfContainersList = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const INQUIRY_DETAILS_MODALS = keyMirror<{ [key: string]: any }>({
  CANCEL_INQUIRY_MODAL: null,
  CANCEL_OFFER_MODAL: null,
})

export const OFFER_CREATION_FORM_SELECTABLE_OPTIONS = {
  INPUT_DISPOSABLE_COST_TON: '1',
  INPUT_RECYCLING_PRICE: '0',
  INPUT_DISCOUNT: '1',
  INPUT_SURCHARGE: '0',
}

export const AGREEMENT_CANCELLATION_REASON = {
  REASON_OVER: 1, // Hat sich erledigt
  REASON_CLIENT_HAS_BETTER_OFFER: 3, // Kunde hat besseres Angebot vorliegen
  REASON_OTHER: 4, // Sonstiges
}

export const AGREEMENT_CANCELLATION_REASON_TEXT = [
  {
    id: AGREEMENT_CANCELLATION_REASON.REASON_OVER,
    description: 'Hat sich erledigt',
  },
  {
    id: AGREEMENT_CANCELLATION_REASON.REASON_CLIENT_HAS_BETTER_OFFER,
    description: 'Kunde hat besseres Angebot vorliegen',
  },
  {
    id: AGREEMENT_CANCELLATION_REASON.REASON_OTHER,
    description: 'Sonstiges',
  },
]

export const OFFER_CANCELLATION_REASON = {
  REASON_CLIENT_UNREACHABLE: 2, // Kunde nicht erreichbar
  REASON_EXPIRED: 5, // Abgelaufen
  ...AGREEMENT_CANCELLATION_REASON,
}

export const OFFER_CANCELLATION_REASON_TEXT = [
  {
    id: OFFER_CANCELLATION_REASON.REASON_CLIENT_UNREACHABLE,
    description: 'Kunde nicht erreichbar',
  },
  {
    id: OFFER_CANCELLATION_REASON.REASON_EXPIRED,
    description: 'Abgelaufen',
  },
  ...AGREEMENT_CANCELLATION_REASON_TEXT,
]

export const AGREEMENT_CANCELLATION_REASONS = [
  {
    id: OFFER_CANCELLATION_REASON.REASON_OVER,
    name: 'constant.offerCancellation.over',
  },
  {
    id: OFFER_CANCELLATION_REASON.REASON_CLIENT_HAS_BETTER_OFFER,
    name: 'constant.offerCancellation.clientHasBetterOffer',
  },
  {
    id: OFFER_CANCELLATION_REASON.REASON_OTHER,
    name: 'constant.offerCancellation.other',
  },
]

export const OFFER_CANCELLATION_REASONS = [
  {
    id: OFFER_CANCELLATION_REASON.REASON_CLIENT_UNREACHABLE,
    name: 'constant.offerCancellation.clientUnreachable',
  },
  ...AGREEMENT_CANCELLATION_REASONS,
].sort((a, b) => a.id - b.id)

/**
 * Add Address selection option
 */
export const ADD_ADDRESS_OPTION = {
  value: 'addAddress',
  label: 'inquiryForm.addAddress',
}

/**
 * Add Contactperson selection option
 */
export const ADD_EMAIL_USER_OPTION = {
  value: 'addEmailUser',
  label: 'inquiryForm.addEmailUser',
}

/**
 * Fractions
 */
export const FRACTIONS = {
  SHREDDING: 10,
}
