import { bool, func, number, oneOfType, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { I18n } from 'react-i18nify'

import { InputText } from 'components/common/InputText'
import { StaticCombobox } from 'components/common/StaticCombobox'
import {
  DISCOUNT_OCCASIONS,
  DISCOUNT_STATES,
  DISCOUNT_TYPES,
} from 'components/discount/constants'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { NoContentMessage } from 'components/common/NoContentMessage/index'

import { getCurrentValueObject, resetInputOption } from '../../helpers'
import { FilterReset } from '../FilterReset'
import { cleanUpAndCountFiltersForReset } from '../FilterReset/helpers'

import connector from './connector'

/**
 * @description This component renders and controls a discount filter component.
 * @function
 * @param {Object} props the component props
 */
class DiscountFilter extends Component {
  static propTypes = {
    handleChange: func.isRequired,
    handleBlur: func.isRequired,
    isLoading: bool,
    length: number,
    resetForm: func.isRequired,
    values: shape({
      discount_type: string,
      company_name: string,
      occasion: string,
      id: oneOfType([number, string]),
      status: string,
    }).isRequired,
  }

  static defaultProps = {
    isLoading: false,
    length: 0,
  }

  state = {
    currentSelectedDiscountType: {},
    currentSelectedOccasion: {},
    currentSelectedStatus: {},
  }

  /**
   * Component “lifecycle method” componentDidUpdate
   */
  componentDidUpdate({ values: prevValues }) {
    const { values } = this.props

    if (values !== prevValues) this.handleValueChange(this.props)

    if (values.discount_type !== prevValues.discount_type) {
      this.setState({
        currentSelectedDiscountType: getCurrentValueObject(
          this.getSelectionItems(this.props).DISCOUNT_TYPES,
          values.discount_type,
        ),
      })
    }

    if (values.status !== prevValues.status) {
      this.setState({
        currentSelectedStatus: getCurrentValueObject(
          this.getSelectionItems(this.props).DISCOUNT_STATES,
          values.status,
        ),
      })
    }

    if (values.occasion !== prevValues.occasion) {
      this.setState({
        currentSelectedOccasion: getCurrentValueObject(
          this.getSelectionItems(this.props).DISCOUNT_OCCASIONS,
          values.occasion,
        ),
      })
    }
  }

  /**
   * @description Method to get selection items.
   */
  getSelectionItems = () => ({
    DISCOUNT_TYPES: [
      ...resetInputOption,
      ...DISCOUNT_TYPES.map(item => ({
        id: `${item.id}`,
        label: I18n.t(item.label),
        value: `${item.value}`,
      })),
    ],
    DISCOUNT_STATES: [
      ...resetInputOption,
      ...DISCOUNT_STATES.map(item => ({
        value: `${item.id}`,
        label: `${I18n.t(item.name)}`,
      })),
    ],
    DISCOUNT_OCCASIONS: [
      ...resetInputOption,
      ...DISCOUNT_OCCASIONS.map(item => ({
        value: `${item.id}`,
        label: `${I18n.t(item.name)}`,
      })),
    ],
  })

  /**
   * @description Method to refresh results after filter change
   */
  handleValueChange = props => {
    if (props.values) {
      const requestValues = {
        company_name: props.values.company_name,
        discount_type: props.values.discount_type,
        status: props.values.status,
        occasion: props.values.occasion,
      }
      props.getCurrentFilterValues(requestValues)
      props.getDiscounts(null, requestValues, props.sortedBy)
    }
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    const { handleChange, handleBlur, isLoading, length, resetForm, values } =
      this.props

    return (
      <>
        <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
          <FilterReset
            onResetFilter={() => {
              resetForm()
            }}
            showResetFilterButton={cleanUpAndCountFiltersForReset(values) > 0}
          />
        </ButtonBar>
        <div className='uk-grid uk-child-width-1-1 uk-child-width-1-4@m'>
          <StaticCombobox
            label={I18n.t('filterTranslations.discountFilter.discountType')}
            name='discount_type'
            noResultsText={I18n.t('filterTranslations.noInputResults')}
            options={this.getSelectionItems().DISCOUNT_TYPES}
            onSelectionChange={handleChange}
            placeholder={I18n.t('general.placeholder.all')}
            selectedOption={this.state.currentSelectedDiscountType}
          />
          <StaticCombobox
            label={I18n.t('filterTranslations.discountFilter.status')}
            name='status'
            noResultsText={I18n.t('filterTranslations.noInputResults')}
            options={this.getSelectionItems().DISCOUNT_STATES}
            onSelectionChange={handleChange}
            placeholder={I18n.t('general.placeholder.all')}
            selectedOption={this.state.currentSelectedStatus}
          />
          <StaticCombobox
            label={I18n.t('filterTranslations.discountFilter.occasion')}
            name='occasion'
            noResultsText={I18n.t('filterTranslations.noInputResults')}
            options={this.getSelectionItems().DISCOUNT_OCCASIONS}
            onSelectionChange={handleChange}
            placeholder={I18n.t('general.placeholder.all')}
            selectedOption={this.state.currentSelectedOccasion}
          />
          <InputText
            label={I18n.t('filterTranslations.discountFilter.companyName')}
            name='company_name'
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={I18n.t('general.placeholder.all')}
            value={values.company_name || ''}
          />
        </div>

        <div className='filter__empty-message'>
          {!isLoading && length < 1 && (
            <NoContentMessage
              message={I18n.t('general.emptyFilterResultMessage')}
              showResetFilterButton={false}
            />
          )}
        </div>
      </>
    )
  }
}

export default connector(DiscountFilter)
