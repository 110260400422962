/**
 * @description Static method to validate zipcode.
 * @param value
 */

export const isValidZipcode = value => {
  if (!value) return true

  return value.match('(^(?!01000|99999)(0[1-9]\\d{3}|[1-9]\\d{4})$)')
}

export default {}
