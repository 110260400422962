import './style.scss'

import { string, node } from 'prop-types'
import React from 'react'

import IndicationIconComponent from './IndicationIcon/index'

/**
 * @description Small container for the indication messages.
 */
const IndicationMessage = props => (
  <div
    className={`indication-message uk-margin-medium-bottom ${props.className}`}
  >
    <div>
      <IndicationIconComponent
        statusClass={props.statusClass}
        statusIconName={props.statusIconName}
      />
    </div>
    <div className='indication-message-content'>{props.children}</div>

    <div className='indication-message-footer'>{props.footerContent}</div>
  </div>
)

IndicationMessage.propTypes = {
  className: string,
  statusClass: string,
  iconName: string,
  statusIconName: string,
  children: node,
  footerContent: node,
}

IndicationMessage.defaultProps = {
  className: 'success',
  statusClass: 'error',
  iconName: 'information',
  statusIconName: 'cross',
  children: [],
  footerContent: [],
}

export default IndicationMessage
