import { arrayOf, bool, func, number, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { Control } from 'react-redux-form'
import AsyncSelect from 'react-select/async'

import { ControlDefaultProps } from '../../constants'
import FormField from '../../index'
import { ControlProps } from '../../schemes'

import connector from './connector'

/**
 * @description This renders a multi-select control using react select.
 * The options should have this structure: { value: 'chocolate', label: 'Chocolate' }.
 * Alternatively you can pass the "valueKey" and "labelKey" properties like this:
 * ```
 * addMapProps={{
 *   valueKey: 'id',
 *   labelKey: 'name',
 *  }}
 * ```
 * see: https://deploy-preview-2289--react-select.netlify.com/home
 */
class AsyncSelectControl extends Component {
  static propTypes = {
    ...ControlProps,
    addMapProps: shape({}),
    dataTestId: string,
    getOptionLabel: func,
    getOptionValue: func,
    inputMinLength: number,
    isMulti: bool,
    loadingMessage: string,
    loadOptions: func.isRequired,
    maxLength: number,
    noNumbers: bool,
    noOptionsMessage: string,
    noText: bool,
    options: arrayOf(shape({})),
  }

  static defaultProps = {
    ...ControlDefaultProps,
    addMapProps: {},
    dataTestId: '',
    getOptionLabel: null,
    getOptionValue: null,
    inputMinLength: 3,
    isMulti: false,
    loadingMessage: '',
    maxLength: null,
    options: [],
    noNumbers: false,
    noOptionsMessage: null,
    noText: false,
  }

  state = {
    callback: null,
  }

  /**
   * @description Component “lifecycle method” UNSAFE_componentWillReceiveProps
   * @param nextProps
   */
  UNSAFE_componentWillReceiveProps({ options }) {
    const { callback } = this.state

    if (callback && options !== this.props.options) {
      callback(options)
    }
  }

  /**
   * @description Overwrite this method in a child class to define an individual field.
   */
  getField = () => {
    const {
      dataTestId,
      field,
      options,
      getOptionLabel,
      getOptionValue,
      loadingMessage,
      noOptionsMessage,
      placeholder,
      isMulti,
      addMapProps,
      maxLength,
      /*
      noText,
      noNumbers,
      */
    } = this.props

    return (
      <Control
        model={field.model}
        component={AsyncSelect}
        mapProps={{
          className: `react-select-container ${dataTestId}`,
          classNamePrefix: 'react-select',
          options,
          isMulti,
          placeholder,
          getOptionValue: () => option =>
            getOptionValue ? getOptionValue(option) : option.id,
          getOptionLabel: () => option =>
            getOptionLabel ? getOptionLabel(option) : option.label,
          loadingMessage: () => () => loadingMessage || '',
          noOptionsMessage: () => () => noOptionsMessage || '',
          loadOptions: () => this.loadOptions,
          onInputChange: () => value => {
            // this will limit the number and type of characters that are possible to write in the input box,
            /*
            const textPattern = /\p{Letter}/u;
            const numberPattern = /\d/;
            */
            if (
              maxLength &&
              value.length > maxLength /* ||
              TODO @rui: these two conditions are disabled at the moment, because if the first input character is a
              forbidden character, the user can see it, although it is not saved in the store.
              This is probably a bug in the react-select library and needs further research. Come back to this later.
              (noText && textPattern.test(value)) ||
              (noNumbers && numberPattern.test(value)) */
            ) {
              return value.substring(0, value.length - 1)
            }
            return value
          },
          hideSelectedOptions: isMulti,
          ...addMapProps,
        }}
      />
    )
  }

  /**
   * @description handles the load options event
   * @param value
   * @param callback
   */
  loadOptions = (value, callback) => {
    const { inputMinLength } = this.props
    const triggerLoadOptions = value.length >= inputMinLength

    this.setState({ callback: triggerLoadOptions ? callback : null }, () => {
      if (triggerLoadOptions) {
        this.props.loadOptions(value)
      } else {
        callback([])
      }
    })
  }

  render() {
    return <FormField {...this.props} fieldComponent={this.getField()} />
  }
}

export default connector(AsyncSelectControl)
