import { createSelector } from 'reselect'

/**
 * @description selector to get the list of communities.
 * @param state
 * @return {*}
 */
export const getCommunitiesSelector = state => state.community.itemList

/**
 * @description selector to get the list of active communities.
 * @param state
 * @return {*}
 */
export const getActiveCommunitiesSelector = createSelector(
  getCommunitiesSelector,
  communities => communities.filter(community => community.is_active),
)

export default {}
