import './style.scss'

import classNames from 'classnames'
import React, { FC, useEffect, useState } from 'react'
import { Translate } from 'react-i18nify'

/**
 * Says whether the rating is a negative, neutral or positive one.
 */
export enum RatingSign {
  Negative = -1,
  Neutral = 0,
  Positive = 1,
}

/**
 * Determines the rating sign by a number between 0 and 1.
 */
export function determineRatingSign(rating: number): RatingSign {
  const negativeMaximum = 0.3
  const positiveMinimum = 0.9

  if (rating && rating <= negativeMaximum) return RatingSign.Negative
  if (rating && rating >= positiveMinimum) return RatingSign.Positive

  return RatingSign.Neutral
}

/**
 * Determines the current rating value into a percentage string like "20%".
 */
export function getRatingPercentageString(rating: number): string {
  return `${(rating * 100).toFixed(0)}%`
}

/**
 * RatingCaption Props
 */
export interface RatingCaptionProps {
  className?: string
  rating: number
}

/**
 * Renders a rating caption by a given rating value.
 */
export const RatingCaption: FC<RatingCaptionProps> = ({
  className,
  rating,
}) => {
  const [ratingSign, setRatingSign] = useState<RatingSign>(RatingSign.Neutral)
  const [ratingPercentage, setRatingPercentage] = useState<string>()

  useEffect(() => {
    const currentRatingSign = determineRatingSign(rating)
    const currentRatingPercentage = getRatingPercentageString(rating)

    setRatingSign(currentRatingSign)
    setRatingPercentage(currentRatingPercentage)
  }, [rating])

  return (
    <Translate
      className={classNames(
        'rating-caption',
        { 'rating-caption--negative': ratingSign === RatingSign.Negative },
        { 'rating-caption--neutral': ratingSign === RatingSign.Neutral },
        { 'rating-caption--positive': ratingSign === RatingSign.Positive },
        className,
      )}
      value={rating > 0 ? 'ratingCaption.amount' : 'ratingCaption.noRatingYet'}
      rating={ratingPercentage}
    />
  )
}
