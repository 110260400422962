/**
 * @description selector to get invoiceCheck list.
 * @param state
 * @return {*}
 */
import { OfferPipelinePagination } from '../reducers/maklerpremium'

export const getInvoiceChecksSelector: (
  state: any,
) => InvoiceCheck[] | InvoiceCheckTableListItem[] = state =>
  state.maklerpremium.invoiceChecks.list

export const getInvoiceCheckPaginationSelector: (
  state: any,
) => Pagination = state => state.maklerpremium.invoiceChecks.pagination

export const getInvoiceCheckSelector: (state: any) => InvoiceCheck = state =>
  state.maklerpremium.invoiceCheck

export const getInvoiceCheckErrorSelector: (state: any) => string = state =>
  state.maklerpremium.invoiceCheckError

export const getInvoiceCheckPositionSelector: (
  state: any,
) => InvoiceCheckPosition = state => state.maklerpremium.invoiceCheckPosition

export const getInvoiceCheckPositionsSelector: (
  state: any,
) => InvoiceCheckPosition[] = state =>
  state.maklerpremium.invoiceCheckPositions.list

export const getInvoiceCheckPositionPaginationSelector: (
  state: any,
) => Pagination = state => state.maklerpremium.invoiceCheckPositions.pagination

export const getInvoiceCheckPositionReviewSelector: (state: any) => {
  loaded: boolean
  item: InvoiceCheckPositionReview
} = state => state.maklerpremium.invoiceCheckPosition

export const getInvoiceCheckPositionNetPriceSelector: (
  state: any,
) => number | undefined = state =>
  state.maklerpremium.invoiceCheckPositionNetPrice

export const getCustomInvoiceNetGrossPricesVatSelector: (state: any) => {
  [key: string]: number
} = state => state.maklerpremium.customInvoiceNetGrossPricesAndVat

export const getCustomInvoiceValidatedIBANErrorMessage: (
  state: any,
) => string = state =>
  state.maklerpremium.customInvoiceValidatedIBANErrorMessage

export const getFrameworkContractPositionsSelector: (state: any) => {
  positions: [FrameworkContractPosition]
  loaded: boolean
} = state => state.maklerpremium.frameworkContractPosition

export const getFrameworkContractPositionsCountSelector: (
  state: any,
) => number = state => state.maklerpremium.frameworkContractPositionsCount

export const getCancelledPaidDocumentSelector: (
  state: any,
) => CancelledPaidDocument = state => state.maklerpremium.cancelledPaidDocument

export const getOfferPipelineSelector: (
  state: any,
) => CancelledPaidDocument[] = state => state.maklerpremium.offerPipeline.list

export const getOfferPipelinePaginationSelector: (
  state: any,
) => OfferPipelinePagination = state =>
  state.maklerpremium.offerPipeline.pagination
