import '../style.scss'

import { useFormikContext } from 'formik'
import React, { FC, useContext, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getAddressesWithOrders } from 'actions/address'
import { resetMaklerPremiumCompanies } from 'actions/company'
import { getOrders } from 'actions/order'
import { DropDownInput } from 'components/common/DropDownInput'
import { FindAddressByFilterInput } from 'components/common/FindByFilterInput'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { COMPANY_ROLE } from 'components/company/constants'
import { getAddressesWithOrdersSelector } from 'selectors/address'
import { getOrdersSelector } from 'selectors/order'
import { createRequestStatusSelector } from 'selectors/requestStatus'

import {
  InvoiceCheckPositionsFormContext,
  InvoiceCheckPositionsFormValues,
} from '../InvoiceCheckPositionsFormSteps'

export const CollectionAddressForm: FC = () => {
  const dispatch = useDispatch()

  const {
    errors,
    handleBlur,
    handleChange,
    setFieldTouched,
    setFieldValue,
    setValues,
    submitCount,
    resetForm,
    values,
  } = useFormikContext<InvoiceCheckPositionsFormValues>()

  const { stepStatus, saveStep, invoiceCheckPosition } = useContext(
    InvoiceCheckPositionsFormContext,
  )

  const isFieldValid = name => values[name]?.length > 0 && !errors[name]
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0
      ? (errors[name] as string)
      : undefined

  const searchFields = [
    {
      optionLabel: I18n.t(
        'invoiceCheckTranslations.positionsForm.steps.1.fields.company_name.label',
      ),
      optionValue: 'company_name',
    },
    {
      optionLabel: I18n.t(
        'invoiceCheckTranslations.positionsForm.steps.1.fields.order.label',
      ),
      optionValue: 'order',
    },
    {
      optionLabel: I18n.t(
        'invoiceCheckTranslations.positionsForm.steps.1.fields.zipcode.label',
      ),
      optionValue: 'zipcode',
    },
  ]

  const orderRequestStatus = useSelector(
    createRequestStatusSelector(['GET_ORDER']),
  )
  useEffect(() => {
    if (values.offer) {
      if (!orderRequestStatus) {
        dispatch(getOrders(undefined, { offer: values.offer }, 1))
      }
    }
  }, [values.offer, orderRequestStatus, dispatch])

  const orders = useSelector(getOrdersSelector)
  const addresses = useSelector(getAddressesWithOrdersSelector)
  useEffect(() => {
    const order_id =
      values.order ?? (orders.length > 0 ? orders[0]?.id : undefined)
    if (
      values.company_name &&
      values.collection_address &&
      values.zipcode &&
      order_id
    ) {
      dispatch(
        getAddressesWithOrders({
          order: order_id,
          role: COMPANY_ROLE.WASTE_PRODUCER,
        }),
      )
    }
  }, [
    dispatch,
    values.collection_address,
    values.company_name,
    values.order,
    values.zipcode,
    orders,
  ])

  return (
    <div className='invoicecheck-form__step'>
      <DropDownInput
        choices={searchFields}
        label={I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.1.fields.search_field.label',
        )}
        name='search_field'
        onChange={e => {
          dispatch(resetMaklerPremiumCompanies()) // Reset Filtered results
          setValues({
            collection_address: undefined,
            company_name: undefined,
            order: undefined,
            zipcode: undefined,
          })
          handleChange(e)
        }}
        showCheckmark={!!values.search_field}
        value={values.search_field}
        withCheckmark
        isDisabled={!!invoiceCheckPosition?.id}
      />
      <FindAddressByFilterInput
        resultType={COMPANY_ROLE.WASTE_PRODUCER}
        label={I18n.t(
          `invoiceCheckTranslations.positionsForm.steps.1.fields.${values.search_field}.label`,
        )}
        name={values.search_field ?? ''}
        noResultsText={I18n.t(
          `invoiceCheckTranslations.positionsForm.steps.1.fields.${values.search_field}.noOptionsMessage`,
        )}
        placeholder={I18n.t(
          `invoiceCheckTranslations.positionsForm.steps.1.fields.${values.search_field}.placeholder`,
        )}
        handleBlur={handleBlur}
        handleSelectionChange={(value, rawValue) => {
          const { search_field = 'company_name' } = values
          if (value !== values.collection_address) {
            resetForm()
            setFieldTouched('order', false, false)
          }

          setFieldValue('collection_address', value)
          setFieldValue('search_field', search_field)
          setFieldValue(search_field, rawValue)
          setFieldTouched(search_field, true)
        }}
        initialOptions={addresses}
        value={values.collection_address}
        error={getFieldError}
        showCheckmark={isFieldValid}
        withCheckmark
        isDisabled={!!invoiceCheckPosition?.id}
      />
      <GoToNextFormStepButton
        buttonText={
          stepStatus === STEP_STATUS.EDITING
            ? I18n.t('general.button.save')
            : I18n.t('createInquiryTranslations.form.button.nextStep')
        }
        onClick={saveStep}
        isDisabled={!values.collection_address}
      />
    </div>
  )
}
