import { useFormikContext } from 'formik'
import moment from 'moment'
import React from 'react'
import { I18n } from 'react-i18nify'

import { DropDownInput } from 'components/common/DropDownInput'
import InputDate from 'components/common/InputDate'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { Textarea } from 'components/common/Textarea'
import {
  INVOICE_CHECK_POSITIONS_CATEGORIES,
  INVOICE_CHECK_POSITIONS_CATEGORIES_CHOICES,
} from 'components/maklerpremium/constants'

import { InvoiceCheckPositionsFormValues } from '../../InvoiceCheckPositionsFormSteps'
import { OrderType } from '../OrderAndServicesForm'

type OtherServiceFormPropsType = {
  isLoading: { orderOptions: boolean }
  isFieldValid: (value: string) => boolean
  getFieldError: (name: string | undefined) => string | undefined
  orderOptions: { label: string; value: string }[]
  selectedOrderOption?: { label: string; value: string }
  dismissibleInfoForAlreadyCapturedOrder: JSX.Element
  dismissibleInfoForCancelledOrder: JSX.Element
  dismissibleInfoForOrderPictures: JSX.Element
}

export const OtherServiceForm = (props: OtherServiceFormPropsType) => {
  const {
    getFieldError,
    orderOptions,
    selectedOrderOption,
    isFieldValid,
    isLoading,
    dismissibleInfoForAlreadyCapturedOrder,
    dismissibleInfoForCancelledOrder,
    dismissibleInfoForOrderPictures,
  } = props

  // Form State (values, errors, functions)
  const { handleBlur, handleChange, setFieldValue, values } =
    useFormikContext<InvoiceCheckPositionsFormValues>()

  if (values.order_type !== OrderType.OTHER_SERVICE) return <></>

  return (
    <>
      {/* Auftragsnummer */}
      <StaticCombobox
        error={getFieldError}
        label={I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.2.fields.order.label',
        )}
        name='order'
        noResultsText={I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.2.fields.order.noResultsText',
        )}
        options={orderOptions}
        onSelectionChange={handleChange}
        selectedOption={selectedOrderOption}
        isLoading={isLoading.orderOptions}
        showCheckmark={isFieldValid}
        withCheckmark
      />
      {dismissibleInfoForAlreadyCapturedOrder}
      {dismissibleInfoForCancelledOrder}
      {dismissibleInfoForOrderPictures}
      {/* Leistungsdatum */}
      <InputDate
        label={I18n.t('orderDetails.deliveryDate')}
        placeholder={I18n.t('orderDetails.deliveryDate')}
        name='delivery_date'
        onChange={value => {
          setFieldValue('delivery_date', value.format('L'))
          setFieldValue('collection_date', undefined)
        }}
        showCheckmark={isFieldValid}
        value={values.delivery_date}
        withCheckmark
        maxDate={moment()}
      />
      {/* Kategorie */}
      <DropDownInput
        choices={INVOICE_CHECK_POSITIONS_CATEGORIES_CHOICES.filter(
          item => item.id !== INVOICE_CHECK_POSITIONS_CATEGORIES.RENT,
        ).map(choice => ({
          optionValue: choice.id,
          optionLabel: I18n.t(choice.name),
        }))}
        name='category'
        onBlur={handleBlur}
        onChange={handleChange}
        label={I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.label',
        )}
        placeholder={I18n.t(
          'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.category.label',
        )}
        showCheckmark={isFieldValid}
        value={values.category}
        error={getFieldError}
        withCheckmark
      />
      {/* Beschreibung der Dienstleistung */}
      {Number(values.category) === INVOICE_CHECK_POSITIONS_CATEGORIES.OTHER && (
        <Textarea
          label={I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.categoryText.label',
          )}
          maxLength={500}
          name='other_service_other_text'
          onBlur={handleBlur}
          onChange={handleChange}
          placeholder={I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.categoryText.label',
          )}
          rows={5}
          value={
            values.other_service_other_text
              ? values.other_service_other_text
              : ''
          }
          showRemainingCharacters
        />
      )}
    </>
  )
}
