import './style.scss'

import { useFormikContext } from 'formik'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { InputText } from '../common/InputText'

export type TaxSectionValues = {
  tax_id: string
  tax_number: string
}

export const TaxSectionFields: FC = () => {
  const { errors, handleChange, handleBlur, touched, values } =
    useFormikContext<TaxSectionValues>()

  const isFieldValid = name =>
    !!(values[name] && values[name].length > 0 && !errors[name])

  return (
    <>
      <p className='form-section-headline'>
        <Translate value='maklerpremium.addWasteProducerModal.form.taxDetails.line1' />
      </p>
      <p>
        <Translate value='maklerpremium.addWasteProducerModal.form.taxDetails.line2' />
      </p>
      <InputText
        error={touched.tax_number ? errors.tax_number : ''}
        label={
          <Translate value='maklerpremium.addWasteProducerModal.form.tax_number' />
        }
        maxLength={13}
        name='tax_number'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t(
          'maklerpremium.addWasteProducerModal.form.tax_number',
        )}
        value={values.tax_number}
        showCheckmark={isFieldValid('tax_number') || isFieldValid('tax_id')}
        withCheckmark
      />
      <InputText
        error={touched.tax_id ? errors.tax_id : ''}
        label={
          <Translate value='maklerpremium.addWasteProducerModal.form.tax_id' />
        }
        maxLength={11}
        name='tax_id'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t('maklerpremium.addWasteProducerModal.form.tax_id')}
        value={values.tax_id}
        showCheckmark={isFieldValid('tax_number') || isFieldValid('tax_id')}
        withCheckmark
      />
    </>
  )
}
