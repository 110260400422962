import moment from 'moment'

import { BUSINESS_SEGMENT } from '../../constants/app'
import { EmptoGroups, MaklerPremiumGroups } from '../../constants/user'
import { COMPANY_ROLE } from '../company/constants'
import { INQUIRY_ORDER_TYPE } from '../inquiry/constants'

export const contractApplies = (
  frameworkContractPositions: {
    positions: [FrameworkContractPosition]
    loaded: boolean
  },
  values: any,
) => {
  const frameworkContractExists =
    frameworkContractPositions.loaded &&
    Object.keys(frameworkContractPositions.positions).length !== 0

  let valuesInFrameworkContract

  if (frameworkContractExists) {
    valuesInFrameworkContract = Object.entries(
      frameworkContractPositions.positions,
    ).find(pos => {
      const date = [
        INQUIRY_ORDER_TYPE.TYPE_ONE_TIME,
        INQUIRY_ORDER_TYPE.TYPE_QUICK,
      ].includes(values.order_type)
        ? values.delivery_date
        : values.turn_begin

      let conditions

      if (pos[1].company !== values.existing_customer) {
        return undefined
      }

      // assert containers match
      if (values.container) {
        const containerCondition = pos[1].containers.includes(values.container)

        conditions =
          conditions === undefined
            ? containerCondition
            : conditions && containerCondition
      }

      // assert security group match
      if (values?.security_group) {
        const securityGroupCondition = pos[1].security_groups.includes(
          values.security_group,
        )
        conditions =
          conditions === undefined
            ? securityGroupCondition
            : conditions && securityGroupCondition
      }

      // assert fraction matches
      if (values.fraction) {
        const fractionCondition = values.fraction === pos[1].fraction

        if (values.fine_fraction) {
          const fineFractionCondition = values.fine_fraction === pos[1].fraction

          conditions =
            conditions === undefined
              ? fineFractionCondition
              : conditions && fineFractionCondition
        } else {
          conditions =
            conditions === undefined
              ? fractionCondition
              : conditions && fractionCondition
        }
      }

      if (date) {
        const dateCondition =
          // assert delivery_date is after start_date
          moment(pos[1].start_date) <= moment(date, 'L') &&
          // assert delivery_date is before end_date
          moment(date, 'L') <= moment(pos[1].end_date)

        conditions =
          conditions === undefined ? dateCondition : conditions && dateCondition
      }

      conditions =
        !values.delivery_date && !values.container && !values.fraction
          ? frameworkContractExists
          : conditions

      if (conditions) {
        return pos
      }

      return undefined
    })
  }

  return !!valuesInFrameworkContract && frameworkContractExists
}

export const contractNoLongerApplies = (
  frameworkContractPositions: {
    positions: [FrameworkContractPosition]
    loaded: boolean
  },
  values,
) => {
  const frameworkContractApplies = contractApplies(
    frameworkContractPositions,
    values,
  )

  const frameworkContractOnceApplied = !!Object.entries(
    frameworkContractPositions.positions,
  ).find(pos => pos[1].company === values.existing_customer)

  const date = [
    INQUIRY_ORDER_TYPE.TYPE_ONE_TIME,
    INQUIRY_ORDER_TYPE.TYPE_QUICK,
  ].includes(values.order_type)
    ? values.delivery_date
    : values.turn_begin

  if (frameworkContractOnceApplied && date !== '') {
    return !frameworkContractApplies
  }
  return frameworkContractApplies
}

// [view_dashboard_makler]
const checkForMiddleMan = (user: User) =>
  Number(user.company_object?.role) === COMPANY_ROLE.MIDDLE_MAN

const checkForMaklerPremium = (user: User) =>
  Number(user.company_object?.role) === COMPANY_ROLE.MIDDLE_MAN &&
  Number(user.company_object?.business_segment) !==
    BUSINESS_SEGMENT.BUSINESS_EMPTO

const checkForBusinessSegmentEmpto = (user: User) =>
  Number(user.company_object?.business_segment) ===
    BUSINESS_SEGMENT.BUSINESS_EMPTO || user.company_object === null

const checkForBusinessSegmentEPD = (user: User) =>
  checkForMaklerPremium(user) &&
  Number(user.company_object?.business_segment) ===
    BUSINESS_SEGMENT.BUSINESS_EPD

const checkForBusinessSegmentTVP = (user: User) =>
  checkForMaklerPremium(user) &&
  Number(user.company_object?.business_segment) ===
    BUSINESS_SEGMENT.BUSINESS_TVP

const checkForBusinessSegmentDSZ = (user: User) =>
  checkForMaklerPremium(user) &&
  Number(user.company_object?.business_segment) ===
    BUSINESS_SEGMENT.BUSINESS_DSZ

const checkForAdministrator = (user: User) =>
  [
    EmptoGroups.EMPTO_ADMIN,
    MaklerPremiumGroups.MAKLER_PREMIUM_EPD_ADMINISTRATOR,
    MaklerPremiumGroups.MAKLER_PREMIUM_TVP_ADMINISTRATOR,
    MaklerPremiumGroups.MAKLER_PREMIUM_DSZ_ADMINISTRATOR,
  ].includes(Number(user.group_id))

const checkForService = (user: User) =>
  [
    EmptoGroups.EMPTO_SERVICE,
    MaklerPremiumGroups.MAKLER_PREMIUM_EPD_SERVICE,
    MaklerPremiumGroups.MAKLER_PREMIUM_TVP_SERVICE,
    MaklerPremiumGroups.MAKLER_PREMIUM_DSZ_SERVICE,
  ].includes(Number(user.group_id))

const checkForServicePlus = (user: User) =>
  [MaklerPremiumGroups.MAKLER_PREMIUM_EPD_SERVICE_PLUS].includes(
    Number(user.group_id),
  )

const checkForBookkeeping = (user: User) =>
  [
    EmptoGroups.EMPTO_INVOICING,
    MaklerPremiumGroups.MAKLER_PREMIUM_EPD_BOOKKEEPING,
    MaklerPremiumGroups.MAKLER_PREMIUM_TVP_BOOKKEEPING,
    MaklerPremiumGroups.MAKLER_PREMIUM_DSZ_BOOKKEEPING,
  ].includes(Number(user.group_id))

export const getRoles = (user: User) => ({
  isMiddleMan: checkForMiddleMan(user),
  isMaklerPremium: checkForMaklerPremium(user),
})

export const getBusinessSegments = (user: User) => ({
  isEmpto: checkForBusinessSegmentEmpto(user),
  isEPD: checkForBusinessSegmentEPD(user),
  isTVP: checkForBusinessSegmentTVP(user),
  isDSZ: checkForBusinessSegmentDSZ(user),
})

export const getGroups = (user: User) => ({
  isAdministrator: checkForAdministrator(user),
  isService: checkForService(user),
  isServicePlus: checkForServicePlus(user),
  isBookkeeping: checkForBookkeeping(user),
})
