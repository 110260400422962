import keyMirror from 'keymirror'

export const ORDER_CLAIM_PROOF_STATUS = {
  STATUS_PENDING: 1,
  STATUS_ACCEPTED: 2,
  STATUS_REJECTED: 3,
}

export const ORDER_CLAIM_PROOF_STATES = [
  {
    id: ORDER_CLAIM_PROOF_STATUS.STATUS_PENDING,
    name: 'executionProof.status.pending',
  },
  {
    id: ORDER_CLAIM_PROOF_STATUS.STATUS_ACCEPTED,
    name: 'executionProof.status.accepted',
  },
  {
    id: ORDER_CLAIM_PROOF_STATUS.STATUS_REJECTED,
    name: 'executionProof.status.rejected',
  },
]

export const ORDER_CLAIM_MODALS = keyMirror({
  CONFIRM_INTERNAL_COMMENT_MODAL: null,
})

export const ORDER_CLAIM_PROOF_WAITING_TIMES = [
  {
    id: 15,
    name: 'orderClaimProof.form.waitingTimes.selectLabels.fifteenMinutes',
  },
  {
    id: 30,
    name: 'orderClaimProof.form.waitingTimes.selectLabels.thirtyMinutes',
  },
  {
    id: 45,
    name: 'orderClaimProof.form.waitingTimes.selectLabels.fortyFiveMinutes',
  },
  {
    id: 60,
    name: 'orderClaimProof.form.waitingTimes.selectLabels.sixtyMinutes',
  },
  {
    id: 75,
    name: 'orderClaimProof.form.waitingTimes.selectLabels.seventyFiveMinutes',
  },
  {
    id: 90,
    name: 'orderClaimProof.form.waitingTimes.selectLabels.ninetyMinutes',
  },
]

export default {
  ...ORDER_CLAIM_PROOF_STATUS,
  ...ORDER_CLAIM_PROOF_STATES,
}
