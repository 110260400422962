import './style.scss'

import uniqueId from 'lodash.uniqueid'
import React, { Component } from 'react'
import Dropzone from 'react-dropzone'
import { I18n, Translate } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'

import ModalHeader from 'components/common/ModalHeader/index'
import { Modal } from 'components/common/Modal'
import Icon from 'components/common/Fontello/index'

import { filePreviewAsUrl } from '../helpers'
import { FileUploadProps } from '../schemes'
import FileUploadService from '../services'

import FileComment from './components/FileComment/index'
import FilePreview from './components/FilePreview/index'

/**
 * Renders a Dropzone (https://github.com/react-dropzone/react-dropzone). When files are uploaded, the files are sent
 * to the API and returned with their new ID. Then the onFilesUploaded prop is called. Files are displayed with a
 * preview (even before upload is done) and can be removed again (will trigger DELETE call to API).
 */
class FileUpload extends Component {
  static propTypes = FileUploadProps

  static defaultProps = {
    className: null,
    initialFiles: [],
    accept: 'image/*, application/pdf',
    maxSize: 5 * 1024 * 1024,
    maxFiles: 3,
    allowFileComment: true,
    showPreviewMetaData: false,
  }

  state = {
    files: this.props.initialFiles, // These are the files which are uploaded.
    selectedFile: null, // The currently selected file for the preview.
  }

  /**
   * @description Uploads one or several files. This is called by Dropzone when files were dropped.
   * @param accepted {array} files that were accepted based on the mime type
   * @param rejected {array} files that were rejected based on the mime type
   */
  handleUpload = (accepted, rejected) => {
    // We remember the files we had before uploading for later.
    const filesBeforeUpload = this.state.files

    // Validate number of files
    const { maxFiles } = this.props
    const currentFiles = this.state.files.length
    if (maxFiles && currentFiles + accepted.length > maxFiles) {
      accepted.splice(maxFiles - currentFiles)

      toastr.error(
        I18n.t('fileUpload.tooManyToast.title'),
        I18n.t(
          `fileUpload.tooManyToast.${maxFiles > 1 ? 'text' : 'textSingle'}`,
          { max: maxFiles },
        ),
      )
    }

    // Display error after validation of mime type and size
    if (rejected.length > 0) {
      // If there were rejected files, we display a toast.
      toastr.error(
        I18n.t('fileUpload.rejectedToast.title'),
        I18n.t('fileUpload.rejectedToast.text', {
          maxSize: Math.round(this.props.maxSize / 1000000),
        }),
      )
    }

    // First we convert all file previews to base64, so we can display the file even before uploading it.
    accepted.forEach(file => {
      filePreviewAsUrl(file)
        .then(result => {
          const fileWithPreview = file
          fileWithPreview.file = result
          this.setState({ files: [...this.state.files, fileWithPreview] })
        })
        .catch(() => {
          // We do not need to do anything here, we just do not display a preview.
        })
    })

    // Now we upload the files and inform our listeners.
    if (accepted.length > 0) {
      return FileUploadService.uploadFiles(accepted)
        .then(uploadedFiles => {
          // The files now have IDs.
          this.setState({ files: [...filesBeforeUpload, ...uploadedFiles] })
          if (this.props.onFilesUploaded)
            this.props.onFilesUploaded(uploadedFiles)
        })
        .catch(err => {
          this.setState({ files: filesBeforeUpload }) // Upload did not work, so remove files from displayed list
          toastr.error(
            I18n.t('fileUpload.uploadErrorToast.title'),
            I18n.t('fileUpload.uploadErrorToast.text', { description: err }),
          )
        })
    }
    return Promise.resolve([])
  }

  /**
   * @description Removes one file, which was already uploaded. It deletes the file on the server and triggers an action
   * which can be used to remove the file in the store for the corresponding instance.
   * @param event
   * @param fileToRemove
   */
  handleRemoveFile = (event, fileToRemove) => {
    event.stopPropagation()

    FileUploadService.removeFile(fileToRemove)
      .then(() => {
        const files = [...this.state.files].filter(
          file => file.id !== fileToRemove.id,
        )
        this.setState({ files })
        if (this.props.onFileRemoved) this.props.onFileRemoved(fileToRemove)
      })
      .catch(err => {
        toastr.error(
          I18n.t('fileUpload.removeErrorToast.title'),
          I18n.t('fileUpload.removeErrorToast.text', { description: err }),
        )
      })
  }

  /**
   * Is called when a preview is clicked. We then display a modal with the preview.
   * @param event
   * @param clickedFile
   */
  handlePreviewClick = (event, clickedFile) => {
    this.setState({ selectedFile: clickedFile })
  }

  /**
   * @description Is called when the preview is closed. The text of the file might have changed.
   * @param editedFile
   */
  handlePreviewClosed = editedFile => {
    // Now update the edited file in the state and close the modal.
    this.setState({
      files: this.state.files.map(file =>
        file.id !== editedFile.id ? file : editedFile,
      ),
    })
    this.setState({ selectedFile: null })
    this.props.onFileEdited(editedFile)
  }

  render() {
    const idFileUploadModalHeadline = uniqueId()

    return (
      <div className={`fileupload ${this.props.className}`}>
        <Dropzone
          className='dropzone uk-border-rounded uk-padding-small uk-flex uk-flex-middle'
          onDrop={this.handleUpload}
          accept={this.props.accept}
          maxSize={this.props.maxSize}
          activeClassName='drag-active'
          rejectClassName='drag-reject'
        >
          {/* Will be hidden by css when dragging active */}
          <div className='cta cta-upload uk-flex-center'>
            <button className='uk-button uk-button-secondary' type='button'>
              <Translate value='fileUpload.uploadButtonTitle' />
              <Icon name='plus' />
            </button>
            <p className='uk-text-contrast'>
              <Translate value='fileUpload.dropCallToAction' />
            </p>
          </div>
          {/* Will be shown by css when dragging active */}
          <div className='cta cta-drop'>
            <Icon name='upload' />
            <p className='uk-text-contrast'>
              <Translate value='fileUpload.dropHint' />
            </p>
          </div>
          {/* Will be shown by css when dragging rejected */}
          <div className='cta cta-reject'>
            <Icon name='warning' />
            <p className='uk-text-contrast'>
              <Translate value='fileUpload.rejectHint' />
            </p>
          </div>
        </Dropzone>

        {this.state.files.length > 0 && (
          <div
            className='files uk-margin uk-child-width-1-3 uk-grid-small'
            data-uk-grid
          >
            {this.state.files.map((file, i) => (
              // new files do not have IDs, so we must use the index here
              <FilePreview
                key={i} // eslint-disable-line react/no-array-index-key
                onRemovedFile={this.handleRemoveFile}
                file={file}
                onClick={this.handlePreviewClick}
                showMetaData={this.props.showPreviewMetaData}
              />
            ))}
          </div>
        )}

        {this.state.selectedFile && (
          <Modal
            ariaDescribedBy={idFileUploadModalHeadline}
            isOpen={!!this.state.selectedFile}
            onClose={this.handlePreviewClosed}
          >
            <>
              <ModalHeader
                onClose={this.handlePreviewClosed}
                title={this.state.selectedFile.name}
                titleId={idFileUploadModalHeadline}
              />

              <FileComment
                file={this.state.selectedFile}
                onFormSubmit={this.handlePreviewClosed}
                allowComment={this.props.allowFileComment}
              />
            </>
          </Modal>
        )}
      </div>
    )
  }
}

export default FileUpload
