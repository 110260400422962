import './style.scss'

import React, { FC } from 'react'

import { ReactComponent as CheckMark } from 'assets/svg/icon-checkmark-with-circle.svg'

interface ErrorMessageProps {
  title: string
  subTitle: string
  text: string
}

export const ErrorMessage: FC<ErrorMessageProps> = ({
  title,
  subTitle,
  text,
}) => {
  return (
    <div className='error-message'>
      <h2 className='headline headline--font-type-sans-serif no-margin'>
        <b>{title}</b>
      </h2>
      <h2 className='headline headline--font-type-sans-serif no-margin'>
        {subTitle}
      </h2>
      <CheckMark className='error-message__logo svgColor' />
      <p
        className='error-message__text'
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  )
}
