/**
 * @description The Dropzone component delivers files with a "blob:" url. We need to read this and convert to
 * base64, then we can display the preview, even before uploading the file.
 * @param file
 * @returns {Promise<any>}
 */
export const filePreviewAsUrl = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      if (reader.result) resolve(reader.result)
      else reject(Error('Failed converting to base64'))
    }
  })

/**
 * This function makes an array from an object or an epmty array.
 * Our FileUpload expects an array. If we only want to handle a single file, we need to make an array from it.
 * If the do [file], we make an array of [null], but we want [].
 * @param file
 * @returns {*}
 */
// eslint-disable-next-line no-confusing-arrow
export const arrayWithObject = file => (file ? [file] : [])

export default {}
