import React, { FC } from 'react'

interface UniversalFileUploadLabelProps {
  label?: string
  subLabel?: string
}

/**
 * conditionally renders the label container and its contents
 * @constructor
 */
export const UniversalFileUploadLabel: FC<UniversalFileUploadLabelProps> = ({
  label,
  subLabel,
}) => {
  if (!label && !subLabel) return null

  return (
    <div className='universal-file-upload__label-container'>
      {label && (
        <strong className='universal-file-upload__label'>{label}</strong>
      )}
      {subLabel && (
        <span className='universal-file-upload__sub-label'>{subLabel}</span>
      )}
    </div>
  )
}
