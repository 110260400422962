const cancelInquiryFormTranslations = {
  title:
    'Möchten Sie Ihre Anfrage wirklich stornieren? Sie haben aktuell %{numberOfOffers} %{offer}!',
  offer: {
    singular: 'Angebot',
    plural: 'Angebote',
  },
  emptoCancel: {
    label: 'Benutzer',
    placeholder: 'Benutzer',
    noResults: 'Zu Ihrer Suchanfrage wurde kein Benutzer gefunden',
  },
  cancelReasonLabel: 'Begründung',
  cancelReasonError: 'Bitte begründen Sie Ihre Stornierung',
  noUserError: 'Bitte wählen Sie einen Kunden aus',
  buttons: {
    cancel: 'Abbrechen',
    submit: 'Ok',
  },
}

export default cancelInquiryFormTranslations
