import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import classNames from 'classnames'
import React, { Component } from 'react'
import { I18n } from 'react-i18nify'
import { bool, func, number, shape, string } from 'prop-types'

import { BUTTON_BACKGROUND_COLOR, BUTTON_SHAPE } from 'components/common/Button'
import Icon, { ICON_COLOR } from 'components/common/Fontello/index'
import { IconButton } from 'components/common/IconButton'
import { INPUT_BORDER_COLOR } from 'components/common/Input'
import { InputNumber } from 'components/common/InputNumber'
import {
  Tooltip,
  TOOLTIP_POSITION,
  TOOLTIP_TRIGGER,
  TOOLTIP_ZINDEX,
} from 'components/common/Tooltip'

export const SEARCH_INPUT_STATUS = {
  PENDING: 'pending',
  RESOLVED: 'resolved',
  REJECTED: 'rejected',
}

class SearchNumberInput extends Component {
  static propTypes = forbidExtraProps({
    label: string,
    onChange: func.isRequired,
    onClick: func.isRequired,
    maxLength: number,
    minLength: number,
    placeholder: string,
    removeBrowserStyling: bool,
    zipCode: shape({
      code: string,
      disposer_count: number,
      is_active: bool,
      showValidationMessage: bool,
    }),
  })

  static defaultProps = {
    label: '',
    minLength: 0,
    maxLength: 255,
    placeholder: null,
    removeBrowserStyling: true,
    zipCode: null,
  }

  state = {
    searchValue: null,
    status: SEARCH_INPUT_STATUS.RESOLVED,
    callback: null,
  }

  /**
   * @description handles the callback after searching.
   * @param resolved
   */
  handleCallback = resolved => {
    if (this.state.callback) {
      this.setState({
        status: resolved
          ? SEARCH_INPUT_STATUS.RESOLVED
          : SEARCH_INPUT_STATUS.REJECTED,
      })
    }
  }

  /**
   * @description Returns the correct rejected message.
   * @param zipCode
   * @returns {*}
   */
  getRejectedMessage = () => (
    <>
      {I18n.t('homepages.wasteProducer.zipCode.isInvalidZipCode')}
      <br />
      {I18n.t('homepages.wasteProducer.zipCode.insertValidZipCode')}
    </>
  )

  /**
   * @description handles the input change event.
   * @param event
   */
  handleChange = event => {
    const { value } = event.target

    const { maxLength, minLength, onChange } = this.props
    const isInputValid =
      value && value.length >= minLength && value.length <= maxLength

    this.setState(
      {
        callback: isInputValid ? this.handleCallback : null,
        searchValue: value,
        status: isInputValid
          ? SEARCH_INPUT_STATUS.PENDING
          : SEARCH_INPUT_STATUS.RESOLVED,
      },
      () => {
        if (isInputValid) onChange(value, this.state.callback)
      },
    )
  }

  handleButtonClick = () => {
    const isPending = this.state.status === SEARCH_INPUT_STATUS.PENDING
    const isRejected = this.state.status === SEARCH_INPUT_STATUS.REJECTED
    const hasRequiredLength =
      this.state.searchValue &&
      this.state.searchValue.length >= this.props.minLength &&
      this.state.searchValue.length <= this.props.maxLength

    if (!isPending) this.props.onClick(this.state.searchValue)
    if (!hasRequiredLength) {
      this.setState({ status: SEARCH_INPUT_STATUS.REJECTED })
    }
    if (isRejected) {
      this.setState({ searchValue: '', status: SEARCH_INPUT_STATUS.RESOLVED })
    }
  }

  /**
   * @description renders the icon.
   * @param backgroundColor
   * @param iconName
   * @return {*}
   */
  renderIcon = ({ backgroundColor, iconName }) => (
    <Icon
      name={iconName}
      className='uk-animation-slide-top-small'
      color={
        [
          BUTTON_BACKGROUND_COLOR.PRIMARY,
          BUTTON_BACKGROUND_COLOR.DANGER,
        ].includes(backgroundColor)
          ? ICON_COLOR.WHITE
          : ICON_COLOR.DEFAULT
      }
    />
  )

  render() {
    const {
      label,
      maxLength,
      onClick,
      placeholder,
      zipCode,
      removeBrowserStyling,
    } = this.props

    const { status, searchValue } = this.state

    const isRejected =
      status === SEARCH_INPUT_STATUS.REJECTED && zipCode.showValidationMessage
    const inputBorderColor = isRejected
      ? INPUT_BORDER_COLOR.DANGER
      : INPUT_BORDER_COLOR.PRIMARY

    return (
      <Tooltip
        distance={25}
        html={this.getRejectedMessage()}
        position={TOOLTIP_POSITION.BOTTOM}
        trigger={TOOLTIP_TRIGGER.MANUAL}
        tooltipId='zipcodeSearchInput'
        open={isRejected}
        zIndex={TOOLTIP_ZINDEX.BEHIND_HEADER}
        inline
      >
        <div
          className={classNames(
            'search-input',
            `search-input--status-${status}`,
            'uk-flex-inline',
            'uk-overflow-hidden',
          )}
        >
          <InputNumber
            borderColor={inputBorderColor}
            hideLabel
            label={label}
            maxLength={maxLength}
            name='' // Input component PropTypes is expecting a name. Just pass empty string in this case
            onChange={this.handleChange}
            onSubmit={() =>
              status !== SEARCH_INPUT_STATUS.PENDING && onClick(searchValue)
            }
            placeholder={placeholder}
            removeBrowserStyling={removeBrowserStyling}
            // Input component PropTypes is expecting a value. If none, then just pass empty string
            value={searchValue || ''}
          />

          <IconButton
            backgroundColor={
              isRejected
                ? BUTTON_BACKGROUND_COLOR.DANGER
                : BUTTON_BACKGROUND_COLOR.PRIMARY
            }
            shape={BUTTON_SHAPE.CORNERED}
            iconName={isRejected ? 'cross' : 'arrow-thin-right'}
            onClick={this.handleButtonClick}
            renderIcon={this.renderIcon}
          />
        </div>
      </Tooltip>
    )
  }
}

export default SearchNumberInput
