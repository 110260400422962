import './style.scss'

import React, { Component } from 'react'
import TextareaAutosize from 'react-autosize-textarea'
import { Translate } from 'react-i18nify'
import { Control } from 'react-redux-form'

import { ControlDefaultProps } from '../../constants'
import FormField from '../../index'

import connector from './connector'
import TextareaControlProps from './schemes'

/**
 * @description Custom textarea component
 * see: http://davidkpiano.github.io/react-redux-form/docs/guides/custom-controls.html
 */
class TextareaControl extends Component {
  /**
   * @function
   */
  componentDidMount() {
    document.addEventListener('change', this.handleCursorPosition, true)
    document.addEventListener('click', this.handleCursorPosition, true)
  }

  /**
   * @function
   */
  componentWillUnmount() {
    document.removeEventListener('change', this.handleCursorPosition, true)
    document.removeEventListener('click', this.handleCursorPosition, true)
  }

  /**
   * @description Override this method in a child class to define an individual field.
   * @return {*}
   */
  getField() {
    if (!this.props.field) return null

    const currentCharacters = this.props.field.value
      ? this.props.field.value.length
      : 0

    return (
      <div
        className={`textarea-control${
          this.props.caption ? ' with-caption' : ''
        }${this.props.disabled ? ' disabled' : ''}`}
        ref={ref => {
          this.textarea = ref
        }}
      >
        <Control.textarea // eslint-disable-line react/jsx-pascal-case
          component={TextareaAutosize}
          model={this.props.field.model}
          placeholder={this.props.placeholder || ''}
          tabIndex={this.props.tabIndex}
          className={this.props.className}
          disabled={this.props.disabled}
          maxLength={this.props.maxLength}
          mapProps={{
            style: {
              minHeight: 100,
            },
          }}
          getRef={node => {
            if (node) this.props.getRef(node.textarea)
          }}
          onKeyPress={
            !this.props.allowSubmissionByKey &&
            (e => {
              if (e.key === 'Enter') e.preventDefault()
            })
          }
        />

        {this.props.caption && (
          <div className='caption'>
            <span>{this.props.caption}</span>
          </div>
        )}
        {this.props.showCharsRemaining && (
          <div className='uk-text-meta'>
            <Translate
              value='general.charsRemaining'
              number={this.props.maxLength - currentCharacters}
            />
          </div>
        )}
      </div>
    )
  }

  /**
   * @function
   * @param {Event} event Passed event by binding method.
   */
  handleCursorPosition = event => {
    if (this.textarea && this.textarea.contains(event.target)) {
      this.props.handleCursorPosition({
        start: event.target.selectionStart,
        end: event.target.selectionEnd,
      })
    }
  }

  render() {
    return <FormField {...this.props} fieldComponent={this.getField()} />
  }
}

TextareaControl.propTypes = TextareaControlProps

TextareaControl.defaultProps = {
  ...ControlDefaultProps,
  caption: '',
  maxLength: 255,
  handleCursorPosition: () => undefined,
  getRef: () => undefined,
  allowSubmissionByKey: false,
}

export default connector(TextareaControl)
