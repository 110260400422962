export const orderTypeDetailsGroupTranslations = {
  form: {
    label: {
      orderType: 'Auftragsart',
      deliveryDate: 'Lieferdatum',
      collectionDate: 'Abholdatum',
      timeOfDayDelivery: 'Zeitraum',
      timeOfDayCollection: 'Zeitraum',
      agreementBegin: 'Vereinbarungsbeginn',
      agreementEnd: 'Vereinbarungsende',
      rythmBegin: 'Rhythmus Startdatum (1. Leerung)',
      interval: 'Intervall',
      intervalWeekdayFirst: 'Wochentag',
      intervalWeekdaySecond: 'Zweiter Wochentag',
    },
    note_rent_time_extension:
      'Die Stellzeit überschreitet 14 Kalendertage. Das Angebot an den Kunden wird eine ' +
      'Pauschale für die Stellzeitverlängerung enthalten.',
    tooltip: {
      orderType:
        '<p><b>Einmalauftrag: </b>' +
        'Einmalige Aufstellung und Abholung eines Behälters inklusive Entsorgung bzw. Verwertung der Abfälle. ' +
        'Die maximale Mietdauer pro Behälter beträgt sechs (6) Wochen.</p>' +
        '<p><b>Dauerauftrag im Turnus: </b>' +
        'Längerfristige Miete eines oder mehrerer Behälter inklusive Entsorgung bzw. Verwertung der Abfälle. ' +
        'Der Tausch des Behälters bzw. der Behälter erfolgt im ausgewählten Intervall.</p>' +
        '<p><b>Dauerauftrag auf Abruf: </b> ' +
        'Längerfristige Miete eines oder mehrerer Behälter inklusive Entsorgung bzw. Verwertung der Abfälle. ' +
        'Der Tausch des Behälters bzw. der Behälter erfolgt im Bedarfsfall. ' +
        'Die marktübliche <u>Reaktionszeit</u> des Entsorgungsunternehmers wird angezeigt. ' +
        'Unter Reaktionszeit wird in diesem Kontext der Zeitraum zwischen Bestellung und Tausch bzw. ' +
        'Abholung des Behälters verstanden.</p>' +
        '<p><b>Sofortbefüllung: </b> ' +
        'Einmalige Anfahrt und Direktbeladung des Fahrzeuges mit den von Ihnen beauftragten Abfällen. ' +
        'Im Standard wird das Entsorgungsunternehmen so kalkulieren, ' +
        'dass Sie als Gewerbetreibender mit eigenem Personal das Fahrzeug beladen.</p>',
    },
    placeholder: {
      orderType: 'Auftragsart',
      deliveryDate: 'Lieferdatum',
      collectionDate: 'Abholdatum',
      timeOfDayDelivery: 'Zeitraum',
      timeOfDayCollection: 'Zeitraum',
      rythmBegin: 'Rhythmus Startdatum (1. Leerung)',
      agreementBegin: 'Vereinbarungsbeginn',
      agreementEnd: 'Vereinbarungsende',
      interval: 'Intervall',
      intervalWeekdayFirst: 'Wochentag',
      intervalWeekdaySecond: 'Zweiter Wochentag',
    },
  },
}
