import { useFormikContext } from 'formik'
import React, { useState } from 'react'
import { I18n } from 'react-i18nify'

import { DropDownInput } from 'components/common/DropDownInput'
import { InputDecimal } from 'components/common/InputDecimal'
import { RadioButton } from 'components/common/RadioButton'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { MONTH_PREVIOUS_CHOICES } from 'components/company/constants'

import { AddAgreementFormValues } from '../../AddAgreementFormSteps'
import { BILLING_MODEL, PRICING } from '../DefineNetPriceForm'
import { getSinglePriceFieldValuesByRoleType } from '../types'

import { inputIsValid } from './helpers'

export const SURCHARGE_REDUCTION = {
  SURCHARGE: 'surcharge',
  REDUCTION: 'reduction',
}

type SinglePriceFormFieldsPropsType = {
  billingModel?: string
  pricing: string
  role: string
  getSinglePriceFieldValuesByRole: getSinglePriceFieldValuesByRoleType
  indexListNames: any
}

export const SinglePriceFormFields = ({
  billingModel,
  pricing,
  role,
  getSinglePriceFieldValuesByRole,
  indexListNames,
}: SinglePriceFormFieldsPropsType) => {
  const translationString = 'addAgreementPageTranslations.steps.4.fields'

  const { handleChange, values, setFieldValue } =
    useFormikContext<AddAgreementFormValues>()

  const {
    rentPrice,
    transportPrice,
    disposalCostTon,
    index: selectedIndex,
    surcharge,
    reduction,
    compensationTon,
    handleCostTon,
  } = getSinglePriceFieldValuesByRole({ role, values })

  const [surchargeReduction, setSurchargeReduction] = useState(
    values[`${role}_index_surcharge_reduction`] ??
      SURCHARGE_REDUCTION.SURCHARGE,
  )
  const surchargeSelected = role =>
    values[`${role}_index_surcharge_reduction`] ===
    SURCHARGE_REDUCTION.SURCHARGE
  const getSurchargeReductionValue = role =>
    surchargeSelected(role) ? surcharge : reduction

  const indexMonths = [
    {
      id: MONTH_PREVIOUS_CHOICES.MONTH_PREVIOUS,
      label: 'Vormonat',
    },
    {
      id: MONTH_PREVIOUS_CHOICES.MONTH_PREVIOUS_BEFORE,
      label: 'Vor-Vormonat',
    },
    {
      id: MONTH_PREVIOUS_CHOICES.MONTH_DELIVERY,
      label: 'Leistungsmonat',
    },
  ]

  const [indexMonth, setIndexMonth] = useState(indexMonths[0].id)

  return (
    <>
      {billingModel === BILLING_MODEL.SINGLE_PRICE && (
        <>
          {
            // Disposal fields
            pricing === PRICING.DISPOSAL && (
              <>
                {/*
                  Mietpreis
                */}
                <InputDecimal
                  name={`${role}_rent_price`}
                  label={I18n.t(`${translationString}.rentPrice.label`)}
                  placeholder={I18n.t(
                    `${translationString}.rentPrice.placeholder`,
                  )}
                  value={rentPrice}
                  maxValue={999.99}
                  onChange={e =>
                    inputIsValid(rentPrice, e.target.value) && handleChange(e)
                  }
                />

                {/* Transportpreis */}
                <InputDecimal
                  name={`${role}_transport_price`}
                  label={I18n.t(`${translationString}.transportPrice.label`)}
                  placeholder={I18n.t(
                    `${translationString}.transportPrice.placeholder`,
                  )}
                  value={transportPrice}
                  maxValue={999.99}
                  onChange={e =>
                    inputIsValid(transportPrice, e.target.value) &&
                    handleChange(e)
                  }
                />
                {/* Entsorgungskosten */}
                <InputDecimal
                  name={`${role}_disposal_cost_ton`}
                  label={I18n.t(`${translationString}.disposalPrice.label`)}
                  placeholder={I18n.t(
                    `${translationString}.disposalPrice.placeholder`,
                  )}
                  value={disposalCostTon}
                  maxValue={4999.99}
                  onChange={e =>
                    inputIsValid(disposalCostTon, e.target.value) &&
                    handleChange(e)
                  }
                />
              </>
            )
          }

          {
            // Compensation fields
            pricing === PRICING.COMPENSATION && (
              <>
                {/* Mietpreis */}
                <InputDecimal
                  name={`${role}_rent_price`}
                  label={I18n.t(`${translationString}.rentPrice.label`)}
                  placeholder={I18n.t(
                    `${translationString}.rentPrice.placeholder`,
                  )}
                  value={rentPrice}
                  maxValue={999.99}
                  onChange={e =>
                    inputIsValid(rentPrice, e.target.value) && handleChange(e)
                  }
                />

                {/* Transportpreis */}
                <InputDecimal
                  name={`${role}_transport_price`}
                  label={I18n.t(`${translationString}.transportPrice.label`)}
                  placeholder={I18n.t(
                    `${translationString}.transportPrice.placeholder`,
                  )}
                  value={transportPrice}
                  maxValue={999.99}
                  onChange={e =>
                    inputIsValid(transportPrice, e.target.value) &&
                    handleChange(e)
                  }
                />
                {
                  // Index
                  indexListNames.length > 0 ? (
                    <DropDownInput
                      dataTestId='index-input'
                      choices={indexListNames.map(indexOption => ({
                        optionValue: indexOption.id,
                        optionLabel: indexOption.name,
                      }))}
                      isPlaceholderClickable
                      label={I18n.t(`${translationString}.index.label`)}
                      name={`${role}_index`}
                      onChange={value => {
                        setFieldValue(`${role}_index`, value.target.value)
                        if (Number(value.target.value) > 0) {
                          setFieldValue(`${role}_compensation_ton`, undefined)
                          setFieldValue(`${role}_index_month`, 0)
                        } else {
                          setFieldValue(`${role}_surcharge`, undefined)
                          setFieldValue(`${role}_reduction`, undefined)
                          setFieldValue(`${role}_index_month`, undefined)
                        }
                      }}
                      placeholder={I18n.t(
                        `${translationString}.index.noValues`,
                      )}
                      value={selectedIndex}
                    />
                  ) : (
                    <ReadOnlyField
                      label={I18n.t(`${translationString}.index.label`)}
                      value={I18n.t(`${translationString}.index.noValues`)}
                    />
                  )
                }

                {
                  // Only render select for months if an index is selected
                  Number(selectedIndex) > 0 && (
                    <>
                      {/* relevanter Monat für Index */}
                      <DropDownInput
                        choices={indexMonths.map(indexOption => ({
                          optionValue: indexOption.id,
                          optionLabel: indexOption.label,
                        }))}
                        isPlaceholderClickable
                        label={I18n.t(`${translationString}.indexMonth.label`)}
                        name={`${role}_index_month`}
                        onChange={item => {
                          setFieldValue(
                            `${role}_index_month`,
                            Number(item.target.value),
                          )
                          setIndexMonth(Number(item.target.value))
                        }}
                        value={indexMonth}
                      />
                    </>
                  )
                }

                {
                  // Only render surcharge and reduction if an index is selected
                  Number(selectedIndex) > 0 && (
                    <>
                      {/* Zuschlag / Abschlag */}
                      <InputDecimal
                        name={`${role}_${surchargeReduction}`}
                        placeholder={I18n.t(
                          `${translationString}.${surchargeReduction}.placeholder`,
                        )}
                        value={getSurchargeReductionValue(role)}
                        maxValue={999.99}
                        onChange={e =>
                          inputIsValid(
                            getSurchargeReductionValue(role),
                            e.target.value,
                          ) && handleChange(e)
                        }
                        label={
                          <div>
                            {/* Zuschlag */}
                            <RadioButton
                              isChecked={surchargeSelected(role)}
                              label={I18n.t(
                                `${translationString}.surcharge.label`,
                              )}
                              name={`${role}-compensation-index-surcharge`}
                              onChange={(event: React.ChangeEvent<any>) => {
                                if (event.target.value === 'on') {
                                  setSurchargeReduction(
                                    SURCHARGE_REDUCTION.SURCHARGE,
                                  )
                                  setFieldValue(
                                    `${role}_index_surcharge_reduction`,
                                    SURCHARGE_REDUCTION.SURCHARGE,
                                  )
                                  setFieldValue(`${role}_surcharge`, reduction)
                                  setFieldValue(`${role}_reduction`, undefined)
                                }
                              }}
                            />
                            {/* Abschlag */}
                            <RadioButton
                              isChecked={!surchargeSelected(role)}
                              label={I18n.t(
                                `${translationString}.reduction.label`,
                              )}
                              name={`${role}-compensation-index-reduction`}
                              onChange={(event: React.ChangeEvent<any>) => {
                                if (event.target.value === 'on') {
                                  setSurchargeReduction(
                                    SURCHARGE_REDUCTION.REDUCTION,
                                  )
                                  setFieldValue(
                                    `${role}_index_surcharge_reduction`,
                                    SURCHARGE_REDUCTION.REDUCTION,
                                  )
                                  setFieldValue(`${role}_reduction`, surcharge)
                                  setFieldValue(`${role}_surcharge`, undefined)
                                }
                              }}
                            />
                          </div>
                        }
                      />
                    </>
                  )
                }

                {
                  // Vergütung
                  (isNaN(Number(selectedIndex)) ||
                    Number(selectedIndex) === 0) && (
                    <InputDecimal
                      name={`${role}_compensation_ton`}
                      label={I18n.t(
                        `${translationString}.compensationTon.label`,
                      )}
                      placeholder={I18n.t(
                        `${translationString}.compensationTon.placeholder`,
                      )}
                      value={compensationTon}
                      maxValue={1999.99}
                      onChange={e =>
                        inputIsValid(compensationTon, e.target.value) &&
                        handleChange(e)
                      }
                    />
                  )
                }

                {/* Handlingkosten */}
                <InputDecimal
                  name={`${role}_handle_cost_ton`}
                  label={I18n.t(`${translationString}.handleCostTon.label`)}
                  placeholder={I18n.t(
                    `${translationString}.handleCostTon.placeholder`,
                  )}
                  value={handleCostTon}
                  maxValue={999.99}
                  onChange={e =>
                    inputIsValid(handleCostTon, e.target.value) &&
                    handleChange(e)
                  }
                />
              </>
            )
          }
        </>
      )}
    </>
  )
}
