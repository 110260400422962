import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import ActionTypes from '../actiontypes/core'
import { APP_CONSTANTS, CARD_LIST_PAGE_SIZE } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetInstructions
 * @description Loads a list of instructions
 */
export const handleGetInstructions = createLogic({
  type: ActionTypes.GET_INSTRUCTIONS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/instruction/`
    let data = { page_size: CARD_LIST_PAGE_SIZE }

    if (action.payload) {
      data = {
        ...data,
        page: action.payload.page || 1,
        page_size: action.payload.pageSize || CARD_LIST_PAGE_SIZE,
      }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }
      if (action.payload.expand) {
        data = { ...data, expand: action.payload.expand.join(',') }
      }
      data = Object.fromEntries(
        Object.entries(data).filter(([, value]) => !!value),
      )
    }

    httpClient
      .get(url, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_INSTRUCTIONS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.core.instruction.handleGetInstructions.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: ActionTypes.GET_INSTRUCTIONS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [handleGetInstructions]
