import './style.scss'

import { arrayOf, func, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { I18n } from 'react-i18nify'

/**
 * @description This component renders and controls ItemSelectionList.
 * @function
 * @param {Object} props the component props
 */
class ItemSelectionList extends Component {
  static propTypes = {
    itemList: arrayOf(shape()).isRequired,
    onSelect: func,
    informationText: string.isRequired,
    noResultsFoundText: string,
  }

  static defaultProps = {
    onSelect: () => undefined,
    noResultsFoundText: I18n.t('inputChooseItemTranslations.noResultsFound'),
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    const { itemList, onSelect, informationText, noResultsFoundText } =
      this.props

    return (
      <div className='item-selection-list'>
        {itemList.length < 1 && (
          <div className='item-selection-list__container'>
            {noResultsFoundText}
          </div>
        )}

        {itemList.length > 0 && (
          <>
            {informationText && (
              <div className='item-selection-list__information'>
                {informationText}
              </div>
            )}
            <div className='item-selection-list__container'>
              {itemList.map(item => {
                const itemName = item.display_name || item.name
                return (
                  <div
                    key={item.id}
                    className='item-selection-list__container--item'
                  >
                    <button
                      data-testid={item.id}
                      onClick={() => onSelect(item.id)}
                      type='button'
                    >
                      {item.image && (
                        <img
                          className='item-selection-list__container--item-image'
                          src={item.image}
                          alt={itemName}
                        />
                      )}
                      <div className='uk-card-title item-selection-list__container--item-title'>
                        {itemName}
                      </div>
                    </button>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    )
  }
}

export default ItemSelectionList
