import { useFormikContext } from 'formik'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'

import { InputText } from '../common/InputText'

export type CustomerAddressValues = {
  street: string
  house_number: string
  postal_code: string
  city: string
}

export const CustomerAddressFields: FC = () => {
  const { errors, handleBlur, handleChange, submitCount, values } =
    useFormikContext<CustomerAddressValues>()

  const isFieldValid = name =>
    values[name] && values[name].length > 0 && !errors[name]
  const getFieldError = name =>
    !isFieldValid(name) && submitCount > 0 ? errors[name] : undefined

  return (
    <>
      <InputText
        error={getFieldError('street')}
        label={
          <Translate value='maklerpremium.addWasteProducerModal.form.street' />
        }
        name='street'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t('maklerpremium.addWasteProducerModal.form.street')}
        value={values.street}
        showCheckmark={isFieldValid}
        withCheckmark
      />
      <InputText
        error={getFieldError}
        label={
          <Translate value='maklerpremium.addWasteProducerModal.form.house_number' />
        }
        name='house_number'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t(
          'maklerpremium.addWasteProducerModal.form.house_number',
        )}
        value={values.house_number}
        showCheckmark={isFieldValid}
        withCheckmark
      />
      <InputText
        error={getFieldError}
        label={
          <Translate value='maklerpremium.addWasteProducerModal.form.postal_code' />
        }
        name='postal_code'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t(
          'maklerpremium.addWasteProducerModal.form.postal_code',
        )}
        value={values.postal_code}
        showCheckmark={isFieldValid}
        withCheckmark
      />
      <InputText
        error={getFieldError}
        label={
          <Translate value='maklerpremium.addWasteProducerModal.form.city' />
        }
        name='city'
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={I18n.t('maklerpremium.addWasteProducerModal.form.city')}
        value={values.city}
        showCheckmark={isFieldValid}
        withCheckmark
      />
    </>
  )
}
