export const changeDisposerFormTranslations = {
  autoOffer: {
    label: 'Neuer Entsorgungspartner',
    placeholder: 'Neuer Entsorgungspartner',
  },
  price: {
    label: 'Einkaufspreis (Brutto)',
    placeholder: '-',
  },
  margin: {
    label: 'Marge',
    placeholder: '-',
  },
  new_start_date: {
    label: 'Neues Startdatum',
    error: {
      inPast: 'Das Startdatum darf nicht in der Vergangenheit liegen',
      afterEnd: 'Das Startdatum darf nicht nach dem Enddatum liegen',
    },
  },
  new_end_date: {
    label: 'Neues Enddatum',
    error: {
      inPast: 'Das Enddatum darf nicht in der Vergangenheit liegen',
      beforeStart: 'Das Enddatum darf nicht vor dem Startdatum liegen',
    },
  },
  noInputResults: {
    noDisposer: 'Zu Ihrer Suchanfrage wurde kein Entsorgungspartner gefunden',
  },
  buttons: {
    submit: 'Entsorger jetzt wechseln',
  },
}
