/**
 * @description selector to get workload list.
 * @param state
 * @return {*}
 */
export const getWorkloadsSelector: (state: {
  [key: string]: { [key: string]: any }
}) => Workload[] = state => state.workload.workloadList

export const getWorkloadPaginationSelector = state => state.workload.pagination
