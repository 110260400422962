import { scroller } from 'react-scroll'

export enum STEP_STATUS {
  INITIAL,
  CREATING,
  COMPLETED,
  EDITING,
}

export const progressStepStatus = (
  stepsStatus: STEP_STATUS[],
  setStepsStatus: (
    value: ((prevState: STEP_STATUS[]) => STEP_STATUS[]) | STEP_STATUS[],
  ) => void,
  step: number,
  scrollOffsets?: { [key: number]: number },
) => {
  const newSteps = {
    [step]:
      stepsStatus[step] === STEP_STATUS.EDITING
        ? STEP_STATUS.COMPLETED
        : stepsStatus[step] + 1,
  }

  if (stepsStatus[step] === STEP_STATUS.CREATING) {
    newSteps[step + 1] = STEP_STATUS.CREATING
  }

  setStepsStatus({
    ...stepsStatus,
    ...newSteps,
  })

  if (scrollOffsets) {
    // when submitting a step or editing one scroll to the top of the previous steps' summary
    scroller.scrollTo(`form_step_${Math.max(1, step - 1)}`, {
      duration: 1000,
      smooth: true,
      offset: scrollOffsets && scrollOffsets[step],
    })
  }
}
