import { Form, Formik } from 'formik'
import React, { FC, useCallback } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'
import * as yup from 'yup'

import {
  DOCUMENT_STATUS_TYPE,
  DOCUMENT_TYPE,
} from 'components/invoice/constants'
import { updateEPDCancelledDocument, updateEPDDocument } from 'actions/invoice'

import { SetDatevForm } from './SetDatevForm'

export type SetDatevFormWrapperValues = Partial<{
  status_change_comment: string
  status_change_set_at: string
}>

interface SetDatevFormWrapperProps {
  onClose?: () => void
  document?: Invoice
}

export const SetDatevFormWrapper: FC<SetDatevFormWrapperProps> = ({
  onClose = () => undefined,
  document = undefined,
}) => {
  const dispatch = useDispatch()
  const documentIsCreditNote =
    document?.document_type === DOCUMENT_TYPE.CREDIT_NOTE ||
    document?.document_type === DOCUMENT_TYPE.CANCELLATION_CREDIT_NOTE
  const updateRequest =
    document?.document_type === DOCUMENT_TYPE.INVOICE ||
    document?.document_type === DOCUMENT_TYPE.CREDIT_NOTE
      ? updateEPDDocument
      : updateEPDCancelledDocument

  const updateDocument = useCallback(
    values => {
      return updateRequest({
        ...values,
        ...(documentIsCreditNote
          ? // backend expects different keys depending on the documents type.
            { credit_note_id: document?.id ?? 0 }
          : { invoice_id: document?.id ?? 0 }),
        document_status: DOCUMENT_STATUS_TYPE.DATEV,
        status_change_before_status: document?.document_status,
      })
    },
    [document, documentIsCreditNote, updateRequest],
  )
  const handleSubmit = values => {
    onClose()
    dispatch(updateDocument(values))
  }

  return (
    <Formik
      initialValues={{
        status_change_comment: '',
        status_change_set_at: undefined,
      }}
      onSubmit={(values: SetDatevFormWrapperValues) => handleSubmit(values)}
      validationSchema={() =>
        yup.object().shape({
          status_change_comment: yup.string(),
          status_change_set_at: yup
            .string()
            .required(
              I18n.t('bookkeepingTranslations.datevModal.fields.datevSetAt'),
            ),
        })
      }
    >
      <Form>
        <SetDatevForm onClose={onClose} />
      </Form>
    </Formik>
  )
}
