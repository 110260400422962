import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import classNames from 'classnames'
import { node, oneOfType, string } from 'prop-types'
import React from 'react'

/**
 * @description This component renders and controls a waste type content component.
 * @function
 * @param {Object} props the component props
 */
const WasteTypeContent = props => (
  <div className={classNames('waste-type-content', 'uk-grid')}>
    <div className='waste-type-content__padding'>
      <h2 className='waste-type-content__headline'>{props.title}</h2>
      <div className='paragraph paragraph--color-gray-light waste-type-content__bodytext'>
        {props.content}
      </div>
      <div className='waste-type-content__display-flex'>
        <div className='uk-float-left'>{props.children}</div>
      </div>
    </div>
  </div>
)

WasteTypeContent.propTypes = forbidExtraProps({
  title: string,
  content: oneOfType([string, node]),
  children: node,
})

WasteTypeContent.defaultProps = {
  title: '',
  content: '',
  children: null,
}

export default WasteTypeContent
