import { Form, Formik } from 'formik'
import React, { FC, ReactText, useEffect } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { sendConvertInquiryConfirmation } from 'actions/inquiry'
import { getUsersByCompany } from 'actions/user'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import { InputText } from 'components/common/InputText'
import { ProgressButton } from 'components/common/ProgressButton'
import { StaticCombobox } from 'components/common/StaticCombobox'
import { createLoadingSelector } from 'selectors/loading'
import { getUsersByCompanyList } from 'selectors/user'

interface ConvertInquiryFormProps {
  onCancel: () => void
  offer: Offer
}

export interface ConvertOrderFormValues {
  contact_person: ReactText
  email: string
}

/**
 * @description This component renders and controls convert form component to send an converting email.
 * @param onCancel
 * @param offer
 * @constructor
 */
export const ConvertInquiryForm: FC<ConvertInquiryFormProps> = ({
  onCancel,
  offer,
}) => {
  const dispatch = useDispatch()

  const usersByCompanyListLoading = useSelector(
    createLoadingSelector(['GET_USERS_BY_COMPANY']),
  )
  const usersByCompanyList = useSelector(getUsersByCompanyList)

  useEffect(() => {
    if (offer?.id && offer.customer_company) {
      dispatch(getUsersByCompany(offer.customer_company))
    }
  }, [dispatch, offer.id, offer.customer_company])

  if (!offer.id && usersByCompanyListLoading) return null

  return (
    <Formik
      initialValues={{
        contact_person: '',
        email: '',
      }}
      onSubmit={(values: ConvertOrderFormValues) => {
        dispatch(
          sendConvertInquiryConfirmation(offer.id, values.contact_person),
        )
        onCancel()
      }}
      validate={(values: ConvertOrderFormValues) => {
        const errors: {
          contact_person?: string
          email?: string
        } = {}
        if (String(values.contact_person) === '') {
          errors.contact_person = `${I18n.t(
            'message.validation.selectionRequired',
          )}`
        }

        if (String(values.email) === '') {
          if (
            String(values.email) === '' &&
            String(values.contact_person) === ''
          ) {
            errors.email = `${I18n.t('message.validation.required')}`
          } else {
            errors.email = `${I18n.t('message.validation.email.isEmpty')}`
          }
        }

        return errors
      }}
      validationSchema={() =>
        yup.object().shape({
          contact_person: yup.string().typeError(''),
          email: yup.string().required(),
        })
      }
    >
      {({
        setValues,
        handleSubmit,
        submitCount,
        touched,
        errors,
        values,
        handleBlur,
        handleChange,
        isValid,
        isSubmitting,
      }) => (
        <>
          <Form
            className='convert-inquiry-form'
            data-testid='convert-inquiry-form'
            onSubmit={handleSubmit}
            noValidate
          >
            <div className='uk-modal-body'>
              <StaticCombobox
                error={
                  submitCount > 0 && touched.contact_person
                    ? errors.contact_person
                    : ''
                }
                isRequired={!values.contact_person}
                dataTestId='convert-inquiry-form-contact-person'
                label={I18n.t(
                  'convertInquiryFormTranslations.contactPerson.label',
                )}
                isLoading={usersByCompanyListLoading}
                name='contact_person'
                noResultsText={I18n.t('inquiryForm.noInputResults.noUsers')}
                options={usersByCompanyList.map(item => ({
                  value: `${item.id}`,
                  label: `${item.first_name} ${item.last_name}`,
                }))}
                onSelectionChange={value => {
                  const selectedUser = usersByCompanyList.filter(
                    user => String(user.id) === value.target.value,
                  )
                  setValues({
                    email: selectedUser[0].email,
                    contact_person: value.target.value,
                  })
                }}
                placeholder={I18n.t(
                  'convertInquiryFormTranslations.contactPerson.placeholder',
                )}
              />

              <InputText
                dataTestId='convert-inquiry-form-email'
                dataTestIdError='form-group-error'
                error={submitCount > 0 && touched.email ? errors.email : ''}
                label={I18n.t('convertInquiryFormTranslations.email.label')}
                name='email'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.email}
                disabled
              />
            </div>
            <div className='uk-modal-footer uk-text-right'>
              <span className='uk-margin-right'>
                <Button
                  backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
                  dataTestId='convert-inquiry-form-close'
                  onClick={onCancel}
                >
                  <Translate value='general.button.cancel' />
                </Button>
              </span>
              <ProgressButton
                backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
                dataTestId='convert-inquiry-form-submit'
                isDisabled={
                  (submitCount > 0 && !isValid) ||
                  (isSubmitting && usersByCompanyListLoading)
                }
                isLoading={isSubmitting && usersByCompanyListLoading}
                onClick={handleSubmit}
                type={BUTTON_TYPE.SUBMIT}
              >
                <Translate value='convertInquiryFormTranslations.buttons.submit' />
              </ProgressButton>
            </div>
          </Form>
        </>
      )}
    </Formik>
  )
}
