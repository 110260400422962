import { useFormikContext } from 'formik'
import React, { FC, useContext, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'react-spinkit'

import { deleteInvoiceCheckPosition } from 'actions/maklerpremium'
import { Button, BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { ErrorMessages } from 'components/common/ErrorMessages'
import { FormStepSummaryRow } from 'components/common/FormSteps'
import { ProgressButton } from 'components/common/ProgressButton'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getInvoiceCheckPositionNetPriceSelector } from 'selectors/maklerpremium'

import {
  InvoiceCheckPositionsFormContext,
  InvoiceCheckPositionsFormValues,
} from '../InvoiceCheckPositionsFormSteps'
import { OrderType } from '../Step2'

export const PositionsSummary: FC = () => {
  const dispatch = useDispatch()

  const {
    errors: formikErrors,
    handleSubmit,
    isSubmitting,
    isValid,
    resetForm,
    setFieldError,
    submitCount,
    values,
  } = useFormikContext<InvoiceCheckPositionsFormValues>()
  const { invoiceCheckPosition, handleCloseModal } = useContext(
    InvoiceCheckPositionsFormContext,
  )

  const isLoading = useSelector(
    createLoadingSelector([
      'CREATE_INVOICE_CHECK_POSITION',
      'UPDATE_INVOICE_CHECK_POSITION',
      'DELETE_INVOICE_CHECK_POSITION',
    ]),
  )

  const errors = useSelector(
    createErrorSelector([
      'CREATE_INVOICE_CHECK_POSITION',
      'UPDATE_INVOICE_CHECK_POSITION',
      'DELETE_INVOICE_CHECK_POSITION',
    ]),
  )

  const priceCalculationLoading = useSelector(
    createLoadingSelector(['GET_INVOICE_CHECK_POSITION_PRICE']),
  )

  useEffect(() => {
    if (errors) {
      setFieldError('non_field_errors', (errors as any).humanReadableError)
    }
  }, [errors, setFieldError])
  const netPrice = useSelector(getInvoiceCheckPositionNetPriceSelector)

  const getNetPriceSummaryRowValue = () => {
    if (
      [OrderType.OTHER_SERVICE, OrderType.RENT].includes(
        values.order_type as OrderType,
      )
    ) {
      return `${values?.net_price} €`.replace('.', ',')
    }
    return netPrice?.toLocaleString('de-DE', {
      style: 'currency',
      currency: 'EUR',
    })
  }

  if (priceCalculationLoading) return <Spinner name='circle' />

  return (
    <div className='maklerpremium-step-summary'>
      <p className='subtitle uk-margin-small-bottom'>
        {I18n.t('invoiceCheckTranslations.positionsForm.steps.3.header')}
      </p>

      <div className='maklerpremium-step-summary_grid-body uk-margin-top'>
        <FormStepSummaryRow
          label={I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.2.fields.otherService.netPrice.label',
          )}
          value={getNetPriceSummaryRowValue()}
        />
      </div>
      <div className='uk-margin-top'>
        <ErrorMessages
          submitCount={submitCount}
          isSubmitting={isLoading}
          isValid={isValid}
          translationBase='invoiceCheckTranslations.positionsForm.steps.2.fields'
          errors={formikErrors}
        />
      </div>
      <div className='uk-margin-top'>
        <ProgressButton
          backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
          onClick={() => {
            handleSubmit()
          }}
          fullWidth
          isDisabled={!isValid || isSubmitting || isLoading}
          isLoading={isSubmitting}
        >
          {I18n.t(
            'invoiceCheckTranslations.positionsForm.steps.3.buttons.saveAndBack',
          )}
        </ProgressButton>
      </div>
      {invoiceCheckPosition && (
        <div className='uk-margin-top'>
          <Button
            backgroundColor={BUTTON_BACKGROUND_COLOR.DANGER}
            fullWidth
            isDisabled={isLoading || isSubmitting}
            onClick={() => {
              dispatch(
                deleteInvoiceCheckPosition(
                  invoiceCheckPosition.id as React.ReactText,
                ),
              )
              resetForm()
            }}
          >
            {I18n.t(
              'invoiceCheckTranslations.positionsForm.steps.3.buttons.deletePosition',
            )}
          </Button>
        </div>
      )}
      <div className='uk-margin-top'>
        <Button
          fullWidth
          isDisabled={isLoading}
          onClick={() => {
            resetForm()
            handleCloseModal()
          }}
        >
          {I18n.t('general.button.cancel')}
        </Button>
      </div>
    </div>
  )
}
