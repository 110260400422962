import './style.scss'

import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'

/**
 * Renders a list wrapper for the following step component.
 */
export const CardStepIndicator: FC<{ children: ReactNode }> = ({
  children,
}) => <ul className='card-step-indicator'>{children}</ul>

interface CardStepIndicatorStepProps {
  count?: string | number
  done?: boolean
  highlightedCount?: boolean
  title: string
  opacify?: boolean
}

/**
 * Renders a single step for the step indicator.
 */
export const CardStepIndicatorStep: FC<CardStepIndicatorStepProps> = ({
  title,
  count = 0,
  done = false,
  highlightedCount = false,
  opacify = false,
}) => (
  <li className='card-step-indicator__step'>
    <div
      className={classNames('card-step-indicator__step-content', {
        'card-step-indicator__step-content--opacify': opacify,
      })}
    >
      <div className='card-step-indicator__step-count' />
      <div className='card-step-indicator__step-description'>
        <span className='card-step-indicator__step-title'>{title}</span>
        {Number(count) > 0 && (
          <span
            className={classNames('card-step-indicator__step-amount', {
              'card-step-indicator__step-amount--highlighted': highlightedCount,
            })}
          >
            {`(${count})`}
          </span>
        )}
      </div>
      <div
        className={classNames(
          'card-step-indicator__step-state',
          { 'card-step-indicator__step-state--light': !done && !opacify },
          { 'card-step-indicator__step-state--done': done },
        )}
      />
    </div>
  </li>
)
