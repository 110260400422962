export const removeNonAlphanumericCharacters = (
  inputString: string,
): string => {
  const regexDigitsSpacesSpecialCharacters =
    /[!"§$%&/()=?`¡“¶¢[\]|{}≠¿'´+*#’\-_.:,;~<>\\^°@]+/g

  return inputString.replace(regexDigitsSpacesSpecialCharacters, '')
}

export const mergeWhitespace = (inputString: string): string =>
  inputString.replace(/\s\s+/, ' ')

export const replaceUmlauts = (inputString: string): string =>
  inputString
    .replace(/ä/g, 'ae')
    .replace(/ö/g, 'oe')
    .replace(/ü/g, 'ue')
    .replace(/ß/g, 'ss')

export const replaceAllWhitespaces = (inputString: string): string =>
  inputString.replace(/ /g, '')

export const splitByWhitespace = (inputString: string): string[] =>
  inputString.split(/\s+/g)

export const removeDuplicates = (inputArray: string[]): string[] =>
  Array.from(new Set(inputArray))

export const removeEmptyEntries = (inputArray: string[]): string[] =>
  inputArray.filter(input => input !== '')

export const isEmptyOrWhitespace = (inputString: string): boolean =>
  inputString.trim() === ''

export const filterAndSortOptions = (options, filterInputValue) => {
  const splitInputValues = removeEmptyEntries(
    removeDuplicates(
      splitByWhitespace(
        replaceUmlauts(
          mergeWhitespace(
            removeNonAlphanumericCharacters(filterInputValue.toLowerCase()),
          ),
        ),
      ),
    ),
  )

  return options
    .map(option => {
      const optionLabel = replaceUmlauts(
        mergeWhitespace(
          removeNonAlphanumericCharacters(option.label.toLowerCase()),
        ),
      )

      // Count matches
      const numberMatches = splitInputValues.reduce(
        (count, value) => (optionLabel.includes(value) ? count + 1 : count),
        0,
      )

      return { ...option, numberMatches }
    })
    .filter(option => option.numberMatches > 0)
    .sort(
      ({ numberMatches: numberMatches1 }, { numberMatches: numberMatches2 }) =>
        numberMatches2 - numberMatches1,
    ) // Greater values come first
}
