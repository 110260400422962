import { createSelector } from 'reselect'

import { ORDER_PROGRESS_TYPES } from '../components/order/constants'

import { getOrderSelector } from './order'
import { getOrderClaimUniFormatSelector } from './orderclaim'

/**
 * @description selector to get an order-status in universal format list.
 * @param state
 * @return {*}
 */
export const getOrderStatesUniFormatSelector = createSelector(
  getOrderSelector,
  order =>
    order.order_status_objects.map(status => ({
      ...status,
      progressType: ORDER_PROGRESS_TYPES.ORDER_PROGRESS_STATUS,
    })),
)

/**
 * @description selector to get an order-status and claims list.
 * @param state
 * @return {*}
 */
export const getOrderStatesAndClaimsSelector = createSelector(
  getOrderStatesUniFormatSelector,
  getOrderClaimUniFormatSelector,
  (states, claims) =>
    [...states, ...claims].sort(
      (a, b) => new Date(a.created_at) - new Date(b.created_at),
    ),
)

export default {}
