import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'

import { PostponeIntervalForm } from './PostponeIntervalForm'

interface PostponeIntervalModalProps {
  callback: any
  showConvertInquiryModal: boolean
  offer: Offer
}

export const PostponeIntervalModal: FC<PostponeIntervalModalProps> = ({
  callback,
  showConvertInquiryModal,
  offer,
}) => {
  const modalId = uniqueId() // from lodash package. Generate a unique ID

  return (
    <Modal
      ariaDescribedBy={modalId}
      isOpen={showConvertInquiryModal}
      onClose={callback}
    >
      <ModalHeader
        onClose={callback}
        title={I18n.t('postponeIntervalModalTranslations.modalTitle')}
        titleId={modalId}
      />
      <div className='uk-modal-body'>
        <p>{I18n.t('postponeIntervalModalTranslations.modalInfo')}</p>

        <PostponeIntervalForm onCancel={callback} offer={offer} />
      </div>
    </Modal>
  )
}
