import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'

import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import { IconButton } from 'components/common/IconButton'

interface ThumbsButtonProps {
  direction?: string
  isDisabled?: boolean
  isSelected?: boolean
  onClick: () => void
}

export const ThumbsButton: FC<ThumbsButtonProps> = ({
  direction = 'up',
  isDisabled = false,
  isSelected = false,
  onClick,
}) => (
  <IconButton
    backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
    className={classNames('thumbs-button', {
      'thumbs-button--green': direction === 'up' && isSelected,
      'thumbs-button--orange': direction === 'down' && isSelected,
    })}
    iconName={`thumbs-${direction}`}
    isDisabled={isDisabled}
    onClick={onClick}
  />
)
