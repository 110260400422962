import './BillingModelFormFields.scss'

import { useFormikContext } from 'formik'
import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { I18n, Translate } from 'react-i18nify'

import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import { RadioPanel } from 'components/common/RadioPanel'
import { RadioPanelGroup } from 'components/common/RadioPanelGroup'

import { AddAgreementFormValues } from '../../AddAgreementFormSteps'
import { ROLE_KEYS } from '../../Step1/helpers'
import { BILLING_MODEL, PRICING } from '../DefineNetPriceForm'

import { clearBillingModelFields } from './helpers'

type BillingModelFormFieldsPropsType = {
  billingModel?: string
  disableSinglePrice: boolean
  role: string
  setBillingModel: Dispatch<SetStateAction<string>>
  disableContainerPrice?: boolean
  pricing: string
}

export const BillingModelFormFields = ({
  billingModel,
  disableSinglePrice,
  role,
  setBillingModel,
  disableContainerPrice = true,
  pricing,
}: BillingModelFormFieldsPropsType) => {
  const { setFieldValue } = useFormikContext<AddAgreementFormValues>()

  useEffect(() => {
    if (
      pricing === PRICING.COMPENSATION &&
      billingModel === BILLING_MODEL.PACKAGE_PRICE
    ) {
      setBillingModel(BILLING_MODEL.CONTAINER_PRICE)
    }
  }, [billingModel, pricing, setBillingModel])

  return (
    <>
      {/*BILLING MODEL*/}
      <div className='radio-panel-group'>
        <RadioPanelGroup
          columns={role === ROLE_KEYS.CUSTOMER ? 3 : 2}
          initialSelection={billingModel}
          label={I18n.t(
            'addAgreementPageTranslations.steps.4.fields.billingModel.label',
          )}
          onChange={value => {
            setFieldValue(`${role}_billing_model`, value)
            setBillingModel(value)

            clearBillingModelFields({ value, role, setFieldValue })
          }}
          enableReinitialize
        >
          {role === ROLE_KEYS.CUSTOMER && pricing === PRICING.DISPOSAL && (
            <RadioPanel value={BILLING_MODEL.PACKAGE_PRICE}>
              <Headline
                color={HEADLINE_COLOR.DARK_GRAY}
                fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
                headlineStyle={HEADLINE_STYLE.H5}
                noMargin
                tag={HEADLINE_TAG.H3}
              >
                <Translate value='addAgreementPageTranslations.steps.4.fields.billingModel.packagePrice' />
              </Headline>
            </RadioPanel>
          )}
          <RadioPanel
            value={BILLING_MODEL.CONTAINER_PRICE}
            isDisabled={disableContainerPrice}
          >
            <Headline
              color={HEADLINE_COLOR.DARK_GRAY}
              fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
              fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
              headlineStyle={HEADLINE_STYLE.H5}
              noMargin
              tag={HEADLINE_TAG.H3}
            >
              <Translate value='addAgreementPageTranslations.steps.4.fields.billingModel.containerPrice' />
            </Headline>
          </RadioPanel>
          <RadioPanel
            value={BILLING_MODEL.SINGLE_PRICE}
            isDisabled={disableSinglePrice}
          >
            <Headline
              color={HEADLINE_COLOR.DARK_GRAY}
              fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
              fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
              headlineStyle={HEADLINE_STYLE.H5}
              noMargin
              tag={HEADLINE_TAG.H3}
            >
              <Translate value='addAgreementPageTranslations.steps.4.fields.billingModel.singlePrice' />
            </Headline>
          </RadioPanel>
        </RadioPanelGroup>
      </div>
    </>
  )
}
