const zipcodeApiTranslations = {
  handleGetZipcodes: {
    error: 'PLZ konnte leider nicht geladen werden.',
  },
  handleGetZipcode: {
    error: 'PLZ konnten leider nicht geladen werden.',
  },
  handleCreateInactiveZipcodeWishList: {
    success: 'Inaktive PLZ Wunschliste wurde erfolgreich gespeichert.',
  },
}

export default zipcodeApiTranslations
