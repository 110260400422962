import React, { FC } from 'react'

/**
 * @description Recursively extracts the actual error messages from an api response's data object.
 * The validation messages can contain simple strings, or nested (infinitely deep) objects or arrays.
 * Example validation message:
 * ```
 * {
 *    non_field_errors: ['message1', 'message2'],
 *    name: ['required', 'another message'],
 *    address: {
 *      name: ['an', 'array', 'of', 'nested', 'validation', 'messages']
 *    }
 * }
 * ```
 */
const getValuesFromError = (field, key) => {
  // We loop the values and currently do not care about the keys. If we want to display the field that caused the
  // error in the future, we need the ke, too.

  // If the field is an array (several error messages), we loop recursively
  if (field instanceof Array) return field.map(getValuesFromError)

  // If the field is nested and contains more messages, we call recursively.
  if (field instanceof Object)
    return Object.values(field).map(getValuesFromError)

  return (
    // In the simplest case we just display the message.
    <div className='form-error' data-testid='api-error-message' key={key}>
      {field}
    </div>
  )
}

/**
 * @description Displays the server side validation messages that are returned by the api. These can be non field errors
 * or other server side validation messages (e.g. email address duplicate validation).
 */
export const ApiValidationMessages: FC<{ error: ApiError }> = props => {
  const errors =
    props.error && props.error.response && props.error.response.data
  if (typeof errors === 'object' && errors !== null) {
    return (
      <>
        {Object.values(errors as { non_field_errors: string[] }).map(
          getValuesFromError,
        )}
      </>
    )
  }
  if (!errors) return null

  return <pre>{errors}</pre>
}
