import './style.scss'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import moment from 'moment'
import React from 'react'
import { I18n } from 'react-i18nify'

import { getName } from 'helper/translations'
import { DATE_FORMATS } from 'constants/app'

import { DetailsSection } from '../../common/DocumentReview/DetailsSection'
import { DropDownInput } from '../../common/DropDownInput'
import { ReadOnlyField } from '../../common/ReadOnly'
import { Textarea } from '../../common/Textarea'
import {
  DOCUMENT_CANCELLATION_REASON,
  DOCUMENT_CANCELLATION_REASONS,
  DOCUMENT_TYPE,
  DOCUMENT_TYPES,
} from '../../invoice/constants'
import {
  INVOICE_CHECK_POSITION_STATES,
  INVOICE_CHECK_POSITION_STATUS,
} from '../InvoiceCheckPositionReviewPage/constants'

import { CancelledPaidDocumentReviewFormValues } from './CancelledPaidDocumentReviewPage'

type CancelPaidDocumentReviewFormPropsType = {
  isReview: boolean
  reviewObject: CancelledPaidDocument
}

export const CancelPaidDocumentReviewForm = (
  props: CancelPaidDocumentReviewFormPropsType,
) => {
  const { isReview, reviewObject } = props

  const { errors, handleBlur, handleChange, submitCount, touched, values } =
    useFormikContext<CancelledPaidDocumentReviewFormValues>()

  const document =
    reviewObject.invoice_object ?? reviewObject.credit_note_object

  const renderCriteria = isReview ? values : reviewObject

  const InternalNote = (
    <>
      <ReadOnlyField
        editable={isReview}
        label={I18n.t(
          'CancelledPaidDocumentReviewPageTranslations.form.internalNote',
        )}
        value={reviewObject.internal_note}
      >
        <Textarea
          label={I18n.t(
            'CancelledPaidDocumentReviewPageTranslations.form.internalNote',
          )}
          maxLength={500}
          name='internal_note'
          onChange={handleChange}
          placeholder={I18n.t(
            'CancelledPaidDocumentReviewPageTranslations.form.internalNotePlaceholder',
          )}
          showRemainingCharacters
          value={values.internal_note}
        />
      </ReadOnlyField>
    </>
  )

  const Review = (
    <>
      <DetailsSection title={isReview ? 'Prüfung' : 'Prüfungsergebnis'}>
        <div className='invoice_check-review'>
          <div
            className={classNames({
              'order-claim-review__review-group': !isReview,
            })}
          >
            {/* DATE OF REVIEW (DAY.MONTH.YEAR) */}
            {!isReview && (
              <ReadOnlyField
                label={I18n.t(
                  'CancelledPaidDocumentReviewPageTranslations.form.reviewedAt',
                )}
                value={moment(reviewObject.reviewed_at).format('L')}
              />
            )}

            {/* INTERNAL NOTE (position in form must be before status) */}
            {isReview && InternalNote}

            {/* STATUS */}
            <ReadOnlyField
              editable={isReview}
              label={I18n.t(
                'CancelledPaidDocumentReviewPageTranslations.form.status',
              )}
              value={
                !isReview
                  ? I18n.t(
                      getName(
                        reviewObject.status,
                        INVOICE_CHECK_POSITION_STATES,
                      ),
                    )
                  : undefined
              }
            >
              <DropDownInput
                error={
                  submitCount > 0 && touched.status
                    ? (errors.status as string)
                    : ''
                }
                dataTestId='status-select'
                choices={INVOICE_CHECK_POSITION_STATES.map(item => ({
                  optionValue: item.id,
                  optionLabel: I18n.t(item.name),
                }))}
                label={I18n.t(
                  'CancelledPaidDocumentReviewPageTranslations.form.status',
                )}
                name='status'
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.status}
                isRequired={
                  Number(values.status) ===
                  INVOICE_CHECK_POSITION_STATUS.STATUS_PENDING
                }
              />
            </ReadOnlyField>

            {/* NAME OF USER WHO MADE REVIEW */}
            {!isReview && (
              <ReadOnlyField
                label={I18n.t(
                  'CancelledPaidDocumentReviewPageTranslations.form.reviewedBy',
                )}
                value={reviewObject.reviewed_by_name}
              />
            )}

            {/* TIME OF REVIEW (HOUR:MINUTE) */}
            {!isReview && (
              <ReadOnlyField
                label={I18n.t(
                  'CancelledPaidDocumentReviewPageTranslations.form.reviewedAtTime',
                )}
                value={moment(reviewObject.reviewed_at).format('LT')}
              />
            )}
          </div>

          {/* INTERNAL NOTE (Position in details should be after reviewer name) */}
          {!isReview && !!renderCriteria.internal_note && InternalNote}

          <div
            className={classNames({
              'order-claim-review__review-message-group': !isReview,
            })}
          >
            {/* REVIEW MESSAGE */}
            {Number(renderCriteria.status) ===
              INVOICE_CHECK_POSITION_STATUS.STATUS_ACCEPTED &&
              !!renderCriteria.review_message && (
                <ReadOnlyField
                  editable={isReview}
                  label={I18n.t(
                    'InvoiceCheckPositionReviewTranslations.' +
                      'invoiceCheckPositionReview.reviewText.label',
                  )}
                  value={document.review_message}
                >
                  <Textarea
                    label={I18n.t(
                      'InvoiceCheckPositionReviewTranslations.' +
                        'invoiceCheckPositionReview.reviewText.placeholder',
                    )}
                    maxLength={500}
                    name='review_message'
                    onChange={handleChange}
                    placeholder={I18n.t(
                      'InvoiceCheckPositionReviewTranslations.' +
                        'invoiceCheckPositionReview.reviewText.placeholder',
                    )}
                    showRemainingCharacters
                    value={values.review_message}
                  />
                </ReadOnlyField>
              )}

            {/* REJECT REASON */}
            {((!isReview && reviewObject.reject_reason) ||
              Number(renderCriteria.status) ===
                INVOICE_CHECK_POSITION_STATUS.STATUS_REJECTED) && (
              <ReadOnlyField
                editable={isReview}
                label={I18n.t(
                  'CancelledPaidDocumentReviewPageTranslations.form.rejectReason',
                )}
                value={reviewObject.reject_reason}
              >
                <Textarea
                  error={
                    submitCount > 0 && touched.reject_reason
                      ? (errors.reject_reason as string)
                      : ''
                  }
                  isRequired={!values.reject_reason}
                  label='Ablehnungsgrund'
                  placeholder='Ablehnungsgrund'
                  maxLength={500}
                  name='reject_reason'
                  onChange={handleChange}
                  showRemainingCharacters
                  value={values.reject_reason}
                />
              </ReadOnlyField>
            )}
          </div>
        </div>
      </DetailsSection>
    </>
  )

  let cancelReasonText = '-'
  const cancelReason = DOCUMENT_CANCELLATION_REASONS.find(
    item => item.id === document.cancel_reason,
  )

  if (
    cancelReason?.id === DOCUMENT_CANCELLATION_REASON.CANCEL_OTHER &&
    document.cancel_reason_text !== ''
  ) {
    cancelReasonText = document.cancel_reason_text
  } else if (cancelReason?.name) {
    cancelReasonText = `${I18n.t(cancelReason.name)}`
  }

  return (
    <>
      <div className='cancelled-paid-document-review'>
        <DetailsSection
          title={I18n.t(
            'InvoiceCheckPositionReviewTranslations.orderDetails.sectionTitle',
          )}
        >
          <div className='document-review-section-order-details__document_section'>
            {/* DOCUMENT NUMBER */}
            {!!document.id && (
              <ReadOnlyField
                label={I18n.t(
                  'CancelledPaidDocumentReviewPageTranslations.form.documentNumber',
                )}
                value={document.document_no}
              />
            )}

            {/* BELEGTYP */}
            <ReadOnlyField
              label={I18n.t(
                'CancelledPaidDocumentReviewPageTranslations.form.documentType',
              )}
              value={I18n.t(
                DOCUMENT_TYPES.find(
                  item => item.id === Number(document.document_no[3]),
                )!.name ?? '-',
              )}
            />

            {/* BELEGDATUM */}
            {document.document_date && (
              <ReadOnlyField
                label={I18n.t(
                  'CancelledPaidDocumentReviewPageTranslations.form.documentDate',
                )}
                value={moment(document.document_date, DATE_FORMATS).format('L')}
              />
            )}

            {/* GESAMTSUMME BRUTTO */}
            {document.gross_price && (
              <ReadOnlyField
                label={I18n.t(
                  'CancelledPaidDocumentReviewPageTranslations.form.documentGrossPrice',
                )}
                value={
                  document.type === DOCUMENT_TYPE.INVOICE
                    ? document.gross_price.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      })
                    : `- ${document.gross_price.toLocaleString('de-DE', {
                        style: 'currency',
                        currency: 'EUR',
                      })}`
                }
              />
            )}
          </div>
          <div className='document-review-section-order-details__document_section'>
            {/* STORNO BELEGNUMMER */}
            {document.id && (
              <ReadOnlyField
                label={I18n.t(
                  'CancelledPaidDocumentReviewPageTranslations.form.cancelledNumber',
                )}
                value={
                  document.cancelled_document_object
                    ? document.cancelled_document_object.document_no
                    : ''
                }
              />
            )}

            {/* BELEGDATUM STORNO */}
            {document.cancelled_at && (
              <ReadOnlyField
                label={I18n.t(
                  'CancelledPaidDocumentReviewPageTranslations.form.documentDate',
                )}
                value={moment(
                  document.cancelled_document_object.document_date,
                  DATE_FORMATS,
                ).format('L')}
              />
            )}

            {/* BENUTZER */}
            {document.cancelled_by && (
              <ReadOnlyField
                label={I18n.t(
                  'CancelledPaidDocumentReviewPageTranslations.form.cancelledUser',
                )}
                value={`${document.cancelled_by.first_name} ${document.cancelled_by.last_name}`}
              />
            )}

            {/* STORNIERUNGSGRUND */}
            <ReadOnlyField
              label={I18n.t(
                'CancelledPaidDocumentReviewPageTranslations.form.cancelledReason',
              )}
              value={cancelReasonText}
            />
          </div>
        </DetailsSection>
      </div>
      {Review}
    </>
  )
}
