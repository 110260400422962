import './style.scss'

import classNames from 'classnames'
import { useFormikContext } from 'formik'
import React, { FC, useContext, useEffect } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import {
  getCompany,
  resetActiveCompanies,
  resetAgreementCompany,
} from 'actions/company'
import { DropDownInput } from 'components/common/DropDownInput'
import { FindCompanyByFilterInput } from 'components/common/FindByFilterInput'
import { FindCompanyByFilterInputActions } from 'components/common/FindByFilterInput/FindCompanyByFilterInput'
import { GoToNextFormStepButton } from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import {
  getAddAgreementCustomerCompanySelector,
  getAddAgreementPartnerCompanySelector,
  getCompanySelector,
} from 'selectors/company'

import {
  AddAgreementFormContext,
  AddAgreementFormValues,
} from '../AddAgreementFormSteps'

import {
  addCompanyToState,
  getChoices,
  getCompanyRole,
  getCompanyRoleKey,
  getErrors,
  getValues,
  ROLES,
} from './helpers'

export const SelectCustomerAndPartnerForm: FC = () => {
  const dispatch = useDispatch()

  const { errors, handleBlur, setFieldValue, values } =
    useFormikContext<AddAgreementFormValues>()

  const { stepStatus, saveStep } = useContext(AddAgreementFormContext)

  const mapCompanyToOption = company => ({
    // eslint-disable-next-line max-len
    label: `${company.empto_external_number}, ${company.name}, ${company.main_address_object?.zipcode} ${company.main_address_object?.location}`,
    value: company.id,
  })

  const company = useSelector(getCompanySelector)
  const customerCompany = useSelector(getAddAgreementCustomerCompanySelector)
  const partnerCompany = useSelector(getAddAgreementPartnerCompanySelector)

  useEffect(() => {
    if (!company.id) return

    const roleKey = getCompanyRoleKey(Number(company.role))

    setFieldValue(`${roleKey}.selected_company`, company)
    setFieldValue(`${roleKey}.company_name`, company?.name)
    setFieldValue(
      `${roleKey}.empto_external_number`,
      company?.empto_external_number,
    )

    addCompanyToState(dispatch, company)
  }, [company, dispatch, setFieldValue])

  return (
    <>
      {ROLES.map((role, index) => {
        const { searchField, selectedCompany } = getValues(role, values)
        const { selectedCompanyError } = getErrors(role, errors)

        return (
          <div key={role}>
            <p
              className={classNames('role-section-headline', {
                'role-section-headline__smaller-height': index === 0,
              })}
            >
              {I18n.t(
                `addAgreementPageTranslations.steps.1.fields.${role}_title`,
              )}
            </p>

            {/*SELECT SEARCH_FIELD*/}
            <>
              <DropDownInput
                choices={getChoices(role)}
                label={I18n.t(
                  `addAgreementPageTranslations.steps.1.fields.${role}_search_field.label`,
                )}
                name={`${role}_search_field`}
                onChange={e => {
                  // Reset other fields
                  setFieldValue(`${role}.company_name`, '')
                  setFieldValue(`${role}.empto_external_number`, undefined)
                  setFieldValue(`${role}.zipcode`, undefined)
                  setFieldValue(`${role}.selected_company`, undefined)

                  // Set search field value
                  setFieldValue(`${role}.search_field`, e.target.value)

                  // Reset filtered results
                  dispatch(resetActiveCompanies())
                  dispatch(resetAgreementCompany(role))
                }}
                showCheckmark
                value={searchField}
                withCheckmark
              />
            </>

            {/*SEARCH*/}
            <>
              <FindCompanyByFilterInput
                action={
                  FindCompanyByFilterInputActions.MAKLER_PREMIUM_COMPANIES
                }
                additionalFilters={{
                  block_auto_offers: false,
                  page_size: 25,
                  is_active: true,
                }}
                initialOptions={
                  customerCompany && partnerCompany
                    ? [customerCompany, partnerCompany]
                    : undefined
                }
                resultType={getCompanyRole(role)}
                label={I18n.t(
                  `addAgreementPageTranslations.steps.1.fields.${role}.${searchField}.label`,
                )}
                mapOptionLabel={mapCompanyToOption}
                name={searchField}
                noOptionsMessage={I18n.t('general.form.noOptions')}
                placeholder={I18n.t(
                  `addAgreementPageTranslations.steps.1.fields.${role}.${searchField}.placeholder`,
                )}
                handleBlur={handleBlur}
                handleSelectionChange={(value, companies) => {
                  const selectedCompanyOption = companies.find(
                    item => item.id === value,
                  )
                  dispatch(
                    getCompany({
                      id: value,
                      slug: selectedCompanyOption?.slug,
                    }),
                  )
                }}
                value={selectedCompany?.id}
                error={selectedCompanyError}
                withCheckmark
              />
            </>
          </div>
        )
      })}

      <GoToNextFormStepButton
        buttonText={
          stepStatus === STEP_STATUS.EDITING
            ? I18n.t('general.button.save')
            : I18n.t('createInquiryTranslations.form.button.nextStep')
        }
        onClick={saveStep}
        isDisabled={
          !(
            values?.customer.selected_company &&
            values?.partner.selected_company
          )
        }
      />
    </>
  )
}
