import uniqueId from 'lodash.uniqueid'
import { bool, func, oneOfType, shape, string } from 'prop-types'
import React, { Component } from 'react'
import { I18n } from 'react-i18nify'

import ModalHeader from 'components/common/ModalHeader'
import { Modal } from 'components/common/Modal'
import { OfferScheme } from 'schemes/offer'
import { UserScheme } from 'schemes/user'

import { OFFER_DETAILS_MODALS } from '../../constants'

import { ResendOfferForm } from './ResendOfferForm'

class ResendOfferModal extends Component {
  static propTypes = {
    callback: func.isRequired,
    offer: shape(OfferScheme).isRequired,
    state: oneOfType([string, bool, shape()]).isRequired,
    user: shape(UserScheme).isRequired,
    history: shape({
      push: func,
    }).isRequired, // in TS this is type { History } from history, but I'll not map the full type here
  }

  render() {
    const resendOfferModalId = uniqueId() // from lodash package. Generate a unique ID

    const { callback, offer, state, user, history } = this.props

    return (
      <Modal
        ariaDescribedBy={resendOfferModalId}
        isOpen={
          state === true || state === OFFER_DETAILS_MODALS.RESEND_OFFER_MODAL
        }
        onClose={callback}
      >
        <ModalHeader
          onClose={callback}
          title={I18n.t('offerForm.resendOffer.button')}
          titleId={resendOfferModalId}
        />
        <ResendOfferForm
          offer={offer}
          onClose={callback}
          user={user}
          history={history}
        />
      </Modal>
    )
  }
}

export default ResendOfferModal
