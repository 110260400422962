import ActionTypes from '../actiontypes/orderclaim'

/**
 * @function
 * @name getOrderClaims
 * @description Loads a list of order-claim objects.
 * @return {Object}
 */
export const getOrderClaims = (page, filters, sortedBy) => ({
  type: ActionTypes.GET_ORDER_CLAIM_LIST_REQUEST,
  payload: {
    page,
    filters,
    sortedBy,
  },
})

/**
 * @function
 * @name getOrderClaim
 * @description Loads a single order-claim object.
 * @return {Object}
 */
export const getOrderClaim = orderClaim => ({
  type: ActionTypes.GET_ORDER_CLAIM_REQUEST,
  payload: orderClaim,
})

/**
 * @name createOrderClaim
 * @description creates a new order claim.
 * @return {Object}
 */
export const createOrderClaim = orderClaim => ({
  type: ActionTypes.CREATE_ORDER_CLAIM_REQUEST,
  payload: orderClaim,
})

/**
 * @name updateReviewedOrderClaim
 * @description updates the status and review fields of an order claim proof.
 * @return {Object}
 */
export const updateReviewedOrderClaim = (orderClaim, history) => ({
  type: ActionTypes.UPDATE_REVIEWED_ORDER_CLAIM_REQUEST,
  payload: {
    orderClaim,
    history,
  },
})

/**
 * @name updateOrderClaimInternalNote
 * @description updates the internal note for order claim review.
 * @return {Object}
 */
export const updateOrderClaimInternalNote = orderClaim => ({
  type: ActionTypes.UPDATE_ORDER_CLAIM_INTERNAL_NOTE_REQUEST,
  payload: orderClaim,
})

/**
 * @function
 * @name exportOrderClaims
 * @return {Object}
 */
export const exportOrderClaims = () => ({
  type: ActionTypes.EXPORT_ORDER_CLAIMS_REQUEST,
})

/**
 * @function
 * @name resetOrderClaimItem
 * @return {Object}
 */
export const resetOrderClaimItem = () => ({
  type: ActionTypes.RESET_ORDER_CLAIM_ITEM_REQUEST,
})

export default {}
