import * as React from 'react'

import {
  ClassificationTile,
  ClassificationTileContentProps,
} from './ClassificationTile'
import { ClassificationTileContent } from './ClassificationTileContent'

export const DualClassificationTile = ({
  tiles,
}: {
  tiles: ClassificationTileContentProps[]
}) =>
  tiles.length === 2 ? (
    <article className='classification-tile classification-tile--span'>
      <div className='classification-tile__container uk-flex uk-padding-remove-bottom'>
        {tiles.map(({ caption, value, offsetValue, icon, format }, index) => (
          <ClassificationTileContent
            key={caption}
            caption={caption}
            value={value}
            offsetValue={offsetValue}
            icon={icon}
            hideIcon={!icon || index > 0}
            format={format}
          />
        ))}
      </div>
    </article>
  ) : (
    <ClassificationTile
      caption={tiles[0].caption}
      value={tiles[0].value}
      offsetValue={tiles[0].offsetValue}
      icon={tiles[0].icon}
      removePadding
    />
  )
