import { connect } from 'react-redux'

import {
  getCompanyCertificateSelector,
  getEfbCertificateSelector,
  getOtherCertificateSelector,
  getPriceAgreementSelector,
} from 'selectors/certificate'

/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  efbCertificate: getEfbCertificateSelector(state),
  otherCertificate: getOtherCertificateSelector(state),
  companyCertificate: getCompanyCertificateSelector(state),
  priceAgreement: getPriceAgreementSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @return {Object}
 */
const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)
