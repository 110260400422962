const selectSecurityGroupTranslations = {
  protectionClass: 'Schutzklasse',
  protectionClassNumber: 'Schutzklasse %{protectionClass}',
  noProtectionClassSelection: 'Keine Schutzklasse ausgewählt',
  noSecurityLevelSelection: 'Keine Sicherheitsstufe ausgewählt',
  securityLevel: 'Sicherheitsstufe',
  securityGroup: {
    protectionClassDescription:
      '<p><strong>Schutzklassen:</strong></p>' +
      '<p><strong>Schutzklasse 1:</strong>' +
      'Der Schutzbedarf der Daten ist als normal einzustufen (z.B. personalisierte Werbung).</p>' +
      '<p><strong>Schutzklasse 2:</strong>' +
      'Es besteht ein erhöhter Schutzbedarf (z.B. Personaldaten).</p>' +
      '<p><strong>Schutzklasse 3:</strong>' +
      'Der Schutzbedarf ist besonders hoch (z.B. Patientendaten).</p>',
    protectionClassLink:
      "<a href='/pdf/Vernichtung_gemaß_DIN_66399.pdf' target='_blank' class='nav-link'>\n" +
      'Vernichtung gemäß DIN 66399</a>',
    securityLevelDescription:
      '<p><strong>Sicherheitsstufen:</strong></p>' +
      '<p></p><strong>Stufe 1:</strong> ' +
      'Allgemeine Daten – Wiederherstellung mit einfachem Aufwand</p>' +
      '<p></p><strong>Stufe 2:</strong> ' +
      'Interne Daten – Wiederherstellung mit besonderem Aufwand</p>' +
      '<p></p><strong>Stufe 3:</strong> ' +
      'Sensible Daten – Wiederherstellung mit erheblichem Aufwand</p>' +
      '<p></p><strong>Stufe 4:</strong> ' +
      'Besonders sensible Daten – Wiederherstellung mit außergewöhnlichem Aufwand</p>' +
      '<p></p><strong>Stufe 5:</strong> ' +
      'Geheim zu haltende Daten – Wiederherstellung mit nicht definierbarem Aufwand</p>' +
      '<p></p><strong>Stufe 6:</strong> ' +
      'Geheime Hochsicherheits-Daten – Wiederherstellung aktuell technisch nicht möglich</p>' +
      '<p></p><strong>Stufe 7:</strong> ' +
      'Top Secret Hochsicherheits-Daten – Wiederherstellung ausgeschlossen</p>',
  },
  securityLevelNumber: 'Sicherheitsstufe %{securityLevel}',
}

export default selectSecurityGroupTranslations
