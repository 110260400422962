import './style.scss'

import classNames from 'classnames'
import React, { FC } from 'react'

import Icon from 'components/common/Fontello'
import { SectionHeader } from 'components/common/SectionHeader'

interface DetailsSectionComponentProps {
  icon?: string
  title?: string
  noStyle?: boolean // used for DocumentDetails. FileViewerComponent already has its own style
}

export const DetailsSection: FC<DetailsSectionComponentProps> = ({
  children,
  icon = 'document',
  title = '',
  noStyle = false,
}) => (
  <section className='document-review-detail-section'>
    <SectionHeader>
      <Icon name={icon} />
      {title}
    </SectionHeader>
    <div
      className={classNames({
        'document-review-detail-section__items': !noStyle,
      })}
    >
      {children}
    </div>
  </section>
)
