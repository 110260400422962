import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import FractionActionTypes from '../actiontypes/fraction'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetFraction
 * @description gets list of collection fractions.
 */
const handleGetFractions = createLogic({
  type: [FractionActionTypes.GET_FRACTIONS_REQUEST],
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/fraction/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: FractionActionTypes.GET_FRACTIONS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.fraction.handleGetFraction.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: FractionActionTypes.GET_FRACTIONS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetFractionContent
 * @description gets content text of a fraction.
 */
export const handleGetFractionContent = createLogic({
  type: FractionActionTypes.GET_FRACTION_CONTENT_REQUEST,
  latest: true,
  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/fraction-content/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: FractionActionTypes.GET_FRACTION_CONTENT_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.fraction.handleGetFraction.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: FractionActionTypes.GET_FRACTION_CONTENT_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetFractionsForFilter
 * @description gets a list of all Fractions
 */
export const handleGetFractionsForFilter = createLogic({
  type: FractionActionTypes.GET_FRACTIONS_FOR_FILTER_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/fraction/for-filter/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: FractionActionTypes.GET_FRACTIONS_FOR_FILTER_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.fraction.handleGetContainer.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )

        dispatch({
          type: FractionActionTypes.GET_FRACTIONS_FOR_FILTER_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [
  handleGetFractions,
  handleGetFractionContent,
  handleGetFractionsForFilter,
]
