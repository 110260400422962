const inquiryApiTranslations = {
  handleGetOpenInquiries: {
    error: 'Offene Anfragen konnten leider nicht geladen werden.',
  },
  handleGetMyInquiries: {
    error: 'Ihre Anfragen konnten nicht leider geladen werden.',
  },
  handleCreateInquiry: {
    success: 'Anfrage wurde erfolgreich hinzugefügt.',
    error: 'Hinzufügen der Anfrage leider fehlgeschlagen.',
  },
  handleGetInquiry: {
    error: 'Anfrage konnte leider nicht geladen werden.',
    errorNotOpenAnymore:
      'Die Anfrage ist nicht mehr offen. Es kann nicht mehr geboten werden.',
  },
  handleExportInquiry: {
    error: 'Exportieren der Anfragen leider fehlgeschlagen.',
  },
  handleCancelInquiry: {
    success: 'Anfrage wurde erfolgreich storniert.',
    error: 'Stornieren der Anfrage leider fehlgeschlagen.',
  },
  handleInformWasteProducer: {
    success: 'Entsorger/Makler wurde informiert.',
    error: 'Fehler beim Informieren des Entsorgers/Maklers.',
  },
  handleGetAvvs: {
    error: 'AVVs konnten leider nicht geladen werden.',
  },
  handleSendConvertInquiryConfirmation: {
    success: 'E-Mail erfolgreich versendet',
    error: 'E-Mail konnte nicht versendet werden',
  },
}

export default inquiryApiTranslations
