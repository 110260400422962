export const documentReviewPageTranslations = {
  companyDetails: {
    sectionTitle: 'Unternehmen',
    companyName: 'Vollständiger Name des Unternehmens',
    costumerNumber: 'Kundennummer',
    companyAddress: {
      street: 'Straße',
      houseNumber: 'Hausnummer',
      zipCode: 'PLZ',
      location: 'Ort',
    },
  },
  documentDetails: {
    sectionTitle: {
      singular: 'Dokument',
      plural: 'Dokumente',
    },
  },
  orderDetails: {
    sectionTitle: 'Auftrag',
    orderNumber: 'Auftragsnummer',
    aez: 'Gewerbetreibender/Makler',
    collectionAddress: 'Leistungsadresse',
    collectionDate: 'Leistungsdatum',
    disposer: 'Abfallentsorger/Makler',
    avv: 'AVV Nummer',
    fraction: 'Abfallart',
    fineFraction: 'Feinfraktion',
    container: 'Behälter',
    numberOfContainers: 'Anzahl der Behälter',
    quantityInCubicMeters: 'Menge in m\u00B3',
  },
  orderClaimDetails: {
    sectionTitle: 'Mitteilung des Kunden',
    companyName: 'Unternehmen',
    claimer: {
      name: 'Name',
      position: 'Position',
      phoneNumber: 'Telefonnummer',
      email: 'E-Mail Adresse',
    },
    createdAt: 'Zeitstempel der Reklamation',
    claimType: 'Grund',
    claimDescription: 'Nachricht',
  },
  certificateReview: {
    sectionTitle: 'Nachweis-Informationen',
    validUntil: 'Gültig bis',
    issuedAt: 'Ausgestellt am',
    dispositedPrices:
      'Sind sämtliche Preise der Vereinbarung im System hinterlegt?',
    dispositedPricesShort: 'Preise im System hinterlegt?',
    internalNote: 'Interner Kommentar',
    status: 'Status',
    rejectReason: {
      label: 'Begründung für Reklamation',
      placeholder:
        'Bitte begründen Sie dem empto Nutzer (extern) Ihre Reklamation',
    },
    reviewedBy: 'Geprüft von',
    reviewedAt: 'Geprüft am',
  },
  executionProofReview: {
    sectionTitle: 'Leistungsnachweis-Informationen',
    fractionIsEqual: {
      small: 'Abfallart und die AVV-Nummer identisch?',
      long: 'Sind die Abfallart und die AVV-Nummer im Auftrag und im Nachweis identisch?',
    },
    fraction: 'Abfallart',
    fineFraction: 'Feinfraktion',
    avv: 'AVV Nummer',
    weightDate: 'Verwiegedatum',
    chooseWeight: 'Gewicht (in kg)',
    chooseNumber: 'Behälteranzahl (in Stück)',
    weightReceipt: 'Wiegescheinnummer %{number}',
    weight: 'Gewicht des Behälter %{number} (in kg)',
    button: {
      addMoreContainerWeight: 'Behälter hinzufügen',
      removeLastContainerWeight: 'Behälter entfernen',
    },
    numberOfContainers: 'Behälteranzahl (in Stück)',
    quantityInCubicMeters: 'Menge in m\u00B3',
    internalNote: 'Interner Kommentar',
    status: 'Status',
    rejectReason: {
      label: 'Bitte begründen Sie dem empto Nutzer (extern) Ihre Reklamation',
      placeholder: 'Begründung für Reklamation',
    },
    reviewedBy: 'Geprüft von',
    reviewedAt: 'Geprüft am',
    disposal_proof_number: {
      label: 'Entsorgungsnachweisnummer',
      placeholder: 'Entsorgungsnachweisnummer (12-stellig)',
    },
    transportation_company_number: {
      label: 'Beförderernummer',
      placeholder: 'Beförderernummer (9-stellig)',
    },
    disposer_number: {
      label: 'Entsorgernummer',
      placeholder: 'Entsorgernummer (9-stellig)',
    },
  },
  orderClaimReview: {
    sectionTitle: {
      review: 'Reklamationsprüfung',
      details: 'Prüfungsergebnis',
    },
    internalNote: 'Interner Kommentar',
    waitingTimeDate: 'Datum der Wartezeit/Sortierkosten',
    serviceDate: 'Datum der Leerfahrt',
    effortDuration: 'Dauer des Aufwands',
    status: 'Status',
    reviewedBy: 'Geprüft von',
    reviewedAt: 'Geprüft am',
    timeOfReview: 'Zeitstempel der Prüfung',
    acceptDeadhead:
      'Mit der Freigabe dieses Problems wird die Fakturierung der Leerfahrt angestoßen.',
    acceptWaitingTime:
      'Mit der Freigabe dieses Problems wird die Fakturierung der Wartezeit/Sortierkosten angestoßen.',
    rejectReason: {
      label: {
        review: 'Ablehnungsgrund',
        details: 'Begründung',
      },
      placeholder: 'Ablehnungsgrund',
    },
    reviewText: {
      label: 'Begründung',
      placeholder: 'Nachricht des Prüfungsergebnisses',
    },
  },
}
