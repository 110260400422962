/**
 * @description selector to get if the mobile menu is visible.
 * @param state
 * @return {*}
 */
export const getMobileMenuVisibleSelector = state => state.app.mobileMenuVisible

export const cookiesAcceptedSelector = state => state.app.cookiesAccepted

export default {}
