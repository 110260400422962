import './style.scss'

import React, { FC } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { INQUIRY_ORDER_TYPE } from 'components/inquiry/constants'
import { ORDER_PROGRESS_TYPES, ORDER_STATUS } from 'components/order/constants'
import { BUSINESS_SEGMENT } from 'constants/app'
import { getCurrentUserSelector } from 'selectors/user'

import { ConfirmExecution } from './ConfirmExecution'
import { OrderDisposeForm } from './OrderDisposeForm'
import { OrderEvaluation } from './OrderEvaluation'
import { SetETA } from './SetETA'
import { UploadExecutionProof } from './UploadExecutionProof'

interface OrderStatusTasksComponentProps {
  order: Order
  orderStates?: OrderStatusUni[]
}

export const OrderStatusTasks: FC<OrderStatusTasksComponentProps> = ({
  order,
  orderStates = [],
}) => {
  const user = useSelector(getCurrentUserSelector)

  /**
   * @description renders the component for confirm execution on delivery.
   * @return {*}
   */
  const renderConfirmExecution = (isCollection, isDelivery) => {
    const isOrderTypeOneTime =
      order.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME

    let headlineText
    let buttonText

    if (isCollection) {
      headlineText = I18n.t(
        'orderStatusTasks.confirmExecution.headlineCollection',
      )
      buttonText = I18n.t('orderStatusTasks.confirmExecution.submitCollection')
    }

    if (isOrderTypeOneTime && isDelivery) {
      headlineText = I18n.t('orderStatusTasks.confirmExecution.submitDelivery')
      buttonText = I18n.t('orderStatusTasks.confirmExecution.submitDelivery')
    }

    return (
      <ConfirmExecution
        order={order}
        buttonText={buttonText}
        headlineText={headlineText}
        confirmCollection={isCollection}
        confirmDelivery={isDelivery}
      />
    )
  }

  /**
   * @description renders the component for set eta.
   * @param isCollection
   * @param isDelivery
   * @return {*}
   */
  const renderSetEta = (isCollection, isDelivery) => {
    const isOrderTypeOneTime =
      order.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME

    let headlineText

    if (isCollection) {
      headlineText = I18n.t('orderStatusTasks.setETA.headlineCollection')
    }

    if (isOrderTypeOneTime && isDelivery) {
      headlineText = I18n.t('orderStatusTasks.setETA.headlineDelivery')
    }

    return (
      <SetETA
        order={order}
        headlineText={headlineText}
        etaCollection={isCollection}
        etaDelivery={isDelivery}
      />
    )
  }

  /**
   * @description get the correct component for status and offer company.
   * @param orderStatus
   * @return {*}
   */
  const renderTaskComponent = orderStatus => {
    const userBusinessSegment = user.company_object?.business_segment
    if (userBusinessSegment !== BUSINESS_SEGMENT.BUSINESS_EMPTO) {
      return null
    }

    switch (orderStatus.value) {
      case ORDER_STATUS.DISPOSER_DID_NOT_DISPOSE:
        return <OrderDisposeForm order={order} />
      case ORDER_STATUS.DISPOSER_WILL_EXECUTE:
      case ORDER_STATUS.DISPOSER_DID_DISPOSE:
      case ORDER_STATUS.DISPOSER_DID_DISPOSE_WITH_DATE:
      case ORDER_STATUS.DISPOSER_DID_POSTPONE_DELIVERY:
      case ORDER_STATUS.DISPOSER_DID_POSTPONE_COLLECTION:
      case ORDER_STATUS.DISPOSER_WILL_ARRIVE_DELIVERY:
      case ORDER_STATUS.DISPOSER_WILL_ARRIVE_COLLECTION:
        if (
          order.offer_object.with_estimated_time_of_arrival &&
          !order.eta_delivery
        ) {
          return renderSetEta(false, true)
        }
        if (!order.delivery_date_executed) {
          return renderConfirmExecution(false, true)
        }
        if (
          order.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME &&
          order.offer_object.with_estimated_time_of_arrival &&
          !order.eta_collection
        ) {
          return renderSetEta(true, false)
        }
        if (
          order.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME &&
          !order.collection_date_executed
        ) {
          return renderConfirmExecution(true, false)
        }

        return null
      case ORDER_STATUS.DISPOSER_DID_EXECUTE_DELIVERY:
        if (
          order.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME &&
          order.offer_object.with_estimated_time_of_arrival &&
          !order.eta_collection
        ) {
          return renderSetEta(true, false)
        }
        if (
          order.order_type === INQUIRY_ORDER_TYPE.TYPE_ONE_TIME &&
          !order.collection_date_executed
        ) {
          return renderConfirmExecution(true, false)
        }

        return <UploadExecutionProof order={order} />
      case ORDER_STATUS.DISPOSER_DID_EXECUTE_COLLECTION:
        return <UploadExecutionProof order={order} />
      case ORDER_STATUS.EXECUTION_PROOFS_REJECTED:
        return <UploadExecutionProof order={order} />
      default:
        return null
    }
  }

  const [lastOrderStatus] = orderStates
    .filter(
      item => item.progressType === ORDER_PROGRESS_TYPES.ORDER_PROGRESS_STATUS,
    )
    .slice(-1)

  const companyObject = order.offer_object.company_object

  return (
    <>
      {[companyObject.id, companyObject.makler_premium_company].includes(
        Number(user.company),
      ) && renderTaskComponent(lastOrderStatus)}

      {lastOrderStatus.value >= ORDER_STATUS.EXECUTION_PROOFS_ACCEPTED && (
        <OrderEvaluation order={order} />
      )}
    </>
  )
}
