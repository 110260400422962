export const activitiesSectionTranslations = {
  through: 'durch',
  rhythmChanged: 'Rythmus geändert am',
  agreementPeriodChanged: 'Vereinbarungszeitraum geändert am',
  weekdayChanged: 'Wochentag geändert am',
  disposerChanged: 'Entsorgungspartner gewechselt am',
  orderCreated: 'Auftrag erstellt am',
  conversionRequested: 'Umwandlung in Dauerauftrag angefragt am',
  conversionExecuted: 'In Dauerauftrag umgewandelt am',
}
