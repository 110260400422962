import './styles.scss'

import React, { FC } from 'react'

import { YouTubePlayer } from 'components/common/YouTubePlayer'

interface ComputerFrameProps {
  showImage: boolean
  imageUrl: string
  showYouTubeVideo: boolean
  videoPoster: string
  videoPosterAlt: string
  videoTitle: string
  videoUrl: string
}

export const ComputerFrame: FC<ComputerFrameProps> = ({
  showImage = false,
  imageUrl = '',
  showYouTubeVideo = false,
  videoPoster = '',
  videoPosterAlt = '',
  videoTitle = '',
  videoUrl = '',
}) => (
  <div className='computer-frame'>
    <div className='computer-frame__content'>
      {showImage && <img alt='' src={imageUrl} aria-hidden='true' />}
      {showYouTubeVideo && (
        <YouTubePlayer
          poster={videoPoster}
          posterAlt={videoPosterAlt}
          title={videoTitle}
          url={videoUrl}
        />
      )}
    </div>
  </div>
)
