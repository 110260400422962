import FileDownload from 'js-file-download'
import moment from 'moment/moment'
import { I18n } from 'react-i18nify'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import ActionTypes from '../actiontypes/certificate'
import { CERTIFICATE_TYPE } from '../components/certificate/constants'
import { COMPANY_ROLE } from '../components/company/constants'
import { APP_CONSTANTS } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetMyCertificates
 * @description gets list of certificates that were created by my company
 */
export const handleGetMyCertificates = createLogic({
  type: ActionTypes.GET_MY_CERTIFICATES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload) {
      data = { page: action.payload.page || 1 }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }

      if (action.payload.sortedBy && action.payload.sortedBy.length) {
        data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
      }
    }

    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/certificate/mine/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_MY_CERTIFICATES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.certificate.handleGetMyCertificates.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_MY_CERTIFICATES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCertificates
 * @description gets list of certificates
 */
export const handleGetCertificates = createLogic({
  type: ActionTypes.GET_CERTIFICATES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload) {
      data = { page: action.payload.page || 1 }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }

      if (action.payload.sortedBy && action.payload.sortedBy.length) {
        data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
      }
    }

    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/certificate/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_CERTIFICATES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.certificate.handleGetCertificates.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_CERTIFICATES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCertificate
 * @description gets a single certificate
 */
export const handleGetCertificate = createLogic({
  type: ActionTypes.GET_CERTIFICATE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/certificate/${action.payload}`,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_CERTIFICATE_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.certificate.handleGetCertificates.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_CERTIFICATE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCertificates
 * @description gets list of certificates
 */
export const handleGetMyLatestCertificates = createLogic({
  type: ActionTypes.GET_MY_LATEST_CERTIFICATES_REQUEST,
  latest: true,

  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/certificate/my_latest/`)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_MY_LATEST_CERTIFICATES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.certificate.handleGetMyLatestCertificates.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_MY_LATEST_CERTIFICATES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCertificates
 * @description gets list of certificates
 */
export const handleGetCompaniesPriceAgreements = createLogic({
  type: ActionTypes.GET_COMPANY_PRICE_AGREEMENTS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    const { payload } = action
    const url = `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/certificate/companies_price_agreements/`
    httpClient
      .get(url, payload)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COMPANY_PRICE_AGREEMENTS_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.certificate.handleGetCompaniesPriceAgreements.error',
        )
        const payload_error = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_COMPANY_PRICE_AGREEMENTS_ERROR,
          payload_error,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetCompanyLatestCertificates
 * @description gets list of certificates filtered by company ID
 */
export const handleGetCompanyLatestCertificates = createLogic({
  type: ActionTypes.GET_COMPANY_LATEST_CERTIFICATES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload?.sortedBy && action.payload?.sortedBy.length) {
      data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
    }

    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/certificate/company-latest/${action.payload.companyId}/`,
        data,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_COMPANY_LATEST_CERTIFICATES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.certificate.handleGetMyLatestCertificates.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_COMPANY_LATEST_CERTIFICATES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateCertificate
 * @description Creates a new certificate. This will only work if there is no pending version of this type yet.
 * Otherwise the existing pending certificate should be updated instead.
 */
export const handleCreateCertificate = createLogic({
  type: ActionTypes.CREATE_CERTIFICATE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/certificate/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.CREATE_CERTIFICATE_SUCCESS,
          payload: res,
        })

        if (action.payload.callbacks && action.payload.callbacks.onSuccess)
          action.payload.callbacks.onSuccess()

        // The Company Info Page needs different success messages for its document upload section.
        // isDocument and documentType are used in the context of the Company Info Page.
        let successMessage = ''
        if (
          action.isDocument &&
          action.documentType === `${CERTIFICATE_TYPE.TYPE_EFB}`
        ) {
          successMessage = I18n.t(
            'api.certificate.handleCreateCertificate.successDocumentTypeEfb',
          )
        } else if (
          action.isDocument &&
          action.documentType === `${CERTIFICATE_TYPE.TYPE_PRICE_AGREEMENT}`
        ) {
          successMessage = I18n.t(
            'api.certificate.handleCreateCertificate.successDocumentTypePriceAgreement',
          )
        } else if (action.isDocument) {
          successMessage = I18n.t(
            'api.certificate.handleCreateCertificate.successDocument',
          )
        } else if (!action.isDocument) {
          successMessage = I18n.t(
            'api.certificate.handleCreateCertificate.success',
          )
        }

        toastr.success('', successMessage)
      })
      .then(done)
      .catch(err => {
        const humanReadableError = action.isDocument
          ? I18n.t('api.certificate.handleCreateCertificate.errorDocument')
          : I18n.t('api.certificate.handleCreateCertificate.error')
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.CREATE_CERTIFICATE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateCertificate
 * @description Updates an existing certificate.
 * This will only work if there is a pending certificate of this type.
 */
export const handleUpdateCertificate = createLogic({
  type: ActionTypes.UPDATE_CERTIFICATE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .put(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/certificate/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.UPDATE_CERTIFICATE_SUCCESS,
          payload: res,
        })

        toastr.success(
          '',
          I18n.t('api.certificate.handleUpdateCertificate.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.certificate.handleUpdateCertificate.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.UPDATE_CERTIFICATE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateReviewedCertificate
 * @description Updates an reviewed certificate.
 */
export const handleUpdateReviewedCertificate = createLogic({
  type: ActionTypes.UPDATE_REVIEWED_CERTIFICATE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .put(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/certificate/${action.payload.certificate.id}/review/`,
        action.payload.certificate,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.UPDATE_REVIEWED_CERTIFICATE_SUCCESS,
          payload: res,
        })

        toastr.success(
          '',
          I18n.t('api.certificate.handleUpdateReviewedCertificate.success'),
        )

        if (
          action.payload.user?.company_object?.role !== COMPANY_ROLE.MIDDLE_MAN
        ) {
          action.payload.history.push('/certificate/all')
        } else {
          action.payload.history.goBack()
        }
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.certificate.handleUpdateReviewedCertificate.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.UPDATE_REVIEWED_CERTIFICATE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportCertificates
 * @description triggers the certificates export.
 */
export const handleExportCertificates = createLogic({
  type: ActionTypes.EXPORT_CERTIFICATES_REQUEST,
  latest: true,

  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/certificate/export/`)
      .then(res => {
        FileDownload(
          res.data,
          `empto_Nachweise_Uebersicht_${moment().format('YYYYMMDD')}.csv`,
        )
        dispatch({
          type: ActionTypes.EXPORT_CERTIFICATES_SUCCESS,
        })
      })
      .then(done)
      .catch(err => {
        if (!err.hideToastr)
          toastr.error(
            '',
            I18n.t('api.certificate.handleExportCertificates.error'),
          )

        done()
      })
  },
})

export const handleArchiveCertificate = createLogic({
  type: ActionTypes.ARCHIVE_CERTIFICATE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/certificate/${action.payload.id}/archive/`,
      )
      .then(res => res.data)
      .then(data => {
        dispatch({
          type: ActionTypes.ARCHIVE_CERTIFICATE_SUCCESS,
          payload: data,
        })

        toastr.success(
          '',
          I18n.t('api.certificate.handleArchiveCertificate.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.certificate.handleArchiveCertificate.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.ARCHIVE_CERTIFICATE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetArchivedCertificates
 * @description gets list of archived certificates
 */
export const handleGetArchivedCertificates = createLogic({
  type: ActionTypes.GET_ARCHIVED_CERTIFICATES_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload) {
      data = { page: action.payload.page || 1 }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }

      if (action.payload.sortedBy && action.payload.sortedBy.length) {
        data = { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
      }
    }

    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/certificate/archive/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_ARCHIVED_CERTIFICATES_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.certificate.handleGetCertificates.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_ARCHIVED_CERTIFICATES_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

export default [
  handleGetMyCertificates,
  handleGetCertificates,
  handleGetCertificate,
  handleGetMyLatestCertificates,
  handleGetCompaniesPriceAgreements,
  handleGetCompanyLatestCertificates,
  handleGetArchivedCertificates,
  handleCreateCertificate,
  handleUpdateCertificate,
  handleUpdateReviewedCertificate,
  handleExportCertificates,
  handleArchiveCertificate,
]
