import ActionTypes from '../actiontypes/community'

/** @function
 * @name getCommunities
 * @description An action method to get a list of communities (Landkreise).
 * Can be filtered, e.g. by the start of the code.
 * @return {Object}
 */
export const getCommunities = filters => ({
  type: ActionTypes.GET_COMMUNITIES_REQUEST,
  payload: filters,
})

export default {}
