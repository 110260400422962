import keyMirror from 'keymirror'

const ACCEPT_COOKIES = keyMirror({
  ACCEPT_COOKIES: null,
})

const TOGGLE_MOBILE_MENU = keyMirror({
  TOGGLE_MOBILE_MENU: null,
})

const RESET_API_FETCH_STATES = keyMirror({
  RESET_API_FETCH_ERRORS: null,
  RESET_API_FETCH_LOADING: null,
})

export default {
  ...ACCEPT_COOKIES,
  ...TOGGLE_MOBILE_MENU,
  ...RESET_API_FETCH_STATES,
}
