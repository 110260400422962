import './style.scss'

import { Form, Formik } from 'formik'
import React, { createContext, FC, ReactNode, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { createCustomInvoice, getCustomTax } from 'actions/maklerpremium'
import { FormStep } from 'components/common/FormSteps'
import {
  progressStepStatus,
  STEP_STATUS,
} from 'components/common/FormSteps/helper'
import { BILLING_INTERVAL } from 'components/company/constants'
import { createLoadingSelector } from 'selectors/loading'
import { getCustomTaxSelector } from 'selectors/pricecalc'

import { InvoiceReceiverForm, InvoiceReceiverSummary } from './Step1'
import { AssignedOrderForm, AssignedOrderSummary } from './Step2'
import { AddInvoicePositionForm } from './Step3'
import { CheckPriceSummary } from './Step4'

interface EPDAddInvoiceContextType {
  stepStatus: STEP_STATUS
  saveStep: () => void
  editStep: () => void
  isInvoice: boolean
  toggleIsInvoice: () => void
}

export interface EPDAddInvoicePosition {
  service_date: string
  service: string
  amount: string
  unit: number
  single_net_price: string
  total_net_price: string
  edit_mode: boolean
}

export const EPDAddInvoiceFormContext = createContext<EPDAddInvoiceContextType>(
  {
    stepStatus: STEP_STATUS.INITIAL,
    saveStep: () => undefined,
    editStep: () => undefined,
    isInvoice: true,
    toggleIsInvoice: () => undefined,
  },
)

export type EPDAddInvoiceFormValues = {
  // Step 1
  // search fields
  search_field: string
  selected_company?: Company
  company_name?: string
  empto_external_number?: number
  zipcode?: number
  // readonly fields
  invoice_address?: Address
  // service address
  different_service_address?: Address

  // Step 2
  assigned_order?: Order
  is_not_allowed_on_collective_invoice?: boolean
  is_not_allowed_on_collective_invoice_comment?: string
  assigned_vat: number
  rcv?: boolean
  iban?: string

  // Step 3
  positions?: EPDAddInvoicePosition[]
}

export const EPDAddInvoiceFormSteps: FC = () => {
  const dispatch = useDispatch()
  const [isInvoice, setIsInvoice] = useState<boolean>(true)

  const toggleIsInvoice = () => {
    setIsInvoice(!isInvoice)
  }

  const isLoading = useSelector(createLoadingSelector(['GET_CUSTOM_TAX']))
  const customTaxObject = useSelector(getCustomTaxSelector)

  useEffect(() => {
    if (!isLoading && !customTaxObject) {
      dispatch(getCustomTax())
    }
  })

  let customTax
  if (customTaxObject) {
    customTax = Object.values(customTaxObject).find(
      (tax: any) => tax.is_current_vat,
    )
  }

  const initialValues = {
    search_field: 'company_name',
    assigned_vat: customTax?.tax_in_percent,
    iban: '',
    is_not_allowed_on_collective_invoice: false,
  }

  const validationSchema = yup.object().shape({
    // Step 1: CustomerInfo
    search_field: yup.string(),
    company_name: yup.string(),
    empto_external_number: yup.number(),
    zipcode: yup.string(),
    selected_company: yup.object(),

    // Step 2: Order assignment, vat and document type
    assigned_order: yup.object(),
    is_not_allowed_on_collective_invoice: yup
      .boolean()
      .when(['selected_company', 'assigned_order'], {
        is: (selected_company, assigned_order) => {
          return (
            selected_company?.billing_interval === BILLING_INTERVAL.MONTH &&
            !assigned_order?.is_not_allowed_on_collective_invoice
          )
        },
        then: yup.boolean().required(),
      }),
    is_not_allowed_on_collective_invoice_comment: yup
      .string()
      .when('is_not_allowed_on_collective_invoice', {
        is: is_not_allowed_on_collective_invoice =>
          is_not_allowed_on_collective_invoice,
        then: yup.string().trim().min(1).max(200),
      }),
  })

  const [stepsStatus, setStepsStatus] = useState<STEP_STATUS[]>([
    STEP_STATUS.COMPLETED,
    STEP_STATUS.CREATING,
    STEP_STATUS.INITIAL,
    STEP_STATUS.INITIAL,
    STEP_STATUS.INITIAL,
  ])

  const formSteps = {
    // Step 1: InvoiceReceiverInfo
    1: { form: <InvoiceReceiverForm />, summary: <InvoiceReceiverSummary /> },
    // Step 2: Offer and InvoiceType
    2: { form: <AssignedOrderForm />, summary: <AssignedOrderSummary /> },
    // Step 3: InvoicePositions
    3: {
      form: <AddInvoicePositionForm />,
      summary: <AddInvoicePositionForm />,
    },
    // Step 4: Inputcheck
    4: { form: <CheckPriceSummary />, summary: <CheckPriceSummary /> },
  }

  function renderStep(
    index: string,
    step: { summary: ReactNode; form: ReactNode },
  ) {
    return stepsStatus[Number(index)] > STEP_STATUS.INITIAL &&
      stepsStatus[Number(index)] === STEP_STATUS.COMPLETED
      ? step.summary
      : step.form
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values: EPDAddInvoiceFormValues) => {
        const valuesToSend = {
          positions: values.positions,
          assigned_order_id: values.assigned_order
            ? values.assigned_order.id
            : undefined,
          assigned_vat: values.assigned_vat ?? undefined,
          rcv: values.rcv ?? undefined,
          invoice_address_id: values.different_service_address
            ? values.different_service_address.id
            : values.invoice_address?.id,
          iban: values.iban,
          is_invoice: isInvoice,
          selected_company_id: values.selected_company?.id,
          is_not_allowed_on_collective_invoice:
            values.is_not_allowed_on_collective_invoice,
          is_not_allowed_on_collective_invoice_comment:
            values.is_not_allowed_on_collective_invoice_comment,
        }
        dispatch(createCustomInvoice(valuesToSend))
      }}
    >
      <Form>
        {Object.entries(formSteps).map(([index, step]) => (
          <FormStep
            key={`step_${index}`}
            isDisabled={stepsStatus[Number(index) - 1] < STEP_STATUS.COMPLETED}
            showDivider
            showProgressLine
            stepNumber={
              stepsStatus[Number(index)] < STEP_STATUS.COMPLETED &&
              Number(index)
            }
            title={`addInvoicePageTranslations.epd.steps.${index}.title`}
          >
            <EPDAddInvoiceFormContext.Provider
              value={{
                stepStatus: stepsStatus[Number(index)],
                saveStep: () => {
                  progressStepStatus(stepsStatus, setStepsStatus, Number(index))
                },
                editStep: () => {
                  progressStepStatus(stepsStatus, setStepsStatus, Number(index))
                },
                isInvoice,
                toggleIsInvoice,
              }}
            >
              {renderStep(index, step)}
            </EPDAddInvoiceFormContext.Provider>
          </FormStep>
        ))}
      </Form>
    </Formik>
  )
}
