export enum REQUEST_STATUS {
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
}

export const createRequestStatusSelector: (
  actions: string[],
) => (state: any) => REQUEST_STATUS | undefined = actions => state =>
  actions.map(action => state.requestStatus[action]).find(action => action)
