import { I18n } from 'react-i18nify'
import { actions } from 'react-redux-form'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import AppActionTypes from '../actiontypes/app'
import OrderClaimActionTypes from '../actiontypes/orderclaim'
import { APP_CONSTANTS, CARD_LIST_PAGE_SIZE } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetOrderClaimList
 * @description Loads all order-claim objects by order.
 */
export const handleGetOrderClaimList = createLogic({
  type: OrderClaimActionTypes.GET_ORDER_CLAIM_LIST_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    let data = {}
    const defaultSort = [{ id: 'created_at', desc: false }]

    if (action.payload) {
      data = { page: action.payload.page || 1 }

      if (action.payload.filters) {
        data = {
          ...data,
          ...action.payload.filters,
          page_size: action.payload.filters.page_size || CARD_LIST_PAGE_SIZE,
        }
      }

      data =
        action.payload.sortedBy && action.payload.sortedBy.length
          ? { ...data, order_by: JSON.stringify(action.payload.sortedBy) }
          : { ...data, order_by: JSON.stringify(defaultSort) }
    }
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order-claim/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderClaimActionTypes.GET_ORDER_CLAIM_LIST_SUCCESS,
          payload: res,
        })
        dispatch({
          type: AppActionTypes.RESET_API_FETCH_ERRORS,
          payload: 'GET_ORDER_CLAIM',
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.orderClaim.handleGetOrderClaimList.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OrderClaimActionTypes.GET_ORDER_CLAIM_LIST_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetOrderClaim
 * @description Loads a single order-claim objects by order.
 */
export const handleGetOrderClaim = createLogic({
  type: OrderClaimActionTypes.GET_ORDER_CLAIM_REQUEST,
  latest: true,
  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order-claim/${action.payload}/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderClaimActionTypes.GET_ORDER_CLAIM_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.orderClaim.handleGetOrderClaim.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OrderClaimActionTypes.GET_ORDER_CLAIM_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateOrderClaim
 * @description create order-claim.
 */
export const handleCreateOrderClaim = createLogic({
  type: OrderClaimActionTypes.CREATE_ORDER_CLAIM_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order-claim/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderClaimActionTypes.CREATE_ORDER_CLAIM_SUCCESS,
          payload: res,
        })

        if (action.payload.callbacks && action.payload.callbacks.onSuccess)
          action.payload.callbacks.onSuccess()

        toastr.success(
          '',
          I18n.t('api.orderClaim.handleCreateOrderClaim.success'),
        )
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.orderClaim.handleCreateOrderClaim.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OrderClaimActionTypes.CREATE_ORDER_CLAIM_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateReviewedOrderClaim
 * @description Updates an reviewed order claim.
 */
export const handleUpdateReviewedOrderClaim = createLogic({
  type: OrderClaimActionTypes.UPDATE_REVIEWED_ORDER_CLAIM_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .put(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order-claim/${action.payload.orderClaim.id}/review/`,
        action.payload.orderClaim,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderClaimActionTypes.UPDATE_REVIEWED_ORDER_CLAIM_SUCCESS,
          payload: res,
        })
        dispatch({
          type: OrderClaimActionTypes.GET_ORDER_CLAIM_LIST_REQUEST,
          payload: res,
        })

        toastr.success(
          '',
          I18n.t('api.orderClaim.handleUpdateReviewedOrderClaim.success'),
        )
      })
      .then(() => {
        action.payload.history.go(-1)
      })
      .then(() => dispatch(actions.reset('orderClaim.item')))
      .then(done)
      .catch(err => {
        dispatch(actions.setPending('orderClaim.item', false))
        const humanReadableError = I18n.t(
          'api.orderClaim.handleUpdateReviewedOrderClaim.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OrderClaimActionTypes.UPDATE_REVIEWED_ORDER_CLAIM_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateOrderClaimInternalNote
 * @description Updates the internal note of order claim order claim.
 */
export const handleUpdateOrderClaimInternalNote = createLogic({
  type: OrderClaimActionTypes.UPDATE_ORDER_CLAIM_INTERNAL_NOTE_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .put(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order-claim/${action.payload.id}/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: OrderClaimActionTypes.UPDATE_ORDER_CLAIM_INTERNAL_NOTE_SUCCESS,
          payload: res,
        })

        toastr.success(
          '',
          I18n.t('api.orderClaim.handleUpdateOrderClaimInternalNote.success'),
        )
      })
      .then(done)
      .catch(err => {
        toastr.error(I18n.t('orderClaimProof.toastr.internalNote.error'))
        const humanReadableError = I18n.t(
          'api.orderClaim.handleUpdateOrderClaimInternalNote.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: OrderClaimActionTypes.UPDATE_ORDER_CLAIM_INTERNAL_NOTE_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportOrderClaims
 * @description triggers the execution proofs export.
 */
export const handleExportOrderClaims = createLogic({
  type: OrderClaimActionTypes.EXPORT_ORDER_CLAIMS_REQUEST,
  latest: true,

  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/order-claim/export/`)
      .then(() => {
        toastr.success(
          '',
          I18n.t('api.orderClaim.handleExportOrderClaims.success'),
        )
        dispatch({
          type: OrderClaimActionTypes.EXPORT_ORDER_CLAIMS_SUCCESS,
        })
      })
      .then(done)
      .catch(err => {
        if (!err.hideToastr)
          toastr.error(
            '',
            I18n.t('api.orderClaim.handleExportOrderClaims.error'),
          )
        dispatch({
          type: OrderClaimActionTypes.EXPORT_ORDER_CLAIMS_ERROR,
        })
        done()
      })
  },
})

export default [
  handleCreateOrderClaim,
  handleGetOrderClaimList,
  handleGetOrderClaim,
  handleUpdateReviewedOrderClaim,
  handleUpdateOrderClaimInternalNote,
  handleExportOrderClaims,
]
