import React, { useMemo } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n, Translate } from 'react-i18nify'
import { useHistory } from 'react-router'

import mapMarkerBlack from 'assets/svg/map/mapMarkerBlack.svg'
import mapMarkerCompany from 'assets/svg/map/mapMarkerCompany.svg'
import mapMarkerPrivate from 'assets/svg/map/mapMarkerPrivate.svg'
import mapMarkerWhite from 'assets/svg/map/mapMarkerWhite.svg'
import truckMarkerBlack from 'assets/svg/map/truckMarkerBlack.svg'
import truckMarkerWhite from 'assets/svg/map/truckMarkerWhite.svg'
import { BUSINESS_SEGMENT } from 'constants/app'

import { BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from '../../common/ButtonBar'
import { ICON_NAME } from '../../common/Fontello'
import { GoogleMaps } from '../../common/GoogleMaps'
import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_STYLE,
} from '../../common/Headline'
import { ICON_POSITION, IconButton } from '../../common/IconButton'
import { COMPANY_ROLE } from '../../company/constants'
import { GoogleMapsMarkerType } from '../../inquiry/OpenInquiriesPage/constants'
import { getBusinessSegments } from '../../maklerpremium/helpers'

import { DSZDashboard } from './DSZ/DSZDashboard'
import { EpdDashboard } from './EPD/EPDDashboard'
import { TvpDashboard } from './TVP/TVPDashboard'

type MaklerPremiumDashboardPropsType = {
  dashboardApiResponse: Dashboard
  user: User
}

export const MaklerPremiumDashboard = (
  props: MaklerPremiumDashboardPropsType,
) => {
  const history = useHistory()

  const { offer_companies_this_month_maps: offerCompaniesThisMonthMaps } =
    props.dashboardApiResponse

  const markersWithLatitudeAndLongitude = useMemo(
    () =>
      offerCompaniesThisMonthMaps
        .map((company, index) => ({
          icon: company.private_person ? mapMarkerPrivate : mapMarkerCompany,
          id: index,
          addressId: company.address_id,
          latitude: company.latitude,
          longitude: company.longitude,
          type: GoogleMapsMarkerType.INQUIRY,
          clickable: false,
        }))
        .filter(
          marker => !!Number(marker.latitude) && !!Number(marker.longitude),
        ),
    [offerCompaniesThisMonthMaps],
  )

  // This should never happen but just in case, it returns an empty page if the user's company is either not a
  // middle_man or belongs to business segment empto
  if (
    Number(props.user.company_object.role) !== COMPANY_ROLE.MIDDLE_MAN ||
    Number(props.user.company_object.business_segment) ===
      BUSINESS_SEGMENT.BUSINESS_EMPTO
  )
    return <></>

  // define business_segment booleans
  const { isEPD, isTVP, isDSZ } = getBusinessSegments(props.user)

  /**
   * @description changes the marker color when changing between map layers
   * @param currentMapTypeId
   */
  const handleChangeMapTypeId = currentMapTypeId => {
    if (currentMapTypeId === 'satellite' || currentMapTypeId === 'hybrid') {
      return {
        mapTypeMarkerColor: {
          inquiry: mapMarkerWhite,
          company: truckMarkerWhite,
        },
      }
    }

    return {
      mapTypeMarkerColor: {
        inquiry: mapMarkerBlack,
        company: truckMarkerBlack,
      },
    }
  }

  const googleMapsComponent = () => (
    <div className='dashboard__contents__map'>
      <GoogleMaps
        markers={markersWithLatitudeAndLongitude}
        onChangeMapType={handleChangeMapTypeId}
        onMarkerClusterClick={() => undefined}
      />
    </div>
  )

  const dashboardComponent = (
    <>
      {isEPD && (
        <EpdDashboard
          dashboardApiResponse={props.dashboardApiResponse}
          mapsComponent={googleMapsComponent}
        />
      )}

      {isTVP && (
        <TvpDashboard
          dashboardApiResponse={props.dashboardApiResponse}
          mapsComponent={googleMapsComponent}
        />
      )}

      {isDSZ && (
        <DSZDashboard
          dashboardApiResponse={props.dashboardApiResponse}
          mapsComponent={googleMapsComponent}
        />
      )}
    </>
  )

  return (
    <>
      <Helmet>
        <title>
          {I18n.t('dashboard.MP.headline', {
            firstname: props.user.first_name,
            lastname: props.user.last_name,
          })}
        </title>
      </Helmet>

      <header className='dashboard__header'>
        <Headline
          headlineStyle={HEADLINE_STYLE.H2}
          color={HEADLINE_COLOR.GRAYISH_BROWN}
          fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
          noMargin
        >
          <Translate
            value='dashboard.MP.headline'
            firstname={props.user.first_name}
            lastname={props.user.last_name}
          />
        </Headline>

        {!isDSZ && (
          <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
            <IconButton
              backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
              iconName={ICON_NAME.PLUS}
              iconPosition={ICON_POSITION.RIGHT}
              onClick={() => history.push('/maklerpremium/offer/create')}
            >
              {isEPD && (
                <Translate value='dashboard.MP.EPD.offerCreate.buttonText' />
              )}
              {isTVP && (
                <Translate value='dashboard.MP.TVP.offerCreate.buttonText' />
              )}
            </IconButton>
          </ButtonBar>
        )}
      </header>

      {dashboardComponent}
    </>
  )
}
