import keyMirror from 'keymirror'

export const UserPermission = keyMirror({
  // USER
  CAN_MANAGE_USERS: null,
  ADD_EMAILUSER: null,
  CHANGE_EMAILUSER: null,
  CHANGE_OTHER_USERS: null,
  DELETE_EMAILUSER: null,

  // ACCOUNT
  EXPORT_USERS: null,

  // LOGENTRY
  ADD_LOGENTRY: null,
  CHANGE_LOGENTRY: null,
  DELETE_LOGENTRY: null,

  // ATTACHMENT
  ADD_ATTACHMENT: null,
  CHANGE_ATTACHMENT: null,
  DELETE_ATTACHMENT: null,

  // GROUP
  ADD_GROUP: null,
  CHANGE_GROUP: null,
  DELETE_GROUP: null,

  // PERMISSON
  ADD_PERMISSION: null,
  CHANGE_PERMISSION: null,
  DELETE_PERMISSION: null,

  // TOKEN
  ADD_TOKEN: null,
  CHANGE_TOKEN: null,
  DELETE_TOKEN: null,

  // ADDRESS
  ADD_ADDRESS: null,
  CHANGE_ADDRESS: null,
  DELETE_ADDRESS: null,
  EXPORT_ADDRESSES: null,
  EXPORT_COMPANIES: null,
  EXPORT_DSZ_MYNATURE_COMPANIES: null,
  EXPORT_CERTIFICATES: null,

  // COMPANY
  ADD_COMPANY: null,
  CHANGE_COMPANY: null,
  DELETE_COMPANY: null,
  CAN_BLOCK_CUSTOMER: null,

  // INACTIVEZIPCODEWISHLIST
  ADD_INACTIVEZIPCODEWISHLIST: null,
  CHANGE_INACTIVEZIPCODEWISHLIST: null,
  DELETE_INACTIVEZIPCODEWISHLIST: null,

  // ZIPCODE
  ADD_ZIPCODE: null,
  CHANGE_ZIPCODE: null,
  DELETE_ZIPCODE: null,

  // CONTENTTYPE
  ADD_CONTENTTYPE: null,
  CHANGE_CONTENTTYPE: null,
  DELETE_CONTENTTYPE: null,

  // TESTMODEL
  ADD_TESTMODEL: null,
  CHANGE_TESTMODEL: null,
  DELETE_TESTMODEL: null,

  // EXECUTION
  ADD_EXECUTION: null,
  CHANGE_EXECUTION: null,
  DELETE_EXECUTION: null,

  // CONTAINER
  ADD_CONTAINER: null,
  CHANGE_CONTAINER: null,
  DELETE_CONTAINER: null,

  // FRACTION
  ADD_FRACTION: null,
  CHANGE_FRACTION: null,
  DELETE_FRACTION: null,

  // INQUIRY
  ADD_INQUIRY: null,
  CHANGE_INQUIRY: null,
  DELETE_INQUIRY: null,
  SEND_CONVERT_INQUIRY: null,
  EXPORT_INQUIRIES: null,

  // OFFER
  ADD_OFFER: null,
  CHANGE_OFFER: null,
  CANCEL_OFFER: null,
  ACCEPT_OFFER: null,
  COMPARE_OFFER: null,
  DELETE_OFFER: null,
  POSTPONE_INTERVAL_OFFER: null,
  CHANGE_DISPOSER_OFFER: null,
  EXPORT_OFFERS: null,
  EXPORT_INDEX_VALUES: null,
  EXPORT_AUTO_OFFERS: null,
  EXPORT_ACCEPTED_OFFERS: null,
  EXPORT_OFFERS_OF_INQUIRY: null,
  EXPORT_RENT: null,

  // ORDER
  ADD_ORDER: null,
  VIEW_ORDER: null,
  CHANGE_ORDER: null,
  DELETE_ORDER: null,
  SET_INTERNAL_COMMENT: null,
  EXPORT_ORDER_CLAIMS: null,
  EXPORT_EXECUTION_PROOFS: null,
  EXPORT_ORDERS: null,

  // SESSION
  ADD_SESSION: null,
  CHANGE_SESSION: null,
  DELETE_SESSION: null,

  // SITE
  ADD_SITE: null,
  CHANGE_SITE: null,
  DELETE_SITE: null,

  // MY INQUIRY
  VIEW_MY_INQUIRY: null,
  VIEW_OPEN_INQUIRY: null,
  VIEW_MY_OFFER: null,
  VIEW_ACCEPTED_OFFER: null,

  // CERTIFICATE
  VIEW_CERTIFICATE: null,
  VIEW_MY_CERTIFICATE: null,
  ADD_EFB_CERTIFICATE: null,
  ADD_OTHER_CERTIFICATE: null,
  ADD_PRICE_AGREEMENT: null,
  ADD_COMPANY_CERTIFICATE: null,
  ADD_CERTIFICATE: null,
  CHANGE_CERTIFICATE: null,
  DELETE_CERTIFICATE: null,
  REVIEW_CERTIFICATE: null,
  VIEW_CERTIFICATE_USER_MENU: null,

  // EXECUTIONPROOF
  ADD_EXECUTIONPROOF: null,
  CHANGE_EXECUTIONPROOF: null,
  DELETE_EXECUTIONPROOF: null,
  VIEW_EXECUTIONPROOF: null,
  REVIEW_EXECUTION_PROOF: null,
  VIEW_EXECUTIONPROOF_USER_MENU: null,

  // ORDER
  DISPOSE_ORDER: null,
  SET_ETA_FOR_ORDER: null,
  CONFIRM_EXECUTION_OF_ORDER: null,
  CANCEL_ORDER: null,

  // INDEXVALUES
  ADD_INDEXVALUES: null,
  CHANGE_INDEXVALUES: null,
  VIEW_INDEXVALUES: null,
  DELETE_INDEXVALUES: null,

  // AUTO OFFER
  VIEW_MY_AUTO_OFFER: null,
  ADD_AUTOOFFER: null,
  CHANGE_AUTOOFFER: null,
  DELETE_AUTOOFFER: null,
  VIEW_AUTOOFFER: null,

  // ORDER CLAIM
  ADD_ORDERCLAIM: null,
  CHANGE_ORDERCLAIM: null,
  DELETE_ORDERCLAIM: null,
  REVIEW_ORDERCLAIM: null,
  VIEW_ORDERCLAIM: null,

  // ORDER VALUATION
  ADD_ORDEREVALUATION: null,
  EDIT_ORDEREVALUATION: null,
  DELETE_ORDEREVALUATION: null,

  // INVOICE
  VIEW_INVOICE: null,
  CREATE_INVOICING_DOCUMENTS: null,
  CHANGE_INVOICE: null,
  ADD_CUSTOMINVOICEITEM: null,
  EXPORT_INVOICES: null,

  // EMPTO USER ONLY
  SET_COMPANY_ETA_SERVICE: null,
  CREATE_PHONE_INQUIRIES: null,
  CREATE_PHONE_OFFERS: null,
  CREATE_PHONE_AUTO_OFFERS: null,

  // DISCOUNT
  ADD_DISCOUNT: null,
  CHANGE_DISCOUNT: null,
  REDEEM_DISCOUNT: null,
  DELETE_DISCOUNT: null,
  VIEW_DISCOUNT: null,
  VIEW_DISCOUNT_USER_MENU: null,

  // DISCOUNT REDEMPTION
  ADD_DISCOUNTREDEMPTION: null,
  CHANGE_DISCOUNTREDEMPTION: null,
  DELETE_DISCOUNTREDEMPTION: null,
  EXPORT_DISCOUNT_REDEMPTIONS: null,

  //
  INFORM_WASTE_DISPOSER: null,

  //
  VIEW_NEWS: null,
  IMPORT_CSV_WITH_AUTO_OFFERS: null,

  // DASHBOARD
  VIEW_DASHBOARD_MAKLER_PREMIUM: null,
  VIEW_STAKEHOLDER_DASHBOARD_EPD: null,

  // MAKLER PREMIUM
  ADD_MAKLER_PREMIUM_CUSTOMER: null,
  ADD_MAKLER_PREMIUM_EXECUTION_PROOF: null,
  VIEW_MAKLER_PREMIUM_COMPANY_ADDRESS: null,
  VIEW_MAKLER_PREMIUM_CUSTOMER_BASE_DATA: null,
  VIEW_MAKLER_PREMIUM_PARTNER_BASE_DATA: null,
  CHANGE_MAKLER_PREMIUM_USER: null,
  CONFIRM_MAKLER_PREMIUM_EXECUTION_OF_ORDER: null,
  DISPOSE_MAKLER_PREMIUM_ORDER: null,
  REPORT_MAKLER_PREMIUM_CLAIMS: null,
  SET_MAKLER_PREMIUM_ETA_FOR_ORDER: null,
  VIEW_MAKLER_PREMIUM_INVOICE: null,
  VIEW_MAKLER_PREMIUM_WORKLOAD: null,
  VIEW_COMMUNICATIONCENTER: null,
  ADD_MAKLER_PREMIUM_OFFER: null,
  ADD_MAKLER_PREMIUM_OFFER_FOR_EXISTING_USER: null,
  IS_MAKLER_PREMIUM: null,
  ADD_CUSTOM_AGREEMENT: null,
  CHANGE_RECURRING_OFFER_TIME_PERIOD: null,
  EXPORT_CUSTOMER_DATA: null,
  EXPORT_INVOICE_CHECKS: null,
  EXPORT_OFFER_PIPELINE: null,
  EXPORT_POSTAL_STATISTICS: null,
  EXPORT_WORKLOAD: null,
  EXPORT_MAKLER_PREMIUM_COMPANIES: null,
  VIEW_COLLECTIVE_INVOICING: null,
  CREATE_COLLECTIVE_INVOICING: null,

  // MAKLER PREMIUM BELEGPRÜFUNG
  ADD_INVOICECHECK: null,
  CHANGE_INVOICECHECK: null,
  DELETE_INVOICECHECK: null,
  VIEW_INVOICECHECK: null,
  REVIEW_INVOICE_CHECK_POSITION: null,

  // MAKLER PREMIUM UPCOMING ORDERS
  EXPORT_UPCOMING_ORDERS: null,

  // MAKLER PREMIUM BELEGPRÜFUNG POSITION
  ADD_INVOICECHECKPOSITION: null,
  CHANGE_INVOICECHECKPOSITION: null,
  DELETE_INVOICECHECKPOSITION: null,
  VIEW_INVOICECHECKPOSITION: null,

  // MAKLER PREMIUM BELEGPRÜFUNG WIEGESCHEIN
  ADD_WEIGHTRECEIPT: null,
  CHANGE_WEIGHTRECEIPT: null,
  DELETE_WEIGHTRECEIPT: null,
  VIEW_WEIGHTRECEIPT: null,

  // MAKLER PREMIUM STORNIERTER BEZAHLTER BELEG
  ADD_CANCELLEDPAIDDOCUMENT: null,
  CHANGE_CANCELLEDPAIDDOCUMENT: null,
  DELETE_CANCELLEDPAIDDOCUMENT: null,
  VIEW_CANCELLEDPAIDDOCUMENT: null,
  REVIEW_CANCELLEDPAIDDOCUMENT: null,

  // MAKLER PREMIUM CUSTOM OFFER
  ADD_CUSTOMOFFER: null,
  CHANGE_CUSTOMOFFER: null,
  VIEW_CUSTOMOFFER: null,

  EXPORT_BATCH_FILE_EPD: null,
  EXPORT_BATCH_FILE_DSZ: null,
  EXPORT_MAKLER_PREMIUM_INVOICES: null,
  EXPORT_EPD_COLLECTIVE_INVOICING_FORECAST: null,
  EXPORT_EPD_COLLECTIVE_INVOICING_ACTIVITIES: null,
  EXPORT_EPD_COLLECTIVE_INVOICING: null,

  VIEW_DSZINVOICE: null,
  VIEW_DSZFRACTIONPRICE: null,

  // DSZ
  EXPORT_DSZ_SUPPLEMENT_QUANTITY: null,
  EXPORT_DSZ_QUANTITY: null,
  EXPORT_DSZ_INVOICES: null,

  // COMMUNICATIONS CENTER
  VIEW_COMCENTERCONTACT: null,
  ADD_COMCENTERCONTACT: null,
  CHANGE_COMCENTERCONTACT: null,
  DELETE_COMCENTERCONTACT: null,
})

// eslint-disable-next-line no-redeclare,@typescript-eslint/no-redeclare
export type UserPermission = keyof typeof UserPermission

// IDs/PKs taken from ~/backend/empto/account/fixtures/groups.json
export enum EmptoGroups {
  EMPTO_ADMIN = 8,
  EMPTO_SERVICE,
  EMPTO_INVOICING,
}

enum EPDGroups {
  MAKLER_PREMIUM_EPD_ADMINISTRATOR = 11,
  MAKLER_PREMIUM_EPD_SERVICE,
  MAKLER_PREMIUM_EPD_CONTACT_PERSON,
  MAKLER_PREMIUM_EPD_BOOKKEEPING,
  MAKLER_PREMIUM_EPD_STAKEHOLDER_DASHBOARD,
  MAKLER_PREMIUM_EPD_SERVICE_PLUS = 23,
}

enum TVPGroups {
  MAKLER_PREMIUM_TVP_ADMINISTRATOR = 16,
  MAKLER_PREMIUM_TVP_SERVICE,
  MAKLER_PREMIUM_TVP_BOOKKEEPING,
}

export enum DSZGroups {
  MAKLER_PREMIUM_DSZ_ADMINISTRATOR = 19,
  MAKLER_PREMIUM_DSZ_SERVICE,
  MAKLER_PREMIUM_DSZ_BOOKKEEPING,
  MAKLER_PREMIUM_DSZ_API,
}

export enum AuditorGroups {
  MAKLER_PREMIUM_AUDITOR = 24,
}

export const MaklerPremiumGroups = {
  ...EPDGroups,
  ...TVPGroups,
  ...DSZGroups,
  ...AuditorGroups,
}

export const USER_GROUP_IS_SUPERUSER = -1
