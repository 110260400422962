import { getGoogleMapsURL } from './helpers'

export const GoogleMapsParms = {
  baseUrl: 'https://maps.googleapis.com/maps/api/js',
  v: '3.exp',
  libraries: ['geometry', 'drawing', 'places'],
  key: 'AIzaSyCrpD7Y92GLG7h7DZyS13bZCwNun5Z3aeA',
}

const GoogleMapsUrl = getGoogleMapsURL(GoogleMapsParms)

export default GoogleMapsUrl
