export const priceDetailFieldsTranslations = {
  rentPriceContainerMonth: 'Mietpreis (€ pro Behälter und Monat)',
  disposalCostContainer: 'Transport- und Entsorgungspreis (€ pro Behälter)',
  costContainer: 'Kosten (€ pro Behälter)',
  indexMonth: 'relevanter Monat für Indexwert',
  compensationContainer: 'Vergütung (€ pro Behälter)',
  transportPricePiece: 'Transportpreis (€ pro Stück)',
  creditMaterial: {
    dispose: 'Entsorgungskosten (€ pro Tonne)',
    recycle: 'Verwertungspreis (€ pro Tonne)',
  },
  disposalCostTon: 'Entsorgungskosten (€ pro Tonne)',
  handlingCostTon: 'Handlingskosten (€ pro Tonne)',
  zeroTo4999Placeholder: 'Wert zwischen 0 € und 4999,99 €',
  zeroTo999Placeholder: 'Wert zwischen 0 € und 999,99 €',
  zeroTo1999Placeholder: 'Wert zwischen 0 € und 1999,99 €',
  index: {
    label: 'Index',
    noValues: 'Keine Indizes auswählbar.',
    noIndex: 'Kein Index',
  },
  compensation: {
    compensation: 'Vergütung (€ pro Tonne)',
    surcharge: 'Zuschlag (€ pro Tonne)',
    reduction: 'Abschlag (€ pro Tonne)',
  },
  handleCostTon: 'Handlingskosten (€ pro Tonne)',
  handleCostPlaceholder: 'Wert zwischen 0 € und 999,99 €',
  pricePlaceholder: 'Wert zwischen 0 € und 999,99 €',
  priceModel: 'Preismodell',
  pricePlaceholderCompensation: 'Wert zwischen 0 € und 1999,99 €',
  validation: {
    required: 'Dieses Feld muss befüllt sein.',
    notZero: 'Dieses Feld darf nicht 0 sein.',
  },
}
