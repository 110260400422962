import classNames from 'classnames'
import { useFormikContext } from 'formik'
import React, { useContext } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { FormStepSummaryRow } from 'components/common/FormSteps'
import { LABEL_TYPE } from 'components/common/FormSteps/FormStepSummaryRow'
import { MONTH_PREVIOUS_CHOICES } from 'components/company/constants'
import { getIndexesSelector } from 'selectors/indexes'

import {
  AddAgreementFormContext,
  AddAgreementFormValues,
} from '../AddAgreementFormSteps'
import { ROLES } from '../Step1/helpers'

import { SURCHARGE_REDUCTION } from './components/SinglePriceFormFields'
import { getFields } from './helpers'

export const DefineNetPriceSummary = () => {
  const { values } = useFormikContext<AddAgreementFormValues>()
  const { editStep } = useContext(AddAgreementFormContext)
  const indexList = useSelector(getIndexesSelector)

  return (
    <>
      {ROLES.map((role, index) => {
        const billingModel = values[`${role}_billing_model`]
        const pricing = values[`${role}_pricing`]
        const selectedIndex = indexList.find(
          currentIndex => currentIndex.id === Number(values[`${role}_index`]),
        )
        const indexMonth = ['Vormonat', 'Vor-Vormonat', 'Leistungsmonat'][
          values[`${role}_index_month`]
        ]
        const surchargeSelected =
          values[`${role}_index_surcharge_reduction`] ===
          SURCHARGE_REDUCTION.SURCHARGE
        const fieldValueLabelArray = getFields({
          role,
          billingModel,
          pricing,
          selectedIndex,
          indexMonth,
          surchargeSelected,
        })

        return (
          <>
            <p
              className={classNames('role-section-headline', {
                'role-section-headline__smaller-height': index === 0,
              })}
            >
              {I18n.t(
                `addAgreementPageTranslations.steps.4.summary_${role}_title`,
              )}
              (
              {I18n.t(
                `addAgreementPageTranslations.steps.4.fields.billingModel.${billingModel}`,
              )}
              )
            </p>
            <div className='maklerpremium-step-summary'>
              <div className='maklerpremium-step-summary_grid-body'>
                {fieldValueLabelArray &&
                  fieldValueLabelArray.map(item => {
                    const { value, label } = item
                    // If value refers to an index month number for customer or partner, display the belonging
                    // description, which is stored in 'indexMonth'.
                    if (
                      value === `${role}_index_month` &&
                      [
                        MONTH_PREVIOUS_CHOICES.MONTH_PREVIOUS,
                        MONTH_PREVIOUS_CHOICES.MONTH_PREVIOUS_BEFORE,
                        MONTH_PREVIOUS_CHOICES.MONTH_DELIVERY,
                      ].includes(values[value])
                    ) {
                      return (
                        <>
                          <FormStepSummaryRow
                            label={I18n.t(label)}
                            value={indexMonth}
                            labelType={LABEL_TYPE.DEFAULT}
                          />
                        </>
                      )
                    }
                    // if value refers to a decimal number, render the value it refers to
                    else if (
                      values[value] &&
                      !isNaN(Number(values[value].replace(',', '.')))
                    ) {
                      return (
                        <>
                          <FormStepSummaryRow
                            label={I18n.t(label)}
                            value={`${values[value]} €`}
                            labelType={LABEL_TYPE.DEFAULT}
                          />
                        </>
                      )
                      // else render value directly
                    } else {
                      return (
                        <>
                          <FormStepSummaryRow
                            label={I18n.t(label)}
                            value={value}
                            labelType={LABEL_TYPE.DEFAULT}
                          />
                        </>
                      )
                    }
                  })}
              </div>
            </div>
          </>
        )
      })}

      <div className='maklerpremium-step-summary'>
        <div className='sidebar'>
          <ButtonLink
            className='sidebar__edit-link'
            linkColor={BUTTON_LINK_COLOR.PRIMARY}
            onClick={editStep}
          >
            {I18n.t('createInquiryTranslations.form.button.editStep')}
          </ButtonLink>
        </div>
      </div>
    </>
  )
}
