import React, { FC, useEffect, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { getZipcodes } from 'actions/zipCode'
import { createErrorSelector } from 'selectors/error'
import { createLoadingSelector } from 'selectors/loading'
import { getActiveZipcodesSelector } from 'selectors/zipCode'

import { AsyncMultiselectCombobox } from '../AsyncMultiselectCombobox/AsyncMultiselectCombobox'
import { callbackOrType } from '../Input'

interface ZipcodeControlProps<T extends { [key: string]: any }> {
  dataTestId?: string
  defaultValue?: T | T[]
  getOptionLabel?: (option?: T | T[]) => string
  getOptionValue?: (option?: T | T[]) => string
  name: string
  onBlur: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void
  onChange: (field: string, value: any, shouldValidate?: boolean) => void
  showRequiredDot?: boolean
  value?: T | T[]
  error?: string
  isClearable?: boolean
  showCheckmark?: callbackOrType<boolean>
  withCheckmark?: boolean
}

export const ZipcodeControl: FC<ZipcodeControlProps<any>> = ({
  defaultValue = [],
  getOptionLabel,
  getOptionValue,
  name,
  onBlur,
  onChange,
  showRequiredDot = false,
  value,
  error = '',
  isClearable = false,
  showCheckmark = false,
  withCheckmark = false,
}) => {
  const dispatch = useDispatch()
  const reduxZipcodes = useSelector(getActiveZipcodesSelector)
  const isLoading = useSelector(createLoadingSelector(['GET_ZIPCODES']))
  const apiErrors = useSelector(createErrorSelector(['GET_ZIPCODES']))

  const handleChange = changedValue => {
    onChange(name, changedValue)
  }
  const handleBlur = () => {
    onBlur(name, true)
  }

  const loadOptionsFromApi = (inputValue?: string) => {
    dispatch(getZipcodes({ code: inputValue }))
  }

  const [zipcodes, setZipcodes] = useState<ZipCode[]>([])

  useEffect(() => {
    if (!isLoading) {
      setZipcodes(reduxZipcodes)
    }
  }, [isLoading, reduxZipcodes])

  const placeholder = I18n.t(
    'myAutoOffersPageTranslations.form.fields.zipcodeControl.placeholder',
  )
  const noInputMessage = I18n.t(
    'myAutoOffersPageTranslations.form.fields.zipcodeControl.noInputMessage',
  )
  const noOptionsMessage = I18n.t(
    'myAutoOffersPageTranslations.form.fields.zipcodeControl.noOptionsMessage',
  )
  const loadingMessage = I18n.t(
    'myAutoOffersPageTranslations.form.fields.zipcodeControl.loadingMessage',
  )

  return (
    <AsyncMultiselectCombobox
      defaultValue={defaultValue}
      errors={apiErrors}
      fieldError={error}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      handleBlur={handleBlur}
      handleChange={handleChange}
      isLoading={isLoading}
      label={placeholder}
      loadingMessage={loadingMessage}
      loadOptions={loadOptionsFromApi}
      noInputMessage={noInputMessage}
      noOptionsMessage={noOptionsMessage}
      options={zipcodes}
      placeholder={placeholder}
      showRequiredDot={showRequiredDot}
      value={value}
      isClearable={isClearable}
      showCheckmark={showCheckmark}
      withCheckmark={withCheckmark}
    />
  )
}
