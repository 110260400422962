import React, { FC } from 'react'
import { Helmet } from 'react-helmet-async'
import { I18n } from 'react-i18nify'

import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import { RequiredPermissions } from 'components/common/RequiredPermissions'
import { UserPermission } from 'constants/user'
import { withApiErrorHandling } from 'helper/withApiErrorHandling'
import withErrorBoundary from 'helper/withErrorBoundary'

import { AddAgreementFormSteps } from './AddAgreementFormSteps/AddAgreementFormSteps'

const AddAgreementPageComponent: FC = () => {
  return (
    // Only EPD Administrators or Service+ users are allowed to view this page
    <RequiredPermissions
      requiredPermissions={[UserPermission.ADD_CUSTOM_AGREEMENT]}
    >
      <Helmet>
        <title>{I18n.t('pageTitles.maklerpremiumAddAgreement')}</title>
      </Helmet>

      <div className='formsteps-page'>
        <header className='formsteps-page__header'>
          <div className='uk-container uk-container-large'>
            <div className='formsteps-page__header-content'>
              <Headline
                fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
                headlineStyle={HEADLINE_STYLE.H2}
                tag={HEADLINE_TAG.H2}
                color={HEADLINE_COLOR.GRAYISH_BROWN}
                fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
              >
                {I18n.t('addAgreementPageTranslations.header.title')}
              </Headline>
              <p className='formsteps-page__header-description'>
                {I18n.t('addAgreementPageTranslations.header.description')}
              </p>
            </div>
          </div>
        </header>

        <div className='formsteps-page__body uk-container uk-container-large'>
          <main className='formsteps-page__main formsteps-page__main--without-sidebar'>
            <AddAgreementFormSteps />
          </main>
        </div>
      </div>
    </RequiredPermissions>
  )
}

export const AddAgreementPage = withErrorBoundary(
  withApiErrorHandling(AddAgreementPageComponent, ['GET_INVOICES']),
)
