import { CERTIFICATE_TYPE } from '../components/certificate/constants'

/**
 * @description After a new certificate was uploaded, we need to update our array of latest certificates.
 * Therefore we must either replace an already existing certificate of the same type or otherwise add the newly
 * uploaded one to our array.
 * @param newCertificate
 * @param certificates
 * @returns {*}
 */
export const replaceCertificateOfTypeOrAdd = (newCertificate, certificates) => {
  // Replace an already existing certificate of this type if there is one.
  const newCertificates = certificates.map(certificate =>
    certificate.type === newCertificate.type &&
    newCertificate.type !== CERTIFICATE_TYPE.TYPE_OTHER
      ? newCertificate
      : certificate,
  )

  // If there is no certificate with the same type yet (so the uploaded one was the first one),
  // we add the new certificate to our array.
  if (
    newCertificates.findIndex(
      certificate =>
        certificate.type === newCertificate.type &&
        newCertificate.type !== CERTIFICATE_TYPE.TYPE_OTHER,
    ) < 0
  ) {
    newCertificates.unshift(newCertificate)
  }
  return newCertificates
}

export default {}
