const disposersSlidersTranslations = {
  slide1: {
    headline: 'Digitale Entsorgung',
    subheadline: 'Skaliert nach ihren Vorgaben.',
    paragraph:
      'Auf Wunsch erstellt <span style="color: #00bbdd">empto</span> Angebote nach ' +
      'Ihren Vorgaben - einfach, zuverlässig und automatisiert.',
  },
  slide2: {
    headline: 'Digitale Entsorgung',
    subheadline: 'jetzt ganz einfach.',
    paragraph:
      'Mit <span style="color: #00bbdd">empto</span> behalten Sie alle Ihre Aufträge stets ' +
      'im Blick und sparen so viel Zeit.',
  },
  slide3: {
    headline: 'Digitale Entsorgung',
    subheadline: 'gut & schnell bezahlt.',
    paragraph:
      'Mit <span style="color: #00bbdd">empto</span> profitieren Sie von einer vollkommen transparenten ' +
      'und schnellen Abrechnung Ihrer Leistungen.',
  },
  buttonCaption: 'empto kostenlos testen',
}

export default disposersSlidersTranslations
