import './style.scss'

import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { Tooltip, TOOLTIP_THEME, TOOLTIP_TRIGGER } from '../Tooltip'

import { connector } from './connector'

interface YouTubePlayerComponentProps {
  cookiesAccepted: boolean
  poster: string
  posterAlt: string
  title: string
  url: string
}

export const YouTubePlayerComponent: FC<YouTubePlayerComponentProps> = ({
  cookiesAccepted,
  poster,
  posterAlt,
  title,
  url,
}) => {
  if (!cookiesAccepted) {
    return (
      <Tooltip
        followCursor
        html={
          <p className='uk-text-bold'>
            {I18n.t('youTubePlayer.cookiesNotAcceptedHint')}
          </p>
        }
        theme={TOOLTIP_THEME.LIGHT}
        tooltipId='youTubePlayer'
        trigger={TOOLTIP_TRIGGER.MOUSEENTER}
      >
        <div className='youtube-player'>
          <img
            alt={posterAlt}
            className='youtube-player__poster'
            src={poster}
          />
        </div>
      </Tooltip>
    )
  }

  return (
    <div className='youtube-player'>
      <iframe
        allow='autoplay; encrypted-media'
        allowFullScreen
        className='youtube-player__video'
        frameBorder='0'
        src={url}
        title={title} // Required for accessibility reasons
      />
    </div>
  )
}

export const YouTubePlayer = connector(YouTubePlayerComponent)
