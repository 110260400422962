import React, { FC, useState } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import {
  FormStepSummaryRow,
  GoToNextFormStepButton,
} from 'components/common/FormSteps'
import { createErrorSelector } from 'selectors/error'
import { getCustomInvoiceNetGrossPricesVatSelector } from 'selectors/maklerpremium'

import { CreateCustomInvoiceModal } from './CreateCustomInvoiceModal'

export const CheckPriceSummary: FC = () => {
  const content = useSelector(getCustomInvoiceNetGrossPricesVatSelector)

  const netGrossPricesVatError = useSelector(
    createErrorSelector(['GET_CUSTOM_INVOICE_NET_GROSS_PRICES_VAT']),
  )
  const createCustomInvoiceError = useSelector(
    createErrorSelector(['CREATE_CUSTOM_INVOICE']),
  )

  const history = useHistory()

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  return (
    <>
      <p className='formsteps-page__header-description'>
        {I18n.t('addInvoicePageTranslations.epd.steps.4.description')}
      </p>
      {content && (
        <div className='add-invoice-step-summary'>
          <div className='add-invoice-step-summary_grid-body'>
            <FormStepSummaryRow
              label={I18n.t(
                'addInvoicePageTranslations.epd.steps.4.fields.total_net_price.label',
              )}
              value={Number(content.total_net_price).toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            />
            <FormStepSummaryRow
              label={I18n.t(
                'addInvoicePageTranslations.epd.steps.4.fields.vat_of_total_net.label',
                {
                  vat: content.vat,
                },
              )}
              value={Number(content.vat_of_total_net).toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            />
            <FormStepSummaryRow
              label={I18n.t(
                'addInvoicePageTranslations.epd.steps.4.fields.total_gross_price.label',
              )}
              value={Number(content.total_gross_price).toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            />
          </div>
        </div>
      )}
      <div className='maklerpremium-offerform__step'>
        <GoToNextFormStepButton
          buttonText={I18n.t(
            'addInvoicePageTranslations.epd.steps.4.buttons.saveInvoice',
          )}
          onClick={() => {
            setModalIsOpen(!modalIsOpen)
          }}
          isDisabled={!content || netGrossPricesVatError !== undefined}
        />
      </div>

      <CreateCustomInvoiceModal
        isOpen={modalIsOpen}
        onClose={() => {
          if (!createCustomInvoiceError) {
            history.push('/maklerpremium/bookkeeping')
          }
          setModalIsOpen(!modalIsOpen)
        }}
      />
    </>
  )
}
