import { createSelector } from 'reselect'

/**
 * @description selector to get an order.
 * @param state
 * @return {*}
 */
export const getOrderSelector: (state: any) => Order = createSelector(
  state => state.order.item,
  order => ({
    ...order,
    offer_object: order.offer_object
      ? {
          ...order.offer_object,
          net_cost: Number(order.offer_object.net_cost),
          total_cost: Number(order.offer_object.total_cost),
          total_revenue: Number(order.offer_object.total_revenue),
        }
      : null,
  }),
)

/**
 * @description selector to get an order list.
 * @param state
 * @return {*}
 */
export const getOrdersSelector: (state: any) => OrderListItem[] = state =>
  state.order.orderList.data

/**
 * @description selector to get an order list. List is optimized for the invoiceCheck context, so it doesn't contain
 * all order data
 * @param state
 * @return {*}
 */
export const getOrdersForInvoiceCheckSelector: (
  state: any,
) => OrderListItem[] = state => state.order.orderForInvoiceCheckList.data

/**
 * @description selector to get an offer list pagination.
 * @param state
 * @return {*}
 */
export const getOrdersPaginationSelector = state =>
  state.order.orderList.pagination

/**
 * @description selector to get an orders count
 * @param state
 * @return {*}
 */
export const getOrdersCountSelector = state => state.order.ordersCount

/**
 * @description selector to get the upcoming ordersof makler premium
 * @param state
 * @return {*}
 */
export const getMaklerPremiumUpcomingOrdersSelector: (
  state: any,
) => UpcomingOrders[] = state => state.order.maklerPremiumUpcomingOrders

/**
 * @description selector to get the pagination for the upcoming orders of a makler premium
 * @param state
 * @return {*}
 */
export const getMaklerPremiumUpcomingOrdersPaginationSelector = state =>
  state.order.pagination

/**
 * @description selector to get the company_list for the company filter of the upcoming orders of a makler premium
 * @param state
 * @return {*}
 */
export const getMaklerPremiumUpcomingOrdersFilterCompanies = state =>
  state.order.filterCompanies

/**
 * @description selector to get the company_list for the company filter of the upcoming orders of a makler premium
 * @param state
 * @return {*}
 */
export const getMaklerPremiumUpcomingOrdersFilterContainers = state =>
  state.order.filterContainers

export const getMaklerPremiumUpcomingOrdersFilterTimestamp = state =>
  state.order.filterTimestamp

/**
 * @description selector to get the order for the disposer management landing page
 * @param state
 * @return {*}
 */
export const getOrderForDisposerManagementSelector: (
  state: any,
) => OrderForDisposerManagement = state =>
  state.order.orderForDisposerManagement
