import React, { FC } from 'react'
import { Translate } from 'react-i18nify'

import { FilesViewer } from 'components/common/FilesViewer'

export const InvoiceCheckAttachments: FC<{ attachments?: Attachment[] }> = ({
  attachments,
}) => {
  if (attachments && attachments.length > 0) {
    return (
      <FilesViewer
        files={attachments}
        showPreview={false}
        withFilenameTooltip
      />
    )
  }

  return <Translate value='general.emptyImageSet' />
}
