import './style.scss'

import uniqueId from 'lodash.uniqueid'
import React, { useState } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-scroll'

import { deleteCompanyNote } from 'actions/company'
import {
  Button,
  BUTTON_BACKGROUND_COLOR,
  BUTTON_TYPE,
} from 'components/common/Button'
import { ICON_NAME, IconButton } from 'components/common/IconButton'
import { Modal } from 'components/common/Modal'
import ModalHeader from 'components/common/ModalHeader'
import { ProgressButton } from 'components/common/ProgressButton'
import { MaklerPremiumGroups } from 'constants/user'
import { localizeDateTime } from 'helper/general'
import { getCurrentUserSelector } from 'selectors/user'

export const Note = ({ note }: { note: CompanyNote }) => {
  const [noteCollapsed, setNoteCollapsed] = useState<boolean>(true)
  const user = useSelector(getCurrentUserSelector)
  const dispatch = useDispatch()
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] =
    useState<boolean>(false)

  const modalId = uniqueId()

  return (
    <>
      <div className='company-single-note'>
        {(note.created_by === user.id ||
          user.group_id ===
            MaklerPremiumGroups.MAKLER_PREMIUM_EPD_ADMINISTRATOR) && (
          <IconButton
            className='uk-float-right uk-padding-remove trash-button'
            iconName={ICON_NAME.TRASH}
            onClick={() => {
              setConfirmDeleteModalOpen(true)
            }}
          ></IconButton>
        )}
        <div className='uk-text-bold'>{note.created_by_full_name}</div>
        <div>{localizeDateTime(note.created_at)}</div>
        <span className={`${noteCollapsed ? 'two-lines' : ''}  break-word`}>
          {note.content}
        </span>
        <div className='uk-margin-small-top uk-margin-bottom'>
          {note.content.length > 280 && (
            <Link to='note' onClick={() => setNoteCollapsed(!noteCollapsed)}>
              <Translate
                value={`companyNotesTranslations.${
                  noteCollapsed ? 'expandNote' : 'collapseNote'
                }`}
              />
            </Link>
          )}
        </div>
      </div>
      <Modal
        ariaDescribedBy={modalId}
        isOpen={confirmDeleteModalOpen}
        onClose={() => setConfirmDeleteModalOpen(false)}
      >
        <ModalHeader
          onClose={() => setConfirmDeleteModalOpen(false)}
          title={I18n.t('companyNotesTranslations.confirmDeleteHeader')}
          titleId={modalId}
        />
        <div className='uk-margin-top'>
          <Translate
            className='uk-padding'
            value='companyNotesTranslations.confirmDeleteText'
          />
        </div>
        <div className='uk-modal-footer uk-text-right'>
          <span className='uk-margin-right'>
            <Button
              backgroundColor={BUTTON_BACKGROUND_COLOR.SECONDARY}
              onClick={() => setConfirmDeleteModalOpen(false)}
            >
              <Translate value='general.button.cancel' />
            </Button>
          </span>

          <ProgressButton
            backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
            dataTestId='cancel-inquiry-submit'
            onClick={() => {
              dispatch(deleteCompanyNote(note.id))
            }}
            type={BUTTON_TYPE.SUBMIT}
          >
            <Translate value='general.button.submit' />
          </ProgressButton>
        </div>
      </Modal>
    </>
  )
}
