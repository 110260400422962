import React, { FC } from 'react'
import { I18n } from 'react-i18nify'

import { APP_CONSTANTS } from '../constants/app'

interface DefaultErrorComponentProps {
  errorMessage?: string
}
export const DefaultErrorComponent: FC<DefaultErrorComponentProps> = ({
  errorMessage,
}) => (
  <p className='uk-alert uk-alert-danger'>
    {APP_CONSTANTS.REACT_APP_ENVIRONMENT === 'prod' || !errorMessage
      ? I18n.t('message.unknownErrorDevelopersNotified')
      : errorMessage}
  </p>
)
