import { connect } from 'react-redux'

import { getCompanies } from 'actions/company'
import { getCompanyUsers, getMyUsers } from 'actions/user'
import { getCompaniesListSelector } from 'selectors/company'
import { createLoadingSelector } from 'selectors/loading'
import { getCurrentUserSelector, getUserList } from 'selectors/user'

const activeUsersLoading = createLoadingSelector(['GET_COMPANY_USERS'])
const activeCompaniesLoading = createLoadingSelector(['GET_COMPANIES'])

const mapStateToProps = state => ({
  userList: getUserList(state),
  companiesList: getCompaniesListSelector(state),
  loading: {
    activeCompanies: activeCompaniesLoading(state),
    activeUsers: activeUsersLoading(state),
  },
  user: getCurrentUserSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  getCompanies: () => dispatch(getCompanies()),
  getCompanyUsers: companyId => dispatch(getCompanyUsers(companyId)),
  getMyUsers: (page, sortedBy, filter) =>
    dispatch(getMyUsers(page, sortedBy, filter)),
})

export default connect(mapStateToProps, mapDispatchToProps)
