import { FieldArray, useFormikContext } from 'formik'
import moment from 'moment'
import React, { FC, useContext } from 'react'
import { Col, Row } from 'react-flexbox-grid'
import { I18n } from 'react-i18nify'
import { useDispatch } from 'react-redux'

import { getCustomInvoiceNetGrossPricesVat } from 'actions/maklerpremium'
import { BUTTON_BACKGROUND_COLOR } from 'components/common/Button'
import ButtonBar, { BUTTON_BAR_ALIGN } from 'components/common/ButtonBar'
import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { DropDownInput } from 'components/common/DropDownInput'
import Icon from 'components/common/Fontello'
import {
  FormStepSummaryRow,
  GoToNextFormStepButton,
} from 'components/common/FormSteps'
import { STEP_STATUS } from 'components/common/FormSteps/helper'
import { ICON_POSITION, IconButton } from 'components/common/IconButton'
import InputDate from 'components/common/InputDate'
import { InputDecimal } from 'components/common/InputDecimal'
import { InputText } from 'components/common/InputText'
import { ReadOnlyField } from 'components/common/ReadOnly'
import { ADD_DOCUMENT_POSITION_UNIT_CHOICES } from 'components/maklerpremium/constants'
import { DATE_FORMATS } from 'constants/app'
import { getName } from 'helper/translations'

import {
  EPDAddInvoiceFormContext,
  EPDAddInvoiceFormValues,
} from '../EPDAddInvoiceFormSteps'

export const AddInvoicePositionForm: FC = () => {
  const maxNumberOfPositions = 10
  const dispatch = useDispatch()

  const { stepStatus, saveStep, editStep } = useContext(
    EPDAddInvoiceFormContext,
  )

  const {
    errors,
    setFieldValue,
    submitCount,
    values,
    handleChange,
    handleBlur,
  } = useFormikContext<EPDAddInvoiceFormValues>()

  const saveAllAndGoToNextStep = () => {
    if (values.positions) {
      const newPositions = values.positions.map(pos => ({
        ...pos,
        edit_mode: false,
      }))
      setFieldValue('positions', newPositions)
      dispatch(
        getCustomInvoiceNetGrossPricesVat({
          positions: newPositions,
          assigned_vat: values.assigned_vat,
        }),
      )
    }
    saveStep()
  }

  const defaultInvoicePosition = {
    amount: '',
    edit_mode: true,
    unit: ADD_DOCUMENT_POSITION_UNIT_CHOICES[0].id,
    service: '',
    service_date: values.assigned_order?.delivery_date
      ? `${moment(values.assigned_order.delivery_date).format('L')}`
      : '',
    single_net_price: '',
    total_net_price: '',
  }

  // Ensure that at least one empty position always exists
  if (!values.positions || values.positions?.length === 0) {
    values.positions = [defaultInvoicePosition]
  }

  // Saves the last position by setting edit_mode false
  // and adds a new position
  const addPositionAndSaveLastPosition = () => {
    if (values.positions) {
      const newPositions = values.positions
      newPositions[newPositions.length - 1].edit_mode = false
      newPositions.push(defaultInvoicePosition)
      setFieldValue('positions', newPositions)
    }
  }

  const calculateAndSetTotalNetPrice = (index, amount, single_net_price) => {
    setFieldValue(
      `positions.${index}.total_net_price`,
      single_net_price && amount
        ? Number(single_net_price.replace(',', '.')) *
            Number(amount.replace(',', '.'))
        : undefined,
      false,
    )
  }

  const deletePosition = (positionIndex: number) => {
    const newPositions = values.positions
    // eslint-disable-next-line no-unused-expressions
    newPositions?.splice(positionIndex, 1)
    setFieldValue('positions', newPositions)
  }

  const isPositionInvalid = (positionIndex: number) => {
    if (
      values?.positions &&
      typeof values?.positions[positionIndex] !== 'undefined'
    ) {
      const pos = values?.positions[positionIndex]

      return (
        !pos.amount ||
        !pos.service ||
        !pos.service_date ||
        // Allow single_net_price to be 0
        typeof pos.single_net_price === 'undefined' ||
        pos.single_net_price === '' ||
        !pos.unit
      )
    }
    return false
  }

  const allPositionsValid = () => {
    if (values.positions) {
      return !values.positions.some((pos, posIdx) => isPositionInvalid(posIdx))
    }
    return false
  }

  const allPositionsValidConst = allPositionsValid()

  const enableEditModeForPosition = (positionIndex: number) => {
    const newPositions = values.positions
    if (newPositions) {
      newPositions[positionIndex].edit_mode = true
    }
    setFieldValue('positions', newPositions)
  }

  // sorts ADD_DOCUMENT_POSITION_UNIT_CHOICES in-place
  // alphabetically by translated name
  ADD_DOCUMENT_POSITION_UNIT_CHOICES.sort((a, b) =>
    I18n.t(a.name).localeCompare(I18n.t(b.name)),
  )

  return (
    <div className='add_invoice_positions_form'>
      <Row>
        <Col md={8}>
          <FieldArray name='positions'>
            {() => (
              <>
                {values.positions?.map((position, index) => (
                  <div key={`${index}`}>
                    {index >= 1 && <hr className='form-step__divider' />}
                    <Row>
                      <Col md={6}>
                        <p>{`${index + 1}. Position`}</p>
                      </Col>
                      <Col md={6}>
                        {stepStatus !== STEP_STATUS.COMPLETED && (
                          <ButtonBar align={BUTTON_BAR_ALIGN.RIGHT}>
                            {!position.edit_mode && (
                              <button
                                type='button'
                                className='icon-button'
                                onClick={() => enableEditModeForPosition(index)}
                                data-uk-tooltip={`title: ${I18n.t(
                                  'general.button.edit',
                                )}`}
                              >
                                <Icon name='pencil' />
                              </button>
                            )}
                            <button
                              type='button'
                              className='icon-button delete-button'
                              data-uk-tooltip={`title: ${I18n.t(
                                'general.button.delete',
                              )}`}
                              onClick={() => deletePosition(index)}
                            >
                              <Icon name='trash' />
                            </button>
                          </ButtonBar>
                        )}
                      </Col>
                    </Row>
                    {position.edit_mode ? (
                      <>
                        {/* EDITING*/}
                        {/* Leistungsdatum */}
                        <InputDate
                          dataTestId={`add-invoice-position-service-date-input-${
                            index + 1
                          }`}
                          label={I18n.t(
                            'addInvoicePageTranslations.epd.steps.3.fields.service_date.label',
                          )}
                          name={`positions.${index}.service_date`}
                          onChange={value => {
                            setFieldValue(
                              `positions.${index}.service_date`,
                              value.format('L'),
                              false,
                            )
                          }}
                          value={position.service_date}
                          withCheckmark
                          showCheckmark={!!position.service_date}
                        />

                        {/* Leistung */}
                        <InputText
                          dataTestId={`add-invoice-position-service-input-${
                            index + 1
                          }`}
                          label={I18n.t(
                            'addInvoicePageTranslations.epd.steps.3.fields.service.label',
                          )}
                          maxLength={70}
                          name={`positions.${index}.service`}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          placeholder={I18n.t(
                            'addInvoicePageTranslations.epd.steps.3.fields.service.placeholder',
                          )}
                          value={position.service}
                          error={
                            // eslint-disable-next-line max-len
                            submitCount > 0 &&
                            position.service === '' &&
                            !!errors.positions
                              ? (errors.positions as string)
                              : ''
                          }
                          withCheckmark
                          showCheckmark={!!position.service}
                        />

                        <div className='execution-proof-review--side-by-side'>
                          {/* Menge */}
                          <InputDecimal
                            label={I18n.t(
                              'addInvoicePageTranslations.epd.steps.3.fields.amount.label',
                            )}
                            placeholder={I18n.t(
                              'addInvoicePageTranslations.epd.steps.3.fields.amount.placeholder',
                            )}
                            name={`positions.${index}.amount`}
                            value={position.amount}
                            maxValue={99999}
                            onChange={e => {
                              handleChange(e)
                              if (values.positions) {
                                calculateAndSetTotalNetPrice(
                                  index,
                                  e.target.value,
                                  position.single_net_price,
                                )
                              }
                            }}
                          />

                          {/* Einheit */}
                          <DropDownInput
                            choices={ADD_DOCUMENT_POSITION_UNIT_CHOICES.map(
                              choice => ({
                                optionValue: choice.id,
                                optionLabel: I18n.t(choice.name),
                              }),
                            )}
                            name={`positions.${index}.unit`}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            label={I18n.t(
                              'addInvoicePageTranslations.epd.steps.3.fields.unit.label',
                            )}
                            placeholder={I18n.t(
                              'addInvoicePageTranslations.epd.steps.3.fields.unit.label',
                            )}
                            showCheckmark={!!position.amount && !!position.unit}
                            value={position.unit}
                            withCheckmark
                          />

                          {/* Nettoeinzelpreis*/}
                          <InputDecimal
                            name={`positions.${index}.single_net_price`}
                            label={I18n.t(
                              'addInvoicePageTranslations.epd.steps.3.fields.single_net_price.label',
                            )}
                            placeholder={I18n.t(
                              'addInvoicePageTranslations.epd.steps.3.fields.single_net_price.placeholder',
                            )}
                            value={position.single_net_price}
                            maxValue={9999.99}
                            onChange={e => {
                              handleChange(e)
                              if (values.positions) {
                                calculateAndSetTotalNetPrice(
                                  index,
                                  position.amount,
                                  e.target.value,
                                )
                              }
                            }}
                          />

                          {/* Nettogesamtpreis*/}
                          <ReadOnlyField
                            className='net_price_read_only_field'
                            label={I18n.t(
                              'addInvoicePageTranslations.epd.steps.3.fields.total_net_price.label',
                            )}
                            value={
                              position.amount && position.single_net_price
                                ? (
                                    Number(position.amount.replace(',', '.')) *
                                    Number(
                                      position.single_net_price.replace(
                                        ',',
                                        '.',
                                      ),
                                    )
                                  ).toLocaleString('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })
                                : ''
                            }
                            withCheckmark={
                              position.single_net_price.length >= 1
                            }
                          />
                        </div>
                      </>
                    ) : (
                      <>
                        {/* READONLY*/}
                        <div className='maklerpremium-step-summary'>
                          <div className='maklerpremium-step-summary_grid-body'>
                            {/* Leistungsdatum */}
                            <FormStepSummaryRow
                              label={I18n.t(
                                'addInvoicePageTranslations.epd.steps.3.fields.service_date.label',
                              )}
                              value={
                                position.service_date
                                  ? position.service_date
                                  : `${moment(
                                      values.assigned_order?.delivery_date,
                                      DATE_FORMATS,
                                    ).format('L')}`
                              }
                            />

                            {/* Leistung*/}
                            <FormStepSummaryRow
                              label={I18n.t(
                                'addInvoicePageTranslations.epd.steps.3.fields.service.label',
                              )}
                              value={position.service}
                            />

                            {/* Menge*/}
                            <FormStepSummaryRow
                              label={I18n.t(
                                'addInvoicePageTranslations.epd.steps.3.fields.amount.label',
                              )}
                              value={position.amount}
                            />

                            {/* Einheit*/}
                            <FormStepSummaryRow
                              label={I18n.t(
                                'addInvoicePageTranslations.epd.steps.3.fields.unit.label',
                              )}
                              value={I18n.t(
                                getName(
                                  position.unit,
                                  ADD_DOCUMENT_POSITION_UNIT_CHOICES,
                                ),
                              )}
                            />

                            {/* Nettoeinzelpreis*/}
                            <FormStepSummaryRow
                              label={I18n.t(
                                'addInvoicePageTranslations.epd.steps.3.fields.single_net_price.label',
                              )}
                              value={Number(
                                position.single_net_price.replace(',', '.'),
                              ).toLocaleString('de-DE', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            />

                            {/* Nettogesamtpreis*/}
                            <FormStepSummaryRow
                              label={I18n.t(
                                'addInvoicePageTranslations.epd.steps.3.fields.total_net_price.label',
                              )}
                              value={
                                position.amount && position.single_net_price
                                  ? (
                                      Number(
                                        position.amount.replace(',', '.'),
                                      ) *
                                      Number(
                                        position.single_net_price.replace(
                                          ',',
                                          '.',
                                        ),
                                      )
                                    ).toLocaleString('de-DE', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })
                                  : ''
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ))}
                {/* remove / add buttons*/}
                {stepStatus !== STEP_STATUS.COMPLETED &&
                  (!values.positions ||
                    values.positions?.length < maxNumberOfPositions) && (
                    <div className='uk-margin-top add-position-button-container'>
                      <IconButton
                        backgroundColor={BUTTON_BACKGROUND_COLOR.WHITE}
                        fullWidth
                        iconName='plus'
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={() => addPositionAndSaveLastPosition()}
                        isDisabled={!allPositionsValidConst}
                      >
                        {I18n.t(
                          'addInvoicePageTranslations.epd.steps.3.buttons.addRemovePosition',
                        )}
                      </IconButton>
                    </div>
                  )}
                {stepStatus !== STEP_STATUS.COMPLETED && (
                  <div className='uk-margin-top'>
                    <GoToNextFormStepButton
                      buttonText={
                        stepStatus === STEP_STATUS.EDITING
                          ? I18n.t('general.button.save')
                          : I18n.t(
                              'createInquiryTranslations.form.button.nextStep',
                            )
                      }
                      onClick={saveAllAndGoToNextStep}
                      isDisabled={!allPositionsValidConst}
                    />
                  </div>
                )}
              </>
            )}
          </FieldArray>
        </Col>
        <Col md={4} />
      </Row>
      {stepStatus === STEP_STATUS.COMPLETED && (
        <div className='sidebar'>
          <ButtonLink
            className='sidebar__edit-link'
            linkColor={BUTTON_LINK_COLOR.PRIMARY}
            onClick={editStep}
          >
            {I18n.t('createInquiryTranslations.form.button.editStep')}
          </ButtonLink>
        </div>
      )}
    </div>
  )
}
