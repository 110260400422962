import './style.scss'

import { Formik } from 'formik'
import uniqueId from 'lodash.uniqueid'
import React, { FC } from 'react'
import { I18n } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'

import { createLoadingSelector } from 'selectors/loading'
import { lockUnlockAutoOffers } from 'actions/autooffer'

import { Modal } from '../../common/Modal'
import ModalHeader from '../../common/ModalHeader'

import { ManageAutoOffersModalForm } from './ManageAutoOffersModalForm'

interface ManageAutoOffersModalProps {
  isOpen: boolean
  onClose: () => void
}

export const ManageAutoOffersModal: FC<ManageAutoOffersModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch()
  const isLoading = useSelector(createLoadingSelector(['UPDATE_COMPANY']))
  const idManageAutoOfferModalHeadline = uniqueId()

  return (
    <Modal
      ariaDescribedBy={idManageAutoOfferModalHeadline}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalHeader
        onClose={onClose}
        title={I18n.t(
          'myAutoOffersPageTranslations.manageAutoOffers.modal.title',
        )}
        titleId={idManageAutoOfferModalHeadline}
      />
      <div className='my-auto-offers-page__manage-modal'>
        <Formik
          initialErrors={{ block_auto_offers: '', company: '', phone_user: '' }}
          initialValues={{
            block_auto_offers: false,
            company: undefined,
            phone_user: undefined,
          }}
          onSubmit={values => {
            dispatch(
              lockUnlockAutoOffers(
                values.company,
                values.block_auto_offers,
                values.phone_user,
              ),
            )
            onClose()
          }}
          validationSchema={() =>
            yup.object().shape({
              block_auto_offers: yup.boolean().required(),
              company: yup.string().required(),
              phone_user: yup.string().required(),
            })
          }
        >
          <ManageAutoOffersModalForm isLoading={isLoading} onClose={onClose} />
        </Formik>
      </div>
    </Modal>
  )
}
