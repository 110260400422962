import React from 'react'

import FormGroupError from '../FormGroupError'

import FormGroupFieldContainerProps from './schemes'

/**
 * @description This component renders and controls the form group field container.
 * @param {Object} props JSX attributes to this component as a single object.
 * @return {*}
 */
const FormGroupFieldContainer = props => (
  <>
    <div
      className={`form-group-input ${props.errors ? 'errors' : ''} ${
        props.showTooltip ? 'tooltip-padding' : ''
      }`}
    >
      <div className='form-group-field-component'>{props.fieldComponent}</div>

      {props.required && <div className='required-dot' />}
    </div>

    {props.errors && (
      <FormGroupError model={props.model} errorMessages={props.errorMessages} />
    )}
  </>
)

FormGroupFieldContainer.propTypes = FormGroupFieldContainerProps

FormGroupFieldContainer.defaultProps = {
  errors: null,
  errorMessages: {},
  required: false,
  model: null,
}

export default FormGroupFieldContainer
