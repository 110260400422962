export const certificateDetailsPageTranslations = {
  breadcrumb: {
    title: {
      review: 'Nachweisprüfung',
      details: 'Nachweis',
    },
    prevLinkTitle: 'Nachweise',
  },
  pageTitle: {
    review: 'Nachweisprüfung für %{name} (%{type})',
    details: 'Nachweis für %{name} (%{type})',
  },
  certificateType: {
    efb: 'Efb Zertifikat',
    other: 'Weitere Zertifikate',
    company: 'Gewerbenachweis',
    priceAgreement: 'Preisvereinbarung',
  },
  submitButtonText: 'Prüfung abschließen',
  validation: {
    validUntil: 'Bitte wählen Sie ein Datum aus.',
    issuedAt: 'Bitte wählen Sie ein Datum aus.',
    rejectReason: 'Bitte begründen Sie Ihre Reklamation.',
    status: 'Bitte ändern Sie den Status.',
    dispositedPrices:
      'Bitte prüfen Sie, ob die relevanten Preise im System hinterlegt wurden.',
  },
}
