import ActionTypes from '../actiontypes/holiday'

/** @function
 * @name getHolidays
 * @description Retrieves holidays.
 * @return {Object}
 */
export const getHolidays = () => ({
  type: ActionTypes.GET_HOLIDAYS_REQUEST,
})
