import ActionTypes from '../actiontypes/emptodownloads'

/**
 * @function
 * @name getEmptoDownloads
 * @description Loads all static Download files
 * @return [Object]
 */
export const getEmptoDownloads = () => ({
  type: ActionTypes.GET_EMPTO_DOWNLOADS_REQUEST,
})
