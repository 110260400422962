import './style.scss'

import React from 'react'
import { I18n } from 'react-i18nify'
import { Errors } from 'react-redux-form'

import FormGroupErrorProps from './schemes'

/**
 * @description This component renders and controls the form group error.
 * @param {Object} props JSX attributes to this component as a single object.
 * @return {*}
 */
const FormGroupError = props => (
  <Errors
    className='form-group-error'
    data-testid='form-group-error'
    messages={{
      hasLength: I18n.t('formGroupErrorTranslations.hasLength', {
        number: props.min,
      }),
      isRequired: I18n.t('formGroupErrorTranslations.isRequired'),
      isURL: I18n.t('formGroupErrorTranslations.isURL'),
      onlyLettersAndNumbers: I18n.t(
        'formGroupErrorTranslations.onlyLettersAndNumbers',
      ),
      ...props.errorMessages,
    }}
    model={props.model}
    show
  />
)

FormGroupError.propTypes = FormGroupErrorProps

FormGroupError.defaultProps = {
  errorMessages: {},
  min: 10,
}

export default FormGroupError
