import React from 'react'
import { I18n } from 'react-i18nify'
import { useHistory } from 'react-router'

import { BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import { ICON_POSITION, IconButton } from '../../common/IconButton'

interface BackButtonProps {
  backgroundColor?: BUTTON_BACKGROUND_COLOR
  iconName?: string
  text?: string
}

export const BackButton = ({
  backgroundColor = BUTTON_BACKGROUND_COLOR.DEFAULT,
  iconName = 'reply',
  text,
}: BackButtonProps) => {
  const history = useHistory()

  return (
    <IconButton
      backgroundColor={backgroundColor}
      dataTestId='back-button'
      iconName={iconName}
      iconPosition={ICON_POSITION.RIGHT}
      onClick={() => history.go(-1)}
    >
      {text || I18n.t('general.back')}
    </IconButton>
  )
}
