import { func } from 'prop-types'
import React from 'react'
import { Translate } from 'react-i18nify'

import { UserPermission } from 'constants/user'

import { BUTTON_BACKGROUND_COLOR } from '../../common/Button'
import {
  ICON_POSITION,
  IconButton,
  IconButtonProps,
} from '../../common/IconButton'
import { RequiredPermissions } from '../../common/RequiredPermissions'

/**
 * @description Displays a button to add an address.
 */
const AddAddressButton = props => (
  <RequiredPermissions requiredPermissions={[UserPermission.ADD_ADDRESS]}>
    <IconButton
      backgroundColor={BUTTON_BACKGROUND_COLOR.PRIMARY}
      iconName='plus'
      iconPosition={ICON_POSITION.RIGHT}
      {...props}
    >
      <Translate value='myAddresses.addButton' />
    </IconButton>
  </RequiredPermissions>
)

AddAddressButton.propTypes = {
  onClick: func.isRequired,
  ...IconButtonProps,
}

export default AddAddressButton
