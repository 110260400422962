import { arrayOf, number, oneOfType, shape, string } from 'prop-types'

import { CompanySmallScheme } from './company'
import { UserSmallScheme } from './user'

export const DiscountScheme = {
  id: number,
  code: string,
  companies: arrayOf(shape(CompanySmallScheme)),
  company: oneOfType([number, string]),
  company_object: shape(CompanySmallScheme),
  created_at: string,
  created_by_name: string,
  created_by_object: shape(UserSmallScheme),
  description: string,
  occasion: number,
  redeemed_at: string,
  redeemed_by_name: string,
  status: number,
  valid_from: string,
  valid_until: string,
  value: number,
  value_percent: number,
}

export default {}
