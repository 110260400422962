import { CONTENT_TYPE } from './constants'

export const getContent = (
  contentList: AbstractContent[] = [],
  contentType?: CONTENT_TYPE,
) => {
  const content = contentList.find(
    _content => _content.content_type === contentType,
  )
  if (content) return content.content
  return ''
}

export const getTitle = (
  contentList: FractionContent[] = [],
  contentType?: CONTENT_TYPE,
) => {
  const content = contentList.find(
    _content => _content.content_type === contentType,
  )
  if (content) return content.title
  return ''
}
