import { connect } from 'react-redux'

import { resetApiFetchErrors } from 'actions/app'
import { getCompanyFormSelector, getCompanySelector } from 'selectors/company'
import { createErrorSelector } from 'selectors/error'

const updateCompanyErrorSelector = createErrorSelector(['UPDATE_COMPANY'])
/**
 * @function
 * @param {Object} state
 * @return {Object}
 */
const mapStateToProps = state => ({
  company: getCompanySelector(state),
  companyForm: getCompanyFormSelector(state),
  error: updateCompanyErrorSelector(state),
})

/**
 * @function
 * @description method to map the dispatches to the props.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  resetApiFetchErrors: type => dispatch(resetApiFetchErrors(type)),
})

export default connect(mapStateToProps, mapDispatchToProps)
