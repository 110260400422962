import './style.scss'

import { forbidExtraProps } from 'airbnb-prop-types'
import classNames from 'classnames'
import uniqueId from 'lodash.uniqueid'
import moment from 'moment'
import { bool, func, node, number, oneOfType, string } from 'prop-types'
import React, { Component } from 'react'
import DatePicker from 'react-datepicker/lib/index'
import { Col, Row } from 'react-flexbox-grid'
import { I18n } from 'react-i18nify'
import { momentObj, momentString } from 'react-moment-proptypes'

import Icon from '../Fontello'
import { InputCheckmark } from '../InputCheckmark'

/**
 * @description Displays and controls the Input Date Component.
 */
class InputDate extends Component {
  static propTypes = forbidExtraProps({
    autoComplete: string,
    dataTestId: string,
    dataTestIdError: string,
    enableInputEdit: bool,
    error: oneOfType([func, string]),
    excludeWeekends: bool,
    filterDate: func,
    fixedHeight: bool,
    isRequired: bool,
    label: node.isRequired,
    maxDate: oneOfType([momentObj, momentString]),
    maxTime: oneOfType([momentObj, momentString]),
    minDate: oneOfType([momentObj, momentString]),
    minTime: oneOfType([momentObj, momentString]),
    name: string.isRequired,
    onBlur: func,
    onChange: func.isRequired,
    overwrittenValue: oneOfType([number, string]),
    placeholder: string,
    popperPlacement: string,
    scrollableYearDropdown: bool,
    shouldCloseOnSelect: bool,
    showCheckmark: oneOfType([bool, func]),
    // In Typescript this should be:
    // showCheckmark: callbackOrType<boolean>,
    // as defined in Input/index.tsx
    // export type callbackOrType<T> = T | ((name: string) => (T | undefined));
    showDisabledMonthNavigation: bool,
    showEditIcon: bool,
    showMonthDropdown: bool,
    showTimeSelect: bool,
    showTimeSelectOnly: bool,
    showYearDropdown: bool,
    symbol: string,
    timeCaption: string,
    timeFormat: string,
    timeIntervals: number,
    value: oneOfType([momentObj, momentString, string.isRequired]),
    validateOnMount: bool, // false => allow dates before minDate as initialValue, but disallow selecting past
    withCheckmark: bool,
  })

  static defaultProps = {
    autoComplete: 'off',
    dataTestId: '',
    dataTestIdError: '',
    enableInputEdit: false,
    error: '',
    excludeWeekends: false,
    filterDate: undefined,
    fixedHeight: true,
    isRequired: false,
    maxDate: moment().add(20, 'years'),
    maxTime: null,
    minDate: moment().subtract(20, 'years'),
    minTime: null,
    onBlur: () => undefined,
    overwrittenValue: null,
    placeholder: '',
    popperPlacement: undefined,
    scrollableYearDropdown: false,
    shouldCloseOnSelect: true,
    showCheckmark: false,
    showDisabledMonthNavigation: true,
    showEditIcon: false,
    showMonthDropdown: false,
    showTimeSelect: false,
    showTimeSelectOnly: false,
    showYearDropdown: false,
    symbol: 'calendar',
    timeCaption: '',
    timeFormat: 'HH:mm',
    timeIntervals: 60,
    value: null,
    validateOnMount: true,
    withCheckmark: false,
  }

  state = {
    edit: false,
    originalValue: '',
    selectedDate: null,
  }

  /**
   * @description Component “lifecycle method”
   */
  componentDidMount() {
    const date = moment(this.props.value, this.getFormat())

    if (date.isValid()) {
      let selectedDate = date
      // validateOnMount defaults to true, if value is after minDate, select minDate as the new value
      if (this.props.validateOnMount) {
        selectedDate = date.isBefore(this.props.minDate)
          ? this.props.minDate
          : date
      }
      this.setState({
        selectedDate,
        originalValue: this.props.value,
      })
    }
    if (!date.isValid()) {
      this.setState({
        selectedDate: null,
        edit: !this.props.showEditIcon,
      })
    }
  }

  /**
   * @description Component “lifecycle method”
   * @param prevProps
   */
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      const date = moment(this.props.value, this.getFormat())
      if (date.isValid()) {
        this.setState({
          selectedDate: date.isBefore(this.props.minDate)
            ? this.props.minDate
            : date,
        })
        if (!this.state.originalValue)
          this.setState({ originalValue: this.props.value })
      }
      if (!date.isValid()) {
        this.setState({
          selectedDate: null,
          edit: true,
        })
      }
    }
  }

  /**
   * @description handles the change event (ATTN: this is a class method on purpose)
   * @param dateProps
   * @return {Function}
   */
  handleValueChange(dateProps) {
    if (!this.state.originalValue && this.props.enableInputEdit) {
      this.setState({ originalValue: this.props.value })
    }

    if (this.props.minTime && this.props.showTimeSelect) {
      this.props.onChange(
        this.getMinTimeSelected(dateProps).format(this.getFormat()),
      )
    } else {
      this.props.onChange(dateProps)
    }
  }

  /**
   * @description get the min hour for selected date.
   * @return {*}
   */
  getMinTimeSelected = date => {
    const { minTime, showTimeSelect } = this.props
    if (!showTimeSelect) return date

    if (minTime && date && date.hour() < minTime.hour()) {
      date.hours(minTime.hour())
    }

    return date
  }

  /**
   * @description get correct date format.
   * @return {string}
   */
  getFormat() {
    if (this.props.showTimeSelectOnly) return 'HH:mm'
    if (this.props.showTimeSelect) return 'L HH:mm'
    return 'L'
  }

  /**
   * @description filter date event.
   * @param isWeekday
   * @return {function(*): boolean}
   */
  filterDate = isWeekday => date =>
    this.props.excludeWeekends
      ? date.day() !== 0 && date.day() !== 6
      : isWeekday

  /**
   * @description scroll to active time class.
   */
  scrollToActiveTime = () => {
    const activeTimeElement = document.getElementsByClassName(
      'react-datepicker__time-list-item--selected',
    )
    if (activeTimeElement && activeTimeElement.length > 0) {
      activeTimeElement[0].scrollIntoView(true)
    }
  }

  /**
   * @description handles the edit button toggle
   */
  handleEditToggle = () => {
    this.setState({ edit: !this.state.edit })
  }

  /**
   * @description handles the change row
   * @return {Function}
   */
  handleChangeRaw = e => {
    if (!this.props.enableInputEdit) e.preventDefault()
  }

  /**
   * @function
   * @return {*}
   */
  render() {
    const {
      autoComplete,
      dataTestId,
      dataTestIdError,
      error,
      fixedHeight,
      isRequired,
      label,
      maxDate,
      maxTime,
      minDate,
      minTime,
      name,
      onBlur,
      overwrittenValue,
      placeholder,
      popperPlacement,
      scrollableYearDropdown,
      shouldCloseOnSelect,
      showCheckmark,
      showDisabledMonthNavigation,
      showEditIcon,
      showMonthDropdown,
      showTimeSelect,
      showTimeSelectOnly,
      showYearDropdown,
      symbol,
      timeCaption,
      timeFormat,
      timeIntervals,
      withCheckmark,
    } = this.props

    const errorValue = typeof error === 'string' ? error : error(name)
    const showCheckmarkValue =
      typeof showCheckmark === 'boolean' ? showCheckmark : showCheckmark(name)

    const readOnlyValue = showEditIcon
      ? overwrittenValue || this.state.originalValue
      : overwrittenValue
    const dateId = uniqueId()

    setTimeout(this.scrollToActiveTime, 100)

    return (
      <div
        className={classNames('input-date', {
          'input-date--with-checkmark': withCheckmark,
        })}
      >
        <Row middle='xs'>
          <Col xs={12} sm={12}>
            <label className='input-date__label' htmlFor={dateId}>
              {label}

              {showEditIcon && (
                <button
                  type='button'
                  className='icon-button'
                  onClick={this.handleEditToggle}
                >
                  <Icon name={this.state.edit ? 'checkmark' : 'pencil'} />
                </button>
              )}
            </label>
            {!this.state.edit && readOnlyValue && (
              <div className='input-date__current-value'>
                {moment(this.state.selectedDate).isValid()
                  ? moment(this.state.selectedDate).format('L')
                  : I18n.t('general.invalidDate')}
              </div>
            )}

            {this.state.edit &&
              readOnlyValue &&
              moment(readOnlyValue).isValid() &&
              moment(readOnlyValue).format('L') !==
                moment(this.state.selectedDate).format('L') && (
                <div className='input-date__old-value'>
                  {moment(readOnlyValue).format('L')}
                </div>
              )}
          </Col>
          {(this.state.edit || !readOnlyValue) && (
            <Col xs={12} sm={12} style={{ position: 'relative' }}>
              {(this.state.edit || !readOnlyValue) &&
                isRequired &&
                !this.state.selectedDate && (
                  <div
                    className={classNames(
                      'input-date__required-dot',
                      {
                        'input-date__required-dot--overwritten-value':
                          readOnlyValue && errorValue === '',
                      },
                      {
                        'input-date__required-dot--overwritten-value-with-error':
                          errorValue !== '' && readOnlyValue,
                      },
                      { 'input-date__required-dot--error': errorValue !== '' },
                    )}
                  />
                )}
              <DatePicker
                autoComplete={autoComplete}
                className={`input-date__input uk-input ${dataTestId}`} // because DatePicker has no data-testid
                dateFormat={this.getFormat()}
                filterDate={this.props.filterDate ?? this.filterDate}
                fixedHeight={fixedHeight}
                maxDate={maxDate}
                maxTime={maxTime}
                minDate={minDate}
                minTime={minTime}
                name={name}
                onBlur={onBlur}
                onChange={value => this.handleValueChange(value)}
                placeholderText={placeholder}
                popperPlacement={popperPlacement}
                scrollableYearDropdown={scrollableYearDropdown}
                selected={this.state.selectedDate}
                shouldCloseOnSelect={shouldCloseOnSelect}
                showDisabledMonthNavigation={showDisabledMonthNavigation}
                showMonthDropdown={showMonthDropdown}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                showYearDropdown={showYearDropdown}
                timeCaption={timeCaption}
                timeFormat={timeFormat}
                timeIntervals={timeIntervals}
                onChangeRaw={this.handleChangeRaw}
              />
              <div className='input-date__caption input-date__caption--symbol'>
                <Icon name={symbol} />
              </div>

              {withCheckmark && (
                <InputCheckmark isHidden={!showCheckmarkValue} />
              )}
            </Col>
          )}
        </Row>
        {errorValue !== '' && (
          <div className='input-date__error' data-testid={dataTestIdError}>
            {errorValue}
          </div>
        )}
      </div>
    )
  }
}

export default InputDate
