import './styles.scss'

import classNames from 'classnames'
import React, { FC, useRef } from 'react'
import Media from 'react-media'

import PrivateCustomerBackgroundDesktop from 'assets/img/homepage/background_private_customer_desktop.jpg'
import WasteDisposerBackgroundDesktop1 from 'assets/img/homepage/background_waste_disposer_1_desktop.jpg'
import WasteDisposerBackgroundDesktop2 from 'assets/img/homepage/background_waste_disposer_2_desktop.jpg'
import WasteDisposerBackgroundDesktop3 from 'assets/img/homepage/background_waste_disposer_3_desktop.jpg'
import WasteProducerBackgroundDesktop1 from 'assets/img/homepage/background_waste_producer_1_desktop.jpg'
import WasteProducerBackgroundMobile1 from 'assets/img/homepage/background_waste_producer_1_mobile.jpg'
import WasteProducerBackgroundDesktop2 from 'assets/img/homepage/background_waste_producer_2_desktop.jpg'
import WasteProducerBackgroundMobile2 from 'assets/img/homepage/background_waste_producer_2_mobile.jpg'
import { BREAKPOINT } from 'constants/design'
import { getRandomArrayEntry } from 'helper/general'

interface HeroImageComponentProps {
  showPrivateCustomerBackground?: boolean
  showWasteProducerBackground?: boolean
  showWasteDisposerBackground?: boolean
  className?: string
  toggled?: boolean
}

type HeroBackgroundImage = {
  mobile: string
  desktop: string
}

/**
 * @description Image section for the hero image
 * @constructor
 */
export const HeroImage: FC<HeroImageComponentProps> = ({
  showPrivateCustomerBackground = false,
  showWasteProducerBackground = false,
  showWasteDisposerBackground = false,
  className = '',
  toggled = false,
  children,
}) => {
  /**
   * @description An array of background images for the hero wallpaper.
   */
  const getBackgroundImages = () => {
    let backgroundImages: HeroBackgroundImage[] = []

    if (showPrivateCustomerBackground) {
      backgroundImages = [
        {
          mobile: PrivateCustomerBackgroundDesktop,
          desktop: PrivateCustomerBackgroundDesktop,
        },
      ]
    }

    if (showWasteProducerBackground) {
      backgroundImages = [
        {
          mobile: WasteProducerBackgroundMobile1,
          desktop: WasteProducerBackgroundDesktop1,
        },
        {
          mobile: WasteProducerBackgroundMobile2,
          desktop: WasteProducerBackgroundDesktop2,
        },
      ]
    }

    if (showWasteDisposerBackground) {
      backgroundImages = [
        {
          mobile: WasteDisposerBackgroundDesktop1,
          desktop: WasteDisposerBackgroundDesktop1,
        },
        {
          mobile: WasteDisposerBackgroundDesktop2,
          desktop: WasteDisposerBackgroundDesktop2,
        },
        {
          mobile: WasteDisposerBackgroundDesktop3,
          desktop: WasteDisposerBackgroundDesktop3,
        },
      ]
    }

    return backgroundImages
  }

  /**
   * @description Picks a random image object from the backgroundImages property.
   */
  const backgroundImage = useRef<HeroBackgroundImage>(
    getRandomArrayEntry(getBackgroundImages()),
  )

  return (
    <>
      <div
        className={classNames('hero-image-wrap', {
          'hero-image-wrap--hidden': toggled,
        })}
      >
        <Media query={{ maxWidth: BREAKPOINT.LARGE - 1 }}>
          {matches => (
            <div
              className={classNames(
                'hero-image',
                { 'hero-image--toggled': toggled },
                { 'hero-image--less-height': toggled },
              )}
              style={{
                backgroundImage: `url(${
                  matches
                    ? backgroundImage.current.mobile
                    : backgroundImage.current.desktop
                })`,
              }}
            />
          )}
        </Media>
      </div>
      {children && (
        <section
          className={classNames(
            'hero-image__content',
            { 'hero-image__content--toggled': toggled },
            className,
          )}
        >
          <div className='hero-image__content-wrap'>{children}</div>
        </section>
      )}
    </>
  )
}
