import './styles.scss'

import React, { FC, useEffect } from 'react'
import { I18n } from 'react-i18nify'

import DetailSection from 'components/common/DetailSwitcher/components/DetailSection'
import { FilesViewer } from 'components/common/FilesViewer'

import Address from './components/Address'
import Details from './components/Details'
import { InvoiceCheckAttachments } from './components/InvoiceCheckAttachments'
import Order from './components/Order'
import OrderExecutionProofs from './components/OrderExecutionProofs'
import Parties from './components/Parties'
import ShortOverview from './components/ShortOverview'
import { Slider } from './components/Slider'
import connector from './connector'

interface OrderSwitcherContentComponentProps {
  fractionList?: Fraction[]
  getFractions: () => void
  switcherDisabled?: boolean
  order: Order
  containerList?: Container[]
  hideExecutionProofsTab: boolean
  hideInvoiceCheckAttachmentsTab: boolean
}

/**
 * @description This component renders the order switcher content component.
 */
export const OrderSwitcherContentComponent: FC<
  OrderSwitcherContentComponentProps
> = ({
  fractionList = [],
  getFractions,
  switcherDisabled = false,
  order,
  containerList = [],
  hideExecutionProofsTab,
  hideInvoiceCheckAttachmentsTab,
}) => {
  useEffect(() => {
    if (fractionList.length === 0) getFractions()
  }, [fractionList, getFractions])

  return (
    <>
      {/* BEGIN SHORT DESCRIPTION */}
      <div className={`${switcherDisabled ? 'uk-active' : ''}`}>
        <ShortOverview />
      </div>
      {/* END SHORT DESCRIPTION */}

      {/* BEGIN ORDER */}
      <div className={`${switcherDisabled ? 'uk-hidden' : ''}`}>
        <Order order={order} />
      </div>
      {/* END ORDER */}

      {/* BEGIN PARTIES */}
      <div className={`uk-padding ${switcherDisabled ? 'uk-hidden' : ''}`}>
        <Parties order={order} />
      </div>
      {/* END PARTIES */}

      {/* BEGIN DETAILS */}
      <div className={`uk-padding ${switcherDisabled ? 'uk-hidden' : ''}`}>
        <Details
          order={order}
          fractionList={fractionList}
          containerList={containerList}
        />
      </div>
      {/* END DETAILS */}

      {/* BEGIN ADDRESS */}
      <div className={`${switcherDisabled ? 'uk-hidden' : ''}`}>
        <Address order={order} />
      </div>
      {/* END ADDRESS */}

      {/* BEGIN SLIDER */}
      <div className={`${switcherDisabled ? 'uk-hidden' : ''}`}>
        <Slider order={order} />
      </div>
      {/* END SLIDER */}

      {/* BEGIN EXECUTION-PROOFS */}
      {!hideExecutionProofsTab && (
        <div className={`${switcherDisabled ? 'uk-hidden' : ''}`}>
          <DetailSection
            className='uk-padding'
            title={I18n.t('general.executionProofs')}
            fullWidth
          >
            <OrderExecutionProofs executionProofs={order.executionproofs} />
          </DetailSection>
        </div>
      )}
      {/* END EXECUTION-PROOFS */}

      {/* BEGIN INVOICE CHECK ATTACHMENTS SLIDER */}
      {!hideInvoiceCheckAttachmentsTab && (
        <div className={`${switcherDisabled ? 'uk-hidden' : ''}`}>
          <DetailSection
            className='uk-padding invoicecheck-attachments'
            title={I18n.t('general.invoiceCheckAttachments')}
            fullWidth
          >
            <InvoiceCheckAttachments
              attachments={order.invoice_check_attachments}
            />
          </DetailSection>
          {order.invoice_files &&
            order.invoice_files.length > 0 &&
            order.invoice_files[0] instanceof Object && (
              <DetailSection
                className='uk-padding invoicecheck-attachments'
                title={I18n.t('general.invoices')}
                fullWidth
              >
                <FilesViewer
                  files={order.invoice_files}
                  showPreview={false}
                  withFilenameTooltip
                />
              </DetailSection>
            )}
        </div>
      )}
      {/* END SLIDER */}
    </>
  )
}

export const OrderSwitcherContent = connector(OrderSwitcherContentComponent)
