import moment from 'moment'

/**
 * @description Get the first possible date for a new order.
 * @return {moment.Moment}
 */
export const isUpcomingPlannedOrder: (
  delivery_date_disposed: string,
) => boolean = delivery_date_disposed =>
  moment(delivery_date_disposed) <= moment().add(14, 'days') &&
  moment(delivery_date_disposed) >= moment()
