import moment from 'moment/moment'
import React, { FC } from 'react'
import { I18n, Translate } from 'react-i18nify'
import { useDispatch, useSelector } from 'react-redux'

import { PaginatedTable } from 'components/common/PaginatedTable'
import { ReactComponent as DocumentIcon } from 'assets/svg/detailspage/document.svg'
import { getCompanyHistory } from 'actions/company'
import {
  getCompanyHistoryPaginationSelector,
  getCompanyHistorySelector,
} from 'selectors/company'
import { createLoadingSelector } from 'selectors/loading'

export const ActivityTable: FC<{
  companyId: number
}> = ({ companyId }) => {
  const dispatch = useDispatch()
  const companyHistory: any = useSelector(getCompanyHistorySelector)
  const pagination = useSelector(getCompanyHistoryPaginationSelector)
  const loading = useSelector(createLoadingSelector(['GET_COMPANY_HISTORY']))

  // filter out edit history entries that have no changes
  const filteredCompanyHistory = companyHistory.filter(
    e => e.history_type !== '~' || e.changes.length > 0,
  )

  const columns = [
    {
      id: 'history_date',
      Header: (
        <span>
          <Translate value='companyProfilePage.activityTable.columns.timestamp' />
        </span>
      ),
      Cell: data => {
        return moment(data.original?.history_date).format('L LT')
      },
      width: 150,
      sortable: false,
    },
    {
      id: 'history_user',
      Header: (
        <span>
          <Translate value='companyProfilePage.activityTable.columns.name' />
        </span>
      ),
      Cell: data => data.original?.history_user?.full_name,
      width: 400,
      sortable: false,
    },
    {
      id: 'history_type',
      accessor: 'company',
      Header: (
        <span>
          <Translate value='companyProfilePage.activityTable.columns.activity' />
        </span>
      ),
      Cell: data =>
        I18n.t(
          `companyProfilePage.activityTable.activityTypes.${data.original?.history_type}`,
        ),
      width: 150,
      sortable: false,
    },
    {
      id: 'changes',
      Cell: data => (
        <>
          {data.original?.history_type === '~' && (
            <div className='uk-grid uk-width-1-1'>
              {data.original?.changes.map(item => (
                <>
                  <div className='uk-width-1-1 uk-margin-small-bottom'>
                    <b>{item.field_verbose_name}</b>
                  </div>
                  <div
                    className='uk-width-1-1 uk-margin-small-bottom'
                    style={{ whiteSpace: 'normal' }}
                  >
                    Vorher: {item.old}
                  </div>
                  <div
                    className='uk-width-1-1 uk-margin-small-bottom'
                    style={{ whiteSpace: 'normal' }}
                  >
                    Nachher: {item.new}
                  </div>
                </>
              ))}
            </div>
          )}
        </>
      ),
      Header: (
        <span>
          <Translate value='companyProfilePage.activityTable.columns.changes' />
        </span>
      ),
      width: 800,
      sortable: false,
    },
  ]
  const defaultSorted = [{ id: 'created_at', desc: true }]

  return (
    <div className='uk-margin-large-top'>
      <span className='dsz-price-management-page--section--title'>
        <DocumentIcon className='icon--color-default icon--background-color-default dsz-price-management-page--section--icon' />
        <Translate value='priceManagementPageTranslations.activitiesTable.headline' />
      </span>
      <PaginatedTable
        serverSidePagination
        page={pagination.current}
        pages={pagination.count}
        loading={loading}
        handleShowPreviousPage={() => {
          dispatch(getCompanyHistory(companyId, pagination.previous))
        }}
        handleShowNextPage={() => {
          dispatch(getCompanyHistory(companyId, pagination.next))
        }}
        table={{
          columns,
          data: filteredCompanyHistory,
          defaultSorted,
          onFetchData: ({ page }) => {
            if (!loading) {
              dispatch(getCompanyHistory(companyId, page > 0 ? page : 1))
            }
          },
        }}
      />
    </div>
  )
}
