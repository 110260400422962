import './style.scss'

import { bool, func, node, string } from 'prop-types'
import React, { Component } from 'react'

import Icon from '../../common/Fontello/index'

export class DropdownComponent extends Component {
  static propTypes = {
    children: node.isRequired,
    containerId: string,
    iconName: string.isRequired,
    isVisible: bool,
    onClick: func,
    showChevronDown: bool,
    showEventMarker: bool,
    renderEventMarker: func,
  }

  static defaultProps = {
    containerId: '',
    isVisible: false,
    onClick: () => undefined,
    showChevronDown: true,
    showEventMarker: false,
    renderEventMarker: null,
  }

  /**
   * @description get a event marker or override this func by prop func 'renderEventMarker'.
   * @return {*}
   */
  renderEventMarker() {
    if (this.props.renderEventMarker)
      return this.props.renderEventMarker(this.props)

    return <span className='dropdown-event-marker' />
  }

  render() {
    return (
      <div className='dropdown-container uk-navbar-item'>
        <button
          type='button'
          className='dropdown-toggle-icon uk-navbar-toggle-icon'
          onClick={this.props.onClick}
        >
          <Icon name={this.props.iconName} />

          {this.props.showChevronDown && (
            <span className='chevron-decorator'>
              <Icon name='chevron-down' />
            </span>
          )}

          {this.props.showEventMarker && this.renderEventMarker()}
        </button>

        <div
          id={this.props.containerId}
          className={[
            'uk-navbar-dropdown',
            'uk-padding-remove',
            'dropdown-body-container',
            `${
              this.props.showChevronDown
                ? 'with-chevron-decorator'
                : 'without-chevron-decorator'
            }`,
            `${
              this.props.isVisible ? 'uk-open uk-animation-fade' : 'uk-hidden'
            }`,
          ].join(' ')}
          data-uk-dropdown='pos: bottom-left'
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}

export default DropdownComponent
