import { combineReducers } from 'redux'

import ActionTypes from '../actiontypes/dsz'
import { replaceObjectInArray } from '../helper/general'

export const initialInvoiceState = {
  dszInvoicesList: [],
  pagination: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
}

/**
 * @description A reducer for the DSZ package (Verpackungslizenzierung)
 * @param {Object} state is the current globale state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
export const dszInvoiceReducer = (state = initialInvoiceState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DSZ_INVOICES_SUCCESS:
      return {
        ...state,
        dszInvoicesList: action.payload.data,
        pagination: { loaded: true, ...action.payload.pagination },
      }
    case ActionTypes.UPDATE_DSZ_INVOICE_SUCCESS:
      return {
        ...state,
        dszInvoicesList: replaceObjectInArray(
          action.payload,
          state.dszInvoicesList,
        ),
      }
  }
  return state
}

export const initialContractState = {
  contractList: [],
  pagination: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
}

/**
 * @description A reducer for the DSZ package (Verpackungslizenzierung)
 * @param {Object} state is the current globale state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
export const contractReducer = (state = initialContractState, action) => {
  switch (action.type) {
    case ActionTypes.GET_CONTRACTS_SUCCESS:
      return {
        ...state,
        contractList: action.payload.data,
        pagination: { loaded: true, ...action.payload.pagination },
      }
  }
  return state
}

export const initialDSZFractionState = {
  fractionList: [],
}

/**
 * @description A reducer for the DSZ package (Verpackungslizenzierung)
 * @param {Object} state is the current globale state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
export const dszFractionReducer = (state = initialDSZFractionState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DSZ_FRACTIONS_SUCCESS:
      return {
        ...state,
        fractionList: action.payload,
      }
  }
  return state
}

export const initialDszFractionPricesState = {
  fractionPriceList: [],
  pagination: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
}

/**
 * @description A reducer for the DSZ package (Verpackungslizenzierung)
 * @param {Object} state is the current globale state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
export const dszFractionPriceReducer = (
  state = initialDszFractionPricesState,
  action,
) => {
  switch (action.type) {
    case ActionTypes.GET_DSZ_FRACTION_PRICES_SUCCESS:
      return {
        ...state,
        fractionPriceList: action.payload.data,
        pagination: { loaded: true, ...action.payload.pagination },
      }
  }
  return state
}

export const dszReducer = combineReducers({
  invoice: dszInvoiceReducer,
  contract: contractReducer,
  fraction: dszFractionReducer,
  fractionPrices: dszFractionPriceReducer,
})
