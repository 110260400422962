import ActionTypes from '../actiontypes/executionproof'
import { replaceObjectInArray } from '../helper/general'

export const initialState = {
  item: {},
  itemList: [], // list of all execution proofs that admin can see
  myItemList: [], // list of my execution proofs
  pagination: {
    count: 0,
    next: 0,
    current: 0,
    previous: 0,
    total_results: 0,
    loaded: false,
  },
}

/**
 * @description A reducer for execution proofs.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const executionProofReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_EXECUTION_PROOFS_SUCCESS:
      return {
        ...state,
        itemList: action.payload.data,
        pagination: { loaded: true, ...action.payload.pagination },
      }
    case ActionTypes.GET_EXECUTION_PROOF_SUCCESS:
      return {
        ...state,
        item: {
          ...state.item,
          ...action.payload,
          // fraction_is_equal is being passed as a string here so it can be normalized.
          // The value for this item is a boolean, coming from the API. However, the choice between "yes" or "no" will
          // forcefully add this value as a string to the store.
          // This will cause some inconsistency in terms of PropTypes. In the form we are passing it as a string,
          // but in the overview we are getting it as a boolean.
          // With this we just focus on a string type.
          fraction_is_equal: `${action.payload.fraction_is_equal}`,
        },
      }
    // Action triggered after reviewing an execution proof
    case ActionTypes.UPDATE_REVIEWED_EXECUTION_PROOF_SUCCESS:
      return {
        ...state,
        item: initialState.item,
        itemList: replaceObjectInArray(
          {
            ...action.payload,
            // same reason as above
            fraction_is_equal: `${action.payload.fraction_is_equal}`,
          },
          state.itemList,
        ),
      }
    case ActionTypes.CREATE_EXECUTION_PROOF_SUCCESS: {
      return {
        ...state,
        item: action.payload,
        itemList: [action.payload, ...state.itemList],
      }
    }
    case ActionTypes.RESET_EXECUTION_PROOF_ITEM_REQUEST:
      return {
        ...state,
        item: initialState.item,
      }
    default:
      return state
  }
}

export default executionProofReducer
