import './BillingModelFormFields.scss'

import { useFormikContext } from 'formik'
import React, { Dispatch, SetStateAction } from 'react'
import { I18n, Translate } from 'react-i18nify'

import {
  Headline,
  HEADLINE_COLOR,
  HEADLINE_FONT_TYPE,
  HEADLINE_FONT_WEIGHT,
  HEADLINE_STYLE,
  HEADLINE_TAG,
} from 'components/common/Headline'
import { RadioPanel } from 'components/common/RadioPanel'
import { RadioPanelGroup } from 'components/common/RadioPanelGroup'

import { AddAgreementFormValues } from '../../AddAgreementFormSteps'
import { PRICING } from '../DefineNetPriceForm'

import { clearPricingFields } from './helpers'

type PricingFormFieldsPropsType = {
  pricing?: string
  role: string
  setPricing: Dispatch<SetStateAction<string>>
  disableCompensation: boolean
}

export const PricingFormFields = (props: PricingFormFieldsPropsType) => {
  /* Component for displaying 2 radio buttons for selecting the pricing type */
  const { pricing, role, setPricing, disableCompensation = false } = props

  const { setFieldValue, validateForm } =
    useFormikContext<AddAgreementFormValues>()

  return (
    <>
      <div className='radio-panel-group'>
        <RadioPanelGroup
          columns={2}
          initialSelection={pricing}
          label={I18n.t(
            'addAgreementPageTranslations.steps.4.fields.pricing.label',
          )}
          onChange={value => {
            setFieldValue(`${role}_pricing`, value)
            setPricing(value)
            clearPricingFields({ value, role, setFieldValue })
            validateForm()
          }}
          enableReinitialize
        >
          <RadioPanel value={PRICING.DISPOSAL}>
            <Headline
              color={HEADLINE_COLOR.DARK_GRAY}
              fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
              fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
              headlineStyle={HEADLINE_STYLE.H5}
              noMargin
              tag={HEADLINE_TAG.H3}
            >
              <Translate value='addAgreementPageTranslations.steps.4.fields.pricing.disposal' />
            </Headline>
          </RadioPanel>
          <RadioPanel
            value={PRICING.COMPENSATION}
            isDisabled={disableCompensation}
          >
            <Headline
              color={HEADLINE_COLOR.DARK_GRAY}
              fontType={HEADLINE_FONT_TYPE.SANS_SERIF}
              fontWeight={HEADLINE_FONT_WEIGHT.MEDIUM}
              headlineStyle={HEADLINE_STYLE.H5}
              noMargin
              tag={HEADLINE_TAG.H3}
            >
              <Translate value='addAgreementPageTranslations.steps.4.fields.pricing.compensation' />
            </Headline>
          </RadioPanel>
        </RadioPanelGroup>
      </div>
    </>
  )
}
