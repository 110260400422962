import ActionTypes from '../actiontypes/securitygroup'

export const itemInitialState = {
  protection_class: null,
  security_level: null,
}

export const initialState = {
  itemList: [],
}

/**
 * @description A reducer for security group.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const securityGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GET_SECURITY_GROUPS_SUCCESS:
      return {
        ...state,
        itemList: action.payload,
      }
    default:
      return state
  }
}

export default securityGroupReducer
