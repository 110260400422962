export const getPriceComparisonTableSelector: (state: {
  [key: string]: any
}) => PriceComparisonCompany[] = state => state.pricecalc.priceComparisonTable

export const getPriceComparisonTablePausedSelector: (state: {
  [key: string]: any
}) => PriceComparisonCompany[] = state =>
  state.pricecalc.priceComparisonTablePaused

export const getCustomTaxSelector: (state: {
  [key: string]: any
}) => any = state => state.pricecalc.customTax
