import { useFormikContext } from 'formik'
import React, { FC, useContext } from 'react'
import { I18n } from 'react-i18nify'
import { useSelector } from 'react-redux'

import { BUTTON_LINK_COLOR, ButtonLink } from 'components/common/ButtonLink'
import { FormStepSummaryRow } from 'components/common/FormSteps'
import createMarkup from 'components/common/TooltipIcon/helpers'
import { getSelectedAddressDisplayName } from 'components/inquiry/CreateInquiryPage/helpers'
import { getAddressListForCompanySelector } from 'selectors/address'
import { getMaklerPremiumCompaniesSelector } from 'selectors/company'

import {
  CreateMaklerPremiumOfferContext,
  CUSTOMER_TYPE,
  MaklerPremiumOfferValues,
} from '../CreateMaklerPremiumOfferFormSteps'

import { getSelectedCompany } from './helpers'

export const CustomerInfoSummary: FC = () => {
  const customers = useSelector(getMaklerPremiumCompaniesSelector)
  const addressList = useSelector(getAddressListForCompanySelector)
  const { values } = useFormikContext<MaklerPremiumOfferValues>()
  const { editStep } = useContext(CreateMaklerPremiumOfferContext)

  return (
    <div className='maklerpremium-step-summary'>
      <div className='maklerpremium-step-summary_grid-body'>
        <FormStepSummaryRow
          label={I18n.t(
            'createMaklerPremiumOfferPageTranslations.steps.1.radiopanel.returningCustomer',
          )}
          value={
            I18n.t(
              `general.${
                values.customer_type === CUSTOMER_TYPE.NEW_CUSTOMER
                  ? 'no'
                  : 'yes'
              }`,
            ) as string
          }
        />
        {values.customer_type === CUSTOMER_TYPE.RETURNING_CUSTOMER && (
          <>
            <FormStepSummaryRow
              label={I18n.t(
                'createMaklerPremiumOfferPageTranslations.steps.1.fields.company_name.label',
              )}
              value={
                getSelectedCompany(Number(values.existing_customer), customers)!
                  .name
              }
            />
            <FormStepSummaryRow
              label={I18n.t(
                'createMaklerPremiumOfferPageTranslations.steps.1.fields.empto_external_number.label',
              )}
              value={
                getSelectedCompany(Number(values.existing_customer), customers)!
                  .empto_external_number
              }
            />
            <FormStepSummaryRow
              label={I18n.t(
                'createMaklerPremiumOfferPageTranslations.steps.1.fields.zipcode.label',
              )}
              value={
                getSelectedCompany(Number(values.existing_customer), customers)!
                  .main_address_object.zipcode
              }
            />
            <FormStepSummaryRow
              label={I18n.t(
                'createInquiryTranslations.form.addressAndDeliveryFormGroup.summary.address',
              )}
              value={
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={createMarkup(
                    getSelectedAddressDisplayName(
                      Number(values.collection_address),
                      addressList,
                    ),
                  )}
                />
              }
            />
          </>
        )}
        {values.customer_type === CUSTOMER_TYPE.NEW_CUSTOMER && (
          <FormStepSummaryRow
            label={I18n.t(
              'createMaklerPremiumOfferPageTranslations.steps.1.fields.zipcode.label',
            )}
            value={values.zipcode}
          />
        )}
      </div>
      <div className='sidebar'>
        <ButtonLink
          className='sidebar__edit-link'
          linkColor={BUTTON_LINK_COLOR.PRIMARY}
          onClick={editStep}
        >
          {I18n.t('createInquiryTranslations.form.button.editStep')}
        </ButtonLink>
      </div>
    </div>
  )
}
