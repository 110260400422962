import ActionTypes from '../actiontypes/zipcode'

/** @function
 * @name getZipcodes
 * @description An action method to get a list of zipcodes. Can be filtered, e.g. by the start of the code.
 * @return {Object}
 */
export const getZipcodes = filters => ({
  type: ActionTypes.GET_ZIPCODES_REQUEST,
  payload: filters,
})

/** @function
 * @name getZipcode
 * @description An action method to get a zipcode. Commonly used to check if the code is active.
 * @return {Object}
 */
export const getZipcode = (code, filters) => ({
  type: ActionTypes.GET_ZIPCODE_REQUEST,
  payload: {
    code,
    ...filters,
  },
})

/** @function
 * @name hideZipcodeValidationMessage
 * @description An action method to hide zipcode validation message.
 * @return {Object}
 */
export const hideZipcodeValidationMessage = () => ({
  type: ActionTypes.HIDE_ZIPCODE_VALIDATION_MESSAGE,
})

/** @function
 * @name resetZipcode
 * @description An action method to reset a zipcode item.
 * @return {Object}
 */
export const resetZipcode = () => ({
  type: ActionTypes.RESET_ZIPCODE,
})

/** @function
 * @name submitZipcode
 * @description An action method to set the zipcode as submitted.
 * @return {Object}
 */
export const submitZipcode = () => ({
  type: ActionTypes.SUBMIT_ZIPCODE,
})

/** @function
 * @name createInactiveZipcodeWishList
 * @description An action method to create Inactive Zipcode Request.
 * @return {Object}
 */
export const createInactiveZipcodeWishList = data => ({
  type: ActionTypes.CREATE_INACTIVE_ZIPCODE_WISHLIST_REQUEST,
  payload: data,
})

export default {}
