export const InactiveCompanyModalTranslations = {
  inactiveCompanyModal: {
    title: 'Hinweis',
    text:
      'Aktuell können Sie keine weiteren Anfragen erstellen, da wichtige Daten von Ihnen fehlen. ' +
      'Um weitere Anfragen zu veröffentlichen, ist es notwendig, dass Sie Ihre Stammdaten hinterlegen.',
    abort: 'Abbrechen',
    forward: 'Stammdaten hinterlegen',
  },
}
