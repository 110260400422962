import { I18n } from 'react-i18nify'
import { actions } from 'react-redux-form'
import { toastr } from 'react-redux-toastr'
import { createLogic } from 'redux-logic'

import AppActionTypes from '../actiontypes/app'
import ActionTypes from '../actiontypes/executionproof'
import OrderActionTypes from '../actiontypes/order'
import { APP_CONSTANTS, TABLE_PAGE_SIZE_10 } from '../constants/app'
import { enhanceError } from '../helper/enhanceError'

/**
 * @function
 * @name handleGetExecutionProofs
 * @description gets list of execution proofs
 */
export const handleGetExecutionProofs = createLogic({
  type: ActionTypes.GET_EXECUTION_PROOFS_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    let data = {}

    if (action.payload) {
      data = {
        page: action.payload.page || 1,
        page_size: action.payload.pageSize || TABLE_PAGE_SIZE_10,
      }

      if (action.payload.filters) {
        data = { ...data, ...action.payload.filters }
      }
    }
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/execution-proof/`, data)
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_EXECUTION_PROOFS_SUCCESS,
          payload: res,
        })
        dispatch({
          type: AppActionTypes.RESET_API_FETCH_ERRORS,
          payload: 'GET_EXECUTION_PROOF',
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.executionProof.handleGetExecutionProofs.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_EXECUTION_PROOFS_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleGetExecutionProof
 * @description gets a single execution proof
 */
export const handleGetExecutionProof = createLogic({
  type: ActionTypes.GET_EXECUTION_PROOF_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .get(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/execution-proof/${action.payload}/?expand=order_object__offer_object__company_object,order_object__offer_object__customer_company_object`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.GET_EXECUTION_PROOF_SUCCESS,
          payload: res,
        })
      })
      .then(done)
      .catch(err => {
        const humanReadableError = I18n.t(
          'api.executionProof.handleGetExecutionProof.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.GET_EXECUTION_PROOF_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleCreateExecutionProof
 * @description Creates a new execution proof. This will only work if there is no pending version of this type yet.
 * Otherwise the existing pending execution proof should be updated instead.
 */
export const handleCreateExecutionProof = createLogic({
  type: ActionTypes.CREATE_EXECUTION_PROOF_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    httpClient
      .post(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/execution-proof/`,
        action.payload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch(actions.reset('executionProof.item'))
        dispatch({
          type: ActionTypes.CREATE_EXECUTION_PROOF_SUCCESS,
          payload: res,
        })
        // TODO @rui: refactor backend in order to exclude this api call and send the most recent object through
        // serializer
        dispatch({
          type: OrderActionTypes.GET_ORDER_REQUEST,
          payload: res.order,
        })

        if (action.payload.callbacks && action.payload.callbacks.onSuccess)
          action.payload.callbacks.onSuccess()

        toastr.success(
          '',
          I18n.t('api.executionProof.handleCreateExecutionProof.success'),
        )
      })
      .then(done)
      .catch(err => {
        dispatch(actions.setPending('executionProof.item', false))
        const humanReadableError = I18n.t(
          'api.executionProof.handleCreateExecutionProof.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.CREATE_EXECUTION_PROOF_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleUpdateReviewedExecutionProof
 * @description Updates an reviewed execution proof.
 */
export const handleUpdateReviewedExecutionProof = createLogic({
  type: ActionTypes.UPDATE_REVIEWED_EXECUTION_PROOF_REQUEST,
  latest: true,

  process({ httpClient, action }, dispatch, done) {
    // if there is a Fine Fraction Selected, then we want to send that one to the backend
    const newFraction = action.payload.executionProof.fine_fraction
      ? action.payload.executionProof.fine_fraction
      : action.payload.executionProof.fraction
    const newFractionIsEqual =
      action.payload.executionProof.fraction_is_equal === 'true'
    // create the new object to send
    const newPayload = {
      ...action.payload.executionProof,
      fraction_is_equal: newFractionIsEqual,
      fraction: newFraction,
    }
    const decimalValues = [
      'quantity_in_cubic_meters',
      'rent_price_container_month',
      'disposal_cost_container',
      'transport_price_piece',
      'disposal_cost_ton',
      'handle_cost_ton',
      'surcharge',
      'discount',
    ]

    // Remove attachments from the object to send (not needed for review).
    // If not removed, it breaks the review if the attachment file was moved, removed or renamed
    // TODO @rui: remove this after refactoring to formik. Attachment will not be sent anymore
    if (newPayload.attachments && newPayload.attachments.length > 0)
      delete newPayload.attachments

    decimalValues.forEach(item => {
      newPayload[item] = newPayload[item]
        ? String(newPayload[item]).replace(',', '.')
        : '0'
    })

    httpClient
      .put(
        `${APP_CONSTANTS.REACT_APP_API_BASE_URL}/execution-proof/${action.payload.executionProof.id}/review/`,
        newPayload,
      )
      .then(res => res.data)
      .then(res => {
        dispatch({
          type: ActionTypes.UPDATE_REVIEWED_EXECUTION_PROOF_SUCCESS,
          payload: res,
        })
        dispatch({
          type: ActionTypes.GET_EXECUTION_PROOFS_REQUEST,
          payload: res,
        })

        toastr.success(
          '',
          I18n.t(
            'api.executionProof.handleUpdateReviewedExecutionProof.success',
          ),
        )
      })
      .then(() => action.payload.history.push('/execution-proof/all'))
      .then(() => dispatch(actions.reset('executionProof.item')))
      .then(done)
      .catch(err => {
        dispatch(actions.setPending('executionProof.item', false))
        const humanReadableError = I18n.t(
          'api.executionProof.handleUpdateReviewedExecutionProof.error',
        )
        const payload = enhanceError(
          err.message,
          err.config,
          err.code,
          err.request,
          err.response,
          humanReadableError,
        )
        dispatch({
          type: ActionTypes.UPDATE_REVIEWED_EXECUTION_PROOF_ERROR,
          payload,
        })

        if (!err.hideToastr) toastr.error('', humanReadableError)

        done()
      })
  },
})

/**
 * @function
 * @name handleExportExecutionProofs
 * @description triggers the execution proofs export.
 */
export const handleExportExecutionProofs = createLogic({
  type: ActionTypes.EXPORT_EXECUTION_PROOFS_REQUEST,
  latest: true,

  process({ httpClient }, dispatch, done) {
    httpClient
      .get(`${APP_CONSTANTS.REACT_APP_API_BASE_URL}/execution-proof/export/`)
      .then(() => {
        toastr.success(
          '',
          I18n.t('api.executionProof.handleExportExecutionProofs.success'),
        )
        dispatch({
          type: ActionTypes.EXPORT_EXECUTION_PROOFS_SUCCESS,
        })
      })
      .then(done)
      .catch(err => {
        if (!err.hideToastr)
          toastr.error(
            '',
            I18n.t('api.executionProof.handleExportExecutionProofs.error'),
          )
        dispatch({
          type: ActionTypes.EXPORT_EXECUTION_PROOFS_ERROR,
        })
        done()
      })
  },
})

export default [
  handleGetExecutionProofs,
  handleGetExecutionProof,
  handleCreateExecutionProof,
  handleUpdateReviewedExecutionProof,
  handleExportExecutionProofs,
]
