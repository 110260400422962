import './ActionCardGrid.scss'

import React, { FC } from 'react'

/**
 * Renders the Actioncard
 * @constructor
 */
export const ActionCardGrid: FC = ({ children }) => (
  <section className='action-card-grid'>{children}</section>
)
