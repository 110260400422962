import { func, node, number } from 'prop-types'
import React, { Component } from 'react'

import { PaginationButtons } from './components/PaginationButtons'
import { PaginationInfo } from './components/PaginationInfo'

export class PaginatedContentComponent extends Component {
  static propTypes = {
    children: node.isRequired,
    page: number,
    pages: number,
    onPreviousPageClick: func,
    onNextPageClick: func,
  }

  static defaultProps = {
    page: 1,
    pages: 1,
    onPreviousPageClick: () => undefined,
    onNextPageClick: () => undefined,
  }

  /**
   * @description render the pagination buttons
   * @return {*}
   */
  renderPaginationButtons() {
    return (
      <>
        <PaginationButtons
          className='uk-float-right'
          page={this.props.page}
          pages={this.props.pages}
          onPreviousPageClick={this.props.onPreviousPageClick}
          onNextPageClick={this.props.onNextPageClick}
        />
        <div className='uk-clearfix' />
      </>
    )
  }

  render() {
    return (
      <div className='pagination-container'>
        <div className='uk-margin-bottom'>
          {this.props.pages > 1 && (
            <>
              <PaginationInfo
                className='uk-float-left'
                page={this.props.page}
                pages={this.props.pages}
              />

              {this.renderPaginationButtons()}
            </>
          )}
        </div>

        <div className='paginated-content'>{this.props.children}</div>

        <div className='uk-margin-top uk-width-1-1'>
          {this.props.pages > 1 && (
            <>
              <PaginationInfo
                className='uk-float-left'
                page={this.props.page}
                pages={this.props.pages}
              />

              {this.renderPaginationButtons()}
            </>
          )}
        </div>
      </div>
    )
  }
}

export default PaginatedContentComponent
