import { connect } from 'react-redux'
import { actions } from 'react-redux-form'
import { bindActionCreators } from 'redux'

/**
 * @function
 * @description method to map the dispatches to the props.
 * @param {function} dispatch Use bindActionCreators here to bind all required action methods.
 * @return {Object}
 */
const mapDispatchToProps = dispatch => ({
  actions: {
    form: bindActionCreators(actions, dispatch),
  },
})

export default connect(null, mapDispatchToProps)
