import { arrayOf, bool, number, shape, string } from 'prop-types'

export const AvvScheme = {
  id: number,
  name: string,
}

export const FractionScheme = {
  avv_list: arrayOf(shape(AvvScheme)),
  containers: arrayOf(number),
  id: number,
  image: string,
  image_large: string,
  name: string,
  comment: string,
  parent: number,
  requires_securitygroup: bool,
  slug: string,
  business_segment: number,
}

export const FractionContentScheme = {
  id: number,
  content_type: number,
  title: string,
  content: string,
  fraction: number,
  fraction_slug: string,
}
