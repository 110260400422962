import './WasteTypeList.scss'

import classNames from 'classnames'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BUSINESS_SEGMENT } from 'constants/app'
import { getFractions } from 'actions/fraction'
import { getFractionsSelector } from 'selectors/fraction'

import { WasteTypeCard } from './WasteTypeCard'

export const WasteTypeList = () => {
  const dispatch = useDispatch()
  const fractionList: Fraction[] = useSelector(getFractionsSelector)

  useEffect(() => {
    if (fractionList.length < 1) dispatch(getFractions())
  }, [dispatch, fractionList])

  return (
    <div
      className={classNames({
        'waste-type-list': true,
      })}
    >
      <div className={classNames('waste-type-list__grid')}>
        {fractionList.map(
          item =>
            !item.parent &&
            ((item.visible_in_empto && item.parent !== null) ||
              (item.visible_in_empto &&
                fractionList.filter(
                  fractionItem =>
                    item.parent === fractionItem.id &&
                    fractionItem.visible_in_empto,
                ))) &&
            [
              BUSINESS_SEGMENT.BUSINESS_EMPTO,
              BUSINESS_SEGMENT.BUSINESS_ALL,
            ].includes(Number(item.business_segment)) && (
              <WasteTypeCard key={item.id} fraction={item} />
            ),
        )}
      </div>
    </div>
  )
}
