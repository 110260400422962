import './UniversalFileUploadPreview.scss'

import classNames from 'classnames'
import React, { FC, useState } from 'react'
import { I18n } from 'react-i18nify'

import { UploadItem } from './types'
import { UniversalFileUploadModal } from './UniversalFileUploadModal'
import { UniversalFileUploadPreviewItem } from './UniversalFileUploadPreviewItem'
import { UniversalFileUploadPreviewTextItem } from './UniversalFileUploadPreviewTextItem'

interface UniversalFileUploadPreviewProps {
  allowComments?: boolean
  allowDelete?: boolean
  allowRemove?: boolean
  allowRotate?: boolean
  fileList: UploadItem[]
  maxFiles: number
  showFilename?: boolean
  showPreviewAsTextItem?: boolean
  showPreviewLabel?: boolean
  showPreviewLabelCounter?: boolean
  onRemove?: (file: UploadItem) => void
  onRotate?: (file: UploadItem) => void
  onComment?: (file: UploadItem, message: string) => void
  previewAsCircle?: boolean
}

/**
 * Display a preview section for uploaded files which can be a simple text
 * based file list or an enhanced list with thumbnails and additional features.
 * @constructor
 */
export const UniversalFileUploadPreview: FC<
  UniversalFileUploadPreviewProps
> = ({
  allowComments,
  allowRemove,
  allowRotate,
  fileList,
  maxFiles,
  showFilename = false,
  showPreviewAsTextItem,
  showPreviewLabel,
  showPreviewLabelCounter,
  onRemove = () => undefined,
  onRotate = () => undefined,
  onComment = () => undefined,
  previewAsCircle = true,
}) => {
  /* state for selected item which is shown in modal */
  const [modalItem, setModalItem] = useState<UploadItem | undefined>(undefined)

  /* writes changes to source item and removes modal copy */
  const handleModalClose = (): void => {
    if (!modalItem) return

    if (modalItem.text) onComment(modalItem, modalItem.text)
    setModalItem(undefined)
  }

  /* writes changes when editing comments to a local copy to prevent re-renderings */
  const handleCommentChange = (text: string) => {
    if (!modalItem) return

    setModalItem({
      ...modalItem,
      text,
    })
  }

  /* prevent rendering anything if fileList is empty */
  if (fileList.length < 1) return null

  return (
    <>
      <div className='universal-file-upload-preview'>
        {(showPreviewLabel || showPreviewLabelCounter) && (
          <div className='universal-file-upload-preview__label'>
            {showPreviewLabel &&
              !showPreviewLabelCounter &&
              I18n.t('universalFileUpload.previewLabel')}
            {showPreviewLabelCounter &&
              I18n.t('universalFileUpload.previewLabelCounter', {
                currentFilesCount: fileList.length,
                maxFiles,
              })}
          </div>
        )}
        <div
          className={classNames({
            'universal-file-upload-preview__list': true,
            'universal-file-upload-preview__list--text': showPreviewAsTextItem,
          })}
        >
          {!showPreviewAsTextItem &&
            fileList.map(file => (
              <UniversalFileUploadPreviewItem
                key={file.file}
                file={file}
                allowRemove={allowRemove}
                allowRotate={allowRotate}
                onRemove={() => onRemove(file)}
                onClick={() => setModalItem(file)}
                onRotate={() => onRotate(file)}
                previewAsCircle={previewAsCircle}
                showFilename={showFilename}
              />
            ))}
          {showPreviewAsTextItem &&
            fileList.map(file => (
              <UniversalFileUploadPreviewTextItem
                key={file.file}
                file={file}
                onRemove={() => onRemove(file)}
              />
            ))}
        </div>
      </div>

      {modalItem && (
        <UniversalFileUploadModal
          item={modalItem}
          onClose={handleModalClose}
          onCommentChange={handleCommentChange}
          allowComments={allowComments}
        />
      )}
    </>
  )
}
