export const orderTranslations = {
  orderClaimTranslations: {
    completion: 'Reklamationen',
    title: 'Problem melden',
    description:
      'Mit dem nachstehenden Formular haben Sie die Möglichkeit ein Problem an empto zu melden. ' +
      'Danach vermerken wir Ihr Anliegen im Auftrag und ein Mitarbeiter ' +
      'von empto wird sich schnellstmöglich um Ihr Anliegen kümmern. ' +
      'Bitte beschreiben Sie Ihr Anliegen so detailliert wie möglich.',
    claimReason: 'Grund für Ihr Anliegen',
    attachments: 'Fotos zu ihrem Anliegen',
    claimDescription: 'Weitere Informationen',
    types: {
      notCarried: 'Entsorgung wurde nicht durchgeführt',
      notCompleted: 'Entsorgung wurde nicht vollständig durchgeführt',
      creditWrong: 'Rechnung/Gutschrift falsch',
      containerWrong: 'Falscher Behälter gestellt',
      creditAlreadyPaid: 'Rechnung wurde bereits bezahlt (Mahnung erhalten)',
      deadhead: 'Leerfahrt (Entsorgung konnte nicht durchgeführt werden)',
      wasteTypeNotMatched:
        'Umdeklaration (Abfallart bei Abholung entspricht nicht Abfallart gemäß Auftrag)',
      others: 'Sonstiges',
      waitingTime: 'Wartezeiten/Sortierkosten',
      messages: {
        notCarried:
          '<b>%{name}</b> meldet eine nicht durchgeführte Entsorgung.',
        notCompleted:
          '<b>%{name}</b> meldet eine nicht vollständig durchgeführte Entsorgung.',
        creditWrong: '<b>%{name}</b>  meldet eine nicht korrekte Abrechnung.',
        containerWrong: '<b>%{name}</b>  meldet einen falschen Behälter.',
        creditAlreadyPaid: '<b>%{name}</b>  meldet eine nicht korrekte Mahnung',
        deadhead: '<b>%{name}</b> meldet eine Leerfahrt.',
        wasteTypeNotMatched:
          '<b>%{name}</b>  meldet eine nicht korrekte Befüllung des Behälters.',
        others: '<b>%{name}</b> hat ein Problem an empto gemeldet.',
        waitingTime: '<b>%{name}</b> meldet Wartezeit bzw. Sortierkosten.',
      },
    },
    validation: {
      orderClaimTypeError: 'Bitte wählen Sie einen Grund für Ihr Anliegen aus',
      orderClaimDescriptionRequired:
        'Bitte beschreiben Sie den Grund der Reklamation.',
    },
    toastr: {
      errors: {
        createOrderClaim: 'Melden des Problems fehlgeschlagen.',
      },
      success: {
        createOrderClaim: 'Problem wurde erfolgreich gemeldet.',
      },
    },
  },
}
