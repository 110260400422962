import {
  GET_LANDING_PAGE_WASTE_MANAGER_CONTENT as ActionTypesWasteManager,
  GET_LANDING_PAGE_WASTE_PRODUCER_CONTENT as ActionTypesLandingpage,
} from '../actiontypes/landingpage'

export const itemInitialState = {
  id: null,
  content: null,
  title: null,
  type: null,
}

export const initialState = {
  wasteProducerLandingPage: {
    itemList: [],
  },
  wastemanagerLandingPage: {
    itemList: [],
  },
}

/**
 * @description A reducer for landing page content.
 * @param {Object} state is the current global state from store.
 * @param {Object} action is the called action.
 * @return {Object}
 */
const landingPageReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypesWasteManager.GET_LANDING_PAGE_WASTE_MANAGER_CONTENT_SUCCESS:
      return {
        ...state,
        wastemanagerLandingPage: {
          itemList: action.payload,
        },
      }
    case ActionTypesLandingpage.GET_LANDING_PAGE_WASTE_PRODUCER_CONTENT_SUCCESS:
      return {
        ...state,
        wasteProducerLandingPage: {
          itemList: action.payload,
        },
      }
    default:
      return state
  }
}

export default landingPageReducer
